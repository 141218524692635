import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';

const propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
const defaultProps = {};

const doFormatNumber = (value) => {
    let whole;
    let decimal;
    let split;
    if (!value) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        value = _.isNumber(value) ? value : undefined;
        return value;
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    value = _.toString(value);
    if (value.indexOf('.') !== -1) {
        // If the user entered their own decimal we use their placement.
        split = value.split('.');
        whole = split[0] ? split[0].replace(/[^0-9]/g, '') : 0;
        decimal = _.padEnd(split[1], 2, 0);
    } else {
        whole = value.replace(/[^0-9]/g, '');
        decimal = '00';
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-use-before-define
    return doUnformatNumber(`${whole}.${decimal}`);
};

const doUnformatNumber = (value) => {
    if (value) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        value = _.toString(value);
        return value.replace(/(?!\.)([^0-9])/g, '');
    }
    return _.isNumber(value) ? value : undefined;
};

function MoneyInput(props) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    const { id, name, value, placeholder, onChange, disabled, required, className, invalid, errorMessage } = props;
    const valueIntlFormat = Intl.NumberFormat('en-US', { style: 'decimal' });

    return (
        <form>
            <FormGroup>
                <ControlLabel htmlFor={id} className={invalid ? 'invalid-insurance-field field-required' : className}>
                    {placeholder}
                </ControlLabel>
                <InputGroup>
                    <InputGroup.Addon>$</InputGroup.Addon>
                    <FormControl
                        required={required}
                        invalid={invalid}
                        id={id}
                        name={name}
                        disabled={disabled}
                        type="text"
                        placeholder={placeholder}
                        onChange={(event) => {
                            event.preventDefault();
                            onChange({
                                ...event,
                                target: { ...event.target, value: doUnformatNumber(event.target.value) },
                            });
                        }}
                        onBlur={(event) => {
                            event.preventDefault();
                            onChange({
                                ...event,
                                target: {
                                    ...event.target,
                                    value:
                                        event.target.value.trim() !== ''
                                            ? valueIntlFormat.format(doFormatNumber(event.target.value))
                                            : '0.00',
                                },
                            });
                        }}
                        value={value}
                        className={invalid && 'invalid-insurance-form'}
                    />
                </InputGroup>
                {invalid ? <span className="error-message-insurance">{errorMessage}</span> : null}
            </FormGroup>
        </form>
    );
}

MoneyInput.propTypes = propTypes;
MoneyInput.defaultProps = defaultProps;

export default MoneyInput;
