import { gql } from 'graphql-request';
import graphClient from '../common/graphClient';
import * as R from 'ramda';
import { useQuery } from 'react-query';
import { errorHandler } from 'App/utils';

/*
GraphQl request for fetch list of medications
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line consistent-return
function fetchMedications(therapyId) {
    if (therapyId) {
        return graphClient
            .request(
                gql`
                    query GetMedications($therapyId: String!) {
                        medications(therapyId: $therapyId) {
                            id
                            name
                            medicationGroup
                            groupId
                            divisionId
                            companyId
                            disabledBy
                            disabledOn
                            brand
                            createdOn
                            dateTime
                            hasPhysician
                        }
                    }
                `,
                {
                    therapyId,
                }
            )
            .then(R.propOr([], 'medications'));
    }
}
/*
fetch list of medications based on therapy id
 */
function useFetchMedicationsQuery(therapyId) {
    return useQuery(['therapyId', therapyId, 'medications'], () => fetchMedications(therapyId), {
        onError: (error) => {
            errorHandler(error);
        },
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchMedicationsQuery;
