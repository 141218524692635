import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import FormControl from 'react-bootstrap/lib/FormControl';
import Select from 'react-select';
import { useFormikContext } from 'formik';
import { dateRelationOptions } from '../constants';
import { isFieldValid } from '../utils';
import * as R from 'ramda';
import { cx } from '@emotion/css';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    dateTypeOptions: PropTypes.array,
};

const defaultProps = {};

function ActivityDueDate({ dateTypeOptions }) {
    const formik = useFormikContext();

    if (!dateTypeOptions) {
        return null;
    }
    const isDaysValid = isFieldValid('days', formik);
    const isDateRelationValid = isFieldValid('dateRelation', formik);
    const isDateTypeValid = isFieldValid('dateType', formik);

    const hasErrorLabel = R.includes('error', [isDateRelationValid, isDateTypeValid, isDaysValid]);

    return (
        <FormGroup validationState={hasErrorLabel ? 'error' : null}>
            <div className="label-tooltip-group">
                <ControlLabel htmlFor="activity-due-date-days" className="field-required">
                    Activity Due Date
                </ControlLabel>

                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="activity-due-date">
                            The Activity Due Date for the new activity that will be triggered
                        </Tooltip>
                    }
                >
                    <FaInfoCircle className="info-icon" />
                </OverlayTrigger>
            </div>

            <div className="activity-due-date-group">
                <FormControl
                    id="activity-due-date-days"
                    min="0"
                    type="number"
                    value={formik.values.days}
                    name="days"
                    className={cx('due-date', { 'has-error': isDaysValid })}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="#"
                />
                <span> days </span>

                <Select
                    options={dateRelationOptions}
                    className={cx('before-after form-control react-select', { 'has-error': isDateRelationValid })}
                    value={formik.values.dateRelation}
                    onChange={(option) => {
                        formik.setFieldValue('dateRelation', option);
                    }}
                    onBlur={() => formik.setFieldTouched('dateRelation')}
                />

                <Select
                    options={dateTypeOptions}
                    className={cx('date-type react-select', { 'has-error': isDateTypeValid })}
                    aria-label="Due Date Type"
                    value={formik.values.dateType}
                    onChange={(option) => {
                        formik.setFieldValue('dateType', option);
                    }}
                    onBlur={() => formik.setFieldTouched('dateType', true, false)}
                />
            </div>
        </FormGroup>
    );
}

ActivityDueDate.propTypes = propTypes;
ActivityDueDate.defaultProps = defaultProps;

export default ActivityDueDate;
