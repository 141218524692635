import { useQuery } from 'react-query';
import { getProtocolTemplates } from 'App/services/ProtocolTemplateService';

function useFetchAllProtocolTemplatesQuery() {
    return useQuery(['protocol', 'templates'], () => getProtocolTemplates(), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchAllProtocolTemplatesQuery;
