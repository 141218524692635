import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import AttachmentEditModal from './AttachmentEditModal';
import PropTypes from 'prop-types';
import summaryNotePropType from './types/summaryNotePropType';

const AttachmentsCell = ({ value, row }) => {
    const [attachmentsModalOpen, setAttachmentsModalOpen] = useState(false);
    if (value === undefined) {
        return null;
    }
    return (
        <>
            <Button bsStyle="link" onClick={() => setAttachmentsModalOpen(true)}>
                [{value}]
            </Button>
            {attachmentsModalOpen && (
                <AttachmentEditModal onClose={() => setAttachmentsModalOpen(false)} note={row.original} />
            )}
        </>
    );
};

AttachmentsCell.propTypes = {
    value: PropTypes.number,
    row: PropTypes.shape({
        original: summaryNotePropType,
    }).isRequired,
};

AttachmentsCell.defaultProps = {
    value: undefined,
};
export default AttachmentsCell;
