import styled from '@emotion/styled';

const PageHeader = styled('div')`
    margin-bottom: 10px;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export default PageHeader;
