/**
 *
 * @ngdoc module
 * @name components.auth
 *
 * @requires ui.router
 *
 * @description
 *
 * This is the auth module. It includes our auth components: login, forgotPassword, and resetPassword
 *
 **/

import AuthController from './auth.controller';
import AuthConfig from './auth.config';
import AuthRun from './auth.run';

import IncompleteConfigurationConfig from './incomplete-configuration/incomplete-configuration.config';

import LoginController from './login/login.controller';
import LoginConfig from './login/login.config';

import LogoutController from './logout/logout.controller';
import LogoutConfig from './logout/logout.config';

import ResetPasswordController from './reset-password/reset-password.controller';
import ResetPasswordConfig from './reset-password/reset-password.config';

import SSOConfig from './sso/sso.config';

import TipsController from './tips/tips.controller';

// Import Services
import AuthService from '../../services/auth.service';
import ForgotPasswordController from './forgot-password/forgot-password.controller';
import ForgotPasswordConfig from './forgot-password/forgot-password.config';

export default angular
    .module('components.auth', ['ngSanitize', 'ui.router'])
    .component('auth', {
        controller: AuthController,
        template: require('./auth.html'),
    })
    .component('login', {
        bindings: {
            successMessage: '<',
        },
        controller: LoginController,
        template: require('./login/login.html'),
    })
    .config(LoginConfig)
    .component('logout', {
        controller: LogoutController,
        template: require('./logout/logout.html'),
    })
    .config(LogoutConfig)
    .component('resetPassword', {
        controller: ResetPasswordController,
        template: require('./reset-password/reset-password.html'),
    })
    .config(ResetPasswordConfig)
    .component('sso', {})
    .config(SSOConfig)
    .component('tips', {
        controller: TipsController,
        template: require('./tips/tips.html'),
    })
    .component('forgotPassword', {
        controller: ForgotPasswordController,
        template: require('./forgot-password/forgot-password.html'),
    })
    .config(ForgotPasswordConfig)
    .component('incompleteConfiguration', {
        template: require('./incomplete-configuration/incomplete-configuration.html'),
    })
    .config(IncompleteConfigurationConfig)
    .service('authService', AuthService)
    .config(AuthConfig)
    .run(AuthRun).name;
