import './polyfills';

import React from 'react';
import { render } from 'react-dom';
import { isProd } from './app/components/config/config';

import App from './App';

/**
 * Disable react dev tools in production
 */
if (isProd) {
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () {};
    }
}

render(<App />, document.getElementById('app'));
