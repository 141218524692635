import React from 'react';
import Page from 'App/components/Page';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import EditTemplateForm from './EditTemplateForm';

const propTypes = {};

const defaultProps = {};

function EditProtocolTemplate() {
    return (
        <Page title="Edit Protocol Template" showPageTitle permissionType={PERMISSION_TYPES.CLINICAL_ADMIN}>
            <EditTemplateForm />
        </Page>
    );
}

EditProtocolTemplate.propTypes = propTypes;
EditProtocolTemplate.defaultProps = defaultProps;

export default EditProtocolTemplate;
