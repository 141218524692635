import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Select from 'react-select';

const propTypes = {
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.arrayOf(PropTypes.object),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.array,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    errors: PropTypes.array,
};

const defaultProps = {
    onChange: Function,
    isDisabled: false,
    value: null,
    options: [],
};

function UserSelector({ onChange, isDisabled, options, value, errors }) {
    const error = R.compose(
        R.applySpec({
            hasError: R.ifElse(R.isNil, R.F, R.propEq('users.current', 'path')),
            message: R.path(['errors', 0]),
        })
    )(errors);

    return (
        <FormGroup validationState={error.hasError ? 'error' : undefined}>
            <ControlLabel htmlFor="alertUsers" className="field-required">
                Assign users to receive alerts
            </ControlLabel>
            <Select
                name="alertUsers"
                inputId="alertUsers"
                placeholder="Assign users to receive alerts"
                options={options}
                value={value}
                getOptionValue={R.prop('id')}
                getOptionLabel={R.prop('fullName')}
                onChange={onChange}
                isClearable
                isMulti
                isDisabled={isDisabled}
            />
            {error.hasError && <HelpBlock>{error.message}</HelpBlock>}
        </FormGroup>
    );
}

UserSelector.propTypes = propTypes;
UserSelector.defaultProps = defaultProps;

export default UserSelector;
