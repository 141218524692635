import { isArray, isPlainObject, map, mapKeys, mapValues, capitalize } from 'lodash';

const mapKeysDeep = (obj, fn) => {
    if (isArray(obj)) return map(obj, (inner) => mapKeysDeep(inner, fn));

    if (isPlainObject(obj)) return mapValues(mapKeys(obj, fn), (value) => mapKeysDeep(value, fn));

    return obj;
};

const convertKeysToCapitalCase = (obj) =>
    mapKeysDeep(obj, (_, key) => {
        if (key === 'id' || key === 'icd10' || key === 'icd9') return key.toUpperCase();

        if (key === 'unassignedMedications') return capitalize(key);
        if (key === 'mtime') return 'ModifiedOn';
        if (key === 'ctime') return 'CreatedOn';

        return key[0].toUpperCase() + key.slice(1);
    });

export default convertKeysToCapitalCase;
