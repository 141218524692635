import React, { createContext, useContext, useMemo, useState } from 'react';

const defaultContext = {
    name: '',
    planPatientId: '',
    pharmacyInsurancePersonCode: '',
    groupNumber: '',
    payerIdCode: '',
    payerSegment: '',
    medicalProductLine: '',
    pharmacyProductLine: '',
    deductableAmount: 0.0,
    deductableAmountPaidToDate: 0.0,
    pharmacyPlanOopMax: 0.0,
    pharmacyPlanOopMaxPaidToDate: 0.0,
    coverageEffectiveDate: '',
    coverageEndDate: '',
    coverageType: '',
    isIntegratedHighDeductible: '',
    isPharmacyBenefitsCarveOutFromMedical: '',
    pharmacyInsuranceGroupId: '',
    pharmacyInsuranceBin: '',
    pharmacyInsurancePcn: '',
    helpDeskPhone: '',
    disabledOn: '',
    disabledBy: '',
};

const InsuranceContext = createContext(defaultContext);

const { Provider, Consumer: InsuranceContextConsumer } = InsuranceContext;

function useInsuranceContext() {
    return useContext(InsuranceContext);
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
function InsuranceContextProvider({ children }) {
    const [values, setValues] = useState(defaultContext);

    const value = useMemo(() => {
        return {
            values,
            setValues,
        };
    }, [values]);

    return <Provider value={value}>{children}</Provider>;
}

export { InsuranceContext, InsuranceContextConsumer, InsuranceContextProvider, defaultContext, useInsuranceContext };
