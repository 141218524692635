import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useUserContext } from 'App/contexts/UserContext';
import { Link } from 'react-router-dom';
import { ActionButtonLink } from 'App/components/styles/ActionCell';
import './PatientProfileLinkStyles.scss';
import ViewAttachments from '../ViewAttachments/ViewAttachments';

const propTypes = {
    id: PropTypes.string.isRequired,
};
const defaultProps = {};
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
const PatientProfileLinks = ({ id, attachments }) => {
    const user = useUserContext();
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const referralManager = R.path(['company_permissions', 'ReferralManager'], user);

    return (
        <>
            <nav className="profile-actions">
                <ul className="list-inline">
                    <li>
                        <Link to={`/editPatient/${id}`}>Edit </Link>
                    </li>
                    <li>
                        <Link to={`/clinicalSummary?patient_id=${id}&=`}>View Patient Summary</Link>
                    </li>
                    {/* TODO: Fix this the next time the file is edited. */}
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                    <li onClick={() => setShowAttachmentModal(true)}>
                        <ActionButtonLink>
                            {/* TODO: Fix this the next time the file is edited. */}
                            {/* eslint-disable-next-line react/prop-types */}
                            View Attachments <span id="number-of-uploads">({attachments?.numberOfUploads || 0})</span>
                        </ActionButtonLink>
                    </li>
                    {referralManager && (
                        <li>
                            <Link to={`/patient/${id}/referral-manager`}>Referral Manager </Link>
                        </li>
                    )}
                </ul>
            </nav>
            <ViewAttachments
                attachments={attachments}
                show={showAttachmentModal}
                onHide={() => setShowAttachmentModal(false)}
                patientId={id}
            />
        </>
    );
};

PatientProfileLinks.propTypes = propTypes;
PatientProfileLinks.defaultProps = defaultProps;

export default PatientProfileLinks;
