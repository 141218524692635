import * as R from 'ramda';

function parseAssessmentSettings(assessment) {
    return R.over(
        R.lensProp('settings'),
        (setting) => {
            try {
                // yes. the prop is assessment.settings.settings
                if (R.is(String, R.prop('settings', setting))) {
                    return {
                        ...setting,
                        ...JSON.parse(setting.settings),
                    };
                }
                if (R.is(Object, R.prop('settings', setting))) {
                    return {
                        ...setting,
                        ...setting.settings,
                    };
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                return setting;
            }
            return setting;
        },
        assessment
    );
}

export default parseAssessmentSettings;
