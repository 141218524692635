import angular from 'angular';
import VideoLibraryController from './video-library.controller';
import VideoLibraryConfig from './video-library.config';

export default angular
    .module('components.videoLibrary', [])
    .component('videoLibrary', {
        controller: VideoLibraryController,
        template: require('./video-library.html'),
    })
    .config(VideoLibraryConfig).name;
