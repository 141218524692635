import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import EditProtocolTemplate from "./EditProtocolTemplate";
import ViewProtocolTemplates from "./ViewProtocolTemplates";

const propTypes = {};

const defaultProps = {};

function ProtocolTemplateRouter() {
    const { path } = useRouteMatch();

    // return <div>{path}</div>
    return (
        <Switch>
            <Route path={`${path}/template/:templateId`} component={EditProtocolTemplate} />
            <Route exact path={path} component={ViewProtocolTemplates} />
        </Switch>
    );
}

ProtocolTemplateRouter.propTypes = propTypes;
ProtocolTemplateRouter.defaultProps = defaultProps;

export default ProtocolTemplateRouter;
