function AssessmentProgressBarController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    function $onChanges(changes) {
        if (changes.questionGroups && ctrl.questionGroups) {
            ctrl.questionGroups = angular.copy(ctrl.questionGroups);
        }
    }
}

export default AssessmentProgressBarController;
