import { cx } from '@emotion/css';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import pathOrDash from 'App/utils/pathOrDash';
import { isExpired, localize } from 'App/utils';
import dayjs from 'dayjs';
import './patientInsurance.scss';

const propTypes = {
    item: PropTypes.number,
    patientInsurance: PropTypes.shape({
        name: PropTypes.string,
        planPatientId: PropTypes.string,
        pharmacyInsurancePersonCode: PropTypes.string,
        groupNumber: PropTypes.string,
        payerIdCode: PropTypes.string,
        payerSegment: PropTypes.string,
        medicalProductLine: PropTypes.string,
        pharmacyProductLine: PropTypes.string,
        deductableAmount: PropTypes.number,
        deductableAmountPaidToDate: PropTypes.number,
        pharmacyPlanOopMax: PropTypes.number,
        pharmacyPlanOopMaxPaidToDate: PropTypes.number,
        coverageEffectiveDate: PropTypes.string,
        coverageEndDate: PropTypes.string,
        coverageType: PropTypes.string,
        isIntegratedHighDeductible: PropTypes.string,
        isPharmacyBenefitsCarveOutFromMedical: PropTypes.string,
        pharmacyInsuranceGroupId: PropTypes.string,
        pharmacyInsuranceBin: PropTypes.string,
        pharmacyInsurancePcn: PropTypes.string,
        helpDeskPhone: PropTypes.string,
        disabledOn: PropTypes.string,
        disabledBy: PropTypes.string,
        disabledByUser: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        }),
    }),
};

const defaultProps = {
    item: 0,
    patientInsurance: {},
};

function PatientInsuranceProfile(props) {
    const { item, patientInsurance } = props;
    const insurance = patientInsurance[item] || {};

    const valueIntlFormat = Intl.NumberFormat('en-US', { style: 'decimal' });

    const isDisabled =
        R.path([item, 'disabledOn'], patientInsurance) ||
        isExpired(
            dayjs(R.path([item, 'coverageEndDate'], patientInsurance))
                .utc()
                .format('YYYY-MM-DD 23:59:59')
        );

    return (
        <div className="insurance-container-profile">
            <div className={cx('profile-patient-demographics', { 'disable-insurance-plan': isDisabled })}>
                <Col>
                    <Col className="block-section">
                        <Row>
                            <Col md={3}>Plan Name</Col>
                            <Col md={3}>Patient ID</Col>
                            <Col md={3}>Benefit Coverage Type</Col>
                            <Col md={3}>Payor Type</Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <strong>{pathOrDash(['name'], insurance)}</strong>
                            </Col>
                            <Col md={3}>
                                <strong>{pathOrDash(['planPatientId'], insurance)}</strong>
                            </Col>
                            <Col md={3}>
                                <strong>{pathOrDash(['coverageType'], insurance)}</strong>
                            </Col>
                            <Col md={3}>
                                <strong>{pathOrDash(['payerSegment'], insurance)}</strong>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="block-section">
                        <Row>
                            <Col md={3}>BIN</Col>
                            <Col md={3}>PCN</Col>
                            <Col md={3}>RX Group Number</Col>
                            <Col md={3}>Person Code</Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <strong>{pathOrDash(['pharmacyInsuranceBin'], insurance)}</strong>
                            </Col>
                            <Col md={3}>
                                <strong>{pathOrDash(['pharmacyInsurancePcn'], insurance)}</strong>
                            </Col>
                            <Col md={3}>
                                <strong>{pathOrDash(['pharmacyInsuranceGroupId'], insurance)}</strong>
                            </Col>
                            <Col md={3}>
                                <strong>{pathOrDash(['pharmacyInsurancePersonCode'], insurance)}</strong>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="block-section">
                        <Row>
                            <Col md={3}>High Deductible Plan</Col>
                            <Col md={3}>Coverage Effective Date</Col>
                            <Col md={3}>Coverage End Date</Col>
                            <Col md={3}>Medical Group ID</Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <strong>{pathOrDash(['isIntegratedHighDeductable'], insurance)}</strong>
                            </Col>
                            <Col md={3}>
                                <strong>
                                    {insurance?.coverageEffectiveDate
                                        ? dayjs(insurance.coverageEffectiveDate).utc().format('YYYY/MM/DD')
                                        : '--'}
                                </strong>
                            </Col>
                            <Col md={3}>
                                <strong>
                                    {insurance?.coverageEndDate
                                        ? dayjs(insurance.coverageEndDate).utc().format('YYYY/MM/DD')
                                        : '--'}
                                </strong>
                            </Col>
                            <Col md={3}>
                                <strong>{pathOrDash(['groupNumber'], insurance)}</strong>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="block-section">
                        <Row>
                            <Col md={3}>Deductible Amount</Col>
                            <Col md={3}>Deductible Amount Paid To Date</Col>
                            <Col md={3}>Out Of Pocket Maximum</Col>
                            <Col md={3}>Out Of Pocket Maximum Paid To Date</Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <strong>
                                    {insurance?.deductableAmount
                                        ? `$${valueIntlFormat.format(insurance.deductableAmount)}`
                                        : '--'}
                                </strong>
                            </Col>
                            <Col md={3}>
                                <strong>
                                    {insurance?.deductableAmountPaidToDate
                                        ? `$${valueIntlFormat.format(insurance.deductableAmountPaidToDate)}`
                                        : '--'}
                                </strong>
                            </Col>
                            <Col md={3}>
                                <strong>
                                    {insurance.pharmacyPlanOopMax
                                        ? `$${valueIntlFormat.format(insurance.pharmacyPlanOopMax)}`
                                        : '--'}
                                </strong>
                            </Col>
                            <Col md={3}>
                                <strong>
                                    {insurance.pharmacyPlanOopMaxPaidToDate
                                        ? `$${valueIntlFormat.format(insurance.pharmacyPlanOopMaxPaidToDate)}`
                                        : '--'}
                                </strong>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="block-section">
                        <Row>
                            <Col md={3}>Coverage Carve-Out</Col>
                            <Col md={3}>Help Desk Phone</Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <strong>{pathOrDash(['isPharmacyBenefitsCarveOutFromMedical'], insurance)}</strong>
                            </Col>
                            <Col md={3}>
                                <strong>{pathOrDash(['helpDeskPhone'], insurance)}</strong>
                            </Col>
                        </Row>
                    </Col>
                </Col>
                {isDisabled ? (
                    <>
                        <span className="disabled-insurance">
                            Disable On:{' '}
                            {isExpired(dayjs(insurance?.coverageEndDate).utc().format('YYYY-MM-DD 23:59:59'))
                                ? dayjs(insurance.coverageEndDate).utc().format('YYYY-MM-DD H:mm:ss')
                                : localize(insurance.disabledOn, 'YYYY-MM-DD H:mm:ss')}
                        </span>
                        <span className="disabled-insurance">
                            {insurance?.disabledByUser ? (
                                <>
                                    {' '}
                                    Disabled By: {insurance?.disabledByUser?.firstName}&nbsp;
                                    {insurance?.disabledByUser?.lastName}
                                </>
                            ) : (
                                'Coverage end date reached'
                            )}
                        </span>
                    </>
                ) : null}{' '}
            </div>
        </div>
    );
}

PatientInsuranceProfile.propTypes = propTypes;
PatientInsuranceProfile.defaultProps = defaultProps;

export default PatientInsuranceProfile;
