import PropTypes from 'prop-types';
import React from 'react';

import SelectField from 'Lib/form/SelectField';
import { medicationAssociationPropType } from '../types';

const EhrEncounterPrescriberContent = ({ medicationAssociationsList }) => {
    return (
        <div>
            <SelectField
                fieldName="associatedPrescriber"
                label="Provider"
                required
                options={medicationAssociationsList.filter((med) => med.rx.physician.npi.length)}
                getOptionLabel={(med) => {
                    const { firstName, lastName, npi } = med.rx.physician;
                    return `${firstName}, ${lastName}, ${npi}, ${med.medication.medicationName}`;
                }}
            />
        </div>
    );
};

EhrEncounterPrescriberContent.propTypes = {
    medicationAssociationsList: PropTypes.arrayOf(medicationAssociationPropType).isRequired,
};

export default EhrEncounterPrescriberContent;
