import PESService from 'App/services/PESService';
import { useQuery } from 'react-query';
import { errorHandler } from 'App/utils';

function useFetchWorkQueueSavedFilters() {
    return useQuery(['workQueue', 'savedFilters'], () => PESService.getFilters(), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}
export default useFetchWorkQueueSavedFilters;
