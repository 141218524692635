import { useMutation, useQueryClient } from 'react-query';
import { updateReferralActivityMedication } from '@/app/services/RMSService';
import errorHandler from '@/app/utils/errorHandler';

function useUpdateReferralActivityMedicationMutation() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ patientId, referralId, activityId, medicationId, data }) =>
            updateReferralActivityMedication(patientId, referralId, activityId, medicationId, data),
        {
            onSuccess: async (referralActivityMedication) => {
                await queryClient.setQueryData(
                    [
                        'patient',
                        referralActivityMedication.patientId,
                        'referral',
                        referralActivityMedication.referralId,
                        'activity',
                        referralActivityMedication.activityId,
                        'medication',
                        referralActivityMedication.id,
                    ],
                    referralActivityMedication
                );
            },
            onError: errorHandler,
        }
    );
}

export default useUpdateReferralActivityMedicationMutation;
