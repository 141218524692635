import { CheckboxField } from 'Lib/form/CheckboxField';
import { CheckboxMultiField } from 'Lib/form/CheckboxMultiField';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

const activityTypeOptions = [
    {
        id: 'PCC',
        option: 'PCC',
    },
    {
        id: 'CLI',
        option: 'Clinical',
    },
    {
        id: 'REF',
        option: 'Referral',
    },
];

const customOptions = [
    { id: 'onlyMine', option: 'Updated By Me' },
    { id: 'assignedToMe', option: 'Assigned To Me' },
    {
        id: 'highRisk',
        option: 'High-Risk Patient',
    },
];

const ActivityFilter = () => {
    const {
        values: {
            filterParams: { filters },
        },
        setFieldValue,
    } = useFormikContext();

    useEffect(() => {
        setFieldValue('filterParams.allTypes', filters?.length === activityTypeOptions.length);
    }, [filters, setFieldValue]);

    return (
        <div className="filter form-group">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="control-label filter-header">Activity Filter</label>
            <CheckboxMultiField fieldName="filterParams.filters" options={activityTypeOptions} />
            {customOptions.map((opt) => (
                <CheckboxField key={opt.id} fieldName={`filterParams.${opt.id}`} label={opt.option} />
            ))}
        </div>
    );
};

export default ActivityFilter;
