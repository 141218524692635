/**
 * This service handles requests for 'patient_other_condition'
 */
/** @ngInject */
function PatientOtherConditionService($http, _, urlBuilderService) {
    const promises = {};
    return {
        del,
        getMany,
        patch,
        post,
    };

    function del(patientId, secondaryConditionId) {
        const url = urlBuilderService.build('delete', {
            patient: patientId,
            otherCondition: secondaryConditionId,
        });

        return $http.delete(url).then((res) => {
            return res.data;
        });
    }

    function getMany(patientId) {
        if (promises.getAdditionalDiagnoses) {
            return promises.getAdditionalDiagnoses;
        }

        const url = urlBuilderService.build('secondaryDiagnosis', {
            patient: patientId,
            otherCondition: '',
        });

        promises.getAdditionalDiagnoses = $http
            .get(url)
            .then((res) => {
                return res.data;
            })
            .finally(() => {
                promises.getAdditionalDiagnoses = undefined;
            });
        return promises.getAdditionalDiagnoses;
    }

    function patch(patientId, secondaryConditionId, body) {
        const url = urlBuilderService.build('patch', {
            patient: patientId,
            otherCondition: secondaryConditionId,
        });

        return $http.patch(url, body).then((res) => {
            return res.data;
        });
    }

    function post(patientId, body) {
        const url = urlBuilderService.build('post', {
            patient: patientId,
            otherCondition: '',
        });

        return $http.post(url, body).then((res) => {
            return res.data;
        });
    }
}

export default PatientOtherConditionService;
