import PropTypes from 'prop-types';
import compose from 'ramda/src/compose';
import path from 'ramda/src/path';
import React from 'react';

const options = ['Open', 'Closed'];

const propTypes = {
    /**
     * These are provided by React Table. As such, these definitions may not be exhaustive.
     */
    column: PropTypes.shape({
        filterValue: PropTypes.string,
        setFilter: PropTypes.func,
        Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element, PropTypes.node]),
    }).isRequired,
};
const defaultProps = {};

function SelectColumnOpenClosed(props) {
    const {
        column: { filterValue, setFilter, Header },
    } = props;
    return (
        <select
            value={filterValue || ''}
            onChange={compose(setFilter, path(['target', 'value']))}
            className="filter filter-select form-control "
            aria-label={`${Header} Filter`}
        >
            <option value="">All</option>
            {options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

SelectColumnOpenClosed.propTypes = propTypes;
SelectColumnOpenClosed.defaultProps = defaultProps;

export default SelectColumnOpenClosed;
