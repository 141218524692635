import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { FileField } from 'Lib/form/FileField';
import SubmitButton from 'Lib/form/SubmitButton';
import { TextField } from 'Lib/form/TextField';
import { queryClient } from 'Lib/queryClient';

import { uploadPatientActivityFiles } from 'App/components/PatientProfile/PatientProfile.utils.js';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import useFetchPatientActivityFiles from 'App/hooks/useFetchPatientActivityFiles';

import summaryNotePropType from './types/summaryNotePropType';

const AttachmentEditModal = ({ onClose, note, existingAttachment }) => {
    const { patientId } = note;
    const { data: attachments } = useFetchPatientActivityFiles(patientId);

    return (
        <Modal show onHide={onClose}>
            <Modal.Header>
                <Modal.Title>{existingAttachment ? 'Attachment Update' : 'Attachment Upload'}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    ...existingAttachment,
                    activity: note,
                }}
                onSubmit={async (values) => {
                    await uploadPatientActivityFiles(values, patientId);
                    queryClient.invalidateQueries(['patientActivity', patientId]);
                    onClose();
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <TextField fieldName="description" label="Description" />
                            {!existingAttachment && (
                                <>
                                    <FileField fieldName="file" label="File" required />
                                    <div>Maximum size: {attachments?.uploadLimit}</div>
                                </>
                            )}

                            <div>
                                {note.attachments?.map((attachment) => (
                                    <div key={attachment.id}>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`/stm/download_note_file.php?file_id=${attachment.id}`}
                                        >
                                            {attachment.origFileName}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <FormButtonGroup>
                                <Button onClick={onClose}>Close</Button>
                                <SubmitButton>Upload</SubmitButton>
                            </FormButtonGroup>
                        </Modal.Footer>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

AttachmentEditModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    note: summaryNotePropType.isRequired,
    existingAttachment: PropTypes.shape({
        description: PropTypes.string,
    }),
};
AttachmentEditModal.defaultProps = {
    existingAttachment: null,
};

export default AttachmentEditModal;
