import { Col, Row } from 'react-bootstrap';
import { errorHandler } from 'App/utils';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Button from 'react-bootstrap/lib/Button';
import Conditions from './Conditions';
import MedicationSelect from '../MedicationSelect/MedicationSelect';
import React from 'react';
import styled from '@emotion/styled';
import FindPatientTable from 'App/components/FindPatient/FindPatientTable';
import CategorySelect from 'App/components/CategorySelect';
import GenderSelect from 'App/components/FindPatient/GenderSelect';
import BranchSelect from 'App/components/FindPatient/BranchSelect';
import dayjs from 'dayjs';
import delay from 'App/utils/delay';
import { FaInfoCircle } from 'react-icons/fa';
import { TextField } from 'Lib/form/TextField';
import { CheckboxField } from 'Lib/form/CheckboxField';
import UpdateSearchParams from 'App/components/FindPatient/UpdateSearchParams';
import PESService from '../../services/PESService';
import { DatePickerField } from 'Lib/form/DatePickerField';
import EhrTypeSelect from 'App/components/FindPatient/EhrTypeSelect';
import * as Yup from 'yup';

const propTypes = {
    paramFirstName: PropTypes.string,
    paramLastName: PropTypes.string,
};

const defaultProps = {
    paramFirstName: '',
    paramLastName: '',
};
const PaddedRow = styled(Row)`
    padding: 0.5% 0;
`;

const initialValues = {
    searchParams: {
        firstName: '',
        lastName: '',
        birthDate: null,
        gender: null,
        city: '',
        state: '',
        ssnLastFour: '',
        email: '',
        zip: '',
        externalId: '',
        otherId: '',
        ehrId: '',
        ehrPrimaryIdType: null,
        internalId: '',
        category: null,
        condition: null,
        medication: null,
        branch: null,
        showDisabled: false,
        sortKey: 'lastName',
        sortOrder: 'asc',
        count: 10,
        page: 0,
    },
    isSortOrPageChanged: false,
    lastSubmitSearchParam: {}, //store last submit param to use in case of pagination or sorting
    data: {
        results: [],
        total: 0,
    },
};

const schema = Yup.object().shape({
    searchParams: Yup.object().shape(
        {
            ehrId: Yup.string().when('ehrPrimaryIdType', {
                is: R.identity,
                then: Yup.string().required('EHR ID is required when EHR Type is selected.'),
                otherwise: Yup.string(),
            }),
            ehrPrimaryIdType: Yup.object()
                .nullable()
                .when('ehrId', {
                    is: R.identity,
                    then: Yup.object().required('EHR Type is required when EHR ID is populated.'),
                    otherwise: Yup.object().nullable(),
                }),
        },
        ['ehrPrimaryIdType', 'ehrId']
    ),
});

const FindPatientForm = (props) => {
    const { paramFirstName, paramLastName } = props; // This is the name entered by the user in the search header

    return (
        <div data-testid="findPatientForm">
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting, setFieldValue }) => {
                    let params = { ...values.lastSubmitSearchParam };
                    if (!values.isSortOrPageChanged) {
                        params = R.compose(
                            R.over(R.lensProp('ehrPrimaryIdType'), R.propOr(null, 'keyValue')),
                            R.over(R.lensProp('branch'), R.propOr(null, 'id')),
                            R.over(R.lensProp('gender'), R.propOr(null, 'id')),
                            R.over(R.lensProp('category'), R.propOr(null, 'id')),
                            R.over(R.lensProp('condition'), R.propOr(null, 'id')),
                            R.over(R.lensProp('medication'), R.propOr(null, 'id')),
                            R.over(R.lensProp('page'), () => 0),
                            R.over(
                                R.lensProp('birthDate'),
                                R.ifElse(R.isNil, R.identity, (date) => dayjs(date).format('YYYY-MM-DD'))
                            )
                        )(values.searchParams);
                        params.additionalDisplayFields = ['branch'];
                        setFieldValue('lastSubmitSearchParam', params);
                        setFieldValue('searchParams.page', 0);
                        setFieldValue('lastSubmitSearchParam.page', 0);
                    }
                    setFieldValue('isSortOrPageChanged', false);
                    Promise.all([
                        PESService.findPatients(params),
                        /**
                         * Build in a small amount of delay so if the response is really fast, we don't get a flicker
                         * from the load spinner being added and removed really fast.
                         */
                        delay(300),
                    ])
                        .then(([results]) => {
                            setFieldValue('data', results);
                        })
                        .catch(errorHandler)
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, setFieldValue, submitForm, handleSubmit, values, resetForm, submitCount }) => {
                    const handleSortChange = (sort) => {
                        setFieldValue('searchParams.sortKey', sort.sortKey);
                        setFieldValue('searchParams.sortOrder', sort.sortOrder);
                        setFieldValue('lastSubmitSearchParam.sortKey', sort.sortKey);
                        setFieldValue('lastSubmitSearchParam.sortOrder', sort.sortOrder);
                        setFieldValue('isSortOrPageChanged', true);

                        submitForm().then().catch(errorHandler);
                    };

                    const handlePageChange = (page) => {
                        setFieldValue('searchParams.count', page.pageSize);
                        setFieldValue('searchParams.page', page.pageIndex - 1);
                        setFieldValue('lastSubmitSearchParam.count', page.pageSize);
                        setFieldValue('lastSubmitSearchParam.page', page.pageIndex - 1);
                        setFieldValue('isSortOrPageChanged', true);

                        submitForm().then().catch(errorHandler);
                    };

                    return (
                        <>
                            <UpdateSearchParams paramFirstName={paramFirstName} paramLastName={paramLastName} />
                            <form onSubmit={handleSubmit} className="find-patient-form">
                                {/*Name, DoB, Gender*/}
                                <PaddedRow>
                                    <Col md={3}>
                                        <TextField
                                            fieldName="searchParams.lastName"
                                            label="Last Name"
                                            placeholder="Last Name"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <TextField
                                            fieldName="searchParams.firstName"
                                            label="First Name"
                                            placeholder="First Name"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <DatePickerField
                                            fieldName="searchParams.birthDate"
                                            label="Birth Date"
                                            placeholder="YYYY/MM/DD"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <GenderSelect />
                                    </Col>
                                </PaddedRow>

                                {/*City, State, SSN, Email*/}
                                <PaddedRow>
                                    <Col md={3}>
                                        <TextField fieldName="searchParams.city" label="City" placeholder="City" />
                                    </Col>
                                    <Col md={3}>
                                        <TextField fieldName="searchParams.state" label="State" placeholder="State" />
                                    </Col>
                                    <Col md={3}>
                                        <TextField
                                            fieldName="searchParams.ssnLastFour"
                                            label="SSN Last Four"
                                            placeholder="SSN Last Four"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <TextField fieldName="searchParams.email" label="Email" placeholder="Email" />
                                    </Col>
                                </PaddedRow>

                                {/*Zip, Other ID, Internal ID*/}
                                <PaddedRow>
                                    <Col md={3}>
                                        <TextField fieldName="searchParams.zip" label="Zip" placeholder="Zip" />
                                    </Col>
                                    <Col md={3} mdOffset={3}>
                                        <TextField
                                            fieldName="searchParams.otherId"
                                            label="Other ID"
                                            placeholder="Other ID"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <TextField
                                            fieldName="searchParams.internalId"
                                            label="Internal ID"
                                            placeholder="Internal ID"
                                        />
                                    </Col>
                                </PaddedRow>

                                {/*Branches and TC*/}
                                <PaddedRow>
                                    <Col md={6}>
                                        <BranchSelect
                                            onChange={(option) => {
                                                setFieldValue('searchParams.branch', option);
                                            }}
                                            value={values.searchParams.branch}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <CategorySelect
                                            fieldName="searchParams.category"
                                            onChange={(option) => {
                                                setFieldValue('searchParams.medication', null);
                                                setFieldValue('searchParams.condition', null);
                                                setFieldValue('searchParams.category', option);
                                            }}
                                        />

                                        {values.searchParams.category ? (
                                            <Conditions
                                                therapyId={values.searchParams.category.id}
                                                onChange={(condition) => {
                                                    setFieldValue('searchParams.condition', condition);
                                                    setFieldValue('searchParams.medication', null);
                                                }}
                                                value={values.searchParams.condition}
                                            />
                                        ) : null}

                                        {values.searchParams.category ? (
                                            <MedicationSelect
                                                fieldName="searchParams.medication"
                                                therapyId={values.searchParams.category.id}
                                                onChange={(medication) => {
                                                    setFieldValue('searchParams.medication', medication);
                                                }}
                                            />
                                        ) : null}
                                    </Col>
                                </PaddedRow>

                                {/*Dispening ID, EHR ID, EHR type*/}
                                <PaddedRow>
                                    <Col md={3}>
                                        <TextField
                                            fieldName="searchParams.externalId"
                                            label="Dispensing ID"
                                            placeholder="Dispensing ID"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <TextField fieldName="searchParams.ehrId" label="EHR ID" placeholder="EHR ID" />
                                    </Col>
                                    <Col md={3}>
                                        <EhrTypeSelect
                                            onChange={(option) => {
                                                setFieldValue('searchParams.ehrPrimaryIdType', option);
                                            }}
                                        />
                                    </Col>
                                </PaddedRow>

                                {/*Show Disabled / Buttons*/}
                                <PaddedRow>
                                    <Col md={6}>
                                        <CheckboxField
                                            fieldName="searchParams.showDisabled"
                                            label="Show Disabled Records"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-button-group">
                                            <Button type="button" disabled={isSubmitting} onClick={() => resetForm()}>
                                                Reset
                                            </Button>
                                            <Button type="submit" bsStyle="primary" disabled={isSubmitting}>
                                                Search
                                            </Button>
                                        </div>
                                    </Col>
                                </PaddedRow>

                                {/* Results Table */}
                                <PaddedRow>
                                    <Col md={12}>
                                        <h3 className="section-title">Search Results</h3>
                                    </Col>
                                    {submitCount < 1 && (
                                        <Col md={12}>
                                            <FaInfoCircle style={{ marginRight: '.5rem' }} />
                                            <span>
                                                Search results based on the search criteria above will display here.
                                            </span>
                                        </Col>
                                    )}
                                </PaddedRow>
                            </form>

                            {submitCount > 0 ? (
                                <FindPatientTable
                                    data={values.data.results}
                                    totalRows={values.data.total}
                                    onPageChange={handlePageChange}
                                    onSortChange={handleSortChange}
                                    currentPage={values.searchParams.page + 1}
                                />
                            ) : null}
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};

FindPatientForm.propTypes = propTypes;
FindPatientForm.defaultProps = defaultProps;

export default FindPatientForm;
