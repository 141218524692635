/** @ngInject */
function BenefitsInvestigationAddInsurancePlanModalController(toastr, rmsService) {
    const ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.submit = submit;

    function $onInit() {
        ctrl.insurance = {};
        ctrl.communication = {};
        ctrl.ngModelOptions = {
            updateOn: 'default blur',
            debounce: {
                default: 1000,
                blur: 0,
            },
        };
    }

    function submit() {
        ctrl.saving = true;
        return rmsService
            .createInsurancePlan(
                ctrl.resolve.patientId,
                ctrl.resolve.referralId,
                ctrl.resolve.activityId,
                ctrl.insurance
            )
            .then((res) => {
                toastr.success('Insurance plan successfully added');
                ctrl.close({ $value: res.data });
            })
            .catch((res) => {
                if (res.status === 404 || res.status === 403) {
                    toastr.error(
                        'The activity was not found or you do not have permission to access it. ' +
                            'Please contact your STM administrator.',
                        'Insurance plan unsuccessfully added',
                        {
                            closeButton: true,
                            closeHtml: '<button><i class="fa fa-fw fa-times"></i></button>',
                            tapToDismiss: false,
                            timeOut: 25000,
                        }
                    );
                } else {
                    toastr.error('Failed to create insurance plan');
                }
                ctrl.dismiss({ $value: ctrl.resolve });
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }
}

export default BenefitsInvestigationAddInsurancePlanModalController;
