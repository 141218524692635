/**
 * Controller for modal component to view rx notes.
 * @param medicationsService
 */
/** @ngInject */
function MedicationNotesController(_, medicationsService) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.cancelForm = cancelForm;

    ctrl.notes = null;

    /**
     * Sets values in resolve to properties
     * @param changes
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.patientId) {
                ctrl.patientId = ctrl.resolve.patientId;
            }
            if (ctrl.resolve.medicationId) {
                ctrl.medicationId = ctrl.resolve.medicationId;
            }
            if (ctrl.resolve.statusId) {
                ctrl.statusId = ctrl.resolve.statusId;
            }

            if (ctrl.patientId && ctrl.medicationId && ctrl.statusId) {
                return medicationsService.getNotes(ctrl.patientId, ctrl.medicationId, ctrl.statusId).then((res) => {
                    const notes = _.values(res);
                    if (notes.length > 0) {
                        ctrl.notes = notes;
                    } else {
                        ctrl.notes = false;
                    }
                });
            }
        }
    }

    function cancelForm() {
        ctrl.dismiss({ $value: 'cancel' });
    }
}

export default MedicationNotesController;
