import React from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import PropTypes from 'prop-types';

const propTypes = {
    icd10: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        code: PropTypes.string,
        name: PropTypes.string,
        shortDescription: PropTypes.string,
    })),
    icd10Merged: PropTypes.string,
};

const defaultProps = {
    icd10: [],
    icd10Merged: ''
}

function ICD10Popover({icd10, icd10Merged}) {
    const ICD10Icon = (
        <Popover id="icd10Popover" className="increase-popover-width">
            <div>
                {icd10.length ? icd10.map((item) => {
                  return(<div key={item.id}> {`${item.code} ${item.name || item.shortDescription} `}</div>)
                }
                )
                :''}      
            </div> 
        </Popover>
    )
      
    return (
        <OverlayTrigger trigger={["hover", "focus"]} rootClose placement="bottom" overlay={ICD10Icon}>
            <strong>{`${icd10Merged.substring(0,40)} (...)`}</strong>
        </OverlayTrigger>
    );
}

ICD10Popover.propTypes = propTypes;
ICD10Popover.defaultProps = defaultProps;

export default ICD10Popover;