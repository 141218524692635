import styled from '@emotion/styled';

const SectionTitleStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 40px;
    padding-bottom: 20px;
`;

export default SectionTitleStyle;
