import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import useFetchActivityCount from './useFetchActivityCount';
import useFetchWorkQueueSavedFilters from './useFetchWorkQueueSavedFilters';
import useFetchWorkQueueTableHeaders from './useFetchWorkQueueTableHeaders';
import useFetchWorkQueueResult from './useFetchWorkQueueResult';
import { processWorkQueueResult } from '../workQueue.utils';
import SessionStorageService from 'App/services/SessionStorageService';
import * as R from 'ramda';
import dayjs from 'Lib/dayjs';

/**
 * The dates used in the filter expect the YYYY-MM-DD format. And when the search is performed on the back-end, it will
 * concatenate that value with time values of "00:00:00" nad "23:59:59", respectively.
 * @param date
 * @returns {*}
 */
function formatDate(date) {
    if (date) {
        return dayjs(date).format('YYYY-MM-DD');
    }
    return date;
}

function useWorkQueue() {
    const { data: activityCount, isLoading: isActivityCountLoading } = useFetchActivityCount();
    const { data: workQueueTableHeaders, isLoading: isTableHeadersLoading } = useFetchWorkQueueTableHeaders();
    const { data: savedFilter, isLoading: isSavedFiltersLoading } = useFetchWorkQueueSavedFilters();
    const {
        values: { filterParams },
        setFieldValue,
        setSubmitting,
    } = useFormikContext();

    //prettier-ignore
    const {isLoading, data: workQueueResult} = useFetchWorkQueueResult(R.compose(
        R.over(R.lensProp('dateStart'), formatDate),
        R.over(R.lensProp('dateEnd'), formatDate)
    )(filterParams));

    useEffect(() => {
        if (!isActivityCountLoading) {
            setFieldValue('result.activityCountResult', activityCount);
        }
    }, [activityCount, isActivityCountLoading, setFieldValue]);

    useEffect(() => setSubmitting(isLoading), [isLoading, setSubmitting]);

    useEffect(() => {
        if (!isTableHeadersLoading) {
            setFieldValue('result.workQueueTableHeaders', workQueueTableHeaders);
        }
    }, [workQueueTableHeaders, isTableHeadersLoading, setFieldValue]);

    useEffect(() => {
        if (!isSavedFiltersLoading && savedFilter) {
            setFieldValue('result.savedFilterResult', Object.values(savedFilter));
        }
    }, [savedFilter, isSavedFiltersLoading, setFieldValue]);

    useEffect(() => {
        if (workQueueResult) {
            setFieldValue('result.workQueueActivityCount', workQueueResult.count);
            setFieldValue('result.workQueueResult', processWorkQueueResult(workQueueResult.data));
            SessionStorageService.setOnUser(`workQueue_filter`, filterParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workQueueResult]);
}

export default useWorkQueue;
