import React, { useState } from 'react';
import CategorySelect from 'App/components/CategorySelect';
import { TextField } from 'Lib/form/TextField';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import ProtocolService from 'App/services/ProtocolService';
import * as R from 'ramda';
import { useFormikContext } from 'formik';
import LaddaButton from 'App/components/LaddaButton';

const propTypes = {};

const defaultProps = {};

const nameIncludes = R.curry((term, datum) => {
    const name = R.propOr('', 'name', datum);
    const lowerTerm = term.toLowerCase();
    return name.toLowerCase().includes(lowerTerm);
});

const isOfficial = R.propSatisfies(R.isNil, 'companyId');

function SearchForm() {
    const { values, setFieldValue } = useFormikContext();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div>
            <CategorySelect
                onChange={(option) => {
                    setFieldValue('category', option);
                }}
            />
            <TextField fieldName="name" placeholder="Protocol Name" label="Protocol Name" />
            <FormButtonGroup>
                <LaddaButton
                    disabled={!values.category}
                    isLoading={isLoading}
                    type="button"
                    onClick={() => {
                        setIsLoading(R.T);
                        return ProtocolService.getProtocols(values.category.id)
                            .then(
                                R.compose(
                                    R.indexBy(R.prop('therapyPolicyId')),
                                    R.map(
                                        R.compose(
                                            R.assoc('category', { name: values.category.name }),
                                            R.assoc('enable', false),
                                            R.chain(R.assoc('therapyPolicyId'), R.prop('id')),
                                            R.chain(R.assoc('protocol'), R.pick(['name']))
                                        )
                                    ),
                                    R.filter(R.allPass([nameIncludes(values.name), isOfficial])),
                                    R.values
                                )
                            )
                            .then((protocols) => setFieldValue('protocols', protocols))
                            .finally(() => setIsLoading(R.F));
                    }}
                >
                    Search
                </LaddaButton>
            </FormButtonGroup>
        </div>
    );
}

SearchForm.propTypes = propTypes;
SearchForm.defaultProps = defaultProps;

export default SearchForm;
