import { useEffect } from 'react';
import useFetchPatient from 'App/hooks/useFetchPatient';
import useProfileDataState from './useProfileDataState';

/**
 * Custom hook which make the http call to fetch patients details required to render patient detail page
 * @param {string} patientId
 * @returns {Object}
 */
function useGetPatientData(patientId) {
    const {
        setData: setPatientData,
        isLoading: isPatientDataLoading,
        triggerRefetch: triggerPatientDataRefetch,
        refetch,
        setIsLoading,
    } = useProfileDataState();

    const patient = useFetchPatient(patientId, { staleTime: 600000 }); // 10 minutes

    useEffect(() => {
        if (patient.data) {
            setPatientData(patient.data);
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient.data, refetch]);

    return {
        ...patient,
        patientData: patient.data,
        isPatientDataLoading,
        triggerPatientDataRefetch,
    };
}

export default useGetPatientData;
