/** @ngInject */
function PatientRun($rootScope, $transitions) {
    $transitions.onStart({ to: 'app.patient.**' }, () => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        $rootScope.preloaderMessage = 'Loading patient information...';
    });
}

export default PatientRun;
