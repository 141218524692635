class SynchronousQueue {
    constructor() {
        this.queue = [];
        this.inFlight = false;
    }

    enqueue(promise) {
        return new Promise((resolve, reject) => {
            this.queue.push({ promise, resolve, reject });
            this.dequeue();
        });
    }

    dequeue() {
        if (this.inFlight) {
            return;
        }

        const next = this.queue.shift();
        if (!next) {
            return;
        }

        try {
            this.inFlight = true;
            next.promise()
                .then((result) => {
                    next.resolve(result);
                })
                .catch((err) => {
                    next.reject(err);
                })
                .finally(() => {
                    this.inFlight = false;
                    this.dequeue();
                });
        } catch (err) {
            this.inFlight = false;
            next.reject(err);
            this.dequeue();
        }
    }
}

export default SynchronousQueue;
