import * as R from 'ramda';
import useFetchDevFeatureFlagQuery from 'App/hooks/useFetchDevFeatureFlagQuery';

const propTypes = {};

const defaultProps = {};

function useFeatureFlag(flag) {
    const { data: flags } = useFetchDevFeatureFlagQuery();

    return R.compose(Boolean, R.propOr(false, flag))(flags);
}

useFeatureFlag.propTypes = propTypes;
useFeatureFlag.defaultProps = defaultProps;

export default useFeatureFlag;
