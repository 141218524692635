/** @ngInject */
function EngageOptInModalController(CSRF_ID_NAME, CSRF_ID_VALUE) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.onChangeMode = onChangeMode;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    function $onChanges(changes) {
        
        if (changes.resolve && ctrl.resolve) {
            ctrl.mode = ctrl.resolve.modalData.mode || 'productSelect' ;
            ctrl.patientEducationSubscription = angular.copy(ctrl.resolve.modalData.patientEducationSubscription);
            ctrl.patientMessagingSubscription = angular.copy(ctrl.resolve.modalData.patientMessagingSubscription);
            ctrl.patient = angular.copy(ctrl.resolve.modalData.patient);
            ctrl.user = angular.copy(ctrl.resolve.modalData.user);
        }
    }

    function $onInit() {
        ctrl.learnMoreUrlPm = 'https://knowledge.therigy.com/course/view.php?id=33';
        ctrl.learnMoreUrlPec = 'https://www.therigy.com/therigystm#section3';
    }

    function onChangeMode(mode) {
        ctrl.mode = mode;
    }
}

export default EngageOptInModalController;
