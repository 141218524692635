import convertKeysToSnakeCase from 'App/utils/keyConversion/convertKeysToSnakeCase';
import dayjs from 'dayjs';
import { BOOLEAN_TYPE, STATUS_TYPE } from './PriorAuthorization/constant';

export const convertPAtoView = (activityDetails) => {
    return {
        ...activityDetails,
        details: {
            ...activityDetails?.details,
            effectiveStartDate:
                activityDetails?.details?.effectiveStartDate &&
                dayjs(activityDetails?.details?.effectiveStartDate).toDate(),
            effectiveEndDate:
                activityDetails?.details?.effectiveEndDate &&
                dayjs(activityDetails?.details?.effectiveEndDate).toDate(),
            reVerificationDueDate:
                activityDetails?.details?.reVerificationDueDate &&
                dayjs(activityDetails?.details?.reVerificationDueDate).toDate(),
            methodApprovalReceived: activityDetails?.details?.methodApprovalReceived && {
                id: activityDetails?.details?.methodApprovalReceived,
                name: activityDetails?.details?.methodApprovalReceived,
            },
            benefitRequiresReVerification: activityDetails?.details?.benefitRequiresReVerification && {
                id: activityDetails?.details?.benefitRequiresReVerification,
                name: activityDetails?.details?.benefitRequiresReVerification,
            },
            denialReason: activityDetails?.details?.denialReason && {
                id: activityDetails?.details?.denialReason,
                name: activityDetails?.details?.denialReason,
            },
            isPrescriberSubmittingAppeal: activityDetails?.details?.isPrescriberSubmittingAppeal && {
                id: activityDetails?.details?.isPrescriberSubmittingAppeal,
                name: activityDetails?.details?.isPrescriberSubmittingAppeal,
            },
            isReAuthorizationRequired: activityDetails?.details?.isReAuthorizationRequired === BOOLEAN_TYPE.yes,
        },
        medications: activityDetails?.medications?.map((med) => ({
            ...med,
            data: {
                ...med.data,
                authorizedUom: med?.data?.authorizedUom && {
                    id: med?.data?.authorizedUom,
                    name: med?.data?.authorizedUom,
                },
                ndcSpecificApproval: med?.data?.ndcSpecificApproval && {
                    id: med?.data?.ndcSpecificApproval,
                    name: med?.data?.ndcSpecificApproval === '1' ? 'Yes' : 'No',
                },
            },
        })),
        status: activityDetails?.status && {
            id: activityDetails?.status,
            name: activityDetails?.status,
        },
    };
};

export const convertPAtoSave = (formValues) => {
    const { completedOn, dueOn, type, status, statusReason, statusDate, details, medications } = formValues || {};
    const modifiedDetails = {
        ...details,
        methodApprovalReceived: details?.methodApprovalReceived?.id,
        benefitRequiresReVerification: details?.benefitRequiresReVerification?.id,
        denialReason: details?.denialReason?.id,
        isPrescriberSubmittingAppeal: details?.isPrescriberSubmittingAppeal?.id,
        isReAuthorizationRequired: details?.isReAuthorizationRequired ? BOOLEAN_TYPE.yes : BOOLEAN_TYPE.no,
        deniedOn: status?.id === STATUS_TYPE.denied ? new Date() : null,
    };
    const activityPayload = {
        completed_on: completedOn,
        due_on: dueOn,
        type,
        status: status?.id,
        status_reason: statusReason,
        status_date: statusDate,
        details: convertKeysToSnakeCase(modifiedDetails),
    };

    const medicationPayload = medications?.map((med) => {
        const data = {
            ...med.data,
            authorizedUom: med?.data?.authorizedUom?.id,
            ndcSpecificApproval: med?.data?.ndcSpecificApproval?.id,
        };
        return {
            id: med.id,
            medication_id: med.medicationId,
            data: convertKeysToSnakeCase(data),
        };
    });

    const notesPayload = {
        note: null,
        status: status?.id,
    };

    const appealActivityPayload =
        modifiedDetails.isPrescriberSubmittingAppeal === BOOLEAN_TYPE.yes
            ? {
                  type: '3',
                  details: {
                      pa_activity_id: formValues.id,
                  },
                  medication: medications.map((med) => {
                      return {
                          medication_id: med.medicationId,
                      };
                  }),
              }
            : null;

    return { medicationPayload, activityPayload, notesPayload, appealActivityPayload };
};
