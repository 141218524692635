import config from 'App/components/config/config';
import { getAuthorizationHeader, getSlsBasePath } from 'App/utils';
import createAxios from 'App/utils/createAxios';
import { setupCache } from 'axios-cache-adapter';

const requestCache = setupCache({
    maxAge: 15 * 60 * 1000,
});

const admin = createAxios({
    baseURL: `${getSlsBasePath(config, 'admin')}/admin/${config.slsVersion}`,
    adapter: requestCache.adapter,
});

const CODE_SETS = Object.freeze({
    ICD10: 'icd10',
    ICD11: 'icd11',
});

function getTherapies() {
    return admin.get(`/therapy`);
}

function addTherapy(name) {
    return admin.post(`/therapy`, { name });
}

function updateTherapy(therapyId, name) {
    return admin.patch(`/therapy/${therapyId}`, { name });
}

function getTherapy(therapyId) {
    return admin.get(`/therapy/${therapyId}`);
}

function getMedications(therapyId) {
    return admin.get(`/therapy/${therapyId}/medications`);
}

function addMedication(therapyId, name) {
    return admin.post(`/therapy/${therapyId}/medication`, { name });
}

function updateMedication(medicationId, name) {
    return admin.patch(`/medication/${medicationId}`, { name });
}

function disableMedication(medicationId) {
    return admin.delete(`/medication/${medicationId}`);
}

function addTherapyDiagnosis(therapyId, code, codeset = CODE_SETS.ICD10) {
    return admin.post(`/therapy/${therapyId}/diagnosis`, { code, codeset });
}

function deleteTherapyDiagnosis(therapyId, diagnosisId, codeset = CODE_SETS.ICD10) {
    return admin.delete(`/therapy/${therapyId}/diagnosis/${diagnosisId}`, {
        params: { codeset },
    });
}

function getTherapyDiagnosis(therapyId, codeset = CODE_SETS.ICD10) {
    return admin.get(`/therapy/${therapyId}/diagnosis`, { params: { codeset } });
}

/**
 * Creates a question group
 * @param {object} questionGroup - The new question group
 * @returns {PromiseLike<any> | Promise<any>} A question group if successful; throws otherwise.
 */
const createQuestionGroup = (questionGroup) => {
    return admin.post(`/questionGroup`, questionGroup);
};

/**
 * Deletes a question group
 * @param {string} uuid - The question group uuid.
 * @returns {PromiseLike<any> | Promise<any>} A question group if successful; throws otherwise.
 */
const deleteQuestionGroup = (uuid) => {
    return admin.delete(`/questionGroup/${uuid}`);
};

/**
 * Gets a question group
 * @param {string} uuid - The question group uuid.
 * @returns {PromiseLike<any> | Promise<any>}
 */
const getQuestionGroup = (uuid) => {
    return admin.get(`/questionGroup/${uuid}`);
};

/**
 * Gets all question groups for this company
 * @returns {PromiseLike<any> | Promise<any>} A map of question group objects keyed by uuid; throws otherwise.
 */
const getQuestionGroups = () => {
    return admin.get(`/questionGroup`);
};

/**
 * Updates a question group
 * @param {string} uuid - The question group uuid.
 * @param {object} questionGroup - The question group changes.
 * @returns {PromiseLike<any> | Promise<any>}
 */
const updateQuestionGroup = (uuid, questionGroup) => {
    return admin.put(`/questionGroup/${uuid}`, questionGroup, getAuthorizationHeader());
};

/**
 * Clones the assessment into the target
 * @param {string} assessmentId - The source assessment id.
 * @param {string} targetId - The target assessment id.
 * @returns {Promise<AxiosResponse<any>>}
 */
function cloneAssessment(assessmentId, targetId) {
    return admin.post(`/assessment/${assessmentId}/clone`, { targetId });
}

/**
 * Gets all therapies regardless of licensing.
 * @returns {Promise<AxiosResponse<any>>}
 */
function getAllTherapies() {
    return admin.get(`/therapy`);
}

/**
 * Gets all assessments in a given therapy refardless of licensing or ownership.
 * @param therapyId
 * @returns {Promise<AxiosResponse<any>>}
 */
function getAllTherapyAssessments(therapyId) {
    return admin.get(`/therapy/${therapyId}/assessments`);
}

/**
 * A config object used for publish a protocol
 * @typedef {Object} ProtocolPublishConfig
 * @property {string} protocolId - The protocol ID in which to publish.
 * @property {string|null} targetId - The optional target protocol ID for which to replace.
 * @property {boolean} enableForAll - Whether the protocol is to be disabled/enabled for all users.
 */

/**
 * Publish the protocols provided.
 * @param {ProtocolPublishConfig[]} protocolConfigs
 * @returns {Promise}
 */
function publishProtocols(protocolConfigs) {
    return admin.post(`/protocol/publish`, protocolConfigs);
}

export {
    CODE_SETS,
    addMedication,
    addTherapy,
    addTherapyDiagnosis,
    cloneAssessment,
    createQuestionGroup,
    deleteQuestionGroup,
    deleteTherapyDiagnosis,
    disableMedication,
    getAllTherapies,
    getAllTherapyAssessments,
    getMedications,
    getQuestionGroup,
    getQuestionGroups,
    getTherapies,
    getTherapy,
    getTherapyDiagnosis,
    publishProtocols,
    updateMedication,
    updateQuestionGroup,
    updateTherapy,
};
