import axios from 'axios';
import createAxios from 'App/utils/createAxios';
import config from '../components/config/config';
import { getSlsBasePath } from 'App/utils';

const { protocol, host, port } = window.location;
const therigyURL = `${protocol}//${host}${port ? `:${port}` : ''}`;
const phpRequest = createAxios({
    baseURL: `${therigyURL}/app.api.php/api`,
});

const slsHost = getSlsBasePath(config, 'pes');

const pesSlsBaseUrl = `${slsHost}/${config.slsVersion}`;

const slsPes = createAxios({
    baseURL: pesSlsBaseUrl,
});

/**
 * get therapy list with respect to medication
 * @returns therapy list
 */
function getTherapy() {
    return phpRequest.get('therapy').then((res) => {
        return res.results[0].availableTherapies;
    });
}

function getTherapyMedications(therapyId) {
    return phpRequest.get(`/therapy/${therapyId}/medications`);
}

function updatePatientTherapies(patientId, payload) {
    const body = new URLSearchParams();
    body.set('patientId', patientId);
    body.set('response', JSON.stringify({ current: payload }));
    return axios.post('/stm/patients.php', body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    });
}

function assignUnassignedMedication(patientId, therapyId, payload) {
    return slsPes.post(`/patient/${patientId}/therapy/${therapyId}`, payload);
}

export { assignUnassignedMedication, getTherapy, getTherapyMedications, updatePatientTherapies };
