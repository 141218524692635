import * as R from 'ramda';

function filterPrograms(data, filterValues) {
    const params = R.toPairs(filterValues);

    const propToLowerEquals = R.curry(([key, value], datum) => {
        return R.compose(R.includes(R.toLower(value)), R.toLower, R.propOr('', key))(datum);
    });

    const fns = R.map(propToLowerEquals, params);

    return R.filter(R.allPass(fns), data);
}

/** @ngInject */
function ProgramTableController(NgTableParams) {
    const ctrl = this;

    ctrl.headingText = '';
    ctrl.programs = [];
    ctrl.showRemove = false;
    ctrl.noRowsText = 'There are no records';
    ctrl.tableParams = null;
    ctrl.removeProgram = Function;
    ctrl.selectProgram = Function;
    ctrl.$onChanges = $onChanges;
    ctrl.shouldDisableAction = false;

    function $onChanges() {
        ctrl.tableParams = new NgTableParams(
            {
                count: 5,
                sorting: {
                    programName: 'asc',
                },
            },
            {
                filterOptions: { filterFn: filterPrograms },
                counts: [5, 10, 25],
                dataset: ctrl.programs,
                // Total hack since there didn't seem to be clear way to add display names for placeholders
                displayNames: {
                    programName: 'Program/Brand',
                    therapeuticAreas: 'Category',
                    drugs: 'Medications',
                    manufacture: 'Manufacturer',
                    assistanceType: 'Type',
                    eligibility: 'Eligibility',
                    foundationFundLevels: 'Current Fund Level',
                },
            }
        );
    }
}

export default ProgramTableController;
