import React from 'react';
import FindPatientForm from './FindPatientForm';
import Page from 'App/components/Page';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import { useLocation } from 'react-router-dom';

const FindPatient = () => {
    const { search } = useLocation();
    const firstName = new URLSearchParams(search).get('firstName');
    const lastName = new URLSearchParams(search).get('lastName');
    return (
        <Page title="Find Patient" permissionType={PERMISSION_TYPES.FIND_PATIENT} showPageTitle={false}>
            <div className="container page-wrapper">
                <FindPatientForm paramFirstName={firstName} paramLastName={lastName} />
            </div>
        </Page>
    );
};

export default FindPatient;
