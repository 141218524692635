/** @ngInject */
function PatientOtherMedicationController(_, $scope, patientOtherMedicationService, rxnconsoService) {
    const ctrl = this;
    const deletedMedications = [];

    ctrl.$onChanges = $onChanges;
    ctrl.addMedication = addMedication;
    ctrl.doChangeValue = doChangeValue;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;
    ctrl.medicationLookup = medicationLookup;
    ctrl.removeMedication = removeMedication;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
            if (ctrl.patient.id) {
                return patientOtherMedicationService.getMany(ctrl.patient.id).then((res) => {
                    ctrl.patient.other_medication = _.values(res);
                    doChangeValue();
                });
            }
            ctrl.patient.other_medication = [{}];
        }
    }

    function addMedication() {
        ctrl.patient.other_medication.push({
            dose: '',
            frequency: '',
            route_of_admin: '',
            text: '',
        });
    }

    function doChangeValue() {
        $scope.$emit('addPatientPage_updatePatient', {
            other_medication: ctrl.patient.other_medication,
        });
        $scope.$emit('addPatientPage_updateDeletedOtherMedications', deletedMedications);
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function medicationLookup(search) {
        if (typeof search === 'string') {
            return rxnconsoService.search(search).then((res) => {
                return _.values(res);
            });
        }
    }

    function removeMedication(index) {
        if (Object.prototype.hasOwnProperty.call(ctrl.patient.other_medication[index], 'id')) {
            deletedMedications.push(ctrl.patient.other_medication[index].id);
        }
        ctrl.patient.other_medication.splice(index, 1);
        doChangeValue();
    }
}

export default PatientOtherMedicationController;
