import { useQuery } from 'react-query';

import { useUserContext } from 'App/contexts/UserContext';
import CompanyService from 'App/services/CompanyService';

function useFetchRequiredPatientFields() {
    const user = useUserContext();
    const companyId = user.active_company.ID;
    return useQuery(
        ['companies', companyId, 'requiredPatientFields'],
        () => CompanyService.getRequiredPatientFields(companyId),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchRequiredPatientFields;
