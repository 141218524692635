import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxField } from 'Lib/form/CheckboxField';
import { useFormikContext } from 'formik';

const propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
    }).isRequired,
};

const defaultProps = {};

function EnableColumn({ row }) {
    const { isSubmitting } = useFormikContext();
    return (
        <CheckboxField
            aria-label="Toggle Enable/Disable"
            fieldName={`rules.${row.index}.enable`}
            disabled={isSubmitting}
        />
    );
}

EnableColumn.propTypes = propTypes;
EnableColumn.defaultProps = defaultProps;

export default EnableColumn;
