import React, { useState } from 'react';
import { Button, Col, Row, FormGroup, FormControl } from 'react-bootstrap';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { useUserContext } from 'App/contexts/UserContext';
import { errorHandler } from 'App/utils';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import FlexCenter from 'App/components/styles/FlexCenter';
import Page from 'App/components/Page';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import CompanyService from 'App/services/CompanyService';
import { isProd } from 'App/components/config/config';
import useSsoCompanyConfigurationQuery from 'App/hooks/useSsoCompanyConfigurationQuery';
import SsoConfigurationForm from './SsoConfigurationForm';
import { xmlBase } from './constants';
import './SsoConfiguration.scss';

const SsoConfiguration = () => {
    const user = useUserContext();
    const companyId = user.active_company.ID;
    const [disable, setDisable] = useState(true);
    const [fileUploadOption, setFileUploadOption] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { data, refetch } = useSsoCompanyConfigurationQuery(companyId);
    const queryClient = useQueryClient();
    const isNewConfiguration = data?.status === 404 && !data.identifierFormat;

    function handleFileLoad(event) {
        try {
            const textContent = event.target.result;
            const parser = new DOMParser();
            const providedXml = parser.parseFromString(textContent, 'text/xml');
            const ssoDescriptor = providedXml.getElementsByTagNameNS('*', 'IDPSSODescriptor')[0];

            // if we cant find the sso descriptor then we cant parse the relevant cert with confidence
            if (!ssoDescriptor) {
                return toast.error('SSO Descriptor could not be found. Please input values manually.');
            }
            // pull customer cert
            const cert = ssoDescriptor.getElementsByTagNameNS('*', 'X509Certificate')[0].textContent;

            // pull attributes for entrypoint and logout url
            const entryPoint = providedXml
                .getElementsByTagNameNS('*', 'SingleSignOnService')[0]
                .getAttribute('Location');

            const logoutUrl = providedXml
                .getElementsByTagNameNS('*', 'SingleLogoutService')[0]
                .getAttribute('Location');

            queryClient.setQueryData(['getSsoConfiguration', companyId], { ...data, entryPoint, logoutUrl, cert });
        } catch {
            return toast.error('Something went wrong parsing SAML metadata. Please input values manually.');
        }
    }

    const handleFileChange = (e) => {
        if (e.target.files[0].type !== 'text/xml') {
            errorHandler({ message: 'File must be of type XML.' });
        }
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = handleFileLoad;
            reader.readAsText(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        setFileUploadOption(true);
    };

    const configSchema = Yup.object().shape({
        cert: Yup.string().min(2, 'Value is too short').required('Required'),
        entryPoint: Yup.string().url().min(2, 'Value is too short').required('Required'),
        logoutUrl: Yup.string().url().min(2, 'Value is too short'),
    });

    const handleDownload = () => {
        const parser = new DOMParser();
        const baseUrl = isProd ? 'https://resources.therigy.com' : 'https://stmuat.therigy.com';
        const entityDescriptorId = isProd ? 'https___resources_therigy_com' : 'https___stmuat_therigy_com';
        const xmlDoc = parser.parseFromString(xmlBase.prod, 'text/xml');
        xmlDoc
            .getElementsByTagName('AssertionConsumerService')[0]
            .setAttribute('Location', `${baseUrl}/tas/1.0.0/saml2/${companyId}`);
        xmlDoc
            .getElementsByTagName('SingleLogoutService')[0]
            .setAttribute('Location', `${baseUrl}/tas/1.0.0/saml2/logout/${companyId}`);
        xmlDoc.getElementsByTagName('EntityDescriptor')[0].setAttribute('entityID', `${baseUrl}`);
        xmlDoc.getElementsByTagName('EntityDescriptor')[0].setAttribute('ID', `${entityDescriptorId}`);
        const a = document.createElement('a');
        const file = new Blob([xmlDoc.documentElement.outerHTML], { type: 'text/xml' });

        a.href = URL.createObjectURL(file);
        a.download = 'therigySamlData.xml';
        a.click();

        URL.revokeObjectURL(a.href);
    };

    const onSubmit = (values) => {
        setDisable(true);
        setFileUploadOption(false);
        submitChanges(values);
    };

    async function submitChanges(values) {
        setIsLoading(true);
        (isNewConfiguration ? CompanyService.addSsoCompanyConfiguration : CompanyService.updateSsoCompanyConfiguration)(
            values
        )
            .then(() => {
                toast.success('Configuration Saved successfully');
                refetch();
            })
            .catch(() => {
                errorHandler({
                    message: 'Not able to save. Please validate the information and format is correct.',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    if (isLoading) {
        return (
            <FlexCenter>
                <TherigyLoadingSpinner />
            </FlexCenter>
        );
    }

    return (
        <Page
            title="SSO Configuration"
            permissionType={PERMISSION_TYPES.ENABLE_SSO_COMPANY_CONFIGURATION}
            showPageTitle
        >
            <Formik initialValues={data} onSubmit={onSubmit} validationSchema={configSchema} enableReinitialize>
                {({ values, setFieldValue, handleSubmit }) => {
                    return (
                        <>
                            <Row>
                                <Col md={12}>
                                    <div className="sso-form-button-group">
                                        <Button
                                            bsStyle="primary"
                                            disabled={!disable}
                                            onClick={() => {
                                                setDisable(false);
                                            }}
                                        >
                                            {isNewConfiguration ? 'Add' : 'Edit'}
                                        </Button>
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            disabled={disable}
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            bsStyle="primary"
                                            type="submit"
                                            disabled={disable}
                                            onClick={handleUpload}
                                            className="btn btn-outline-primary"
                                        >
                                            Autofill from XML
                                        </Button>

                                        <Button
                                            bsStyle="primary"
                                            disabled={!disable}
                                            onClick={() => {
                                                handleDownload();
                                            }}
                                        >
                                            Download XML
                                        </Button>
                                        {!disable && (
                                            <Button
                                                bsStyle="primary"
                                                onClick={() => {
                                                    setDisable(true);
                                                    setFileUploadOption(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            {isNewConfiguration && disable && (
                                <Row>
                                    <Col md={12}>
                                        <h4>No existing SSO configuration found</h4>
                                    </Col>
                                </Row>
                            )}
                            {fileUploadOption && (
                                <Row>
                                    <Col md={12}>
                                        <div className="sso-form-button-group">
                                            <FormGroup controlId="formFile" className="mb-3">
                                                <FormControl type="file" onChange={(e) => handleFileChange(e)} />
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            {(!isNewConfiguration || !disable) && (
                                <SsoConfigurationForm setFieldValue={setFieldValue} values={values} disable={disable} />
                            )}
                        </>
                    );
                }}
            </Formik>
        </Page>
    );
};

export default SsoConfiguration;
