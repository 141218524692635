import styled from '@emotion/styled';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React from 'react';

const SafeHtmlStyle = styled.span`
    /* white-space: pre-line respects the newline characters (\\n) that occur when user hits the Enter key */
    white-space: pre-line;
`;

function sanitizeUnsafeHtml(input) {
    return DOMPurify.sanitize(input, { FORBID_TAGS: ['script'] });
}

const SafeHtmlOnly = ({ children }) => {
    return (
        // eslint-disable-next-line react/no-danger
        <SafeHtmlStyle dangerouslySetInnerHTML={{ __html: sanitizeUnsafeHtml(children) }} />
    );
};

SafeHtmlOnly.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SafeHtmlOnly;
