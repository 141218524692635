import CompanyService from '../../services/CompanyService';

/** @ngInject */
function AssessmentConfig(__env, $rootScopeProvider, $stateProvider) {
    $rootScopeProvider.digestTtl(20);

    $stateProvider.state({
        component: 'assessment',
        name: 'app.activity',
        url: '/patient/:patientId/activity/:activityId',
        resolve: {
            activity($state, $transition$, amsService) {
                const params = $transition$.params();
                return amsService
                    .getActivity(params.patientId, params.activityId, true)
                    .then((res) => {
                        return res;
                    })
                    .catch((error) => {
                        // ams returns 400 for patient not found
                        return error.status === 400 ? $state.go('app.404') : $state.go('app.500');
                    });
            },
            branchSettings() {
                return CompanyService.getEhrBranchSettings().then((res) => {
                    return res;
                });
            },
            patient($state, $transition$, pesService) {
                const params = $transition$.params();
                return pesService
                    .get(params.patientId)
                    .then((res) => {
                        return res;
                    })
                    .catch((error) => {
                        return error.status === 404 ? $state.go('app.404') : $state.go('app.500');
                    });
            },
            groups(_, $transition$, amsService) {
                return amsService.getQuestionGroups().then((res) => {
                    // TODO: Remove hack for group order
                    return _.map(res, (g) => {
                        if (!g.groupOrder) {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            g.groupOrder = 0;
                        }
                        return g;
                    });
                });
            },
            transitionParams($transition$) {
                return $transition$.params();
            },
        },
    });
}

export default AssessmentConfig;
