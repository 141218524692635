import { isArray, isPlainObject, map, mapKeys, mapValues, camelCase } from 'lodash';

const containsNumber = (key) => /\d/.test(key);

const mapKeysDeep = (obj, fn) => {
    if (isArray(obj)) return map(obj, (inner) => mapKeysDeep(inner, fn));

    if (isPlainObject(obj)) return mapValues(mapKeys(obj, fn), (value) => mapKeysDeep(value, fn));

    return obj;
};

const convertKeysToCamelCase = (obj) =>
    mapKeysDeep(obj, (_, key) => {
        if (containsNumber(key)) return key;

        return camelCase(key);
    });

export default convertKeysToCamelCase;
