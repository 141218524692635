import angular from 'angular';
import ReportEngineController from './report-engine.controller';
import ReportEngineConfig from './report-engine.config';

export default angular
    .module('components.reportEngine', [])
    .component('reportEngine', {
        bindings: {
            user: '<',
        },
        controller: ReportEngineController,
        template: require('./report-engine.html'),
    })
    .config(ReportEngineConfig).name;
