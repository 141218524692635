import angular from 'angular';

import AssessmentPreviewController from './assessment-preview.controller';
import AssessmentPreviewConfig from './assessment-preview.config';

/**
 *  This component creates preview functionality to mimic assessment.component.js.
 *  When making changes to this file verify functionality of the assessment component.
 */
export default angular
    .module('components.assessmentPreview', [])
    .component('assessmentPreview', {
        bindings: {
            assessment: '<',
            assessmentQuestions: '<',
            groups: '<',
            user: '<',
        },
        controller: AssessmentPreviewController,
        template: require('../assessment/assessment.html'),
    })
    .config(AssessmentPreviewConfig).name;
