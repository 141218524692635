import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as R from 'ramda';

import asFormField from './asFormField';
import CompanyService from 'App/services/CompanyService';
import { SelectInput } from './SelectField';

export const CustomMenu = ({
    name,
    value,
    onChange,
    onBlur,
    className,
    menuName,
    getOptionValue,
    getOptionLabel,
    placeholder,
    isClearable,
    isLoading,
    isDisabled,
    isMulti,
}) => {
    const options = useMemo(() => CompanyService.getCustomMenu(menuName), [menuName]);

    return (
        <SelectInput
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={className}
            options={options}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            placeholder={placeholder}
            isClearable={isClearable}
            isLoading={isLoading}
            isDisabled={isDisabled}
            isMulti={isMulti}
        />
    );
};

CustomMenu.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    menuName: PropTypes.string.isRequired,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
};

CustomMenu.defaultProps = {
    value: null,
    onBlur: null,
    className: '',
    getOptionValue: R.prop('valueName'),
    getOptionLabel: R.prop('valueName'),
    placeholder: undefined,
    isClearable: false,
    isLoading: false,
    isDisabled: false,
    isMulti: false,
};

const CustomMenuField = asFormField({ WrappedComponent: CustomMenu });

export default CustomMenuField;
