import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';

const propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholderText: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    invalid: PropTypes.string,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
};
const defaultProps = {
    value: '',
    label: '',
    placeholderText: '',
    type: '',
    disabled: false,
    required: false,
    invalid: '',
    errorMessage: '',
    className: '',
};

const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 7) return phoneNumber;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

function PhoneInput(props) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    const {
        id,
        name,
        value,
        label,
        placeholderText,
        onChange,
        type,
        disabled,
        required,
        invalid,
        errorMessage,
        className,
    } = props;
    return (
        <form>
            <FormGroup>
                <ControlLabel htmlFor={id} className={invalid ? 'invalid-insurance-field field-required' : className}>
                    {label}
                </ControlLabel>
                <FormControl
                    required={required}
                    invalid={invalid}
                    id={id}
                    name={name}
                    type={type}
                    placeholder={placeholderText}
                    onChange={onChange}
                    disabled={disabled}
                    value={formatPhoneNumber(value)}
                    className={invalid && 'invalid-insurance-form'}
                />
                {invalid ? <span className="error-message-insurance">{errorMessage}</span> : null}
            </FormGroup>
        </form>
    );
}

PhoneInput.propTypes = propTypes;
PhoneInput.defaultProps = defaultProps;

export default PhoneInput;
