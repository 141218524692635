import PESService from 'App/services/PESService';
import * as R from 'ramda';

/**
 * We need to do this in serial so the "last" one will be the "active" one.
 */
async function patientPrescriptionsUpdate(patientPrescriptions, patientId) {
    const prescriptionMedicationIds = Object.keys(patientPrescriptions || {});
    if (prescriptionMedicationIds.length) {
        for (const prescriptionMedicationId of prescriptionMedicationIds) {
            if (patientPrescriptions[prescriptionMedicationId].rxNumbers.length > 0) {
                // eslint-disable-next-line no-await-in-loop
                const prescription = await PESService.addPatientPrescriptions(patientId, [
                    {
                        medicationId: prescriptionMedicationId,
                        rxNumbers: patientPrescriptions[prescriptionMedicationId].rxNumbers.map((item) =>
                            item.rxFill
                                ? R.omit(['_internalUiId', 'rxFill', 'medication', 'rxFillLength', 'summary'], item)
                                : R.omit(['_internalUiId'], item)
                        ),
                    },
                ]);

                /**
                 * We use entries/toPairs here because we need the index.
                 */
                for (const [index, rxNumber] of R.toPairs(patientPrescriptions[prescriptionMedicationId].rxNumbers)) {
                    if (rxNumber?.rxFill?.length > 0) {
                        for (const fill of rxNumber.rxFill) {
                            if (fill.id) {
                                // eslint-disable-next-line no-await-in-loop
                                await PESService.updateRxFill(patientId, fill.id, fill);
                            } else {
                                // eslint-disable-next-line no-await-in-loop
                                await PESService.addRxFills(
                                    patientId,
                                    prescription[index].patientMedicationId,
                                    prescription[index].id,
                                    R.omit(['_internalUiId'], fill)
                                );
                            }
                        }
                    }
                }
            }
        }
    }
}

export default patientPrescriptionsUpdate;
