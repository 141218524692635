import { useQuery } from 'react-query';

import { errorHandler } from 'App/utils';
import { getReferralActivity } from 'App/services/RMSService';

function useFetchActivityDetails(patientId, referralId, activityId, typeId) {
    return useQuery(
        ['patient', patientId, 'referral', referralId, 'activity', activityId, 'type', typeId],
        () => getReferralActivity(patientId, referralId, activityId, typeId),
        {
            onError: errorHandler,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchActivityDetails;
