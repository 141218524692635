/** @ngInject */
function PatientProfilePendingActivitiesController(
    $scope,
    $uibModal,
    userService,
    NgTableParams,
    CSRF_ID_VALUE,
    CSRF_ID_NAME
) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.openActivityNotes = openActivityNotes;
    ctrl.openEditDueDateModal = openEditDueDateModal;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    userService.get().then((data) => {
        ctrl.angular_assessment = data.company_permissions.AngularAssessments;
    });

    function _filterActivities(data, filterValues) {
        const filteredData = data.filter((item) => {
            return (
                // Filter by type or show all types if no option is selected
                ((filterValues.type_label.Clinical === false &&
                    filterValues.type_label.PCC === false &&
                    filterValues.type_label.Referral === false) ||
                    filterValues.type_label[item.type_label]) &&
                (filterValues.status
                    ? item.status.toLowerCase().indexOf(filterValues.status.toLowerCase()) !== -1
                    : true) &&
                (filterValues.name ? item.name.toLowerCase().indexOf(filterValues.name.toLowerCase()) !== -1 : true)
            );
        });

        return filteredData;
    }

    function $onChanges() {
        if (ctrl.activities) {
            ctrl.activities = angular.copy(ctrl.activities);

            ctrl.tableParams = new NgTableParams(
                {
                    count: 5,
                    sorting: {
                        date_due: 'asc',
                    },
                    filter: {
                        type_label: {
                            Clinical: true,
                            PCC: true,
                            Referral: true,
                        },
                    },
                },
                {
                    filterOptions: { filterFn: _filterActivities },
                    counts: [5, 10, 25],
                    dataset: ctrl.activities,
                }
            );
        }

        if (ctrl.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
        }

        if (ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function $onInit() {
        ctrl.activityTypes = ['Clinical', 'PCC', 'Referral'];
    }

    function openActivityNotes(activity) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'activityNotes',
            size: 'md',
            windowClass: 'activity-notes-modal',
            resolve: {
                modalData() {
                    return {
                        activityId: activity.id,
                        activityName: activity.name,
                        firstName: ctrl.patient.first_name,
                        lastName: ctrl.patient.last_name,
                        type: activity.type_label,
                    };
                },
            },
        });

        modalInstance.result
            .then((noteChanged) => {
                if (noteChanged) {
                    $scope.$emit('patientActivitiesUpdated', {
                        patient_id: ctrl.patient.id,
                    });
                }

                return noteChanged;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function openEditDueDateModal(activity) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'patientProfileEditDueDateModal',
            size: 'md',
            resolve: {
                activity() {
                    return activity;
                },
            },
        });

        modalInstance.result.then((res) => {
            if (res) {
                $scope.$emit('patientActivitiesUpdated', {
                    patient_id: activity.patient_id,
                });
            }
            return res;
        });
    }
}

export default PatientProfilePendingActivitiesController;
