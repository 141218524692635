import React, { useEffect, useState } from 'react';
import { Button, Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import Select from 'react-select';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { Formik } from 'formik';
import CompanyService from 'App/services/CompanyService';
import * as R from 'ramda';
import ProtocolService from 'App/services/ProtocolService';
import { errorHandler } from 'App/utils';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { CheckboxField } from 'Lib/form/CheckboxField';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    excludeIds: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    show: PropTypes.bool,
};

const defaultProps = {
    show: false,
};

const getValuesAndSort = R.compose(R.sortBy(R.prop('name')), R.values);

const newOption = { name: 'Publish as a New Standalone', id: 'New' };

const ifNewOptionEquals = R.equals(newOption);

const validationSchema = Yup.object().shape({
    protocolToPublish: Yup.object().nullable().required('A protocol to publish is required.'),
    protocolToReplace: Yup.object().nullable().required('A protocol to replace is required.'),
});

function PublishProtocolModalBody({ onSave, onHide, show, excludeIds }) {
    //#region State
    const [availableCategories, setAvailableCategories] = useState([]);

    /**
     * Category is not stored in the form since we need an easy way to trigger a fetch when it changes.
     * What better way than a useEffect?
     */
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [availableProtocols, setAvailableProtocols] = useState([]);

    //#endregion

    //#region Side-Effects

    // Get available categories
    useEffect(() => {
        if (show) {
            CompanyService.getTherapyCategories()
                .then(getValuesAndSort)
                .then(setAvailableCategories)
                .catch(errorHandler);
        }
    }, [show]);

    // Get Available protocols
    const categoryId = R.prop('id', selectedCategory);

    useEffect(() => {
        if (categoryId) {
            ProtocolService.getProtocols(categoryId)
                .then(getValuesAndSort)
                .then(
                    //prettier-ignore
                    R.reject(
                        R.compose(
                            R.includes(R.__, excludeIds),
                            R.prop('id')
                        ),
                    )
                )
                .then(setAvailableProtocols)
                .catch(errorHandler);
        }
    }, [categoryId, excludeIds]);

    //#endregion

    return (
        <Formik
            initialValues={{
                protocolToPublish: null,
                protocolToReplace: null,
                enableForAll: false,
            }}
            onSubmit={(values) => {
                onSave(R.assoc('category', selectedCategory, values));
            }}
            validationSchema={validationSchema}
        >
            {({ handleSubmit, values, setValues, dirty, isValid }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <ControlLabel htmlFor="therapeutic-category">Therapeutic Category</ControlLabel>
                                    <Select
                                        options={availableCategories}
                                        isClearable
                                        inputId="therapeutic-category"
                                        placeholder="Select"
                                        getOptionValue={R.prop('id')}
                                        getOptionLabel={R.prop('name')}
                                        onChange={(option) => {
                                            R.compose(
                                                R.thunkify(setSelectedCategory)(option),
                                                setValues,
                                                R.set(R.lensProp('protocolToPublish'), null),
                                                R.set(R.lensProp('protocolToReplace'), null)
                                            )(values);
                                        }}
                                        value={selectedCategory}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <ControlLabel htmlFor="protocol-to-publish">Protocol to Publish</ControlLabel>
                                    <Select
                                        options={R.filter(R.prop('companyId'), availableProtocols)}
                                        placeholder="Select"
                                        isClearable
                                        inputId="protocol-to-publish"
                                        getOptionValue={R.prop('id')}
                                        getOptionLabel={R.prop('name')}
                                        onChange={(option) => {
                                            R.compose(
                                                setValues,
                                                R.set(R.lensProp('protocolToPublish'), option),
                                                R.set(R.lensProp('protocolToReplace'), null)
                                            )(values);
                                        }}
                                        value={values.protocolToPublish}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <ControlLabel htmlFor="protocol-to-replace">Protocol to Replace</ControlLabel>
                                    <Select
                                        options={R.compose(
                                            R.prepend(newOption),
                                            R.reject(R.prop('companyId'))
                                        )(availableProtocols)}
                                        placeholder="Select"
                                        inputId="protocol-to-replace"
                                        getOptionValue={R.prop('id')}
                                        getOptionLabel={R.prop('name')}
                                        onChange={(option) => {
                                            R.compose(
                                                setValues,
                                                R.ifElse(
                                                    R.always(ifNewOptionEquals(option)),
                                                    R.set(R.lensProp('enableForAll'), true),
                                                    R.set(R.lensProp('enableForAll'), false)
                                                ),
                                                R.set(R.lensProp('protocolToReplace'), option)
                                            )(values);
                                        }}
                                        value={values.protocolToReplace}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        {ifNewOptionEquals(values.protocolToReplace) ? (
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <CheckboxField fieldName="enableForAll" label="Enable for All" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        ) : null}

                        <Row>
                            <Col md={12}>
                                <FormButtonGroup>
                                    <Button type="button" onClick={onHide}>
                                        Cancel
                                    </Button>
                                    <Button bsStyle="primary" type="submit" disabled={dirty ? !isValid : true}>
                                        Confirm
                                    </Button>
                                </FormButtonGroup>
                            </Col>
                        </Row>
                    </form>
                );
            }}
        </Formik>
    );
}

PublishProtocolModalBody.propTypes = propTypes;
PublishProtocolModalBody.defaultProps = defaultProps;

export default PublishProtocolModalBody;
