import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted';
import PESService from '../../../services/PESService';
import * as R from 'ramda';
import { isExpired } from 'App/utils';
import mountReactComponent from 'App/utils/mountReactComponent';
import TreatmentHistory from 'App/components/TreatmentHistory';
import AppContextForAngular from 'App/components/AppContextForAngular';
import { v4 as uuid } from 'uuid';
import ViewPatientPrescription from 'App/components/PatientPrescription/ViewPatientPrescription';

/** @ngInject */
function PatientTherapeuticCategoryController(
    $q,
    $rootScope,
    $scope,
    $uibModal,
    _,
    patientService,
    patientMedicationRxNumberService
) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;
    ctrl.$onDestroy = $onDestroy;
    ctrl.openMedicationHistoryModal = openMedicationHistoryModal;
    ctrl.openPrescriptionHistoryModal = openPrescriptionHistoryModal;
    ctrl.openTherapyModal = openTherapyModal;
    ctrl.openEditTherapyModal = openEditTherapyModal;
    ctrl.openTherapyHistoryModal = openTherapyHistoryModal;
    ctrl.removeMedication = removeMedication;
    ctrl.removeTherapy = removeTherapy;
    ctrl.ic10Merged = ic10Merged;
    ctrl.hasNewRxFillNumberEnabled = isPermitted(PERMISSION_TYPES.ENABLE_RX_FILL_NUMBERS, ctrl.user);
    ctrl.medication_rx = {};
    ctrl.patientMedicationsArray = [];
    ctrl.rxNumbers = [];
    ctrl.isNewRxLoading = false;

    function $onChanges(changes) {
        if (changes.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
        if (changes.patient) {
            ctrl.patient = angular.copy(ctrl.patient);

            const promises = {};

            if (ctrl.patient.id) {
                return patientService
                    .getMedications(ctrl.patient.id)
                    .then((res) => {
                        ctrl.patient.medications = angular.copy(res);
                        ctrl.patientMedicationsArray = angular.copy(res);
                        ctrl.initialMedicationCount = _.size(ctrl.patient.medications);
                        if (!ctrl.hasNewRxFillNumberEnabled) {
                            _.forEach(res, (patientMedication) => {
                                promises[patientMedication.medication.id] = patientMedicationRxNumberService.query(
                                    ctrl.patient.id,
                                    patientMedication.medication.id
                                );
                            });
                        }
                        return $q.all(promises);
                    })
                    .then((res) => {
                        ctrl.patient.prescriptions = {};
                        ctrl.patientPrescriptions = {};
                        if (!ctrl.hasNewRxFillNumberEnabled) {
                            _.forEach(res, (patientPrescriptions, medicationId) => {
                                ctrl.patient.prescriptions[medicationId] = _.values(patientPrescriptions);
                            });
                        }
                        return patientService.getTherapies(ctrl.patient.id);
                    })
                    .then((res) => {
                        ctrl.patient.therapies = res;
                        ctrl.originalTherapies = angular.copy(res);
                        doChangeValue();
                        if (Object.keys(ctrl.patient.therapies).length) {
                            for (const therapy of Object.keys(ctrl.patient.therapies)) {
                                PESService.getAllTherapyICD10s(ctrl.patient.id, therapy).then((result) => {
                                    ctrl.patient.therapies[therapy].icd10 = result;
                                    ctrl.patient.therapies[therapy].icd10Merged = ctrl.ic10Merged(result);
                                });
                            }
                        }
                    });
            }
            ctrl.patient.medications = {};
            ctrl.patient.prescriptions = {};
            ctrl.patientPrescriptions = {};
            ctrl.icd10selected = {};
            ctrl.patient.therapies = {};
            ctrl.originalTherapies = {};
            ctrl.initialMedicationCount = 0;
        }
    }

    /**
     * Mount react
     */

    function mountPatientPrescriptions(medication, patientPrescriptions) {
        mountReactComponent(
            `#view-rx-number-${medication.id}`,
            <AppContextForAngular>
                <ViewPatientPrescription
                    patientPrescriptions={patientPrescriptions}
                    medication={medication}
                    viewRxNumber
                />
            </AppContextForAngular>
        );
    }

    function mountTreatmentHistory() {
        R.forEachObjIndexed((therapy, therapyId) => {
            mountReactComponent(
                `#treatment-history-${therapyId}`,
                <AppContextForAngular>
                    <TreatmentHistory therapyId={therapyId} onConfirm={onAddTreatmentHistory} />
                </AppContextForAngular>
            );
        }, ctrl.patient?.therapies || {});
    }

    function onAddTreatmentHistory(payload) {
        const treatmentId = uuid();
        ctrl.patient.medications[treatmentId] = {
            treatmentId,
            medication: {
                isTreatmentHistory: true,
                newMedication: true,
                id: payload.medication.id,
                name: payload.medication.name,
                group_id: payload.therapyId,
            },
            status: {
                end: payload.endDate,
                start: payload.startDate,
                end_reason: payload.endReason,
                end_notes: payload.notes,
            },
        };
    }

    function $onInit() {
        ctrl._ = _;
        ctrl.medicationsAdded = [];
        ctrl.prescriptionsAdded = [];
        ctrl.patientPrescriptionsAdded = [];

        if (ctrl.patient.id) {
            patientService.getTherapies(ctrl.patient.id).then((res) => {
                ctrl.patient.therapies = res;
                ctrl.originalTherapies = angular.copy(res);
                for (const therapyId of Object.keys(ctrl.patient.therapies)) {
                    PESService.getAllTherapyICD10s(ctrl.patient.id, therapyId).then((result) => {
                        ctrl.patient.therapies[therapyId].icd10 = result;
                        ctrl.patient.therapies[therapyId].icd10Merged = ctrl.ic10Merged(result);
                    });
                }
            });
            return patientService.getMedications(ctrl.patient.id).then((res) => {
                ctrl.patientMedicationsArray = _.values(res);
                ctrl.patientMedicationsArray.forEach((patientMedication) => {
                    ctrl.isNewRxLoading = true;
                    return PESService.getPatientMedicationRxNumber(
                        ctrl.patient.id,
                        patientMedication.medication.id,
                        true
                    ).then((rxRes) => {
                        ctrl.medication_rx = {};
                        ctrl.medication_rx.id = patientMedication.medication.id;
                        ctrl.getPatientPrescriptions = rxRes;
                        ctrl.getPatientPrescriptions
                            .filter((prescription) => prescription.end === null || !isExpired(prescription.end))
                            .map((patientPrescription, index) => {
                                ctrl.patientPrescriptions[patientPrescription.medication.id] =
                                    ctrl.patientPrescriptions[patientPrescription.medication.id] || {};
                                ctrl.patientPrescriptions[patientPrescription.medication.id].rxNumbers =
                                    ctrl.patientPrescriptions[patientPrescription.medication.id].rxNumbers || [];
                                ctrl.patientPrescriptions[patientPrescription.medication.id].rxNumbers[index] =
                                    R.omit(['prescriptionData', 'medication'], patientPrescription) || [];

                                if (
                                    ctrl.patientPrescriptions[patientPrescription.medication.id].rxNumbers[index].rxFill
                                        .length
                                ) {
                                    const recentFill = _.orderBy(
                                        ctrl.patientPrescriptions[patientPrescription.medication.id].rxNumbers[index]
                                            .rxFill,
                                        ['createdOn', 'fillNumber'],
                                        ['desc', 'desc']
                                    );
                                    ctrl.patientPrescriptions[patientPrescription.medication.id].rxNumbers[
                                        index
                                    ].rxFill = [];
                                    ctrl.patientPrescriptions[patientPrescription.medication.id].rxNumbers[
                                        index
                                    ].rxFill.push(recentFill[0]);
                                }
                                return ctrl.patientPrescriptions;
                            });
                        ctrl.isNewRxLoading = false;
                        $onDestroy(ctrl.medication_rx);
                        mountPatientPrescriptions(ctrl.medication_rx, ctrl.patientPrescriptions);
                    });
                });
            });
        }
    }

    function doChangeValue() {
        $scope.$emit('addPatientPage_updatePatient', {
            medications: ctrl.patient.medications,
            prescriptions: ctrl.patient.prescriptions,
            therapies: ctrl.patient.therapies,
        });

        $rootScope.$emit('patientMedicationsChanged', {
            medications: ctrl.patient.medications,
            prescriptions: ctrl.patient.prescriptions,
            therapies: ctrl.patient.therapies,
            medicationsAdded: ctrl.medicationsAdded,
            prescriptionsAdded: ctrl.prescriptionsAdded,
        });

        mountTreatmentHistory();
    }

    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
    }

    function openMedicationHistoryModal(patientId, medication) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'medicationHistoryModal',
            size: 'lg',
            resolve: {
                patientId() {
                    return patientId;
                },
                medication() {
                    return medication;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    function openPrescriptionHistoryModal(patientId, prescriptions, medication) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'prescriptionHistoryModal',
            size: 'lg',
            resolve: {
                patientId() {
                    return patientId;
                },
                prescriptions() {
                    return prescriptions;
                },
                medication() {
                    return medication;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    function openTherapyModal(patientTherapy, patientMedication, isEdit = false) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'addTherapyModal',
            windowClass: 'add-therapy-modal',
            keyboard: true,
            size: 'lg',
            resolve: {
                isEdit,
                disabledFields() {
                    return ctrl.disabledFields;
                },
                requiredFields() {
                    return ctrl.requiredFields;
                },
                user() {
                    return ctrl.user;
                },
                originalTherapy() {
                    if (patientTherapy && patientTherapy.id) {
                        return ctrl.originalTherapies[patientTherapy.id];
                    }
                    return {};
                },
                therapy() {
                    if (patientTherapy) {
                        return patientTherapy;
                    }
                },
                medication() {
                    if (patientMedication) {
                        return {
                            id: patientMedication.medication.id,
                            original_medication_id: patientMedication.medication.id,
                            patient_medication_id: patientMedication.id,
                            newMedication: patientMedication.medication.newMedication,
                            name: patientMedication.medication.name,
                            start: patientMedication.status.start ? new Date(patientMedication.status.start) : null,
                            end: patientMedication.status.end ? new Date(patientMedication.status.end) : null,
                        };
                    }
                    if (patientMedication === false) {
                        return false;
                    }
                    return null;
                },
                patient() {
                    return ctrl.patient;
                },
                prescriptions() {
                    if (patientMedication) {
                        return ctrl.patient.prescriptions;
                    }
                    return [];
                },
                patientPrescriptions() {
                    if (patientMedication) {
                        return ctrl.patientPrescriptions;
                    }
                    return {};
                },
                icd10selected() {
                    if (patientTherapy) {
                        return patientTherapy.icd10;
                    }
                    return [];
                },
            },
        });

        modalInstance.result
            .then((res) => {
                if (res.therapy.id) {
                    ctrl.patient.therapies[res.therapy.id] = angular.copy(res.therapy);
                }

                // Check if there is a medication added first, with a valid id to avoid silent errors
                if (res.medication && res.medication.id) {
                    if (res.medication.patient_medication_id) {
                        ctrl.patient.medications[res.medication.patient_medication_id].status.start =
                            res.medication.start;
                        ctrl.patient.medications[res.medication.patient_medication_id].status.end = res.medication.end;
                        ctrl.patient.medications[res.medication.patient_medication_id].status.end_reason =
                            res.medication.end_reason;
                        ctrl.patient.medications[res.medication.patient_medication_id].status.end_notes =
                            res.medication.end_notes;
                    } else {
                        if (res.medication.id !== res.medication.original_medication_id) {
                            delete ctrl.patient.medications[res.medication.original_medication_id];
                        }

                        if (res.medication && res.medication.id) {
                            ctrl.patient.medications[res.medication.id] = {
                                medication: {
                                    newMedication: res.medication.newMedication || undefined,
                                    id: res.medication.id,
                                    name: res.medication.name,
                                    group_id: res.therapy.id,
                                },
                                status: {
                                    end: res.medication.end,
                                    start: res.medication.start,
                                    end_reason: res.medication.end_reason,
                                },
                            };

                            ctrl.medicationsAdded.push(res.medication);
                        }
                    }
                }

                if (res.prescriptions) {
                    _.assignIn(ctrl.patient.prescriptions, res.prescriptions);
                    ctrl.prescriptionsAdded.push(ctrl.patient.prescriptions);
                }
                doChangeValue();

                if (res.patientPrescriptions) {
                    _.assignIn(ctrl.patientPrescriptions, res.patientPrescriptions);
                    ctrl.patientPrescriptionsAdded.push(ctrl.patientPrescriptions);
                    $rootScope.$broadcast('addPatientPrescription', ctrl.patientPrescriptions);
                    const patientPrescriptionsKeys = Object.keys(ctrl.patientPrescriptions || {});

                    if (patientPrescriptionsKeys.length > 0) {
                        patientPrescriptionsKeys.forEach((rxKey) => {
                            const medication = {};
                            medication.id = rxKey;
                            $onDestroy(medication);
                            mountPatientPrescriptions(medication, ctrl.patientPrescriptions);
                        });
                    }
                }

                if (res.icd10selected) {
                    _.assignIn(ctrl.icd10selected, res.icd10selected);
                    ctrl.patient.therapies[res.therapy.id].icd10 = res.icd10selected;
                    ctrl.patient.therapies[res.therapy.id].icd10Merged = ctrl.ic10Merged(res.icd10selected);
                    $rootScope.$broadcast('addRemovePatientICD10', ctrl.patient.therapies);
                }

                if (res.addAdditional) {
                    // Reset the value here of it will carry into the modal
                    res.addAdditional = false;
                    openTherapyModal(res.therapy);
                    return res;
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    }

    function openEditTherapyModal(patientTherapy, patientMedication) {
        openTherapyModal(patientTherapy, patientMedication, true);
    }

    function openTherapyHistoryModal(patientId, therapy) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'therapeuticCategoryHistory',
            size: 'lg',
            resolve: {
                patientId() {
                    return patientId;
                },
                therapy() {
                    return therapy;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    function removeMedication(patientMed) {
        if (patientMed.medication.isTreatmentHistory) {
            delete ctrl.patient.medications[patientMed.treatmentId];
        } else {
            delete ctrl.patient.medications[patientMed.medication.id];
        }
        doChangeValue();
    }

    function removeTherapy(key) {
        delete ctrl.patient.therapies[key];

        // Remove any medications associated to the therapy
        _.forEach(ctrl.patient.medications, (m, i) => {
            if (m.medication.group_id === key) {
                delete ctrl.patient.medications[i];
            }
        });
        doChangeValue();
    }

    function ic10Merged(therapyIcd10) {
        let icd10s = '';
        therapyIcd10.map((item) => {
            if (item.icd10Details) {
                // eslint-disable-next-line no-unused-expressions
                therapyIcd10.length > 1
                    ? (icd10s += `${item.icd10Details.DxCode} - ${item.icd10Details.DxDescShort}, `)
                    : (icd10s += `${item.icd10Details.DxCode} - ${item.icd10Details.DxDescShort}`);
            }
            if (item.code) {
                // eslint-disable-next-line no-unused-expressions
                therapyIcd10.length > 1
                    ? (icd10s += `${item.code} - ${item.shortDescription}, `)
                    : (icd10s += `${item.code} - ${item.shortDescription}`);
            }
            return icd10s;
        });
        return icd10s;
    }

    function $onDestroy(medication) {
        if (medication) {
            const dom = document.getElementById(`view-rx-number-${medication.id}`);
            if (dom) {
                unmountComponentAtNode(dom);
            }
        }

        const treatmentHistoryNode = document.getElementById('treatment-history');
        if (treatmentHistoryNode) {
            unmountComponentAtNode(treatmentHistoryNode);
        }
    }
}

export default PatientTherapeuticCategoryController;
