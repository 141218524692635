import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';

function useFetchPatientRx(patientId) {
    return useQuery(
        ['patient', patientId, 'rx'],
        () => {
            /**
             * Patient ID is optional here to support the AssessmentPreview, which needs to render without a patientId
             */
            return !patientId ? null : PESService.getPatientRx(patientId);
        },
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchPatientRx;
