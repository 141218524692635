import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap/lib/InputGroup';
import { errorHandler } from 'App/utils';
import propOrDash from 'App/utils/propOrDash';
import dateOrDashUtc from 'App/utils/dateOrDashUtc';
import pathOrDash from 'App/utils/pathOrDash';
import { toast } from 'react-toastify';
import CustomMenuField from 'Lib/form/CustomMenu';
import CompanyService from 'App/services/CompanyService';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import TherigyInfoMessage from '../TherigyInfoMessage/TherigyInfoMessage';
import Table from '../Table/Table';
import { DatePickerField } from 'Lib/form/DatePickerField';
import useUpdateReferralMedication from 'App/hooks/useUpdateReferralMedication';
import useGetReferralMedication from 'App/hooks/useGetReferralMedication';
import { referralModalPropType } from '../AddReferralActivity/types';
import FormButtonGroup from '../styles/FormButtonGroup';

const rowPropTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            medicationId: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

const RowDatePicker = ({ row }) => (
    <DatePickerField
        fieldName={`medications.${row.original.medicationId}.statusDate`}
        required
        placeholder="YYYY/MM/DD"
    />
);
const RowCustomMenu = ({ row }) => (
    <CustomMenuField
        menuName="referral_medication_status_reason"
        fieldName={`medications.${row.original.medicationId}.status`}
    />
);

RowDatePicker.propTypes = rowPropTypes;
RowDatePicker.defaultProps = {};

RowCustomMenu.propTypes = rowPropTypes;
RowCustomMenu.defaultProps = {};

const defaultProps = {};

const EditMedicationModal = ({ show, onHide, patientId, referralId }) => {
    const isFeatureReferralPMEnabled = CompanyService.getFeatureFlagStatus(
        PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER
    );
    const updateMedicationMutation = useUpdateReferralMedication();
    const { data: medications, isLoading } = useGetReferralMedication(patientId, referralId);
    const columns = useMemo(
        () => [
            {
                Header: isFeatureReferralPMEnabled ? 'Medication' : 'Medications',
                accessor: 'medication.name',
                Cell: ({ row }) => pathOrDash(['original', 'medication', 'name'], row),
                id: 'medication',
            },
            {
                Header: 'ICD-10',
                accessor: propOrDash('icd_10'),
                id: 'icd_10',
            },
            {
                Header: 'Rx Number',
                accessor: 'rx.rxNumber',
                Cell: ({ row }) => pathOrDash(['original', 'rx', 'rxNumber'], row),
                id: 'rxNumber',
            },
            {
                Header: 'Rx Status',
                accessor: propOrDash('status'),
                Cell: RowCustomMenu,
                id: 'status',
            },
            {
                Header: 'Rx Status Date',
                accessor: dateOrDashUtc(['statusDate']),
                Cell: RowDatePicker,
                id: 'statusDate',
            },
        ],
        [isFeatureReferralPMEnabled]
    );
    const initialValues = useMemo(() => {
        const state = {};
        medications?.forEach((it) => {
            state[it.medicationId] = {
                id: it.id,
                status: {
                    valueName: it.status,
                },
                statusDate: new Date(it.statusDate),
                medicationId: it.medicationId,
            };
        });
        return {
            medications: state,
        };
    }, [medications]);

    return (
        <Modal
            bsSize="lg"
            show={show}
            onHide={() => onHide(false)}
            backdrop="static"
            className="referral-add-communication"
        >
            <Modal.Header>
                <Modal.Title>{isFeatureReferralPMEnabled ? 'Edit Medication' : 'Edit Medications'}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const { medications: formMedicationValue } = values;
                    const promiseArray = Object.values(formMedicationValue || {}).map((med) =>
                        updateMedicationMutation.mutateAsync({
                            patientId,
                            referralId,
                            medicationId: med.id,
                            body: {
                                medication_id: med.medicationId,
                                status: med?.status?.valueName,
                                status_date: med?.statusDate,
                            },
                        })
                    );
                    Promise.all(promiseArray)
                        .then(() => {
                            toast.success('Referral medication successfully updated');
                        })
                        .catch(errorHandler)
                        .finally(() => {
                            setSubmitting(false);
                            onHide(true);
                        });
                }}
            >
                {({ isSubmitting, handleSubmit, submitForm, isValid }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Modal.Body>
                                {medications?.length > 0 && !isLoading ? (
                                    <Table
                                        enablePagination={false}
                                        columns={columns}
                                        data={medications}
                                        isLoading={isLoading}
                                    />
                                ) : (
                                    <TherigyInfoMessage message="There are currently no medications attached to this referral." />
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button
                                        bsClass="btn btn-primary"
                                        className="pull-right save-due-date"
                                        onClick={submitForm}
                                        id="add-referral-communication-modal-submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        disabled={isSubmitting}
                                        bsClass="btn btn-default"
                                        className="pull-right"
                                        onClick={() => onHide(false)}
                                    >
                                        Cancel
                                    </Button>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

EditMedicationModal.propTypes = referralModalPropType;
EditMedicationModal.defaultProps = defaultProps;

export default EditMedicationModal;
