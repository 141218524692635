import angular from 'angular';

import PDXFindPatientController from './pdx-find-patient.controller';
import PDXFindPatientConfig from './pdx-find-patient.config';
import lockedFields from './constant/locked-fields.constant';
import PDXFindPatientZipCodeFilter from './filter/zip-code.filter';
import PDXDuplicatePatientResolveModalController from './pdx-duplicate-patient-resolve-modal/pdx-duplicate-patient-resolve-modal.controller';

export default angular
    .module('components.pdxFindPatient', ['ui.bootstrap', 'ngTable', 'ui.select', 'ngMessages'])
    .component('pdxFindPatient', {
        controller: PDXFindPatientController,
        bindings: {
            lockedFields: '<',
        },
        template: require('./pdx-find-patient.html'),
    })
    .config(PDXFindPatientConfig)
    .component('pdxDuplicatePatientResolveModal', {
        bindings: {
            modalInstance: '<',
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
        controller: PDXDuplicatePatientResolveModalController,
        template: require('./pdx-duplicate-patient-resolve-modal/pdx-duplicate-patient-resolve-modal.html'),
    })
    .component('pdxPreViewPatient', {
        bindings: {
            modalInstance: '<',
            resolve: '<',
        },
        template: require('./pdx-preview-patient-modal/pdx-preview-patient.html'),
    })
    .constant('pdxLockedFields', lockedFields)
    .filter('zipCode', PDXFindPatientZipCodeFilter).name;
