/** @ngInject */
function WorkQueueActivityTypeFilterController(_, workQueueService, CSRF_ID_VALUE, CSRF_ID_NAME) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.doChangeFilter = doChangeFilter;

    ctrl.activityTypes = {};
    ctrl.all_types = false;
    ctrl.assignedToMe = false;
    ctrl.onlyMine = false;
    ctrl.highRisk = false;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    function $onChanges(changes) {
        if (changes.activityTypes && ctrl.activityTypes) {
            ctrl.activityTypes = angular.copy(workQueueService.clearObject(ctrl.activityTypes));
            ctrl.allTypes = _.size(ctrl.types) === _.size(ctrl.activityTypes);
        }

        if (ctrl.allTypes !== undefined) {
            ctrl.allTypes = angular.copy(ctrl.allTypes);
        }

        if (ctrl.assignedToMe !== undefined) {
            ctrl.assignedToMe = angular.copy(ctrl.assignedToMe);
        }

        if (ctrl.onlyMine !== undefined) {
            ctrl.onlyMine = angular.copy(ctrl.onlyMine);
        }

        if (ctrl.highRisk !== undefined) {
            ctrl.highRisk = angular.copy(ctrl.highRisk);
        }

        if (ctrl.pccOnly !== undefined) {
            ctrl.pccOnly = angular.copy(ctrl.pccOnly);
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);

            ctrl.types = {
                PCC: 'PCC',
                CLI: 'Clinical',
                REF: 'Referral',
            };
        }
    }

    function doChangeFilter() {
        const activityTypes = workQueueService.clearObject(ctrl.activityTypes);

        ctrl.onChangeFilter({
            filter: {
                all_types: _.size(ctrl.types) === _.size(activityTypes),
                activityTypes,
                assignedToMe: ctrl.assignedToMe,
                onlyMine: ctrl.onlyMine,
                highRisk: ctrl.highRisk,
            },
        });
    }
}

export default WorkQueueActivityTypeFilterController;
