import React from 'react';
import Table from 'App/components/Table';
import * as R from 'ramda';
import propOrDash from 'App/utils/propOrDash';
import localize from 'App/utils/localize';
import useFetchPatientReferralsQuery from 'App/hooks/useFetchPatientReferralsQuery';
import dateOrDashUtc from 'App/utils/dateOrDashUtc';
import hasLength from 'App/utils/hasLength';
import ReferralIdCell from './ReferralIdCell';
import ReferralActionsCell from './ReferralActionsCell';
import PropTypes from 'prop-types';
import MultiStatusFilter, { multiStatusFilter } from './MultiStatusFilter';

const propTypes = {
    patientId: PropTypes.string.isRequired,
};

const defaultProps = {};

const columns = [
    {
        Header: 'Referral #',
        accessor: propOrDash('number'),
        Cell: ReferralIdCell,
    },
    {
        Header: 'Medication',
        accessor: R.ifElse(
            R.compose(hasLength, R.prop('medications')),
            R.compose(
                R.join(', '),
                R.sortBy(R.identity),
                R.uniq,
                R.map(R.path(['medication', 'name'])),
                R.prop('medications')
            ),
            R.always('--')
        ),
    },
    {
        Header: 'Due Date',
        id: 'dueDate',
        accessor: dateOrDashUtc(['createdOn']),
    },
    {
        Header: 'Last Updated',
        accessor: R.ifElse(
            R.prop('updatedOn'),
            R.compose((date) => localize(date, 'YYYY-MM-DD hh:mm A'), R.prop('updatedOn')),
            R.always('--')
        ),
    },
    {
        Header: 'Status',
        accessor: propOrDash('status'),
        filter: multiStatusFilter,
        Filter: MultiStatusFilter,
    },
    {
        Header: 'Status Reason',
        accessor: propOrDash('statusReason'),
    },
    {
        Header: 'Actions',
        Cell: ReferralActionsCell,
    },
];

function tableStateReducer(state, action, prevState) {
    const initialSort = [{ id: 'dueDate', desc: true }];

    const { type } = action;
    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
        default:
            return state;
    }
}

function ReferralTable({ patientId }) {
    const { isLoading, data = [] } = useFetchPatientReferralsQuery(patientId);

    return <Table columns={columns} data={data} isLoading={isLoading} stateReducer={tableStateReducer} />;
}

ReferralTable.propTypes = propTypes;
ReferralTable.defaultProps = defaultProps;

export default ReferralTable;
