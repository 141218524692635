import * as R from 'ramda';
import * as Yup from 'yup';

import ActionCell, { ActionButtonLink } from 'App/components/styles/ActionCell';
import { Button, Col, Row } from 'react-bootstrap';
import {
    allDiagnosisOption,
    allMedicationsOption,
    DISPLAY_OPERATORS,
    OPERATORS,
    REFERRAL_TYPES,
    TRIGGER_TYPES,
} from '../constants';
import React, { Fragment, useEffect, useMemo } from 'react';
import { getFilterOperator, groupFilters } from '../utils';
import { errorHandler, hasLength, localize } from 'App/utils';

import AddRule from '../AddRule';
import AssessmentService from 'App/services/AssessmentService';
import { getReferralTypes } from 'App/services/RMSService';
import DeleteModal from 'App/components/DeleteModal';
import { useMachine } from '@xstate/react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/lib/Alert';
import { Helmet } from 'react-helmet';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createMachine } from 'xstate';

import env from 'App/components/config/config';
import ProtocolService from 'App/services/ProtocolService';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';

import PageTitle from 'App/components/styles/PageTitle/PageTitle';
import PageWrapper from 'App/components/styles/PageWrapper/PageWrapper';
import SearchColumnFilter from 'App/components/Table/SearchColumnFilter';

import Table from 'App/components/Table';
import ProtocolBaseForm from '../ProtocolBaseForm';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';

import useEditProtocolContext from 'App/features/ProtocolManager/EditProtocolPage/useEditProtocolContext';
import Collapse from 'App/components/Collapse';
import useFeatureFlag from 'App/hooks/useFeatureFlag';

//#region State Machine
const MACHINE_STATES = Object.freeze({
    ADDING_RULE: 'ADDING_RULE',
    COPYING: 'COPYING',
    EDITING: 'EDITING',
    LOADING: 'LOADING',
    SAVING: 'SAVING',
});

const MACHINE_EVENTS = Object.freeze({
    ADD_RULE: 'ADD_RULE',
    ADD_RULE_COMPLETE: 'ADD_RULE_COMPLETE',
    CANCEL: 'CANCEL',
    COPY: 'COPY',
    DELETE_PROTOCOL: 'DELETE_PROTOCOL',
    DELETE_RULE: 'DELETE_RULE',
    DELETE_RULE_COMPLETE: 'DELETE_RULE_COMPLETE',
    EDIT: 'EDIT',
    SAVE: 'SAVE',
    SAVE_COMPLETE: 'SAVE_COMPLETE',
});

const editProtocolMachine = createMachine({
    initial: MACHINE_STATES.LOADING,
    states: {
        [MACHINE_STATES.ADDING_RULE]: {
            on: {
                [MACHINE_EVENTS.ADD_RULE_COMPLETE]: MACHINE_STATES.EDITING,
                [MACHINE_EVENTS.CANCEL]: MACHINE_STATES.EDITING,
            },
        },

        [MACHINE_STATES.COPYING]: {
            type: 'final',
        },

        [MACHINE_STATES.EDITING]: {
            on: {
                [MACHINE_EVENTS.ADD_RULE]: MACHINE_STATES.ADDING_RULE,

                [MACHINE_EVENTS.ADD_RULE_COMPLETE]: MACHINE_STATES.EDITING,

                [MACHINE_EVENTS.COPY]: MACHINE_STATES.COPYING,

                [MACHINE_EVENTS.SAVE]: MACHINE_STATES.SAVING,
            },
        },

        [MACHINE_STATES.LOADING]: {
            on: {
                [MACHINE_EVENTS.EDIT]: MACHINE_STATES.EDITING,
            },
        },
        [MACHINE_STATES.SAVING]: {
            on: {
                [MACHINE_EVENTS.SAVE_COMPLETE]: MACHINE_STATES.EDITING,
            },
        },
    },
});

//#endregion

//#region Table Setup
const tableStateReducer = (state, action, prevState) => {
    const initialSort = [{ id: 'event', desc: false }];

    const { type } = action;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-case
    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
    }
    return state;
};

function conditionRenderer(filters) {
    return R.compose(
        R.addIndex(R.map)((filterValues, valuesIdx, valuesArray) => {
            return (
                <Fragment key={valuesIdx}>
                    {R.addIndex(R.map)((filter, groupIdx, groupArray) => {
                        return (
                            <div
                                key={filter.id}
                                style={{
                                    color:
                                        R.has('disabledOn', filter) &&
                                        filter.disabledOn !== null &&
                                        filter.disabledOn !== '0000-00-00 00:00:00'
                                            ? 'red'
                                            : 'none',
                                }}
                            >
                                {'when '}
                                <strong>{`"${filter.hidden ? '(HIDDEN) ' : ''}${filter.questionText}" `}</strong>
                                {filter.operator === OPERATORS.IS_ANSWERED ? (
                                    DISPLAY_OPERATORS.is_answered
                                ) : (
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line react/jsx-no-useless-fragment
                                    <>
                                        {R.has('disabledOn', filter) &&
                                        filter.disabledOn !== null &&
                                        filter.disabledOn !== '0000-00-00 00:00:00' ? (
                                            <>
                                                {`${getFilterOperator(filter)} `}
                                                <strong>{`"${filter.answerText}" `}</strong> disabled on
                                                <strong>{` "${localize(
                                                    filter.disabledOn,
                                                    'YYYY/MM/DD h:mm a'
                                                )}"`}</strong>
                                            </>
                                        ) : (
                                            <>
                                                {`${getFilterOperator(filter)} `}
                                                <strong>{`"${filter.answerText}"`}</strong>
                                            </>
                                        )}
                                    </>
                                )}
                                {groupArray[groupIdx + 1] ? ' or' : valuesArray[valuesIdx + 1] && ' and'}
                            </div>
                        );
                    }, filterValues)}
                </Fragment>
            );
        }),
        R.values,
        groupFilters
    )(filters);
}

function shouldShowProtocolDelete({ hasDeleteTherigyProtocol, isReadOnly }) {
    return hasDeleteTherigyProtocol || !isReadOnly;
}

function isProtocolDeleteDisabled({ machineValue, isSubmitting, isReadOnly, hasDeleteTherigyProtocol }) {
    const result = R.anyPass([
        R.equals(MACHINE_STATES.LOADING),
        R.equals(MACHINE_STATES.SAVING),
        R.always(isSubmitting),
    ])(machineValue);

    // isReadOnly is mostly used to determine if the _page_ is readonly (i.e., view rather than create.)
    return hasDeleteTherigyProtocol ? result : result || isReadOnly;
}

//#endregion

const propTypes = {
    /**
     * This is provided by React Router
     */
    match: PropTypes.shape({
        params: PropTypes.shape({
            protocolId: PropTypes.string,
        }),
    }).isRequired,
};

const defaultProps = {};

const EditProtocolPage = (props) => {
    const protocolId = R.path(['match', 'params', 'protocolId'], props);
    const hasDeleteTherigyProtocol = useIsPermitted(PERMISSION_TYPES.DELETE_THERIGY_PROTOCOL);
    const showReferralAssessment = useFeatureFlag(PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER);
    const isRenewalsEnabled = useFeatureFlag(PERMISSION_TYPES.FEATURE_NEW_RENEWAL_ACTIVITY_TYPES);

    const { state, actions } = useEditProtocolContext();

    const [machineState, send] = useMachine(editProtocolMachine);

    const history = useHistory();

    //#region State
    const {
        addedTriggers,
        protocolToDelete,
        deleteTriggerAdHocFn,
        deletedTriggers,
        diagnosisCodeOptions,
        isReadOnly,
        medicationOptions,
        protocol,
        protocolTriggers,
        selectedDiagnosisCodes,
        selectedMedications,
        showDeleteProtocolModal,
        showDeleteTriggerModal,
    } = state;
    //#endregion

    //#region Actions
    const {
        addTrigger,
        deleteTrigger,
        getAvailableRuleAssessmentsSuccess,
        reset,
        setInitialData,
        toggleDeleteProtocolModal,
        toggleDeleteTriggerModal,
    } = actions;
    //#endregion

    //#region Click/Action/Event Handlers
    const goToLandingPage = () => history.push('/protocolManager');

    const deleteTriggerHandler = R.curry((triggerId, event) => {
        event.preventDefault();

        // the passed in function will be the delete function in the modal
        toggleDeleteTriggerModal(() => deleteTrigger(triggerId));
    });

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-shadow
    const copyHandler = R.curry((protocol, event) => {
        event.preventDefault();
        send(MACHINE_EVENTS.SAVE);
        ProtocolService.copyProtocol(protocol)
            .then(goToLandingPage)
            .catch((error) => {
                toast.error(error.message);
            });
    });

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-shadow
    const deleteProtocolHandler = R.curry((protocol, event) => {
        event.preventDefault();

        // create delete Confirm function
        toggleDeleteProtocolModal(protocol);
    });

    const saveHandler = (values, formikBag) => {
        send(MACHINE_EVENTS.SAVE);

        Promise.resolve()
            // eslint-disable-next-line consistent-return
            .then(() => {
                if (hasLength(addedTriggers)) {
                    return Promise.all(
                        R.map(({ remoteTrigger: rule }) => {
                            // eslint-disable-next-line consistent-return
                            return ProtocolService.createTrigger(protocolId, rule).then(({ id: triggerId }) => {
                                if (hasLength(R.path(['profileTrigger', 'profileFilters'], rule))) {
                                    return ProtocolService.createProfileFilters(
                                        protocolId,
                                        triggerId,
                                        rule.profileTrigger.profileFilters
                                    );
                                }

                                if (hasLength(R.path(['activityTrigger', 'activityFilters'], rule))) {
                                    return ProtocolService.createAssessmentFilters(
                                        protocolId,
                                        triggerId,
                                        rule.activityTrigger.activityFilters
                                    );
                                }
                            });
                        }, addedTriggers)
                    );
                }
            })
            // eslint-disable-next-line consistent-return
            .then(() => {
                if (hasLength(deletedTriggers)) {
                    return ProtocolService.deleteTriggers(
                        protocolId,
                        R.map(R.prop('policyAssessmentScheduleId'), deletedTriggers)
                    );
                }
            })
            .then(() => {
                const drugAny = R.includes(allMedicationsOption)(values.medications) ? 1 : 0;

                const diagnosisAny = R.ifElse(
                    hasLength,
                    R.ifElse(R.includes(allDiagnosisOption), R.always(1), R.always(0)),
                    R.always(0)
                )(values.diagnosisCodes);

                return ProtocolService.updateProtocol({
                    id: protocolId,
                    name: values.name,
                    drugAny,
                    medications: drugAny
                        ? []
                        : R.compose(R.map(R.prop('id')), R.without([allMedicationsOption]))(values.medications),
                    diagnosisAny,
                    diagnoses: diagnosisAny
                        ? []
                        : R.compose(
                              R.map(R.prop('code')),
                              R.without([allDiagnosisOption])
                          )(values.diagnosisCodes || []),
                    enabled: 1,
                });
            })
            .then((savedProtocol) =>
                ProtocolService.getHydratedProtocol(protocolId).then(R.assoc('protocol', savedProtocol))
            )
            .then(setInitialData)
            .then(() => {
                formikBag.setSubmitting(false);
                send(MACHINE_EVENTS.SAVE_COMPLETE);
                toast.success('Protocol saved successfully.');
            })
            .catch((error) => {
                formikBag.setSubmitting(false);
                send(MACHINE_EVENTS.SAVE_COMPLETE);
                errorHandler(error);
            });
    };

    const addRule = (rule) => {
        R.compose(addTrigger, R.assocPath(['remoteTrigger', 'therapyPolicyId'], protocolId))(rule);

        send(MACHINE_EVENTS.ADD_RULE_COMPLETE);
    };
    //#endregion

    //#region Side Effects
    useEffect(() => {
        if (protocolId) {
            ProtocolService.getHydratedProtocol(protocolId)
                .then(setInitialData)
                .then(() => send(MACHINE_EVENTS.EDIT))
                .catch((error) => {
                    toast.error(error.message);
                    /* eslint-disable-next-line no-console */
                    console.error(error);
                });
        } else {
            reset();
        }
    }, [protocolId, reset, send, setInitialData]);

    useEffect(() => {
        if (protocol && !isReadOnly) {
            const promises = [
                AssessmentService.getTherapyAssessments(protocol.therapyId, state.showRetiredAssessments),
                AssessmentService.getGeneralTherapyAssessments(),
            ];
            if (showReferralAssessment) {
                promises.push(
                    getReferralTypes().then((res) => {
                        let filteredReferralRenewals;
                        if (!isRenewalsEnabled) {
                            filteredReferralRenewals = R.reject(
                                R.anyPass([R.propEq(4, 'referralTypeEnum'), R.propEq(5, 'referralTypeEnum')]),
                                res
                            );
                        }
                        return filteredReferralRenewals || res;
                    })
                );
            }
            Promise.all(promises).then(getAvailableRuleAssessmentsSuccess);
        }
    }, [
        protocol,
        isReadOnly,
        getAvailableRuleAssessmentsSuccess,
        showReferralAssessment,
        state.showRetiredAssessments,
        isRenewalsEnabled,
    ]);

    //#endregion

    //#region Runtime Calculations
    const tableColumns = useMemo(() => {
        const columns = [
            {
                Header: 'Event',
                style: {
                    width: '20%',
                },
                SubHeader: 'The Action that will initiate this rule',
                accessor: 'event',
                id: 'event',
                Filter: SearchColumnFilter,
            },
            {
                Header: 'Activity Triggered',
                style: {
                    width: '20%',
                },
                SubHeader: 'The activity that will be triggered',
                accessor: 'activityTriggered',
                id: 'activityTriggered',
                Filter: SearchColumnFilter,
            },
            {
                style: {
                    width: '30%',
                },
                Header: 'Conditions',
                SubHeader: 'Criteria that triggers the new activity',
                accessor: 'conditions',
                id: 'conditions',
                Filter: SearchColumnFilter,
                Cell: ({ value, row }) => {
                    const filterKey = R.cond([
                        [R.propEq(TRIGGER_TYPES.REFERRAL_STATUS, 'type'), R.always('filter')],
                        [R.propEq(TRIGGER_TYPES.INSURANCE, 'type'), R.always('filter')],
                        [
                            R.anyPass(
                                R.compose(
                                    R.map(R.compose(R.propEq(R.__, 'referralActivityType'), Number)),
                                    R.keys
                                )(REFERRAL_TYPES)
                            ),
                            R.always('filter'),
                        ],
                        [R.propEq(TRIGGER_TYPES.PROFILE, 'type'), R.always('profileFilters')],
                        [R.T, R.always('activityFilters')],
                    ])(row.original);

                    const filters = R.path(['original', filterKey], row);

                    if (hasLength(filters)) {
                        return R.compose(
                            conditionRenderer,
                            R.map((filter) => {
                                const hidden = !!R.pathOr(
                                    false,
                                    ['questions', filter.questionId, 'attributes', 'hidden'],
                                    row.original
                                );
                                return R.assoc('hidden', hidden, filter);
                            })
                        )(filters);
                    }

                    if (value) {
                        return value;
                    }

                    return '--';
                },
            },
            {
                style: {
                    width: '20%',
                },
                Header: 'Due Date Configuration',
                SubHeader: 'Determines the new Activity Due Date',
                accessor: 'dueDate',
                id: 'dueDate',
                Filter: SearchColumnFilter,
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row }) => {
                    const isHidden = R.pathOr(
                        false,
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/prop-types
                        ['questions', row.original.dateFieldId, 'attributes', 'hidden'],
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/prop-types
                        row.original
                    )
                        ? '(HIDDEN)'
                        : '';
                    return (
                        <>
                            {/* TODO: Fix this the next time the file is edited. */}
                            {/* eslint-disable-next-line react/prop-types */}
                            <span>{`${row.original.dueDateValues.days} `}</span>
                            <strong>
                                {`${isHidden} `}
                                {/* TODO: Fix this the next time the file is edited. */}
                                {/* eslint-disable-next-line react/prop-types */}
                                {row.original.dueDateValues.field}
                            </strong>
                        </>
                    );
                },
            },
        ];

        if (!isReadOnly) {
            return [
                ...columns,
                {
                    Header: 'Action',
                    id: 'action',
                    style: {
                        width: '10%',
                    },
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                    Cell: ({ row }) => {
                        return (
                            <ActionCell style={{ minWidth: '9rem' }}>
                                {/* TODO: Fix this the next time the file is edited. */}
                                {/* eslint-disable-next-line react/prop-types */}
                                <ActionButtonLink bsStyle="link" onClick={deleteTriggerHandler(row.original.id)}>
                                    Delete
                                </ActionButtonLink>
                            </ActionCell>
                        );
                    },
                },
            ];
        }
        return columns;
    }, [deleteTriggerHandler, isReadOnly]);

    const combinedTriggers = useMemo(() => {
        return R.concat(protocolTriggers, addedTriggers);
    }, [protocolTriggers, addedTriggers]);

    const isSaveDisabled = (formik) => {
        if (formik.isSubmitting) return true;

        if (hasLength(deletedTriggers) || hasLength(addedTriggers)) {
            return false;
        }

        const isClean = !formik.dirty;
        const isInvalid = !formik.isValid;

        const arr = [
            isClean,
            R.anyPass([R.equals(MACHINE_STATES.SAVING), R.equals(MACHINE_STATES.LOADING)])(machineState.value),
            isReadOnly,
            isInvalid,
        ];

        return R.reduce((result, value) => result || value, false, arr);
    };
    //#endregion

    return (
        <>
            <Helmet>
                <title>
                    {isReadOnly ? 'View' : 'Edit'} Protocol - {env.catchPhrase}
                </title>
            </Helmet>
            <PageTitle>{isReadOnly ? 'View' : 'Edit'} Protocol</PageTitle>

            <PageWrapper>
                <Formik
                    key={machineState.value === MACHINE_STATES.LOADING}
                    initialValues={{
                        name: R.prop('name', protocol),
                        medications: selectedMedications,
                        diagnosisCodes: selectedDiagnosisCodes,
                    }}
                    onSubmit={saveHandler}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .max(75, 'Name must be less than 75 characters.')
                            .required('A name is required'),
                        medications: Yup.array().required('A medication is required'),
                    })}
                >
                    {(formik) => (
                        <form data-testid="edit-protocol-form" onSubmit={formik.handleSubmit}>
                            <Collapse defaultShow collapsedTitle={`${protocol?.therapy?.name}: ${protocol?.name}`}>
                                <ProtocolBaseForm
                                    category={R.ifElse(R.isNil, R.always(null), (p) =>
                                        R.mergeWith(
                                            R.assoc,
                                            {
                                                id: R.prop('therapyId', p),
                                                name: R.path(['therapy', 'name'], p),
                                            },
                                            {}
                                        )
                                    )(protocol)}
                                    isFormLoading={R.anyPass([R.equals(MACHINE_STATES.LOADING)])(machineState.value)}
                                    isDisabled={
                                        formik.isSubmitting ||
                                        isReadOnly ||
                                        R.anyPass([
                                            R.equals(MACHINE_STATES.LOADING),
                                            R.equals(MACHINE_STATES.SAVING),
                                            R.equals(MACHINE_STATES.ADDING_RULE),
                                        ])(machineState.value)
                                    }
                                    medicationOptions={medicationOptions}
                                    diagnosisCodeOptions={diagnosisCodeOptions}
                                />
                            </Collapse>

                            {R.equals(MACHINE_STATES.ADDING_RULE, machineState.value) ||
                                (!isReadOnly && (
                                    <Row>
                                        <Col md={12}>
                                            <Button
                                                bsStyle="link"
                                                className="pull-right"
                                                type="button"
                                                disabled={
                                                    !R.allPass([
                                                        R.pathOr(false, ['therapyId']),
                                                        R.pathOr(false, ['therapy', 'name']),
                                                    ])(protocol) ||
                                                    R.anyPass([
                                                        R.equals(MACHINE_STATES.LOADING),
                                                        R.equals(MACHINE_STATES.SAVING),
                                                    ])(machineState.value) ||
                                                    formik.isSubmitting
                                                }
                                                onClick={() => send(MACHINE_EVENTS.ADD_RULE)}
                                            >
                                                <FaPlus /> Add Rule
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}

                            {!R.equals(MACHINE_STATES.ADDING_RULE, machineState.value) && (
                                <Row>
                                    <Col md={12} data-testid="rules-table">
                                        <Table
                                            key={isReadOnly}
                                            columns={tableColumns}
                                            data={combinedTriggers}
                                            stateReducer={tableStateReducer}
                                            isLoading={R.anyPass([
                                                R.equals(MACHINE_STATES.LOADING),
                                                R.equals(MACHINE_STATES.SAVING),
                                            ])(machineState.value)}
                                            placeholderText="All rules for this protocol will appear here."
                                        />
                                    </Col>
                                </Row>
                            )}

                            {R.equals(MACHINE_STATES.ADDING_RULE, machineState.value) ? null : (
                                <Row>
                                    <Col md={12}>
                                        <div className="form-button-group">
                                            {shouldShowProtocolDelete({ hasDeleteTherigyProtocol, isReadOnly }) && (
                                                <Button
                                                    bsStyle="danger"
                                                    type="button"
                                                    aria-label="Delete Protocol"
                                                    disabled={isProtocolDeleteDisabled({
                                                        machineValue: machineState.value,
                                                        isSubmitting: formik.isSubmitting,
                                                        isReadOnly,
                                                        hasDeleteTherigyProtocol,
                                                    })}
                                                    onClick={deleteProtocolHandler(protocol)}
                                                >
                                                    Delete
                                                </Button>
                                            )}
                                            <Button
                                                type="button"
                                                aria-label="Copy Protocol"
                                                disabled={R.anyPass([
                                                    R.equals(MACHINE_STATES.LOADING),
                                                    R.equals(MACHINE_STATES.SAVING),
                                                    R.always(formik.isSubmitting),
                                                ])(machineState.value)}
                                                onClick={copyHandler(protocol)}
                                            >
                                                Copy
                                            </Button>

                                            <Button
                                                onClick={goToLandingPage}
                                                aria-label="Back to protocol page"
                                                type="button"
                                                disabled={
                                                    R.anyPass([
                                                        R.equals(MACHINE_STATES.LOADING),
                                                        R.equals(MACHINE_STATES.SAVING),
                                                    ])(machineState.value) || formik.isSubmitting
                                                }
                                            >
                                                {isReadOnly ? 'Go Back' : 'Cancel'}
                                            </Button>

                                            {!isReadOnly && (
                                                <Button
                                                    bsStyle="primary"
                                                    type="submit"
                                                    aria-label="Save"
                                                    disabled={isSaveDisabled(formik)}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </form>
                    )}
                </Formik>

                {R.equals(MACHINE_STATES.ADDING_RULE, machineState.value) && (
                    <AddRule onCancel={() => send(MACHINE_EVENTS.CANCEL)} onAddRule={addRule} />
                )}
            </PageWrapper>

            {showDeleteTriggerModal && (
                <DeleteModal
                    onHide={() => toggleDeleteTriggerModal()}
                    show
                    onDelete={deleteTriggerAdHocFn}
                    title="Delete Rule"
                    message="Are you sure you want to delete this rule?"
                />
            )}

            {showDeleteProtocolModal && (
                <DeleteModal
                    onHide={() => toggleDeleteProtocolModal()}
                    show
                    onDelete={() => {
                        ProtocolService.deleteProtocol(protocolToDelete)
                            .then(goToLandingPage)
                            .catch((error) => {
                                toast.error(error.message);
                                toggleDeleteProtocolModal();
                            });
                    }}
                    title="Delete Protocol"
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/no-unstable-nested-components
                    message={({ style }) => {
                        return (
                            <>
                                {!R.path(['companyId'], protocolToDelete) && (
                                    <Alert bsStyle="warning">
                                        Warning! You are about to delete a Therigy-owned protocol.
                                    </Alert>
                                )}
                                <p style={style}>Are you sure you want to delete this protocol?</p>
                            </>
                        );
                    }}
                />
            )}
        </>
    );
};

EditProtocolPage.propTypes = propTypes;
EditProtocolPage.defaultProps = defaultProps;

export default EditProtocolPage;
