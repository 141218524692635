import PESService from '../../../services/PESService';
import * as R from 'ramda';
import isExpired from '../../../utils/isExpired/isExpired';
import { setPatientIdentifiers } from 'App/utils/setPatientIdentifiers';
import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted';
import RxFillNumberSideBar from 'App/components/PatientPrescription/RxFillNumberSideBar';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'Lib/queryClient';
import {
    PatientPredictiveModelRiskIndicator,
    PatientPredictiveModelRiskText,
} from 'App/components/PatientPredictiveModelRisk';
import mountReactComponent from 'App/utils/mountReactComponent';

// TODO: Replace patientService with pesService once the getMedications route exists in PES.
/** @ngInject */
function PatientCardController(_, moment, $location, $q, $uibModal, patientService, pesService) {
    const ctrl = this;
    ctrl.formatPhone = formatPhone;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.hasNewRxFillNumberEnabled = isPermitted(PERMISSION_TYPES.ENABLE_RX_FILL_NUMBERS, ctrl.user);
    ctrl.patientPrescriptions = {};
    ctrl.isNewRxFillLoading = false;

    async function $onChanges(changes) {
        ctrl.patientPredictiveModelEnabled = isPermitted(PERMISSION_TYPES.PATIENT_PREDICTIVE_MODEL, ctrl.user);
        if (changes.patient && ctrl.patient && ctrl.patient.id) {
            ctrl.patient = angular.copy(ctrl.patient);
            ctrl.patient.noKnownAllergies = !!Number(ctrl.patient.noKnownAllergies);
            ctrl.address = [ctrl.patient.addressLine1, ctrl.patient.addressLine2].join(' ').trim();

            try {
                await setPatientIdentifiers(ctrl);
            } catch (err) {
                ctrl.displayedId = '';
            }

            pesService.getAllergy(ctrl.patient.id).then((res) => {
                ctrl.patient.allergies = res;
            });

            patientService.getMedications(ctrl.patient.id, 'all').then((res) => {
                ctrl.patient.medications = R.compose(
                    R.groupBy(R.path(['medication', 'name'])),

                    R.filter((entry) => {
                        const statusDate = R.path(['status', 'end'], entry);

                        if (statusDate) {
                            return !isExpired(statusDate);
                        }
                        return true;
                    }),
                    R.values
                )(res);
                ctrl.patientMedicationsArray = _.values(res);
                ctrl.patientMedicationsArray.forEach((patientMedication) => {
                    mountIt(patientMedication.medication);
                });
            });

            pesService.getOtherCondition(ctrl.patient.id).then((res) => {
                ctrl.patient.otherConditions = _.values(res);
            });

            pesService.getOtherMedication(ctrl.patient.id).then((res) => {
                ctrl.patient.otherMedications = _.values(res);
            });

            pesService.getPrescriptions(ctrl.patient.id).then((res) => {
                ctrl.patient.prescriptions = _.groupBy(res, 'medication.id');
            });

            pesService
                .getTherapies(ctrl.patient.id)
                .then((res) => {
                    ctrl.patient.therapies = res;

                    const promises = [];
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-restricted-syntax
                    for (const id in ctrl.patient.therapies) {
                        if (Object.prototype.hasOwnProperty.call(ctrl.patient.therapies, id)) {
                            const therapyId = ctrl.patient.therapies[id].therapy.id;
                            promises.push(pesService.getICD10(ctrl.patient.id, therapyId));
                        }
                    }
                    return $q.all(promises);
                })
                .then((res) => {
                    ctrl.patient.icd10s = _.remove(res, (r) => {
                        return r !== '';
                    });
                });

            PESService.getPatientLabels(ctrl.patient.id).then((res) => {
                ctrl.patientLabels = Object.values(res);
            });
            if (ctrl.patientPredictiveModelEnabled) {
                PESService.getPatientPredictiveModelRisk(ctrl.patient.id).then((riskResponse) => {
                    ctrl.patient.predictiveModel = riskResponse || [];
                    mountPredictiveModelComponent();
                });
            }

            ctrl.patient.homePhone = formatPhone(ctrl.patient.homePhone);
            ctrl.patient.mobilePhone = formatPhone(ctrl.patient.mobilePhone);
            ctrl.patient.workPhone = formatPhone(ctrl.patient.workPhone);
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    /**
     * Mount react
     */
    function mountIt(medication) {
        const interval = setInterval(() => {
            if (medication) {
                const dom = document.getElementById(`rx-fill-number-list-${medication.id}`);
                if (dom) {
                    ReactDOM.render(
                        <QueryClientProvider client={queryClient}>
                            <RxFillNumberSideBar patientId={ctrl.patient.id} medication={medication} />
                        </QueryClientProvider>,
                        dom
                    );
                    clearInterval(interval);
                }
            }
        }, 100);
    }
    mountPredictiveModelComponent();
    function mountPredictiveModelComponent() {
        if (!ctrl.patientPredictiveModelEnabled) return;
        mountReactComponent(
            `#predictive-risk-${ctrl.patient.id}`,
            <PatientPredictiveModelRiskIndicator riskSegments={ctrl.patient.predictiveModel} />
        );
        mountReactComponent(
            `#predictive-risk-reason-${ctrl.patient.id}`,
            <PatientPredictiveModelRiskText riskSegments={ctrl.patient.predictiveModel} />
        );
    }

    function formatPhone(str) {
        const cleaned = `${str}`.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return str;
    }

    function $onInit() {
        ctrl.redirectUrl = $location.absUrl().split('?')[0];
    }
}

export default PatientCardController;
