export const STATUS_TYPE = {
    pending: 'Pending',
    notRequired: 'Not Required',
    approved: 'Approved',
    deleted: 'Deleted',
    denied: 'Denied',
    appealed: 'Appealed',
    appealedOverturned: 'Appealed - Overturned',
    appealedUpheld: 'Appealed - Upheld',
};

export const BOOLEAN_TYPE = {
    yes: 'Yes',
    no: 'No',
};

export const RE_VERIFICATION_OPTIONS_TYPE = {
    daysPrior: '14 Days Prior to PA Expiration',
    specificDate: 'Specific Date',
};

export const disableActivityStatusList = [
    STATUS_TYPE.approved,
    STATUS_TYPE.notRequired,
    STATUS_TYPE.denied,
    STATUS_TYPE.appealed,
    STATUS_TYPE.appealedOverturned,
    STATUS_TYPE.appealedUpheld,
];

export const booleanOptions = [BOOLEAN_TYPE.no, BOOLEAN_TYPE.yes];

export const reVerificationScheduleOption = [
    RE_VERIFICATION_OPTIONS_TYPE.daysPrior,
    RE_VERIFICATION_OPTIONS_TYPE.specificDate,
];

export const denialReasonOptions = [
    'Drug criteria not met',
    'Insufficient information submitted',
    'Required step edits',
    'Quantity limits',
    'No coverage policy',
];

export const approvalOptions = [
    'Pharmacy claim',
    'Physician notification',
    'Patient notification',
    'Payor notification',
];
