import angular from 'angular';

import AssessmentPublisherController from './assessment-publisher.controller';
import AssessmentPublisherConfig from './assessment-publisher.config';

export default angular
    .module('components.assessmentPublisher', [])
    .component('assessmentPublisher', {
        bindings: {
            user: '<',
        },
        controller: AssessmentPublisherController,
        template: require('./assessment-publisher.html'),
    })
    .config(AssessmentPublisherConfig).name;
