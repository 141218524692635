/** @ngInject */
function AssessmentQuestionController($, $scope) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doShowContent = doShowContent;
    ctrl.getNumberOfAnswers = getNumberOfAnswers;
    ctrl.onChangeAnswer = onChangeAnswer;

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
        }

        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
            setQuestionText(ctrl.question);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);
            if (ctrl.question.name === 'need_by_date') {
                ctrl.question.type = 'need_by_date';
            } else if (ctrl.question.name === 'activity_date') {
                ctrl.question.type = 'activity_date';
            }
            ctrl.question.id = ctrl.question.officialQuestionId
                ? ctrl.question.officialQuestionId
                : ctrl.question.questionId;
            setQuestionText(ctrl.question);
        }
    }

    function doShowContent(label) {
        $scope.$emit('openAssessmentContentModal', label);
    }

    function getNumberOfAnswers(questionId) {
        let numberOfAnswers = 0;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        numberOfAnswers += $(`#${questionId} .assessment-question-children input`).filter(function () {
            const type = $(this).attr('type');
            if (type === 'radio' || type === 'checkbox') {
                return $(this).is(':checked');
            }
            return $(this).val() !== '' && $(this).val() !== undefined && $(this).val() !== $(this).attr('placeholder');
        }).length;
        numberOfAnswers += $(`#${questionId} .assessment-question-children .ui-select-match-text`).filter(
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line func-names
            function () {
                return $(this).html() !== '';
            }
        ).length;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        numberOfAnswers += $(`#${questionId} .assessment-question-children textarea`).filter(function () {
            return $(this).val() !== '' && $(this).val() !== undefined;
        }).length;
        return numberOfAnswers;
    }

    function onChangeAnswer(answer, unselectedAnswers) {
        if (ctrl.question && ctrl.question.type !== 'activity_date') {
            $scope.$emit('setActivityDate', 'Attempt to Set Date Automatically');
        }

        if (ctrl.question && ctrl.question.mobileUserResponse) {
            ctrl.question.mobileUserResponse = false;
        }

        $scope.$emit('saveAssessmentQuestionResponse', {
            unselectedAnswers,
            response: {
                response: answer,
            },
            question: ctrl.question,
        });
    }

    function setQuestionText(question) {
        if (question) {
            const compactQuestion = question.compactQuestion || question.question;
            ctrl.question.questionDisplay = ctrl.fullyScriptedMode ? question.question : compactQuestion;
        }
    }
}

export default AssessmentQuestionController;
