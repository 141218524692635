import angular from 'angular';

import EngageController from './engage.controller';
import EngageOptInModalController from './opt-in/engage-opt-in-modal.controller';
import EngageEducationContentOptInModalController from './opt-in/patient-education-enroll-modal/patient-education-enroll-modal.controller';
import EngageMessagingOptInModalController from './opt-in/patient-messaging-enroll-modal/patient-messaging-enroll-modal.controller';
import EngageMessagingOptOutModalController from './opt-in/patient-messaging-enroll-modal/patient-messaging-unenroll-modal.controller';

import EngageProductionSelectionModalController from './opt-in/product-selection-modal/engage-production-selection-modal.controller';
import PatientMessagingHistoryController from './patient-messaging-history-modal/patient-messaging-history-modal.controller';
import PatientMessageViewController from './patient-messaging-history-modal/patient-message-view-modal.controller';
import VideoModalController from './videos/engage-video-modal.controller';
import SelectOneTimeMessageController from './one-time-messaging-model/select-one-time-message-model.controller';
import SendOneTimeMessageController from './one-time-messaging-model/send-one-time-message-model.controller';

export default angular
    .module('components.engage', ['ui.bootstrap'])
    .component('engage', {
        bindings: {
            user: '<',
            patient: '<',
        },
        controller: EngageController,
        template: require('./engage.html'),
    })
    .component('engageOptInModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: EngageOptInModalController,
        template: require('./opt-in/engage-opt-in-modal.html'),
    })
    .component('engageMessagingOptOutModal', {
        bindings: {
            close: '<',

            resolve: '<',
            onChangeMode: '&',
            subscription: '<',
            patient: '<',
            user: '<',
        },
        controller: EngageMessagingOptOutModalController,
        template: require('./opt-in/patient-messaging-enroll-modal/patient-messaging-unenroll-modal.html'),
    })
    .component('engageEducationContentOptInModal', {
        bindings: {
            close: '<',
            onChangeMode: '&',
            subscription: '<',
            patient: '<',
        },
        controller: EngageEducationContentOptInModalController,
        template: require('./opt-in/patient-education-enroll-modal/patient-education-enroll-modal.html'),
    })
    .component('engageMessagingOptInModal', {
        bindings: {
            close: '<',
            onChangeMode: '&',
            subscription: '<',
            patient: '<',
        },
        controller: EngageMessagingOptInModalController,
        template: require('./opt-in/patient-messaging-enroll-modal/patient-messaging-enroll-modal.html'),
    })

    .component('engageProductionSelectionModal', {
        bindings: {
            close: '<',
            onChangeMode: '&',
            patientEducationSubscription: '<',
            patientMessagingSubscription: '<',
            user: '<',
        },
        controller: EngageProductionSelectionModalController,
        template: require('./opt-in/product-selection-modal/engage-product-selection-modal.html'),
    })
    .component('patientMessagingHistoryModal', {
        bindings: {
            close: '&',
            resolve: '<',
        },
        controller: PatientMessagingHistoryController,
        template: require('./patient-messaging-history-modal/patient-messaging-history-modal.html'),
    })
    .component('patientmessageViewModal', {
        bindings: {
            close: '&',
            resolve: '<',
        },
        controller: PatientMessageViewController,
        template: require('./patient-messaging-history-modal/patient-message-view-modal.html'),
    })
    .component('engageVideoModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: VideoModalController,
        template: require('./videos/engage-video-modal.html'),
    })
    .component('selectOneTimeMessageModel', {
        bindings: {
            close: '&',
            resolve: '<',
        },
        controller: SelectOneTimeMessageController,
        template: require('./one-time-messaging-model/select-one-time-message-model.html'),
    })
    .component('sendOneTimeMessageModel', {
        bindings: {
            close: '&',
            resolve: '<',
        },
        controller: SendOneTimeMessageController,
        template: require('./one-time-messaging-model/send-one-time-message-model.html'),
    }).name;
