/**
 * This service handles requests for customized assessment data
 */
/** @ngInject */
function CustomAssessmentService($http, urlBuilderService) {
    return {
        query,
    };

    function query() {
        const url = urlBuilderService.build('assessment', {
            assessment: '',
            customized: '',
        });

        return $http.get(url).then((res) => {
            return res.data;
        });
    }
}
export default CustomAssessmentService;
