import PESService from '../services/PESService';

/** @ngInject */
function CommonController(_, $location, $rootScope, $scope, __env) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.copyrightYear = new Date().getFullYear();
    ctrl.privacyWebsite = __env.privacyWebsite;
    ctrl.supportWebsite = __env.supportWebsite;

    $scope.$on('updateDisabledFields', (event, data) => {
        ctrl.disabledFields = angular.copy(data.disabledFields);
    });

    const cleanUpUpdateUser = $rootScope.$on('updateUser', (event, user) => {
        ctrl.user = angular.copy(user);
    });

    $scope.$on('$destroy', () => {
        cleanUpUpdateUser();
    });

    /**
     * @summary Initializes the component
     */
    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            PESService.getDisabledFields(ctrl.user.active_company.ID).then((res) => {
                ctrl.disabledFields = angular.copy(Object.values(res).map((record) => record.field));
                return res;
            });
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        $rootScope.page = 'app';
        ctrl.showNotice = false;
    }
}

export default CommonController;
