import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'react-bootstrap';

import asFormField from './asFormField';

const CheckboxMultiInput = ({
    name,
    value,
    onChange,
    onBlur,
    className,
    style,

    options,
    disabled,
}) => {
    const handleChange = (event) => {
        const selectedValue = event.target.value;
        const newVal = value.includes(selectedValue)
            ? value.filter((v) => v !== selectedValue)
            : [...value, selectedValue];
        onChange(newVal);
    };

    return (
        <div className={className} style={style}>
            {options.map((opt) => (
                <Checkbox
                    key={opt.id}
                    name={name}
                    value={opt.id}
                    checked={value.includes(opt.id)}
                    onChange={handleChange}
                    onBlur={onBlur}
                    disabled={disabled}
                >
                    {opt.option}
                </Checkbox>
            ))}
        </div>
    );
};

CheckboxMultiInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            option: PropTypes.node,
        })
    ).isRequired,
    disabled: PropTypes.bool,
};

CheckboxMultiInput.defaultProps = {
    value: [],
    className: '',
    style: null,

    disabled: false,
};

export const CheckboxMultiField = asFormField({
    WrappedComponent: CheckboxMultiInput,
});
