import { useEffect } from 'react';
import PESService from 'App/services/PESService';
import { getReferrals } from 'App/services/RMSService';
import { errorHandler } from 'App/utils';
import { formatActivities, formatReferrals } from 'App/components/PatientProfile/PatientProfile.utils';
import useProfileDataState from './useProfileDataState';

/**
 * Custom hook which make all the http call to fetch activities required to render patient detail page
 * @param {string} patientId
 * @returns {Object}
 */
function useGetPatientActivities(patientId) {
    const {
        data: activities,
        setData: setActivities,
        isLoading: isActivityLoading,
        triggerRefetch: triggerActivityRefetch,
        refetch,
        setIsLoading,
    } = useProfileDataState([]);

    useEffect(() => {
        const promiseArray = [PESService.getActivities(patientId), getReferrals(patientId)];

        setIsLoading(true);
        Promise.all(promiseArray)
            .then(([activityResult, referralResult]) => {
                const referralChain = formatReferrals(referralResult);
                const activityList = formatActivities(Object.values(activityResult));
                for (const referral of referralChain) {
                    activityList.push(...referral.activities);
                }
                setActivities(activityList);
            })
            .catch(errorHandler)
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, refetch]);

    return {
        activities,
        isActivityLoading,
        triggerActivityRefetch,
    };
}

export default useGetPatientActivities;
