import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import FlexContainer from 'App/common/styles/FlexContainer';
import IconWrapper from 'App/common/styles/IconWrapper';
import useGetReferralActivities from './useGetReferralActivities';
import Table from '../Table/Table';
import propOrDash from 'App/utils/propOrDash';
import dateOrDashUtc from 'App/utils/dateOrDashUtc';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import { localize } from 'App/utils';
import { generateReferralLink } from 'App/components/PatientProfile/PatientProfile.utils.js';
import { NoteStatusDateCell } from 'App/components/PatientProfile/components/PatientActivities/components/ActivitiesColumns';
import { ActivityTypes } from 'App/components/PatientProfile/components/PatientActivities/PatientActivities';
import ActivityNotesModal from '../PatientProfile/components/PatientActivities/components/ActivityNotesModal';
import EditActivityDueDateModal from '../PatientProfile/components/PatientActivities/components/EditActivityDueDateModal';
import AddReferralActivity from '../AddReferralActivity/AddReferralActivity';
import TherigyInfoMessage from '../TherigyInfoMessage/TherigyInfoMessage';

const propTypes = {
    patientId: PropTypes.string.isRequired,
    referralId: PropTypes.string.isRequired,
};

const defaultProps = {};

const NameCell = ({ value, row }) => {
    const params = {
        patientId: row.original.patientId,
        referralId: row.original.referralId,
        activityId: row.original.id,
        typeId: row.original.type,
    };
    return (
        <Link id={`referral-activity-${row.id}`} to={generateReferralLink(params)}>
            {value}
        </Link>
    );
};

const nameCellPropTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.shape({
        original: PropTypes.shape({
            patientId: PropTypes.string,
            referralId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            id: PropTypes.number,
            type: PropTypes.number,
        }),
        id: PropTypes.string,
    }).isRequired,
};

NameCell.propTypes = nameCellPropTypes;
NameCell.defaultProps = {};

const initialState = {
    sortBy: [
        {
            id: 'dueOn',
            desc: true,
        },
    ],
};

function ReferralActivityTable({ patientId, referralId }) {
    const { data, isLoading, refetch: refetchActivities } = useGetReferralActivities(patientId, referralId);
    const [currentActivity, setCurrentActivity] = useState(null);
    const [showAddActivityModal, setShowAddActivityModal] = useState(false);
    const [showDueDateModal, setShowDueDateModal] = useState(false);
    const [showActivityStatusModal, setShowActivityStatusModal] = useState(false);

    const hideAddActivityModal = (refetch) => {
        if (refetch) {
            refetchActivities();
        }
        setShowAddActivityModal(false);
    };

    const openActivityDueDateModal = (activity) => {
        setCurrentActivity({ ...activity, dateDue: activity.dueOn, typeLabel: 'Referral' });
        setShowDueDateModal(true);
    };

    const hideActivityDueDateModal = (refetch) => {
        if (refetch) {
            refetchActivities();
        }
        setShowDueDateModal(false);
        setCurrentActivity(null);
    };

    const openActivityNoteModal = (activity) => {
        setCurrentActivity({ ...activity, dateDue: activity.dueOn, typeLabel: 'Referral' });
        setShowActivityStatusModal(true);
    };

    const hideActivityNoteModal = (refetch) => {
        if (refetch) {
            refetchActivities();
        }
        setShowActivityStatusModal(false);
        setCurrentActivity(null);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Activity Name',
                accessor: propOrDash('name'),
                Cell: NameCell,
                id: 'name',
            },
            {
                Header: 'Status',
                accessor: propOrDash('status'),
                id: 'status',
            },
            {
                Header: 'Medications',
                accessor: 'medications',
                Cell: ({ value }) => value.map((it) => it?.medication?.name).join(', '),
                id: 'medications',
            },
            {
                Header: 'Due Date',
                disableFilters: true,
                accessor: dateOrDashUtc(['dueOn']),
                Cell: NoteStatusDateCell({
                    activityType: ActivityTypes.PendingActivities,
                    openModal: openActivityDueDateModal,
                }),
                id: 'dueOn',
            },
            {
                Header: 'Last Updated',
                disableFilters: true,
                accessor: R.ifElse(
                    R.prop('updatedOn'),
                    R.compose((date) => localize(date, 'YYYY-MM-DD hh:mm A'), R.prop('updatedOn')),
                    R.identity
                ),
            },
            {
                Header: 'Notes Status',
                disableFilters: true,
                accessor: (val) => R.pipe(R.pathOr([], ['notes']), R.last, R.propOr('--', 'status'))(val),
                Cell: NoteStatusDateCell({
                    activityType: ActivityTypes.PendingActivities,
                    openModal: openActivityNoteModal,
                }),
                id: 'noteStatus',
            },
        ],
        []
    );

    return (
        <div className="referral-activity">
            <FlexContainer>
                <h3>Activities</h3>
                <div className="add-create-action">
                    <Button bsStyle="link" onClick={() => setShowAddActivityModal(true)}>
                        <IconWrapper>
                            <FaPlus />
                        </IconWrapper>
                        Add Activity
                    </Button>
                </div>
            </FlexContainer>
            {data?.length > 0 && !isLoading ? (
                <Table initialState={initialState} columns={columns} data={data} />
            ) : (
                <TherigyInfoMessage message="There are no activities that match your current search criteria." />
            )}
            {showActivityStatusModal && (
                <div>
                    <ActivityNotesModal
                        activity={currentActivity}
                        patientId={patientId}
                        show={showActivityStatusModal}
                        onHide={hideActivityNoteModal}
                    />
                </div>
            )}
            {showDueDateModal && (
                <EditActivityDueDateModal
                    show={showDueDateModal}
                    onHide={hideActivityDueDateModal}
                    activity={currentActivity}
                />
            )}
            {showAddActivityModal && (
                <div>
                    <AddReferralActivity
                        show={showAddActivityModal}
                        patientId={patientId}
                        onHide={hideAddActivityModal}
                        activities={data}
                        referralId={referralId}
                    />
                </div>
            )}
        </div>
    );
}

ReferralActivityTable.propTypes = propTypes;
ReferralActivityTable.defaultProps = defaultProps;

export default ReferralActivityTable;
