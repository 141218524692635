import React from 'react';

import HelpIcon from './icons/HelpIcon';
import UserIcon from './icons/UserIcon';
import SessionTimer from './icons/SessionTimer';
import AlertsCenterHeader from 'App/components/AlertsCenter/AlertsCenterIcon';
import useFetchMessageCountQuery from 'App/hooks/useFetchMessageCountQuery';

const IconBar = () => {
    const { data } = useFetchMessageCountQuery();

    const count = data?.count || 0;
    return (
        <div id="icon-bar" className="icon-bar">
            <UserIcon />
            <AlertsCenterHeader />
            <a className="icon icon-envelope" href="/#!/viewMessages">
                <i className="fas fa-fw fa-envelope" />
                {count ? (
                    <span className="message-counter-box">
                        <span className="message-counter">{count}</span>
                    </span>
                ) : null}
            </a>
            <HelpIcon />
            <SessionTimer />
        </div>
    );
};

export default IconBar;
