import styled from '@emotion/styled';
import env from 'App/components/config/config';
import DeleteModal from 'App/components/DeleteModal';
import PageHeader from 'App/components/styles/PageHeader/PageHeader';
import PageTitle from 'App/components/styles/PageTitle/PageTitle';
import PageWrapper from 'App/components/styles/PageWrapper/PageWrapper';
import PlaceHolderMessage from 'App/components/styles/PlaceholderMessage/PlaceHolderMessage';
import SearchColumnFilter from 'App/components/Table/SearchColumnFilter';
import SelectColumnFilter from 'App/components/Table/SelectColumnFilter';
import Table from 'App/components/Table/Table';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';
import Alert from 'react-bootstrap/lib/Alert';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { Helmet } from 'react-helmet';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import actionCellRenderer from '../actionCellRenderer';
import { useProtocolManagerContext } from '../ProtocolManagerContext';
import { Events, stateMatchers, States } from './machine';

const tableStateReducer = (state, action, prevState) => {
    const initialSort = [{ id: 'name', desc: false }];

    const { type } = action;
    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
        default:
            return state;
    }
};

//#region Styles
const DisabledText = styled('span')`
    color: ${(props) => props.theme.brandDanger};
`;

const StatusValues = Object.freeze({
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
});

//#endregion

const propTypes = {
    // This is provided via React Router
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

const defaultProps = {};

function ProtocolManagerIndex({ match }) {
    //#region State
    const { state, send } = useProtocolManagerContext();

    const { value, context } = state;

    const { availableTherapies, category, protocols } = context;

    //#endregion

    //#region Click Handlers
    const toggleEnableHandler = R.curry((protocol, event) => {
        event.preventDefault();
        send({ type: Events.TOGGLE, protocol });
    });

    const copyHandler = R.curry((protocol, event) => {
        event.preventDefault();
        send({ type: Events.COPY, protocol });
    });

    const deleteHandler = R.curry((protocol, event) => {
        event.preventDefault();

        send({ type: Events.DELETE, protocol });
    });
    //#endregion

    //#region Side-effects
    /**
     * This is only necessary so that when this page is revisited after after clicking the name of a protocol or
     * similar, the table will correctly reload now that we are using a finite state machine and that machine is lifted
     * into the react context in ProtocolManagerContext. As such, we only ever want to do this once, which is why we do
     * not populate a dependency array. This may be removed if the other pages which copy, disable, and delete begin
     * using the service in that provided in that context, and can send similar events.
     */
    useEffect(() => {
        if (R.prop('id', category) && state.matches(States.PROTOCOLS_LOADED)) {
            send({
                type: Events.SELECT_THERAPY,
                therapy: category,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //#endregion

    //#region Column Setup
    const tableColumns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                id: 'name',
                Filter: SearchColumnFilter,
                // eslint-disable-next-line no-shadow, react/prop-types, react/no-unstable-nested-components
                Cell: ({ value, row }) => {
                    // eslint-disable-next-line react/prop-types
                    const { id } = row.original;
                    const linkTo = `protocolManager/protocol/${id}`;
                    return <Link to={linkTo}>{value}</Link>;
                },
            },
            {
                Header: 'Created By',
                Filter: SelectColumnFilter,
                // note: this field is derived and set in the reducer
                accessor: 'createdBy',
            },
            {
                Header: 'Status',
                // eslint-disable-next-line react/prop-types
                accessor: (row) => (row.isEnabled ? StatusValues.ENABLED : StatusValues.DISABLED),
                // eslint-disable-next-line no-shadow, react/prop-types, react/no-unstable-nested-components
                Cell: ({ value }) => {
                    return value === StatusValues.ENABLED ? <span>{value}</span> : <DisabledText>{value}</DisabledText>;
                },
                Filter: SelectColumnFilter,
            },
            {
                Header: 'Actions',
                Cell: actionCellRenderer({
                    copyHandler,
                    toggleEnableHandler,
                    deleteHandler,
                }),
            },
        ],
        [copyHandler, deleteHandler, toggleEnableHandler]
    );

    //#endregion

    return (
        <>
            <Helmet>
                <title>Protocol Manager - {env.catchPhrase}</title>
            </Helmet>

            <PageTitle>Protocol Manager</PageTitle>

            <PageWrapper>
                <PageHeader>
                    <FormGroup style={{ width: '250px' }}>
                        <ControlLabel htmlFor="therapeutic-category">Select a therapeutic category</ControlLabel>
                        <Select
                            options={availableTherapies}
                            value={category}
                            inputId="therapeutic-category"
                            isClearable
                            placeholder="Select"
                            getOptionValue={R.prop('id')}
                            getOptionLabel={R.prop('name')}
                            onChange={(option) => {
                                send({
                                    type: Events.SELECT_THERAPY,
                                    therapy: option,
                                });
                            }}
                            isLoading={state.matches(States.LOADING_THERAPIES)}
                        />
                    </FormGroup>

                    {category && (
                        <Link to={`${match.path}/protocol`} className="btn btn-primary">
                            <FaPlus /> Create Custom Protocol
                        </Link>
                    )}
                </PageHeader>

                {category ? (
                    <Table
                        columns={tableColumns}
                        data={R.values(protocols)}
                        stateReducer={tableStateReducer}
                        isLoading={stateMatchers.isLoading(value)}
                    />
                ) : (
                    <PlaceHolderMessage placeholderText="All protocols for the selected therapeutic category will appear here." />
                )}
            </PageWrapper>

            <DeleteModal
                show={state.matches(States.CONFIRM_DELETE)}
                onHide={() => send(Events.CANCEL)}
                onDelete={() => {
                    send({ type: Events.CONFIRM });
                }}
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/no-unstable-nested-components
                message={({ style }) => {
                    return (
                        <>
                            {state.matches(States.CONFIRM_DELETE) &&
                            !R.path(['companyId'], context.protocolToDelete) ? (
                                <Alert bsStyle="warning">
                                    Warning! You are about to delete a Therigy-owned protocol.
                                </Alert>
                            ) : null}
                            <p style={style}>Are you sure you want to delete this protocol?</p>
                        </>
                    );
                }}
                title="Delete Protocol"
            />
        </>
    );
}

ProtocolManagerIndex.propTypes = propTypes;
ProtocolManagerIndex.defaultProps = defaultProps;

export default ProtocolManagerIndex;
