/** @ngInject */
function ReferralAppealActivityConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.referralActivityAppeal',
        url: '/patient/:patientId/referral/:referralId/appeal/:activityId/type/:typeId',
        views: {
            '': 'referralActivityAppeal',
            'pageTitle@^': {
                template: 'Appeal Activity',
            },
        },
        resolve: {
            activity($transition$, rmsService) {
                const params = $transition$.params();
                return rmsService
                    .getActivityByType(params.patientId, params.referralId, params.activityId, params.typeId)
                    .catch(() => {
                        return false;
                    });
            },
            activities(_, $transition$, rmsService) {
                const params = $transition$.params();
                return rmsService
                    .getActivities(params.patientId, params.referralId, {
                        type: '1', // Prior Authorization
                    })
                    .then((res) => {
                        return _.remove(res, (a) => {
                            return a.status === 'Denied' || a.status.indexOf('Appealed') !== -1;
                        });
                    });
            },
            appealDocuments($transition$, rmsService) {
                const params = $transition$.params();
                return rmsService.getAppealDocuments(params.patientId, params.referralId, params.activityId);
            },
            patient($transition$, patientService) {
                const params = $transition$.params();
                return patientService.get(params.patientId).catch(() => {
                    return false;
                });
            },
        },
    });
}

export default ReferralAppealActivityConfig;
