import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { RadioField } from 'Lib/form/RadioField';
import { camelCase } from 'lodash';
import { generatePath, useHistory } from 'react-router-dom';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { workQueueRoute } from 'App/common/routeLookup';
import { dateRangeOptions } from '../../workQueue.constant';

const DateRangeFilter = () => {
    const history = useHistory();
    const { values } = useFormikContext();
    const {
        result: { activityCountResult },
        filterParams,
    } = values;

    const options = useMemo(() => {
        return dateRangeOptions.map((range) => {
            const activityCount = activityCountResult[camelCase(range.value)];
            return {
                id: range.value,
                option: `${range.name} ${activityCount >= 0 ? `(${activityCount})` : ''}`,
            };
        });
    }, [activityCountResult]);

    useEffect(() => {
        if (filterParams.type) {
            history.push(
                generatePath(workQueueRoute, {
                    dateFilter: filterParams.type,
                })
            );
        }
    }, [filterParams.type, history]);

    return (
        <>
            <div className="filter form-group">
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="control-label filter-header">Date Range</label>
                <RadioField fieldName="filterParams.type" options={options} />
            </div>
            {values.filterParams.type === 'custom' && (
                <>
                    <DatePickerField
                        fieldName="filterParams.dateStart"
                        label="Date Range Start"
                        placeholder="YYYY/MM/DD"
                    />
                    <DatePickerField fieldName="filterParams.dateEnd" label="Date Range End" placeholder="YYYY/MM/DD" />
                </>
            )}
        </>
    );
};

export default DateRangeFilter;
