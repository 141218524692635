import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useMemo, useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { v4 as uuid } from 'uuid';

const propTypes = {
    /**
     * `value`, `column`, and `row` are supplied by React Table
     */
    value: PropTypes.string.isRequired,
    column: PropTypes.shape({
        filterValue: PropTypes.string,
    }).isRequired,
    row: PropTypes.shape({
        /**
         * This will be the object that represents _your_ data in the table
         */
        original: PropTypes.shape({}).isRequired,
    }).isRequired,

    /**
     * Getter that will be passed the original row object and expects an array of strings. This array will be filtered
     * to find items that match the `column.filterValue` result, which will be used as the display value.
     * original => string[]
     */
    arrayGetter: PropTypes.func.isRequired,
    /**
     * The maximum character length for the cell before truncating.
     */
    maxLength: PropTypes.number,
};

const defaultProps = {
    maxLength: 400,
};

const StyledTooltip = styled(Tooltip)`
    .tooltip-inner.tooltip-inner {
        color: #0f181d;
        background-color: #e6e6e6;
        box-shadow: 0 6px 12px rgb(0 0 0 / 40%);
        text-align: left;
        max-width: 100%;
    }

    .tooltip-arrow.tooltip-arrow {
        border-right-color: #e6e6e6;
    }
`;

function MaxLengthTooltipCell({ value, column: { filterValue }, row: { original }, maxLength, arrayGetter }) {
    //#region State
    const tooltipId = useRef(uuid());
    //#endregion

    //#region RunTime Calculations
    const display = useMemo(() => {
        if (filterValue) {
            const lowerValue = R.toLower(filterValue);
            const match = R.filter((name) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                name = R.toLower(name);
                return name.includes(lowerValue);
            }, arrayGetter(original));

            const matchValue = R.join(', ', match);
            return (
                <>
                    {' ... '}
                    <strong>{matchValue}</strong>
                    {' ... '}
                </>
            );
        }

        return value.length > maxLength ? R.compose(R.concat(R.__, '...'), R.slice(0, maxLength))(value) : value;
    }, [arrayGetter, filterValue, maxLength, original, value]);

    const tooltip = useMemo(() => <StyledTooltip id={tooltipId.current}>{value}</StyledTooltip>, [value]);
    //#endregion

    return (
        <OverlayTrigger trigger={['hover', 'focus']} overlay={tooltip} rootClose delay={300}>
            <span>{display}</span>
        </OverlayTrigger>
    );
}

MaxLengthTooltipCell.propTypes = propTypes;
MaxLengthTooltipCell.defaultProps = defaultProps;

function maxLengthTooltipCellRenderer(maxLength, getter) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/display-name
    return (props) => React.createElement(MaxLengthTooltipCell, { ...props, maxLength, arrayGetter: getter });
}

export { maxLengthTooltipCellRenderer };
export default MaxLengthTooltipCell;
