import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import * as R from 'ramda';
import Button from 'react-bootstrap/lib/Button';

const propTypes = {
    defaultShow: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    collapsedTitle: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    children: null,
    defaultShow: false,
};

function Collapse({ defaultShow, children, collapsedTitle }) {
    const [show, setShow] = useState(!!defaultShow);

    return (
        <div>
            <Button type="button" onClick={() => setShow(R.not)}>
                {show ? <FaCaretDown /> : <FaCaretRight />}
                {show ? null : collapsedTitle}
            </Button>
            {show ? <div>{children}</div> : null}
        </div>
    );
}

Collapse.propTypes = propTypes;
Collapse.defaultProps = defaultProps;

export default Collapse;
