import * as R from 'ramda';
import startCase from 'lodash/fp/startCase';

const createProgramDataPairs = R.compose(
    R.map(([key, value]) => {
        return {
            label: startCase(key),
            value: R.cond([
                [R.anyPass([R.isNil, R.isEmpty]), R.always('--')],
                [R.is(Array), R.join(', ')],
                [R.is(Boolean), R.ifElse(R.identity, R.always('Yes'), R.always('No'))],
                [R.is(Object), R.always('--')],
                [R.T, R.identity],
            ])(value),
        };
    }),
    R.toPairs,
    R.omit(['AssociatedPAPs', 'AssociatedFoundations'])
);

export { createProgramDataPairs };
