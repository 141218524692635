import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';

function useFetchAssociationQueue() {
    return useQuery(['associationQueue'], PESService.getAssociationQueue, {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchAssociationQueue;
