import CMSService from 'App/services/CMSService';

/** @ngInject */
function ContentLibraryEditMonographConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.contentLibraryEditMonograph',
        url: '/content-library/content-group/:groupUuid',
        component: 'contentLibraryEditMonograph',
        redirectTo(trans) {
            return (
                trans
                    .injector()
                    .getAsync('user')
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line consistent-return
                    .then((user) => {
                        if (user.company_permissions.TherigyAssessmentAdmin !== true) {
                            return 'app.404';
                        }
                    })
            );
        },
        resolve: {
            contentGroup(_, $transition$, $state) {
                const params = $transition$.params();
                return CMSService.getContentGroup(params.groupUuid, {
                    includeContentResource: true,
                })
                    .then((res) => {
                        if (res.publishedBy !== null || res.publishedOn !== null) {
                            throw new Error('A published content group cannot be edited');
                        }
                        return res;
                    })
                    .catch(() => {
                        return $state.go('app.404');
                    });
            },
        },
    });
}

export default ContentLibraryEditMonographConfig;
