/** @ngInject  */
function SendEmrSummaryNoteModalController($q) {
    const ctrl = this;
    ctrl.doNotSendToEmr = doNotSendToEmr;
    ctrl.doSendToEmr = doSendToEmr;

    function doNotSendToEmr() {
        return $q.when().then(() => {
            ctrl.modalInstance.close({ sendToEmr: false });
        });
    }

    function doSendToEmr() {
        return $q.when().then(() => {
            ctrl.modalInstance.close({ sendToEmr: true });
        });
    }
}

export default SendEmrSummaryNoteModalController;
