import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from 'react-bootstrap';

import asFormField from './asFormField';

export const FileInput = ({ name, onChange, onBlur, className, style }) => {
    const handleChange = (event) => {
        onChange(event.currentTarget.files[0]);
    };

    return (
        <FormControl
            type="file"
            id={name}
            name={name}
            onChange={handleChange}
            onBlur={onBlur}
            className={className}
            style={style}
        />
    );
};

FileInput.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.shape({}),
};

FileInput.defaultProps = {
    onBlur: null,
    className: '',
    style: null,
};

export const FileField = asFormField({
    WrappedComponent: FileInput,
});
