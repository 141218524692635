import { useQuery } from 'react-query';
import { errorHandler } from 'App/utils';
import CompanyService from 'App/services/CompanyService';

function useFetchTherapeuticCategory() {
    return useQuery(['workQueue', 'TherapeuticCategory'], () => CompanyService.getTherapyCategories(), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}
export default useFetchTherapeuticCategory;
