/** @ngInject */
function InputConfig(uibDatepickerConfig, uibDatepickerPopupConfig) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    uibDatepickerPopupConfig.closeOnDateSelection = true;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    uibDatepickerPopupConfig.showButtonBar = false;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    uibDatepickerConfig.showWeeks = false;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    uibDatepickerPopupConfig.startingDay = true;
}

export default InputConfig;
