import CompanyService from '../../../services/CompanyService';
import UserService from 'App/services/UserService';
import PESService from 'App/services/PESService';
import * as R from 'ramda';

/** @ngInject */
function PatientInformationController(
    $filter,
    $q,
    $scope,
    _,
    __env,
    patientStatusService,
    patientStatusHistoryService,
    statesService
) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangePatientHighRiskReasons = doChangePatientHighRiskReasons;
    ctrl.doChangeRequiredOnly = doChangeRequiredOnly;
    ctrl.doChangeValue = doChangeValue;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;
    ctrl.stateFilter = stateFilter;
    ctrl.emailRegex = __env.emailRegex;
    $scope.onlyNumbers = /^[0-9-]*$/;

    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
            ctrl.tabIndexLeft = 0;
            ctrl.tabIndexRight = 0;

            ctrl.highRiskOptions = [
                {
                    name: 'No',
                    id: 0,
                },
                {
                    name: 'Yes',
                    id: 1,
                },
            ];

            if (ctrl.patient.id) {
                ctrl.tabIndexRight = ctrl.patient.gender === 'female' ? 4 : 0;

                if (ctrl.patient.patient_status) {
                    ctrl.tabIndexLeft = 1;
                    ctrl.tabIndexRight = 8;
                } else {
                    ctrl.tabIndexLeft = 0;
                }

                return patientStatusHistoryService.get(ctrl.patient.id).then((res) => {
                    ctrl.patient.patient_status = res.status_text;
                    doChangeValue();
                });
            }

            doChangeValue();
            ctrl.patient.high_risk =
                ctrl.patient.high_risk === 1 ? ctrl.highRiskOptions[1].id : ctrl.highRiskOptions[0].id;
        }

        if (changes.highRiskReasons) {
            ctrl.highRiskReasons = angular.copy(ctrl.highRiskReasons);
        }
        if (changes.patientLabels) {
            ctrl.patientLabels = angular.copy(ctrl.patientLabels);
        }
        if (changes.emptyPatientLabel) {
            ctrl.emptyPatientLabel = angular.copy(ctrl.emptyPatientLabel);
        }
    }

    function $onInit() {
        ctrl.states = statesService.states;
        ctrl.highRiskReasonOptions = CompanyService.getCustomMenu('high_risk_reason').map(R.prop('valueName'));

        const patientId = ctrl.patient.id;

        ctrl.user = UserService.get();

        return patientStatusService

            .getMany()

            .then((res) => {
                ctrl.patientStatuses = _.values(res);
            })
            .then(() => {
                if (patientId) {
                    return PESService.getIdentifiers(patientId);
                }
                return [];
            })
            .then((res) => {
                const identifiers = Object.values(res);
                const otherIdRow = identifiers.filter((row) => row.identifierType === 'stm_other_id')[0];
                ctrl.other_id = otherIdRow ? otherIdRow.identifier : null;
                ctrl.other_id_initial_value = ctrl.other_id;
            })
            .then(getEhrIdAndType);
    }

    async function getEhrIdAndType() {
        const ehrBranchSettings = await CompanyService.getEhrBranchSettings();
        ctrl.ehr_primary_id_type = ehrBranchSettings.ehrPrimaryIdType;
        ctrl.ehr_enabled = !!ctrl.ehr_primary_id_type;

        if (!ctrl.patient.id || !ctrl.ehr_enabled) {
            return;
        }

        const identifiers = Object.values(await PESService.getIdentifiers(ctrl.patient.id));
        ctrl.patient.ehr_id = identifiers.find(
            (identifierEntry) => identifierEntry.identifierType === ctrl.ehr_primary_id_type
        )?.identifier;
    }

    function doChangePatientHighRiskReasons() {
        $scope.$emit('addPatientPage_updatePatientRiskReasons', ctrl.highRiskReasons);
    }

    function doChangeRequiredOnly() {
        $scope.$emit('addPatientPage_toggleRequiredFields', ctrl.requiredOnly);
    }

    function doChangeValue() {
        const update = {
            address_line_1: ctrl.patient.address_line_1,
            address_line_2: ctrl.patient.address_line_2,
            birth_date: ctrl.patient.birth_date,
            city: ctrl.patient.city,
            country: ctrl.patient.country,
            deceased_date: ctrl.patient.deceased_date,
            disabled: ctrl.patient.disabled,
            email: ctrl.patient.email,
            ethnicity: ctrl.patient.ethnicity,
            external_id: ctrl.patient.external_id,
            first_name: ctrl.patient.first_name,
            gender: ctrl.patient.gender,
            gender_identity: ctrl.patient.gender_identity,
            pronouns: ctrl.patient.pronouns,
            preferred_name: ctrl.patient.preferred_name,
            height: ctrl.patient.height,
            high_risk: _.toInteger(ctrl.patient.high_risk),
            home_phone: ctrl.patient.home_phone,
            lactating: ctrl.patient.lactating,
            language_spoken: ctrl.patient.language_spoken,
            last_name: ctrl.patient.last_name,
            marital_status: ctrl.patient.marital_status,
            middle_name: ctrl.patient.middle_name,
            mobile_phone: ctrl.patient.mobile_phone,
            other_id: ctrl.other_id,
            patient_status: ctrl.patient.patient_status,
            pharmacy: ctrl.patient.pharmacy,
            preferred_contact_method: ctrl.patient.preferred_contact_method,
            pregnancy_status: ctrl.patient.pregnancy_status,
            smoker_status: ctrl.patient.smoker_status,
            ssn_last_four: ctrl.patient.ssn_last_four,
            state: ctrl.patient.state,
            suffix: ctrl.patient.suffix,
            terminally_ill: ctrl.patient.terminally_ill,
            weight: ctrl.patient.weight,
            work_phone: ctrl.patient.work_phone,
            zip: ctrl.patient.zip,
        };
        if (update.high_risk !== 1) {
            ctrl.highRiskReasons = [];
            ctrl.doChangePatientHighRiskReasons();
        }

        update.other_id_did_update = update.other_id !== ctrl.other_id_initial_value;

        if (update.gender) {
            update.gender = update.gender.toLowerCase();

            if (update.gender === 'male') {
                update.lactating = null;
                update.pregnancy_status = null;
            }
        }

        if (_.isDate(update.birth_date)) {
            update.birth_date = $filter('date')(update.birth_date, 'yyyy/MM/dd');
        }

        if (_.isDate(update.deceased_date)) {
            update.deceased_date = $filter('date')(update.deceased_date, 'yyyy/MM/dd');
        }

        $scope.$emit('addPatientPage_updatePatient', update);

        // Adjust the tab index for dynamic fields when gender is female (shift tab index by 4)
        ctrl.tabIndexRight = update.gender === 'female' ? 4 : 0;
        if (update.patient_status === 'Deceased') {
            ctrl.tabIndexLeft = 1;
            ctrl.tabIndexDeceased = 4;
        } else {
            ctrl.tabIndexLeft = 0;
            ctrl.tabIndexDeceased = 0;
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function stateFilter(state, viewValue) {
        if (typeof state === 'object' && state.name && state.abbreviation) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            state = `${state.abbreviation} - ${state.name}`;
            return state.substr(0, viewValue.length).toLowerCase() === viewValue.toLowerCase();
        }
    }
}

export default PatientInformationController;
