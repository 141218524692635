import { useMachine } from '@xstate/react';
import React, { createContext, useContext, useMemo } from 'react';
import { machine } from './ProtocolManagerHomePage/machine';
import PropTypes from 'prop-types';

const defaultContext = {
    category: null,
    setCategory: () => {},
};

const ProtocolManagerContext = createContext(defaultContext);

const { Provider, Consumer: ProtocolManagerContextConsumer } = ProtocolManagerContext;

function ProtocolManagerContextProvider({ children }) {
    const [state, send, service] = useMachine(machine);

    const value = useMemo(() => {
        return {
            state,
            send,
            service,
        };
    }, [send, service, state]);

    return <Provider value={value}>{children}</Provider>;
}

ProtocolManagerContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function useProtocolManagerContext() {
    return useContext(ProtocolManagerContext);
}

export {
    ProtocolManagerContext,
    ProtocolManagerContextConsumer,
    ProtocolManagerContextProvider,
    defaultContext,
    useProtocolManagerContext,
};
