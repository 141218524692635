import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import SelectField from 'Lib/form/SelectField';
import useFetchAllBranchSettingsForTypeQuery from 'App/hooks/useFetchAllBranchSettingsForTypeQuery';
import hasLength from 'App/utils/hasLength';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
};

const defaultProps = {
    value: null,
};

function EhrTypeSelect({ onChange }) {
    const { isLoading, data } = useFetchAllBranchSettingsForTypeQuery('ehrPrimaryIdType');

    const ehrPrimaryIdTypes = useMemo(() => {
        if (hasLength(data)) {
            return R.sortBy(R.prop('keyValue'), data);
        }
        return [];
    }, [data]);

    return (
        <SelectField
            fieldName="searchParams.ehrPrimaryIdType"
            options={ehrPrimaryIdTypes}
            label="EHR Type"
            getOptionValue={R.prop('uuid')}
            getOptionLabel={R.prop('keyValue')}
            isClearable
            onChange={(option) => {
                onChange(option);
            }}
            isLoading={isLoading}
        />
    );
}

EhrTypeSelect.propTypes = propTypes;
EhrTypeSelect.defaultProps = defaultProps;

export default EhrTypeSelect;
