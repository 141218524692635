import SessionStorageService from '../services/SessionStorageService';

/**
 * Get an authorization header object for Axios
 * @returns {{headers: {Authorization: string}}}
 */
function getAuthorizationHeader() {
    return {
        headers: {
            Authorization: `Bearer ${SessionStorageService.getJwt()}`,
        },
    };
}

export default getAuthorizationHeader;
