import convertKeysToSnakeCase from '../utils/keyConversion/convertKeysToSnakeCase';
import PESService from './PESService';
import { getAuthorizationHeader, getSlsBasePath } from '../utils';
import config from '../components/config/config';
import axios from 'axios';
import getData from '../utils/getData';

/** @ngInject */
function ActivityStatusService() {
    const vm = this;

    vm.getActivityNotes = getActivityNotes;
    vm.sendActivityUpdate = sendActivityUpdate;
    const endpoint = getSlsBasePath(config, 'ams');

    function getActivityNotes(activityId) {
        return PESService.getActivityNotes(activityId)
            .then((res) => Object.values(res))
            .then(convertKeysToSnakeCase);
    }

    function sendActivityUpdate(patientId, activityId, completeActivityAction) {
        const url = `${endpoint}/1.0.0/patient/${patientId}/activity/${activityId}/sendActivityUpdate`;
        return axios
            .post(
                url,
                {
                    completeActivityAction,
                },
                getAuthorizationHeader()
            )
            .then(getData);
    }
}

export default ActivityStatusService;
