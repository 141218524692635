import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { queryClient } from 'Lib/queryClient';
import { QueryClientProvider } from 'react-query';
import { search } from 'App/services/ICD10Service';
import * as R from 'ramda';
import { SelectInput } from '@/lib/form/SelectField';
import './patientICD10s.scss';
import { errorHandler } from 'App/utils';
import useRequiredDisabledPatientFields from 'App/hooks/useRequiredDisabledPatientFields';
import { cx } from '@emotion/css';

const propTypes = {
    onChangeICD10: PropTypes.func.isRequired,
    icd10selected: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            icd10: PropTypes.number,
            patientId: PropTypes.string,
            createdOn: PropTypes.string,
            groupId: PropTypes.string,
            employeeId: PropTypes.string,
            icd10Details: PropTypes.shape({
                DxPk: PropTypes.number,
                DxCode: PropTypes.string,
                DxDescLong: PropTypes.string,
                DxDescShort: PropTypes.string,
            }),
        })
    ).isRequired,
    validatePatientICD10s: PropTypes.func,
    validatePatientICD10sErrors: PropTypes.shape({
        icd10Code: PropTypes.string,
    }),
};

const defaultProps = {
    validatePatientICD10s: Function,
    validatePatientICD10sErrors: {
        icd10Code: '',
    },
};

function PatientICD10s(props) {
    const { onChangeICD10, icd10selected, validatePatientICD10s, validatePatientICD10sErrors } = props;
    const [initialICD10, setInitialICD10] = useState([]);
    const { requiredPatientFieldsArr, disabledPatientFieldsArr } = useRequiredDisabledPatientFields();

    const loadOptions = (value) => {
        let searchValue = value;
        if (!searchValue) {
            searchValue = 'a';
        }
        return search(searchValue, 50)
            .then((res) => {
                setInitialICD10(R.values(res));
            })
            .catch(errorHandler);
    };

    useEffect(() => {
        loadOptions();
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <FormGroup>
                <ControlLabel
                    htmlFor="icd10Code"
                    className={cx({
                        'invalid-insurance-field field-required':
                            requiredPatientFieldsArr.includes('icd_10_code') && validatePatientICD10sErrors?.icd10Code,
                        'field-required': requiredPatientFieldsArr.includes('icd_10_code'),
                    })}
                >
                    ICD-10 Diagnosis Code
                </ControlLabel>
                <SelectInput
                    isMulti
                    name="icd10Code"
                    inputId="icd10Code"
                    placeholder="ICD-10 Diagnosis Code"
                    className={cx('icd10-multi-select', {
                        'invalid-insurance-form icd10-multi-select':
                            requiredPatientFieldsArr.includes('icd_10_code') && validatePatientICD10sErrors?.icd10Code,
                    })}
                    options={initialICD10}
                    value={icd10selected}
                    getOptionLabel={(option) =>
                        option.icd10Details
                            ? `${option.icd10Details.DxCode} - ${option.icd10Details.DxDescShort}`
                            : `${option.code} - ${option.name || option.shortDescription}`
                    }
                    getOptionValue={(data) => {
                        const value = R.prop('id', data);
                        if (value) {
                            return value;
                        }
                        return R.prop('code', data);
                    }}
                    onChange={(option) => {
                        onChangeICD10(option);
                        validatePatientICD10s('icd10Code', option);
                    }}
                    onInputChange={(e) => {
                        loadOptions(e);
                    }}
                    isClearable
                    isDisabled={disabledPatientFieldsArr.includes('icd_10_code')}
                />
                {requiredPatientFieldsArr.includes('icd_10_code') && validatePatientICD10sErrors?.icd10Code ? (
                    <span className="error-message-insurance">{validatePatientICD10sErrors?.icd10Code}</span>
                ) : null}
            </FormGroup>
        </QueryClientProvider>
    );
}

PatientICD10s.propTypes = propTypes;
PatientICD10s.defaultProps = defaultProps;

export default PatientICD10s;
