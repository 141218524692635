import { localize } from 'App/utils';

const formatForCell = (value) => localize(value, 'M/D/YYYY h:mm A');

const DateFormatCell = ({ value }) => {
    return formatForCell(value);
};

export { formatForCell };

export default DateFormatCell;
