import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './PatientDemographicsStyles.scss';
import TherigyInfoMessage from '@/app/components/TherigyInfoMessage/TherigyInfoMessage';
import ViewOtherMedications from '@/app/components/PatientProfile/components/ViewOtherMedications/ViewOtherMedications';
import pathOrDash from '@/app/utils/pathOrDash';
import { otherMedicationsPropType, patientPropType, patientSubscriptionPropType } from 'Lib/types';
import { formatUtcDate } from '@/app/services/DateService';
import PatientEngagement from '../PatientEngagement/PatientEngagement';
import { formatPhone } from 'App/utils';

const propTypes = {
    patientDemographicsData: patientPropType.isRequired,
    address: PropTypes.string,
    otherMedication: otherMedicationsPropType,
    patientMessagingSubscription: patientSubscriptionPropType,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    emergencyContactData: PropTypes.object.isRequired,
    refetchMessagingSubscriptions: PropTypes.func,
};
const defaultProps = {
    address: '',
    patientMessagingSubscription: null,
    otherMedication: [],
    refetchMessagingSubscriptions: () => {},
};
const getSSNStringValue = (ssnLastFour) => {
    if (ssnLastFour) {
        return `xxx-xx-${ssnLastFour}`;
    }
    return '--';
};
const getmedicalPlanValue = (medicalPlan, remoteMedicalPlanId) => {
    return `${medicalPlan || '--'}${remoteMedicalPlanId ? ` - ${remoteMedicalPlanId}` : ''}`;
};
const getPbmValue = (pbm, remotePbmId) => {
    return `${pbm || '--'}${remotePbmId ? ` - ${remotePbmId}` : ''}`;
};

function PatientDemographics({
    patientMessagingSubscription,
    patientDemographicsData,
    address,
    otherMedication,
    emergencyContactData,
    refetchMessagingSubscriptions,
}) {
    const [showAllOtherMedications, setShowAllOtherMedications] = useState(false);
    const {
        ssnLastFour,
        remoteMedicalPlanId,
        remotePbmId,
        medicalPlan,
        pbm,
        homePhone,
        mobilePhone,
        branchName,
        id: patientId,
    } = patientDemographicsData;
    const { ecFirstName, ecLastName, ecRelationship, ecTelephone, ecMobilePhone } = emergencyContactData;

    return (
        <>
            {/* Patient Demographics  */}
            <Row className="profile-patient-demographics">
                <Col md={12}>
                    <div className="block-section">
                        <Row>
                            <Col md={2}>
                                <strong>DOB</strong>
                            </Col>
                            <Col md={2}>
                                <strong>Sex</strong>
                            </Col>
                            {patientDemographicsData.genderIdentity && (
                                <Col md={2}>
                                    <strong>Gender Identity</strong>
                                </Col>
                            )}
                            <Col md={2}>
                                <strong>SSN</strong>
                            </Col>
                            <Col md={2}>
                                <strong>Medical</strong>
                            </Col>
                            <Col md={2}>
                                <strong>PBM</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                {formatUtcDate({ date: patientDemographicsData.birthDate, format: 'YYYY/MM/DD' }) ||
                                    '--'}
                            </Col>
                            <Col md={2}>{capitalize(pathOrDash(['gender'], patientDemographicsData))}</Col>
                            {patientDemographicsData.genderIdentity && (
                                <Col md={2}>{capitalize(pathOrDash(['genderIdentity'], patientDemographicsData))}</Col>
                            )}
                            <Col md={2}>{getSSNStringValue(ssnLastFour)}</Col>
                            <Col md={2}>{getmedicalPlanValue(medicalPlan, remoteMedicalPlanId)}</Col>
                            <Col md={2}>{getPbmValue(pbm, remotePbmId)}</Col>
                        </Row>
                    </div>

                    <div className="block-section">
                        <Row>
                            <Col md={2}>
                                <dl>
                                    <dt>Address</dt>
                                    <dd>{address || '--'}</dd>
                                </dl>
                            </Col>
                            <Col md={2}>
                                <dl>
                                    <dt>Home Phone</dt>
                                    <dd>{formatPhone(homePhone) || '--'}</dd>
                                </dl>
                            </Col>
                            <Col md={2}>
                                <dl>
                                    <dt>Mobile Phone</dt>
                                    <dd>{formatPhone(mobilePhone) || '--'}</dd>
                                </dl>
                            </Col>

                            <Col md={2}>
                                <dl>
                                    <dt>Language</dt>
                                    <dd>{pathOrDash(['languageSpoken'], patientDemographicsData)} </dd>
                                </dl>
                            </Col>
                            <Col md={2}>
                                <dl>
                                    <dt>Other Medication(s)</dt>
                                    {otherMedication.length >= 1 &&
                                        otherMedication.slice(0, 2).map((medication, index) => (
                                            // TODO: Fix this the next time the file is edited.
                                            // eslint-disable-next-line react/no-array-index-key
                                            <dd key={`${medication.text}-${medication.dose}-${index}`}>
                                                {medication.text} &nbsp;&nbsp;{medication.dose}
                                            </dd>
                                        ))}
                                    {otherMedication.length > 2 && (
                                        <Button
                                            bsStyle="link"
                                            bsSize="sm"
                                            onClick={() => setShowAllOtherMedications(true)}
                                        >
                                            View All ({otherMedication.length})
                                        </Button>
                                    )}
                                    {otherMedication.length === 0 && <dd>--</dd>}
                                </dl>
                            </Col>
                            <Col md={2}>
                                <strong>Patient Branch</strong>
                                <br />
                                {branchName}&nbsp;
                                <span>
                                    - <Link to={`/editPatientBranch?patient_id=${patientId}`}>Edit</Link>
                                </span>
                            </Col>
                        </Row>
                        <ViewOtherMedications
                            show={showAllOtherMedications}
                            otherMedication={otherMedication}
                            onHide={() => {
                                setShowAllOtherMedications(false);
                            }}
                        />
                    </div>

                    <div className="block-section">
                        <Row>
                            <Col md={6}>
                                {!ecFirstName && !ecLastName && !ecRelationship && !ecTelephone && !ecMobilePhone && (
                                    <TherigyInfoMessage message="There is no emergency contact listed for this patient." />
                                )}

                                {(ecFirstName || ecRelationship || ecTelephone || ecMobilePhone) && (
                                    <div>
                                        {/* TODO: Fix this the next time the file is edited. */}
                                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                        <label>Emergency Contact Information</label>
                                        <Row>
                                            <Col md={3}>
                                                <dl>
                                                    <dt className="control-label">Name</dt>
                                                    <dd> {`${ecFirstName} ${ecLastName}` || '--'}</dd>
                                                </dl>
                                            </Col>
                                            <Col md={3}>
                                                <dl>
                                                    <dt>Relationship</dt>
                                                    <dd>{ecRelationship || '--'}</dd>
                                                </dl>
                                            </Col>
                                            <Col md={3}>
                                                <dl>
                                                    <dt>Home Phone</dt>
                                                    <dd>{formatPhone(ecTelephone) || '--'}</dd>
                                                </dl>
                                            </Col>
                                            <Col md={3}>
                                                <dl>
                                                    <dt>Mobile Phone</dt>
                                                    <dd>{formatPhone(ecMobilePhone) || '--'}</dd>
                                                </dl>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Col>

                            <Col md={6}>
                                <PatientEngagement
                                    patientDemographicsData={patientDemographicsData}
                                    patientMessagingSubscription={patientMessagingSubscription}
                                    refetchMessagingSubscriptions={refetchMessagingSubscriptions}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </>
    );
}

PatientDemographics.propTypes = propTypes;
PatientDemographics.defaultProps = defaultProps;

export default PatientDemographics;
