import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmDeleteAssessmentModal = ({ onClose }) => {
    return (
        <Modal show>
            <Modal.Body>
                <h4 className="text-center">WARNING</h4>
                <div className="text-center">Are you sure you want to delete this assessment?</div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <Button bsStyle="danger" onClick={() => onClose(true)}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmDeleteAssessmentModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ConfirmDeleteAssessmentModal;
