import React, { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { TextField } from 'Lib/form/TextField';
import { useFormikContext } from 'formik';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import SelectField from 'Lib/form/SelectField';
import MedicationTableForm from './MedicationTableForm';
import { CheckboxField } from 'Lib/form/CheckboxField';
import CompanyService from 'App/services/CompanyService';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import { FaExclamationCircle } from 'react-icons/fa';
import { RadioField } from 'Lib/form/RadioField';
import {
    BOOLEAN_TYPE,
    RE_VERIFICATION_OPTIONS_TYPE,
    STATUS_TYPE,
    approvalOptions,
    booleanOptions,
    denialReasonOptions,
    disableActivityStatusList,
    reVerificationScheduleOption,
} from '../constant';

const tooltip = <Tooltip id="actions-tooltip">Must have an applicable protocol enabled.</Tooltip>;

const InformationForm = () => {
    const { initialValues, values, setFieldValue } = useFormikContext();
    const { disableActivity, disableInputs } = values;
    const isReferralPMEnabled = CompanyService.getFeatureFlagStatus(PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER);
    const isStatusApproved = useMemo(() => values?.status?.id === STATUS_TYPE.approved, [values?.status]);
    const statusOptions = useMemo(() => {
        let defaultStatusOptions = Object.values(STATUS_TYPE).map((it) => ({
            id: it,
            name: it,
        }));
        if (!disableInputs) {
            defaultStatusOptions = defaultStatusOptions.filter((it) => it.id !== STATUS_TYPE.deleted);
        }
        if (!disableActivity) {
            const removeList = [STATUS_TYPE.appealed, STATUS_TYPE.appealedOverturned, STATUS_TYPE.appealedUpheld];
            defaultStatusOptions = defaultStatusOptions.filter((it) => !removeList.includes(it.id));
        }
        return defaultStatusOptions;
    }, [disableInputs, disableActivity]);

    useEffect(() => {
        const computedDisableActivity = disableActivityStatusList.includes(initialValues?.status?.id);
        const computedDisableInputs = initialValues?.status?.id === STATUS_TYPE.deleted;
        setFieldValue('disableActivity', computedDisableActivity);
        setFieldValue('disableInputs', computedDisableInputs);
    }, [initialValues?.status, setFieldValue]);

    return (
        <>
            <Row>
                <Col md={3}>
                    <SelectField
                        options={statusOptions}
                        label="Status"
                        fieldName="status"
                        isDisabled={disableActivity || disableInputs}
                        getOptionValue={R.prop('id')}
                        getOptionLabel={R.prop('name')}
                    />
                </Col>
            </Row>
            {values?.status?.id !== STATUS_TYPE.notRequired && values?.status?.id !== STATUS_TYPE.denied && (
                <>
                    <Row>
                        <Col md={3}>
                            <TextField
                                label="PA Number"
                                fieldName="details.paNumber"
                                placeholder="PA Number"
                                disabled={disableActivity || disableInputs}
                                required={isStatusApproved}
                            />
                        </Col>
                        <Col md={3}>
                            <TextField
                                label="PA Follow Up Phone Number"
                                fieldName="details.followUpPhone"
                                disabled={disableActivity || disableInputs}
                                required={isStatusApproved}
                                placeholder="(###) ###-####"
                            />
                        </Col>
                        <Col md={3}>
                            <DatePickerField
                                fieldName="details.effectiveStartDate"
                                label="Effective Start Date"
                                disabled={disableActivity || disableInputs}
                                required={isStatusApproved}
                                placeholder="YYYY/MM/DD"
                            />
                        </Col>
                        <Col md={3}>
                            <DatePickerField
                                fieldName="details.effectiveEndDate"
                                label="Effective End Date"
                                disabled={disableActivity || disableInputs}
                                required={isStatusApproved}
                                placeholder="YYYY/MM/DD"
                            />
                        </Col>
                        <Col md={12}>
                            <MedicationTableForm />
                        </Col>
                    </Row>
                    {isStatusApproved && (
                        <>
                            <Row>
                                <Col md={3}>
                                    <SelectField
                                        options={approvalOptions.map((it) => ({ id: it, name: it }))}
                                        isDisabled={disableInputs || disableActivity}
                                        label="How was approval received?"
                                        fieldName="details.methodApprovalReceived"
                                        getOptionValue={R.prop('id')}
                                        getOptionLabel={R.prop('name')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <CheckboxField
                                        disabled={disableInputs || disableActivity}
                                        fieldName="details.isReAuthorizationRequired"
                                        label={
                                            <>
                                                {isReferralPMEnabled
                                                    ? 'Create a Prior Authorization Renewal activity'
                                                    : 'Is Re-Authorization Required?'}
                                                {isReferralPMEnabled && (
                                                    <OverlayTrigger
                                                        trigger={['hover', 'focus']}
                                                        placement="top"
                                                        overlay={tooltip}
                                                    >
                                                        <FaExclamationCircle />
                                                    </OverlayTrigger>
                                                )}
                                            </>
                                        }
                                    />
                                </Col>
                            </Row>
                            {!isReferralPMEnabled && values?.details?.isReAuthorizationRequired && (
                                <Row>
                                    <Col md={3}>
                                        <SelectField
                                            options={booleanOptions.map((it) => ({ id: it, name: it }))}
                                            isDisabled={disableInputs || disableActivity}
                                            label="Schedule Re-Authorization upon Completion?"
                                            fieldName="details.benefitRequiresReVerification"
                                            getOptionValue={R.prop('id')}
                                            getOptionLabel={R.prop('name')}
                                        />
                                    </Col>
                                </Row>
                            )}
                            {!isReferralPMEnabled &&
                                values?.details?.benefitRequiresReVerification?.id === BOOLEAN_TYPE.yes && (
                                    <RadioField
                                        fieldName="details.reVerificationScheduleOption"
                                        disabled={disableActivity || disableInputs}
                                        options={reVerificationScheduleOption.map((it) => ({ id: it, option: it }))}
                                    />
                                )}
                            {!isReferralPMEnabled &&
                                values?.details?.reVerificationScheduleOption ===
                                    RE_VERIFICATION_OPTIONS_TYPE.specificDate && (
                                    <Row>
                                        <Col md={3}>
                                            <DatePickerField
                                                fieldName="details.reVerificationDueDate"
                                                value={values?.details?.reVerificationDueDate}
                                                label="Re-Authorization Due Date"
                                                disabled={disableActivity || disableInputs}
                                                required={isStatusApproved}
                                                placeholder="YYYY/MM/DD"
                                            />
                                        </Col>
                                    </Row>
                                )}
                        </>
                    )}
                </>
            )}
            {values?.status?.id === STATUS_TYPE.denied && (
                <>
                    <Row>
                        <Col md={3}>
                            <SelectField
                                options={denialReasonOptions.map((it) => ({ id: it, name: it }))}
                                isDisabled={disableInputs || disableActivity}
                                label="Denial Reason"
                                fieldName="details.denialReason"
                                getOptionValue={R.prop('id')}
                                getOptionLabel={R.prop('name')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <SelectField
                                options={booleanOptions.map((it) => ({ id: it, name: it }))}
                                isDisabled={disableInputs || disableActivity}
                                label="Create an Appeal activity"
                                fieldName="details.isPrescriberSubmittingAppeal"
                                getOptionValue={R.prop('id')}
                                getOptionLabel={R.prop('name')}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};
export default InformationForm;
