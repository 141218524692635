import React from 'react';
import Alert from 'react-bootstrap/lib/Alert';
import { getLatestVersion } from 'App/services/AppVersionService';
import { useUserContext } from 'App/contexts/UserContext';
import {useQuery, useQueryClient} from 'react-query';

const propTypes = {};

const defaultProps = {};

function convertBrowserRefreshTime(time) {
    try {
        const parsed = Number.parseInt(time, 10);

        if (parsed) {
            return parsed;
        }
    } catch (error) {
        console.log(error);
    }
    return 3_600_000;
}

function useShouldShowVersionBanner() {
    const user = useUserContext();
    const queryClient = useQueryClient()

    const BrowserRefreshTime = convertBrowserRefreshTime(user.company_permissions.BrowserRefreshTime, 10);

    const { isLoading, data } = useQuery(
        ['appVersion'],
        () => {
            return getLatestVersion();
        },
        {
            cacheTime: BrowserRefreshTime,
            retry: false,
            staleTime: BrowserRefreshTime,
            refetchInterval: BrowserRefreshTime,
            refetchOnWindowFocus: false,
            onSuccess(latest) {
                const prev = queryClient.getQueryData(['prevAppVersion'])
                if (!prev && latest) {
                    queryClient.setQueryData([ 'prevAppVersion' ], latest)
                }

            },
            onError(error) {
                // eslint-disable-next-line no-console
                console.error(error);
            },
        }
    );

    const prev = queryClient.getQueryData(['prevAppVersion'])

    if (isLoading || !prev) {
        return false;
    }
    return prev !== data;
}

function LatestAppVersion() {
    const showBanner = useShouldShowVersionBanner();

    if (showBanner) {
        return (
            <Alert bsStyle="warning" style={{ marginBottom: 0 }}>
                You are running on an older version of TherigySTM&trade;. Please refresh your browser to download the
                latest version.
            </Alert>
        );
    }

    return null;
}

LatestAppVersion.propTypes = propTypes;
LatestAppVersion.defaultProps = defaultProps;

export default LatestAppVersion;
