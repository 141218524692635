import PropTypes from 'prop-types';

const branchSettingsPropType = PropTypes.shape({
    ehrType: PropTypes.string,
    ehrNoteType: PropTypes.string,
    ehrNoteRequiresVisit: PropTypes.bool,
    ehrPrimaryIdType: PropTypes.string,
    noteRequiresEhrProviderId: PropTypes.bool,
    redoxNoteRequiresPrescriber: PropTypes.bool,
    redoxNotePrescriberType: PropTypes.string,
});

export default branchSettingsPropType;
