import React from 'react';
import PropTypes from 'prop-types';
import hasLength from 'App/utils/hasLength';
import useFetchPatientLabels from 'App/hooks/useFetchPatientLabels';
import * as R from 'ramda';

const propTypes = {
    patientId: PropTypes.string.isRequired,
};

const defaultProps = {};

function PatientLabels({ patientId }) {
    const { isLoading, data } = useFetchPatientLabels(patientId);

    if (isLoading) {
        return null;
    }

    const labels = R.values(data);

    if (hasLength(labels)) {
        return (
            <div className="patient-labels">
                {labels.map((label) => (
                    <span className="patient-label" key={label.id}>
                        {label.label}
                    </span>
                ))}
            </div>
        );
    }
    return null;
}

PatientLabels.propTypes = propTypes;
PatientLabels.defaultProps = defaultProps;

export default PatientLabels;
