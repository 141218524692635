import PropTypes from 'prop-types';

const prescriptionPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    rxNumber: PropTypes.string.isRequired,
    dispensedMedication: PropTypes.string,
    strength: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
    modifiedOn: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    endReason: PropTypes.string,
    prescriptionData: PropTypes.string,
    physician: PropTypes.shape({
        id: PropTypes.string.isRequired,
        npi: PropTypes.string.isRequired,
        faxNumber: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
    }).isRequired,
    medication: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
});

/**
 * Some places send this down as a hash, others an array.
 */
const prescriptionsPropType = PropTypes.oneOfType([
    PropTypes.arrayOf(prescriptionPropType),
    PropTypes.shape({
        [PropTypes.string]: prescriptionPropType,
    }),
]);

export default prescriptionsPropType;
