// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from 'react';
import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';
import useProfileDataState from './useProfileDataState';

/**
 * Custom hook which make the http call to fetch patient therapies required to render patient detail page
 * @param {string} patientId
 * @returns {Object}
 */
function useGetPatientTherapies(patientId) {
    const {
        data: therapies,
        setData: setTherapies,
        isLoading: isTherapyLoading,
        triggerRefetch: triggerTherapyRefetch,
        refetch,
        setIsLoading,
    } = useProfileDataState([]);

    useEffect(() => {
        PESService.getTherapies(patientId)
            .then((result) => {
                setTherapies(Object.values(result));
            })
            .catch(errorHandler)
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, refetch]);

    return {
        therapies,
        isTherapyLoading,
        triggerTherapyRefetch,
    };
}

export default useGetPatientTherapies;
