/** @ngInject */
function PatientMessageViewController() {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;

    function $onChanges() {
        ctrl.loading = false;
        if (ctrl.resolve.modalData.reason === 'massText') {
            ctrl.title = 'Mass Text';
        } else if (ctrl.resolve.modalData.reason === 'oneTime') {
            ctrl.title = 'One-Time Text';
        } else {
            ctrl.title = '';
        }
        const res = {
            title: ctrl.title,
            message: ctrl.resolve.modalData.message,
            sentOn: ctrl.resolve.modalData.sentOn,
        };
        return res;
    }
}

export default PatientMessageViewController;
