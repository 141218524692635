import { cx } from '@emotion/css';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Nav, NavItem, TabContent } from 'react-bootstrap';

const TabNavStyle = styled(Nav)`
    border: 0;
`;

const PaneAreaStyle = styled.div`
    border: 1px solid #ddd;
    padding: 1.5em;
    border-radius: 4px;
`;

const NavTabs = ({ items, renderNavTab, renderPaneContent }) => {
    const [activeIndex, setActiveIndex] = useState(items.length - 1);

    useEffect(() => {
        setActiveIndex(items.length - 1);
    }, [items.length]);

    return (
        <div>
            <TabNavStyle bsStyle="tabs">
                {items.map((item, index) => (
                    <NavItem
                        key={item.id || `index-${index}`}
                        onClick={() => {
                            setActiveIndex(index);
                        }}
                        active={index === activeIndex}
                    >
                        {renderNavTab(item, index)}
                    </NavItem>
                ))}
            </TabNavStyle>
            <PaneAreaStyle>
                <TabContent>
                    {items.map((item, index) => (
                        <div
                            key={item.id || `index-${index}`}
                            className={cx('tab-pane', { active: index === activeIndex })}
                        >
                            {renderPaneContent(item, index)}
                        </div>
                    ))}
                </TabContent>
            </PaneAreaStyle>
        </div>
    );
};

NavTabs.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
        })
    ).isRequired,
    renderNavTab: PropTypes.func.isRequired,
    renderPaneContent: PropTypes.func.isRequired,
};

export default NavTabs;
