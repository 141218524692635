import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    row: PropTypes.shape({
        original: PropTypes.shape({
            patientId: PropTypes.string.isRequired,
            id: PropTypes.number,
        }),
    }).isRequired,
};

const defaultProps = {};

function ReferralIdCell({ value, row }) {
    const { original: referral } = row;
    return <Link to={`/patient/${referral.patientId}/referral/${referral.id}`}>{value}</Link>;
}

ReferralIdCell.propTypes = propTypes;
ReferralIdCell.defaultProps = defaultProps;

export default ReferralIdCell;
