import React, { useMemo } from 'react';
import useFetchBranchesQuery from 'App/hooks/useFetchBranchesQuery';
import { CheckboxField } from 'Lib/form/CheckboxField';
import { CheckboxMultiField } from 'Lib/form/CheckboxMultiField';
import { useFormikContext } from 'formik';
import { sortBy } from 'lodash';

const BranchFilter = () => {
    const { data: branchList } = useFetchBranchesQuery();
    const {
        values: {
            filterParams: { branches, allBranches },
        },
    } = useFormikContext();
    const options = useMemo(() => {
        return sortBy(
            Object.values(branchList || {}).map((opt) => ({ id: opt.ID, option: opt.Name })),
            'option'
        );
    }, [branchList]);

    return (
        <div className="filter form-group">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="control-label filter-header">Branch</label>
            <CheckboxField fieldName="filterParams.allBranches" label="All Branches" />
            {!allBranches && (
                <CheckboxMultiField fieldName="filterParams.branches" value={branches} options={options} />
            )}
        </div>
    );
};

export default BranchFilter;
