import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const PagerWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const propTypes = {
    canNextPage: PropTypes.bool.isRequired,
    canPreviousPage: PropTypes.bool.isRequired,
    gotoPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    pageIndex: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    previousPage: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    maxPages: PropTypes.number,
};

const defaultProps = {
    pageSizeOptions: [10, 25, 50],
    maxPages: 10,
};

const TablePagination = (props) => {
    const {
        canNextPage,
        canPreviousPage,
        gotoPage,
        maxPages,
        nextPage,
        pageIndex,
        pageOptions,
        pageSize,
        pageSizeOptions,
        previousPage,
        setPageSize,
    } = props;

    const pageGroups = R.splitEvery(maxPages, pageOptions);
    const groupIndex = Math.floor(pageIndex / maxPages);

    const nextGroup = pageGroups[groupIndex + 1];
    const prevGroup = pageGroups[groupIndex - 1];

    const canNextGroup = !!nextGroup;
    const canPrevGroup = !!prevGroup;

    const goToNextGroup = () => {
        gotoPage(nextGroup[0]);
    };

    const goToPrevGroup = () => {
        gotoPage(prevGroup[prevGroup.length - 1]);
    };

    const currentGroup = R.propOr([0], groupIndex, pageGroups);

    return (
        <PagerWrapper data-testid="table-pager">
            <ButtonGroup>
                <Button
                    aria-label="Previous Page"
                    title="Previous Page"
                    disabled={!canPreviousPage}
                    onClick={previousPage}
                >
                    <FaAngleDoubleLeft />
                </Button>

                {canPrevGroup && (
                    <Button
                        aria-label="Previous Group"
                        title="Previous Group"
                        disabled={!canPrevGroup}
                        onClick={goToPrevGroup}
                    >
                        ...
                    </Button>
                )}

                {currentGroup.map((pageNumber) => {
                    const isActive = pageIndex === pageNumber;

                    return (
                        <Button
                            key={pageNumber}
                            active={isActive}
                            bsStyle={isActive ? 'primary' : 'default'}
                            disabled={isActive}
                            onClick={() => gotoPage(pageNumber)}
                            aria-label={`Go to page ${pageNumber + 1}`}
                            title={`Go to page ${pageNumber + 1}`}
                            style={{ opacity: 1, zIndex: 0 }}
                        >
                            {pageNumber + 1}
                        </Button>
                    );
                })}

                {canNextGroup && (
                    <Button aria-label="Next Group" title="Next Group" disabled={!canNextGroup} onClick={goToNextGroup}>
                        ...
                    </Button>
                )}

                <Button aria-label="Next Page" title="Next Page" disabled={!canNextPage} onClick={nextPage}>
                    <FaAngleDoubleRight />
                </Button>
            </ButtonGroup>

            <div>
                <ButtonGroup>
                    {pageSizeOptions.map((sizeOption) => (
                        <Button
                            onClick={() => setPageSize(sizeOption)}
                            active={sizeOption === pageSize}
                            key={sizeOption}
                            aria-label={`Set page size to ${sizeOption}`}
                            title={`Set page size to ${sizeOption}`}
                            style={{ zIndex: 0 }}
                        >
                            {sizeOption}
                        </Button>
                    ))}
                </ButtonGroup>
            </div>
        </PagerWrapper>
    );
};

TablePagination.propTypes = propTypes;
TablePagination.defaultProps = defaultProps;

export default TablePagination;
