import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import pathOrDash from 'App/utils/pathOrDash';
import useMedicationHistory from 'App/components/PatientProfile/hooks/useMedicationHistory';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import AddNoteModal from './AddNoteModal';
import { NoteModalTypes } from 'App/components/PatientProfile/constants';
import NoteListModal from './NoteListModal';
import { noop } from 'lodash';
import { FaInfoCircle } from 'react-icons/fa';
import IconWrapper from 'App/common/styles/IconWrapper';
import dateOrDash from 'App/utils/dateOrDash';
import FillNumberHistory from 'App/components/PatientPrescription/FillNumberHistory';
import useFetchPatientMedicationRxNumber from 'App/hooks/useFetchPatientMedicationRxNumber';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    medicationId: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    medicationName: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    patientId: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    show: PropTypes.bool.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onHide: PropTypes.func.isRequired,
};
const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    medicationId: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    medicationName: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    patientId: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    show: false,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    onHide: noop,
};

function MedicationHistory({ medicationId, medicationName, patientId, show, onHide }) {
    const { medicationHistory, rxHistory, isLoading } = useMedicationHistory(patientId, medicationId);
    const [showAddNote, setShowAddNote] = useState(false);
    const [showNoteList, setShowNoteList] = useState(false);
    const [noteModalData, setNoteModalData] = useState({});
    const { data: prescription, isLoading: isLoadingRxFill } = useFetchPatientMedicationRxNumber(
        patientId,
        medicationId
    );

    const showNoteModal = useCallback(
        (parentNoteId, rxId, noteType, isAddNote) => {
            setNoteModalData({
                parentNoteId,
                medicationId,
                patientId,
                rxId,
                noteType,
            });
            if (isAddNote) {
                setShowAddNote(true);
            } else {
                setShowNoteList(true);
            }
        },
        [medicationId, patientId]
    );

    const hideNoteModal = useCallback(() => {
        setNoteModalData({});
        setShowAddNote(false);
        setShowNoteList(false);
    }, []);

    return (
        <>
            <Modal
                show={show && !showAddNote && !showNoteList}
                onHide={onHide}
                backdrop="static"
                className="patientMedicationHistoryModal"
                bsSize="large"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title className="header">Medication and Rx Number History</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {isLoading ? (
                        <Row>
                            <Col md={12} className="text-center">
                                <TherigyLoadingSpinner />
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <h4 className="header-medication-history-modal">Medication History</h4>
                            {medicationHistory.length > 0 && (
                                <table className="therigy-table">
                                    <colgroup>
                                        <col width="20%" />
                                        <col width="10%" />
                                        <col width="10%" />
                                        <col width="38%" />
                                        <col width="22%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Medication</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Reason</th>
                                            <th className="actions">Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {medicationHistory.map((history) => (
                                            <tr className="therapy-row" key={history.id}>
                                                <td>{medicationName}</td>
                                                <td>{dateOrDash(['start'], history)}</td>
                                                <td>{dateOrDash(['end'], history)}</td>
                                                <td>{pathOrDash(['endReason'], history)}</td>
                                                <td>
                                                    <Button
                                                        bsClass="btn-link"
                                                        onClick={() =>
                                                            showNoteModal(
                                                                history.id,
                                                                null,
                                                                NoteModalTypes.medication,
                                                                false
                                                            )
                                                        }
                                                    >
                                                        View
                                                    </Button>
                                                    |
                                                    <Button
                                                        bsClass="btn-link"
                                                        onClick={() =>
                                                            showNoteModal(
                                                                history.id,
                                                                null,
                                                                NoteModalTypes.medication,
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Add
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <h4 className="header-medication-history-modal">Rx Number History</h4>
                            {rxHistory.length > 0 ? (
                                <table className="therigy-table">
                                    <colgroup>
                                        <col width="20%" />
                                        <col width="10%" />
                                        <col width="12%" />
                                        <col width="10%" />
                                        <col width="10%" />
                                        <col width="18%" />
                                        <col width="22%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Rx Number</th>
                                            <th>Strength</th>
                                            <th>Written Date</th>
                                            <th>End Date</th>
                                            <th>Prescriber</th>
                                            <th>Reason</th>
                                            <th className="actions">Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rxHistory.map((history) => (
                                            <tr className="therapy-row" key={history.id}>
                                                <td>{pathOrDash(['rxNumber'], history)}</td>
                                                <td>{pathOrDash(['strength'], history)}</td>
                                                <td>{dateOrDash(['start'], history)}</td>
                                                <td>{dateOrDash(['end'], history)}</td>
                                                <td>
                                                    {history.physician?.id
                                                        ? // eslint-disable-next-line max-len
                                                          `${history?.physician?.physicianFirstName} ${history?.physician?.physicianLastName}`
                                                        : '--'}
                                                </td>
                                                <td>{pathOrDash(['endReason'], history)}</td>
                                                <td>
                                                    <Button
                                                        bsClass="btn-link"
                                                        onClick={() =>
                                                            showNoteModal(
                                                                history.id,
                                                                history.rxNumber,
                                                                NoteModalTypes.prescription,
                                                                false
                                                            )
                                                        }
                                                    >
                                                        View
                                                    </Button>
                                                    |
                                                    <Button
                                                        bsClass="btn-link"
                                                        onClick={() =>
                                                            showNoteModal(
                                                                history.id,
                                                                history.rxNumber,
                                                                NoteModalTypes.prescription,
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Add
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="row">
                                    <div className="col-md-12">
                                        <IconWrapper>
                                            <FaInfoCircle />
                                        </IconWrapper>
                                        <span>There is no Rx number history for this medication.</span>
                                    </div>
                                </div>
                            )}
                            {isLoadingRxFill ? (
                                <Row>
                                    <Col md={12} className="text-center">
                                        <TherigyLoadingSpinner />
                                    </Col>
                                </Row>
                            ) : (
                                <FillNumberHistory patientPrescriptions={prescription} />
                            )}
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        data-automation-id="medication_history_close"
                        bsStyle="default"
                        type="button"
                        onClick={onHide}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {showAddNote && <AddNoteModal {...noteModalData} show={showAddNote} onHide={hideNoteModal} />}
            {showNoteList && <NoteListModal {...noteModalData} show={showNoteList} onHide={hideNoteModal} />}
        </>
    );
}

MedicationHistory.propTypes = propTypes;
MedicationHistory.defaultProps = defaultProps;

export default MedicationHistory;
