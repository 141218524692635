import { useQueries } from 'react-query';
import CompanyService from '@/app/services/CompanyService';
import { useUserContext } from '@/app/contexts/UserContext';
import { useMemo } from 'react';
import { every, map } from 'lodash';

const useCompanySettings = (settings) => {
    const user = useUserContext();

    const companySettings = useQueries(
        settings.map((setting) => ({
            queryKey: ['company', user.active_company.ID, 'branch', user.active_branch.ID, 'settings', setting],
            queryFn: () => CompanyService.getBranchSettings(setting, false, false, user.active_branch.ID),
        }))
    );

    return useMemo(() => {
        const allLoaded = every(companySettings, ['status', 'success']);
        const data = map(companySettings, 'data');
        return { isLoading: !allLoaded, data };
    }, [companySettings]);
};

export default useCompanySettings;
