/** @ngInject */
function VideoLibraryController(videoService, _, $location, $anchorScroll, $sce) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.nameStartsWith = nameStartsWith;
    ctrl.loadVideos = loadVideos;
    ctrl.playVideo = playVideo;
    ctrl.assignRandomThumbnail = assignRandomThumbnail;

    function $onInit() {
        ctrl.filterButtons = [];
        ctrl.selectedVideoUrl = null;
        ctrl.selectedVideoId = undefined;
        ctrl.videos = [];
        ctrl.filter = 'a';
        ctrl.videoTypes = ['Medications', 'Conditions'];
        ctrl.selectedType = 'Conditions';
        ctrl.loadVideos();
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function loadVideos() {
        ctrl.selectedVideoUrl = null;
        let vidRequest;
        ctrl.videos = [];
        switch (ctrl.selectedType) {
            case 'Medications':
                vidRequest = videoService.getAllDrugVideos();
                break;
            case 'Conditions':
                vidRequest = videoService.getAllTherapyVideos();
                break;
            default:
                ctrl.videos = [];
                return false;
        }
        ctrl.filter = '';
        vidRequest.then((res) => {
            const groupInitial = {};
            _.each(res, (vidGroup) => {
                const letter = vidGroup[0].group.charAt(0);
                groupInitial[letter] = letter;
                ctrl.videos.push(vidGroup);
            });
            ctrl.filterButtons = _.values(_.sortBy(groupInitial));
            ctrl.assignRandomThumbnail();
        });
    }

    function nameStartsWith(video) {
        if (ctrl.filter === '') {
            return true;
        }
        const name = video[0].group.toLowerCase();
        return name.indexOf(ctrl.filter.toLowerCase()) === 0;
    }

    function playVideo(video) {
        ctrl.selectedVideoId = video.id;
        angular.element('html,body').animate(
            {
                scrollTop: angular.element('#videoAnchor').prop('offsetTop'),
            },
            1000
        );
        ctrl.selectedVideoUrl = $sce.trustAsResourceUrl(video.link);
    }

    function assignRandomThumbnail() {
        _.each(ctrl.videos, (vids, group) => {
            _.each(vids, (vid, id) => {
                ctrl.videos[group][id].thumbnail = `images/video_library/VUCA_00${Math.floor(
                    Math.random() * 6 + 1
                )}.png`;
            });
        });
    }
}

export default VideoLibraryController;
