import CompanyService from 'App/services/CompanyService';
import ProtocolService from 'App/services/ProtocolService';
import { errorHandler } from 'App/utils';
import * as R from 'ramda';
import { assign, createMachine } from 'xstate';
import { CREATED_BY } from '../constants';

/**
 * Appends the `createdBy` property with the proper text value
 * @param {protocol} protocol
 * @returns {protocol}
 */
const setProtocolCreatedBy = (protocol) => {
    const policyCompanyId = R.path(['companyId'], protocol);

    if (policyCompanyId) {
        return R.assoc('createdBy', CREATED_BY.MY_PHARMACY, protocol);
    }
    return R.assoc('createdBy', CREATED_BY.THERIGY, protocol);
};

/**
 * Appends the `isEnabled` field with the proper text value
 * @param {protocol} protocol
 * @returns {protocol}
 */
const setProtocolStatusValue = (protocol) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-use-before-define
    const isEnabled = getProtocolStatusValue(protocol);
    return R.assoc('isEnabled', isEnabled, protocol);
};

/**
 * Gets the protocol policy enabled value and converts it to boolean.
 * @param {protocol}
 * @returns {Boolean}
 */
const getProtocolStatusValue = R.compose((value) => {
    /**
     * Value will be undefined when no prop exists, and
     * therefore, we presume that it is enabled globally.
     */
    return R.isNil(value) || value === 1;
}, R.path(['status', 'enabled']));

const processProtocol = R.compose(setProtocolCreatedBy, setProtocolStatusValue);

const Events = Object.freeze({
    TOGGLE: 'TOGGLE',
    SELECT_THERAPY: 'SELECT_THERAPY',
    CONFIRM: 'CONFIRM',
    DELETE: 'DELETE',
    CANCEL: 'CANCEL',
    COPY: 'COPY',
});

const States = Object.freeze({
    LOADING_THERAPIES: 'LOADING_THERAPIES',
    THERAPIES_LOADED: 'THERAPIES_LOADED',
    LOADING_PROTOCOLS: 'LOADING_PROTOCOLS',
    TOGGLING_PROTOCOL: 'TOGGLING_PROTOCOL',
    COPYING_PROTOCOL: 'COPYING_PROTOCOL',
    DELETING_PROTOCOL: 'DELETING_PROTOCOL',
    PROTOCOLS_LOADED: 'PROTOCOLS_LOADED',
    CONFIRM_DELETE: 'CONFIRM_DELETE',
    FAILED: 'FAILED',
});

const machine = createMachine(
    {
        id: 'protocolManagerHome',
        initial: States.LOADING_THERAPIES,
        context: {
            availableTherapies: [],
            category: null,
            protocols: {},
            protocolToDelete: null,
        },
        states: {
            [States.LOADING_THERAPIES]: {
                invoke: {
                    id: 'loadTherapies',
                    src: 'loadTherapies',
                    onDone: {
                        target: States.THERAPIES_LOADED,
                        actions: ['setTherapies'],
                    },
                    onError: {
                        target: States.FAILED,
                    },
                },
            },
            [States.THERAPIES_LOADED]: {
                on: {
                    [Events.SELECT_THERAPY]: {
                        target: States.LOADING_PROTOCOLS,
                        actions: ['selectTherapy'],
                        cond: 'hasCategory',
                    },
                },
            },
            [States.LOADING_PROTOCOLS]: {
                invoke: {
                    id: 'loadProtocols',
                    src: 'loadProtocols',
                    onDone: {
                        target: States.PROTOCOLS_LOADED,
                        actions: ['setProtocols'],
                    },
                    onError: {
                        target: States.THERAPIES_LOADED,
                        actions: ['handleError'],
                    },
                },
            },
            [States.PROTOCOLS_LOADED]: {
                on: {
                    [Events.SELECT_THERAPY]: [
                        { target: States.LOADING_PROTOCOLS, actions: ['selectTherapy'], cond: 'hasCategory' },
                        { actions: ['selectTherapy'] },
                    ],
                    [Events.COPY]: { target: States.COPYING_PROTOCOL },
                    [Events.DELETE]: { target: States.CONFIRM_DELETE, actions: ['setProtocolToDelete'] },
                    [Events.TOGGLE]: { target: States.TOGGLING_PROTOCOL },
                },
            },
            [States.TOGGLING_PROTOCOL]: {
                invoke: {
                    id: 'toggleProtocol',
                    src: 'toggleProtocol',
                    onDone: {
                        target: States.PROTOCOLS_LOADED,
                        actions: ['updateProtocol'],
                    },
                    onError: {
                        target: States.PROTOCOLS_LOADED,
                        actions: ['handleError'],
                    },
                },
            },
            [States.COPYING_PROTOCOL]: {
                invoke: {
                    id: 'copyProtocol',
                    src: 'copyProtocol',
                    onDone: {
                        target: States.PROTOCOLS_LOADED,
                        actions: ['updateProtocol'],
                    },
                    onError: {
                        target: States.PROTOCOLS_LOADED,
                        actions: ['handleError'],
                    },
                },
            },
            [States.CONFIRM_DELETE]: {
                on: {
                    [Events.CONFIRM]: { target: States.DELETING_PROTOCOL },
                    [Events.CANCEL]: { target: States.PROTOCOLS_LOADED, actions: ['cleanUpDeleteProtocol'] },
                },
            },
            [States.DELETING_PROTOCOL]: {
                invoke: {
                    id: 'deleteProtocol',
                    src: 'deleteProtocol',
                    onDone: {
                        target: States.PROTOCOLS_LOADED,
                        actions: ['removeProtocol', 'cleanUpDeleteProtocol'],
                    },
                    onError: {
                        target: States.PROTOCOLS_LOADED,
                        actions: ['handleError', 'cleanUpDeleteProtocol'],
                    },
                },
            },
            [States.FAILED]: { type: 'final', actions: ['handleError'] },
        },
    },
    {
        actions: {
            selectTherapy: assign({
                category: (_, event) => {
                    return R.propOr(null, 'therapy', event);
                },
            }),
            setTherapies: assign({
                availableTherapies: (_, event) => {
                    return R.compose(R.sortBy(R.prop('name')), R.values, R.propOr({}, 'data'))(event);
                },
            }),
            setProtocols: assign({
                protocols: (_, event) => {
                    return R.compose(R.map(processProtocol), R.propOr({}, 'data'))(event);
                },
            }),
            setProtocolToDelete: assign({
                protocolToDelete: (_, event) => {
                    return R.propOr(null, 'protocol', event);
                },
            }),
            cleanUpDeleteProtocol: assign({
                protocolToDelete: R.always(null),
            }),
            updateProtocol: assign({
                protocols: (context, event) => {
                    return R.compose(
                        R.set(R.lensProp(event.data.id), processProtocol(event.data)),
                        R.prop('protocols')
                    )(context);
                },
            }),
            removeProtocol: assign({
                protocols: (context, event) => {
                    return R.compose(R.dissoc(event.data.id), R.prop('protocols'))(context);
                },
            }),
            handleError: (_, event) => {
                errorHandler(event.data);
            },
        },
        guards: {
            hasCategory: (context, event) => {
                return !!R.prop('therapy', event);
            },
        },
        services: {
            loadTherapies: () => {
                return CompanyService.getTherapyCategories();
            },
            loadProtocols: (context) => {
                return ProtocolService.getProtocols(context.category.id);
            },
            toggleProtocol: (_, event) => {
                const protocol = R.compose(
                    R.pick(['id', 'enabled']),
                    // convert it
                    R.chain(R.assoc('enabled'), R.ifElse(R.prop('isEnabled'), R.always(1), R.always(0))),
                    // toggle it
                    R.over(R.lensProp('isEnabled'), R.not),
                    R.prop('protocol')
                )(event);
                return ProtocolService.updateProtocolStatus(protocol.id, protocol);
            },
            copyProtocol: (_, event) => {
                return ProtocolService.copyProtocol(event.protocol);
            },
            deleteProtocol: (context) => {
                return ProtocolService.deleteProtocol(context.protocolToDelete);
            },
        },
    }
);

const stateMatchers = {
    isLoading: R.anyPass([
        R.equals(States.COPYING_PROTOCOL),
        R.equals(States.DELETING_PROTOCOL),
        R.equals(States.LOADING_PROTOCOLS),
        R.equals(States.LOADING_THERAPIES),
        R.equals(States.TOGGLING_PROTOCOL),
    ]),
};

export { Events, States, machine, stateMatchers };
