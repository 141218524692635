import React, { useEffect, useState } from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import Select from 'react-select';
import { useFormikContext } from 'formik';
import AssessmentService from '../../../services/AssessmentService';
import * as R from 'ramda';
import ActivityDueDate from '../ActivityDueDate';
import { DATE_TYPES } from '../constants';
import AddConditionButton from '../AddConditionButton';
import { isFieldValid, mapActivityFilterOptions } from '../utils';
import { hasLength } from '../../../utils';
import ActivityToTrigger from 'App/features/ProtocolManager/AddRule/ActivityToTrigger';

import useEditProtocolContext from 'App/features/ProtocolManager/EditProtocolPage/useEditProtocolContext';
import FilterFields from 'App/features/ProtocolManager/FilterFields';

const propTypes = {};

const defaultProps = {};

function CompletionOfClinicalActivityForm() {
    //#region State
    const formik = useFormikContext();
    const { values } = formik;
    const [activityQuestions, setActivityQuestions] = useState([]);
    const [dateQuestionOptions, setDateQuestionOptions] = useState([DATE_TYPES.CURRENT_DATE]);
    const {
        state: { availableRuleAssessments },
    } = useEditProtocolContext();
    //#endregion

    //#region Actions
    const { setValues } = formik;
    //#endregion

    //#region Side Effects
    /**
     * Get the Date type options for the assessment
     */
    useEffect(() => {
        if (values.activityToComplete) {
            AssessmentService.getQuestions(values.activityToComplete.id, { allQuestions: true }).then((questions) => {
                R.compose(
                    setDateQuestionOptions,
                    R.ifElse(
                        hasLength,
                        R.compose(R.prepend(DATE_TYPES.CURRENT_DATE), R.sortBy(R.prop('question'))),
                        R.always([DATE_TYPES.CURRENT_DATE])
                    ),
                    R.prop('date'),
                    R.groupBy(R.prop('type')),
                    R.tap(setActivityQuestions),
                    mapActivityFilterOptions
                )(questions);
            });
        }
    }, [values.activityToComplete]);

    //#endregion

    return (
        <>
            <FormGroup validationState={isFieldValid('activityToComplete', formik)}>
                <div className="label-tooltip-group">
                    <ControlLabel htmlFor="activity-to-complete" className="field-required">
                        Activity to Complete
                    </ControlLabel>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="activity-complete-tooltip">
                                The activity that needs to be completed in order to trigger the new activity
                            </Tooltip>
                        }
                    >
                        <FaInfoCircle className="info-icon" />
                    </OverlayTrigger>
                </div>
                <Select
                    inputId="activity-to-complete"
                    className="form-control react-select"
                    options={
                        availableRuleAssessments.filter(assessment => {
                            return  assessment.label !== 'Referral'
                        })
                    }
                    value={values.activityToComplete}
                    getOptionValue={R.prop('id')}
                    getOptionLabel={R.prop('name')}
                    onChange={(option) => {
                        R.compose(
                            setValues,
                            R.set(R.lensProp('dateType'), null),
                            R.set(R.lensProp('filterField'), null),
                            R.set(R.lensProp('conditions'), {}),
                            R.set(R.lensProp('activityToComplete'), option)
                        )(values);
                    }}
                    onBlur={() => formik.setFieldTouched('activityToComplete')}
                    placeholder="Select an activity to complete"
                />
            </FormGroup>

            <ActivityToTrigger />

            <ActivityDueDate dateTypeOptions={dateQuestionOptions} />

            {values.activityToComplete && (
                <>
                    <FilterFields fields={activityQuestions} />

                    <AddConditionButton />
                </>
            )}
        </>
    );
}

CompletionOfClinicalActivityForm.propTypes = propTypes;
CompletionOfClinicalActivityForm.defaultProps = defaultProps;

export default CompletionOfClinicalActivityForm;
