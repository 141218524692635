import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import Table from 'App/components/Table/Table';
import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils.js';
import propOrDash from 'App/utils/propOrDash';
import { otherMedicationsPropType } from 'Lib/types';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
    otherMedication: otherMedicationsPropType,
};

const defaultProps = {
    show: false,
    otherMedication: [],
};

function ViewOtherMedications({ onHide, show, otherMedication }) {
    const columns = useMemo(() => {
        return [
            {
                Header: 'Medication',
                accessor: propOrDash('text'),
                id: 'text',
                disableFilters: true,
            },
            {
                Header: 'Strength',
                accessor: propOrDash('dose'),
                id: 'dose',
                disableFilters: true,
            },
            {
                Header: 'Frequency',
                accessor: propOrDash('frequency'),
                id: 'frequency',
                disableFilters: true,
            },
            {
                Header: 'Route of Administration',
                accessor: propOrDash('routeOfAdmin'),
                id: 'routeOfAdmin',
                disableFilters: true,
            },
            {
                Header: 'Last Updated',
                accessor: 'dateTime',
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ value }) => {
                    return <span>{formatDate(value, 'YYYY/MM/DD h:mm A') || '--'}</span>;
                },
                disableFilters: true,
            },
        ];
    }, []);
    const getInitialState = useCallback(() => {
        return {
            sortBy: [
                {
                    id: 'dateTime',
                    desc: true,
                },
            ],
            pageSize: 10,
        };
    }, []);

    return (
        <Modal show={show} bsSize="large" onHide={onHide} backdrop="static">
            <Modal.Header>
                <Modal.Title>Patient Other Medications</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="engage-modal-selection-screen">
                    <Table
                        data={otherMedication}
                        placeholderText="There are no other medications for this patient."
                        initialState={getInitialState()}
                        columns={columns}
                        pageSizeOptions={[5, 10, 25]}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle="default" type="button" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ViewOtherMedications.propTypes = propTypes;
ViewOtherMedications.defaultProps = defaultProps;

export default ViewOtherMedications;
