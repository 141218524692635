import { useMemo, useReducer } from 'react';
import bindActionCreators from '../../bindActionCreators/bindActionCreators';

/**
 * Creates a reducer that has it's action creators bound to the dispatch function
 * @param {function} reducer - The reducer similar
 * @param {object} initialState - The initial state of the reducer
 * @param {object} actionCreators - An object of functions that return actions. This will be remapped and composed with
 * the dispatch function from useReducer.
 * @returns {state, dispatch, actions} - A composed object containing the state, the dispatch function and
 * the bound actions.
 */
function useReducerWithBoundCreators(reducer, initialState, actionCreators) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const actions = useMemo(() => bindActionCreators(actionCreators, dispatch), [actionCreators, dispatch]);

    return {
        state,
        dispatch,
        actions,
    };
}

export default useReducerWithBoundCreators;
