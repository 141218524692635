import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'react-bootstrap';

import asFormField from './asFormField';

export const CheckboxInput = ({ name, value, onChange, onBlur, className, style, disabled, label, ...rest }) => {
    return (
        <Checkbox
            {...rest}
            id={name}
            name={name}
            checked={value}
            onChange={onChange}
            onBlur={onBlur}
            className={className}
            style={style}
            disabled={disabled}
        >
            {label}
        </Checkbox>
    );
};

CheckboxInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    disabled: PropTypes.bool,
    label: PropTypes.string,
};

CheckboxInput.defaultProps = {
    value: false,
    onBlur: null,
    className: '',
    style: null,

    disabled: false,
    label: '',
};

export const CheckboxField = asFormField({
    WrappedComponent: CheckboxInput,
    suppressFieldLabel: true,
});
