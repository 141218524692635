import { $q } from 'angular-ui-router';
import ActivityMedService from '../../../services/ActivityMedicationService';
import PESService from '../../../services/PESService';
import * as R from 'ramda';
import { hasLength } from '../../../utils';
import isExpired from '../../../utils/isExpired/isExpired';
import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted';

/** @ngInject */
function MedicationAssociationController(_, $filter, pesService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.groupByStatus = groupByStatus;
    ctrl.deleteAssociatedMedication = deleteAssociatedMedication;
    ctrl.createMedicationAssociation = createMedicationAssociation;
    ctrl.options = [];
    ctrl.selectedOptions = [];
    ctrl.filterOptions = filterOptions;
    ctrl.hasNewRxFillNumberEnabled = isPermitted(PERMISSION_TYPES.ENABLE_RX_FILL_NUMBERS, ctrl.user);

    // Available option blacklist (selected options)
    let selectedKeys = [];

    const isStatusDateActive = (statusDate) => {
        if (statusDate) {
            return !isExpired(statusDate);
        }
        return true;
    };

    const isActiveMedStatus = R.compose(isStatusDateActive, R.path([0, 'end']));

    const isActiveRx = R.compose(isStatusDateActive, R.prop('end'));

    /**
     * Single point of logic to calculate the display medication name
     * @param {string} dispensedMedicationName
     * @param {string} displayName
     * @param {string} medicationName
     * @returns
     */
    const calculateDisplayName = (dispensedMedicationName, displayName, medicationName, rx, rxFillId = 0) => {
        let medName = medicationName || '';
        if (dispensedMedicationName && dispensedMedicationName.trim() !== '') {
            medName = dispensedMedicationName;
        } else if (displayName && displayName.trim() !== '') {
            medName = displayName;
        }

        if (rx && rx.rxNumber) {
            medName += ` - ${rx.rxNumber}`;
            if (rx?.rxFill?.length && ctrl.hasNewRxFillNumberEnabled) {
                const recentFill = rxFillId
                    ? _.find(rx.rxFill, (fill) => {
                          return fill.id === rxFillId;
                      })
                    : _.orderBy(rx.rxFill, ['createdOn', 'fillNumber'], ['desc', 'desc'])[0];
                medName += ` - ${recentFill.fillNumber}`;
            }
        }

        return medName;
    };

    function getSelectedOptions(medicationAssociations, patientMedications) {
        return R.compose(
            // eslint-disable-next-line no-unused-vars
            R.reduce((result, [_s, associations]) => {
                if (associations.length > 1) {
                    return R.compose(
                        R.concat(result),
                        R.last(),
                        R.tap(([rejected]) => {
                            Promise.all(
                                R.map(
                                    (option) =>
                                        ActivityMedService.deleteMedicationAssociation(
                                            ctrl.patient.id,
                                            ctrl.activity.id,
                                            option.id
                                        ),

                                    rejected
                                )
                            );
                        }),
                        R.partition(R.propEq(null, 'rxNumberId'))
                    )(associations);
                }
                return R.concat(result, associations);
            }, []),
            R.toPairs,
            R.groupBy(R.prop('patientMedicationId')),
            R.map((selectedOption) => {
                const { id, medication, patientMedicationId, rxNumberId, rxFillId, rx } = selectedOption;
                const dispensedMedicationName = R.prop('dispensedMedication')(rx);
                const patientMedication = patientMedications
                    ? R.propOr({}, patientMedicationId)(patientMedications)
                    : {};
                const medicationDisplayName = R.pathOr('', ['medication', 'displayName'])(patientMedication);

                const formattedSelectedOption = {
                    id,
                    patientMedicationId,
                    rxNumberId,
                    rxFillId: rxFillId || 0,
                    displayName: calculateDisplayName(
                        dispensedMedicationName,
                        medicationDisplayName,
                        medication.medicationName,
                        rx,
                        rxFillId
                    ),
                    dispensedMedication: R.prop('dispensedMedication')(rx),
                };

                return formattedSelectedOption;
            }),
            R.values
        )(medicationAssociations);
    }

    const getRxReducer = R.curry((med, patientMedicationsAndRxs, result, rx) => {
        if (rx.rxNumber) {
            const { id, medication } = med;
            const matchingMedRx = R.find(R.propEq(rx.id, 'id'), patientMedicationsAndRxs[medication.id].rxs);
            const dispensedMedicationName = R.prop('dispensedMedication', matchingMedRx);
            const medDisplayName = R.prop('displayName', patientMedicationsAndRxs[medication.id]);
            const medicationName = R.prop('name', medication);

            const patientMedicationRx = {
                displayName: calculateDisplayName(dispensedMedicationName, medDisplayName, medicationName, rx),
                patientMedicationId: id,
                name: medication.name,
                rxNumberId: rx.id,
                rxFillId:
                    rx?.rxFill?.length && ctrl.hasNewRxFillNumberEnabled
                        ? _.orderBy(rx.rxFill, ['createdOn', 'fillNumber'], ['desc', 'desc'])[0].id
                        : 0,
                active: isActiveRx(rx),
            };

            patientMedicationRx.key = formatKey(patientMedicationRx);

            return R.append(patientMedicationRx, result);
        }
        return result;
    });

    const getAvailableRx = R.curry((med, patientMedicationsAndRxs) => {
        const { rxs } = med;

        if (hasLength(rxs)) {
            return R.reduce(getRxReducer(med, patientMedicationsAndRxs), [], rxs);
        }
        return [];
    });

    const getAvailableMed = R.curry((patientMedicationsAndRxs, result, med) => {
        const rx = getAvailableRx(med, patientMedicationsAndRxs);
        const { id, medication, status } = med;

        const medicationObj = {
            displayName: medication.displayName,
            patientMedicationId: id,
            name: medication.name,
            active: isActiveMedStatus(status),
        };

        medicationObj.key = formatKey(medicationObj);

        const processedRx = R.ifElse(
            R.always(!medicationObj.active),
            R.map(R.assoc('active', false)), // Mark all RX entries as inactive if medication is inactive
            R.identity
        )(rx);

        // Include medicationObj in the result if there is no rx or if all of the rx's are ended but medication is active
        const updatedMedicationObj = R.ifElse(
            () => R.isEmpty(rx) || (R.all(R.propEq(false, 'active'), rx) && medicationObj.active),
            R.always([medicationObj]),
            R.always([])
        )();

        return R.compose(R.concat(result), () => R.concat(updatedMedicationObj, processedRx))();
    });

    function getAvailableOptions(patientMedications, patientMedicationsAndRxs) {
        return R.compose(
            R.sortBy(R.propOr(R.prop('name'), 'displayName')),
            R.reduce(getAvailableMed(patientMedicationsAndRxs), []),
            R.values
        )(patientMedications);
    }

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            let assessmentSettings = _.get(ctrl.activity, 'assessment.settings.settings', false);
            assessmentSettings = angular.fromJson(assessmentSettings);
            ctrl.requiredMedicationAssociation = assessmentSettings.requireMedicationAssociation;
        }

        if (changes.patient && ctrl.patient && ctrl.patient.id) {
            ctrl.patient = angular.copy(ctrl.patient);
            return $q
                .all({
                    medicationAssociations: ActivityMedService.getMedicationAssociations(
                        ctrl.patient.id,
                        ctrl.activity.id
                    ),
                    patientMedications: pesService.getMedication(ctrl.patient.id, {
                        includeRx: true,
                        includeInactiveRx: true,
                    }),
                    patientMedicationsAndRxs: PESService.getPatientRx(ctrl.patient.id),
                })

                .then((res) => {
                    // Setup display options
                    ctrl.selectedOptions = getSelectedOptions(res.medicationAssociations, res.patientMedications);
                    selectedKeys = ctrl.selectedOptions.map(formatKey);
                    ctrl.options = getAvailableOptions(res.patientMedications, res.patientMedicationsAndRxs);
                    const singleMedKey = R.compose(formatKey, R.head)(ctrl.options);

                    // If there is only one possible option to associate, associate it by default.
                    if (ctrl.options.length === 1 && !R.includes(singleMedKey, selectedKeys)) {
                        const [singleMedicationAssociation] = ctrl.options;

                        return Promise.all([
                            // First, remove the existing associations
                            ...R.map(
                                (option) =>
                                    ActivityMedService.deleteMedicationAssociation(
                                        ctrl.patient.id,
                                        ctrl.activity.id,
                                        option.id
                                    ),
                                ctrl.selectedOptions
                            ),

                            // Associate the new item.
                            ActivityMedService.createMedicationAssociation(
                                ctrl.patient.id,
                                ctrl.activity.id,
                                singleMedicationAssociation
                            ).then((association) => {
                                const { id } = association;
                                ctrl.selectedOptions = [
                                    {
                                        ...singleMedicationAssociation,
                                        id,
                                    },
                                ];
                                selectedKeys = [singleMedKey];
                            }),
                        ]);
                    }
                    return $q.when();
                });
        }
        return $q.when();
    }

    function groupByStatus(item) {
        return item.active ? 'Active' : 'Inactive';
    }

    function formatKey(patientMedication) {
        return `${patientMedication.patientMedicationId}${patientMedication.rxNumberId || ''}${
            patientMedication.rxFillId || ''
        }`;
    }

    function filterOptions(option) {
        if (R.path(['patientMedicationId'], option)) {
            return selectedKeys.indexOf(formatKey(option)) === -1;
        }
        return false;
    }

    function deleteAssociatedMedication(medicationAssociation) {
        ActivityMedService.deleteMedicationAssociation(
            ctrl.patient.id,
            ctrl.activity.id,
            medicationAssociation.id
        ).then(() => {
            const keyToDelete = formatKey(medicationAssociation);
            // Index of removed item from
            const index = selectedKeys.findIndex((selectedOptionKey) => {
                return selectedOptionKey === keyToDelete;
            });
            if (index > -1) {
                selectedKeys.splice(index, 1);
            }
        });
    }

    function createMedicationAssociation(medicationAssociation) {
        return ActivityMedService.createMedicationAssociation(
            ctrl.patient.id,
            ctrl.activity.id,
            medicationAssociation
        ).then((res) => {
            const { patientMedicationId, rxNumberId = '', rxFillId = '' } = res;
            const createdMedicationAssociationKey = formatKey({ patientMedicationId, rxNumberId, rxFillId });
            selectedKeys.push(createdMedicationAssociationKey);
            const index = ctrl.selectedOptions.findIndex((selectedOption) => {
                const key = formatKey(selectedOption);
                return key === createdMedicationAssociationKey;
            });
            ctrl.selectedOptions[index].id = res.id;
        });
    }
}

export default MedicationAssociationController;
