import { getTherapies } from 'App/services/AdminService';
import { errorHandler } from 'App/utils';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaPlus } from 'react-icons/fa';
import { Link, useRouteMatch } from 'react-router-dom';
import { catchPhrase } from '../config/config';
import PageHeader from '../styles/PageHeader/PageHeader';
import PageTitle from '../styles/PageTitle/PageTitle';
import PageWrapper from '../styles/PageWrapper/PageWrapper';
import TherapyTable from './TherapyTable';

const propTypes = {};

const defaultProps = {};

function CategoryListPage() {
    //#region State
    const { path } = useRouteMatch();
    const [therapies, setTherapies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    //#endregion

    //#region Side Effects
    useEffect(() => {
        getTherapies()
            .then(
                R.compose(
                    R.tap(() => setIsLoading(false)),
                    setTherapies,
                    R.sortBy(R.prop('name')),
                    R.values
                )
            )
            .catch((error) => {
                errorHandler(error);
                setIsLoading(false);
            });
    }, []);
    //#endregion

    return (
        <>
            <Helmet>
                <title>Therapeutic Categories - {catchPhrase}</title>
            </Helmet>

            <PageTitle>Therapeutic Categories</PageTitle>

            <PageWrapper>
                <PageHeader style={{ justifyContent: 'flex-end' }}>
                    <Link to={`${path}/create`} className="btn btn-primary">
                        <FaPlus /> Create Therapeutic Category
                    </Link>
                </PageHeader>

                <TherapyTable data={therapies} isLoading={isLoading} />
            </PageWrapper>
        </>
    );
}

CategoryListPage.propTypes = propTypes;
CategoryListPage.defaultProps = defaultProps;

export default CategoryListPage;
