import { useMutation } from 'react-query';
import { updateReferralMedication } from 'App/services/RMSService';

const useUpdateReferralMedication = () => {
    return useMutation(({ patientId, referralId, medicationId, body }) =>
        updateReferralMedication(patientId, referralId, medicationId, body)
    );
};

export default useUpdateReferralMedication;
