import _ from 'lodash';

/** @ngInject */
function MonographLinkController($uibModal) {
    const ctrl = this;

    ctrl._ = _;
    ctrl.$onChanges = $onChanges;
    ctrl.openMedicalModal = openMedicalModal;

    function $onChanges(changes) {
        if (changes.contentResourceName && ctrl.contentResourceName) {
            ctrl.resourceName = ctrl.contentResourceName;
        }
        if (changes.contentResourceValue && ctrl.contentResourceValue) {
            ctrl.resourceValue = ctrl.contentResourceValue;
        }
        if (changes.contentResourceLabel && ctrl.contentResourceLabel) {
            ctrl.resourceLabel = ctrl.contentResourceLabel;
        }
    }

    function openMedicalModal(contentResourceLabel, contentResourceName) {
        $uibModal.open({
            backdrop: 'static',
            component: 'assessmentContentModal',
            openedClass: 'assessment-content-modal',
            windowClass: 'assessment-content-modal',
            windowTopClass: 'assessment-content-modal',
            keyboard: false,
            size: 'lg',
            resolve: {
                modalData() {
                    return {
                        label: _.camelCase(contentResourceLabel),
                        name: _.camelCase(contentResourceName),
                        versionDate: null,
                    };
                },
            },
        });
    }
}

export default MonographLinkController;
