/**
 * This service is responsible for taking URL parameters and putting them into a usable object.
 * @todo Remove once we switch to ng-route
 */
/** @ngInject */
function ParamsService($location) {
    const search = $location.search.substring(1);
    let params = {};
    if (search.length > 0) {
        /* jslint validate:tru */
        params = JSON.parse(
            `{"${decodeURI(search)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/([a-zA-Z0-9-_]+)=([a-zA-Z0-9-_=]+)/g, '$1":"$2')}"}`
        );
    }
    return params;
}

export default ParamsService;
