import React from 'react';
import { Button, Col, ModalBody, ModalFooter, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import SelectField from 'Lib/form/SelectField';
import * as Yup from 'yup';
import useCreateReferralMutation from 'App/hooks/useCreateReferralMutation';
import { toast } from 'react-toastify';
import { REFERRAL_TYPES } from 'App/features/ProtocolManager/constants';
import useCreateReferralActivityMutation from 'App/hooks/useCreateReferralActivityMutation';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    patientId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    medications: PropTypes.array,
};

const defaultProps = {
    isLoading: false,
    medications: [],
};

const validationSchema = Yup.object().shape({
    medication: Yup.object().required().nullable().label('Medication'),
    activities: Yup.array().min(1).required().nullable().label('Activities'),
});

function getOptionValue(option) {
    return `${option.patient_medication_id}-${option.rx_number}`;
}

const activityOptions = R.compose(
    R.sortBy(R.prop('label')),
    R.map(([value, label]) => {
        return { value, label };
    }),
    R.toPairs,
    // filter out Appeal
    R.dissoc(3)
)(REFERRAL_TYPES);

function ReferralProtocolForm({ onHide, patientId, isLoading, medications }) {
    const createReferralMutation = useCreateReferralMutation();
    const createReferralActivityMutation = useCreateReferralActivityMutation();
    const modifiedActivityOptions = R.reject(
        R.anyPass([R.propEq('4', 'value'), R.propEq('5', 'value')]),
        activityOptions
    );

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{
                medication: null,
                activities: null,
            }}
            onSubmit={(values) => {
                return createReferralMutation
                    .mutateAsync({
                        patientId,
                        medication_creates_referral: false,
                        medications: [
                            R.applySpec({
                                medication_id: R.prop('medication_id'),
                                rx_number_id: R.prop('id'),
                            })(values.medication),
                        ],
                    })
                    .then((referral) => {
                        toast.success('The referral was created successfully.');
                        return Promise.all(
                            values.activities.map((activity) => {
                                return createReferralActivityMutation.mutateAsync({
                                    patientId,
                                    referralId: referral.id,
                                    body: {
                                        details: {},
                                        medication: [{ medication_id: values.medication.medication_id }],
                                        type: activity.value,
                                    },
                                });
                            })
                        );
                    })
                    .then(() => {
                        if (values.activities.length === 1) {
                            toast.success('The referral activity was created successfully.');
                        } else {
                            toast.success('The referral activities were created successfully.');
                        }
                    })
                    .then(onHide);
            }}
        >
            {({ handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <SelectField
                                    required
                                    getOptionValue={getOptionValue}
                                    isLoading={isLoading}
                                    options={medications}
                                    label="Medication"
                                    fieldName="medication"
                                    onChange={(option) => {
                                        setFieldValue('medication', option);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <SelectField
                                    required
                                    options={modifiedActivityOptions}
                                    isMulti
                                    label="Select activity(s) to trigger upon referral creation"
                                    fieldName="activities"
                                    onChange={(option) => {
                                        setFieldValue('activities', option);
                                    }}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <FormButtonGroup>
                            <Button onClick={onHide}>Cancel</Button>
                            <SubmitButton>Add Referral</SubmitButton>
                        </FormButtonGroup>
                    </ModalFooter>
                </form>
            )}
        </Formik>
    );
}

ReferralProtocolForm.propTypes = propTypes;
ReferralProtocolForm.defaultProps = defaultProps;

export default ReferralProtocolForm;
