import { useMutation, useQueryClient } from 'react-query';
import { createReferral } from 'App/services/RMSService';

const useCreateReferralMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(({ patientId, ...body }) => createReferral(patientId, body), {
        onSuccess: (referral) => {
            /**
             * Referral, here, is using an older format, which doesn't match the new one. Appending it to the state
             * will result in an issue where the keys are mismatched. Instead, just refetch, but in the future, we
             * should just append the new item.
             */
            return queryClient.refetchQueries(['patient', referral.patient_id, 'referrals']);
        },
    });
};

export default useCreateReferralMutation;
