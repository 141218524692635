import PESService from 'App/services/PESService';
import useProfileDataState from './useProfileDataState';
import CompanyService from '../../../services/CompanyService';
import { shouldSyncPatient } from '../../../services/EhrService';
import * as R from 'ramda';
import { useEffect } from 'react';

/**
 * Custom hook which make all the http call to fetch patient Medication required to render patient detail page
 * @param {string} patientId
 * @returns {Object}
 */
function usePatientEhr(patientId) {
    const {
        data: ehr,
        setData: setEhr,
        isLoading: isEhrLoading,
        triggerRefetch: triggerEhrRefetch,
        refetch,
        setIsLoading,
    } = useProfileDataState();

    useEffect(() => {
        CompanyService.getEhrBranchSettings(patientId)
            .then((branchSettings) => {
                setEhr(R.assoc('branchSettings', branchSettings));
                if (branchSettings.ehrType) {
                    let syncResult;
                    let ehrIdentifiers;
                    let ehrVisits;
                    // eslint-disable-next-line no-unused-vars
                    let patientEhrIdentifier;
                    const promises = [PESService.getIdentifiers(patientId), PESService.getVisits(patientId)];
                    return Promise.all(promises).then(async ([identifiers, visits]) => {
                        ehrIdentifiers = R.clone(identifiers);
                        ehrVisits = R.clone(visits);
                        const shouldSync = shouldSyncPatient(Object.values(identifiers), visits, branchSettings);
                        if (!shouldSync) {
                            setEhr(R.assoc('patientSynced', true));
                            return;
                        }
                        try {
                            syncResult = await PESService.syncPatientWithEhr(patientId);
                            ehrIdentifiers = R.mergeDeepLeft(identifiers, syncResult.identifiers);
                            patientEhrIdentifier = ehrIdentifiers.find(
                                (i) => i.identifierType === branchSettings.ehrPrimaryIdType
                            );
                            ehrVisits = R.mergeDeepLeft(visits, syncResult.visits);
                        } catch (error) {
                            setEhr(R.assoc('syncingWithEhr', false));
                            throw error;
                        } finally {
                            const doesExist = R.compose(R.not, R.either(R.isEmpty, R.isNil));
                            const isSynced = R.allPass([
                                R.propSatisfies(doesExist, 'patientEhrIdentifier'),
                                R.anyPass([
                                    R.propSatisfies(doesExist, 'ehrVisits'),
                                    R.complement(R.propSatisfies(R.identity, 'visitRequired')),
                                ]),
                            ]);

                            const visitRequired = branchSettings.ehrNoteRequiresVisit;
                            setEhr(
                                R.compose(
                                    R.assoc(
                                        'patientSynced',
                                        shouldSync ? isSynced({ ehrIdentifiers, ehrVisits, visitRequired }) : false
                                    ),
                                    R.assoc('syncingWithEhr', false)
                                )
                            );
                        }
                    });
                }
            })
            .catch((error) => {
                // silently catch errors from the FHIR sync. We don't want to erroneously mark the patient
                // as ineligible if they have identifiers and visits, but the patient sync returns an error code
                // (such as not having an FHIR identifier)
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [patientId, refetch, setEhr, setIsLoading]);

    return {
        ehr,
        isEhrLoading,
        triggerEhrRefetch,
    };
}

export default usePatientEhr;
