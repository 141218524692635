import * as R from 'ramda';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import styled from '@emotion/styled';
import { v4 as uuid } from 'uuid';
import { hasLength } from '../../utils';
import { StyledIcon, StyledTooltip } from './styles';

const riskSegmentPropType = PropTypes.shape({
    action: PropTypes.string,
    createdOn: PropTypes.string,
    riskSegment: PropTypes.string,
});

const propTypes = {
    riskSegments: PropTypes.arrayOf(riskSegmentPropType),
    showAll: PropTypes.bool,
    showFlag: PropTypes.bool,
    showOnHover: PropTypes.bool,
};
const defaultProps = {
    riskSegments: [],
    showAll: true,
    showFlag: true,
    showOnHover: false,
};

const StyledSpan = styled.span`
    color: var(--brandDanger);

    ul {
        list-style-type: none;
        margin-bottom: 0;
        padding: 4px;
    }

    li {
        margin-bottom: 3px;
    }

    li:not(:first-of-type) {
        padding-left: 23px;
    }
`;

const PatientPredictiveModelRiskText = ({ riskSegments, showAll, showFlag, showOnHover }) => {
    const tooltipId = useRef(uuid());

    const renderFlag = () =>
        showFlag ? (
            <StyledIcon
                style={{ marginRight: '8px' }}
                className="fa fa-fw fa-flag"
                aria-label="Predictive Model Risk Flag"
            />
        ) : null;

    const renderTooltip = () => (
        <StyledTooltip id={tooltipId.current} key={tooltipId.current}>
            <ul>
                {R.compose(
                    R.addIndex(R.map)((reason, index) => (
                        <li key={`${tooltipId.current}-${index}`}>
                            <strong>{reason.riskSegment}</strong>
                            {reason.action ? ` - ${reason.action}` : ''}
                        </li>
                    ))
                )(riskSegments)}
            </ul>
        </StyledTooltip>
    );

    const renderText = () => {
        let showTooltip = false;
        let predictiveRiskText;
        if (showAll) {
            // show the list of risk segments in the patient profile and assessment sidebar
            predictiveRiskText = (
                <ul>
                    {R.compose(
                        R.addIndex(R.map)((reason, index) => (
                            <li key={`predictive-risk-reason-${index}`}>
                                {index === 0 && renderFlag()}
                                <strong>{reason.riskSegment}</strong>
                                {reason.action && ` - ${reason.action}`}
                            </li>
                        ))
                    )(riskSegments)}
                </ul>
            );
        } else {
            // Show the top 1 risk segment in the work queue table and truncate the total length to 45 characters and add ellipsis if longer
            const riskSegment = riskSegments[0].riskSegment || '';
            let action = riskSegments[0].action || '';
            const combinedText = action ? `${riskSegment} - ${action}` : riskSegment;
            const maxCharacterLimit = 45;
            // show tooltip if the text is truncated or there are more than 1 risk segments to show
            showTooltip = combinedText.length > maxCharacterLimit || riskSegments.length > 1;
            if (combinedText.length > maxCharacterLimit) {
                const segmentLength = riskSegment.length || 0;
                const actionLengthToTruncate = maxCharacterLimit - segmentLength - 3; //  3 characters for " - "
                action = `${action.slice(0, actionLengthToTruncate)}...`;
            }
            predictiveRiskText = (
                <>
                    {renderFlag()}
                    <strong>{riskSegment}</strong>
                    {action && ` - ${action}`}
                </>
            );
        }

        const renderRiskSegments = () => <StyledSpan>{predictiveRiskText}</StyledSpan>;

        return showTooltip ? (
            <OverlayTrigger
                trigger={showOnHover ? ['hover', 'focus'] : 'click'}
                placement="right"
                rootClose
                overlay={renderTooltip()}
            >
                {renderRiskSegments()}
            </OverlayTrigger>
        ) : (
            renderRiskSegments()
        );
    };

    return <span className="patient-predictive-risk-indicator">{hasLength(riskSegments) ? renderText() : null}</span>;
};

PatientPredictiveModelRiskText.propTypes = propTypes;
PatientPredictiveModelRiskText.defaultProps = defaultProps;

export default PatientPredictiveModelRiskText;
