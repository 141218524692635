import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import Pako from 'pako';

import { getSlsBasePath, stripAllHtml } from 'App/utils';
import createAxios from 'App/utils/createAxios';
import downloadFileByBlob from 'App/utils/downloadFileByBlob';
import config from '../components/config/config';

const { amsEndpoint, amsPort, amsVersion } = config;
const baseUrl = `${amsEndpoint}:${amsPort}/ams/${amsVersion}`;

const ams = createAxios({
    baseURL: baseUrl,
});

const amsSls = createAxios({
    baseURL: `${getSlsBasePath(config, 'ams')}/${config.slsVersion}`,
});

const specialQuestionNames = [
    'activity_date',
    'need_by_date',
    'therapy_start_date',
    'cost_savings',
    'assessment_status',
];

export function getActivity(patientId, activityId) {
    return ams.get(`/patient/${patientId}/activity/${activityId}`, {
        params: { includeAssessment: true },
    });
}

export function getActivityQuestions(activityId) {
    return ams.get(`/activity/${activityId}/questions`);
}

export function getActivityQuestionsById(activityId, questionIds) {
    return ams.post(`/activity/${activityId}/questions`, { questionIds });
}

export function getAssessment(assessmentId) {
    return ams.get(`/assessment/${assessmentId}`);
}

export function getAssessmentQuestionsById(assessmentId, questionIds) {
    return ams.post(`/assessment/${assessmentId}/questions`, { questionIds });
}

export function getQuestionGroups() {
    return ams.get('/questionGroups');
}

export function saveResponse(activityId, questionId, response) {
    const body = {
        response: stripAllHtml(response),
        clientSubmissionTimestamp: dayjs().valueOf(),
    };
    return ams.put(`/activity/${activityId}/response/${questionId}`, body);
}

export function saveStatus(activityId, status) {
    return ams.patch(`/activity/${activityId}/status`, { status });
}

export function sendActivityUpdate(patientId, activityId, completeActivityAction) {
    return amsSls.post(`/patient/${patientId}/activity/${activityId}/sendActivityUpdate`, { completeActivityAction });
}

export function saveEhrNote(activityId, payload) {
    return ams.post(`/activity/${activityId}/ehrEncounterNote`, payload);
}

export async function generatePdf(assessmentMarkup, assessmentType, assessmentName, activityId) {
    const compressed = Pako.deflate(assessmentMarkup, { to: 'string' });
    const base64String = btoa(compressed);

    const res = await ams.post(
        '/pdf/generate',
        {
            assessmentCompressed: base64String,
            assessmentType,
        },
        {
            responseType: 'arraybuffer',
        }
    );

    const date = dayjs().format('YYYY-MM-DD').toString();
    const filename = `${assessmentName.split(' ').join('-')}-${activityId && `${activityId}-`}${date}.pdf`;

    downloadFileByBlob(res, filename);
}

export function attachChildren(questions, allQuestions = questions) {
    const childQuestionLookup = groupBy(allQuestions, 'parentQuestionId');
    return Object.values(questions).map((question) => {
        const children = childQuestionLookup[question.questionId];
        return {
            ...question,
            children: children ? attachChildren(children, allQuestions) : [],
        };
    });
}

export function splitQuestions(questions) {
    const splitResult = questions.reduce(
        (splitAgg, question) => {
            if (specialQuestionNames.includes(question.name)) {
                splitAgg.specialQuestions.push(question);
                return splitAgg;
            }
            if (question.name === 'therapy_summary_note') {
                splitAgg.therapySummaryNote.push(question);
                return splitAgg;
            }
            if (question.attributes.group_id) {
                splitAgg.groupedQuestions.push(question);
                return splitAgg;
            }
            if (question.parentQuestionId === null) {
                splitAgg.questions.push(question);
                return splitAgg;
            }
            return splitAgg;
        },
        {
            specialQuestions: [],
            therapySummaryNote: [],
            groupedQuestions: [],
            questions: [],
        }
    );

    splitResult.specialQuestions.sort((a, b) => {
        return specialQuestionNames.indexOf(a.name) - specialQuestionNames.indexOf(b.name);
    });

    splitResult.groupedQuestions = groupBy(splitResult.groupedQuestions, 'attributes.group_id');

    return splitResult;
}
