/** @ngInject */
function AccessViewController($rootScope, $stateParams, toastr, authService, userService) {
    const ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.$onDestroy = $onDestroy;
    ctrl.setBranch = setBranch;
    ctrl.setDefaultBranch = setDefaultBranch;

    function $onInit() {
        ctrl.branches = {};
        ctrl.branchList = [];
        ctrl.branchesLoading = true;
        ctrl.defaultSetSuccessfully = false;
        ctrl.openDescription = false;
        ctrl.resetDefaultBranch = $stateParams.resetDefaultBranch;

        userService
            .getBranches()
            .then((branches) => {
                ctrl.branchesLoading = false;
                ctrl.branches = branches;
                ctrl.branchList = Object.values(branches);
                return ctrl.branches;
            })
            .catch((err) => {
                ctrl.branchesLoading = false;
                ctrl.branches = [];
                ctrl.branchList = [];
                return err;
            });
    }

    function $onDestroy() {
        // dismiss toasters if any before navigating from angular to react pages
        toastr.clear();
    }

    function setBranch(id) {
        return authService.changeBranch(id).then((res) => {
            toastr.success('Branch has been changed successfully.');
            return res;
        });
    }

    function setDefaultBranch(id) {
        return userService
            .setDefaultBranch(id)
            .then(() => {
                return userService.setBranch(id);
            })
            .then(() => {
                return authService.updateJwt();
            })
            .then(() => {
                return authService.getUser();
            })
            .then((res) => {
                ctrl.resetDefaultBranch = undefined;
                toastr.success('Default branch has been set successfully.');
                $rootScope.$emit('updateUser', res);
                return res;
            });
    }
}

export default AccessViewController;
