import PropTypes from 'prop-types';
import prescriptionsPropType from './prescription';

const medicationPropType = PropTypes.shape({
    createdOn: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    employeeId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    patientId: PropTypes.string.isRequired,
    therapy: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    medication: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        groupId: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
    }).isRequired,
    createdBy: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            patientMedicationId: PropTypes.string.isRequired,
            startedBy: PropTypes.string.isRequired,
            start: PropTypes.string,
            endedBy: PropTypes.string.isRequired,
            end: PropTypes.string,
            endReason: PropTypes.string,
        })
    ).isRequired,
    prescriptions: prescriptionsPropType,
});

const medicationsPropType = PropTypes.arrayOf(medicationPropType);

export default medicationsPropType;
