import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { errorHandler } from 'App/utils';
import PropTypes from 'prop-types';

const propTypes = {
    paramFirstName: PropTypes.string,
    paramLastName: PropTypes.string,
};

const defaultProps = {
    paramFirstName: '',
    paramLastName: '',
};

/**
 * When search params from the header are submitted, we need to make sure they are put into the form.
 */
function UpdateSearchParams({ paramFirstName, paramLastName }) {
    const { setFieldValue, submitForm } = useFormikContext();

    useEffect(() => {
        // to handle patient search by name in the top nav
        if (paramLastName) {
            setFieldValue('searchParams.firstName', paramFirstName || '');
            setFieldValue('searchParams.lastName', paramLastName);
            submitForm().then().catch(errorHandler);
        }
    }, [paramFirstName, paramLastName, setFieldValue, submitForm]);

    return null;
}

UpdateSearchParams.propTypes = propTypes;
UpdateSearchParams.defaultProps = defaultProps;

export default UpdateSearchParams;
