import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import { Formik } from 'formik';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';
import usePublishProtocolTemplateMutation from 'App/hooks/usePublishProtocolTemplateMutation';
import { useBuilderContext } from '../BuilderContext';

const propTypes = {};

const defaultProps = {};

function PublishTemplateModal() {
    const { itemToPublish, setItemToPublish } = useBuilderContext();
    const publishTemplate = usePublishProtocolTemplateMutation();

    if (itemToPublish) {
        return (
            <Modal backdrop="static" show>
                <ModalHeader>
                    <ModalTitle>Publish Protocol Template</ModalTitle>
                </ModalHeader>

                <ModalBody>Are you sure you wish to publish this template?</ModalBody>
                <ModalFooter>
                    <Formik
                        initialValues={{
                            confirm: true,
                        }}
                        isInitialValid
                        onSubmit={() => {
                            return publishTemplate.mutateAsync(itemToPublish.uuid).then(() => setItemToPublish(null));
                        }}
                    >
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <FormButtonGroup>
                                    <Button type="button" onClick={() => setItemToPublish(null)}>
                                        Cancel
                                    </Button>
                                    <SubmitButton ignoreDirty>Publish</SubmitButton>
                                </FormButtonGroup>
                            </form>
                        )}
                    </Formik>
                </ModalFooter>
            </Modal>
        );
    }

    return null;
}

PublishTemplateModal.propTypes = propTypes;
PublishTemplateModal.defaultProps = defaultProps;

export default PublishTemplateModal;
