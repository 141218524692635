import styled from '@emotion/styled';

const PageWrapper = styled('div')`
    background: white;
    padding: 20px;
    min-height: calc(100vh - 50px - 38px - 50px);
    margin-right: auto;
    margin-left: auto;
`;

export default PageWrapper;
