import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import useFetchBranchesQuery from 'App/hooks/useFetchBranchesQuery';
import SelectField from 'Lib/form/SelectField';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
};

const defaultProps = {
    value: null,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    onChange: () => {},
};

function BranchSelect({ onChange, value }) {
    const { isLoading, data } = useFetchBranchesQuery();

    const branches = useMemo(() => {
        return data ? R.compose(R.sortBy(R.prop('name')), R.values)(data) : [];
    }, [data]);

    return (
        <SelectField
            fieldName="searchParams.branch"
            options={branches}
            label="Branches"
            getOptionValue={R.prop('id')}
            getOptionLabel={R.prop('name')}
            isClearable
            onChange={(option) => {
                onChange(option);
            }}
            value={value}
            isLoading={isLoading}
        />
    );
}

BranchSelect.propTypes = propTypes;
BranchSelect.defaultProps = defaultProps;

export default BranchSelect;
