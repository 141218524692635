/**
 * @ngdoc directive
 * @name forgotPassword
 * @module components.auth
 *
 * @description
 *
 * This is the forgotPassword component responsible for handling the forgot password form.
 **/
/** @ngInject */
function ForgotPasswordController($http, $httpParamSerializerJQLike, __env) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.submit = submit;
    ctrl.emailRegex = __env.emailRegex;

    /**
     * @ngdoc method
     * @name forgotPasswordController#activate
     *
     * @param {event} event Receive the emitted event
     * Updates the contact information
     *
     * @return {method} ContactService returns the updateContact method and a promise
     */
    function $onInit() {
        ctrl.formData = {
            next: '',
            forgot_password: 'Change Password',
        };
        ctrl.success = null;
    }

    /**
     * @summary Handles the forgot password form submit
     * @description
     * Responsible for generating a reset token and emailing the user instructions on how to reset their password.
     * @returns {Promise} Resolves if successful; rejects otherwise
     */
    function submit() {
        ctrl.success = null;

        if (ctrl.forgotPassword.$invalid) {
            return false;
        }

        ctrl.loading = true;

        return $http({
            method: 'POST',
            url: '/stm/login.php',
            data: $httpParamSerializerJQLike(ctrl.formData),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        })
            .then((res) => {
                ctrl.success = `An email has been sent to ${ctrl.formData.email} with further instructions.`;
                ctrl.forgotPassword.$setPristine();
                ctrl.formData.email = undefined;
                return res;
            })
            .catch((err) => {
                ctrl.error = err.data.message;
                return err;
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }
}

export default ForgotPasswordController;
