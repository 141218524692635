import React, { useMemo } from 'react';
import { CheckboxField } from 'Lib/form/CheckboxField';
import { CheckboxMultiField } from 'Lib/form/CheckboxMultiField';
import { useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import useFetchTherapeuticCategory from '../../hooks/useFetchTherapeuticCategory';

const TherapeuticCategoryFilter = () => {
    const { data: availableTherapies } = useFetchTherapeuticCategory();
    const {
        values: {
            filterParams: { allCategories, categories },
        },
    } = useFormikContext();

    const options = useMemo(() => {
        return sortBy(
            Object.values(availableTherapies || {}).map((opt) => ({ id: opt.id, option: opt.name })),
            'option'
        );
    }, [availableTherapies]);

    return (
        <div className="filter form-group">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="control-label filter-header">Therapeutic Category</label>
            <CheckboxField fieldName="filterParams.allCategories" label="All Therapeutic Categories" />
            {!allCategories && (
                <CheckboxMultiField fieldName="filterParams.categories" value={categories} options={options} />
            )}
        </div>
    );
};

export default TherapeuticCategoryFilter;
