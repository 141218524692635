/** @ngInject */
function ResetPasswordConfig($stateProvider) {
    $stateProvider.state({
        name: 'auth.resetPassword',
        url: '/resetPassword',
        component: 'resetPassword',
    });
}

export default ResetPasswordConfig;
