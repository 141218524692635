import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import RequiredDisabledFieldWrapper from 'App/components/PatientForm/components/CommonPatientFieldWrapper';
import { PhoneField } from 'App/components/PatientForm/components/PhoneField';
import { CheckboxField } from 'Lib/form/CheckboxField';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { NumberField } from 'Lib/form/NumberField';
import SelectField from 'Lib/form/SelectField';
import { TextField } from 'Lib/form/TextField';

const coverageTypeOptions = [
    'Buy and Bill',
    'Cash',
    'Financial Assistance',
    'Foundation Assistance',
    'Medical',
    'None',
    'Pharmacy',
];

const payerSegmentOptions = [
    'Auto Insurance',
    'Charity/Indigent Program',
    'Government',
    'HMO/Commercial',
    'Integrated PBM',
    'Managed Medicaid',
    'Medicaid',
    'Medicare',
    'Medicare A',
    'Medicare Advantage (Part C)',
    'Medicare B',
    'Medicare D',
    'None',
    'Other',
    'PPO/Commercial',
    'Prisoners',
    'Self-Pay',
    'Tri-Care',
    'Unknown',
    'Workers Comp',
];

const yesNoOptions = ['No', 'Yes'];

const InsuranceFormArea = ({ fieldPrefix }) => {
    const { values } = useFormikContext();
    const insuranceValues = get(values, fieldPrefix);
    return (
        <div>
            <Row>
                <RequiredDisabledFieldWrapper fieldName="planName">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField
                                {...commonProps}
                                fieldName={`${fieldPrefix}name`}
                                label="Plan Name"
                                placeholder="Plan Name"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="patientId">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField
                                {...commonProps}
                                fieldName={`${fieldPrefix}planPatientId`}
                                label="Patient ID"
                                placeholder="Patient ID"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="benefitCoverageType">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField
                                {...commonProps}
                                fieldName={`${fieldPrefix}coverageType`}
                                label="Benefit Coverage Type"
                                options={coverageTypeOptions.map((o) => ({ label: o, value: o }))}
                                isClearable
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="payorType">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField
                                {...commonProps}
                                fieldName={`${fieldPrefix}payerSegment`}
                                label="Payor Type"
                                options={payerSegmentOptions.map((o) => ({ label: o, value: o }))}
                                isClearable
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>

                <RequiredDisabledFieldWrapper fieldName="bin">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField
                                {...commonProps}
                                fieldName={`${fieldPrefix}pharmacyInsuranceBin`}
                                label="BIN"
                                placeholder="BIN"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="pcn">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField
                                {...commonProps}
                                fieldName={`${fieldPrefix}pharmacyInsurancePcn`}
                                label="PCN"
                                placeholder="PCN"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="rxGroupNumber">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField
                                {...commonProps}
                                fieldName={`${fieldPrefix}pharmacyInsuranceGroupId`}
                                label="RX Group Number"
                                placeholder="RX Group Number"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="personCode">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField
                                {...commonProps}
                                fieldName={`${fieldPrefix}pharmacyInsurancePersonCode`}
                                label="Person Code"
                                placeholder="Person Code"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>

                <RequiredDisabledFieldWrapper fieldName="highDeductiblePlan">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField
                                {...commonProps}
                                fieldName={`${fieldPrefix}isIntegratedHighDeductable`}
                                label="High Deductible Plan"
                                options={yesNoOptions.map((o) => ({ label: o, value: o }))}
                                isClearable
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="coverageEffectiveDate">
                    {(commonProps) => (
                        <Col md={3}>
                            <DatePickerField
                                {...commonProps}
                                fieldName={`${fieldPrefix}coverageEffectiveDate`}
                                label="Coverage Effective Date"
                                placeholder="Coverage Effective Date"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="coverageEndDate">
                    {(commonProps) => (
                        <Col md={3}>
                            <DatePickerField
                                {...commonProps}
                                fieldName={`${fieldPrefix}coverageEndDate`}
                                label="Coverage End Date"
                                placeholder="Coverage End Date"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="medicalGroupId">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField
                                {...commonProps}
                                fieldName={`${fieldPrefix}groupNumber`}
                                label="Medical Group ID"
                                placeholder="Medical Group ID"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
            </Row>

            <Row>
                <RequiredDisabledFieldWrapper fieldName="deductibleAmount">
                    {(commonProps) => (
                        <Col md={3}>
                            <NumberField
                                {...commonProps}
                                fieldName={`${fieldPrefix}deductableAmount`}
                                label="Deductible Amount"
                                placeholder="Deductible Amount"
                                prefixAddon="$"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="deductibleAmountPaidToDate">
                    {(commonProps) => (
                        <Col md={3}>
                            <NumberField
                                {...commonProps}
                                fieldName={`${fieldPrefix}deductableAmountPaidToDate`}
                                label="Deductible Amount Paid To Date"
                                placeholder="Deductible Amount Paid To Date"
                                prefixAddon="$"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="outOfPocketMaximum">
                    {(commonProps) => (
                        <Col md={3}>
                            <NumberField
                                {...commonProps}
                                fieldName={`${fieldPrefix}pharmacyPlanOopMax`}
                                label="Out Of Pocket Maximum"
                                placeholder="Out Of Pocket Maximum"
                                prefixAddon="$"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="outOfPocketMaximumPaidToDate">
                    {(commonProps) => (
                        <Col md={3}>
                            <NumberField
                                {...commonProps}
                                fieldName={`${fieldPrefix}pharmacyPlanOopMaxPaidToDate`}
                                label="Out Of Pocket Maximum Paid To Date"
                                placeholder="Out Of Pocket Maximum Paid To Date"
                                prefixAddon="$"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>

                <RequiredDisabledFieldWrapper fieldName="coverageCarveOut">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField
                                {...commonProps}
                                fieldName={`${fieldPrefix}isPharmacyBenefitsCarveOutFromMedical`}
                                label="Coverage Carve-Out"
                                options={yesNoOptions.map((o) => ({ label: o, value: o }))}
                                isClearable
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
                <RequiredDisabledFieldWrapper fieldName="helpDeskPhone">
                    {(commonProps) => (
                        <Col md={3}>
                            <PhoneField
                                {...commonProps}
                                fieldName={`${fieldPrefix}helpDeskPhone`}
                                label="Help Desk Phone"
                            />
                        </Col>
                    )}
                </RequiredDisabledFieldWrapper>
            </Row>

            {insuranceValues.id && (
                <CheckboxField fieldName={`${fieldPrefix}disableInsurancePlan`} label="Disable Insurance Plan" />
            )}
        </div>
    );
};

InsuranceFormArea.propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
};

export default InsuranceFormArea;
