import SessionStorageService from 'App/services/SessionStorageService';

/** @ngInject */
function sessionStorageService(_, $window, jwtHelper, tokenService) {
    const service = {
        get,
        getOnUser,
        remove,
        set,
        setOnUser,
        setJwt,
        getJwt,
        isPasswordExpired,
    };

    setInterval(clearExpired, 5000);
    return service;

    /**
     * @summary Gets the provided key, and will return the result as an object if json is set true;
     *
     * @param {string} key - The key to lookup in local storage.
     * @returns {string|object}
     */
    function get(key) {
        if (typeof key !== 'string') {
            throw new Error(`Invalid key provided expected type string; got type ${typeof key} ${key}`);
        }
        const row = $window.localStorage.getItem(`V2_${tokenService.get()}_${key}`);
        if (row === null) {
            return {};
        }
        return JSON.parse(row).value;
    }

    /**
     * @summary Finds all versioned local storage items that have expired and removes them.
     */
    function clearExpired() {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax
        for (const i in $window.localStorage) {
            if (i.substr(0, 2) === 'V2') {
                const row = JSON.parse($window.localStorage.getItem(i));
                if (new Date(row.expiration) < new Date()) {
                    $window.localStorage.removeItem(i);
                }
            }
        }
    }

    /**
     * @summary Gets the provided key, and will return the result as an object if json is set true;
     *
     * @description
     * This differs from get(). Get retrieves keys that are prefixed by the user token. This retrieves keys that
     * are prefixed by the user id.
     *
     * @param {string} key - The key to lookup in local storage.
     * @param {boolean} json - Whether to return the result as JSON or not
     * @returns {string|object|null}
     */
    function getOnUser(key, json) {
        if (typeof key !== 'string') {
            throw new Error(`Invalid key provided expected type string; got type ${typeof key} ${key}`);
        }

        const userId = $window.localStorage.getItem('userId');
        if (userId) {
            const value = $window.localStorage.getItem(`${userId}_${key}`);
            if (json === true) {
                try {
                    return typeof value === 'string' ? JSON.parse(value) : null;
                } catch (e) {
                    $window.localStorage.removeItem(`${userId}_${key}`);
                }
            }
            return value;
        }
        throw new Error('No user id found for this user');
    }

    /**
     * @summary Deletes the provided `key` from local storage.
     *
     * @param {string} key
     * @returns void
     */
    function remove(key) {
        if (typeof key !== 'string') {
            throw new Error(`Invalid key provided expected type string; got type ${typeof key} ${key}`);
        }

        return $window.localStorage.removeItem(`${tokenService.get()}_${key}`);
    }

    /**
     * @summary Sets a value in local storage
     *
     * @description
     * Takes the provided key and prepends it with the session token, if the provided `value` is an object
     * it will be JSON stringified to enable its storage in local storage
     *
     * @param {string} key - Key to store the value by
     * @param {any} value - Value to be stored.
     * @param {number} expiration - TTL in seconds
     * @returns void
     */
    function set(key, value, expiration) {
        if (typeof expiration !== 'number' || expiration <= 0) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            expiration = 3600;
        }
        if (typeof key !== 'string') {
            throw new Error(`Invalid key provided expected type string; got type ${typeof key} ${key}`);
        }

        const expirationDate = new Date(new Date().setSeconds(new Date().getSeconds() + expiration));
        const item = {
            value,
            expiration: expirationDate,
        };
        return $window.localStorage.setItem(`V2_${tokenService.get()}_${key}`, JSON.stringify(item));
    }

    /**
     * @summary Sets a value in local storage
     *
     * @description
     * Takes the provided key and prepends it with the user id, if the provided `value` is an object
     * it will be JSON stringified to enable its storage in local storage
     *
     * @param {string} key - Key to store the value by
     * @param {any} value - Value to be stored.
     * @returns void
     */
    function setOnUser(key, value) {
        if (typeof key !== 'string') {
            throw new Error(`Invalid key provided expected type string; got type ${typeof key} ${key}`);
        }
        if (typeof value === 'object') {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            value = JSON.stringify(value);
        }

        const userId = $window.localStorage.getItem('userId');
        if (userId) {
            return $window.localStorage.setItem(`${userId}_${key}`, value);
        }
        throw new Error('No user id found for this user');
    }

    /**
     * @summary Saves JWT in a cross-browser manner
     *
     * @param {string} token - JWT
     * @returns {null}
     */
    function setJwt(token) {
        const chunks = token.match(/.{1,1000}/g); // IE bug with long LS values
        $window.localStorage.setItem('JWT-chunks', chunks.length);
        _.forEach(chunks, (chunk, index) => {
            $window.localStorage.setItem(`JWT-${index}`, chunk);
        });
    }

    /**
     * @summary Retrieves JWT in a cross-browser manner
     *
     * @returns {string}
     */
    function getJwt() {
        return SessionStorageService.getJwt();
    }

    /**
     * Extracts the PasswordExpired value from the JWT
     * @returns boolean
     */
    function isPasswordExpired() {
        try {
            return jwtHelper.decodeToken(getJwt()).data.PasswordExpired;
        } catch (e) {
            return false;
        }
    }
}

export default sessionStorageService;
