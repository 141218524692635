/** @ngInject */
function AssignMedicationTherapyController($uibModal, therapyService, _) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.doModalDone = onModalDone;
    ctrl.open = openModal;

    function $onChanges() {
        if (_.get(ctrl, 'patient.Unassignedmedications')) {
            therapyService.getTherapy().then((res) => {
                const therapies = res.availableTherapies;
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-restricted-syntax, guard-for-in
                for (const p in therapies) {
                    therapies[p.toLowerCase()] = therapies[p];
                }

                angular.forEach(ctrl.patient.Unassignedmedications, (unAssignedMed) => {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    unAssignedMed.therapies = [];
                    unAssignedMed.therapies.push(therapies[unAssignedMed.MedicationName.toLowerCase()]);
                });
            });
        }
    }

    /**
     * Resolve processed modal, API call successful or never made.
     * @param {object} result - An object containing the patient object, patient index, and api response.
     */
    function onModalDone(result) {
        ctrl.onModalDone({
            result: result.selection,
        });
    }

    /**
     * Function responsible for opening the Modal, and resolving properties in to the modal.
     * @class
     * @type {function}
     * @property {object}  resolve.modalData.patient - Patient Object
     */
    function openModal() {
        $uibModal
            .open({
                component: 'assignMedicationTherapyModal',
                size: 'lg',
                resolve: {
                    modalData() {
                        return {
                            patient: ctrl.patient,
                        };
                    },
                },
            })
            .result.then((result) => {
                return ctrl.doModalDone(result);
            })
            .catch((err) => {
                return err;
            });
    }
}

export default AssignMedicationTherapyController;
