import React from 'react';
import PropTypes from 'prop-types';
import './patientPrescription.scss';
import { Button } from 'react-bootstrap';
import RxNumber from './RxNumber';
import * as R from 'ramda';
import { cx } from '@emotion/css';
import useFetchDisabledPatientFields from 'App/hooks/useFetchDisabledPatientFields';
import { preparePresciptions } from 'App/components/PatientPrescription/utils';

const propTypes = {
    onAddRxNumber: PropTypes.func.isRequired,
    onAddRxFill: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    patientPrescriptions: PropTypes.object.isRequired,
    handlePropChange: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    medication: PropTypes.object,
    handleFillPropChange: PropTypes.func,
    emitEventToAddPrescriber: PropTypes.func,
    handleRemoveRxNumber: PropTypes.func,
    handleRemoveFillNumber: PropTypes.func,
    handleSearchPrescriber: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    searchPrescriber: PropTypes.array,
    handleSelectedPrescriber: PropTypes.func,
    viewRxNumber: PropTypes.bool,
    handleEndRxNumber: PropTypes.func,
    handleResetEndPrescription: PropTypes.func,
    viewProfile: PropTypes.bool,
    showEndedMedications: PropTypes.bool,
    validateRxFillNumber: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    validateRxFillNumberErrors: PropTypes.object,
};

const defaultProps = {
    viewRxNumber: false,
    medication: {},
    handlePropChange: () => {},
    handleFillPropChange: () => {},
    emitEventToAddPrescriber: () => {},
    handleRemoveRxNumber: () => {},
    handleRemoveFillNumber: () => {},
    handleSearchPrescriber: () => {},
    searchPrescriber: [],
    handleSelectedPrescriber: () => {},
    handleEndRxNumber: () => {},
    handleResetEndPrescription: () => {},
    viewProfile: false,
    showEndedMedications: false,
    validateRxFillNumber: () => {},
    validateRxFillNumberErrors: {},
};

function PatientPrescription(props) {
    const {
        emitEventToAddPrescriber,
        handleEndRxNumber,
        handleFillPropChange,
        handlePropChange,
        handleRemoveFillNumber,
        handleRemoveRxNumber,
        handleResetEndPrescription,
        handleSearchPrescriber,
        handleSelectedPrescriber,
        medication,
        onAddRxFill,
        onAddRxNumber,
        patientPrescriptions,
        searchPrescriber,
        showEndedMedications,
        validateRxFillNumber,
        validateRxFillNumberErrors,
        viewProfile,
        viewRxNumber,
    } = props;

    const { data: disabledPatientFields } = useFetchDisabledPatientFields();

    const disabledPatientFieldsArr = Object.values(disabledPatientFields || []).map((item) => item.field);

    const removeRxNumber = (rxPosition) => {
        handleRemoveRxNumber(medication.id, rxPosition);
    };

    /**
     * This currently needs to run every time and cannot be memoized due to the use of the rxNumber state being used to
     * add new rx numbers.
     */
    const filteredPrescriptions = preparePresciptions(
        patientPrescriptions,
        medication,
        viewProfile,
        showEndedMedications
    );

    /**
     * Always grouped by medicationID, so we get and iterate over the entries, which
     * seems to be just the one for every medication ID.
     */
    const rxPairs = R.toPairs(filteredPrescriptions);

    return (
        <form>
            <div className={cx({ 'patient-prescription-container': viewRxNumber })}>
                {rxPairs.length > 0
                    ? rxPairs.map(([medicationId, group]) => {
                          return group.rxNumbers.map((rx, idx) => {
                              return (
                                  <div key={`${medicationId}-${rx._internalUiId}`}>
                                      <RxNumber
                                          handlePropChange={handlePropChange}
                                          patientPrescriptions={filteredPrescriptions}
                                          medicationId={medicationId}
                                          index={idx}
                                          handleFillPropChange={handleFillPropChange}
                                          emitEventToAddPrescriber={emitEventToAddPrescriber}
                                          removeRxNumber={removeRxNumber}
                                          handleRemoveFillNumber={handleRemoveFillNumber}
                                          handleSearchPrescriber={handleSearchPrescriber}
                                          searchPrescriber={searchPrescriber}
                                          handleSelectedPrescriber={handleSelectedPrescriber}
                                          handleEndRxNumber={handleEndRxNumber}
                                          medication={medication}
                                          handleResetEndPrescription={handleResetEndPrescription}
                                          validateRxFillNumber={validateRxFillNumber}
                                          validateRxFillNumberErrors={validateRxFillNumberErrors}
                                          onAddRxFill={onAddRxFill}
                                      />
                                  </div>
                              );
                          });
                      })
                    : null}
                <div>
                    {!viewRxNumber && medication.id ? (
                        <Button
                            className="btn btn-link"
                            disabled={disabledPatientFieldsArr.includes('add_rx_number')}
                            onClick={() => {
                                onAddRxNumber();
                            }}
                        >
                            <i className="fa fa-fw fa-plus" />
                            Add Rx Number
                        </Button>
                    ) : null}
                </div>
            </div>
        </form>
    );
}

PatientPrescription.propTypes = propTypes;
PatientPrescription.defaultProps = defaultProps;

export default PatientPrescription;
