import * as R from 'ramda';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import values from 'ramda/src/values';
import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted';
import PESService from '../../services/PESService';
import { errorHandler, hasLength, stripAllHtml, formatPhone } from '../../utils';
import CompanyService from '../../services/CompanyService';
import React from 'react';
import ReactDOM from 'react-dom';
import PatientInsuranceTabs from 'App/components/PatientInsurance/PatientInsuranceTabs';
import * as yup from 'yup';
import { isEmpty, keyBy } from 'lodash';
import dayjs from 'dayjs';
import patientPrescriptionUpdate from './utils/patientPrescriptionUpdate';
import { isExpired } from 'App/utils';
import { queryClient } from 'Lib/queryClient';

/** @ngInject */
function EditPatientController(
    $http,
    $httpParamSerializerJQLike,
    $q,
    $rootScope,
    $scope,
    $state,
    $window,
    _,
    moment,
    authService,
    engageService,
    jwtHelper,
    patientService,
    patientStatusHistoryService,
    patientOtherConditionService,
    patientOtherMedicationService,
    rmsService,
    sessionStorageService,
    __env
) {
    const ctrl = this;
    let deletedOtherConditions = [];
    let deletedOtherMedications = [];
    const errorPriority = {
        first_name: 1,
        last_name: 2,
        birth_date: 3,
        gender: 4,
        city: 5,
        state: 6,
        zip_code: 7,
    };
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.disablePatient = disablePatient;
    ctrl.enablePatient = enablePatient;
    ctrl.resetForm = resetForm;
    ctrl.submitForm = submitForm;
    ctrl.isRequired = isRequired;
    ctrl.submitPatientTherapies = submitPatientTherapies;
    ctrl._savePatient = _savePatient;
    ctrl.patientInsurance = {};
    ctrl.validationInsuranceErrors = {};
    ctrl.validationOfInsurance = [];
    ctrl.disablePatientInsurance = {};
    ctrl.patientPrescriptions = {};
    ctrl.patientPrescriptionsArray = [];
    ctrl.validationOfRxFillNumber = [];
    ctrl.patientICD10s = [];

    $scope.$on('addPatientPage_updateDeletedOtherConditions', updateDeletedOtherConditions);
    $scope.$on('addPatientPage_updateDeletedOtherMedications', updateDeletedOtherMedications);
    $scope.$on('addPatientPage_updatePatient', updatePatient);
    $scope.$on('addPatientPage_toggleRequiredFields', toggleRequiredFields);
    $scope.$on('addPatientPage_updatePatientRiskReasons', updatePatientHighRiskReasons);
    $scope.$on('updatePatientLabels', updatePatientLabels);
    $scope.$on('emptyPatientLabel', emptyPatientLabel);
    $scope.$on('updatePatientReferral', updatePatientReferral);
    $scope.$on('validateRxFillNumber', validateRxFillNumber);
    $rootScope.$on('addPatientPrescription', addPatientPrescription);
    $rootScope.$on('addRemovePatientICD10', addRemovePatientICD10);

    ctrl.hasNewRxFillNumberEnabled = isPermitted(PERMISSION_TYPES.ENABLE_RX_FILL_NUMBERS, ctrl.user);

    function $onChanges(changes) {
        if (changes.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
        if (changes.disabledFields) {
            ctrl.disabledFields = angular.copy(ctrl.disabledFields);
        }
    }

    function validateInsurancePlan(item, id, value) {
        ctrl.validationInsuranceErrors[item] = ctrl.validationInsuranceErrors[item] || {};
        ctrl.validationInsuranceErrors[item][id] = '';
        if (!value || value === 'Select') {
            if (id === 'name') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Plan Name';
            }
            if (id === 'planPatientId') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Patient ID';
            }
            if (id === 'coverageType') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Benefit Coverage Type';
            }
            if (id === 'payerSegment') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Payor Type';
            }
        }
        mountIt();
    }

    function disablePlan(index, value) {
        ctrl.disablePatientInsurance[index] = value;
        mountIt();
    }

    function handlePropChange(item, id, value) {
        ctrl.patientInsurance[item] = ctrl.patientInsurance[item] || {};
        ctrl.patientInsurance[item][id] = value;
        mountIt();
    }

    function removeInsurancePlan(index) {
        delete ctrl.patientInsurance[index];
        mountIt();
    }

    /**
     * Mount react
     */
    mountIt();

    function mountIt() {
        const dom = document.getElementById('insurance-plans');
        if (dom) {
            ReactDOM.render(
                <PatientInsuranceTabs
                    patientInsurance={ctrl.patientInsurance || []}
                    handleChangeProps={handlePropChange}
                    removeInsurancePlan={removeInsurancePlan}
                    validationInsuranceErrors={ctrl.validationInsuranceErrors || []}
                    validateInsurancePlan={validateInsurancePlan}
                    disablePlan={disablePlan}
                    disablePatientInsurance={ctrl.disablePatientInsurance || []}
                    editPatientInsurance
                    requiredFields={ctrl.requiredFields}
                    disabledFields={ctrl.disabledFields}
                />,
                dom
            );
        } else {
            setTimeout(mountIt, 100);
        }
    }

    function $onInit() {
        ctrl._ = _;
        ctrl.showAnnouncementBanner = !sessionStorageService.getOnUser('announcementBanner');
        ctrl.therapyErrors = [];
        ctrl.validationErrors = [];
        ctrl.lockError = false;
        ctrl.differences = [];
        ctrl.patientLabels = [];
        ctrl.emptyPatientLabel = false;
        ctrl.originalPatientLabels = [];
        ctrl.patientReferral = false;
        ctrl.supportEmail = __env.supportEmail;
        ctrl.getPatientInsurances = {};

        ctrl.lockForUpdateEnabled = CompanyService.getFeatureFlagStatus(
            PERMISSION_TYPES.ENABLE_EDIT_PATIENT_LOCK_FOR_UPDATE
        );

        const promises = [
            patientService.get($state.params.patientId),
            patientService
                .getHighRiskReasons($state.params.patientId)
                .then(R.values)
                .then(R.map(R.prop('reason'))),
            CompanyService.getServerTime(),
        ];

        CompanyService.getRequiredPatientFields(ctrl.user.active_company.ID).then((res) => {
            ctrl.requiredFields = angular.copy(Object.values(res).map((record) => record.field));
            return res;
        });

        PESService.getPatientLabels($state.params.patientId)
            .then((res) => {
                ctrl.patientLabels = values(map(prop('label'))(res));
                ctrl.originalPatientLabels = values(res);
                return res;
            })
            .catch((err) => {
                return err;
            });

        PESService.getPatientInsurance($state.params.patientId)
            .then((res) => {
                ctrl.getPatientInsurances = res;
                const items = Object.keys(ctrl.getPatientInsurances);

                const filterIds = items.filter(
                    (item) =>
                        ctrl.getPatientInsurances[item].disabledOn === null &&
                        (ctrl.getPatientInsurances[item].coverageEndDate === null ||
                            !isExpired(
                                dayjs(ctrl.getPatientInsurances[item].coverageEndDate)
                                    .utc()
                                    .format('YYYY-MM-DD 23:59:59')
                            ))
                );
                ctrl.patientInsurance = keyBy(
                    filterIds.map((item) => ctrl.getPatientInsurances[item]),
                    'position'
                );
                mountIt();
            })
            .catch((err) => {
                errorHandler(err);
            });

        PESService.getPatientReferral($state.params.patientId)
            .then((res) => {
                ctrl.patientReferral = !isEmpty(res);
                ctrl.originalPatientReferral = !isEmpty(res);
                return res;
            })
            .catch((err) => {
                ctrl.patientReferral = false;
                ctrl.originalPatientReferral = false;
                return err;
            });

        return Promise.all(promises)
            .then(([patient, highRiskReasons, serverTime]) => {
                let branchCheck = $q.when();

                if (patient.division_id !== ctrl.user.active_branch.ID) {
                    branchCheck = authService.changeBranch(patient.division_id);
                }
                return branchCheck.then((branchChangeUser) => {
                    if (branchChangeUser) {
                        ctrl.user = branchChangeUser;
                    }
                    ctrl.patient = {
                        ...patient,
                    };
                    ctrl.lockObj = {
                        openedOn: serverTime.utcMillisec,
                        resourceType: 'patientId',
                        resourceId: $state.params.patientId,
                    };
                    ctrl.originalPatient = {
                        ...patient,
                    };
                    ctrl.originalPatientHighRiskReasons = highRiskReasons;
                    ctrl.patientHighRiskReasons = highRiskReasons;

                    return patient;
                });
            })
            .catch((err) => {
                ctrl.patient = false;
                throw err;
            });
    }

    function disablePatient() {
        return submitForm(false)
            .then(() => {
                return patientService.disable(ctrl.patient);
            })
            .then(() => {
                $state.go('app.patient', {
                    patientId: ctrl.patient.id,
                    branchId: ctrl.patient.division_id,
                    user: ctrl.user,
                });
            });
    }

    function enablePatient() {
        return submitForm(false)
            .then(() => {
                return patientService.enable(ctrl.patient);
            })
            .then(() => {
                $state.go('app.patient', {
                    patientId: ctrl.patient.id,
                    branchId: ctrl.patient.division_id,
                    user: ctrl.user,
                });
            });
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
        return false;
    }

    function resetForm() {
        ctrl.editPatientForm.$setPristine();
        ctrl.editPatientForm.$setUntouched();
        // Reset patient
        if (ctrl.originalPatient) {
            ctrl.patient = angular.copy(ctrl.originalPatient);
        } else {
            ctrl.patient = {};
        }

        // Reset risk reasons
        if (ctrl.originalPatientHighRiskReasons) {
            ctrl.patientHighRiskReasons = angular.copy(ctrl.originalPatientHighRiskReasons);
        } else {
            ctrl.patientHighRiskReasons = [];
        }

        // Reset patient labels
        if (ctrl.originalPatientLabels) {
            ctrl.patientLabels = angular.copy(ctrl.originalPatientLabels);
        } else {
            ctrl.patientLabels = [];
        }

        // Reset patient referral
        if (ctrl.originalPatientReferral) {
            ctrl.patientReferral = angular.copy(ctrl.originalPatientReferral);
        } else {
            ctrl.patientReferral = false;
        }
    }

    function validateInsuranceOnSubmit() {
        ctrl.insuranceKeys = Object.keys(ctrl.patientInsurance);
        const schema = yup.object().shape({
            name: yup.string().nullable().required('Please enter a Plan Name').label('Plan Name'),
            planPatientId: yup.string().nullable().required('Please enter a Patient ID').label('Patient ID'),
            coverageType: yup
                .string()
                .nullable()
                .matches(/\b(?!Select\b)\w+/, 'Please enter a Benefit Coverage Type')
                .required('Please enter a Benefit Coverage Type')
                .label('Benefit Coverage Type'),
            payerSegment: yup
                .string()
                .nullable()
                .matches(/\b(?!Select\b)\w+/, 'Please enter a Payor Type')
                .required('Please enter a Payor Type')
                .label('Payor Type'),
            pharmacyInsuranceBin:
                ctrl.isRequired('bin') && yup.string().nullable().required('Please enter a BIN').label('BIN'),
            pharmacyInsurancePcn:
                ctrl.isRequired('pcn') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a PCN')
                    .required('Please enter a PCN')
                    .label('PCN'),
            pharmacyInsuranceGroupId:
                ctrl.isRequired('rx_group_number') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Rx Group Number')
                    .required('Please enter a Rx Group Number')
                    .label('Rx Group Number'),
            pharmacyInsurancePersonCode:
                ctrl.isRequired('person_code') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Person Code')
                    .required('Please enter a Person Code')
                    .label('Person Code'),
            groupNumber:
                ctrl.isRequired('medical_group_id') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Medical Group ID')
                    .required('Please enter a Medical Group ID')
                    .label('Medical Group ID'),
            isIntegratedHighDeductable:
                ctrl.isRequired('high_deductible_plan') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a High Deductible Plan')
                    .required('Please enter a High Deductible Plan')
                    .label('High Deductible Plan'),
            isPharmacyBenefitsCarveOutFromMedical:
                ctrl.isRequired('coverage_carve_out') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Coverage Carve Out')
                    .required('Please enter a Coverage Carve Out')
                    .label('Coverage Carve Out'),
            coverageEffectiveDate:
                ctrl.isRequired('coverage_effective_date') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Coverage Effective Date')
                    .required('Please enter a Coverage Effective Date')
                    .label('Coverage Effective Date'),
            coverageEndDate:
                ctrl.isRequired('coverage_end_date') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Coverage End Date')
                    .required('Please enter a Coverage End Date')
                    .label('Coverage End Date'),
            deductableAmount:
                ctrl.isRequired('deductible_amount') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Deductible Amount')
                    .required('Please enter a Deductible Amount')
                    .label('Deductible Amount'),
            deductableAmountPaidToDate:
                ctrl.isRequired('deductible_amount_paid_to_date') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Deductible Amount Paid To Date')
                    .required('Please enter a Deductible Amount Paid To Date')
                    .label('Deductible Amount Paid To Date'),
            pharmacyPlanOopMax:
                ctrl.isRequired('out_of_pocket_maximum') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Out Of Pocket Maximum')
                    .required('Please enter a Out Of Pocket Maximum')
                    .label('Out Of Pocket Maximum'),
            pharmacyPlanOopMaxPaidToDate:
                ctrl.isRequired('out_of_pocket_maximum_paid_to_date') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Out Of Pocket Maximum Paid To Date')
                    .required('Please enter a Out Of Pocket Maximum Paid To Date')
                    .label('Out Of Pocket Maximum Paid To Date'),
            helpDeskPhone:
                ctrl.isRequired('help_desk_phone') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Help Desk Phone')
                    .required('Please enter a Help Desk Phone')
                    .label('Help Desk Phone'),
        });
        for (const key of ctrl.insuranceKeys) {
            ctrl.validationInsuranceErrors[key] = ctrl.validationInsuranceErrors[key] || {};
            try {
                schema.validateSync(ctrl.patientInsurance[key], { abortEarly: false });
            } catch (e) {
                if (e.name === 'ValidationError') {
                    for (const error of e.inner) {
                        ctrl.validationInsuranceErrors[key][error.path] = error.errors[0];
                    }
                }
            }

            ctrl.validationOfInsurance = [
                ...new Set(ctrl.validationOfInsurance.concat(Object.values(ctrl.validationInsuranceErrors[key]))),
            ];
        }
        ctrl.validationOfInsurance = ctrl.validationOfInsurance.map((item) => item.replace('Please enter a ', ''));

        return ctrl.validationInsuranceErrors;
    }

    function validatePatientLabelsOnSubmit() {
        const schema =
            ctrl.isRequired('labels') &&
            yup.array().of(yup.string()).required('Please select patient labels').label('Labels');

        try {
            schema.validateSync(ctrl.patientLabels, { abortEarly: false });
        } catch (e) {
            if (e.name === 'ValidationError') {
                ctrl.validationLabelsError.push({
                    name: 'labels',
                    required: true,
                });
            }
        }
    }

    async function submitForm(redirect) {
        ctrl.editPatientForm.$submitted = true;
        ctrl.validationErrors = [];
        ctrl.validationInsuranceErrors = {};
        ctrl.validationOfInsurance = [];
        ctrl.validationLabelsError = [];
        ctrl.savingPatient = true;

        validatePatientLabelsOnSubmit();
        validateInsuranceOnSubmit();
        if (Object.keys(ctrl.validationInsuranceErrors).length) {
            mountIt();
        }
        if (
            ctrl.editPatientForm.$invalid ||
            ctrl.noTherapies ||
            ctrl.therapyErrors.length ||
            ctrl.validationOfInsurance.length ||
            ctrl.validationOfRxFillNumber.length ||
            ctrl.validationLabelsError.length
        ) {
            if (ctrl.editPatientForm.$error.required) {
                _.forEach(ctrl.editPatientForm.$error.required, (error) => {
                    // eslint-disable-next-line no-param-reassign
                    error.priority = errorPriority[error.$name] || null;
                    // eslint-disable-next-line no-param-reassign
                    error.required = true;
                    ctrl.validationErrors.push(error);
                });
            }
            ctrl.validationErrors = _.sortBy(ctrl.validationErrors, ['priority']);
            ctrl.savingPatient = false;
            return false;
        }
        if (ctrl.lockForUpdateEnabled) {
            try {
                const lockable = await CompanyService.lockForUpdate(ctrl.lockObj);
                ctrl.lockObj.openedOn = lockable.updatedOn;
            } catch (err) {
                ctrl.savingPatient = false;
                if (err?.response?.status === 409) {
                    ctrl.lockError = true;
                } else {
                    errorHandler({ message: 'Patient update failed. Please try again.' });
                }
                return false;
            }
        }
        await _savePatient(redirect);
    }

    /**
     * THIS NEEDS TO RUN BEFORE ANY NEW MEDS ARE ADDED
     * This will filter out the treatment therapies so they don't get duplicated
     * and run protocols.
     */
    async function submitTreatmentHistory() {
        const [treatmentHistories, medications] = R.compose(
            R.partition(R.path(['medication', 'isTreatmentHistory'])),
            R.values
        )(ctrl.patient.medications);

        ctrl.patient.medications = R.indexBy(R.path(['medication', 'id']), medications);

        const treatmentHistoryPayload = R.map(
            R.applySpec({
                medicationId: R.path(['medication', 'id']),
                therapyId: R.path(['medication', 'group_id']),
                status: {
                    start: R.path(['status', 'start']),
                    end: R.path(['status', 'end']),
                    endReason: R.path(['status', 'end_reason']),
                    notes: R.path(['status', 'end_notes']),
                },
            }),
            treatmentHistories
        );
        if (hasLength(treatmentHistoryPayload)) {
            return PESService.addTreatmentHistory(ctrl.patient.id, treatmentHistoryPayload);
        }
    }

    function _savePatient(redirect) {
        return (
            submitPatientOtherMedication()
                .then(submitPatientOtherCondition)
                .then(submitPatientRiskReasons)
                /**
                 * submitTreatmentHistory needs to run before patient therapies and meds are edited/added.
                 */
                .then(submitTreatmentHistory)
                .then(submitPatientTherapies)
                .then(submitPatientLabels)
                .then(submitPatientReferral)
                .then(submitPatientInsurance)
                .then(submitPatientPrescriptions)
                .then(submitPatientOtherIdentifier)
                .then(submitPatient)
                .then((res) => {
                    // In some cases editing a patient while switched in another tab to another branch
                    // will cause the patient to move branches as a side effect.
                    const currentPatientDivisionId = ctrl.patient.division_id;
                    const currentSessionToken = jwtHelper.decodeToken(sessionStorageService.getJwt());
                    // If the patient's division id is different than the current session one,
                    // change the current session's branch id to match the patient's.
                    if (currentPatientDivisionId !== currentSessionToken.data.BranchID) {
                        authService.changeBranch(currentPatientDivisionId);
                    }
                    return res;
                })
                .then((res) => {
                    ctrl.patient.id = res.id;
                    return patientService.getPrescriptions(ctrl.patient.id);
                })
                .then((res) => {
                    // We need to ensure the prescriptions have been created before adding referrals.
                    return submitReferralManager(res);
                })
                .then(() => {
                    return submitPatientMessagingEnrollment();
                })
                .then(() => {
                    // Submit patient status history.
                    if (ctrl.patient.patient_status) {
                        return patientStatusHistoryService
                            .create(ctrl.patient.id, { status: ctrl.patient.patient_status })
                            .catch((err) => {
                                return err;
                            });
                    }
                    return $q.when();
                })
                .then((res) => {
                    const therapyIds = Object.keys(ctrl.patientTherapiesICD10 || {});
                    const promisesICD10 = [];
                    for (const therapyId of therapyIds) {
                        promisesICD10.push(
                            PESService.addRemovePatientICD10(ctrl.patient.id, therapyId, {
                                patientICD10: ctrl.patientTherapiesICD10[therapyId].icd10,
                            })
                        );
                    }
                    if (promisesICD10.length) {
                        return $q.all(promisesICD10).catch((err) => {
                            return err;
                        });
                    }
                    return res;
                })
                .then((res) => {
                    if ($state.params.redirectUrl) {
                        // eslint-disable-next-line no-param-reassign -- reassigning the window location to redirect the Url
                        $window.location.href = $state.params.redirectUrl;
                    } else if (redirect !== false) {
                        $state.go('app.patient', {
                            patientId: ctrl.patient.id,
                            branchId: ctrl.patient.division_id,
                            user: ctrl.user,
                        });
                    }
                    return res;
                })

                .catch(() => {
                    errorHandler({ message: 'Patient update failed. Please try again.' });
                })
                .finally(() => {
                    ctrl.savingPatient = false;
                })
        );
    }
    function submitPatient() {
        const patient = {
            address_line_1: ctrl.patient.address_line_1,
            address_line_2: ctrl.patient.address_line_2,
            allergy: ctrl.patient.allergy,
            birth_date: ctrl.patient.birth_date,
            city: ctrl.patient.city,
            country: ctrl.patient.country,
            deceased_date: ctrl.patient.deceased_date,
            division_id: ctrl.patient.division_id,
            ec_first_name: ctrl.patient.ec_first_name,
            ec_last_name: ctrl.patient.ec_last_name,
            ec_relationship: ctrl.patient.ec_relationship,
            ec_telephone: ctrl.patient.ec_telephone,
            ec_mobile_phone: ctrl.patient.ec_mobile_phone,
            email: ctrl.patient.email,
            ethnicity: ctrl.patient.ethnicity,
            external_id: ctrl.patient.external_id,
            first_name: ctrl.patient.first_name,
            gender: ctrl.patient.gender.toLowerCase(),
            gender_identity: ctrl.patient.gender_identity,
            pronouns: ctrl.patient.pronouns,
            preferred_name: ctrl.patient.preferred_name,
            height: ctrl.patient.height,
            high_risk: ctrl.patient.high_risk,
            home_phone: ctrl.patient.home_phone,
            lactating: ctrl.patient.lactating,
            language_spoken: ctrl.patient.language_spoken,
            last_name: ctrl.patient.last_name,
            marital_status: ctrl.patient.marital_status,
            medical_plan: ctrl.patient.medical_plan,
            middle_name: ctrl.patient.middle_name,
            mobile_phone: ctrl.patient.mobile_phone,
            no_known_allergies: ctrl.patient.no_known_allergies,
            no_known_medical_conditions: ctrl.patient.no_known_medical_conditions,
            other_allergy: ctrl.patient.other_allergy.join(),
            updated_on: moment().utc().toISOString(),
            pcc_id: ctrl.patient.pcc_id,
            updated_by: ctrl.user.id,
            pcc_send_email: ctrl.patient.pcc_send_email,
            pbm: ctrl.patient.pbm,
            pharmacy: ctrl.patient.pharmacy,
            preferred_contact_method: ctrl.patient.preferred_contact_method,
            pregnancy_status: ctrl.patient.pregnancy_status,
            reimbursement_notes: ctrl.patient.reimbursement_notes,
            remote_medical_plan_id: ctrl.patient.remote_medical_plan_id,
            remote_pbm_id: ctrl.patient.remote_pbm_id,
            smoker_status: ctrl.patient.smoker_status,
            ssn_last_four: ctrl.patient.ssn_last_four,
            state: ctrl.patient.state,
            suffix: ctrl.patient.suffix,
            terminally_ill: ctrl.patient.terminally_ill,
            weight: ctrl.patient.weight,
            welcome_packet: ctrl.patient.welcome_packet,
            welcome_packet_returned: ctrl.patient.welcome_packet_returned,
            welcome_packet_sent: ctrl.patient.welcome_packet_sent,
            work_phone: ctrl.patient.work_phone,
            zip: ctrl.patient.zip,
        };

        return patientService.update(ctrl.patient.id, patient);
    }

    async function submitPatientOtherIdentifier() {
        const otherId = ctrl.patient.other_id;

        if (!ctrl.patient.other_id_did_update) {
            return;
        }
        if (!otherId) {
            return PESService.deletePatientIdentifier(ctrl.patient.id, 'stm_other_id');
        }
        return PESService.upsertPatientIdentifiers(ctrl.patient.id, { Type: 'stm_other_id', Id: otherId });
    }

    function submitPatientOtherCondition() {
        const promises = [];

        if (ctrl.patient.no_known_medical_conditions) {
            _.forEach(ctrl.patient.other_condition, (condition) => {
                promises.push(patientOtherConditionService.del(ctrl.patient.id, condition.id));
            });
        } else {
            _.forEach(deletedOtherConditions, (conditionId) => {
                promises.push(patientOtherConditionService.del(ctrl.patient.id, conditionId));
            });

            _.forEach(ctrl.patient.other_condition, (condition) => {
                if (Object.prototype.hasOwnProperty.call(condition, 'id')) {
                    promises.push(
                        patientOtherConditionService.patch(ctrl.patient.id, condition.id, { text: condition.text })
                    );
                } else {
                    promises.push(patientOtherConditionService.post(ctrl.patient.id, condition));
                }
            });
        }

        if (promises.length) {
            return $q.all(promises).catch((err) => {
                return err;
            });
        }
        return $q.when();
    }
    function submitReferralManager(currentPatientMedications) {
        const referralPromises = [];

        if (ctrl.patient.referralType === 'each' || ctrl.patient.referralType === 'one') {
            const referralMedications = [];

            // Compare the medications that are saved to the patient to the medications that were added and create a
            // referral from the medications that have been added
            _.forEach(currentPatientMedications, (m) => {
                if (
                    ctrl.patient.medications[m.medication_id] &&
                    ctrl.patient.medications[m.medication_id].medication.newMedication
                ) {
                    const referralMedication = {
                        medication_id: m.medication_id,
                    };
                    if (m.id) {
                        referralMedication.rx_number_id = parseInt(m.id, 10);
                    }

                    if (ctrl.patient.referralType === 'each') {
                        referralPromises.push(
                            rmsService.createReferral(ctrl.patient.id, { medications: [referralMedication] })
                        );
                    } else if (ctrl.patient.referralType === 'one') {
                        referralMedications.push(referralMedication);
                    }
                }
            });

            if (ctrl.patient.referralType === 'one') {
                referralPromises.push(rmsService.createReferral(ctrl.patient.id, { medications: referralMedications }));
            }

            return $q.all(referralPromises).then((res) => {
                return res;
            });
        }
        if (ctrl.patient.referralType === 'custom') {
            _.map(ctrl.patient.referralConfig, (referrals) => {
                const referralMedications = [];
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-restricted-syntax
                for (const medicationId in referrals) {
                    if (
                        Object.prototype.hasOwnProperty.call(referrals, medicationId) &&
                        referrals[medicationId] === true
                    ) {
                        referralMedications.push({ medication_id: medicationId });
                    }
                }

                referralPromises.push(rmsService.createReferral(ctrl.patient.id, { medications: referralMedications }));
            });

            return $q.all(referralPromises).then((res) => {
                return res;
            });
        }
        return $q.when();
    }
    function submitPatientMessagingEnrollment() {
        return engageService.getSubscriptions(ctrl.patient.portal_user_id).then((res) => {
            let subscription = false;

            _.forEach(res, (s) => {
                if (s.name === 'Mobile Assessments') {
                    subscription = angular.copy(s);
                }
            });

            // Subscribe if not subscription exists or update subscription if phone number has changed.
            if (subscription && ctrl.originalPatient.mobile_phone !== ctrl.patient.mobile_phone) {
                return engageService.subscribeSMS(ctrl.patient.id, ctrl.patient.mobile_number).catch(() => {
                    return $q.when();
                });
            }
            return $q.when();
        });
    }
    function submitPatientOtherMedication() {
        const promises = [];

        _.forEach(deletedOtherMedications, (medicationId) => {
            promises.push(patientOtherMedicationService.del(ctrl.patient.id, medicationId));
        });

        _.forEach(ctrl.patient.other_medication, (medication) => {
            if (medication.text) {
                const body = {
                    dose: medication.dose || undefined,
                    frequency: medication.frequency || undefined,
                    route_of_admin: medication.route_of_admin || undefined,
                    text: medication.text,
                };

                if (Object.prototype.hasOwnProperty.call(medication, 'id')) {
                    promises.push(patientOtherMedicationService.patch(ctrl.patient.id, medication.id, body));
                } else {
                    promises.push(patientOtherMedicationService.post(ctrl.patient.id, body));
                }
            }
        });

        if (promises.length) {
            return $q.all(promises).catch((err) => {
                return err;
            });
        }
        return $q.when();
    }
    function convertToNumber(value) {
        if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''));
        }
        return value;
    }
    function submitPatientReferral() {
        if (!ctrl.originalPatientReferral && ctrl.patientReferral === true) {
            return PESService.addPatientReferral(ctrl.patient.id)
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    return err;
                });
        }
        if (ctrl.originalPatientReferral === true && ctrl.patientReferral === false) {
            return PESService.deletePatientReferral(ctrl.patient.id)
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    return err;
                });
        }
        return $q.when();
    }
    function submitPatientInsurance() {
        const insuranceIds = Object.keys(ctrl.patientInsurance);
        const insurancePromises = [];
        if (hasLength(insuranceIds)) {
            let newTabCounter = 0;
            _.forEach(insuranceIds, (insuranceId) => {
                ctrl.patientInsurance[insuranceId].deductableAmount = convertToNumber(
                    ctrl.patientInsurance[insuranceId].deductableAmount
                );

                ctrl.patientInsurance[insuranceId].deductableAmountPaidToDate = convertToNumber(
                    ctrl.patientInsurance[insuranceId].deductableAmountPaidToDate
                );

                ctrl.patientInsurance[insuranceId].pharmacyPlanOopMax = convertToNumber(
                    ctrl.patientInsurance[insuranceId].pharmacyPlanOopMax
                );

                ctrl.patientInsurance[insuranceId].pharmacyPlanOopMaxPaidToDate = convertToNumber(
                    ctrl.patientInsurance[insuranceId].pharmacyPlanOopMaxPaidToDate
                );
                ctrl.patientInsurance[insuranceId].helpDeskPhone = formatPhone(
                    ctrl.patientInsurance[insuranceId].helpDeskPhone
                );
                if (
                    ctrl.patientInsurance[insuranceId].coverageEndDate &&
                    isExpired(
                        dayjs(ctrl.patientInsurance[insuranceId].coverageEndDate).utc().format('YYYY-MM-DD 23:59:59')
                    )
                ) {
                    ctrl.patientInsurance[insuranceId].disabledBy = ctrl.user.id;
                    ctrl.patientInsurance[insuranceId].disabledOn = dayjs.utc().format('YYYY/MM/DD H:mm:ss');
                }

                if (ctrl.patientInsurance[insuranceId].id) {
                    delete ctrl.patientInsurance[insuranceId].disabledByUser;
                    if (ctrl.disablePatientInsurance[insuranceId] === true) {
                        ctrl.patientInsurance[insuranceId].disabledOn = dayjs.utc().format('YYYY/MM/DD H:mm:ss');

                        ctrl.patientInsurance[insuranceId].disabledBy = ctrl.user.id;
                        insurancePromises.push(
                            PESService.updatePatientInsurance(
                                ctrl.patient.id,
                                ctrl.patientInsurance[insuranceId].id,
                                ctrl.patientInsurance[insuranceId]
                            )
                        );
                    } else {
                        insurancePromises.push(
                            PESService.updatePatientInsurance(
                                ctrl.patient.id,
                                ctrl.patientInsurance[insuranceId].id,
                                ctrl.patientInsurance[insuranceId]
                            )
                        );
                    }
                } else {
                    ctrl.patientInsuranceArray = {
                        ...ctrl.patientInsurance[insuranceId],
                        position: Object.keys(ctrl.getPatientInsurances).length
                            ? Math.max(...Object.keys(ctrl.getPatientInsurances)) + newTabCounter + 1
                            : newTabCounter + 1,
                    };

                    ctrl.patientInsuranceObject = {
                        insurance: [ctrl.patientInsuranceArray],
                    };
                    newTabCounter += 1;
                    insurancePromises.push(
                        PESService.addPatientInsurance(ctrl.patient.id, ctrl.patientInsuranceObject)
                    );
                }
            });
            return $q.all(insurancePromises);
        }
        return $q.when();
    }
    function submitPatientRiskReasons() {
        return patientService
            .updateHighRiskReasons(ctrl.patient.id, ctrl.patientHighRiskReasons)
            .then((res) => {
                queryClient.setQueryData(['patient', '62a22c6724ada7e368', 'highRiskReasons'], res);
            })
            .catch((err) => {
                console.error(err);
            });
    }
    function submitPatientLabels() {
        const promises = [];

        // For each original label...
        const patientLabels = [...ctrl.patientLabels];
        ctrl.originalPatientLabels.forEach((opl) => {
            // If it was removed from the list of patient labels then delete it
            const oplIndex = patientLabels.indexOf(opl.label);
            if (oplIndex === -1) {
                promises.push(PESService.deletePatientLabel(ctrl.patient.id, opl.id));
            }

            // If it exists in the array we do not to submit a request to add it
            if (oplIndex !== -1) {
                patientLabels.splice(oplIndex, 1);
            }
        });

        // The remaining patient labels are new so we add them
        patientLabels.forEach((pl) => {
            promises.push(PESService.addPatientLabel(ctrl.patient.id, pl));
        });

        if (promises.length) {
            return $q.all(promises).catch((err) => {
                return err;
            });
        }
        return $q.when();
    }
    function sanitizeMedicationStatusNotes(medications) {
        // prettier-ignore
        return R.map(R.over(R.lensPath(['status', 'end_notes']), stripAllHtml), medications);
    }
    function sanitizePrescriptionNotes(prescriptions) {
        // prettier-ignore
        return R.ifElse(R.isNil, R.identity, R.map(R.ifElse(R.isNil, R.identity, R.map(R.over(R.lensProp('notes'), stripAllHtml)))))(prescriptions);
    }
    function submitPatientTherapies() {
        const post = {
            current: {
                medications: sanitizeMedicationStatusNotes(ctrl.patient.medications),
                prescriptions: sanitizePrescriptionNotes(ctrl.patient.prescriptions),
                therapies: ctrl.patient.therapies,
            },
        };
        return $http
            .post(
                '/stm/patients.php',
                $httpParamSerializerJQLike({ response: JSON.stringify(post), patientId: ctrl.patient.id }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            )
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err;
            });
    }
    function toggleRequiredFields(event, data) {
        ctrl.requiredOnly = data;
    }
    function updateDeletedOtherConditions(event, data) {
        deletedOtherConditions = data;
    }
    function updateDeletedOtherMedications(event, data) {
        deletedOtherMedications = data;
    }
    function updatePatientHighRiskReasons(event, highRiskReasons) {
        ctrl.patientHighRiskReasons = highRiskReasons;
    }
    function updatePatientLabels(event, patientLabels) {
        ctrl.patientLabels = patientLabels;
    }
    function emptyPatientLabel(event, emptyLabel) {
        ctrl.emptyPatientLabel = emptyLabel;
    }
    function validateRxFillNumber(event, validationOfRxFillNumber) {
        ctrl.validationOfRxFillNumber = validationOfRxFillNumber;
    }
    function updatePatientReferral(event, patientReferral) {
        ctrl.patientReferral = patientReferral;
    }
    function updatePatient(event, data) {
        _.assignIn(ctrl.patient, data);

        if (_.isEmpty(ctrl.patient.therapies)) {
            ctrl.noTherapies = true;
        } else {
            ctrl.noTherapies = false;
            ctrl.therapyErrors = [];
            _.forEach(ctrl.patient.therapies, (therapy) => {
                const match = _.find(ctrl.patient.medications, (m) => {
                    return m.medication.group_id === therapy.id;
                });

                if (!match && therapy.newTherapy) {
                    ctrl.therapyErrors.push(
                        `You must select <strong>at least one</strong> medication for ${therapy.name}`
                    );
                }
            });
        }
    }
    function addPatientPrescription(event, patientPrescriptions) {
        ctrl.patientPrescriptions = patientPrescriptions;
    }

    function addRemovePatientICD10(event, patientTherapiesICD10) {
        ctrl.patientTherapiesICD10 = patientTherapiesICD10;
    }

    function submitPatientPrescriptions() {
        if (ctrl.hasNewRxFillNumberEnabled) {
            return patientPrescriptionUpdate(ctrl.patientPrescriptions, ctrl.patient.id);
        }
        return null;
    }
}
export default EditPatientController;
