/** @ngInject */
function TipsController($interval, $timeout) {
    const ctrl = this;

    ctrl.interval = 10000;

    ctrl.tips = [
        'Did you know you can add patients in bulk? <a href="http://knowledge.therigy.com/mod/forum/discuss.php?d=59" target="_blank" title="Therigy Knowledge Network">Learn How</a>',
        'Did you know that TherigySTM Admins can add and hide questions in the assessments? <a href="http://knowledge.therigy.com/mod/lesson/view.php?id=481" target="_blank" title="Therigy Knowledge Network">Learn How</a>',
        'Can’t remember all the different assessments for each therapeutic category? <a href="http://knowledge.therigy.com/mod/resource/view.php?id=471" target="_blank" title="Therigy Knowledge Network">We’ve Got You Covered</a>',
        'Is there something new you’d like to see in TherigySTM. <a href="http://knowledge.therigy.com/mod/forum/view.php?id=394" target="_blank" title="Therigy Knowledge Network">Let Us Know</a>',
        'Keep track of future Prior Authorizations using Referral Manager. <a href="http://knowledge.therigy.com/course/view.php?id=3&section=8" target="_blank" title="Therigy Knowledge Network">Learn How</a>',
        'Do you need to change when an assessment is triggered? <a href="http://knowledge.therigy.com/mod/page/view.php?id=479" target="_blank" title="Therigy Knowledge Network">Learn How </a>',
    ];

    ctrl.$onInit = $onInit;

    function $onInit() {
        let index = 0;
        ctrl.tip = `<strong>Tip: </strong>${ctrl.tips[index]}`;
        const elm = angular.element('.tip-text');

        $interval(() => {
            elm.addClass('fade-out');
            $timeout(() => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                index++;
                if (index === ctrl.tips.length) {
                    index = 0;
                }
                ctrl.tip = `<strong>Tip: </strong>${ctrl.tips[index]}`;
                elm.removeClass('fade-out');
            }, 1000);
        }, ctrl.interval);
    }
}

export default TipsController;
