import { useQuery } from 'react-query';
import { getProtocolTemplate } from 'App/services/ProtocolTemplateService';

function useFetchProtocolTemplateQuery(templateId) {
    return useQuery(['protocol', 'templates', templateId], () => getProtocolTemplate(templateId), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchProtocolTemplateQuery;
