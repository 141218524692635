/* eslint-disable no-param-reassign */
import PESService from 'App/services/PESService';
import CompanyService from 'App/services/CompanyService';

async function setPatientIdentifiers(ctrl) {
    const ehrBranchSettings = await CompanyService.getEhrBranchSettings();
    ctrl.ehr_primary_id_type = ehrBranchSettings.ehrPrimaryIdType;
    ctrl.ehr_enabled = !!ctrl.ehr_primary_id_type;

    if (!ctrl.patient.id && !ctrl.patientId) {
        return;
    }

    const identifiers = Object.values(await PESService.getIdentifiers(ctrl.patient.id || ctrl.patientId));
    ctrl.patient.ehrId = identifiers.find(
        (identifierEntry) => identifierEntry.identifierType === ctrl.ehr_primary_id_type
    )?.identifier;
    ctrl.patient.otherId = identifiers.find(
        (identifierEntry) => identifierEntry.identifierType === 'stm_other_id'
    )?.identifier;

    const idDisplayMap = {
        external_id: ctrl.patient.externalId || ctrl.patient.external_id,
        human_id: ctrl.patient.humanId || ctrl.patient.human_id,
        ehr_id: ctrl.patient.ehrId,
        other_id: ctrl.patient.otherId,
    };

    if (ctrl.user.company_permissions.DisplayedId === 'ehr_id') {
        idDisplayMap.ehrFallback = idDisplayMap.external_id;
    }

    ctrl.displayedId = idDisplayMap[ctrl.user.company_permissions.DisplayedId] || idDisplayMap.ehrFallback || '';
}

module.exports = { setPatientIdentifiers };
