/** @ngInject */
function DatepickerController(_, $filter) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeValue = doChangeValue;

    function $onInit() {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        ctrl.model.$render = function () {
            ctrl.name = ctrl.model.$name;

            // Because our dates return in YYYY-MM-DD format, we need to change the hyphens to slashes otherwise
            // the date will be off by a day
            if (ctrl.model.$viewValue && typeof ctrl.model.$viewValue === 'string') {
                ctrl.value = Date.parse(ctrl.model.$viewValue) // eslint-disable-next-line
                    ? new Date(ctrl.model.$viewValue.replace(/-/g, '/'))
                    : undefined;
            } else {
                ctrl.value = ctrl.model.$viewValue;
            }
        };
    }

    /**
     * @summary Initializes the component's dynamic variables
     */
    function $onChanges(changes) {
        if (changes.errors) {
            ctrl.errors = angular.copy(ctrl.errors);
            _.forEach(ctrl.errors, (error, errorName) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                error.message = error.message || null;
                ctrl.model.$setValidity(errorName, error.isValid);
            });
        }
        if (changes.ngDisabled) {
            ctrl.ngDisabled = angular.copy(ctrl.ngDisabled);
        }
        if (changes.ngReadonly) {
            ctrl.ngReadonly = angular.copy(ctrl.ngReadonly);
        }
        if (changes.ngRequired) {
            ctrl.ngRequired = angular.copy(ctrl.ngRequired);
            ctrl.requiredMessage = `Please enter a ${ctrl.label.toLowerCase()}`;
        }
        if (changes.datepickerOptions) {
            ctrl.datepickerOptions = angular.copy(ctrl.datepickerOptions);

            datePickerOptions(ctrl);
        }
    }

    /**
     * @summary Do change value handler
     * @description
     * This method is responsible for passing the value selected to bound onChangeValue function.
     *
     * @param value - The value that was selected
     */
    function doChangeValue(value) {
        ctrl.model.$setViewValue(value);

        if (value && ctrl.datepickerOptions && ctrl.datepickerOptions.minDate) {
            const minDateValid =
                $filter('date')(new Date(ctrl.datepickerOptions.minDate), 'yyyy/MM/dd') <=
                $filter('date')(value, 'yyyy/MM/dd');
            ctrl.model.$setValidity('minDate', minDateValid);
        }

        if (value && ctrl.datepickerOptions && ctrl.datepickerOptions.maxDate) {
            const maxDateValid =
                $filter('date')(new Date(ctrl.datepickerOptions.maxDate), 'yyyy/MM/dd') >=
                $filter('date')(value, 'yyyy/MM/dd');
            ctrl.model.$setValidity('maxDate', maxDateValid);
        }

        if (!value) {
            ctrl.model.$setValidity('minDate', true);
            ctrl.model.$setValidity('maxDate', true);
        }

        ctrl.onChangeValue({
            value,
        });
    }

    /**
     * @summary Handles datePickerOptions
     * @description
     * This method is here to allow datePickerOptions to be handled outside of $onChanges.
     *
     * @param ctrl - ctrl is being passed
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-shadow
    function datePickerOptions(ctrl) {
        if (ctrl.datepickerOptions && ctrl.datepickerOptions.maxDate) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            ctrl.maxDateMessage = `The ${ctrl.label.toLowerCase()} cannot be past ${$filter('date')(
                new Date(ctrl.datepickerOptions.maxDate),
                'yyyy/MM/dd'
            )}`;
        }
        if (ctrl.datepickerOptions && ctrl.datepickerOptions.minDate) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            ctrl.minDateMessage = `The ${ctrl.label.toLowerCase()} cannot be before ${$filter('date')(
                new Date(ctrl.datepickerOptions.minDate),
                'yyyy/MM/dd'
            )}`;
        }

        if (ctrl.value && ctrl.datepickerOptions && ctrl.datepickerOptions.minDate) {
            const minDateValid =
                $filter('date')(new Date(ctrl.datepickerOptions.minDate), 'yyyy/MM/dd') <=
                $filter('date')(ctrl.value, 'yyyy/MM/dd');
            ctrl.model.$setValidity('minDate', minDateValid);
        }

        if (ctrl.value && ctrl.datepickerOptions && ctrl.datepickerOptions.maxDate) {
            const maxDateValid =
                $filter('date')(new Date(ctrl.datepickerOptions.maxDate), 'yyyy/MM/dd') >=
                $filter('date')(ctrl.value, 'yyyy/MM/dd');
            ctrl.model.$setValidity('maxDate', maxDateValid);
        }
    }
}

export default DatepickerController;
