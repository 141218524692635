import React from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import PatientInsuranceTabs from 'App/components/PatientInsurance/PatientInsuranceTabs';

/** @ngInject */
function AddPatientInsuranceController($filter, $http, $httpParamSerializerJQLike, $q, $scope) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.$onDestroy = $onDestroy;
    ctrl.$onChanges = $onChanges;
    ctrl.handlePropChange = handlePropChange;
    ctrl.removeInsurancePlan = removeInsurancePlan;
    ctrl.validationInsuranceErrors = validationInsuranceErrors;
    ctrl.patientInsurance = {};
    ctrl.validationInsuranceErrors = {};
    $scope.$on('validatePatientInsurance', validationInsuranceErrors);
    $scope.$on('updateRequiredFields', updateRequiredFields);

    function validateInsurancePlan(item, id, value) {
        ctrl.validationInsuranceErrors[item] = ctrl.validationInsuranceErrors[item] || {};
        ctrl.validationInsuranceErrors[item][id] = '';
        if (!value || value === 'Select') {
            if (id === 'name') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Plan Name';
            }
            if (id === 'planPatientId') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Patient ID';
            }
            if (id === 'coverageType') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Benefit Coverage Type';
            }
            if (id === 'payerSegment') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Payor Type';
            }
        }
        mountIt();
    }

    function handlePropChange(item, id, value) {
        ctrl.patientInsurance[item] = ctrl.patientInsurance[item] || {};
        ctrl.patientInsurance[item][id] = value;
        $scope.$emit('updatePatientInsurance', ctrl.patientInsurance);
        mountIt();
    }

    function removeInsurancePlan(index) {
        delete ctrl.patientInsurance[index];
        mountIt();
    }

    function mountIt() {
        const dom = document.getElementById('insurance-plans');
        if (dom) {
            // TODO: Fix this the next time the file is edited.
            /* eslint-disable react/jsx-handler-names */
            ReactDOM.render(
                <PatientInsuranceTabs
                    patientInsurance={ctrl.patientInsurance || []}
                    handleChangeProps={ctrl.handlePropChange}
                    removeInsurancePlan={ctrl.removeInsurancePlan}
                    validationInsuranceErrors={ctrl.validationInsuranceErrors || []}
                    validateInsurancePlan={validateInsurancePlan}
                    requiredFields={ctrl.requiredFields}
                    disabledFields={ctrl.disabledFields}
                />,
                dom
            );
            /* eslint-enable react/jsx-handler-names */
        } else {
            setTimeout(mountIt, 100);
        }
    }

    function $onInit() {
        ctrl.patientInsurance = {};

        mountIt();
    }

    function $onChanges(changes) {
        if (changes.disabledFields) {
            ctrl.disabledFields = angular.copy(ctrl.disabledFields);
        }
    }

    function $onDestroy() {
        const dom = document.getElementById('insurance-plans');
        if (dom) {
            unmountComponentAtNode(dom);
        }
    }

    function validationInsuranceErrors(event, validateInsurance) {
        ctrl.validationInsuranceErrors = validateInsurance;
        mountIt();
    }

    function updateRequiredFields(event, requiredFields) {
        ctrl.requiredFields = requiredFields;
        mountIt();
    }
}

export default AddPatientInsuranceController;
