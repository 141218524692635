import React from 'react';
import { UserChecker, UserContextProvider } from 'App/contexts/UserContext';
import Router from './Router';

const ReferralManagerWrapper = () => {
    return (
        <UserContextProvider>
            <UserChecker>
                <Router />
            </UserChecker>
        </UserContextProvider>
    );
};

export default ReferralManagerWrapper;
