import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useGetPatientInsurance from '../../hooks/useGetPatientInsurance';
import PatientInsuranceTabs from 'App/components/PatientInsurance/PatientInsuranceTabs';
import { PatientSection, getSectionVisibility, toggleSectionVisibility } from '../../PatientProfile.utils';

const propTypes = {
    patientId: PropTypes.string.isRequired,
};

const defaultProps = {};

function PatientInsuranceWrapper({ patientId }) {
    const [patientInsurance, setPatientInsurance] = useState({});
    const [showInsurancePlan, setShowInsurancePlan] = useState(getSectionVisibility(PatientSection.insurance));
    const [disableInsurancePlan, setDisableInsurancePlan] = useState(false);
    const { isInsuranceLoading, insurance } = useGetPatientInsurance(patientId);

    useEffect(() => {
        setPatientInsurance(insurance);
    }, [insurance]);

    const toggleInsurancePlans = () => {
        setShowInsurancePlan(!showInsurancePlan);
        toggleSectionVisibility(PatientSection.insurance);
    };

    const removeInsurancePlan = (index) => {
        const tempInsurance = { ...patientInsurance };
        delete tempInsurance[index];
        setPatientInsurance({ ...tempInsurance });
    };

    const handleDisablePlan = (flag) => {
        setDisableInsurancePlan(!!flag);
    };

    return (
        <PatientInsuranceTabs
            patientInsurance={patientInsurance}
            removeInsurancePlan={removeInsurancePlan}
            toggleInsurancePlans={toggleInsurancePlans}
            showInsurancePlans={showInsurancePlan}
            handleDisablePlan={handleDisablePlan}
            disableInsurancePlan={disableInsurancePlan}
            isReadOnly
            loadingPatientInsurance={isInsuranceLoading}
        />
    );
}

PatientInsuranceWrapper.propTypes = propTypes;
PatientInsuranceWrapper.defaultProps = defaultProps;

export default PatientInsuranceWrapper;
