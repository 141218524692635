import createAxios from 'App/utils/createAxios';
import config from '../components/config/config';
import getSlsBasePath from '../utils/getSlsBasePath/getSlsBasePath';

const slsHost = getSlsBasePath(config, 'rms');

const rms = createAxios({
    baseURL: `${slsHost}/${config.slsVersion}`,
});

function getAlerts() {
    return rms.get('/alerts');
}

function getAlertCount() {
    return rms.get('/alerts/count');
}

function deleteAlerts(alertUuids = []) {
    return rms.delete('/alerts', { data: { alertUuids } });
}

function addAlertUser(userId) {
    return rms.post(`/alertUser`, { userId });
}

function deleteAlertUser(id) {
    return rms.delete(`/alertUser/${id}`);
}

function setAlertAsViewed(alertId) {
    return rms.patch(`/alerts/${alertId}`, { viewedOn: new Date() });
}

function getAlertUsers() {
    return rms.get('/alertUser');
}

function getAlertPatients(programId) {
    return rms.get(`/alert/${programId}/patient`);
}

function getAlertSettings() {
    return rms.get('alertSettings');
}

function addAlertSetting(setting) {
    return rms.post('alertSetting', setting);
}

function updateAlertSetting(setting) {
    const { uuid } = setting;
    return rms.put(`alertSetting/${uuid}`, setting);
}
/**
 * Get the alert settings for the company
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function getAlertCompanySettings() {
    return rms.get(`/alertCompanySetting`);
}

/**
 * Update the alert setting for the company.
 * The original design was to allow one to opt in or out of _both_ email and alert center. It was then later
 * decided to only allow the toggling of the email option.
 * @param {object} setting - The setting
 * @return {Promise<AxiosResponse<any>>}
 */
function updateAlertCompanySetting(setting) {
    const { uuid, email } = setting;
    return rms.patch(`/alertCompanySetting/${uuid}`, { email });
}

export {
    addAlertSetting,
    addAlertUser,
    deleteAlertUser,
    deleteAlerts,
    getAlertCompanySettings,
    getAlertCount,
    getAlertPatients,
    getAlertSettings,
    getAlertUsers,
    getAlerts,
    setAlertAsViewed,
    updateAlertCompanySetting,
    updateAlertSetting,
};
