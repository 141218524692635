/** @ngInject */
function LoginConfig($stateProvider) {
    $stateProvider.state('auth.login', {
        url: '/login',
        component: 'login',
        params: {
            passwordReset: false,
            sessionExpired: false,
        },
    });
}

export default LoginConfig;
