import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';

function useFetchAllBranchSettingsForTypeQuery(settingKeyType) {
    return useQuery(
        ['branchSettings', settingKeyType],
        () => CompanyService.getAllBranchSettingsForType(settingKeyType),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchAllBranchSettingsForTypeQuery;
