/** @ngInject */
function CommonConfig($stateProvider, laddaProvider, toastrConfig) {
    laddaProvider.setOption({
        style: 'slide-right',
    });
    angular.extend(toastrConfig, {
        closeButton: true,
        closeHtml: '<button><i class="fa fa-fw fa-times"></i></button>',
        positionClass: 'toast-top-right',
        tapToDismiss: false,
        timeOut: 10000,
    });

    $stateProvider.state('app', {
        redirectTo: 'app.workQueue',
        data: {
            requiredAuth: true,
        },
        params: {
            branchId: null,
            user: null,
        },
        component: 'app',
        resolve: {
            user(userService) {
                return userService
                    .get()
                    .then((res) => {
                        return res;
                    })
                    .catch(() => {});
            },
        },
    });
}

export default CommonConfig;
