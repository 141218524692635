import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import reactStringReplace from 'react-string-replace';

import { patientPropType } from 'Lib/types';
import ScrollToElementLink from '../ScrollToElementLink';
import { scorePropType } from '../types';
import AssessmentContentMonographLink from './AssessmentContentMonographLink';

const DisplayCMSContent = ({ content, patient, score }) => {
    const parsed = parse(content, {
        transform: (reactNode, domNode, index) => {
            if (domNode.type === 'text') {
                const replacedString = reactStringReplace(reactNode, /\{\{([^{}]*?)\}\}/g, (match, i) => {
                    return <AssessmentContentMonographLink key={match + i} templateMatch={match} patient={patient} />;
                });
                return replacedString;
            }

            if (domNode.name === 'a' && domNode.attribs.href === '#pend-activity-btn') {
                return (
                    <ScrollToElementLink key={index} elementId={domNode.attribs.href.replace(/^#/, '')}>
                        {reactNode.props.children}
                    </ScrollToElementLink>
                );
            }

            if (domNode.name === 'span' && ['rapid3-score', 'rapid3-functional-score'].includes(domNode.attribs.id)) {
                return (
                    <domNode.name {...domNode.attribs} key={index}>
                        {score.value}
                    </domNode.name>
                );
            }
            if (domNode.name === 'span' && domNode.attribs.id === 'rapid3-stratification') {
                return (
                    <domNode.name {...domNode.attribs} key={index}>
                        {score.stratification}
                    </domNode.name>
                );
            }

            return reactNode;
        },
    });

    return parsed;
};

DisplayCMSContent.propTypes = {
    content: PropTypes.string.isRequired,
    patient: patientPropType,
    score: scorePropType,
};

export default DisplayCMSContent;
