import * as R from 'ramda';
import * as uuid from 'uuid';
import hasLength from '../../utils/hasLength';
import stripCurlyTags from '../../utils/stripCurlyTags';
import { DISPLAY_OPERATORS, OPERATORS, QUESTION_TYPES, REFERRAL_TYPES, RULE_TYPES } from './constants';
import { stripAllHtml } from '../../utils';
import moment from 'moment';

/**
 * Helper to append a new condition group to the the formik state.
 * @param {string} groupName - The key in the state to add conditions to.
 * @param {array} filters - The filters to append. Can be an empty array.
 * @param {object} formikValues - The whole of the state value from formik.
 * @type {any}
 */
const addCondition = R.curry((groupName, filters, formikValues) => {
    return R.compose(
        R.over(
            R.lensPath(['conditions', groupName, 'conditions']),
            R.append({
                // This key is primarily for proper updates in React.
                // See https://reactjs.org/docs/lists-and-keys.html#keys
                key: uuid.v4(),
                filters,
            })
        )
    )(formikValues);
});

/**
 * Map the inbound available options.
 * @param {string|number} id - The ID.
 * @param {string|number} option - The option value/label
 */
const mapInboundOptions = R.curry((option) => {
    return { label: option, value: option };
});

/**
 * Map the inbound operators so they are sorted and negated correctly.
 * @param {object[]} operators
 * @returns {array}
 */
const mapInboundOperators = (operators) => {
    const doNotNegateOperators = [
        OPERATORS.GREATER_THAN,
        OPERATORS.GREATER_THAN_EQUALS,
        OPERATORS.LESS_THAN,
        OPERATORS.LESS_THAN_EQUALS,
    ];

    return R.compose(
        R.sortBy(R.prop('label')),
        R.reduce((result, operator) => {
            result.push({
                label: DISPLAY_OPERATORS[operator],
                value: operator,
            });

            if (!doNotNegateOperators.includes(operator)) {
                result.push({
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-use-before-define
                    label: negateOperator(operator).replace(/_/g, ' '),
                    isNegation: true,
                    value: `${operator}-negated`,
                });
            }

            return result;
        }, [])
    )(operators);
};

/**
 * Negates a provided operator for display purposes..
 * @param {string} operator
 * @returns {string}
 */
const negateOperator = (operator) => {
    switch (operator) {
        case OPERATORS.EQUALS:
        case OPERATORS.IS:
            return 'is not equal to';

        case OPERATORS.CONTAINS:
            return 'does not contain';

        default:
            return `not ${operator}`;
    }
};

/**
 * Maps and sorts the operators and options from the profile filter.
 * @params {object} data - The collection to process.
 * @returns {object[]} - An array of objects with the new keys mapped.
 */
const mapProfileFilterOptions = R.compose(
    R.sortBy(R.prop('label')),
    R.map((opt) =>
        R.compose(
            R.over(
                R.lensProp('availableOptions'),
                R.compose(
                    R.ifElse(hasLength, R.compose(R.sortBy(R.prop('label')), R.map(mapInboundOptions)), R.identity)
                )
            ),

            // need to rename options to something else so react-select won't think we're giving it option groups
            R.dissoc('options'),
            R.set(R.lensProp('availableOptions'), opt.options),

            R.over(R.lensProp('operators'), R.ifElse(hasLength, mapInboundOperators, R.identity))
        )(opt)
    ),
    R.values
);

/**
 * Extracts the answers from the questions and formats them into a sorted list.
 * @param {object} answers
 * @returns {object[]}
 */
function getAvailableAnswerOptions(answers) {
    return R.compose(
        R.ifElse(
            hasLength,
            R.compose(
                R.sortBy(R.prop('position')),
                R.map((answer) => {
                    return {
                        ...answer,
                        label: R.compose(stripCurlyTags, stripAllHtml)(answer.option),
                        value: answer.id,
                    };
                })
            ),
            R.always(undefined)
        ),
        R.values
    )(answers);
}

/**
 * These operators only apply when building activities. The Profile operators are provided via an API.
 * @param {string} type
 * @returns {string[]}
 */
function getAssessmentOperators(type) {
    switch (type) {
        case QUESTION_TYPES.RADIO:
        case QUESTION_TYPES.SELECT:
        case QUESTION_TYPES.CHECKBOX:
            return [OPERATORS.EQUALS];

        case QUESTION_TYPES.DATE:
        case QUESTION_TYPES.NUMBER:
            return [
                OPERATORS.IS,
                OPERATORS.GREATER_THAN,
                OPERATORS.GREATER_THAN_EQUALS,
                OPERATORS.LESS_THAN,
                OPERATORS.LESS_THAN_EQUALS,
            ];

        case QUESTION_TYPES.MESSAGE:
        case QUESTION_TYPES.TEXTAREA:
        case QUESTION_TYPES.TEXTBOX:
            return [OPERATORS.IS, OPERATORS.CONTAINS];

        default:
            return [];
    }
}

/**
 * These question names are excluded from the available question filters.
 */
const EXCLUDED_QUESTION_NAMES = ['therapy_summary_note'];

/**
 * These question types are excluded from the available question filters.
 */
const EXCLUDED_QUESTION_TYPES = ['message'];
const mapActivityFilterOptions = R.compose(
    R.sortBy(R.prop('label')),
    R.map((question) => {
        return {
            ...question,
            availableOptions: getAvailableAnswerOptions(question.answers),
            operators: R.compose(mapInboundOperators, getAssessmentOperators)(question.type),
            value: question.questionId,
            label:
                (R.pathOr(false, ['attributes', 'hidden'], question) ? '(HIDDEN) ' : '') +
                R.compose(stripCurlyTags, stripAllHtml)(question.question),
        };
    }),
    R.reject((q) => R.includes(q.name, EXCLUDED_QUESTION_NAMES) || R.includes(q.type, EXCLUDED_QUESTION_TYPES)),
    R.values
);

function getFilterValue(filter) {
    const value = R.hasPath(['value', 'value'], filter) ? filter.value.value : filter.value;
    if (value instanceof Date) {
        return moment(value).format('YYYY-MM-DD');
    }
    return value;
}

/**
 * Maps the filters so they can be displayed in the table
 * @param {string} answerIdField - The field that will hold the answer ID in the final result.
 * @param {function} answerIdSelector - The function that will be called with each item in order to get the ID of the
 * answer.
 * @param {object} conditions - The conditions object used to derive the filters.
 * @returns {object[]} - The filters.
 */
const mapFiltersForLocalSave = R.curry((answerIdField, answerIdSelector, conditions) => {
    return R.compose(
        R.reduce((result, item) => {
            const hasConditions = hasLength(item.conditions);
            const filter = {
                id: uuid.v4(),
                groupId: 0,
                [answerIdField]: answerIdSelector(item),
                notSelected: 0,
                value: '',
            };

            if (hasConditions) {
                R.addIndex(R.forEach)((c, idx) => {
                    R.forEach((f) => {
                        const [operator] = f.operator.value.split('-');

                        const value = getFilterValue(f);

                        const conditionFilter = {
                            ...filter,
                            id: uuid.v4(),
                            groupId: idx,
                            notSelected: f.operator.isNegation ? 1 : 0,
                            operator,
                            value,
                            questionText: item.field.label,
                            answerText: String(R.pathOr(value, ['value', 'label'], f)),
                        };

                        result.push(conditionFilter);
                    }, c.filters);
                }, item.conditions);
            } else {
                filter.questionText = item.field.label;
                filter.operator = OPERATORS.IS_ANSWERED;
                result.push(filter);
            }

            return result;
        }, []),
        R.values
    )(conditions);
});

/**
 * Map the triggers so they can be displayed in the table from the add rule page.
 * @param {object} values - Formik values.
 * @returns {object}
 */
function mapAddRuleValuesForLocalSave(values) {
    const trigger = {
        // The assessment to create/add/trigger
        assessmentId: values.activityToTrigger.id,
        alias: '',
        profileTrigger: null,
        activityTrigger: null,
        nextAssessmentName: values.activityToTrigger.name,
    };

    if (values.triggeredFrom.value === RULE_TYPES.PROFILE) {
        trigger.profileTrigger = {
            // remove ID before saving
            id: uuid.v4(),
            days: Number(values.days),
            beforeAfter: values.dateRelation.value,
            dateFieldId: values.dateType.value,
            dateFieldText: values.dateType.label,
            profileFilters: mapFiltersForLocalSave('fieldId', R.path(['field', 'id']), values.conditions),
        };
    }

    if (values.triggeredFrom.value === RULE_TYPES.INSURANCE) {
        trigger.insuranceTrigger = {
            // remove ID before saving
            id: uuid.v4(),
            days: Number(values.days),
            beforeAfter: values.dateRelation.value,
            dateFieldId: values.dateType.value,
            dateFieldText: values.dateType.label,
            filter: mapFiltersForLocalSave('fieldId', R.path(['field', 'id']), values.conditions),
        };
    }

    if (values.triggeredFrom.value === RULE_TYPES.REFERRAL_STATUS) {
        trigger.referralTrigger = {
            // remove ID before saving
            id: uuid.v4(),
            days: Number(values.days),
            beforeAfter: values.dateRelation.value,
            dateFieldId: values.dateType.value,
            dateFieldText: values.dateType.label,
            filter: mapFiltersForLocalSave('fieldId', R.path(['field', 'id']), values.conditions),
        };
    }

    if (R.keys(REFERRAL_TYPES).map(Number).includes(values.triggeredFrom.value)) {
        trigger.referralActivityTrigger = {
            // remove ID before saving
            id: uuid.v4(),
            referralActivityType: values.triggeredFrom.value,
            days: Number(values.days),
            beforeAfter: values.dateRelation.value,
            dateFieldId: values.dateType.value,
            dateFieldText: values.dateType.label,
            filter: mapFiltersForLocalSave('fieldId', R.path(['field', 'id']), values.conditions),
        };
    }

    if (values.triggeredFrom.value === RULE_TYPES.ACTIVITY) {
        trigger.activityTrigger = {
            // remove ID before saving
            id: uuid.v4(),
            // this is the assessment to complete
            assessmentId: values.activityToComplete.id,
            dateCheck: 0,
            dateFieldId: values.dateType.value,
            dateFieldText: values.dateType.label,
            days: Number(values.days),
            beforeAfter: values.dateRelation.value,
            activityFilters: mapFiltersForLocalSave(
                'questionId',
                Object.values(values.conditions)
                    .map((t) => t.field.officialQuestionId)
                    .filter((t) => t !== null).length
                    ? R.path(['field', 'officialQuestionId'])
                    : R.path(['field', 'questionId']),
                values.conditions
            ),
            onCompletionOfActionName: values.activityToComplete.name,
        };
    }
    return trigger;
}

/**
 * Maps the out-going filters.
 * @param {string} answerIdField - The field that will hold the answer ID in the final result.
 * @param {function} answerIdSelector - The function that will be called with each item in order to get the ID of the
 * answer.
 * @param {object} filters - The conditions object used to derive the filters.
 * @returns {object[]} - The filters.
 */
const mapFiltersForRemoteSave = R.curry((answerIdField, answerIdSelector, conditions) => {
    return R.compose(
        R.reduce((result, item) => {
            const hasConditions = hasLength(item.conditions);
            const filter = {
                groupId: 0,
                [answerIdField]: answerIdSelector(item),
                notSelected: 0,
                value: '',
            };

            if (hasConditions) {
                R.addIndex(R.forEach)((c, idx) => {
                    R.forEach((f) => {
                        const [operator] = f.operator.value.split('-');

                        const value = getFilterValue(f);

                        const conditionFilter = {
                            ...filter,
                            groupId: idx,
                            notSelected: f.operator.isNegation ? 1 : 0,
                            operator,
                            value,
                        };

                        result.push(conditionFilter);
                    }, c.filters);
                }, item.conditions);
            } else {
                filter.operator = OPERATORS.IS_ANSWERED;
                result.push(filter);
            }

            return result;
        }, []),
        R.values
    )(conditions);
});

/**
 * Map the triggers for out-going trigger and filter values
 * @param {object} values - Formik values.
 * @returns {object}
 */
function mapAddRuleValuesForRemoteSave(values) {
    const trigger = {
        // The assessment to create/add/trigger
        assessmentId: R.isNil(values.activityToTrigger.referralTypeEnum) ? values.activityToTrigger.id : null,
        alias: '',
        profileTrigger: null,
        activityTrigger: null,
        referralActivityType: values.activityToTrigger.referralTypeEnum,
    };

    if (values.triggeredFrom.value === RULE_TYPES.PROFILE) {
        trigger.profileTrigger = {
            days: Number(values.days),
            beforeAfter: values.dateRelation.value,
            dateFieldId: values.dateType.value,
            profileFilters: mapFiltersForRemoteSave('fieldId', R.path(['field', 'id']), values.conditions),
        };
    }

    if (values.triggeredFrom.value === RULE_TYPES.INSURANCE) {
        trigger.insuranceTrigger = {
            days: Number(values.days),
            beforeAfter: values.dateRelation.value,
            dateFieldId: values.dateType.value,
            /**
             * The insurance triggers use a JSON column for filters, so we can just map them to the same schema
             * we use in the UI.
             */
            filter: mapFiltersForLocalSave('fieldId', R.path(['field', 'id']), values.conditions),
        };
    }

    if (values.triggeredFrom.value === RULE_TYPES.REFERRAL_STATUS) {
        trigger.referralTrigger = {
            days: Number(values.days),
            beforeAfter: values.dateRelation.value,
            dateFieldId: values.dateType.value,
            /**
             * The insurance triggers use a JSON column for filters, so we can just map them to the same schema
             * we use in the UI.
             */
            filter: mapFiltersForLocalSave('fieldId', R.path(['field', 'id']), values.conditions),
        };
    }

    if (R.keys(REFERRAL_TYPES).map(Number).includes(values.triggeredFrom.value)) {
        trigger.referralActivityTrigger = {
            referralActivityType: values.triggeredFrom.value,
            days: Number(values.days),
            beforeAfter: values.dateRelation.value,
            dateFieldId: values.dateType.value,
            /**
             * The insurance triggers use a JSON column for filters, so we can just map them to the same schema
             * we use in the UI.
             */
            filter: mapFiltersForLocalSave('fieldId', R.path(['field', 'id']), values.conditions),
        };
    }

    if (values.triggeredFrom.value === RULE_TYPES.ACTIVITY) {
        trigger.activityTrigger = {
            // this is the assessment to complete
            assessmentId: values.activityToComplete.id,
            dateCheck: 0,
            dateFieldId: values.dateType.value,
            days: Number(values.days),
            beforeAfter: values.dateRelation.value,
            activityFilters: mapFiltersForRemoteSave(
                'questionId',
                Object.values(values.conditions)
                    .map((condition) => condition.field.officialQuestionId)
                    .filter((question) => question !== null).length
                    ? R.path(['field', 'officialQuestionId'])
                    : R.path(['field', 'questionId']),
                values.conditions
            ),
        };
    }

    return trigger;
}

/**
 * Gets the filter operator, or negates it if necessary.
 * @param filter
 * @returns {string}
 */
function getFilterOperator(filter) {
    if (Number(filter.notSelected)) {
        return negateOperator(filter.operator);
    }

    if (DISPLAY_OPERATORS[filter.operator]) {
        return DISPLAY_OPERATORS[filter.operator];
    }

    return filter.operator;
}

/**
 * Determine if a Formik field has been touched and has an error.
 * @param {string} name - The name or key within the formik state.
 * @param {object} formik - The formik context or "bag".
 * @returns {boolean} - True if the field has both been touched and has an error.
 */
const isFieldValid = R.curry((name, formik) => {
    return R.compose(
        R.ifElse(
            R.compose(R.isNil, R.path(['touched', name])),
            R.always(null),
            R.ifElse(R.compose(R.isNil, R.path(['errors', name])), R.always(null), R.always('error'))
        )
    )(formik);
});

const groupFilters = R.groupBy(({ questionId, groupId, fieldId }) => {
    if (questionId) {
        // Activities have questionIds
        return `${questionId}-${groupId}`;
    }
    // Profile fields have fieldIds
    return `${fieldId}-${groupId}`;
});

export {
    addCondition,
    getFilterOperator,
    groupFilters,
    isFieldValid,
    mapActivityFilterOptions,
    mapAddRuleValuesForLocalSave,
    mapAddRuleValuesForRemoteSave,
    mapInboundOperators,
    mapProfileFilterOptions,
    negateOperator,
};
