import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    row: PropTypes.object.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    type: PropTypes.string.isRequired,
};

const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    row: {},
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    type: '',
};

function MedicationSetting({ row, type }) {
    const { values, setValues } = useFormikContext();
    const disabled = type === 'manage' && values.medications[row.index].enroll;

    const update = (change) => {
        let newValue = change.target.value !== 'true';

        if (type === 'manage' && !newValue && values.medications[row.index].enroll) {
            // Prevent disabling manage when enroll is enabled.
            newValue = true;
        }
        if (type === 'enroll' && newValue) {
            // Set manage to true if enroll is true.
            values.medications[row.index].manage = newValue;
        }

        values.medications[row.index][type.toString()] = newValue;
        // If everything is not checked set the "all" checkboxes accordingly.
        values.all.enroll = values.medications.filter((r) => !r.enroll).length === 0;
        values.all.manage = values.medications.filter((r) => !r.manage).length === 0;
        setValues(values);
    };

    return (
        <Field
            type="checkbox"
            disabled={disabled}
            onChange={(e) => update(e)}
            data-testid={`medication-${row.index}-${type}`}
            name={`medications[${row.index}].${type}`}
        />
    );
}

MedicationSetting.propTypes = propTypes;
MedicationSetting.defaultProps = defaultProps;

export default MedicationSetting;
