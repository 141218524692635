import React from 'react';
import { cellPropsType } from '../../workQueue.constant';

const MedicationAssociationInsuranceCell = ({ value }) => {
    if (value !== '--') {
        let data = JSON.parse(value);
        data = data.length > 0 ? data : '--';
        return <span>{data}</span>;
    }
    return <span>{value}</span>;
};

MedicationAssociationInsuranceCell.propTypes = cellPropsType;

export default MedicationAssociationInsuranceCell;
