import UserService from 'App/services/UserService';
import AuthService from 'App/services/AuthService';
import React, { createContext, useContext, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import FeatureFlags from 'App/components/FeatureFlags';
import PropTypes from 'prop-types';
import LoadBaseData from 'App/components/LoadBaseData';
import { configureNewRelic, configureWalkme, configureMatomo } from 'App/services/TrackingService';

const defaultContext = {
    active_branch: {
        ID: '',
        Name: '',
        Privileges: {
            AddPatient: false,
            Api: false,
            BranchAdmin: false,
            CompanyAdmin: false,
            ConductActivities: false,
            EditProtocols: false,
            Nursing: false,
            Pcc: false,
            ReportBuilder: false,
            ReportViewer: false,
            SummaryNotes: false,
            ViewPatient: false,
            ViewReports: false,
            WorkQueueAssignment: false,
        },
    },
    active_company: {
        ID: '',
        Name: '',
    },
    active_roles: {
        RN: false,
        RPh: false,
        PCC: false,
    },
    active_session: {
        SessionId: '',
    },
    branches: [
        {
            id: '',
            name: '',
            createdOn: '',
            modifiedOn: '',
        },
        {
            id: '',
            name: '',
            createdOn: '',
            modifiedOn: '',
        },
    ],
    company_permissions: {
        AdvanceReport: false,
        AdvancedReferral: false,
        Api: false,
        ApiDefaultReferral: '',
        AssessmentManager: false,
        BrowserRefreshTime: '3600000',
        Builder: '',
        ClientLinking: false,
        ClientProtocolBuilder: false,
        ContentDeveloper: false,
        DisableAddPatient: false,
        DisplayedId: '',
        FromEmail: '',
        FromName: '',
        MckessonCpsIntegration: false,
        MckessonNodeRed: false,
        NewProtocolManager: false,
        OnboardAfterReferral: false,
        PatientMessaging: false,
        Pcc: false,
        Pdx: false,
        PdxClientId: '',
        PdxClientIdType: '',
        ReferralManager: false,
        ReportEngine: false,
        ReportGroups: '',
        ShowRetiredAssessments: false,
        SsoEnabled: false,
        SsoLogin: '',
        SsoLogout: '',
        TherigyAssessmentAdmin: false,
        TmsEndpoint: '',
        Viewer: '',
        WorkQueueForceBranchFilter: false,
        WorkQueueHeaderCountBy: '',
    },
    daylight_savings: 0,
    default_branch: false,
    email: '',
    expired_branches: [],
    first_name: '',
    id: '',
    is_password_expired: false,
    last_name: '',
    message_email: 'Y',
    time_zone_offset: -12,
    timeout_length: 120,
    verbose: 1,
    user_permissions: {
        AddPatient: false,
        Api: false,
        BranchAdmin: false,
        CompanyAdmin: false,
        ConductActivities: false,
        EditProtocols: false,
        Nursing: false,
        Pcc: false,
        ReportBuilder: false,
        ReportViewer: false,
        SummaryNotes: false,
        ViewPatient: false,
        ViewReports: false,
        WorkQueueAssignment: false,
    },
    updateCount: Function,
};

const UserContext = createContext(defaultContext);

const { Provider, Consumer: UserContextConsumer } = UserContext;

function UserContextProvider({ children }) {
    const [user, setUser] = useState(UserService.get());

    return <Provider value={{ ...user, setUser }}>{children}</Provider>;
}

UserContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function useUserContext() {
    return useContext(UserContext);
}

function UserChecker({ children }) {
    const user = useUserContext();
    const location = useLocation();

    if (!user?.id) {
        AuthService.setTargetPage(`${location.pathname}${location.search}`);
        return <Redirect to="/login" />;
    }

    if (user.ApiUser || !user?.Applications.STM) {
        localStorage.clear();
        return <Redirect to="/login?message=Unauthorized" />;
    }

    // if (!user.default_branch) {
    //     //todo: add a way to tell the app resetDefaultBranch is true
    //     return <Redirect to="/accessView" />;
    // }

    const targetPage = AuthService.getTargetPage();

    if (targetPage) {
        AuthService.clearTargetPage();
        return <Redirect to={targetPage} />;
    }

    configureWalkme(user);
    configureMatomo(user);
    configureNewRelic(user);

    return (
        <LoadBaseData>
            <FeatureFlags>{children}</FeatureFlags>
        </LoadBaseData>
    );
}

UserChecker.propTypes = {
    children: PropTypes.node.isRequired,
};

export { UserContext, UserContextConsumer, UserContextProvider, defaultContext, useUserContext, UserChecker, Provider };
