import React from 'react';
import mountReactComponent from 'App/utils/mountReactComponent';
import {
    PatientPredictiveModelRiskIndicator,
    PatientPredictiveModelRiskText,
} from 'App/components/PatientPredictiveModelRisk';
import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';
import hasLength from 'App/utils/hasLength';

/** @ngInject */
function WorkQueueController(
    _,
    moment,
    $q,
    $scope,
    $state,
    $uibModal,
    ngTableEventsChannel,
    NgTableParams,
    toastr,
    dateService,
    rmsService,
    sessionStorageService,
    workQueueService,
    CSRF_ID_VALUE,
    CSRF_ID_NAME
) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.onChangeFilter = onChangeFilter;
    ctrl.openDueDateModal = openDueDateModal;
    ctrl.openNotesModal = openNotesModal;
    ctrl.saveFilter = saveFilter;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;
    ctrl._formatActivities = _formatActivities;
    ctrl.data = [];

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
        ctrl.patientPredictiveModelEnabled = isPermitted(PERMISSION_TYPES.PATIENT_PREDICTIVE_MODEL, ctrl.user);
    }

    function $onInit() {
        const rangeType = sessionStorageService.getOnUser(`workQueue_selectedFilter`, true);

        if (rangeType && rangeType.dateRange) {
            ctrl.dateRange = rangeType.dateRange;
        } else {
            ctrl.dateRange = 'this_week';
        }

        ctrl.cols = workQueueService.getDefaultColumns(ctrl.user);
        ctrl.loading = true;
        ctrl.tableParams = new NgTableParams(
            {
                count: 25,
                filter: workQueueService.getDefaultFilter(ctrl.dateRange),
            },
            {
                counts: [10, 25, 50],
                defaultSort: 'asc',
                getData(ref) {
                    return getRows(ref);
                },
            }
        );

        applyTableParams(sessionStorageService.getOnUser(`workQueue_selectedFilter`, true) || ctrl.tableParams._params);

        return getHeader().then(() => {
            ngTableEventsChannel.onAfterReloadData((data) => {
                sessionStorageService.setOnUser(`workQueue_selectedFilter`, data._params);
            }, $scope);
        });
    }

    function onChangeFilter(filter) {
        angular.extend(ctrl.tableParams.filter(), filter);
        applyTableParams(ctrl.tableParams._params);
    }

    function openDueDateModal(rowData) {
        let p;
        if (rowData.type === 'REF') {
            p = rmsService.getActivity(rowData.patient_id, rowData.referral_id, rowData.activity_id).then((res) => {
                const activity = res;
                activity.date_due = activity.due_on;
                activity.type_label = 'Referral';
                return activity;
            });
        } else {
            p = $q.when({
                id: rowData.activity_id,
                date_due: rowData.date_due,
                patient_id: rowData.patient_id,
                type_label: rowData.type,
                referral_id: rowData.referral_id,
            });
        }

        return p.then((activity) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            activity.date_due = dateService.localize(activity.date_due);
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                component: 'patientProfileEditDueDateModal',
                size: 'md',
                resolve: {
                    activity() {
                        return activity;
                    },
                },
            });

            modalInstance.result
                .then((noteChanged) => {
                    if (noteChanged) {
                        $scope.$broadcast('updateWorkQueueCounts', {
                            params: { clearCache: Math.random() },
                        });
                        ctrl.tableParams.reload();
                    }
                })
                .catch((err) => {
                    return err.data;
                });
        });
    }

    function openNotesModal(rowData) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'activityNotes',
            size: 'md',
            windowClass: 'activity-notes-modal',
            resolve: {
                modalData() {
                    return {
                        activityId: rowData.activity_id,
                        activityName: rowData.activity_name,
                        firstName: rowData.patient_first_name,
                        lastName: rowData.patient_last_name,
                        type: rowData.type === 'REF' ? 'Referral' : rowData.type,
                    };
                },
            },
        });

        modalInstance.result
            .then((noteChanged) => {
                if (noteChanged) {
                    $scope.$broadcast('updateWorkQueueCounts');
                    ctrl.tableParams.reload();
                }
            })
            .catch((err) => {
                return err.data;
            });
    }

    function saveFilter() {
        ctrl.savingFilter = true;
        const params = {
            name: ctrl.filterName,
            filter: ctrl.tableParams.filter(),
        };

        return workQueueService
            .saveFilter(params)
            .then((res) => {
                toastr.success('Filter has been saved successfully.');
                ctrl.savedFilters = angular.copy(res);
            })
            .finally(() => {
                ctrl.filterName = '';
                ctrl.savingFilter = false;
            });
    }

    function applyTableParams(tableParams) {
        if (tableParams instanceof Object && tableParams.constructor === Object) {
            if (!(tableParams.filter instanceof Object) || !tableParams.filter.constructor === Object) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                tableParams.filter = {};
            }
            // Set the ng-table
            _.forEach(workQueueService.getTableParams(), (param) => {
                ctrl.tableParams[param](tableParams[param]);
            });

            // Set the UI
            _.forEach(workQueueService.getUiParams(), (param) => {
                ctrl[param] = tableParams.filter[param];
            });
        }
    }

    function getHeader() {
        return workQueueService
            .getHeader({
                params: {
                    type: ctrl.dateRange,
                },
            })
            .then((res) => {
                _.forEach(res.headers, (h) => {
                    if (!ctrl.cols[h.field_selected]) {
                        ctrl.cols[h.field_selected] = {};
                    }
                    ctrl.cols[h.field_selected].title = h.name_of_header;
                    ctrl.cols[h.field_selected].show = true;
                    ctrl.cols[h.field_selected].order = h.field_order;
                });

                ctrl.colsList = _.orderBy(
                    _.values(ctrl.cols),
                    (c) => {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line radix
                        return parseInt(c.order);
                    },
                    ['asc']
                );

                return res;
            });
    }

    function getRows(ref) {
        ctrl.loading = true;

        const params = {
            allBranches: ctrl.all_branches,
            allCategories: ctrl.all_categories,
            allTypes: ctrl.all_types,
            assignedToMe: ctrl.assignedToMe,
            branches: _.keys(ctrl.branches),
            categories: _.keys(ctrl.group_ids),
            count: ctrl.tableParams.count(),
            dateEnd: moment(ctrl.dateEnd).format('YYYY-MM-DD'),
            dateStart: moment(ctrl.dateStart).format('YYYY-MM-DD'),
            filters: _.keys(ctrl.activityTypes),
            highRisk: ctrl.highRisk,
            onlyMine: ctrl.onlyMine,
            page: ctrl.tableParams.page(),
            predictiveModelRisk: ctrl.predictiveModelRisk,
            search: workQueueService.createSearch(ctrl.tableParams.filter()),
            sorting: ctrl.tableParams.sorting(),
            type: ctrl.dateRange,
        };

        return workQueueService
            .get(params)
            .then((res) => {
                ctrl.data = res.data;
                const formattedActivities = _formatActivities(res.data);
                ref.total(res.count); // trigger redraw
                return formattedActivities;
            })
            .finally(() => {
                mountPredictiveModelComponent();
                ctrl.loading = false;
            });
    }
    /**
     * Mount react
     */
    function mountPredictiveModelComponent() {
        if (!ctrl.patientPredictiveModelEnabled) return;
        const formattedData = convertKeysToCamelCase(ctrl.data);
        formattedData?.forEach((row, index) => {
            if (row.predictiveModel?.length) {
                mountReactComponent(
                    `#predictive-risk-${row.patientId}-${row.activityId}`,
                    <PatientPredictiveModelRiskIndicator key={index} riskSegments={row.predictiveModel} showOnHover />
                );
                mountReactComponent(
                    `#predictive-risk-reason-${row.patientId}-${row.activityId}`,
                    <PatientPredictiveModelRiskText
                        key={index}
                        riskSegments={row.predictiveModel}
                        showAll={false}
                        showFlag={false}
                        showOnHover
                    />
                );
            }
        });
    }

    function _formatActivities(activities) {
        return activities.map((activity) => {
            const modifiedActivity = { ...activity };
            modifiedActivity.dateDueValue = modifiedActivity.date_due
                ? dateService.localize(modifiedActivity.date_due)
                : dateService.localize(modifiedActivity.due_on);
            try {
                modifiedActivity.medication_association = JSON.parse(modifiedActivity.medication_association);
                modifiedActivity.medication_association = hasLength(modifiedActivity.medication_association)
                    ? modifiedActivity.medication_association
                    : null;
            } catch (e) {
                console.log(e);
                console.error('error in parsing medication associations');
            }
            try {
                modifiedActivity.insurance_plan_name = JSON.parse(modifiedActivity.insurance_plan_name);
                modifiedActivity.insurance_plan_name = hasLength(modifiedActivity.insurance_plan_name)
                    ? modifiedActivity.insurance_plan_name
                    : null;
            } catch (e) {
                console.log(e);
                console.error('error in parsing insurance plan names');
            }
            modifiedActivity.patient_labels = [
                modifiedActivity.patientLabel1,
                modifiedActivity.patientLabel2,
                modifiedActivity.patientLabel3,
                modifiedActivity.patientLabel4,
            ];
            modifiedActivity.patient_labels = modifiedActivity.patient_labels.filter((value) => !!value);
            modifiedActivity.needsByDateValue = dateService.localize(modifiedActivity.needs_by);
            return modifiedActivity;
        });
    }
}

export default WorkQueueController;
