import PropTypes from 'prop-types';

const historyNoteType = PropTypes.shape({
    body: PropTypes.string.isRequired,
    completed: PropTypes.string.isRequired,
    edited: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
});

const noteType = PropTypes.shape({
    body: PropTypes.string.isRequired,
    completed: PropTypes.string.isRequired,
    edited: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(historyNoteType).isRequired,
});

const notesPatient = PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    humanId: PropTypes.number.isRequired,
    externalId: PropTypes.string,
});

export { noteType, notesPatient, historyNoteType };
