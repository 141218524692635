import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';

function useFetchPatientIdentifiers(patientId) {
    return useQuery(['patientIdentifiers', patientId], () => PESService.getIdentifiers(patientId), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchPatientIdentifiers;
