import React from 'react';
import PropTypes from 'prop-types';
import ActionCell, { ActionButtonLink } from 'App/components/styles/ActionCell';
import { ActivityTypes } from 'App/components/PatientProfile/components/PatientActivities/PatientActivities';
import curry from 'ramda/src/curry';
import { Link } from 'react-router-dom';

const defaultProps = {};

export const NameCell = curry(({ divisionId, generateReferralLink }, { row, value }) => {
    if (row.original.typeLabel === 'Referral') {
        const params = {
            patientId: row.original.patientId,
            referralId: row.original.referralId,
            activityId: row.original.id,
            typeId: row.original.type,
        };
        return (
            <Link id={`pending-activity-${row.id}`} to={generateReferralLink(params)}>
                {value}
            </Link>
        );
    }
    return (
        <Link
            id={`pending-activity-${row.id}`}
            to={`/patient/${row.original.patientId}/activity/${row.original.id}?branchId=${divisionId}`}
        >
            {value}
        </Link>
    );
});
NameCell.propTypes = {
    divisionId: PropTypes.string,
    generateReferralLink: PropTypes.func.isRequired,
    row: PropTypes.shape({
        id: PropTypes.string,
        original: PropTypes.shape({
            companyId: PropTypes.string,
            id: PropTypes.string,
            patientId: PropTypes.string,
            referralId: PropTypes.string,
            typeLabel: PropTypes.string,
        }),
    }),
    value: PropTypes.string,
};
NameCell.defaultProps = defaultProps;

export const NoteStatusDateCell = curry(({ activityType, openModal }, { row, value }) => {
    return (
        <ActionCell>
            {ActivityTypes.DeletedActivities === activityType ? (
                value
            ) : (
                <ActionButtonLink onClick={() => openModal(row.original)}>{value}</ActionButtonLink>
            )}
        </ActionCell>
    );
});
NoteStatusDateCell.propTypes = {
    activityType: PropTypes.number.isRequired,
    openModal: PropTypes.func.isRequired,
    row: PropTypes.shape({
        original: PropTypes.shape({
            companyId: PropTypes.string,
        }),
    }),
    value: PropTypes.string,
};
NoteStatusDateCell.defaultProps = defaultProps;

export const NotesCell = curry(({ openModal }, { row }) => {
    return (
        <ActionCell>
            <ActionButtonLink onClick={() => openModal(row.original)}>Notes</ActionButtonLink>
        </ActionCell>
    );
});
NotesCell.propTypes = {
    openModal: PropTypes.func.isRequired,
    row: PropTypes.shape({
        original: PropTypes.shape({
            companyId: PropTypes.string,
        }),
    }),
};
NotesCell.defaultProps = defaultProps;

export const DateCell = ({ value }) => <ActionCell>{value}</ActionCell>;
DateCell.propTypes = {
    value: PropTypes.string.isRequired,
};
DateCell.defaultProps = defaultProps;
