/** @ngInject */
function PatientReferralInputController($scope) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangePatientReferral = doChangePatientReferral;

    function $onChanges(changes) {
        if (changes.patientReferral) {
            ctrl.patientReferral = angular.copy(ctrl.patientReferral);
        }
    }

    function doChangePatientReferral() {
        $scope.$emit('updatePatientReferral', ctrl.patientReferral);
    }
}

export default PatientReferralInputController;
