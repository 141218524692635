import angular from 'angular';

import ContentPublisherController from './content-publisher.controller';
import ContentPublisherConfig from './content-publisher.config';

export default angular
    .module('components.contentPublisher', ['ui.router'])
    .component('contentPublisher', {
        bindings: {
            contentGroup: '<',
            user: '<',
        },
        controller: ContentPublisherController,
        template: require('./content-publisher.html'),
    })
    .config(ContentPublisherConfig).name;
