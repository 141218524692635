import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAlertCenterAccess } from 'App/utils/hooks/useIsPermitted';
import env from '../config/config';
import { NotFound } from '../errors';
import Main from '../styles/Main/Main';
import PageTitle from '../styles/PageTitle/PageTitle';
import PageWrapper from '../styles/PageWrapper/PageWrapper';
import FinancialAssistance from './FinancialAssistance';
import AvailablePatients from './FinancialAssistance/AvailablePatients';

const propTypes = {};

const defaultProps = {};

function AlertsCenterPage() {
    const hasAlertCenterAccess = useAlertCenterAccess();
    const { path } = useRouteMatch();

    if (hasAlertCenterAccess) {
        return (
            <Main>
                <Helmet>
                    <title>Alerts Center - {env.catchPhrase}</title>
                </Helmet>

                <PageTitle>Alerts Center</PageTitle>
                <PageWrapper>
                    <Switch>
                        <Route
                            path={`${path}/financial-assistance/:programId/patients`}
                            component={AvailablePatients}
                        />

                        <Route>{hasAlertCenterAccess && <FinancialAssistance />}</Route>
                    </Switch>
                </PageWrapper>
            </Main>
        );
    }

    return <NotFound />;
}

AlertsCenterPage.propTypes = propTypes;
AlertsCenterPage.defaultProps = defaultProps;

export default AlertsCenterPage;
