import * as R from 'ramda';
import config from '../components/config/config';
import getSlsBasePath from '../utils/getSlsBasePath/getSlsBasePath';
import CompanyService from './CompanyService';
import createAxios from 'App/utils/createAxios';
import { getReferralAvailableMenus } from 'App/services/RMSService';
import hasLength from 'App/utils/hasLength';

const slsHost = getSlsBasePath(config, 'pms');
const axios = createAxios({
    baseURL: `${slsHost}/${config.slsVersion}`,
});

const mapCustomMenuLabelValue = (key) =>
    R.compose(
        R.map(
            R.applySpec({
                value: R.prop('valueName'),
                label: R.prop('valueName'),
            })
        ),
        CompanyService.getCustomMenu
    )(key);

/**
 * Map a set of menu keys to a collection of menu values
 * @param {Object} menuKeys - Object where the keys are the names of the custom menu and the values are arrays.
 * Values will be concatentated with the result here.
 * @return {Object}
 */
function mapCustomMenu(menuKeys) {
    return R.mapObjIndexed((val, key) => {
        const menu = CompanyService.getCustomMenu(key);
        return R.compose(R.concat(val), R.map(R.prop('valueName')))(menu);
    }, menuKeys);
}

class ProtocolService {
    /**
     * Fetches the protocol for the provided protocol ID.
     * @param {string} protocolId - The ID of the protocol.
     * @returns {Promise<protocols>} - A map of protocols keyed by ID for the therapeutic category provided.
     */
    static getProtocol(protocolId) {
        return axios.get(`/protocol/${protocolId}`);
    }

    /**
     * Fetches the protocols for the provided therapy ID.
     * @param {string} therapyId - The ID of the therapeutic category.
     * @returns {Promise<protocols>} - A map of protocols keyed by ID for the therapeutic category provided.
     */
    static getProtocols(therapyId) {
        return axios.get(`/therapy/${therapyId}/protocol`);
    }

    /**
     * Updates the protocol with the provided protocol.
     * @param {{id: string | number, name: string, status: 0 | 1}} protocol - The protocol including changes
     * @returns {Promise<protocol>} - The updated protocol
     */
    static updateProtocol(protocol) {
        return axios.patch(`/protocol/${protocol.id}`, protocol);
    }

    /**
     * Update the status of a given protocol. This allows updating protocols that are owned by Therigy as well as one's
     * own company
     * @param {string} protocolId - The protocol ID to update.
     * @param {{status: 0 | 1}} status - The status. 0 is disabled. 1 is enabled.
     * @returns {Promise<AxiosResponse<any>>}
     */
    static updateProtocolStatus(protocolId, status) {
        return axios.patch(`/protocol/${protocolId}/status`, status);
    }

    /**
     * Make a copy of the provided protocol ID
     * @param {{id: string}} protocol - The protocol to copy
     * @returns {Promise<protocol>}
     */
    static copyProtocol(protocol) {
        return axios.post(`/protocol/${protocol.id}/clone`, null);
    }

    /**
     * Perform a soft-delete of the provided protocol
     * @param {{id: string}} protocol - The protocol to delete
     * @returns {Promise<protocol>}
     */
    static deleteProtocol(protocol) {
        return axios.delete(`/protocol/${protocol.id}`);
    }

    static createProtocol(protocol) {
        return axios.post(`/protocol`, protocol);
    }

    /**
     * Create a protocol trigger
     * @param {string} protocolId
     * @param {object} trigger
     * @returns {Promise<AxiosResponse<any>>}
     */
    static createTrigger(protocolId, trigger) {
        return axios.post(`/protocol/${protocolId}/trigger`, trigger);
    }

    static getTriggers(protocolId) {
        return axios.get(`/protocol/${protocolId}/trigger`);
    }

    static getHydratedProtocol(protocolId) {
        return ProtocolService.getProtocol(protocolId).then((protocol) => {
            if (protocol.therapyId) {
                return (
                    Promise.all([
                        protocol,
                        CompanyService.getTherapyMedications(protocol.therapyId),
                        CompanyService.getTherapyDiagnosis(protocol.therapyId),
                        ProtocolService.getTriggers(protocolId),
                    ]) // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-shadow
                        .then(([protocol, medications, diagnosis, triggers]) => {
                            return { protocol, medications, diagnosis, triggers };
                        })
                );
            }
            return {};
        });
    }

    /**
     * Delete the triggers from the server
     * @param {string} protocolId
     * @param {number[]} triggerIds
     * @returns {Promise<AxiosResponse<any>>}
     */
    static deleteTriggers(protocolId, triggerIds) {
        return axios.delete(`/protocol/${protocolId}/trigger`, {
            data: triggerIds,
        });
    }

    /**
     * Gets the options available for filtering based on profile creation.
     * @returns {Promise<any>}
     */
    static getProfileFilterOptions() {
        return axios.get(`/profileFilterOptions`);
    }

    static getInsuranceFilterOptions() {
        return Promise.resolve([
            {
                id: 'planName',
                value: 'planName',
                label: 'Plan Name',
                operators: ['is'],
                type: 'textbox',
                options: [],
            },

            {
                id: 'coverageType',
                value: 'coverageType',
                label: 'Benefit Coverage Type',
                operators: ['is'],
                type: 'select',
                options: [
                    'Buy and Bill',
                    'Cash',
                    'Financial Assistance',
                    'Foundation Assistance',
                    'Medical',
                    'None',
                    'Pharmacy',
                ],
            },
            {
                id: 'payerSegment',
                value: 'payerSegment',
                label: 'Payor Type',
                operators: ['is'],
                type: 'select',
                options: [
                    'Auto Insurance',
                    'Charity/Indigent Program',
                    'Government',
                    'HMO/Commercial',
                    'Integrated PBM',
                    'Managed Medicaid',
                    'Medicaid',
                    'Medicare A',
                    'Medicare B',
                    'Medicare Advantage (Part C)',
                    'Medicare D',
                    'Medicare',
                    'None',
                    'Other',
                    'PPO/Commercial',
                    'Prisoners',
                    'Self-Pay',
                    'Tri-Care',
                    'Unknown',
                    'Workers Comp',
                ],
            },
            {
                id: 'pharmacyInsuranceBin',
                value: 'pharmacyInsuranceBin',
                label: 'BIN',
                operators: ['is'],
                type: 'textbox',
                options: [],
            },
            {
                id: 'pharmacyInsurancePcn',
                value: 'pharmacyInsurancePcn',
                label: 'PCN',
                operators: ['is'],
                type: 'textbox',
                options: [],
            },
            {
                id: 'pharmacyInsuranceGroupId',
                value: 'pharmacyInsuranceGroupId',
                label: 'RX Group Number',
                operators: ['is'],
                type: 'textbox',
                options: [],
            },
        ]);
    }

    static getReferralStatusChangeFilterOptions() {
        /**
         * Map each key to the evaluated custom menu
         */
        const customMenus = mapCustomMenu({
            referral_status: [],
            referral_status_reasons_complete: [],
            referral_status_reasons_hold: [],
            referral_status_reasons_pending: [],
            referral_dispense_status: [],
            referral_received_from: [],
        });

        /**
         * Recurse over the static data and extract out
         * the unique values for each level.
         * @param {Object[]} array
         * @param {Array[]} collector
         * @param {number} depth
         * @return {Array[]} - A tuple-like array where the first eleement is the depth and the second are the items.
         */
        const getChildren = (array, collector = [], depth = 0) => {
            if (!hasLength(array) && !hasLength(array?.children)) {
                return collector;
            }

            return R.reduce((result, curr) => {
                const next = result;

                if (!hasLength(curr.children)) {
                    return next;
                }

                next[depth] = next[depth] ? next[depth] : [];

                /**
                 * Each level/depth will be a tuple-like in this array
                 * [ [0, [...firstLvlMenuItems], [1, [...secondLvlMenuItems] ]
                 */
                next[depth] = R.compose(R.uniq, R.concat(next[depth]), R.map(R.prop('value')))(curr.children);

                return getChildren(curr.children, next, depth + 1);
            }, collector)(array);
        };

        const commonHubs = mapCustomMenuLabelValue('referral_no_go_status_outcome_hub');

        const majorSps = mapCustomMenuLabelValue('referral_no_go_status_outcome_sp');

        const referralAvailableMenus = getReferralAvailableMenus(commonHubs, majorSps);

        const staticMenus = getChildren(referralAvailableMenus);

        return Promise.resolve([
            {
                id: 'status',
                value: 'status',
                label: 'Status',
                operators: ['is'],
                type: 'select',
                options: customMenus.referral_status,
            },
            {
                id: 'statusReason',
                value: 'statusReason',
                label: 'Status Reason',
                operators: ['is'],
                type: 'select',
                options: R.compose(
                    R.uniq,
                    R.concat(staticMenus[0]),
                    R.concat(customMenus.referral_status_reasons_complete),
                    R.concat(customMenus.referral_status_reasons_hold)
                )(customMenus.referral_status_reasons_pending),
            },
            {
                id: 'statusSubReason',
                value: 'statusSubReason',
                label: 'Status Sub-Reason',
                operators: ['is'],
                type: 'select',
                options: staticMenus[1],
            },
            {
                id: 'statusAction',
                value: 'statusAction',
                label: 'Status Action',
                operators: ['is'],
                type: 'select',
                options: staticMenus[2],
            },
            {
                id: 'statusOutcome',
                value: 'statusOutcome',
                label: 'Status Outcome',
                operators: ['is'],
                type: 'select',
                options: staticMenus[3],
            },
            {
                id: 'receivedFrom',
                value: 'receivedFrom',
                label: 'Received From',
                operators: ['is'],
                type: 'select',
                options: customMenus.referral_received_from,
            },
            {
                id: 'dispenseStatus',
                value: 'dispenseStatus',
                label: 'Dispense Status',
                operators: ['is'],
                type: 'select',
                options: customMenus.referral_dispense_status,
            },
        ]);
    }

    static getBIFilterableFields() {
        const customMenus = mapCustomMenu({
            benefits_investigation_complete_status_reason: [],
            benefits_investigation_pending_status_reason: [],
            referral_BI_Activity_Reason: [],
            referral_BI_Initial_Site_of_Care: [],
        });

        return Promise.resolve([
            {
                id: 'status',
                value: 'status',
                label: 'Status',
                operators: ['is'],
                type: 'select',
                options: ['Pending', 'Completed', 'Deleted'],
            },
            {
                id: 'statusReason',
                value: 'statusReason',
                label: 'Status Reason',
                operators: ['is'],
                type: 'select',
                options: customMenus.referral_BI_Activity_Reason,
            },
            {
                id: 'patientTotalResponsibility',
                value: 'patientTotalResponsibility',
                label: 'Patient Total Responsibility',
                operators: ['is', 'greater_than', 'less_than', 'greater_than_equals', 'less_than_equals'],
                type: 'number',
                options: [],
            },
            {
                id: 'initialMedicationSiteOfCare',
                value: 'initialMedicationSiteOfCare',
                label: 'Initial Medication Site Of Care',
                operators: ['is'],
                type: 'select',
                options: customMenus.referral_BI_Initial_Site_of_Care,
            },
            {
                id: 'planName',
                value: 'planName',
                label: 'Plan Name',
                operators: ['is'],
                type: 'textbox',
                options: [],
            },
            {
                id: 'benefitCoverageType',
                value: 'benefitCoverageType',
                label: 'Benefit Coverage Type',
                operators: ['is'],
                type: 'select',
                options: [
                    'Pharmacy',
                    'Medical',
                    'Buy and Bill',
                    'Cash',
                    'None',
                    'Financial Assistance',
                    'Foundation Assistance',
                ],
            },
            {
                id: 'payorType',
                value: 'payorType',
                label: 'Payor Type',
                operators: ['is'],
                type: 'select',
                options: [
                    'Auto Insurance',
                    'Charity/Indigent Program',
                    'Government',
                    'HMO/Commercial',
                    'Integrated PBM',
                    'Managed Medicaid',
                    'Medicaid',
                    'Medicare',
                    'Medicare A',
                    'Medicare Advantage (Part C)',
                    'Medicare B',
                    'Medicare D',
                    'None',
                    'Other',
                    'PPO/Commercial',
                    'Prisoners',
                    'Self-Pay',
                    'Tri-Care',
                    'Unknown',
                    'Workers Comp',
                ],
            },
            {
                id: 'bin',
                value: 'bin',
                label: 'BIN',
                operators: ['is'],
                type: 'textbox',
                options: [],
            },
            {
                id: 'pcn',
                value: 'pcn',
                label: 'PCN',
                operators: ['is'],
                type: 'textbox',
                options: [],
            },
            {
                id: 'rxGroupNumber',
                value: 'rxGroupNumber',
                label: 'RX Group Number',
                operators: ['is'],
                type: 'textbox',
                options: [],
            },
        ]);
    }

    static getPriorAuthFilterableFields() {
        return Promise.resolve([
            {
                id: 'status',
                value: 'status',
                label: 'Status',
                operators: ['is'],
                type: 'select',
                options: ['Pending', 'Not Required', 'Denied', 'Approved'],
            },
            {
                id: 'howWasApprovalReceived',
                value: 'howWasApprovalReceived',
                label: 'How was approval received',
                operators: ['is'],
                type: 'select',
                options: ['Pharmacy claim', 'Physician notification', 'Patient notification', 'Payor notification'],
            },
            {
                id: 'denialReason',
                value: 'denialReason',
                label: 'Denial Reason',
                operators: ['is'],
                type: 'select',
                options: [
                    'Drug criteria not met',
                    'Insufficient information submitted',
                    'Required step edits',
                    'Quantity limits',
                    'No coverage policy',
                ],
            },
            {
                id: 'scheduleReAuthorizationUponCompletion',
                value: 'scheduleReAuthorizationUponCompletion',
                label: 'Create a Prior Authorization Renewal activity',
                operators: ['is'],
                type: 'select',
                options: ['Yes', 'No'],
            },
        ]);
    }

    static getPriorAuthRenewalFilterableFields() {
        return Promise.resolve([
            {
                id: 'status',
                value: 'status',
                label: 'Status',
                operators: ['is'],
                type: 'select',
                options: ['Pending', 'Not Required', 'Denied', 'Approved'],
            },
            {
                id: 'howWasApprovalReceived',
                value: 'howWasApprovalReceived',
                label: 'How was approval received',
                operators: ['is'],
                type: 'select',
                options: ['Pharmacy claim', 'Physician notification', 'Patient notification', 'Payor notification'],
            },
            {
                id: 'denialReason',
                value: 'denialReason',
                label: 'Denial Reason',
                operators: ['is'],
                type: 'select',
                options: [
                    'Drug criteria not met',
                    'Insufficient information submitted',
                    'Required step edits',
                    'Quantity limits',
                    'No coverage policy',
                ],
            },
            {
                id: 'scheduleReAuthorizationUponCompletion',
                value: 'scheduleReAuthorizationUponCompletion',
                label: 'Create a Prior Authorization Renewal activity',
                operators: ['is'],
                type: 'select',
                options: ['Yes', 'No'],
            },
        ]);
    }

    static getFinancialAssistanceFilterableFields() {
        return Promise.resolve([
            {
                id: 'status',
                value: 'status',
                label: 'Status',
                operators: ['is'],
                type: 'select',
                options: [
                    'Created',
                    'Pending Submission',
                    'Submitted',
                    'Partially Approved',
                    'Approved',
                    'Denied',
                    'Deleted',
                ],
            },
            {
                id: 'didThePatientFileAnApplication',
                value: 'didThePatientFileAnApplication',
                label: 'Did the patient file an application',
                operators: ['is'],
                type: 'select',
                options: ['Yes', 'No'],
            },
            {
                id: 'denialReason',
                value: 'denialReason',
                label: 'Denial Reason',
                operators: ['is'],
                type: 'select',
                options: ['Insufficient information submitted', 'Eligibility criteria not met'],
            },
            {
                id: 'financialAssistanceSource',
                value: 'financialAssistanceSource',
                label: 'Financial Assistance Source',
                operators: ['is'],
                type: 'select',
                options: [
                    'Clinical Trial  Programs (HIV, HEP-C, Oncology)',
                    'PAN Foundation (Patient Access Network)',
                    'Johnson & Johnson Foundation – Copay Assistance',
                    'Novartis Patient Assistance Foundation - Assistance',
                    '(PAF) Patient Advocate Foundation – Copay Assistance',
                    'Other',
                ],
            },
            {
                id: 'isItCoveringPartAllOfMedicationCost',
                value: 'isItCoveringPartAllOfMedicationCost',
                label: 'Is it covering part/all of medication cost',
                operators: ['is'],
                type: 'select',
                options: ['No', 'Yes'],
            },
            {
                id: 'isReEnrollmentToTheProgramRequired',
                value: 'isReEnrollmentToTheProgramRequired',
                label: 'Is re-enrollment to the program required',
                operators: ['is'],
                type: 'select',
                options: ['No', 'Yes'],
            },
            {
                id: 'scheduleAFollowUpActivityOnCompletion',
                value: 'scheduleAFollowUpActivityOnCompletion',
                label: 'Create a Financial Assistance Renewal activity',
                operators: ['is'],
                type: 'select',
                options: ['Yes', 'No'],
            },
        ]);
    }

    static getFinancialAssistanceRenewalFilterableFields() {
        return Promise.resolve([
            {
                id: 'status',
                value: 'status',
                label: 'Status',
                operators: ['is'],
                type: 'select',
                options: [
                    'Created',
                    'Pending Submission',
                    'Submitted',
                    'Partially Approved',
                    'Approved',
                    'Denied',
                    'Deleted',
                ],
            },
            {
                id: 'didThePatientFileAnApplication',
                value: 'didThePatientFileAnApplication',
                label: 'Did the patient file an application',
                operators: ['is'],
                type: 'select',
                options: ['Yes', 'No'],
            },
            {
                id: 'denialReason',
                value: 'denialReason',
                label: 'Denial Reason',
                operators: ['is'],
                type: 'select',
                options: ['Insufficient information submitted', 'Eligibility criteria not met'],
            },
            {
                id: 'financialAssistanceSource',
                value: 'financialAssistanceSource',
                label: 'Financial Assistance Source',
                operators: ['is'],
                type: 'select',
                options: [
                    'Clinical Trial  Programs (HIV, HEP-C, Oncology)',
                    'PAN Foundation (Patient Access Network)',
                    'Johnson & Johnson Foundation – Copay Assistance',
                    'Novartis Patient Assistance Foundation - Assistance',
                    '(PAF) Patient Advocate Foundation – Copay Assistance',
                    'Other',
                ],
            },
            {
                id: 'isItCoveringPartAllOfMedicationCost',
                value: 'isItCoveringPartAllOfMedicationCost',
                label: 'Is it covering part/all of medication cost',
                operators: ['is'],
                type: 'select',
                options: ['No', 'Yes'],
            },
            {
                id: 'isReEnrollmentToTheProgramRequired',
                value: 'isReEnrollmentToTheProgramRequired',
                label: 'Is re-enrollment to the program required',
                operators: ['is'],
                type: 'select',
                options: ['No', 'Yes'],
            },
            {
                id: 'scheduleAFollowUpActivityOnCompletion',
                value: 'scheduleAFollowUpActivityOnCompletion',
                label: 'Create a Financial Assistance Renewal activity',
                operators: ['is'],
                type: 'select',
                options: ['Yes', 'No'],
            },
        ]);
    }

    static getAppealFilterableFields() {
        return Promise.resolve([
            {
                id: 'status',
                value: 'status',
                label: 'Status',
                operators: ['is'],
                type: 'select',
                options: ['Completed', 'Created', 'Deleted', 'Overturned', 'Pending Submission', 'Submitted', 'Upheld'],
            },
            {
                id: 'appealDocuments',
                value: 'appealDocuments',
                label: 'Selected Documents for appeals submission',
                operators: ['contains'],
                type: 'select',
                options: [
                    'Denial Letter',
                    'MD Notes',
                    'Required clinical information/labs',
                    'Appeals Letter with MD signature',
                ],
            },
            {
                id: 'howWasApprovalReceived',
                value: 'howWasApprovalReceived',
                label: 'How was approval received',
                operators: ['is'],
                type: 'select',
                options: ['Pharmacy claim', 'Physician notification', 'Patient notification', 'Payor notification'],
            },
            {
                id: 'scheduleReAuthorizationUponCompletion',
                value: 'scheduleReAuthorizationUponCompletion',
                label: 'Create a Prior Authorization Renewal activity',
                operators: ['is'],
                type: 'select',
                options: ['Yes', 'No'],
            },
        ]);
    }

    static getReferralActivityStatusChangeFilterOptions(referralActivity) {
        switch (referralActivity) {
            case 0: {
                // benefits investigation
                return ProtocolService.getBIFilterableFields();
            }
            case 1: {
                // prior authorization
                return ProtocolService.getPriorAuthFilterableFields();
            }
            case 2: {
                // financial assistance
                return ProtocolService.getFinancialAssistanceFilterableFields();
            }
            case 3: {
                // appeal
                return ProtocolService.getAppealFilterableFields();
            }
            case 4: {
                // appeal
                return ProtocolService.getPriorAuthRenewalFilterableFields();
            }
            case 5: {
                // appeal
                return ProtocolService.getFinancialAssistanceRenewalFilterableFields();
            }
            default:
                return Promise.resolve([]);
        }
    }

    /**
     * Create the filters for a given Profile trigger
     * @param {string} protocolId - The ID of the protocol
     * @param {string} triggerId - The Id of the trigger
     * @param {object[]} filters - An array of filters
     */
    static createProfileFilters(protocolId, triggerId, filters) {
        return axios.post(`/protocol/${protocolId}/trigger/${triggerId}/profileFilter`, filters);
    }

    /**
     * Create the filters for a given assessment trigger
     * @param {string} protocolId - The ID of the protocol
     * @param {string} triggerId - The Id of the trigger
     * @param {object[]} filters - An array of filters
     */
    static createAssessmentFilters(protocolId, triggerId, filters) {
        return axios.post(`/protocol/${protocolId}/trigger/${triggerId}/assessmentFilter`, filters);
    }
}

export default ProtocolService;
