import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { get, set } from 'lodash';
import { prop } from 'ramda';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

import { getActivityQuestionsById, getAssessmentQuestionsById, saveResponse } from 'App/services/AMSService';
import { CheckboxMultiField } from 'Lib/form/CheckboxMultiField';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { NumberField } from 'Lib/form/NumberField';
import { RadioField } from 'Lib/form/RadioField';
import SelectField from 'Lib/form/SelectField';
import { TextField } from 'Lib/form/TextField';
import { TextareaField } from 'Lib/form/TextareaField';
import { patientPropType } from 'Lib/types';
import SafeHtmlOnly from '../../SafeHtmlOnly/SafeHtmlOnly';
import { rapid3FunctionalScoreQuestionIds, rapid3TotalScoreQuestionIds } from '../constants';
import { activityPropType, questionPropType } from '../types';
import AssessmentQuestionScoring from './AssessmentQuestionScoring';
import AssessmentQuestionText from './AssessmentQuestionText';
import AssessmentRapid3ScoreQuestion from './AssessmentRapid3ScoreQuestion';
import ConfirmDeleteChildrenModal from './ConfirmDeleteChildrenModal';
import DisplayCMSContent from './DisplayCMSContent';

const AssessmentQuestionStyle = styled.div`
    margin-bottom: 25px;
`;

const StyledTooltip = styled(Tooltip)`
    .tooltip-inner {
        background-color: var(--brandPrimary);
    }

    &.tooltip.right .tooltip-arrow {
        border-right-color: var(--brandPrimary);
    }
`;

const ChildQuestionStyle = styled.div`
    margin-left: 30px;
`;

const AssessmentQuestion = ({ question, activity, patient }) => {
    const { values, setValues, setSubmitting } = useFormikContext();
    const children = values[question.questionId]?.children;
    const [showDeleteChildrenModal, setShowDeleteChildrenModal] = useState(false);
    const [pendingValue, setPendingValue] = useState(null);

    const getAllChildIds = (childrenList) => {
        if (!childrenList) return [];
        return childrenList.reduce((idsAgg, currQuestion) => {
            if (currQuestion.children) {
                idsAgg.push(...getAllChildIds(currQuestion.children));
            }
            idsAgg.push(currQuestion.questionId);
            return idsAgg;
        }, []);
    };

    const getNumberOfAnswers = () => {
        return getAllChildIds(children).reduce((agg, currId) => {
            const value = get(values, `${currId}.response`);
            if (value) {
                return agg + (Array.isArray(value) ? value.length : 1);
            }
            return agg;
        }, 0);
    };

    const getQuestionsByIds = async (ids) => {
        if (!ids.length || (ids.length === 1 && ids[0] === undefined)) return [];
        if (activity.id) return getActivityQuestionsById(activity.id, ids);
        return getAssessmentQuestionsById(activity.assessment.id, ids);
    };

    const commitSaveResponse = async ({
        value,
        activityDateQuestion,
        activityDateValue,
        needByDateQuestion,
        needByDateValue,
    }) => {
        if (!activity.id) {
            return;
        }

        setSubmitting(true);
        await saveResponse(activity.id, question.questionId, value?.id || value || '');
        if (activityDateQuestion) {
            await saveResponse(activity.id, activityDateQuestion.questionId, activityDateValue);
        }
        if (needByDateQuestion) {
            await saveResponse(activity.id, needByDateQuestion.questionId, needByDateValue);
        }
        setSubmitting(false);
    };

    const commitChange = async (value) => {
        const childIds = getAllChildIds(children);
        const valueChanges = childIds.reduce((agg, currId) => set(agg, `${currId}.response`, undefined), values);
        set(valueChanges, `${question.questionId}.response`, value);

        // Update values for Activity Date & Need By Date
        const valuesList = Object.values(values);
        const activityDateQuestion = valuesList.find(
            (questionValue) => questionValue.name === 'activity_date' && !questionValue.response
        );
        const activityDateValue = dayjs().toDate();
        if (activityDateQuestion) {
            set(valueChanges, `${activityDateQuestion.questionId}.response`, activityDateValue);
        }
        const needByDateQuestion = valuesList.find(
            (questionValue) => questionValue.name === 'need_by_date' && !questionValue.response
        );
        const needByDateValue = dayjs().add(7, 'days').toDate();
        if (needByDateQuestion) {
            set(valueChanges, `${needByDateQuestion.questionId}.response`, needByDateValue);
        }

        setValues(valueChanges);

        commitSaveResponse({
            value,
            activityDateQuestion,
            activityDateValue,
            needByDateQuestion,
            needByDateValue,
        });

        await setNewChildrenValues(value);
    };

    async function setNewChildrenValues(value) {
        const answersArray = Object.values(question.answers);
        if (!answersArray.length) return;
        const fullAnswerValues = Array.isArray(value)
            ? answersArray.filter((a) => value.includes(a.id))
            : [question.answers[value?.id ?? value]];
        const allChildIds = fullAnswerValues.map((val) => val?.children).flat();
        const newChildren = await getQuestionsByIds(allChildIds);

        const newChildrenList = Object.values(newChildren);
        const valueChanges = newChildrenList.reduce((agg, child) => set(agg, `${child.questionId}`, child), {
            ...values,
        });
        set(valueChanges, `${question.questionId}.children`, newChildrenList);
        setValues(valueChanges);
    }

    const handleChange = async (event) => {
        const value = event?.target?.value ?? event;

        const numberOfAnswers = getNumberOfAnswers();
        if (numberOfAnswers > 0) {
            setPendingValue(value);
            setShowDeleteChildrenModal(true);
        } else {
            commitChange(value);
        }
    };

    const handleCloseDeleteChildrenModal = (confirm) => {
        if (confirm) {
            commitChange(pendingValue);
        }
        setShowDeleteChildrenModal(false);
    };

    const renderQuestionBaseField = () => {
        const isTotalScoreQuestion = rapid3TotalScoreQuestionIds.includes(question.questionId);
        const isFunctionalScoreQuestion = rapid3FunctionalScoreQuestionIds.includes(question.questionId);
        if (isTotalScoreQuestion || isFunctionalScoreQuestion) {
            return (
                <AssessmentRapid3ScoreQuestion
                    activity={activity}
                    question={question}
                    isFunctionalScore={isFunctionalScoreQuestion}
                />
            );
        }

        const { fullyScriptedMode } = values;
        const mainLabelText = (
            <AssessmentQuestionText question={question} fullyScriptedMode={fullyScriptedMode} patient={patient} />
        );
        const label = (
            <>
                {mainLabelText}{' '}
                {question.attributes.extra_info && (
                    <OverlayTrigger
                        overlay={
                            <StyledTooltip id={`question-tooltip-${question.questionId}`}>
                                <SafeHtmlOnly>{question.attributes.extra_info}</SafeHtmlOnly>
                            </StyledTooltip>
                        }
                    >
                        <FaInfoCircle className="text-primary" />
                    </OverlayTrigger>
                )}
                {question.attributes.risk_config && (
                    <AssessmentQuestionScoring question={question} activity={activity} />
                )}
            </>
        );

        const isActivityComplete = activity.status === 1;
        const isActivityDeleted = activity.status === 2;

        const commonProps = {
            fieldName: `${question.questionId}.response`,
            label,
            onChange: handleChange,
            disabled: isActivityComplete || isActivityDeleted,
            required: question.attributes.required,
        };
        switch (question.type) {
            case 'message':
                return label;
            case 'date':
                return (
                    <DatePickerField
                        {...commonProps}
                        placeholder="YYYY/MM/DD"
                        disabled={
                            commonProps.disabled ||
                            question.name === 'activity_date' ||
                            question.name === 'need_by_date'
                        }
                    />
                );
            case 'select':
                return (
                    <SelectField
                        {...commonProps}
                        options={Object.values(question.answers)}
                        getOptionLabel={prop('option')}
                        getOptionValue={prop('id')}
                        isClearable
                    />
                );
            case 'radio':
                return (
                    <RadioField
                        {...commonProps}
                        options={Object.values(question.answers).map((a) => ({
                            ...a,
                            option: <SafeHtmlOnly>{a.option}</SafeHtmlOnly>,
                        }))}
                    />
                );
            case 'checkbox':
                return (
                    <CheckboxMultiField
                        {...commonProps}
                        options={Object.values(question.answers).map((a) => ({
                            ...a,
                            option: <DisplayCMSContent content={a.option} patient={patient} />,
                        }))}
                    />
                );
            case 'textbox':
                return <TextField {...commonProps} />;
            case 'number':
                return <NumberField {...commonProps} />;
            case 'textarea':
                return <TextareaField {...commonProps} />;
            default:
                return null;
        }
    };

    return (
        <AssessmentQuestionStyle id={question.questionId}>
            {renderQuestionBaseField()}
            {(children || [])
                .sort((a, b) => a.attributes.position - b.attributes.position)
                .map((child) => (
                    <ChildQuestionStyle key={child.questionId}>
                        <AssessmentQuestion question={child} activity={activity} patient={patient} />
                    </ChildQuestionStyle>
                ))}
            {showDeleteChildrenModal && (
                <ConfirmDeleteChildrenModal
                    numberOfAnswers={getNumberOfAnswers()}
                    onClose={handleCloseDeleteChildrenModal}
                />
            )}
        </AssessmentQuestionStyle>
    );
};

AssessmentQuestion.propTypes = {
    question: questionPropType.isRequired,
    activity: activityPropType.isRequired,
    patient: patientPropType.isRequired,
};

export default AssessmentQuestion;
