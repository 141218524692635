import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';

function useFetchPatientTherapies(patientId) {
    return useQuery(['patientTherapies', patientId], () => (!patientId ? null : PESService.getTherapies(patientId)), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchPatientTherapies;
