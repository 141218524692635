import stripAllHtml from '../../../utils/stripAllHtml';

/** @ngInject */
function TextInputController(_, $filter) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeValue = doChangeValue;

    function $onInit() {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        ctrl.model.$render = function () {
            ctrl.name = ctrl.model.$name;
            ctrl.value = ctrl.filter ? $filter(ctrl.filter)(ctrl.model.$viewValue) : ctrl.model.$viewValue;
        };
    }

    function $onChanges(changes) {
        if (changes.errors) {
            ctrl.errors = angular.copy(ctrl.errors);
            _.forEach(ctrl.errors, (error, errorName) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                error.message = error.message || null;
                ctrl.model.$setValidity(errorName, error.isValid);
            });
        }
        if (changes.filter) {
            ctrl.filter = angular.copy(ctrl.filter);
        }
        if (changes.id) {
            ctrl.id = angular.copy(ctrl.id);
        }
        if (changes.ngDisabled) {
            ctrl.ngDisabled = angular.copy(ctrl.ngDisabled);
        }
        if (changes.ngReadonly) {
            ctrl.ngReadonly = angular.copy(ctrl.ngReadonly);
        }
        if (changes.ngRequired) {
            ctrl.ngRequired = angular.copy(ctrl.ngRequired);
            ctrl.requiredMessage = `Please enter a ${ctrl.label.toLowerCase()}`;
        }
        if (changes.ngPattern) {
            ctrl.ngPattern = angular.copy(ctrl.ngPattern);
            ctrl.patternMessage = `The ${ctrl.label.toLowerCase()} is not in the correct format`;
        }
        if (changes.ngMaxlength) {
            ctrl.ngMaxlength = angular.copy(ctrl.ngMaxlength);
            ctrl.maxlengthMessage = `The ${ctrl.label.toLowerCase()} cannot be more than ${
                ctrl.ngMaxlength
            } characters`;
        }
        if (changes.ngMinlength) {
            ctrl.ngMinlength = angular.copy(ctrl.ngMinlength);
            if (
                ctrl.id === 'mobile-phone' ||
                ctrl.id === 'home-phone' ||
                ctrl.id === 'work-phone' ||
                ctrl.id === 'ec-telephone' ||
                ctrl.id === 'ec-mobile-phone'
            ) {
                ctrl.minlengthMessage = `The ${ctrl.label.toLowerCase()} must be ${ctrl.ngMinlength} digits`;
            } else {
                ctrl.minlengthMessage = `The ${ctrl.label.toLowerCase()} must be at least ${
                    ctrl.ngMinlength
                } characters`;
            }
        }
    }

    function doChangeValue(value) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        value = stripAllHtml(value);
        ctrl.model.$setViewValue(value);
        ctrl.onChangeValue({
            value,
        });
    }
}

export default TextInputController;
