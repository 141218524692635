import { getData, stripAllHtml } from 'App/utils';
import * as R from 'ramda';

import PESService from './PESService';
import convertKeysToSnakeCase from '../utils/keyConversion/convertKeysToSnakeCase';

/** @ngInject */
function MedicationsService($http, $httpParamSerializerJQLike, $q, _, urlBuilderService) {
    const cachedMedications = {};

    return {
        addNote,
        addRxNote,
        get,
        getHistory,
        getRxHistory,
        getPatientMedications,
        getRxNotes,
        getNotes,
    };

    function _format(data) {
        _.forEach(data, (value, key) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            data[_.snakeCase(key)] = value;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete data[key];
        });
        return data;
    }

    function addNote(patient, medication, statusId, note) {
        return $http({
            method: 'post',
            url: urlBuilderService.build('addNote', {
                patient,
                medication,
                notes: String(statusId),
            }),
            data: {
                note: stripAllHtml(note),
            },
        }).then((res) => {
            return res.data;
        });
    }

    function addRxNote(patient, medication, rxId, note) {
        return $http({
            method: 'post',
            url: urlBuilderService.build('rxNotes', {
                patient,
                medication,
                rxId,
                note: '',
            }),
            data: $httpParamSerializerJQLike({
                note: stripAllHtml(note),
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }).then((res) => {
            return res.data;
        });
    }

    function get(categoryId) {
        if (cachedMedications[categoryId]) {
            return $q.when(cachedMedications[categoryId]);
        }

        const url = urlBuilderService.build('categoryMedications', {
            therapy: categoryId,
            medications: '',
        });

        return (
            $http
                .get(url)
                .then((res) => {
                    _.forEach(res.data, (value) => {
                        _format(value);
                    });

                    cachedMedications[categoryId] = res.data;

                    return res.data;
                })
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((err) => {
                    if (err.status === 404) {
                        return [];
                    }
                })
        );
    }

    function getHistory(patient, medication) {
        return (
            $http({
                method: 'get',
                url: urlBuilderService.build('medHistory', {
                    patient,
                    medication: String(medication),
                    statushistory: '',
                }),
            })
                .then((res) => {
                    return res.data;
                })
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((err) => {
                    if (err.status === 404) {
                        return [];
                    }
                })
        );
    }

    function getRxHistory(patient, medication) {
        return (
            $http({
                method: 'get',
                url: urlBuilderService.build('medHistory', {
                    patient,
                    medication: String(medication),
                    history: '',
                }),
            })
                .then((res) => {
                    return res.data;
                })
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((err) => {
                    if (err.status === 404) {
                        return [];
                    }
                })
        );
    }

    function getPatientMedications(patient) {
        return PESService.getPatientMedications(patient.patientId).then((res) => {
            const updatedMedications = convertKeysToSnakeCase(res);
            _.forEach(updatedMedications, (medication) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                medication.collection = medication.therapy;
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                delete medication.therapy;
            });
            return updatedMedications;
        });
    }

    function sanitizeNotes(medications) {
        return R.map(R.over(R.lensProp('note'), stripAllHtml), medications);
    }

    function getRxNotes(patient, medication, rxId) {
        return $http({
            method: 'get',
            url: urlBuilderService.build('rxNotes', {
                patient,
                medication,
                rxId,
                notes: '',
            }),
        })
            .then(getData)
            .then(sanitizeNotes);
    }

    function getNotes(patient, medicationId, statusId) {
        return $http({
            method: 'get',
            url: urlBuilderService.build('getNotes', {
                patient,
                medication: medicationId,
                notes: String(statusId),
            }),
        })
            .then(getData)
            .then(sanitizeNotes);
    }
}

export default MedicationsService;
