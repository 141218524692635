import * as R from 'ramda';
import pathIsFalsy from '../pathIsFalsy';
import pathIsTruthy from '../pathisTruthy';
import isFeatureFlagEnabled from 'App/utils/isFeatureFlagEnabled';

const hasClinicalAdmin = pathIsTruthy(['company_permissions', 'TherigyAssessmentAdmin']);

const isCloneAssessmentEnabled = isFeatureFlagEnabled('ENABLE_CLONE_ASSESSMENT');
const isEditPatientLockForUpdateEnabled = isFeatureFlagEnabled('ENABLE_EDIT_PATIENT_LOCK_FOR_UPDATE');
const isPublishProtocolEnabled = isFeatureFlagEnabled('ENABLE_PUBLISH_PROTOCOL');
const isMedManagementEnabled = isFeatureFlagEnabled('ENABLE_MED_MANAGEMENT');
const isDeleteTherigyProtocolEnabled = isFeatureFlagEnabled('ENABLE_DELETE_THERIGY_PROTOCOL');
const isInsurancePlansEnabled = isFeatureFlagEnabled('ENABLE_INSURANCE_PLANS');
const isRxFillNumbersEnabled = isFeatureFlagEnabled('ENABLE_RX_FILL_NUMBERS');
const isReactAssessmentsEnabled = isFeatureFlagEnabled('ENABLE_REACT_ASSESSMENTS');
const isPatientProfileEnabled = isFeatureFlagEnabled('ENABLE_REACT_PATIENT_PROFILE');
const isReferralDetailEnabled = isFeatureFlagEnabled('ENABLE_REACT_REFERRAL_DETAIL');
const isReactPriorAuthorizationEnabled = isFeatureFlagEnabled('ENABLE_REACT_REFERRAL_PRIOR_AUTHORIZATION');
const isWorkQueueServerlessEndpoint = isFeatureFlagEnabled('ENABLE_WORKQUEUE_SERVERLESS_ENDPOINT');
const isFeatureReferralNoGoEnabled = isFeatureFlagEnabled('FEATURE_REFERRAL_NO_GO');
const isReferralProtocolMgrEnabled = isFeatureFlagEnabled('FEATURE_REFERRAL_PROTOCOL_MANAGER');
const isReferralActivityTriggers = isFeatureFlagEnabled('FEATURE_REFERRAL_ACTIVITY_TRIGGERS');
const isWorkQueueEnabled = isFeatureFlagEnabled('ENABLE_REACT_WORK_QUEUE');
const isSsoConfigurationEnabled = isFeatureFlagEnabled('ENABLE_SSO_COMPANY_CONFIGURATION');
const isReactAddEditPatientEnabled = isFeatureFlagEnabled('ENABLE_REACT_ADD_EDIT_PATIENT');
const isTreatmentHistoryEnabled = isFeatureFlagEnabled('ENABLE_TREATMENT_HISTORY');
const isNewRenewalActivityTypesEnabled = isFeatureFlagEnabled('FEATURE_NEW_RENEWAL_ACTIVITY_TYPES');

/**
 * The function mapping for each permission type.
 */
const permissionFns = Object.freeze({
    ADD_PATIENT: R.allPass([
        pathIsTruthy(['active_branch', 'Privileges', 'AddPatient']),
        pathIsFalsy(['company_permissions', 'DisableAddPatient']),
        pathIsFalsy(['company_permissions', 'Pdx']),
    ]),

    ADVANCED_REFERRAL: pathIsTruthy(['company_permissions', 'AdvancedReferral']),

    ADVANCED_REPORTS: pathIsTruthy(['company_permissions', 'AdvanceReport']),

    ALERT_CENTER: pathIsTruthy(['company_permissions', 'AdvancedReferral']),

    ALERT_MANAGER: R.allPass([
        pathIsTruthy(['company_permissions', 'AdvancedReferral']),
        pathIsTruthy(['active_branch', 'Privileges', 'CompanyAdmin']),
    ]),

    ASSESSMENT_MANAGER: pathIsTruthy(['company_permissions', 'AssessmentManager']),

    ASSESSMENT_PUBLISHER: pathIsTruthy(['company_permissions', 'TherigyAssessmentAdmin']),

    ASSIGNMENT_MANAGER: R.anyPass([
        pathIsTruthy(['active_branch', 'Privileges', 'BranchAdmin']),
        pathIsTruthy(['active_branch', 'Privileges', 'CompanyAdmin']),
        pathIsTruthy(['active_branch', 'Privileges', 'WorkQueueAssignment']),
    ]),

    CLINICAL_ADMIN: pathIsTruthy(['company_permissions', 'TherigyAssessmentAdmin']),

    CLONE_ASSESSMENT: R.allPass([
        isCloneAssessmentEnabled,
        pathIsTruthy(['company_permissions', 'TherigyAssessmentAdmin']),
    ]),

    CONTENT_LIBRARY: pathIsTruthy(['company_permissions', 'TherigyAssessmentAdmin']),

    CONTENT_PUBLISHER: pathIsTruthy(['company_permissions', 'TherigyAssessmentAdmin']),

    DELETE_THERIGY_PROTOCOL: R.allPass([hasClinicalAdmin, isDeleteTherigyProtocolEnabled]),

    EDIT_PROTOCOLS: pathIsTruthy(['user_permissions', 'EditProtocols']),

    ENABLE_EDIT_PATIENT_LOCK_FOR_UPDATE: isEditPatientLockForUpdateEnabled,

    ENABLE_INSURANCE_PLANS: isInsurancePlansEnabled,

    ENABLE_RX_FILL_NUMBERS: isRxFillNumbersEnabled,

    ENABLE_REACT_PATIENT_PROFILE: isPatientProfileEnabled,

    ENABLE_REACT_REFERRAL_DETAIL: isReferralDetailEnabled,

    ENABLE_REACT_REFERRAL_PRIOR_AUTHORIZATION: isReactPriorAuthorizationEnabled,

    ENABLE_REACT_WORK_QUEUE: isWorkQueueEnabled,

    ENABLE_REACT_ASSESSMENTS: isReactAssessmentsEnabled,

    ENABLE_SSO_COMPANY_CONFIGURATION: R.allPass([
        isSsoConfigurationEnabled,
        pathIsTruthy(['active_branch', 'Privileges', 'CompanyAdmin']),
        pathIsTruthy(['company_permissions', 'SsoEnabled']),
    ]),

    ENABLE_TREATMENT_HISTORY: isTreatmentHistoryEnabled,

    ENABLE_WORKQUEUE_SERVERLESS_ENDPOINT: isWorkQueueServerlessEndpoint,

    ENABLE_REACT_ADD_EDIT_PATIENT: isReactAddEditPatientEnabled,

    FEATURE_REFERRAL_NO_GO: isFeatureReferralNoGoEnabled,

    FEATURE_REFERRAL_PROTOCOL_MANAGER: R.allPass([
        isReferralProtocolMgrEnabled,
        pathIsTruthy(['company_permissions', 'NewProtocolManager']),
        pathIsTruthy(['company_permissions', 'ClientProtocolBuilder']),
        pathIsTruthy(['company_permissions', 'AdvancedReferral']),
    ]),

    FEATURE_REFERRAL_ACTIVITY_TRIGGERS: isReferralActivityTriggers,

    FIND_PATIENT: pathIsFalsy(['company_permissions', 'Pdx']),

    MEDICATION_MANAGEMENT: R.allPass([
        isMedManagementEnabled,
        pathIsTruthy(['active_branch', 'Privileges', 'CompanyAdmin']),
    ]),

    PASSWORD_MANAGER: pathIsTruthy(['active_branch', 'Privileges', 'CompanyAdmin']),

    PATIENT_PREDICTIVE_MODEL: pathIsTruthy(['company_permissions', 'PatientPredictiveModel']),

    PDX_FIND_PATIENT: pathIsTruthy(['company_permissions', 'Pdx']),

    PROTOCOL_MANAGER: pathIsTruthy(['company_permissions', 'ClientProtocolBuilder']),

    PUBLISH_PROTOCOL: R.allPass([hasClinicalAdmin, isPublishProtocolEnabled]),

    QUESTION_GROUPS: R.allPass([hasClinicalAdmin]),

    QUEUE_MANAGER: pathIsTruthy(['active_branch', 'Privileges', 'CompanyAdmin']),

    REFERRAL_MANAGER: pathIsTruthy(['company_permissions', 'ReferralManager']),

    SHOW_RETIRED_ASSESSMENTS: pathIsTruthy(['company_permissions', 'ShowRetiredAssessments']),

    THERAPEUTIC_CATEGORIES: R.allPass([hasClinicalAdmin]),

    THERIGY_INSIGHTS: R.allPass([
        pathIsTruthy(['company_permissions', 'ReportEngine']),
        R.anyPass([
            pathIsTruthy(['active_branch', 'Privileges', 'ReportViewer']),
            pathIsTruthy(['active_branch', 'Privileges', 'ReportBuilder']),
        ]),
    ]),

    TMS_ENABLED: pathIsTruthy(['company_permissions', 'TmsEndpoint']),

    UPLOAD_PATIENTS: R.allPass([
        pathIsTruthy(['active_branch', 'Privileges', 'AddPatient']),
        pathIsFalsy(['company_permissions', 'DisableAddPatient']),
        pathIsFalsy(['company_permissions', 'Pdx']),
    ]),

    UPLOAD_PHYSICIANS: R.T,

    USER_MANAGER: R.anyPass([
        pathIsTruthy(['active_branch', 'Privileges', 'CompanyAdmin']),
        pathIsTruthy(['active_branch', 'Privileges', 'BranchAdmin']),
    ]),

    WORK_QUEUE: R.T,

    PATIENT_MESSAGING: R.allPass([
        pathIsTruthy(['active_branch', 'Privileges', 'CompanyAdmin']),
        R.anyPass([
            pathIsTruthy(['company_permissions', 'SmsMassTexting']),
            pathIsTruthy(['company_permissions', 'SmsActivityCustomSchedule']),
        ]),
    ]),

    MASS_TEXTING: pathIsTruthy(['company_permissions', 'SmsMassTexting']),

    ACTIVITY_TEXT_SCHEDULE: pathIsTruthy(['company_permissions', 'SmsActivityCustomSchedule']),

    FEATURE_NEW_RENEWAL_ACTIVITY_TYPES: isNewRenewalActivityTypesEnabled,
});

/**
 * An "enum" of permission types.
 * ** Please add to this definition any time you add a new type to the collection above.**
 * @typedef PERMISSION_TYPES
 * @type {object}
 * @property {stiing} ENABLE_EDIT_PATIENT_LOCK_FOR_UPDATE
 * @property {string} ACTIVITY_TEXT_SCHEDULE
 * @property {string} ADD_PATIENT
 * @property {string} ADVANCED_REFERRAL
 * @property {string} ADVANCED_REPORTS
 * @property {string} ALERT_CENTER
 * @property {string} ALERT_MANAGER
 * @property {string} ASSESSMENT_MANAGER
 * @property {string} ASSESSMENT_PUBLISHER
 * @property {string} ASSIGNMENT_MANAGER
 * @property {string} CLINICAL_ADMIN
 * @property {string} CLONE_ASSESSMENT
 * @property {string} CONTENT_LIBRARY
 * @property {string} CONTENT_PUBLISHER
 * @property {string} DELETE_THERIGY_PROTOCOL
 * @property {string} EDIT_PROTOCOLS
 * @property {string} ENABLE_INSURANCE_PLANS
 * @property {string} ENABLE_REACT_ADD_EDIT_PATIENT
 * @property {string} ENABLE_REACT_ASSESSMENTS
 * @property {string} ENABLE_REACT_PATIENT_PROFILE
 * @property {string} ENABLE_REACT_REFERRAL_DETAIL
 * @property {string} ENABLE_REACT_REFERRAL_PRIOR_AUTHORIZATION
 * @property {string} ENABLE_REACT_WORK_QUEUE
 * @property {string} ENABLE_RX_FILL_NUMBERS
 * @property {string} ENABLE_SSO_COMPANY_CONFIGURATION
 * @property {string} ENABLE_TREATMENT_HISTORY
 * @property {string} ENABLE_WORKQUEUE_SERVERLESS_ENDPOINT
 * @property {string} FEATURE_NEW_RENEWAL_ACTIVITY_TYPES
 * @property {string} FEATURE_REFERRAL_ACTIVITY_TRIGGERS
 * @property {string} FEATURE_REFERRAL_NO_GO
 * @property {string} FEATURE_REFERRAL_PROTOCOL_MANAGER
 * @property {string} FIND_PATIENT
 * @property {string} MASS_TEXTING
 * @property {string} MEDICATION_MANAGEMENT
 * @property {string} PASSWORD_MANAGER
 * @property {string} PATIENT_MESSAGING
 * @property {string} PATIENT_PREDICTIVE_MODEL
 * @property {string} PDX_FIND_PATIENT
 * @property {string} PROTOCOL_MANAGER
 * @property {string} PUBLISH_PROTOCOL
 * @property {string} QUESTION_GROUPS
 * @property {string} QUEUE_MANAGER
 * @property {string} REFERRAL_MANAGER
 * @property {string} SHOW_RETIRED_ASSESSMENTS
 * @property {string} THERAPEUTIC_CATEGORIES
 * @property {string} THERIGY_INSIGHTS
 * @property {string} TMS_ENABLED
 * @property {string} UPLOAD_PATIENTS
 * @property {string} UPLOAD_PHYSICIANS
 * @property {string} USER_MANAGER
 * @property {string} WORK_QUEUE
 */

/**
 * An "enum" of permission types.
 * @type PERMISSION_TYPES
 */
const PERMISSION_TYPES = R.mapObjIndexed((value, key) => key, permissionFns);

/**
 * Determines if the user has a given permission type.
 * Curried
 * @param {string} permissionType - The permission type
 * @param {object} user - The user object which contains the permission properties. The token.
 */
const isPermitted = R.curry((permissionType, user) => {
    const fn = permissionFns[permissionType];

    if (typeof fn === 'function') {
        return fn(user);
    }
    return false;
});
export { PERMISSION_TYPES };

export default isPermitted;
