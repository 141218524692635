/** @ngInject */
function AssessmentPublisherController($q, _, toastr, assessmentService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.addAssessments = addAssessments;
    ctrl.loadAssessments = loadAssessments;
    ctrl.publish = publish;
    ctrl.removeAssessments = removeAssessments;
    ctrl.selectTherapy = selectTherapy;

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            ctrl.clinicalAdmin = ctrl.user.company_permissions.TherigyAssessmentAdmin === true;
            if (ctrl.clinicalAdmin) {
                ctrl.loadAssessments();
            }
        }
    }

    function $onInit() {
        ctrl.assessments = {};
        ctrl.loading = true;
        ctrl.selectedToAdd = [];
        ctrl.selectedToRemove = [];
        ctrl.therapyAssessments = [];
    }

    function addAssessments() {
        _.each(ctrl.selectedToAdd, (assessmentId) => {
            ctrl.readyToPublish.push(_.find(ctrl.therapyAssessments, { id: assessmentId }));
            _.remove(ctrl.therapyAssessments, { id: assessmentId });
        });
    }

    function loadAssessments() {
        ctrl.readyToPublish = [];
        ctrl.therapies = [];
        ctrl.selectedTherapy = null;
        return assessmentService
            .getModifiedAssessments()
            .then((res) => {
                _.each(res, (assessment) => {
                    if (
                        _.findIndex(ctrl.therapies, {
                            id: assessment.group_id,
                        }) === -1
                    ) {
                        ctrl.therapies.push({
                            id: assessment.group_id,
                            name: assessment.therapy,
                        });
                    }
                    if (!ctrl.assessments[assessment.group_id]) {
                        ctrl.assessments[assessment.group_id] = [];
                    }
                    if (
                        _.findIndex(ctrl.assessments[assessment.group_id], {
                            id: assessment.id,
                        }) === -1
                    ) {
                        ctrl.assessments[assessment.group_id].push({
                            id: assessment.id,
                            name: assessment.name,
                        });
                    }
                });
            })
            .catch((err) => {
                toastr.error('Unable to load modified assessments.');
                return err;
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }

    function publish() {
        if (ctrl.readyToPublish.length === 0) {
            return;
        }
        ctrl.loading = true;
        const promises = {};
        _.each(ctrl.readyToPublish, (assessment) => {
            promises[assessment.id] = assessmentService.publishAssessment(assessment.id);
        });
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return $q
            .all(promises)
            .then((res) => {
                let successCount = 0;
                _.each(res, (response, assessmentId) => {
                    // Check if all assessments published.
                    if (_.findIndex(_.values(response), false) !== -1) {
                        const assessment = _.find(ctrl.readyToPublish, {
                            id: assessmentId,
                        });
                        toastr.error(`${assessment.name} failed to publish. Please contact support`);
                    } else {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-plusplus
                        successCount++;
                    }
                });
                if (successCount > 0) {
                    toastr.success(`${successCount} assessments published successfully.`);
                }
                ctrl.loadAssessments();
            })
            .catch((err) => {
                toastr.error(err.data);
                ctrl.loadAssessments();
            });
    }

    function removeAssessments() {
        _.each(ctrl.selectedToRemove, (assessmentId) => {
            ctrl.therapyAssessments.push(_.find(ctrl.readyToPublish, { id: assessmentId }));
            _.remove(ctrl.readyToPublish, { id: assessmentId });
        });
    }

    function selectTherapy() {
        ctrl.therapyAssessments = angular.copy(ctrl.assessments[ctrl.selectedTherapy]);
        ctrl.readyToPublish = [];
    }
}

export default AssessmentPublisherController;
