import React from 'react';
import PatientProfile from './PatientProfile';
import UserBranchSwitcher from './UserBranchSwitcher';
import PatientPdxSync from './PatientPdxSync';

const PatientProfileWrapper = () => (
    <UserBranchSwitcher>
        <PatientPdxSync>
            <PatientProfile />
        </PatientPdxSync>
    </UserBranchSwitcher>
);

export default PatientProfileWrapper;
