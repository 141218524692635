import React from 'react';
import { UserChecker, UserContextProvider } from '../../contexts/UserContext';
import AssessmentManagerRouter from './AssessmentManagerRouter';

//Can get rid of this once we get all the assement-manager route in React.
const AssessmentManagerWrapper = () => {
    return (
        <UserContextProvider>
            <UserChecker>
                <AssessmentManagerRouter />
            </UserChecker>
        </UserContextProvider>
    );
};

export default AssessmentManagerWrapper;
