import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Main from '@/app/components/styles/Main/Main';
import Page from '@/app/components/Page';
import useIsPermitted from '@/app/utils/hooks/useIsPermitted';
import { catchPhrase } from '@/app/components/config/config';
import { PERMISSION_TYPES } from '@/app/utils/isPermitted';
import ActivityTextSchedule from './ActivityTextSchedule';
import MassTexting from './MassTexting';

const propTypes = {};
const defaultProps = {};

function PatientMessaging() {
    // flag to if the user has permission to access patient messaging activity text schedule
    const hasEnabledActivityTextSchedule = useIsPermitted(PERMISSION_TYPES.ACTIVITY_TEXT_SCHEDULE);
    // flag to if the user has permission to access patient messaging activity text schedule
    const hasEnabledMassTexting = useIsPermitted(PERMISSION_TYPES.MASS_TEXTING);

    return (
        <Main>
            <Helmet>
                <title>Patient Messaging - {catchPhrase}</title>
            </Helmet>
            <Page title="Patient Messaging" permissionType={PERMISSION_TYPES.PATIENT_MESSAGING} showPageTitle>
                <Tabs
                    defaultActiveKey={hasEnabledMassTexting ? 'mass-texting' : 'activity_text_schedule'}
                    id="patient-messaging"
                >
                    {hasEnabledMassTexting && (
                        <Tab eventKey="mass-texting" title="Mass Texting">
                            <MassTexting />
                        </Tab>
                    )}
                    {hasEnabledActivityTextSchedule && (
                        <Tab eventKey="activity_text_schedule" title="Activity Text Schedule">
                            <ActivityTextSchedule />
                        </Tab>
                    )}
                </Tabs>
            </Page>
        </Main>
    );
}

PatientMessaging.prototype = propTypes;
PatientMessaging.defaultProps = defaultProps;

export default PatientMessaging;
