/** @ngInject */
function AccessViewConfig(_, $stateProvider) {
    $stateProvider.state({
        name: 'app.accessView',
        url: '/accessView',
        params: {
            resetDefaultBranch: null,
        },
        views: {
            '': 'accessView',
            'pageTitle@^': {
                template: 'My Branches',
            },
        },
    });
}

export default AccessViewConfig;
