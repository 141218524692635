import * as Yup from 'yup';

import { Formik } from 'formik';
import compose from 'ramda/src/compose';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import without from 'ramda/src/without';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { allDiagnosisOption, allMedicationsOption } from './constants';

import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import CompanyService from '../../services/CompanyService';
import PageTitle from '../../components/styles/PageTitle/PageTitle';
import PageWrapper from '../../components/styles/PageWrapper/PageWrapper';
import ProtocolBaseForm from './ProtocolBaseForm';
import ProtocolService from '../../services/ProtocolService';
import env from '../../components/config/config';
import { get } from 'lodash';
import { sortAndPrepend } from '../../utils';
import { useProtocolManagerContext } from './ProtocolManagerContext';

function CreateProtocolPage() {
    const { state } = useProtocolManagerContext();
    const { category } = state.context;
    const [diagnosisCode, setDiagnosisCodes] = useState([]);
    const [isLoadingDiagCodes, setIsLoadingDiagCodes] = useState(false);

    const [medications, setMedications] = useState([]);
    const [isLoadingMedications, setIsLoadingMedications] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (category) {
            setIsLoadingDiagCodes(true);
            CompanyService.getTherapyDiagnosis(category.id)
                .then(compose(setDiagnosisCodes, sortAndPrepend(allDiagnosisOption, 'code')))
                .then(() => setIsLoadingDiagCodes(false))
                .catch((error) => {
                    setIsLoadingDiagCodes(false);
                    toast.error(error.message);
                });
        }
    }, [category]);

    useEffect(() => {
        if (category) {
            setIsLoadingMedications(true);
            CompanyService.getTherapyMedications(category.id)
                .then(compose(setMedications, sortAndPrepend(allMedicationsOption, 'name')))
                .then(() => setIsLoadingMedications(false))
                .catch((error) => {
                    setIsLoadingMedications(false);
                    toast.error(error.message);
                });
        }
    }, [category]);

    const handleSubmit = (values, formikHelpers) => {
        /**
         * The `*Any` variables below indicate the same as "all" options.
         * As such, we do not want to send up medications or diagnosis codes
         * when the user has chosen "all" of either one. And instead, we need
         * to toggle the flag, respectively.
         */
        const drugAny = values.medications.includes(allMedicationsOption);

        const getIds = compose(without([allMedicationsOption.id]), map(prop('id')));

        const diagnosisAny = values.diagnosisCodes.includes(allDiagnosisOption);

        const getCodes = compose(without([allDiagnosisOption.code]), map(prop('code')));

        const payload = {
            therapyId: category.id,
            name: values.name,
            drugAny: drugAny ? 1 : 0,
            medications: drugAny ? [] : getIds(values.medications),
            diagnosisAny: diagnosisAny ? 1 : 0,
            diagnoses: diagnosisAny ? [] : getCodes(values.diagnosisCodes),
        };

        ProtocolService.createProtocol(payload)
            .then((protocol) => {
                formikHelpers.setSubmitting(false);
                formikHelpers.resetForm();
                toast.success('Protocol created successfully.');
                history.push(`/protocolManager/protocol/${protocol.id}`);
            })
            .catch((error) => {
                formikHelpers.setSubmitting(false);
                if (get(error, 'response.data.error') === 'Bad Request' && get(error, 'response.data.message')) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            });
    };
    return (
        <>
            <Helmet>
                <title>New Protocol - {env.catchPhrase}</title>
            </Helmet>
            <PageTitle>New Protocol</PageTitle>

            <PageWrapper>
                {category ? (
                    <Formik
                        initialValues={{
                            name: '',
                            medications: null,
                            diagnosisCodes: [],
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={Yup.object().shape({
                            name: Yup.string()
                                .max(75, 'Name must be less than 75 characters.')
                                .required('A name is required'),
                            medications: Yup.array().required('A medication is required'),
                        })}
                    >
                        {(formik) => (
                            <form>
                                <ProtocolBaseForm
                                    category={category}
                                    medicationOptions={medications}
                                    diagnosisCodeOptions={diagnosisCode}
                                    isFormLoading={isLoadingMedications || isLoadingDiagCodes}
                                />

                                <Row>
                                    <Col md={12}>
                                        <div className="form-button-group">
                                            <Button onClick={() => history.push('/protocolManager')} type="button">
                                                Cancel
                                            </Button>
                                            <Button
                                                bsStyle="primary"
                                                disabled={!formik.dirty ? true : !formik.isValid || formik.isSubmitting}
                                                onClick={formik.handleSubmit}
                                                type="submit"
                                            >
                                                Create
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        )}
                    </Formik>
                ) : (
                    <Redirect to="/protocolManager" />
                )}
            </PageWrapper>
        </>
    );
}

export default CreateProtocolPage;
