import PESService from 'App/services/PESService';
import { useQuery } from 'react-query';
import { errorHandler } from 'App/utils';

function useFetchWorkQueueTableHeaders() {
    return useQuery(['workQueue', 'header'], () => PESService.getHeader(), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchWorkQueueTableHeaders;
