import * as jsDiff from 'diff';

/** @ngInject */
function QuestionDiffController(
    $q,
    _,
    assessmentDiffService,
    assessmentQuestionService,
    customAssessmentService,
    therapyService
) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.selectAssessment = selectAssessment;
    ctrl.diffQuestions = diffQuestions;
    ctrl.diffText = diffText;
    ctrl.filterByTherapy = filterByTherapy;
    ctrl.selectAssessment = selectAssessment;
    ctrl.keepCustom = keepCustom;
    ctrl.revertQuestion = revertQuestion;
    ctrl._size = _.size;

    function $onInit() {
        ctrl.assessmentsLoaded = false;
        ctrl.customizedAssessments = {};
        ctrl.diff = {};
        ctrl.diffLoaded = false;
        ctrl.errors = {};
        ctrl.hasCustom = null;
        ctrl.questionData = {};
        ctrl.therapies = {};

        const promises = {
            customAssessments: customAssessmentService.query(),
            therapies: therapyService.query(),
        };

        return $q.all(promises).then((res) => {
            ctrl.assessmentsLoaded = true;
            if (_.size(res.customAssessments) > 0) {
                ctrl.customizedAssessments = _.values(res.customAssessments);
                ctrl.hasCustom = true;
                _.each(ctrl.customizedAssessments, (assessment) => {
                    if (res.therapies[assessment.group_id]) {
                        ctrl.therapies[assessment.group_id] = res.therapies[assessment.group_id];
                    }
                });
            } else {
                ctrl.hasCustom = false;
            }
        });
    }

    function diffQuestions() {
        ctrl.diff = {};
        _.each(ctrl.questionData, (diffData, id) => {
            const questionDiff = {};
            questionDiff.customQuestionId = diffData.custom_question_id;
            questionDiff.officialQuestionId = diffData.official_question_id;
            questionDiff.verbose = diffText(diffData.official_question, diffData.custom_question);
            questionDiff.compact = diffText(diffData.official_compact_question, diffData.custom_compact_question);
            ctrl.diff[id] = questionDiff;
        });
    }

    function diffText(textA, textB) {
        const diff = jsDiff.diffWordsWithSpace(textA, textB);
        const diffData = { custom: [], official: [] };
        diff.forEach((part) => {
            if (part.added) {
                diffData.custom.push({
                    style: { 'background-color': '#c0e6c0' },
                    text: part.value,
                });
            } else if (part.removed) {
                diffData.official.push({
                    style: { 'background-color': '#ea8a8a' },
                    text: part.value,
                });
            } else {
                diffData.custom.push({ style: {}, text: part.value });
                diffData.official.push({ style: {}, text: part.value });
            }
        });
        return diffData;
    }

    function filterByTherapy(item) {
        return item.group_id === ctrl.selectedTherapy;
    }

    function keepCustom(questionId) {
        _.unset(ctrl.diff, questionId);
    }

    function revertQuestion(questionId) {
        return assessmentQuestionService
            .revert(questionId)
            .then(() => {
                _.unset(ctrl.diff, questionId);
            })
            .catch((res) => {
                ctrl.errors[questionId] = res.data.message;
            });
    }

    function selectAssessment() {
        ctrl.diffLoaded = false;
        return assessmentDiffService.query(ctrl.selectedAssessment).then((res) => {
            ctrl.diffLoaded = true;
            ctrl.questionData = res;
            ctrl.diffQuestions();
        });
    }
}

export default QuestionDiffController;
