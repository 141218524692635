/** @ngInject */
function httpInterceptorService($injector, $q, $rootScope, sessionStorageService) {
    const service = {
        request,
        responseError,
    };
    const errorStatusCodes = [401];

    return service;

    function request(config) {
        return config;
    }

    function responseError(res) {
        const token = sessionStorageService.getJwt();
        const $state = $injector.get('$state');
        if (errorStatusCodes.indexOf(res.status) > -1 && $state.current.url !== '/login') {
            return $state.go('auth.logout');
        }
        if (token) {
            $rootScope.$emit('requestFailed', res);
        }
        return $q.reject(res);
    }
}

export default httpInterceptorService;
