import React, { useCallback, useRef } from 'react';
import { Formik } from 'formik';
import Select from 'react-select';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Button, Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import { uploadPatientActivityFiles } from 'App/components/PatientProfile/PatientProfile.utils.js';

import { noop } from 'lodash';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    uploadLimit: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    uploadLimitInt: PropTypes.string.isRequired,
    notesCount: PropTypes.number.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    notes: PropTypes.arrayOf(
        PropTypes.shape({
            parentNoteId: PropTypes.string.isRequired,
            subject: PropTypes.string.isRequired,
        })
    ).isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    patientId: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onHide: PropTypes.func.isRequired,
};
const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    uploadLimit: '0 Mb',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    uploadLimitInt: '0',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    numberOfNotes: 0,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    notes: [],
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    patientId: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    onHide: noop,
};

const FileUploadForm = ({ uploadLimit, uploadLimitInt, notesCount, notes, patientId, onHide }) => {
    const fileInputRef = useRef();
    const initialValues = {
        activity: notes[0] || '',
        description: '',
        file: null,
        showRequiredError: false,
        showMaxSizeError: false,
        errorFileSize: 0,
    };

    const validateFile = useCallback((file, setFieldValue) => {
        if (file) {
            setFieldValue('showRequiredError', false);
            if (file.size <= uploadLimitInt) {
                setFieldValue('showMaxSizeError', false);
                return true;
            }
            setFieldValue('showMaxSizeError', true);
            setFieldValue('errorFileSize', file.size);
            fileInputRef.current.value = '';
        } else {
            setFieldValue('showRequiredError', true);
            fileInputRef.current.value = '';
        }
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col md={12}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting, setFieldValue }) => {
                        setFieldValue('errorMsg', null);
                        if (!validateFile(values.file, setFieldValue)) {
                            setSubmitting(false);
                            return;
                        }
                        setSubmitting(true);
                        uploadPatientActivityFiles(values, patientId)
                            .then(() => {
                                onHide(true); // true for refetching data from BE.
                            })
                            .catch(({ response }) => {
                                if (response?.status > 0) {
                                    let errorMsg = null;
                                    if (response?.data?.message) {
                                        errorMsg = `${response.status}: ${response.data.message}`;
                                    } else {
                                        errorMsg = `${response.status}: ${response.data}`;
                                    }
                                    setFieldValue('errorMsg', errorMsg);
                                }
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ isSubmitting, setFieldValue, handleSubmit, values }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                {notesCount > 0 && (
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <ControlLabel htmlFor="activity">Activity/Subject</ControlLabel>
                                                <Select
                                                    classNamePrefix="react-select"
                                                    options={notes || []}
                                                    getOptionValue={R.prop('parentNoteId')}
                                                    getOptionLabel={R.prop('subject')}
                                                    inputId="activity"
                                                    isClearable
                                                    onChange={(val) => {
                                                        setFieldValue('activity', val);
                                                    }}
                                                    value={values.activity}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel htmlFor="description">
                                                    Description {values.description?.length || 0}/2500
                                                </ControlLabel>
                                                <textarea
                                                    id="description"
                                                    className="form-control"
                                                    placeholder="New Note"
                                                    rows="3"
                                                    maxLength="2500"
                                                    value={values.description}
                                                    onChange={(e) => setFieldValue('description', e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel htmlFor="file">
                                                    <b>File *</b>
                                                </ControlLabel>
                                                <input
                                                    name="file"
                                                    id="file"
                                                    required
                                                    ref={fileInputRef}
                                                    type="file"
                                                    onChange={(event) => {
                                                        const file = event.currentTarget.files[0];
                                                        if (validateFile(file, setFieldValue)) {
                                                            setFieldValue('file', file);
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                            {values.errorMsg && <div className="text-danger">{values.errorMsg}</div>}
                                            <FormGroup>
                                                <ControlLabel> Maximum size: {uploadLimit}</ControlLabel>
                                            </FormGroup>
                                            <div className="text-left text-danger">
                                                {values.showMaxSizeError && (
                                                    <div>
                                                        The selected file is too large.
                                                        {values.errorFileSize / 1000000} MB: max {uploadLimit}
                                                    </div>
                                                )}
                                                {values.showRequiredError && <div>Please select a file.</div>}
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <div className="row">
                                    <div className="col-md-12 modal-footer">
                                        <div className="form-button-group">
                                            <Button type="button" disabled={isSubmitting} onClick={() => onHide(false)}>
                                                Cancel
                                            </Button>
                                            <Button
                                                id="view-attachments-add-note-button"
                                                type="submit"
                                                bsStyle="primary"
                                                disabled={isSubmitting}
                                            >
                                                Add Note
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </Col>
        </Row>
    );
};

FileUploadForm.propTypes = propTypes;
FileUploadForm.defaultProps = defaultProps;

export default FileUploadForm;
