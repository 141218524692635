import angular from 'angular';

import ICD9TypeaheadController from './icd9-typeahead/icd9-typeahead.component';
import ICD10TypeaheadController from './icd10-typeahead/icd10-typeahead.component';

export default angular
    .module('components.therapy', [])
    .component('icd9Typeahead', {
        bindings: {
            editable: '<',
            label: '@',
            ngDisabled: '<',
            ngRequired: '<',
            onChangeValue: '&',
            placeholder: '@',
            requiredMessage: '@',
            minChar: '@',
            therapyId: '<',
        },
        controller: ICD9TypeaheadController,
        require: {
            form: '^form',
            model: 'ngModel',
        },
        template: require('./icd9-typeahead/icd9-typeahead.html'),
    })
    .component('icd10Typeahead', {
        bindings: {
            editable: '<',
            label: '@',
            ngDisabled: '<',
            ngRequired: '<',
            onChangeValue: '&',
            placeholder: '@',
            requiredMessage: '@',
            minChar: '@',
        },
        controller: ICD10TypeaheadController,
        require: {
            form: '^form',
            model: 'ngModel',
        },
        template: require('./icd10-typeahead/icd10-typeahead.html'),
    }).name;
