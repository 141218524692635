/** @ngInject */
function SummaryNotesController(_, moment, pesService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
        if (changes.patient && ctrl.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
        }
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
        }
    }

    function $onInit() {
        return pesService.getSummaryNotes(ctrl.patient.id, ctrl.activity.assessment.defaultNoteType).then((res) => {
            const notes = _.values(res);
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = notes.length; i < len; i++) {
                let timeZoneFromDB = ctrl.user.time_zone_offset;
                if (ctrl.user.daylight_savings === 1 && moment(notes[i].createdOn).isDST()) {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-plusplus
                    ++timeZoneFromDB;
                }

                const targetTime = new Date(notes[i].createdOn);

                // Get the timezone offset from local time in minutes
                const tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();

                // Convert the offset to milliseconds, add to targetTime, and make a new Date
                notes[i].createdOn = new Date(targetTime.getTime() + tzDifference * 60 * 1000);
            }
            ctrl.notes = angular.copy(notes);
        });
    }
}

export default SummaryNotesController;
