import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import ErrorMessage from 'App/common/formik/ErrorMessage';
import PaddedRow from 'App/common/styles/PaddedRow';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { errorHandler } from 'App/utils';
import { NoteModalConst, NoteModalTypes } from 'App/components/PatientProfile/constants';
import { noop } from 'lodash';

const propTypes = {
    parentNoteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    medicationId: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    patientId: PropTypes.string.isRequired,
    noteType: PropTypes.oneOf(Object.values(NoteModalTypes)),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    show: PropTypes.bool.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onHide: PropTypes.func.isRequired,
};
const defaultProps = {
    parentNoteId: '',
    medicationId: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    patientId: '',
    noteType: 1,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    show: false,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    onHide: noop,
};

const validationSchema = yup.object().shape({
    note: yup.string().required('Please enter a note'),
});
function AddNoteModal({ patientId, medicationId, parentNoteId, noteType, show, onHide }) {
    const metaData = NoteModalConst[noteType];
    if (!show) {
        return null;
    }
    return (
        <Modal show={show} onHide={onHide} backdrop="static" className="addNoteModal">
            <Modal.Header closeButton>
                <Modal.Title>{metaData?.title}</Modal.Title>
            </Modal.Header>

            <Formik
                initialValues={{
                    note: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    let promise = Promise.resolve();
                    switch (noteType) {
                        case NoteModalTypes.medication:
                            promise = metaData.submitFunction(patientId, medicationId, parentNoteId, values.note);
                            break;
                        case NoteModalTypes.prescription:
                            promise = metaData.submitFunction(patientId, medicationId, parentNoteId, values.note);
                            break;
                        case NoteModalTypes.therapy:
                            promise = metaData.submitFunction(patientId, parentNoteId, values.note);
                            break;
                        default:
                            promise = null;
                    }
                    promise
                        .then(() => {
                            toast.success('Note added successfully');
                        })
                        .catch(errorHandler)
                        .finally(() => {
                            setSubmitting(false);
                            onHide(true);
                        });
                }}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, values, errors, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <PaddedRow>
                                    <Col md={12}>
                                        <FormGroup className={errors.note ? 'has-error' : ''}>
                                            <ControlLabel htmlFor="note" className="field-required">
                                                Notes [{values.note.length}/200]
                                            </ControlLabel>
                                            <textarea
                                                data-automation-id="add-rx-note-input"
                                                rows="5"
                                                placeholder="Add notes"
                                                className="form-control"
                                                id="note"
                                                name="note"
                                                maxLength="200"
                                                value={values.note}
                                                onChange={(e) => setFieldValue('note', e.target.value)}
                                            />
                                        </FormGroup>
                                        <ErrorMessage name="note" />
                                    </Col>
                                </PaddedRow>
                                <ErrorMessage name="notes" />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button bsStyle="default" type="button" onClick={onHide}>
                                    Close
                                </Button>
                                <Button bsStyle="primary" type="submit">
                                    Add Note
                                </Button>
                            </Modal.Footer>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
}

AddNoteModal.propTypes = propTypes;
AddNoteModal.defaultProps = defaultProps;

export default AddNoteModal;
