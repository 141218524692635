import { CheckboxInput } from 'Lib/form/CheckboxField';
import React from 'react';
import PropTypes from 'prop-types';
import summaryNotePropType from './types/summaryNotePropType';

const AddToPDFCell = ({ row, column: { onChange }, value }) => {
    return <CheckboxInput name={String(row.original.id)} onChange={onChange} value={value} />;
};

AddToPDFCell.propTypes = {
    row: PropTypes.shape({
        original: summaryNotePropType,
    }).isRequired,
    column: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    value: PropTypes.bool.isRequired,
};

export default AddToPDFCell;
