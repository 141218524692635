import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import CompanyService from 'App/services/CompanyService';
import { Button } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';
import FlexContainer from 'App/common/styles/FlexContainer';
import IconWrapper from 'App/common/styles/IconWrapper';
import Table from '../Table/Table';
import propOrDash from 'App/utils/propOrDash';
import dateOrDashUtc from 'App/utils/dateOrDashUtc';
import pathOrDash from 'App/utils/pathOrDash';
import EditMedicationModal from '../EditMedicationModal/EditMedicationModal';
import TherigyInfoMessage from '../TherigyInfoMessage/TherigyInfoMessage';
import useGetReferralMedication from 'App/hooks/useGetReferralMedication';

const propTypes = {
    patientId: PropTypes.string.isRequired,
    referralId: PropTypes.string.isRequired,
};

const defaultProps = {};

function ReferralMedicationTable({ patientId, referralId }) {
    const isFeatureReferralPMEnabled = CompanyService.getFeatureFlagStatus(
        PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER
    );
    const { data, isLoading, refetch } = useGetReferralMedication(patientId, referralId);
    const columns = useMemo(
        () => [
            {
                Header: isFeatureReferralPMEnabled ? 'Medication' : 'Medications',
                accessor: 'medication.name',
                Cell: ({ row }) => pathOrDash(['original', 'medication', 'name'], row),
                id: 'medication',
            },
            {
                Header: 'ICD-10',
                accessor: propOrDash('icd_10'),
                id: 'icd_10',
            },
            {
                Header: 'Rx Number',
                accessor: 'rx.rxNumber',
                Cell: ({ row }) => pathOrDash(['original', 'rx', 'rxNumber'], row),
                id: 'rxNumber',
            },
            {
                Header: 'Rx Status',
                accessor: propOrDash('status'),
                id: 'status',
            },
            {
                Header: 'Rx Status Date',
                accessor: dateOrDashUtc(['statusDate']),
                id: 'statusDate',
            },
        ],
        [isFeatureReferralPMEnabled]
    );
    const [showEditModal, setShowEditModal] = useState(false);

    const onHide = () => {
        refetch();
        setShowEditModal(false);
    };

    return (
        <div className="referral-medication">
            <FlexContainer>
                <h3>{isFeatureReferralPMEnabled ? 'Medication' : 'Medications'}</h3>
                <div className="add-create-action">
                    <Button bsStyle="link" onClick={() => setShowEditModal(true)}>
                        <IconWrapper>
                            <FaPencilAlt />
                        </IconWrapper>
                        Edit Medication
                    </Button>
                </div>
            </FlexContainer>
            {data?.length > 0 && !isLoading ? (
                <Table
                    enablePagination={false}
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    placeholderText="No Medication"
                />
            ) : (
                <TherigyInfoMessage message="There are currently no medications attached to this referral." />
            )}
            {showEditModal && (
                <EditMedicationModal
                    show={showEditModal}
                    onHide={onHide}
                    patientId={patientId}
                    referralId={referralId}
                />
            )}
        </div>
    );
}

ReferralMedicationTable.propTypes = propTypes;
ReferralMedicationTable.defaultProps = defaultProps;

export default ReferralMedicationTable;
