import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';
import isOnLoginPage from 'App/utils/isOnLoginPage';

function useFetchDevFeatureFlagQuery() {
    const time = 15 * 60 * 1000;

    return useQuery(['featureFlags'], () => CompanyService.getDevFeatureFlags(), {
        retry: 3,
        retryDelay: 500,
        cacheTime: time,
        staleTime: time,
        refetchInterval: () => {
            if (isOnLoginPage()) {
                return false;
            }
            return time;
        },
        refetchOnWindowFocus: false,
        enabled: !isOnLoginPage(),
        onError: (error) => {
            // eslint-disable-next-line
            console.error(error);
        },
    });
}

export default useFetchDevFeatureFlagQuery;
