import { useFormikContext } from 'formik';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';

import useFetchDisabledPatientFields from 'App/hooks/useFetchDisabledPatientFields';
import useFetchRequiredPatientFields from 'App/hooks/useFetchRequiredPatientFields';

const alwaysRequiredFields = [
    'firstName',
    'lastName',
    'birthDate',
    'gender',
    'city',
    'state',
    'zip',
    'planName',
    'patientId',
    'benefitCoverageType',
    'payorType',
];

const isFieldInObject = (fieldName, object) => {
    return !!Object.values(object).find((field) => camelCase(field.field) === fieldName);
};

const CommonPatientFieldWrapper = ({ fieldName, children }) => {
    const {
        values: { requiredOnly },
    } = useFormikContext();
    const { data: disabledFields = {} } = useFetchDisabledPatientFields();
    const { data: requiredFields = {} } = useFetchRequiredPatientFields();
    alwaysRequiredFields.forEach((field) => {
        requiredFields[field] = { field };
    });

    const disabled = isFieldInObject(fieldName, disabledFields);
    const required = isFieldInObject(fieldName, requiredFields);

    if (requiredOnly && !required) {
        return null;
    }

    const commonProps = {
        fieldName,
        disabled,
        required,
    };
    return children(commonProps);
};

CommonPatientFieldWrapper.propTypes = {
    fieldName: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
};

export default CommonPatientFieldWrapper;
