/** @ngInject */
function PDXDuplicatePatientResolveModalController(_, patientService) {
    const ctrl = this;

    ctrl.ok = ok;
    ctrl.selected = null;

    function ok() {
        if (ctrl.selected) {
            patientService.update(ctrl.selected.ID, {
                external_id: ctrl.selected.PDXExternalId,
            });

            _.each(_.without(ctrl.resolve.patient, ctrl.selected), (row) => {
                patientService.disable({ id: row.ID });
            });
            ctrl.modalInstance.close(ctrl.selected);
        }

        ctrl.modalInstance.close();
    }
}

export default PDXDuplicatePatientResolveModalController;
