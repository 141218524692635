import React, { useMemo, useState } from 'react';
import Page from 'App/components/Page/Page';
import PatientProfileTitle from 'App/components/PatientProfile/PatientProfileTitle';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import useFetchActivityDetails from 'App/hooks/useFetchActivityDetails';
import useFetchPatient from 'App/hooks/useFetchPatient';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import { TextareaField } from 'Lib/form/TextareaField';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams, generatePath, useHistory } from 'react-router-dom';
import InformationForm from './components/InformationForm';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { Button } from 'react-bootstrap/lib/InputGroup';
import FlexContainer from 'App/common/styles/FlexContainer';
import { NotFound } from 'App/components/errors';
import useUpdateReferralActivityMutation from 'App/hooks/useUpdateReferralActivityMutation';
import useUpdateReferralActivityMedicationMutation from 'App/hooks/useUpdateReferralActivityMedicationMutation';
import { errorHandler } from 'App/utils';
import { pick } from 'lodash';
import { patientRoute, referralDetailRoute } from 'App/common/routeLookup';
import { toast } from 'react-toastify';
import DeleteModal from 'App/components/DeleteModal/DeleteModal';
import useDeleteReferralActivity from 'App/hooks/useDeleteReferralActivity';
import { convertPAtoSave, convertPAtoView } from '../referral.utils';
import { createReferralActivityNote } from 'App/services/RMSService';
import useCreateReferralActivityMutation from 'App/hooks/useCreateReferralActivityMutation';

const propTypes = {};
const defaultProps = {};

const validationSchema = yup.object().shape({
    status: yup.string().required(),
    details: yup.object().shape({
        paNumber: yup.string().max(64).nullable(),
        followUpPhone: yup.string().max(20).nullable(),
        effectiveStartDate: yup.date().nullable(),
        effectiveEndDate: yup.date().nullable(),
    }),
});

function PriorAuthorization() {
    const { patientId, referralId, priorAuthorizationId, typeId } = useParams();
    const history = useHistory();
    const { isLoading, isError, data: patient } = useFetchPatient(patientId);
    const { data: activityDetails } = useFetchActivityDetails(patientId, referralId, priorAuthorizationId, typeId);
    const updateReferralActivity = useUpdateReferralActivityMutation();
    const updateReferralActivityMedication = useUpdateReferralActivityMedicationMutation();
    const createReferralActivityMutation = useCreateReferralActivityMutation();
    const navigateToReferralDetailPage = () => {
        history.push(generatePath(referralDetailRoute, { patientId, referralId }));
    };
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const deleteReferralActivity = useDeleteReferralActivity();

    const deleteActivity = () => {
        deleteReferralActivity
            .mutateAsync({
                patientId,
                referralId,
                activityId: priorAuthorizationId,
            })
            .then(() => {
                navigateToReferralDetailPage();
            })
            .catch(errorHandler);
    };

    const initialValues = useMemo(() => {
        return activityDetails ? convertPAtoView(activityDetails) : {};
    }, [activityDetails]);

    if (isLoading) {
        return <TherigyLoadingSpinner />;
    }

    if (isError) {
        return (
            <Page
                title="Not Found"
                showPageTitle={false}
                permissionType={PERMISSION_TYPES.ENABLE_REACT_REFERRAL_PRIOR_AUTHORIZATION}
            >
                <NotFound />
            </Page>
        );
    }

    return (
        <Page title="Prior Authorization" permissionType={PERMISSION_TYPES.ENABLE_REACT_REFERRAL_PRIOR_AUTHORIZATION}>
            <div className="container page-wrapper">
                <Row>
                    <Col md={12}>
                        <PatientProfileTitle patient={patient} showEhrStatus={false} />
                    </Col>
                </Row>
                <nav className="profile-actions">
                    <ul className="list-inline">
                        <li>
                            <Link to={generatePath(patientRoute, { patientId })}>Back to Patient Profile</Link>
                        </li>
                        <li>
                            <Link to={generatePath(referralDetailRoute, { patientId, referralId })}>
                                Back to Referral
                            </Link>
                        </li>
                    </ul>
                </nav>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        const { medicationPayload, activityPayload, notesPayload, appealActivityPayload } =
                            convertPAtoSave(values);
                        const promiseArray = [
                            updateReferralActivity.mutateAsync({
                                patientId,
                                referralId,
                                activityId: priorAuthorizationId,
                                data: activityPayload,
                            }),
                        ];
                        medicationPayload.forEach((med) => {
                            promiseArray.push(
                                updateReferralActivityMedication.mutateAsync({
                                    patientId,
                                    referralId,
                                    activityId: priorAuthorizationId,
                                    medicationId: med.id,
                                    data: pick(med, ['medication_id', 'data']),
                                })
                            );
                        });
                        if (appealActivityPayload) {
                            promiseArray.push(
                                createReferralActivityMutation.mutateAsync({
                                    patientId,
                                    referralId,
                                    body: appealActivityPayload,
                                })
                            );
                        }
                        promiseArray.push(createReferralActivityNote(priorAuthorizationId, notesPayload));

                        Promise.all(promiseArray)
                            .then(() => {
                                toast.success('Activity Information has been updated successfully');
                                navigateToReferralDetailPage();
                            })
                            .catch(errorHandler)
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ isSubmitting, handleSubmit, submitForm, isValid, values }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <InformationForm />
                                <Row>
                                    <Col md={6}>
                                        <TextareaField
                                            fieldName="details.notes"
                                            label="Notes"
                                            placeholder="Notes"
                                            disabled={values?.disableActivity || values?.disableInputs}
                                            maxLength={255}
                                            rows={3}
                                        />
                                    </Col>
                                </Row>
                                <FlexContainer>
                                    <FormButtonGroup>
                                        <Button
                                            bsClass="btn btn-primary"
                                            className="pull-right save-due-date"
                                            onClick={submitForm}
                                            id="add-referral-communication-modal-submit"
                                            disabled={
                                                !isValid ||
                                                isSubmitting ||
                                                values?.disableActivity ||
                                                values?.disableInputs
                                            }
                                        >
                                            Save Activity
                                        </Button>
                                        <Button
                                            disabled={isSubmitting}
                                            bsClass="btn btn-default"
                                            className="pull-right mass-texting"
                                            onClick={navigateToReferralDetailPage}
                                        >
                                            Cancel
                                        </Button>
                                    </FormButtonGroup>

                                    <Button
                                        bsClass="btn btn-danger"
                                        className="pull-right"
                                        onClick={() => setShowDeleteModal(true)}
                                        disabled={isSubmitting || values.disableActivity || values.disableInputs}
                                    >
                                        Delete
                                    </Button>
                                </FlexContainer>
                            </form>
                        );
                    }}
                </Formik>
            </div>
            {showDeleteModal && (
                <DeleteModal
                    onHide={() => setShowDeleteModal(false)}
                    show={showDeleteModal}
                    onDelete={deleteActivity}
                    title="Delete Activity"
                    message="Are you sure you want to delete this activity?"
                />
            )}
        </Page>
    );
}

PriorAuthorization.propTypes = propTypes;
PriorAuthorization.defaultProps = defaultProps;

export default PriorAuthorization;
