import QueryClientWrapper from 'Lib/QueryClientWrapper';
import { UserContextProvider } from 'App/contexts/UserContext';
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.node.isRequired,
};
const defaultProps = {};

function AppContextForAngular({ children }) {
    return (
        <QueryClientWrapper>
            <UserContextProvider>{children}</UserContextProvider>
        </QueryClientWrapper>
    );
}

AppContextForAngular.propTypes = propTypes;
AppContextForAngular.defaultProps = defaultProps;

export default AppContextForAngular;
