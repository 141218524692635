import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import useActivityDueDateModal from '../../hooks/useActivityDueDateModal';
import { cellPropsType } from '../../workQueue.constant';
import Button from 'react-bootstrap/lib/Button';

const ActivityDueDateCell = ({ row, value }) => {
    const { setFieldValue } = useFormikContext();
    const onActivityDateClick = useCallback(
        (state) => setFieldValue('modalState.activityDateModal', state),
        [setFieldValue]
    );
    const modalState = useActivityDueDateModal(row);
    return (
        <span>
            <Button bsStyle="link" onClick={() => onActivityDateClick(modalState)}>{value}</Button>
        </span>
    );
};

ActivityDueDateCell.propTypes = cellPropsType;

export default ActivityDueDateCell;
