/** @ngInject */
function ForgotPasswordConfig($stateProvider) {
    $stateProvider.state({
        name: 'auth.forgotPassword',
        url: '/forgotPassword',
        component: 'forgotPassword',
    });
}

export default ForgotPasswordConfig;
