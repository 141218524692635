import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import { NoteModalConst, NoteModalTypes } from 'App/components/PatientProfile/constants';
import { noop } from 'lodash';
import dateOrDash from 'App/utils/dateOrDash';

const propTypes = {
    parentNoteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    medicationId: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    patientId: PropTypes.string.isRequired,
    noteType: PropTypes.oneOf(Object.values(NoteModalTypes)),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    show: PropTypes.bool.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onHide: PropTypes.func.isRequired,
};
const defaultProps = {
    parentNoteId: '',
    medicationId: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    patientId: '',
    noteType: 1,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    show: false,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    onHide: noop,
};

function NoteListModal({ patientId, medicationId, parentNoteId, noteType, show, onHide }) {
    const metaData = NoteModalConst[noteType];
    const [isLoading, setIsLoading] = useState(false);
    const [noteList, setNoteList] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        if (patientId && parentNoteId && metaData) {
            let promise;
            if (
                medicationId &&
                (NoteModalTypes?.medication === noteType || NoteModalTypes?.prescription === noteType)
            ) {
                promise = metaData.fetchNotes(patientId, medicationId, parentNoteId);
            } else {
                promise = metaData.fetchNotes(patientId, parentNoteId);
            }
            promise
                .then((res) => {
                    setNoteList(res);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [medicationId, patientId, parentNoteId, metaData, noteType]);

    if (!show) {
        return null;
    }

    return (
        <Modal show={show} onHide={onHide} backdrop="static" className="noteListModal" bsSize="large">
            <Modal.Header closeButton={false}>
                <Modal.Title className="header">{metaData.noteListTitle}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="rx-notes">
                {isLoading ? (
                    <Row>
                        <Col md={12} className="text-center">
                            <TherigyLoadingSpinner />
                        </Col>
                    </Row>
                ) : (
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                        {noteList.length === 0 ? (
                            <div>
                                <h3>{metaData.noNoteListMessage}</h3>
                            </div>
                        ) : (
                            noteList.map((note) => (
                                <div className="rx-notes-wrapper" key={note.id}>
                                    <div className="rx-note">
                                        <span className="rx-note-text">{note.note}</span>
                                        {' - '}
                                        <span className="therapy-status-note-creator">
                                            {note.createdBy?.firstName} {note.createdBy?.lastName}
                                        </span>
                                        <span className="pull-right rx-note-creator">
                                            {dateOrDash(['createdDate'], note)}
                                        </span>
                                    </div>
                                </div>
                            ))
                        )}
                    </>
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button data-automation-id={metaData.closeButtonId} bsStyle="default" type="button" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

NoteListModal.propTypes = propTypes;
NoteListModal.defaultProps = defaultProps;

export default NoteListModal;
