/** @ngInject */
function DivisionService($http, $q, urlBuilderService) {
    const cachedPolls = {};
    const promises = {};
    const service = {
        getMedications,
        getPolls,
        getUsers,
        getUsersById,
    };

    function getMedications() {
        const url = urlBuilderService.build('', {
            division: '',
            medications: '',
        });

        return $http.get(url).then((res) => {
            return res.data;
        });
    }

    function getPolls(params) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        params = params || {};

        if (params.therapyId && cachedPolls[params.therapyId]) {
            return $q.when(cachedPolls[params.therapyId]);
        }

        const url = urlBuilderService.build('', {
            division: '',
            poll: '',
        });

        return $http
            .get(url, {
                params,
            })
            .then((res) => {
                cachedPolls[params.therapyId] = res.data;
                return res.data;
            });
    }

    function getUsers(params) {
        if (promises.getUsers) {
            return promises.getUsers;
        }

        const url = urlBuilderService.build('', {
            division: '',
            users: '',
        });

        promises.getUsers = $http
            .get(url, {
                params,
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.status === 404) {
                    return {};
                }
                return err;
            })
            .finally(() => {
                promises.getUsers = null;
            });

        return promises.getUsers;
    }

    function getUsersById(divisionId) {
        const url = urlBuilderService.build('', {
            division: divisionId,
            users: '',
        });

        return $http.get(url).then((res) => {
            return res.data;
        });
    }

    return service;
}

export default DivisionService;
