import { Formik, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import TherigyErrorBoundary from 'App/components/errors/TherigyErrorBoundary';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { toDate } from 'App/services/DateService';
import { CheckboxField } from 'Lib/form/CheckboxField';
import SubmitButton from 'Lib/form/SubmitButton';
import { patientTherapyPropType } from 'Lib/types';

import { patientMedicationRxPropType } from '../../types';
import SpaceBetweenContainer from '../SpaceBetweenContainer';
import EditMedicationModalSection from './EditMedicationModalSection';
import EditTherapeuticCategoryModalSection from './EditTherapeuticCategoryModalSection';

const EditTherapeuticCategoryMedicationModal = ({
    existingTherapy,
    existingMedication,
    editMode,
    onClose,
    onSubmit,
}) => {
    const {
        values: { rxs, therapies, medications },
    } = useFormikContext();
    return (
        <Modal show bsSize="lg" onHide={onClose}>
            <TherigyErrorBoundary>
                <Modal.Header>
                    <Modal.Title>Add Therapeutic Category & Medication</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        therapy: {
                            ...existingTherapy,
                            category: existingTherapy
                                ? {
                                      id: existingTherapy.id,
                                      name: existingTherapy.name,
                                  }
                                : null,
                            status: {
                                ...existingTherapy?.status,
                                optIn: existingTherapy?.status?.optIn ?? true,
                                start: toDate(existingTherapy?.status?.start),
                            },
                        },
                        patientMedication: {
                            ...existingMedication,
                            medication: existingMedication?.medication
                                ? {
                                      ...existingMedication.medication,
                                      ID: existingMedication.medication.id,
                                      Name: existingMedication.medication.name,
                                  }
                                : null,
                            status: {
                                ...existingMedication?.status,
                                start: toDate(existingMedication?.status.start),
                            },
                        },
                        rxs: rxs
                            .filter((rx) => rx.patientMedicationId === existingMedication?.id)
                            .map((rx) => ({
                                ...rx,
                                start: toDate(rx.start),
                                fillExpiration: toDate(rx.fillExpiration),
                                rxFill: rx.rxFill?.map((rxFill) => ({
                                    ...rxFill,
                                    fillDate: toDate(rxFill.fillDate),
                                })),
                            })),
                        addMoreMedications: false,
                    }}
                    onSubmit={(values) => {
                        const submissionValues = {
                            ...values,
                            therapy: {
                                ...values.therapy,
                                id: values.therapy.category.id,
                                name: values.therapy.category.name,
                            },
                            patientMedication: {
                                ...values.patientMedication,
                                medication: values.patientMedication.medication
                                    ? {
                                          id: values.patientMedication.medication.ID,
                                          name: values.patientMedication.medication.Name,
                                          groupId: values.therapy.category.id,
                                          newMedication: !editMode,
                                      }
                                    : null,
                                therapy: {
                                    name: values.therapy.category.name,
                                },
                            },
                            rxs: values.rxs.map((rx) => ({
                                ...rx,
                                medication: rx.medication || {
                                    id: values.patientMedication.medication.ID,
                                    name: values.patientMedication.medication.Name,
                                },
                            })),
                        };
                        onClose();
                        onSubmit(submissionValues);
                    }}
                >
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <EditTherapeuticCategoryModalSection
                                existingTherapy={existingTherapy}
                                allTherapies={therapies}
                            />

                            {values.therapy.category?.id && !editMode && (
                                <EditMedicationModalSection
                                    existingMedication={existingMedication}
                                    allMedications={medications}
                                />
                            )}

                            <Modal.Footer>
                                <SpaceBetweenContainer>
                                    <div>
                                        {!editMode && !existingTherapy && values.patientMedication.medication && (
                                            <CheckboxField
                                                fieldName="addMoreMedications"
                                                label="Add more medications"
                                            />
                                        )}
                                    </div>

                                    <FormButtonGroup>
                                        <Button onClick={onClose}>Cancel</Button>
                                        <SubmitButton>Add</SubmitButton>
                                    </FormButtonGroup>
                                </SpaceBetweenContainer>
                            </Modal.Footer>
                        </form>
                    )}
                </Formik>
            </TherigyErrorBoundary>
        </Modal>
    );
};

EditTherapeuticCategoryMedicationModal.propTypes = {
    existingTherapy: patientTherapyPropType,
    existingMedication: patientMedicationRxPropType,
    editMode: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

EditTherapeuticCategoryMedicationModal.defaultProps = {
    existingTherapy: null,
    existingMedication: null,
};

export default EditTherapeuticCategoryMedicationModal;
