import CompanyService from '../../../services/CompanyService';

/** @ngInject */
function NotesModalController($, activityStatusService, amsService, dateService, jwtHelper, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;

    ctrl.firstName = '';
    ctrl.lastName = '';
    ctrl.activityName = '';
    ctrl.therapyStatusId = null;
    ctrl.note = '';
    ctrl.noteChanged = false;
    ctrl.noteData = null;
    ctrl.isEhrEligible = false;
    ctrl.noteStatus = null;
    ctrl.noteStatusList = null;
    ctrl.addNote = addNote;
    ctrl.showAddNote = showAddNote;
    ctrl.hideAddNote = hideAddNote;

    function $onInit() {
        let menu = 'activity_status';
        if (ctrl.type === 'Referral') {
            menu = 'referral_note_status';
        }
        ctrl.noteStatusList = CompanyService.getCustomMenu(menu);

        ctrl.branchSettings = angular.copy(ctrl.branchSettings);

        if (ctrl.branchSettings) {
            ctrl.isEhrEligible = !!ctrl.branchSettings.ehrType;
        }
    }

    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.modalData) {
                const modalData = angular.copy(ctrl.resolve.modalData);
                ctrl.firstName = modalData.firstName;
                ctrl.lastName = modalData.lastName;
                ctrl.activityName = modalData.activityName;
                ctrl.activityId = modalData.activityId;
                ctrl.type = modalData.type;
                ctrl.branchSettings = modalData.branchSettings;
                ctrl.status = modalData.status;
                getNotes();
            }
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.close = function () {
        ctrl.modalInstance.close(ctrl.noteChanged);
    };

    function getNotes() {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        let getNotes;
        if (ctrl.type === 'Referral') {
            getNotes = rmsService.getReferralActivityNotes(ctrl.activityId).then((data) => {
                $.each(data, (key, note) => {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    note.created_by = `${note.created_name.first_name} ${note.created_name.last_name}`;
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    note.notes = note.note;
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    note.created_on = dateService.localize(note.created_on, 'YYYY-MM-DD h:mm a');
                });
                return data.reverse();
            });
        } else {
            getNotes = activityStatusService.getActivityNotes(ctrl.activityId).then((data) => {
                $.each(data, (key, note) => {
                    // TODO: Fix this the next time the file is edited.
                    if (note.status !== 'Finished') {
                        // eslint-disable-next-line no-param-reassign
                        note.activity_ehr_notes = null;
                    }
                    // eslint-disable-next-line no-param-reassign
                    note.created_on = dateService.localize(note.created_on, 'YYYY-MM-DD h:mm a');
                });
                return data;
            });
        }

        return getNotes
            .then((data) => {
                if (data.length > 0) {
                    ctrl.noteData = data;
                } else {
                    ctrl.noteData = false;
                }
                return true;
            })
            .catch((error) => {
                return error.data;
            });
    }

    function addNote() {
        ctrl.saving = true;
        let createNote;
        if (ctrl.type === 'Referral') {
            const body = {
                note: ctrl.note,
                status: ctrl.noteStatus,
            };
            createNote = rmsService.createReferralActivityNotes(ctrl.activityId, body);
        } else {
            createNote = amsService.addActivityNote(ctrl.activityId, ctrl.noteStatus, ctrl.note);
        }
        return createNote
            .then((res) => {
                hideAddNote();
                getNotes();
                ctrl.noteChanged = true;
                return res;
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }

    function showAddNote() {
        $('.action-row').hide();
        $('.modal-footer').show();
    }

    function hideAddNote() {
        ctrl.noteStatus = null;
        ctrl.note = '';
        $('.modal-footer').hide();
        $('.action-row').show();
    }
}

export default NotesModalController;
