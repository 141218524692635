/** @ngInject */
function HttpCacheFactory(CacheFactory) {
    /**
     * Cache Factory
     * That keeps items in sessionStorage
     * Items expiry set for 15 minutes, and flushes the cache every 60 minutes.
     */
    let cache;
    if (!CacheFactory.get('stmCache')) {
        cache = CacheFactory('stmCache', {
            maxAge: 15 * 60 * 1000,
            cacheFlushInterval: 60 * 60 * 1000,
            deleteOnExpire: 'aggressive',
            storageMode: 'sessionStorage',
            storagePrefix: 'stm.',
        });
    } else {
        cache = CacheFactory.get('stmCache');
    }
    return cache;
}

export default HttpCacheFactory;
