import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import PublishProtocolModalBody from './PublishProtocolModalBody';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    excludeIds: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    show: PropTypes.bool,
};

const defaultProps = {
    show: false,
};

function PublishProtocolModal(props) {
    const { show, onHide } = props;
    return (
        <Modal backdrop="static" onHide={onHide} show={show}>
            <ModalHeader closeButton>
                <ModalTitle>Add Protocol to Publish</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <PublishProtocolModalBody {...props} />
            </ModalBody>
        </Modal>
    );
}

PublishProtocolModal.propTypes = propTypes;
PublishProtocolModal.defaultProps = defaultProps;

export default PublishProtocolModal;
