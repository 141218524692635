/**
 * This service handles requests for the RXNCONSO table
 */
/** @ngInject */
function RxnconsoService($http, _, urlBuilderService) {
    const service = {
        search,
    };

    function _format(data) {
        _.forEach(data, (value, key) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            data[_.snakeCase(key)] = value;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete data[key];
        });
        return data;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-shadow
    function search(search) {
        const url = urlBuilderService.build('searchRxnconso', {
            resource: '',
            rxnconso: '',
        });

        return $http
            .get(url, {
                params: {
                    CODE: search,
                    STR: search,
                },
            })
            .then((res) => {
                _.forEach(res.data, (value) => {
                    _format(value);
                });
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    return service;
}

export default RxnconsoService;
