/** @ngInject */
function AssessmentQuestionDateController(moment) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeAnswer = doChangeAnswer;

    function $onChanges(changes) {
        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);

            if (ctrl.question.response !== undefined) {
                ctrl.response = moment(ctrl.question.response, 'YYYY-MM-DDTHH:mm:ss').toDate();
                ctrl.dateValue = moment(ctrl.question.response, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            }
        }
    }

    function doChangeAnswer() {
        const response = ctrl.response ? ctrl.response.toISOString() : '';
        ctrl.dateValue = ctrl.response ? moment(ctrl.response, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD') : '';
        ctrl.onChangeAnswer({
            answer: response,
        });
    }
}

export default AssessmentQuestionDateController;
