import { useQuery } from 'react-query';
import { getReferrals } from 'App/services/RMSService';
import * as R from 'ramda';

const propTypes = {};

const defaultProps = {};

function useFetchPatientReferralsQuery(patientId) {
    return useQuery(
        ['patient', patientId, 'referrals'],
        () => getReferrals(patientId).then(R.ifElse(R.isNil, R.always([]), R.identity)),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

useFetchPatientReferralsQuery.propTypes = propTypes;
useFetchPatientReferralsQuery.defaultProps = defaultProps;

export default useFetchPatientReferralsQuery;
