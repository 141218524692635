import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(utcPlugin);

const isoDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
const isoDateOnlyFormat = 'YYYY-MM-DD';
const dateOnlySlashesFormat = 'YYYY/MM/DD';

const formatUtcDate = ({ date, format = isoDateTimeFormat }) => {
    if (!date) {
        return null;
    }

    return dayjs.utc(date).format(format);
};

function toUtc(date) {
    if (typeof date === 'string' && date.length >= 10 && date !== '0000-00-00 00:00:00') {
        return dayjs.utc(date).toDate();
    }
    return null;
}

export function toDate(dateInput) {
    if (!dateInput) {
        return null;
    }
    return dayjs(dateInput).toDate();
}

export { isoDateOnlyFormat, isoDateTimeFormat, dateOnlySlashesFormat, formatUtcDate, toUtc };
