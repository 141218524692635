function FileInputDirective() {
    return {
        scope: true,
        link(scope, el) {
            el.bind('change', (event) => {
                const file = event.target.files[0];
                scope.$emit('onFileSelect', { file });
            });
        },
    };
}

export default FileInputDirective;
