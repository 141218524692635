import _ from 'lodash';

const { protocol } = window.location;
const { host } = window.location;
const { port } = window.location;
const therigyURL = `${protocol}//${host}${port ? `:${port}` : ''}`;

class UrlBuilderService {
    static build = (type, data) => {
        return this.buildURL(data, type);
    };

    /**
     * @access private
     * @param data array | object - Array of object with key and value or Object with key value
     * @returns {string}
     */
    static buildURL = (data, type) => {
        let url = '';
        switch (type) {
            case 'patientPortal':
                url = `${therigyURL}/app.patient-portal.php`;
                break;
            default:
                url = `${therigyURL}/app.api.php/api`;
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const processArray = (data) => {
            try {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-restricted-syntax
                for (const p in data) {
                    if (typeof p === 'string' && typeof data[p] === 'string') {
                        url += `/${p}`;
                        /**
                         * Handles the scenario where we append an extra trailing slash if the
                         *  data value is an empty string.
                         */
                        if (data[p].length > 0) {
                            url += `/${data[p]}`;
                        }
                    } else if (typeof p !== 'string') {
                        throw new Error(
                            `Invalid params object provided expected object key to be a string but got: ${typeof p}`
                        );
                    } else if (typeof data[p] !== 'string') {
                        const msg = 'Invalid params object provided expected object value to be a string but got: ';
                        throw new Error(msg + typeof data[p]);
                    }
                }
                return url;
            } catch (e) {
                console.error(e);
                throw e;
            }
        };

        /* Check if passed array then process each object */
        if (Array.isArray(data)) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-shadow
            data.forEach((data) => {
                url = processArray(data);
            });
        } else if (typeof data === 'object') {
            url = processArray(data);
        } else {
            throw new Error('Invalid data passed into url builder');
        }
        return url;
    };

    static get = (type, data) => {
        return this.getRouteURL(type, data);
    };

    /**
     * @summary Returns the host name
     *
     * @returns {string} therigyURL - The host name
     */
    static getHost = () => {
        return therigyURL;
    };

    /**
     * URL builder since we are not using ng-route.
     * @param type {string} - Type of URL
     * @param data [[object]] - Options object of data to use when generating the URL
     * @returns {string} - The absolute URL string.
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    static getRouteURL = (type, data) => {
        const api = `${therigyURL}/app.api.php/api`;
        switch (type) {
            case 'categories':
                return `${api}/therapy`;
            case 'diagnosis':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-var,vars-on-top
                var therapyId = data.patient_therapy_id; // jshint ignore:line
                return `${api}/patient/${data.patientId}/diagnosis/${therapyId}`;
            case 'patientMedication':
                return `${api}/patient/${data.patientId}/medication`;
            case 'patientActivityMedication':
                return `${api}/patient/${data.patientId}/activity/${data.activityId}/medication`;
            case 'patientActivityMedicationAction':
                // eslint-disable-next-line max-len
                return `${api}/patient/${data.patientId}/activity/${data.activityId}/medication/${data.medication}/rx_number/${data.rxNumber}`;
            case 'duplicatePatient':
                return `${therigyURL}/ajax/patient_duplicate.php`;
            case 'physicianLookup':
                return `${therigyURL}/ajax/api.physician.php?id=${data.id}`;
            case 'physicianSubmit':
                return `${therigyURL}/ajax/api.physician.php`;
            default:
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-console
                console.trace(`Unsupported route type provided: ${type}`);
        }
    };

    /**
     * @summary Transforms our variable HTTP responses to more usable, non nested form.
     * @param {array|object} res - Res from HTTP promise
     * @returns mixed
     */
    static normalizeResponse = (res) => {
        return Array.isArray(res.data.results) && res.data.results.length >= 1 ? res.data.results[0] : res.data;
    };

    /**
     * @summary snakeCase array keys
     * @param data array
     * @returns array
     */
    static format = (data) => {
        _.forEach(data, (value, key) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            data[_.snakeCase(key)] = value;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete data[key];
        });

        return data;
    };

    /**
     * Parses an object with query params as URI valid query params string
     * @param {object} queryObj - A query params object with fields and values
     * @returns {string} - The parsed query params string
     */
    static getQueryParamsFromObj = (queryObj) => {
        // Return empty string for non valid or empty object
        if (!_.isObject(queryObj) || _.isEmpty(queryObj)) {
            return '';
        }
        const queryString = Object.keys(queryObj)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryObj[key])}`)
            .join('&');
        return `?${queryString}`;
    };
}

export default UrlBuilderService;
