/**
 * This module is an async, select version of the StandaloneFormField component and utilizes react-select. If you want
 * a non-async version, copy this file, replace ReactSelect's import with just "react-select", remove the `loadOptions`
 * prop, and replace the `defaultOptions` with just, `options`.
 */
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import { FaCheck, FaEdit, FaRedo, FaTimes } from 'react-icons/fa';
import ReactSelect from 'react-select/async';
import { ClipLoader } from 'react-spinners';
import useStandaloneFormField from './useStandaloneFormField';

const propTypes = {
    onSave: PropTypes.func.isRequired,
    loadOptions: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    defaultOptions: PropTypes.array,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    initialValue: PropTypes.any,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    isDisabled: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    name: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    getOptionLabel: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    getOptionValue: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    placeholder: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    validator: PropTypes.func,
};

const defaultProps = {
    defaultOptions: [],
    onChange: Function,
};

const Container = styled('div')`
    display: flex;
    align-items: flex-end;

    .form-field {
        flex: 11;
        margin-right: 0.5rem;
    }

    .btn-group {
        flex: 2;
    }
`;

function AsyncSelect({
    initialValue,
    onSave,
    validator,
    id,
    name,
    placeholder,
    onDelete,
    isDisabled: propDisable,
    loadOptions,
    defaultOptions,
    getOptionLabel,
    getOptionValue,
    onChange,
    ...props
}) {
    //#region State
    const {
        handleCancel,
        handleSave,
        hasFailed,
        isActive,
        isDirty,
        isDisabled,
        isPending,
        isValid,
        onEdit,
        setValue,
        state,
    } = useStandaloneFormField({
        initialValue,
        onSave,
        validator,
    });
    const { context } = state;

    //#endregion

    return (
        <form onSubmit={handleSave}>
            <FormGroup validationState={isValid ? null : 'error'}>
                <Container>
                    <ReactSelect
                        {...props}
                        cacheOptions
                        defaultOptions={defaultOptions}
                        loadOptions={loadOptions}
                        id={id}
                        name={name}
                        className="form-field"
                        placeholder={placeholder}
                        value={context.currentValue}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        onChange={(opt) => {
                            setValue(opt);
                            onChange(opt);
                        }}
                        isDisabled={propDisable || isDisabled || !isActive}
                    />

                    {isActive || isPending ? (
                        <ButtonGroup>
                            <Button
                                type="button"
                                onClick={handleCancel}
                                disabled={isPending}
                                title="Cancel"
                                aria-label="Cancel"
                            >
                                <FaTimes />
                            </Button>

                            <Button
                                type="submit"
                                disabled={!isDirty || isPending}
                                title="Save"
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line react/prop-types
                                aria-label={`${props['aria-label']} Save`}
                            >
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {hasFailed ? (
                                    <FaRedo />
                                ) : isPending ? (
                                    <ClipLoader size={15} color="#333" />
                                ) : (
                                    <FaCheck />
                                )}
                            </Button>
                        </ButtonGroup>
                    ) : (
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/prop-types
                        <Button type="button" onClick={onEdit} title="Edit" aria-label={`${props['aria-label']} Edit`}>
                            <FaEdit />
                        </Button>
                    )}

                    {typeof onDelete === 'function' && (
                        <Button
                            type="button"
                            bsStyle="link"
                            onClick={onDelete}
                            title="Delete"
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line react/prop-types
                            aria-label={`${props['aria-label']} Delete`}
                        >
                            Delete
                        </Button>
                    )}
                </Container>
                {context.error && <HelpBlock>{context.error}</HelpBlock>}
            </FormGroup>
        </form>
    );
}

AsyncSelect.propTypes = propTypes;
AsyncSelect.defaultProps = defaultProps;

export default AsyncSelect;
