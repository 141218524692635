function AssessmentGroupController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
        }

        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.group && ctrl.group) {
            ctrl.group = angular.copy(ctrl.group);
        }

        if (changes.questions && ctrl.questions) {
            ctrl.questions = angular.copy(ctrl.questions);
        }
    }
}

export default AssessmentGroupController;
