function TherigyTrackedTableRow() {
    return {
        restrict: 'A',
        priority: -1,
        require: ['^therigyTrackedTable', 'ngForm'],
        controller: TherigyTrackedTableRowController,
    };
}

function TherigyTrackedTableRowController($attrs, $element, $parse, $scope) {
    const self = this;
    const row = $parse($attrs.therigyTrackedTableRow)($scope);
    const trackedTableCtrl = $element.controller('therigyTrackedTable');

    self.isCellDirty = isCellDirty;
    self.setCellDirty = setCellDirty;
    self.setCellInvalid = setCellInvalid;

    function isCellDirty(cell) {
        return trackedTableCtrl.isCellDirty(row, cell);
    }

    function setCellDirty(cell, isDirty) {
        trackedTableCtrl.setCellDirty(row, cell, isDirty);
    }

    function setCellInvalid(cell, isInvalid) {
        trackedTableCtrl.setCellInvalid(row, cell, isInvalid);
    }
}

export default TherigyTrackedTableRow;
