import { useQuery } from 'react-query';
import graphClient from '../common/graphClient';
import * as R from 'ramda';
import { gql } from 'graphql-request';
import { errorHandler } from 'App/utils';

/*
GraphQl request for fetch list of diagnosis
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line consistent-return
function fetchDiagnosis(therapyId) {
    if (therapyId) {
        return graphClient
            .request(
                gql`
                    query GetDiagnosis($therapyId: String!) {
                        diagnosis(therapyId: $therapyId) {
                            id
                            groupId
                            name
                            icd9
                            divisionId
                            companyId
                            disabledBy
                            disabledOn
                            createdOn
                            dateTime
                        }
                    }
                `,
                { therapyId }
            )
            .then(R.propOr([], 'diagnosis'));
    }
}
/*
fetch list of diagnosis based on therapy id
 */
function useFetchDiagnosisQuery(therapyId) {
    return useQuery(['therapyId', therapyId, 'diagnosis'], () => fetchDiagnosis(therapyId), {
        onError: (error) => {
            errorHandler(error);
        },
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchDiagnosisQuery;
