/**
 * A simple error message display
 */
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

const defaultProps = {
    children: <>An unknown error has occurred</>,
};

function ErrorMessage({ children }) {
    return (
        <div className="container-fluid page-wrapper" style={{ paddingTop: 15 }} role="alert">
            <div className="error_display">{children}</div>
        </div>
    );
}

ErrorMessage.propTypes = propTypes;
ErrorMessage.defaultProps = defaultProps;

export default ErrorMessage;
