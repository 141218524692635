import PESService from './PESService';
import { convertPatientPrescriptions } from '../utils/phpPayloadConversions';

/** @ngInject */
function PatientMedicationRxNumberService($http, _) {
    const promises = {};
    return {
        query,
    };

    function query(patientId, medicationId) {
        if (promises[`queryMedicationRxNumbers_${medicationId}`]) {
            return promises[`queryMedicationRxNumbers_${medicationId}`];
        }

        promises[`queryMedicationRxNumbers_${medicationId}`] = PESService.getPatientPrescriptions(
            patientId,
            medicationId
        )
            .then((res) => {
                const prescriptions = convertPatientPrescriptions(res);
                _.forEach(prescriptions, (value) => {
                    if (_.isString(value.start)) {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        value.start = new Date(value.start);
                    }
                    if (_.isString(value.end)) {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        value.end = new Date(value.end);
                    }
                });
                return prescriptions;
            })
            .catch((err) => {
                return err;
            })
            .finally(() => {
                promises[`queryMedicationRxNumbers_${medicationId}`] = undefined;
            });
        return promises[`queryMedicationRxNumbers_${medicationId}`];
    }
}

export default PatientMedicationRxNumberService;
