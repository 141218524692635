import axios from 'axios';
import Cookies from 'js-cookie';
import Qs from 'qs';
import JwtDecode from 'jwt-decode';

import __env from '../components/config/config';
import CompanyService from './CompanyService';
import SessionStorageService from './SessionStorageService';
import UserService from './UserService';
import getSlsBasePath from '../utils/getSlsBasePath/getSlsBasePath';
import { getAuthorizationHeader, hasLength } from '../utils';
import * as R from 'ramda';
import env from 'App/components/config/config';
import getData from 'App/utils/getData';

const slsEndpoint = getSlsBasePath(__env, 'user');

class AuthService {
    /**
     * Stores the endpoint url for TAS
     */
    static endpoint = `${__env.tasEndpoint}:${__env.tasPort}/tas/${__env.tasVersion}`;

    /**
     * @ngdoc method
     * @name authService#changeBranch
     *
     * @description
     * Changes the branch a users session is attached to.
     *
     * @param {string} divisionId - The id of the division the user will be switched to.
     */
    static changeBranch = async (divisionId) => {
        await UserService.setBranch(divisionId);
        await this.updateJwt();
    };

    static changePassword = async (currentPassword, newPassword) => {
        const session = SessionStorageService.getJwt();
        const url = `${this.endpoint}/user/changePassword`;
        const data = { currentPassword, newPassword };
        const headers = {
            Authorization: `Bearer ${session}`,
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        const response = await axios.patch(url, data, {
            paramsSerializer(params) {
                // Equivalent to angular's $httpParamSerializerJQLike
                return Qs.stringify(params, { arrayFormat: 'brackets' });
            },
            withCredentials: true,
            headers,
        });
        return response.data;
    };

    /**
     * @ngdoc method
     * @name authService#get
     *
     * @description
     * Gets the current session token from one of two places:
     * 1. The currentToken object in local storage
     * 2. The rootscope of the application
     * 2. The Cookie for SSO users
     *
     * If no key is found, it will redirect to the login page.
     *
     * @returns {string|null} The session token if it exists, or null
     */
    static get = () => {
        const token = window.localStorage.getItem('currentToken');
        if (Cookies.get('sso_jwt') && !token) {
            // If the user is arriving from single sign-on we need to set these values.
            SessionStorageService.getJwt();
            return this.get();
        }
        if (typeof token === 'string') {
            return token;
        }
        return null;
    };

    static getTargetPage = () => {
        const target = window.localStorage.getItem('targetPage');
        if (typeof target === 'string') {
            return target;
        }
        return null;
    };

    /**
     * Clears anything stored for target page.
     * @returns {boolean}
     */
    static clearTargetPage = () => {
        window.localStorage.removeItem('targetPage');
        return true;
    };

    /**
     * @ngdoc method
     * @name authService#getUser
     *
     * @description
     * Gets User's Object
     */
    static getUser = () => {
        return UserService.get();
    };

    /**
     * Fetches branches the user has access to.
     * @param {Boolean} setInLocalStorage - If true, will set the userBranches in localStorage
     * @returns {<Promise>.UserBranches} - Resolves into the User Branches object keyed by Id
     */
    static fetchUserBranches = async (setInLocalStorage = false) => {
        const url = `${slsEndpoint}/1.0.0/branches`;

        const { data = {} } = await axios.get(url, getAuthorizationHeader());
        if (setInLocalStorage) {
            // Clean createdBy, and modifiedBy keys containing user ids
            const userBranches = R.compose(
                R.tryCatch(JSON.stringify, R.always('{}')),
                R.map(R.pick(['id', 'name', 'createdOn', 'modifiedOn']))
            )(data);

            window.localStorage.setItem('userBranches', userBranches);
        }
        return data;
    };

    /**
     * Gets and parses userBranches object string from localStorage.
     * @param {Boolean} returnArray - If true, will return an array of branches objects.
     * @returns {null|Array.<Object>|Object}
     */
    static getUserBranches = (returnArray = false) => {
        const userBranches = window.localStorage.getItem('userBranches');
        if (userBranches) {
            const parsedUserBranches = JSON.parse(userBranches);
            if (hasLength(Object.keys(parsedUserBranches))) {
                if (returnArray) {
                    return Object.values(parsedUserBranches);
                }
                return parsedUserBranches;
            }
        }
        return null;
    };

    static setTargetPage = (target) => {
        window.localStorage.setItem('targetPage', target);
    };

    /**
     * @ngdoc method
     * @name authService#remove
     *
     * @description
     * Removes the current token
     */
    static clearSession = () => {
        SessionStorageService.remove('timeout');
        window.localStorage.removeItem('branchId');
        window.localStorage.removeItem('companyId');
        window.localStorage.removeItem('currentToken');
        window.localStorage.removeItem('JWT-chunks');
        window.localStorage.removeItem('messageCount');
        window.localStorage.removeItem('sessionExpirationShown');
        window.localStorage.removeItem('sessionId');
        window.localStorage.removeItem('targetPage');
        window.localStorage.removeItem('userAge');
        window.localStorage.removeItem('userId');
        window.localStorage.removeItem('userBranches');
        let jwtCount = 0;
        while (jwtCount >= 0) {
            if (typeof window.localStorage.getItem(`JWT-${jwtCount.toString()}`) === 'string') {
                window.localStorage.removeItem(`JWT-${jwtCount.toString()}`);
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                ++jwtCount;
            } else {
                jwtCount = -1;
            }
        }
    };

    /**
     * @summary Fetches an update JWT from Therigy Authentication Service
     *
     * @param {string} username - The username.
     * @param {string} password - The password.
     * @returns {Promise} The JWT
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    static login = async (username, password) => {
        const url = `${this.endpoint}/jwt`;
        const data = { username, password };
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        const response = await axios.post(url, data, {
            paramsSerializer(params) {
                // Equivalent to angular's $httpParamSerializerJQLike
                return Qs.stringify(params, { arrayFormat: 'brackets' });
            },
            withCredentials: true,
            headers,
        });

        if (response && response.data) {
            SessionStorageService.setJwt(response.data);
            await this.fetchUserBranches(true);
            await CompanyService.getCustomMenus();
            await UserService.getMessageCount();
            return JwtDecode(response.data);
        }
    };

    /**
     * @ngdoc method
     * @name authService#ssoLogout
     *
     * @description
     * Clears sso users session.
     *
     */
    static ssoLogout = () => {
        this.clearSession();
    };

    /**
     * @ngdoc method
     * @name authService#logout
     *
     * @description
     * Logs out the current user and removes their token.
     *
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    static logout = async () => {
        const url = '/stm/logout.php?angular=true';
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };
        try {
            await axios.post(
                url,
                {},
                {
                    headers,
                }
            );
            this.clearSession();
        } catch (error) {
            this.clearSession();
            return error.data;
        }
    };

    /**
     * @ngdoc method
     * @name authService#set
     *
     * @description
     * Sets a user token into local storage. This method is used to set the 'currentToken' value in local storage, this
     * action needs to occur here as requring SessionStorage will create a circular dependency. *
     * @param {string} token - The token value
     */
    static set = (token) => {
        window.localStorage.setItem('currentToken', token);
    };

    static getSsoUrl({ email }) {
        /**
         * This is an unauthenticated route. Do not provide token.
         */
        const url = `${getSlsBasePath(__env, 'tas')}/${env.slsVersion}`;
        return axios.post(`${url}/sso_redirect`, { email }).then(getData).then(R.prop('ssoLoginUrl'));
    }

    /**
     * @ngdoc method
     * @name authService#updateJwt
     *
     * @description
     * Fetches an update JWT from Therigy Authentication Service
     *
     * @returns {Promise}
     */
    static updateJwt = async () => {
        // In order to ensure that tokens remain fresh, we request a new one every time there is UI activity.
        const session = SessionStorageService.getJwt();
        if (typeof session !== 'string') {
            return false;
        }
        const url = `${this.endpoint}/jwt`;
        const headers = {
            Authorization: `Bearer ${session}`,
        };
        const response = await axios.post(url, null, {
            headers,
        });
        SessionStorageService.setJwt(response.data);

        return response.data;
    };
}

export default AuthService;
