import PropTypes from 'prop-types';

const summaryNotePropTypeShape = {
    subject: PropTypes.string,
    type: PropTypes.number,
    body: PropTypes.string,
    createdOn: PropTypes.string,
    createdByUser: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
    patientId: PropTypes.string,
    parentNoteId: PropTypes.number,
    lastEditedOn: PropTypes.string,
};
summaryNotePropTypeShape.history = PropTypes.arrayOf(PropTypes.shape(summaryNotePropTypeShape));

const summaryNotePropType = PropTypes.shape(summaryNotePropTypeShape);

export default summaryNotePropType;
