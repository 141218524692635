import createAxios from 'App/utils/createAxios';
import { getSlsBasePath } from '../utils';
import config from '../components/config/config';

const rms = createAxios({
    baseURL: `${getSlsBasePath(config, 'rms')}/1.0.0/financialAssistanceProgram`,
});

function getProgramById(id, params = {}) {
    return rms.get(`/${id}`, { params });
}

function getAllPrograms(params = {}) {
    return rms.get('', { params });
}

function getProgramHistory(id, params = {}) {
    return rms.get(`/${id}/latestHistory`, { params });
}

export { getAllPrograms, getProgramById, getProgramHistory };
