import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';

//#region PropTypes
const propTypes = {
    onHide: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onConfirm: PropTypes.func,
    show: PropTypes.bool,
    message: PropTypes.string,
    title: PropTypes.string,
};

const defaultProps = {
    show: false,
    message: 'Are you sure you want to edit this item?',
    title: 'Edit Item',
};

//#endregion

function DeleteModal({ onHide, show, onConfirm, message, title }) {
    return (
        <Modal show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p style={{ fontSize: '1.8rem' }}>{message}</p>

                <p>
                    <strong>This action cannot be undone.</strong>
                </p>
            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle="default" onClick={onHide}>
                    Cancel
                </Button>
                <Button bsStyle="primary" onClick={onConfirm}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

DeleteModal.propTypes = propTypes;
DeleteModal.defaultProps = defaultProps;

export default DeleteModal;
