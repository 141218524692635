import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import styled from '@emotion/styled';
import TherigyInfoMessage from '../../TherigyInfoMessage/TherigyInfoMessage';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    placeholderText: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

const PlaceHolder = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
    height: 400px;
    border-radius: 3px;
    font-size: 14px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%);
    color: #20386c;
`;

function PlaceHolderMessage({ placeholderText, children }) {
    return (
        <Row>
            <Col md={12}>
                <PlaceHolder>
                    {/* TODO: Fix this the next time the file is edited. */}
                    {/* eslint-disable-next-line react/no-children-prop */}
                    <TherigyInfoMessage message={placeholderText} children={children} />
                </PlaceHolder>
            </Col>
        </Row>
    );
}

PlaceHolderMessage.propTypes = propTypes;

export default PlaceHolderMessage;
