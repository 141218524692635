/**
 * This service handles requests for assessment questions
 */
/** @ngInject */
function AssessmentQuestionService($http, $httpParamSerializerJQLike, urlBuilderService) {
    return {
        getQuestionGroups,
        getQuestionLinkOptions,
        getQuestionLinks,
        getQuestions,
        getSingleQuestion,
        revert: revertCustomQuestion,
        saveQuestions,
    };

    function getQuestionGroups() {
        return $http({
            method: 'get',
            url: urlBuilderService.build('questionGroups', {
                assessment: '',
                questions: '',
                groups: '',
            }),
        }).then((res) => {
            return res.data;
        });
    }

    function getQuestionLinkOptions() {
        return $http({
            method: 'get',
            url: urlBuilderService.build('questionLinkOptions', {
                assessment: '',
                questions: '',
                linkoptions: '',
            }),
        }).then((res) => {
            return res.data;
        });
    }

    function getQuestionLinks(pollId) {
        return $http({
            method: 'get',
            url: urlBuilderService.build('questionLinks', {
                assessment: '',
                questions: pollId,
                links: '',
            }),
        }).then((res) => {
            return res.data;
        });
    }

    function getQuestions(pollId, parentAnswerId) {
        return $http({
            method: 'get',
            url: urlBuilderService.build('questions', {
                assessment: '',
                questions: pollId,
            }),
            params: {
                parentAnswerId,
            },
        }).then((res) => {
            return res.data;
        });
    }

    function saveQuestions(pollId, questions) {
        const questionJson = JSON.stringify(questions);
        return $http({
            method: 'post',
            url: urlBuilderService.build('saveQuestions', {
                assessment: '',
                questions: pollId,
            }),
            data: $httpParamSerializerJQLike({
                questionJson,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        }).then((res) => {
            return res.data;
        });
    }

    function getSingleQuestion(questionId) {
        return $http({
            method: 'get',
            url: urlBuilderService.build('questions', {
                assessment: '',
                question: questionId,
            }),
        }).then((res) => {
            return res.data;
        });
    }

    function revertCustomQuestion(questionId) {
        const url = urlBuilderService.build('assessment', {
            assessment: '',
            question: questionId,
            revert: '',
        });

        return $http.patch(url);
    }
}

export default AssessmentQuestionService;
