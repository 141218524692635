import React from 'react';
import hasLength from 'App/utils/hasLength';
import Note from './Note';
import { notesPatient, noteType } from './types';
import PropTypes from 'prop-types';
import NotesStyle from './NotesStyle';

const propTypes = {
    notes: PropTypes.arrayOf(noteType).isRequired,
    patient: notesPatient.isRequired,
};

const defaultProps = {};

function Notes({ notes, patient }) {
    return (
        <NotesStyle>
            <div id="display-summary-wrapper">
                <div className="download-summary">
                    <p>
                        <span className="text">{`${patient.lastName}, ${patient.firstName} - ${patient.humanId}`}</span>
                        <br />
                        <span className="detail">{patient.externalId}</span>
                    </p>

                    {hasLength(notes)
                        ? notes.map((note, idx, array) => {
                              const isLast = array.length - 1 === idx;
                              return <Note note={note} key={note.id} isLast={isLast} />;
                          })
                        : null}
                </div>
            </div>
        </NotesStyle>
    );
}

Notes.propTypes = propTypes;
Notes.defaultProps = defaultProps;

export default Notes;
