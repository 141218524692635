import * as R from 'ramda';
import { useMutation, useQueryClient } from 'react-query';
import { createProtocolTemplate } from 'App/services/ProtocolTemplateService';

const useCreateProtocolTemplateMutation = () => {
    const queryClient = useQueryClient();

    return useMutation((payload) => createProtocolTemplate(payload), {
        onSuccess: (template) => {
            return queryClient.setQueryData(['protocol', 'templates'], R.append(template));
        },
    });
};

export default useCreateProtocolTemplateMutation;
