/** @ngInject */
function UserManagerConfig($stateProvider) {
    $stateProvider.state({
        component: 'userManager',
        name: 'app.userManager',
        url: '/user-manager',
    });
}

export default UserManagerConfig;
