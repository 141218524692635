import * as R from 'ramda';
import React from 'react';
import { Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { TextField } from '@/lib/form/TextField';
import SelectField from '@/lib/form/SelectField';
import { DatePickerField } from '@/lib/form/DatePickerField';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import ResetButton from 'Lib/form/ResetButton';
import SubmitButton from 'Lib/form/SubmitButton';
import dayjs from 'dayjs';
import dayJsUtc from 'dayjs/plugin/utc';
import useGetCustomMenuQuery from 'App/hooks/useGetCustomMenuQuery';
import hasLength from 'App/utils/hasLength';
import { useHistory, useParams } from 'react-router-dom';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import useUpdateReferralMutation from 'App/hooks/useUpdateReferralMutation';
import useGetReferral from './hooks/useGetReferral';
import useGetAvailableReferralMenus from './hooks/useGetAvailableReferralMenus';
import * as Yup from 'yup';
import InvalidMessage from 'App/common/styles/InvalidMessage';

dayjs.extend(dayJsUtc);

const propTypes = {};

const defaultProps = {};

const validationSchema = Yup.object().shape({
    updatedOn: Yup.date().required().label('Status Update Date'),
    status: Yup.object().required().nullable().label('Status'),
    statusReason: Yup.object().required().nullable().label('Status Reason'),
    statusSubReason: Yup.object()
        .nullable()
        .label('Status Sub-Reason')
        .when('statusReason', {
            is: (val) => {
                return hasLength(val?.children);
            },
            then: (schema) => schema.required(),
        }),
    statusAction: Yup.object()
        .nullable()
        .label('Status Action')
        .when('statusSubReason', {
            is: (val) => {
                return hasLength(val?.children);
            },
            then: (schema) => schema.required(),
        }),
    statusOutcome: Yup.object()
        .nullable()
        .label('Status Outcome')
        .when('statusAction', {
            is: (val) => {
                return hasLength(val?.children);
            },
            then: (schema) => schema.required(),
        }),

    receivedOn: Yup.date().required().label('Received Date'),
    receivedBy: Yup.object().nullable().label('Received From'),

    createdOn: Yup.date().required().label('Start Date'),
    number: Yup.string().nullable().label('Referral Number'),

    encounterId: Yup.string().nullable().label('Patient Encounter ID'),
    systemId: Yup.string().nullable().label('Patient Pharmacy System ID'),
    dispenseStatus: Yup.object().nullable().label('Disspense Status'),
    dispenseStatusReason: Yup.object().nullable().label('Disspense Status Reason'),
    hubProgramId: Yup.string().nullable().label('Patient HUB Program ID'),

    statusDate: Yup.date().nullable().label('Status Update Date'),
});

function ReferralInformationForm() {
    const { patientId, referralId } = useParams();
    const history = useHistory();

    const referralUpdateMutation = useUpdateReferralMutation(patientId, referralId);
    const statuses = useGetAvailableReferralMenus();
    const referralQuery = useGetReferral(patientId, referralId, statuses);

    const dispsenseStatusQuery = useGetCustomMenuQuery('referral_dispense_status');
    const dispsenseStatusReasonQuery = useGetCustomMenuQuery('referral_dispense_status_reason');
    const receivedFromQuery = useGetCustomMenuQuery('referral_received_from');

    if (referralQuery.isLoading) {
        return <TherigyLoadingSpinner />;
    }

    return (
        <Formik
            initialValues={referralQuery.referral}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const payload = R.applySpec({
                    status_date: R.propOr(null, 'updatedOn'),
                    created_on: R.propOr(null, 'createdOn'),
                    dispense_status: R.pathOr(null, ['dispenseStatus', 'label']),
                    dispense_status_reason: R.pathOr(null, ['dispenseStatusReason', 'label']),
                    encounter_id: R.propOr(null, 'encounterId'),
                    hub_program_id: R.propOr(null, 'hubProgramId'),
                    no_go_triaged_to: R.always(null),
                    number: R.propOr(null, 'number'),
                    received_by: R.pathOr(null, ['receivedBy', 'label']),
                    received_on: R.propOr(null, 'receivedOn'),
                    status: R.pathOr(null, ['status', 'label']),
                    status_action: R.pathOr(null, ['statusAction', 'label']),
                    status_outcome: R.pathOr(null, ['statusOutcome', 'label']),
                    status_reason: R.pathOr(null, ['statusReason', 'label']),
                    status_sub_reason: R.pathOr(null, ['statusSubReason', 'label']),
                    system_id: R.propOr(null, 'systemId'),
                    type: R.propOr(0, 'type'),
                })(values);

                return referralUpdateMutation
                    .mutateAsync(payload)
                    .then(() => {
                        resetForm({ values });
                        if (payload.status === 'Complete') {
                            history.push(`/patient/${patientId}/referral-manager`);
                        }
                    })
                    .catch(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {({ values, setFieldValue, handleSubmit, setValues }) => {
                return (
                    <form className="referral-information-form" noValidate onSubmit={handleSubmit}>
                        <Row className="row" style={{ marginBottom: '1rem' }}>
                            <Col md={3}>
                                <DatePickerField
                                    required
                                    label="Status Update Date"
                                    placeholder="Status Update Date"
                                    fieldName="updatedOn"
                                />
                            </Col>

                            <Col md={3}>
                                <SelectField
                                    label="Status"
                                    required
                                    placeholder="Status"
                                    fieldName="status"
                                    options={statuses}
                                    onChange={(option) => {
                                        R.compose(
                                            setValues,
                                            R.set(R.lensProp('status'), option),
                                            R.set(R.lensProp('statusReason'), null),
                                            R.set(R.lensProp('statusSubReason'), null),
                                            R.set(R.lensProp('statusAction'), null),
                                            R.set(R.lensProp('statusOutcome'), null)
                                        )(values);
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            {R.compose(hasLength, R.path(['status', 'children']))(values) ? (
                                <Col md={3}>
                                    <SelectField
                                        required
                                        label="Status Reason"
                                        fieldName="statusReason"
                                        options={values.status.children}
                                        onChange={(option) => {
                                            R.compose(
                                                setValues,
                                                R.set(R.lensProp('statusReason'), option),
                                                R.set(R.lensProp('statusSubReason'), null),
                                                R.set(R.lensProp('statusAction'), null),
                                                R.set(R.lensProp('statusOutcome'), null)
                                            )(values);
                                        }}
                                    />
                                </Col>
                            ) : (
                                <Col md={12}>
                                    <InvalidMessage>
                                        Unable to fetch all status options. Please try logging out and back in.
                                    </InvalidMessage>
                                </Col>
                            )}

                            {R.compose(hasLength, R.path(['statusReason', 'children']))(values) ? (
                                <Col md={3}>
                                    <SelectField
                                        required
                                        label="Status Sub-Reason"
                                        fieldName="statusSubReason"
                                        options={values.statusReason.children}
                                        onChange={(option) => {
                                            R.compose(
                                                setValues,
                                                R.set(R.lensProp('statusSubReason'), option),
                                                R.set(R.lensProp('statusAction'), null),
                                                R.set(R.lensProp('statusOutcome'), null)
                                            )(values);
                                        }}
                                    />
                                </Col>
                            ) : null}

                            {R.compose(hasLength, R.path(['statusSubReason', 'children']))(values) ? (
                                <Col md={3}>
                                    <SelectField
                                        required
                                        label="Status Action"
                                        fieldName="statusAction"
                                        options={values.statusSubReason.children}
                                        onChange={(option) => {
                                            R.compose(
                                                setValues,
                                                R.set(R.lensProp('statusAction'), option),
                                                R.set(R.lensProp('statusOutcome'), null)
                                            )(values);
                                        }}
                                    />
                                </Col>
                            ) : null}

                            {R.compose(hasLength, R.path(['statusAction', 'children']))(values) ? (
                                <Col md={3}>
                                    <SelectField
                                        required
                                        label="Status Outcome"
                                        fieldName="statusOutcome"
                                        options={values.statusAction.children}
                                    />
                                </Col>
                            ) : null}
                        </Row>

                        <div style={{ height: '5rem' }} />

                        <Row>
                            <Col md={3}>
                                <DatePickerField label="Received Date" fieldName="receivedOn" required />
                            </Col>

                            <Col md={3}>
                                <SelectField
                                    label="Received From"
                                    fieldName="receivedBy"
                                    options={receivedFromQuery.data}
                                />
                            </Col>

                            <Col md={3}>
                                <DatePickerField label="Start Date" fieldName="createdOn" required />
                            </Col>

                            <Col md={3}>
                                <TextField fieldName="number" placeholder="Referral Number" label="Referral Number" />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={3}>
                                <TextField
                                    fieldName="encounterId"
                                    placeholder="Patient Encounter ID"
                                    label="Patient Encounter ID"
                                />
                            </Col>

                            <Col md={3}>
                                <TextField
                                    fieldName="systemId"
                                    placeholder="Patient Pharmacy System ID"
                                    label="Patient Pharmacy System ID"
                                />
                            </Col>
                            <Col md={3}>
                                <SelectField
                                    label="Dispense Status"
                                    placeholder="Dispense Status"
                                    fieldName="dispenseStatus"
                                    options={dispsenseStatusQuery.data}
                                    onChange={(option) => {
                                        setFieldValue('dispenseStatus', option);
                                        setFieldValue('dispenseStatusReason', null);
                                    }}
                                />
                            </Col>
                            {R.path(['dispenseStatus', 'label'], values) === 'Discontinued' ? (
                                <Col md={3}>
                                    <SelectField
                                        label="Dispense Status Reason"
                                        placeholder="Dispense Status Reason"
                                        fieldName="dispenseStatusReason"
                                        options={dispsenseStatusReasonQuery.data}
                                    />
                                </Col>
                            ) : null}
                        </Row>

                        <Row>
                            <Col md={3}>
                                <TextField
                                    label="Patient HUB Program ID"
                                    fieldName="hubProgramId"
                                    placeholder="Patient HUB Program ID"
                                />
                            </Col>
                        </Row>

                        <FormButtonGroup>
                            <ResetButton>Reset</ResetButton>
                            <SubmitButton>Save</SubmitButton>
                        </FormButtonGroup>
                    </form>
                );
            }}
        </Formik>
    );
}

ReferralInformationForm.propTypes = propTypes;
ReferralInformationForm.defaultProps = defaultProps;

export default ReferralInformationForm;
