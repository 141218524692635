import MersenneTwister from 'mersennetwister';

/** @ngInject */
function IdService(_, $q) {
    return {
        createId,
    };

    function createId() {
        return $q.when().then(() => {
            const mt = new MersenneTwister();
            const time = _.round(new Date().getTime() / 1000).toString(16);
            const random = (mt.rnd() * 1000000000000000000).toString(16);
            return (time + random).substr(0, 18);
        });
    }
}

export default IdService;
