import React from 'react';
import {PERMISSION_TYPES} from "App/utils/isPermitted";
import Page from "App/components/Page";
import Builder from './Builder'

const propTypes = {};

const defaultProps = {};

function ViewProtocolTemplates() {
    return (
        <Page title="Protocol Template Builder" showPageTitle permissionType={PERMISSION_TYPES.CLINICAL_ADMIN}>
            <Builder/>
        </Page>
    )
}

ViewProtocolTemplates.propTypes = propTypes;
ViewProtocolTemplates.defaultProps = defaultProps;

export default ViewProtocolTemplates;
