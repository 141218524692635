function TherigySpinnerController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;

    function $onChanges() {}

    function $onInit() {}
}

export default TherigySpinnerController;
