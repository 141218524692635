import createAxios from 'App/utils/createAxios';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';

const { protocol, host, port } = window.location;
const therigyURL = `${protocol}//${host}${port ? `:${port}` : ''}`;
const phpRequest = createAxios({
    baseURL: `${therigyURL}/app.api.php/api`,
});

/**
 * get therapy list with respect to medication
 * @returns therapy list
 */
function getPdxPatient(pdxId) {
    return phpRequest.get(`/patient/pdx/${pdxId}`).then(convertKeysToCamelCase);
}

export { getPdxPatient };
