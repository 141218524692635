import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { TextField } from 'Lib/form/TextField';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';
import * as Yup from 'yup';
import { Alert } from 'react-bootstrap';
import stripAllHtml from 'App/utils/stripAllHtml';
import AuthService from 'App/services/AuthService';

const propTypes = {};

const defaultProps = {};

const schema = Yup.object().shape({
    email: Yup.string('Please enter an email address.')
        .email('Please enter a valid email address.')
        .required('Please enter an email address.')
        .label('Email'),
});

function Login() {
    const [hash, params] = window.location.hash.split('?');

    const search = new URLSearchParams(params);
    const message = search.get('message');

    /**
     * Remove message query
     */
    useEffect(() => {
        window.location.hash = hash;
    }, [params, hash]);

    return (
        <Formik
            validationSchema={schema}
            initialValues={{
                email: '',
            }}
            onSubmit={(values) => {
                return Promise.resolve()
                    .then(() => AuthService.getSsoUrl(values))
                    .then((ssoLoginUrl) => {
                        window.location.assign(ssoLoginUrl);
                    });
            }}
        >
            {({ handleSubmit, isSubmitting }) => {
                return (
                    <form onSubmit={handleSubmit} className="login-form" name="login" noValidate>
                        <div className="login-form-container-sso-only">
                            <div className="email form-group">
                                <TextField
                                    fieldName="email"
                                    required
                                    label="Email"
                                    placeholder="Email"
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>

                        {message && !isSubmitting ? (
                            <Alert bsStyle="danger">
                                <div id="form-error-message">{stripAllHtml(message)}</div>
                            </Alert>
                        ) : null}

                        <FormButtonGroup>
                            <SubmitButton id="sign-in-btn">Next</SubmitButton>
                        </FormButtonGroup>
                    </form>
                );
            }}
        </Formik>
    );
}

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

export default Login;
