import { useMutation, useQueryClient } from 'react-query';
import { updateReferral } from '@/app/services/RMSService';
import errorHandler from '@/app/utils/errorHandler';

function useUpdateReferralMutation(patientId, referralId) {
    const queryClient = useQueryClient();

    return useMutation((data) => updateReferral(patientId, referralId, data), {
        onSuccess: async (referral) => {
            await queryClient.setQueryData(['patient', patientId, 'referral', referralId], referral);
        },
        onError: errorHandler,
    });
}

export default useUpdateReferralMutation;
