/** @ngInject */
function MoneyInputController(_, $filter) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doFormatNumber = doFormatNumber;
    ctrl.doUnformatNumber = doUnformatNumber;

    function $onChanges(changes) {
        if (changes.ngDisabled) {
            ctrl.ngDisabled = angular.copy(ctrl.ngDisabled);
        }
    }

    function $onInit() {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        ctrl.model.$render = function () {
            ctrl.displayValue = ctrl.doFormatNumber(ctrl.model.$viewValue);
        };
    }

    function doFormatNumber(value) {
        let whole;
        let decimal;
        let split;
        if (!value) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            value = _.isNumber(value) ? value : undefined;
            ctrl.model.$setViewValue(value);
            return value;
        }
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        value = _.toString(value);
        if (value.indexOf('.') !== -1) {
            // If the user entered their own decimal we use their placement.
            split = value.split('.');
            whole = split[0] ? split[0].replace(/[^0-9]/g, '') : 0;
            decimal = _.padEnd(split[1], 2, 0);
        } else {
            // If the user did not enter a decimal place we use the last two digits.
            whole = value.replace(/[^0-9]/g, '');
            decimal = '00';
        }
        ctrl.model.$setViewValue(ctrl.doUnformatNumber(`${whole}.${decimal}`));
        return $filter('number')(`${whole}.${decimal}`, 2);
    }

    function doUnformatNumber(value) {
        if (value) {
            return value.replace(/(?!\.)([^0-9])/g, '');
        }
        return _.isNumber(value) ? value : undefined;
    }
}

export default MoneyInputController;
