import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import { ActionButtonLink } from '@/app/components/styles/ActionCell';
import useIsPermitted from '@/app/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from '@/app/utils/isPermitted';
import { patientPropType, patientSubscriptionPropType } from 'Lib/types';
import OneTimeTextMessageModal from '../PatientMessagingEnrollmentModal/OneTimeTextMessageModal';
import PatientMessagingEnrollmentModal from '../PatientMessagingEnrollmentModal/PatientMessagingEnrollmentModal';
import PatientMessagingHistoryModal from '../PatientMessagingEnrollmentModal/PatientMessagingHistoryModal';
import PatientMessagingUnenrollmentModal from '../PatientMessagingEnrollmentModal/PatientMessagingUnenrollmentModal';
import SendOneTimeTextModal from '../PatientMessagingEnrollmentModal/SendOneTimeTextModal';

const PatientEngagement = ({
    patientDemographicsData,
    patientMessagingSubscription,
    refetchMessagingSubscriptions,
}) => {
    const [showPatientMessagingModal, setShowPatientMessagingModal] = useState(false);
    const [showPatientMessagingUnenrollmentModal, setShowPatientMessagingUnenrollmentModal] = useState(false);

    const [showMessagingHistoryModal, setShowMessagingHistoryModal] = useState(false);
    const [showOneTimeMessage, setShowOneTimeMessage] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    // flag to if the user has permission to access patient messaging activity text schedule
    const hasEnabledMassTexting = useIsPermitted(PERMISSION_TYPES.MASS_TEXTING);

    const { mobilePhone, id: patientId, divisionId } = patientDemographicsData;
    const subscribedOnDate = patientMessagingSubscription?.subscribedOn;
    const unsubscribedOnDate = patientMessagingSubscription?.unsubscribedOn;
    const confirmed = patientMessagingSubscription?.confirmed;
    const tooltip = (
        <Tooltip id="actions-tooltip">
            Educational content and digital communication services available for the patient
        </Tooltip>
    );
    const isMessagingOptedIn = confirmed === 0 && !subscribedOnDate && !unsubscribedOnDate;
    const isMessagingSubscribed = subscribedOnDate && !unsubscribedOnDate;
    const isMessagingOptedOut = unsubscribedOnDate;
    return (
        <div data-testid="patient-engagement">
            <span className="engage-label">Patient Engagement</span>
            <OverlayTrigger trigger={['hover', 'focus']} overlay={tooltip}>
                <i className="fas fa-fw fa-question-circle" />
            </OverlayTrigger>
            <div>
                <Row>
                    <Col sm={7}>
                        {patientMessagingSubscription ? (
                            <>
                                <i
                                    className={cx('fas fa-fw', {
                                        'fa-check text-success': !isMessagingOptedOut,
                                        'fa-times text-danger': isMessagingOptedOut,
                                    })}
                                />
                                <span>
                                    Patient Messaging:{' '}
                                    <span id="engage-patient-messaging-enrollment-status">
                                        {isMessagingOptedIn ? `Not Yet Enrolled` : ``}
                                        {isMessagingSubscribed ? `Enrolled` : ``}
                                        {isMessagingOptedOut && 'Opted-out'}
                                    </span>
                                    {' |'}
                                </span>
                            </>
                        ) : (
                            ''
                        )}
                        {patientMessagingSubscription && (
                            <ActionButtonLink onClick={() => setShowPatientMessagingModal(true)}>Edit</ActionButtonLink>
                        )}
                        {!patientMessagingSubscription || isMessagingOptedOut ? (
                            <ActionButtonLink onClick={() => setShowPatientMessagingModal(true)}>
                                Enroll Patient
                            </ActionButtonLink>
                        ) : (
                            <ActionButtonLink onClick={() => setShowPatientMessagingUnenrollmentModal(true)}>
                                Unenroll Patient
                            </ActionButtonLink>
                        )}
                    </Col>
                    <Col sm={5} style={{ paddingLeft: 0 }}>
                        {patientMessagingSubscription && (
                            <div>
                                <ActionButtonLink onClick={() => setShowMessagingHistoryModal(true)}>
                                    <i
                                        className="fa fa-fw fa-history"
                                        id="view-engage-patient-messaging-history-link"
                                    />{' '}
                                    View Patient Messaging History
                                </ActionButtonLink>
                            </div>
                        )}
                        {patientMessagingSubscription?.status && hasEnabledMassTexting && (
                            <div>
                                <ActionButtonLink
                                    name="sendOneTimeTextMessage"
                                    onClick={() => setShowOneTimeMessage(true)}
                                >
                                    <i className="fa fa-mobile" id="send-one-time-text-message-link" /> Send One-Time
                                    Text Message
                                </ActionButtonLink>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
            {showPatientMessagingUnenrollmentModal && (
                <PatientMessagingUnenrollmentModal
                    mobilePhone={mobilePhone}
                    subscriptionValue={patientMessagingSubscription}
                    patientId={patientId}
                    show={showPatientMessagingUnenrollmentModal}
                    onHide={(wasSuccess = false) => {
                        setShowPatientMessagingUnenrollmentModal(false);
                        if (wasSuccess) {
                            refetchMessagingSubscriptions();
                        }
                    }}
                />
            )}
            {showPatientMessagingModal && (
                <PatientMessagingEnrollmentModal
                    mobilePhone={mobilePhone}
                    subscriptionValue={patientMessagingSubscription}
                    patientId={patientId}
                    show={showPatientMessagingModal}
                    onHide={(wasSuccess = false) => {
                        setShowPatientMessagingModal(false);
                        if (wasSuccess) {
                            refetchMessagingSubscriptions();
                        }
                    }}
                />
            )}
            {showMessagingHistoryModal && (
                <PatientMessagingHistoryModal
                    show={showMessagingHistoryModal}
                    onHide={() => setShowMessagingHistoryModal(false)}
                    patientId={patientId}
                    divisionId={divisionId}
                />
            )}
            <OneTimeTextMessageModal
                show={showOneTimeMessage}
                onClose={(template) => {
                    setShowOneTimeMessage(false);
                    if (template) {
                        setSelectedTemplate(template);
                    }
                }}
            />
            {patientId && (
                <SendOneTimeTextModal
                    patientId={patientId}
                    onClose={() => {
                        setSelectedTemplate(null);
                    }}
                    selectedTemplate={selectedTemplate}
                />
            )}
        </div>
    );
};

PatientEngagement.propTypes = {
    patientDemographicsData: patientPropType.isRequired,
    patientMessagingSubscription: patientSubscriptionPropType,
    refetchMessagingSubscriptions: PropTypes.func.isRequired,
};

PatientEngagement.defaultProps = {
    patientMessagingSubscription: null,
};

export default PatientEngagement;
