import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';

function useFetchPatientOtherMedications(patientId) {
    return useQuery(
        ['patient', patientId, 'otherMedications'],
        () => (!patientId ? null : PESService.getPatientOtherMedication(patientId)),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchPatientOtherMedications;
