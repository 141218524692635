import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import LoadingSpinner from 'App/components/TherigyLoadingSpinner';

const propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
    width: '100%',
};

const SpinnerStyle = styled('div')`
    position: absolute;
    background: rgb(255 255 255 / 47%);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function LoadSpinnerOverlay({ width }) {
    return (
        <SpinnerStyle style={{ width }}>
            <LoadingSpinner />
        </SpinnerStyle>
    );
}

LoadSpinnerOverlay.propTypes = propTypes;
LoadSpinnerOverlay.defaultProps = defaultProps;

export default LoadSpinnerOverlay;
