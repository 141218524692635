/** @ngInject */
function PatientWelcomePacketController(_, $filter, $scope) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeValue = doChangeValue;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;

    function $onChanges(changes) {
        if (changes.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
        }
    }

    function doChangeValue() {
        if (ctrl.patient.welcome_packet === true) {
            const update = {
                welcome_packet: ctrl.patient.welcome_packet,
                welcome_packet_returned: ctrl.patient.welcome_packet_returned,
                welcome_packet_sent: ctrl.patient.welcome_packet_sent,
            };

            if (_.isDate(update.welcome_packet_returned)) {
                update.welcome_packet_returned = $filter('date')(update.welcome_packet_returned, 'yyyy/MM/dd');
            }

            if (_.isDate(update.welcome_packet_sent)) {
                update.welcome_packet_sent = $filter('date')(update.welcome_packet_sent, 'yyyy/MM/dd');
            }

            $scope.$emit('addPatientPage_updatePatient', update);
        } else {
            $scope.$emit('addPatientPage_updatePatient', {
                welcome_packet: undefined,
                welcome_packet_returned: undefined,
                welcome_packet_sent: undefined,
            });
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
    }
}

export default PatientWelcomePacketController;
