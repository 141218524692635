import React from 'react';
import QueryClientWrapper from 'Lib/QueryClientWrapper';
import { UserContextProvider } from 'App/contexts/UserContext';
import ReferralInformationForm from './ReferralInformationForm';
import { HashRouter, Route } from 'react-router-dom';
import FeatureFlags from "App/components/FeatureFlags";

const propTypes = {};

const defaultProps = {};

function ReferralInformationWrapper() {
    return (
        <QueryClientWrapper>
            <FeatureFlags>
                <HashRouter hashType="hashbang">
                    <UserContextProvider>
                        <Route path="/patient/:patientId/referral/:referralId">
                            <ReferralInformationForm />
                        </Route>
                    </UserContextProvider>
                </HashRouter>
            </FeatureFlags>
        </QueryClientWrapper>
    );
}

ReferralInformationWrapper.propTypes = propTypes;
ReferralInformationWrapper.defaultProps = defaultProps;

export default ReferralInformationWrapper;
