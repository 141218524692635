function downloadFileByBlob(fileResponse, filename) {
    const blobData = new Blob([fileResponse], {
        type: 'application/pdf',
    });
    const blobUrl = window.URL.createObjectURL(blobData);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();

    // Cleanup
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
}

export default downloadFileByBlob;
