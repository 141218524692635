/** @ngInject */
function ReferralActivityFinancialAssistanceConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.referralAetnaActivityFinancialAssistance',
        url: '/patient/:patientId/referral/:referralId/aetna-financial-assistance/:activityId/type/:typeId',
        views: {
            '': 'referralAetnaActivityFinancialAssistance',
            'pageTitle@^': {
                template: '{{$ctrl.activity.name}}',
            },
        },
        resolve: {
            activity($transition$, rmsService) {
                const params = $transition$.params();
                return rmsService
                    .getActivityByType(params.patientId, params.referralId, params.activityId, params.typeId)
                    .catch(() => {
                        return false;
                    });
            },
            patient($transition$, patientService) {
                const params = $transition$.params();
                return patientService.get(params.patientId).catch(() => {
                    return false;
                });
            },
            pendingStatusReasons(aetnaActivityService) {
                return aetnaActivityService.get('financial_assistance_pending_status_reason').catch(() => {
                    return false;
                });
            },
            completeStatusReasons(aetnaActivityService) {
                return aetnaActivityService.get('financial_assistance_complete_status_reason').catch(() => {
                    return false;
                });
            },
        },
        redirectTo(trans) {
            return {
                state: 'app.referralActivityFinancialAssistance',
                params: trans.params(),
            };
        },
    });
}

export default ReferralActivityFinancialAssistanceConfig;
