import * as R from 'ramda';
import { useMutation, useQueryClient } from 'react-query';
import { deleteProtocolTemplate } from 'App/services/ProtocolTemplateService';

const useDeleteProtocolTemplateMutation = () => {
    const queryClient = useQueryClient();

    return useMutation((uuid) => deleteProtocolTemplate(uuid), {
        onSuccess: (template) => {
            return queryClient.setQueryData(['protocol', 'templates'], R.reject(R.propEq(template.uuid, 'uuid')));
        },
    });
};

export default useDeleteProtocolTemplateMutation;
