import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * Simple abstraction over whether a date is expired.
 * @param date - The expired on date.
 * @returns {boolean} True if today is after the expired on date.
 */
const isExpired = (date) => {
    if (date) {
        // Did it get ended today?
        if (dayjs().format('YYYY-MM-DD') === dayjs.utc(date).format('YYYY-MM-DD')) {
            return false;
        }

        return dayjs().isAfter(date);
    }
    return false;
};

export default isExpired;
