import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ActionCell from 'App/components/styles/ActionCell';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            patientId: PropTypes.string.isRequired,
            id: PropTypes.number,
        }),
    }).isRequired,
};

const defaultProps = {};

function ReferralActionsCell({ row }) {
    const { original: referral } = row;
    return (
        <ActionCell>
            <Link to={`/patient/${referral.patientId}/referral/${referral.id}`}>Edit</Link>
        </ActionCell>
    );
}

ReferralActionsCell.propTypes = propTypes;
ReferralActionsCell.defaultProps = defaultProps;

export default ReferralActionsCell;
