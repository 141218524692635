/** @ngInject */
function ActivePatientFieldsConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.activePatientFields',
        url: '/active-patient-fields',
        views: {
            $default: 'page',
            '$default.title': {
                template: 'Active Patient Fields',
            },
            '$default.content': 'activePatientFields',
        },
    });
}

export default ActivePatientFieldsConfig;
