import PropTypes from 'prop-types';
import React from 'react';

const ScrollToElementLink = ({ elementId, children }) => {
    return (
        <a
            href={`#${elementId}`}
            onClick={(e) => {
                e.preventDefault();
                document.getElementById(elementId).scrollIntoView({ behavior: 'smooth' });
            }}
        >
            {children}
        </a>
    );
};

ScrollToElementLink.propTypes = {
    elementId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default ScrollToElementLink;
