import React, { useState } from 'react';
import { Alert, Button, Col, Form, Grid, Row } from 'react-bootstrap';
import axios from 'axios';

import FileUpload, { PageHeader, Sidebar, Blockquote, HighlightSpan, validateFiles } from './FileUpload';

import UploadTemplate from '../../../public/physician_upload_template.xlsm';

const PhysicianUpload = () => {
    const [formValid, setFormValid] = useState(false);
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);

    const onChangeFiles = (newFiles) => {
        const filesValidated = validateFiles(newFiles);
        if (filesValidated) {
            if (error) {
                setError(null);
            }
            setFormValid(true);
            setFiles(newFiles);
        } else {
            setFormValid(false);
            setFiles([]);
        }
        return null;
    };

    /**
     * Submit/Upload file event handler
     * @param {Event} event
     */
    const sendFileUploadRequest = async (event) => {
        event.preventDefault();

        const [file] = files;
        if (formValid && files && files.length) {
            if (file) {
                // Since the PHP api requires a `FormData` object, format and append the file and options
                // in a `new FormData()`
                const formData = new FormData();
                formData.append('form_data', file);

                const url = '/stm/physician_upload.php';
                const headers = {
                    'Content-Type': 'multipart/form-data; charset=UTF-8',
                };
                // TODO: Create a "Upload" api module to avoid using axios or fetch directly in
                //       this event handler and to enable mock-ability of this feature.
                const response = await axios.post(url, formData, {
                    withCredentials: true,
                    headers,
                });

                const { data } = response;
                if (data.message) {
                    // TODO: Since the message received here is a HTML string, create and apply a
                    //       HTML sanitization before setting the state.
                    const errorMessage = { message: data.message };
                    if (data.messageType) {
                        errorMessage.messageType = data.messageType;
                    }
                    setError(errorMessage);
                }
            }
        }
    };

    return (
        <Grid fluid>
            <Row>
                <PageHeader>Upload Physicians</PageHeader>
                <Col xs={2} style={{ padding: 0 }}>
                    <Sidebar>
                        <h3>Helpful Hints</h3>

                        <div>
                            <ol>
                                <li>Any field in red denotes it is required and must contain physician data</li>
                                <li>
                                    The specialty listed for the physician must be typed exactly how it presents within
                                    TherigySTM
                                </li>
                                <li>
                                    This feature does not block duplicate physicians from being uploaded if a physician
                                    with the same first name, last name, and zip code already exists in TherigySTM
                                </li>
                            </ol>
                        </div>
                    </Sidebar>
                </Col>
                <Col xs={10}>
                    {error && error.message && (
                        <Alert bsStyle={error.messageType || 'danger'}>
                            <div
                                id="user-message"
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: error.message,
                                }}
                            />
                        </Alert>
                    )}
                    <p>
                        The first row (in purple) contains helpful information to assist in populating the template:
                        this row must be deleted prior to uploading the file. The second row contains the data
                        identification fields available for upload: the text in this row must not be changed.
                    </p>
                    <Button bsStyle="primary" href={UploadTemplate} download="physicians_upload_template.xlsm">
                        Download Template
                    </Button>
                    <Blockquote>
                        <HighlightSpan>physician_first_name, physician_last_name, </HighlightSpan>
                        physician_contact_person_first_name, physician_contact_person_last_name, physician_address,
                        physician_address_2, physician_city, physician_state, physician_zip_code,
                        physician_phone_number, physician_fax_number, physician_contact_person_phone_number,
                        physician_contact_person_fax_number,
                        <HighlightSpan> npi, </HighlightSpan>
                        specialty, physician_group_id, medicaid_id, dea, state_license, hospital_id, pharma_hub_id,
                        ims_id, affiliation, practice_name, notes
                    </Blockquote>
                    <Form>
                        <FileUpload
                            onFiles={onChangeFiles}
                            accept="text/csv','application/vnd.ms-excel"
                            id="upload_file"
                        />
                        <Button id="submit" bsStyle="primary" disabled={!formValid} onClick={sendFileUploadRequest}>
                            Upload Physicians
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Grid>
    );
};

export default PhysicianUpload;
