function OptOutTherapyController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.submit = submit;

    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.therapy) {
                ctrl.therapy = angular.copy(ctrl.resolve.therapy);

                // Set the minimum/maximum dates that the user can opt out of therapy.
                if (ctrl.therapy.status && ctrl.therapy.status.start) {
                    ctrl.minOptOutDate = ctrl.therapy.status.start;
                }
                ctrl.maxOptOutDate = new Date();
            }
        }
    }

    function submit() {
        ctrl.saving = true;
        if (ctrl.therapy.status) {
            ctrl.therapy.status.opt_in = false;
        } else {
            ctrl.therapy.status = {
                opt_in: false,
            };
        }

        ctrl.close({
            $value: ctrl.therapy,
        });
    }
}

export default OptOutTherapyController;
