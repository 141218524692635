import { camelCase, snakeCase } from 'lodash';
import { getDefaultWorkQueueState } from './defaultWorkQueueState';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';
import { toDate } from 'App/services/DateService';

const mismatchKeys = [
    'allBranches',
    'allCategories',
    'allTypes',
    'filterActivityName',
    'filterActivityStatus',
    'filterAssignedTo',
    'filterDateDueSearch',
    'filterHumanId',
    'filterMedication',
    'filterNeedsBySearch',
    'filterPatientDob',
    'filterPatientLabels',
    'filterPatientName',
    'filterUpdatedName',
];

const defaultWorkQueueState = getDefaultWorkQueueState();

export const convertFilterToViewRecord = (filter) => {
    const updatedFilter = { ...defaultWorkQueueState.filterParams, ...filter };
    Object.keys(filter).forEach((filterKey) => {
        if (mismatchKeys.includes(camelCase(filterKey))) {
            updatedFilter[camelCase(filterKey)] = filter[filterKey];
            delete updatedFilter[filter];
        }
    });
    delete updatedFilter.group_ids;
    delete updatedFilter.dateRange;
    return {
        ...updatedFilter,
        type: filter.dateRange,
        branches: Object.keys(filter.branches || {}),
        categories: Object.keys(filter.group_ids || {}),
        filters: Object.keys(filter.filters || {}),
        dateEnd: toDate(updatedFilter.dateEnd),
        dateStart: toDate(updatedFilter.dateStart),
    };
};

export const convertFilterToDatabaseRecord = (filter) => {
    const updatedFilter = { ...filter };
    Object.keys(filter).forEach((filterKey) => {
        if (mismatchKeys.includes(filterKey)) {
            updatedFilter[snakeCase(filterKey)] = filter[filterKey];
            delete updatedFilter[filter];
        }
    });
    delete updatedFilter.categories;
    delete updatedFilter.type;
    return {
        ...updatedFilter,
        dateRange: filter.type,
        group_ids: filter.categories.reduce((acc, it) => {
            acc[it] = true;
            return acc;
        }, {}),
        branches: filter.branches.reduce((acc, it) => {
            acc[it] = true;
            return acc;
        }, {}),
        filters: filter.filters.reduce((acc, it) => {
            acc[it] = true;
            return acc;
        }, {}),
    };
};

export const processWorkQueueResult = (records) => {
    return records.map((record) => ({
        ...convertKeysToCamelCase(record),
        patientName: `${record.patientLastName}, ${record.patientFirstName}`,
        highRisk: record.high_risk === '1',
    }));
};
