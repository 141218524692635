import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import * as R from 'ramda';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
    }).isRequired,
};

const defaultProps = {};

function IncludeCell({ row }) {
    const { values, setFieldValue } = useFormikContext();

    const rules = R.propOr({}, 'rules', values);
    const item = row.original;
    const { id } = item;
    const isSelected = R.compose(Boolean, R.prop(id))(rules);
    return (
        <Checkbox
            aria-label="include-protocol"
            checked={isSelected}
            onChange={() => {
                if (isSelected) {
                    setFieldValue('rules', R.dissoc(id, rules));
                } else {
                    setFieldValue('rules', R.assoc(id, item, rules));
                }
            }}
        />
    );
}

IncludeCell.propTypes = propTypes;
IncludeCell.defaultProps = defaultProps;

export default IncludeCell;
