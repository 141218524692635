/** @ngInject */
function AssessmentQuestionNeedByDateController($rootScope, $scope, moment, dateService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeAnswer = doChangeAnswer;

    const setNeedsByDate = $rootScope.$on('setNeedsByDate', onSetNeedsByDate);

    // Unbind the listener when the controller scope is destroyed
    $scope.$on('$destroy', setNeedsByDate);

    function $onChanges(changes) {
        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);

            if (ctrl.question.response !== undefined) {
                ctrl.response = moment(ctrl.question.response, 'YYYY-MM-DDTHH:mm:ss').toDate();
                ctrl.needByDateValue = moment(ctrl.question.response, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            }
        }
    }

    function doChangeAnswer() {
        ctrl.onChangeAnswer({
            answer: dateService.toLocalISODate(ctrl.response),
        });
    }

    function onSetNeedsByDate(event, date) {
        if (ctrl.response === undefined) {
            ctrl.response = new Date(date);
            ctrl.response.setDate(ctrl.response.getDate() + 7);
            ctrl.needByDateValue = moment(ctrl.response, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            doChangeAnswer();
        }
    }
}

export default AssessmentQuestionNeedByDateController;
