import React, { useMemo, useState } from 'react';
import UnassignedMedicationsModal from './UnassignedMedicationsModal';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import hasLength from 'App/utils/hasLength';
import { Button } from 'react-bootstrap';
import useGetTherapy from 'App/hooks/useGetTherapy';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';
import useFetchUnassignedMedications from 'App/hooks/useFetchUnassignedMedications';
import { patientPropType } from 'Lib/types';

const propTypes = {
    patient: patientPropType.isRequired,
    patientId: PropTypes.string.isRequired,
    onHide: PropTypes.func,
};

const defaultProps = {
    onHide: Function,
};

const UnassignedMedicationsBanner = ({ patient, patientId, onHide }) => {
    const { data: patientWithUnassignedMeds, isLoading, refetch } = useFetchUnassignedMedications(patientId);
    const [showUnassignedMedicationsModal, setShowUnassignedMedicationsModal] = useState(false);
    const { data: availableTherapies } = useGetTherapy();

    const unassignedMeds = useMemo(() => {
        if (availableTherapies) {
            const therapiesAvailable = R.compose(
                R.fromPairs,
                R.map(([k, v]) => [k.toLowerCase(), v]),
                R.toPairs
            )(availableTherapies);

            if (hasLength(patientWithUnassignedMeds?.unassignedMedications)) {
                const medications = { ...patientWithUnassignedMeds };
                medications.unassignedMedications = medications.unassignedMedications.map((unAssignedMed) => {
                    const therapies =
                        therapiesAvailable[unAssignedMed?.medicationName.toLowerCase()]?.map(convertKeysToCamelCase);

                    if (therapies) {
                        return R.assoc('therapies', therapies, unAssignedMed);
                    }
                    return unAssignedMed;
                });
                return medications;
            }
        }
        return null;
    }, [availableTherapies, patientWithUnassignedMeds]);

    if (!hasLength(patientWithUnassignedMeds?.unassignedMedications) || isLoading) {
        return null;
    }

    const { firstName, lastName } = patientWithUnassignedMeds;

    return (
        <>
            <div className="alert bg-danger" role="alert">
                <div>
                    <strong>{`${firstName} ${lastName}`}</strong>
                    has medications that are not assigned a therapeutic category. &nbsp;
                    <Button
                        bsStyle="link"
                        data-testid="unassigned-med-alert"
                        onClick={() => setShowUnassignedMedicationsModal(true)}
                    >
                        Assign Therapeutic Category
                    </Button>
                </div>
            </div>
            {showUnassignedMedicationsModal && unassignedMeds && (
                <UnassignedMedicationsModal
                    patient={patient}
                    show={showUnassignedMedicationsModal}
                    onHide={() => {
                        setShowUnassignedMedicationsModal(false);
                        onHide();
                        refetch();
                    }}
                    unassignedMedications={unassignedMeds}
                />
            )}
        </>
    );
};

UnassignedMedicationsBanner.propTypes = propTypes;
UnassignedMedicationsBanner.defaultProps = defaultProps;

export default UnassignedMedicationsBanner;
