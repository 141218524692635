import { useQuery } from 'react-query';
import { errorHandler } from '@/app/utils';
import { getAllAssignment } from '@/app/services/PatientMessagingService';
import { useState } from 'react';
import * as R from 'ramda';

const useActivityTextScheduleAssignmentQuery = (reqQuery) => {
    const [allAssignments, setAllAssignments] = useState(null);

    const formatData = (assesments) => {
        const listData = assesments.data.map((assesmentData) => ({
            ...assesmentData,
            company: assesmentData.companyId ? 'My Pharmacy' : 'Therigy',
            activityTextSchedulesUuid: assesmentData.activity.activityTextSchedulesUuid || '',
        }));
        return {
            data: listData,
            total: assesments.total,
        };
    };

    const query = useQuery(
        ['getAllAssignment', reqQuery],
        () => {
            // changing sorting order to fix sorting order (Therigy and My Pharmacy) created by column in manual table
            const requestBody = R.ifElse(
                R.propEq('company', 'sortKey'),
                R.evolve({
                    sortOrder: R.ifElse(R.equals('asc'), R.always('desc'), R.always('asc')),
                }),
                R.identity
            )(reqQuery);
            return getAllAssignment(requestBody);
        },
        {
            onError: errorHandler,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            initialData: { getAllAssesments: { data: [], total: 0 } },
            onSuccess: ({ getAllAssesments }) => {
                setAllAssignments(formatData(getAllAssesments));
            },
        }
    );

    return {
        ...query,
        allAssignments: allAssignments?.data,
        total: allAssignments?.total,
    };
};

export default useActivityTextScheduleAssignmentQuery;
