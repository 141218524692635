/** @ngInject */
function PasswordManagerConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.passwordManager',
        url: '/password-manager',
        views: {
            '': 'passwordManager',
            'pageTitle@^': {
                template: 'Password Manager',
            },
        },
    });
}

export default PasswordManagerConfig;
