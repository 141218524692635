import angular from 'angular';

import ReferralManagerSettingsController from './referral-manager-settings.controller';
import ReferralManagerSettingsConfig from './referral-manager-settings.config';
import ReferralManagerSettingsAddRxStatusRuleController from './add-rx-status-rule/add-rx-status-rule.component';

export default angular
    .module('components.referralManagerSettings', [])
    .component('referralManagerSettings', {
        bindings: {
            user: '<',
        },
        controller: ReferralManagerSettingsController,
        template: require('./referral-manager-settings.html'),
    })
    .config(ReferralManagerSettingsConfig)
    .component('referralManagerSettingsAddRxStatusRule', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: ReferralManagerSettingsAddRxStatusRuleController,
        template: require('./add-rx-status-rule/add-rx-status-rule.html'),
    }).name;
