import { useQuery } from 'react-query';
import { getMessageTemplates } from 'App/services/PatientMessagingService';

const useMessageTemplates = () => {
    return useQuery(['messageTemplates'], getMessageTemplates, {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        initialData: [],
    });
};

export default useMessageTemplates;
