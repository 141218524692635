/** @ngInject */
function AssessmentPreviewController(_, _paq, __env, $, $scope, $window, amsService, moment) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.isClinicalAndPcc = function () {
        return true;
    };
    ctrl.generatePdf = generatePdf;
    ctrl.isModalOpen = false;
    ctrl.cdnURL = __env.cdnURL;

    // There functions must exist but no nothing in the assessment preview.
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.doChangeSummaryNote = function () {};
    ctrl.doSaveResponse = doSaveResponse;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.openDeleteModal = function () {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.pendAssessment = function () {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.saveSummaryNote = function () {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.submitAssessmentForm = function () {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.updateAssessmentQuestions = function () {};

    $scope.$on('saveAssessmentQuestionResponse', doSaveResponse);
    $scope.$on('updateAssessmentQuestions', updateAssessmentQuestions);

    function $onChanges(changes) {
        if (changes.assessment && ctrl.assessment) {
            ctrl.assessment = angular.copy(ctrl.assessment);
            ctrl.activity = {
                id: 'preview',
                name: ctrl.assessment.name,
                assessment: ctrl.assessment,
            };

            ctrl.patient = {
                city: 'Orlando',
                firstName: 'patient',
                lastName: 'preview',
                state: 'FL',
                zip: '12345',
            };
        }

        if (changes.assessmentQuestions && ctrl.assessmentQuestions) {
            // Manipulate the response before we create any one-time bindings
            amsService.formatRapid3Questions(ctrl.assessmentQuestions);
            getScores(ctrl.assessment.scoring, ctrl.assessmentQuestions);
            // Flat question list for storing state
            ctrl.questionList = angular.copy(ctrl.assessmentQuestions);
            // Nested question list for display
            ctrl.allQuestions = angular.copy(amsService.attachChildren(ctrl.assessmentQuestions));
            // Remove any hidden or disable questions and split them into their respective groups/sections
            let questions = amsService.formatQuestions(ctrl.allQuestions);
            questions = amsService.splitQuestions(questions);
            ctrl.questions = angular.copy(questions.questions);
            ctrl.specialQuestions = angular.copy(questions.specialQuestions);
            ctrl.therapySummaryNote = questions.therapySummaryNote.length > 0;
            ctrl.groupedQuestions = angular.copy(questions.groupedQuestions);
            const groupIds = _.keys(questions.groupedQuestions);
            const questionGroups = _.values(amsService.formatQuestionGroups(groupIds));
            ctrl.questionGroups = _.remove(questionGroups, (g) => {
                return groupIds.indexOf(g.id.toString()) !== -1;
            });
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            ctrl.fullyScriptedMode = ctrl.user.verbose === 1;
        }
    }

    function $onInit() {
        // Override getActivityQuestionsById to pull for the assessment instead.
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign, func-names
        amsService.getActivityQuestionsById = function (activityId, questionIds) {
            return amsService.getAssessmentQuestionsById(ctrl.assessment.id, questionIds);
        };
    }

    function generatePdf() {
        // hide UI
        $('#generate-pdf img').hide(0);
        $('#generate-pdf i').show(0);
        $('#walkme-player').hide();

        // grab body HTML
        const htmlString = document.body.outerHTML;

        return amsService.generatePdf(htmlString, 'preview', ctrl.assessment.name).finally(() => {
            $('#generate-pdf img').show(0);
            $('#generate-pdf i').hide(0);
            $('#walkme-player').show();
            _paq.push(['trackEvent', 'TherigyAssessments', 'Activity - Generate PDF']);
        });
    }

    function doSaveResponse(event, data) {
        if (!ctrl.questionList[data.question.id]) {
            ctrl.questionList[data.question.id] = data.question;
        }

        ctrl.questionList[data.question.id].response = data.response.response;
        if (data.unselectedAnswers) {
            removeQuestions(ctrl.questionList, data.unselectedAnswers);
        }
        ctrl.questionList = angular.copy(ctrl.questionList);

        getScores(ctrl.activity.assessment.scoring, ctrl.questionList);
    }

    function getScores(scoring, questions) {
        if (_.size(ctrl.activity.assessment.scoring) > 0) {
            ctrl.showScoring = true;
            const scores = amsService.getScores(scoring, questions);
            const followUpDate = new Date();

            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-restricted-syntax
            for (const p in scores) {
                if (Object.prototype.hasOwnProperty.call(scores, p)) {
                    scores[p].followUpDate = moment(followUpDate, 'YYYY/MM/DD')
                        .add(scores[p].followUp, 'days')
                        .toDate();
                }
            }

            // TODO: Rapid 3 hack
            // Handle the functional scoring
            const functionalScore = scores['15446'] || scores['15450'] || scores['15554'] || scores['15558'];
            if (functionalScore) {
                $scope.$broadcast('updateRapid3FunctionalScore', {
                    value: functionalScore.value,
                });
            }

            // TODO: Rapid 3 hack
            // Handle the total scoring
            const totalScore = scores['15447'] || scores['15451'] || scores['15555'] || scores['15559'];
            if (totalScore) {
                $scope.$broadcast('updateRapid3TotalScore', {
                    followUpDate: totalScore.followUpDate,
                    stratification: totalScore.stratification,
                    value: totalScore.value,
                });
            }

            // TODO: Rapid 3 hack
            delete scores['15446'];
            delete scores['15447'];
            delete scores['15450'];
            delete scores['15451'];
            delete scores['15554'];
            delete scores['15555'];
            delete scores['15558'];
            delete scores['15559'];
            ctrl.scores = scores;
        }
    }

    function removeQuestions(questions, answerIds) {
        const unansweredQuestions = _.filter(questions, (q) => {
            return answerIds.indexOf(q.parentAnswerId) !== -1;
        });

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = unansweredQuestions.length; i < len; i++) {
            const hasChildren = _.flatten(_.map(unansweredQuestions[i].answers, 'children'));
            if (hasChildren.length > 0) {
                const childAnswerIds = _.keys(unansweredQuestions[i].answers);
                removeQuestions(questions, childAnswerIds);
            } else {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                delete questions[unansweredQuestions[i].questionId];
            }
        }
    }

    function updateAssessmentQuestions(event, questions) {
        // TODO: Rapid 3 hack
        amsService.formatRapid3Questions(questions);
        ctrl.questionList = _.assignIn(ctrl.questionList, questions);
        ctrl.questionList = angular.copy(ctrl.questionList);
        ctrl.allQuestions = angular.copy(amsService.attachChildren(ctrl.questionList));
    }
}

export default AssessmentPreviewController;
