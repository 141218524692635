/** @ngInject */
function AuthRun($transitions, $state) {
    $transitions.onStart(
        {
            to(state) {
                return !!(state.data && state.data.requiredAuth);
            },
        },
        (trans) => {
            const authService = trans.injector().get('authService');
            if (!authService.get()) {
                const target = window.location.href;
                authService.setTargetPage(target);
                return $state.target('auth.login');
            }
        }
    );

    $transitions.onStart(
        {
            to(state) {
                return state.name.indexOf('auth') !== -1 && state.name !== 'auth.logout';
            },
        },
        (trans) => {
            const authService = trans.injector().get('authService');
            const $rootScope = trans.injector().get('$rootScope');
            $rootScope.page = 'auth';
            const token = authService.get();
            if (token) {
                // SSO logins do not hit the login.controller so we need to handle a deep link here.
                const targetPage = authService.getTargetPage();
                if (targetPage) {
                    // Clear the targetPage so that it doesn't affect future transitions.
                    authService.clearTargetPage();
                    window.location = targetPage;
                    return;
                }
                return $state.target('app');
            }
        }
    );
}

export default AuthRun;
