function ContentViewerDirective() {
    return {
        restrict: 'A',
        scope: {
            content: '=content',
        },
        link(scope, element) {
            if (scope.content) {
                tui.Editor.factory({
                    viewer: true,
                    usageStatistics: false,
                    el: element,
                    exts: ['table'],
                    height: '100%',
                    initialValue: scope.content,
                });
            }
        },
    };
}

export default ContentViewerDirective;
