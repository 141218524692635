import angular from 'angular';

import PasswordManagerController from './password-manager.controller';
import PasswordManagerConfig from './password-manager.config';

export default angular
    .module('components.passwordManager', [])
    .component('passwordManager', {
        bindings: {
            user: '<',
        },
        controller: PasswordManagerController,
        template: require('./password-manager.html'),
    })
    .config(PasswordManagerConfig).name;
