import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import pathOrDash from 'App/utils/pathOrDash';
import MedicationHistory from './MedicationHistory';
import dateOrDash from 'App/utils/dateOrDash';
import { NoteModalTypes } from 'App/components/PatientProfile/constants';
import AddNoteModal from './AddNoteModal';
import NoteListModal from './NoteListModal';
import ViewPatientPrescription from 'App/components/PatientPrescription/ViewPatientPrescription';

const propTypes = {
    patientMed: PropTypes.shape({
        medication: PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            displayName: PropTypes.string,
        }),
        ended: PropTypes.bool.isRequired,
        status: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
            endReason: PropTypes.string,
        }),
        id: PropTypes.string,
        patientId: PropTypes.string,
        isTreatmentHistory: PropTypes.bool,
        prescriptions: PropTypes.shape({
            rxNumber: PropTypes.string,
            strength: PropTypes.string,
            status: PropTypes.string,
            start: PropTypes.string,
            end: PropTypes.string,
            physician: PropTypes.shape({
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
            }),
        }),
    }).isRequired,
    showEndedMedication: PropTypes.bool.isRequired,
};

const defaultProps = {};

function TherapyMedications({ patientMed, showEndedMedication }) {
    const [showMedicationHistory, setShowMedicationHistory] = useState(false);
    const [showAddNote, setShowAddNote] = useState(false);
    const [showNoteList, setShowNoteList] = useState(false);
    const [noteModalData, setNoteModalData] = useState({});

    const handleViewRxNotes = useCallback((patientId, patientPrescriptions, rxIndex, medication) => {
        const { rxNumbers } = patientPrescriptions[medication.id];
        setNoteModalData({
            parentNoteId: rxNumbers[rxIndex]?.id,
            medicationId: medication.id,
            patientId,
            rxId: rxNumbers[rxIndex].rxNumber,
            noteType: NoteModalTypes.prescription,
        });
        setShowNoteList(true);
    }, []);

    const handleAddRxNotes = useCallback((patientId, prescription) => {
        setNoteModalData({
            parentNoteId: prescription?.id,
            medicationId: prescription.medication.id,
            patientId,
            rxId: prescription.rxNumber,
            noteType: NoteModalTypes.prescription,
        });
        setShowAddNote(true);
    }, []);

    const hideNoteModal = useCallback(() => {
        setNoteModalData({});
        setShowAddNote(false);
        setShowNoteList(false);
    }, []);

    return (
        <>
            <div key={patientMed.id} className={`medication-container ${patientMed?.ended ? 'medication-ended' : ''}`}>
                <Row>
                    <Col sm={3} md={3} className="medication-container-title">
                        Medication
                    </Col>
                    <Col sm={2} md={2} className="medication-container-title">
                        Medication Start Date
                    </Col>
                    <Col sm={2} md={2} className="medication-container-title">
                        Medication End Date
                    </Col>
                    <Col sm={2} md={2} className="medication-container-title">
                        End Reason
                    </Col>
                    <Col md={3} className="medication-action align-right">
                        <Button bsClass="btn-link" onClick={() => setShowMedicationHistory(true)}>
                            <i className="fa fa-fw fa-history" data-automation-id="view_therapy_medication_history" />
                            View Medication History
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3} md={3}>
                        <strong id={`patient-profile-medication-${patientMed.medication?.id}`}>
                            {patientMed.medication.displayName || patientMed.medication.name || '--'}
                        </strong>
                        {patientMed?.ended && <span className="medication-end-label"> - Ended</span>}
                    </Col>
                    <Col sm={2} md={2}>
                        {dateOrDash(['status', 'start'], patientMed)}
                    </Col>
                    <Col sm={2} md={2}>
                        {dateOrDash(['status', 'end'], patientMed)}
                    </Col>
                    <Col sm={2} md={2}>
                        {pathOrDash(['status', 'endReason'], patientMed)}
                    </Col>
                </Row>
                {/* Prescriptions are not to be shown for Treatment Histories */}
                {!patientMed.isTreatmentHistory && patientMed.prescriptions && (
                    <ViewPatientPrescription
                        patientPrescriptions={patientMed?.prescriptions}
                        medication={{
                            id: patientMed?.medication?.id,
                        }}
                        viewRxNumber
                        viewProfile
                        patientId={patientMed.patientId}
                        showEndedMedications={showEndedMedication}
                        handleViewRxNotes={handleViewRxNotes}
                        handleAddRxNotes={handleAddRxNotes}
                    />
                )}
            </div>
            {showMedicationHistory && (
                <MedicationHistory
                    medicationId={patientMed?.medication?.id}
                    medicationName={patientMed?.medication?.name}
                    patientId={patientMed?.patientId}
                    show={showMedicationHistory}
                    onHide={() => setShowMedicationHistory(false)}
                />
            )}
            {showAddNote && <AddNoteModal {...noteModalData} show={showAddNote} onHide={hideNoteModal} />}
            {showNoteList && <NoteListModal {...noteModalData} show={showNoteList} onHide={hideNoteModal} />}
        </>
    );
}

TherapyMedications.propTypes = propTypes;
TherapyMedications.defaultProps = defaultProps;

export default TherapyMedications;
