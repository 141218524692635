/** @ngInject */
function SSOConfig(__env, $stateProvider) {
    $stateProvider.state({
        component: 'sso',
        name: 'app.sso',
        url: '/sso/:company',
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        redirectTo($transition$) {
            const params = $transition$.params();
            const { company } = params;

            if (__env.ssoEndpoints[company]) {
                // If we have SSO config for this company redirect them.
                window.location.href = __env.ssoEndpoints[company];
            } else {
                // Redirect to our standard login if the company does not have an SSO config.
                return {
                    state: 'auth.login',
                };
            }
        },
    });
}

export default SSOConfig;
