import * as R from 'ramda';

const isPropString = (prop, value) => R.compose(R.is(String), R.prop(prop))(value);

/**
 * Extension of Ramda's `propEq`, but expects the value to be a string and compares them as lowercase.
 * @param {string} prop - The Prop to compare.
 * @param {string} compare - The string value that the prop should equal.
 * @param {object} value - The object that contains the prop.
 * @returns {Boolean} - True if the strings are equal
 */
function propLowerEq(prop, compare, value) {
    if (isPropString(prop, value)) {
        return R.compose(R.equals(R.toLower(compare)), R.toLower, R.prop(prop))(value);
    }
    return false;
}

export default R.curry(propLowerEq);
