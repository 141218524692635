/** @ngInject */
function VideoLibraryConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.videoLibrary',
        url: '/video-library',
        views: {
            '': 'videoLibrary',
            'pageTitle@^': {
                template: 'Video Library',
            },
        },
        data: {
            requiredAuth: false,
        },
    });
}

export default VideoLibraryConfig;
