import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import { FaFilter } from 'react-icons/fa';
import React from 'react';
import * as PropTypes from 'prop-types';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    groupName: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onClick: PropTypes.func,
};

const defaultProps = {};

function ConditionCell({ groupName, onClick }) {
    return (
        <td>
            <div className="condition-cell">
                <span className="condition-text">{groupName}</span>

                <ButtonGroup>
                    <Button
                        bsSize="sm"
                        className="add-filter-group"
                        aria-label="Add filter group"
                        title="Add filter group"
                        onClick={onClick}
                    >
                        <FaFilter />
                    </Button>
                </ButtonGroup>
            </div>
        </td>
    );
}

ConditionCell.propTypes = propTypes;
ConditionCell.defaultProps = defaultProps;

export default ConditionCell;
