import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import React, { useEffect, useRef } from 'react';
import { hasLength } from 'App/utils';
import SelectField from 'Lib/form/SelectField';
import useCreateMedicationAssociationMutation from 'App/hooks/useCreateMedicationAssociationMutation';
import useDeleteMedicationAssociationMutation from 'App/hooks/useDeleteMedicationAssociationMutation';
import PropTypes from 'prop-types';
import useMedAssociationProcessor from './hooks/useMedAssociationProcessor';
import { MED_ASSOCIATION_FIELD_NAME } from './constants';
import { queryClient } from 'Lib/queryClient';

const MedicationAssociationQuestionStyle = styled.div`
    min-width: 300px;
    max-width: 50%;
`;

const propTypes = {
    activityId: PropTypes.string,
    patientId: PropTypes.string,
    activityStatus: PropTypes.number,
    required: PropTypes.bool,
};

const defaultProps = {
    activityId: null,
    patientId: null,
    activityStatus: null,
    required: false,
};

const MedicationAssociation = ({ activityId, required, activityStatus, patientId }) => {
    const { setFieldValue } = useFormikContext();
    const hasRanAssignment = useRef(false);

    const createMedicationMutation = useCreateMedicationAssociationMutation(patientId, activityId);
    const deleteMedicationMutation = useDeleteMedicationAssociationMutation(patientId, activityId);

    //prettier-ignore
    const {
        availableOptions,
        groupedAvailableOptions,
        isLoading,
        selectedKeys,
        selectedOptions,
        singleMedKey,
        refetch,
    } = useMedAssociationProcessor(patientId, activityId);

    const isActivityComplete = activityStatus === 1;
    const isActivityDeleted = activityStatus === 2;

    /**
     * When there is only on possible option, associate it by default, but only do so once.
     */
    useEffect(() => {
        if (
            !hasLength(selectedOptions) && // don't run if we already have associations
            !hasRanAssignment.current && // don't run if we've already ran once
            createMedicationMutation.isIdle && // don't run if we're currently "creating"
            deleteMedicationMutation.isIdle && // don't run if we're currently "deleting"
            availableOptions.length === 1 && // do run if we have exactly one possible option
            !R.includes(singleMedKey, selectedKeys) // do run if the selected options don't match our single key
        ) {
            // prevent this from running again.
            hasRanAssignment.current = true;

            const [singleMedicationAssociation] = availableOptions;

            createMedicationMutation.mutateAsync(singleMedicationAssociation).then(() => {
                setFieldValue(MED_ASSOCIATION_FIELD_NAME, [groupedAvailableOptions[0].options[0]]);
            });
        }
    }, [
        groupedAvailableOptions,
        availableOptions,
        createMedicationMutation,
        deleteMedicationMutation,
        selectedKeys,
        selectedOptions,
        setFieldValue,
        singleMedKey,
    ]);

    /**
     * Set the currently selected medications so that Formik will validate
     */
    useEffect(() => {
        if (hasLength(selectedOptions)) {
            setFieldValue(MED_ASSOCIATION_FIELD_NAME, selectedOptions);
        }
    }, [selectedOptions, setFieldValue]);

    /**
     * Since we are not yet using react everywhere, we need to refetch this on first mount. In the future, we should
     * clearing, setting, or updating this on patient save.
     */
    useEffect(() => {
        queryClient.refetchQueries(['patient', patientId, 'patient-medication']);
    }, [patientId]);

    /**
     * Switch on the changes so we can asyncronosly add and remove items.
     * @param options
     * @param action
     * @returns {Promise<Awaited<*|void>[]>|Promise<*>}
     */
    const handleChange = (options, action) => {
        setFieldValue(MED_ASSOCIATION_FIELD_NAME, options);

        switch (action.action) {
            case 'select-option':
                return (
                    createMedicationMutation
                        .mutateAsync(action.option)
                        /**
                         * We need a refetch here so the "processor" will rerun with the MA changes
                         * This affects the case where displayName is set and is lost when selected.
                         */
                        .then(() => refetch())
                );
            case 'remove-value':
                return deleteMedicationMutation.mutateAsync(action.removedValue.id);
            case 'clear':
                return Promise.all(
                    action.removedValues.map((removedValue) => {
                        if (removedValue.id) {
                            return deleteMedicationMutation.mutateAsync(removedValue.id);
                        }
                        return Promise.resolve();
                    })
                );
            default:
        }
    };

    const isDisabled =
        isLoading ||
        createMedicationMutation.isLoading ||
        deleteMedicationMutation.isLoading ||
        isActivityComplete ||
        isActivityDeleted;

    return (
        <MedicationAssociationQuestionStyle id="medicationAssociation">
            <SelectField
                isClearable
                value={selectedOptions}
                isMulti
                isLoading={isLoading || createMedicationMutation.isLoading || deleteMedicationMutation.isLoading}
                fieldName={MED_ASSOCIATION_FIELD_NAME}
                label="Medication Association"
                isDisabled={isDisabled}
                options={groupedAvailableOptions}
                getOptionValue={R.prop('displayName')}
                getOptionLabel={R.prop('displayName')}
                onChange={handleChange}
                placeholder="Medication - Rx Number - Fill Number"
                required={required}
            />
        </MedicationAssociationQuestionStyle>
    );
};

MedicationAssociation.propTypes = propTypes;
MedicationAssociation.defaultProps = defaultProps;

export default MedicationAssociation;
