import styled from '@emotion/styled';

const FlexContainer = styled('div')`
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
`;

export default FlexContainer;
