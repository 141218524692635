import PESService from '../services/PESService';
import convertKeysToSnakeCase from '../utils/keyConversion/convertKeysToSnakeCase';
import {
    convertPatientPrescriptions,
    convertPatientTherapyPayload,
    convertGetPatientRx,
    convertMedicationsPayload,
    convertPatientDetailsPayload,
} from '../utils/phpPayloadConversions';
import * as _PatientService from 'App/services/PatientService';

/** @ngInject */
function PatientService(_, $http, $httpParamSerializerJQLike, $q, CacheFactory, dateService, urlBuilderService) {
    const promises = {};

    // Only create Cache for 'patientCache' if it has expired or was deleted
    if (!CacheFactory.get('patientCache')) {
        CacheFactory('patientCache', {
            maxAge: 15 * 60 * 1000, // Items added to this cache expire after 15 minutes
            cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour
            deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
        });
    }

    return {
        checkDuplicate,
        create,
        createActivity,
        disable,
        enable,
        get,
        getActivities,
        getAllMedicationsAndRx,
        getContacts,
        getHighRiskByIds,
        getHighRiskReasons,
        getMedicationRxs,
        getMedications,
        getPDX,
        getPrescriptions,
        getProfile,
        getTherapies,
        getAllTherapyICD10s,
        getNursingAgencyPayRates,
        query,
        update,
        updateHighRiskReasons,
        updateRx,
        unsubscribePatientMessaging,
    };

    function _format(data) {
        _.forEach(data, (value, key) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            data[_.snakeCase(key)] = value;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete data[key];
        });
        return data;
    }

    function _formatActivity(activity) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        activity.date_completed = dateService.localize(activity.date_completed);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        activity.date_due = dateService.localize(activity.date_due);

        if (activity.status === 'Closed') {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            activity.status = 'Finished';
        }

        switch (activity.default_note_type) {
            case '0':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                activity.type_label = 'PCC';
                break;
            case '1':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                activity.type_label = 'Clinical';
                break;
            default:
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                activity.type_label = null;
                break;
        }
        return activity;
    }

    function _formatActivities(activities) {
        _.forEach(activities, (activity) => {
            _formatActivity(activity);
        });
        return activities;
    }

    function checkDuplicate(patient) {
        // API endpoint only supports yyyy-mm-dd
        if (typeof patient.birthdate !== 'object') {
            throw new Error('Invalid birth date provided');
        }

        const birthdate = patient.birthdate.toISOString().substr(0, 10);
        const url = urlBuilderService.get('duplicatePatient');
        return $http
            .get(
                url,
                $httpParamSerializerJQLike({
                    first_name: patient.firstName,
                    last_name: patient.lastName,
                    gender: patient.gender,
                    birth_date: birthdate,
                })
            )
            .then((res) => {
                return res.data;
            });
    }

    function create(patient) {
        const url = urlBuilderService.build('createPatient', {
            'create-patient': '',
        });

        // Sanitize the input from the client
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        patient = _.pick(patient, [
            'address_line_1',
            'address_line_2',
            'allergy',
            'birth_date',
            'city',
            'company_id',
            'country',
            'deceased_date',
            'division_id',
            'ec_first_name',
            'ec_last_name',
            'ec_mobile_phone',
            'ec_relationship',
            'ec_telephone',
            'email',
            'ethnicity',
            'external_id',
            'first_name',
            'gender',
            'gender_identity',
            'pronouns',
            'preferred_name',
            'height',
            'high_risk',
            'home_phone',
            'lactating',
            'language_spoken',
            'language_spoken_other',
            'last_name',
            'marital_status',
            'medical_plan',
            'middle_name',
            'mobile_phone',
            'no_known_allergies',
            'no_known_medical_conditions',
            'notes',
            'other_allergy',
            'pbm',
            'pcc_email_on_form',
            'pcc_id',
            'pcc_identifier',
            'pcc_send_email',
            'pharmacy',
            'place_of_service',
            'preferred_contact_method',
            'pregnancy_status',
            'referral_reason',
            'referral_status',
            'referral_update',
            'reimbursement_notes',
            'remote_medical_plan_id',
            'remote_pbm_id',
            'smoker_status',
            'ssn_last_four',
            'state',
            'suffix',
            'terminally_ill',
            'updated_by',
            'updated_on',
            'weight',
            'welcome_packet',
            'welcome_packet_returned',
            'welcome_packet_sent',
            'work_phone',
            'zip',
        ]);

        return $http.post(url, patient).then((res) => {
            return res.data;
        });
    }

    function createActivity(patientId, activity) {
        const url = urlBuilderService.build('createActivity', {
            patient: patientId,
            activity: '',
        });

        return $http.post(url, activity).then((res) => {
            return res.data;
        });
    }

    function disable(patient) {
        const url = urlBuilderService.build('disablePatient', {
            patient: patient.id,
        });

        return $http.delete(url);
    }

    function enable(patient) {
        const url = urlBuilderService.build('enablePatient', {
            patient: patient.id,
            disable: '',
        });

        return $http.patch(url);
    }

    function get(patientId) {
        return PESService.get(patientId)
            .then(convertPatientDetailsPayload)
            .then((res) => {
                return res;
            });
    }

    async function getActivities(patientId) {
        return PESService.getActivities(patientId)
            .then((res) => {
                const activities = Object.values(res).map((activity) => convertKeysToSnakeCase(activity));
                return _formatActivities(activities);
            })
            .catch((err) => err);
    }

    function getContacts(params) {
        const url = urlBuilderService.build('getPatientContact', {
            'patient/contacts': '',
        });

        return $http
            .post(url, params)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function getHighRiskByIds(params) {
        const url = urlBuilderService.build('getHighRiskByIds', {
            'patient/highRisk': '',
        });
        return $http.post(url, params).then((res) => {
            return res.data;
        });
    }

    function getHighRiskReasons(patientId) {
        return _PatientService.getHighRiskReasons(patientId);
    }

    function getMedicationRxs(patientId, medicationId, allRxs) {
        const params = {
            type: allRxs,
        };
        return PESService.getPatientPrescriptions(patientId, medicationId, params).then(convertPatientPrescriptions);
    }

    function getMedications(patientId, type) {
        return PESService.getPatientMedications(patientId)
            .then((res) => convertMedicationsPayload(res, type))
            .catch((err) => {
                if (err.status === 404) {
                    return {};
                }

                return err;
            });
    }

    function getAllMedicationsAndRx(patientId, allMedications, allRxs) {
        const url = urlBuilderService.build('queryMedications', {
            patient: patientId,
            medications: '',
        });

        const medications = {};

        return $http
            .get(url, {
                params: {
                    type: allMedications,
                },
            })
            .then((res) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-shadow
                const promises = {};

                // Iterate through and request prescriptions for each medication.
                _.map(res.data, (medication) => {
                    medications[medication.medication.id] = medication;
                    promises[medication.medication.id] = getMedicationRxs(patientId, medication.medication.id, allRxs);
                });
                return $q.all(promises);
            })
            .then((rxs) => {
                _.map(rxs, (rx, key) => {
                    if (_.isEmpty(rx)) {
                        return;
                    }
                    medications[key].rx = rx;
                });
                return medications;
            });
    }

    function getNursingAgencyPayRates(patientId) {
        if (promises.getNursingAgencyPayRates) {
            return promises.getNursingAgencyPayRates;
        }

        const url = urlBuilderService.build('getNursingAgencyPayRates', {
            patient: patientId,
            nursing: '',
            rates: '',
        });
        promises.getNursingAgencyPayRates = $http
            .get(url)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.status === 404) {
                    return [];
                }
                return err.data;
            })
            .finally(() => {
                promises.getNursingAgencyPayRates = undefined;
            });
        return promises.getNursingAgencyPayRates;
    }

    /**
     * Get prescriptions from serverless endpoint for medication status and related prescrition numbers
     * @param {string} patientId
     * @returns
     */
    function getPrescriptions(patientId) {
        return PESService.getPatientRx(patientId).then((res) => convertGetPatientRx(res));
    }

    function getProfile(patientId) {
        return $http
            .get('/stm/patient_activity.php', {
                params: {
                    angular: true,
                    patient_id: patientId,
                },
            })
            .then((res) => {
                if (res.data.PATIENT_FILES) {
                    res.data.PATIENT_FILES = _.values(res.data.PATIENT_FILES);
                }
                return res.data;
            });
    }

    function getPDX(id) {
        const url = urlBuilderService.build('getPDX', { patient: '', pdx: id });

        return $http
            .get(url)
            .then((res) => {
                const response = res.data;
                _.forEach(response, (value, key) => {
                    delete response[key];
                    response[_.camelCase(key)] = value;
                });
                return response;
            })
            .catch((err) => {
                throw err;
            });
    }

    function getTherapies(patientId) {
        return PESService.getTherapies(patientId).then(convertPatientTherapyPayload);
    }

    function getAllTherapyICD10s(patientId, therapyId) {
        return PESService.getAllTherapyICD10s(patientId, therapyId);
    }

    function query(params) {
        return $http
            .post(
                '/stm/find_patient.php',
                $httpParamSerializerJQLike({
                    count: params.count,
                    page: params.page,
                    search: params.search,
                    sortKey: params.sortKey,
                    sortOrder: params.sortOrder,
                    submit: true,
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                }
            )
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function update(id, patient) {
        const url = urlBuilderService.build('updatePatient', {
            patient: id,
        });

        return $http.patch(url, patient).then((res) => {
            return _format(res.data);
        });
    }

    function updateHighRiskReasons(patientId, highRiskReasons) {
        return _PatientService.updateHighRiskReasons(patientId, highRiskReasons);
    }

    function updateRx(patientId, rxId, body) {
        const url = urlBuilderService.build('updatePatient', {
            patient: patientId,
            rx: rxId.toString(),
        });

        return $http.patch(url, body).then((res) => {
            return _format(res.data);
        });
    }

    function unsubscribePatientMessaging(patientId, subscriptionTypeId, body) {
        return PESService.updatePatientSubscription(patientId, subscriptionTypeId, body);
    }
}

export default PatientService;
