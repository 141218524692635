/** @ngInject */
function ReportingService($http, __env) {
    return {
        get,
        getAll,
        getMenu,
        getPatientTherapies,
        getFilterOptions,
        saveReport,
        saveReportDw,
    };

    function get() {
        return $http.get(`${__env.trs.endpoint}/reportsOld/1.0.0`).then((res) => {
            return res.data;
        });
    }

    function getMenu(menu) {
        return $http.get(`${__env.trs.endpoint}/reportsOld/1.0.0/menu/${menu}`).then((res) => {
            return res.data;
        });
    }

    function getPatientTherapies() {
        return $http.get(`${__env.trs.endpoint}/reportsOld/1.0.0/usage/patientTherapies`).then((res) => {
            return res.data;
        });
    }

    function getFilterOptions(params) {
        return $http
            .post(`${__env.trs.endpoint}/reportsOld/1.0.0/filter`, {
                column: params,
            })
            .then((res) => {
                return res.data;
            });
    }

    // TODO: Create a separate route for this report
    function saveReportDw(report) {
        return $http.post(`${__env.trs.endpoint}/reportsOld/1.0.0/insights/dwPatient`, report).then((res) => {
            return res.data;
        });
    }

    // TODO: Create a separate route for this report
    function getAll(params) {
        return $http.post(`${__env.trs.endpoint}/reportsOld/1.0.0/fetchAll`, params).then((res) => {
            return res.data;
        });
    }

    function saveReport(params) {
        return $http.post(`${__env.trs.endpoint}/reportsOld/1.0.0`, {
            report: params,
        });
    }
}

export default ReportingService;
