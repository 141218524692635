import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { patientPropType } from 'Lib/types';

import ScrollToElementLink from '../ScrollToElementLink';
import { questionPropType } from '../types';
import AssessmentQuestionText from './AssessmentQuestionText';
import { MED_ASSOCIATION_FIELD_NAME } from 'App/components/Assessments/question/MedicationAssociation/constants';

const AssessmentQuestionErrorDisplay = ({ questionId, allQuestions, patient }) => {
    const { values } = useFormikContext();

    function getQuestionText() {
        if (questionId === MED_ASSOCIATION_FIELD_NAME) {
            return 'Medication Association';
        }

        const question = Object.values(allQuestions).find((q) => q.questionId === questionId);
        if (!question) {
            return null;
        }
        return (
            <AssessmentQuestionText
                question={question}
                fullyScriptedMode={values.fullyScriptedMode}
                patient={patient}
            />
        );
    }

    const questionText = getQuestionText();
    if (!questionText) {
        return null;
    }

    return (
        <li id={`required-error-${questionId}`}>
            <ScrollToElementLink elementId={questionId}>{questionText}</ScrollToElementLink>
        </li>
    );
};

AssessmentQuestionErrorDisplay.propTypes = {
    questionId: PropTypes.string.isRequired,
    allQuestions: PropTypes.objectOf(questionPropType).isRequired,
    patient: patientPropType.isRequired,
};

export default AssessmentQuestionErrorDisplay;
