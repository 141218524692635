import styled from '@emotion/styled';
import { prop } from 'ramda';
import React from 'react';
import { Col } from 'react-bootstrap';

import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';
import { useUserContext } from 'App/contexts/UserContext';
import SelectField from 'Lib/form/SelectField';

import useChangeBranch from 'App/hooks/useChangeBranch';

const ChangeBranchStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--snow);
    padding: 15px 20px;
`;

const ChangeBranch = () => {
    const user = useUserContext();
    const { handleChangeBranch, isChanging } = useChangeBranch();

    return (
        <ChangeBranchStyle>
            <Col md={3}>
                <SelectField
                    fieldName="selectedBranch"
                    options={user.branches}
                    label={<>Select Branch {isChanging && <i className="fa fa-spinner fa-spin" />}</>}
                    getOptionLabel={prop('name')}
                    getOptionValue={prop('id')}
                    value={{
                        id: user.active_branch.ID,
                        name: user.active_branch.Name,
                    }}
                    onChange={(option) => handleChangeBranch(option.id)}
                    isDisabled={isChanging}
                />
            </Col>
            <TherigyInfoMessage message="Therapeutic Categories may change based on the selected branch." />
        </ChangeBranchStyle>
    );
};

export default ChangeBranch;
