import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { sendMassText } from '@/app/services/PatientMessagingService';

const useSendMassTextingMutation = (showSuccessToast = true) => {
    return useMutation((data) => sendMassText(data), {
        onSuccess: (res) => {
            if (showSuccessToast) {
                toast.success(`Successfully added ${res.successful} patients for mass texting.`);
            }
        },
    });
};

export default useSendMassTextingMutation;
