import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted';

/** @ngInject */
function AssessmentManagerController(_, $scope, $state, $uibModal, __env, NgTableParams, amsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.canDeleteAssessment = canDeleteAssessment;
    ctrl.canEditQuestions = canEditQuestions;
    ctrl.canEditSettings = canEditSettings;
    ctrl.changeCategory = changeCategory;
    ctrl.deleteAssessment = deleteAssessment;
    ctrl.showHideAssessment = showHideAssessment;
    ctrl.isShowRetired = false;

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            ctrl.company = ctrl.user.active_company.Name;
            ctrl.customAssessments = ctrl.user.company_permissions.ContentDeveloper;
        }
    }

    function $onInit() {
        ctrl.categoryId = $state.params.categoryId;
        ctrl.lastModifiedAssessment = $state.params.pollId;
        ctrl.canCloneAssessment = isPermitted(PERMISSION_TYPES.CLONE_ASSESSMENT, ctrl.user);
        ctrl.isShowRetired = $state.params.isShowRetired;
        ctrl.cols = [
            {
                field: 'name',
                title: 'Name',
                show: true,
                sortable: 'name',
                filter: {
                    name: '/name-filter.html',
                },
            },
            {
                field: 'companyCreated',
                title: 'Created By',
                sortable: 'companyCreated',
                filter: {
                    companyCreated: 'select',
                },
                filterData: [
                    { id: '', title: 'All' },
                    { id: true, title: 'My Pharmacy' },
                    { id: false, title: 'Therigy' },
                ],
            },
            {
                title: 'Mobile-Enabled Questions',
                field: 'isMobile',
                filter: {
                    isMobile: 'select',
                },
                filterData: [
                    { id: '', title: 'All' },
                    { id: true, title: 'Yes' },
                    { id: false, title: 'No' },
                ],
                sortable: 'isMobile',
            },
            {
                title: 'Actions',
                field: 'actions',
            },
        ];

        ctrl.tableParams = new NgTableParams(
            {
                count: 10,
                sorting: { name: 'asc' },
                filter: { companyCreated: '', isMobile: '' },
            },
            {
                counts: [10, 25, 100],
                dataset: ctrl.assessments,
            }
        );
    }

    //TODO: Implement isPermitted function
    function canDeleteAssessment(assessment) {
        if (ctrl.user.company_permissions.TherigyAssessmentAdmin === true) {
            return true;
        }
        return (
            ctrl.user.company_permissions.ContentDeveloper === true &&
            ctrl.user.active_company.ID === assessment.companyId
        );
    }

    //TODO: Implement isPermitted function
    function canEditQuestions(assessment) {
        return (
            (ctrl.user.company_permissions.ContentDeveloper === true && assessment.ableToModify === 1) ||
            ctrl.user.company_permissions.TherigyAssessmentAdmin === true
        );
    }

    //TODO: Implement isPermitted function
    function canEditSettings() {
        return (
            ctrl.user.company_permissions.ContentDeveloper === true ||
            ctrl.user.company_permissions.TherigyAssessmentAdmin === true
        );
    }

    function changeCategory(categoryId) {
        $state.go('app.assessmentManager', {
            categoryId,
        });
    }

    function showHideAssessment(isShowRetired) {
        $state.go('app.assessmentManager', {
            isShowRetired,
        });
    }

    function deleteAssessment(assessment) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'deleteAssessmentModal',
            size: 'md',
            resolve: {
                assessment() {
                    return assessment;
                },
            },
        });

        modalInstance.result
            .then((res) => {
                return _refreshAssessments(res.groupId);
            })
            .catch((err) => {
                return err.data;
            });
    }

    function _refreshAssessments(categoryId) {
        const includeMobileStatus = true;
        return amsService.getTherapyAssessments(categoryId, includeMobileStatus).then((res) => {
            _.each(res, (assessment) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                assessment.companyCreated = assessment.companyId !== null;
            });
            ctrl.tableParams.settings().dataset = _.values(res);
            ctrl.tableParams.reload().then((data) => {
                if (data.length === 0 && ctrl.tableParams.total() > 0) {
                    ctrl.tableParams.page(ctrl.tableParams.page() - 1);
                    ctrl.tableParams.reload();
                }
            });
            ctrl.assessments = _.values(res);
            return res;
        });
    }
}

export default AssessmentManagerController;
