function ConfirmationModalController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.coinfirmAction = coinfirmAction;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve && ctrl.resolve.message && ctrl.resolve.title) {
            ctrl.confirmationTitle = ctrl.resolve.title;
            ctrl.confirmationMessage = ctrl.resolve.message;
        }
    }

    function coinfirmAction() {
        ctrl.close({
            $value: true,
        });
    }
}

export default ConfirmationModalController;
