import React, { useCallback, useState, useEffect } from 'react';
import { errorHandler } from 'App/utils';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FlexCenter from 'App/components/styles/FlexCenter';
import Main from 'App/components/styles/Main/Main';
import PageWrapper from 'App/components/styles/PageWrapper/PageWrapper';
import PatientProfileTitle from 'App/components/PatientProfile/PatientProfileTitle';
import PatientActivities, {
    ActivityTypes,
} from 'App/components/PatientProfile/components/PatientActivities/PatientActivities';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import UnassignedMedicationsBanner from 'App/components/PatientProfile/components/UnassignedMedications/UnassignedMedicationsBanner';
import PatientProfileLinks from 'App/components/PatientProfile/components/PatientProfileLinks/PatientProfileLinks';
import TherapeuticCategories from './components/TherapeuticCategories/TherapeuticCategories';
import PatientDemographics from 'App/components/PatientProfile/components/PatientDemographics/PatientDemographics';
import PatientMetaData from 'App/components/PatientProfile/components/PatientMetaData/PatientMetaData';
import usePatientProfile from './hooks/usePatientProfile';
import { RefetchFlags } from './constants';
import PESService from 'App/services/PESService';
import * as R from 'ramda';
import PatientInsuranceWrapper from './components/PatientInsurance/PatientInsuranceWrapper';

const PatientProfile = () => {
    const { patientId } = useParams();
    const [patientOtherMedication, setPatientOtherMedication] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const {
        isProfileLoading,
        isTherapyLoading,
        isActivityLoading,
        patientData,
        activities,
        patientMessagingSubscription,
        therapies,
        medications,
        ehr,
        attachments,
        setRefetchFlag,
    } = usePatientProfile(patientId);

    const patientDemographicsData = R.pick(
        [
            'gender',
            'genderIdentity',
            'pronouns',
            'preferredName',
            'birthDate',
            'languageSpoken',
            'ssnLastFour',
            'medicalPlan',
            'remoteMedicalPlanId',
            'pbm',
            'remotePbmId',
            'homePhone',
            'mobilePhone',
            'otherMedication',
            'branchName',
            'id',
        ],
        patientData
    );
    const emergencyContactData = R.pick(
        ['ecFirstName', 'ecLastName', 'ecRelationship', 'ecTelephone', 'ecMobilePhone'],
        patientData
    );
    const patientMetaData = R.pick(
        [
            'pcc',
            'pccSendEmail',
            'updatedOn',
            'referralStatus',
            'referralReason',
            'referralUpdate',
            'welcomePacket',
            'welcomePacketSent',
            'welcomePacketReturned',
            'updatedBy',
            'updatedByUser',
            'id',
        ],
        patientData
    );
    const getFormattedAddress = () => {
        if (patientData) {
            return [
                patientData.addressLine1,
                patientData.addressLine2,
                `${patientData.city}, ${patientData.state}`,
                patientData.zip,
            ].join(' ');
        }
        return false;
    };
    useEffect(() => {
        PESService.getPatientOtherMedication(patientId)
            .then((result) => {
                setPatientOtherMedication(Object.values(result));
            })
            .catch(errorHandler)
            .finally(() => {
                setIsLoading(false);
            });
    }, [patientId]);

    const refetchActivities = useCallback(() => setRefetchFlag(RefetchFlags.activitiesAndReferral), [setRefetchFlag]);

    const refetchMessagingSubscriptions = useCallback(
        () => setRefetchFlag(RefetchFlags.messagingSubscriptions),
        [setRefetchFlag]
    );
    const refetchUnassignedMedications = useCallback(
        () => setRefetchFlag(RefetchFlags.unassignedMedications),
        [setRefetchFlag]
    );

    if (isLoading || isTherapyLoading || isProfileLoading) {
        return (
            <FlexCenter>
                <TherigyLoadingSpinner />
            </FlexCenter>
        );
    }

    return (
        <Main>
            <Helmet>
                <title>Therigy | Improving therapy, enhancing lives</title>
            </Helmet>
            <PageWrapper>
                <div className="container">
                    <UnassignedMedicationsBanner
                        patient={patientData}
                        patientId={patientId}
                        onHide={refetchUnassignedMedications}
                    />
                    <PatientProfileTitle patient={patientData} ehr={ehr} />
                    <PatientProfileLinks id={patientId} attachments={attachments} />
                    <PatientDemographics
                        patientMessagingSubscription={patientMessagingSubscription}
                        patientDemographicsData={patientDemographicsData}
                        address={getFormattedAddress()}
                        otherMedication={patientOtherMedication}
                        emergencyContactData={emergencyContactData}
                        refetchMessagingSubscriptions={refetchMessagingSubscriptions}
                    />
                    <PatientMetaData patientMetaData={patientMetaData} />

                    <TherapeuticCategories
                        key={patientId}
                        isTherapyLoading={isTherapyLoading}
                        therapies={therapies}
                        medications={medications}
                    />
                    <PatientInsuranceWrapper patientId={patientId} />
                    <div>
                        <PatientActivities
                            id={patientId}
                            activityType={ActivityTypes.PendingActivities}
                            includeNewActivities
                            activities={activities}
                            divisionId={patientData.divisionId}
                            therapy={therapies}
                            refetchActivities={refetchActivities}
                            isActivityLoading={isActivityLoading}
                        />
                    </div>
                    <div>
                        <PatientActivities
                            id={patientId}
                            activityType={ActivityTypes.PastActivities}
                            activities={activities}
                            divisionId={patientData.divisionId}
                        />
                    </div>
                    <div>
                        <PatientActivities
                            id={patientId}
                            activityType={ActivityTypes.DeletedActivities}
                            activities={activities}
                            divisionId={patientData.divisionId}
                        />
                    </div>
                </div>
            </PageWrapper>
        </Main>
    );
};

export default PatientProfile;
