/** @ngInject */
function AuthController(__env) {
    const ctrl = this;
    ctrl.$onInit = $onInit;

    function $onInit() {
        ctrl.cdnURL = __env.cdnURL;
        ctrl.supportEmail = __env.supportEmail;
        ctrl.supportPhoneNumber = __env.supportPhoneNumber;
    }
}
export default AuthController;
