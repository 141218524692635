import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Button, Col, ControlLabel, Modal, Row } from 'react-bootstrap';
import { SelectInput } from '@/lib/form/SelectField';
import MessagePreview from './components/MessagePreview';
import useMessageTemplates from '@/app/hooks/useMessageTemplates';
import * as R from 'ramda';

const PaddedRow = styled(Row)`
    padding: 0.5% 0;
`;

const propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const defaultProps = {};

const OneTimeTextMessageModel = ({ show, onClose }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const { isLoading, data } = useMessageTemplates();

    return (
        <Modal data-testid="onetimemessagemodelModal" show={show} backdrop="static" bsSize="lg">
            <Modal.Header closeButton={false}>
                <Modal.Title className="header">One-Time Text Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PaddedRow>
                    <Col md={6}>
                        <ControlLabel className="field-required" htmlFor="selectMessage">
                            Select Message
                        </ControlLabel>
                        <SelectInput
                            isLoading={isLoading}
                            name="selectMessage"
                            data-testid="selectMessage"
                            options={data}
                            getOptionLabel={R.prop('name')}
                            getOptionValue={R.prop('uuid')}
                            onChange={(option) => {
                                setSelectedTemplate(option);
                            }}
                            value={selectedTemplate}
                        />
                    </Col>
                    {selectedTemplate && (
                        <Col md={12} style={{ paddingTop: '20px' }}>
                            <MessagePreview content={selectedTemplate.content} />
                        </Col>
                    )}
                </PaddedRow>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    bsStyle="default"
                    data-testid="onetime-cancel-button"
                    name="cancel"
                    className="float-right"
                    onClick={() => {
                        setSelectedTemplate(null);
                        onClose();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    bsStyle="primary"
                    name="send"
                    className="float-right"
                    disabled={!selectedTemplate}
                    onClick={() => {
                        setSelectedTemplate(null);
                        onClose(selectedTemplate);
                    }}
                >
                    Send
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

OneTimeTextMessageModel.propTypes = propTypes;
OneTimeTextMessageModel.defaultProps = defaultProps;

export default OneTimeTextMessageModel;
