/** @ngInject */
function AssessmentPreviewConfig($rootScopeProvider, $stateProvider) {
    $rootScopeProvider.digestTtl(20);
    $stateProvider.state({
        component: 'assessmentPreview',
        name: 'app.assessmentPreview',
        url: '/assessment/:assessmentId/preview',
        resolve: {
            assessment($state, $transition$, amsService) {
                const params = $transition$.params();
                return amsService
                    .getAssessment(params.assessmentId)
                    .then((res) => {
                        return res;
                    })
                    .catch((error) => {
                        return error.status === 400 ? $state.go('app.404') : $state.go('app.500');
                    });
            },
            assessmentQuestions($location, $state, $transition$, amsService) {
                const params = $transition$.params();
                let getAssessmentQuestions;
                if ($location.search().expanded) {
                    getAssessmentQuestions = amsService.getAllAssessmentQuestions(params.assessmentId);
                } else {
                    getAssessmentQuestions = amsService.getParentQuestions(params.assessmentId);
                }

                return getAssessmentQuestions
                    .then((res) => {
                        return res;
                    })
                    .catch((error) => {
                        return error.status === 400 ? $state.go('app.404') : $state.go('app.500');
                    });
            },
            groups(_, $transition$, amsService) {
                return amsService.getQuestionGroups().then((res) => {
                    // TODO: Remove hack for group order
                    return _.map(res, (g) => {
                        if (!g.groupOrder) {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            g.groupOrder = 0;
                        }
                        return g;
                    });
                });
            },
        },
    });
}

export default AssessmentPreviewConfig;
