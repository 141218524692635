import useGetCustomMenuQuery from 'App/hooks/useGetCustomMenuQuery';
import { useMemo } from 'react';
import hasLength from '../../../utils/hasLength';
import { getReferralAvailableMenus } from 'App/services/RMSService';
import * as R from 'ramda';

function useGetAvailableReferralMenus() {
    const statusCompleteQuery = useGetCustomMenuQuery('referral_status_reasons_complete');
    const statusReasonsHoldQuery = useGetCustomMenuQuery('referral_status_reasons_hold');
    const statusReasonsPendingQuery = useGetCustomMenuQuery('referral_status_reasons_pending');
    const topStatusQuery = useGetCustomMenuQuery('referral_status');
    const { data: commonHubs = [] } = useGetCustomMenuQuery('referral_no_go_status_outcome_hub');
    const { data: majorSps = [] } = useGetCustomMenuQuery('referral_no_go_status_outcome_sp');

    const referralAvailableMenus = useMemo(() => {
        if (hasLength(majorSps) && hasLength(commonHubs)) {
            return getReferralAvailableMenus(commonHubs, majorSps);
        }
        return [];
    }, [majorSps, commonHubs]);

    const statuses = useMemo(() => {
        if (hasLength(topStatusQuery.data)) {
            return topStatusQuery.data.map((status) => {
                const menu = referralAvailableMenus.find(R.propEq(status.value, 'value'));
                if (menu && R.propSatisfies(hasLength, 'children', menu)) {
                    return menu;
                }

                if (status.value === 'Pending') {
                    return R.assoc('children', statusReasonsPendingQuery.data, status);
                }

                if (status.value === 'Complete') {
                    return R.assoc('children', statusCompleteQuery.data, status);
                }

                if (status.value === 'Hold') {
                    return R.assoc('children', statusReasonsHoldQuery.data, status);
                }

                return R.assoc('children', [], status);
            });
        }
        return [];
    }, [
        statusCompleteQuery.data,
        statusReasonsHoldQuery.data,
        statusReasonsPendingQuery.data,
        topStatusQuery.data,
        referralAvailableMenus,
    ]);

    return statuses;
}

export default useGetAvailableReferralMenus;
