/** @ngInject */
function ErrorStateService($location, $state) {
    return {
        redirectToErrorState,
    };

    /**
     * Handler for error state transitions
     *
     * @description
     * This function is responsible for handing transitions to error states. Since our error states do not have
     * a url this function will build the error state url the user was attempting to go to and
     * set the location path.
     *
     * @param trans - A ui-router transaction object
     * @return {string} Returns the new path.
     */
    function redirectToErrorState(trans) {
        const err = trans.error().detail;
        const toState = trans.$to();

        let path = $state.href(toState.name, trans.params());
        path = path.replace(/^#!.*?/, '');

        if (err.status === 403) {
            $state.go('app.403');
        } else if (err.status === 404) {
            $state.go('app.404');
        } else {
            $state.go('app.500');
        }
        return $location.path(path);
    }
}

export default ErrorStateService;
