import { useMutation, useQueryClient } from 'react-query';
import { createCommunication } from 'App/services/RMSService';

const useCreateReferralCommunicationMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ patientId, referralId, body }) => {
            return createCommunication(patientId, referralId, body);
        },
        {
            onSuccess: (communication) => {
                return queryClient.refetchQueries([
                    'patient',
                    communication.patientId,
                    'referrals',
                    communication.referralId,
                    'communication',
                ]);
            },
        }
    );
};

export default useCreateReferralCommunicationMutation;
