import hasLength from 'App/utils/hasLength';
import React from 'react';
import FilterField from './FilterField';
import addRuleProptypes from '../AddRule.proptypes';

const propTypes = addRuleProptypes;

const defaultProps = {};

function FilterCell({ groupName, group }) {
    return (
        <td>
            {hasLength(group.conditions) ? (
                group.conditions.map((condition, conditionIdx) => {
                    return (
                        <FilterField
                            key={condition.key}
                            conditionIdx={conditionIdx}
                            group={group}
                            condition={condition}
                            groupName={groupName}
                        />
                    );
                })
            ) : (
                <div className="filter-cell" key={`${groupName}-is-answered`}>
                    is answered
                </div>
            )}
        </td>
    );
}

FilterCell.propTypes = propTypes;
FilterCell.defaultProps = defaultProps;

export default FilterCell;
