import { amsEndpoint, amsPort, amsVersion } from '../components/config/config';
import CompanyService from './CompanyService';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import * as R from 'ramda';
import createAxios from 'App/utils/createAxios';

const endpoint = `${amsEndpoint}:${amsPort}/ams/${amsVersion}`;

class AssessmentService {
    static GENERAL_NAME = 'General';

    static axios = createAxios({
        baseURL: endpoint,
    });

    static getAssessment(assessmentId) {
        return this.axios.get(`/assessment/${assessmentId}`);
    }

    static getQuestions(assessmentId, params = {}) {
        return this.axios.get(`/assessment/${assessmentId}/questions`, { params });
    }

    static getQuestionsById(assessmentId, questionIds) {
        const url = `/assessment/${assessmentId}/questions`;
        return this.axios.post(url, { questionIds });
    }

    static getTherapyAssessments(therapyId, showRetiredAssessments = false) {
        let params = { isMobileEnabled: 'true' };
        if (showRetiredAssessments) {
            params = { ...params, isShowRetired: 'true' };
        }
        return this.axios.get(`/therapy/${therapyId}/assessments`, { params });
    }

    static getGeneralTherapyAssessments() {
        return CompanyService.getTherapyCategories()
            .then(R.compose(find(propEq(AssessmentService.GENERAL_NAME, 'name')), R.values))
            .then((general) => {
                if (general) {
                    return AssessmentService.getTherapyAssessments(general.id);
                }
            });
    }

    /**
     * Create new activity note status
     * @param activityId - The activity id.
     * @param note - The note object
     * @returns {Promise<ActivityNote>} - activity note.
     */
    static createActivityNoteStatus(activityId, note) {
        return this.axios.post(`/activity/${activityId}/status`, note);
    }
}

export default AssessmentService;
