import PropTypes from 'prop-types';

const patientPropType = PropTypes.shape({
    accessType: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    allergy: PropTypes.string,
    birthDate: PropTypes.string,
    branchName: PropTypes.string,
    brand: PropTypes.string,
    brandOfAccess: PropTypes.string,
    city: PropTypes.string,
    companyId: PropTypes.string,
    country: PropTypes.string,
    createdBy: PropTypes.string,
    createdOn: PropTypes.string,
    dateAcessInserted: PropTypes.string,
    dateTime: PropTypes.string,
    deceasedDate: PropTypes.string,
    disabledBy: PropTypes.string,
    disabledOn: PropTypes.string,
    divisionId: PropTypes.string,
    ecFirstName: PropTypes.string,
    ecLastName: PropTypes.string,
    ecMobilePhone: PropTypes.string,
    ecRelationship: PropTypes.string,
    ecTelephone: PropTypes.string,
    email: PropTypes.string,
    ethnicity: PropTypes.string,
    employeeId: PropTypes.string,
    externalId: PropTypes.string,
    firstName: PropTypes.string,
    functionalLimitations: PropTypes.string,
    gender: PropTypes.string,
    genderIdentity: PropTypes.string,
    height: PropTypes.number,
    highRisk: PropTypes.bool,
    homePhone: PropTypes.string,
    hubId: PropTypes.string,
    humanId: PropTypes.number,
    id: PropTypes.string,
    insuranceIdNOTUSED: PropTypes.string,
    integrationUniqueId: PropTypes.string,
    isPdxpatient: PropTypes.bool,
    lactating: PropTypes.string,
    languageSpoken: PropTypes.string,
    languageSpokenOther: PropTypes.string,
    lastName: PropTypes.string,
    lengthOfAcess: PropTypes.string,
    maritalStatus: PropTypes.string,
    mdAddress1: PropTypes.string,
    mdAddress2: PropTypes.string,
    mdCity: PropTypes.string,
    mdFaxNumber: PropTypes.string,
    mdFirstName: PropTypes.string,
    mdId: PropTypes.string,
    mdLastName: PropTypes.string,
    mdOfficeNumber: PropTypes.string,
    mdPracticeName: PropTypes.string,
    mdState: PropTypes.string,
    mdZip: PropTypes.string,
    medicalHistory: PropTypes.string,
    medicalPlan: PropTypes.string,
    middleName: PropTypes.string,
    mobilePhone: PropTypes.string,
    noKnownAllergies: PropTypes.string,
    noKnownMedicalConditions: PropTypes.string,
    notes: PropTypes.string,
    numberOfLumens: PropTypes.string,
    nurseId: PropTypes.string,
    nursingAgencyId: PropTypes.string,
    nursingCoordinatorId: PropTypes.string,
    nursingRequired: PropTypes.string,
    otherAllergy: PropTypes.string,
    pbm: PropTypes.string,
    pcc: PropTypes.string,
    pccEmailOnForm: PropTypes.string,
    pccId: PropTypes.string,
    pccIdentifier: PropTypes.string,
    pccSendEmail: PropTypes.bool,
    pdx: PropTypes.shape({
        patientId: PropTypes.string,
        ctime: PropTypes.string,
        divisionId: PropTypes.string,
        mtime: PropTypes.string,
        companyId: PropTypes.string,
        rxcomId: PropTypes.string,
    }),
    pharmacy: PropTypes.string,
    pharmacyId: PropTypes.string,
    physicianId: PropTypes.string,
    placeOfService: PropTypes.string,
    portalUserId: PropTypes.string,
    preferredContactMethod: PropTypes.string,
    preferredName: PropTypes.string,
    pregnancyStatus: PropTypes.string,
    pronouns: PropTypes.string,
    referralReason: PropTypes.string,
    referralStatus: PropTypes.string,
    referralUpdate: PropTypes.string,
    reimbursementNotes: PropTypes.string,
    remoteMedicalPlanId: PropTypes.string,
    remotePbmId: PropTypes.string,
    smokerStatus: PropTypes.string,
    ssnLastFour: PropTypes.string,
    state: PropTypes.string,
    status: PropTypes.string,
    suffix: PropTypes.string,
    terminallyIll: PropTypes.string,
    updatedBy: PropTypes.string,
    updatedByUser: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
    updatedOn: PropTypes.string,
    weight: PropTypes.number,
    welcomePacket: PropTypes.bool,
    welcomePacketReturned: PropTypes.string,
    welcomePacketSent: PropTypes.string,
    workPhone: PropTypes.string,
    zip: PropTypes.string,
});

export default patientPropType;
