/**
 * Create a timeout that can be awaited.
 * Useful when you just want to delay execution for a moment.
 * @param {number} ms - The number of milliseconds to wait.
 * @returns {Promise} - A promise with no resolved value.
 */
function delay(ms = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export default delay;
