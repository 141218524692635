import React from 'react';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { cellPropsType } from '../../workQueue.constant';

const PatientIdCell = ({ row, value }) => {
    const actualId = R.path(['original', 'patientId'], row);
    return (
        <span>
            <Link to={`/patient/${actualId}`}>{value}</Link>
        </span>
    );
};

PatientIdCell.propTypes = cellPropsType;

export default PatientIdCell;
