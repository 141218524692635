/** @ngInject */
function PDXFindPatientService($http, urlBuilderService) {
    /**
     * 'PDX' : 'STM'
     */
    const fieldMapping = {
        firstName: 'first_name',
        lastName: 'last_name',
        zipCode: 'zip',
        birthDate: 'birth_date',
        externalId: 'external_id',
        disabledBy: 'disabled_by',
    };

    return {
        searchSTM,
        searchPDX,
        getPDXPatient,
    };

    function searchSTM(params) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        params = formatForSTM(params);

        const url = '/app.api.php/api/patient/search';
        const parameters = { params };

        return $http.get(url, parameters).then(successCallback).catch(failCallback);

        function successCallback(result) {
            return result.data.results;
        }

        function failCallback(error) {
            throw error;
        }
    }

    function searchPDX(params) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        params = formatForPdx(params);

        const url = '/app.api.php/api/patient/pdx';
        const parameters = { params };

        return $http.get(url, parameters).then(successCallback).catch(failCallback);

        function successCallback(result) {
            return result.data.results;
        }

        function failCallback(error) {
            if (error.status === -1) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                error.statusText = 'Request Was Aborted or Timed Out';
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                error.data.message = `Here are some additional information, request timeout: ${error.config.timeout}`;
                throw error;
            }
            throw error;
        }
    }

    /**
     *
     * @param id
     * @returns array of results
     */
    function getPDXPatient(id) {
        const url = urlBuilderService.build('getPDX', { patient: '', pdx: id });

        return $http
            .get(url)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    /**
     * Format for STM
     * Takes the param and comparing it to the left side of the mapping
     * @param params
     * @returns {Array}
     */
    function formatForSTM(params) {
        const result = [];
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const i in params) {
            // Check against mapping array
            if (fieldMapping[i]) {
                result[fieldMapping[i]] = params[i];
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-continue
                continue;
            }
            // No transformation required
            result[i] = params[i];
        }
        return result;
    }

    /**
     * Formatting for PDX
     * Making sure that the parameter exist inside of the mapping
     * @param params
     * @returns {Array}
     */
    function formatForPdx(params) {
        const result = [];
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const i in params) {
            result[i] = params[i];
        }
        return result;
    }
}

export default PDXFindPatientService;
