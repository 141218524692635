function QuestionDeleteWarningModalController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doCancel = doCancel;
    ctrl.doAccept = doAccept;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve) {
            ctrl.numberOfQuestionsToRemove = angular.copy(ctrl.resolve.modalData.numberOfQuestionsToRemove);
        }
    }

    function doCancel() {
        // Cancel the change and close the dialog
        ctrl.close({
            $value: false,
        });
    }

    function doAccept() {
        // Accept the changes and close the dialog
        ctrl.close({
            $value: true,
        });
    }
}

export default QuestionDeleteWarningModalController;
