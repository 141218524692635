/** @ngInject */
function RXViewHistoryController($uibModal) {
    const vm = this;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    vm.open = function () {
        $uibModal.open({
            component: 'rxViewHistoryModal',
            resolve: {
                modalData() {
                    return {
                        medication: vm.medication,
                        patientId: vm.patientId,
                    };
                },
            },
            size: 'lg',
        });
    };
}

export default RXViewHistoryController;
