import CMSService from 'App/services/CMSService';
import React from 'react';
import mountReactComponent from 'App/utils/mountReactComponent';
import AddTeachingPointModalWrapper from 'App/components/AddTeachingPointModal/AddTeachingPointModalWrapper';

/** @ngInject */
function ContentLibraryController(_, NgTableParams, moment, CSRF_ID_VALUE, CSRF_ID_NAME, $http, $state) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.loadTable = loadTable;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }
    mountAddTeachingPoint();

    function mountAddTeachingPoint() {
        mountReactComponent('#add-teaching-point', <AddTeachingPointModalWrapper loadTable={ctrl.loadTable} />);
    }

    function $onInit() {
        ctrl.selectedTab = $state.params.type || 'drug';
        ctrl.loadTable(ctrl.selectedTab);
        ctrl.loading = true;
        mountAddTeachingPoint();
    }

    function loadTable(tabName) {
        if (ctrl.loading) {
            return false;
        }

        $state.transitionTo('app.contentLibrary', { type: tabName }, { notify: false });
        ctrl.selectedTab = tabName;

        // Reset the table before loading the new values
        ctrl.tableParams = new NgTableParams(
            {
                page: 1,
                count: 5,
            },
            {
                counts: [], // hide page counts control
                total: 1, // value less than count hide pagination
                dataset: [1],
            }
        );

        ctrl.loading = true;

        return CMSService.getContentGroups(ctrl.selectedTab, {
            unpublished: true,
        })
            .then((data) => {
                _.map(data, (value) => {
                    if (value.updatedByUser) {
                        if (value.updatedByUser.updatedOn) {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            value.updatedByUser.updatedOn = moment(value.updatedByUser.updatedOn).format(
                                'YYYY/MM/DD h:mm a'
                            );
                        }

                        if (value.updatedByUser.firstName && value.updatedByUser.lastName) {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign,max-len
                            value.updatedByUser.fullName = `${value.updatedByUser.lastName}, ${value.updatedByUser.firstName}`;
                        }
                    }
                });

                ctrl.tableParams = new NgTableParams(
                    {
                        page: 1,
                        count: 10,
                        sorting: {
                            name: 'asc',
                        },
                    },
                    {
                        dataset: _.values(data),
                    }
                );
                return data;
            })
            .catch((err) => {
                ctrl.tableParams = new NgTableParams(
                    {
                        page: 1,
                        count: 10,
                    },
                    {
                        dataset: [],
                    }
                );
                return err;
            })
            .finally((res) => {
                ctrl.loading = false;
                return res;
            });
    }
}

export default ContentLibraryController;
