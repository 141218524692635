import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import CMSService from 'App/services/CMSService';
import { TextareaField } from 'Lib/form/TextareaField';
import SubmitButton from 'Lib/form/SubmitButton';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    loadTable: PropTypes.func.isRequired,
};

const validationSchema = yup.object().shape({
    teachingPoint: yup.string().required('Please enter a teaching point name').nullable(),
});

function AddTeachingPointModal({ onHide, loadTable }) {
    const initialValues = {
        teachingPoint: '',
    };

    return (
        <Modal show onHide={onHide} backdrop="static">
            <Modal.Header>
                <Modal.Title>Add Teaching Point Monograph</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    CMSService.addTeachingPoint(values).then(() => {
                        setSubmitting(false);
                        onHide(true);
                        loadTable('teaching');
                    });
                }}
            >
                {({ isSubmitting, handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Modal.Body>
                                <Row>
                                    <Col md={12}>
                                        <p>Use this form to add a new Teaching Point Monograph</p>
                                        <TextareaField
                                            fieldName="teachingPoint"
                                            label="Name"
                                            placeholder="Name"
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button
                                        disabled={isSubmitting}
                                        bsClass="btn btn-default"
                                        className="pull-right"
                                        onClick={onHide}
                                    >
                                        Cancel
                                    </Button>
                                    <SubmitButton>Add</SubmitButton>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
}

AddTeachingPointModal.propTypes = propTypes;

export default AddTeachingPointModal;
