import React, { useMemo } from 'react';
import Table from 'App/components/Table';
import * as R from 'ramda';
import SelectColumnFilter from 'App/components/Table/SelectColumnFilter';
import pathOrDash from 'App/utils/pathOrDash';
import ActionsColumn from './ActionsColumn';
import PropTypes from 'prop-types';
import EnableColumn from 'App/features/ProtocolTemplateBuilder/EditProtocolTemplate/EditTemplateForm/RulesTable/EnableColumn';

const propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            protocol: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }),
            category: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }),
        })
    ),
};

const defaultProps = {
    data: [],
};

function tableStateReducer(state, action, prevState) {
    const initialSort = [{ id: 'name', desc: false }];

    const { type } = action;

    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
        default: {
            return state;
        }
    }
}

function RulesTable({ data }) {
    const columns = useMemo(() => {
        return [
            {
                Header: 'Enable/Disable',
                accessor: R.ifElse(R.prop('enable'), R.always('Enable'), R.always('Disable')),
                Filter: SelectColumnFilter,
                Cell: EnableColumn,
            },
            {
                Header: 'Name',
                id: 'name',
                accessor: pathOrDash(['protocol', 'name']),
            },
            {
                Header: 'Category',
                accessor: pathOrDash(['category', 'name']),
            },
            {
                Header: 'Actions',
                Cell: ActionsColumn,
            },
        ];
    }, []);
    return <Table stateReducer={tableStateReducer} getKey={R.prop('uuid')} data={data} columns={columns} />;
}

RulesTable.propTypes = propTypes;
RulesTable.defaultProps = defaultProps;

export default RulesTable;
