import React, { useEffect, useState } from 'react';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import MedicationSearch from './MedicationSearch/MedicationSearch';
import TherapySearch from './TherapySearch/TherapySearch';
import Page from 'App/components/Page';
import CompanyService from 'App/services/CompanyService';
import { errorHandler } from 'App/utils';
import * as R from 'ramda';
import Select from 'react-select';
import { ControlLabel } from 'react-bootstrap';
import PlaceHolderMessage from 'App/components/styles/PlaceholderMessage/PlaceHolderMessage';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';

const propTypes = {};

const defaultProps = {};

function MedicationManagementPage() {
    const [searchType, setSearchType] = useState(null);
    const [therapies, setTherapies] = useState(true);
    const [loading, setLoading] = useState(true);

    const searchTypeOptions = [
        { id: 'medication', name: 'Medication' },
        { id: 'therapy', name: 'Therapeutic Category' },
    ];

    useEffect(() => {
        CompanyService.getTherapyCategories()
            .then((res) => {
                //setLoading(true);
                setTherapies(R.sortBy(R.prop('name'))(Object.values(res)));
            })
            .catch((error) => {
                errorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    let searchDisplay = '';
    switch (searchType) {
        case 'therapy':
            searchDisplay = <TherapySearch therapies={therapies} />;
            break;
        case 'medication':
            searchDisplay = <MedicationSearch therapies={therapies} />;
            break;
        default:
            searchDisplay = <PlaceHolderMessage placeholderText="Select how you would like to search." />;
    }

    if (loading) {
        return (
            <Page title="Medication Management" permissionType={PERMISSION_TYPES.MEDICATION_MANAGEMENT}>
                <PlaceHolderMessage>
                    <TherigyLoadingSpinner />
                </PlaceHolderMessage>
            </Page>
        );
    }

    return (
        <Page title="Medication Management" permissionType={PERMISSION_TYPES.MEDICATION_MANAGEMENT}>
            <div>
                <FormGroup style={{ width: '250px' }}>
                    <ControlLabel htmlFor="search-type">Search By</ControlLabel>
                    <Select
                        classNamePrefix="react-select"
                        options={searchTypeOptions}
                        getOptionValue={R.prop('id')}
                        getOptionLabel={R.prop('name')}
                        inputId="search-type"
                        onChange={(option) => {
                            setSearchType(option.id);
                        }}
                    />
                </FormGroup>
            </div>
            {searchDisplay}
        </Page>
    );
}

MedicationManagementPage.propTypes = propTypes;
MedicationManagementPage.defaultProps = defaultProps;

export default MedicationManagementPage;
