/** @ngInject */
function tokenService($rootScope, $state, $window) {
    const service = {
        get,
        remove,
        set,
    };

    return service;

    /**
     * @summary Returns the current session token.
     * @description
     * Gets the current session token from one of two places:
     * 1) The currentToken object in local storage
     * 2) The rootscope of the application
     *
     * If no key is found, it will redirect to the login page.
     *
     * @returns {string} The session token
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function get() {
        if (typeof $window.localStorage.getItem('currentToken') === 'string') {
            return $window.localStorage.getItem('currentToken');
        }
        if ($rootScope.token) {
            return $rootScope.token;
        }
        $state.go('auth.login');
    }

    /**
     * @summary Sets a user token into local storage.
     * @description
     * This method is used to set the 'currentToken' value in local storage, this action needs to
     * occur here as requring SessionStorage will create a circular dependency.
     *
     * @param {string} token
     */
    function set(token) {
        $window.localStorage.setItem('currentToken', token);
    }

    /**
     * @summary Removes the currentToken
     */
    function remove() {
        $window.localStorage.removeItem('currentToken');
    }
}

export default tokenService;
