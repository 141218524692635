import React from 'react';
import FlexCenter from 'App/components/styles/FlexCenter';
import { ClipLoader } from 'react-spinners';
import getCssVariable from 'App/utils/getCssVariable';

const propTypes = {};

const defaultProps = {};

function PageSpinner() {
    return (
        <FlexCenter>
            <div
                style={{
                    marginTop: '45vh',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    color: 'var(--brandPrimary)',
                }}
            >
                <div>One moment Please</div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ClipLoader color={getCssVariable('--brandPrimary')} size={50} />
                </div>
            </div>
        </FlexCenter>
    );
}

PageSpinner.propTypes = propTypes;
PageSpinner.defaultProps = defaultProps;

export default PageSpinner;
