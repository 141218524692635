/** @ngInject */
function AssessmentPublisherConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.assessmentPublisher',
        url: '/assessment-publisher',
        component: 'assessmentPublisher',
        redirectTo(trans) {
            return (
                trans
                    .injector()
                    .getAsync('user')
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line consistent-return
                    .then((user) => {
                        if (user.company_permissions.TherigyAssessmentAdmin !== true) {
                            return {
                                state: 'app.403',
                                params: {
                                    permissionName: 'Therigy admin',
                                },
                            };
                        }
                    })
            );
        },
    });
}

export default AssessmentPublisherConfig;
