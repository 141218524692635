import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils.js';
import { formatUtcDate, dateOnlySlashesFormat } from 'App/services/DateService';
import { updatePccEmail } from 'App/components/PatientProfile/PatientProfile.utils.js';
import './PatientMetaDataStyles.scss';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    patientMetaData: PropTypes.object.isRequired,
};
const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    patientMetaData: {},
};

function PatientMetaData({ patientMetaData }) {
    const {
        pcc,
        pccSendEmail,
        updatedOn,
        referralStatus,
        referralReason,
        referralUpdate,
        welcomePacket,
        welcomePacketSent,
        welcomePacketReturned,
        updatedByUser,
        id,
    } = patientMetaData;
    const [isPatientUpdating, setIspatientUpdating] = useState(false);
    const [checked, setChecked] = useState(pccSendEmail);
    const updatePcc = () => {
        setIspatientUpdating(true);
        updatePccEmail(true, id)
            .then()
            .catch((err) => console.log(err))
            .finally(() => {
                setIspatientUpdating(false);
            });
    };

    return (
        <div className="meta-data-section">
            <Row className="row-meta-data-section">
                <Col md={4}>Assigned PCC: {pcc || '--'}</Col>
                <Col md={4} className="input-wrapper__patientpccvalue">
                    <input
                        disabled={!pcc || isPatientUpdating}
                        onClick={updatePcc}
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        type="checkbox"
                    />
                    <span className="email-update-message">Email PCC When Assessments Updated</span>
                    {isPatientUpdating && <i className="fa fa-spinner fa-pulse fa-fw" />}
                </Col>
                <Col md={4}>
                    Last Updated By: {updatedByUser?.firstName} {updatedByUser?.lastName}
                    {updatedOn && (
                        <span data-testid="updated-on-date">{` on ${formatDate(updatedOn, 'YYYY/MM/DD h:mm a')}`}</span>
                    )}
                </Col>
            </Row>
            {referralStatus && referralReason && referralUpdate && (
                <Row className="row-meta-data-section">
                    <Col md={4}>RSV Referral Status: {referralStatus}</Col>
                    <Col md={4}>RSV Referral Status Description: {referralReason}</Col>
                    <Col md={4}>RSV Referral Status Change Date: {referralUpdate}</Col>
                </Row>
            )}

            {welcomePacket && (
                <Row className="row-meta-data-section">
                    <Col md={4}>
                        <strong>Welcome Packet Requested</strong>
                    </Col>
                    <Col md={4}>
                        Mail Date: {formatUtcDate({ date: welcomePacketSent, format: dateOnlySlashesFormat })}
                    </Col>
                    <Col md={4}>
                        Return Date: {formatUtcDate({ date: welcomePacketReturned, format: dateOnlySlashesFormat })}
                    </Col>
                </Row>
            )}
        </div>
    );
}

PatientMetaData.propTypes = propTypes;
PatientMetaData.defaultProps = defaultProps;

export default PatientMetaData;
