import * as R from 'ramda';
import { isProd, isStaging } from 'App/components/config/config';

function configureNewRelic(user) {
    if (window.newrelic) {
        window.newrelic.setUserId(user.Email);
    }
}

/**
 * Sets up the user-specific data for walkme to consume.
 * The "boot up" script can be found in the config
 * The loading of the script can be found in the TrackingLoader component
 * @param user
 */
function configureWalkme(user) {
    window.walkme = {
        userAge: user.UserAge,
        email: user.Email,
        fullName: `${user.FirstName} ${user.LastName}`,
        privilegeAdmin: user.UserPermissions.BranchAdmin || user.UserPermissions.CompanyAdmin,
        privilegeAssignment: user.UserPermissions.WorkQueueAssignment,
        privilegeProtocol: user.UserPermissions.EditProtocols,
        privilegeReports: user.UserPermissions.ReportBuilder || user.UserPermissions.ReportViewer,
        companyName: user.CompanyName,
    };
}

/**
 * Starts up Matomo tracking, attaching the script and event array to the DOM
 */
function trackEvent(category, action, name = null, value = null) {
    if (isProd || isStaging) {
        const { _paq } = window;

        if (!category || !action) {
            // eslint-disable-next-line no-console
            return console.warn('No Event Category set for tracked event');
        }

        if (!category || !action) {
            // eslint-disable-next-line no-console
            return console.warn('No Event Action set for tracked event');
        }

        if (_paq) {
            _paq.push(['trackEvent', category, action, name, value]);
        } else {
            // eslint-disable-next-line no-console
            return console.error('Tracking not running.');
        }
    }
}

const trackFaProgramSearch = R.partial(trackEvent, ['Financial Assistance Program Searches', 'Get Program Details']);

function configureMatomo(user) {
    if (window._paq) {
        window._paq.push(['setUserId', user.UserID]);
        window._paq.push(['trackEvent', 'Authentication', 'Login', `${user.CompanyName} - ${user.BranchName}`, 1]);
    }
}

export { configureMatomo, configureNewRelic, configureWalkme, trackEvent, trackFaProgramSearch };
