import * as R from 'ramda';

function truncateFilter() {
    return function truncate(text, max = 50) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign, radix
        max = Math.max(0, parseInt(max));

        if (R.is(String, text)) {
            return R.take(max, text);
        }
        return text;
    };
}

export default truncateFilter;
