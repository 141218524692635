import * as R from 'ramda';

/**
 * @typedef NameObject
 * @param {string} firstName
 * @param {string} lastName
 */

/**
 * Takes a name object and returns the surame. (Last, First).
 * If one value is missing, does not include the comma.
 * If no object exists, returns a dash
 * @param {NameObject}
 */
const formatName = R.ifElse(
    R.equals('--'),
    R.identity,
    // prettier-ignore
    R.compose(
        R.join(', '),
        R.filter(R.identity),
        R.juxt([R.prop('lastName'), R.prop('firstName')]))
);

export default formatName;
