import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { TextField } from 'Lib/form/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as R from 'ramda';
import SubmitButton from 'Lib/form/SubmitButton';
import FormButtonGroup from '../styles/FormButtonGroup';
import { Button } from 'react-bootstrap';

//#region PropTypes
const propTypes = {
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    show: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    value: PropTypes.object,
    title: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    meds: PropTypes.array,
    modalState: PropTypes.func.isRequired,
    updateMedicationsState: PropTypes.func.isRequired,
};

const defaultProps = {
    show: false,
};

//#endregion

function MedicationModal({ onHide, show, onSave, value, title, meds, modalState, updateMedicationsState }) {
    const validationSchema = Yup.object().shape({
        medication: Yup.string()
            .required('Please enter a medication.')
            .test('not-empty', 'Please enter a medication.', (val) => {
                return val !== undefined && val.trim() !== '';
            })
            .max(150, 'Medication name must not exceed 150 characters .')
            .test('unique-medication', 'This medication already exists in this therapeutic category.', (val) => {
                return !R.find(R.propEq(val, 'name'), meds);
            }),
    });

    const saveMedication = (values) => {
        modalState(null);
        if (value.isNew) updateMedicationsState([...meds, value]);
        onSave(value, values.medication);
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{ medication: value.name }}
                validationSchema={validationSchema}
                onSubmit={saveMedication}
            >
                {({ handleSubmit, setFieldValue, setFieldTouched, values }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Modal.Body>
                                <TextField
                                    id="medication"
                                    label="Medication"
                                    required
                                    fieldName="medication"
                                    value={values.medication}
                                    onChange={(e) => {
                                        setFieldTouched('medication', true);
                                        setFieldValue('medication', e.target.value);
                                    }}
                                    placeholder="Medication"
                                />
                            </Modal.Body>

                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button bsStyle="default" onClick={onHide} type="button">
                                        Cancel
                                    </Button>
                                    <SubmitButton>Save</SubmitButton>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
}

MedicationModal.propTypes = propTypes;
MedicationModal.defaultProps = defaultProps;

export default MedicationModal;
