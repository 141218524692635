import React from 'react';
import ErrorMessage from './ErrorMessage';

function NotFound() {
    return (
        <ErrorMessage>
            404. Not found.
            <br />
            <br />
            The requested URL was not found on this server.
        </ErrorMessage>
    );
}

export default NotFound;
