/** @ngInject */
function ReferralManagerConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.referralManager',
        url: '/patient/:patientId/referral-manager',
        views: {
            '': 'referralManager',
            'pageTitle@^': {
                template: 'Referral Manager',
            },
        },
        resolve: {
            patient($transition$, patientService) {
                const params = $transition$.params();
                return patientService.get(params.patientId).catch(() => {
                    return false;
                });
            },
            referrals($transition$, rmsService) {
                const params = $transition$.params();
                return rmsService.getReferrals(params.patientId).catch(() => {
                    return [];
                });
            },
            rxs($transition$, patientService) {
                const params = $transition$.params();
                return patientService.getPrescriptions(params.patientId, true).catch(() => {
                    return [];
                });
            },
        },
    });
}

export default ReferralManagerConfig;
