/** @ngInject */
function PDXFindPatientConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.pdxFindPatient',
        url: '/pdxFindPatient',
        views: {
            '': 'pdxFindPatient',
            'pageTitle@^': {
                template: 'Find Patients in TherigySTM & Rx.com',
            },
        },
        params: {
            firstName: null,
            lastName: null,
        },
        resolve: {
            lockedFields(pdxLockedFields) {
                return pdxLockedFields;
            },
        },
        redirectTo(trans) {
            return (
                trans
                    .injector()
                    .getAsync('user')
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line consistent-return
                    .then((res) => {
                        if (!res.company_permissions.Pdx) {
                            window.location = `/#!/findPatient`
                        }
                    })
            );
        },
    });
}

export default PDXFindPatientConfig;
