/** @ngInject */
function VideoService($http, urlBuilderService) {
    return {
        getAllDrugVideos,
        getAllTherapyVideos,
    };

    function getAllDrugVideos() {
        const url = urlBuilderService.build('videos', {
            resource: '',
            videos: '',
            drug: '',
        });

        return $http.get(url).then((res) => {
            return res.data;
        });
    }

    function getAllTherapyVideos() {
        const url = urlBuilderService.build('videos', {
            resource: '',
            videos: '',
            condition: '',
        });

        return $http.get(url).then((res) => {
            return res.data;
        });
    }
}

export default VideoService;
