import PropTypes from 'prop-types';

const medicationAssociationPropType = PropTypes.shape({
    id: PropTypes.number,
    displayName: PropTypes.string,
    medication: PropTypes.shape({
        medicationName: PropTypes.string,
    }),
    rx: PropTypes.shape({
        rxNumber: PropTypes.string,
        physician: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            npi: PropTypes.string,
        }),
    }),
});

export default medicationAssociationPropType;
