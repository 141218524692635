import * as R from 'ramda';
import { hasLength } from '../../../../utils';

/** @ngInject */
function WorkQueueSavedFiltersController(_, toastr, workQueueService, CSRF_ID_VALUE, CSRF_ID_NAME) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;

    ctrl.doChangeFilter = doChangeFilter;
    ctrl.deleteSavedFilter = deleteSavedFilter;
    ctrl.setFilters = setFilters;

    ctrl.filterName = '';
    ctrl.maxFiltersLength = 5;
    ctrl.showAllFilters = false;
    ctrl.savedFilters = [];
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    function $onChanges(changes) {
        if (changes.filters && ctrl.filters) {
            ctrl.setFilters(angular.copy(ctrl.filters));
        }
    }

    function $onInit() {
        ctrl.loading = true;
        return workQueueService
            .getFilters()
            .then((res) => {
                ctrl.setFilters(res);
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }

    function doChangeFilter(index) {
        let filter = ctrl.savedFilters[index];
        filter = _.extend(workQueueService.getDefaultFilter(ctrl.dateRange), filter);
        if (filter) {
            ctrl.onChangeFilter({
                filter: filter.filter,
            });
        }
    }

    function deleteSavedFilter(uuid) {
        return workQueueService.deleteFilter({ uuid }).then((res) => {
            toastr.success('Filter has been deleted successfully.');
            ctrl.setFilters(res);
        });
    }

    function setFilters(filters) {
        ctrl.savedFilters = R.compose(
            angular.copy,
            R.ifElse(hasLength, R.map(R.over(R.lensProp('filter'), JSON.parse)), R.always([]))
        )(filters);
    }
}

export default WorkQueueSavedFiltersController;
