/** @ngInject */
function ReferralCommunicationsController($scope, NgTableParams, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    $scope.$on('referralCommunicationAdded', (event, data) => {
        return rmsService.getCommunications(data.patient_id, data.referral_id).then((res) => {
            ctrl.tableParams.settings().dataset = res;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-shadow
            ctrl.tableParams.reload().then((data) => {
                if (data.length === 0 && ctrl.tableParams.total() > 0) {
                    ctrl.tableParams.page(ctrl.tableParams.page() - 1);
                    ctrl.tableParams.reload();
                }
            });
            return res;
        });
    });

    function $onChanges() {
        if (ctrl.communications) {
            ctrl.tableParams = new NgTableParams(
                {
                    count: 5,
                    sorting: { created_on: 'desc' },
                },
                {
                    counts: [],
                    dataset: ctrl.communications,
                }
            );

            if (ctrl.tableParams.total() > ctrl.tableParams.count()) {
                ctrl.tableParams.settings({ counts: [5, 10, 25] });
            }
        }
    }
}

export default ReferralCommunicationsController;
