function PDXFindPatientZipCodeFilter() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    return function (input) {
        if (!input) {
            return input;
        }
        if (String(input).length === 9) {
            return `${String(input).slice(0, 5)}-${String(input).slice(5)}`;
        }
        if (String(input).length === 5) {
            return String(input);
        }
        return input;
    };
}

export default PDXFindPatientZipCodeFilter;
