import React from 'react';

function TherigyLoadingSpinner() {
    return (
        <div className="message-therigy-loading-spinner" data-testid="therigy-load-spinner">
            <i id="loading" className="fa fa-3x fa-fw fa-circle-notch fa-spin" />
        </div>
    );
}

export default TherigyLoadingSpinner;
