import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Page from 'App/components/Page';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import { Button, Col, Row } from 'react-bootstrap';
import PatientReferralHeader from './PatientReferralHeader';
import PatientLabels from 'App/components/PatientLabels';
import { FaPlus } from 'react-icons/fa';
import ReferralTable from './ReferralTable';
import CreateReferralModal from './CreateReferralModal';
import * as R from 'ramda';
import useFetchPatient from 'App/hooks/useFetchPatient';
import NotFound from 'App/components/errors/NotFound';
import PageSpinner from 'App/components/PageSpinner';

const propTypes = {};

const defaultProps = {};

function ReferralManagerHome() {
    const [showAddModal, setShowAddModal] = useState(false);
    const { patientId } = useParams();

    const { isLoading, isError, data: patient } = useFetchPatient(patientId);

    if (isLoading) {
        return <PageSpinner />;
    }

    if (isError) {
        return (
            <Page title="Not Found" showPageTitle={false} permissionType={PERMISSION_TYPES.WORK_QUEUE}>
                <NotFound />
            </Page>
        );
    }

    return (
        <Page title="Referral Manager" permissionType={PERMISSION_TYPES.REFERRAL_MANAGER}>
            <div className="container page-wrapper">
                <Row>
                    <Col md={12}>
                        <PatientReferralHeader patient={patient} />
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <PatientLabels patientId={patientId} />
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div
                            style={{
                                marginTop: '1rem',
                                marginBottom: '1rem',
                            }}
                        >
                            <Link to={`/patient/${patientId}`}>Back to Patient Profile</Link>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '1rem',
                            }}
                        >
                            <h3>Referrals</h3>

                            <Button bsStyle="link" onClick={() => setShowAddModal(R.T)}>
                                <FaPlus /> Create Referral
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <ReferralTable patientId={patientId} />
                    </Col>
                </Row>
            </div>

            {showAddModal ? (
                <CreateReferralModal patientId={patientId} onConfirm={Function} onHide={() => setShowAddModal(R.F)} />
            ) : null}
        </Page>
    );
}

ReferralManagerHome.propTypes = propTypes;
ReferralManagerHome.defaultProps = defaultProps;

export default ReferralManagerHome;
