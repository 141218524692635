import * as R from 'ramda';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'App/components/Table';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dateOrDashUtc from 'App/utils/dateOrDashUtc';
import ActionsColumn from './ActionsColumn';
import TemplateNameLink from './TemplateNameLink';
import propOrDash from 'App/utils/propOrDash';
import formatName from './formatName';

dayjs.extend(dayjsUtc);

function tableStateReducer(state, action, prevState) {
    const initialSort = [{ id: 'name', desc: false }];

    const { type } = action;

    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
        default: {
            return state;
        }
    }
}

const propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            uuid: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            updater: PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
            }),
            updatedBy: PropTypes.string,
            updatedOn: PropTypes.string,
            publisher: PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
            }),
            publishedOn: PropTypes.string,
            publishedBy: PropTypes.string,
            rules: PropTypes.arrayOf(PropTypes.shape({})),
        })
    ),
    isLoading: PropTypes.bool,
};

const defaultProps = {
    data: [],
    isLoading: false,
};

function TemplateTable({ data, isLoading }) {
    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: TemplateNameLink,
            },
            {
                Header: 'Updated By',
                accessor: R.compose(formatName, propOrDash('updater')),
            },
            {
                Header: 'Updated On',
                accessor: dateOrDashUtc(['updatedOn']),
            },
            {
                Header: 'Published By',
                accessor: R.compose(formatName, propOrDash('publisher')),
            },
            {
                Header: 'Published On',
                accessor: dateOrDashUtc(['publishedOn']),
            },
            {
                Header: 'Actions',
                Cell: ActionsColumn,
            },
        ];
    }, []);
    return (
        <div data-testid="protocol-template-table">
            <Table
                getKey={R.prop('uuid')}
                data={data}
                columns={columns}
                isLoading={isLoading}
                stateReducer={tableStateReducer}
            />
        </div>
    );
}

TemplateTable.propTypes = propTypes;
TemplateTable.defaultProps = defaultProps;

export default TemplateTable;
