import { useEffect, useMemo, useState } from 'react';

import useFetchPatientActivityFiles from '../../../hooks/useFetchPatientActivityFiles';
import { RefetchFlags } from '../constants';
import useGetPatientData from './useGetPatientData';
import useGetPatientActivities from './useGetPatientActivities';
import useGetPatientSubscriptions from './useGetPatientSubscriptions';
import useGetPatientTherapies from './useGetPatientTherapies';
import useGetPatientMedications from './useGetPatientMedications';
import usePatientEhr from './usePatientEhr';

/**
 * Custom hook which make all the http call to fetch details required to render patient detail page
 * @param {string} patientId
 * @returns Object containing profile data
 */
function usePatientProfile(patientId) {
    const { patientData, isPatientDataLoading } = useGetPatientData(patientId);
    const { activities, isActivityLoading, triggerActivityRefetch } = useGetPatientActivities(patientId);
    const { patientMessagingSubscription, isSubscriptionLoading, triggerSubscriptionRefetch } =
        useGetPatientSubscriptions(patientId);
    const { therapies, isTherapyLoading, triggerTherapyRefetch } = useGetPatientTherapies(patientId);
    const { medications, isMedicationLoading, triggerMedicationRefetch } = useGetPatientMedications(patientId);
    const { ehr, isEhrLoading } = usePatientEhr(patientId);
    const { data: attachments, isLoading: isAttachmentsLoading } = useFetchPatientActivityFiles(patientId);
    const [refetchFlag, setRefetchFlag] = useState(null);

    /**
     * This is fine for now, but ideally, we want to move all this data into react-query, and then to refetch we could
     * - update the values directly thorugh the query client, which will cause things using hook to re-render with the
     *   new data.
     * - re-use the hook for the data "slicke" and call refetch directly out of _that_ hook
     * - or invalidate the cache, which will cause react-query to refetch
     */
    useEffect(() => {
        if (refetchFlag) {
            switch (refetchFlag) {
                case RefetchFlags.activitiesAndReferral:
                    triggerActivityRefetch();
                    break;
                case RefetchFlags.messagingSubscriptions:
                    triggerSubscriptionRefetch();
                    break;
                case RefetchFlags.unassignedMedications: {
                    triggerMedicationRefetch();
                    triggerTherapyRefetch();
                    triggerActivityRefetch();
                    break;
                }
                default:
                // do nothing
            }
            setRefetchFlag(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchFlag]);

    const isProfileLoading = useMemo(() => {
        return (
            isPatientDataLoading ||
            isSubscriptionLoading ||
            isTherapyLoading ||
            isMedicationLoading ||
            isEhrLoading ||
            isAttachmentsLoading
        );
    }, [
        isPatientDataLoading,
        isSubscriptionLoading,
        isTherapyLoading,
        isMedicationLoading,
        isEhrLoading,
        isAttachmentsLoading,
    ]);

    return {
        isProfileLoading,
        isTherapyLoading,
        isActivityLoading,
        patientData,
        activities,
        patientMessagingSubscription,
        therapies,
        medications,
        ehr,
        attachments,
        setRefetchFlag,
    };
}

export default usePatientProfile;
