/** @ngInject */
function ContentLibraryMonographEditorController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = () => {
        ctrl.loading = true;
    };

    function $onChanges(changes) {
        if (changes.contentVersion && ctrl.contentVersion) {
            ctrl.contentVersion = angular.copy(ctrl.contentVersion);
            angular.element(() => {
                createEditor(ctrl.contentVersion);
                // this is to add the class codemirror adds to its pre tags for styling.
                const codeMirrorPlaceholderElements = document.querySelectorAll('.CodeMirror-placeholder');
                for (const codeMirrorPlaceholderElement of codeMirrorPlaceholderElements) {
                    codeMirrorPlaceholderElement.classList.add('CodeMirror-line-like');
                }
            });
        }
    }

    function handleChange(contentVersion, editor) {
        if (editor) {
            const value = editor.getValue ? editor.getValue() : editor.getTextObject();
            // eslint-disable-next-line no-param-reassign
            contentVersion.content = value || '';
            doChangeContentVersion();
        }
    }

    function createEditor(contentVersion) {
        const editor = new tui.Editor({
            usageStatistics: false,
            el: document.querySelector(`#${contentVersion.label}-${contentVersion.uuid}`),
            previewStyle: 'vertical',
            height: '400px',
            initialEditType: 'markdown',
            hideModeSwitch: false,
            placeholder: 'Add content here',
            events: {
                stateChange: () => {
                    handleChange(contentVersion, editor);
                },
                change: () => {
                    handleChange(contentVersion, editor);
                },
            },
            exts: ['scrollSync', 'table'],
            toolbarItems: [
                'heading',
                'bold',
                'italic',
                'strike',
                'divider',
                'ul',
                'ol',
                'divider',
                'table',
                'link',
                'divider',
            ],
        });

        ctrl.loading = false;
        // eslint-disable-next-line no-unused-expressions
        editor.setValue
            ? editor.setValue(contentVersion.content, false)
            : editor.setMarkdown(contentVersion.content, false);
        return editor;
    }

    function doChangeContentVersion() {
        ctrl.onChangeContentVersion({
            contentVersion: ctrl.contentVersion,
        });
    }
}

export default ContentLibraryMonographEditorController;
