import * as R from 'ramda';
import { isExpired } from 'App/utils';
import { v4 as uuid } from 'uuid';
import hasLength from 'App/utils/hasLength';

const isNotExpired = R.complement(isExpired);

function preparePresciptions(patientPrescriptions, medication, viewProfile, showEndedMedications) {
    let filteredPrescriptions = R.pick([medication.id], patientPrescriptions);

    if (R.isEmpty(filteredPrescriptions)) {
        return filteredPrescriptions;
    }

    const rxNumbersLens = R.lensPath([medication.id, 'rxNumbers']);

    filteredPrescriptions = R.over(
        rxNumbersLens,
        R.ifElse(R.identity, R.identity, R.always([])),
        filteredPrescriptions
    );
    if (viewProfile) {
        if (!showEndedMedications) {
            filteredPrescriptions = R.over(
                rxNumbersLens,
                R.compose(
                    R.map(
                        R.compose(
                            R.over(R.lensProp('_internalUiId'), uuid),
                            R.over(
                                R.lensProp('rxFill'),
                                R.ifElse(
                                    hasLength,
                                    R.compose(
                                        R.flip(R.append)([]),
                                        R.head,
                                        R.sortWith([R.descend(R.prop('createdOn')), R.descend(R.prop('fillNumber'))])
                                    ),
                                    R.identity
                                )
                            )
                        )
                    ),
                    R.filter(R.compose(R.anyPass([R.equals(null), isNotExpired]), R.prop('end')))
                ),
                filteredPrescriptions
            );
        }
    } else {
        filteredPrescriptions[medication.id] = patientPrescriptions[medication.id];
    }

    filteredPrescriptions = R.over(
        R.lensPath([medication.id, 'rxNumbers']),
        R.sortBy(R.prop('ctime')),
        filteredPrescriptions
    );

    return filteredPrescriptions;
}

export { preparePresciptions };
