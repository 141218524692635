/** @ngInject */
function AssessmentQuestionRadioController(_, $scope, $state, $uibModal, amsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeAnswer = doChangeAnswer;
    ctrl.doShowContent = doShowContent;

    // TODO: Rapid3 hack
    ctrl.hideScoringGroups = [
        32, // QOL Assessment
        48, // QOL Assessment
        52, // QOL Assessment
        56, // QOL Assessment
        97, // RAPID3 Questionnaire
    ];

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
        }

        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);
            if (ctrl.question.attributes.group_id) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line radix
                ctrl.question.attributes.group_id = parseInt(ctrl.question.attributes.group_id);
            }
            ctrl.answers = _.values(ctrl.question.answers);
            ctrl.response = angular.copy(ctrl.question.response);
            if (ctrl.question.children) {
                ctrl.childQuestions = amsService.formatQuestions(ctrl.question.children);
            }
        }
        ctrl.oldAnswer = ctrl.response;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function _doChangeAnswer(answer) {
        ctrl.oldAnswer = ctrl.response;
        const unselectedAnswers = _.keys(_.omit(ctrl.question.answers, [answer.id]));

        ctrl.onChangeAnswer({
            answer: ctrl.response,
            unselectedAnswers,
        });

        if (answer && Array.isArray(answer.children) && answer.children.length > 0) {
            return amsService.getActivityQuestionsById(ctrl.activity.id, answer.children).then((res) => {
                $scope.$emit('updateAssessmentQuestions', angular.copy(res));
                amsService.formatRapid3Questions(res);
                let childQuestions = amsService.formatQuestions(res);
                childQuestions = _.keyBy(childQuestions, 'questionId');
                ctrl.childQuestions = amsService.attachChildren(childQuestions);
            });
        }
        ctrl.childQuestions = null;
    }

    function doChangeAnswer(answer) {
        const numberOfAnswers = ctrl.getNumberOfAnswers({
            questionId: ctrl.question.id,
        });
        if (numberOfAnswers > 0) {
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                component: 'confirmDeleteChildrenModal',
                keyboard: false,
                size: 'md',
                resolve: {
                    modalData() {
                        return {
                            numberOfAnswers,
                        };
                    },
                },
            });
            modalInstance.result
                .then((doAction) => {
                    if (doAction) {
                        _doChangeAnswer(answer);
                    } else {
                        ctrl.response = ctrl.oldAnswer;
                    }
                })
                .catch((err) => {
                    return err.data;
                });
        } else {
            _doChangeAnswer(answer);
        }
    }

    function doShowContent(label) {
        $scope.$emit('openAssessmentContentModal', label);
    }
}

export default AssessmentQuestionRadioController;
