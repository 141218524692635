import React from 'react';

import SelectField from 'Lib/form/SelectField';
import useFetchPatientVisits from '../hooks/useFetchPatientVisits';
import { patientPropType } from 'Lib/types';
import { dateOnlySlashesFormat, formatUtcDate } from 'App/services/DateService';

const EhrEncounterVisitContent = ({ patient }) => {
    const { data: patientVisits } = useFetchPatientVisits(patient.id);

    return (
        <div>
            <SelectField
                fieldName="associatedVisit"
                label="Visit"
                required
                options={!patientVisits ? [] : Object.values(patientVisits)}
                getOptionLabel={(v) => {
                    const formattedDate = formatUtcDate({ date: v.visitDateTime, format: dateOnlySlashesFormat });
                    const providerName =
                        !v.providerFirstName && !v.providerLastName
                            ? '--'
                            : `${v.providerFirstName} ${v.providerLastName}`;
                    return `${v.visitId}, ${formattedDate}, ${v.department || '--'}, ${providerName}`;
                }}
            />
        </div>
    );
};

EhrEncounterVisitContent.propTypes = {
    patient: patientPropType.isRequired,
};

export default EhrEncounterVisitContent;
