/**
 * Add Patient Demographics service controller.
 */
/** @ngInject */
function DemographicService($http, $q, urlBuilderService) {
    const vm = this;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    vm.checkDuplicatePatient = function (patientData) {
        // API endpoint only supports yyyy-mm-dd
        if (typeof patientData.birthdate !== 'object') {
            throw new Error('Invalid birthdate provided');
        }
        const birthdate = patientData.birthdate.toISOString().substr(0, 10);
        return $http({
            method: 'get',
            url: urlBuilderService.get('duplicatePatient'),
            params: {
                first_name: patientData.firstName,
                last_name: patientData.lastName,
                gender: patientData.gender,
                birth_date: birthdate,
            },
            paramSerializer: '$httpParamSerializerJQLike',
        }).then((res) => {
            return res.data;
        });
    };
}

export default DemographicService;
