import { getReferralHistory } from 'App/services/RMSService';
import { useQuery } from 'react-query';

function useGetReferralHistory(patientId, referralId) {
    return useQuery(
        ['patient', patientId, 'referral', referralId, 'history'],
        () => getReferralHistory(patientId, referralId),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useGetReferralHistory;
