import React from 'react';
import dateOrDash from 'App/utils/dateOrDash';
import pathOrDash from 'App/utils/pathOrDash';
import PropTypes from 'prop-types';
import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';
import { orderBy } from 'lodash';

const propTypes = {
    patientPrescriptions: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
    patientPrescriptions: [],
};

function FillNumberHistory(props) {
    const { patientPrescriptions } = props;
    const prescriptions = patientPrescriptions?.map((patientPrescription) => {
        const updatedPrescription = { ...patientPrescription };
        if (updatedPrescription?.rxFill.length) {
            updatedPrescription.rxFill = orderBy(updatedPrescription.rxFill, 'createdOn', 'desc');
        }
        return updatedPrescription;
    });
    const fillsLength = !prescriptions?.length
        ? 0
        : prescriptions.reduce((agg, rx) => {
              return agg + rx.rxFill.length;
          }, 0);
    return (
        <div>
            <h4 className="header-medication-history-modal">Fill Number History</h4>
            <div>
                {fillsLength ? (
                    <table className="therigy-table">
                        <thead>
                            <tr>
                                <th>Rx Number</th>
                                <th>Fill Number</th>
                                <th>Fill Date</th>
                                <th>Dispensed Qty</th>
                                <th>Days Supply</th>
                            </tr>
                        </thead>
                        <tbody>
                            {prescriptions?.length > 0 &&
                                prescriptions.map(
                                    (rx) =>
                                        rx.rxFill?.length > 0 &&
                                        rx.rxFill.map((fill) => (
                                            <tr className="therigy-row" key={fill.id}>
                                                <td>{pathOrDash(['rxNumber'], rx)}</td>
                                                <td>
                                                    {fill?.fillNumber === 0
                                                        ? fill.fillNumber
                                                        : pathOrDash(['fillNumber'], fill)}
                                                </td>
                                                <td>{dateOrDash(['fillDate'], fill)}</td>
                                                <td>{pathOrDash(['dispensedQuantity'], fill)}</td>
                                                <td>{pathOrDash(['daysSupply'], fill)}</td>
                                            </tr>
                                        ))
                                )}
                        </tbody>
                    </table>
                ) : (
                    <TherigyInfoMessage message={` There is no Fill number history for this medication.`} />
                )}
            </div>
        </div>
    );
}

FillNumberHistory.propTypes = propTypes;
FillNumberHistory.defaultProps = defaultProps;

export default FillNumberHistory;
