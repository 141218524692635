/** @ngInject */
function ReferralActivityPriorAuthorizationConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.referralActivityPriorAuthorization',
        url: '/patient/:patientId/referral/:referralId/prior-authorization/:activityId/type/:typeId',
        views: {
            '': 'referralActivityPriorAuthorization',
            'pageTitle@^': {
                template: 'Prior Authorization Activity',
            },
        },
        resolve: {
            activity($transition$, rmsService) {
                const params = $transition$.params();
                return rmsService
                    .getActivityByType(params.patientId, params.referralId, params.activityId, params.typeId)
                    .catch(() => {
                        return false;
                    });
            },
            patient($transition$, patientService) {
                const params = $transition$.params();
                return patientService.get(params.patientId).catch(() => {
                    return false;
                });
            },
        },
    });
}

export default ReferralActivityPriorAuthorizationConfig;
