import { useMutation, useQueryClient } from 'react-query';
import { updateReferralActivity } from '@/app/services/RMSService';
import errorHandler from '@/app/utils/errorHandler';

function useUpdateReferralActivityMutation() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ patientId, referralId, activityId, data }) =>
            updateReferralActivity(patientId, referralId, activityId, data),
        {
            onSuccess: async (referralActivity) => {
                await queryClient.setQueryData(
                    [
                        'patient',
                        referralActivity.patientId,
                        'referral',
                        referralActivity.referralId,
                        'activity',
                        referralActivity.id,
                    ],
                    referralActivity
                );
            },
            onError: errorHandler,
        }
    );
}

export default useUpdateReferralActivityMutation;
