import styled from '@emotion/styled';

const NotesStyle = styled('div')`
    .download-summary {
        font-family: 'Trebuchet MS', Verdana, Helvetica, sans-serif;
        font-size: 12px;
        color: #333;
        background-color: #fff;

        .text {
            font-size: 17px;
            font-weight: bold;
            color: #444;
        }

        .detail {
            font-size: 11px;
            color: #444;
        }

        table {
            width: 100%;
            table-layout: fixed;
            margin-bottom: 10px;

            th,
            td {
                word-wrap: break-word;
            }

            th {
                &.header {
                    font-size: 11px;
                    color: #222;
                    text-align: left;
                    padding-top: 10px;
                    border-top: 2px solid black;
                }
            }

            td {
                &.content {
                    font-size: 9px;
                    color: #444;
                    text-align: left;
                }

                &.note {
                    font-size: 10px;
                    color: #222;
                    text-align: left;
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 10px;
                    padding-top: 10px;
                }

                &.time {
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 10px;
                    padding-top: 10px;
                }

                &.date {
                    width: 10%;
                }

                &.completed {
                    width: 10%;
                }

                &.history {
                    color: #666;
                }

                &.no-bottom-border {
                    border-bottom: 0;
                }
            }
        }
    }
`;

export default NotesStyle;
