import axios from 'axios';
import createAxios from 'App/utils/createAxios';
import config from '../components/config/config';
import getAuthorizationHeader from '../utils/getAuthorizationHeader';
import getData from '../utils/getData';
import getSlsBasePath from '../utils/getSlsBasePath/getSlsBasePath';
import { setup } from 'axios-cache-adapter';
import * as R from 'ramda';
import { queryClient } from '@/lib/queryClient';
import { addPageAction } from 'App/services/NewRelicService';

const slsHost = getSlsBasePath(config, 'company');

const company = createAxios({
    baseURL: `${slsHost}/company/${config.slsVersion}`,
});

const therapyCacheApi = setup({
    baseURL: `${slsHost}/${config.slsVersion}`,
    cache: {
        maxAge: 60 * 60 * 1000,
        invalidate: async (conf, request) => {
            if (request.clearCacheEntry) {
                await conf.store.removeItem(conf.uuid);
            }
        },
    },
});

class CompanyService {
    static company = createAxios({
        baseURL: `${slsHost}/${config.slsVersion}`,
    });

    /**
     * Fetches all custom menus for a company.
     * @returns {Promise<customMenuOptions>} - An array of custom menu options.
     */
    static getCustomMenus() {
        return axios.get(`${slsHost}/${config.slsVersion}/custom-menu`, getAuthorizationHeader()).then((res) => {
            for (const [menuName, menuOptions] of Object.entries(res.data)) {
                window.localStorage.setItem(`custom_menu_${menuName}`, JSON.stringify(menuOptions));
            }
            return res.data;
        });
    }

    /**
     * Fetches a custom menu.
     * @param menuName - The custom menu name.
     * @returns {*[]|*} - An array of custom menu options.
     */
    static getCustomMenu(menuName) {
        let menuOptions = window.localStorage.getItem(`custom_menu_${menuName}`);
        try {
            menuOptions = JSON.parse(menuOptions);
            return menuOptions;
        } catch (e) {
            return [];
        }
    }

    /**
     * Fetches the available therapeutic categories.
     * @returns {Promise<categories>} - A map of therapeutic categories keyed by ID.
     */
    static getTherapyCategory(therapyId) {
        return axios
            .get(`${slsHost}/${config.slsVersion}/therapy/${therapyId}`, getAuthorizationHeader())
            .then(getData);
    }

    /**
     * Fetches the available therapeutic categories.
     * @param {bool} clearCacheEntry - if the cache should be cleared.
     * @returns {Promise<categories>} - A map of therapeutic categories keyed by ID.
     */
    static getTherapyCategories(clearCacheEntry = false) {
        return therapyCacheApi.get(`/therapy`, { clearCacheEntry, ...getAuthorizationHeader() }).then(getData);
    }

    /**
     * Fetches the available diagnosis codes for the provided therapeutic category.
     * @param {string} therapyId - The ID of the category.
     * @param {number} type - The diagnosis revision number.
     * @returns {Promise<diagnosisCodes>}
     */
    static getTherapyDiagnosis(therapyId, type = 10) {
        return axios
            .get(`${slsHost}/${config.slsVersion}/therapy/${therapyId}/diagnosis/${type}`, getAuthorizationHeader())
            .then(getData);
    }

    /**
     * Fetches the available medications for the provided therapeutic cateogry
     * @param {string} therapyId - The ID of the category.
     * @returns {Promise<medications>}
     */
    static getTherapyMedications(therapyId) {
        return axios
            .get(`${slsHost}/${config.slsVersion}/therapy/${therapyId}/medication`, getAuthorizationHeader())
            .then(getData);
    }

    static getCompanyUsers(params) {
        return company.get('/users', {
            params,
        });
    }

    static getLicencedMedications() {
        return company.get('/medications');
    }

    static getServerTime() {
        return company.get('/resource/serverTime');
    }

    static lockForUpdate(lockData) {
        return company.put('/resource/lastSaved', lockData);
    }

    /**
     * @typedef BranchSetting
     * @property {string} uuid
     * @property {string} branchId
     * @property {string} companyId
     * @property {string} keyValue
     * @property {string} keyType
     */

    /**
     * Fetches all branch settings for all branches for the current company
     * @param {string} keyType - The ID of the category.
     * @returns {Promise<BranchSetting[]>}
     */
    static getAllBranchSettingsForType(keyType) {
        return this.company.get(`/setting/${keyType}/all`);
    }

    /**
     * Fetches the branch setting for the user. assumes current users branchId is the one they want.
     * @param {string} keyType - The ID of the category.
     * @param {string} defaultValue - default value
     * @param {Boolean} convertToBoolean - converts ['true', '1'] to true, other values will return false
     * @param {string} branchId - the ID of the desired branch to search for setting (optional)
     * @returns {Promise<any>}
     */
    static getBranchSettings(keyType, defaultValue, convertToBoolean, branchId = null, fetchAll = false) {
        return this.company.get(`/setting/${keyType}`, {
            params: { defaultValue, convertToBoolean, branchId, fetchAll },
        });
    }

    /**
     * Fetches EHR related branch settings
     * @returns {Promise<object>} A keyed hash of all EHR branch settings for the current branch
     */
    static getEhrBranchSettings() {
        return axios.get(`${slsHost}/${config.slsVersion}/ehr/settings`, getAuthorizationHeader()).then(getData);
    }

    /**
     * Updates api medication configuration for enrollment/management
     * @param therapyId
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    static patchApiMedications = async (therapyId, data) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        data = R.map(R.reject(R.equals(null)))(data);
        return axios.patch(
            `${slsHost}/${config.slsVersion}/managedMedications/${therapyId}`,
            data,
            getAuthorizationHeader()
        );
    };

    /**
     * Fetches all dev features for a company.
     * @returns {Promise<featureFlagOptions>} - An array of dev feature flags options.
     */
    static getDevFeatureFlags = async () => {
        return this.company.get(`/features`).then(R.compose(R.map(R.prop('isEnabled')), R.indexBy(R.prop('name'))));
    };

    /**
     * Fetches a dev feature flag.
     * @param flagName - The dev feature flag name.
     * @returns {boolean} - true or false.
     */
    static getFeatureFlagStatus(flagName) {
        const flags = queryClient.getQueryData(['featureFlags']);

        if (flags === undefined) {
            console.error('Feature Flags were queried, but flags was not set.');
            addPageAction('feature-flag-queried-before-set');
            return false;
        }

        if (flags[flagName] === undefined) {
            addPageAction('feature-flag-queried-none-exist', { flagName });
            console.error('Feature flags were queried for a flag that does not exist.');
            return false;
        }

        return R.compose(Boolean, R.propOr(false, flagName))(flags);
    }

    /**
     * Get Required Patient Fields
     * @param params - The filter params.
     * @returns {Promise} - A required patient fields response object
     */
    static getRequiredPatientFields(companyId) {
        return this.company.get(`/company/${companyId}/requiredPatientFields`);
    }

    /**
     * Gets sso configuration for a particular company.
     * @param therapyId
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getSsoCompanyConfiguration(companyId) {
        return company.get(`/ssoConfiguration/${companyId}`, {
            clearCacheEntry: true,
        });
    }

    /**
     * Adds sso configuration for a particular company.
     * @param therapyId
     * @returns {Promise<AxiosResponse<any>>}
     */
    static addSsoCompanyConfiguration(ssoConfig) {
        return company.post(`/ssoConfiguration/`, ssoConfig);
    }

    /**
     * Get unassigned medication count.
     * @param filter and search parameters
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getUnassignedMedicationCount(params) {
        return this.company.post(`/company/unassigned-medication-count`, params);
    }

    /**
     * Get unassigned medication list.
     * @param filter and search parameters
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getUnassignedMedication(params) {
        return this.company.post(`/company/unassigned-medication`, params);
    }

    /**
     * Updates sso configuration for a particular company.
     * @param therapyId
     * @returns {Promise<AxiosResponse<any>>}
     */
    static updateSsoCompanyConfiguration(ssoConfig) {
        return company.put(`/ssoConfiguration/`, ssoConfig);
    }
}

export default CompanyService;
