/** @ngInject */
function WorkQueuePredictiveModelFilterController() {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.doChangeFilter = doChangeFilter;

    ctrl.predictiveModelRisk = false;

    function $onChanges(changes) {
        if (changes.predictiveModelRisk && ctrl.predictiveModelRisk !== undefined) {
            ctrl.predictiveModelRisk = angular.copy(ctrl.predictiveModelRisk);
        }
    }

    function doChangeFilter() {
        ctrl.onChangeFilter({
            filter: {
                predictiveModelRisk: ctrl.predictiveModelRisk,
            },
        });
    }
}

export default WorkQueuePredictiveModelFilterController;
