import React from 'react';
import compose from 'ramda/src/compose';
import identity from 'ramda/src/identity';
import map from 'ramda/src/map';
import path from 'ramda/src/path';
import sortBy from 'ramda/src/sortBy';
import uniq from 'ramda/src/uniq';

const getOptions = (id, rows) => compose(sortBy(identity), uniq, map(path(['values', id])))(rows);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) {
    // Calculate the options for filtering. This is dynamic based on the values set in the data
    // using the preFilteredRows
    const options = React.useMemo(() => {
        return getOptions(id, preFilteredRows);
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={compose(setFilter, path(['target', 'value']))}
            className="filter filter-select form-control "
            aria-label={`${Header} Filter`}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/no-array-index-key
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

export default SelectColumnFilter;
