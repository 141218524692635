import React from 'react';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SafeHtmlOnly from 'App/components/SafeHtmlOnly/SafeHtmlOnly';
import PatientHighRiskIndicator from 'App/components/PatientHighRiskIndicator';
import { PatientPredictiveModelRiskIndicator, PatientPredictiveModelRiskText } from 'App/components/PatientPredictiveModelRisk';
import { cellPropsType } from '../../workQueue.constant';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';

const PatientNameCell = ({ row, value }) => {
    const patientPredictiveModelEnabled = useIsPermitted(PERMISSION_TYPES.PATIENT_PREDICTIVE_MODEL);
    const actualId = R.path(['original', 'patientId'], row);
    const contactInformation = R.path(['original', 'contactInformation'], row);
    const highRisk = R.path(['original', 'highRisk'], row);
    const predictiveModel = R.path(['original', 'predictiveModel'], row);
    const hasPredictiveModelRiskData = patientPredictiveModelEnabled && predictiveModel?.length > 0;

    const informationToolTip = (
        <Tooltip className="patient-contact-information" id="patient-contact-information">
            <SafeHtmlOnly>{contactInformation}</SafeHtmlOnly>
        </Tooltip>
    );

    return (
        <span className={`patient-name patient-${actualId}`}>
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={informationToolTip}>
                <Link className="patient-name-link therigy-table-tooltip" to={`/patient/${actualId}`}>
                    {value}
                </Link>
            </OverlayTrigger>
            {highRisk && <PatientHighRiskIndicator patientId={actualId} showOnHover />}
            {hasPredictiveModelRiskData && (
                <>
                    <PatientPredictiveModelRiskIndicator riskSegments={predictiveModel} showOnHover />
                    <div>
                        <PatientPredictiveModelRiskText
                            riskSegments={predictiveModel}
                            showAll={false}
                            showFlag={false}
                            showOnHover
                        />
                    </div>
                </>
            )}
        </span>
    );
};

PatientNameCell.propTypes = cellPropsType;

export default PatientNameCell;
