import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import ActionCell from '../styles/ActionCell';
import Table from '../Table/Table';
import DiagnosisModal from './DiagnosesModal';
import { Button } from 'react-bootstrap';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    updateDiagnosesState: PropTypes.func.isRequired,
    loadOptions: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    defaultOptions: PropTypes.array,
};

const defaultProps = {
    data: [],
    isLoading: false,
};

const tableStateReducer = (state, action, prevState) => {
    const initialSort = [{ id: 'name', desc: false }];

    const { type } = action;

    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
        default: {
            return state;
        }
    }
};

function DiagnosesTable(props) {
    const [editDiagnosisModalData, setEditDiagnosisModalData] = useState(null);
    const editDiagnosis = (diag, idx) => {
        setEditDiagnosisModalData({
            value: diag,
            index: idx,
            title: 'Edit Diagnosis',
        });
    };
    const addDiagnosis = () => {
        const newDiag = {
            id: uuid(),
            isNew: true,
        };
        setEditDiagnosisModalData({
            value: newDiag,
            title: 'Add Diagnosis',
        });
    };
    //#endregion

    const { onDelete } = props;

    function nameCell({ row }) {
        return (
            <>
                {row.original.code}: {row.original.longDescription}
            </>
        );
    }

    nameCell.propTypes = {
        row: PropTypes.shape({
            original: PropTypes.shape({
                code: PropTypes.string,
                longDescription: PropTypes.string,
            }),
        }),
    };

    function actionCell({ row }) {
        return (
            <ActionCell>
                <Button
                    bsStyle="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                        editDiagnosis(row.original, row.index);
                    }}
                    data-testid={`edit-btn-${row.index}`}
                >
                    Edit
                </Button>
                <Button
                    bsStyle="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                        return onDelete(row.original, row.index);
                    }}
                    data-testid={`delete-btn-${row.index}`}
                >
                    Delete
                </Button>
            </ActionCell>
        );
    }

    actionCell.propTypes = {
        row: PropTypes.shape({
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line react/forbid-prop-types
            orignal: PropTypes.object,
            index: PropTypes.number,
        }),
    };

    //#region RunTime Calculations
    const columns = [
        {
            Header: 'Diagnoses',
            accessor: (row) => `${row.code}: ${row.longDescription}`,
            id: 'name',
            Cell: nameCell,
        },
        {
            Header: 'Actions',
            id: 'action',
            Cell: actionCell,
        },
    ];
    //#endregion

    return (
        <>
            <Table {...props} columns={columns} stateReducer={tableStateReducer} />
            <Button bsStyle="link" onClick={addDiagnosis}>
                <i className="fa fa-solid fa-plus" /> Add Diagnosis
            </Button>
            {editDiagnosisModalData && (
                <DiagnosisModal
                    onHide={() => setEditDiagnosisModalData(null)}
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/destructuring-assignment
                    onSave={props.onSave}
                    show
                    title={editDiagnosisModalData.title}
                    value={editDiagnosisModalData.value}
                    index={editDiagnosisModalData.index}
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/destructuring-assignment
                    diags={props.data}
                    modalState={setEditDiagnosisModalData}
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/destructuring-assignment
                    updateDiagnosesState={props.updateDiagnosesState}
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/destructuring-assignment
                    loadOptions={props.loadOptions}
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/destructuring-assignment
                    defaultOptions={props.defaultOptions}
                />
            )}
        </>
    );
}

DiagnosesTable.propTypes = propTypes;
DiagnosesTable.defaultProps = defaultProps;

export default DiagnosesTable;
