import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';

import { Formik } from 'formik';

import { toast } from 'react-toastify';
import './PatientMessagingEnrollmentModalStyle.scss';
import { errorHandler } from '../../../../utils';
import PESService from '../../../../services/PESService';
import { useUserContext } from 'App/contexts/UserContext';

import UnenrollmentForm from './components/UnenrollmentForm';
import * as Yup from 'yup';
import { patientSubscriptionPropType } from 'Lib/types';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
    subscriptionValue: patientSubscriptionPropType,
    mobilePhone: PropTypes.string,
    patientId: PropTypes.string,
};

const defaultProps = {
    show: false,
    subscriptionValue: null,
    mobilePhone: '',
    patientId: '',
};

const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
const initialValues = {
    phone: '',
};
const validationSchema = Yup.object().shape({
    phone: Yup.string().matches(phoneRegex, 'Invalid phone number.').required('Phone is required'),
});

function PatientMessagingUnenrollmentModal({ onHide, show, subscriptionValue, mobilePhone, patientId }) {
    const title = 'Unenroll from Patient Messaging';
    const user = useUserContext();
    const onSubmit = (values, actions) => {
        const body = { reason: `unsubscribed by ${user.first_name} ${user.last_name}` };
        PESService.updatePatientSubscription(patientId, subscriptionValue.id, body)
            .then(() => {
                actions.setSubmitting(false);
                onHide(true);
                toast.success('Patient Unenrolled successfully');
            })
            .catch(errorHandler);
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static" className="patientMessagingModal">
            <Modal.Header closeButton={false}>
                <Modal.Title className="header">{title}</Modal.Title>
            </Modal.Header>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                <UnenrollmentForm mobilePhone={mobilePhone} onHide={onHide} />
            </Formik>
        </Modal>
    );
}

PatientMessagingUnenrollmentModal.propTypes = propTypes;
PatientMessagingUnenrollmentModal.defaultProps = defaultProps;

export default PatientMessagingUnenrollmentModal;
