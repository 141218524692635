import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTherapeuticCategoriesAccess } from 'App/utils/hooks/useIsPermitted';
import { NotFound } from '../errors';
import Main from '../styles/Main/Main';
import CategoryListPage from './CategoryListPage';
import CreateCategoryPage from './CreateCategoryPage';
import EditCategoryPage from './EditCategoryPage';

const propTypes = {};

const defaultProps = {};

function TherapeuticCategoryRouter() {
    //#region State
    const hasTcPageAccess = useTherapeuticCategoriesAccess();
    const { path } = useRouteMatch();
    //#endregion

    if (hasTcPageAccess) {
        return (
            <Main>
                <Switch>
                    <Route path={`${path}/create`} component={CreateCategoryPage} />

                    <Route path={`${path}/:therapyId`} component={EditCategoryPage} />

                    <Route component={CategoryListPage} />
                </Switch>
            </Main>
        );
    }

    return <NotFound />;
}

TherapeuticCategoryRouter.propTypes = propTypes;
TherapeuticCategoryRouter.defaultProps = defaultProps;

export default TherapeuticCategoryRouter;
