import styled from '@emotion/styled';
import { Risk } from '@stm/arm';
import { useFormikContext } from 'formik';
import React from 'react';

import { activityPropType, questionPropType } from '../types';

const AssessmentQuestionScoringStyle = styled.div`
    font-weight: bold;
    color: var(--brandDanger);
`;

const AssessmentQuestionScoring = ({ question, activity }) => {
    const { values } = useFormikContext();
    const questionValue = values[question.questionId];
    const response = questionValue?.response;

    if (!response) {
        return null;
    }

    function getQuestionScore() {
        const modifiedQuestionValue = { ...questionValue, response: response?.id || response };
        const allScores = Risk.calculate(activity.assessment.scoring, [modifiedQuestionValue]);
        const thisScore = allScores[question.attributes.risk_config];
        return thisScore?.value;
    }

    return <AssessmentQuestionScoringStyle>Question Score: {getQuestionScore()}</AssessmentQuestionScoringStyle>;
};

AssessmentQuestionScoring.propTypes = {
    question: questionPropType.isRequired,
    activity: activityPropType.isRequired,
};

export default AssessmentQuestionScoring;
