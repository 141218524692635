import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BSModal, ModalFooter, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import Button from 'react-bootstrap/lib/Button';

const propTypes = {
    detach: PropTypes.bool,
    isDisabled: PropTypes.bool,
    setDetach: PropTypes.func,
    handleDetach: PropTypes.func,
    removePlan: PropTypes.func,
    activeState: PropTypes.number,
};

const defaultProps = {
    detach: false,
    isDisabled: false,
    setDetach: () => {},
    handleDetach: () => {},
    removePlan: () => {},
    activeState: 1,
};

function AddInsuranceDetachModal({ detach, isDisabled, setDetach, handleDetach, removePlan, activeState }) {
    if (!detach && isDisabled) {
        return null;
    }
    return (
        <BSModal show={detach && !isDisabled} backdrop="static">
            <ModalHeader>
                <ModalTitle>Detach Insurance Plan</ModalTitle>
            </ModalHeader>
            <ModalBody>Are you sure you want to detach this insurance plan?</ModalBody>
            <ModalFooter>
                <Button onClick={() => setDetach(false)}>Cancel</Button>
                <Button
                    className="detach-modal-button"
                    name="detach"
                    onClick={(event) => {
                        handleDetach(event.target.name);
                        removePlan(event, activeState);
                        setDetach(false);
                    }}
                >
                    Detach
                </Button>
            </ModalFooter>
        </BSModal>
    );
}

AddInsuranceDetachModal.propTypes = propTypes;
AddInsuranceDetachModal.defaultProps = defaultProps;

export default AddInsuranceDetachModal;
