import patientLabelPropType from './patientLabel';
import patientHighRiskReasonPropType from './patientHighRiskReason';
import patientMedicationRxPropType from './patientMedicationRx';
import patientOtherConditionPropType from './patientOtherCondition';

export {
    patientLabelPropType,
    patientHighRiskReasonPropType,
    patientMedicationRxPropType,
    patientOtherConditionPropType,
};
