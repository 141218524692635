import config from '../components/config/config';
import convertKeysToCamelCase from '../utils/keyConversion/convertKeysToCamelCase';
import graphClient from '../common/graphClient';
import { gql } from 'graphql-request';
import * as R from 'ramda';
import createAxios from 'App/utils/createAxios';
import getSlsBasePath from '@/app/utils/getSlsBasePath';
import { toUtc } from 'App/services/DateService';

const { rmsEndpoint, rmsPort, rmsVersion } = config;
const baseUrl = `${rmsEndpoint}:${rmsPort}/rms/${rmsVersion}`;

const slsRms = createAxios({
    baseURL: `${getSlsBasePath(config, 'rms')}/${config.slsVersion}`,
});

const rms = createAxios({
    baseURL: baseUrl,
});

/**
 * Fetches the patient's referrals
 * @param patientId - The patient id.
 * @returns {Promise<referrals>} - A map of patient referrals.
 */
function getReferrals(patientId) {
    return rms.get(`/patient/${patientId}/referral`).then(convertKeysToCamelCase);
}

function formatReferral(referral) {
    return R.compose(
        R.over(R.lensProp('created_on'), toUtc),
        R.over(R.lensProp('received_on'), toUtc),
        R.over(R.lensProp('status_date'), toUtc),
        R.over(R.lensProp('updated_on'), toUtc),
        R.ifElse(
            R.prop('updated_name'),
            R.chain(
                R.assoc('user'),
                R.compose(
                    R.trim,
                    R.join(''),
                    R.juxt([
                        R.pathOr('', ['updated_name', 'first_name']),
                        R.always(' '),
                        R.pathOr('', ['updated_name', 'last_name']),
                    ])
                )
            ),
            R.identity
        ),
        R.chain(
            R.assoc('medicationList'),
            R.compose(
                R.join(', '),
                R.sortBy(R.identity),
                R.uniq,
                R.map(R.path(['medication', 'name'])),
                R.prop('medications')
            )
        )
    )(referral);
}

/**
 * @typedef ReferralUpdatePayload
 * @type object
 * @property {string | null} created_on
 * @property {string | null} dispense_status
 * @property {string | null} dispense_status_reason
 * @property {string | null} encounter_id
 * @property {string | null} hub_program_id
 * @property {string | null} no_go_triaged_to
 * @property {string | null} number
 * @property {string | null} received_by
 * @property {string | null} received_on
 * @property {string | null} status
 * @property {string | null} status_reason
 * @property {string | null} status_date
 * @property {string | null} system_id
 * @property {number | null} type
 */

/**
 * Updates the referral data.
 * @param {string} patientId
 * @param {string} referralId
 * @param {ReferralUpdatePayload} data
 * @return {Promise<AxiosResponse<any>>}
 */
function updateReferral(patientId, referralId, data) {
    return rms.put(`/patient/${patientId}/referral/${referralId}`, data).then(convertKeysToCamelCase);
}

/**
 * Gets a specific referral for a given patient
 * @param {string} patientId
 * @param {string} referralId
 * @return {Promise<AxiosResponse<any>>}
 */
function getReferral(patientId, referralId) {
    return rms.get(`/patient/${patientId}/referral/${referralId}`).then(convertKeysToCamelCase);
}

function getReferralHistory(patientId, referralId) {
    return rms.get(`/patient/${patientId}/referral/${referralId}/history`).then(convertKeysToCamelCase);
}

function fetchReferralActivityNotes(activityId) {
    return rms.get(`/referrals/notes/${activityId}`).then(convertKeysToCamelCase);
}

function createReferralActivityNote(activityId, note) {
    return rms.post(`/referrals/notes/${activityId}`, note);
}

/**
 * Update patient referral activity due date
 * @param activity - The activity object.
 * @param dateDue - The new due date
 * @returns {Promise<Activity>} - update activity.
 */
function updateActivityReferrals(activity, dateDue) {
    const body = {
        completed_on: activity.completedOn,
        created_on: activity.createdOn,
        current_employee_id: activity.currentEmployeeId,
        current_employee_time: activity.currentEmployeeTime,
        due_on: dateDue,
        type: activity.type,
        status: activity.status,
        status_reason: activity.statusReason,
        status_date: activity.statusDate,
        details: {
            status: activity.noteStatus,
        },
    };

    return rms
        .put(`/patient/${activity.patientId}/referral/${activity.referralId}/activity/${activity.id}`, body)
        .then(convertKeysToCamelCase);
}

function getReferralActivityBiPlan(patientId, referralId, activityId) {
    return graphClient
        .request(
            gql`
                query getReferralActivityBiPlan($patientId: String!, $referralId: Int, $activityId: Int) {
                    getReferralActivityBiPlan(patientId: $patientId, referralId: $referralId, activityId: $activityId) {
                        id
                        position
                        referralId
                        activityId
                        answerId
                        patientId
                        createdOn
                        dateTime
                        coverageType
                        payerSegment
                        planPatientId
                        name
                        medicalProductLine
                        pharmacyProductLine
                        groupNumber
                        payerIdCode
                        pharmacyInsurancePersonCode
                        deductableAmount
                        deductableAmountPaidToDate
                        pharmacyPlanOopMax
                        pharmacyPlanOopMaxPaidToDate
                        coverageEffectiveDate
                        coverageEndDate
                        isIntegratedHighDeductable
                        isPharmacyBenefitsCarveOutFromMedical
                        pharmacyInsuranceGroupId
                        pharmacyInsuranceBin
                        pharmacyInsurancePcn
                        helpDeskPhone
                        patientInsuranceUuid
                    }
                }
            `,
            { patientId, referralId, activityId }
        )
        .then(R.propOr([], 'getReferralActivityBiPlan'));
}

function addReferralActivityBiPlan(patientId, patientInsuranceUuids, referralId, activityId) {
    return graphClient
        .request(
            gql`
                mutation addReferralActivityBiPlan(
                    $patientId: String!
                    $patientInsuranceUuids: [String]
                    $referralId: Int
                    $activityId: Int
                ) {
                    addReferralActivityBiPlan(
                        patientId: $patientId
                        patientInsuranceUuids: $patientInsuranceUuids
                        referralId: $referralId
                        activityId: $activityId
                    ) {
                        id
                        position
                        referralId
                        activityId
                        answerId
                        patientId
                        createdOn
                        dateTime
                        coverageType
                        payerSegment
                        planPatientId
                        name
                        medicalProductLine
                        pharmacyProductLine
                        groupNumber
                        payerIdCode
                        pharmacyInsurancePersonCode
                        deductableAmount
                        deductableAmountPaidToDate
                        pharmacyPlanOopMax
                        pharmacyPlanOopMaxPaidToDate
                        coverageEffectiveDate
                        coverageEndDate
                        isIntegratedHighDeductable
                        isPharmacyBenefitsCarveOutFromMedical
                        pharmacyInsuranceGroupId
                        pharmacyInsuranceBin
                        pharmacyInsurancePcn
                        helpDeskPhone
                        patientInsuranceUuid
                    }
                }
            `,
            { patientId, patientInsuranceUuids, referralId, activityId }
        )
        .then(R.propOr({}, 'addReferralActivityBiPlan'));
}

function deleteReferralActivityBiPlan(patientId, referralId, activityId, patientInsuranceUuid) {
    return graphClient
        .request(
            gql`
                mutation deleteReferralActivityBiPlan(
                    $patientId: String!
                    $referralId: Int
                    $activityId: Int
                    $patientInsuranceUuid: String
                ) {
                    deleteReferralActivityBiPlan(
                        patientId: $patientId
                        referralId: $referralId
                        activityId: $activityId
                        patientInsuranceUuid: $patientInsuranceUuid
                    ) {
                        id
                        position
                        referralId
                        activityId
                        answerId
                        patientId
                        createdOn
                        dateTime
                        coverageType
                        payerSegment
                        planPatientId
                        name
                        medicalProductLine
                        pharmacyProductLine
                        groupNumber
                        payerIdCode
                        pharmacyInsurancePersonCode
                        deductableAmount
                        deductableAmountPaidToDate
                        pharmacyPlanOopMax
                        pharmacyPlanOopMaxPaidToDate
                        coverageEffectiveDate
                        coverageEndDate
                        isIntegratedHighDeductable
                        isPharmacyBenefitsCarveOutFromMedical
                        pharmacyInsuranceGroupId
                        pharmacyInsuranceBin
                        pharmacyInsurancePcn
                        helpDeskPhone
                        patientInsuranceUuid
                    }
                }
            `,
            { patientId, referralId, activityId, patientInsuranceUuid }
        )
        .then(R.propOr({}, 'deleteReferralActivityBiPlan'));
}

/**
 * @typedef ReferralStatuses
 * @type Object
 * @property {string} uuid
 * @property {string|null} parentName
 * @property {string} name
 */

/**
 * Gets the status menus for a Referral
 * @param {string|null} parentName - The parent name or null if top-level
 * @return {Promise<ReferralStatuses[]>}
 */
function getReferralStatuses(parentName = null) {
    if (parentName) {
        return slsRms.get(`/statuses`);
    }
    return slsRms.get(`/statuses/${parentName}`);
}

function getReferralTypes() {
    return slsRms.get('/referral/types');
}

function createReferral(patientId, referral) {
    return rms.post(`/patient/${patientId}/referral`, referral);
}

/**
 * @typedef CreateReferralBody
 * @property {number} type
 * @property {string} medication
 * @property {object} details
 */
/**
 * Creates a Referral Activity
 * @param {string} patientId
 * @param {string} referralId
 * @param {string} body
 * @returns {Promise<AxiosResponse<any>>}
 */
function createActivity(patientId, referralId, body) {
    return rms.post(`/patient/${patientId}/referral/${referralId}/activity`, body);
}

/**
 * @typedef ReferralStatusMenu
 * @property {string} value
 * @property {string} label
 * @property {ReferralStatusMenu[]} children
 */

/**
 * @typedef CustomMenuOption
 * @property {string} value
 * @property {string} label
 */

/**
 * Creates the static menu with the provided hubs and SPs from customMenus
 * @param {CustomMenuOption[]} commonHubs
 * @param {CustomMenuOption[]} majorSps
 * @returns {ReferralStatusMenu[]} - Recursive array of menus
 */
function getReferralAvailableMenus(commonHubs, majorSps) {
    return [
        {
            value: 'No Go',
            label: 'No Go',
            children: [
                {
                    value: 'Drug Not Covered',
                    label: 'Drug Not Covered',
                    children: [
                        {
                            value: 'Appeal Denied',
                            label: 'Appeal Denied',
                            children: [
                                {
                                    value: 'Sent to PAP',
                                    label: 'Sent to PAP',
                                    children: [
                                        {
                                            value: 'PAP Denied',
                                            label: 'PAP Denied',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Direct Dispense',
                                            label: 'PAP Direct Dispense',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Funding Unavailable',
                                            label: 'PAP Funding Unavailable',
                                            children: [],
                                        },
                                    ],
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                        {
                            value: 'Buy and Bill',
                            label: 'Buy and Bill',
                            children: [
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                        {
                            value: 'PA Denied',
                            label: 'PA Denied',
                            children: [
                                {
                                    value: 'Sent to PAP',
                                    label: 'Sent to PAP',
                                    children: [
                                        {
                                            value: 'PAP Denied',
                                            label: 'PAP Denied',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Direct Dispense',
                                            label: 'PAP Direct Dispense',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Funding Unavailable',
                                            label: 'PAP Funding Unavailable',
                                            children: [],
                                        },
                                    ],
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                        {
                            value: 'Formulary Exclusion',
                            label: 'Formulary Exclusion',
                            children: [
                                {
                                    value: 'Sent to PAP',
                                    label: 'Sent to PAP',
                                    children: [
                                        {
                                            value: 'PAP Denied',
                                            label: 'PAP Denied',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Direct Dispense',
                                            label: 'PAP Direct Dispense',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Funding Unavailable',
                                            label: 'PAP Funding Unavailable',
                                            children: [],
                                        },
                                    ],
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                        {
                            value: 'Plan Exclusion',
                            label: 'Plan Exclusion',
                            children: [
                                {
                                    value: 'Sent to PAP',
                                    label: 'Sent to PAP',
                                    children: [
                                        {
                                            value: 'PAP Denied',
                                            label: 'PAP Denied',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Direct Dispense',
                                            label: 'PAP Direct Dispense',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Funding Unavailable',
                                            label: 'PAP Funding Unavailable',
                                            children: [],
                                        },
                                    ],
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                    ],
                },

                {
                    value: 'Limited Distribution Drug',
                    label: 'Limited Distribution Drug',
                    children: [
                        {
                            value: 'Unable to dispense',
                            label: 'Unable to dispense',
                            children: [
                                {
                                    value: 'Triaged to other SP',
                                    label: 'Triaged to other SP',
                                    children: majorSps,
                                },
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                            ],
                        },
                    ],
                },
                {
                    value: 'PAP / Financial Assistance',
                    label: 'PAP / Financial Assistance',
                    children: [
                        {
                            value: 'No Insurance Coverage',
                            label: 'No Insurance Coverage',
                            children: [
                                {
                                    value: 'Sent to PAP',
                                    label: 'Sent to PAP',
                                    children: [
                                        {
                                            value: 'PAP Denied',
                                            label: 'PAP Denied',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Direct Dispense',
                                            label: 'PAP Direct Dispense',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Funding Unavailable',
                                            label: 'PAP Funding Unavailable',
                                            children: [],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            value: 'High Copay',
                            label: 'High Copay',
                            children: [
                                {
                                    value: 'Sent to PAP',
                                    label: 'Sent to PAP',
                                    children: [
                                        {
                                            value: 'PAP Denied',
                                            label: 'PAP Denied',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Direct Dispense',
                                            label: 'PAP Direct Dispense',
                                            children: [],
                                        },
                                        {
                                            value: 'PAP Funding Unavailable',
                                            label: 'PAP Funding Unavailable',
                                            children: [],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    value: 'Other',
                    label: 'Other',
                    children: [
                        {
                            value: 'Reason non-specified',
                            label: 'Reason non-specified',
                            children: [
                                {
                                    value: 'Triaged to SP',
                                    label: 'Triaged to SP',
                                    children: majorSps,
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                        {
                            value: 'Not licensed in patients state of residence',
                            label: 'Not licensed in patients state of residence',
                            children: [
                                {
                                    value: 'Triaged to SP',
                                    label: 'Triaged to SP',
                                    children: majorSps,
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                    ],
                },

                {
                    value: 'Out of Payor Network',
                    label: 'Out of Payor Network',
                    children: [
                        {
                            value: 'Pharmacy not contracted',
                            label: 'Pharmacy not contracted',
                            children: [
                                {
                                    value: 'Triaged to SP',
                                    label: 'Triaged to SP',
                                    children: majorSps,
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                        {
                            value: 'Medical/Medicare B Billing',
                            label: 'Medical/Medicare B Billing',
                            children: [
                                {
                                    value: 'Triaged to SP',
                                    label: 'Triaged to SP',
                                    children: majorSps,
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                    ],
                },

                {
                    value: 'Patient Choice',
                    label: 'Patient Choice',
                    children: [
                        {
                            value: 'Cost of Therapy or Co-Pay (no PAP)',
                            label: 'Cost of Therapy or Co-Pay (no PAP)',
                            children: [
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                            ],
                        },
                        {
                            value: 'Patient no longer candidate for drug',
                            label: 'Patient no longer candidate for drug',
                            children: [
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                        {
                            value: 'Patient not ready to start drug',
                            label: 'Patient not ready to start drug',
                            children: [
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                        {
                            value: 'Patient Receiving Alternate Therapy',
                            label: 'Patient Receiving Alternate Therapy',
                            children: [
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                        {
                            value: 'Patient to use alternative SP',
                            label: 'Patient to use alternative SP',
                            children: [
                                {
                                    value: 'Triaged to SP',
                                    label: 'Triaged to SP',
                                    children: majorSps,
                                },
                            ],
                        },
                        {
                            value: 'Other reason non-specified',
                            label: 'Other reason non-specified',
                            children: [
                                {
                                    value: 'Triaged to SP',
                                    label: 'Triaged to SP',
                                    children: majorSps,
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                            ],
                        },
                    ],
                },

                {
                    value: 'Patient Deceased',
                    label: 'Patient Deceased',
                    children: [], //none
                },

                {
                    value: 'Prescriber Choice',
                    label: 'Prescriber Choice',
                    children: [
                        {
                            value: 'Cost of Therapy or Co-Pay (no PAP)',
                            label: 'Cost of Therapy or Co-Pay (no PAP)',
                            children: [
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                            ],
                        },
                        {
                            value: 'Patient no longer candidate for drug',
                            label: 'Patient no longer candidate for drug',
                            children: [],
                        },
                        {
                            value: 'Patient not ready to start drug',
                            label: 'Patient not ready to start drug',
                            children: [],
                        },
                        {
                            value: 'Patient Receiving Alternate Therapy',
                            label: 'Patient Receiving Alternate Therapy',
                            children: [],
                        },

                        {
                            value: 'Patient to use alternative SP',
                            label: 'Patient to use alternative SP',
                            children: [
                                {
                                    value: 'Triaged to SP',
                                    label: 'Triaged to SP',
                                    children: majorSps,
                                },
                            ],
                        },
                        {
                            value: 'Therapy discontinued',
                            label: 'Therapy discontinued',
                            children: [],
                        },

                        {
                            value: 'Cancelled - Sent in error',
                            label: 'Cancelled - Sent in error',
                            children: [],
                        },

                        {
                            value: 'Other reason non-specified',
                            label: 'Other reason non-specified',
                            children: [],
                        },
                    ],
                },

                {
                    value: 'Unable to contact patient',
                    label: 'Unable to contact patient',
                    children: [
                        {
                            value: 'Invalid contact information',
                            label: 'Invalid contact information',
                            children: [
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                            ],
                        },
                        {
                            value: 'No Response',
                            label: 'No Response',
                            children: [
                                {
                                    value: 'Notified Provider',
                                    label: 'Notified Provider',
                                    children: [],
                                },
                                {
                                    value: 'Sent to HUB',
                                    label: 'Sent to HUB',
                                    children: commonHubs,
                                },
                            ],
                        },
                    ],
                },

                {
                    value: 'Unable to contact prescriber',
                    label: 'Unable to contact prescriber',
                    children: [
                        {
                            value: 'Invalid contact information',
                            label: 'Invalid contact information',
                            children: [
                                {
                                    value: 'Notified Patient',
                                    label: 'Notified Patient',
                                    children: [],
                                },
                            ],
                        },
                        {
                            value: 'No Response',
                            label: 'No Response',
                            children: [
                                {
                                    value: 'Notified Patient',
                                    label: 'Notified Patient',
                                    children: [],
                                },
                            ],
                        },
                    ],
                },

                {
                    value: 'Created in Error',
                    label: 'Created in Error',
                    children: [], //none
                },
            ],
        },
    ];
}

/**
 *
 * @param {string} patientId
 * @param {string} referralId
 * @returns {ReferralMedication[]}
 */
function getReferralMedication(patientId, referralId) {
    return rms.get(`/patient/${patientId}/referral/${referralId}/medication`).then(convertKeysToCamelCase);
}

/**
 *
 * @param {string} patientId
 * @param {string} referralId
 * @returns {ReferralPriorAuthorization[]}
 */
function getReferralActivity(patientId, referralId, activityId, typeId) {
    return rms
        .get(`/patient/${patientId}/referral/${referralId}/activity/${activityId}/type/${typeId}`)
        .then(convertKeysToCamelCase);
}

/**
 *
 * @param {string} patientId
 * @param {string} referralId
 * @param {string} medicationId
 * @param {object} body
 * @returns {ReferralMedication[]}
 */
function updateReferralMedication(patientId, referralId, medicationId, body) {
    return rms
        .put(`/patient/${patientId}/referral/${referralId}/medication/${medicationId}`, body)
        .then(convertKeysToCamelCase);
}

/**
 *
 * @param {string} patientId
 * @param {string} referralId
 * @param {string} activityId
 * @param {string} medicationId
 * @param {object} body
 * @returns {ReferralActivityMedication[]}
 */
function updateReferralActivityMedication(patientId, referralId, activityId, medicationId, body) {
    return rms
        .put(`/patient/${patientId}/referral/${referralId}/activity/${activityId}/medication/${medicationId}`, body)
        .then(convertKeysToCamelCase);
}

function deleteReferralActivity(patientId, referralId, activityId) {
    return rms
        .delete(`/patient/${patientId}/referral/${referralId}/activity/${activityId}`)
        .then(convertKeysToCamelCase);
}

/**
 *
 * @param {string} patientId
 * @param {string} referralId
 * @returns {ReferralActivity[]}
 */
function getReferralActivities(patientId, referralId) {
    return rms.get(`/patient/${patientId}/referral/${referralId}/activity`).then(convertKeysToCamelCase);
}

/**
 *
 * @param {string} patientId
 * @param {string} referralId
 * @returns {ReferralCommunication[]}
 */
function getReferralCommunications(patientId, referralId) {
    return rms.get(`/patient/${patientId}/referral/${referralId}/communication`).then(convertKeysToCamelCase);
}

/**
 * Creates a Referral Communication
 * @param {string} patientId
 * @param {string} referralId
 * @param {string} body
 * @returns {Promise<AxiosResponse<any>>}
 */
function createCommunication(patientId, referralId, body) {
    return rms.post(`/patient/${patientId}/referral/${referralId}/communication`, body).then(convertKeysToCamelCase);
}

/**
 * Update a Referral activity
 * @param {string} patientId
 * @param {string} referralId
 * @param {string} body
 * @returns {Promise<AxiosResponse<any>>}
 */
function updateReferralActivity(patientId, referralId, activityId, body) {
    return rms
        .put(`/patient/${patientId}/referral/${referralId}/activity/${activityId}`, body)
        .then(convertKeysToCamelCase);
}

export {
    addReferralActivityBiPlan,
    createActivity,
    createReferral,
    createReferralActivityNote,
    deleteReferralActivityBiPlan,
    fetchReferralActivityNotes,
    formatReferral,
    getReferral,
    getReferralHistory,
    getReferralActivityBiPlan,
    getReferralAvailableMenus,
    getReferralStatuses,
    getReferralTypes,
    getReferralActivity,
    getReferrals,
    getReferralMedication,
    updateReferralMedication,
    getReferralActivities,
    getReferralCommunications,
    createCommunication,
    updateActivityReferrals,
    updateReferralActivity,
    deleteReferralActivity,
    updateReferralActivityMedication,
    updateReferral,
};
