import { isProd } from 'App/components/config/config';

/**
 * Simple console.warn wrapper that encapsulates logic to only show the warning when in a non-production environment.
 */

function warning(bool, message) {
    // Do nothing if in production
    if (!isProd) {
        // Condition passed, do not log
        if (bool) {
            return;
        }

        // Condition not passed
        const text = `Warning: ${message}`;

        // eslint-disable-next-line no-console
        console.warn(text);
        /**
         * Throwing an error and catching it immediately helps improve debugging as a dev can enable
         * `pause on caught exceptions`
         */
        try {
            // noinspection ExceptionCaughtLocallyJS
            throw Error(text);
        } catch (error) {
            // do nothing
        }
    }
}

export default warning;
