import PropTypes from 'prop-types';

const scorePropType = PropTypes.shape({
    value: PropTypes.number.isRequired,
    stratification: PropTypes.string.isRequired,
    followUp: PropTypes.number.isRequired,
    questionScores: PropTypes.objectOf(PropTypes.number),
});

export default scorePropType;
