import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import FileRecord from './components/FileRecord';
import FileUploadForm from './components/FileUploadForm';
import { deletePatientActivity } from 'App/components/PatientProfile/PatientProfile.utils.js';

import './ViewAttachments.scss';
import { FaInfoCircle, FaTrash, FaUpload } from 'react-icons/fa';
import { queryClient } from '../../../../../lib/queryClient';
import IconWrapper from 'App/common/styles/IconWrapper';
import { noop } from 'lodash';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    attachments: PropTypes.shape({
        numberOfUploads: PropTypes.number.isRequired,
        numberOfNotes: PropTypes.number.isRequired,
        uploadLimit: PropTypes.string.isRequired,
        uploadLimitInt: PropTypes.string.isRequired,
        patientNotes: PropTypes.arrayOf(
            PropTypes.shape({
                parentNoteId: PropTypes.string.isRequired,
                subject: PropTypes.string.isRequired,
            })
        ),
        patientFiles: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                origFileName: PropTypes.string.isRequired,
                icon: PropTypes.string,
                createdOn: PropTypes.string.isRequired,
            })
        ),
    }).isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    patientId: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    show: PropTypes.bool.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onHide: PropTypes.func.isRequired,
};
const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    attachments: {
        numberOfUploads: 0,
        numberOfNotes: 0,
        uploadLimit: '0 Mb',
        uploadLimitInt: '0',
        patientFiles: [],
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    medicationName: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    patientId: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    show: false,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    onHide: noop,
};

const ViewAttachments = ({ show, onHide, attachments, patientId }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('origFileName');
    const [showUploadFile, setShowUploadFile] = useState(false);

    const sortedFiles = useMemo(() => {
        if (Array.isArray(attachments?.patientFiles)) {
            return attachments.patientFiles.sort((a, b) => {
                const val1 = a[selectedFilter] && a[selectedFilter].toLowerCase();
                const val2 = b[selectedFilter] && b[selectedFilter].toLowerCase();
                // eslint-disable-next-line no-nested-ternary
                return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
            });
        }
        return [];
    }, [selectedFilter, attachments?.patientFiles]);
    const isAllSelected = useMemo(() => sortedFiles.length === selectedFiles.length, [sortedFiles, selectedFiles]);

    const toggleSelectAll = (e) => {
        if (e.target.checked) {
            const allFileIds = sortedFiles.map((file) => file.id);
            setSelectedFiles([...allFileIds]);
        } else {
            setSelectedFiles([]);
        }
    };

    const toggleFileSelect = (e, fileId) => {
        if (e.target.checked) {
            const isFileSelected = selectedFiles.find((file) => file.id);
            if (!isFileSelected?.id) {
                const selectedFilesArr = [...selectedFiles];
                selectedFilesArr.push(fileId);
                setSelectedFiles(selectedFilesArr);
            }
        } else {
            const selectedFilesArr = selectedFiles.filter((id) => id !== fileId);
            setSelectedFiles([...selectedFilesArr]);
        }
    };

    const hideUploadForm = useCallback(
        (refetch) => {
            (async () => {
                if (refetch) {
                    await queryClient.refetchQueries(['patientActivity', patientId], { active: true, exact: true });
                }
                setShowUploadFile(false);
            })();
        },
        [patientId]
    );

    const deleteFiles = () => {
        deletePatientActivity(patientId, selectedFiles)
            .then(() => {
                hideUploadForm(true);
                setSelectedFiles([]);
            })
            .catch((err) => console.log(err));
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static" className="patient-view-attachments">
            <Modal.Header>
                <Modal.Title>
                    Attachment Summary <span id="number-of-uploads">({attachments?.numberOfUploads || 0})</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {attachments?.numberOfUploads === 0 && (
                    <Row>
                        <Col md={12}>
                            <IconWrapper>
                                <FaInfoCircle />
                            </IconWrapper>
                            <span>
                                There are currently no attachments for this patient.
                                {attachments?.numberOfNotes === 0 &&
                                    ' The patient must have at least one summary note to upload files.'}
                            </span>
                        </Col>
                    </Row>
                )}
                {attachments?.numberOfUploads > 0 && (
                    <>
                        <Row className="note-box">
                            <Col md={12} className="text-right form-inline">
                                <select
                                    className="form-control file-filter"
                                    onChange={(e) => setSelectedFilter(e.target.value)}
                                    value={selectedFilter}
                                >
                                    <option value="createdOn">Sorted by Date</option>
                                    <option value="origFileName">Sort by File Name</option>
                                    <option value="icon">Sorted by File Type</option>
                                </select>
                                <button
                                    disabled={selectedFiles.length < 1}
                                    className="btn btn-default"
                                    onClick={deleteFiles}
                                    type="button"
                                >
                                    <FaTrash />
                                </button>
                            </Col>
                        </Row>
                        <Row className="note-box">
                            <Col md={12}>
                                <table className="therigy-table">
                                    <thead>
                                        <tr>
                                            <th className="col-md-2">
                                                <strong>File Type</strong>
                                            </th>
                                            <th className="col-md-9">
                                                <strong>File Name</strong>
                                            </th>
                                            <th className="col-md-1 text-right">
                                                <input
                                                    type="checkbox"
                                                    onChange={toggleSelectAll}
                                                    checked={isAllSelected}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedFiles.map((file) => (
                                            <FileRecord
                                                key={file.id}
                                                file={file}
                                                selectedFiles={selectedFiles}
                                                toggleFileSelect={toggleFileSelect}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </>
                )}
                {showUploadFile && (
                    <FileUploadForm
                        notes={attachments?.patientNotes}
                        notesCount={attachments?.numberOfNotes}
                        uploadLimit={attachments?.uploadLimit}
                        uploadLimitInt={attachments?.uploadLimitInt}
                        patientId={patientId}
                        onHide={hideUploadForm}
                    />
                )}
            </Modal.Body>
            {!showUploadFile && (
                <Modal.Footer>
                    {!showUploadFile && attachments?.numberOfNotes > 0 && (
                        <Row>
                            <Col md={12}>
                                <Button
                                    bsClass="btn-link"
                                    className="pull-left"
                                    id="view-attachment-upload-file-link"
                                    onClick={() => setShowUploadFile(true)}
                                >
                                    <FaUpload /> Upload File
                                </Button>
                                <Button
                                    className="btn btn-default pull-right"
                                    id="view-attachments-close-modal-button"
                                    onClick={onHide}
                                >
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    )}
                    {attachments?.numberOfNotes === 0 && (
                        <Row>
                            <Col md={12}>
                                <Button className="btn btn-default pull-right" onClick={onHide} type="button">
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    );
};

ViewAttachments.propTypes = propTypes;
ViewAttachments.defaultProps = defaultProps;

export default ViewAttachments;
