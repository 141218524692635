import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils.js';
import TherigyErrorBoundary from 'App/components/errors/TherigyErrorBoundary';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { toDate } from 'App/services/DateService';
import CustomMenuField from 'Lib/form/CustomMenu';
import { DatePickerField } from 'Lib/form/DatePickerField';
import SubmitButton from 'Lib/form/SubmitButton';
import { TextareaField } from 'Lib/form/TextareaField';

import { patientMedicationRxPropType } from '../../types';
import SpaceBetweenContainer from '../SpaceBetweenContainer';

const MedicationEndModal = ({ onClose, onSubmit, existingMedication }) => {
    return (
        <Modal show onHide={onClose}>
            <TherigyErrorBoundary>
                <Modal.Header>
                    <Modal.Title>Medication End</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        patientMedication: {
                            ...existingMedication,
                            status: {
                                ...existingMedication.status,
                                end: toDate(existingMedication.status.end),
                                endReason: existingMedication.status.endReason
                                    ? { valueName: existingMedication.status.endReason }
                                    : null,
                                endNotes: existingMedication.status.endNotes || '',
                            },
                        },
                    }}
                    onSubmit={(values) => {
                        const submissionValues = {
                            patientMedication: {
                                status: {
                                    ...values.patientMedication.status,
                                    endReason: values.patientMedication.status.endReason.valueName,
                                },
                            },
                        };
                        onSubmit(submissionValues);
                        onClose();
                    }}
                >
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Row>
                                    <Col md={6} className="form-group">
                                        <div>Medication Name</div>
                                        <div>{values.patientMedication.medication.name}</div>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <div>Medication Start Date</div>
                                        <div>{formatDate(values.patientMedication.status.start)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <DatePickerField
                                            fieldName="patientMedication.status.end"
                                            label="Medication End Date"
                                            placeholder="YYYY/MM/DD"
                                            required
                                            minDate={values.patientMedication.status.start}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <CustomMenuField
                                            fieldName="patientMedication.status.endReason"
                                            label="Medication End Reason"
                                            menuName="medication_end_reason"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <TextareaField
                                    fieldName="patientMedication.status.endNotes"
                                    label={
                                        <SpaceBetweenContainer>
                                            <div>Notes</div>
                                            <div>{values.patientMedication.status.endNotes.length}/200</div>
                                        </SpaceBetweenContainer>
                                    }
                                    placeholder="Write notes here"
                                    maxLength={200}
                                />
                            </Modal.Body>

                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <SubmitButton>Done</SubmitButton>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    )}
                </Formik>
            </TherigyErrorBoundary>
        </Modal>
    );
};

MedicationEndModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    existingMedication: patientMedicationRxPropType,
};

MedicationEndModal.defaultProps = {
    existingMedication: null,
};

export default MedicationEndModal;
