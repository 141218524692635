import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import * as R from 'ramda';
import hasLength from 'App/utils/hasLength';

/** @ngInject */
function ReferralActivitiesController($scope, $uibModal, NgTableParams) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.openActivityNotes = openActivityNotes;
    ctrl.openEditDueDateModal = openEditDueDateModal;
    ctrl.viewHistory = viewHistory;
    $scope.referralProtocolManagerEnabled = false;

    function _filterActivities(data, filterValues) {
        return data.filter((item) => {
            return (
                (filterValues.status
                    ? item.status && item.status.toLowerCase().indexOf(filterValues.status.toLowerCase()) !== -1
                    : true) &&
                (filterValues.name ? item.name.toLowerCase().indexOf(filterValues.name.toLowerCase()) !== -1 : true) &&
                (filterValues.medication
                    ? item.medicationList.toLowerCase().indexOf(filterValues.medication.toLowerCase()) !== -1
                    : true)
            );
        });
    }

    function $onInit() {
        $scope.referralProtocolManagerEnabled = isPermitted(
            PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER,
            ctrl.user
        );
    }

    function $onChanges(changes) {
        if (changes.activities && ctrl.activities) {
            ctrl.activities = R.map((activity) => {
                const { notes } = activity;
                if (hasLength(notes)) {
                    const latestNoteStatus = R.compose(R.prop('status'), R.last)(notes);
                    return R.assoc('note_status', latestNoteStatus, activity);
                }
                return R.assoc('note_status', activity.status, activity);
            })(ctrl.activities);
            ctrl.tableParams = new NgTableParams(
                {
                    count: 10,
                    sorting: { due_on: 'desc' },
                },
                {
                    counts: [10, 25, 50],
                    dataset: ctrl.activities,
                    filterOptions: { filterFn: _filterActivities },
                }
            );
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function openActivityNotes(activity) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'activityNotes',
            size: 'md',
            windowClass: 'activity-notes-modal',
            resolve: {
                modalData() {
                    return {
                        activityId: activity.id,
                        activityName: activity.name,
                        firstName: ctrl.patient.first_name,
                        lastName: ctrl.patient.last_name,
                        type: 'Referral',
                    };
                },
            },
        });

        modalInstance.result
            .then((noteChanged) => {
                if (noteChanged) {
                    $scope.$emit('patientActivitiesUpdated', {
                        patient_id: ctrl.patient.id,
                    });
                }

                return noteChanged;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function openEditDueDateModal(activity) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'referralActivitiesEditDueDateModal',
            size: 'md',
            resolve: {
                activity() {
                    return activity;
                },
            },
        });

        modalInstance.result.then((res) => {
            if (res) {
                $scope.$emit('updateReferralActivities', {
                    patient_id: activity.patient_id,
                    referral_id: activity.referral_id,
                });
            }
            return res;
        });
    }

    function viewHistory(patientId, referralId, activityId) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            size: 'lg',
            component: 'referralActivityHistoryModal',
            keyboard: false,
            resolve: {
                activityId() {
                    return activityId;
                },
                patientId() {
                    return patientId;
                },
                referralId() {
                    return referralId;
                },
            },
        });

        modalInstance.result.then((value) => {
            return value;
        });
    }
}

export default ReferralActivitiesController;
