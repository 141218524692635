import angular from 'angular';

import CompileDirective from './compile.directive';
import ContentViewerDirective from './content-viewer.directive';
import FileInputDirective from './file-input.directive';

/**
 *
 * @ngdoc module
 * @name directives
 *
 * @description
 *
 * This is the directives module. It includes all of our reusable directives.
 *
 **/
export default angular
    .module('directives', [])
    .directive('compile', CompileDirective)
    .directive('contentViewer', ContentViewerDirective)
    .directive('uploadFile', FileInputDirective).name;
