/** @ngInject */
function PatientUserAlertsController($scope, divisionService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeValue = doChangeValue;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
            return divisionService
                .getUsers({
                    pcc: true,
                })
                .then((res) => {
                    ctrl.pccs = res;
                })
                .catch((err) => {
                    return err;
                });
        }
    }

    function doChangeValue() {
        if (ctrl.patient.pcc_send_email === true) {
            $scope.$emit('addPatientPage_updatePatient', {
                pcc_id: ctrl.patient.pcc_id,
                pcc_send_email: ctrl.patient.pcc_send_email,
            });
        } else {
            $scope.$emit('addPatientPage_updatePatient', {
                pcc_id: undefined,
                pcc_send_email: undefined,
            });
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
    }
}

export default PatientUserAlertsController;
