import * as R from 'ramda';
import { toast } from 'react-toastify';

const isString = R.is(String);

const toastIfMessage = (path) =>
    // prettier-ignore
    [
        R.pathSatisfies(isString, path),
        (error) => toast.error(R.path(path, error))
    ];

function errorHandler(error, showToast = true) {
    // eslint-disable-next-line no-console
    console.error(error);

    if (!(window.location.hash !== '#!/login' || window.location.hash !== '#!/logout') && showToast) {
        // prettier-ignore
        R.cond([
            toastIfMessage(['response', 'errors', 0, 'message']),
            toastIfMessage(['response', 'data', 'message']),
            toastIfMessage(['message']),
        ])(error)
    }
}

export default errorHandler;
