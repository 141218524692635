import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import Button from 'react-bootstrap/lib/Button';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import { FaCheck, FaEdit, FaRedo, FaTimes } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import useStandaloneFormField from './useStandaloneFormField';

const propTypes = {
    onSave: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onDelete: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    initialValue: PropTypes.any,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    validator: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    name: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    placeholder: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    isDisabled: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    style: PropTypes.object,
};

const defaultProps = {};

const Container = styled('div')`
    display: flex;
    align-items: flex-end;

    .form-field,
    .input-group {
        width: 100%;
    }
`;

const Text = ({
    initialValue,
    onSave,
    validator,
    id,
    name,
    placeholder,
    style,
    onDelete,
    isDisabled: propDisable,
    ...props
}) => {
    const {
        handleCancel,
        handleSave,
        hasFailed,
        isActive,
        isDirty,
        isDisabled,
        isNotEditing,
        isPending,
        isValid,
        onEdit,
        setValue,
        state,
    } = useStandaloneFormField({
        initialValue,
        onSave,
        validator,
    });
    const { context } = state;

    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            if (isActive) {
                ref.current.focus();
            }

            if (isNotEditing) {
                ref.current.blur();
            }
        }
    }, [isActive, isNotEditing]);

    return (
        <form onSubmit={handleSave}>
            <FormGroup validationState={isValid ? null : 'error'}>
                <Container>
                    <div className="form-field input-group">
                        <FormControl
                            {...props}
                            id={id}
                            inputRef={
                                // eslint-disable-next-line no-return-assign
                                (r) => (ref.current = r)
                            }
                            name={name}
                            onChange={(event) => setValue(event.target.value)}
                            placeholder={placeholder}
                            type="text"
                            autoComplete="off"
                            disabled={propDisable || isDisabled || !isActive}
                            value={context.currentValue || ''}
                            style={style}
                        />

                        <span className="input-group-btn">
                            {isActive || isPending ? (
                                <>
                                    <Button
                                        type="button"
                                        onClick={handleCancel}
                                        disabled={isPending}
                                        title="Cancel"
                                        aria-label="Cancel"
                                    >
                                        <FaTimes />
                                    </Button>

                                    <Button
                                        type="submit"
                                        disabled={!isDirty || isPending}
                                        title="Save"
                                        aria-label="Save"
                                    >
                                        {
                                            // eslint-disable-next-line no-nested-ternary
                                            hasFailed ? (
                                                <FaRedo />
                                            ) : isPending ? (
                                                <ClipLoader size={15} color="#333" />
                                            ) : (
                                                <FaCheck />
                                            )
                                        }
                                    </Button>
                                </>
                            ) : (
                                <Button type="button" onClick={onEdit} title="Edit" aria-label="Edit">
                                    <FaEdit />
                                </Button>
                            )}
                        </span>
                    </div>

                    {typeof onDelete === 'function' && (
                        <Button type="button" bsStyle="link" onClick={onDelete} title="Delete" aria-label="Delete">
                            Delete
                        </Button>
                    )}
                </Container>
                {context.error && <HelpBlock>{context.error}</HelpBlock>}
            </FormGroup>
        </form>
    );
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
