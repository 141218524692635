import * as R from 'ramda';
import { getProgramById } from 'App/services/FinancialAssistanceService';
import { createProgramDataPairs } from 'App/components/AlertsCenter/FinancialAssistance/utils';

/** @ngInject */
function ProgramDetailModalController() {
    const ctrl = this;

    ctrl.programData = [];
    ctrl.selectProgram = Function;
    ctrl.$onChanges = $onChanges;
    ctrl.select = select;
    ctrl.isLoading = true;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        const programId = getProgramId(changes);
        const params = R.compose(getFaParams)(changes);

        if (programId) {
            return getProgramById(programId, params)
                .then((program) => {
                    ctrl.program = program;
                    ctrl.programData = createProgramDataPairs(program);
                })
                .finally(() => {
                    ctrl.isLoading = false;
                });
        }
    }

    function getProgramId(changes) {
        return R.path(['resolve', 'currentValue', 'program', 'programId'], changes);
    }

    function getFaParams(changes) {
        return R.path(['resolve', 'currentValue', 'params'], changes);
    }

    function select() {
        ctrl.resolve.getSetter();
        ctrl.close();
    }
}

export default ProgramDetailModalController;
