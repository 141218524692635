import * as R from 'ramda';

import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';

import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import TherigyMultiSelect from 'App/components/TherigyMultiSelect/TherigyMultiSelect';
import { allDiagnosisOption } from './constants';
import { isFieldValid } from './utils';
import { TextField } from 'Lib/form/TextField';
import { useFormikContext } from 'formik';

const optionShape = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
});

const propTypes = {
    medicationOptions: PropTypes.arrayOf(optionShape).isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    diagnosisCodeOptions: PropTypes.arrayOf(optionShape),
    isFormLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    category: optionShape,
};

const defaultProps = {
    category: null,
    isFormLoading: false,
    isDisabled: false,
};

function ProtocolBaseForm({ category, isFormLoading, isDisabled, medicationOptions, diagnosisCodeOptions }) {
    const formik = useFormikContext();
    return (
        <>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <ControlLabel htmlFor="category">Therapeutic Category</ControlLabel>
                        <Select
                            inputId="category"
                            options={[]}
                            value={category}
                            isDisabled
                            isLoading={isFormLoading}
                            getOptionValue={R.prop('id')}
                            getOptionLabel={R.prop('name')}
                        />
                    </FormGroup>
                </Col>

                <Col md={6}>
                    <FormGroup validationState={isFieldValid('medications', formik)}>
                        <ControlLabel htmlFor="medications" className="field-required">
                            Medications
                        </ControlLabel>
                        <TherigyMultiSelect
                            inputId="medications"
                            name="medications"
                            placeholder="Select medications"
                            className="form-control react-select"
                            options={medicationOptions}
                            triggerPropValue="all"
                            isLoading={isFormLoading}
                            isDisabled={isDisabled}
                            getOptionValue={R.prop('id')}
                            getOptionLabel={R.prop('name')}
                            onChange={R.partial(formik.setFieldValue, ['medications'])}
                            onBlur={() => {
                                formik.setFieldTouched('medications');
                            }}
                            value={formik.values.medications}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <TextField
                        fieldName="name"
                        label="Name"
                        placeholder="Enter name"
                        required
                        disabled={isDisabled}
                        style={{ height: 38 }}
                    />
                </Col>

                <Col md={6}>
                    <FormGroup>
                        <ControlLabel htmlFor="diagnosisCodes">Diagnosis Codes</ControlLabel>
                        <TherigyMultiSelect
                            inputId="diagnosisCodes"
                            name="diagnosisCodes"
                            placeholder="Select diagnosis codes"
                            options={diagnosisCodeOptions}
                            triggerPropValue="all"
                            isLoading={isFormLoading}
                            isDisabled={isDisabled}
                            getOptionValue={R.prop('id')}
                            getOptionLabel={(option) => {
                                if (R.equals(allDiagnosisOption, option)) {
                                    return option.code;
                                }
                                return `${option.code}: ${option.shortDescription}`;
                            }}
                            onChange={R.partial(formik.setFieldValue, ['diagnosisCodes'])}
                            onBlur={() => formik.setFieldTouched('diagnosisCodes')}
                            value={formik.values.diagnosisCodes}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
}

ProtocolBaseForm.propTypes = propTypes;
ProtocolBaseForm.defaultProps = defaultProps;

export default ProtocolBaseForm;
