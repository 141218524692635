import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap/lib/InputGroup';
import propOrDash from 'App/utils/propOrDash';
import TherigyInfoMessage from '../TherigyInfoMessage/TherigyInfoMessage';
import Table from '../Table/Table';
import useGetReferralHistory from 'App/hooks/useGetReferralHistory';
import { referralModalPropType } from '../AddReferralActivity/types';

const defaultProps = {};

const columns = [
    {
        Header: 'Status',
        accessor: propOrDash('status'),
        id: 'status',
    },
    {
        Header: 'Status Reason',
        accessor: propOrDash('statusReason'),
        id: 'statusReason',
    },
    {
        Header: 'Last Updated',
        accessor: 'updatedOn',
        id: 'updatedOn',
    },
    {
        Header: 'User',
        accessor: 'updatedName',
        Cell: ({ value }) => `${value.firstName} ${value.lastName}`.trim(),
        id: 'updatedName',
    },
];

const initialState = {
    sortBy: [
        {
            id: 'updatedOn',
            desc: true,
        },
    ],
};

const ReferralHistoryModal = ({ show, onHide, patientId, referralId }) => {
    const { data: history, isLoading } = useGetReferralHistory(patientId, referralId);

    return (
        <Modal bsSize="lg" show={show} onHide={() => onHide(false)} backdrop="static" className="referral-history">
            <Modal.Header>
                <Modal.Title>Referral Status History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {history?.length > 0 && !isLoading ? (
                    <Table
                        initialState={initialState}
                        enablePagination={false}
                        columns={columns}
                        data={history}
                        isLoading={isLoading}
                    />
                ) : (
                    <TherigyInfoMessage message="There is no history for this referral." />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col md={12}>
                        <Button bsClass="btn btn-default" className="pull-right" onClick={() => onHide(false)}>
                            Close
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

ReferralHistoryModal.propTypes = referralModalPropType;
ReferralHistoryModal.defaultProps = defaultProps;

export default ReferralHistoryModal;
