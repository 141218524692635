import PropTypes from 'prop-types';

const patientSubscriptionPropType = PropTypes.shape({
    subscribedOn: PropTypes.string,
    unsubscribedOn: PropTypes.string,
    confirmed: PropTypes.number,
    status: PropTypes.bool,
});

export default patientSubscriptionPropType;
