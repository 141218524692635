import CompanyService from '../../services/CompanyService';

/** @ngInject */
function PatientConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.patient',
        url: '/patient/:patientId',
        views: {
            '': 'patient',
            'pageTitle@^': {
                template: 'Patient Profile',
            },
        },
        params: {
            patient: null,
        },
        resolve: {
            branchSettings: () =>
                CompanyService.getEhrBranchSettings().then((res) => {
                    return res;
                }),
        },
    });
}

export default PatientConfig;
