import React from 'react';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import ProtocolPublisher from './ProtocolPublisher';
import Page from 'App/components/Page';

const propTypes = {};

const defaultProps = {};

function ProtocolPublisherPage() {
    return (
        <Page title="Publish Protocol" permissionType={PERMISSION_TYPES.PUBLISH_PROTOCOL}>
            <ProtocolPublisher />
        </Page>
    );
}

ProtocolPublisherPage.propTypes = propTypes;
ProtocolPublisherPage.defaultProps = defaultProps;

export default ProtocolPublisherPage;
