/** @ngInject */
function ReferralAddMedicationModalController(_, $state, $q, NgTableParams, toastr, rmsService) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeMedication = doChangeMedication;
    ctrl.doChangeRxNumber = doChangeRxNumber;
    ctrl.submit = submit;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve.modalData) {
            if (ctrl.resolve.modalData.medications) {
                ctrl.originalReferralMedications = angular.copy(ctrl.resolve.modalData.medications);
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                ctrl.medicationIds = _.map(ctrl.resolve.modalData.medications, (rx) => {
                    if (rx.id) {
                        return rx.medication_id + rx.rx_number_id;
                    }
                });
            }

            if (ctrl.resolve.modalData.patientMedicationRxs) {
                ctrl.patientMedicationRxs = angular.copy(ctrl.resolve.modalData.patientMedicationRxs);
            }

            if (ctrl.resolve.modalData.patientRxs) {
                ctrl.patientRxs = angular.copy(ctrl.resolve.modalData.patientRxs);
            }

            if (ctrl.medicationIds && ctrl.patientRxs) {
                ctrl.medications = [];
                ctrl.rxs = {};
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus,no-var,vars-on-top
                for (var i = 0, rx, len = ctrl.patientRxs.length; i < len; i++) {
                    rx = ctrl.patientRxs[i];
                    if (rx.id && ctrl.medicationIds.indexOf(rx.medication_id + rx.id) === -1) {
                        if (!Object.prototype.hasOwnProperty.call(ctrl.rxs, rx.medication_id)) {
                            ctrl.medications.push(rx);
                            ctrl.rxs[rx.medication_id] = [];
                        }

                        if (rx.id) {
                            ctrl.rxs[rx.medication_id].push(rx);
                        }
                    }
                }
            }
        }
    }

    function $onInit() {
        ctrl.referralMedications = [];
    }

    function doChangeMedication() {
        ctrl.validMedication = true;

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = ctrl.originalReferralMedications.length; i < len; i++) {
            if (
                ctrl.originalReferralMedications[i].medication_id === ctrl.medication &&
                ctrl.originalReferralMedications[i].rx_number_id === null
            ) {
                ctrl.validMedication = false;
                break;
            }
        }

        if (ctrl.medication) {
            ctrl.selectedRxs = {};
            ctrl.referralMedications = [];

            ctrl.tableParams = new NgTableParams(
                {
                    count: 5,
                },
                {
                    counts: [],
                    dataset: ctrl.rxs[ctrl.medication],
                }
            );

            if (ctrl.tableParams.total() > ctrl.tableParams.count()) {
                ctrl.tableParams.settings({ counts: [5, 10, 25] });
            }
        }
    }

    function doChangeRxNumber(rx, selected) {
        if (selected) {
            ctrl.referralMedications.push({
                rx_number_id: rx.id,
                medication_id: rx.medication_id,
            });
        } else {
            _.remove(ctrl.referralMedications, (rm) => {
                return rm.rx_number_id === rx.id;
            });
        }
    }

    function submit() {
        ctrl.saving = true;

        const promises = [];

        if (ctrl.referralMedications.length === 0) {
            ctrl.referralMedications.push({
                medication_id: ctrl.medication,
            });
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = ctrl.referralMedications.length; i < len; i++) {
            promises.push(
                rmsService.createMedication(
                    $state.params.patientId,
                    $state.params.referralId,
                    ctrl.referralMedications[i]
                )
            );
        }

        return $q
            .all(promises)
            .then((res) => {
                toastr.success('The referral medications have been added successfully.');
                ctrl.close({ $value: res });
                return res;
            })
            .catch((res) => {
                if (res.status === 404 || res.status === 403) {
                    toastr.error(
                        'The referral was not found or you do not have permission to access it. ' +
                            'Please contact your STM administrator.'
                    );
                } else if (!res.status) {
                    toastr.error(res);
                } else {
                    toastr.error('Referral medications were not added successfully. Please try again.');
                }
                ctrl.dismiss({ $value: ctrl.resolve });
                return res;
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }
}

export default ReferralAddMedicationModalController;
