import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import useFetchTherapyCategoriesQuery from 'App/hooks/useFetchTherapyCategoriesQuery';
import SelectField from 'Lib/form/SelectField';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    fieldName: PropTypes.string,
};

const defaultProps = {
    fieldName: 'category',
};

function CategorySelect({ fieldName, onChange }) {
    const { isLoading, data } = useFetchTherapyCategoriesQuery();

    const categories = useMemo(() => {
        return data ? R.compose(R.sortBy(R.prop('name')), R.values)(data) : [];
    }, [data]);

    return (
        <SelectField
            fieldName={fieldName}
            options={categories}
            label="Therapeutic Category"
            getOptionValue={R.prop('id')}
            getOptionLabel={R.prop('name')}
            isClearable
            onChange={onChange}
            isLoading={isLoading}
        />
    );
}

CategorySelect.propTypes = propTypes;
CategorySelect.defaultProps = defaultProps;

export default CategorySelect;
