import React, { useMemo } from 'react';
import pathOrDash from 'App/utils/pathOrDash';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { authorizationList } from '../authorizationList';
import Table from 'App/components/Table/Table';
import SelectField from 'Lib/form/SelectField';
import { TextField } from 'Lib/form/TextField';
import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';

const ndcSpecificApprovalList = [
    {
        id: '1',
        name: 'Yes',
    },
    {
        id: '2',
        name: 'No',
    },
];

const cellPropTypes = {
    row: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

const generateAuthorizerQuantityCell = (disableActivity, disableInputs) => {
    const AuthorizerQuantityCell = ({ row }) => (
        <TextField
            fieldName={`medications[${row.id}].data.authorizedQuantity`}
            disabled={disableActivity || disableInputs}
            placeholder="Authorized Quantity"
        />
    );

    AuthorizerQuantityCell.propTypes = cellPropTypes;

    return AuthorizerQuantityCell;
};

const generateAuthorizerUomCell = (disableActivity, disableInputs) => {
    const AuthorizerUomCell = ({ row }) => (
        <SelectField
            isClearable
            options={authorizationList.map((it) => ({ id: it, name: it }))}
            fieldName={`medications[${row.id}].data.authorizedUom`}
            isDisabled={disableActivity || disableInputs}
            getOptionValue={R.prop('id')}
            getOptionLabel={R.prop('name')}
        />
    );

    AuthorizerUomCell.propTypes = cellPropTypes;

    return AuthorizerUomCell;
};

const generateNdcSpecificApprovalCell = (disableActivity, disableInputs) => {
    const NdcSpecificApprovalCell = ({ row }) => (
        <SelectField
            options={ndcSpecificApprovalList}
            isClearable
            fieldName={`medications[${row.id}].data.ndcSpecificApproval`}
            isDisabled={disableActivity || disableInputs}
            getOptionValue={R.prop('id')}
            getOptionLabel={R.prop('name')}
        />
    );

    NdcSpecificApprovalCell.propTypes = cellPropTypes;

    return NdcSpecificApprovalCell;
};

const generateApproveNdcCell = (disableActivity, disableInputs) => {
    const ApproveNdcCell = ({ row }) => (
        <TextField
            disabled={disableActivity || disableInputs}
            fieldName={`medications[${row.id}].data.approvedNdc`}
            placeholder="Approved NDC"
        />
    );

    ApproveNdcCell.propTypes = cellPropTypes;

    return ApproveNdcCell;
};

const MedicationTableForm = () => {
    const { values } = useFormikContext();
    const { disableActivity, disableInputs } = values;

    const columns = useMemo(
        () => [
            {
                Header: 'Medication',
                accessor: 'medication.name',
                Cell: ({ row }) => pathOrDash(['original', 'medication', 'name'], row),
                id: 'medication',
                disableFilters: true,
                canSort: false,
            },
            {
                Header: 'Authorized Quantity',
                accessor: 'data.authorizedQuantity',
                Cell: generateAuthorizerQuantityCell(disableActivity, disableInputs),
                id: 'authorizedQuantity',
                disableFilters: true,
                canSort: false,
            },
            {
                Header: 'Unit of Measure',
                accessor: 'data.authorizedUom',
                Cell: generateAuthorizerUomCell(disableActivity, disableInputs),
                id: 'authorizedUom',
                disableFilters: true,
                canSort: false,
            },
            {
                Header: 'Is Approval NDC Specific?',
                accessor: 'data.ndcSpecificApproval',
                Cell: generateNdcSpecificApprovalCell(disableActivity, disableInputs),
                id: 'ndcSpecificApproval',
                disableFilters: true,
                canSort: false,
            },
            {
                Header: 'Approved NDC',
                accessor: 'data.approvedNdc',
                Cell: generateApproveNdcCell(disableActivity, disableInputs),
                id: 'approvedNdc',
                disableFilters: true,
                canSort: false,
            },
        ],
        [disableActivity, disableInputs]
    );

    if (values?.medications && values?.medications.length === 0) {
        return (
            <TherigyInfoMessage message="There is currently no medication attached to this Prior Authorization Activity." />
        );
    }

    return (
        <>
            <h3>Medication</h3>
            <Table enablePagination={false} columns={columns} data={values?.medications || []} canSort={false} />
        </>
    );
};

export default MedicationTableForm;
