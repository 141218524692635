import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Button, Col, ControlLabel, FormGroup, Modal, Row } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import Select from 'react-select';
import IconWrapper from 'App/common/styles/IconWrapper';
import useGetActivityNotes from 'App/components/PatientProfile/hooks/useGetActivityNotes';
import AssessmentService from 'App/services/AssessmentService';
import CompanyService from 'App/services/CompanyService';
import { createReferralActivityNote } from 'App/services/RMSService';
import localize from 'App/utils/localize/localize';
import * as yup from 'yup';
import { Formik } from 'formik';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';

const propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    activity: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        typeLabel: PropTypes.string,
        name: PropTypes.string,
    }),
    patient: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }),
};

const defaultProps = {
    show: false,
    onHide: () => {},
    activity: {},
    patient: {},
};

const validationSchema = yup.object().shape({
    notes: yup.string(),
    status: yup.object().required('Please select status'),
});

const generateEhrNoteText = (note, isEhrEligible) => {
    const ehrText = [];
    if (note.status === 'Finished' && isEhrEligible) {
        if (note.activityEhrNotes) {
            ehrText.push('Eligible for EHR Note at time of completion: Yes');
        } else {
            ehrText.push('Eligible for EHR Note at time of completion: No');
            ehrText.push('Note Sent to Ehr: No');
            return ehrText;
        }

        if (note.activityEhrNotes.sentOn && !note.activityEhrNotes.failed) {
            ehrText.push('Note Sent to EHR: Yes');
        } else {
            ehrText.push('Note Sent to EHR: No');
        }
    }
    return ehrText;
};

const ActivityNotesModal = ({ show, onHide, activity, patient }) => {
    const {
        activityNotes: notes,
        isActivityNotesLoading,
        triggerActivityNoteRefetch,
    } = useGetActivityNotes(activity?.id, activity?.typeLabel);

    const [showAddNoteForm, setShowAddNoteForm] = useState(false);
    const [ehrProvider, setEhrProvider] = useState(false);
    const isReferralActivity = activity?.typeLabel === 'Referral' || activity?.typeLabel === 'REF';
    const noteStatusList = useMemo(() => {
        let menu = 'activity_status';
        if (isReferralActivity) {
            menu = 'referral_note_status';
        }
        return CompanyService.getCustomMenu(menu);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity.typeLabel]);

    useMemo(() => {
        return CompanyService.getBranchSettings('ehrType', '', false).then((data) => {
            setEhrProvider(data);
        });
    }, []);

    const isEhrEligible = !!ehrProvider;

    return (
        <Modal
            show={show}
            onHide={() => onHide(false)}
            backdrop="static"
            className="patient-activity-note-modal activity-notes-modal"
        >
            <Modal.Header>
                <Modal.Title>
                    Activity Notes:{' '}
                    {`${R.propOr('', 'firstName', patient)} ${R.propOr('', 'lastName', patient)} (${activity.name})`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="notes-modal-body">
                {!isActivityNotesLoading ? (
                    <>
                        {!notes.length && (
                            <Row className="no-notes-header">
                                <Col md={12} className="text-center">
                                    <h4>There are no notes for this activity.</h4>
                                </Col>
                            </Row>
                        )}

                        {notes.map((note) => {
                            return (
                                <div className="note-box" key={note.id}>
                                    <div>
                                        <b>{note.status}</b>{' '}
                                        <span className="notes-byline">
                                            - {localize(note.createdOn, 'YYYY-MM-DD h:mm a')}, By {note.createdBy}
                                        </span>
                                    </div>
                                    <div>
                                        {generateEhrNoteText(note, isEhrEligible).map((line) => (
                                            <React.Fragment key={line}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div className="notes">{note.notes}</div>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <TherigyLoadingSpinner />
                )}
                <Row>
                    <Col md={12}>
                        <div className="flex-header">
                            <Button
                                bsClass="btn-link"
                                onClick={() => setShowAddNoteForm(true)}
                                id="add-activity-note-link"
                                className="add-activity-note-link"
                            >
                                <IconWrapper>
                                    <FaPlus />
                                </IconWrapper>
                                Add Activity Note
                            </Button>
                            <Button
                                id="close-note-modal-button"
                                className="btn btn-default"
                                onClick={() => onHide(true)}
                            >
                                Close
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            {showAddNoteForm && (
                <div className="notes-footer">
                    <Formik
                        initialValues={{}}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            let promiseObj;
                            if (isReferralActivity) {
                                const note = {
                                    note: values.notes,
                                    status: values.status.valueName,
                                };
                                promiseObj = createReferralActivityNote(activity?.id, note);
                            } else {
                                const note = {
                                    notes: values.notes,
                                    status: values.status.valueName,
                                };
                                promiseObj = AssessmentService.createActivityNoteStatus(activity?.id, note);
                            }
                            promiseObj.then(() => {
                                triggerActivityNoteRefetch();
                                setShowAddNoteForm(false);
                            });
                        }}
                    >
                        {({ isSubmitting, setFieldValue, handleSubmit, values }) => {
                            return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                {/* TODO: Fix this the next time the file is edited. */}
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label className="control-label text-right">
                                                    Notes {values?.notes?.length || 0}/2500
                                                </label>
                                                <textarea
                                                    className="form-control note-area"
                                                    placeholder="New note"
                                                    rows="3"
                                                    maxLength="2500"
                                                    name="notes"
                                                    value={values?.notes}
                                                    onChange={(e) => {
                                                        const val = e.target.value;
                                                        setFieldValue('notes', val);
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="select-note-status">
                                        <Col md={6}>
                                            <FormGroup>
                                                <ControlLabel htmlFor="noteStatus" className="field-required">
                                                    Note Status
                                                </ControlLabel>
                                                <Select
                                                    classNamePrefix="react-select"
                                                    options={noteStatusList}
                                                    isClearable
                                                    required
                                                    inputId="noteStatus"
                                                    name="status"
                                                    placeholder="Select a note status"
                                                    getOptionValue={R.prop('valueName')}
                                                    getOptionLabel={R.prop('valueName')}
                                                    onChange={(option) => {
                                                        setFieldValue('status', option);
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="text-right">
                                            <Button
                                                id="cancel-add-note-button"
                                                onClick={() => setShowAddNoteForm(false)}
                                                className="btn btn-default"
                                                type="button"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                id="add-note-button"
                                                className="btn btn-primary add-note-button"
                                                disabled={isSubmitting || !values.status}
                                                type="submit"
                                            >
                                                Add Note
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            )}
        </Modal>
    );
};

ActivityNotesModal.propTypes = propTypes;
ActivityNotesModal.defaultProps = defaultProps;

export default ActivityNotesModal;
