import React, { useMemo } from 'react';
import Table from 'App/components/Table/Table';
import * as R from 'ramda';
import ToggleAll from 'App/components/MedicationManagement/ToggleAll/ToggleAll';
import MedicationSetting from 'App/components/MedicationManagement/MedicationSetting/MedicationSetting';

const propTypes = {};

const defaultProps = {};

const tableStateReducer = (state, action, prevState) => {
    const initialSort = [{ id: 'Name', desc: false }];
    const { type } = action;
    switch (type) {
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
        case 'toggleSortBy': {
            return state;
        }
        default: {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            state.sortBy = prevState.sortBy;
        }
    }
    return state;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
function MedicationManagementTable({ field }) {
    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: R.path(['name']),
            },
            {
                Header: 'Enroll',
                accessor: (data) => (data.enroll ? 1 : 0),
                Filter: <ToggleAll column="enroll" />,
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, no-unused-vars, react/no-unstable-nested-components
                Cell: ({ row, value }) => {
                    return <MedicationSetting type="enroll" row={row} />;
                },
            },
            {
                Header: 'Manage',
                accessor: (data) => (data.manage ? 1 : 0),
                Filter: <ToggleAll column="manage" />,
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, no-unused-vars, react/no-unstable-nested-components
                Cell: ({ row, value }) => {
                    return <MedicationSetting type="manage" row={row} />;
                },
            },
        ];
    }, []);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    const { value } = field;
    return (
        <Table
            data={value}
            columns={columns}
            stateReducer={tableStateReducer}
            getKey={R.prop('medicationId')}
            pageSizeOptions={[10, 50, 100, 200]}
        />
    );
}

MedicationManagementTable.propTypes = propTypes;
MedicationManagementTable.defaultProps = defaultProps;

export default MedicationManagementTable;
