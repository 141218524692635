import CompanyService from 'App/services/CompanyService';
import * as R from 'ramda';
import { errorHandler } from '../../../../utils';

/** @ngInject */
function WorkQueueTherapeuticCategoryFilterController(_, workQueueService, CSRF_ID_VALUE, CSRF_ID_NAME) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeFilter = doChangeFilter;

    ctrl.group_ids = {};
    ctrl.all_categories = true;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    function $onChanges(changes) {
        if (changes.allCategories && ctrl.allCategories !== undefined) {
            ctrl.all_categories = angular.copy(ctrl.allCategories);
        }

        if (changes.groupIds && ctrl.groupIds) {
            ctrl.group_ids = angular.copy(ctrl.groupIds);
        }
    }

    function $onInit() {
        return CompanyService.getTherapyCategories()
            .then((therapies) => {
                ctrl.therapies = R.compose(R.sortBy(R.prop('name')), R.values)(therapies);
            })
            .catch(errorHandler);
    }

    function doChangeFilter() {
        ctrl.onChangeFilter({
            filter: {
                all_categories: ctrl.all_categories,
                group_ids: workQueueService.clearObject(ctrl.group_ids),
            },
        });
    }
}

export default WorkQueueTherapeuticCategoryFilterController;
