import { useQuery } from 'react-query';
import * as R from 'ramda';
import AssessmentService from 'App/services/AssessmentService';
import UserService from 'App/services/UserService';
import { errorHandler, parseAssessmentSettings, shouldShowRetiredAssessment } from 'App/utils';
import { useUserContext } from 'App/contexts/UserContext';

/**
 * Custom hook which make all the http call to fetch therapy assessments required
 * @param {string} categoryId
 * @returns {Object}
 */
function useGetTherapyAssessments(categoryId) {
    const user = useUserContext();
    const companyId = user.active_company.ID;

    const showRetiredAssessments = R.pathOr(
        false,
        ['company_permissions', 'ShowRetiredAssessments'],
        UserService.get()
    );
    const getValuesAndSort = R.compose(R.sortBy(R.prop('name')), R.values);
    const fetchAssessments = () => {
        return !categoryId
            ? []
            : AssessmentService.getTherapyAssessments(categoryId, showRetiredAssessments)
                  .then(getValuesAndSort)
                  .then(
                      R.compose(
                          R.filter(shouldShowRetiredAssessment(showRetiredAssessments)),
                          R.map(parseAssessmentSettings),
                          R.values
                      )
                  );
    };

    const queryKey = [companyId, 'therapyAssessments', categoryId, showRetiredAssessments];
    const time = 5 * 60 * 1000;
    return useQuery(queryKey, fetchAssessments, {
        cacheTime: time,
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useGetTherapyAssessments;
