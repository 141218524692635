import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Row, Button, Col, Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { chunk, trimStart, findLast, findIndex } from 'lodash';
import { errorHandler } from '@/app/utils';
import CompanyService from '@/app/services/CompanyService';
import useActivityTextScheduleQuery from '@/app/hooks/useActivityTextScheduleQuery';
import TextScheduleModal from './TextScheduleModal';
import Main from '../styles/Main/Main';
import './PatientMessagingStyles.scss';
import ManualPageTable from '../ManualPageTable';
import { SelectInput } from '@/lib/form/SelectField';
import * as R from 'ramda';
import useActivityTextScheduleAssignmentQuery from '@/app/hooks/useActivityTextScheduleAssignmentQuery';
import PaddedRow from '@/app/common/styles/PaddedRow';
import useActivityTextScheduleAssignmentMutation from '@/app/hooks/useActivityTextScheduleAssignmentMutation';
import { useQueryClient } from 'react-query';
import SearchColumnFilterManual from '../ManualPageTable/SearchColumnFilterManual';
import SelectColumnFilterManual from '../ManualPageTable/SelectColumnFilterManual';
import { useUserContext } from '@/app/contexts/UserContext';

const propTypes = {};
const defaultProps = {};

// Components
const PaddedButton = styled(Button)`
    padding-top: 0;
    padding-bottom: 0;
`;

const PaddedColumn = styled(Col)`
    padding-top: 15px;
`;

// pagination options
const pageSizeOptions = [10, 25, 100];

function ActivityTextSchedule() {
    const [showDefaultModel, setShowDefaultModel] = useState(false);
    const [branchSetting, setBranchSetting] = useState([]);
    const [textScheduleData, setTextScheuleData] = useState(null);
    const [dataToSave, setDataToSave] = useState([]);
    const [query, setQuery] = useState({
        page: 0,
        limit: 10,
        assessmentName: '',
        createdBy: '',
        sortKey: 'name',
        sortOrder: 'asc',
    });
    const { data: textScheduleList } = useActivityTextScheduleQuery();
    const activityTextScheduleAssignmentMutation = useActivityTextScheduleAssignmentMutation();
    const { data, total, isLoading, allAssignments } = useActivityTextScheduleAssignmentQuery(query);
    const queryClient = useQueryClient();
    const user = useUserContext();

    const options = useMemo(
        () => [
            {
                text: 'Therigy',
                value: 'Therigy',
            },
            {
                text: 'My Pharmacy',
                value: user.active_company.ID,
            },
        ],
        [user]
    );

    const branchSettings = async () => {
        try {
            const firstMessge = await CompanyService.getBranchSettings('messagingFirstMessage');
            const secondMessage = await CompanyService.getBranchSettings('messagingSecondMessage');
            setBranchSetting([firstMessge, secondMessage]);
        } catch (error) {
            errorHandler(error);
        }
    };

    const getDays = (text) => (text < 0 ? { value: 'after', label: 'after' } : { value: 'before', label: 'before' });

    const textScheduleOnClick = (textSchedule) => {
        if (typeof textSchedule === 'string') {
            setShowDefaultModel(true);
        } else {
            const activeTextSchedule = {
                name: textSchedule.name,
                uuid: textSchedule.uuid,
                schedule: [],
            };
            Object.keys(textSchedule).forEach((key) => {
                if (!['name', 'uuid'].includes(key) && textSchedule[key] !== null) {
                    activeTextSchedule.schedule.push({
                        text: trimStart(`${textSchedule[key]}`, '-'),
                        select: getDays(textSchedule[key]),
                    });
                }
            });
            setTextScheuleData(activeTextSchedule);
        }
    };

    const textSchedleModelOnClose = (uuidActiveTextSchedule) => {
        if (uuidActiveTextSchedule) {
            // check deleted uuid is in data to save
            const savedAssignmentsExist = dataToSave.some(
                (savedAssignment) => savedAssignment.activityTextSchedulesUuid === uuidActiveTextSchedule
            );
            // if exist remove those data
            if (savedAssignmentsExist) {
                setDataToSave((prv) =>
                    prv.filter((prvData) => prvData.activityTextSchedulesUuid !== uuidActiveTextSchedule)
                );
            }
            // check deleted schedule is in table
            const assignmentsExist = data.getAllAssesments.data.some(
                (assignments) => assignments.activity.activityTextSchedulesUuid === uuidActiveTextSchedule
            );
            // if exist refetch current page
            if (assignmentsExist) {
                queryClient.refetchQueries(['getAllAssignment', query]);
            }
        }
        setTextScheuleData(null);
    };

    const onPageChange = ({ pageIndex, pageSize }) => {
        setQuery((prev) => ({ ...prev, page: pageIndex - 1, limit: pageSize }));
    };

    const onSortChange = (sort) => {
        setQuery((prev) => ({ ...prev, ...sort }));
    };

    const onReset = () => {
        setDataToSave([]);
    };

    const onSave = () => {
        activityTextScheduleAssignmentMutation.mutateAsync(dataToSave).then(() => {
            queryClient.refetchQueries(['getAllAssignment', query]);
            onReset();
        });
    };

    const onTextChange = (e) => {
        e.persist();
        setQuery((prev) => ({ ...prev, [e.target.name]: e.target.value, page: 0 }));
    };

    const onSelectChange = (e) => {
        e.persist();
        setQuery((prev) => ({
            ...prev,
            [e.target.name]: e.target.value === 'Therigy' ? null : e.target.value,
            page: 0,
        }));
    };

    useEffect(() => {
        branchSettings();
    }, []);

    const columns = useMemo(() => {
        const textSchedule = textScheduleList.map((activityTextScheduleData) => ({
            label:
                typeof activityTextScheduleData === 'string' ? activityTextScheduleData : activityTextScheduleData.name,
            value: typeof activityTextScheduleData === 'string' ? '' : activityTextScheduleData.uuid,
        }));
        return [
            {
                Header: 'Activity Name',
                accessor: 'name',
                id: 'name',
                Filter: (
                    <SearchColumnFilterManual
                        name="assessmentName"
                        placeHolder="Filter by Activity Name"
                        onFilter={onTextChange}
                    />
                ),
                canFilter: true,
                canSort: true,
            },
            {
                Header: 'Created By',
                Filter: (
                    <SelectColumnFilterManual
                        onFilter={onSelectChange}
                        options={options}
                        name="createdBy"
                        label="Company Filter"
                    />
                ),
                accessor: 'company',
                id: 'company',
                canFilter: true,
                canSort: true,
            },
            {
                Header: 'Text Schedule',
                canSort: false,
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row }) => {
                    // eslint-disable-next-line react/prop-types
                    let selectedActivityText = findLast(dataToSave, { pollId: row.original.id });
                    if (!selectedActivityText) {
                        // eslint-disable-next-line react/prop-types
                        selectedActivityText = row.original;
                    }
                    const schedule = findLast(textScheduleList, {
                        uuid: selectedActivityText.activityTextSchedulesUuid,
                    });
                    selectedActivityText = {
                        value: schedule ? schedule.uuid : '',
                        label: schedule ? schedule.name : 'Default',
                    };
                    // eslint-disable-next-line react/prop-types
                    const rowId = row.id;
                    return (
                        <SelectInput
                            options={textSchedule}
                            name={rowId}
                            fieldName={rowId}
                            getOptionValue={R.prop('value')}
                            getOptionLabel={R.prop('label')}
                            value={selectedActivityText}
                            onChange={(option) => {
                                setDataToSave((prevData) => {
                                    // eslint-disable-next-line react/prop-types
                                    const index = findIndex(prevData, { pollId: row.original.id });
                                    if (index !== -1) {
                                        prevData.splice(index, 1);
                                    }
                                    if (
                                        // eslint-disable-next-line react/prop-types
                                        option.value === row.original.activityTextSchedulesUuid ||
                                        // eslint-disable-next-line react/prop-types
                                        (option.value === '' && row.original.activityTextSchedulesUuid === null)
                                    ) {
                                        return [...prevData];
                                    }
                                    return [
                                        ...prevData,
                                        {
                                            // eslint-disable-next-line react/prop-types
                                            pollId: row.original.id,
                                            activityTextSchedulesUuid: option.value,
                                            useDefault: !option.value,
                                        },
                                    ];
                                });
                            }}
                            onBlur={() => {}}
                        />
                    );
                },
                canFilter: false,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textScheduleList, dataToSave, allAssignments, options]);

    return (
        <Main fluid className="activity-text-schedule">
            <h1>Edit Activity Text Schedule</h1>
            <Row>
                <span>Use this page to create custom text schedules and assign to an activity.</span>
            </Row>
            {textScheduleList.length < 11 && (
                <div className="add-text-schedule">
                    <Button
                        bsStyle="link"
                        bsSize="sm"
                        data-testid="addTextSchedule"
                        aria-label="Add Text Schedule"
                        title="Add Text Schedule"
                        onClick={() =>
                            setTextScheuleData({
                                name: '',
                                schedule: [
                                    {
                                        text: '',
                                        select: getDays(),
                                    },
                                ],
                            })
                        }
                    >
                        <FaPlus data-testid="plusIconAddTextSchedule" /> Add Text Schedule
                    </Button>
                </div>
            )}
            <div style={{ maxWidth: '800px', marginTop: '10px' }}>
                <Row>
                    {chunk(textScheduleList, 6).map((scheduleList, index) => (
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/no-array-index-key
                        <Col key={index} className="col-md-6">
                            <ul>
                                {scheduleList.map((schedule, scheduleIndex) => (
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line react/no-array-index-key
                                    <li key={scheduleIndex}>
                                        <PaddedButton
                                            data-testid={`paddingbutton-${scheduleIndex}`}
                                            bsStyle="link"
                                            bsSize="sm"
                                            title={scheduleIndex}
                                            onClick={() => textScheduleOnClick(schedule)}
                                        >
                                            {typeof schedule === 'string' ? schedule : schedule.name}
                                        </PaddedButton>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    ))}
                </Row>
            </div>
            {!isLoading && allAssignments ? (
                <PaddedRow>
                    <PaddedColumn sm={12}>
                        <ManualPageTable
                            columns={columns}
                            data={allAssignments}
                            totalRows={total}
                            onPageChange={onPageChange}
                            isLoading={isLoading}
                            onSortChange={onSortChange}
                            placeholderText="No assignment data found for this company"
                            pageSizeOptions={pageSizeOptions}
                            currentPage={query.page + 1}
                            initialSortKey="name"
                            initialSortOrder="asc"
                        />
                    </PaddedColumn>
                    <PaddedColumn sm={12}>
                        <Button
                            bsStyle="primary"
                            name="save"
                            disabled={activityTextScheduleAssignmentMutation.isLoading || !dataToSave.length}
                            className="float-right"
                            onClick={onSave}
                            data-testid="save-btn"
                        >
                            Save
                        </Button>
                        <Button
                            bsStyle="default"
                            name="reset"
                            disabled={activityTextScheduleAssignmentMutation.isLoading || !dataToSave.length}
                            className="float-right"
                            onClick={onReset}
                            data-testid="reset-btn"
                        >
                            Reset
                        </Button>
                    </PaddedColumn>
                </PaddedRow>
            ) : null}
            <TextScheduleModal textScheduleData={textScheduleData} onClose={textSchedleModelOnClose} />
            <Modal show={showDefaultModel} backdrop="static">
                <Modal.Header closeButton={false}>
                    <Modal.Title className="header">Default Text Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col className="modal-body-disclaimer">
                        <p>
                            The default text schedule for your current branch. Note: default text schedules may vary by
                            branch.
                        </p>
                        {branchSetting.map((settings, index) => (
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line react/no-array-index-key
                            <p key={index}>
                                Text sent {settings.replace(/-/g, '')} days{' '}
                                {settings.includes('-') ? 'after' : 'before'} activity due date.
                            </p>
                        ))}
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="default"
                        name="close"
                        className="float-right"
                        onClick={() => setShowDefaultModel(false)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Main>
    );
}

ActivityTextSchedule.prototype = propTypes;
ActivityTextSchedule.defaultProps = defaultProps;

export default ActivityTextSchedule;
