import createAxios from 'App/utils/createAxios';
import __env from '../components/config/config';
import graphClient from '../common/graphClient';
import { gql } from 'graphql-request';

const endpoint = `${__env.slsEndpoint}:${__env.slsPort}${__env.slsStage ? `/${__env.slsStage}-messaging` : ''}`;

const smsAxios = createAxios({ baseURL: `${endpoint}/${__env.slsVersion}/sms` });
const activityScheduleAxios = createAxios({ baseURL: `${endpoint}/messaging/${__env.slsVersion}` });

/**
 * Send message to serverless to initiate mass texting
 * @param data - Request Body.
 * @returns {Promise<labels>} - A map of success and failed additions to queue.
 */
function sendMassText(data) {
    return smsAxios.post(`/massText/send`, data);
}

/**
 *Retrieves the activity text schedule from the serverless function.
 *@returns {Promise<Object>} - The activity text schedule.
 */
function getActivityTextSchedule() {
    return activityScheduleAxios.get(`/activitySchedule`);
}

/**
 *Posts the activity text schedule to the serverless function.
 *@param {Object} body - The body containing the activity text schedule.
 *@returns {Promise<Object>} - The response from the server.
 */
function postActivityTextSchedule(body) {
    return activityScheduleAxios.post(`/activitySchedule`, body);
}

/**
 *Updates the activity text schedule using a patch request to the serverless function.
 *@param {string} uuid - The uuid of the activity text schedule to be updated.
 *@param {Object} body - The body containing the updates.
 *@returns {Promise<Object>} - The response from the server.
 */
function patchActivityTextSchedule(uuid, body) {
    return activityScheduleAxios.patch(`/activitySchedule/${uuid}`, body);
}

/**
 *Posts the activity text schedule to the serverless function.
 *@param {Array.<Object>} body - The body containing the activity text schedule assignments.
 *@returns {Promise<Array.<Object>>} - The response from the server.
 */
function postActivityTextScheduleAssignments(body) {
    return activityScheduleAxios.post('/activitySchedule/assign', body);
}

/**
 *GraphQl request for fetch all assignments
 *@param {number} page - Requested Page.
 *@param {number} limit - Responce limit.
 *@param {string} assessmentName - Filter by assignment name
 *@param {string} createdBy - Filter by createdBy
 *@param {string} sortKey - sort by key
 *@param {string} sortOrder - sort order
 *@returns {Promise<Object>} - The response from the server.
 */
function getAllAssignment({ page, limit, assessmentName = '', createdBy = '', sortKey = 'name', sortOrder = 'asc' }) {
    return graphClient.request(
        gql`
            query FetchAllAssesments(
                $mobileEnabled: Boolean!
                $page: Int!
                $limit: Int!
                $assessmentName: String
                $createdBy: String
                $sortKey: String
                $sortOrder: String
            ) {
                getAllAssesments(
                    mobileEnabled: $mobileEnabled
                    page: $page
                    limit: $limit
                    assessmentName: $assessmentName
                    createdBy: $createdBy
                    sortKey: $sortKey
                    sortOrder: $sortOrder
                ) {
                    data {
                        id
                        name
                        activity
                        companyId
                    }
                    total
                }
            }
        `,
        {
            mobileEnabled: true,
            page,
            limit,
            assessmentName,
            createdBy,
            sortKey,
            sortOrder,
        }
    );
}

/**
 *delete the activity text schedule using a delete request to the serverless function.
 *@param {string} uuid - The uuid of the activity text schedule to be deleted.
 *@returns {Promise<Object>} - The response from the server.
 */
function deleteActivityTextSchedule(uuid) {
    return activityScheduleAxios.delete(`/activitySchedule/${uuid}`);
}

/**
 * Send message to serverless to initiate mass texting
 * @returns {Promise<labels>} - A map of success and failed additions to queue.
 */
function getMessageTemplates() {
    return activityScheduleAxios.get(`/sms/message/templates`);
}

export {
    sendMassText,
    getActivityTextSchedule,
    postActivityTextSchedule,
    patchActivityTextSchedule,
    getAllAssignment,
    postActivityTextScheduleAssignments,
    deleteActivityTextSchedule,
    getMessageTemplates,
};
