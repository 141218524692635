/** @ngInject */
function AetnaActivityService($http, urlBuilderService, httpCache) {
    return {
        get,
    };

    function get(menuName) {
        const url = urlBuilderService.build(menuName, {
            resource: '',
            menu: menuName,
            options: '',
        });

        return $http
            .get(url, {
                cache: httpCache,
            })
            .then((res) => {
                return res.data;
            });
    }
}

export default AetnaActivityService;
