import angular from 'angular';

import ActivePatientFieldsController from './active-patient-fields.controller';
import ActivePatientFieldsConfig from './active-patient-fields.config';

export default angular
    .module('components.activePatientFields', ['ngAnimate', 'ngTable', 'toastr', 'ui.router'])
    .component('activePatientFields', {
        bindings: {
            assessments: '<',
            categories: '<',
            user: '<',
        },
        controller: ActivePatientFieldsController,
        template: require('./active-patient-fields.html'),
    })
    .config(ActivePatientFieldsConfig).name;
