import React from 'react';
import { useFormikContext } from 'formik';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Button from 'react-bootstrap/lib/Button';
import * as R from 'ramda';
import { addCondition } from '../utils';

const propTypes = {};

const defaultProps = {};

function AddConditionButton() {
    //#region State
    const formik = useFormikContext();
    const { values: formikValues } = formik;
    //#endregion
    return (
        <FormGroup>
            <Button
                type="button"
                disabled={R.isNil(formikValues.filterField)}
                onClick={() => {
                    const groupName = R.path(['filterField', 'label'], formikValues);

                    if (R.path(['conditions', groupName], formikValues)) {
                        R.compose(
                            formik.setValues,
                            R.set(R.lensProp('filterField'), null),
                            addCondition(groupName, [])
                        )(formikValues);
                    } else {
                        R.compose(
                            formik.setValues,
                            R.set(R.lensProp('filterField'), null),
                            R.set(R.lensPath(['conditions', groupName, 'field']), formikValues.filterField)
                        )(formikValues);
                    }
                }}
            >
                Add Condition
            </Button>
        </FormGroup>
    );
}

AddConditionButton.propTypes = propTypes;
AddConditionButton.defaultProps = defaultProps;

export default AddConditionButton;
