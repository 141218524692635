import * as R from 'ramda';
import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import Table from 'App/components/Table';
import pathOrDash from 'App/utils/pathOrDash';
import IncludeCell from './IncludeCell';

const propTypes = {};

const defaultProps = {};

function tableStateReducer(state, action, prevState) {
    const initialSort = [{ id: 'name', desc: false }];

    const { type } = action;

    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
        default: {
            return state;
        }
    }
}

function ProtocolTable() {
    const { values } = useFormikContext();

    const columns = useMemo(() => {
        return [
            {
                Header: 'Include',
                Cell: IncludeCell,
            },
            {
                Header: 'Name',
                id: 'name',
                accessor: pathOrDash(['protocol', 'name']),
            },
        ];
    }, []);

    const protocols = useMemo(() => {
        return R.values(values.protocols);
    }, [values.protocols]);

    return <Table columns={columns} data={protocols} stateReducer={tableStateReducer} getKey={R.prop('id')} />;
}

ProtocolTable.propTypes = propTypes;
ProtocolTable.defaultProps = defaultProps;

export default ProtocolTable;
