import { localize } from 'App/utils';
import axios from 'axios';
import Dayjs from 'dayjs';
import { cloneDeep, first, last, size } from 'lodash';
import * as R from 'ramda';
import dayjs from 'dayjs';
import isExpired from 'App/utils/isExpired';
import { getData } from 'App/utils';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';
import SessionStorageService from 'App/services/SessionStorageService';

const formatActivity = (activityParam) => {
    const activity = cloneDeep(activityParam);
    activity.dateCompleted = localize(activity.dateCompleted, 'YYYY/MM/DD');
    activity.dateDue = localize(activity.dateDue, 'YYYY/MM/DD');

    if (activity.status === 'Closed') {
        activity.status = 'Finished';
    }

    switch (activity.defaultNoteType) {
        case '0':
            activity.typeLabel = 'PCC';
            break;
        case '1':
            activity.typeLabel = 'Clinical';
            break;
        default:
            activity.typeLabel = null;
            break;
    }
    return activity;
};

const formatActivities = (activities) => {
    return activities.map((activity) => formatActivity(activity));
};

const generateReferralLink = (params) => {
    const { patientId, referralId, activityId, typeId } = params;
    switch (typeId) {
        case 0:
            return `/patient/${patientId}/referral/${referralId}/benefits-investigation/${activityId}/type/0`;
        case 1:
            return `/patient/${patientId}/referral/${referralId}/prior-authorization/${activityId}/type/1`;
        case 2:
            return `/patient/${patientId}/referral/${referralId}/financial-assistance/${activityId}/type/2`;
        case 3:
            return `/patient/${patientId}/referral/${referralId}/appeal/${activityId}/type/3`;
        case 4:
            return `/patient/${patientId}/referral/${referralId}/prior-authorization/${activityId}/type/4`;
        case 5:
            return `/patient/${patientId}/referral/${referralId}/financial-assistance/${activityId}/type/5`;
        default:
            return '';
    }
};

const formatReferrals = (referrals) => {
    return referrals.map((referralParam) => {
        const referral = cloneDeep(referralParam);
        if (Array.isArray(referral.activities) && size(referral.activities) > 0) {
            referral.activities = referral.activities.map((activityParam) => {
                const activity = cloneDeep(activityParam);
                const { notes } = activity;
                if (Array.isArray(notes) && size(notes) > 0) {
                    const latestNotes = last(notes);
                    activity.noteStatus = latestNotes.status;
                } else {
                    activity.noteStatus = activity.status;
                }
                activity.typeLabel = 'Referral';
                activity.dateCompleted = localize(activity.completedOn, 'YYYY/MM/DD');
                activity.dateDue = localize(activity.dueOn, 'YYYY/MM/DD');
                switch (activity.status) {
                    case 'Appealed':
                    case 'Appealed - Overturned':
                    case 'Appealed - Upheld':
                    case 'Approved':
                    case 'Complete':
                    case 'Completed':
                    case 'Denied':
                    case 'Not Required':
                    case 'Overturned':
                    case 'Partially Approved':
                    case 'Upheld':
                        activity.activityStatusLookupId = '1';
                        break;
                    case 'Deleted':
                        activity.activityStatusLookupId = '2';
                        break;
                    default:
                        activity.activityStatusLookupId = '0';
                        break;
                }
                return activity;
            });
        }
        return referral;
    });
};

const getLatestMobileAssessment = (assessments) => {
    return assessments.reduce((agg, assessment) => {
        if (!agg) {
            return assessment;
        }
        const laterAssessmentDate = dayjs(assessment.unsubscribedOn).isAfter(assessment.subscribedOn)
            ? assessment.unsubscribedOn
            : assessment.subscribedOn;
        const laterAggDate = dayjs(agg.unsubscribedOn).isAfter(agg.subscribedOn)
            ? agg.unsubscribedOn
            : agg.subscribedOn;
        if (dayjs(laterAssessmentDate).isAfter(laterAggDate)) {
            return assessment;
        }
        return agg;
    });
};

const getPatientSubscriptions = (subscriptions = []) => {
    let patientEducationSubscription = null;
    let patientMessagingSubscription = null;

    const videos = subscriptions.filter(
        (subscription) => subscription.name === 'Pharmacy Videos' && subscription.unsubscribedOn === null
    );
    const assessments = subscriptions.filter((subscription) => subscription.name === 'Mobile Assessments');
    if (videos.length) {
        patientEducationSubscription = { ...videos[0] };
    }
    const currentAssessment = assessments.length > 1 ? getLatestMobileAssessment(assessments) : assessments[0];
    if (currentAssessment) {
        patientMessagingSubscription = {
            ...currentAssessment,
            status: !!(currentAssessment.subscribedOn && currentAssessment.unsubscribedOn === null),
        };
    }
    return [patientEducationSubscription, patientMessagingSubscription];
};

const bindTherapyAndMedications = (therapies, medications) => {
    if (Array.isArray(therapies) && Array.isArray(medications)) {
        const medicationRecords = medications.map((medication) => {
            return {
                ...medication,
                status: Array.isArray(medication?.status) ? last(medication?.status) : {},
            };
        });
        return therapies.map((therapy) => {
            return {
                ...therapy,
                medications: medicationRecords.filter((ele) => therapy.id === ele.medication.groupId),
            };
        });
    }
    return [];
};

const formatDate = (date, dateFormat = 'YYYY/MM/DD') => {
    const dateString = date instanceof Date ? date.toISOString() : date;
    if (
        typeof dateString !== 'string' ||
        dateString.length < 10 ||
        dateString === '0000-00-00 00:00:00' ||
        typeof dateFormat !== 'string' ||
        dateFormat.length === 0
    ) {
        return null;
    }
    const result = new Dayjs(dateString);
    if (result.isValid() === false) {
        return null;
    }
    return result.format(dateFormat);
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to serverless
const uploadPatientActivityFiles = (formValues, patientId) => {
    const url = `/stm/patient_activity.php?submit=true&patient_id=${patientId}`;
    return axios({
        method: 'POST',
        url,
        headers: { 'Content-Type': undefined }, // Let the browser determine the content-type

        // Transform the request to allow us to post the file and the json data
        transformRequest(values) {
            const formData = new FormData();
            formData.append('parent_note_id', values.parent_note_id);
            formData.append('form_file_description', values.form_file_description);
            formData.append('file', values.files);
            return formData;
        },
        data: {
            parent_note_id: formValues.activity?.parentNoteId,
            form_file_description: formValues.description,
            files: formValues.file,
        },
    });
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to serverless
/**
 * @param {boolean} pccValue value depending on whether user have opted for pcc email services
 * @param {string} patientId patiendId of the patient
 */
const updatePccEmail = (pccValue, patientId) => {
    const url = `/app.api.php/api/patient/${patientId}`;
    return axios({
        method: 'PATCH',
        url,
        headers: { 'Content-Type': undefined }, // Let the browser determine the content-type
        data: {
            pcc_send_email: !pccValue,
        },
    });
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to graphql
const deletePatientActivity = (patientId, filesIds) => {
    const url = `/stm/patient_activity.php?patient_id=${patientId}`;
    const formData = new FormData();
    formData.append('delete', true);
    filesIds.map((fileId) => formData.append('targets[]', fileId));

    return axios({
        method: 'POST',
        url,
        data: formData,
    });
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to graphql
const fetchPatientMedicationHistory = (patientId, medicationId) => {
    const url = `/app.api.php/api/patient/${patientId}/medication/${medicationId}/statushistory`;

    return axios.get(url).then(getData).then(convertKeysToCamelCase).then(Object.values);
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to graphql
const fetchPatientTherapeuticCategoryHistory = (patientId, categoryId) => {
    const url = `/app.api.php/api/patient/${patientId}/therapy/${categoryId}/history`;
    return axios.get(url).then(getData).then(convertKeysToCamelCase).then(Object.values);
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to graphql
const fetchPatientTherapeuticCategoryNotes = (patientId, parentNoteId) => {
    const url = `/app.api.php/api/patient/${patientId}/therapy/status/${parentNoteId}/notes`;
    return axios.get(url).then(getData).then(convertKeysToCamelCase).then(Object.values);
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to graphql
const fetchPatientMedicationNotes = (patientId, medicationId, parentNoteId) => {
    const url = `/app.api.php/api/patient/${patientId}/medication/${medicationId}/notes/${parentNoteId}`;

    return axios.get(url).then(getData).then(convertKeysToCamelCase).then(Object.values);
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to graphql
const fetchPatientMedicationRxHistory = (patientId, medicationId) => {
    const url = `/app.api.php/api/patient/${patientId}/medication/${medicationId}/history`;

    return axios.get(url).then(getData).then(convertKeysToCamelCase).then(Object.values);
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to graphql
const fetchPatientPrescriptionNotes = (patientId, medicationId, parentNoteId) => {
    const url = `/app.api.php/api/patient/${patientId}/medication/${medicationId}/rxId/${parentNoteId}/notes`;

    return axios.get(url).then(getData).then(convertKeysToCamelCase).then(Object.values);
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to graphql
const addMedicationNote = (patientId, medicationId, parentNoteId, note) => {
    const url = `/app.api.php/api/patient/${patientId}/medication/${medicationId}/notes/${parentNoteId}`;

    return axios({
        method: 'POST',
        url,
        data: { note },
    });
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to graphql
const addTherapeuticCategoryNote = (patientId, parentNoteId, note) => {
    const url = `/app.api.php/api/patient/${patientId}/therapy/status/${parentNoteId}/note`;
    return axios({
        method: 'POST',
        url,
        data: { note },
    });
};

//These functions are temporary here to support UI, but would be removed later as we migrate this endpoint to graphql
const addPrescriptionNote = (patientId, medicationId, parentNoteId, note) => {
    const url = `/app.api.php/api/patient/${patientId}/medication/${medicationId}/rxId/${parentNoteId}/note`;
    const formData = new FormData();
    formData.append('note', note);

    return axios({
        method: 'POST',
        url,
        data: formData,
    });
};

const formatMedications = (medications) => {
    return medications.map((medication) => {
        const medicationStatus = first(medication.status) || {
            id: null,
            patientMedicationId: null,
            startedBy: null,
            start: null,
            endedBy: null,
            end: null,
            endReason: null,
        };
        return {
            ...medication,
            ended: R.allPass([R.path(['end']), R.compose(isExpired, R.path(['end']))])(medicationStatus),
        };
    });
};

export const PatientSection = {
    pendingActivities: 'pendingActivities',
    pastActivities: 'pastActivities',
    deletedActivities: 'deletedActivities',
    therapies: 'therapies',
    insurance: 'insurance',
};

export const defaultVisibilityState = {
    [PatientSection.therapies]: true,
    [PatientSection.insurance]: true,
    [PatientSection.pendingActivities]: true,
    [PatientSection.pastActivities]: false,
    [PatientSection.deletedActivities]: false,
};

const getSectionVisibility = (sectionName) => {
    const sectionVisibility = SessionStorageService.getOnUser('patientProfile_', true) || defaultVisibilityState;
    return sectionVisibility[sectionName] || false;
};

const toggleSectionVisibility = (sectionName) => {
    const sectionVisibility = SessionStorageService.getOnUser('patientProfile_', true) || defaultVisibilityState;

    SessionStorageService.setOnUser('patientProfile_', {
        ...sectionVisibility,
        [sectionName]: !sectionVisibility[sectionName],
    });
};

export {
    addMedicationNote,
    addPrescriptionNote,
    addTherapeuticCategoryNote,
    formatActivities,
    generateReferralLink,
    getSectionVisibility,
    formatReferrals,
    fetchPatientMedicationHistory,
    fetchPatientMedicationNotes,
    fetchPatientMedicationRxHistory,
    fetchPatientPrescriptionNotes,
    fetchPatientTherapeuticCategoryHistory,
    fetchPatientTherapeuticCategoryNotes,
    getPatientSubscriptions,
    bindTherapyAndMedications,
    formatDate,
    uploadPatientActivityFiles,
    deletePatientActivity,
    updatePccEmail,
    formatMedications,
    getLatestMobileAssessment,
    toggleSectionVisibility,
};
