import hasLength from 'App/utils/hasLength';

/** @ngInject */
function UrlBuilderService(_, $location, CSRF_ID_VALUE, CSRF_ID_NAME) {
    let therigyURL = `${$location.protocol()}://${$location.host()}`;
    const port = $location.port();
    if (port) {
        therigyURL += `:${port}`;
    }

    const service = {
        build,
        get,
        getHost,
        normalize: normalizeResponse,
        format,
    };

    return service;

    function build(type, data) {
        return buildURL(data, type);
    }

    /**
     * @access private
     * @param token
     * @param data array | object - Array of object with key and value or Object with key value
     * @returns {string}
     */
    function buildURL(data, type) {
        let url = '';
        switch (type) {
            case 'patientPortal':
                url = `${therigyURL}/app.patient-portal.php`;
                break;
            default:
                url = `${therigyURL}/app.api.php/api`;
        }

        /* Check if passed array then process each object */
        if (Array.isArray(data)) {
            data.forEach((datum) => {
                url = processRouteData(datum);
            });
        } else if (typeof data === 'object') {
            url = processRouteData(data);
        } else {
            throw new Error('Invalid data passed into url builder');
        }
        return url;

        function processRouteData(routeData) {
            try {
                for (const [key, value] of Object.entries(routeData)) {
                    if (typeof key === 'string') {
                        url += `/${key}`;
                        /**
                         * Handles the scenario where we append an extra trailing slash if the
                         *  value is an empty string.
                         */
                        if (hasLength(value) || typeof value === 'number') {
                            url += `/${value}`;
                        }
                    } else if (typeof key !== 'string') {
                        throw new Error(
                            `Invalid params object provided expected object key to be a string but got: ${typeof key}`
                        );
                    } else if (typeof value !== 'string') {
                        const msg = 'Invalid params object provided expected object value to be a string but got: ';
                        throw new Error(msg + typeof value);
                    }
                }
                return url;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                throw e;
            }
        }
    }

    function get(type, data) {
        return getRouteURL(type, data);
    }

    /**
     * @summary Returns the host name
     *
     * @returns {string} therigyURL - The host name
     */
    function getHost() {
        return therigyURL;
    }

    /**
     * URL builder since we are not using ng-route.
     * @param type {string} - Type of URL
     * @param data {object} - Options object of data to use when generating the URL
     * @returns {string} - The absolute URL string.
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function getRouteURL(type, data) {
        const api = `${therigyURL}/app.api.php/api`;
        switch (type) {
            case 'categories':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-useless-concat
                return `${api}/therapy` + `?${CSRF_ID_NAME}=${CSRF_ID_VALUE}`;
            case 'diagnosis':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-var,vars-on-top
                var therapyId = data.patient_therapy_id; // jshint ignore:line
                return `${api}/patient/${data.patientId}/diagnosis/${therapyId}?${CSRF_ID_NAME}=${CSRF_ID_VALUE}`;
            case 'patientMedication':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-useless-concat
                return `${api}/patient/${data.patientId}/medication` + `?${CSRF_ID_NAME}=${CSRF_ID_VALUE}`;
            case 'patientActivityMedication':
                return (
                    `${api}/patient/${data.patientId}/activity/${data.activityId}/medication` +
                    `?${CSRF_ID_NAME}=${CSRF_ID_VALUE}`
                );
            case 'patientActivityMedicationAction':
                // eslint-disable-next-line max-len
                return `${api}/patient/${data.patientId}/activity/${data.activityId}/medication/${data.medication}/rx_number/${data.rxNumber}?${CSRF_ID_NAME}=${CSRF_ID_VALUE}`;
            case 'duplicatePatient':
                return `${therigyURL}/ajax/patient_duplicate.php`;
            case 'physicianLookup':
                return `${therigyURL}/ajax/api.physician.php?id=${data.id}`;
            case 'physicianSubmit':
                return `${therigyURL}/ajax/api.physician.php`;
            default:
                // eslint-disable-next-line no-console
                console.trace(`Unsupported route type provided: ${type}`);
        }
    }

    /**
     * @summary Transforms our variable HTTP responses to more usable, non nested form.
     * @param {array|object} res - Res from HTTP promise
     * @returns mixed
     */
    function normalizeResponse(res) {
        return Array.isArray(res.data.results) && res.data.results.length >= 1 ? res.data.results[0] : res.data;
    }

    /**
     * @summary snakeCase array keys
     * @param data array
     * @returns array
     */
    function format(data) {
        _.forEach(data, (value, key) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            data[_.snakeCase(key)] = value;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete data[key];
        });

        return data;
    }
}

export default UrlBuilderService;
