import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';

function useFetchPatient(patientId, options) {
    return useQuery(['patient', patientId], () => PESService.get(patientId), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        ...options,
    });
}

export default useFetchPatient;
