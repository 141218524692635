import { useQuery } from 'react-query';
import { errorHandler } from '@/app/utils';
import UserService from 'App/services/UserService';

function useFetchMessageCountQuery() {
    return useQuery(
        ['messageCount'],
        () => {
            return UserService.getMessageCount();
        },
        {
            onError: errorHandler,
            // every 1 hours or until relogin
            cacheTime: 1000 * 60 * 60 * 1,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchMessageCountQuery;
