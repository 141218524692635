import dayjs from 'dayjs';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import TherigyErrorBoundary from 'App/components/errors/TherigyErrorBoundary';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import CustomMenuField from 'Lib/form/CustomMenu';
import { DatePickerField } from 'Lib/form/DatePickerField';
import SubmitButton from 'Lib/form/SubmitButton';
import { TextField } from 'Lib/form/TextField';
import { TextareaField } from 'Lib/form/TextareaField';
import { patientTherapyPropType } from 'Lib/types';

import SpaceBetweenContainer from '../SpaceBetweenContainer';

const TherapeuticCategoryOptOutModal = ({ onClose, onSubmit, existingTherapy }) => {
    return (
        <Modal show onHide={onClose}>
            <TherigyErrorBoundary>
                <Modal.Header>
                    <Modal.Title>Clinical Care Opt-out</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        therapy: {
                            ...existingTherapy,
                            status: {
                                ...existingTherapy.status,
                                endReason: existingTherapy ? { valueName: existingTherapy.status.endReason } : null,
                                optOutNotes: existingTherapy.status.optOutNotes || '',
                            },
                        },
                    }}
                    onSubmit={(values) => {
                        const submissionValues = {
                            therapy: {
                                status: {
                                    ...values.therapy.status,
                                    optIn: false,
                                    endReason: values.therapy.status.endReason.valueName,
                                },
                            },
                        };
                        onSubmit(submissionValues);
                        onClose();
                    }}
                >
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <div className="form-group">
                                    <TextField fieldName="therapy.name" label="Therapeutic Category" disabled />
                                </div>
                                <Row>
                                    <Col md={6}>
                                        <DatePickerField
                                            fieldName="therapy.status.end"
                                            label="Care Opt-out Date"
                                            placeholder="YYYY/MM/DD"
                                            required
                                            minDate={values.therapy.status.start}
                                            maxDate={dayjs().toDate()}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <CustomMenuField
                                            fieldName="therapy.status.endReason"
                                            label="Reason for Opt-out"
                                            menuName="therapy_opt_out_reasons"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <TextareaField
                                    fieldName="therapy.status.optOutNotes"
                                    label={
                                        <SpaceBetweenContainer>
                                            <div>Notes</div>
                                            <div>{values.therapy.status.optOutNotes.length}/200</div>
                                        </SpaceBetweenContainer>
                                    }
                                    placeholder="Write notes here"
                                    maxLength={200}
                                />
                            </Modal.Body>

                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <SubmitButton>Opt Out</SubmitButton>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    )}
                </Formik>
            </TherigyErrorBoundary>
        </Modal>
    );
};

TherapeuticCategoryOptOutModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    existingTherapy: patientTherapyPropType,
};

TherapeuticCategoryOptOutModal.defaultProps = {
    existingTherapy: null,
};

export default TherapeuticCategoryOptOutModal;
