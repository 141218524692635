import * as R from 'ramda';
import curry from 'ramda/src/curry';
import pathOr from 'ramda/src/pathOr';
import React from 'react';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import ActionCell, { ActionButtonLink } from '../../components/styles/ActionCell';
import { CREATED_BY } from './constants';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';

/**
 * Creates a component after first receiving some handlers.
 * Curried
 */
const actionCellRenderer = curry(({ copyHandler, toggleEnableHandler, deleteHandler }, { row }) => {
    const hasDeleteTherigyProtocol = useIsPermitted(PERMISSION_TYPES.DELETE_THERIGY_PROTOCOL);
    const protocol = row.original;
    const isEnabled = pathOr(false, ['original', 'isEnabled'], row);
    const createdBy = pathOr('', ['original', 'createdBy'], row);

    const showDelete = R.anyPass([R.equals(CREATED_BY.MY_PHARMACY), R.always(hasDeleteTherigyProtocol)]);

    return (
        <ActionCell>
            <ActionButtonLink onClick={copyHandler(protocol)}>Copy</ActionButtonLink>
            <ActionButtonLink onClick={toggleEnableHandler(protocol)}>
                {isEnabled ? 'Disable' : 'Enable'}
            </ActionButtonLink>
            {showDelete(createdBy) ? (
                <ActionButtonLink onClick={deleteHandler(protocol)}>Delete</ActionButtonLink>
            ) : null}
        </ActionCell>
    );
});

export default actionCellRenderer;
