import angular from 'angular';

import AddPatientController from './add-patient.controller';
import AddPatientConfig from './add-patient.config';
import ChangeBranchController from '../change-branch/change-branch.controller';
import AddPatientInsuranceController from '../add-patient-insurance/add-patient-insurance.controller';
import AddTherapyModalController from '../therapy/add-therapy-modal/add-therapy-modal.controller';

export default angular
    .module('components.addPatient', ['ngMessages', 'ui.router', 'ui.select'])
    .component('addPatient', {
        bindings: {
            advancedReferral: '<',
            disabledFields: '<',
            requiredFields: '<',
            enabled: '<',
            user: '<',
        },
        controller: AddPatientController,
        template: require('./add-patient.html'),
    })
    .component('patientAddInsurance', {
        bindings: {
            patientInsurance: '=',
            requiredFields: '<',
            disabledFields: '<',
        },
        controller: AddPatientInsuranceController,
        template: require('../add-patient-insurance/add-patient-insurance.html'),
    })
    .component('patientPrescription', {
        bindings: {
            patientPrescriptions: '=',
        },
        controller: AddTherapyModalController,
        template: require('../therapy/add-therapy-modal/add-therapy-modal.controller'),
    })
    .config(AddPatientConfig)
    .component('changeBranch', {
        bindings: {
            branches: '<',
            selection: '<',
            user: '<',
        },
        controller: ChangeBranchController,
        template: require('../change-branch/change-branch.html'),
    }).name;
