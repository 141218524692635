import __env from '../components/config/config';

import createAxios from 'App/utils/createAxios';

const qs = require('qs');

const endpoint = `${__env.slsEndpoint}:${__env.slsPort}${__env.slsStage ? `/${__env.slsStage}-cms` : ''}/${
    __env.slsVersion
}`;

const axios = createAxios({
    baseURL: endpoint,
});

class CMSService {
    static labelOrder = {
        drug: [
            'overview',
            'bbw',
            'rems',
            'indications',
            'recommendedDose',
            'doseModifications',
            'howSupplied',
            'storage',
            'administration',
            'labAndMonitoring',
            'interactions',
            'contraindications',
            'warningsAndPrecautions',
            'sideEffects',
            'miscCounsellingPoints',
        ],
        disease: [
            'overview',
            'complications',
            'prevention',
            'goals',
            'treatment',
            'management',
            'support',
            'references',
            'misc',
        ],
        teaching: ['generic', 'evaluation', 'strategy', 'references'],
    };

    /**
     * Associates a content group to a content resource
     *
     * @param {string} uuid - The content group uuid.
     * @param {string} resourceUuid - The resource uuiid.
     * @return {Object} Returns an object of content versions.
     */
    static createChildContentGroup = async (uuid, resourceUuid) => {
        const url = `/contentGroup/${uuid}`;
        const response = await axios.put(url, {
            resourceUuid,
        });
        return response;
    };

    /**
     * Deletes a content group
     *
     * @param {string} uuid - The content group uuid.
     * @return {Object} Returns an empty object.
     */
    static deleteContentGroup = async (uuid) => {
        const url = `/contentGroup/${uuid}`;

        const response = await axios.delete(url);
        return response;
    };

    /**
     * Gets a content group
     *
     * @param {string} uuid - The content group uuid.
     * @param {Object} params - The query params object.
     * @return {Object} Returns a content group.
     */
    static getContentGroup = async (uuid, params) => {
        const url = `/contentGroup/${uuid}`;
        const config = {
            params,
        };

        const response = await axios.get(url, config);
        return response;
    };

    /**
     * Gets content groups
     *
     * @param {string} type - The content group type.
     * @param {Object} params - The query params object.
     * @return {Object} Returns a map of content groups.
     */
    static getContentGroups = async (type, params = {}) => {
        const url = `/contentGroups/${type}`;
        const config = {
            params,
        };

        const response = await axios.get(url, config);
        return response;
    };

    static getContent = async (label, params) => {
        const url = `/content/${label}`;
        const config = {
            params: {
                labels: params.labels || [],
                versionDate: params.versionDate,
            },
            paramsSerializer(paramsToSerialize) {
                return qs.stringify(paramsToSerialize, { arrayFormat: 'repeat' });
            },
        };

        const response = await axios.get(url, config);
        return response;
    };

    /**
     * Publishes a content group
     *
     * @param {string} uuid - The content group uuid.
     * @return {Object} Returns the published content group.
     */
    static publishContentGroup = async (uuid) => {
        const url = `/contentGroup/${uuid}/publish`;

        const response = await axios.post(url, null);
        return response;
    };

    /**
     * Saves a content version
     *
     * @param {string} uuid - The content version uuid.
     * @param {Object} contentVersion - The content version object.
     * @return {Object} The updated content version object.
     */
    static saveContentVersion = async (uuid, contentVersion) => {
        const url = `/contentVersion/${uuid}`;
        const response = await axios.put(url, contentVersion);
        return response;
    };

    /**
     * Saves a content group teaching point
     *
     * @param {Object} params - The teaching point to add object.
     * @return {Object} The updated content group object.
     */
    static addTeachingPoint = (params) => {
        return axios.post('/contentGroup/teachingPoint', params);
    };
}

export default CMSService;
