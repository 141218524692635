import { useMutation } from 'react-query';
import { deleteReferralActivity } from 'App/services/RMSService';
import { toast } from 'react-toastify';
import { queryClient } from 'Lib/queryClient';
import convertKeysToCamelCase from 'App/utils/keyConversion/convertKeysToCamelCase';

const useDeleteReferralActivity = () => {
    return useMutation(
        ({ patientId, referralId, activityId }) => deleteReferralActivity(patientId, referralId, activityId),
        {
            onSuccess: async (data) => {
                toast.success('Activity has been deleted successfully.');
                const res = convertKeysToCamelCase(data);
                const activities = queryClient.getQueriesData(
                    'patient',
                    res.patientId,
                    'referral',
                    res.referralId,
                    'activity'
                );

                await queryClient.setQueryData(
                    ['patient', res.patientId, 'referral', res.referralId, 'activity'],
                    (activities || []).map((activity) => (activity.id === res.id ? res : activity))
                );
            },
        }
    );
};

export default useDeleteReferralActivity;
