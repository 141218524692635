import { getReferralCommunications } from 'App/services/RMSService';
import { useQuery } from 'react-query';

function useGetReferralCommunications(patientId, referralId) {
    return useQuery(
        ['patient', patientId, 'referrals', referralId, 'communication'],
        () => getReferralCommunications(patientId, referralId),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useGetReferralCommunications;
