// Factories
import DateService from './date.service';
import PDXFindPatientService from './pdx-find-patient.service';

// Services
import SessionStorageService from './session-storage.service';
import TokenService from './token.service';
import UserService from './user.service';
import UrlBuilderService from './url-builder.service';
import RmsService from './rms.service';
import WorkQueueService from './work-queue.service';
import ActivityStatusService from './activity-status.service';
import AmsService from './ams.service';
import CustomAssessmentService from './assessment-custom.service';
import AssessmentDiffService from './assessment-diff.service';
import AssessmentQuestionService from './assessment-question.service';
import AssessmentService from './assessment.service';
import AssignmentManagerService from './assignment-manager.service';
import CompanyService from './company.service';
import DaysOfWeekService from './days-of-week.service';
import DemographicService from './demographics.service';
import DiagnosisService from './diagnosis.service';
import DivisionService from './division.service';
import EngageService from './engage.service';
import ErrorStateService from './error-state.service';
import Icd9Service from './icd9.service';
import Icd10Service from './icd10.service';
import IdService from './id.service';
import InsightsService from './insights.service';
import LicenseService from './license.service';
import MedicationsService from './medication.service';
import ParamsService from './params.service';
import PatientMedicationRxNumberService from './patient-medication-rx-number.service';
import PatientOtherConditionService from './patient-other-condition.service';
import PatientOtherMedicationService from './patient-other-medication.service';
import PatientStatusHistoryService from './patient-status-history.service';
import PatientStatusService from './patient-status.service';
import PatientService from './patient.service';
import PesService from './pes.service';
import PhysicianService from './physician.service';
import PrescriberService from './prescriber.service';
import QueueManagerService from './queue-manager.service';
import ReportEngineService from './report-engine.service';
import ReportingService from './reporting.service';
import RxnconsoService from './rxnconso.service';
import SocketService from './socket.service';
import StateService from './state.service';
import TherapyService from './therapy.service';
import UnassignedMedicationService from './unassigned-medication.service';
import VideoService from './video.service';

/**
 *
 * @ngdoc module
 * @name services
 *
 * @description
 *
 * This is the services module. It includes all of our reusable services.
 *
 **/
export default angular
    .module('services', ['angular-cache', 'angular-jwt', 'ngCookies'])
    .service('activityStatusService', ActivityStatusService)
    .service('amsService', AmsService)
    .service('customAssessmentService', CustomAssessmentService)
    .service('assessmentDiffService', AssessmentDiffService)
    .service('assessmentQuestionService', AssessmentQuestionService)
    .service('assessmentService', AssessmentService)
    .service('assignmentManagerService', AssignmentManagerService)
    .service('companyService', CompanyService)
    .service('daysOfWeekService', DaysOfWeekService)
    .service('DemographicsService', DemographicService)
    .service('diagnosisService', DiagnosisService)
    .service('divisionService', DivisionService)
    .service('engageService', EngageService)
    .service('errorStateService', ErrorStateService)
    .service('icd9Service', Icd9Service)
    .service('icd10Service', Icd10Service)
    .service('idService', IdService)
    .service('insightsService', InsightsService)
    .service('licenseService', [LicenseService])
    .service('medicationsService', MedicationsService)
    .service('params', ParamsService)
    .service('patientService', PatientService)
    .service('patientMedicationRxNumberService', PatientMedicationRxNumberService)
    .service('patientOtherConditionService', PatientOtherConditionService)
    .service('patientOtherMedicationService', PatientOtherMedicationService)
    .service('patientStatusHistoryService', PatientStatusHistoryService)
    .service('patientStatusService', PatientStatusService)
    .service('pesService', PesService)
    .service('physicianService', PhysicianService)
    .service('prescriberService', PrescriberService)
    .service('queueManagerService', QueueManagerService)
    .service('reportEngineService', ReportEngineService)
    .service('reportingService', ReportingService)
    .service('rmsService', RmsService)
    .service('rxnconsoService', RxnconsoService)
    .service('sessionStorageService', SessionStorageService)
    .service('socketService', SocketService)
    .service('statesService', StateService)
    .service('therapyService', TherapyService)
    .service('tokenService', TokenService)
    .service('userService', UserService)
    .service('urlBuilderService', UrlBuilderService)
    .service('unassignedMedicationService', UnassignedMedicationService)
    .service('workQueueService', WorkQueueService)
    .factory('dateService', DateService)
    .factory('pdxFindPatientService', PDXFindPatientService)
    .service('videoService', VideoService).name;
