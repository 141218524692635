import React from 'react';
import * as R from 'ramda';
import { cellPropsType } from '../../workQueue.constant';

const LastUpdatedCell = ({ row, value }) => {
    const updatedName = R.path(['original', 'updatedName'], row);
    return (
        <span className="last-updated">
            {value}
            <br />
            <span>{updatedName}</span>
        </span>
    );
};

LastUpdatedCell.propTypes = cellPropsType;

export default LastUpdatedCell;
