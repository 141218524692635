import * as R from 'ramda';
import React, { useState } from 'react';
import { Formik } from 'formik';
import useFetchProtocolTemplateQuery from 'App/hooks/useFetchProtocolTemplateQuery';
import { useHistory, useParams } from 'react-router-dom';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import useUpdateProtocolTemplateMutation from 'App/hooks/useUpdateProtocolTemplateMutation';
import NotFound from 'App/components/errors/NotFound';
import { TextField } from 'Lib/form/TextField';
import { Button, Col, Row } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import RulesTable from './RulesTable';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';
import * as Yup from 'yup';
import AddProtocolModal from './AddProtocolModal';

const propTypes = {};

const defaultProps = {};

const validationSchema = Yup.object({
    name: Yup.string().min(1).required().label('Template Name'),
});

function EditTemplateForm() {
    const [showAddRule, setShowAddRule] = useState();
    const { templateId } = useParams();

    const { isLoading, isError, data } = useFetchProtocolTemplateQuery(templateId);

    const updateTemplateMutation = useUpdateProtocolTemplateMutation();

    const history = useHistory();

    if (isLoading) {
        return <TherigyLoadingSpinner />;
    }

    if (isError) {
        return <NotFound />;
    }

    return (
        <div>
            <Formik
                validationSchema={validationSchema}
                initialValues={data}
                onSubmit={(values, { resetForm }) => {
                    const next = R.applySpec({
                        uuid: R.prop('uuid'),
                        payload: R.compose(
                            R.over(R.lensProp('rules'), R.map(R.pick(['enable', 'therapyPolicyId']))),
                            R.pick(['name', 'rules'])
                        ),
                    })(values);

                    return updateTemplateMutation.mutateAsync(next).then((template) => resetForm({ values: template }));
                }}
            >
                {({ handleSubmit, values, handleReset, setFieldValue, isSubmitting }) => (
                    <>
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <TextField
                                        fieldName="name"
                                        label="Template Name"
                                        required
                                        disabled={isSubmitting}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Button
                                            type="button"
                                            bsStyle="link"
                                            onClick={() => setShowAddRule(R.T)}
                                            disabled={isSubmitting}
                                        >
                                            <FaPlus /> Add a protocol
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <RulesTable data={values.rules} />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <FormButtonGroup>
                                        <Button
                                            type="button"
                                            onClick={() => history.push('/protocol-template-builder')}
                                            disabled={isSubmitting}
                                        >
                                            Go Back
                                        </Button>
                                        <Button type="button" onClick={handleReset} disabled={isSubmitting}>
                                            Reset
                                        </Button>
                                        <SubmitButton>Save</SubmitButton>
                                    </FormButtonGroup>
                                </Col>
                            </Row>
                        </form>
                        {showAddRule && (
                            <AddProtocolModal
                                currentRules={values.rules}
                                onHide={() => setShowAddRule(R.F)}
                                onConfirm={(rules) => {
                                    setFieldValue('rules', rules);
                                    setShowAddRule(R.F);
                                }}
                            />
                        )}
                    </>
                )}
            </Formik>
        </div>
    );
}

EditTemplateForm.propTypes = propTypes;
EditTemplateForm.defaultProps = defaultProps;

export default EditTemplateForm;
