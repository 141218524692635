import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';

import { contentMonographRoute } from 'App/common/routeLookup';
import { patientPropType } from 'Lib/types';
import useFetchMedications from '../hooks/useFetchMedications';
import useFetchPatientTherapies from 'App/hooks/useFetchPatientTherapies';

const AssessmentContentMonographLink = ({ templateMatch, patient }) => {
    const { data: patientMedications } = useFetchMedications(patient.id);
    const { data: patientTherapies } = useFetchPatientTherapies(patient.id);

    const templateParts = templateMatch.split('__');
    const labelType = templateParts[0];
    const linkText = templateParts[1];

    const paramsObj = {};
    if (patientTherapies && labelType === 'disease') {
        paramsObj.labels = Object.values(patientTherapies).map((pt) => camelCase(pt.name));
    }
    if (patientMedications && (labelType === 'drug' || labelType.match(/^drug_/))) {
        paramsObj.labels = Object.values(patientMedications).map((pm) => camelCase(pm.medication.name));
    }
    const paramsString = qs.stringify(paramsObj, { arrayFormat: 'repeat' });

    return (
        <Link
            className={`show-content-${labelType}`}
            to={`${generatePath(contentMonographRoute, { label: labelType })}${paramsString && `?${paramsString}`}`}
            target="_blank"
        >
            {linkText}
        </Link>
    );
};

AssessmentContentMonographLink.propTypes = {
    templateMatch: PropTypes.string.isRequired,
    patient: patientPropType.isRequired,
};

export default AssessmentContentMonographLink;
