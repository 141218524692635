import React from 'react';
import PropTypes from 'prop-types';
import './patientPrescription.scss';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import dateOrDash from 'App/utils/dateOrDash';
import pathOrDash from 'App/utils/pathOrDash';
import { Button } from 'react-bootstrap';
import { rxPropType } from 'Lib/types';

const propTypes = {
    prescription: rxPropType.isRequired,
    viewProfile: PropTypes.bool,
    handleViewRxNotes: PropTypes.func,
    handleAddRxNotes: PropTypes.func,
};

const defaultProps = {
    viewProfile: false,
    handleViewRxNotes: () => {},
    handleAddRxNotes: () => {},
};

function RxFillNumberView({ prescription, viewProfile, handleViewRxNotes, handleAddRxNotes }) {
    const { physician, rxFill } = prescription;

    return (
        <div className="view-rx-fill-container">
            <Col>
                <Col className="block-section">
                    <Row>
                        <Col md={2}>Rx Number</Col>
                        <Col md={2}>Rx Strength</Col>
                        <Col md={2}>Refills Allowed</Col>
                        <Col md={2}>Prescriber</Col>

                        {viewProfile ? (
                            <Col>
                                <Col md={2}>Rx Status</Col>
                                <Col md={2}>Notes</Col>
                            </Col>
                        ) : null}
                    </Row>
                    <Row>
                        <Col md={2}>
                            <strong>{pathOrDash(['rxNumber'], prescription)}</strong>
                        </Col>
                        <Col md={2}>
                            <strong>{pathOrDash(['strength'], prescription)}</strong>
                        </Col>
                        <Col md={2}>
                            <strong>{pathOrDash(['refillsAuthorized'], prescription)}</strong>
                        </Col>
                        <Col md={2}>
                            <strong>
                                {physician?.firstName ? `${physician?.firstName} ${physician?.lastName}` : '--'}
                            </strong>
                        </Col>
                        {viewProfile ? (
                            <>
                                <Col md={2}>
                                    {prescription?.prescriptionData?.data?.RxStatus ? (
                                        <strong>
                                            {pathOrDash(['RxStatus'], prescription?.prescriptionData?.data)}
                                        </strong>
                                    ) : (
                                        <strong>
                                            {pathOrDash(
                                                ['RxStatus'],
                                                prescription?.prescriptionData?.data?.Prescription
                                            )}
                                        </strong>
                                    )}
                                </Col>
                                <Col md={2}>
                                    <div className="notes-button">
                                        <Button
                                            className="btn btn-link btn-default custom-button-left"
                                            onClick={handleViewRxNotes}
                                        >
                                            View{' '}
                                        </Button>{' '}
                                        |
                                        <Button
                                            className="btn btn-link btn-default custom-button-left"
                                            onClick={handleAddRxNotes}
                                        >
                                            {' '}
                                            Add
                                        </Button>
                                    </div>
                                </Col>
                            </>
                        ) : null}
                    </Row>
                </Col>

                <Col className="block-section">
                    <Row className="seven-cols">
                        <Col md={2}>Rx Written Date</Col>
                        <Col md={2}>Rx Exp. Date</Col>
                        <Col md={2}>Rx End Date</Col>
                        {viewProfile ? <Col md={2}>End Reason</Col> : null}
                    </Row>
                    <Row>
                        <Col md={2}>
                            <strong>{dateOrDash(['start'], prescription)}</strong>
                        </Col>
                        <Col md={2}>
                            <strong>{dateOrDash(['fillExpiration'], prescription)}</strong>
                        </Col>
                        <Col md={2}>
                            <strong>{dateOrDash(['end'], prescription)}</strong>
                        </Col>
                        {viewProfile ? (
                            <Col md={2}>
                                <strong>{pathOrDash(['endReason'], prescription)}</strong>
                            </Col>
                        ) : null}
                    </Row>
                </Col>
            </Col>
            <div>
                {rxFill?.length > 0 ? (
                    <div className="view-rx-fill-container">
                        <Col className="block-section">
                            <Row>
                                <Col md={3}>Fill Number</Col>
                                <Col md={3}>Fill Date</Col>
                                <Col md={3}>Dispensed Qty</Col>
                                <Col md={3}>Days Supply</Col>

                                {rxFill.map((fill, index) => (
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div key={index}>
                                        <Col md={3}>
                                            {fill?.fillNumber === 0 ? (
                                                <strong>{fill.fillNumber}</strong>
                                            ) : (
                                                <strong>{pathOrDash(['fillNumber'], fill)}</strong>
                                            )}
                                        </Col>
                                        <Col md={3}>
                                            <strong>{dateOrDash(['fillDate'], fill)}</strong>
                                        </Col>
                                        <Col md={3}>
                                            <strong>{pathOrDash(['dispensedQuantity'], fill)}</strong>
                                        </Col>
                                        <Col md={3}>
                                            <strong>{pathOrDash(['daysSupply'], fill)}</strong>
                                        </Col>
                                    </div>
                                ))}
                            </Row>
                        </Col>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

RxFillNumberView.propTypes = propTypes;
RxFillNumberView.defaultProps = defaultProps;

export default RxFillNumberView;
