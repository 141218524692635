import React from 'react';
import PropTypes from 'prop-types';
import PatientHighRiskIndicator from 'App/components/PatientHighRiskIndicator';
import PatientDisplayId from 'App/components/PatientDisplayId/PatientDisplayId';

const propTypes = {
    patient: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        humanId: PropTypes.number,
        highRisk: PropTypes.bool,
    }).isRequired,
};

const defaultProps = {};

function PatientReferralHeader({ patient }) {
    const { firstName = '', lastName = '' } = patient;

    return (
        <h2>
            {lastName}, {firstName}
            &nbsp;
            {patient.id && <PatientDisplayId patientId={patient.id} />}
            {patient.highRisk && <PatientHighRiskIndicator patientId={patient.id} />}
        </h2>
    );
}

PatientReferralHeader.propTypes = propTypes;
PatientReferralHeader.defaultProps = defaultProps;

export default PatientReferralHeader;
