import { getSlsBasePath } from '../utils';
import config from '../components/config/config';
import createAxios from 'App/utils/createAxios';

const endpoint = getSlsBasePath(config, 'ams');

const ams = createAxios({
    baseURL: `${endpoint}/1.0.0/patient`,
});
class ActivityMedicationService {
    /**
     * Creates a medication association
     * @param {string} patientId - Patient id to create the medication association for
     * @param {string} activityId - Activity id to create the medication association for
     * @param {Object} medicationAssociation - MedicationAssociation object to create
     * @returns {<ResponseData>}
     */
    static createMedicationAssociation = async (patientId, activityId, medicationAssociation) => {
        const url = `/${patientId}/activity/${activityId}/medicationAssociation`;
        const response = await ams.post(url, medicationAssociation);
        return response;
    };

    /**
     * Deletes the medication association
     * @param {string} patientId - Patient id to delete the medication association for
     * @param {string} activityId - Activity id to delete the medication association for
     * @param {string} medicationAssociationId - MedicationAssociation id to delete
     * @returns {<ResponseData>}
     */
    static deleteMedicationAssociation = async (patientId, activityId, medicationAssociationId) => {
        // eslint-disable-next-line max-len
        const url = `/${patientId}/activity/${activityId}/medicationAssociation/${medicationAssociationId}`;

        const response = await ams.delete(url);
        return response;
    };

    /**
     * Fetches the medication association for the patient and activity
     * @param {string} patientId - Patient id to fetch medication associations for
     * @param {string} activityId - Activity id to fetch medication associations for
     * @returns {<ResponseData>}
     */
    static getMedicationAssociations = async (patientId, activityId) => {
        const url = `/${patientId}/activity/${activityId}/medicationAssociation`;

        const response = await ams.get(url);
        return response;
    };
}

export default ActivityMedicationService;
