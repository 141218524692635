import jwtDecode from 'jwt-decode';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import AuthService from '../../../services/AuthService';
import SessionStorageService from '../../../services/SessionStorageService';

const SessionTimer = () => {
    const history = useHistory();
    const [timeoutLength, setTimeoutLength] = useState(120);
    const [minutes, setMinutes] = useState(120);
    const [seconds, setSeconds] = useState('00');
    const [showNotice, setShowNotice] = useState(false);
    const [showSessionExpirationNotice, setShowSessionExpirationNotice] = useState(false);

    const { path } = useRouteMatch();

    // Store the interval instance
    let interval = null;

    const startTimer = () => {
        if (interval) {
            clearInterval(interval);
        }
        interval = setInterval(() => {
            let token = SessionStorageService.getJwt();
            if (!token) {
                return;
            }
            token = jwtDecode(token);

            setTimeoutLength(token.data.TimeoutLength);

            SessionStorageService.set('timeout', {
                secs: token.data.TimeoutLength * 60,
                lastUpdated: new Date(),
            });
            const secondsUntilSessionTimesOut = moment.utc(token.data.Expires).unix() - moment.utc().unix();
            // token.exp is in seconds
            const secondsUntilSessionExpires = token.exp - moment.utc().unix();
            setMinutes(Math.floor(secondsUntilSessionTimesOut / 60));
            const currentSeconds = Math.ceil(secondsUntilSessionTimesOut % 60);
            setSeconds(currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds);

            if (secondsUntilSessionTimesOut <= 0 || secondsUntilSessionExpires <= 0) {
                clearInterval(interval);
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                return AuthService.logout().then(() => history.push('/'));
            }

            // Show the notice after 4 minutes
            if (secondsUntilSessionTimesOut < 240) {
                setShowNotice(true);
            }
            if (secondsUntilSessionExpires <= 60 * 5) {
                const sessionExpirationShown = !!window.localStorage.getItem('sessionExpirationShown');
                if (sessionExpirationShown !== true) {
                    setShowSessionExpirationNotice(true);
                }
            }
        }, 330);
    };

    const resetTimer = () => {
        setShowNotice(false);
        AuthService.updateJwt()
            .then()
            .catch(() => {
                // do nothing
            });
        startTimer();
    };

    const hideNotification = () => {
        setShowSessionExpirationNotice(false);
        window.localStorage.setItem('sessionExpirationShown', true);

        AuthService.updateJwt();
        startTimer();
    };

    useEffect(() => {
        resetTimer();
        return () => clearInterval(interval);
        /* eslint-disable-next-line  */
    }, [path]);

    return (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div style={{ cursor: 'pointer' }} onClick={resetTimer}>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="icon">
                <i className="fas fa-fw fa-clock" />
                <span>{`${minutes}:${seconds}`}</span>
            </a>
            {showNotice && (
                <div id="notice_div">
                    {/* TODO: Fix this the next time the file is edited. */}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <a className="bg-warning" id="resetTimeLink" onClick={resetTimer}>
                        {`Your session is about to expire, please click here to reset session 
                        duration back to ${timeoutLength} minutes.`}
                    </a>
                </div>
            )}
            {showSessionExpirationNotice && (
                <div id="notice_div">
                    {/* TODO: Fix this the next time the file is edited. */}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <a className="bg-warning" id="hideNotificationLink" onClick={hideNotification}>
                        {`For your security, TherigySTM will automatically log you off from your current session. 
                        Please save your work and re-authenticate in with your user account.`}
                    </a>
                </div>
            )}
        </div>
    );
};

export default SessionTimer;
