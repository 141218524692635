function TherigyTrackedTableCell() {
    return {
        restrict: 'A',
        priority: -1,
        scope: true,
        require: ['^therigyTrackedTableRow', 'ngForm'],
        controller: TherigyTrackedTableCellController,
    };
}

function TherigyTrackedTableCellController($attrs, $element, $scope) {
    const cellFormCtrl = $element.controller('form');
    const cellName = cellFormCtrl.$name;
    const trackedTableRowCtrl = $element.controller('therigyTrackedTableRow');

    if (trackedTableRowCtrl.isCellDirty(cellName)) {
        cellFormCtrl.$setDirty();
    } else {
        cellFormCtrl.$setPristine();
    }

    $scope.$watch(
        () => {
            return cellFormCtrl.$dirty;
        },
        (newValue, oldValue) => {
            if (newValue === oldValue) return;

            trackedTableRowCtrl.setCellDirty(cellName, newValue);
        }
    );

    $scope.$watch(
        () => {
            return cellFormCtrl.$invalid;
        },
        (newValue, oldValue) => {
            if (newValue === oldValue) return;

            trackedTableRowCtrl.setCellInvalid(cellName, newValue);
        }
    );
}

export default TherigyTrackedTableCell;
