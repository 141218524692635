import React from 'react';
import './patientInsurance.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import PatientInsuranceProfile from './PatientInsuranceProfile';
import PatientInsuranceMain from './PatientInsuranceMain';

dayjs.extend(utc);

const propTypes = {
    item: PropTypes.number,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    patientInsurance: PropTypes.object,
    onChangeText: PropTypes.func,
    isReadOnly: PropTypes.bool,
    editPatientInsurance: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    validationInsuranceErrors: PropTypes.object,
    validateInsurancePlan: PropTypes.func,
    disablePlan: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    requiredFields: PropTypes.array,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    disabledFields: PropTypes.array,
};

const defaultProps = {
    item: 0,
    patientInsurance: {},
    onChangeText: () => {},
    isReadOnly: false,
    editPatientInsurance: false,
    validationInsuranceErrors: {},
    validateInsurancePlan: () => {},
    disablePlan: () => {},
    requiredFields: [],
    disabledFields: [],
};

function PatientInsurance(props) {
    const {
        item,
        patientInsurance,
        onChangeText,
        isReadOnly,
        editPatientInsurance,
        validationInsuranceErrors,
        validateInsurancePlan,
        disablePlan,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        isBiDisabled,
        requiredFields,
        disabledFields,
    } = props;

    if (isReadOnly) {
        return <PatientInsuranceProfile item={item} patientInsurance={patientInsurance} />;
    }
    return (
        <PatientInsuranceMain
            item={item}
            patientInsurance={patientInsurance}
            onChangeText={onChangeText}
            editPatientInsurance={editPatientInsurance}
            validationInsuranceErrors={validationInsuranceErrors}
            validateInsurancePlan={validateInsurancePlan}
            disablePlan={disablePlan}
            isBiDisabled={isBiDisabled}
            requiredFields={requiredFields}
            disabledFields={disabledFields}
        />
    );
}

PatientInsurance.propTypes = propTypes;
PatientInsurance.defaultProps = defaultProps;

export default PatientInsurance;
