/** @ngInject */
function VideoModalController($sce) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;

    function $onInit() {
        // eslint-disable-next-line no-multi-assign
        ctrl.video = ctrl.videoLink = {};
    }

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve) {
            ctrl.video = angular.copy(ctrl.resolve.modalData.video);
            ctrl.videoLink = $sce.trustAsResourceUrl(ctrl.video.link);
        }
    }
}

export default VideoModalController;
