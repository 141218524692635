/** @ngInject */
function UnassignedMedicationActivityController(
    _,
    $q,
    therapyService,
    unassignedMedicationService,
    NgTableParams,
    sessionStorageService
) {
    const ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.updateDisplay = updateDisplay;
    ctrl.showMedTable = sessionStorageService.getOnUser(`unassignedMedicationTable-toggle`, true);
    ctrl.toggleMedicationTable = toggleMedicationTable;
    ctrl.cols = [
        {
            title: 'Patient ID',
            field: 'ExternalId',
            sortable: 'ExternalId',
            show: true,
            filter: {
                ExternalId: '/text-filter.html',
            },
        },
        {
            title: 'Last Name',
            field: 'LastName',
            sortable: 'LastName',
            show: true,
            filter: {
                LastName: '/text-filter.html',
            },
        },
        {
            title: 'First Name',
            field: 'FirstName',
            sortable: 'FirstName',
            show: true,
            filter: {
                FirstName: '/text-filter.html',
            },
        },
        {
            title: 'Medication',
            field: 'medicationList',
            sortable: 'medicationList',
            filter: { medicationList: '/text-filter.html' },
            show: true,
        },
        {
            title: 'Activity Name',
            field: 'activityName',
            show: true,
        },
        {
            title: 'Patient DOB',
            field: 'BirthDate',
            sortable: 'BirthDate',
            filter: { BirthDate: '/text-filter.html' },
            show: true,
        },
        {
            title: 'Created Date',
            field: 'createdDate',
            sortable: 'createdDate',
            filter: { createdDate: '/text-filter.html' },
            show: true,
        },
        {
            title: 'Profile Updated',
            field: 'updatedDate',
            sortable: 'updatedDate',
            filter: { updatedDate: '/text-filter.html' },
            show: true,
        },
    ];

    function toggleMedicationTable() {
        ctrl.showMedTable = !ctrl.showMedTable;
        sessionStorageService.setOnUser(`unassignedMedicationTable-toggle`, ctrl.showMedTable);
    }

    /**
     * Gets all patients that have unassigned medications.
     */
    function $onInit() {
        ctrl.patients = [];
        unassignedMedicationService.getPatients(ctrl.user.active_company.ID).then((res) => {
            ctrl.patients = res.patients;
            // eslint-disable-next no-param-reassign
            const dataset = res.patients.map((patient) => {
                const cleanedPatient = patient;
                cleanedPatient.createdDate = patient.Unassignedmedications[0].CreatedOn.toString().substring(0, 10);
                cleanedPatient.updatedDate = patient.UpdatedOn
                    ? patient.UpdatedOn.toString().substring(0, 10)
                    : patient.CreatedOn.toString().substring(0, 10);
                cleanedPatient.medicationList = patient.Unassignedmedications.map((medication) => {
                    return medication.DisplayName || medication.MedicationName;
                }).join(', ');
                return cleanedPatient;
            });
            ctrl.tableParams = new NgTableParams(
                {
                    count: 10,
                    sorting: { updated_on: 'desc' },
                },
                {
                    counts: [],
                    dataset,
                }
            );
        });
    }

    function updateDisplay(selection, patientIndex) {
        // select what property has selection done for and remove it

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax
        for (const medicationName in selection) {
            if (Object.prototype.hasOwnProperty.call(selection, medicationName)) {
                const patients = ctrl.patients[patientIndex];
                const unassignedMedicationIndex = _.findIndex(patients.Unassignedmedications, [
                    'MedicationName',
                    medicationName,
                ]);

                patients.Unassignedmedications.splice(unassignedMedicationIndex, 1);
                if (patients.Unassignedmedications.length === 0) {
                    ctrl.patients.splice(patientIndex, 1);
                }
            }
        }

        doChangeDisplay();
    }

    function doChangeDisplay() {
        ctrl.onChangeDisplay();
    }
}

export default UnassignedMedicationActivityController;
