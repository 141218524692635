/** @ngInject */
function TherigyNotFoundController($timeout) {
    const ctrl = this;
    ctrl.show = false;
    ctrl.$onInit = $onInit;
    ctrl.$onDestroy = $onDestroy;

    let timeoutId;

    function $onInit() {
        /**
         * When transitioning from an Angular page to a React page, the Angular router will fall back to it's
         * 404 for a brief moment before React is able to get the page rendered. This just gives it some time
         * to prevent us from seeing a red flash of text.
         * @type {number}
         */
        timeoutId = $timeout(() => {
            ctrl.show = true;
        }, 200);
    }

    function $onDestroy() {
        // Be sure to clean up, just in case...
        if (timeoutId && !ctrl.show) {
            $timeout.cancel(timeoutId);
        }
    }
}

export default TherigyNotFoundController;
