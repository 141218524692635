import styled from '@emotion/styled';
import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import identity from 'ramda/src/identity';
import React, { useMemo } from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import hasLength from '../../utils/hasLength';
import TherigyLoadingSpinner from '../TherigyLoadingSpinner/TherigyLoadingSpinner';

import SearchColumnFilter from './SearchColumnFilter';
import TableMessage from './TableMessage';
import TablePagination from './TablePagination';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    columns: PropTypes.array.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.array,
    stateReducer: PropTypes.func,
    isLoading: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    placeholderText: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    initialState: PropTypes.object,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    enablePagination: PropTypes.bool,
};

const defaultProps = {
    data: [],
    stateReducer: identity,
    isLoading: false,
    initialState: {},
    pageSizeOptions: [10, 20, 50],
    enablePagination: true,
};

const FilterRowStyle = styled('tr')`
    background-color: #fff;
`;

const FlexHeaderContents = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .header-text {
        margin-right: 0.5rem;
    }

    .sort-indicator-container {
        display: flex;
        align-items: center;
    }

    .sort-indicator {
        opacity: 0.4;

        &:hover {
            opacity: 1;
        }
    }
`;

const Table = ({
    columns,
    data,
    stateReducer,
    isLoading,
    placeholderText,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    getKey,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    filterTypes,
    initialState,
    pageSizeOptions,
    enablePagination,
}) => {
    const defaultColumn = useMemo(
        () => ({
            // Set up our default Filter UI
            Filter: SearchColumnFilter,
        }),
        []
    );
    const plugins = enablePagination ? [useFilters, useSortBy, usePagination] : [useFilters, useSortBy];
    const hasFilter = useMemo(() => !columns.every((column) => column.disableFilters), [columns]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page when pagination enabled
        rows,

        // The rest of these things are super handy, too.
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            stateReducer,
            filterTypes,
            initialState,
        },
        ...plugins
    );

    const records = useMemo(() => {
        return enablePagination ? page : rows;
    }, [enablePagination, page, rows]);

    return (
        <div>
            <table {...getTableProps()} className="therigy-table">
                <thead>
                    {headerGroups.map((headerGroup) => {
                        return (
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line react/jsx-key
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    return (
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line react/jsx-key
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            style={column.style}
                                            aria-label={column.canSort ? `Sort by ${column.Header}` : column.Header}
                                            className={cx({
                                                sortable: column.canSort,
                                                'sort-asc': column.isSorted && !column.isSortedDesc,
                                                'sort-desc': column.isSorted && column.isSortedDesc,
                                            })}
                                        >
                                            <FlexHeaderContents>
                                                <span className="header-text">{column.render('Header')}</span>
                                                {column.canSort && (
                                                    <span className="sort-indicator-container">
                                                        {
                                                            // TODO: Fix this the next time the file is edited.
                                                            // eslint-disable-next-line no-nested-ternary
                                                            column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <FaSortDown className="sort-indicator" />
                                                                ) : (
                                                                    <FaSortUp className="sort-indicator" />
                                                                )
                                                            ) : (
                                                                <FaSort className="sort-indicator" />
                                                            )
                                                        }
                                                    </span>
                                                )}
                                            </FlexHeaderContents>
                                            {column.SubHeader && (
                                                <small className="text-muted">{column.render('SubHeader')}</small>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}

                    {hasFilter &&
                        headerGroups.map((headerGroup) => (
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line react/jsx-key
                            <FilterRowStyle {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line react/jsx-key
                                    <th {...column.getHeaderProps()}>
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </FilterRowStyle>
                        ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-nested-ternary
                        isLoading ? (
                            <TableMessage>
                                <TherigyLoadingSpinner />
                            </TableMessage>
                        ) : hasLength(records) ? (
                            records.map((row) => {
                                prepareRow(row);
                                const props = row.getRowProps();
                                if (getKey && getKey(row.original)) {
                                    props.key = getKey(row.original);
                                }
                                return (
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line react/jsx-key
                                    <tr {...props}>
                                        {row.cells.map((cell) => {
                                            return (
                                                // TODO: Fix this the next time the file is edited.
                                                // eslint-disable-next-line react/jsx-key
                                                <td
                                                    {...cell.getCellProps()}
                                                    data-testid={
                                                        typeof cell.column.Header !== 'string'
                                                            ? `cell-${cell.column.id}`
                                                            : `cell-${cell.column.Header}`
                                                    }
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        ) : (
                            <TableMessage message={placeholderText} />
                        )
                    }
                </tbody>
            </table>
            {enablePagination && (
                <TablePagination
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    gotoPage={gotoPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSizeOptions={pageSizeOptions}
                />
            )}
        </div>
    );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default Table;
