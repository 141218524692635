import React from 'react';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import NotFound from 'App/components/errors/NotFound';
import { HashRouter, Route, Switch } from 'react-router-dom';
import RouteWithHeader from 'App/components/RouteWithHeader';
import { Helmet } from 'react-helmet';
import { catchPhrase } from 'App/components/config/config';
import AngularRootReactComponent from '@/angular-root';
import ReferralManagerHome from '../ReferralManagerHome';
import Page from 'App/components/Page';
import WithHeader from 'App/components/WithHeader';
import { referralDetailRoute, referralManagerRoute, referralPriorAuthorization } from 'App/common/routeLookup';
import ReferralDetail from 'App/features/ReferralManager/Referral/ReferralDetail/ReferralDetail';
import PriorAuthorization from 'App/features/ReferralManager/Referral/PriorAuthorization/PriorAuthorization';

const propTypes = {};

const defaultProps = {};

function Router() {
    const hasReferralMgr = useIsPermitted(PERMISSION_TYPES.REFERRAL_MANAGER);
    const hasReactReferralDetailPage = useIsPermitted(PERMISSION_TYPES.ENABLE_REACT_REFERRAL_DETAIL);
    const hasReactPriorAuthorizationPage = useIsPermitted(PERMISSION_TYPES.ENABLE_REACT_REFERRAL_PRIOR_AUTHORIZATION);

    if (hasReferralMgr) {
        return (
            <HashRouter hashType="hashbang">
                <Switch>
                    <RouteWithHeader path={`${referralManagerRoute}`} component={ReferralManagerHome} exact />
                    {hasReactReferralDetailPage && (
                        <RouteWithHeader path={`${referralDetailRoute}`} component={ReferralDetail} exact />
                    )}
                    {hasReactPriorAuthorizationPage && (
                        <RouteWithHeader path={`${referralPriorAuthorization}`} component={PriorAuthorization} exact />
                    )}
                    <Route>
                        <Helmet>
                            <title>{catchPhrase}</title>
                        </Helmet>

                        <AngularRootReactComponent />
                    </Route>
                </Switch>
            </HashRouter>
        );
    }

    return (
        <WithHeader>
            <Page title="Not Found" permissionType={PERMISSION_TYPES.WORK_QUEUE} showPageTitle={false}>
                <NotFound />
            </Page>
        </WithHeader>
    );
}

Router.propTypes = propTypes;
Router.defaultProps = defaultProps;

export default Router;
