import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FaInfoCircle, FaPencilAlt, FaPlus, FaTimes } from 'react-icons/fa';

import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils.js';
import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';
import { DatePickerField } from 'Lib/form/DatePickerField';
import SelectField from 'Lib/form/SelectField';

import useFetchTherapyMedications from '../../hooks/useFetchTherapyMedications';
import { patientMedicationRxPropType } from '../../types';
import MedicationEndModal from './MedicationEndModal';
import RxNumberForm from './RxNumberForm';

const MedicationSectionStyle = styled(Modal.Body)`
    background-color: var(--snow);
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
`;

const EditMedicationModalSection = ({ existingMedication, allMedications }) => {
    const { values, initialValues, setFieldValue } = useFormikContext();
    const { data: medications = {}, isLoading: isMedicationsLoading } = useFetchTherapyMedications(
        values.therapy.category.id
    );
    const [endModalOpen, setEndModalOpen] = useState(false);

    return (
        <MedicationSectionStyle>
            <h5>{values.therapy.category.name} Medications</h5>

            <Row>
                <Col md={6}>
                    <SelectField
                        fieldName="patientMedication.medication"
                        label="Medication"
                        options={Object.values(medications).sort((a, b) => a.Name.localeCompare(b.Name))}
                        isLoading={isMedicationsLoading}
                        getOptionLabel={prop('Name')}
                        getOptionValue={prop('ID')}
                        isOptionDisabled={(option) =>
                            allMedications.find((patientMed) => patientMed.medication.id === option.ID)
                        }
                        required
                        isDisabled={!!existingMedication}
                        isClearable
                        placeholder="Type or Select Medication"
                    />
                </Col>

                {!values.patientMedication?.medication ? (
                    <Col md={12}>
                        <TherigyInfoMessage message="Select a Medication to continue." />
                    </Col>
                ) : (
                    <>
                        <Col md={3}>
                            <DatePickerField
                                fieldName="patientMedication.status.start"
                                label="Medication Start Date"
                                placeholder="YYYY/MM/DD"
                                disabled={existingMedication && !!initialValues.patientMedication.status.start}
                            />
                        </Col>
                        {!values.patientMedication.status.end && values.patientMedication.id && (
                            <Col md={3} style={{ marginTop: 25 }}>
                                <Button block onClick={() => setEndModalOpen(true)}>
                                    End Medication
                                </Button>
                            </Col>
                        )}
                        {values.patientMedication.status.end && !initialValues.patientMedication.status.end && (
                            <Col md={12} className="global-info-message">
                                <FaInfoCircle className="fa-fw" />
                                <span>
                                    Ending {values.patientMedication.medication.Name} because of{' '}
                                    {values.patientMedication.status.endReason}, on{' '}
                                    {formatDate(values.patientMedication.status.end)}
                                </span>
                                <Button bsStyle="link" onClick={() => setEndModalOpen(true)}>
                                    <FaPencilAlt /> Edit
                                </Button>
                                <Button
                                    bsStyle="link"
                                    onClick={() =>
                                        setFieldValue(
                                            'patientMedication.status',
                                            initialValues.patientMedication.status
                                        )
                                    }
                                >
                                    <FaTimes /> Cancel Medication End
                                </Button>
                            </Col>
                        )}

                        <Col md={12}>
                            {values.rxs.map((rx, index) => (
                                <RxNumberForm
                                    key={rx.id || `index-${index}`}
                                    fieldPrefix={`rxs.[${index}]`}
                                    onClickRemove={() => {
                                        values.rxs.splice(index, 1);
                                        setFieldValue('rxs', values.rxs);
                                    }}
                                />
                            ))}

                            <Button
                                bsStyle="link"
                                onClick={() => {
                                    const newIndex = values.rxs.length;
                                    setFieldValue(`rxs.[${newIndex}]`, {});
                                }}
                            >
                                <FaPlus /> Add Rx Number
                            </Button>
                        </Col>
                    </>
                )}
            </Row>
            {endModalOpen && (
                <MedicationEndModal
                    existingMedication={values.patientMedication}
                    onClose={() => setEndModalOpen(false)}
                    onSubmit={(modalValues) => {
                        setFieldValue('patientMedication.status', modalValues.patientMedication.status);
                    }}
                />
            )}
        </MedicationSectionStyle>
    );
};

EditMedicationModalSection.propTypes = {
    existingMedication: patientMedicationRxPropType,
    allMedications: PropTypes.arrayOf(patientMedicationRxPropType).isRequired,
};

EditMedicationModalSection.defaultProps = {
    existingMedication: null,
};

export default EditMedicationModalSection;
