import * as R from 'ramda';

/**
 * Remove curly tags as seen in medication and TC links and the content library.
 * @example
 * Contains a display value:
 * stripCurlyTags(Perform {{phq2GeneralCounseling__PHQ-2 Depression screening}})
 * // => Perform PHQ-2 Depression screening
 *
 * // No display value
 * stripCurlyTags({{phq2GeneralCounseling}})
 * // => ''
 * @param {string} text;
 */
function stripCurlyTags(text) {
    if (R.is(String, text)) {
        return R.replace(/{{.*?(?:__(.[^}]*))?}}/g, '$1', text);
    }
    return '';
}

export default stripCurlyTags;
