import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaCheck, FaPencilAlt, FaPlus, FaTimes } from 'react-icons/fa';

import RxFillNumberView from 'App/components/PatientPrescription/RxFillNumberView';
import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils.js';
import LimitLengthContentPopover from 'App/components/Popovers/LimitLengthContentPopover';
import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';
import dateOrDash from 'App/utils/dateOrDash';
import pathOrDash from 'App/utils/pathOrDash';
import { CheckboxField } from 'Lib/form/CheckboxField';

import SectionTitleStyle from '../SectionTitleStyle';
import EditTherapeuticCategoryMedicationModal from './EditTherapeuticCategoryMedicationModal';

const TherapyContainerStyle = styled.div`
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 2rem;

    & .btn-link {
        font-size: 12px;
    }
`;

const TherapyHeaderStyle = styled.div`
    background-color: var(--snow);
    padding: 5px 10px;
`;

const MedicationContainerStyle = styled.div`
    padding: 5px 10px;
`;

const MedicationContainerTitle = styled.div`
    font-size: 12px;
`;

const TherapeuticCategoryForm = () => {
    const { values, setValues } = useFormikContext();
    const [modalOpen, setModalOpen] = useState(false);
    const [editingTherapy, setEditingTherapy] = useState(null);
    const [editingMedication, setEditingMedication] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const onCloseModal = () => {
        setModalOpen(false);
        setEditMode(false);
        setEditingTherapy(null);
        setEditingMedication(null);
    };

    return (
        <div>
            <SectionTitleStyle>
                <h2>Therapeutic Category & Medication</h2>

                <Button bsStyle="link" onClick={() => setModalOpen(true)}>
                    <FaPlus /> Add Therapeutic Category
                </Button>
            </SectionTitleStyle>

            {!values.therapies.length && (
                <TherigyInfoMessage
                    message={
                        <>
                            Therapeutic Categories & Medications will display here. This section is{' '}
                            <strong>required</strong> in order to add a patient.
                        </>
                    }
                />
            )}

            {values.therapies.map((therapy) => (
                <TherapyContainerStyle key={therapy.id}>
                    <TherapyHeaderStyle>
                        <Row>
                            <Col md={3}>
                                <div>Therapeutic Category</div>
                                <strong>{therapy.name}</strong>
                            </Col>
                            <Col md={4}>
                                <div>ICD-10 Diagnosis Code</div>
                                <strong>
                                    {therapy.icd10?.length ? (
                                        <LimitLengthContentPopover
                                            items={therapy.icd10.map(
                                                (icd10) => `${icd10.code} - ${icd10.name || icd10.shortDescription}`
                                            )}
                                        />
                                    ) : (
                                        '--'
                                    )}
                                </strong>
                            </Col>
                            <Col md={2}>
                                <div>Care Opt-in Status</div>
                                {therapy.status.optIn ? (
                                    <>
                                        <FaCheck className="text-success" /> Opt-in
                                        {therapy.status.start && ` - ${formatDate(therapy.status.start)}`}
                                    </>
                                ) : (
                                    <>
                                        <FaTimes className="text-danger" /> Opt-out
                                        {therapy.status.end && ` - ${formatDate(therapy.status.end)}`}
                                    </>
                                )}
                            </Col>
                            <Col md={3}>
                                <Button
                                    bsStyle="link"
                                    className="pull-right"
                                    onClick={() => {
                                        setEditingTherapy(therapy);
                                        setModalOpen(true);
                                    }}
                                >
                                    <FaPlus /> Add Medication
                                </Button>
                                <Button
                                    bsStyle="link"
                                    className="pull-right"
                                    onClick={() => {
                                        setEditingTherapy(therapy);
                                        setEditMode(true);
                                        setModalOpen(true);
                                    }}
                                >
                                    <FaPencilAlt /> Edit
                                </Button>
                            </Col>
                        </Row>
                    </TherapyHeaderStyle>
                    {values.medications
                        .filter((patientMed) => patientMed.therapy.name === therapy.name)
                        .map((patientMed) => (
                            <MedicationContainerStyle key={patientMed.medication.id}>
                                <Row>
                                    <Col md={3}>
                                        <MedicationContainerTitle>Medication</MedicationContainerTitle>
                                        <strong>{patientMed.medication.name}</strong>
                                    </Col>
                                    <Col md={2}>
                                        <MedicationContainerTitle>Medication Start Date</MedicationContainerTitle>
                                        {dateOrDash(['status', 'start'], patientMed)}
                                    </Col>
                                    <Col md={2}>
                                        <MedicationContainerTitle>Medication End Date</MedicationContainerTitle>
                                        {dateOrDash(['status', 'end'], patientMed)}
                                    </Col>
                                    <Col md={2}>
                                        <MedicationContainerTitle>End Reason</MedicationContainerTitle>
                                        {pathOrDash(['status', 'endReason'], patientMed)}
                                    </Col>
                                    <Col md={3}>
                                        <Button
                                            bsStyle="link"
                                            className="pull-right"
                                            onClick={() => {
                                                setEditingTherapy(therapy);
                                                setEditingMedication(patientMed);
                                                setModalOpen(true);
                                            }}
                                        >
                                            <FaPencilAlt /> Edit
                                        </Button>
                                    </Col>
                                </Row>
                                {values.rxs
                                    .filter((rx) => rx.medication.id === patientMed.medication.id)
                                    .map((rx, index) => (
                                        <RxFillNumberView key={rx.id || `index-${index}`} prescription={rx} />
                                    ))}
                            </MedicationContainerStyle>
                        ))}
                </TherapyContainerStyle>
            ))}

            <CheckboxField fieldName="patientReferral" label="Receive Financial Assistance Alerts for patient" />

            {modalOpen && (
                <EditTherapeuticCategoryMedicationModal
                    existingTherapy={editingTherapy}
                    existingMedication={editingMedication}
                    editMode={editMode}
                    onClose={onCloseModal}
                    onSubmit={(modalValues) => {
                        const valueChanges = { ...values };
                        const existingTherapyIndex = valueChanges.therapies.findIndex(
                            (therapy) => therapy.id === modalValues.therapy.id
                        );
                        if (existingTherapyIndex > -1) {
                            valueChanges.therapies[existingTherapyIndex] = modalValues.therapy;
                        } else {
                            valueChanges.therapies.push(modalValues.therapy);
                        }
                        if (modalValues.patientMedication.medication) {
                            const existingMedicationIndex = valueChanges.medications.findIndex(
                                (medication) => medication.medication.id === modalValues.patientMedication.medication.id
                            );
                            if (existingMedicationIndex > -1) {
                                valueChanges.medications[existingMedicationIndex] = modalValues.patientMedication;
                            } else {
                                valueChanges.medications.push(modalValues.patientMedication);
                            }
                        }
                        modalValues.rxs.forEach((updatedRx) => {
                            const existingRxIndex = valueChanges.rxs.findIndex((rx) => rx.id === updatedRx.id);
                            if (existingRxIndex > -1) {
                                valueChanges.rxs[existingRxIndex] = updatedRx;
                            } else {
                                valueChanges.rxs.push(updatedRx);
                            }
                        });
                        setValues(valueChanges);

                        if (modalValues.addMoreMedications) {
                            setEditingTherapy(modalValues.therapy);
                            setModalOpen(true);
                        }
                    }}
                />
            )}
        </div>
    );
};

export default TherapeuticCategoryForm;
