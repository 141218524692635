import * as R from 'ramda';
import React from 'react';
import TemplateTable from './TemplateTable';
import useFetchAllProtocolTemplatesQuery from 'App/hooks/useFetchAllProtocolTemplatesQuery';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import CreateTemplateModal from './CreateTemplateModal/CreateTemplateModal';
import styled from '@emotion/styled';
import { BuilderContextConsumer, BuilderContextProvider } from './BuilderContext';
import PublishTemplateModal from './PublishTemplateModal';
import DeleteTemplateModal from './DeleteTemplateModal';

const TemplateBuilderStyle = styled('div')`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
`;

const propTypes = {};

const defaultProps = {};

function Builder() {
    const { isLoading, data } = useFetchAllProtocolTemplatesQuery();

    return (
        <BuilderContextProvider>
            <BuilderContextConsumer>
                {({ setShowCreateModal }) => (
                    <TemplateBuilderStyle>
                        <Button bsStyle="primary" onClick={() => setShowCreateModal(R.T)}>
                            <FaPlus /> Create New Template
                        </Button>
                    </TemplateBuilderStyle>
                )}
            </BuilderContextConsumer>

            <TemplateTable isLoading={isLoading} data={data} />

            <CreateTemplateModal />

            <DeleteTemplateModal />

            <PublishTemplateModal />
        </BuilderContextProvider>
    );
}

Builder.propTypes = propTypes;
Builder.defaultProps = defaultProps;

export default Builder;
