import styled from '@emotion/styled';
import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { patientPropType } from 'Lib/types';
import { activityPropType, questionGroupPropType, questionPropType } from '../types';
import getGroupScrollId from '../utils/getGroupScrollId';
import AssessmentQuestion from './AssessmentQuestion';

const AssessmentGroupStyle = styled.div`
    border: 1px solid var(--silver);
    border-radius: 4px;
    margin-bottom: 20px;
`;

const AssessmentGroupHeader = styled.div`
    background-color: var(--brandPrimary);
    color: white;
    padding: 6px;
    cursor: pointer;
`;

const AssessmentGroupBody = styled.div`
    padding: 20px;
`;

const AssessmentQuestionGroup = ({ group, questions, activity, patient }) => {
    const [open, setOpen] = useState(true);

    return (
        <AssessmentGroupStyle id={getGroupScrollId(group)}>
            <AssessmentGroupHeader onClick={() => setOpen(!open)}>
                <i
                    className={cx('fa fa-fw', {
                        'fa-minus': open,
                        'fa-plus': !open,
                    })}
                />
                {group.name} Questions
            </AssessmentGroupHeader>

            {open && (
                <AssessmentGroupBody>
                    {questions
                        .sort((a, b) => a.attributes.position - b.attributes.position)
                        .map((question) => (
                            <AssessmentQuestion
                                key={question.questionId}
                                question={question}
                                activity={activity}
                                patient={patient}
                            />
                        ))}
                </AssessmentGroupBody>
            )}
        </AssessmentGroupStyle>
    );
};

AssessmentQuestionGroup.propTypes = {
    group: questionGroupPropType.isRequired,
    questions: PropTypes.arrayOf(questionPropType).isRequired,
    activity: activityPropType.isRequired,
    patient: patientPropType.isRequired,
};

export default AssessmentQuestionGroup;
