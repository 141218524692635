import styled from '@emotion/styled';
import React from 'react';
import { Link, generatePath, useLocation } from 'react-router-dom';

import { patientRoute } from 'App/common/routeLookup';
import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils.js';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import Main from 'App/components/styles/Main/Main';
import PageTitle from 'App/components/styles/PageTitle/PageTitle';
import PageWrapper from 'App/components/styles/PageWrapper/PageWrapper';
import useFetchPatient from 'App/hooks/useFetchPatient';
import useFetchPatientMedicationRxNumber from 'App/hooks/useFetchPatientMedicationRxNumber';
import useFetchPatientMedications from 'App/hooks/useFetchPatientMedications';
import useFetchPatientOtherMedications from 'App/hooks/useFetchPatientOtherMedications';
import useFetchPatientTherapies from 'App/hooks/useFetchPatientTherapies';
import { formatUtcDate, isoDateOnlyFormat } from 'App/services/DateService';
import PESService from 'App/services/PESService';
import { CheckboxInput } from 'Lib/form/CheckboxField';
import { queryClient } from 'Lib/queryClient';
import PatientSummaryNotesTable from './PatientSummaryNotesTable';
import PatientDisplayId from 'App/components/PatientDisplayId/PatientDisplayId';
import { formatPhone } from 'App/utils';

const ClinicalSummaryStyle = styled(PageWrapper)`
    .checkbox {
        margin-top: 0;
        margin-bottom: 0;
    }

    .therigy-table {
        .btn-link {
            padding: 0;
        }
    }
`;

const ClinicalSummaryTitle = styled(PageTitle)`
    padding-left: 0;
    padding-right: 0;
`;

const ClinicalSummaryToolbarStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0;
`;

const PhysicianDetailCell = styled.td`
    && {
        padding: 0.3em 1em;
        border: 0;
    }
`;

const ClinicalSummary = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const patientId = searchParams.get('patient_id');
    const { data: patient, isLoading: isPatientLoading } = useFetchPatient(patientId);
    const { data: therapies = {}, isLoading: isTherapiesLoading } = useFetchPatientTherapies(patientId);
    const { data: medications = {}, isLoading: isMedicationsLoading } = useFetchPatientMedications(patientId);
    const { data: otherMedications = {}, isLoading: isOtherMedicationsLoading } =
        useFetchPatientOtherMedications(patientId);
    const { data: prescriptions, isLoading: isPrescriptionsLoading } = useFetchPatientMedicationRxNumber(patientId);

    if (
        isPatientLoading ||
        isTherapiesLoading ||
        isMedicationsLoading ||
        isOtherMedicationsLoading ||
        isPrescriptionsLoading
    ) {
        return <TherigyLoadingSpinner />;
    }

    return (
        <Main>
            <ClinicalSummaryStyle>
                <ClinicalSummaryTitle>Patient Summary</ClinicalSummaryTitle>

                <h2>
                    {patient.lastName}, {patient.firstName} {patient.preferredName && `"${patient.preferredName}"`}{' '}
                    <PatientDisplayId patientId={patient.id} />
                </h2>

                {patient.pronouns ? <p>({patient.pronouns})</p> : null}

                <ClinicalSummaryToolbarStyle>
                    <Link to={generatePath(patientRoute, { patientId })}>Back to Patient Profile</Link>
                </ClinicalSummaryToolbarStyle>

                <table className="therigy-table">
                    <tbody>
                        <tr>
                            <td>
                                <p>Sex: {patient.gender}</p>
                                {patient.genderIdentity ? <p>Gender Identity: {patient.genderIdentity}</p> : null}
                            </td>
                            <td>DOB: {formatUtcDate({ date: patient.birthDate, format: isoDateOnlyFormat })}</td>
                            <td>Language: {patient.languageSpoken}</td>
                            <td>SSN: xxx-xx-{patient.ssnLastFour}</td>
                            <td>Pharmacy: {patient.pharmacy}</td>
                            <td>
                                <p>
                                    Medical: {patient.medicalPlan} {patient.remoteMedicalPlanId}
                                </p>
                                <p>
                                    PBM: {patient.pbm} {patient.remotePbmId}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <div>ICD-10 Diagnosis:</div>
                                {Object.values(therapies).map((therapy) =>
                                    !therapy?.icd10?.length
                                        ? null
                                        : therapy.icd10.map((item) => (
                                              <div key={item.id}>
                                                  {item.code} {item.name}
                                              </div>
                                          ))
                                )}
                            </td>
                            <td colSpan={3}>
                                Current Medication(s):{' '}
                                {Object.values(medications)
                                    .map((medication) => medication.medication.name)
                                    .join(', ')}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                Address: {patient.addressLine1}, {patient.addressLine2 && `${patient.addressLine2}, `}
                                {patient.city}, {patient.state} {patient.zip}
                            </td>
                            <td colSpan={3}>
                                Other Medication(s):{' '}
                                {Object.values(otherMedications)
                                    .map((otherMedication) => otherMedication.text)
                                    .join(', ')}
                            </td>
                        </tr>
                        <tr>
                            <td>Home Phone: {formatPhone(patient.homePhone)}</td>
                            <td colSpan={2}>Mobile Phone: {formatPhone(patient.mobilePhone)}</td>
                            <td colSpan={3}>
                                Patient Branch: {patient.branchName} -{' '}
                                <Link to={`/editPatientBranch?patient_id=${patientId}`}>Edit</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>Assigned PCC: {patient.pcc || 'None'}</td>
                            <td colSpan={2}>
                                <CheckboxInput
                                    name="pccEmailOnForm"
                                    label="Email PCC When Assessments Updated"
                                    value={patient.pccEmailOnForm === 'Y'}
                                    disabled={!patient.pcc}
                                    onChange={async (event) => {
                                        const newValue = event.target.checked ? 'Y' : 'N';
                                        const newPatientData = await PESService.updatePatient(patientId, {
                                            pccEmailOnForm: newValue,
                                        });
                                        queryClient.setQueryData(['patient', patientId], newPatientData);
                                    }}
                                />
                            </td>
                            <td colSpan={3}>
                                Last Updated On {formatDate(patient.updatedOn, 'YYYY/MM/DD h:mm a')} by{' '}
                                {patient.updatedBy}
                            </td>
                        </tr>
                        <tr>
                            <td>Prescriber(s):</td>
                            <td colSpan={2}>Office Number:</td>
                            <td colSpan={3}>Prescriber Profile:</td>
                        </tr>
                        {prescriptions?.map((prescription) => (
                            <tr key={prescription.id}>
                                <PhysicianDetailCell>{prescription.medication.name}</PhysicianDetailCell>
                                <PhysicianDetailCell colSpan={2}>
                                    {prescription.physician?.phoneNumber}
                                </PhysicianDetailCell>
                                <PhysicianDetailCell colSpan={3}>
                                    {prescription.physician?.firstName} {prescription.physician?.lastName}
                                </PhysicianDetailCell>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <PatientSummaryNotesTable patient={patient} />
            </ClinicalSummaryStyle>
        </Main>
    );
};

export default ClinicalSummary;
