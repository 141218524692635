import React from 'react';
import WithIframe from '../../components/WithIframe';
import { useHistory } from 'react-router-dom';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import PatientBranch from './PatientBranch';

const EditPatientBranch = () => {
    const isReactPatientProfileEnabled = useIsPermitted(PERMISSION_TYPES.ENABLE_REACT_PATIENT_PROFILE);
    const history = useHistory();
    if (isReactPatientProfileEnabled) {
        return <PatientBranch />;
    }

    const {
        location: { search },
    } = history;
    const url = `/stm/edit_patient_division.php${search}`;

    return <WithIframe url={url} />;
};

export default EditPatientBranch;
