import React, { useMemo } from 'react';
import { Modal, ModalHeader, ModalTitle } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import useFetchPatientRx from 'App/hooks/useFetchPatientRx';
import { convertGetPatientRx } from 'App/utils/phpPayloadConversions';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import ReferralProtocolForm from './ReferralProtocolForm';
import NoReProtocolForm from './NoReferralProtocolForm';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    patientId: PropTypes.string.isRequired,
};

const defaultProps = {};

function CreateReferralModal({ onHide, patientId }) {
    const { isLoading, data } = useFetchPatientRx(patientId);

    const referralProtocolManager = useIsPermitted(PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER);

    const medications = useMemo(() => {
        if (data) {
            return R.compose(
                R.map((options) => {
                    return {
                        label: options[0].collection_name,
                        options: R.map((option) => {
                            return R.assoc(
                                'label',
                                `${option.name}${option.rx_number ? ` - ${option.rx_number}` : ''}`,
                                option
                            );
                        }, options),
                    };
                }),
                R.values,
                R.groupBy(R.prop('collection_name')),
                R.filter((entry) => {
                    return entry.status_end === null || entry.status_end >= new Date().toISOString();
                }),
                convertGetPatientRx
            )(data);
        }
        return [];
    }, [data]);

    return (
        <Modal backdrop="static" show>
            <ModalHeader>
                <ModalTitle>Add Referral</ModalTitle>
            </ModalHeader>

            {referralProtocolManager ? (
                <ReferralProtocolForm
                    patientId={patientId}
                    onHide={onHide}
                    isLoading={isLoading}
                    medications={medications}
                />
            ) : (
                <NoReProtocolForm
                    patientId={patientId}
                    onHide={onHide}
                    isLoading={isLoading}
                    medications={medications}
                />
            )}
        </Modal>
    );
}

CreateReferralModal.propTypes = propTypes;
CreateReferralModal.defaultProps = defaultProps;

export default CreateReferralModal;
