import { useQuery } from 'react-query';

import { getQuestionGroups } from 'App/services/AMSService';
import { errorHandler } from 'App/utils';

function useFetchQuestionGroups() {
    return useQuery(['questionGroups'], getQuestionGroups, {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchQuestionGroups;
