import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import InvalidMessage from 'App/common/styles/InvalidMessage';
import FieldLabel from './FieldLabel';

function isEvent(input) {
    return !!input?.nativeEvent && input.nativeEvent instanceof Event;
}

function asFormikField({ WrappedComponent, suppressFieldLabel }) {
    const FieldComponent = ({ fieldName, onChange, label, required, onValidate, ...rest }) => {
        const handleValidate = (value) => {
            // run custom validate function if its provided
            if (onValidate) {
                return onValidate(value);
            }

            if (required && (value === undefined || value === null || value === '' || value.length === 0)) {
                // generic error message as a fallback if a custom function is not used above
                return 'Field is required';
            }
        };

        return (
            <Field name={fieldName} validate={handleValidate}>
                {({ field, form, meta }) => {
                    const { name, value, onBlur } = field;
                    const hasError = (meta.touched || form.submitCount > 0) && !!meta.error;

                    const innerContent = (
                        <>
                            <WrappedComponent
                                name={name}
                                value={value}
                                onBlur={onBlur}
                                onChange={(...args) => {
                                    // Custom onChange supplied from component
                                    if (onChange) {
                                        return onChange(...args);
                                    }
                                    if (isEvent(args[0])) {
                                        return field.onChange(...args);
                                    }
                                    return form.setFieldValue(field.name, args[0]);
                                }}
                                label={suppressFieldLabel ? label : undefined}
                                // `rest` at the bottom, should override if a specific component supplies specific props
                                {...rest}
                            />
                            {hasError ? <InvalidMessage>{meta.error}</InvalidMessage> : null}
                        </>
                    );

                    if (label && !suppressFieldLabel) {
                        return (
                            <FieldLabel fieldName={field.name} label={label} required={required} hasError={hasError}>
                                {innerContent}
                            </FieldLabel>
                        );
                    }

                    return innerContent;
                }}
            </Field>
        );
    };

    FieldComponent.displayName = `asFormikField(${WrappedComponent.displayName || WrappedComponent.name})`;

    FieldComponent.propTypes = {
        fieldName: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        label: PropTypes.node,
        required: PropTypes.bool,
        onValidate: PropTypes.func,
    };

    FieldComponent.defaultProps = {
        onChange: null,
        label: null,
        required: false,
        onValidate: null,
    };

    return FieldComponent;
}

export default asFormikField;
