function AssessmentQuestionTextareaController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeAnswer = doChangeAnswer;

    function $onChanges(changes) {
        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);
            ctrl.response = angular.copy(ctrl.question.response);
        }
    }

    function doChangeAnswer() {
        ctrl.onChangeAnswer({
            answer: ctrl.response,
        });
    }
}

export default AssessmentQuestionTextareaController;
