/** @ngInject */
function CompanyService(_, $http, urlBuilderService) {
    return {
        getUsers,
    };

    function getUsers() {
        const url = urlBuilderService.build('getUsers', {
            company: '',
            users: '',
        });

        return $http.get(url).then((res) => {
            return _.values(res.data);
        });
    }
}

export default CompanyService;
