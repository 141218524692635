import React, { useEffect, useState } from 'react';
import ActivityDueDate from '../ActivityDueDate';
import { DATE_TYPES } from '../constants';

import values from 'ramda/src/values';
import * as R from 'ramda';
import { mapProfileFilterOptions } from '../utils';
import ProtocolService from '../../../services/ProtocolService';
import AddConditionButton from '../AddConditionButton';
import ActivityToTrigger from 'App/features/ProtocolManager/AddRule/ActivityToTrigger';
import FilterFields from 'App/features/ProtocolManager/FilterFields';

const propTypes = {};

const defaultProps = {};

function AdditionOfMedicationForm() {
    //#region State
    const [patientFields, setPatientFields] = useState([]);
    //#endregion

    //#region Side Effects
    useEffect(() => {
        ProtocolService.getProfileFilterOptions().then(R.compose(setPatientFields, mapProfileFilterOptions));
    }, []);
    //#endregion

    return (
        <>
            <ActivityToTrigger />

            <ActivityDueDate dateTypeOptions={values(DATE_TYPES)} />

            <FilterFields fields={patientFields} />

            <AddConditionButton />
        </>
    );
}

AdditionOfMedicationForm.propTypes = propTypes;
AdditionOfMedicationForm.defaultProps = defaultProps;

export default AdditionOfMedicationForm;
