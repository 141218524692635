import { useMachine } from '@xstate/react';
import { NotFound } from 'App/components/errors';
import PatientHighRiskIndicator from 'App/components/PatientHighRiskIndicator';
import ActionCell from 'App/components/styles/ActionCell';
import Table from 'App/components/Table/Table';
import { useUserContext } from 'App/contexts/UserContext';
import { getAlertPatients } from 'App/services/AlertService';
import { getProgramById } from 'App/services/FinancialAssistanceService';
import { errorHandler, hasLength } from 'App/utils';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { assign, createMachine } from 'xstate';

const propTypes = {
    // This is provided by React Router
    match: PropTypes.shape({
        params: PropTypes.shape({
            programId: PropTypes.string,
        }),
    }).isRequired,
};

const defaultProps = {};

const States = Object.freeze({
    CHECKING_PROGRAM_ID: 'CHECKING_PROGRAM_ID',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    NOT_FOUND: 'NOT_FOUND',
});

const Events = Object.freeze({
    SUCCESS: 'SUCCESS',
    PROGRAM_NOT_FOUND: 'PROGRAM_NOT_FOUND',
    SET_PROGRAM_ID: 'SET_PROGRAM_ID',
});

const availablePatientsMachine = createMachine(
    {
        id: 'availablePatients',
        initial: States.CHECKING_PROGRAM_ID,
        context: {
            program: null,
            programId: null,
            patients: [],
        },
        states: {
            [States.CHECKING_PROGRAM_ID]: {
                on: {
                    [Events.SET_PROGRAM_ID]: { target: States.LOADING, actions: ['setProgramId'] },
                    [Events.PROGRAM_NOT_FOUND]: { target: States.NOT_FOUND },
                },
            },
            [States.LOADING]: {
                invoke: {
                    src: 'getData',
                    onDone: {
                        target: States.LOADED,
                        actions: ['setData'],
                    },
                    onError: [
                        {
                            actions: ['handleError'],
                            target: States.NOT_FOUND,
                            cond: 'is404',
                        },
                        {
                            target: States.LOADED,
                            actions: ['handleError'],
                        },
                    ],
                },
                on: {
                    [Events.PROGRAM_NOT_FOUND]: {
                        target: States.NOT_FOUND,
                    },
                },
            },
            [States.LOADED]: {
                on: {
                    [Events.SET_PROGRAM_ID]: { target: States.LOADING, actions: ['setProgramId'] },
                },
            },
            [States.NOT_FOUND]: {},
        },
    },
    {
        actions: {
            setProgramId: assign({
                programId: (_, event) => {
                    return event.programId;
                },
            }),
            setData: assign({
                program: (_, event) => {
                    return R.compose(R.path(['data', 1]))(event);
                },
                patients: (_, event) => {
                    return R.compose(R.values, R.path(['data', 0]))(event);
                },
            }),
            handleError: (context, event) => {
                errorHandler(event.data);
            },
        },
        services: {
            getData: (context) => {
                /*
                 * Order matters here. If there is no 'server-level' copy of the program, `getProgramById` will
                 * try to fetch and create one. This way, `getAlertPatients` won't 404 for a silly reason like
                 * no one has tried to view the details of the program before.
                 */
                return getProgramById(context.programId).then((program) => {
                    return getAlertPatients(context.programId).then((patients) => {
                        return [patients, program];
                    });
                });
            },
        },
        guards: {
            is404: (context, event) => {
                return R.pathEq(404, ['data', 'response', 'status'], event);
            },
        },
    }
);

function AvailablePatients(props) {
    const programId = R.path(['match', 'params', 'programId'], props);
    //#region State

    const user = useUserContext();
    const displayedId = R.path(['company_permissions', 'DisplayedId'], user);

    const [state, send] = useMachine(
        availablePatientsMachine.withContext({
            ...availablePatientsMachine.initialState.context,
            programId,
        })
    );

    const { context } = state;
    const { program, patients } = context;
    //#endregion

    //#region Side Effects
    useEffect(() => {
        if (programId) {
            send({ type: Events.SET_PROGRAM_ID, programId });
        } else {
            send(Events.PROGRAM_NOT_FOUND);
        }
    }, [programId, send]);

    //#endregion

    //#region RunTime Calculations
    const columns = useMemo(() => {
        const tooltip = (
            <Tooltip id="actions-tooltip">
                Clicking Apply will open a page in a new tab. If the tab does not open, check your computer&apos;s
                Downloads folder.
            </Tooltip>
        );
        return [
            {
                Header: 'Patient ID',
                accessor: R.cond([
                    [() => displayedId === 'external_id', R.prop('externalId')],
                    [R.prop('humanId'), R.prop('humanId')],
                    [R.T, R.prop('id')],
                ]),
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ value, row }) => {
                    const actualId = R.path(['original', 'id'], row);
                    return <Link to={`/patient/${actualId}`}>{value}</Link>;
                },
            },
            {
                Header: 'Patient Name',
                accessor: (row) => {
                    return `${row.lastName}, ${row.firstName}`;
                },
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row, value }) => {
                    return (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {/* TODO: Fix this the next time the file is edited. */}
                            {/* eslint-disable-next-line react/prop-types */}
                            <Link to={`/patient/${row.original.id}`} style={{ marginRight: '.5rem' }}>
                                {value}
                            </Link>
                            {/* TODO: Fix this the next time the file is edited. */}
                            {/* eslint-disable-next-line react/prop-types */}
                            {row.original.highRisk && <PatientHighRiskIndicator patientId={row.original.id} />}
                        </span>
                    );
                },
            },
            {
                Header: 'Patient Labels',
                accessor: R.compose(R.ifElse(hasLength, R.join(', '), R.always('--')), R.prop('labels')),
            },
            {
                Header: 'Medications',
                accessor: R.compose(R.join(', '), R.prop('medications')),
            },
            {
                Header: 'Therapeutic Categories',
                accessor: R.compose(R.join(', '), R.prop('therapies')),
            },
            {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/no-unstable-nested-components
                Header: () => (
                    <span style={{ display: 'flex', alignItems: 'center', minWidth: 190 }}>
                        <span style={{ marginRight: '.5rem' }}>Actions</span>

                        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
                            <FaInfoCircle />
                        </OverlayTrigger>
                    </span>
                ),
                id: 'actions',
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row }) => {
                    return (
                        <ActionCell>
                            {/* TODO: Fix this the next time the file is edited. */}
                            {/* eslint-disable-next-line react/prop-types */}
                            <Link to={`/patient/${row.original.id}/referral-manager`}>Referral Manager</Link>
                            <a href={R.prop('EnrollmentURL', program)} target="_blank" rel="noopener noreferrer">
                                Apply
                            </a>
                        </ActionCell>
                    );
                },
            },
        ];
    }, [displayedId, program]);
    //#endregion

    if (state.matches(States.NOT_FOUND)) {
        return <NotFound />;
    }

    const isLoading = [States.CHECKING_PROGRAM_ID, States.LOADING].some(state.matches);

    return (
        <>
            <h3>Available Patients</h3>
            <h4>{isLoading ? 'Loading...' : R.propOr('--', 'ProgramName', program)}</h4>
            <Table
                columns={columns}
                data={patients}
                isLoading={isLoading}
                placeholderText="There are no available patients for this program."
            />
        </>
    );
}

AvailablePatients.propTypes = propTypes;
AvailablePatients.defaultProps = defaultProps;

export default AvailablePatients;
