/**
 * This service handles requests for assessment question diff
 */
/** @ngInject */
function AssessmentDiffService($http, urlBuilderService) {
    return {
        query,
    };

    function query(assessmentId) {
        const url = urlBuilderService.build('assessment', {
            assessment: assessmentId,
            diff: '',
        });

        return $http.get(url).then((res) => {
            return res.data;
        });
    }
}

export default AssessmentDiffService;
