import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

/**
 * @param patientId
 * @param divisionId
 * @param showMessageViewModal
 * @returns {(function({row: *, value: *}): *)|*}
 */
function ActivityCellWrapper(patientId, divisionId, showMessageViewModal) {
    function ActivityCell({ row, value }) {
        const message = row.original;

        if (message.isActivity) {
            return (
                <Link className="btn" to={`/patient/${patientId}/activity/${message.id}?branchId=${divisionId}`}>
                    {value}
                </Link>
            );
        }

        return (
            <Button bsStyle="link" onClick={() => showMessageViewModal(message)}>
                {value}
            </Button>
        );
    }

    ActivityCell.propTypes = {
        row: PropTypes.shape({
            original: PropTypes.shape({
                reason: PropTypes.string,
                id: PropTypes.string,
                mobilePhone: PropTypes.number,
                answeredOn: PropTypes.string,
                isActivity: PropTypes.bool,
                name: PropTypes.string,
                dateDue: PropTypes.string,
            }),
        }).isRequired,
        value: PropTypes.string.isRequired,
    };

    return ActivityCell;
}

export default ActivityCellWrapper;
