import { useFormikContext } from 'formik';
import React from 'react';

import { CheckboxField } from 'Lib/form/CheckboxField';
import SelectField from 'Lib/form/SelectField';

import useFetchDivisionUsers from '../hooks/useFetchDivisionUsers';
import CommonPatientFieldWrapper from './CommonPatientFieldWrapper';
import SectionTitleStyle from './SectionTitleStyle';

const EmailAlertsForm = () => {
    const { values } = useFormikContext();
    const { data: divisionUsers = {}, isLoading: isDivisionUsersLoading } = useFetchDivisionUsers();
    const divisionUsersList = Object.values(divisionUsers).map((o) => ({
        label: `${o.first_name} ${o.last_name}`,
        value: o.id,
    }));

    return (
        <CommonPatientFieldWrapper fieldName="emailAlerts">
            {(commonProps) => (
                <div>
                    <SectionTitleStyle>
                        <h2>Email Alerts</h2>
                    </SectionTitleStyle>

                    <CheckboxField
                        fieldName="pccSendEmail"
                        label="Enable weekly email for upcoming activities"
                        disabled={commonProps.disabled}
                    />

                    {values.pccSendEmail && (
                        <SelectField
                            fieldName="pccId"
                            label="Assign user to receive alerts for this patient"
                            options={divisionUsersList}
                            isLoading={isDivisionUsersLoading}
                            required
                        />
                    )}
                </div>
            )}
        </CommonPatientFieldWrapper>
    );
};

export default EmailAlertsForm;
