import UserService from '../../services/UserService';

/** @ngInject */
function MyAccountConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.myAccount',
        url: '/myAccount',
        resolve: {
            ehrProviders() {
                return UserService.getEhrBranchSettings().then((res) => {
                    return res;
                });
            },
        },
        params: {
            redirect: null,
        },
        views: {
            '': 'myAccount',
            'pageTitle@^': {
                template: 'My Account',
            },
        },
    });
}

export default MyAccountConfig;
