import PESService from 'App/services/PESService';
import { useQuery } from 'react-query';
import { errorHandler } from 'App/utils';

function useFetchActivityCount() {
    return useQuery(['workQueue', 'activityCount'], () => PESService.getCounts(), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchActivityCount;
