import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';

function useFetchPatientMedicationRxNumber(patientId, medicationId) {
    return useQuery(
        ['patient', patientId, 'medicationRxNumber', medicationId],
        () => PESService.getPatientMedicationRxNumber(patientId, medicationId, true),
        {
            onError: errorHandler,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchPatientMedicationRxNumber;
