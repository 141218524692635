/** @ngInject */
function TherapyStatusViewHistoryModalController(therapyService) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.$onChanges = $onChanges;
    ctrl.addNote = addNote;

    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.therapy) {
                ctrl.therapy = angular.copy(ctrl.resolve.therapy);
            }
        }
    }

    function $onInit() {
        ctrl.mode = 'therapy-status-history';
        ctrl.therapyStatusId = null;
        ctrl.note = null;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.close = function (mode) {
        if (ctrl.mode === 'therapy-status-history') {
            ctrl.modalInstance.close(ctrl.modalData);
        }
        ctrl.mode = mode;
    };

    function addNote() {
        ctrl.savingNote = true;
        return therapyService
            .addTherapyStatusNote(ctrl.therapy.patient_id, ctrl.therapyStatusId, ctrl.note)
            .then(() => {
                ctrl.mode = 'therapy-status-history';
            })
            .finally(() => {
                ctrl.savingNote = false;
            });
    }
}

export default TherapyStatusViewHistoryModalController;
