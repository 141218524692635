import { useMutation, useQueryClient } from 'react-query';
import { updateProtocolTemplate } from 'App/services/ProtocolTemplateService';

const useUpdateProtocolTemplateMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(({ uuid, payload }) => updateProtocolTemplate(uuid, payload), {
        onSuccess: (template) => {
            queryClient.setQueryData(['protocol', 'templates', template.uuid], template);
        },
    });
};

export default useUpdateProtocolTemplateMutation;
