/** @ngInject */
function StatesController(statesService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeState = doChangeState;
    ctrl.stateFilter = stateFilter;

    function $onChanges(changes) {
        if (changes.allowClear) {
            ctrl.allowClear = angular.copy(ctrl.allowClear);
        }
        if (changes.editable) {
            ctrl.editable = angular.copy(ctrl.editable);
        }
        if (changes.minChar) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line radix
            ctrl.minChar = parseInt(ctrl.minChar);
        }
        if (changes.required) {
            ctrl.required = angular.copy(ctrl.required);
        }
    }

    /**
     * @summary Initializes the component
     */
    function $onInit() {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        ctrl.model.$render = function () {
            ctrl.name = ctrl.model.$name;
            return statesService
                .get('abbreviation', ctrl.model.$viewValue)
                .then((res) => {
                    ctrl.model.$setViewValue(res);
                    ctrl.value = res;
                })
                .catch(() => {
                    ctrl.value = undefined;
                });
        };
        ctrl.states = statesService.states;
    }

    /**
     * @summary Change state handler
     * @description
     * This method is responsible for passing the state selected to the binded onChangeState function.
     *
     * @param state - The name of the state that was selected
     */
    function doChangeState(state) {
        ctrl.model.$setViewValue(state);
        ctrl.onChangeState({
            state,
        });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function stateFilter(state, viewValue) {
        if (typeof state === 'object' && state.name) {
            return state.name.substr(0, viewValue.length).toLowerCase() === viewValue.toLowerCase();
        }
    }
}

export default StatesController;
