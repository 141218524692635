import PropTypes from 'prop-types';

export const referralPropType = {
    patientId: PropTypes.string.isRequired,
    referralId: PropTypes.string.isRequired,
};
export const referralModalPropType = {
    ...referralPropType,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};

export const referralActivityPropType = {
    activities: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            status: PropTypes.string,
            type: PropTypes.number,
            medications: PropTypes.arrayOf(
                PropTypes.shape({
                    medicationId: PropTypes.string,
                })
            ),
            details: PropTypes.shape({
                paActivityId: PropTypes.string,
            }),
        }).isRequired
    ).isRequired,
};
