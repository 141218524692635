import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const LimitLengthContentPopover = ({ items, maxLength }) => {
    const commaSeparatedContent = items.join(', ');

    if (commaSeparatedContent.length <= maxLength) {
        return commaSeparatedContent;
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Popover id="expanded-content-popover" className="increase-popover-width">
                    {items.map((item) => (
                        <div key={item}>{item}</div>
                    ))}
                </Popover>
            }
        >
            <span>{commaSeparatedContent.substring(0, maxLength)} (...)</span>
        </OverlayTrigger>
    );
};

LimitLengthContentPopover.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    maxLength: PropTypes.number,
};

LimitLengthContentPopover.defaultProps = {
    maxLength: 40,
};

export default LimitLengthContentPopover;
