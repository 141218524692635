/**
 * A simple abstraction to make constructing the SLS base path codified and easier to reuse.
 * @param {object} config - Object expects slsEndpoint, slsPort, and slsStage keys
 * @returns {string} - A string representing the full base path for the current Serverless config.
 * @param {string} service - A string used to denote the service.
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line default-param-last
function getSlsBasePath({ slsEndpoint, slsPort, slsStage } = {}, service) {
    return `${slsEndpoint}:${slsPort}${slsStage ? `/${slsStage}-${service}` : ''}`;
}

export default getSlsBasePath;
