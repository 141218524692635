import angular from 'angular';
import PrescriberModalComponentController from './prescriber/prescriber.component';
import SortWeekDaysComparator from './prescriber/sort-week-days.filter';
import PrescriberAvailabilityController from './prescriber-availability/prescriber-availability.controller';
import AvailabilityTimepickerController from './prescriber-availability/availability-timepicker.controller';
import PrescriberTypeaheadController from './prescriber-typeahead/prescriber-typeahead.controller';

export default angular
    .module('components.prescriber', ['ngMessages'])
    .component('prescriberModalComponent', {
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
        controller: PrescriberModalComponentController,
        template: require('./prescriber/prescriber.html'),
    })
    .component('prescriberAvailability', {
        bindings: {
            availability: '<',
            onDateChange: '&',
        },
        controller: PrescriberAvailabilityController,
        template: require('./prescriber-availability/prescriber-availability.html'),
    })
    .component('availablityTimepicker', {
        bindings: {
            day: '<',
            onChange: '&',
        },
        controller: AvailabilityTimepickerController,
        template: require('./prescriber-availability/availability-timepicker.html'),
    })
    .component('prescriberTypeahead', {
        bindings: {
            editable: '<',
            label: '@',
            ngDisabled: '<',
            ngRequired: '<',
            ngReadonly: '<',
            onChangeValue: '&',
            placeholder: '@',
            requiredMessage: '@',
            minChar: '@',
        },
        controller: PrescriberTypeaheadController,
        require: {
            form: '^form',
            model: 'ngModel',
        },
        template: require('./prescriber-typeahead/prescriber-typeahead.html'),
    })
    .factory('SortWeekDaysComparator', SortWeekDaysComparator).name;
