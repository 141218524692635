import { useFormikContext } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { CheckboxField } from 'Lib/form/CheckboxField';
import { DatePickerField } from 'Lib/form/DatePickerField';

import CommonPatientFieldWrapper from './CommonPatientFieldWrapper';
import SectionTitleStyle from './SectionTitleStyle';

const WelcomePacketForm = () => {
    const { values } = useFormikContext();

    return (
        <CommonPatientFieldWrapper fieldName="welcomePacket">
            {(commonProps) => (
                <div>
                    <SectionTitleStyle>
                        <h2>Welcome Packet</h2>
                    </SectionTitleStyle>

                    <CheckboxField
                        fieldName="welcomePacket"
                        label="Welcome packet requested"
                        disabled={commonProps.disabled}
                    />

                    {values.welcomePacket && (
                        <Row>
                            <Col md={6}>
                                <DatePickerField
                                    fieldName="welcomePacketSent"
                                    label="Mail Date"
                                    placeholder="YYYY/MM/DD"
                                    required
                                    maxDate={values.welcomePacketReturned}
                                    disabled={commonProps.disabled}
                                />
                            </Col>
                            <Col md={6}>
                                <DatePickerField
                                    fieldName="welcomePacketReturned"
                                    label="Return Date"
                                    placeholder="YYYY/MM/DD"
                                    minDate={values.welcomePacketSent}
                                    disabled={commonProps.disabled}
                                />
                            </Col>
                        </Row>
                    )}
                </div>
            )}
        </CommonPatientFieldWrapper>
    );
};

export default WelcomePacketForm;
