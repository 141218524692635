import CompanyService from '../../../../services/CompanyService';

/** @ngInject */
function PrescriptionEndModalController(_, $filter) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.cancelForm = cancelForm;
    ctrl.submit = submit;
    ctrl.physicianFullName = physicianFullName;

    /**
     * Sets values in resolve to properties
     * @param changes
     */
    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.prescription) {
                ctrl.prescription = angular.copy(ctrl.resolve.prescription);
            }
            if (typeof ctrl.resolve.index !== 'undefined') {
                ctrl.index = ctrl.resolve.index;
            }
            if (ctrl.resolve.medication) {
                ctrl.medication = angular.copy(ctrl.resolve.medication);
            }
        }
    }

    /**
     * Method called on initialization of modal
     * @returns {*}
     */
    function $onInit() {
        const optOptReasons = CompanyService.getCustomMenu('medication_opt_out_reasons');

        // The menu looks at the "endReason" and the text needs to absolutely match to display.
        _.forEach(optOptReasons, (menu) => {
            if (menu.valueName === ctrl.prescription.end_reason) {
                ctrl.prescription.end_reason = menu.valueName;
            }
        });
        ctrl.optOutReasons = optOptReasons;
    }

    /**
     * Sends the data from the modal back to where it was called from.
     */
    function submit() {
        ctrl.saving = true;
        ctrl.prescription.summary = `Ending prescription because of ${ctrl.prescription.end_reason}, on ${$filter(
            'date'
        )(ctrl.prescription.end, 'yyyy/MM/dd')}`;

        ctrl.close({
            $value: {
                medication: ctrl.medication,
                index: ctrl.index,
                prescription: ctrl.prescription,
            },
        });
    }

    function physicianFullName(physician) {
        if (physician?.id) {
            if (physician?.first_name) {
                return `${physician.first_name} ${physician.last_name}`;
            }
            return `${physician.physician_first_name} ${physician.physician_last_name}`;
        }
        return '--';
    }

    /**
     * Closes the end rx modal.
     */
    function cancelForm() {
        ctrl.dismiss({ $value: 'cancel' });
    }
}

export default PrescriptionEndModalController;
