import * as R from 'ramda';
import dayjs from 'dayjs'
import dayjsUtc from 'dayjs/plugin/utc'

dayjs.extend(dayjsUtc)

const dateOrDashUtc = R.curry((path, data) => {
    const date = R.path(path, data);
    if (date) {
        return dayjs.utc(date).format('YYYY-MM-DD');
    }

    return '--';
});


export default dateOrDashUtc;
