/** @ngInject */
function EditPatientConfig(_, $stateProvider) {
    $stateProvider.state({
        name: 'app.editPatient',
        url: '/editPatient/{patientId}',
        views: {
            '': 'editPatient',
            'pageTitle@^': {
                template: 'Edit Patient: {{$ctrl.user.active_branch.Name}}',
            },
        },
        resolve: {
            locked(user, pdxLockedFields) {
                if (user.company_permissions.Pdx) {
                    return pdxLockedFields;
                }

                return {};
            },
            integration(user) {
                if (user.company_permissions.Pdx) {
                    return { pdx: {} };
                }
                if (user.company_permissions.MckessonCpsIntegration) {
                    return { mckesson: {} };
                }
                return {};
            },
        },
        params: {
            patientId: null,
            redirectUrl: null,
        },
    });
}

export default EditPatientConfig;
