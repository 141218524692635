import { useQuery } from 'react-query';
import { getStatusOptions } from '@/app/services/PatientService';

/*
fetch list patient statuses
 */
function useFetchPatientStausOptions() {
    return useQuery(['data'], () => getStatusOptions(), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchPatientStausOptions;
