import angular from 'angular';

import AssignmentManagerController from './assignment-manager.controller';
import AssignmentManagerConfig from './assignment-manager.config';

export default angular
    .module('components.assignmentManager', ['ui.router'])
    .component('assignmentManager', {
        bindings: {
            user: '<',
        },
        controller: AssignmentManagerController,
        template: require('./assignment-manager.html'),
    })
    .config(AssignmentManagerConfig).name;
