/** @ngInject */
function ContentLibraryConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.contentLibrary',
        url: '/content-library?type',
        component: 'contentLibrary',
        redirectTo(trans) {
            return (
                trans
                    .injector()
                    .getAsync('user')
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line consistent-return
                    .then((user) => {
                        if (user.company_permissions.TherigyAssessmentAdmin !== true) {
                            return 'app.404';
                        }
                    })
            );
        },
    });
}

export default ContentLibraryConfig;
