import { useCallback, useEffect } from 'react';
import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';
import { formatMedications } from 'App/components/PatientProfile/PatientProfile.utils';
import useProfileDataState from './useProfileDataState';

/**
 * Custom hook which make all the http call to fetch patient Medication required to render patient detail page
 * @param {string} patientId
 * @returns {Object}
 */
function useGetPatientMedications(patientId) {
    const {
        data,
        setData: setMedications,
        isLoading: isMedicationLoading,
        triggerRefetch: triggerMedicationRefetch,
        setIsLoading,
        refetch,
    } = useProfileDataState();

    const fetchPrescriptions = useCallback(
        (medications) => {
            const medicationIds = medications.map((ele) => ele.medication.id);
            const promiseArray = medicationIds.map((id) => PESService.getPatientPrescriptions(patientId, id));
            Promise.all(promiseArray)
                .then((prescriptions) => {
                    const tempMedications = prescriptions.map((prescription, index) => {
                        return {
                            ...medications[index],
                            prescriptions: {
                                [medications[index].medication.id]: {
                                    rxNumbers: Object.values(prescription),
                                },
                            },
                        };
                    });
                    const formattedMedications = formatMedications(tempMedications);
                    setMedications(formattedMedications);
                })
                .catch(errorHandler)
                .finally(() => {
                    setIsLoading(false);
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [patientId]
    );

    useEffect(() => {
        PESService.getPatientMedications(patientId)
            .then((medications) => fetchPrescriptions(Object.values(medications)))
            .catch(errorHandler);
    }, [patientId, fetchPrescriptions, refetch]);

    return {
        medications: data,
        isMedicationLoading,
        triggerMedicationRefetch,
    };
}

export default useGetPatientMedications;
