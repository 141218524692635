import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';
import { errorHandler } from 'App/utils';

function useFetchGenderQuery() {
    return useQuery(['customMenus', 'patient_gender'], () => CompanyService.getCustomMenu('patient_gender'), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchGenderQuery;
