/** @ngInject */
function InsightsService(_, $http, $window, __env, sessionStorageService) {
    const trsEndpoint = `${__env.trsEndpoint}:${__env.trsPort}`;
    const telerikEndpoint = __env.telerik.endpoint;
    return {
        getConfig,
        getParams,
        getParamOptions,
        getMenu,
    };

    function _authorization() {
        return {
            headers: {
                Authorization: `Bearer ${sessionStorageService.getJwt()}`,
            },
        };
    }

    function getConfig(report) {
        return $http.get(`${trsEndpoint}/insights/1.0.0/report/${report}/config`, _authorization()).then((res) => {
            res.data.ReportNameID = res.data.ReportName.replace(/\W/g, '');
            return res.data;
        });
    }

    function getParams(report) {
        const params = [];
        return $http.get(`${trsEndpoint}/insights/1.0.0/report/${report}/params`, _authorization()).then((res) => {
            _.each(res.data, (paramSet) => {
                if (paramSet.parameter_visible) {
                    params.push({
                        ConfigurationID: paramSet.parameter_id,
                        PropertyName: paramSet.parameter_name,
                        DisplayName: paramSet.parameter_description,
                        RequiredField: paramSet.parameter_required > 0,
                        DisplayElement: paramSet.parameter_type_name,
                        DataName: paramSet.value_url,
                        ShowFilter: paramSet.parameter_visible > 0,
                        Priority: paramSet.parameter_priority,
                    });
                }
            });
            return params;
        });
    }

    function getParamOptions(report, procedure) {
        return $http
            .get(`${trsEndpoint}/insights/1.0.0/report/${report}/params/${procedure}`, _authorization())
            .then((res) => {
                return res.data;
            });
    }

    function getMenu() {
        const menus = [];
        return $http
            .get(`${telerikEndpoint}/telerik/api/containers`, _authorization())
            .then((res) => {
                if (res.data && res.data.length) {
                    _.each(res.data, (container) => {
                        const menuItem = {
                            name: container.description,
                            priority: container.priority,
                            options: [],
                        };
                        if (container.reports && container.reports.length) {
                            _.each(container.reports, (report) => {
                                menuItem.options.push({
                                    id: report.id,
                                    priority: report.priority,
                                    automation_id: `${report.id}_${report.name.replace(/\W/g, '')}`,
                                    name: report.description,
                                    reportFile: report.url,
                                    reportPath: _.kebabCase(report.name),
                                    active: true,
                                    category: container.name,
                                });
                            });
                        }
                        menus.push(menuItem);
                    });
                }
                return menus;
            })
            .catch(() => {
                return {};
            });
    }
}

export default InsightsService;
