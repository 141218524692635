import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import PESService from 'App/services/PESService';
import useChangeBranch from 'App/hooks/useChangeBranch';
import { useUserContext } from 'App/contexts/UserContext';

const propTypes = {
    goToPatientProfile: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    patientId: PropTypes.string.isRequired,
    selectedBranchId: PropTypes.string.isRequired,
};

const EditBranchConfirmationModal = ({ goToPatientProfile, onHide, patientId, selectedBranchId }) => {
    const { handleChangeBranch } = useChangeBranch();
    const user = useUserContext();

    const handleSubmit = (e) => {
        e.preventDefault();
        PESService.updatePatientBranch(patientId, selectedBranchId)
            .then(async () => {
                if (selectedBranchId !== user.active_branch.ID) {
                    await handleChangeBranch(selectedBranchId);
                }
                goToPatientProfile();
            })
            .catch((error) => {
                onHide();
                toast.error(error.response?.data?.message || error.toString());
            });
    };

    return (
        <Modal show onHide={onHide} backdrop="static">
            <ModalHeader>
                <ModalTitle />
            </ModalHeader>
            <ModalBody>
                <span>
                    The patient&apos;s branch is about to be changed. To view this patient again, you may need to change
                    your Active Branch.
                </span>
                <br />
                <br />
                <span>You will be redirected to the Patient Profile page.</span>
            </ModalBody>
            <ModalFooter>
                <FormButtonGroup>
                    <Button type="button" onClick={onHide}>
                        Cancel
                    </Button>
                    <Button aria-label="Ok" bsClass="btn btn-primary" className="submit-branch" onClick={handleSubmit}>
                        OK
                    </Button>
                </FormButtonGroup>
            </ModalFooter>
        </Modal>
    );
};

EditBranchConfirmationModal.propTypes = propTypes;

export default EditBranchConfirmationModal;
