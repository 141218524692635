import React, { useMemo } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';
import SearchForm from './SearchForm';
import ProtocolTable from './ProtocolTable';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as Yup from 'yup';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    currentRules: PropTypes.array,
};

const defaultProps = {
    currentRules: [],
};

function AddProtocolModal({ onHide, onConfirm, currentRules }) {
    const indexedRules = useMemo(() => {
        return R.indexBy(R.prop('therapyPolicyId'), currentRules);
    }, [currentRules]);

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            rules: Yup.object().test({
                name: 'deep-equal',
                test(value) {
                    return !R.equals(value, indexedRules);
                },
            }),
        });
    }, [indexedRules]);

    return (
        <Modal backdrop="static" show bsSize="lg">
            <ModalHeader>
                <ModalTitle>Add Protocols</ModalTitle>
            </ModalHeader>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                    category: null,
                    name: '',
                    protocols: {},
                    rules: indexedRules,
                }}
                onSubmit={(values) => {
                    R.compose(onConfirm, R.values, R.prop('rules'))(values);
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <SearchForm />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div style={{ marginTop: '2rem' }}>
                                        <ProtocolTable />
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <FormButtonGroup>
                                <Button onClick={onHide}>Cancel</Button>
                                <SubmitButton>Update</SubmitButton>
                            </FormButtonGroup>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </Modal>
    );
}

AddProtocolModal.propTypes = propTypes;
AddProtocolModal.defaultProps = defaultProps;

export default AddProtocolModal;
