import React from 'react';
import { useFormikContext } from 'formik';
import ActionCell, { ActionButtonLink } from 'App/components/styles/ActionCell';
import * as R from 'ramda';
import PropTypes from 'prop-types';

const propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
    }).isRequired,
};

const defaultProps = {};

function ActionsColumn({ row }) {
    const { values, setFieldValue, isSubmitting } = useFormikContext();
    return (
        <ActionCell>
            <ActionButtonLink
                disabled={isSubmitting}
                onClick={() => {
                    setFieldValue('rules', R.remove(row.index, 1, values.rules));
                }}
            >
                Delete
            </ActionButtonLink>
        </ActionCell>
    );
}

ActionsColumn.propTypes = propTypes;
ActionsColumn.defaultProps = defaultProps;

export default ActionsColumn;
