import CompanyService from '../../../services/CompanyService';

/** @ngInject */
function CustomMenuController(_) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.$onChanges = $onChanges;
    ctrl.addMissingSelectedValue = addMissingSelectedValue;
    ctrl.doChangeValue = doChangeValue;

    /**
     * @summary Initializes the component
     */
    function $onInit() {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        ctrl.model.$render = function () {
            ctrl.name = ctrl.model.$name;
            ctrl.value = ctrl.model.$viewValue;
            ctrl.addMissingSelectedValue(ctrl.value);
        };
    }

    function $onChanges(changes) {
        if (changes.default) {
            ctrl.default = angular.copy(ctrl.default);
        }
        if (changes.ngDisabled) {
            ctrl.ngDisabled = angular.copy(ctrl.ngDisabled);
        }
        if (changes.ngRequired) {
            ctrl.ngRequired = angular.copy(ctrl.ngRequired);
            ctrl.requiredMessage = `Please enter a ${ctrl.label.toLowerCase()}`;
        }
        if (changes.menuName) {
            ctrl.menuName = angular.copy(ctrl.menuName);
            ctrl.options = CompanyService.getCustomMenu(ctrl.menuName);
        }
        if (changes.readonly) {
            ctrl.readonly = angular.copy(ctrl.readonly);
        }
        if (changes.showDefault) {
            ctrl.showDefault = angular.copy(ctrl.showDefault);
        }
    }

    /**
     * Ensures that the selected value is an option in the menu.
     * @param selected - The current selected value.
     */
    function addMissingSelectedValue(selected) {
        if (
            selected !== null &&
            typeof selected !== 'undefined' &&
            ctrl.options &&
            !_.find(ctrl.options, { valueName: selected })
        ) {
            ctrl.options.selected = {
                valueDescription: selected,
                valueName: selected,
                position: -1,
            };
            ctrl.options = _.sortBy(ctrl.options, (o) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line radix
                return parseInt(o.position);
            });
        }
    }

    /**
     * @summary Do change value handler
     * @description
     * This method is responsible for passing the value selected to bound onChangeValue function.
     *
     * @param value - The value that was selected
     */
    function doChangeValue(value) {
        ctrl.model.$setViewValue(value);
        ctrl.onChangeValue({
            value,
        });
    }
}

export default CustomMenuController;
