import { useQuery } from 'react-query';
import { getReferral } from 'App/services/RMSService';
import { errorHandler } from 'App/utils';

function useFetchReferralQuery(patientId, referralId) {
    return useQuery(['patient', patientId, 'referral', referralId], () => getReferral(patientId, referralId), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchReferralQuery;
