/** @ngInject */
function AssessmentQuestionRapid3FunctionalScoreController(_, $sce, $scope, $timeout, amsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    $scope.$on('updateRapid3FunctionalScore', updateRapid3FunctionalScore);

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
        }

        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);
            ctrl.question.id = ctrl.question.officialQuestionId
                ? ctrl.question.officialQuestionId
                : ctrl.question.questionId;
        }

        if (ctrl.fullyScriptedMode !== undefined && ctrl.question) {
            setQuestionText(ctrl.question);
        }
    }

    function setQuestionText(question) {
        if (question) {
            const compactQuestion = question.compactQuestion || question.question;
            ctrl.question.questionDisplay = $sce.trustAsHtml(
                ctrl.fullyScriptedMode ? question.question : compactQuestion
            );
            $timeout(() => {
                angular.element(document.querySelector('#rapid3-functional-score')).html(ctrl.question.response);
                angular
                    .element(document.querySelector('#rapid3-functional-score_compact'))
                    .html(ctrl.question.response);
            }, 1000);
            ctrl.question = angular.copy(ctrl.question);
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function updateRapid3FunctionalScore(event, functionalScore) {
        if (_.isNumber(functionalScore.value)) {
            angular.element(document.querySelector('#rapid3-functional-score')).html(functionalScore.value);
            angular.element(document.querySelector('#rapid3-functional-score_compact')).html(functionalScore.value);
            ctrl.question.response = functionalScore.value;
            return amsService.saveResponse(ctrl.activity.id, ctrl.question.id, {
                response: functionalScore.value,
            });
        }
    }
}

export default AssessmentQuestionRapid3FunctionalScoreController;
