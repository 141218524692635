import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';

import useFetchSummaryNotes from 'App/hooks/useFetchSummaryNotes';
import { localize } from 'App/utils';
import { patientPropType } from 'Lib/types';
import SafeHtmlOnly from '../SafeHtmlOnly/SafeHtmlOnly';
import { activityPropType } from './types';

const SummaryNoteWrapperStyle = styled.div`
    border: solid 1px var(--silver);
    border-radius: 4px;
    padding: 10px;
    background-color: #eee;
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 12px;
`;

const SummaryNoteStyle = styled.div`
    margin-bottom: 5px;
`;

const SummaryNoteCreatorStyle = styled.div`
    font-size: 10px;
    color: var(--manatee);
`;

const SummaryNotesDisplay = ({ patient, activity }) => {
    const { data: summaryNotes, isLoading } = useFetchSummaryNotes(patient.id, activity.assessment.defaultNoteType);

    if (isLoading || !summaryNotes) {
        return null;
    }

    const summaryNotesList = Object.values(summaryNotes);
    if (!summaryNotesList.length) {
        return null;
    }

    return (
        <div>
            <div>Previous summary notes:</div>
            <SummaryNoteWrapperStyle>
                {summaryNotesList
                    .sort((a, b) => dayjs(b.createdOn).diff(a.createdOn))
                    .map((note) => (
                        <SummaryNoteStyle key={note.id}>
                            <SummaryNoteCreatorStyle>
                                <span>
                                    {note.createdByUser.firstName} {note.createdByUser.lastName}
                                </span>
                                <span className="pull-right">{localize(note.createdOn, 'MM/DD/YYYY hh:mm A')}</span>
                            </SummaryNoteCreatorStyle>
                            <SafeHtmlOnly>{note.body}</SafeHtmlOnly>
                        </SummaryNoteStyle>
                    ))}
            </SummaryNoteWrapperStyle>
        </div>
    );
};

SummaryNotesDisplay.propTypes = {
    patient: patientPropType.isRequired,
    activity: activityPropType.isRequired,
};

export default SummaryNotesDisplay;
