import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Row from 'react-bootstrap/lib/Row';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { addTherapy } from '../../services/AdminService';
import { catchPhrase } from '../config/config';
import { Text } from '../StandaloneFormField';
import PageHeader from '../styles/PageHeader/PageHeader';
import PageTitle from '../styles/PageTitle/PageTitle';
import PageWrapper from '../styles/PageWrapper/PageWrapper';

const schema = Yup.string().required('Name is required');

const propTypes = {};

const defaultProps = {};

function CreateCategoryPage() {
    //#region State
    const history = useHistory();
    //#endregion

    //#region Click/Action/Event Handlers
    const gotToLandingPage = () => history.push('/therapeutic-categories');
    //#endregion

    return (
        <>
            <Helmet>
                <title>New Therapeutic Category - {catchPhrase}</title>
            </Helmet>

            <PageTitle>New Therapeutic Category</PageTitle>

            <PageWrapper>
                <PageHeader>
                    <Row style={{ width: '100%' }}>
                        <Col md={6}>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <ControlLabel htmlFor="name" className="field-required">
                                            Name
                                        </ControlLabel>
                                        <Text
                                            id="name"
                                            placeholder="Name"
                                            onSave={(name) => {
                                                return addTherapy(name).then(({ id }) => {
                                                    history.push(`/therapeutic-categories/${id}`);
                                                });
                                            }}
                                            validator={(value) => {
                                                return schema.validate(value);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="form-button-group">
                                        <Button type="button" onClick={gotToLandingPage}>
                                            Cancel
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </PageHeader>
            </PageWrapper>
        </>
    );
}

CreateCategoryPage.propTypes = propTypes;
CreateCategoryPage.defaultProps = defaultProps;

export default CreateCategoryPage;
