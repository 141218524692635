/** @ngInject */
function AssessmentQuestionActivityDateController($rootScope, $scope, moment, dateService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeAnswer = doChangeAnswer;

    const setActivityDate = $rootScope.$on('setActivityDate', onSetActivityDate);

    // Unbind the listener when the controller scope is destroyed
    $scope.$on('$destroy', setActivityDate);

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
        }

        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);
            if (ctrl.question.response !== undefined) {
                ctrl.response = moment(ctrl.question.response, 'YYYY-MM-DDTHH:mm:ss').toDate();
                // this is to ensure the value matches the UI mask for PDF Generation
                ctrl.activityDateValue = moment(ctrl.question.response, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
                $scope.$emit('updateActivityDate', ctrl.response);
            }
        }
    }

    function doChangeAnswer() {
        if (ctrl.response) {
            ctrl.activityDateValue = moment(ctrl.response, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            ctrl.onChangeAnswer({
                answer: dateService.toLocalISODate(ctrl.response),
            });
            $rootScope.$emit('setNeedsByDate', ctrl.response);
            $scope.$emit('updateActivityDate', ctrl.response);
        }
    }

    function onSetActivityDate() {
        if (ctrl.response === undefined) {
            ctrl.response = new Date();
            ctrl.activityDateValue = moment(ctrl.response, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            doChangeAnswer();
        }
    }
}

export default AssessmentQuestionActivityDateController;
