import React, { useEffect, useState } from 'react';
import ActivityDueDate from '../ActivityDueDate';
import { INSURANCE_DATE_TYPES } from '../constants';
import * as R from 'ramda';
import { mapProfileFilterOptions } from '../utils';
import ProtocolService from '../../../services/ProtocolService';
import AddConditionButton from '../AddConditionButton';
import ActivityToTrigger from 'App/features/ProtocolManager/AddRule/ActivityToTrigger';
import FilterFields from 'App/features/ProtocolManager/FilterFields';

const propTypes = {};

const defaultProps = {};

function AdditionOfInsuranceForm() {
    //#region State
    const [filterFields, setFilterFields] = useState([]);
    //#endregion

    //#region Side Effects
    useEffect(() => {
        ProtocolService.getInsuranceFilterOptions().then(R.compose(setFilterFields, mapProfileFilterOptions));
    }, []);
    //#endregion

    return (
        <>
            <ActivityToTrigger />

            <ActivityDueDate dateTypeOptions={R.values(INSURANCE_DATE_TYPES)} />

            <FilterFields fields={filterFields} />

            <AddConditionButton />
        </>
    );
}

AdditionOfInsuranceForm.propTypes = propTypes;
AdditionOfInsuranceForm.defaultProps = defaultProps;

export default AdditionOfInsuranceForm;
