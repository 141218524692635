import * as R from 'ramda';

const useActivityDueDateModal = (row) => {
    const patientId = R.path(['original', 'patientId'], row);
    const activityId = R.path(['original', 'activityId'], row);
    const dateDue = R.path(['original', 'dateDue'], row);
    const typeLabel = R.path(['original', 'type'], row);
    const referralId = R.path(['original', 'referralId'], row);
    const type = R.path(['original', 'referralType'], row);

    return {
        show: true,
        activity: {
            id: activityId,
            dateDue,
            typeLabel,
            patientId,
            referralId,
            type,
        },
    };
};

export default useActivityDueDateModal;
