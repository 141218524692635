import * as R from 'ramda';
import { getData, stripAllHtml } from '../utils';
import moment from 'moment';
import { formatUtcDate, isoDateOnlyFormat, isoDateTimeFormat } from 'App/services/DateService';

const COMPLETED_ACTIVITY_STATUS = {
    COMPLETE: 'Complete',
    COMPLETED: 'Completed',
    APPROVED: 'Approved',
    PARTIALLY_APPROVED: 'Partially Approved',
    NOT_REQUIRED: 'Not Required',
    DENIED: 'Denied',
    UPHELD: 'Upheld',
    OVERTURNED: 'Overturned',
    APPEALED: 'Appealed',
    APPEALED_UPHELD: 'Appealed - Upheld',
    APPEALED_OVERTURNED: 'Appealed - Overturned',
};

const getCompletedOn = (data) => {
    let completedOn;
    if (R.includes(data.status, R.values(COMPLETED_ACTIVITY_STATUS))) {
        if (data.completed_on) {
            completedOn = data.completed_on;
        } else {
            completedOn = new Date();
        }
    } else {
        completedOn = null;
    }
    return completedOn;
};

/**
 * Converts the value of `true` or `false` of a nested `details` property to the appropriate text value (`Yes`, `No`)
 * provided it passes the predicate.
 * @param {string} field - The prop to check.
 * @param {Function} typeCheckFn - A predicate function to determine if the operation should continue.
 * @param {object} data - The data which contains the nested property that needs to be converted.
 * @private
 */
const _convertBooleanToYesNo = R.curry((field, typeCheckFn, data) => {
    const lens = R.lensPath(['details', field]);
    if (R.prop('details', data)) {
        if (typeCheckFn(data) && R.is(Boolean, R.view(lens, data))) {
            return R.over(lens, R.ifElse(R.identity, R.always('Yes'), R.always('No')), data);
        }
    }
    return data;
});

/**
 * Simple abstraction over converting the type value to an integer and comparing the values.
 * @param {number} value - The value to compare
 * @param {object} data - The data which has a `type` property that can be compared.
 */
const isOfType = R.curry((value, data) => R.compose(R.equals(value), parseInt, R.prop('type'))(data));

const isBenefitsInvestigation = isOfType(0);
const isPriorAuth = isOfType(1);
const isFinancialAssistance = isOfType(2);
const isReferralAppeal = isOfType(3);
const isPriorAuthRenewal = isOfType(4);
const isFinancialAssistanceRenewal = isOfType(5);

const isNOTFinancialAssistance =
    /* eslint-disable-next-line no-nested-ternary */
    isFinancialAssistance
        ? R.complement(isFinancialAssistance)
        : isFinancialAssistanceRenewal
        ? R.complement(isFinancialAssistanceRenewal)
        : null;

/** @ngInject */
function RmsService(_, $http, $window, __env, dateService, sessionStorageService, toastr) {
    const endpoint = `${__env.rmsEndpoint}:${__env.rmsPort}/rms/${__env.rmsVersion}`;

    return {
        createActivity,
        createAppealDocument,
        createReferral,
        createReferralActivityNotes,
        createMedication,
        createMedicationRules,
        createCommunication,
        createInsurancePlan,
        createRxStatusRule,

        deleteRxStatusRule,
        deleteActivity,

        get,
        getHistory,
        getActivity,
        getActivityMedications,
        getActivityByType,
        getActivities,
        getActivityHistory,
        getAppealDocuments,
        getCommunications,
        getDefaultRule,
        getInsurancePlans,
        getMedications,
        getMedicationRules,
        getReferrals,
        getReferralActivityNotes,
        getRxStatusRules,
        update,
        updateActivity,
        updateAetnaActivity,
        updateActivityMedication,
        updateAppealDocument,
        updateInsurancePlan,
        updateDefaultRule,
        updateMedication,
        updateRxStatusRule,
        url,
    };

    function _authorization() {
        return {
            headers: {
                Authorization: `Bearer ${sessionStorageService.getJwt()}`,
            },
        };
    }

    function _formatInsurancePlans(plans) {
        _.forEach(plans, (plan) => {
            _formatInsurancePlan(plan);
        });

        return plans;
    }

    function _formatInsurancePlan(plan) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        plan.coverage_effective_date = plan.coverage_effective_date
            ? dateService.utc(plan.coverage_effective_date)
            : null;
    }

    function _formatReferral(referral) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        referral.created_on = referral?.created_on ? dateService.utc(referral.created_on) : null;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        referral.received_on = referral.received_on ? dateService.utc(referral.received_on) : null;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        referral.status_date = referral.status_date ? dateService.utc(referral.status_date) : null;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        referral.updated_on = referral.updated_on ? dateService.utc(referral.updated_on) : null;

        // Create a list of medications
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        referral.medicationList = _.uniq(_.map(referral.medications, 'medication.name')).sort().join(', ');

        if (referral.updated_name) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            referral.user = `${referral.updated_name.first_name} ${referral.updated_name.last_name}`.trim();
        }
        return referral;
    }

    function _formatReferrals(referrals) {
        _.forEach(referrals, (referral) => {
            _formatReferral(referral);
        });
        return referrals;
    }

    function _formatReferralActivity(activity) {
        const act = R.clone(activity);

        act.created_on = act?.created_on ? dateService.utc(act.created_on) : null;
        act.date_time = activity.date_time ? dateService.utc(act.date_time) : null;
        act.due_on = activity.due_on ? dateService.utc(act.due_on) : null;
        act.status_date = activity.status_date ? dateService.utc(act.status_date) : null;
        act.updated_on = activity.updated_on ? dateService.utc(act.updated_on) : null;

        if (act.details) {
            act.details = _formatActivityDetails(act);
        }

        // Create a list of medications
        act.medicationList = _.uniq(_.map(act.medications, 'medication.name')).join(', ');

        // Build the user name (if it exists)
        if (act.updated_name) {
            act.user = `${act.updated_name.first_name} ${act.updated_name.last_name}`.trim();
        }
        return act;
    }

    function _formatReferralActivities(activities) {
        return R.map(_formatReferralActivity, activities);
    }

    function _formatActivityDetails(activity) {
        const details = {};

        details.complete_date = activity.details.complete_date
            ? formatUtcDate({ date: activity.details.complete_date, format: isoDateOnlyFormat })
            : null;

        if (parseInt(activity.type, 10) === 0) {
            details.re_verification_due_date = activity.details.re_verification_due_date
                ? formatUtcDate({ date: activity.details.re_verification_due_date, format: isoDateOnlyFormat })
                : null;
        }

        if (parseInt(activity.type, 10) === 1) {
            details.effective_end_date = activity.details.effective_end_date
                ? new Date(formatUtcDate({ date: activity.details.effective_end_date, format: isoDateTimeFormat }))
                : null;
            details.effective_start_date = activity.details.effective_start_date
                ? formatUtcDate({ date: activity.details.effective_start_date, format: isoDateOnlyFormat })
                : null;
            details.is_prescriber_submitting_appeal = activity.details.is_prescriber_submitting_appeal === 'Yes';
            details.is_re_authorization_required = activity.details.is_re_authorization_required === 'Yes';
            details.re_verification_due_date = activity.details.re_verification_due_date
                ? formatUtcDate({ date: activity.details.re_verification_due_date, format: isoDateOnlyFormat })
                : null;
        }

        if (parseInt(activity.type, 10) === 2) {
            details.application_submission = activity.details.application_submission
                ? formatUtcDate({ date: activity.details.application_submission, format: isoDateOnlyFormat })
                : null;
            details.effective_end_date = activity.details.effective_end_date
                ? new Date(formatUtcDate({ date: activity.details.effective_end_date, format: isoDateTimeFormat }))
                : null;
            details.effective_start_date = activity.details.effective_start_date
                ? formatUtcDate({ date: activity.details.effective_start_date, format: isoDateOnlyFormat })
                : null;
            details.follow_up_due_date = activity.details.follow_up_due_date
                ? formatUtcDate({ date: activity.details.follow_up_due_date, format: isoDateOnlyFormat })
                : null;
        }

        if (parseInt(activity.type, 10) === 3) {
            details.appeal_date = activity.details.appeal_date
                ? formatUtcDate({ date: activity.details.appeal_date, format: isoDateOnlyFormat })
                : null;
            details.benefit_requires_re_verification = activity.details.benefit_requires_re_verification === 'Yes';
            details.schedule_followup_appeal = activity.details.schedule_followup_appeal === 'Yes';
            details.effective_end_date = activity.details.effective_end_date
                ? new Date(formatUtcDate({ date: activity.details.effective_end_date, format: isoDateTimeFormat }))
                : null;
            details.effective_start_date = activity.details.effective_start_date
                ? formatUtcDate({ date: activity.details.effective_start_date, format: isoDateOnlyFormat })
                : null;
            details.re_verification_due_date = activity.details.re_verification_due_date
                ? formatUtcDate({ date: activity.details.re_verification_due_date, format: isoDateOnlyFormat })
                : null;
        }

        if (parseInt(activity.type, 10) === 4) {
            details.effective_end_date = activity.details.effective_end_date
                ? new Date(formatUtcDate({ date: activity.details.effective_end_date, format: isoDateTimeFormat }))
                : null;
            details.effective_start_date = activity.details.effective_start_date
                ? formatUtcDate({ date: activity.details.effective_start_date, format: isoDateOnlyFormat })
                : null;
            details.is_prescriber_submitting_appeal = activity.details.is_prescriber_submitting_appeal === 'Yes';
            details.is_re_authorization_required = activity.details.is_re_authorization_required === 'Yes';
            details.re_verification_due_date = activity.details.re_verification_due_date
                ? formatUtcDate({ date: activity.details.re_verification_due_date, format: isoDateOnlyFormat })
                : null;
        }

        if (parseInt(activity.type, 10) === 5) {
            details.application_submission = activity.details.application_submission
                ? formatUtcDate({ date: activity.details.application_submission, format: isoDateOnlyFormat })
                : null;
            details.effective_end_date = activity.details.effective_end_date
                ? new Date(formatUtcDate({ date: activity.details.effective_end_date, format: isoDateTimeFormat }))
                : null;
            details.effective_start_date = activity.details.effective_start_date
                ? formatUtcDate({ date: activity.details.effective_start_date, format: isoDateOnlyFormat })
                : null;
            details.follow_up_due_date = activity.details.follow_up_due_date
                ? formatUtcDate({ date: activity.details.follow_up_due_date, format: isoDateOnlyFormat })
                : null;
        }

        return { ...activity.details, ...details };
    }

    function getReVerificationDueDate(body) {
        const option = R.path(['details', 're_verification_schedule_option'], body);

        if (option === 'Specific Date') {
            const reVerificationDueDate = moment(body.details.re_verification_due_date);

            return reVerificationDueDate.isValid() ? reVerificationDueDate.format('YYYY-MM-DDT23:59:59') : null;
        }

        if (option === '14 days Prior to PA Expiration' && body.details.effective_end_date) {
            const effectiveEndDate = moment(body.details.effective_end_date);

            return effectiveEndDate.isValid()
                ? effectiveEndDate.subtract(14, 'day').format('YYYY-MM-DDT23:59:59')
                : null;
        }
        return null;
    }

    function _formatActivityUpdate(body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        body = R.compose(
            R.set(R.lensPath(['details', 'status']), body.status),

            // nullify empty string values for details
            R.over(R.lensProp('details'), R.map(R.ifElse(R.equals(''), R.always(null), R.identity))),
            R.over(
                R.lensProp('details'),
                R.omit(['activity_id', 'referral_id', 'patient_id', 'brand', 'date_time', 'company_id'])
            ),
            R.ifElse(
                R.hasPath(['details', 'notes']),
                R.over(R.lensPath(['details', 'notes']), stripAllHtml),
                R.identity
            ),
            R.ifElse(
                R.anyPass([isBenefitsInvestigation, isPriorAuth, isPriorAuthRenewal, isReferralAppeal]),
                R.chain(R.set(R.lensPath(['details', 're_verification_due_date'])), getReVerificationDueDate),
                R.identity
            ),

            _convertBooleanToYesNo('is_prescriber_submitting_appeal', isPriorAuth),
            _convertBooleanToYesNo('is_re_authorization_required', isPriorAuth),
            _convertBooleanToYesNo('is_prescriber_submitting_appeal', isPriorAuthRenewal),
            _convertBooleanToYesNo('is_re_authorization_required', isPriorAuthRenewal),
            _convertBooleanToYesNo('benefit_requires_re_verification', isNOTFinancialAssistance),
            _convertBooleanToYesNo('schedule_followup_appeal', isReferralAppeal)
        )(body);

        return {
            completed_on: getCompletedOn(body),
            created_on: body.created_on,
            current_employee_id: body.current_employee_id || null,
            current_employee_time: body.current_employee_time || null,
            due_on: body.due_on || null,
            type: parseInt(body.type, 10),
            status: body.status || null,
            status_reason: body.status_reason || 'N/A',
            status_date: body.status_date || new Date(),
            details: body.details,
        };
    }

    function _formatReferralCommunications(communications) {
        _.forEach(communications, (communication) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            communication.created_on = communication?.created_on ? dateService.utc(communication.created_on) : null;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            communication.date_time = communication.date_time ? dateService.utc(communication.date_time) : null;

            if (communication.created_name) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                communication.user =
                    `${communication.created_name.first_name} ${communication.created_name.first_name}`.trim();
            }
        });
        return communications;
    }

    function _formatReferralMedication(medication) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        medication.created_on = medication?.created_on ? dateService.utc(medication.created_on) : null;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        medication.date_time = medication.date_time ? dateService.utc(medication.date_time) : null;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        medication.status_date = medication.status_date ? dateService.utc(medication.status_date) : null;
        return medication;
    }

    function _formatReferralMedications(medications) {
        _.forEach(medications, (medication) => {
            _formatReferralMedication(medication);
        });
        return medications;
    }

    function createActivity(patientId, referralId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: '',
        });

        return $http.post(url, body, _authorization()).then((res) => {
            return _formatReferralActivity(res.data);
        });
    }

    function createAppealDocument(patientId, referralId, activityId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
            'appeal-document': '',
        });

        return $http.post(url, body, _authorization()).then((res) => {
            return res.data;
        });
    }

    function createReferral(patientId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: '',
        });

        return $http.post(url, body, _authorization()).then((res) => {
            return _formatReferral(res.data);
        });
    }

    function createReferralActivityNotes(activityId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            referrals: '',
            notes: activityId,
        });

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        body.note = stripAllHtml(body.note);

        return $http.post(url, body, _authorization()).then((res) => {
            return _formatReferral(res.data);
        });
    }

    function createMedication(patientId, referralId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            medication: '',
        });

        return $http.post(url, body, _authorization()).then((res) => {
            return _formatReferralMedication(res.data);
        });
    }

    function createMedicationRules(branchId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            referrals: '',
            settings: branchId,
            'activity-trigger': '',
        });

        return $http
            .post(url, body, {
                headers: _authorization().headers,
            })
            .then((res) => {
                return res.data;
            });
    }

    function createInsurancePlan(patientId, referralId, activityId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
            'bi-plan': '',
        });
        return $http.post(url, body, _authorization());
    }

    function createCommunication(patientId, referralId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            communication: '',
        });

        return $http.post(url, body, _authorization()).then((res) => {
            return res.data;
        });
    }

    function createRxStatusRule(branchId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            referrals: '',
            settings: branchId,
            'status-trigger': '',
        });

        return $http
            .post(url, body, {
                headers: _authorization().headers,
            })
            .then((res) => {
                return res.data;
            });
    }

    function deleteRxStatusRule(branchId, statusTriggerId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            referrals: '',
            settings: branchId,
            'status-trigger': statusTriggerId,
        });

        return $http
            .delete(url, {
                headers: _authorization().headers,
            })
            .then((res) => {
                return res.data;
            });
    }

    function deleteActivity(patientId, referralId, activityId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
        });

        return $http.delete(url, _authorization()).then((res) => {
            return _formatReferralActivity(res.data);
        });
    }

    function getInsurancePlans(patientId, referralId, activityId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
            'bi-plan': '',
        });

        return $http.get(url, _authorization()).then((res) => {
            return _formatInsurancePlans(res.data);
        });
    }

    function get(patientId, referralId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
        });

        return $http.get(url, _authorization()).then((res) => {
            return _formatReferral(res.data);
        });
    }

    function getHistory(patientId, referralId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            history: '',
        });

        return $http.get(url, _authorization()).then((res) => {
            return _formatReferrals(res.data);
        });
    }

    function getActivity(patientId, referralId, activityId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
        });

        return $http.get(url, _authorization()).then((res) => {
            return _formatReferralActivity(res.data);
        });
    }

    function getActivityByType(patientId, referralId, activityId, typeId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
            type: typeId,
        });

        return $http.get(url, _authorization()).then((res) => {
            return _formatReferralActivity(res.data);
        });
    }

    function getActivities(patientId, referralId, filters) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: '',
        });

        return $http
            .get(url, {
                params: filters,
                headers: _authorization().headers,
            })
            .then((res) => {
                return _formatReferralActivities(res.data);
            });
    }

    function getActivityHistory(patientId, referralId, activityId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
            history: '',
        });

        return $http.get(url, _authorization()).then((res) => {
            return _formatReferralActivities(res.data);
        });
    }

    function getActivityMedications(patientId, referralId, activityId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
            medication: '',
        });

        return $http.get(url, _authorization()).then((res) => {
            return res.data;
        });
    }

    function getAppealDocuments(patientId, referralId, activityId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
            'appeal-document': '',
        });

        return $http.get(url, _authorization()).then((res) => {
            return res.data;
        });
    }

    function getCommunications(patientId, referralId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            communication: '',
        });

        return $http.get(url, _authorization()).then((res) => {
            return _formatReferralCommunications(res.data);
        });
    }

    function getDefaultRule(branchId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            referrals: '',
            settings: branchId,
            'referral-default': '',
        });

        return $http
            .get(url, {
                headers: _authorization().headers,
            })
            .then((res) => {
                return res.data;
            });
    }

    function getReferrals(patientId, filters) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: '',
        });

        return $http
            .get(url, {
                params: filters || {},
                headers: _authorization().headers,
            })
            .then((res) => {
                return _formatReferrals(res.data);
            });
    }

    function getReferralActivityNotes(activityId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            referrals: '',
            notes: activityId,
        });

        return $http
            .get(url, _authorization())
            .then(R.compose(_formatReferralActivities, R.map(R.over(R.lensProp('notes'), stripAllHtml)), getData));
    }

    function getMedications(patientId, referralId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            medication: '',
        });

        return $http
            .get(url, {
                headers: _authorization().headers,
            })
            .then((res) => {
                return _formatReferralMedications(res.data);
            });
    }

    function getMedicationRules(branchId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            referrals: '',
            settings: branchId,
            'activity-trigger': '',
        });

        return $http
            .get(url, {
                headers: _authorization().headers,
            })
            .then((res) => {
                return res.data;
            });
    }

    function getRxStatusRules(branchId) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            referrals: '',
            settings: branchId,
            'status-trigger': '',
        });

        return $http
            .get(url, {
                headers: _authorization().headers,
            })
            .then((res) => {
                return res.data;
            });
    }

    function update(patientId, referralId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
        });

        return $http
            .put(
                url,
                {
                    created_on: body?.created_on || null,
                    dispense_status: body.dispense_status || null,
                    dispense_status_reason: body.dispense_status_reason || null,
                    encounter_id: body.encounter_id || null,
                    hub_program_id: body.hub_program_id || null,
                    no_go_triaged_to: body.no_go_triaged_to || null,
                    number: body.number || null,
                    received_by: body.received_by || null,
                    received_on: body.received_on || null,
                    status: body.status || null,
                    status_reason: body.status_reason || null,
                    status_date: body.status_date || null,
                    system_id: body.system_id || null,
                    type: body.type || 0,
                },
                _authorization()
            )
            .then((res) => {
                return _formatReferral(res.data);
            });
    }

    function updateActivity(patientId, referralId, activityId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
        });

        const payload = _formatActivityUpdate(body);

        return $http.put(url, payload, _authorization()).then((res) => {
            return _formatReferralActivity(res.data);
        });
    }

    function updateAetnaActivity(patientId, referralId, activityId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
        });

        return $http
            .put(
                url,
                {
                    status: body.status || null,
                    status_date: body.status_date || null,
                    status_reason: body.status_reason || null,
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line radix
                    type: parseInt(body.type),
                },
                _authorization()
            )
            .then((res) => {
                return {
                    status: res.data.status,
                    status_date: dateService.utc(res.data.status_date),
                    status_reason: res.data.status_reason,
                };
            });
    }

    function updateAppealDocument(patientId, referralId, activityId, documentId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
            'appeal-document': documentId,
        });

        return $http.put(url, body, _authorization()).then((res) => {
            return res.data;
        });
    }

    function updateActivityMedication(patientId, referralId, activityId, medicationId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
            medication: medicationId,
        });

        return $http
            .put(
                url,
                {
                    medication_id: body.medication_id,
                    data: R.ifElse(R.isNil, R.always({}), R.over(R.lensProp('copay_percent'), stripAllHtml))(body.data),
                },
                _authorization()
            )
            .then((res) => {
                return _formatReferralMedication(res.data);
            });
    }

    function updateDefaultRule(branchId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            referrals: '',
            settings: branchId,
            'referral-default': '',
        });

        return $http
            .post(url, body, {
                headers: _authorization().headers,
            })
            .then((res) => {
                return res.data;
            });
    }

    // eslint-disable-next-line complexity
    function updateInsurancePlan(patientId, referralId, activityId, planId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            activity: activityId,
            'bi-plan': planId,
        });

        return $http
            .put(
                url,
                {
                    position: body.position || null,
                    answer_id: body.answer_id || null,
                    coverage_type: body.coverage_type || null,
                    payer_segment: body.payer_segment || null,
                    group_number: body.group_number || null,
                    payer_id_code: body.payer_id_code || null,
                    name: body.name || null,
                    plan_patient_id: body.plan_patient_id || null,
                    medical_product_line: body.medical_product_line || null,
                    pharmacy_product_line: body.pharmacy_product_line || null,
                    pharmacy_insurance_person_code: body.pharmacy_insurance_person_code || null,
                    pharmacy_insurance_pcn: body.pharmacy_insurance_pcn || null,
                    pharmacy_insurance_bin: body.pharmacy_insurance_bin || null,
                    pharmacy_insurance_group_id: body.pharmacy_insurance_group_id || null,
                    is_integrated_high_deductable: body.is_integrated_high_deductable || null,
                    is_pharmacy_benefits_carve_out_from_medical:
                        body.is_pharmacy_benefits_carve_out_from_medical || null,
                    coverage_effective_date: body.coverage_effective_date || null,
                    help_desk_phone: body.help_desk_phone || null,
                    coverage_end_date: body.coverage_end_date || null,
                    deductable_amount: body.deductable_amount || null,
                    deductable_amount_paid_to_date: body.deductable_amount_paid_to_date || null,
                    pharmacy_plan_oop_max: body.pharmacy_plan_oop_max || null,
                    pharmacy_plan_oop_max_paid_to_date: body.pharmacy_plan_oop_max_paid_to_date || null,
                    patient_insurance_uuid: body.patient_insurance_uuid || null,
                },
                _authorization()
            )
            .then((res) => {
                return _formatInsurancePlan(res.data);
            })
            .catch(() => {
                toastr.error('Failed to update insurance plan');
            });
    }

    function updateMedication(patientId, referralId, medicationId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            patient: patientId,
            referral: referralId,
            medication: medicationId,
        });

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        body = _.pickBy(body, (d) => {
            return !_.isNil(d) && d !== '';
        });

        return $http.put(url, body, _authorization()).then((res) => {
            return _formatReferralMedication(res.data);
        });
    }

    function updateRxStatusRule(branchId, statusTriggerId, body) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        const url = this.url({
            referrals: '',
            settings: branchId,
            'status-trigger': statusTriggerId,
        });

        return $http
            .put(url, body, {
                headers: _authorization().headers,
            })
            .then((res) => {
                return res.data;
            });
    }

    function url(data) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        let url = endpoint;

        if (typeof data === 'object') {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-restricted-syntax
            for (const p in data) {
                if (Object.prototype.hasOwnProperty.call(data, p)) {
                    if (typeof data[p] === 'number') {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        data[p] = data[p].toString();
                    }

                    if (typeof p === 'string' && typeof data[p] === 'string') {
                        url += `/${p}`;
                        /**
                         * Handles the scenario where we append an extra trailing slash if the
                         *  data value is an empty string.
                         */
                        if (data[p].length > 0) {
                            url += `/${data[p]}`;
                        }
                    } else if (typeof p !== 'string') {
                        throw new Error(
                            `Invalid params object provided expected object key to be a string but got: ${typeof p}`
                        );
                    } else if (typeof data[p] !== 'string') {
                        const msg = 'Invalid params object provided expected object value to be a string but got: ';
                        throw new Error(msg + typeof data[p]);
                    }
                }
            }
            return url;
        }
        throw new Error('Invalid data passed into url builder');
    }
}

export { COMPLETED_ACTIVITY_STATUS };

export default RmsService;
