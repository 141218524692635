import styled from '@emotion/styled';
import Main from 'App/components/styles/Main/Main';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {
    getQuestionGroups,
    updateQuestionGroup,
    deleteQuestionGroup,
    createQuestionGroup,
} from '../../services/AdminService';
import { errorHandler } from '../../utils';
import env from '../config/config';
import PageTitle from '../styles/PageTitle/PageTitle';
import PageWrapper from '../styles/PageWrapper/PageWrapper';
import TherigyLoadingSpinner from '../TherigyLoadingSpinner';
import QuestionGroupInput from './QuestionGroupInput';

const FlexCenter = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FormFooter = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
`;

const States = Object.freeze({
    Idle: 'Idle',
    Loading: 'Loading',
    Saving: 'Saving',
});

function getAndFormatQuestionGroups() {
    return getQuestionGroups().then(
        //prettier-ignore
        R.compose(
            R.sortWith([
                R.ascend(R.prop('groupOrder')),
                R.ascend(R.prop('name')),
            ]),
            R.map(
                R.compose(
                    R.chain(R.assoc('label'), R.prop('name')),
                    R.chain(R.assoc('value'), R.prop('uuid')),
                ),
            ),
            R.reject(R.anyPass([
                R.propEq(null, 'name'),
                R.propEq('', 'name'),
            ])),
            R.values,
        )
    );
}

function QuestionGroupsPage() {
    const [questionGroupList, setQuestionGroupList] = useState([]);
    const [isLoading, setIsLoading] = useState(States.Loading);

    const addNewGroup = () => {
        const uuid = uuidv4();
        setQuestionGroupList(
            R.append({
                uuid,
                name: `new ${questionGroupList.length}`,
                groupOrder: 1,
                label: '',
                value: uuid,
                isNew: true,
            })
        );
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(States.Saving);
        const data = R.reduce(
            (result, curr) => {
                if (curr.shouldDelete) {
                    return R.over(R.lensProp('delete'), R.append(curr), result);
                }

                if (curr.isNew) {
                    return R.over(R.lensProp('create'), R.append(curr), result);
                }

                if (curr.isModified) {
                    return R.over(R.lensProp('update'), R.append(curr), result);
                }
                return result;
            },
            { create: [], delete: [], update: [] },
            questionGroupList
        );

        /**
         * This might need throttling/batching in the future.
         */
        return Promise.all([
            /**
             * Create new groups
             */
            Promise.all(R.map(R.compose(createQuestionGroup, R.pick(['name', 'groupOrder'])), data.create)),

            /**
             * Update existing groups
             */
            Promise.all(
                R.map((group) => updateQuestionGroup(group.uuid, R.pick(['name', 'groupOrder'], group)), data.update)
            ),

            /**
             * Delete unwanted groups
             */
            Promise.all(R.map((group) => deleteQuestionGroup(group.uuid), data.delete)),
        ])
            .then(() => {
                toast.success('Saved Successfully');
                setIsLoading(States.Loading);
            })
            .then(getAndFormatQuestionGroups)
            .then(setQuestionGroupList)
            .catch(errorHandler)
            .finally(() => {
                setIsLoading(States.Idle);
            });
    };

    useEffect(() => {
        getAndFormatQuestionGroups()
            .then(setQuestionGroupList)
            .catch(errorHandler)
            .finally(() => {
                setIsLoading(States.Idle);
            });
    }, []);

    return (
        <Main>
            <Helmet>
                <title>Question Groups - {env.catchPhrase}</title>
            </Helmet>
            <PageTitle>Question Groups</PageTitle>
            <PageWrapper>
                {isLoading === States.Loading || isLoading === States.Saving ? (
                    <FlexCenter>
                        <div>
                            <TherigyLoadingSpinner />
                        </div>
                        <div>{R.equals(States.Loading, isLoading) ? 'Loading data...' : 'Saving data...'}</div>
                    </FlexCenter>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={2}>
                                <h4>Position</h4>
                            </Col>

                            <Col md={4}>
                                <h4>Name</h4>
                            </Col>

                            <Col md={2}>
                                <h4>Delete?</h4>
                            </Col>
                        </Row>
                        {R.addIndex(R.map)((group, idx) => {
                            return (
                                <QuestionGroupInput
                                    key={`${group.uuid}-${group.name}`}
                                    questionGroup={group}
                                    questionGroupList={questionGroupList}
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line no-shadow
                                    updateQuestionGroup={(group) => {
                                        setQuestionGroupList(
                                            R.compose(
                                                R.over(R.lensPath([idx, 'isModified']), R.T),
                                                R.set(R.lensIndex(idx), group)
                                            )
                                        );
                                    }}
                                />
                            );
                        }, questionGroupList)}
                        <Row>
                            <Col md={7}>
                                <FormFooter>
                                    <Button type="button" onClick={addNewGroup}>
                                        Add new group
                                    </Button>
                                    <Button bsStyle="primary" type="submit">
                                        Save
                                    </Button>
                                </FormFooter>
                            </Col>
                        </Row>
                    </Form>
                )}
            </PageWrapper>
        </Main>
    );
}

export default QuestionGroupsPage;
