import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';
import { errorHandler } from 'App/utils';

function useSsoCompanyConfigurationQuery(companyId) {
    return useQuery(['getSsoConfiguration', companyId], () => CompanyService.getSsoCompanyConfiguration(companyId), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useSsoCompanyConfigurationQuery;
