import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';
import useFetchPatientTherapies from 'App/hooks/useFetchPatientTherapies';

function useFetchPatientICD10(patientId) {
    const { data: patientTherapies } = useFetchPatientTherapies(patientId);
    return useQuery(
        ['patient', patientId, 'therapy', patientTherapies, 'icd10'],
        async () => {
            if (!patientTherapies) {
                return;
            }
            const result = await Promise.all(
                Object.values(patientTherapies).map((therapy) => PESService.getICD10(patientId, therapy.id))
            );
            return result.filter((r) => r !== '');
        },
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchPatientICD10;
