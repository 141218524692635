import React from 'react';
import { useQuestionGroupsAccess } from 'App/utils/hooks/useIsPermitted';
import { NotFound } from '../errors';
import QuestionGroupList from './QuestionGroupList';

const propTypes = {};

const defaultProps = {};

function QuestionGroupsPage() {
    const hasQGAccess = useQuestionGroupsAccess();

    if (hasQGAccess) {
        return <QuestionGroupList />;
    }

    return <NotFound />;
}

QuestionGroupsPage.propTypes = propTypes;
QuestionGroupsPage.defaultProps = defaultProps;

export default QuestionGroupsPage;
