import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import getCssVariable from '@/app/utils/getCssVariable';

const propTypes = {
    color: PropTypes.string,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    bsStyle: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-foreign-prop-types
    ...Button.propTypes,
};

const defaultProps = {
    color: getCssVariable('--snow'),
    isLoading: false,
    disabled: false,
};

const Ladda = styled(Button)`
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .ladda-label {
        cursor: --var(--snow);
        position: relative;
    }

    .ladda-spinner {
        display: inline-block;
        margin-left: 0;
        margin-top: 3px;
        opacity: 0;
        pointer-events: none;
        transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important;
        z-index: 2;
        position: absolute;
    }

    &[data-loading='true'] {
        .ladda-label {
            opacity: 0;
            left: 100%;
        }

        .ladda-spinner {
            opacity: 1;
            margin-left: 0;
        }
    }
`;

function LaddaButton({ isLoading, disabled, color, children, ...props }) {
    return (
        <Ladda {...props} disabled={disabled || isLoading} data-loading={isLoading}>
            <span className="ladda-label">{children}</span>
            <span className="ladda-spinner">
                <ClipLoader size={15} color={color} />
            </span>
        </Ladda>
    );
}

LaddaButton.propTypes = propTypes;
LaddaButton.defaultProps = defaultProps;

export default LaddaButton;
