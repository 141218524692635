import PropTypes from 'prop-types';
import React from 'react';

import { patientPropType } from 'Lib/types';
import { questionPropType, scorePropType } from '../types';
import DisplayCMSContent from './DisplayCMSContent';

function getBaseQuestionText(question, fullyScriptedMode) {
    if (fullyScriptedMode) return question.question;
    return question.compactQuestion || question.question;
}

const AssessmentQuestionText = ({ question, fullyScriptedMode, patient, score }) => {
    const baseQuestionText = getBaseQuestionText(question, fullyScriptedMode);

    return <DisplayCMSContent content={baseQuestionText} patient={patient} score={score} />;
};

AssessmentQuestionText.propTypes = {
    question: questionPropType.isRequired,
    fullyScriptedMode: PropTypes.bool,
    patient: patientPropType,
    score: scorePropType,
};

AssessmentQuestionText.defaultProps = {
    patient: null,
    score: null,
    fullyScriptedMode: false,
};

export default AssessmentQuestionText;
