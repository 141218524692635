import { useQuery } from 'react-query';

import { search } from 'App/services/ICD10Service';

function useFetchICD10Autocomplete(inputValue, limit) {
    return useQuery(['icd10Autocomplete', inputValue, 'limit', limit], () => search(inputValue, limit), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000,
    });
}

export default useFetchICD10Autocomplete;
