import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'App/components/Table/Table';
import * as R from 'ramda';
import ActionCell from 'App/components/styles/ActionCell';
import { Button } from 'react-bootstrap';
import SelectColumnFilter from 'App/components/Table/SelectColumnFilter';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    onRemove: PropTypes.func.isRequired,
};

const defaultProps = {
    data: [],
    isLoading: false,
};

const tableStateReducer = (state, action, prevState) => {
    const initialSort = [{ id: 'name', desc: false }];

    const { type } = action;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-case
    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
    }
    return state;
};
const ifOptionIsNew = R.ifElse(R.pathEq('New', ['protocolToReplace', 'id']));

function PublishProtocolTable(props) {
    const { onRemove } = props;
    //#region RunTime Calculations
    const columns = useMemo(() => {
        return [
            {
                Header: 'Protocol to Publish',
                accessor: R.path(['protocolToPublish', 'name']),
            },
            {
                Header: 'Therapeutic Category',
                accessor: R.path(['category', 'name']),
            },
            {
                Header: 'Publish Type',
                Filter: SelectColumnFilter,
                accessor: ifOptionIsNew(R.always('New Standalone'), R.always('Replace')),
            },
            {
                Header: 'Protocol to Replace',
                accessor: ifOptionIsNew(R.always('--'), R.path(['protocolToReplace', 'name'])),
            },
            {
                Header: 'Enable for All',
                Filter: SelectColumnFilter,
                accessor: ifOptionIsNew(
                    // convert to boolean
                    R.ifElse(R.prop('enableForAll'), R.always('Yes'), R.always('No')),
                    // option does not apply
                    R.always('--')
                ),
            },
            {
                Header: 'Action',
                id: 'action',
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row: { index } }) => {
                    return (
                        <ActionCell>
                            <Button
                                bsStyle="link"
                                onClick={() => {
                                    onRemove(index);
                                }}
                            >
                                Remove
                            </Button>
                        </ActionCell>
                    );
                },
            },
        ];
    }, [onRemove]);
    //#endregion

    return <Table {...props} columns={columns} stateReducer={tableStateReducer} />;
}

PublishProtocolTable.propTypes = propTypes;
PublishProtocolTable.defaultProps = defaultProps;

export default PublishProtocolTable;
