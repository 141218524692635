import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { useUserContext } from 'App/contexts/UserContext';
import useChangeBranch from 'App/hooks/useChangeBranch';
import useGetPatientData from 'App/components/PatientProfile/hooks/useGetPatientData';

import FlexCenter from 'App/components/styles/FlexCenter';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import Page from 'App/components/Page';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import ErrorMessage from 'App/components/errors/ErrorMessage';
import { supportEmail } from 'App/components/config/config';

const propTypes = {
    children: PropTypes.element,
};
const defaultProps = {
    children: null,
};

const UserBranchSwitcher = ({ children }) => {
    const { patientId } = useParams();
    const user = useUserContext();
    const { handleChangeBranch, isChanging } = useChangeBranch();

    const { patientData, isPatientDataLoading, isError } = useGetPatientData(patientId);

    useEffect(() => {
        if (
            !isPatientDataLoading &&
            !isChanging &&
            patientData.divisionId &&
            patientData.divisionId !== user.active_branch.ID
        ) {
            handleChangeBranch(patientData.divisionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientData, user, handleChangeBranch]);

    if (isError) {
        return (
            <Page title="Not Found" permissionType={PERMISSION_TYPES.WORK_QUEUE} showPageTitle={false}>
                <ErrorMessage>
                    Error: You do not have permission to view/edit patients in this branch.
                    <br />
                    <br />
                    If you believe that this has been done in error, please contact Therigy at{' '}
                    <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
                </ErrorMessage>
            </Page>
        );
    }

    if (isPatientDataLoading) {
        return (
            <FlexCenter>
                <TherigyLoadingSpinner />
            </FlexCenter>
        );
    }

    return children;
};

UserBranchSwitcher.defaultProps = defaultProps;
UserBranchSwitcher.propTypes = propTypes;

export default UserBranchSwitcher;
