import { useMachine } from '@xstate/react';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import Button from 'react-bootstrap/lib/Button';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import Col from 'react-bootstrap/lib/Col';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Row from 'react-bootstrap/lib/Row';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import { useUserContext } from '../../../contexts/UserContext';
import { trackFaProgramSearch } from 'App/services/TrackingService';
import DetailsModal from '../../AlertsCenter/FinancialAssistance/DetailsModal';
import ActionCell, { ActionButtonLink } from '../../styles/ActionCell';
import Table from '../../Table/Table';
import UserSelector from '../UserSelector';
import machine, { Events, stateMatchers } from './machine';
import { maxLengthTooltipCellRenderer } from './MaxLengthTooltipCell';
import SelectColumnOpenClosed from './SelectColumnOpenClosed';

const propTypes = {};

const defaultProps = {};

/**
 * Gets the value of the `open` property of the provided alert setting.
 * @param {object} alertSettings - The collection of alert settings.
 * @param {object} row - The row as provided by React Table.
 *
 */
const isOpen = R.curry((alertSettings, row) =>
    R.pathOr(false, ['current', row.original.ProgramID, 'open'], alertSettings)
);

/**
 * Gets the value of the `close` property of the provided alert setting.
 * @param {object} alertSettings - The collection of alert settings.
 * @param {object} row - The row as provided by React Table.
 *
 */
const isClosed = R.curry((alertSettings, row) =>
    R.pathOr(false, ['current', row.original.ProgramID, 'close'], alertSettings)
);

/**
 * A React Table reducer. Is used here to set the default sort, and to maintain that sort until explicitly changed.
 * @param {object} state
 * @param {object} action
 * @param {object} prevState
 * @return {object}
 */
let tableStateReducer;
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line prefer-const
tableStateReducer = (state, action, prevState) => {
    const initialSort = [{ id: 'ProgramName', desc: false }];

    const { type } = action;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-case
    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
    }
    return state;
};

function FinancialAssistance() {
    //#region State
    const user = useUserContext();
    const [state, send] = useMachine(machine);

    const { value, context } = state;

    const { programs, modalProgramId, alertSettings, isDirty } = context;
    //#endregion

    //#region RunTime Calculations
    const columns = useMemo(() => {
        const tooltip = (
            <Tooltip id="actions-tooltip">
                A program is open if funds are available and closed if empty. Choose if you want to receive alerts when
                a program&apos;s status changes to Open, Closed, or both.
            </Tooltip>
        );

        return [
            {
                Header: 'Program/Brand',
                accessor: 'ProgramName',
            },
            {
                Header: 'Category',
                accessor: R.compose(R.join(', '), R.propOr([], 'TherapAreas')),
            },
            {
                Header: 'Medication',
                accessor: R.compose(R.join(', '), R.propOr([], 'Drugs')),
                style: {
                    width: 200,
                },
                Cell: maxLengthTooltipCellRenderer(400, R.prop('Drugs')),
            },
            {
                Header: 'Manufacturer',
                accessor: R.compose(R.join(', '), R.propOr([], 'Manufacturers')),
                style: {
                    width: 200,
                },
                Cell: maxLengthTooltipCellRenderer(400, R.prop('Manufacturers')),
            },
            {
                Header: 'Eligibility',
                accessor: R.compose(R.join(', '), R.propOr([], 'Eligibilities')),
            },
            {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/no-unstable-nested-components
                Header: () => (
                    <span style={{ display: 'flex', alignItems: 'center', minWidth: 140 }}>
                        <span style={{ marginRight: '.5rem' }}>Alerts</span>
                        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
                            <FaInfoCircle />
                        </OverlayTrigger>
                    </span>
                ),
                id: 'alerts',
                // the accessor here doesn't matter. Just need it to enable the filter.
                accessor: R.prop('programId'),
                filter: 'openClosed',
                Filter: SelectColumnOpenClosed,
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row }) => {
                    const sendOption = (prop) => {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/prop-types
                        send({ type: Events.TOGGLE_OPTION, prop, programId: row.original.ProgramID });
                    };

                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* TODO: Fix this the next time the file is edited. */}
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label className="checkbox-inline" style={{ marginTop: 0 }}>
                                <input
                                    type="checkbox"
                                    checked={isOpen(alertSettings, row)}
                                    onChange={() => {
                                        sendOption('open');
                                    }}
                                />
                                Open
                            </label>
                            {/* TODO: Fix this the next time the file is edited. */}
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label className="checkbox-inline" style={{ marginTop: 0 }}>
                                <input
                                    type="checkbox"
                                    checked={isClosed(alertSettings, row)}
                                    onChange={() => {
                                        sendOption('close');
                                    }}
                                />
                                Closed
                            </label>
                        </div>
                    );
                },
            },
            {
                Header: 'Actions',
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row }) => {
                    return (
                        <ActionCell>
                            <ActionButtonLink
                                onClick={() => {
                                    trackFaProgramSearch(user.active_company.Name);
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line react/prop-types
                                    send({ type: Events.SHOW_DETAILS, programId: row.original.ProgramID });
                                }}
                            >
                                Program Details
                            </ActionButtonLink>
                        </ActionCell>
                    );
                },
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertSettings, send]);
    //#endregion

    /**
     * We use a filterType here so that the table component can keep better track of the filters and the results.
     */
    const filterTypes = useMemo(() => {
        return {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-shadow
            openClosed: (rows, columns, filterValue) => {
                /**
                 * When filterValue is falsy, it means the user has selected "All" from the dropdown.
                 */
                if (!filterValue) {
                    return rows;
                }

                const fns = {
                    Open: isOpen(alertSettings),
                    Closed: isClosed(alertSettings),
                };

                return R.filter((row) => {
                    return fns[filterValue](row);
                }, rows);
            },
        };
    }, [alertSettings]);

    return (
        <>
            <h3>Financial Assistance Funds</h3>

            <Row>
                <Col md={12}>
                    <p>
                        The below table displays all <strong>Foundation</strong> programs available. Configure each
                        program to receive alerts via the Alerts column.
                    </p>
                </Col>
            </Row>

            <div style={{ marginBottom: '2rem' }}>
                <Table
                    columns={columns}
                    data={programs}
                    isLoading={stateMatchers.isLoading(value)}
                    stateReducer={tableStateReducer}
                    filterTypes={filterTypes}
                />
            </div>

            <Row>
                <Col md={6}>
                    <UserSelector
                        isDisabled={stateMatchers.isLoading(value)}
                        value={context.users.current}
                        options={context.availableOptions.users}
                        onChange={(users) => {
                            send({ type: Events.SELECT_USERS, users });
                        }}
                        errors={context.errors}
                    />
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <Checkbox
                        checked={context.companySettings.email}
                        disabled={stateMatchers.isLoading(value)}
                        onChange={() => {
                            send(Events.TOGGLE_EMAIL);
                        }}
                    >
                        Enable users to receive email alerts for Financial Assistance
                    </Checkbox>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <div className="form-button-group">
                        <Button
                            bsStyle="primary"
                            disabled={stateMatchers.isLoading(value) || !isDirty}
                            onClick={() => {
                                send(Events.SAVE);
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </Col>
            </Row>

            {stateMatchers.isDetailsShowing(value) && (
                <DetailsModal
                    programId={modalProgramId}
                    onHide={() => {
                        send(Events.HIDE_DETAILS);
                    }}
                />
            )}
        </>
    );
}

FinancialAssistance.propTypes = propTypes;
FinancialAssistance.defaultProps = defaultProps;

export default FinancialAssistance;
