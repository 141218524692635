import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SelectField from 'Lib/form/SelectField';
import { TextInput } from 'Lib/form/TextField';

import useFetchICD10Autocomplete from '../hooks/useFetchICD10Autocomplete';

const StyledTypeaheadTextInput = styled(TextInput)`
    border: 0;
    padding: 4px;

    &&& {
        box-shadow: none;
    }
`;

const TypeaheadTextInput = ({ id, value, onChange, onBlur, onFocus }) => {
    return (
        <StyledTypeaheadTextInput
            name={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder="Enter an Additional Diagnosis"
        />
    );
};
TypeaheadTextInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
};

const formatOption = (option) => {
    return `${option.code} ${option.shortDescription}`;
};

const OtherDiagnosisTypeaheadField = ({ fieldName, ...rest }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const { values, setFieldValue } = useFormikContext();
    const fieldValue = get(values, fieldName);
    const { data: options = {}, isLoading } = useFetchICD10Autocomplete(fieldValue, 5);
    const optionsList = Object.values(options).map((o) => ({ label: formatOption(o), value: formatOption(o) }));
    const optionsHasLength = optionsList.length > 0;

    return (
        <SelectField
            {...rest}
            fieldName={fieldName}
            label="Other Diagnosis"
            value={{
                label: fieldValue,
                value: fieldValue,
            }}
            inputValue={fieldValue}
            isLoading={isLoading}
            options={optionsList}
            menuIsOpen={menuIsOpen && optionsHasLength}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Input: TypeaheadTextInput,
            }}
            onChange={(newValue) => {
                setFieldValue(fieldName, newValue.value);
            }}
            onInputChange={(newInputValue, actionMeta) => {
                if (actionMeta.action === 'input-change') {
                    setMenuIsOpen(true);
                    setFieldValue(fieldName, newInputValue);
                }
                if (actionMeta.action === 'input-blur') {
                    setMenuIsOpen(false);
                }
            }}
        />
    );
};

OtherDiagnosisTypeaheadField.propTypes = {
    fieldName: PropTypes.string.isRequired,
};

export default OtherDiagnosisTypeaheadField;
