import PropTypes from 'prop-types';

export const dateRangeOptions = [
    {
        name: 'Overdue',
        value: 'overdue',
    },
    {
        name: 'This Week',
        value: 'this_week',
    },
    {
        name: 'Today',
        value: 'today',
    },
    {
        name: 'Next Week',
        value: 'next_week',
    },
    {
        name: 'In 2 Weeks',
        value: 'week_after',
    },
    {
        name: 'Within a Year',
        value: 'all_pending_activities',
    },
    {
        name: 'Custom',
        value: 'custom',
    },
];

export const cellPropsType = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            patientId: PropTypes.string,
            activityId: PropTypes.string,
            dateDue: PropTypes.string,
            type: PropTypes.string,
        }),
    }),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
