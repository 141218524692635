import React from 'react';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Select from 'react-select';
import * as R from 'ramda';
import useFetchDiagnosisQuery from '../../hooks/useFetchConditionQuery';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';

const propTypes = {
    therapyId: PropTypes.string,
    onChange: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
};

const defaultProps = {
    therapyId: '',
    onChange: () => {},
};

function Conditions({ onChange, therapyId, value }) {
    const { isLoading, data: conditions } = useFetchDiagnosisQuery(therapyId);

    return (
        <FormGroup>
            <ControlLabel htmlFor="condition">Condition</ControlLabel>
            <Select
                classNamePrefix="react-select"
                options={conditions}
                getOptionValue={R.prop('id')}
                getOptionLabel={R.prop('name')}
                inputId="condition"
                isClearable
                onChange={(option) => {
                    onChange(option);
                }}
                isLoading={isLoading}
                value={value}
            />
        </FormGroup>
    );
}
Conditions.propTypes = propTypes;
Conditions.defaultProps = defaultProps;

export default Conditions;
