/** @ngInject */
function WorkQueueConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.workQueue',
        url: '/workQueue',
        params: {
            dateRange: {
                value: null,
                squash: true,
                dynamic: true,
            },
        },
        views: {
            '': 'workQueue',
            'pageTitle@^': {
                template: 'Work Queue',
            },
        },
    });
}

export default WorkQueueConfig;
