import { useQuery } from 'react-query';

import CompanyService from 'App/services/CompanyService';
import { errorHandler } from 'App/utils';

function useFetchEhrBranchSettings() {
    return useQuery(['branchSettings'], CompanyService.getEhrBranchSettings, {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchEhrBranchSettings;
