import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import TherapeuticCategoryHistory from './TherapeuticCategoryHistory';
import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils.js';
import pathOrDash from 'App/utils/pathOrDash';
import { patientTherapyPropType } from 'Lib/types';
import ICD10Popover from './ICD10Popover';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    therapy: patientTherapyPropType.isRequired,
};
const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    therapy: {},
};

const icd10Merged = (icd10) => {
    return icd10.reduce((agg, item) => {
        return icd10.length > 1 ? `${agg} ${item.code} ${item.name}, ` : `${agg} ${item.code} ${item.name}`;
    }, '');
};

export function TherapyHeaders({ therapy }) {
    const [showMedicationHistory, setShowMedicationHistory] = useState(false);
    return (
        <div className="therapy-header">
            <Row>
                <Col className="medication-container-title" md={3}>
                    Therapeutic Category
                </Col>
                <Col className="medication-container-title" md={4}>
                    {`ICD-10 Diagnosis Code ${therapy?.icd10?.length > 0 ? `(${therapy.icd10.length} Items)` : ''} `}
                </Col>
                <Col className="medication-container-title" sm={2} md={2}>
                    Care Opt-in Status
                </Col>
                <Col className="therapy-action align-right" md={3}>
                    <Button bsClass="btn-link" onClick={() => setShowMedicationHistory(true)}>
                        <i className="fa fa-fw fa-history" data-automation-id="view_therapeautic_category_history" />
                        View Therapeutic Category History
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <strong id={`patient-profile-therapy-${therapy.id}`}>{pathOrDash(['name'], therapy)}</strong>
                </Col>
                <Col md={4} id={`icd10-${therapy.id}`}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {therapy?.icd10?.length ? (
                        icd10Merged(therapy.icd10)?.length > 40 ? (
                            <ICD10Popover icd10={therapy.icd10} icd10Merged={icd10Merged(therapy.icd10)} />
                        ) : (
                            <strong>{icd10Merged(therapy.icd10)}</strong>
                        )
                    ) : (
                        '--'
                    )}
                </Col>
                <Col sm={2} md={2}>
                    {therapy?.status?.optIn ? (
                        <>
                            <i className="fas fa-fw fa-check text-success" />
                            Opt-in
                            {therapy?.status?.start
                                ? ` - ${therapy?.status?.start ? formatDate(therapy?.status?.start) : ''}`
                                : ''}
                        </>
                    ) : (
                        <>
                            <i className="fas fa-fw fa-times text-danger" />
                            Opt-out
                            {therapy?.status?.end
                                ? ` - ${therapy?.status?.end ? formatDate(therapy?.status?.end) : ''}`
                                : ''}
                        </>
                    )}
                </Col>
            </Row>
            {showMedicationHistory && (
                <TherapeuticCategoryHistory
                    categoryId={therapy?.id}
                    therapyName={therapy?.name}
                    patientId={therapy?.patientId}
                    show={showMedicationHistory}
                    onHide={() => setShowMedicationHistory(false)}
                />
            )}
        </div>
    );
}

TherapyHeaders.propTypes = propTypes;
TherapyHeaders.defaultProps = defaultProps;

export default TherapyHeaders;
