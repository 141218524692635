import { getAllTherapies } from 'App/services/AdminService';
import _ from 'lodash';
import * as R from 'ramda';
import { isPermitted } from '../../utils';
import isExpired from '../../utils/isExpired';
import { PERMISSION_TYPES } from '../../utils/isPermitted';

function mapAssessments(res) {
    _.each(res, (assessment) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        assessment.companyCreated = assessment.companyId !== null;

        const retiredOn = R.path(['retiredOn'], assessment);
        const assessmentHide = R.path(['settings', 'settings', 'hide'], assessment);
        if (retiredOn || assessmentHide) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            assessment.retired = isExpired(retiredOn) || assessmentHide;
        }
    });
    return _.values(res);
}

/** @ngInject */
function AssessmentManagerConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.assessmentManagerRedirect',
        url: '/assessment-manager',
        redirectTo: {
            params: {
                categoryId: null,
            },
            state: 'app.assessmentManager',
        },
    });

    $stateProvider.state({
        name: 'app.assessmentManager',
        url: '/assessment-manager/:categoryId',
        component: 'assessmentManager',
        params: {
            categoryId: null,
            pollId: null,
            refreshAssessments: null,
            isShowRetired: false,
        },
        redirectTo(trans) {
            return (
                trans
                    .injector()
                    .getAsync('user')
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line consistent-return
                    .then((res) => {
                        const companyHasAccess = res.company_permissions && res.company_permissions.AssessmentManager;
                        const userHasAccess =
                            res.active_branch.Privileges.BranchAdmin || res.active_branch.Privileges.CompanyAdmin;
                        if (!companyHasAccess || !userHasAccess) {
                            return {
                                state: 'app.403',
                                params: {
                                    permissionName: 'Company Admin or Branch Admin',
                                },
                            };
                        }
                    })
                    .catch(() => {
                        return {
                            state: 'app.403',
                            params: {
                                permissionName: 'Company Admin or Branch Admin',
                            },
                        };
                    })
            );
        },
        resolve: {
            categories(assessmentService, userService, $transition$) {
                const params = $transition$.params();
                if (params.categories) return;

                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                return userService.get().then((user) =>
                    // prettier-ignore
                    isPermitted(PERMISSION_TYPES.CLINICAL_ADMIN, user)
                        ? getAllTherapies().then(R.compose(
                            R.sortBy(R.compose(
                                R.toLower,
                                R.prop('name'),
                            )),
                            R.values,
                        ))
                        : assessmentService.getAssessmentCategories()
                );
            },
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-shadow
            assessments(_, $transition$, amsService) {
                const { categoryId, isShowRetired } = $transition$.params();
                if (!categoryId) return;
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                return amsService.getTherapyAssessments(categoryId, true, isShowRetired).then(mapAssessments);
            },
        },
    });
}

export default AssessmentManagerConfig;
