import React from 'react';
import DateRangeFilter from './Filters/DateRangeFilter';
import BranchFilter from './Filters/BranchFilter';
import ActivityFilter from './Filters/ActivityFilter';
import TherapeuticCategoryFilter from './Filters/TherapeuticCategoryFilter';
import PredictiveModelFilter from './Filters/PredictiveModelFilter';
import SaveFilterForm from './SaveFilterForm';
import useWorkQueue from '../hooks/useWorkQueue';

const WorkQueueFilters = () => {
    useWorkQueue();

    return (
        <div className="filter-section">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="filter-section-header filter-section-header-refine-by control-label">Filter By</label>
            <DateRangeFilter />
            <BranchFilter />
            <ActivityFilter />
            <PredictiveModelFilter />
            <TherapeuticCategoryFilter />
            <SaveFilterForm />
        </div>
    );
};

export default WorkQueueFilters;
