/**
 * This service is responsible for getting a license object and returning a the license status for a given key.
 */
function LicenseService() {
    const vm = this;
    const element = angular.element('#licenseJSON');
    const licenses = element.text().length > 0 ? JSON.parse(element.text()) : {};

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    vm.get = function (key) {
        // Always return a boolean value.
        return licenses[key] === true;
    };
}

export default LicenseService;
