import { sendMassText } from '@/app/services/PatientMessagingService';

/** @ngInject */
function SendOneTimeMessageController(_, toastr) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.sendMessage = sendMessage;

    function $onInit() {
        ctrl.loading = false;
    }

    async function sendMessage() {
        ctrl.loading = true;
        const { content, patient, selectedTemplate } = ctrl.resolve.modalData;
        try {
            const res = await sendMassText({
                message: content,
                selectedPatients: [patient.id],
                patientMessagesTemplateId: selectedTemplate,
                queueReason: 'oneTime',
            });
            if (res.successful > 0) {
                toastr.success('Message sent successfully');
                ctrl.close({ $value: true });
            }
        } catch (err) {
            console.log(err);
        } finally {
            ctrl.loading = false;
        }
    }
}

export default SendOneTimeMessageController;
