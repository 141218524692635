import React from 'react';
import useFetchDevFeatureFlagQuery from 'App/hooks/useFetchDevFeatureFlagQuery';
import PropTypes from 'prop-types';
import PageSpinner from 'App/components/PageSpinner';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultProps = {};

/**
 * A component that is intended to sit higher in the tree and manage the general fetching
 * and re-fetching the Feature flags. It does require the User and React-Query contenxt
 * to already be present.
 *
 * @param children
 * @return {*|JSX.Element}
 */
function FeatureFlags({ children }) {
    const { isLoading } = useFetchDevFeatureFlagQuery();

    if (isLoading) {
        return <PageSpinner />;
    }

    return children;
}

FeatureFlags.propTypes = propTypes;
FeatureFlags.defaultProps = defaultProps;

export default FeatureFlags;
