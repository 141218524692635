function PrescriberAvailabilityController() {
    const ctrl = this;

    ctrl.changeTime = changeTime;

    function changeTime(day) {
        ctrl.availability[day.name] = day;
    }
}

export default PrescriberAvailabilityController;
