import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

import asFormField from './asFormField';

export const TextInput = ({
    name,
    value,
    onChange,
    onBlur,
    className,
    style,

    placeholder,
    disabled,
    addonButton,
    ...rest
}) => {
    const formControlContent = (
        <FormControl
            {...rest}
            id={name}
            name={name}
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            className={className}
            style={style}
            type="text"
            autoComplete="off"
            placeholder={placeholder}
            disabled={disabled}
        />
    );

    if (addonButton) {
        return (
            <InputGroup>
                {formControlContent}
                <InputGroup.Button>{addonButton}</InputGroup.Button>
            </InputGroup>
        );
    }

    return formControlContent;
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    addonButton: PropTypes.node,
};

TextInput.defaultProps = {
    value: '',
    onBlur: null,
    className: undefined,
    style: null,

    placeholder: undefined,
    disabled: false,
    addonButton: null,
};

/**
 * `Field` components like TextField below can ONLY be used within a Formik context.
 * For uses outside of Formik, use the raw TextInput component.
 */
export const TextField = asFormField({ WrappedComponent: TextInput });
