/** @ngInject */
function PatientHighRiskIndicatorController(_, pesService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    function $onChanges(changes) {
        if (changes.patientId && ctrl.patientId) {
            pesService.getHighRiskReasons(ctrl.patientId).then((res) => {
                const reasons = _.values(angular.copy(res));
                ctrl.reasons = _.map(reasons, (r) => {
                    return r.reason;
                });
            });
        }

        if (changes.triggers && ctrl.triggers) {
            ctrl.triggers = angular.copy(ctrl.triggers);
        } else {
            ctrl.triggers = 'click outsideClick';
        }
    }
}

export default PatientHighRiskIndicatorController;
