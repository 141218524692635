import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './AccordionWrapper.scss';

const propTypes = {
    title: PropTypes.string.isRequired,
    ActionElement: PropTypes.element,
    children: PropTypes.element,
    showActionWhenClosed: PropTypes.bool,
    initialVisible: PropTypes.bool,
    onClick: PropTypes.func,
};

const defaultProps = {
    ActionElement: null,
    children: null,
    showActionWhenClosed: false,
    initialVisible: true,
    onClick: null,
};

const AccordionWrapper = ({ title, ActionElement, children, showActionWhenClosed, initialVisible, onClick }) => {
    const [isVisible, setIsVisible] = useState(initialVisible);

    return (
        <div className="accordion-wrapper">
            <div className="accordion-title">
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                <h3
                    className="plan-header"
                    data-testid="accordion-title"
                    onClick={() => {
                        setIsVisible(!isVisible);
                        onClick?.(!isVisible);
                    }}
                >
                    <i
                        className={isVisible ? 'fa fa-caret-down' : 'fa fa-caret-right'}
                        style={{ marginRight: '8px' }}
                    />
                    {title}
                </h3>
                {(isVisible || showActionWhenClosed) && ActionElement}
            </div>
            {isVisible && (
                <div className="accordion-body" data-testid="accordion-body">
                    {children}
                </div>
            )}
        </div>
    );
};

AccordionWrapper.propTypes = propTypes;
AccordionWrapper.defaultProps = defaultProps;

export default AccordionWrapper;
