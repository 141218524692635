import { useCallback, useState } from 'react';

/**
 * Custom hook which give a data structure to use in profile data hooks
 * @param {any} defaultState
 * @returns Object containing getter and setters
 */
function useProfileDataState(defaultState = {}) {
    const [data, setData] = useState(defaultState);
    const [isLoading, setIsLoading] = useState(true);
    const [refetch, setRefetch] = useState(0);

    const triggerRefetch = useCallback(() => {
        setRefetch(Math.random());
    }, []);

    return {
        data,
        setData,
        isLoading,
        setIsLoading,
        refetch,
        triggerRefetch,
    };
}

export default useProfileDataState;
