/** @ngInject */
function SessionTimerController(
    $interval,
    $transitions,
    $state,
    $window,
    authService,
    sessionStorageService,
    jwtHelper,
    moment
) {
    const ctrl = this;
    let interval;

    ctrl.$onChanges = $onChanges;
    ctrl.$onDestroy = $onDestroy;
    ctrl.$onInit = $onInit;
    ctrl.resetTimer = resetTimer;
    ctrl.hideNotification = hideNotification;
    /**
     * @summary Initializes the component
     */
    function $onInit() {
        ctrl.display = {
            minutes: null,
            seconds: null,
        };
    }

    /**
     * @summary Sets the timeout remaining to the same value as the bound timeout length
     * @param changes - The changes object passed in by angular
     */
    function $onChanges(changes) {
        if (changes.timeoutLength) {
            ctrl.timeoutLength = angular.copy(ctrl.timeoutLength);
            startTimer();
        }
    }

    /**
     * @summary Clears interval function when component is destroyed.
     */
    function $onDestroy() {
        $interval.cancel(interval);
    }

    /**
     * @summary Resets the session timer to the bound timeout length
     */
    function resetTimer() {
        // Need to cancel the interval otherwise the notice will pop up multiple times while waiting for http.
        $interval.cancel(interval);
        return authService
            .updateJwt()
            .then(() => {
                ctrl.showNotice = false;
            })
            .finally(() => {
                startTimer();
            });
    }
    /**
     * @summary sets cookie to prevent maxAge Notification from showing after closed
     */
    function hideNotification() {
        ctrl.showSessionExpirationNotification = false;
        $window.localStorage.setItem('sessionExpirationShown', true);

        return authService
            .updateJwt()
            .then(() => {})
            .finally(() => {
                startTimer();
            });
    }

    /**
     * @summary Starts the session timer
     * @description
     * This function starts the session timer to the bound timeout length and starts and interval
     * that decrements the timeout length by a second.
     */
    function startTimer() {
        if (ctrl.timeoutLength) {
            sessionStorageService.set('timeout', {
                secs: ctrl.timeoutLength * 60,
                lastUpdated: new Date(),
            });
        }

        interval = $interval(() => {
            try {
                let token = sessionStorageService.getJwt();
                if (token === null) {
                    return;
                }
                token = jwtHelper.decodeToken(token);
                const secondsUntilSessionTimesOut = moment.utc(token.data.Expires).unix() - moment.utc().unix();
                // token.exp is in seconds
                const secondsUntilSessionExpires = token.exp - moment.utc().unix();
                ctrl.display.minutes = Math.floor(secondsUntilSessionTimesOut / 60);
                const seconds = Math.ceil(secondsUntilSessionTimesOut % 60);
                ctrl.display.seconds = seconds < 10 ? `0${seconds}` : seconds;

                if (secondsUntilSessionTimesOut <= 0 || secondsUntilSessionExpires <= 0) {
                    $interval.cancel(interval);
                    $state.go('auth.logout', {
                        sessionExpired: true,
                    });
                }

                // Show the notice after 4 minutes
                if (secondsUntilSessionTimesOut < 240) {
                    ctrl.showNotice = true;
                }
                if (secondsUntilSessionExpires <= 60 * 5) {
                    const sessionExpirationShown = !!$window.localStorage.getItem('sessionExpirationShown');
                    if (sessionExpirationShown !== true) {
                        ctrl.showSessionExpirationNotification = true;
                    }
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                $interval.cancel(interval);
                $state.go('auth.logout', {
                    sessionExpired: true,
                });
            }
        }, 330);
    }
}

export default SessionTimerController;
