function DeleteContentGroupResourceModalController() {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.deleteContentGroupResource = deleteContentGroupResource;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve) {
            if (ctrl.resolve.contentGroup) {
                ctrl.contentGroup = angular.copy(ctrl.resolve.contentGroup);
            }
        }
    }

    function deleteContentGroupResource() {
        ctrl.saving = true;
        ctrl.close({
            $value: angular.copy(ctrl.contentGroup),
        });
    }
}

export default DeleteContentGroupResourceModalController;
