import angular from 'angular';

import Auth from './auth/auth.module';
import ExpiredLicenseModalController from './auth/login/branch-modal.controller';
import WorkQueue from './work-queue/work-queue.module';
import AccessView from './access-view/access-view.component';
import ActivePatientFields from './active-patient-fields/active-patient-fields.component';
import AddPatient from './add-patient/add-patient.module';
import AetnaActivity from './aetna-activity/aetna-activity.module';
import Assessment from './assessment/assessment.module';
import AssessmentManager from './assessment-manager/assessment-manager.module';
import AssessmentPreview from './assessment-preview/assessment-preview.module';
import AssessmentPublisher from './assessment-publisher/assessment-publisher.module';
import AssignmentManager from './assignment-manager/assignment-manager.module';
import ContentLibrary from './content-library/content-library.module';
import ContentPublisher from './content-publisher/content-publisher.module';
import EditPatient from './edit-patient/edit-patient.module';
import Engage from './engage/engage.module';
import Input from './input/input.module';
import MyAccount from './my-account/my-account.module';
import NotesModalController from './activity/notes/notes.controller';
import Patient from './patient/patient.module';
import PDXFindPatient from './pdx-find-patient/pdx-find-patient.module';
import PasswordManager from './password-manager/password-manager.module';
import Prescriber from './prescriber/prescriber.module';
import QuestionDiff from './question-diff/question-diff.module';
import QueueManager from './queue-manager/queue-manager.module';
import Referral from './referral/referral.module';
import ReferralManager from './referral-manager/referral-manager.module';
import ReferralManagerSettings from './referral-manager-settings/referral-manager-settings.module';
import ReportEngine from './report-engine/report-engine.module';
import SessionTimerController from './session-timer/session-timer.controller';
import Therapy from './therapy/therapy.module';
import UnassignedPatient from './unassigned-patient/unassigned-patient.module';
import UserManager from './user-manager/user-manager.module';
import VideoLibrary from './video-library/video-library-module';

// Prescriptions controllers imports
import PrescriptionAddNoteController from './patient/patient-prescription/prescription-add-note/prescription-add-note-modal.controller';
import PrescriptionEndModalController from './patient/patient-prescription/prescription-end-modal/prescription-end-modal.controller';
import PrescriptionHistoryModalController from './patient/patient-prescription/prescription-history/prescription-history-modal.controller';
import PrescriptionNotesController from './patient/patient-prescription/prescription-notes/prescription-notes-modal.controller';

// Therapy controllers imports
import TherapyStatusAddNoteController from './patient/patient-therapeutic-category/therapeutic-category-add-note/therapeutic-category-add-note.controller';
import TherapyStatusViewHistoryModalController from './patient/patient-therapeutic-category/therapeutic-category-history/therapeutic-category-history.controller';
import TherapyStatusHistoryController from './patient/patient-therapeutic-category/therapeutic-category-status-history/therapeutic-category-status-history.controller';
import TherapyStatusNotesController from './patient/patient-therapeutic-category/therapeutic-category-view-notes/therapeutic-category-view-notes.controller';

// Additional imports (standalone modules)
import './aetna-activity/aetna-activity.module';
import './question-diff/question-diff.module';
import RXHistoryModalController from './patient/patient-prescription/prescription-view-history/prescription-view-history-modal.controller';
import RXViewHistoryController from './patient/patient-prescription/prescription-view-history/prescription-view-history.controller';
import UnassignedMedicationActivityController from './unassigned-medication-activity/unassigned-medication-activity.component';
import UnassignedPatientActivityController from './unassigned-patient-activity/unassigned-patient-activity.controller';

export default angular
    .module('components', [
        Auth,
        WorkQueue,
        AccessView,
        ActivePatientFields,
        AddPatient,
        AetnaActivity,
        Assessment,
        AssessmentManager,
        AssessmentPreview,
        AssessmentPublisher,
        AssignmentManager,
        ContentLibrary,
        ContentPublisher,
        EditPatient,
        Engage,
        Input,
        MyAccount,
        Patient,
        PasswordManager,
        PDXFindPatient,
        Prescriber,
        QuestionDiff,
        QueueManager,
        Referral,
        ReferralManager,
        ReferralManagerSettings,
        ReportEngine,
        Therapy,
        UnassignedPatient,
        UserManager,
        VideoLibrary,
    ])
    .component('expiredLicenseModal', {
        bindings: {
            close: '&',
            dismiss: '&',
        },
        controller: ExpiredLicenseModalController,
        template: require('./auth/login/branch-modal.html'),
    })
    .component('activityNotes', {
        bindings: {
            modalInstance: '<',
            resolve: '<',
        },
        controller: NotesModalController,
        template: require('./activity/notes/notes.html'),
    })
    .component('prescriptionAddNote', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: PrescriptionAddNoteController,
        template: require('./patient/patient-prescription/prescription-add-note/prescription-add-note-modal.html'),
    })
    .component('prescriptionEndModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: PrescriptionEndModalController,
        template: require('./patient/patient-prescription/prescription-end-modal/prescription-end-modal.html'),
    })
    .component('prescriptionHistoryModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: PrescriptionHistoryModalController,
        template: require('./patient/patient-prescription/prescription-history/prescription-history-modal.html'),
    })
    .component('prescriptionNotes', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: PrescriptionNotesController,
        template: require('./patient/patient-prescription/prescription-notes/prescription-notes-modal.html'),
    })
    .component('rxHistoryModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: RXHistoryModalController,
        template: require('./patient/patient-prescription/prescription-view-history/prescription-view-history-modal.html'),
    })
    .component('prescriptionViewHistory', {
        bindings: {
            medication: '<',
            patientId: '@',
        },
        controller: RXViewHistoryController,
        controllerAs: 'vm',
        template: '<a href class="pull-right" ng-click="vm.open()">View History</a>',
    })
    .component('therapyStatusAddNote', {
        bindings: {
            categoryId: '@',
            note: '=',
            patientId: '@',
            saving: '<',
            therapyStatusId: '@',
        },
        controller: TherapyStatusAddNoteController,
        template: require('./patient/patient-therapeutic-category/therapeutic-category-add-note/therapeutic-category-add-note.html'),
    })
    .component('therapeuticCategoryHistory', {
        bindings: {
            modalInstance: '<',
            resolve: '<',
        },
        controller: TherapyStatusViewHistoryModalController,
        template: require('./patient/patient-therapeutic-category/therapeutic-category-history/therapeutic-category-history.html'),
    })
    .component('therapyStatusHistory', {
        bindings: {
            category: '<',
            modalInstance: '=',
            mode: '=',
            therapyStatusId: '=',
        },
        controller: TherapyStatusHistoryController,
        template: require('./patient/patient-therapeutic-category/therapeutic-category-status-history/therapeutic-category-status-history.html'),
    })
    .component('therapyStatusNotes', {
        bindings: {
            categoryId: '@',
            patientId: '@',
            therapyStatusId: '@',
        },
        controller: TherapyStatusNotesController,
        template: require('./patient/patient-therapeutic-category/therapeutic-category-view-notes/therapeutic-category-view-notes.html'),
    })
    .component('sessionTimer', {
        bindings: {
            timeoutLength: '<',
        },
        controller: SessionTimerController,
        template: require('./session-timer/session-timer.html'),
    })
    .component('unassignedMedicationActivity', {
        bindings: {
            user: '<',
            onChangeDisplay: '&',
        },
        controller: UnassignedMedicationActivityController,
        template: require('./unassigned-medication-activity/unassigned-medication-activity.html'),
    })
    .component('unassignedPatientActivity', {
        controller: UnassignedPatientActivityController,
        template: require('./unassigned-patient-activity/unassigned-patient-activity.html'),
    }).name;
