import DomPurify from 'dompurify';
import * as R from 'ramda';

/**
 * Strip out all HTML from a given string and just returns the text content.
 * @param {string} text - The string that contains HTML.
 * @returns {string}
 */
function stripAllHtml(text) {
    if (R.is(String, text)) {
        const div = document.createElement('div');
        div.innerHTML = DomPurify.sanitize(text);
        return div.textContent;
    }
    return text;
}

/**
 * Double strip HTML. This is to prevent someone from using HTML entities to create HTML
 */
const doubleStrip = R.compose(stripAllHtml, stripAllHtml);

export default doubleStrip;
