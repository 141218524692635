/** @ngInject */
function TherapyStatusNotesController(_, therapyService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    ctrl.notes = null;

    function $onChanges() {
        return therapyService.getTherapyStatusNotes(ctrl.patientId, ctrl.therapyStatusId).then((res) => {
            ctrl.notes = _.values(res);
            if (ctrl.notes.length === 0) {
                ctrl.notes = false;
            }
        });
    }
}

export default TherapyStatusNotesController;
