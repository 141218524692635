/** @ngInject */
function ICD10TypeaheadController(icd10Service) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeValue = doChangeValue;
    ctrl.search = search;

    function $onChanges(changes) {
        if (changes.editable) {
            ctrl.editable = angular.copy(ctrl.editable);
        }
        if (changes.minChar) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line radix
            ctrl.minChar = parseInt(ctrl.minChar);
        }
        if (changes.ngDisabled) {
            ctrl.ngDisabled = angular.copy(ctrl.ngDisabled);
        }
        if (changes.required) {
            ctrl.required = angular.copy(ctrl.required);
        }
    }

    function $onInit() {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        ctrl.model.$render = function () {
            search();
            ctrl.name = ctrl.model.$name;
            ctrl.value = ctrl.model.$viewValue
                ? `${ctrl.model.$viewValue.code} ${
                      ctrl.model.$viewValue.name || ctrl.model.$viewValue.shortDescription
                  }`
                : null;
        };
    }

    function search(value) {
        if (!value) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            value = 'a';
        }
        return icd10Service
            .search(value)
            .then((res) => {
                return res.splice(0, 5);
            })
            .catch(() => {
                return [];
            });
    }

    function doChangeValue(value) {
        ctrl.model.$setViewValue(value);
        ctrl.onChangeValue({
            icd10: value,
        });
    }
}

export default ICD10TypeaheadController;
