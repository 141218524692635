/** @ngInject */
function PatientOtherMedicationHistoryController(_, NgTableParams, patientOtherMedicationService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.$onInit = function () {
        ctrl.medications = null;
    };

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.patientId && ctrl.patientId) {
            return patientOtherMedicationService.getMany(ctrl.patientId).then((res) => {
                ctrl.medications = _.values(res);
                ctrl.tableParams = new NgTableParams(
                    {
                        count: 10,
                        sorting: { date_time: 'desc' },
                    },
                    {
                        counts: [5, 10, 25],
                        dataset: ctrl.medications,
                    }
                );
                return res;
            });
        }
    }
}

export default PatientOtherMedicationHistoryController;
