import React, { useState, useEffect } from 'react';
import './typeAheadDropDown.scss';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Button } from 'react-bootstrap';
import * as R from 'ramda';
import Select from 'react-select';
import useFetchRequiredPatientFields from 'App/hooks/useFetchRequiredPatientFields';
import useFetchDisabledPatientFields from 'App/hooks/useFetchDisabledPatientFields';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    patientPrescriptions: PropTypes.object,
    medicationId: PropTypes.string,
    index: PropTypes.number,
    handleSearchPrescriber: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    searchPrescriber: PropTypes.array,
    emitEventToAddPrescriber: PropTypes.func,
    handlePropChange: PropTypes.func,
    handleSelectedPrescriber: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    initialValue: PropTypes.object,
    validateRxFillNumber: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    validateRxFillNumberErrors: PropTypes.object,
};
const defaultProps = {
    patientPrescriptions: {},
    medicationId: '',
    index: 0,
    handleSearchPrescriber: () => {},
    searchPrescriber: [],
    emitEventToAddPrescriber: () => {},
    handlePropChange: () => {},
    handleSelectedPrescriber: () => {},
    initialValue: {},
    validateRxFillNumber: () => {},
    validateRxFillNumberErrors: {},
};

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: '32px',
        height: '32px',
        border: 'none',
        '&:hover': {
            borderColor: '#66afe9',
            outline: 0,
            boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '32px',
        padding: '0px 6px',
    }),
};

function PatientPrescriberTypeAhead(props) {
    const [text, setText] = useState(null);
    const {
        patientPrescriptions,
        medicationId,
        index,
        handleSearchPrescriber,
        searchPrescriber,
        emitEventToAddPrescriber,
        handlePropChange,
        handleSelectedPrescriber,
        initialValue,
        validateRxFillNumber,
        validateRxFillNumberErrors,
    } = props;

    const [prescriberModal, setPrescriberModal] = useState('Add');
    const [prescriberSelected, setPrescriberSelected] = useState({});
    const prescriberSelectedKey = Object.keys(prescriberSelected) || {};
    const prescription = patientPrescriptions?.[medicationId]?.rxNumbers;
    const prescriptionFirstName = patientPrescriptions?.[medicationId]?.rxNumbers?.[index]?.physician?.firstName;
    const prescriptionPhysician = patientPrescriptions?.[medicationId]?.rxNumbers?.[index]?.physician;
    const { data: requiredPatientFields } = useFetchRequiredPatientFields();
    const { data: disabledPatientFields } = useFetchDisabledPatientFields();

    const requiredPatientFieldsArr = Object.values(requiredPatientFields || {}).map((item) => item.field);
    const disabledPatientFieldsArr = Object.values(disabledPatientFields || {}).map((item) => item.field);

    useEffect(() => {
        if (prescriptionFirstName) {
            setText(prescriptionPhysician);
            setPrescriberModal('Edit');
        }
    }, [prescriptionFirstName, prescriptionPhysician]);

    const suggestionSelected = (value) => {
        if (value) {
            const prescriber = searchPrescriber.find(R.propEq(value.id, 'id'));

            setPrescriberSelected(prescriber);

            handlePropChange(medicationId, index, 'prescriberId', prescriber.id);
            handlePropChange(medicationId, index, 'physician', value);
            handleSelectedPrescriber(prescriber);
        }
    };

    const handlePrescriberModal = () => {
        if (prescriberModal === 'Add') {
            emitEventToAddPrescriber(prescriberSelected[prescriberSelectedKey]);
        } else {
            emitEventToAddPrescriber(patientPrescriptions?.[medicationId]?.rxNumbers?.[index]?.physician);
        }
    };

    // Used onKeyUp event and couldn't use react-select/async, due to the state is in angular
    const onKeyUp = (e) => {
        handleSearchPrescriber(e.target.value);
    };

    return (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div onKeyUp={onKeyUp}>
            <FormGroup>
                <div className="prescriber-typeahead">
                    <ControlLabel
                        htmlFor="prescriberId"
                        className={
                            requiredPatientFieldsArr.includes('rx_prescriber') &&
                            (R.pathOr('', [index, 'physician'], validateRxFillNumberErrors)
                                ? 'invalid-insurance-field field-required'
                                : 'field-required')
                        }
                    >
                        Prescriber
                    </ControlLabel>
                    <Button className="btn btn-link" onClick={() => handlePrescriberModal()}>
                        {prescriberModal}
                    </Button>
                </div>
                <Select
                    name="prescriberId"
                    inputId="prescriberId"
                    placeholder="Prescriber"
                    classNamePrefix="react-select"
                    className={
                        requiredPatientFieldsArr.includes('rx_prescriber') &&
                        R.pathOr('', [index, 'physician'], validateRxFillNumberErrors)
                            ? 'invalid-insurance-form '
                            : 'prescriber-select'
                    }
                    /* eslint-disable camelcase */
                    options={searchPrescriber.map(({ id, physician_first_name, physician_last_name }) => ({
                        id,
                        firstName: physician_first_name,
                        lastName: physician_last_name,
                    }))}
                    value={text}
                    getOptionValue={R.prop('id')}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    onChange={(opt) => {
                        setText(opt);
                        suggestionSelected(opt);
                        validateRxFillNumber(index, 'physician', opt?.id);
                    }}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                    openMenuOnClick={false}
                    isClearable
                    styles={customStyles}
                    isDisabled={
                        (R.path([index, 'id'], prescription) &&
                            R.path([medicationId, 'rxNumbers', index, 'physician'], initialValue)) ||
                        disabledPatientFieldsArr.includes('rx_prescriber')
                    }
                />
                {requiredPatientFieldsArr.includes('rx_prescriber') &&
                R.pathOr('', [index, 'physician'], validateRxFillNumberErrors) ? (
                    <span className="error-message-insurance">
                        {R.pathOr('', [index, 'physician'], validateRxFillNumberErrors)}
                    </span>
                ) : null}
            </FormGroup>
        </div>
    );
}

PatientPrescriberTypeAhead.propTypes = propTypes;
PatientPrescriberTypeAhead.defaultProps = defaultProps;

export default PatientPrescriberTypeAhead;
