import React from 'react';

function Logo() {
    return (
        <div className="logo">
            <a href="/#!/workQueue">
                <img
                    id="therigy-logo"
                    src="https://cdn.therigy.com/images/stm/TherigySTMlogo-SM.png"
                    alt="Therigy - The Trusted Source for Specialty Solutions"
                />
            </a>
        </div>
    );
}

export default Logo;
