import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';
import { errorHandler } from 'App/utils';

function useFetchPatientLabelsMenu() {
    return useQuery(['customMenus', 'patient_labels'], () => CompanyService.getCustomMenu('patient_labels'), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchPatientLabelsMenu;
