import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import SelectField from 'Lib/form/SelectField';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    highRiskvalue: PropTypes.shape({}),
};

const defaultProps = {
    highRiskvalue: null,
};

function HighRisk({ onChange, highRiskvalue }) {
    const highRiskData = [
        {
            name: 'No',
            id: 0,
        },
        {
            id: 1,
            name: 'Yes',
        },
    ];
    return (
        <SelectField
            fieldName="searchParams.highRiskvalue"
            options={highRiskData}
            label="High Risk"
            getOptionValue={R.prop('id')}
            getOptionLabel={R.prop('name')}
            isClearable
            onChange={(option) => {
                onChange(option);
            }}
            value={highRiskvalue}
        />
    );
}

HighRisk.propTypes = propTypes;
HighRisk.defaultProps = defaultProps;

export default HighRisk;
