import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';

function useFetchMedications(patientId) {
    return useQuery(
        ['patientMedications', patientId],
        () => (!patientId ? null : PESService.getMedication(patientId)),
        {
            onError: errorHandler,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchMedications;
