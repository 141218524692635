import * as R from 'ramda';
import { hasLength } from '../index';
import isPermitted, { PERMISSION_TYPES } from '../isPermitted';
import { matchPath } from 'react-router-dom';

/**
 * This is the core data for the navigation routes. The order that you place this data is the order that the navigation
 * item will appear, provided the user has authorization. The schema is as follows:
 *
 * {
 *     display: The text to use in the link,
 *     location: The "shebang" path to the page,
 *     angular: The app state value, in the case of angular,
 *     permissionType: The key to use in order to find the permissions required for this navigation,
 *     children: An array of children navigation data of the same schema.
 * }
 *
 * While technically, you may nest as many children levels as you desire, only the top two will be displayed.
 *
 * If you have a navigation and do not provide a permissionType, the children will be evaluated. If there are no
 * children found to be permitted, the whole menu will be excluded. This means that you cannot both permission the
 * top-level _and_ the children in any given definition.
 */
const navigationData = [
    {
        display: 'Work Queue',
        location: '/#!/workQueue',
        angular: 'app.workQueue',
        permissionType: PERMISSION_TYPES.WORK_QUEUE,
    },

    {
        display: 'Add Patient',
        location: '/#!/addPatient',
        angular: 'app.addPatient',
        permissionType: PERMISSION_TYPES.ADD_PATIENT,
    },

    {
        display: 'Find Patients',
        location: '/#!/pdxFindPatient',
        angular: 'app.pdxFindPatient',
        permissionType: PERMISSION_TYPES.PDX_FIND_PATIENT,
    },

    {
        display: 'Find Patients',
        location: '/#!/findPatient',
        permissionType: PERMISSION_TYPES.FIND_PATIENT,
    },

    {
        display: 'Reporting',
        children: [
            {
                display: 'Advanced Reports',
                location: '/#!/advancedReports',
                permissionType: PERMISSION_TYPES.ADVANCED_REPORTS,
            },

            {
                display: 'TherigyInsights',
                location: '/#!/reportEngine',
                angular: 'app.reportEngine',
                permissionType: PERMISSION_TYPES.THERIGY_INSIGHTS,
            },
        ],
    },

    {
        display: 'Tools',
        children: [
            {
                display: 'Alerts Manager',
                location: '/#!/alerts-manager',
                permissionType: PERMISSION_TYPES.ALERT_MANAGER,
            },

            {
                display: 'Assessment Manager',
                location: '/#!/assessment-manager',
                angular: 'app.assessmentManager({ categoryId: null })',
                permissionType: PERMISSION_TYPES.ASSESSMENT_MANAGER,
            },

            {
                display: 'Assessment Publisher',
                location: '/#!/assessment-publisher',
                angular: 'app.assessmentPublisher',
                permissionType: PERMISSION_TYPES.ASSESSMENT_PUBLISHER,
            },

            {
                display: 'Content Library',
                location: '/#!/content-library',
                angular: 'app.contentLibrary',
                permissionType: PERMISSION_TYPES.CONTENT_LIBRARY,
            },

            {
                display: 'Content Publisher',
                location: '/#!/content-publisher',
                angular: 'app.contentPublisher',
                permissionType: PERMISSION_TYPES.CONTENT_PUBLISHER,
            },

            {
                display: 'Therapeutic Categories',
                location: '/#!/therapeutic-categories',
                permissionType: PERMISSION_TYPES.THERAPEUTIC_CATEGORIES,
            },

            {
                display: 'Question Groups',
                location: '/#!/question-groups',
                permissionType: PERMISSION_TYPES.THERAPEUTIC_CATEGORIES,
            },

            {
                display: 'Assignment Manager',
                location: '/#!/assignment-manager',
                angular: 'app.assignmentManager',
                permissionType: PERMISSION_TYPES.ASSIGNMENT_MANAGER,
            },

            {
                display: 'Medication Management',
                location: '/#!/medicationManagement',
                permissionType: PERMISSION_TYPES.MEDICATION_MANAGEMENT,
            },

            {
                display: 'Password Manager',
                location: '/#!/password-manager',
                permissionType: PERMISSION_TYPES.PASSWORD_MANAGER,
            },

            {
                display: 'Patient Messaging',
                location: '/#!/patientmessaging',
                permissionType: PERMISSION_TYPES.PATIENT_MESSAGING,
            },

            {
                display: 'Protocol Manager',
                location: '/#!/protocolManager',
                permissionType: PERMISSION_TYPES.PROTOCOL_MANAGER,
            },

            {
                display: 'Protocol Publisher',
                location: '/#!/protocolPublisher',
                permissionType: PERMISSION_TYPES.PUBLISH_PROTOCOL,
            },

            {
                display: 'Protocol Template Builder',
                location: '/#!/protocol-template-builder',
                permissionType: PERMISSION_TYPES.CLINICAL_ADMIN,
            },

            {
                display: 'Queue Manager',
                location: '/#!/queue-manager',
                permissionType: PERMISSION_TYPES.QUEUE_MANAGER,
            },

            {
                display: 'User Manager',
                location: '/#!/user-manager',
                angular: 'app.userManager',
                permissionType: PERMISSION_TYPES.USER_MANAGER,
            },

            {
                display: 'Upload Patients',
                location: '/#!/patientUpload',
                permissionType: PERMISSION_TYPES.UPLOAD_PATIENTS,
            },

            {
                display: 'Upload Physicians',
                location: '/#!/physicianUpload',
                permissionType: PERMISSION_TYPES.UPLOAD_PHYSICIANS,
            },

            {
                display: 'SSO Configuration',
                location: '/#!/ssoConfiguration',
                permissionType: PERMISSION_TYPES.ENABLE_SSO_COMPANY_CONFIGURATION,
            },
        ],
    },
];

/**
 * Generates the navigation data for the provided user.
 * @param {object[]} data - The navigation data to recurse over.
 * @param {object} user - The user object
 * @returns {navigationData} - The permitted navigation items.
 */
const getNavData = R.curry((data, user) => {
    return R.reduce(
        (result, curr) => {
            if (curr.permissionType) {
                if (hasLength(curr.children)) {
                    // eslint-disable-next-line no-console
                    console.error(
                        'You have an error in your navigation definition.',
                        'You can either provide a permission or children, but not both.',
                        `Please check the configuration for ${curr.display}`
                    );
                    throw new Error('Navigation Definition error.');
                }
                if (isPermitted(curr.permissionType, user)) {
                    return R.append(curr, result);
                }
            } else if (hasLength(curr.children)) {
                const d = R.over(R.lensProp('children'), getNavData(R.__, user), curr);
                if (hasLength(d.children)) {
                    return R.append(d, result);
                }
            }

            return result;
        },
        [],
        data
    );
});

/**
 * Generates the navigation data for the provided user.
 * @param {object} user - The user object. Most likely from the token data.
 * @returns {object[]} - The permitted navigation items.
 */
function getNavigationForUser(user) {
    return getNavData(navigationData, user);
}

/**
 * Determines if a given navigation is active based on the path.
 * @param {string} pathToMatch - The current location path.
 * @param {object} nav - A single navigation item.
 * @returns {boolean}
 */
const isActive = R.curry((pathToMatch, nav) => {
    const noHashFn = R.replace(/^\/#!/, '');
    const matchWithoutHash = noHashFn(pathToMatch);
    return R.compose(
        R.ifElse(
            R.isNil,
            R.F,
            //prettier-ignore
            R.compose(
                Boolean,
                path => matchPath(matchWithoutHash, {path, exact: true}),
                noHashFn
            )
        ),
        R.prop('location')
    )(nav);
});

/**
 * Maps the navigation data and appends an `active` boolean prop if it is active.
 * @param {string} pathToMatch - The current location path.
 * @param {object[]} data - A collection of navigation items.
 * @returns {object[]} - The navigation collection.
 */
const mapLocationActive = R.curry((currentPath, data) =>
    R.map((nav) => {
        let parentActive = isActive(currentPath, nav, data);

        if (hasLength(nav.children)) {
            // eslint-disable-next-line no-param-reassign
            nav.children = R.map((child) => {
                const childActive = isActive(currentPath, child);

                if (childActive) {
                    parentActive = true;
                }

                return R.assoc('active', childActive, child);
            }, nav.children);
        }

        return R.assoc('active', parentActive, nav);
    }, data)
);

export { mapLocationActive };

export default getNavigationForUser;
