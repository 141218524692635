import PropTypes from 'prop-types';

const patientTherapyPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    patientId: PropTypes.string,
    patientTherapyId: PropTypes.string,
    name: PropTypes.string.isRequired,
    icd10: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            code: PropTypes.string,
            name: PropTypes.string,
            shortDescription: PropTypes.string,
        })
    ),
    icd9: PropTypes.shape({
        id: PropTypes.string,
        code: PropTypes.string,
        name: PropTypes.string,
    }),
    status: PropTypes.shape({
        id: PropTypes.number,
        start: PropTypes.string,
        end: PropTypes.string,
        endReason: PropTypes.string,
        optIn: PropTypes.bool,
    }),
});

export default patientTherapyPropType;
