import * as R from 'ramda';
import pathIsTruthy from './pathisTruthy';
import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils.js';

const dateOrDash = R.curry((path, data) => {
    if (pathIsTruthy(path, data)) {
        return formatDate(R.path(path, data));
    }

    return '--';
});


export default dateOrDash;
