import { useQuery } from 'react-query';
import * as R from 'ramda';
import CompanyService from 'App/services/CompanyService';
import { errorHandler } from 'App/utils';
import { useUserContext } from 'App/contexts/UserContext';

/**
 * Custom hook which make all the http call to fetch messaging subscription required to render patient detail page
 * @param {string} patientId
 * @returns {Object}
 */
function useGetTherapyCategories(patientTherapies) {
    const user = useUserContext();
    const companyId = user.active_company.ID;

    const getValuesAndSort = R.compose(R.sortBy(R.prop('name')), R.values);
    const fetchTherapyCategories = () => {
        return !patientTherapies?.length
            ? []
            : CompanyService.getTherapyCategories()
                  .then(getValuesAndSort)
                  .then((therapies) => {
                      const patientTherapyIds = patientTherapies.map((therapy) => therapy.id);
                      const filteredTherapies = therapies.filter(
                          (therapy) => patientTherapyIds.includes(therapy.id) || therapy.name === 'General'
                      );
                      return filteredTherapies;
                  });
    };

    const queryKey = [companyId, 'therapyCategories'];
    // const time = 5 * 60 * 1000;
    return useQuery(queryKey, fetchTherapyCategories, {
        // cacheTime: time,
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useGetTherapyCategories;
