import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import * as R from 'ramda';
import Select from 'react-select';

import useFetchEhrBranchSettings from 'App/components/Assessments/hooks/useFetchEhrBranchSettings';
import useFetchPatientIdentifiers from 'App/hooks/useFetchPatientIdentifiers';
import useGetPatientData from 'App/components/PatientProfile/hooks/useGetPatientData';
import { useUserContext } from 'App/contexts/UserContext';

import { patientRoute } from 'App/common/routeLookup';
import FlexCenter from 'App/components/styles/FlexCenter';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import EditBranchConfirmationModal from './EditBranchConfirmationModal';
import './PatientBranchStyles.scss';

const PatientBranch = () => {
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const patientId = searchParams.get('patient_id');
    const user = useUserContext();

    const { isPatientDataLoading, patientData } = useGetPatientData(patientId);
    const { data: patientIdentifiers } = useFetchPatientIdentifiers(patientId);
    const { data: branchSettings } = useFetchEhrBranchSettings();

    const [selectedBranch, setSelectedBranch] = useState();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const { branchName, divisionId, lastName, firstName, externalId, humanId } = patientData;

    /* get user branches */
    const branches = useMemo(
        () =>
            user.branches
                ? R.compose(
                      R.filter((branch) => branch.id !== divisionId), // Exclude the branch with the current divisionId
                      R.sortBy(R.prop('name')),
                      R.values
                  )(user.branches)
                : [],
        [user.branches, divisionId]
    );

    useEffect(() => {
        // this is required to exclude the current branch from the dropdown list
        setSelectedBranch(branches?.[0]);
    }, [divisionId, branches]);

    const getDisplayedId = () => {
        /* get ID to display based on the configuration */
        const displayedId = R.path(['company_permissions', 'DisplayedId'], user);

        const ehrId =
            displayedId === 'ehr_id'
                ? Object.values(patientIdentifiers || {}).find(
                      ({ identifierType }) => identifierType === branchSettings?.ehrPrimaryIdType
                  )?.identifier
                : undefined;

        const otherId =
            displayedId === 'other_id'
                ? Object.values(patientIdentifiers || {}).find(
                      ({ identifierType }) => identifierType === 'stm_other_id'
                  )?.identifier
                : undefined;

        const idDisplayMap = {
            external_id: externalId,
            human_id: humanId,
            ehr_id: ehrId,
            other_id: otherId,
        };
        return idDisplayMap[displayedId];
    };

    const goToPatientProfile = () => history.push(generatePath(patientRoute, { patientId }));

    const handleSubmit = () => setShowConfirmationModal(true);

    const renderBranchesDropdown = () => (
        <Row className="block-section">
            <Col>
                <span className="branch-type left">New Branch:</span>
            </Col>
            <Col md={10} style={{ width: '450px' }}>
                <Select
                    classNamePrefix="react-select"
                    data-testid="select-branch"
                    getOptionValue={R.prop('id')}
                    getOptionLabel={R.prop('name')}
                    name="selectBranch"
                    onChange={(option) => setSelectedBranch(option)}
                    options={branches}
                    placeholder=""
                    value={selectedBranch}
                />
            </Col>
        </Row>
    );

    if (isPatientDataLoading) {
        return (
            <FlexCenter>
                <TherigyLoadingSpinner />
            </FlexCenter>
        );
    }
    return (
        <div className="patient-branch">
            <h1 className="page-title">Change Patient Branch</h1>
            <Row className="block-section">
                <Col md={2} className="left-nav">
                    <br />
                    <h3>Notes:</h3>
                    <ol>
                        <li>This Patient may only be moved into another branch that matches.</li>
                        <li>
                            After changing the Patient&apos;s branch you will need to change your branch to view them or
                            search for the patient in the Find Patients tab.
                        </li>
                    </ol>
                    <br />
                    <br />
                </Col>
                <Col md={10}>
                    <div className="content">
                        <h2 className="patient-name">
                            {lastName}, {firstName} {getDisplayedId()}
                        </h2>
                        <p>
                            <Link to={generatePath(patientRoute, { patientId })}>[Back to patient profile]</Link>
                        </p>
                        <br />
                        <p className="branch-type">
                            Current Branch: <strong>{branchName}</strong>
                        </p>
                        {renderBranchesDropdown()}
                        <br />
                        <Button
                            aria-label="Submit"
                            bsClass="btn btn-primary"
                            className="submit-branch"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <Button aria-label="Cancel" bsClass="btn btn-default" onClick={goToPatientProfile}>
                            Cancel
                        </Button>
                        <br />
                        <br />
                        <hr />
                    </div>
                </Col>
            </Row>
            {showConfirmationModal && selectedBranch?.id && (
                <EditBranchConfirmationModal
                    goToPatientProfile={goToPatientProfile}
                    onHide={() => setShowConfirmationModal(false)}
                    patientId={patientId}
                    selectedBranchId={selectedBranch.id}
                />
            )}
        </div>
    );
};

export default PatientBranch;
