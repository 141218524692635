import styled from '@emotion/styled';

const PageTitle = styled('h1')`
    background-color: #fff;
    border-bottom: 2px solid rgb(207 215 223 / 25%);
    font-size: 22px;
    height: 45px;
    padding: 0 20px;
    line-height: 45px;
`;

export default PageTitle;
