import { useQuery } from 'react-query';

import { getTherapyMedications } from 'App/services/TherapyService';

function useFetchTherapyMedications(therapyId) {
    return useQuery(['therapy', therapyId, 'medications'], () => getTherapyMedications(therapyId), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchTherapyMedications;
