import React from 'react';
import { Helmet } from 'react-helmet';
import { HashRouter, Route, Switch, useRouteMatch } from 'react-router-dom';

import AngularRootReactComponent from '../../../angular-root';
import env from '../../components/config/config';
import { useAssessmentManagerAccess } from 'App/utils/hooks/useIsPermitted';
import RouteWithHeader from '@/app/components/RouteWithHeader';
import { NotFound } from '../errors';
import CloneAssessmentPage from './CloneAssessmentPage';

const propTypes = {};

const defaultProps = {};

function AssessmentManagerRouter() {
    //#region State
    const hasAmPageAccess = useAssessmentManagerAccess();
    const { path } = useRouteMatch();
    //#endregion

    if (hasAmPageAccess) {
        return (
            <HashRouter hashType="hashbang">
                <Switch>
                    <RouteWithHeader path={`${path}/:categoryId/clone/:assessmentId`} component={CloneAssessmentPage} />

                    <Route>
                        <Helmet>
                            <title>{env.catchPhrase}</title>
                        </Helmet>
                        <AngularRootReactComponent />
                    </Route>
                </Switch>
            </HashRouter>
        );
    }

    return <NotFound />;
}

AssessmentManagerRouter.propTypes = propTypes;
AssessmentManagerRouter.defaultProps = defaultProps;

export default AssessmentManagerRouter;
