import dayjs from 'dayjs';
import { Formik } from 'formik';
import { capitalize, isEmpty, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { patientRoute } from 'App/common/routeLookup';
import { useUserContext } from 'App/contexts/UserContext';
import { toDate } from 'App/services/DateService';
import PESService from 'App/services/PESService';
import {
    createOtherCondition,
    deleteOtherCondition,
    updateOtherCondition,
} from 'App/services/PatientOtherConditionService';
import { disablePatient, enablePatient, updateHighRiskReasons } from 'App/services/PatientService';
import findRelevantRxFill from 'App/utils/findRelevantRxFill';
import ResetButton from 'Lib/form/ResetButton';
import SubmitButton from 'Lib/form/SubmitButton';
import {
    otherMedicationsPropType,
    patientInsurancePropType,
    patientPropType,
    patientTherapyPropType,
    rxPropType,
} from 'Lib/types';

import FormButtonGroup from '../styles/FormButtonGroup';
import PageWrapper from '../styles/PageWrapper/PageWrapper';
import AllergiesForm from './components/AllergiesForm';
import ChangeBranch from './components/ChangeBranch';
import EmailAlertsForm from './components/EmailAlertsForm';
import EmergencyContactForm from './components/EmergencyContactForm';
import MedicalPlanForm from './components/MedicalPlanForm';
import OtherDiagnosesForm from './components/OtherDiagnosesForm';
import OtherMedicationsForm from './components/OtherMedicationsForm';
import PatientDemographicsForm from './components/PatientDemographicsForm';
import PatientInsuranceForm from './components/PatientInsuranceForm';
import TherapeuticCategoryForm from './components/TherapeuticCategory/TherapeuticCategoryForm';
import WelcomePacketForm from './components/WelcomePacketForm';
import { highRiskOptions, states } from './constants';
import {
    patientHighRiskReasonPropType,
    patientLabelPropType,
    patientMedicationRxPropType,
    patientOtherConditionPropType,
} from './types';
import { submitPatientTherapies } from './utils';

function convertToDropdownOption(input) {
    if (!input) return input;
    return {
        label: input,
        value: input,
    };
}

function removeNullsFromObject(obj) {
    return Object.fromEntries(
        Object.entries(obj).filter(
            // eslint-disable-next-line no-unused-vars
            ([_key, value]) => {
                return value !== null;
            }
        )
    );
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email('The email is not in the correct format'),
    mobilePhone: Yup.string().min(10, 'The mobile phone must be 10 digits'),
    homePhone: Yup.string().min(10, 'The home phone must be 10 digits'),
    workPhone: Yup.string().min(10, 'The work phone must be 10 digits'),

    therapies: Yup.array().min(1, 'Therapeutic category is required'),

    ecTelephone: Yup.string().min(10, 'The home phone must be 10 digits'),
    ecMobilePhone: Yup.string().min(10, 'The mobile phone must be 10 digits'),
});

async function submitHighRiskReasons(patientId, values) {
    const highRiskReasonStrings = values.highRiskReasons.map((reason) => reason.value);
    await updateHighRiskReasons(patientId, highRiskReasonStrings);
}

async function submitOtherIdentifier(patientId, values) {
    if (!values.otherId) {
        return PESService.deletePatientIdentifier(patientId, 'stm_other_id');
    }
    return PESService.upsertPatientIdentifiers(patientId, { Type: 'stm_other_id', Id: values.otherId });
}

const PatientForm = ({
    existingPatient,
    existingPatientLabels,
    existingPatientHighRiskReasons,
    existingPatientOtherId,
    existingPatientTherapies,
    existingPatientMedicationsRx,
    existingPatientRxList,
    existingPatientReferral,
    existingPatientOtherDiagnoses,
    existingPatientOtherMedications,
    existingPatientInsurance,
}) => {
    const history = useHistory();
    const user = useUserContext();

    function getInitialValues() {
        return {
            requiredOnly: false,

            ...(existingPatient && removeNullsFromObject(existingPatient)),
            firstName: existingPatient?.firstName || '',
            lastName: existingPatient?.lastName || '',
            birthDate: existingPatient
                ? dayjs.utc(existingPatient.birthDate).hour(12).minute(0).second(0).toDate()
                : null,
            gender: convertToDropdownOption(existingPatient?.gender && capitalize(existingPatient?.gender)),
            pronouns: convertToDropdownOption(existingPatient?.pronouns),
            genderIdentity: convertToDropdownOption(existingPatient?.genderIdentity),
            ethnicity: convertToDropdownOption(existingPatient?.ethnicity),

            pregnancyStatus: convertToDropdownOption(existingPatient?.pregnancyStatus),
            lactating: convertToDropdownOption(existingPatient?.lactating),

            city: existingPatient?.city || '',
            state: existingPatient ? states.find((s) => s.value === existingPatient.state) : null,
            zip: existingPatient?.zip || '',
            maritalStatus: convertToDropdownOption(existingPatient?.maritalStatus),
            status: existingPatient ? { ValueName: existingPatient.status } : null,

            preferredContactMethod: convertToDropdownOption(existingPatient?.preferredContactMethod),
            languageSpoken: convertToDropdownOption(existingPatient?.languageSpoken),

            height: existingPatient?.height ? String(existingPatient.height) : null,
            weight: existingPatient?.weight ? String(existingPatient.weight) : null,

            terminallyIll: convertToDropdownOption(existingPatient?.terminallyIll),
            smokerStatus: convertToDropdownOption(existingPatient?.smokerStatus),

            highRisk: existingPatient ? highRiskOptions.find((o) => o.value === existingPatient.highRisk) : null,
            highRiskReasons: existingPatientHighRiskReasons.map((hrr) => convertToDropdownOption(hrr.reason)),
            labels: existingPatientLabels,
            otherId: existingPatientOtherId,

            therapies: [...existingPatientTherapies],
            medications: existingPatientMedicationsRx.filter((patientMed) => !patientMed.status.end),

            rxs: existingPatientRxList.map((rxNumber) => ({
                ...rxNumber,
                rxFill: rxNumber.rxFill.length ? [findRelevantRxFill(rxNumber.rxFill)] : [],
            })),
            patientReferral: existingPatientReferral,

            noKnownAllergies: existingPatient?.noKnownAllergies === '1',
            otherAllergy: existingPatient?.otherAllergy.length ? existingPatient.otherAllergy.split(',') : [],

            noKnownMedicalConditions: existingPatient?.noKnownMedicalConditions === '1',
            otherCondition: [...existingPatientOtherDiagnoses],
            otherMedication: [...existingPatientOtherMedications],

            patientInsurance: existingPatientInsurance
                .filter((insurance) => !insurance.disabledOn)
                .map((insurance) => ({
                    ...insurance,
                    coverageType: convertToDropdownOption(insurance.coverageType),
                    payerSegment: convertToDropdownOption(insurance.payerSegment),
                    isIntegratedHighDeductable: convertToDropdownOption(insurance.isIntegratedHighDeductable),
                    coverageEffectiveDate: toDate(insurance.coverageEffectiveDate),
                    coverageEndDate: toDate(insurance.coverageEndDate),
                    isPharmacyBenefitsCarveOutFromMedical: convertToDropdownOption(
                        insurance.isPharmacyBenefitsCarveOutFromMedical
                    ),
                    disableInsurancePlan: false,
                })),

            ecRelationship: existingPatient?.ecRelationship ? { valueName: existingPatient.ecRelationship } : null,

            pccSendEmail: existingPatient?.pccSendEmail || false,
            pccId: existingPatient?.pccId ? { label: existingPatient.pcc, value: existingPatient.pccId } : null,

            welcomePacket: existingPatient?.welcomePacket || false,
            welcomePacketSent: toDate(existingPatient?.welcomePacketSent),
            welcomePacketReturned: toDate(existingPatient?.welcomePacketReturned),
        };
    }

    async function savePatient(values) {
        const patient = await submitPatient(values);
        await Promise.all([
            submitHighRiskReasons(patient.id, values),
            submitPatientLabels(patient.id, values),
            submitOtherIdentifier(patient.id, values),
            submitPatientTherapies(patient.id, values),
            submitPatientICD10s(patient.id, values),
            submitPatientPrescriptions(patient.id, values),
            submitPatientReferral(patient.id, values),
            submitPatientOtherCondition(patient.id, values),
            submitPatientOtherMedication(patient.id, values),
            submitPatientInsurance(patient.id, values),
        ]);
        return patient;
    }

    async function submitPatient(values) {
        const payload = {
            firstName: values.firstName,
            lastName: values.lastName,
            birthDate: values.birthDate,
            gender: values.gender.value,

            preferredName: values.preferredName,
            pronouns: values.pronouns?.value,
            genderIdentity: values.genderIdentity?.value,

            middleName: values.middleName,
            suffix: values.suffix,
            ethnicity: values.ethnicity?.value,

            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            pregnancyStatus: values.pregnancyStatus?.value,
            lactating: values.lactating?.value,

            city: values.city,
            state: values.state.value,
            maritalStatus: values.maritalStatus?.value,
            status: values.status?.ValueName,

            zip: values.zip,
            country: values.country,
            preferredContactMethod: values.preferredContactMethod?.value,
            languageSpoken: values.languageSpoken?.value,

            height: values.height || '',
            weight: values.weight || '',
            email: values.email,
            mobilePhone: values.mobilePhone,

            terminallyIll: values.terminallyIll?.value,
            smokerStatus: values.smokerStatus?.value,
            homePhone: values.homePhone,
            workPhone: values.workPhone,

            highRisk: values.highRisk?.value,
            pharmacy: values.pharmacy,
            ssnLastFour: values.ssnLastFour,
            externalId: values.externalId,

            medicalPlan: values.medicalPlan,
            remoteMedicalPlanId: values.remoteMedicalPlanId,
            pbm: values.pbm,
            remotePbmId: values.remotePbmId,

            noKnownAllergies: values.noKnownAllergies ? '1' : '0',
            allergy: values.allergy,
            otherAllergy: values.otherAllergy.join(','),

            noKnownMedicalConditions: values.noKnownMedicalConditions ? '1' : '0',

            ecFirstName: values.ecFirstName,
            ecLastName: values.ecLastName,
            ecTelephone: values.ecTelephone,
            ecMobilePhone: values.ecMobilePhone,
            ecRelationship: values.ecRelationship?.valueName,

            pccSendEmail: values.pccSendEmail,
            pccId: values.pccId?.value,

            welcomePacket: values.welcomePacket,
            welcomePacketSent: values.welcomePacketSent,
            welcomePacketReturned: values.welcomePacketReturned,
        };

        if (existingPatient) {
            return PESService.updatePatient(existingPatient.id, payload);
        }
        return PESService.createPatient(payload);
    }

    async function submitPatientLabels(patientId, values) {
        const labelsToDelete = existingPatientLabels.filter(
            (epl) => values.labels.findIndex((label) => label.id === epl.id) === -1
        );
        const labelsToAdd = values.labels.filter(
            (label) => existingPatientLabels.findIndex((epl) => epl.id === label.id) === -1
        );
        const deleteRequests = labelsToDelete.map((label) => PESService.deletePatientLabel(patientId, label.id));
        const addRequests = labelsToAdd.map((label) => PESService.addPatientLabel(patientId, label.label));
        const promises = [...deleteRequests, ...addRequests];
        await Promise.all(promises);
    }

    async function submitPatientICD10s(patientId, values) {
        return Promise.all(
            values.therapies.map((therapy) =>
                PESService.addRemovePatientICD10(patientId, therapy.id, {
                    patientICD10: therapy.icd10 || [],
                })
            )
        );
    }

    async function submitPatientPrescriptions(patientId, values) {
        values.rxs.map(async (rx) => {
            const res = await PESService.addPatientPrescriptions(patientId, [
                {
                    medicationId: rx.medication.id,
                    rxNumbers: [
                        {
                            ...omit(rx, ['medication', 'rxFill']),
                            physician: rx.physician && {
                                id: rx.physician.id,
                            },
                        },
                    ],
                },
            ]);
            rx.rxFill?.map((fill) => {
                if (fill.id) {
                    return PESService.updateRxFill(patientId, fill.id, fill);
                }
                return PESService.addRxFills(patientId, res[0].patientMedicationId, res[0].id, fill);
            });
        });
    }

    async function submitPatientReferral(patientId, values) {
        if (!existingPatientReferral && values.patientReferral) {
            return PESService.addPatientReferral(patientId).catch((e) => e);
        }
        if (existingPatientReferral && !values.patientReferral) {
            return PESService.deletePatientReferral(patientId).catch((e) => e);
        }
    }

    async function submitPatientOtherCondition(patientId, values) {
        const conditionsToDelete = existingPatientOtherDiagnoses.filter(
            (diagnosis) => values.otherCondition.findIndex((condition) => condition.id === diagnosis.id) === -1
        );
        const deleteRequests = conditionsToDelete.map((condition) => deleteOtherCondition(patientId, condition.id));
        const addUpdateRequests = values.otherCondition
            .filter((condition) => !!condition.text)
            .map((condition) => {
                if (condition.id) {
                    return updateOtherCondition(patientId, condition.id, { text: condition.text });
                }
                return createOtherCondition(patientId, condition);
            });
        const promises = [...deleteRequests, ...addUpdateRequests];
        await Promise.all(promises);
    }

    async function submitPatientOtherMedication(patientId, values) {
        const medicationsToDelete = existingPatientOtherMedications.filter(
            (existingMed) => values.otherMedication.findIndex((otherMed) => otherMed.id === existingMed.id) === -1
        );
        const deleteRequests = medicationsToDelete.map((medication) =>
            PESService.deletePatientOtherMedication(patientId, medication.id)
        );
        const addUpdateRequests = values.otherMedication
            .filter((medication) => !isEmpty(medication))
            .map((medication) => {
                if (medication.id) {
                    return PESService.updatePatientOtherMedication(patientId, medication.id, medication);
                }
                return PESService.createPatientOtherMedication(patientId, medication);
            });
        const promises = [...deleteRequests, ...addUpdateRequests];
        await Promise.all(promises);
    }

    async function submitPatientInsurance(patientId, values) {
        const existingMaxPosition = existingPatientInsurance.length
            ? Math.max(...existingPatientInsurance.map((i) => i.position))
            : 0;
        let newPositionCounter = existingMaxPosition;
        const promises = values.patientInsurance.map((insurance) => {
            const insurancePayload = {
                ...insurance,
                position: insurance.position || (newPositionCounter += 1),
                coverageType: insurance.coverageType?.value,
                payerSegment: insurance.payerSegment?.value,
                isIntegratedHighDeductable: insurance.isIntegratedHighDeductable?.value,
                isPharmacyBenefitsCarveOutFromMedical: insurance.isPharmacyBenefitsCarveOutFromMedical?.value,
                ...(insurance.disableInsurancePlan && {
                    disabledOn: dayjs.utc().format('YYYY/MM/DD H:mm:ss'),
                    disabledBy: user.id,
                }),
            };
            delete insurancePayload.disabledByUser;
            delete insurancePayload.disableInsurancePlan;
            if (insurance.id) {
                return PESService.updatePatientInsurance(patientId, insurance.id, insurancePayload);
            }
            return PESService.addPatientInsurance(patientId, { insurance: [insurancePayload] });
        });
        await Promise.all(promises);
    }

    async function handleDisablePatient(values) {
        const patient = await savePatient(values);
        await disablePatient(patient.id);
        goToPatientProfile(patient.id);
    }

    async function handleEnablePatient(values) {
        const patient = await savePatient(values);
        await enablePatient(patient.id);
        goToPatientProfile(patient.id);
    }

    function goToPatientProfile(patientId) {
        history.push(generatePath(patientRoute, { patientId }));
    }

    return (
        <div className="container">
            <PageWrapper>
                <Formik
                    enableReinitialize
                    initialValues={getInitialValues()}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        try {
                            const patient = await savePatient(values);
                            goToPatientProfile(patient.id);
                        } catch (error) {
                            console.error(error);
                            toast.error(error.response?.data?.message || error.toString());
                        }
                    }}
                >
                    {({ handleSubmit, submitCount, errors, values }) => (
                        <form onSubmit={handleSubmit}>
                            {!existingPatient && <ChangeBranch />}

                            <PatientDemographicsForm />

                            <TherapeuticCategoryForm />

                            <Row>
                                <Col md={6}>
                                    <MedicalPlanForm />
                                </Col>
                                <Col md={6}>
                                    <AllergiesForm />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <OtherDiagnosesForm />
                                </Col>
                                <Col md={6}>
                                    <OtherMedicationsForm />
                                </Col>
                            </Row>

                            <PatientInsuranceForm />

                            <EmergencyContactForm />

                            <Row>
                                <Col md={6}>
                                    <EmailAlertsForm />
                                </Col>
                                <Col md={6}>
                                    <WelcomePacketForm />
                                </Col>
                            </Row>

                            {submitCount > 0 && errors.therapies && (
                                <Alert bsStyle="danger" style={{ marginTop: 20 }}>
                                    <ul>
                                        <li>{errors.therapies}</li>
                                    </ul>
                                </Alert>
                            )}

                            <Row style={{ marginTop: 25 }}>
                                <Col md={6}>
                                    {existingPatient ? (
                                        <>
                                            This patient is part of <strong>{user.active_branch.Name}</strong> branch.
                                        </>
                                    ) : (
                                        <>
                                            This patient will be added to branch{' '}
                                            <strong>{user.active_branch.Name}</strong>.
                                        </>
                                    )}
                                </Col>

                                <Col md={6}>
                                    <FormButtonGroup>
                                        {existingPatient &&
                                            (!existingPatient.disabledBy ? (
                                                <Button bsStyle="danger" onClick={() => handleDisablePatient(values)}>
                                                    Disable Patient
                                                </Button>
                                            ) : (
                                                <Button bsStyle="success" onClick={() => handleEnablePatient(values)}>
                                                    Enable Patient
                                                </Button>
                                            ))}
                                        <ResetButton>Reset</ResetButton>
                                        <SubmitButton ignoreDirty>
                                            {existingPatient ? 'Save Patient' : 'Add Patient'}
                                        </SubmitButton>
                                    </FormButtonGroup>
                                </Col>
                            </Row>
                        </form>
                    )}
                </Formik>
            </PageWrapper>
        </div>
    );
};

PatientForm.propTypes = {
    existingPatient: patientPropType,
    existingPatientLabels: PropTypes.arrayOf(patientLabelPropType),
    existingPatientHighRiskReasons: PropTypes.arrayOf(patientHighRiskReasonPropType),
    existingPatientOtherId: PropTypes.string,
    existingPatientTherapies: PropTypes.arrayOf(patientTherapyPropType),
    existingPatientMedicationsRx: PropTypes.arrayOf(patientMedicationRxPropType),
    existingPatientRxList: PropTypes.arrayOf(rxPropType),
    existingPatientReferral: PropTypes.bool,
    existingPatientOtherDiagnoses: PropTypes.arrayOf(patientOtherConditionPropType),
    existingPatientOtherMedications: otherMedicationsPropType,
    existingPatientInsurance: PropTypes.arrayOf(patientInsurancePropType),
};
PatientForm.defaultProps = {
    existingPatient: null,
    existingPatientLabels: [],
    existingPatientHighRiskReasons: [],
    existingPatientOtherId: null,
    existingPatientTherapies: [],
    existingPatientMedicationsRx: [],
    existingPatientRxList: [],
    existingPatientReferral: false,
    existingPatientOtherDiagnoses: [],
    existingPatientOtherMedications: [{}],
    existingPatientInsurance: [],
};

export default PatientForm;
