import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

const propTypes = {
    onClick: PropTypes.func.isRequired,
};

const defaultProps = {};

function CancelButton({ onClick }) {
    return (
        <Button bsStyle="default" type="button" onClick={onClick}>
            Cancel
        </Button>
    );
}

CancelButton.propTypes = propTypes;
CancelButton.defaultProps = defaultProps;

export default CancelButton;
