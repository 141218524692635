import React, { useMemo } from 'react';
import * as R from 'ramda';
import useFetchMedicationsQuery from 'App/hooks/useFetchMedicationsQuery';
import PropTypes from 'prop-types';
import SelectField from 'Lib/form/SelectField';

const propTypes = {
    therapyId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
};

const defaultProps = {
    label: 'Medication',
    required: false,
};

function MedicationSelect({ onChange, therapyId, fieldName, label, required }) {
    const { isLoading, data: medications } = useFetchMedicationsQuery(therapyId);
    const medicationsData = useMemo(() => {
        return medications ? R.compose(R.sortBy(R.prop('name')), R.values)(medications) : [];
    }, [medications]);
    return (
        <SelectField
            required={required}
            fieldName={fieldName}
            options={medicationsData}
            label={label}
            getOptionValue={R.prop('id')}
            getOptionLabel={R.prop('name')}
            isClearable
            onChange={onChange}
            isLoading={isLoading}
        />
    );
}
MedicationSelect.propTypes = propTypes;
MedicationSelect.defaultProps = defaultProps;

export default MedicationSelect;
