import React from 'react';
import { Link } from 'react-router-dom';
import useFetchAlertsQuery from 'App/hooks/useFetchAlertsQuery';
import * as R from 'ramda';

const propTypes = {};

const defaultProps = {};

function AlertsCenterIcon() {
    const { data } = useFetchAlertsQuery();

    const count = R.propOr(0, 'count', data);

    return (
        <Link className="icon icon-envelope" to="/alerts-center">
            <i className="fas fa-bell" aria-label="Alert Center" />
            {count > 0 && (
                <span className="message-counter-box">
                    <span data-testid="alert-count" className="message-counter" aria-label="Alert Count">
                        {count}
                    </span>
                </span>
            )}
        </Link>
    );
}

AlertsCenterIcon.propTypes = propTypes;
AlertsCenterIcon.defaultProps = defaultProps;

export default AlertsCenterIcon;
