import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { getDefaultWorkQueueState } from './defaultWorkQueueState';
import SessionStorageService from 'App/services/SessionStorageService';
import { toDate } from 'App/services/DateService';

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

const defaultProps = {};
const defaultWorkQueueState = getDefaultWorkQueueState();

function WorkQueueProvider({ children }) {
    const getInitialValues = useCallback(() => {
        let storedFilter = SessionStorageService.getOnUser(`workQueue_filter`, true);
        if (storedFilter) {
            storedFilter = {
                ...storedFilter,
                dateEnd: toDate(storedFilter.dateEnd),
                dateStart: toDate(storedFilter.dateStart),
            };
        }
        const filter = storedFilter || defaultWorkQueueState.filterParams;
        return {
            ...defaultWorkQueueState,
            filterParams: {
                ...defaultWorkQueueState.filterParams,
                ...filter,
            },
        };
    }, []);

    return (
        <Formik initialValues={getInitialValues()}>
            {({ handleSubmit, resetForm }) => {
                return (
                    <Form onSubmit={handleSubmit} onReset={resetForm} className="work-queue-form">
                        {children}
                    </Form>
                );
            }}
        </Formik>
    );
}
WorkQueueProvider.propTypes = propTypes;
WorkQueueProvider.defaultProps = defaultProps;

export { WorkQueueProvider };
