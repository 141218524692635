import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import { UserChecker, UserContextProvider } from '@/app/contexts/UserContext';
import WithHeader from '@/app/components/WithHeader';

const createWrapper = (Component) => {
    const withHeader = (props) => (
        <WithHeader>
            <Component {...props} />
        </WithHeader>
    );

    withHeader.displayName = `withHeader(${Component.displayName})`;
    return withHeader;
};

const propTypes = {
    children: PropTypes.node,
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
};
const defaultProps = {
    children: null,
    component: null,
};

/**
 * Return a React Router Route with the user context and header.
 * @param children
 * @param component
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function RouteWithHeader({ children, component, ...props }) {
    if (component) {
        return <Route {...props}>{createWrapper(component)}</Route>;
    }
    return (
        <Route {...props}>
            <UserContextProvider>
                <UserChecker>
                    <WithHeader>{children}</WithHeader>
                </UserChecker>
            </UserContextProvider>
        </Route>
    );
}

RouteWithHeader.propTypes = propTypes;
RouteWithHeader.defaultProps = defaultProps;

export default RouteWithHeader;
