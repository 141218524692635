/** @ngInject */
function AddPatientConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.addPatient',
        url: '/addPatient',
        views: {
            '': 'addPatient',
            'pageTitle@^': {
                template: 'Add Patient: {{$ctrl.user.active_branch.Name}}',
            },
        },
        params: {
            patient: null,
            integration: null,
        },
        resolve: {
            enabled(user) {
                return !user.company_permissions.Pdx && user.active_branch.Privileges.AddPatient;
            },
        },
    });
}

export default AddPatientConfig;
