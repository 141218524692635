/** @ngInject */
function ReferralConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.referral',
        url: '/patient/:patientId/referral/:referralId',
        views: {
            '': 'referral',
            'pageTitle@^': {
                template: 'Referral',
            },
        },
        resolve: {
            patient($transition$, patientService) {
                const params = $transition$.params();
                return patientService.get(params.patientId).catch(() => {
                    return false;
                });
            },
        },
    });
}

export default ReferralConfig;
