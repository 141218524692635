import * as R from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

const propTypes = {
    questionGroup: PropTypes.shape({
        groupOrder: PropTypes.number,
        name: PropTypes.string,
        label: PropTypes.string,
        shouldDelete: PropTypes.bool,
    }).isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    questionGroupList: PropTypes.array.isRequired,
    updateQuestionGroup: PropTypes.func.isRequired,
};
const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    questionGroupList: [],
};

function QuestionGroupInput({ questionGroupList, questionGroup, updateQuestionGroup }) {
    return (
        <Row>
            <Col md={2}>
                <FormGroup>
                    <FormControl
                        type="number"
                        min={0}
                        placeholder="Position"
                        aria-label={`group order ${questionGroup.name}`}
                        value={questionGroup.groupOrder}
                        onChange={(e) => {
                            const groupOrder = Number(e.target.value);
                            updateQuestionGroup(R.assoc('groupOrder', groupOrder, questionGroup));
                        }}
                    />
                </FormGroup>
            </Col>

            <Col md={4}>
                <FormGroup>
                    <CreatableSelect
                        options={questionGroupList}
                        value={questionGroup}
                        aria-label={`group name ${questionGroup.name}`}
                        onChange={(option) => {
                            updateQuestionGroup(
                                R.compose(R.assoc('name', option.label), R.assoc('label', option.label))(questionGroup)
                            );
                        }}
                    />
                </FormGroup>
            </Col>

            <Col md={2}>
                <Checkbox
                    checked={questionGroup.shouldDelete || false}
                    aria-label={`group delete check ${questionGroup.name}`}
                    onChange={() => {
                        updateQuestionGroup(R.over(R.lensProp('shouldDelete'), R.not, questionGroup));
                    }}
                >
                    Delete
                </Checkbox>
            </Col>
        </Row>
    );
}

QuestionGroupInput.propTypes = propTypes;
QuestionGroupInput.defaultProps = defaultProps;

export default QuestionGroupInput;
