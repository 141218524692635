import PropTypes from 'prop-types';
import React from 'react';

import SafeHtmlOnly from 'App/components/SafeHtmlOnly/SafeHtmlOnly';

const SafeHtmlCell = ({ value }) => {
    return <SafeHtmlOnly>{value}</SafeHtmlOnly>;
};

SafeHtmlCell.propTypes = {
    value: PropTypes.string.isRequired,
};

export default SafeHtmlCell;
