import { stripAllHtml } from 'App/utils';
import * as R from 'ramda';

/* This is Adapter to format ajax input from old API to useful format */
/** @ngInject */
function PrescriberAdapterService(orderByFilter, SortWeekDaysComparator) {
    /* jshint validthis: true */
    const vm = this;

    vm.doNormalise = normalise;
    vm.doPrepare = prepare;
    vm.doReverse = reverse;

    /* Sorting of the weekdays */
    function sortAvailability(availability) {
        return orderByFilter(availability, 'physician_best_day_to_call', false, SortWeekDaysComparator);
    }

    function prepareTimeForSubmission(avail) {
        const submitAvail = {};
        let dayIndex;

        function toMeridian(hour) {
            return hour >= 13 ? hour - 12 : hour;
        }

        function getPeriod(hour) {
            return hour >= 12 ? 'pm' : 'am';
        }

        function timeDefault(time) {
            if (time < 10) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                time = `0${time}`;
            }
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-restricted-globals
            return isNaN(time) ? '00' : time;
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax
        for (dayIndex in avail) {
            if (Object.prototype.hasOwnProperty.call(avail, dayIndex)) {
                const dayObj = avail[dayIndex];
                const dayName = dayObj.name;

                if (dayObj.enabled) {
                    submitAvail[dayName] = {};
                    submitAvail[dayName].enabled = dayObj.enabled;
                    submitAvail[dayName].start_hour = timeDefault(toMeridian(dayObj.startHour));
                    submitAvail[dayName].start_minute = timeDefault(dayObj.startMinute);
                    submitAvail[dayName].end_hour = timeDefault(toMeridian(dayObj.endHour));
                    submitAvail[dayName].end_minute = timeDefault(dayObj.endMinute);
                    submitAvail[dayName].end_time = getPeriod(dayObj.endHour);
                    submitAvail[dayName].start_time = getPeriod(dayObj.startHour);
                } else {
                    submitAvail[dayName] = {};
                    submitAvail[dayName].enabled = '';
                    submitAvail[dayName].start_hour = '';
                    submitAvail[dayName].start_minute = '';
                    submitAvail[dayName].end_hour = '';
                    submitAvail[dayName].end_minute = '';
                    submitAvail[dayName].end_time = '';
                    submitAvail[dayName].start_time = '';
                }
            }
        }
        return submitAvail;
    }

    /* Map of API name differences of output vs input */

    /* jshint validthis: true */
    const mapPhysicianFieldNames = {
        physician_first_name: 'first_name',
        physician_last_name: 'last_name',
        physician_fax_number: 'fax_number',
        physician_phone_number: 'office_number',
        physician_group_id: 'group_id',
        physician_address: 'address_1',
        physician_address_2: 'address_2',
        physician_city: 'city',
        physician_state: 'state',
        physician_zip_code: 'zip',
        physician_contact_person_first_name: 'contact_first_name',
        physician_contact_person_last_name: 'contact_last_name',
        physician_contact_person_fax_number: 'contact_fax',
        physician_contact_person_phone_number: 'contact_phone',
    };

    function sanitizeNotes(Obj) {
        return R.over(R.lensProp('notes'), stripAllHtml, Obj);
    }

    function prepareFieldsForSubmission(Obj) {
        const newSubmissionObj = {};

        /* Looping through all object to submit and moving values to new object
         * with fields that are recognised by create input */
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax
        for (const key in Obj) {
            /* Standard lookup to verify its legit key of  object */
            if (Object.prototype.hasOwnProperty.call(Obj, key)) {
                /* Match of map field */
                if (mapPhysicianFieldNames[key]) {
                    /* Add using new key */
                    newSubmissionObj[mapPhysicianFieldNames[key]] = Obj[key];
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-continue
                    continue;
                }
                /* No map match use same key */
                newSubmissionObj[key] = Obj[key];
            }
        }
        /* Return newly created Object for submission */
        return sanitizeNotes(newSubmissionObj);
    }

    function reverse(Obj) {
        const result = {};
        /* Looping through all object to submit and moving values to new object
         * with fields that are recognised by create input */
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax
        for (const key in Obj) {
            /* Standard lookup to verify its legit key of  object */
            if (Object.prototype.hasOwnProperty.call(Obj, key)) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-restricted-syntax
                for (const k in mapPhysicianFieldNames) {
                    /* Match of map field */
                    if (mapPhysicianFieldNames[k] === key) {
                        result[k] = Obj[key];
                        break;
                    } else if (
                        // eslint-disable-next-line
                        mapPhysicianFieldNames[
                            Object.keys(mapPhysicianFieldNames)[Object.keys(mapPhysicianFieldNames).length - 1]
                        ] === mapPhysicianFieldNames[k]
                    ) {
                        result[key] = Obj[key];
                    }
                }
            }
        }
        return result;
    }

    function prepare(Obj) {
        let submit = angular.copy(Obj);
        submit = prepareFieldsForSubmission(submit);
        submit.availability = prepareTimeForSubmission(submit.availability);

        return submit;
    }

    function normalise(Obj) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        Obj.availability = sortAvailability(Obj.availability);

        return Obj;
    }
}

export default PrescriberAdapterService;
