import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    id: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    name: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    label: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    type: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    placeholderText: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onChange: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    value: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    invalid: PropTypes.string,
};
function PatientInsuranceFormField(props) {
    const {
        id,
        name,
        label,
        type,
        placeholderText,
        onChange,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        onBlur,
        value,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        required,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        disabled,
        invalid,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        errorMessage,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        className,
    } = props;

    return (
        <FormGroup>
            <ControlLabel htmlFor={id} className={invalid ? 'invalid-insurance-field field-required' : className}>
                {label}
            </ControlLabel>
            <FormControl
                required={required}
                id={id}
                name={name}
                type={type}
                placeholder={placeholderText || label}
                onChange={onChange}
                value={value}
                invalid={invalid}
                onBlur={onBlur}
                disabled={disabled}
                className={invalid && 'invalid-insurance-form'}
            />
            {invalid ? <span className="error-message-insurance">{errorMessage}</span> : null}
        </FormGroup>
    );
}

PatientInsuranceFormField.propTypes = propTypes;
export default PatientInsuranceFormField;
