import PESService from '../../../services/PESService';

/** @ngInject */
function PatientLabelsController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.patientId && ctrl.patientId) {
            return PESService.getPatientLabels(ctrl.patientId).then((res) => {
                ctrl.patientLabels = Object.values(res);
            });
        }
    }
}

export default PatientLabelsController;
