function TherigyTrackedTable() {
    return {
        restrict: 'A',
        priority: -1,
        require: 'ngForm',
        controller: TherigyTrackedTableController,
    };
}

function TherigyTrackedTableController(_, $scope, $parse, $attrs) {
    const self = this;
    let dirtyCellsByRow = [];
    let invalidCellsByRow = [];

    init();

    function init() {
        const setter = $parse($attrs.therigyTrackedTable).assign;
        setter($scope, self);
        $scope.$on('$destroy', () => {
            setter(null);
        });

        self.reset = reset;
        self.isCellDirty = isCellDirty;
        self.setCellDirty = setCellDirty;
        self.setCellInvalid = setCellInvalid;
        self.untrack = untrack;
    }

    function getCellsForRow(row, cellsByRow) {
        return _.find(cellsByRow, (entry) => {
            return entry.row === row;
        });
    }

    function isCellDirty(row, cell) {
        const rowCells = getCellsForRow(row, dirtyCellsByRow);
        return rowCells && rowCells.cells.indexOf(cell) !== -1;
    }

    function reset() {
        dirtyCellsByRow = [];
        invalidCellsByRow = [];
        setInvalid(false);
    }

    function setCellDirty(row, cell, isDirty) {
        setCellStatus(row, cell, isDirty, dirtyCellsByRow);
    }

    function setCellInvalid(row, cell, isInvalid) {
        setCellStatus(row, cell, isInvalid, invalidCellsByRow);
        setInvalid(invalidCellsByRow.length > 0);
    }

    function setCellStatus(row, cell, value, cellsByRow) {
        let rowCells = getCellsForRow(row, cellsByRow);
        if (!rowCells && !value) {
            return;
        }

        if (value) {
            if (!rowCells) {
                rowCells = {
                    row,
                    cells: [],
                };
                cellsByRow.push(rowCells);
            }
            if (rowCells.cells.indexOf(cell) === -1) {
                rowCells.cells.push(cell);
            }
        } else {
            _.remove(rowCells.cells, (item) => {
                return cell === item;
            });
            if (rowCells.cells.length === 0) {
                _.remove(cellsByRow, (item) => {
                    return rowCells === item;
                });
            }
        }
    }

    function setInvalid(isInvalid) {
        self.$invalid = isInvalid;
        self.$valid = !isInvalid;
    }

    function untrack(row) {
        _.remove(invalidCellsByRow, (item) => {
            return item.row === row;
        });
        _.remove(dirtyCellsByRow, (item) => {
            return item.row === row;
        });
        setInvalid(invalidCellsByRow.length > 0);
    }
}

export default TherigyTrackedTable;
