import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import SelectField from 'Lib/form/SelectField';
import SubmitButton from 'Lib/form/SubmitButton';
import { TextField } from 'Lib/form/TextField';
import { TextareaField } from 'Lib/form/TextareaField';
import { queryClient } from 'Lib/queryClient';

import Table from 'App/components/Table';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import PESService, { NOTE_TYPE_TO_SUMMARY_NOTE_TYPE } from 'App/services/PESService';
import { stripAllHtml } from 'App/utils';

import CreatedByCell from './CreatedByCell';
import DateFormatCell from './DateFormatCell';
import SafeHtmlCell from './SafeHtmlCell';
import summaryNotePropType from './types/summaryNotePropType';

const typeOptions = Object.entries(NOTE_TYPE_TO_SUMMARY_NOTE_TYPE).map((entry) => ({
    value: entry[0],
    label: entry[1],
}));

const columns = [
    {
        Header: 'Note Body',
        accessor: 'body',
        Cell: SafeHtmlCell,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: 'Created By',
        accessor: 'createdByUser',
        Cell: CreatedByCell,
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: 'Last Edited On',
        accessor: 'createdOn',
        Cell: DateFormatCell,
        disableFilters: true,
        disableSortBy: true,
    },
];

const SummaryNoteEditModal = ({ onClose, patientId, existingNote }) => {
    const [editMode, setEditMode] = useState(!existingNote);

    return (
        <Modal show onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {!existingNote && 'Add A New Note'}
                    {existingNote && (editMode ? 'Edit Note' : 'View Note')}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    ...existingNote,
                    body: stripAllHtml(existingNote?.body),
                    subject: stripAllHtml(existingNote?.subject),
                    type: typeOptions.find((opt) => String(existingNote?.type) === opt.value) || typeOptions[1],
                }}
                onSubmit={async (values) => {
                    await PESService.createSummaryNote({
                        patientId,
                        noteBody: values.body || '',
                        noteSubject: values.subject || '',
                        noteType: values.type.value,
                        parentNoteId: existingNote?.parentNoteId,
                    });
                    queryClient.invalidateQueries(['summaryNotes', patientId]);
                    onClose();
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <TextField fieldName="subject" label="Subject" disabled={!!existingNote} />
                            <TextareaField fieldName="body" label="Note" disabled={!editMode} />
                            <SelectField
                                fieldName="type"
                                label="Type"
                                options={typeOptions}
                                isDisabled={!!existingNote}
                            />

                            {existingNote && (
                                <div>
                                    <h4>Note History</h4>
                                    <Table columns={columns} data={existingNote.history} />
                                </div>
                            )}
                        </Modal.Body>

                        <Modal.Footer>
                            <FormButtonGroup>
                                <Button onClick={onClose}>{editMode && !!existingNote ? 'Cancel' : 'Close'}</Button>
                                {!editMode && (
                                    <Button bsStyle="primary" onClick={() => setEditMode(true)}>
                                        Edit
                                    </Button>
                                )}
                                {editMode && <SubmitButton ignoreDirty>Save</SubmitButton>}
                            </FormButtonGroup>
                        </Modal.Footer>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

SummaryNoteEditModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    patientId: PropTypes.string.isRequired,
    existingNote: summaryNotePropType,
};
SummaryNoteEditModal.defaultProps = {
    existingNote: null,
};

export default SummaryNoteEditModal;
