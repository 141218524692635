/**
 * The fallback used when no other error catches.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { isProd, supportEmail, supportPhoneNumber, isUAT, isStaging, isQA } from 'App/components/config/config';
import styled from '@emotion/styled';
import ErrorMessage from './ErrorMessage';
import * as R from 'ramda';

const DevError = styled('div')`
    padding: 10px 20px;
    color: #f44336;
`;

const propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    componentStack: PropTypes.string,
};

const defaultProps = {
    error: {
        message: 'An unknown error has occurred.',
    },
    componentStack: '',
};

function ErrorFallback({ error, componentStack }) {
    return (
        <>
            <ErrorMessage>
                <h3>An unknown error has occurred. Please try again later.</h3>
                <p>
                    For immediate assistance, contact Tech Support by phone at{' '}
                    <a href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</a> or via email at{' '}
                    <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                </p>
            </ErrorMessage>

            {[isProd, isStaging, isQA, isUAT].some(R.identity) ? null : (
                <DevError>
                    <h4>{error.message}</h4>
                    <h2>Component Stack</h2>
                    <pre>{componentStack}</pre>
                </DevError>
            )}
        </>
    );
}

ErrorFallback.propTypes = propTypes;
ErrorFallback.defaultProps = defaultProps;

export default ErrorFallback;
