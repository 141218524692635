import { useQuery } from 'react-query';

import { getHighRiskReasons } from 'App/services/PatientService';
import { errorHandler } from 'App/utils';

function useFetchPatientHighRiskReasons(patientId) {
    return useQuery(['patient', patientId, 'highRiskReasons'], () => getHighRiskReasons(patientId), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchPatientHighRiskReasons;
