import React, { useEffect, useState } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useUserContext } from 'App/contexts/UserContext';

const AccessView = () => {
    const user = useUserContext();
    const [branchName, setBranchName] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);

    const BranchNameText = (
        <a id="current-branch" href="#!/accessView">
            {branchName}
        </a>
    );

    const BranchNameTooltip = <Tooltip id="tooltip">{branchName}</Tooltip>;

    useEffect(() => {
        if (user && user.active_branch && user.active_branch.Name) {
            setBranchName(user.active_branch.Name);
            if (user.active_branch.Name.length > 20) {
                setShowTooltip(true);
            }
        }
    }, [user]);

    return (
        <span className="branch" id="access-view">
            Branch:&nbsp;
            {(showTooltip && (
                <OverlayTrigger placement="bottom" overlay={BranchNameTooltip}>
                    {BranchNameText}
                </OverlayTrigger>
            )) ||
                BranchNameText}
        </span>
    );
};

AccessView.propTypes = {
};

export default AccessView;
