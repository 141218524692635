import React from 'react';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import { CheckboxField } from 'Lib/form/CheckboxField';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';

const PredictiveModelFilter = () => {
    const patientPredictiveModelEnabled = useIsPermitted(PERMISSION_TYPES.PATIENT_PREDICTIVE_MODEL);

    return (
        patientPredictiveModelEnabled && (
            <div className="filter form-group">
                <ControlLabel htmlFor="predictiveModelRisk" className="control-label filter-header">
                    Predictive Model Filter
                </ControlLabel>
                <CheckboxField
                    id="predictiveModelRisk"
                    fieldName="filterParams.predictiveModelRisk"
                    label="Predictive Model Patient"
                />
            </div>
        )
    );
};

export default PredictiveModelFilter;
