import dayjs from 'dayjs';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import TherigyErrorBoundary from 'App/components/errors/TherigyErrorBoundary';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { DatePickerField } from 'Lib/form/DatePickerField';
import SubmitButton from 'Lib/form/SubmitButton';
import { TextareaField } from 'Lib/form/TextareaField';
import { TextField } from 'Lib/form/TextField';

import SpaceBetweenContainer from '../SpaceBetweenContainer';

const TherapeuticCategoryOptInModal = ({ onClose, onSubmit, therapyName }) => {
    return (
        <Modal show onHide={onClose}>
            <TherigyErrorBoundary>
                <Modal.Header>
                    <Modal.Title>Clinical Care Opt-in</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        therapy: {
                            name: therapyName,
                            status: {
                                start: null,
                                optInNotes: '',
                            },
                        },
                    }}
                    onSubmit={(values) => {
                        const submissionValues = {
                            therapy: {
                                status: {
                                    ...values.therapy.status,
                                    optIn: true,
                                },
                            },
                        };
                        onSubmit(submissionValues);
                        onClose();
                    }}
                >
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Row>
                                    <Col md={6}>
                                        <TextField fieldName="therapy.name" label="Therapeutic Category" disabled />
                                    </Col>
                                    <Col md={6}>
                                        <DatePickerField
                                            fieldName="therapy.status.start"
                                            label="Care Opt-in Date"
                                            placeholder="YYYY/MM/DD"
                                            required
                                            maxDate={dayjs().toDate()}
                                        />
                                    </Col>
                                </Row>
                                <TextareaField
                                    fieldName="therapy.status.optInNotes"
                                    label={
                                        <SpaceBetweenContainer>
                                            <div>Notes</div>
                                            <div>{values.therapy.status.optInNotes.length}/200</div>
                                        </SpaceBetweenContainer>
                                    }
                                    placeholder="Write notes here"
                                    maxLength={200}
                                />
                            </Modal.Body>

                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <SubmitButton>Done</SubmitButton>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    )}
                </Formik>
            </TherigyErrorBoundary>
        </Modal>
    );
};

TherapeuticCategoryOptInModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    therapyName: PropTypes.string.isRequired,
};

export default TherapeuticCategoryOptInModal;
