import React from 'react';
import PropTypes from 'prop-types';
import hasLength from 'App/utils/hasLength';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { Checkbox, DropdownButton } from 'react-bootstrap';

const propTypes = {
    column: PropTypes.shape({
        setFilter: PropTypes.func,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/forbid-prop-types
        filterValue: PropTypes.array,
    }).isRequired,
};

const defaultProps = {};

const StyledUl = styled('ul')`
    list-style: none;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
`;

function multiStatusFilter(rows, columnIds, filterValue) {
    if (hasLength(filterValue)) {
        return rows.filter((row) => {
            return filterValue.includes(row.original.status);
        });
    }
    return rows;
}

const options = ['Complete', 'Hold', 'New', 'No Go', 'Pending'];

function MultiStatusFilter({ column: { setFilter, filterValue } }) {
    const handleChange = (option) => {
        if (Array.isArray(filterValue)) {
            const index = filterValue.findIndex(R.equals(option));

            if (index >= 0) {
                setFilter(R.remove(index, 1, filterValue));
            } else {
                setFilter(R.append(option, filterValue));
            }
        } else {
            setFilter([option]);
        }
    };

    const appliedFilters = Array.isArray(filterValue) ? filterValue : [];

    return (
        <DropdownButton title="Filter by Status" bsSize="small" id="referral-table-filter-status">
            <StyledUl>
                {options.map((option) => {
                    return (
                        <li key={option}>
                            <Checkbox onChange={() => handleChange(option)} checked={appliedFilters.includes(option)}>
                                {option}
                            </Checkbox>
                        </li>
                    );
                })}
            </StyledUl>
        </DropdownButton>
    );
}

MultiStatusFilter.propTypes = propTypes;
MultiStatusFilter.defaultProps = defaultProps;

export { multiStatusFilter };

export default MultiStatusFilter;
