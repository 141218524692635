/** @ngInject */
function PatientMessagingHistoryController(_, $uibModal, dateService, NgTableParams, pesService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.showMessage = showMessage;

    function showMessage(message, sentOn, reason) {
        // ctrl.loading = false;
        // ctrl.close({ $value: null })
        $uibModal.open({
            component: 'patientmessageViewModal',
            size: 'md',
            backdrop: 'static',
            resolve: {
                modalData() {
                    return {
                        message,
                        sentOn,
                        reason,
                        patient: ctrl.patient,
                    };
                },
            },
        });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (
            changes.resolve &&
            ctrl.resolve.patientMessagingData &&
            ctrl.resolve.patientMessagingData.patient &&
            ctrl.resolve.patientMessagingData.patientMessagingSubscription
        ) {
            ctrl.loading = true;
            ctrl.patientMessagingSubscriptionResponse = angular.copy(
                ctrl.resolve.patientMessagingData.patientMessagingSubscription
            );
            ctrl.patientMessagingSubscription = {
                status: ctrl.patientMessagingSubscriptionResponse.unsubscribedOn ? 'Opted-out' : 'Enrolled',
                date: ctrl.patientMessagingSubscriptionResponse.unsubscribedOn
                    ? dateService.addTimeDesignator(ctrl.patientMessagingSubscriptionResponse.unsubscribedOn)
                    : dateService.addTimeDesignator(ctrl.patientMessagingSubscriptionResponse.subscribedOn),
                reason: ctrl.patientMessagingSubscriptionResponse.reason,
            };
            ctrl.patient = angular.copy(ctrl.resolve.patientMessagingData.patient);
            return pesService
                .getPatientMessages(ctrl.patient.id)
                .then((res) => {
                    ctrl.tableParams = new NgTableParams(
                        {
                            count: 10,
                            sorting: {
                                date_time: 'desc',
                            },
                        },
                        {
                            counts: [],
                            dataset: res,
                        }
                    );

                    if (ctrl.tableParams.total() > ctrl.tableParams.count()) {
                        ctrl.tableParams.settings({
                            counts: [5, 10, 25],
                        });
                    }
                    return res;
                })
                .finally(() => {
                    ctrl.loading = false;
                });
        }
    }
}

export default PatientMessagingHistoryController;
