import { assignUnassignedMedication as slsAssignUnassignedMedication } from './TherapyService';

/** @ngInject */
function TherapyService($http, $httpParamSerializerJQLike, _, urlBuilderService) {
    const vm = this;

    vm.addTherapyStatusNote = addTherapyStatusNote;
    vm.assignUnassignedMedication = assignUnassignedMedication;
    vm.query = query;
    vm.getHistory = getHistory;
    vm.getPatientTherapyHistory = getPatientTherapyHistory;
    vm.getTherapyStatusNotes = getTherapyStatusNotes;
    vm.getTherapy = getTherapy;
    vm.postPatientTherapy = postPatientTherapy;
    vm.getCategories = getCategories;

    function addTherapyStatusNote(patient, therapyStatusId, note) {
        return $http({
            method: 'post',
            url: urlBuilderService.build('therapyStatusNotes', {
                patient,
                therapy: '',
                status: therapyStatusId,
                note: '',
            }),
            data: $httpParamSerializerJQLike({
                note,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }).then((res) => {
            return res.data;
        });
    }

    function getCategories() {
        const url = urlBuilderService.get('categories');
        return (
            $http
                .get(url)
                .then((res) => {
                    return urlBuilderService.normalize(res);
                })
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((res) => {
                    if (res.status === 404) {
                        return [];
                    }
                })
        );
    }

    function getTherapy() {
        const url = urlBuilderService.build('therapy', { therapy: '' });
        return (
            $http
                .get(url)
                .then((res) => {
                    return urlBuilderService.normalize(res);
                })
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((res) => {
                    if (res.status === 404) {
                        return [];
                    }
                })
        );
    }

    function getHistory(patient, therapy) {
        return (
            $http({
                method: 'get',
                url: urlBuilderService.build('therapyHistory', {
                    patient,
                    therapy,
                    history: '',
                }),
            })
                .then((res) => {
                    return res.data;
                })
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((res) => {
                    if (res.status === 404) {
                        return [];
                    }
                })
        );
    }

    function getPatientTherapyHistory(patientId) {
        const url = urlBuilderService.build({
            patient: patientId,
            therapy: '',
            history: '',
        });

        return (
            $http
                .get(url)
                .then((res) => {
                    return res.data;
                })
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((res) => {
                    if (res.status === 404) {
                        return [];
                    }
                })
        );
    }

    function getTherapyStatusNotes(patient, therapyStatusId) {
        return $http({
            method: 'get',
            url: urlBuilderService.build('therapyStatusNotes', {
                patient,
                therapy: '',
                status: therapyStatusId,
                notes: '',
            }),
        }).then((res) => {
            return res.data;
        });
    }

    function postPatientTherapy(patientId, therapyId, medications, therapyDiagnoses) {
        const diagnoses = Array.isArray(therapyDiagnoses) ? therapyDiagnoses : [];
        const data = { medications, diagnoses };
        const url = urlBuilderService.build('patientTherapy', {
            patient: patientId,
            therapy: therapyId,
        });

        return $http.post(url, data).then((res) => {
            return urlBuilderService.normalize(res);
        });
    }

    function assignUnassignedMedication(patientId, therapyId, payload) {
        return slsAssignUnassignedMedication(patientId, therapyId, payload);
    }

    function query() {
        return $http({
            method: 'get',
            url: urlBuilderService.build('getAvailableTherapies', {
                user: '',
                therapy: '',
            }),
        })
            .then((res) => {
                _.forEach(res.data, (value) => {
                    urlBuilderService.format(value);
                });
                return res.data;
            })
            .catch((err) => {
                if (err.status === 404) {
                    return [];
                }
                return err;
            });
    }
}

export default TherapyService;
