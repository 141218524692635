import { useFormikContext } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import InvalidMessage from '../styles/InvalidMessage';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    name: PropTypes.string.isRequired,
};
const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    name: '',
};

function ErrorMessage({ name }) {
    const formik = useFormikContext();

    const path = R.split('.', name);

    if (R.allPass([R.path(['errors', ...path])])(formik)) {
        return <InvalidMessage>{R.path(['errors', ...path], formik)}</InvalidMessage>;
    }
    return null;
}

ErrorMessage.propTypes = propTypes;
ErrorMessage.defaultProps = defaultProps;

export default ErrorMessage;
