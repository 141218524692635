import CompanyService from '../../../services/CompanyService';

/** @ngInject */
function PrescriberModalComponentController(__env, daysOfWeekService, prescriberService, statesService) {
    /* jslint validate:true */
    const ctrl = this;

    /* This is where prescriber lives */
    ctrl.displayAvailability = false;
    ctrl.prescriber = {};
    ctrl.states = statesService.states;

    ctrl.$onInit = $onInit;
    ctrl.cancel = cancel;
    ctrl.cdnURL = __env.cdnURL;
    ctrl.defaultTime = getDefault;
    ctrl.submit = submit;
    ctrl.toggleDisplayAvailability = toggleDisplayAvailability;
    ctrl.stateFilter = stateFilter;

    function $onInit() {
        ctrl.prescriber.availability = emptyAvailability();
        ctrl.availableSpeciality = CompanyService.getCustomMenu('physician_specialty');

        /* Edit mode, pull up the set prescriber */
        if (ctrl.resolve.prescriberId) {
            prescriberService.get(ctrl.resolve.prescriberId).then((data) => {
                ctrl.prescriber = data;

                if (data.availability) {
                    ctrl.prescriber.availability = getPhysicianSelections(data.availability);
                    toggleDisplayAvailability();
                }
            });
        }
    }

    function submit() {
        return prescriberService.post(ctrl.prescriber).then((data) => {
            successSubmit(data);
        }, failSubmit);
    }

    function successSubmit(data) {
        ctrl.close({ $value: data });
    }
    function failSubmit() {
        // eslint-disable-next-line no-console
        console.log('something went terribly wrong');
    }
    function cancel() {
        ctrl.dismiss({ $value: 'cancel' });
    }

    function toggleDisplayAvailability() {
        ctrl.displayAvailability = !ctrl.displayAvailability;
    }
    function emptyAvailability() {
        const week = [];

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in daysOfWeekService) {
            if (Object.prototype.hasOwnProperty.call(daysOfWeekService, prop)) {
                const day = daysOfWeekService[prop];

                week.push(ctrl.defaultTime(day));
            }
        }
        return week;
    }
    /* Comparing WeekDays to User Selection */
    function getPhysicianSelections(availability) {
        const newAvailability = [];

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in daysOfWeekService) {
            if (Object.prototype.hasOwnProperty.call(daysOfWeekService, prop)) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-var,vars-on-top
                var day = daysOfWeekService[prop];

                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-loop-func
                const index = availability.findIndex((arr) => {
                    return arr.physician_best_day_to_call === day;
                });
                if (index !== -1) {
                    newAvailability.push({
                        name: day,
                        startHour:
                            availability[index].start_time === 'pm'
                                ? parseInt(availability[index].start_hour, 10) + 12
                                : parseInt(availability[index].start_hour, 10),
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line radix
                        startMinute: parseInt(availability[index].start_minute),
                        endHour:
                            availability[index].end_time === 'pm'
                                ? parseInt(availability[index].end_hour, 10) + 12
                                : parseInt(availability[index].end_hour, 10),
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line radix
                        endMinute: parseInt(availability[index].end_minute),
                        enabled: true,
                    });
                } else {
                    newAvailability.push(ctrl.defaultTime(day));
                }
            }
        }
        return newAvailability;
    }
    function getDefault(day) {
        return {
            name: day,
            startHour: 9,
            startMinute: 0,
            endHour: 17,
            endMinute: 0,
            enabled: false,
        };
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function stateFilter(state, viewValue) {
        if (typeof state === 'object' && state.name && state.abbreviation) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            state = `${state.abbreviation} - ${state.name}`;
            return state.substr(0, viewValue.length).toLowerCase() === viewValue.toLowerCase();
        }
    }
}

export default PrescriberModalComponentController;
