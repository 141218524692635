import { useQuery } from 'react-query';
import { errorHandler } from 'App/utils';
import AuthService from 'App/services/AuthService';

function useFetchBranchesQuery() {
    return useQuery(['branches'], AuthService.fetchUserBranches, {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchBranchesQuery;
