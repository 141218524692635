/**
 * @ngdoc directive
 * @name resetPassword
 * @module components.auth
 *
 * @description
 *
 * This is the resetPassword component responsible for handling resetting a password.
 **/
/** @ngInject */
function ResetPasswordController($http, $httpParamSerializerJQLike, $location, $state) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.submit = submit;

    function $onInit() {
        ctrl.formData = {
            next: '',
            resetPassword: true,
        };
        ctrl.success = null;

        const { resetToken } = $location.search();
        return $http
            .get('/stm/reset_password.php', {
                params: {
                    resetToken,
                },
            })
            .then((res) => {
                ctrl.formData.userId = res.data;
            })
            .catch((err) => {
                ctrl.resetLinkInvalid = err.data.type === 'reset_link_invalid';
                ctrl.error = err.data.message;
            });
    }

    function submit() {
        ctrl.success = null;

        if (ctrl.formData.password !== ctrl.formData.password2) {
            ctrl.resetPassword.password2.$setValidity('mismatch', false);
        } else {
            ctrl.resetPassword.password2.$setValidity('mismatch', true);
        }

        if (ctrl.resetPassword.$invalid) {
            return;
        }

        ctrl.loading = true;

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return $http({
            method: 'POST',
            url: '/stm/reset_password.php',
            data: $httpParamSerializerJQLike(ctrl.formData),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        })
            .then((res) => {
                ctrl.formData.password = undefined;
                ctrl.formData.password2 = undefined;
                ctrl.success = res.data.message;
                return $state.go('auth.login', {
                    passwordReset: true,
                });
            })
            .catch((err) => {
                ctrl.error = err.data.message;
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }
}

export default ResetPasswordController;
