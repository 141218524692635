import React from 'react';
import { Helmet } from 'react-helmet';
import { useAlertManagerAccess } from 'App/utils/hooks/useIsPermitted';
import env from '../config/config';
import { NotFound } from '../errors';
import Main from '../styles/Main/Main';
import PageTitle from '../styles/PageTitle/PageTitle';
import PageWrapper from '../styles/PageWrapper/PageWrapper';
import FinancialAssistance from './FinancialAssistance';

const propTypes = {};

const defaultProps = {};

function AlertsManagerPage() {
    //#region State
    const hasAlertManagerAccess = useAlertManagerAccess();
    //#endregion

    if (hasAlertManagerAccess) {
        return (
            <Main>
                <Helmet>
                    <title>Alerts Manager - {env.catchPhrase}</title>
                </Helmet>

                <PageTitle>Alerts Manager</PageTitle>
                <PageWrapper>{hasAlertManagerAccess && <FinancialAssistance />}</PageWrapper>
            </Main>
        );
    }

    return <NotFound />;
}

AlertsManagerPage.propTypes = propTypes;
AlertsManagerPage.defaultProps = defaultProps;

export default AlertsManagerPage;
