import * as R from 'ramda';
import isExpired from 'App/utils/isExpired';
import ActivityMedicationService from 'App/services/ActivityMedicationService';
import hasLength from 'App/utils/hasLength';
import _ from 'lodash';
import { queryClient } from 'Lib/queryClient';
import propIsFalsy from 'App/utils/propIsFalsy';

/**
 * Simple complement of the isExpired function
 * @param statusDate
 * @returns {boolean}
 */
function isStatusDateActive(statusDate) {
    if (statusDate) {
        return !isExpired(statusDate);
    }
    return true;
}

/**
 * Single point of logic to calculate the display medication name
 * @param {string} dispensedMedicationName
 * @param {string} displayName
 * @param {string} medicationName
 * @returns
 */
function calculateDisplayName(dispensedMedicationName, displayName, medicationName, rx, rxFillId = 0) {
    let medName = medicationName || '';
    if (dispensedMedicationName && dispensedMedicationName.trim() !== '') {
        medName = dispensedMedicationName;
    } else if (displayName && displayName.trim() !== '') {
        medName = displayName;
    }

    if (rx && rx.rxNumber) {
        medName += ` - ${rx.rxNumber}`;
        if (rx?.rxFill?.length) {
            const recentFill = rxFillId
                ? _.find(rx.rxFill, (fill) => {
                      return fill.id === rxFillId;
                  })
                : _.orderBy(rx.rxFill, ['createdOn', 'fillNumber'], ['desc', 'desc'])[0];
            medName += ` - ${recentFill.fillNumber}`;
        }
    }

    return medName;
}

function getSelectedOptions(patientId, activityId, medicationAssociations, patientMedications) {
    return R.compose(
        //#region Remove standalone association when RX version is included
        /**
         * Sometimes a medication association is added before an RX is set on the patient.
         * Later, the user adds the medciation association that now has the display name of
         * "Medication - RxNumber". Now there are two associations for the same med and RX.
         *
         * This will remove the "Medication" entry and keep the "Medication - RxNumber" entry.
         */
        R.reduce((result, [, associations]) => {
            if (associations.length > 1) {
                return R.compose(
                    R.concat(result),
                    R.last,
                    R.tap(([rejected]) => {
                        Promise.all(
                            R.map(
                                (option) => {
                                    return ActivityMedicationService.deleteMedicationAssociation(
                                        patientId,
                                        activityId,
                                        option.id
                                    ).then((m) => {
                                        const existingMeds = queryClient.getQueryData([
                                            'medicationAssociations',
                                            patientId,
                                            activityId,
                                        ]);
                                        queryClient.setQueryData(
                                            ['medicationAssociations', patientId, activityId],
                                            R.dissoc(m.id, existingMeds)
                                        );
                                    });
                                },

                                rejected
                            )
                        );
                    }),
                    R.partition(R.propEq(null, 'rxNumberId'))
                )(associations);
            }
            return R.concat(result, associations);
        }, []),
        R.toPairs,
        R.groupBy(R.prop('patientMedicationId')),
        //#endregion
        R.map((selectedOption) => {
            const { id, medication, patientMedicationId, rxNumberId, rxFillId, rx } = selectedOption;
            const dispensedMedicationName = R.prop('dispensedMedication')(rx);
            const patientMedication = patientMedications ? R.propOr({}, patientMedicationId)(patientMedications) : {};
            const medicationDisplayName = R.pathOr('', ['medication', 'displayName'])(patientMedication);

            const formattedSelectedOption = {
                id,
                patientMedicationId,
                rxNumberId,
                rxFillId: rxFillId || 0,
                displayName: calculateDisplayName(
                    dispensedMedicationName,
                    medicationDisplayName,
                    medication.medicationName,
                    rx,
                    rxFillId
                ),
                dispensedMedication: R.prop('dispensedMedication')(rx),
            };

            return formattedSelectedOption;
        }),
        R.values
    )(medicationAssociations);
}

function formatKey(patientMedication) {
    return `${patientMedication.patientMedicationId}${patientMedication.rxNumberId || ''}${
        patientMedication.rxFillId || ''
    }`;
}

const isActiveMedStatus = R.compose(isStatusDateActive, R.path([0, 'end']));

const isActiveRx = R.compose(isStatusDateActive, R.prop('end'));

const getRxReducer = R.curry((patientMedication, patientMedicationsAndRxs, result, rx) => {
    if (rx.rxNumber) {
        const { id, medication } = patientMedication;
        const matchingMedRx = R.find(R.propEq(rx.id, 'id'), patientMedicationsAndRxs[medication.id].rxs);
        const dispensedMedicationName = R.prop('dispensedMedication', matchingMedRx);
        const rxMedDisplayName = R.prop('displayName', patientMedicationsAndRxs[medication.id]);
        const medicationName = R.prop('name', medication);

        const patientMedicationRx = {
            displayName: calculateDisplayName(dispensedMedicationName, rxMedDisplayName, medicationName, rx),
            patientMedicationId: id,
            name: medication.name,
            rxNumberId: rx.id,
            rxFillId: rx?.rxFill?.length
                ? _.orderBy(rx.rxFill, ['createdOn', 'fillNumber'], ['desc', 'desc'])[0].id
                : 0,
            active: isActiveRx(rx),
        };

        patientMedicationRx.key = formatKey(patientMedicationRx);

        return R.append(patientMedicationRx, result);
    }
    return result;
});

const getAvailableRx = R.curry((patientMedication, patientMedicationsAndRxs) => {
    const { rxs } = patientMedication;

    if (hasLength(rxs)) {
        return R.reduce(getRxReducer(patientMedication, patientMedicationsAndRxs), [], rxs);
    }
    return [];
});

const getAvailableMed = R.curry((patientMedicationsAndRxs, result, patientMedication) => {
    const { id, medication, status } = patientMedication;

    const medicationObj = {
        displayName: medication.displayName,
        patientMedicationId: id,
        name: medication.name,
        active: isActiveMedStatus(status),
        key: id,
    };

    const rx = getAvailableRx(patientMedication, patientMedicationsAndRxs);
    /**
     *  Mark all RX entries as inactive if medication is inactive
     */
    //prettier-ignore
    const processedRx = R.ifElse(
        R.always(!medicationObj.active),
        R.map(R.assoc('active', false)),
        R.identity
    )(rx);

    /**
     * Include medicationObj in the result if there is no rx or if all the RXs are ended
     * but the medication is still active.
     */
    const updatedMedicationObj = R.ifElse(
        R.either(R.isEmpty, R.allPass([R.all(propIsFalsy('active')), R.always(medicationObj.active)])),
        R.always([medicationObj]),
        R.always([])
    )(rx);

    //prettier-ignore
    return R.compose(
        R.concat(result),
        R.concat(updatedMedicationObj)
    ) (processedRx);
});

function getAvailableOptions(patientMedications, patientMedicationsAndRxs) {
    return R.compose(
        R.sortBy(R.propOr(R.prop('name'), 'displayName')),
        R.reduce(getAvailableMed(patientMedicationsAndRxs), []),
        R.values
    )(patientMedications);
}

function groupOptions(options) {
    return R.compose(
        R.sortBy(R.prop('label')),
        R.values,
        R.mapObjIndexed((opts, label) => {
            return {
                label,
                /**
                 * Grouping by Active can cause these to get out of order.
                 */
                options: R.sortBy(R.compose(R.toLower, R.prop('displayName')), opts),
            };
        }),
        R.groupBy((o) => (o.active ? 'Active' : 'Inactive'))
    )(options);
}

export {
    calculateDisplayName,
    formatKey,
    getAvailableMed,
    getAvailableOptions,
    getAvailableRx,
    getRxReducer,
    getSelectedOptions,
    groupOptions,
    isActiveMedStatus,
    isActiveRx,
    isStatusDateActive,
};
