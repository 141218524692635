import { useEffect } from 'react';
import { errorHandler } from 'App/utils';
import {
    fetchPatientMedicationHistory,
    fetchPatientMedicationRxHistory,
} from 'App/components/PatientProfile/PatientProfile.utils';
import useProfileDataState from './useProfileDataState';

/**
 * Custom hook which API call to fetch medication and rx history
 * @param {string} patientId
 * @param {string} medicationId
 * @returns {Object}
 */
function useMedicationHistory(patientId, medicationId) {
    const {
        data: medicationHistory,
        setData: setMedications,
        isLoading,
        triggerRefetch: triggerHistoryRefetch,
        refetch,
        setIsLoading,
    } = useProfileDataState([]);
    const { data: rxHistory, setData: setRxHistory } = useProfileDataState([]);

    useEffect(() => {
        setIsLoading(true);
        const promiseArray = [
            fetchPatientMedicationHistory(patientId, medicationId),
            fetchPatientMedicationRxHistory(patientId, medicationId),
        ];

        Promise.all(promiseArray)
            .then(([medicationHistoryRes, rxHistoryRes]) => {
                setMedications(medicationHistoryRes);
                setRxHistory(rxHistoryRes);
            })
            .catch(errorHandler)
            .finally(() => {
                setIsLoading(false);
            });
    }, [patientId, medicationId, refetch, setIsLoading, setMedications, setRxHistory]);

    return {
        medicationHistory,
        rxHistory,
        isLoading,
        triggerHistoryRefetch,
    };
}

export default useMedicationHistory;
