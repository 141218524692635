import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import AccordionWrapper from 'App/components/AccordionWrapper/AccordionWrapper';
import {
    generateReferralLink,
    getSectionVisibility,
    toggleSectionVisibility,
} from 'App/components/PatientProfile/PatientProfile.utils.js';
import SelectColumnFilter from 'App/components/Table/SelectColumnFilter';
import Table from 'App/components/Table/Table';
import { FaInfoCircle, FaPlus } from 'react-icons/fa';
import AddPatientActivityModal from 'App/components/PatientProfile/components/PatientActivities/AddPatientActivityModal';
import EditActivityDueDateModal from './components/EditActivityDueDateModal';
import { Button } from 'react-bootstrap';
import IconWrapper from 'App/common/styles/IconWrapper';
import ActivityNotesModal from './components/ActivityNotesModal';
import './PatientActivities.scss';
import { DateCell, NameCell, NotesCell, NoteStatusDateCell } from './components/ActivitiesColumns';

export const ActivityTypes = {
    PendingActivities: 0,
    PastActivities: 1,
    DeletedActivities: 2,
};

const ActivityTitle = {
    [ActivityTypes.PendingActivities]: 'Pending Activities',
    [ActivityTypes.PastActivities]: 'Past Activities',
    [ActivityTypes.DeletedActivities]: 'Deleted Activities',
};

const ActivityVisibility = {
    [ActivityTypes.PendingActivities]: 'pendingActivities',
    [ActivityTypes.PastActivities]: 'pastActivities',
    [ActivityTypes.DeletedActivities]: 'deletedActivities',
};

const propTypes = {
    id: PropTypes.string.isRequired,
    activityType: PropTypes.number.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    activities: PropTypes.arrayOf(PropTypes.object).isRequired,
    divisionId: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    therapy: PropTypes.arrayOf(PropTypes.object),
    refetchActivities: PropTypes.func,
    isActivityLoading: PropTypes.bool,
    includeNewActivities: PropTypes.bool,
};

const defaultProps = {
    divisionId: '',
    therapy: [],
    refetchActivities: () => {},
    isActivityLoading: false,
    includeNewActivities: false,
};

const PatientActivities = ({
    id,
    activityType,
    activities,
    divisionId,
    therapy,
    refetchActivities,
    isActivityLoading,
    includeNewActivities,
}) => {
    const [filteredActivities, setActivities] = useState([]);
    const [showAddPatientActivityModal, setShowAddPatientActivityModal] = useState(false);
    const [showDueDateModal, setShowDueDateModal] = useState(false);
    const [currentActivity, setCurrentActivity] = useState(null);
    const [showActivityStatusModal, setShowActivityStatusModal] = useState(false);

    const openActivityModal = (activity) => {
        setCurrentActivity(activity);
        setShowDueDateModal(true);
    };

    const hideActivityModal = (refetch) => {
        if (refetch) {
            refetchActivities();
        }
        setShowDueDateModal(false);
        setCurrentActivity(null);
    };

    const openActivityNoteModal = (activity) => {
        setCurrentActivity(activity);
        setShowActivityStatusModal(true);
    };

    const hideActivityNoteModal = (refetch) => {
        if (refetch) {
            refetchActivities();
        }
        setShowActivityStatusModal(false);
        setCurrentActivity(null);
    };

    const columns = useMemo(() => {
        return [
            {
                Header: 'Activity Name',
                accessor: 'name',
                Cell: NameCell({ divisionId, generateReferralLink }),
                style: { width: '25%' },
            },
            {
                Header: 'Type',
                accessor: 'typeLabel',
                Filter: SelectColumnFilter,
                style: { width: ActivityTypes.DeletedActivities === activityType ? '12%' : '18%' },
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: { width: ActivityTypes.DeletedActivities === activityType ? '15.75%' : '19%' },
            },
            ...(ActivityTypes.PendingActivities === activityType || ActivityTypes.DeletedActivities === activityType
                ? [
                      {
                          Header: 'Due Date',
                          accessor: 'dateDue',
                          Cell: NoteStatusDateCell({ activityType, openModal: openActivityModal }),
                          disableFilters: true,
                          style: { width: ActivityTypes.DeletedActivities === activityType ? '15.75%' : '19%' },
                      },
                  ]
                : []),
            ...(ActivityTypes.PastActivities === activityType
                ? [
                      {
                          Header: 'Completed Date',
                          accessor: 'dateCompleted',
                          Cell: DateCell,
                          disableFilters: true,
                          style: { width: ActivityTypes.DeletedActivities === activityType ? '15.75%' : '19%' },
                      },
                  ]
                : []),
            {
                Header: 'Notes Status',
                accessor: 'noteStatus',
                Cell: NoteStatusDateCell({ activityType, openModal: openActivityNoteModal }),
                disableFilters: true,
                style: { width: ActivityTypes.DeletedActivities === activityType ? '15.75%' : '19%' },
            },
            ...(ActivityTypes.DeletedActivities === activityType
                ? [
                      {
                          Header: 'Actions',
                          Cell: NotesCell({ openModal: openActivityNoteModal }),
                          disableFilters: true,
                          style: { width: ActivityTypes.DeletedActivities === activityType ? '15.75%' : '19%' },
                      },
                  ]
                : []),
        ];
    }, [divisionId, activityType]);

    const getInitialState = useCallback(() => {
        switch (activityType) {
            case ActivityTypes.PendingActivities:
                return {
                    sortBy: [
                        {
                            id: 'dateDue',
                            desc: false,
                        },
                    ],
                    pageSize: 5,
                };
            case ActivityTypes.PastActivities:
                return {
                    sortBy: [
                        {
                            id: 'dateCompleted',
                            desc: true,
                        },
                    ],
                    pageSize: 5,
                };
            case ActivityTypes.DeletedActivities:
                return {
                    sortBy: [
                        {
                            id: 'dateDue',
                            desc: true,
                        },
                    ],
                    pageSize: 5,
                };
            default:
                return {};
        }
    }, [activityType]);

    useEffect(() => {
        if (Array.isArray(activities)) {
            const filteredActivitiesArr = activities.filter(
                (it) => parseInt(it.activityStatusLookupId, 10) === activityType
            );
            if (includeNewActivities) {
                const newActivities = activities.filter((it) => parseInt(it.activityStatusLookupId, 10) === 3);
                filteredActivitiesArr.push(...newActivities);
            }
            setActivities(filteredActivitiesArr);
        }
    }, [activities, activityType, includeNewActivities]);

    return (
        <>
            <AccordionWrapper
                title={ActivityTitle[activityType]}
                initialVisible={getSectionVisibility(ActivityVisibility[activityType])}
                ActionElement={
                    activityType === ActivityTypes.PendingActivities ? (
                        <div className="add-create-action">
                            <Button
                                bsStyle="link"
                                bsSize="sm"
                                aria-label="Add Activity"
                                title="Add Activity"
                                onClick={() => setShowAddPatientActivityModal(true)}
                            >
                                <FaPlus /> Add Activity
                            </Button>
                        </div>
                    ) : null
                }
                onClick={() => toggleSectionVisibility(ActivityVisibility[activityType])}
            >
                {filteredActivities.length > 0 ? (
                    <Table
                        className={`activities-${id}-${activityType}`}
                        data-testid={`activities-${id}-${activityType}`}
                        columns={columns}
                        data={filteredActivities}
                        placeholderText="No available activities"
                        initialState={getInitialState()}
                        pageSizeOptions={[5, 10, 25]}
                        isLoading={isActivityLoading}
                    />
                ) : (
                    <div>
                        <IconWrapper>
                            <FaInfoCircle />
                        </IconWrapper>
                        <span>There are currently no activities to display.</span>
                    </div>
                )}
            </AccordionWrapper>
            {showAddPatientActivityModal && (
                <div>
                    <AddPatientActivityModal
                        patientId={id}
                        onHide={() => {
                            hideActivityModal(true);
                            setShowAddPatientActivityModal(false);
                        }}
                        patientTherapies={therapy}
                    />
                </div>
            )}
            {showActivityStatusModal && (
                <div>
                    <ActivityNotesModal
                        activity={currentActivity}
                        patientId={id}
                        show={showActivityStatusModal}
                        onHide={hideActivityNoteModal}
                    />
                </div>
            )}
            {showDueDateModal && (
                <EditActivityDueDateModal
                    show={showDueDateModal}
                    onHide={hideActivityModal}
                    activity={currentActivity}
                />
            )}
        </>
    );
};

PatientActivities.propTypes = propTypes;
PatientActivities.defaultProps = defaultProps;

export default PatientActivities;
