/** @ngInject */
function AssignmentManagerController(
    _,
    $scope,
    $state,
    assignmentManagerService,
    authService,
    moment,
    ngTableEventsChannel,
    NgTableParams,
    workQueueService,
    sessionStorageService
) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;

    ctrl.assignTasks = assignTasks;
    ctrl.doChangeAssignee = doChangeAssignee;
    ctrl.onChangeFilter = onChangeFilter;
    ctrl.selectOneRow = selectOneRow;
    $scope.selectAllRows = selectAllRows;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (ctrl.user && changes.user) {
            ctrl.user = angular.copy(ctrl.user);

            ctrl.colsList = [
                {
                    field: 'selector',
                    headerTemplateURL: 'headerCheckbox.html',
                    show: true,
                    title: '',
                },
                {
                    title: 'Patient ID',
                    show: true,
                    field: ctrl.user.company_permissions.DisplayedId,
                    filter:
                        ctrl.user.company_permissions.DisplayedId === 'external_id'
                            ? {
                                  filter_external_id: '/text-filter.html',
                              }
                            : {
                                  filter_human_id: '/text-filter.html',
                              },
                    sortable: ctrl.user.company_permissions.DisplayedId,
                },
                {
                    title: 'Patient Name',
                    field: 'patient_name',
                    filter: {
                        filter_patient_name: '/text-filter.html',
                    },
                    show: true,
                    sortable: 'patient_name',
                },
                {
                    title: 'Patient Labels',
                    field: 'patient_labels',
                    filter: {
                        filter_patient_labels: '/text-filter.html',
                    },
                    show: true,
                },
                {
                    title: 'Medication',
                    field: 'medication',
                    filter: {
                        filter_medication: '/text-filter.html',
                    },
                    show: true,
                },
                {
                    title: 'Activity Due Date',
                    field: 'date_due',
                    filter: {
                        filter_date_due_search: '/text-filter.html',
                    },
                    show: true,
                    sortable: 'date_due',
                },
                {
                    title: 'Activity Name',
                    field: 'activity_name',
                    filter: {
                        filter_activity_name: '/text-filter.html',
                    },
                    show: true,
                    sortable: 'activity_name',
                },
                {
                    title: 'Patient DOB',
                    field: 'patient_dob',
                    filter: {
                        filter_patient_dob: '/text-filter.html',
                    },
                    show: true,
                    sortable: 'patient_dob',
                },
                {
                    title: 'Activity Status',
                    field: 'activity_status',
                    filter: {
                        filter_activity_status: '/text-filter.html',
                    },
                    show: true,
                    sortable: 'activity_status',
                },
                {
                    title: 'Assigned To',
                    field: 'assigned_to',
                    filter: {
                        filter_assigned_to: '/text-filter.html',
                    },
                    show: true,
                    sortable: 'assigned_to',
                },
            ];

            return assignmentManagerService
                .getUsers()
                .then((res) => {
                    ctrl.users = angular.copy(res);
                    return res;
                })
                .finally(() => {
                    ctrl.loadingUsers = false;
                });
        }
    }

    /**
     * @summary Initializes the component
     */
    function $onInit() {
        const rangeType = sessionStorageService.getOnUser(`workQueue_selectedFilter`);

        if (rangeType && rangeType.dateRange) {
            ctrl.dateRange = rangeType.dateRange;
        } else {
            ctrl.dateRange = 'this_week';
        }

        ctrl.loading = true;
        ctrl.tableParams = new NgTableParams(
            {
                count: 25,
                filter: workQueueService.getDefaultFilter(ctrl.dateRange),
            },
            {
                counts: [10, 25, 50],
                defaultSort: 'asc',
                filterDelay: 250,
                getData(ref) {
                    return getRows(ref);
                },
            }
        );
        applyTableParams(ctrl.tableParams._params);

        $scope.checkboxes = {
            checked: null,
            items: {},
        };

        ngTableEventsChannel.onAfterReloadData((res) => {
            $scope.workQueueData = res.data;

            ctrl.checkedItems = _.size($scope.checkboxes.items);

            if (ctrl.checkedItems === 0) {
                $scope.checkboxes.checked = null;
            } else {
                $scope.checkboxes.checked = ctrl.checkedItems === $scope.workQueueData.length;
            }

            ctrl.loading = false;
        }, $scope);
    }

    function applyTableParams(tableParams) {
        if (tableParams instanceof Object && tableParams.constructor === Object) {
            if (!(tableParams.filter instanceof Object) || !tableParams.filter.constructor === Object) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                tableParams.filter = {};
            }
            // Set the ng-table
            _.forEach(workQueueService.getTableParams(), (param) => {
                ctrl.tableParams[param](tableParams[param]);
            });

            // Set the UI
            _.forEach(workQueueService.getUiParams(), (param) => {
                ctrl[param] = tableParams.filter[param];
            });
        }
    }

    function assignTasks() {
        const activityIds = [];

        angular.forEach($scope.checkboxes.items, (item, activityId) => {
            if (item === true) {
                activityIds.push(activityId);
            }
        });

        if (activityIds.length === 0) {
            return;
        }

        ctrl.saving = true;
        ctrl.loading = true;

        let branches = _.keys(ctrl.branches);

        if (ctrl.assignee && ctrl.assignee.branches) {
            branches = ctrl.assignee.branches;
        }

        const params = {
            allCategories: ctrl.all_categories,
            allTypes: ctrl.all_types,
            assignee: ctrl.assignee.id,
            assignedToMe: ctrl.assignedToMe,
            branches,
            categories: _.keys(ctrl.group_ids),
            count: ctrl.tableParams.count(),
            dateEnd: moment(ctrl.dateEnd).format('YYYY-MM-DD'),
            dateStart: moment(ctrl.dateStart).format('YYYY-MM-DD'),
            filters: _.keys(ctrl.activityTypes),
            highRisk: ctrl.highRisk,
            onlyMine: ctrl.onlyMine,
            onlyPcc: ctrl.assignee.pccOnly,
            page: ctrl.tableParams.page(),
            search: workQueueService.createSearch(ctrl.tableParams.filter()),
            sorting: ctrl.tableParams.sorting(),
            tasks: activityIds,
            type: ctrl.dateRange,
        };

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return assignmentManagerService
            .assignTasks(params)
            .then((res) => {
                ctrl.tableParams.reload();
                return res;
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }

    function getRows(ref) {
        ctrl.loading = true;
        const branchList = ctrl.user.branches;
        let branches = ctrl.all_branches ? _.map(branchList, 'id') : _.keys(ctrl.branches);
        if (branches.length === 0) {
            branches = _.map(branchList, 'id');
        }
        if (ctrl.assignee) {
            branches = Array.isArray(ctrl.assignee.branches) ? _.intersection(ctrl.assignee.branches, branches) : [];
        }

        if (branches.length === 0) {
            ref.total(0); // trigger redraw
            $scope.workQueueData = [];
            ctrl.loading = false;
            return [];
        }

        const params = {
            allCategories: ctrl.all_categories,
            allTypes: ctrl.all_types,
            assignedToMe: ctrl.assignedToMe,
            branches,
            categories: _.keys(ctrl.group_ids),
            count: ctrl.tableParams.count(),
            dateEnd: moment(ctrl.dateEnd).format('YYYY-MM-DD'),
            dateStart: moment(ctrl.dateStart).format('YYYY-MM-DD'),
            filters: _.keys(ctrl.activityTypes),
            highRisk: ctrl.highRisk,
            onlyMine: ctrl.onlyMine,
            page: ctrl.tableParams.page(),
            search: workQueueService.createSearch(ctrl.tableParams.filter()),
            sorting: ctrl.tableParams.sorting(),
            type: ctrl.dateRange,
        };

        return workQueueService
            .get(params)
            .then((res) => {
                ref.total(res.count); // trigger redraw
                $scope.workQueueData = res.data;
                return res.data;
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }

    function doChangeAssignee() {
        if (ctrl.assignee) {
            if (ctrl.assignee.pccOnly) {
                ctrl.activityTypes = angular.copy({
                    PCC: true,
                });

                angular.extend(ctrl.tableParams.filter(), {
                    activityTypes: ctrl.activityTypes,
                    allTypes: false,
                });
                applyTableParams();
            }

            if (ctrl.assignee.branches) {
                ctrl.visibleBranches = angular.copy(ctrl.assignee.branches);
                ctrl.tableParams.reload();
            }
        } else {
            ctrl.tableParams.reload();
        }
    }

    function onChangeFilter(filter) {
        angular.extend(ctrl.tableParams.filter(), filter);
        applyTableParams(ctrl.tableParams._params);
    }

    /**
     * Sets all rows to the passed in value
     * @param value - True if checked, false otherwise
     */
    function selectAllRows() {
        if ($scope.checkboxes.checked === null) {
            $scope.checkboxes.checked = true;
        } else {
            $scope.checkboxes.checked = null;
        }

        angular.forEach(ctrl.tableParams.data, (item) => {
            if ($scope.checkboxes.checked === true) {
                $scope.checkboxes.items[item.activity_id] = true;
            } else {
                delete $scope.checkboxes.items[item.activity_id];
            }
        });

        ctrl.checkedItems = _.size($scope.checkboxes.items);
    }

    /**
     * Selects one row and set the selectAll checkbox to true if all checkboxes are now true
     * @param value - True if checked, false otherwise
     */
    function selectOneRow(activityId) {
        $scope.checkboxes.items[activityId] = !$scope.checkboxes.items[activityId];
        $scope.checkboxes.items = workQueueService.clearObject($scope.checkboxes.items);

        ctrl.checkedItems = _.size($scope.checkboxes.items);

        if (ctrl.checkedItems === 0) {
            $scope.checkboxes.checked = null;
        } else {
            $scope.checkboxes.checked = ctrl.checkedItems === $scope.workQueueData.length;
        }
    }
}

export default AssignmentManagerController;
