import { useState } from 'react';

import { useUserContext } from 'App/contexts/UserContext';
import AuthService from 'App/services/AuthService';
import UserService from 'App/services/UserService';

const useChangeBranch = () => {
    const { setUser } = useUserContext();
    const [isChanging, setIsChanging] = useState(false);

    const handleChangeBranch = async (branchId) => {
        setIsChanging(true);

        await AuthService.changeBranch(branchId);
        const newUser = UserService.get();
        setUser(newUser);

        setIsChanging(false);
    };

    return {
        handleChangeBranch,
        isChanging,
    };
};

export default useChangeBranch;
