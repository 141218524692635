import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import asFormField from './asFormField';

const TextareaInput = ({
    name,
    value,
    onChange,
    onBlur,
    className,
    style,

    placeholder,
    disabled,
    readOnly,
    maxLength,
    rows,
    onClick,
}) => {
    return (
        <textarea
            id={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={cx('form-control', className)}
            style={style}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            maxLength={maxLength}
            rows={rows}
            onClick={onClick}
        />
    );
};

TextareaInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    rows: PropTypes.number,
    onClick: PropTypes.func,
};

TextareaInput.defaultProps = {
    value: '',
    className: '',
    style: null,

    placeholder: '',
    disabled: false,
    readOnly: false,
    maxLength: null,
    rows: null,
    onClick: null,
};

export const TextareaField = asFormField({ WrappedComponent: TextareaInput });
