import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Col,
    ControlLabel,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Row,
} from 'react-bootstrap';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { Formik } from 'formik';
import SubmitButton from 'Lib/form/SubmitButton';
import MedicationSelect from 'App/components/MedicationSelect';
import { DatePickerField } from 'Lib/form/DatePickerField';
import * as R from 'ramda';
import SelectField from 'Lib/form/SelectField';
import { TextareaField } from 'Lib/form/TextareaField';
import useGetCustomMenuQuery from 'App/hooks/useGetCustomMenuQuery';
import dayjs from 'dayjs';
import * as Yup from 'yup';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    therapyId: PropTypes.string.isRequired,
};

const defaultProps = {};

const validationSchema = Yup.object().shape({
    medication: Yup.object().nullable().required().label('Medication'),
    endDate: Yup.date()
        .nullable()
        .max(Yup.ref('today'), ({ label }) => {
            return `${label} must be before today`;
        })
        .required()
        .label('End Date'),
    startDate: Yup.date()
        .nullable()
        .notRequired()
        .max(Yup.ref('today'), ({ label }) => {
            return `${label} must be before today`;
        })
        .label('Start Date'),
    endReason: Yup.object().nullable().required().label('End Reason'),
});

function rejectTodayAndLater(date) {
    return dayjs().startOf('d').isAfter(date);
}

function TreatmentHistoryModal({ onHide, therapyId, onConfirm }) {
    const { isLoading, data = [] } = useGetCustomMenuQuery('medication_end_reason');

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{
                today: dayjs().subtract(1, 'd').startOf('d').toDate(),
                therapyId,
                medication: null,
                startDate: null,
                endDate: null,
                endReason: null,
                notes: '',
            }}
            onSubmit={(values) => {
                return Promise.resolve(values)
                    .then(
                        R.compose(
                            R.over(R.lensProp('startDate'), (d) => d?.toISOString() || null),
                            R.over(R.lensProp('endDate'), (d) => d?.toISOString() || null),
                            R.over(R.lensProp('endReason'), R.prop('value')),
                            R.omit(['today'])
                        )
                    )
                    .then(onConfirm)
                    .catch(console.error)
                    .finally(onHide);
            }}
        >
            {({ setFieldValue, handleSubmit, values }) => (
                <Modal show onHide={onHide} backdrop="static">
                    <form onSubmit={handleSubmit}>
                        <ModalHeader>
                            <ModalTitle>Add Treatment History</ModalTitle>
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={6}>
                                    <MedicationSelect
                                        required
                                        therapyId={therapyId}
                                        fieldName="medication"
                                        label="Medication"
                                        onChange={(option) => {
                                            setFieldValue('medication', option);
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <DatePickerField
                                        filterDate={rejectTodayAndLater}
                                        label="Start Date"
                                        placeholder="YYYY/MM/DD"
                                        fieldName="startDate"
                                        onChange={(value) => {
                                            setFieldValue('startDate', value);
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <DatePickerField
                                        required
                                        filterDate={rejectTodayAndLater}
                                        label="End Date"
                                        placeholder="YYYY/MM/DD"
                                        fieldName="endDate"
                                        onChange={(value) => {
                                            setFieldValue('endDate', value);
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <SelectField
                                        required
                                        fieldName="endReason"
                                        label="End Reason"
                                        isLoading={isLoading}
                                        options={data}
                                        isClearable
                                        onChange={(value) => {
                                            setFieldValue('endReason', value);
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <ControlLabel htmlFor="notes">Notes</ControlLabel>
                                    <span className="pull-right">{values.notes.length}/200</span>

                                    <TextareaField
                                        id="notes"
                                        maxLength={200}
                                        placeholder="Write notes here"
                                        fieldName="notes"
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <FormButtonGroup>
                                <Button type="button" onClick={onHide}>
                                    Cancel
                                </Button>
                                <SubmitButton>Done</SubmitButton>
                            </FormButtonGroup>
                        </ModalFooter>
                    </form>
                </Modal>
            )}
        </Formik>
    );
}

TreatmentHistoryModal.propTypes = propTypes;
TreatmentHistoryModal.defaultProps = defaultProps;

export default TreatmentHistoryModal;
