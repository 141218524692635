import angular from 'angular';
import UnassignedPatientController from './unassigned-patient.controller';
import UnassignedPatientConfig from './unassigned-patient.config';

export default angular
    .module('components.unassignedPatient', ['ngMessages', 'ui.router'])
    .component('unassignedPatient', {
        controller: UnassignedPatientController,
        template: require('./unassigned-patient.html'),
    })
    .config(UnassignedPatientConfig).name;
