import React from 'react';
import PropTypes from 'prop-types';
import ActionCell, { ActionButtonLink } from 'App/components/styles/ActionCell';
import { useBuilderContext } from '../BuilderContext';
import { Link } from 'react-router-dom';

const propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            uuid: PropTypes.string.isRequired,
            publishedOn: PropTypes.string,
        }),
    }).isRequired,
};

const defaultProps = {};

function ActionsColumn({ row }) {
    const item = row.original;

    const { setItemToDelete, setItemToPublish } = useBuilderContext();

    return (
        <ActionCell>
            <Link to={`/protocol-template-builder/template/${item.uuid}`}>Edit</Link>
            <ActionButtonLink disabled={Boolean(item.publishedOn)} onClick={() => setItemToPublish(item)}>
                Publish
            </ActionButtonLink>
            <ActionButtonLink onClick={() => setItemToDelete(item)}>Delete</ActionButtonLink>
        </ActionCell>
    );
}

ActionsColumn.propTypes = propTypes;
ActionsColumn.defaultProps = defaultProps;

export default ActionsColumn;
