import { deleteAlerts, getAlerts } from 'App/services/AlertService';
import { errorHandler, hasLength, localize } from 'App/utils';
import * as R from 'ramda';
import { assign, createMachine } from 'xstate';

const deleteAlertsService = (context) => {
    const [toDelete, toKeep] = R.partition(R.prop('checked'), context.data);
    if (hasLength(toDelete)) {
        return deleteAlerts(R.map(R.prop('uuid'), toDelete)).then(R.always(toKeep));
    }
    return Promise.resolve(toKeep);
};
const getAlertsService = () => {
    return getAlerts().then(
        R.compose(
            R.map(
                R.compose(
                    R.over(R.lensProp('createdOn'), R.partialRight(localize, ['YYYY-MM-DD hh:mm A'])),
                    R.assoc('checked', false)
                )
            ),
            R.values
        )
    );
};

const States = Object.freeze({
    LOADING: 'LOADING',
    DELETING: 'DELETING',
    LOADED: 'LOADED',
    CONFIRM_DELETE: 'CONFIRM_DELETE',
    FAILED: 'FAILED',
    DETAILS_SHOWING: 'DETAILS_SHOWING',
    GET_DETAILS: 'GET_DETAILS',
});

const Events = Object.freeze({
    SUCCESS: 'SUCCESS',
    DELETE: 'DELETE',
    CANCEL: 'CANCEL',
    TOGGLE_ALL: 'TOGGLE_ALL',
    TOGGLE_ITEM: 'TOGGLE_ITEM',
    SHOW_DETAILS: 'SHOW_DETAILS',
    HIDE_DETAILS: 'HIDE_DETAILS',
    CONFIRM: 'CONFIRM',
    FAILURE: 'FAILURE',
});

/**
 * Create a collection of event matchers
 */
const stateMatchers = {
    isLoading: R.equals(States.LOADING),
    isDeleting: R.equals(States.DELETING),
    isConfirmDelete: R.equals(States.CONFIRM_DELETE),
    isDetailsShowing: R.equals(States.DETAILS_SHOWING),
    isPending: R.anyPass([R.equals(States.LOADING), R.equals(States.DELETING)]),
};

const financialAssistanceAlertsMachine = createMachine(
    {
        id: 'financialAssistanceAlerts',
        initial: States.LOADING,
        context: {
            data: [],
            isAllSelected: false,
            modalProgramId: null,
        },
        states: {
            [States.LOADING]: {
                invoke: {
                    id: 'getAlerts',
                    src: getAlertsService,
                    onDone: {
                        target: States.LOADED,
                        actions: ['setData'],
                    },
                    onError: {
                        target: States.FAILED,
                        actions: ['handleError'],
                    },
                },
            },
            [States.LOADED]: {
                on: {
                    [Events.DELETE]: { target: States.CONFIRM_DELETE },
                    [Events.TOGGLE_ITEM]: { actions: ['toggleItem'] },
                    [Events.TOGGLE_ALL]: { actions: ['toggleAll'] },
                    [Events.SHOW_DETAILS]: {
                        target: States.DETAILS_SHOWING,
                        actions: ['showDetails'],
                    },
                },
            },
            [States.DETAILS_SHOWING]: {
                on: {
                    [Events.HIDE_DETAILS]: { target: States.LOADED, actions: ['hideDetails'] },
                },
            },
            [States.CONFIRM_DELETE]: {
                on: {
                    [Events.CONFIRM]: { target: States.DELETING, actions: ['setExternalOnDone'] },
                    [Events.CANCEL]: { target: States.LOADED },
                },
            },
            [States.DELETING]: {
                invoke: {
                    id: 'deleteAlerts',
                    src: deleteAlertsService,
                    onDone: {
                        target: States.LOADED,
                        actions: ['setData', 'callExternalOnDone', 'toggleOffAll'],
                    },
                    onError: {
                        target: States.LOADED,
                        actions: ['handleError'],
                    },
                },
            },
            [States.FAILED]: {
                type: 'final',
                actions: ['handleError'],
            },
        },
    },
    {
        actions: {
            toggleItem: assign((context, event) => {
                return R.compose(
                    R.over(R.lensProp('data'), R.over(R.lensPath([event.index, 'checked']), R.not)),
                    R.over(R.lensProp('isAllSelected'), R.F)
                )(context);
            }),
            setData: assign((context, event) => {
                return R.compose(R.set(R.lensProp('data'), event.data))(context);
            }),
            toggleAll: assign((context) => {
                return R.compose(
                    R.chain(
                        R.compose(R.over(R.lensProp('data')), R.compose(R.map, R.set(R.lensProp('checked')))),
                        R.prop('isAllSelected')
                    ),
                    R.over(R.lensProp('isAllSelected'), R.not)
                )(context);
            }),
            showDetails: assign((context, event) => {
                return R.compose(R.set(R.lensProp('modalProgramId'), event.programId))(context);
            }),
            hideDetails: assign((context) => {
                return R.compose(R.set(R.lensProp('modalProgramId'), null))(context);
            }),
            setExternalOnDone: assign((context, event) => {
                return R.assoc('onDone', event.onDone, context);
            }),
            toggleOffAll: assign((context) => {
                return R.assoc('isAllSelected', false, context);
            }),
            callExternalOnDone: (context) => {
                if (context.onDone) {
                    context.onDone();
                }
            },
            handleError: (context, event) => {
                errorHandler(event.data);
            },
        },
    }
);

export { States, Events, stateMatchers };

export default financialAssistanceAlertsMachine;
