function AssessmentScoringController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    function $onChanges(changes) {
        if (changes.scores && ctrl.scores) {
            ctrl.scores = angular.copy(ctrl.scores);
        }

        if (changes.scoring && ctrl.scoring) {
            ctrl.scoring = angular.copy(ctrl.scoring);
        }
    }
}

export default AssessmentScoringController;
