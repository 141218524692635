import { useUserContext } from 'App/contexts/UserContext';
import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';

function getTruncatedEmail(email) {
    return email?.replace(/\+.?[^@]*/i, '');
}

const UserIcon = () => {
    const history = useHistory();
    const user = useUserContext();

    const goLogout = () => history.push('/logout');

    const goMyAccount = () => history.push('/myAccount');

    const UserPopover = (
        <Popover id="user-profile-popover">
            <div className="add-header-popover-heading">
                <div
                    id="user-full-name"
                    className="add-header-popover-username"
                >{`${user.first_name} ${user.last_name}`}</div>
                <div id="user-email" className="add-header-popover-subtext">
                    {user?.CdcType === 'user:Support:provision' ? getTruncatedEmail(user.email) : user.email}
                </div>
                {user?.CdcType === 'user:Support:provision' ? (
                    <div id="user-company" className="add-header-popover-subtext">
                        ({user.active_company.Name})
                    </div>
                ) : null}
            </div>
            <FormButtonGroup style={{ justifyContent: 'space-between' }}>
                <Button id="user-profile-popover-button-logout" bsSize="small" onClick={goLogout}>
                    Sign Out
                </Button>
                <Button
                    id="user-profile-popover-button-my-account"
                    bsStyle="primary"
                    bsSize="small"
                    onClick={goMyAccount}
                >
                    My Account
                </Button>
            </FormButtonGroup>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={UserPopover}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a id="user-icon" className="icon">
                <i className="fas fa-fw fa-user-circle" />
            </a>
        </OverlayTrigger>
    );
};

export default UserIcon;
