import React from 'react';
import ErrorMessage from './ErrorMessage';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
function ErrorForbidden({ permissionName }) {
    return (
        <ErrorMessage>
            <p>
                Permissions Error: You do not have the permission &apos;
                {permissionName}&apos; for this division.
            </p>
            <p>Please speak to your Division Administrator if you believe you should have this permission.</p>
        </ErrorMessage>
    );
}

export default ErrorForbidden;
