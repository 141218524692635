import PropTypes from 'prop-types';
import { QUESTION_TYPES } from 'App/features/ProtocolManager/constants';

const condition = PropTypes.shape({
    key: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            operator: PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            }),
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.shape({
                    label: PropTypes.string,
                    value: PropTypes.string,
                }),
            ]),
        })
    ),
});

const groupName = PropTypes.string.isRequired;

const field = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    operators: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            isNegation: PropTypes.bool,
        })
    ),
    availableOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    type: PropTypes.oneOf([...Object.values(QUESTION_TYPES), 'string', 'array']),
});

const group = PropTypes.shape({
    field,
    conditions: PropTypes.arrayOf(condition),
});

const addRuleProptypes = {
    groupName,
    group: group.isRequired,
};

export { condition, group, groupName, field };
export default addRuleProptypes;
