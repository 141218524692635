import React, { useState } from 'react';
import { Alert, Button, ControlLabel, Col, Form, FormGroup, Grid, Radio, Row } from 'react-bootstrap';
import axios from 'axios';

import FileUpload, {
    PageHeader,
    Blockquote,
    HighlightSpan,
    DateFormatContainer,
    validateFiles,
    Sidebar,
} from './FileUpload';

import UploadTemplate from '../../../public/patient_upload_template.xls';

const dateFormatOptions = [
    {
        key: 5,
        id: 'date_format_1',
        label: 'dd/mm/yyyy',
    },
    {
        key: 1,
        id: 'date_format_2',
        label: 'mm/dd/yyyy',
    },
    {
        key: 6,
        id: 'date_format_3',
        label: 'dd-mm-yyyy',
    },
    {
        key: 3,
        id: 'date_format_4',
        label: 'mm-dd-yyyy',
    },
    {
        key: -1,
        id: 'date_format_5',
        label: 'yyyy-mm-dd',
    },
];

const UploadPatient = () => {
    const [formValid, setFormValid] = useState(false);
    const [selectedDateFormat, setSelectedDateFormat] = useState(5);
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);

    const onChangeDateFormat = (event) => {
        const newDateFormat = Number(event.target.value);
        setSelectedDateFormat(newDateFormat);
        return null;
    };

    const onChangeFiles = (newFiles) => {
        const filesValidated = validateFiles(newFiles);
        if (filesValidated) {
            if (error) {
                setError(null);
            }
            setFormValid(true);
            setFiles(newFiles);
        } else {
            setFormValid(false);
            setFiles([]);
        }
        return null;
    };

    const sendFileUploadRequest = async (event) => {
        event.preventDefault();
        const [file] = files;
        if (formValid && files && files.length) {
            if (file && selectedDateFormat) {
                // Since the PHP api requires a `FormData` object, format and append the file and options
                // in a `new FormData()`
                const formData = new FormData();
                formData.append('form_data', file);
                formData.append('date_format', selectedDateFormat);

                const url = '/stm/patient_upload.php';
                const headers = {
                    'Content-Type': 'multipart/form-data; charset=UTF-8',
                };
                // TODO: Create a "Upload" api module to avoid using axios or fetch directly in
                //       this event handler and to enable mock-ability of this feature.
                const response = await axios.post(url, formData, {
                    withCredentials: true,
                    headers,
                });

                const { data } = response;
                if (data.message) {
                    // TODO: Since the message received here is a HTML string, create and apply a
                    //       HTML sanitization before setting the state.
                    setError({ message: data.message });
                }
            }
        }
    };

    return (
        <Grid fluid>
            <Row>
                <PageHeader>Upload Patients</PageHeader>
                <Col xs={2} style={{ padding: 0 }}>
                    <Sidebar>
                        <h3>Helpful Hints</h3>

                        <ol>
                            <li>Any field in red denotes it is required and must contain patient data</li>
                            <li>
                                Add only one therapeutic category per medication: more than one category can be added to
                                a patient by separating each category by two colons (e.g., General::Hepatitis C)
                            </li>
                            <li>
                                The therapeutic category, medication, and diagnosis must be typed exactly how it
                                presents within TherigySTM, including extra spaces and special characters
                            </li>
                            <li>Only ICD-10 diagnosis codes can be uploaded</li>
                            <li>
                                The medication and diagnosis entered for the patient must be listed within the
                                therapeutic category
                            </li>
                            <li>
                                More than one medication can be added for a patient by separating each medication with
                                two colons (e.g., “medication X::medication Y”)
                            </li>
                            <li>
                                Rx numbers must correspond with the order of medications. More than one Rx number can be
                                added by separating each with two colons (e.g., Rx#::Rx#::Rx#). Include the two colons
                                for any Rx numbers that do not have an Rx number (e.g., three medications, with no Rx#
                                for the first medication, is written as “::Rx#::Rx#”; three medications, with no Rx# for
                                the second medication, is written as “Rx#::::Rx#”; three medications, with no RX# for
                                the third medication, is written as “Rx#::Rx#::”).
                            </li>
                            <li>
                                If a patient with the same first name, last name, and zip code already exists in
                                TherigySTM, the patient will not be uploaded
                            </li>
                            <li>The file must be saved in a comma delimited (CSV) format</li>
                        </ol>
                    </Sidebar>
                </Col>
                <Col xs={10}>
                    {error && error.message && (
                        <Alert bsStyle="danger">
                            <div
                                id="user-message"
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: error.message,
                                }}
                            />
                        </Alert>
                    )}
                    <p>
                        The first row (in purple) contains helpful information to assist in populating the template:
                        this row must be deleted prior to uploading the file. The second row contains the data
                        identification fields available for upload: the text in this row must not be changed.
                    </p>
                    <Button bsStyle="primary" href={UploadTemplate} download="patient_upload_template.xls">
                        Download Template
                    </Button>
                    <Blockquote>
                        other_id,
                        <HighlightSpan> first_name, last_name, gender, birth_date, </HighlightSpan>
                        weight, height, ssn_last_four, email, home_phone, mobile_phone, address_line_1, address_line_2,
                        <HighlightSpan> city, state, zip, </HighlightSpan>
                        country, allergy, other_allergy, pharmacy, medical_plan, remote_medical_plan_id, pbm,
                        remote_pbm_id, pcc_identifier, ec_first_name, ec_last_name, ec_telephone, ec_relationship,
                        external_id,
                        <HighlightSpan> therapeutic_category, medications, </HighlightSpan>
                        diagnosis, rx_numbers, rx_number_start, due_date, ehr_patient_id, ehr_patient_id_type,
                        dispensing_id, preferred_name, pronouns, gender_identity
                    </Blockquote>
                    <Form>
                        <DateFormatContainer>
                            <FormGroup>
                                <ControlLabel>Select Date Format:</ControlLabel>
                                {dateFormatOptions.map((dateFormatOption) => (
                                    <Radio
                                        key={dateFormatOption.key}
                                        id={dateFormatOption.id}
                                        checked={selectedDateFormat === dateFormatOption.key}
                                        onChange={onChangeDateFormat}
                                        value={dateFormatOption.key}
                                    >
                                        {dateFormatOption.label}
                                    </Radio>
                                ))}
                            </FormGroup>
                        </DateFormatContainer>

                        <FileUpload
                            onFiles={onChangeFiles}
                            accept="text/csv','application/vnd.ms-excel"
                            id="upload_file"
                        />
                        <Button id="submit" bsStyle="primary" disabled={!formValid} onClick={sendFileUploadRequest}>
                            Upload Patients
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Grid>
    );
};

export default UploadPatient;
