import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { stripCurlyTags } from 'App/utils';

dayjs.extend(utc);

/** @ngInject */
function EhrEncounterNoteQuestionController(_, amsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.stripCurlyTags = stripCurlyTags;

    function $onChanges(changes) {
        if (changes.hasParent && ctrl.hasParent) {
            ctrl.hasParent = angular.copy(ctrl.hasParent);
        }
        if (changes.question && ctrl.question) {
            // Format some question data to be displayed easily.
            ctrl.question = angular.copy(ctrl.question);
            formatQuestionForEhrNote();
        }
    }

    /**
     * Formats responses and child questions to be displayed on an EHR note.
     */
    function formatQuestionForEhrNote() {
        ctrl.question.responses = [];
        switch (ctrl.question.type) {
            case 'checkbox':
                if (Array.isArray(ctrl.question.response)) {
                    // Checkboxes can have multiple answers and each answer can have children.
                    for (const response of ctrl.question.response) {
                        const position = ctrl.question.answers[response] ? ctrl.question.answers[response].position : 0;
                        const children = _.filter(ctrl.question.children, { parentAnswerId: response });
                        const answer = {
                            answerId: response,
                            children: Object.values(children).sort(amsService.sortQuestions),
                            position,
                            text: ctrl.question.answers[response].option,
                        };
                        ctrl.question.responses.push(answer);
                    }
                }
                break;
            case 'radio':
            case 'select':
                // Radios and Selects have a single answer that can have children.
                if (ctrl.question.answers[ctrl.question.response] !== undefined) {
                    const children = _.filter(ctrl.question.children, { parentAnswerId: ctrl.question.response });
                    ctrl.question.responses.push({
                        answerId: ctrl.question.response,
                        children: Object.values(children).sort(amsService.sortQuestions),
                        position: 0, // only one response on these.
                        text: ctrl.question.answers[ctrl.question.response].option,
                    });
                }
                break;
            case 'date':
                if (ctrl.question.response) {
                    ctrl.question.responses.push({
                        position: 0, // only one response on these.
                        text:
                            typeof ctrl.question.response === 'string'
                                ? dayjs.utc(ctrl.question.response).format('YYYY-MM-DD')
                                : ctrl.question.response,
                        children: [],
                    });
                }
                break;
            default:
                // Other question types cannot have children and just need to display their single response.
                if (ctrl.question.response || ctrl.question.response === 0) {
                    ctrl.question.responses.push({
                        position: 0, // only one response on these.
                        text:
                            typeof ctrl.question.response === 'string'
                                ? ctrl.question.response.replace(/\n/g, '<br />')
                                : ctrl.question.response,
                        children: [],
                    });
                }
                break;
        }
        if (ctrl.question.responses.length === 0) {
            ctrl.question.responses.push(getUnansweredFormat());
        }
    }

    /**
     * Unanswered questions should display <unanswered> as their response text.
     * @returns {*}
     */
    function getUnansweredFormat() {
        return {
            text: '&lt;unanswered&gt;',
            children: [],
            position: 0,
        };
    }
}

export default EhrEncounterNoteQuestionController;
