/**
 * This service is responsible for retrieving ICD9 data.
 */
/** @ngInject */
function PhysicianService($http, _, urlBuilderService) {
    const service = {
        autocomplete,
        get,
        getMany,
    };

    function autocomplete(search) {
        const url = urlBuilderService.build('autocompletePhysician', {
            user: '',
            physicians: '',
            like: search,
        });

        return $http.get(url).then((res) => {
            return res.data;
        });
    }

    function get(physician) {
        return $http({
            method: 'get',
            url: urlBuilderService.build('physician', {
                physician: '',
            }),
            params: {
                ids: JSON.stringify([physician]),
            },
        }).then((res) => {
            return res.data;
        });
    }

    function getMany(physicians) {
        // This is used on form.php to remove prototypes invalid toJSON method.
        delete Array.prototype.toJSON;
        return $http({
            method: 'get',
            url: urlBuilderService.build('physician', {
                physician: '',
            }),
            params: {
                ids: JSON.stringify(_.values(physicians)),
            },
        }).then((res) => {
            return res.data;
        });
    }

    return service;
}

export default PhysicianService;
