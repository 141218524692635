import map from 'ramda/src/map';
import compose from 'ramda/src/compose';
import curry from 'ramda/src/curry';

/**
 * Composes the dispatch function and each key together and returns a new
 * map of functions.
 * @param {object} actionCreators An object of functions
 * @param {function} dispatch The function that will be composed
 * @returns {function(*=): (*)}
 */
function bindActionCreators(actionCreators, dispatch) {
    return map((fn) => compose(dispatch, fn), actionCreators);
}

export default curry(bindActionCreators);
