import * as R from 'ramda';
import isExpired from '../isExpired/isExpired';

const shouldShowRetiredAssessment = R.curry((showRetiredAssessments, assessment) => {
    const retiredOn = R.prop('retiredOn', assessment);

    const hidden = R.path(['settings', 'hide'], assessment);

    if (hidden) {
        return false;
    }

    if (showRetiredAssessments) {
        return true;
    }

    return !isExpired(retiredOn);
});

export default shouldShowRetiredAssessment;
