import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';

const FieldLabel = ({ fieldName, label, children, required, hasError }) => {
    return (
        <FormGroup
            className={cx({
                'has-error': hasError,
            })}
        >
            <ControlLabel
                htmlFor={fieldName}
                className={cx({
                    'field-required': required,
                })}
            >
                {label}
            </ControlLabel>
            {children}
        </FormGroup>
    );
};

FieldLabel.propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    required: PropTypes.bool,
    hasError: PropTypes.bool,
};

FieldLabel.defaultProps = {
    required: false,
    hasError: false,
};

export default FieldLabel;
