import React from 'react';
import { useBuilderContext } from '../BuilderContext';
import useDeleteProtocolTemplateMutation from 'App/hooks/useDeleteProtocolTemplateMutation';
import DeleteModal from 'App/components/DeleteModal';

const propTypes = {};

const defaultProps = {};

function DeleteTemplateModal() {
    const { itemToDelete, setItemToDelete } = useBuilderContext();

    const deleteProtocolTemplateMutation = useDeleteProtocolTemplateMutation();

    if (itemToDelete) {
        return (
            <DeleteModal
                title="Delete Protocol Template"
                show
                onHide={() => setItemToDelete(null)}
                onDelete={() => {
                    return deleteProtocolTemplateMutation
                        .mutateAsync(itemToDelete.uuid)
                        .then(() => setItemToDelete(null));
                }}
            />
        );
    }

    return null;
}

DeleteTemplateModal.propTypes = propTypes;
DeleteTemplateModal.defaultProps = defaultProps;

export default DeleteTemplateModal;
