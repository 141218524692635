import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils.js';
import env from 'App/components/config/config';
import { FaDownload } from 'react-icons/fa';
import { noop } from 'lodash';

const cdnURL = env.cdnURL || '';
const formatCreatedOn = (milliSec) => {
    try {
        return formatDate(milliSec, 'MM/DD/YYYY hh:mm A');
    } catch (err) {
        console.log(err);
        return '';
    }
};
const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    file: PropTypes.shape({
        id: PropTypes.string.isRequired,
        origFileName: PropTypes.string.isRequired,
        icon: PropTypes.string,
        createdOn: PropTypes.string.isRequired,
    }).isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    selectedFiles: PropTypes.arrayOf(PropTypes.string).isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    toggleFileSelect: PropTypes.func.isRequired,
};
const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    file: {
        id: '',
        origFileName: '',
        icon: '',
        createdOn: '',
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    selectedFiles: [],
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    toggleFileSelect: noop,
};
const FileRecord = ({ file, selectedFiles, toggleFileSelect }) => {
    const isFileSelected = selectedFiles.includes(file.id);
    return (
        <tr>
            <td>
                <img
                    className="patient-files-thumbnail"
                    src={`${cdnURL}/images/${file.icon}`}
                    alt={file.origFileName}
                />
            </td>
            <td>
                <a target="_blank" href={`/stm/download_note_file.php?file_id=${file.id}`} rel="noopener noreferrer">
                    {file.origFileName}
                    <FaDownload />
                </a>
                <br />
                <span className="patient_files_created_on">{formatCreatedOn(file.createdOn)}</span>
            </td>
            <td className="text-right">
                <input type="checkbox" checked={isFileSelected} onChange={(e) => toggleFileSelect(e, file.id)} />
            </td>
        </tr>
    );
};

FileRecord.propTypes = propTypes;
FileRecord.defaultProps = defaultProps;

export default FileRecord;
