import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import MessageBody from './MessageBody';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
const Message = ({ message, slectedMessages, onMessageSelectClick, onMessageViewed }) => {
    const [isOpen, setIsOpen] = useState(false);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/prop-types
    const isMessageSelected = slectedMessages.includes(message?.id);
    const expandMessage = () => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        if (!message?.viewed) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line react/prop-types
            onMessageViewed(message?.id);
        }
        setIsOpen(!isOpen);
    };

    return (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        <Row className="message-row" data-testid={`message-row-${message?.id}`}>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line react/prop-types */}
            <Col className="message-content-delete" onClick={(e) => onMessageSelectClick(e, message?.id)}>
                <input
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/prop-types
                    data-testid={`delete-${message?.id}`}
                    type="checkbox"
                    checked={isMessageSelected}
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/prop-types
                    onChange={(e) => onMessageSelectClick(e, message?.id)}
                />
            </Col>
            <Col md={11} className="message-content-data" onClick={expandMessage}>
                <Row>
                    <Col
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/prop-types
                        data-testid={`message-row-${message?.id}-body`}
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/prop-types
                        className={`message-content ${!message?.viewed ? 'message-content-bold' : ''}`}
                        md={6}
                    >
                        {/* TODO: Fix this the next time the file is edited. */}
                        {/* eslint-disable-next-line react/prop-types */}
                        {message?.subject || '--'}
                    </Col>
                    <Col className="message-content" md={4}>
                        {/* TODO: Fix this the next time the file is edited. */}
                        {/* eslint-disable-next-line react/prop-types */}
                        {message?.department || '--'}
                    </Col>
                    <Col className="message-content" md={2}>
                        {/* TODO: Fix this the next time the file is edited. */}
                        {/* eslint-disable-next-line react/prop-types */}
                        {message?.messageDate || '--'}
                    </Col>
                    {isOpen && (
                        <Col className="message-content" md={12}>
                            {/* TODO: Fix this the next time the file is edited. */}
                            {/* eslint-disable-next-line react/prop-types */}
                            <MessageBody message={message?.message} />
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default Message;
