import { toast } from 'react-toastify';
import { useQueryClient, useMutation } from 'react-query';
import { patchActivityTextSchedule, postActivityTextSchedule } from '@/app/services/PatientMessagingService';
import { errorHandler } from '@/app/utils';

const useActivityTextScheduleMutation = (activityId) => {
    const queryClient = useQueryClient();
    return useMutation(
        (reqBody) => (activityId ? patchActivityTextSchedule(activityId, reqBody) : postActivityTextSchedule(reqBody)),
        {
            onError: errorHandler,
            onSuccess: () => {
                toast.success(`Activity Text Schedule successfully ${activityId ? 'updated' : 'added'}`);
                queryClient.invalidateQueries('getActivityTextSchedule');
            },
        }
    );
};

export default useActivityTextScheduleMutation;
