import React from 'react';
import { Helmet } from 'react-helmet';
import { catchPhrase } from '../config/config';
import { NotFound } from '../errors';
import Main from '../styles/Main/Main';
import PageTitle from '../styles/PageTitle/PageTitle';
import PageWrapper from '../styles/PageWrapper/PageWrapper';
import CloneAssessment from './CloneAssessment';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';

function CloneAssessmentPage() {
    //#region State
    const hasCloneAssessment = useIsPermitted(PERMISSION_TYPES.CLONE_ASSESSMENT);
    //#endregion

    if (hasCloneAssessment) {
        return (
            <Main>
                <Helmet>
                    <title>Clone Assessment - {catchPhrase}</title>
                </Helmet>

                <PageTitle>Clone Assessment</PageTitle>

                <PageWrapper>{hasCloneAssessment && <CloneAssessment />}</PageWrapper>
            </Main>
        );
    }

    return <NotFound />;
}

export default CloneAssessmentPage;
