import { useUserContext } from 'App/contexts/UserContext';
import { useAlertCenterAccess } from 'App/utils/hooks/useIsPermitted';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { getAlertUsers } from 'App/services/AlertService';
import AlertsCenterIcon from 'App/components/AlertsCenter/AlertsCenterIcon/AlertsCenterIcon';

const propTypes = {};

const defaultProps = {};

function AlertsCenterHeader() {
    //#region State
    const hasAlertCenterAccess = useAlertCenterAccess();
    const user = useUserContext();
    const [alertUser, setAlertUser] = useState();
    //#endregion

    const userId = R.prop('id', user);

    useEffect(() => {
        if (hasAlertCenterAccess) {
            getAlertUsers().then(R.compose(setAlertUser, R.find(R.propEq(userId, 'userId')), R.values));
        }
    }, [hasAlertCenterAccess, userId]);

    if (!hasAlertCenterAccess || R.isNil(alertUser)) {
        return null;
    }

    return <AlertsCenterIcon />;
}

AlertsCenterHeader.propTypes = propTypes;
AlertsCenterHeader.defaultProps = defaultProps;

export default AlertsCenterHeader;
