import {
    addMedicationNote,
    addPrescriptionNote,
    addTherapeuticCategoryNote,
    fetchPatientMedicationNotes,
    fetchPatientTherapeuticCategoryNotes,
    fetchPatientPrescriptionNotes,
} from './PatientProfile.utils';

const RefetchFlags = {
    activitiesAndReferral: 'ACTIVITIES_INCLUDING_REFERRAL',
    messagingSubscriptions: 'MESSAGING_SUBSCRIPTIONS',
    unassignedMedications: 'UNASSIGNED_MEDICATIONS',
};

const NoteModalTypes = {
    therapy: 0,
    medication: 1,
    prescription: 2,
};

const NoteModalConst = {
    [NoteModalTypes.therapy]: {
        title: 'Add Notes',
        noteListTitle: 'View Notes',
        noNoteListMessage: 'There are no notes for this therapy.',
        submitFunction: addTherapeuticCategoryNote,
        fetchNotes: fetchPatientTherapeuticCategoryNotes,
        closeButtonId: 'therapeutic_notes_close',
    },
    [NoteModalTypes.medication]: {
        title: 'Medication Note',
        noteListTitle: 'Medication Notes',
        noNoteListMessage: 'There are no notes for this medication.',
        submitFunction: addMedicationNote,
        fetchNotes: fetchPatientMedicationNotes,
        closeButtonId: 'medication_notes_close',
    },
    [NoteModalTypes.prescription]: {
        title: 'Prescription Note',
        noteListTitle: 'Rx Number Notes',
        noNoteListMessage: 'There are no notes for this rx number.',
        submitFunction: addPrescriptionNote,
        fetchNotes: fetchPatientPrescriptionNotes,
        closeButtonId: 'rx_notes_close',
    },
};

export { RefetchFlags, NoteModalTypes, NoteModalConst };
