import React from 'react';
import { Helmet } from 'react-helmet';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';

import AdvancedReports from './app/components/AdvancedReports/AdvancedReports';
import AlertsCenter from 'App/components/AlertsCenter';
import AlertsManager from 'App/components/AlertsManager';
import AngularRootReactComponent from './angular-root';
import MedicationManagement from 'App/components/MedicationManagement';
import AssessmentManager from './app/components/AssessmentManager';
import ClinicalSummary from './app/features/ClinicalSummary';
import EditPatientBranch from './app/components/EditPatientBranch/EditPatientBranch';
import PatientUpload from './app/components/Upload/PatientUpload';
import PhysicianUpload from './app/components/Upload/PhysicianUpload';
import ProtocolManager from 'App/features/ProtocolManager/ProtocolManagerPage';
import QuestionGroups from 'App/components/QuestionGroups';
import RouteWithHeader from '@/app/components/RouteWithHeader';
import TherapeuticCategories from 'App/components/TherapeuticCategories';
import ViewMessages from './app/components/ViewMessages/ViewMessages';
import ProtocolPublisher from 'App/components/ProtocolPublisher';
import { catchPhrase } from './app/components/config/config';
import { UserChecker, UserContextProvider } from 'App/contexts/UserContext';
import { PERMISSION_TYPES } from './app/utils/isPermitted';
import FindPatient from './app/components/FindPatient/FindPatient';
import PatientProfile from './app/components/PatientProfile';
import TrackingLoader from 'App/utils/TrackingLoader';
import ContentMonographDisplay from 'App/features/ContentMonograph/ContentMonographDisplay';
import PatientMessaging from 'App/components/PatientMessaging';
import * as routeLookup from 'App/common/routeLookup';
import Activity from 'App/features/Activity/Activity';
import AssessmentPreview from 'App/features/AssessmentPreview/AssessmentPreview';
import WorkQueue from 'App/features/WorkQueue/WorkQueue';
import useFetchDevFeatureFlagQuery from 'App/hooks/useFetchDevFeatureFlagQuery';
import checkFeatureFlagStatus from 'App/components/FeatureFlags/checkFeatureFlagStatus';
import ProtocolTemplateBuilderPage from 'App/features/ProtocolTemplateBuilder';
import SsoConfiguration from 'App/components/SsoConfiguration';
import ReferralManager from 'App/features/ReferralManager';
import AddPatient from 'App/features/AddPatient/AddPatient';
import EditPatient from 'App/features/EditPatient/EditPatient';

const nonAuthenticatedRoutes = [
    '/login',
    '/logout',
    '/forgotPassword',
    '/sso/:company',
    '/resetPassword',
    '/incompleteConfiguration',
];

const Routes = () => {
    const { data: flags } = useFetchDevFeatureFlagQuery();
    return (
        <HashRouter hashType="hashbang">
            <TrackingLoader />
            <Switch>
                <RouteWithHeader path="/advancedReports">
                    <AdvancedReports />
                </RouteWithHeader>
                <RouteWithHeader path="/medicationManagement">
                    <MedicationManagement />
                </RouteWithHeader>
                <RouteWithHeader path="/clinicalSummary">
                    <ClinicalSummary />
                </RouteWithHeader>
                <RouteWithHeader path="/editPatientBranch">
                    <EditPatientBranch />
                </RouteWithHeader>
                <RouteWithHeader path="/patientUpload">
                    <PatientUpload />
                </RouteWithHeader>
                <RouteWithHeader path="/physicianUpload">
                    <PhysicianUpload />
                </RouteWithHeader>
                <RouteWithHeader path="/protocolManager">
                    <ProtocolManager />
                </RouteWithHeader>
                <RouteWithHeader path="/protocolPublisher">
                    <ProtocolPublisher />
                </RouteWithHeader>
                <RouteWithHeader path="/protocol-template-builder">
                    <ProtocolTemplateBuilderPage />
                </RouteWithHeader>
                <RouteWithHeader path="/alerts-center">
                    <AlertsCenter />
                </RouteWithHeader>
                <RouteWithHeader path="/alerts-manager">
                    <AlertsManager />
                </RouteWithHeader>
                <RouteWithHeader path="/viewMessages">
                    <ViewMessages />
                </RouteWithHeader>
                <RouteWithHeader path="/therapeutic-categories">
                    <TherapeuticCategories />
                </RouteWithHeader>
                <RouteWithHeader path="/question-groups">
                    <QuestionGroups />
                </RouteWithHeader>
                {checkFeatureFlagStatus({
                    flags,
                    featureFlagName: PERMISSION_TYPES.ENABLE_SSO_COMPANY_CONFIGURATION,
                }) && (
                    <RouteWithHeader path="/ssoConfiguration">
                        <SsoConfiguration />
                    </RouteWithHeader>
                )}

                <Route path="/assessment-manager">
                    <AssessmentManager />
                </Route>

                <Route path={routeLookup.referralBaseRouteRegex}>
                    <ReferralManager />
                </Route>
                {checkFeatureFlagStatus({ flags, featureFlagName: PERMISSION_TYPES.ENABLE_REACT_ASSESSMENTS }) && (
                    <RouteWithHeader path={routeLookup.assessmentPreviewRoute} exact>
                        <AssessmentPreview />
                    </RouteWithHeader>
                )}
                <RouteWithHeader path="/patientmessaging">
                    <PatientMessaging />
                </RouteWithHeader>

                <RouteWithHeader path="/findPatient">
                    <FindPatient />
                </RouteWithHeader>

                {checkFeatureFlagStatus({ flags, featureFlagName: PERMISSION_TYPES.ENABLE_REACT_WORK_QUEUE }) && (
                    <RouteWithHeader path={routeLookup.workQueueRoute}>
                        <WorkQueue />
                    </RouteWithHeader>
                )}

                {checkFeatureFlagStatus({ flags, featureFlagName: PERMISSION_TYPES.ENABLE_REACT_ADD_EDIT_PATIENT }) && (
                    <RouteWithHeader path={routeLookup.addPatientRoute}>
                        <AddPatient />
                    </RouteWithHeader>
                )}
                {checkFeatureFlagStatus({ flags, featureFlagName: PERMISSION_TYPES.ENABLE_REACT_ADD_EDIT_PATIENT }) && (
                    <RouteWithHeader path={routeLookup.editPatientRoute}>
                        <EditPatient />
                    </RouteWithHeader>
                )}

                {checkFeatureFlagStatus({ flags, featureFlagName: PERMISSION_TYPES.ENABLE_REACT_PATIENT_PROFILE }) && (
                    <RouteWithHeader path={routeLookup.patientRoute} exact>
                        <PatientProfile />
                    </RouteWithHeader>
                )}
                {checkFeatureFlagStatus({ flags, featureFlagName: PERMISSION_TYPES.ENABLE_REACT_ASSESSMENTS }) && (
                    <RouteWithHeader path={routeLookup.activityRoute} exact>
                        <Activity />
                    </RouteWithHeader>
                )}
                <Route path={routeLookup.contentMonographRoute}>
                    <UserContextProvider>
                        <UserChecker>
                            <ContentMonographDisplay />
                        </UserChecker>
                    </UserContextProvider>
                </Route>
                {/* Redirect lost users to /workQueue */}
                <Route path="/" exact>
                    <Redirect to="/workQueue" />
                </Route>

                {/**
                 * Wrap these angular routes with a React route.
                 * These are our non-authenticated routes. Therefore, they do not need the user checker.
                 */}
                {nonAuthenticatedRoutes.map((path) => (
                    <Route path={path} key={path}>
                        <AngularRootReactComponent />
                    </Route>
                ))}

                {/* All other paths render in angular */}
                <Route>
                    <UserContextProvider>
                        <UserChecker>
                            <Helmet>
                                <title>{catchPhrase}</title>
                            </Helmet>
                            <AngularRootReactComponent />
                        </UserChecker>
                    </UserContextProvider>
                </Route>
            </Switch>
        </HashRouter>
    );
};

export default Routes;
