/** @ngInject */
function ChangeBranchController(authService) {
    const ctrl = this;
    ctrl.setBranch = setBranch;
    ctrl.$onChanges = $onChanges;
    ctrl.selectedBranch = {};

    function $onChanges(changes) {
        if (changes.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
        if (changes.selection) {
            const selectedBranch = angular.copy(ctrl.selection);
            ctrl.selectedBranch = {
                id: selectedBranch.ID,
                name: selectedBranch.Name,
            };
        }
    }

    async function setBranch() {
        ctrl.loading = true;
        await authService.changeBranch(ctrl.selectedBranch.id);
        ctrl.loading = false;
    }
}

export default ChangeBranchController;
