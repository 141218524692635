import PropTypes from 'prop-types';

const reactQueryPropType = PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    error: PropTypes.any,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    status: PropTypes.oneOf(['success', 'error', 'loading']),
});

export default reactQueryPropType;
