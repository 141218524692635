import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import AddTeachingPointModal from './AddTeachingPointModal';
import { FaPlus } from 'react-icons/fa';
import PropTypes from 'prop-types';
import './AddTeachingPointModal.scss';

const propTypes = {
    loadTable: PropTypes.func.isRequired,
};

function AddTeachingPointModalWrapper({ loadTable }) {
    const [showTechingModal, setShowTeachingModal] = useState(false);
    return (
        <div className="add-teaching-point-monograph">
            <Button bsStyle="link" title="Add Teaching Point Monograph" onClick={() => setShowTeachingModal(true)}>
                <FaPlus /> Add Teaching Point Monograph
            </Button>
            {showTechingModal && (
                <AddTeachingPointModal
                    loadTable={loadTable}
                    onHide={() => {
                        setShowTeachingModal(false);
                    }}
                />
            )}
        </div>
    );
}

AddTeachingPointModalWrapper.propTypes = propTypes;

export default AddTeachingPointModalWrapper;
