/** @ngInject */
function PatientStatusHistoryService($http, _, urlBuilderService) {
    const promises = {};
    const service = {
        create,
        get,
    };

    function _format(res) {
        _.forEach(res.data, (value, key) => {
            res.data[_.snakeCase(key)] = value;
            delete res.data[key];
        });
        return res.data;
    }

    function create(patientId, body) {
        const url = urlBuilderService.build('createPatientStatusHistory', {
            patient: patientId,
            status: '',
        });

        return $http.post(url, body).then(_format);
    }

    function get(patientId) {
        if (promises.get) {
            return promises.get;
        }
        const url = urlBuilderService.build('getPatientStatusHistory', {
            patient: patientId,
            status: '',
        });

        promises.get = $http
            .get(url)
            .then(_format)
            .finally(() => {
                promises.get = null;
            });

        return promises.get;
    }

    return service;
}

export default PatientStatusHistoryService;
