/** @ngInject */
function UnassignedPatientConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.associatedPatients',
        url: '/unassignedPatient/:associationQueueId',
        views: {
            '': 'unassignedPatient',
        },
    });
}

export default UnassignedPatientConfig;
