import * as R from 'ramda';
import { useMutation, useQueryClient } from 'react-query';
import { publishProtocolTemplate } from 'App/services/ProtocolTemplateService';

const usePublishProtocolTemplateMutation = () => {
    const queryClient = useQueryClient();

    return useMutation((uuid) => publishProtocolTemplate(uuid), {
        onSuccess: (template) => {
            return queryClient.setQueryData(
                ['protocol', 'templates'],
                R.map(R.ifElse(R.propEq(template.uuid, 'uuid'), R.always(template), R.identity))
            );
        },
    });
};

export default usePublishProtocolTemplateMutation;
