import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as uuid from 'uuid';
import Button from 'react-bootstrap/lib/Button';
import { FaPlus, FaTimes } from 'react-icons/fa';
import hasLength from '../../../../utils/hasLength';
import Select from 'react-select';
import { QUESTION_TYPES } from 'App/features/ProtocolManager/constants';
import DatePicker from 'react-datepicker';
import CustomDatePickerInput from './CustomDatePickerInput';
import FormControl from 'react-bootstrap/lib/FormControl';
import { useFormikContext } from 'formik';
import { groupName as groupNameProp, condition as conditionProp, group as groupProp } from '../AddRule.proptypes';

const propTypes = {
    groupName: groupNameProp,
    group: groupProp,
    condition: conditionProp,
    conditionIdx: PropTypes.number.isRequired,
};

const defaultProps = {
    groupName: undefined,
    group: undefined,
    condition: undefined,
};

function FilterField({ groupName, conditionIdx, condition, group }) {
    const { values: formikValues, setValues } = useFormikContext();
    const lens = R.lensPath(['conditions', groupName, 'conditions', conditionIdx, 'filters']);

    const addFilter = () => {
        return R.compose(
            setValues,
            R.over(
                lens,
                R.append({
                    key: uuid.v4(),
                    operator: null,
                    value: '',
                })
            )
        )(formikValues);
    };

    const removeFilter = (index) => {
        return R.compose(setValues, R.over(lens, R.remove(index, 1)))(formikValues);
    };

    return (
        <div className="filter-cell" key={condition.key}>
            {conditionIdx > 0 && <div className="and-text">and</div>}
            <Button
                bsSize="sm"
                className="remove-filter-group"
                aria-label="Remove filter group"
                title="Remove filter group"
                onClick={() => {
                    R.compose(
                        setValues,
                        R.over(R.lensPath(['conditions', groupName, 'conditions']), R.remove(conditionIdx, 1))
                    )(formikValues);
                }}
            >
                <FaTimes />
            </Button>

            <div className="filters">
                {R.ifElse(
                    hasLength,
                    R.addIndex(R.map)((filter, filterIdx) => {
                        return (
                            <div key={filter.key} className="filter-options">
                                <div className="or-text">{filterIdx > 0 && <div>or</div>}</div>
                                <Select
                                    classNamePrefix="react-select"
                                    className="filter-operator"
                                    aria-label="Filter Operators"
                                    options={group.field.operators}
                                    onChange={(option) => {
                                        R.compose(
                                            setValues,
                                            R.set(R.compose(lens, R.lensPath([filterIdx, 'operator'])), option)
                                        )(formikValues);
                                    }}
                                    value={filter.operator}
                                />

                                {/*Conditionally render the value selection fields*/}

                                {/* eslint-disable-next-line no-nested-ternary */}
                                {hasLength(group.field.availableOptions) ? (
                                    <Select
                                        className="filter-value"
                                        aria-label="Filter Value Options"
                                        options={group.field.availableOptions}
                                        value={filter.value}
                                        onChange={(option) => {
                                            R.compose(
                                                setValues,
                                                R.set(R.compose(lens, R.lensPath([filterIdx, 'value'])), option)
                                            )(formikValues);
                                        }}
                                        style={{
                                            height: 38,
                                            marginRight: '.5rem',
                                        }}
                                    />
                                ) : group.field.type === QUESTION_TYPES.DATE ? (
                                    <DatePicker
                                        selected={filter.value}
                                        startDate={new Date()}
                                        customInput={<CustomDatePickerInput />}
                                        dateFormat="yyyy-MM-dd"
                                        onChange={(date) => {
                                            R.compose(
                                                setValues,
                                                R.set(R.compose(lens, R.lensPath([filterIdx, 'value'])), date)
                                            )(formikValues);
                                        }}
                                    />
                                ) : (
                                    <FormControl
                                        className="filter-value"
                                        aria-label="Filter Value"
                                        type={group.field.type}
                                        value={filter.value}
                                        onChange={(event) => {
                                            const { value } = event.target;

                                            let next = value;
                                            if (group.field.type === 'number') {
                                                const num = Number(value);
                                                if (!Number.isNaN(num)) {
                                                    next = num.toString();
                                                }
                                            }

                                            R.compose(
                                                setValues,
                                                R.set(R.compose(lens, R.lensPath([filterIdx, 'value'])), next)
                                            )(formikValues);
                                        }}
                                        style={{
                                            height: 38,
                                            marginRight: '.5rem',
                                        }}
                                    />
                                )}

                                <Button
                                    bsSize="small"
                                    bsStyle="link"
                                    className="remove-filter"
                                    aria-label="Remove filter"
                                    title="Remove filter"
                                    onClick={R.thunkify(removeFilter)(filterIdx)}
                                >
                                    <FaTimes />
                                </Button>

                                {R.equals(filter, R.last(condition.filters)) && (
                                    <Button
                                        bsSize="small"
                                        className="add-filter"
                                        aria-label="Add filter"
                                        title="Add filter"
                                        bsStyle="link"
                                        onClick={addFilter}
                                    >
                                        <FaPlus />
                                    </Button>
                                )}
                            </div>
                        );
                    }),
                    R.always(null)
                )(condition.filters)}
            </div>
        </div>
    );
}

FilterField.propTypes = propTypes;
FilterField.defaultProps = defaultProps;

export default FilterField;
