/** @ngInject */
function EndMedicationController($filter) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.submit = submit;

    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.medication) {
                ctrl.medication = angular.copy(ctrl.resolve.medication);
            }
        }
    }

    function submit() {
        ctrl.saving = true;
        ctrl.medication.summary = `Ending ${ctrl.medication.name} because of ${
            ctrl.medication.end_reason
        }, on ${$filter('date')(ctrl.medication.end, 'yyyy/MM/dd')}`;
        ctrl.close({
            $value: ctrl.medication,
        });
    }
}

export default EndMedicationController;
