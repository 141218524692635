import React, { useEffect, useState, useMemo } from 'react';
import ActivityDueDate from '../ActivityDueDate';
import { ACTIVITY_STATUS_DATE_TYPES } from '../constants';
import * as R from 'ramda';
import { mapProfileFilterOptions } from '../utils';
import ProtocolService from '../../../services/ProtocolService';
import AddConditionButton from '../AddConditionButton';
import ActivityToTrigger from 'App/features/ProtocolManager/AddRule/ActivityToTrigger';
import FilterFields from 'App/features/ProtocolManager/FilterFields';
import { useFormikContext } from 'formik';

const propTypes = {};

const defaultProps = {};

function ChangeOfReferralActivityStatusForm() {
    const [filterFields, setFilterFields] = useState([]);

    const formik = useFormikContext();

    const activityType = formik.values.triggeredFrom?.value;

    const dateTypes = useMemo(() => {
        return R.propOr({}, activityType, ACTIVITY_STATUS_DATE_TYPES);
    }, [activityType]);

    useEffect(() => {
        if (!R.isNil(activityType)) {
            ProtocolService.getReferralActivityStatusChangeFilterOptions(activityType).then(
                R.compose(setFilterFields, mapProfileFilterOptions)
            );
        }
    }, [activityType]);

    return (
        <>
            <ActivityToTrigger />

            <ActivityDueDate dateTypeOptions={R.values(dateTypes)} />

            <FilterFields fields={filterFields} />

            <AddConditionButton />
        </>
    );
}

ChangeOfReferralActivityStatusForm.propTypes = propTypes;
ChangeOfReferralActivityStatusForm.defaultProps = defaultProps;

export default ChangeOfReferralActivityStatusForm;
