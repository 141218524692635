// SSO
const xmlBase = {
    uat: `<?xml version="1.0"?>
        <EntityDescriptor xmlns="urn:oasis:names:tc:SAML:2.0:metadata" xmlns:ds="http://www.w3.org/2000/09/xmldsig#" entityID="https://stmuat.therigy.com" ID="https___stmuat_therigy_com">
        <SPSSODescriptor protocolSupportEnumeration="urn:oasis:names:tc:SAML:2.0:protocol">
            <KeyDescriptor use="signing">
            <ds:KeyInfo>
                <ds:X509Data>
                <ds:X509Certificate>
                MIICljCCAX4CCQCaf7N8iTAejjANBgkqhkiG9w0BAQsFADANMQswCQYDVQQGE
                wJhczAeFw0xOTEyMDQxNDM1NTRaFw0yOTEyMDExNDM1NTRaMA0xCzAJBgNVBA
                YTAmFzMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3ZUXge9NXmp
                YZ+QAKEj9LuZvhZ5kd24rdlyTUiL697kS0MnDg5FVvakDoeOOdcJqNOa1Hcpz
                OtkqNRPXDEQQ8n6BAz19taq0TjP2Soe8yNXbnt3kUhpJiVcoKPdPMx6fVaLF1
                JcMZsK86OttJFghlFejLaJHhYcor+eVcKJbVesoOGhtadCMrtci5Lt4PAAinN
                4tdR/R7rrh4VCK3ChVz72dOrpp3WqydkOsJ8nZGaZX8clrOAHe7DrdjaLwJZV
                UH4pJ5pSil85yoH80SKcf7In7tuEa1/RQ/jqp3ER8FqQtJH9NyGzQNPX0nGfv
                9xY++Qs12IbuVSfI9VmvKD18BQIDAQABMA0GCSqGSIb3DQEBCwUAA4IBAQAD6
                +TOp67p94YeSktsQuL0LiCLg3ld45qC2VZidKVlgaXGenlgNzS80pD+4+TUoe
                UoY2d1Kw0XN+V9YH4fKelXej6BIpDot1TWUJb0hGW/+6HJYbBNe9yIgmgjo8L
                MgsZdW/yGuqwFGfHjlrjBdxRwrD64JRn3XhK5caD9o/7ozk1JFc2zGRD8qo7q
                rOm/gvc00ymbTS8BZtIxEdX3Fr4dde2vkAPUiWZ3RjNznTf1MYlNXdU5QY1di
                CimXxndqcP+luYsAPZrCeUJHQUnHMa/ujqZqeS7tMC7ggIHTuHcg8wg4+eUjO
                k3q7kiis6fXihJweiZwOZ6q+qVGfOBC2Dh
                </ds:X509Certificate>
                </ds:X509Data>
            </ds:KeyInfo>
            </KeyDescriptor>
            <SingleLogoutService Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect" Location="https://stmuat.therigy.com/tas/1.0.0/saml2/logout/"/>
            <NameIDFormat>urn:oasis:names:tc:SAML:2.0:nameid-format:persistent</NameIDFormat>
            <AssertionConsumerService index="1" isDefault="true" Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST" Location="https://stmuat.therigy.com/tas/1.0.0/saml2/"/>
        </SPSSODescriptor>
        </EntityDescriptor>`,
    prod: `<?xml version="1.0"?>
    <EntityDescriptor xmlns="urn:oasis:names:tc:SAML:2.0:metadata" xmlns:ds="http://www.w3.org/2000/09/xmldsig#" entityID="https://resources.therigy.com" ID="https___resources_therigy_com">
        <SPSSODescriptor protocolSupportEnumeration="urn:oasis:names:tc:SAML:2.0:protocol">
            <KeyDescriptor use="signing">
                <ds:KeyInfo>
                    <ds:X509Data>
                        <ds:X509Certificate>MIICsDCCAZgCCQD4IhnnKyFyZTANBgkqhkiG9w0BAQsFADAaMQswCQYDVQQGEwJV
                            UzELMAkGA1UECAwCRkwwHhcNMTkxMjAzMTcwNTUxWhcNMjkxMTMwMTcwNTUxWjAa
                            MQswCQYDVQQGEwJVUzELMAkGA1UECAwCRkwwggEiMA0GCSqGSIb3DQEBAQUAA4IB
                            DwAwggEKAoIBAQC5xifH7yMN+CETFO4b0a/1Vs2j54c/leW9ZtNruULCEREABUku
                            VNPl3imt5nWFB5Ij9T1Y4ZHfdQ0UOYnlliAC50kAPwHp0cdRMpJuapb0n0Xiun87
                            1QESde3MWwn/mNOUbRoQzs4I+IFGXMHbUis3USKkf8Zjlqa0dskmPqXQmL9UYhzC
                            z9YF8zTNd3145RTPyBWZ3shlbvIgzkCep/5ElEOZNtNsGD+JVT5ZdU0KLZAX8ImV
                            pG2xfLxD/w1aKWxv8W0MSePxj7wOs9kGFEdTotPU42HFMhV/izCg5t3N9kuZ4gjO
                            y2TqKyY6Ix178qbhTOkQvzHBz++2RDb1ZxNHAgMBAAEwDQYJKoZIhvcNAQELBQAD
                            ggEBAESDLmd+IuySKsxeugCARsF3xu+YNWurXSXgtcRb9a1mmx3LMD1/3LiYHMDo
                            PPceXdBGf++NeywBvXLLEwmOkaG8a8jZR6hgqx9GMS8T0y8jRPIbjiZxEES89/Gx
                            A5A0XMeeJ04o9uFQFBWLeKoXyhTn8UqqaGnnu4/h7/EvLUcah45kSOg0SYQZ/xNu
                            FQiSK+ZpIpMXaE6EQ2s9pWB23xG7EFjpacttixxWAo9yrPIaEPiLgbcUjnUg6XoN
                            7HPxFDtm9oUEcdUfOMYujBaGyCWinessI+yiS20gIM6lJZoBZuDFfXb/m0LaNPoA
                            TTBnpv9M7JIQKCgIckeczBVrPNA=
                        </ds:X509Certificate>
                    </ds:X509Data>
                </ds:KeyInfo>
            </KeyDescriptor>
            <SingleLogoutService Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-REDIRECT" Location="https://resources.therigy.com/tas/1.0.0/saml2/logout/"/>
            <NameIDFormat>urn:oasis:names:tc:SAML:2.0:nameid-format:persistent</NameIDFormat>
            <AssertionConsumerService index="1" isDefault="true" Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST" Location="https://resources.therigy.com/tas/1.0.0/saml2/"/>
        </SPSSODescriptor>
    </EntityDescriptor>`,
};
export { xmlBase };
