const R = require('ramda');

const localhostEndpoint = 'https://stm.local';
const therigyEndpoint = process.env.ENVIRONMENT_URL || localhostEndpoint;
const environmentGroup = process.env.ENVIRONMENT_GROUP;
const environment = process.env.ENVIRONMENT;
let port;

switch (environmentGroup) {
    case 'test':
        port = false;
        break;
    case 'build':
        port = 80;
        break;
    default:
        port = 443;
        break;
}

const env = Object.freeze({
    therigyEndpoint,
    catchPhrase: 'Therigy | Improving therapy, enhancing lives',
    cdnURL: environmentGroup !== 'test' ? 'https://cdn.therigy.com' : 'https://stm.local',

    // STM Configuration
    disableAnimations: process.env.DISABLE_ANIMATIONS || false,
    isProd: environmentGroup === 'production',
    isQA: environmentGroup === 'qa',
    isStaging: environmentGroup === 'staging',
    isUAT: environmentGroup === 'uat',
    privacyWebsite: 'https://cps.com/consumer-privacy-notice',
    stmEndpoint: 'https://resources.therigy.com',
    stmPort: port || 443,
    supportEmail: 'therigysupport@cps.com',
    supportPhoneNumber: '407.992.8750 x3',
    supportWebsite: 'https://cps.com/technical-support',
    therapyIds: {
        general: '6582172ed711c550075d9d1c6c4ba6',
    },
    localhostEndpoint,

    // AMS Configuration
    amsEndpoint: therigyEndpoint || 'https://stm.local',
    amsPort: port || 443,
    amsSocketEndpoint: therigyEndpoint || 'ws://stm.local',
    amsSocketPath: '/ams/socket/',
    amsSocketPort: port || 443,
    amsVersion: '1.0.0',

    // PES Configuration
    pesEndpoint: therigyEndpoint || 'https://stm.local',
    pesPort: port || 443,
    pesVersion: '1.0.0',

    // RMS Configuration
    rmsEndpoint: therigyEndpoint || 'https://stm.local',
    rmsPort: port || 443,
    rmsVersion: '1.0.0',

    // SLS Configuration
    slsEndpoint:
        // eslint-disable-next-line no-nested-ternary
        environmentGroup === 'test'
            ? 'https://stm.local'
            : environmentGroup === 'build'
            ? 'http://sls'
            : `https://${environmentGroup}-gw-stm.therigy.${
                  environmentGroup === 'production' || environmentGroup === 'uat' ? 'com' : 'net'
              }`,
    slsPort: environmentGroup === 'build' ? 3010 : 443,
    slsStage: environment !== 'test' && environment !== 'build' ? environment : false,
    slsVersion: '1.0.0',

    // TAS Configuration
    tasEndpoint: therigyEndpoint || 'https://stm.local',
    tasPort: port || 443,
    tasVersion: '1.0.0',

    // PIWIK Configuration
    trackingUrl: therigyEndpoint,

    // TRS configuration
    trsVersion: '1.0.0',
    trsEndpoint: therigyEndpoint,
    trsPort: port || 443,
    telerik: {
        endpoint: '',
    },
    ssoEndpoints: {
        'biologicsuat-stm': `${therigyEndpoint}/tas/1.0.0/saml2/biologics`,
        costco: `${therigyEndpoint}/tas/1.0.0/saml2/costco`,
        danafarber: `${therigyEndpoint}/tas/1.0.0/saml2/danafarber`,
        trinity: `${therigyEndpoint}/tas/1.0.0/saml2/trinity`,
    },
    // WALKME Configuration
    walkmeUrl: R.cond([
        [
            R.anyPass([R.equals('uat'), R.equals('production')]),
            R.always(
                'https://cdn.walkme.com/users/f8febc159f7d423e8b2cca9199b48a9d/walkme_f8febc159f7d423e8b2cca9199b48a9d_https.js'
            ),
        ],
        [
            R.equals('staging'),
            R.always(
                'https://cdn.walkme.com/users/f8febc159f7d423e8b2cca9199b48a9d/test/walkme_f8febc159f7d423e8b2cca9199b48a9d_https.js'
            ),
        ],
        [R.T, R.always('')],
    ])(environmentGroup),

    newRelicTracking: !(environmentGroup === 'build' || environmentGroup === 'test'),

    // QOL

    // Email Regex compliant with RFC-5322
    emailRegex:
        '^(([^<>()[]\\.,;:s@]+(.[^<>()[]\\.,;:s@]+)*)|(.+))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$',
});

module.exports = env;
