/**
 * Needed some place to put these reusable values
 */

/**
 * Select options that are used to identify when all items are selected.
 */
const allDiagnosisOption = { id: 'all', code: 'All diagnoses' };

/**
 * Select options that are used to identify when all items are selected.
 */
const allMedicationsOption = { id: 'all', name: 'All medications' };

const dateRelationOptions = [
    { value: 0, label: 'before' },
    { value: 1, label: 'after' },
];

const RULE_TYPES = Object.freeze({
    PROFILE: 'profile',
    ACTIVITY: 'activity',
    INSURANCE: 'insurance',
    REFERRAL_STATUS: 'referral_status',
    REFERRAL_ACTIVITY_STATUS: 'referral_activity_status',
});

const DATE_TYPES = Object.freeze({
    CURRENT_DATE: { value: 2, label: 'Current Date' },
    BIRTH_DATE: { value: 5, label: 'Birth Date' },
    DECEASED_DATE: { value: 6, label: 'Deceased Date' },
});

const INSURANCE_DATE_TYPES = Object.freeze({
    CURRENT_DATE: DATE_TYPES.CURRENT_DATE,
    COVERAGE_EFFECTIVE_DATE: { value: 'coverageEffectiveDate', label: 'Coverage Effective Date' },
    COVERAGE_END_DATE: { value: 'coverageEndDate', label: 'Coverage End Date' },
});

const REFERRAL_STATUS_DATE_TYPES = Object.freeze({
    CURRENT_DATE: DATE_TYPES.CURRENT_DATE,
    RECEIVED_DATE: { value: 'receivedOn', label: 'Received Date' },
    START_DATE: { value: 'createdOn', label: 'Start Date' },
});

const BENEFIT_INVESTIGATION_DATE_TYPES = Object.freeze({
    CURRENT_DATE: DATE_TYPES.CURRENT_DATE,
    COVERAGE_EFFECTIVE_DATE: { value: 'coverageEffectiveDate', label: 'Coverage Effective Date' },
    COVERAGE_END_DATE: { value: 'coverageEndDate', label: 'Coverage End Date' },
});

const PRIOR_AUTHORIZATION_DATE_TYPES = Object.freeze({
    CURRENT_DATE: DATE_TYPES.CURRENT_DATE,
    EFFECTIVE_START_DATE: { value: 'effectiveStartDate', label: 'Effective Start Date' },
    EFFECTIVE_END_DATE: { value: 'effectiveEndDate', label: 'Effective End Date' },
});

const APPEAL_DATE_TYPES = Object.freeze({
    CURRENT_DATE: DATE_TYPES.CURRENT_DATE,
    APPEAL_SUBMISSION_DATE: { value: 'appealSubmissionDate', label: 'Appeal Submission Date' },
    EFFECTIVE_START_DATE: { value: 'effectiveStartDate', label: 'Effective Start Date' },
    EFFECTIVE_END_DATE: { value: 'effectiveEndDate', label: 'Effective End Date' },
});

const FINANCIAL_ASSISTANCE_DATE_TYPES = Object.freeze({
    CURRENT_DATE: DATE_TYPES.CURRENT_DATE,
    APPLICATION_SUBMISSION_DATE: { value: 'applicationSubmissionDate', label: 'Application Submission Date' },
    EFFECTIVE_START_DATE: { value: 'effectiveStartDate', label: 'Effective Start Date' },
    EFFECTIVE_END_DATE: { value: 'effectiveEndDate', label: 'Effective End Date' },
});

const ACTIVITY_STATUS_DATE_TYPES = Object.freeze({
    0: BENEFIT_INVESTIGATION_DATE_TYPES,
    1: PRIOR_AUTHORIZATION_DATE_TYPES,
    2: FINANCIAL_ASSISTANCE_DATE_TYPES,
    3: APPEAL_DATE_TYPES,
    4: PRIOR_AUTHORIZATION_DATE_TYPES,
    5: FINANCIAL_ASSISTANCE_DATE_TYPES,
});

/**
 * There are two types of triggers. These are extracted from the protocol and "normalized" into
 * an array to be shown in a table. As such, it then becomes necessary to identify the type
 * in order to show the two types differently. This provides an enumeration to do just that.
 */
const TRIGGER_TYPES = Object.freeze({
    PROFILE: 'PROFILE',
    ACTIVITY: 'ACTIVITY',
    INSURANCE: 'INSURANCE',
    REFERRAL_STATUS: 'REFERRAL_STATUS',
    REFERRAL_ACTIVITY_STATUS: 'REFERRAL_ACTIVITY_STATUS',
});

/**
 * Enumeration for operators
 */
const OPERATORS = Object.freeze({
    CONTAINS: 'contains',
    EQUALS: '=',
    GREATER_THAN: 'greater_than',
    GREATER_THAN_EQUALS: 'greater_than_equals',
    IS: 'is',
    IS_ANSWERED: 'is_answered',
    LESS_THAN: 'less_than',
    LESS_THAN_EQUALS: 'less_than_equals',
});

/**
 * Mapping from server operators to display operators
 */
const DISPLAY_OPERATORS = Object.freeze({
    '=': 'is equal to',
    contains: 'does contain',
    greater_than: 'is greater than',
    greater_than_equals: 'is greater than or equal to',
    is: 'is equal to',
    is_answered: 'is answered',
    less_than: 'is less than',
    less_than_equals: 'is less than or equal to',
});

const QUESTION_TYPES = Object.freeze({
    RADIO: 'radio',
    SELECT: 'select',
    DATE: 'date',
    NUMBER: 'number',
    MESSAGE: 'message',
    TEXTAREA: 'textarea',
    TEXTBOX: 'textbox',
    CHECKBOX: 'checkbox',
});

/**
 * An "enum" containing the values to be held by the Created By column
 */
const CREATED_BY = Object.freeze({
    MY_PHARMACY: 'My Pharmacy',
    THERIGY: 'Therigy',
});

/**
 * We do have an enum that we pull down, but it's sometimes not available in all areas, sadly.
 */
const REFERRAL_TYPES = Object.freeze({
    0: 'Benefits Investigation',
    1: 'Prior Authorization',
    2: 'Financial Assistance',
    3: 'Appeal',
    4: 'Prior Authorization Renewal',
    5: 'Financial Assistance Renewal',
});

export {
    ACTIVITY_STATUS_DATE_TYPES,
    APPEAL_DATE_TYPES,
    BENEFIT_INVESTIGATION_DATE_TYPES,
    CREATED_BY,
    DATE_TYPES,
    DISPLAY_OPERATORS,
    FINANCIAL_ASSISTANCE_DATE_TYPES,
    INSURANCE_DATE_TYPES,
    OPERATORS,
    PRIOR_AUTHORIZATION_DATE_TYPES,
    QUESTION_TYPES,
    REFERRAL_STATUS_DATE_TYPES,
    REFERRAL_TYPES,
    RULE_TYPES,
    TRIGGER_TYPES,
    allDiagnosisOption,
    allMedicationsOption,
    dateRelationOptions,
};
