import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { ControlLabel } from 'react-bootstrap';

const propTypes = {
    column: PropTypes.string.isRequired,
};

const defaultProps = {
};

function ToggleAll({ column }) {
    const { values, handleChange, setValues } = useFormikContext();

    const toggle = (change) => {
        handleChange(change);
        const newValue = change.target.checked;
        for (const m of values.medications) {
            if (!newValue && column === 'manage' && m.enroll) {
                // Don't disable manage if enroll is enabled.
                m[column.toString()] = true;
            } else if (newValue && column === 'enroll') {
                // If enroll is set true so must be manage.
                m.enroll = newValue;
                m.manage = newValue;
            } else {
                m[column.toString()] = newValue;
            }
        }

        // If everything is not checked set the "all" checkboxes accordingly.
        values.all.enroll = values.medications.filter((r) => r.enroll === false).length === 0;
        values.all.manage = values.medications.filter((r) => r.manage === false).length === 0;
        setValues(values);
    };
    return (
        <>
            <Field
                onChange={(e) => {
                    toggle(e);
                }}
                data-testid={`select-all-${column}`}
                type="checkbox"
                name={`all.${column}`}
            />
            &nbsp;
            <ControlLabel htmlFor={`all.${column}`}>select all</ControlLabel>
        </>
    );
}

ToggleAll.propTypes = propTypes;
ToggleAll.defaultProps = defaultProps;

export default ToggleAll;
