import angular from 'angular';

import MyAccountController from './my-account.controller';
import MyAccountConfig from './my-account.config';

export default angular
    .module('components.myAccount', ['ngTable', 'ui.select'])
    .component('myAccount', {
        bindings: {
            ehrProviders: '<',
            onChangeAccount: '&',
            user: '<',
        },
        controller: MyAccountController,
        template: require('./my-account.html'),
    })
    .config(MyAccountConfig).name;
