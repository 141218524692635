import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const defaultContext = {
    itemToDelete: null,
    itemToPublish: null,
    setItemToDelete: Function,
    setItemToPublish: Function,
    showCreate: false,
    setShowCreate: Function,
};

const builderContext = createContext(defaultContext);
const { Provider, Consumer } = builderContext;

function BuilderContextProvider({ children }) {
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToPublish, setItemToPublish] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);

    const value = useMemo(
        () => ({
            itemToDelete,
            itemToPublish,
            setItemToPublish,
            setItemToDelete,
            showCreateModal,
            setShowCreateModal,
        }),
        [itemToDelete, itemToPublish, showCreateModal]
    );

    return <Provider value={value}>{children}</Provider>;
}

BuilderContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function useBuilderContext() {
    return useContext(builderContext);
}

export { builderContext, useBuilderContext, BuilderContextProvider, Consumer as BuilderContextConsumer, Provider };
