import React from 'react';
import LatestAppVersion from '../LatestAppVersion';

import SearchBar from './SearchBar';
import Logo from './Logo';
import AccessView from './AccessView';
import IconBar from './IconBar';

const Header = () => {
    return (
        <>
            <LatestAppVersion />
            <header id="header">
                <Logo />
                <div className="search-bar">
                    <SearchBar />
                    <AccessView />
                </div>
                <IconBar />
            </header>
        </>
    );
};

export default Header;
