/** @ngInject */
function PatientProfileAddReferralModalController(_, toastr, rmsService) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.submit = submit;

    function $onInit() {
        ctrl.ngModelOptions = {
            updateOn: 'default blur',
            debounce: {
                default: 1000,
                blur: 0,
            },
        };

        ctrl.medication = null;
    }

    function $onChanges() {
        if (ctrl.resolve.modalData) {
            ctrl.rxs = angular.copy(ctrl.resolve.modalData.rxs);
            ctrl.medications = _.filter(ctrl.resolve.modalData.rxs, (entry) => {
                return entry.status_end === null || entry.status_end >= new Date().toISOString();
            });
            ctrl.patientId = ctrl.resolve.modalData.patientId;
            ctrl.referrals = angular.copy(ctrl.resolve.modalData.referrals);
        }
    }

    function submit() {
        ctrl.saving = true;

        const medicationIds = _.map(ctrl.medication, 'medication_id');
        const referralMedications = [];

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = ctrl.rxs.length; i < len; i++) {
            if (medicationIds.indexOf(ctrl.rxs[i].medication_id) !== -1) {
                referralMedications.push({
                    medication_id: ctrl.rxs[i].medication_id,
                    rx_number_id: ctrl.rxs[i].id,
                });
            }
        }

        return rmsService
            .createReferral(ctrl.patientId, {
                medication_creates_referral: false,
                medications: referralMedications,
            })
            .then((res) => {
                toastr.success('The referral was created successfully.');
                ctrl.close({ $value: res });
                return res;
            })
            .catch((res) => {
                if (res.status === 404 || res.status === 403) {
                    toastr.error(
                        'The referral was not found or you do not have permission to access it. ' +
                            'Please contact your STM administrator.'
                    );
                } else if (!res.status) {
                    toastr.error(res);
                } else {
                    toastr.error('Referral was not created successfully. Please try again.');
                }
                ctrl.dismiss({ $value: ctrl.resolve });
                return res;
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }
}

export default PatientProfileAddReferralModalController;
