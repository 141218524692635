import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { Col, Row } from 'react-bootstrap';
import styled from '@emotion/styled';
import { createActivity } from 'App/services/PatientActivityService';
import { Formik } from 'formik';
import * as R from 'ramda';
import * as yup from 'yup';
import { errorHandler } from 'App/utils';
import { toast } from 'react-toastify';
import SelectField from 'Lib/form/SelectField';
import SelectAllField from 'Lib/form/SelectAllField';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';
import CancelButton from 'Lib/form/CancelButton';
import { generatePath, useHistory } from 'react-router-dom';
import { activityRoute } from 'App/common/routeLookup';
import config from 'App/components/config/config';
import useGetTherapyAssessments from '../../hooks/useGetTherapyAssessments';
import useGetTherapyCategories from '../../hooks/useGetTherapyCategories';

const PaddedRow = styled(Row)`
    padding: 0.5% 0;
`;

const propTypes = {
    onHide: PropTypes.func.isRequired,
    patientId: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    patientTherapies: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
    patientId: '',
    patientTherapies: [],
};

const validationSchema = yup.object().shape({
    activity: yup.object().when('therapeuticCategory', {
        is: (therapeuticCategory) => therapeuticCategory,
        then: (schema) => schema.nullable().required('Please enter an activity.'),
        otherwise: (schema) => schema.nullable(),
    }),
    therapeuticCategory: yup.object().nullable().required('Please select a therapeutic category.'),
});

function AddPatientActivityModal({ onHide, patientId, patientTherapies }) {
    const history = useHistory();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedAdditionalCategory, setSelectedAdditionalCategory] = useState(null);
    const title = 'Add Activity';

    const { data: availableCategories = [], isLoading: isTherapyCategoriesLoading } =
        useGetTherapyCategories(patientTherapies);
    const categoryId = R.prop('id', selectedCategory);
    const { data: availableActivity = [], isLoading: isTherapyAssessmentsLoading } =
        useGetTherapyAssessments(categoryId);

    const additionalAvailableCategories = useMemo(() => {
        let additionalCategories;
        // Remove general from the additional TC categories if general TC is not present in the patient profile
        if (selectedCategory?.id === config.therapyIds.general) {
            additionalCategories = [...availableCategories];
            const patientTherapyIds = patientTherapies.map((therapy) => therapy.id);
            const filteredCategories = additionalCategories.filter((category) =>
                patientTherapyIds.includes(category.id)
            );
            additionalCategories = filteredCategories;
        }
        return additionalCategories;
    }, [selectedCategory, availableCategories, patientTherapies]);

    const addActivity = useCallback(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        (selectedCategory, selectedActivity, additionalAvailableCategories) => {
            const groupId = [];
            let addGroupIds = [];
            if (selectedCategory && selectedActivity) {
                const pollId = selectedActivity.id;
                groupId.push(selectedActivity.groupId);
                if (selectedAdditionalCategory) {
                    addGroupIds = selectedAdditionalCategory.map((additionalCategory) => additionalCategory.id);
                } else if (selectedCategory?.name === 'General' && additionalAvailableCategories?.length === 1) {
                    // if the patient only has 1 TC, then the activity should automatically be assigned to the TC and the user doesn't have to pick since it's only 1 option
                    addGroupIds = [additionalAvailableCategories[0].id];
                }
                const params = {
                    patientId,
                    pollId,
                    additionalGroupIds: addGroupIds,
                };
                createActivity(params)
                    .then((res) => {
                        toast.success('Your activity has been added successfully');
                        history.push(
                            generatePath(activityRoute, {
                                patientId,
                                activityId: res.addActivities.id,
                            })
                        );
                    })
                    .catch(errorHandler)
                    .finally(() => {
                        onHide(true);
                    });
            }
        },
        [patientId, onHide, history, selectedAdditionalCategory]
    );

    return (
        <Modal show onHide={onHide} backdrop="static" className="createEnrollpatientModal ">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Formik
                initialValues={{
                    therapeuticCategory: null,
                    activity: null,
                    additionalCategory: [],
                }}
                onSubmit={(values, { setSubmitting, setFieldValue }) => {
                    setFieldValue('errorMsg', null);
                    if (!values) {
                        setSubmitting(false);
                        return;
                    }
                    setSubmitting(true);
                    addActivity(values?.therapeuticCategory, values?.activity, additionalAvailableCategories);
                }}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, values, setValues, setFieldValue, errors, setErrors, touched, setTouched }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <PaddedRow>
                                    <Col md={12}>
                                        <SelectField
                                            options={availableCategories}
                                            isClearable
                                            required
                                            label="Therapeutic Category"
                                            fieldName="therapeuticCategory"
                                            getOptionValue={R.prop('id')}
                                            getOptionLabel={R.prop('name')}
                                            onChange={(option) => {
                                                R.compose(R.thunkify(setSelectedCategory)(option), setValues)(values);
                                                setFieldValue('therapeuticCategory', option);
                                                setFieldValue('activity', null);
                                                setFieldValue('additionalCategory', []);
                                                setErrors({});
                                                setTouched({}, false);
                                            }}
                                            isLoading={isTherapyCategoriesLoading}
                                        />
                                    </Col>
                                </PaddedRow>
                                {selectedCategory && (
                                    <PaddedRow>
                                        <Col md={12} className={errors.activity && touched.activity ? 'has-error' : ''}>
                                            <SelectField
                                                options={R.filter(R.prop('id'), availableActivity)}
                                                isClearable
                                                getOptionValue={R.prop('id')}
                                                getOptionLabel={R.prop('name')}
                                                fieldName="activity"
                                                label="Activity"
                                                required
                                                onChange={(option) => {
                                                    setValues({
                                                        ...values,
                                                        activity: option,
                                                        additionalCategory: [],
                                                    });
                                                }}
                                                value={values.activity}
                                                isLoading={isTherapyAssessmentsLoading}
                                            />
                                        </Col>
                                    </PaddedRow>
                                )}
                                {values.activity &&
                                    selectedCategory?.name === 'General' &&
                                    additionalAvailableCategories?.length > 1 && (
                                        <PaddedRow>
                                            <Col md={12}>
                                                <SelectAllField
                                                    options={additionalAvailableCategories}
                                                    placeholder="Select"
                                                    isClearable
                                                    required
                                                    onValidate={(value) => {
                                                        if (value.length < 1) {
                                                            return 'Please select one of more corresponding therapeutic categories for this activity.';
                                                        }
                                                    }}
                                                    getOptionValue={R.prop('id')}
                                                    getOptionLabel={R.prop('name')}
                                                    fieldName="additionalCategory"
                                                    label="Select one or more corresponding therapeutic categories for this activity"
                                                    className="form-control react-select"
                                                    triggerPropValue="all"
                                                    onChange={(option) => {
                                                        R.compose(
                                                            R.thunkify(setSelectedAdditionalCategory)(option),
                                                            setValues
                                                        )(values);
                                                        setFieldValue('additionalCategory', option);
                                                    }}
                                                    value={values.additionalCategory}
                                                />
                                            </Col>
                                        </PaddedRow>
                                    )}
                            </Modal.Body>
                            <Modal.Footer>
                                <FormButtonGroup>
                                    <CancelButton onClick={onHide} />
                                    <SubmitButton>Add Activity</SubmitButton>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
}

AddPatientActivityModal.propTypes = propTypes;
AddPatientActivityModal.defaultProps = defaultProps;

export default AddPatientActivityModal;
