import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useMemo, useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { v4 as uuid } from 'uuid';
import { hasLength } from '../../utils';

import './PatientHighRiskIndicator.scss';
import useFetchPatientHighRiskReasons from 'App/hooks/useFetchPatientHighRiskReasons';

const propTypes = {
    patientId: PropTypes.string.isRequired,
    showOnHover: PropTypes.bool,
};

const defaultProps = {
    showOnHover: false,
};

function PatientHighRiskIndicator({ patientId, showOnHover }) {
    const tooltipId = useRef(uuid());

    const { data, isLoading } = useFetchPatientHighRiskReasons(patientId);

    const highRiskReasons = useMemo(() => {
        if (data && !isLoading) {
            return R.values(data);
        }
        return [];
    }, [data, isLoading]);

    const tooltip = (
        <Tooltip id={tooltipId.current} className="high-risk-reasons">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="control-label">High-Risk Reasons</label>
            <ul>{R.compose(R.map((reason) => <li key={reason.id}>{reason.reason}</li>))(highRiskReasons)}</ul>
        </Tooltip>
    );

    return (
        <span className="patient-high-risk-indicator">
            {hasLength(highRiskReasons) ? (
                <OverlayTrigger
                    trigger={showOnHover ? ['hover', 'focus'] : 'click'}
                    placement="right"
                    rootClose
                    overlay={tooltip}
                >
                    <i className="fa fa-fw fa-exclamation-triangle high-risk-indicator" aria-label="High Risk" />
                </OverlayTrigger>
            ) : (
                <i className="fa fa-fw fa-exclamation-triangle high-risk-indicator" aria-label="High Risk" />
            )}
        </span>
    );
}

PatientHighRiskIndicator.propTypes = propTypes;
PatientHighRiskIndicator.defaultProps = defaultProps;

export default PatientHighRiskIndicator;
