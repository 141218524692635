import angular from 'angular';

import QuestionDiffConfig from './question-diff.config';
import QuestionDiffController from './question-diff.controller';

export default angular
    .module('components.questionDiff', [])
    .component('questionDiff', {
        controller: QuestionDiffController,
        template: require('./question-diff.html'),
    })
    .config(QuestionDiffConfig).name;
