import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ViewMessageHeader = () => {
    return (
        <Row className="message-header">
            <Col className="message-table-header message-table-header-delete" md={1}>
                Delete
            </Col>
            <Col md={11}>
                <Row>
                    <Col className="message-table-header" md={6}>
                        Subject
                    </Col>
                    <Col className="message-table-header" md={4}>
                        Department
                    </Col>
                    <Col className="message-table-header" md={2}>
                        Date
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ViewMessageHeader;
