import React from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Button, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import MessagePreview from './components/MessagePreview';
import LaddaButton from '@/app/components/LaddaButton';
import FormButtonGroup from '@/app/components/styles/FormButtonGroup';
import useSendMassTextingMutation from '@/app/hooks/useSendMassTextingMutation';

const PaddedRow = styled(Row)`
    padding: 0.5% 0;
`;

const propTypes = {
    patientId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    selectedTemplate: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

const defaultProps = {};

const SendOneTimeTextModal = ({ patientId, onClose, selectedTemplate }) => {
    const { mutateAsync, isLoading } = useSendMassTextingMutation(false);

    const onSend = async () => {
        try {
            await mutateAsync({
                message: selectedTemplate.content,
                selectedPatients: [patientId],
                patientMessagesTemplateId: selectedTemplate.uuid,
                queueReason: 'oneTime',
            });
            toast.success('Message sent successfully');
            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal show={selectedTemplate !== null} backdrop="static">
            <Modal.Header closeButton={false}>
                <Modal.Title className="header">Send One-Time Text</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PaddedRow>
                    <Col md={12}>
                        <MessagePreview content={selectedTemplate?.content} />
                    </Col>
                    <Col md={12} style={{ paddingTop: '15px' }}>
                        Are you sure you want to send this text? <b>This will send immediately.</b>
                    </Col>
                </PaddedRow>
            </Modal.Body>
            <Modal.Footer>
                <FormButtonGroup>
                    <Button bsStyle="default" name="cancel" className="float-right" onClick={onClose}>
                        Cancel
                    </Button>
                    <LaddaButton
                        bsStyle="primary"
                        name="send"
                        className="float-right"
                        onClick={onSend}
                        isLoading={isLoading}
                    >
                        Send
                    </LaddaButton>
                </FormButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

SendOneTimeTextModal.propTypes = propTypes;
SendOneTimeTextModal.defaultProps = defaultProps;

export default SendOneTimeTextModal;
