import angular from 'angular';
import WorkQueueController from './work-queue.controller';
import WorkQueueConfig from './work-queue.config';
import WorkQueueActivityTypeFilterController from './filters/work-queue-activity-type-filter/work-queue-activity-type-filter.controller';
import WorkQueueBranchFilterController from './filters/work-queue-branch-filter/work-queue-branch-filter.controller';
import WorkQueueDateRangeFilterController from './filters/work-queue-date-range-filter/work-queue-date-range-filter.controller';
import WorkQueueSavedFiltersController from './filters/work-queue-saved-filters/work-queue-saved-filters.controller';
import WorkQueueTherapeuticCategoryFilterController from './filters/work-queue-therapeutic-category-filter/work-queue-therapeutic-category-filter.controller';
import WorkQueuePredictiveModelFilterController from './filters/work-queue-predictive-model-filter/work-queue-predictive-model-filter.controller';

export default angular
    .module('components.workQueue', ['ngTable'])
    .component('workQueue', {
        bindings: {
            user: '<',
        },
        controller: WorkQueueController,
        template: require('./work-queue.html'),
    })
    .config(WorkQueueConfig)
    .component('workQueueActivityTypeFilter', {
        controller: WorkQueueActivityTypeFilterController,
        bindings: {
            activityTypes: '<',
            allTypes: '<',
            assignedToMe: '<',
            onlyMine: '<',
            highRisk: '<',
            onChangeFilter: '&',
            pccOnly: '<',
            predictiveModelRisk: '<',
            user: '<',
        },
        template: require('./filters/work-queue-activity-type-filter/work-queue-activity-type-filter.html'),
    })
    .component('workQueueBranchFilter', {
        controller: WorkQueueBranchFilterController,
        bindings: {
            allBranches: '<',
            branches: '<',
            onChangeFilter: '&',
            user: '<',
            visibleBranches: '<',
        },
        template: require('./filters/work-queue-branch-filter/work-queue-branch-filter.html'),
    })
    .component('workQueueDateRangeFilter', {
        controller: WorkQueueDateRangeFilterController,
        bindings: {
            dateStart: '<',
            dateEnd: '<',
            dateRange: '<',
            onChangeFilter: '&',
        },
        template: require('./filters/work-queue-date-range-filter/work-queue-date-range-filter.html'),
    })
    .component('workQueueSavedFilters', {
        controller: WorkQueueSavedFiltersController,
        bindings: {
            filters: '<',
            dateRange: '<',
            onChangeFilter: '&',
        },
        template: require('./filters/work-queue-saved-filters/work-queue-saved-filters.html'),
    })
    .component('workQueuePredictiveModelFilter', {
        controller: WorkQueuePredictiveModelFilterController,
        bindings: {
            predictiveModelRisk: '<',
            onChangeFilter: '&',
        },
        template: require('./filters/work-queue-predictive-model-filter/work-queue-predictive-model-filter.html'),
    })
    .component('workQueueTherapeuticCategoryFilter', {
        controller: WorkQueueTherapeuticCategoryFilterController,
        bindings: {
            allCategories: '<',
            groupIds: '<',
            onChangeFilter: '&',
        },
        template: require('./filters/work-queue-therapeutic-category-filter/work-queue-therapeutic-category-filter.html'),
    }).name;
