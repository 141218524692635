import useFetchPatientRx from 'App/hooks/useFetchPatientRx';
import { useQuery } from 'react-query';
import PESService from 'App/services/PESService';
import { useMemo } from 'react';
import { formatKey, getAvailableOptions, getSelectedOptions, groupOptions } from '../utils.js';
import * as R from 'ramda';
import useFetchMedicationAssociations from 'App/components/Assessments/hooks/useFetchMedicationAssociations';

function useMedAssociationProcessor(patientId, activityId) {
    /**
     * Patient Medication
     * This is the "main" data and is keyed by the patient medication ID
     */
    const patientMedications = useQuery(
        ['patient', patientId, 'patient-medication'],
        () => {
            /**
             * Patient ID is optional here to support the AssessmentPreview, which needs to render without a patientId
             */
            return !patientId
                ? null
                : PESService.getMedication(patientId, { includeRx: true, includeInactiveRx: true });
        },
        {
            refetchOnMount: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    /**
     * Patient Rx
     * This has similar data, but is keyed by medication ID, which is not the same as the
     * patient medication join table.
     */
    const patientMedicationsAndRxs = useFetchPatientRx(patientId);

    /**
     * Med Associations
     */
    const medicationAssociations = useFetchMedicationAssociations(patientId, activityId);

    return useMemo(() => {
        if (patientMedicationsAndRxs.isLoading || patientMedications.isLoading || medicationAssociations.isLoading) {
            return {
                isLoading: true,
                availableOptions: [],
                groupedAvailableOptions: [],
                selectedKeys: [],
                selectedOptions: [],
                singleMedKey: null,
                refetch: medicationAssociations.refetch,
            };
        }

        const selectedOptions = getSelectedOptions(
            patientId,
            activityId,
            medicationAssociations.data,
            patientMedicationsAndRxs.data
        );
        const selectedKeys = selectedOptions.map(formatKey);
        const availableOptions = getAvailableOptions(patientMedications.data, patientMedicationsAndRxs.data);

        const singleMedKey = R.compose(R.ifElse(R.isNil, R.identity, formatKey), R.head)(availableOptions);

        const groupedAvailableOptions = groupOptions(availableOptions);

        return {
            isLoading: false,
            availableOptions,
            groupedAvailableOptions,
            selectedKeys,
            selectedOptions,
            singleMedKey,
            refetch: medicationAssociations.refetch,
        };
    }, [
        activityId,
        medicationAssociations.data,
        medicationAssociations.isLoading,
        patientId,
        patientMedications.data,
        patientMedications.isLoading,
        patientMedicationsAndRxs.data,
        patientMedicationsAndRxs.isLoading,
        medicationAssociations.refetch,
    ]);
}

export default useMedAssociationProcessor;
