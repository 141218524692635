import io from 'socket.io-client';

import { amsSocketEndpoint, amsSocketPath, amsSocketPort } from 'App/components/config/config';

export const socket = io(`${amsSocketEndpoint}:${amsSocketPort}`, {
    forceNew: true,
    path: amsSocketPath,
    reconnection: true,
    reconnectionAttempts: 3,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    transports: ['websocket'],
});
