/** @ngInject */
function ReportEngineConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.reportEngine',
        redirectTo(trans) {
            return (
                trans
                    .injector()
                    .getAsync('user')
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line consistent-return
                    .then((res) => {
                        const companyHasAccess = res.company_permissions && res.company_permissions.ReportEngine;
                        const userHasAccess =
                            res.active_branch.Privileges.ReportBuilder || res.active_branch.Privileges.ReportViewer;
                        if (!companyHasAccess || !userHasAccess) {
                            return {
                                state: 'app.403',
                                params: {
                                    permissionName: 'Insights Reporting',
                                },
                            };
                        }
                    })
            );
        },
        url: '/reportEngine',
        views: {
            '': 'reportEngine',
            'pageTitle@^': {
                template: 'Reports',
            },
        },
    });
}

export default ReportEngineConfig;
