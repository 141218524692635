import { useEffect } from 'react';
import { errorHandler } from 'App/utils';
import { fetchPatientTherapeuticCategoryHistory } from 'App/components/PatientProfile/PatientProfile.utils';
import useProfileDataState from './useProfileDataState';

/**
 * Custom hook which API call to fetch medication and rx history
 * @param {string} patientId
 * @param {string} categoryId
 * @returns {Object}
 */
function useTherapeuticCategoryHistory(patientId, categoryId) {
    const {
        data: therapeuticCategoryHistory,
        setData: setTherapeuticCategory,
        isLoading,
        triggerRefetch: triggerHistoryRefetch,
        refetch,
        setIsLoading,
    } = useProfileDataState([]);

    useEffect(() => {
        setIsLoading(true);
        const promiseArray = [fetchPatientTherapeuticCategoryHistory(patientId, categoryId)];

        Promise.all(promiseArray)
            .then(([therapeuticCategoryHistoryRes]) => {
                setTherapeuticCategory(therapeuticCategoryHistoryRes);
            })
            .catch(errorHandler)
            .finally(() => {
                setIsLoading(false);
            });
    }, [patientId, categoryId, refetch, setIsLoading, setTherapeuticCategory]);

    return {
        therapeuticCategoryHistory,
        isLoading,
        triggerHistoryRefetch,
    };
}

export default useTherapeuticCategoryHistory;
