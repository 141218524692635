import { find } from 'lodash';
import * as R from 'ramda';
import { getMessageTemplates } from '@/app/services/PatientMessagingService';

/** @ngInject */
function SelectOneTimeMessageController() {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.doChangeTemplate = doChangeTemplate;
    ctrl.openSendMessage = openSendMessage;

    async function $onInit() {
        ctrl.loading = true;
        ctrl.templates = [];
        ctrl.content = '';
        ctrl.pharmacyName = R.pathOr('', ['resolve', 'oneTimeMessage', 'messageSettings', 'messagingBranchName'], ctrl);
        ctrl.pharmacyPhone = R.pathOr('', ['resolve', 'oneTimeMessage', 'messageSettings', 'messagingPhone'], ctrl);
        try {
            const res = await getMessageTemplates();
            ctrl.templates = res;
        } catch (error) {
            console.error(error);
        } finally {
            ctrl.loading = false;
        }
    }

    function openSendMessage() {
        ctrl.close({
            $value: {
                content: ctrl.content,
                selectedTemplate: ctrl.selectedTemplate,
            },
        });
    }

    function doChangeTemplate(selectedTemplate) {
        const template = find(ctrl.templates, { uuid: selectedTemplate });
        ctrl.content = template.content;
    }
}

export default SelectOneTimeMessageController;
