/** @ngInject */
function UnassignedPatientActivityController(_, moment, pesService, sessionStorageService, toastr) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.showPatientTable = sessionStorageService.getOnUser(`unassignedPatientActivity-toggle`, true);
    ctrl.togglePatientTable = togglePatientTable;

    function togglePatientTable() {
        ctrl.showPatientTable = !ctrl.showPatientTable;
        sessionStorageService.setOnUser(`unassignedPatientActivity-toggle`, ctrl.showPatientTable);
    }

    function $onInit() {
        return pesService
            .getAssociationQueue()
            .then((res) => {
                ctrl.associationQueue = res;
                ctrl.results = _.map(res, (entry) => {
                    return {
                        associatedPatientsId: entry.uuid,
                        birthDate: moment.utc(entry.message.BirthDate).format('YYYY-MM-DD'),
                        externalId: entry.message.ExternalId,
                        firstName: entry.message.FirstName,
                        lastName: entry.message.LastName,
                        zip: entry.message.Location.Zip,
                    };
                });
            })
            .catch((err) => {
                toastr.error(err.message);
            });
    }
}

export default UnassignedPatientActivityController;
