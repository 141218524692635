import React, { useCallback } from 'react';
import Select, { Props } from 'react-select';
import PropTypes from 'prop-types';
import * as R from 'ramda';

/**
 * These actions are internal to react-select. There are more, but these are the only ones we're customizing.
 * @link https://react-select.com/advanced#action-meta
 */
const Actions = Object.freeze({
    SELECT_OPTION: 'select-option',
    REMOVE_VALUE: 'remove-value',
    POP_VALUE: 'pop-value',
});

const isSelectOption = R.equals(Actions.SELECT_OPTION);
const isRemoveValue = R.equals(Actions.REMOVE_VALUE);
const isPopValue = R.equals(Actions.POP_VALUE);

/**
 * Gets the next options in a way that will add all or remove all options available when a single option is selected. It
 * will also add all when all options are added individually.
 *
 * This function is specific to React Select's change handler.
 *
 * @param {object} selectProps - Object that contains two specific props: getOptionValue, triggerPropValue.
 * getOptionValue - Function which takes a single argument that is an object and returns a value from that object to be
 * used as the option value.
 * triggerPropValue - The value which is used to determine if the trigger option was added.
 * @param {object[]} options - All the available options.
 * @param {object[] | null} currentValues - The currently selected values.
 * @param {object | null} option - The option that is _being_ selected. This will likely be provided by React-Select
 * @param {object} action - An object that represents the action, and contains a key with the name of that action. This
 * will likely be provided by React-Select.
 * @returns {object[] | null} - The final array of selected options.
 * @link https://react-select.com/home
 */
const getNextOptions = R.curry(
    ({ getOptionValue, triggerPropValue }, options, currentValues, option, { action, removedValue }) => {
        const findValueHasAll = R.find(R.compose(R.equals(triggerPropValue), getOptionValue));

        if (isSelectOption(action)) {
            // check if all, then set value as all
            const hasAll = findValueHasAll(option);

            if (hasAll) {
                return options;
            }
            const diff = R.symmetricDifferenceWith(R.eqBy(getOptionValue), options, option);

            // When all items are added, treat it as such
            if (diff.length === 1 && R.compose(R.equals(R.head(diff)), findValueHasAll)(options)) {
                return options;
            }
        }

        if (R.anyPass([isPopValue, isRemoveValue])(action)) {
            if (removedValue?.id === triggerPropValue) {
                return null;
            }
            if (currentValues) {
                return currentValues.filter((opt) => opt.id !== triggerPropValue && opt.id !== removedValue?.id);
            }
        }
        return option;
    }
);

function valueStyle(provided, state) {
    if (state.isDisabled) {
        return R.assoc('pointerEvents', 'none', provided);
    }
    return provided;
}

function valueContainerStyle(provided) {
    return R.compose(
        R.assoc('maxHeight', 200),
        R.assoc('overflowY', 'auto'),
        R.assoc('pointerEvents', 'auto')
    )(provided);
}

const propTypes = {
    /**
     * The value to use in order to trigger the "add all" feature. If not provided, feature will not be enabled.
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    triggerPropValue: PropTypes.string,
    ...Props,
};

const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    getOptionValue: R.prop('value'),
};

/**
 * Props are the same as React Select. This is just an abstraction to simplify the use of this configuration.
 * @see https://react-select.com/
 */
function TherigyMultiSelect(props) {
    const { getOptionValue, triggerPropValue } = props;

    const handleChange = useCallback(
        (option, action) => {
            if (triggerPropValue) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/destructuring-assignment
                props.onChange(
                    getNextOptions(
                        {
                            getOptionValue,
                            triggerPropValue,
                        },
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/destructuring-assignment
                        props.options,
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/destructuring-assignment
                        props.value,
                        option,
                        action
                    )
                );
            } else {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/destructuring-assignment
                props.onChange(option, action);
            }
        },
        [getOptionValue, props, triggerPropValue]
    );

    const getMultiValueLabelStyle = useCallback((labelProps, state) => {
        if (state.selectProps.triggerPropValue) {
            const found = R.find(
                R.compose(R.equals(state.selectProps.triggerPropValue), state.selectProps.getOptionValue)
            )(state.getValue());

            const value = state.selectProps.getOptionValue(state.data);
            if (found && value !== state.selectProps.triggerPropValue) {
                return {
                    ...labelProps,
                    color: state.theme.colors.neutral40,
                    backgroundColor: state.theme.colors.neutral5,
                };
            }
            return {
                ...labelProps,
                backgroundColor: state.theme.colors.neutral10,
            };
        }
    }, []);

    return (
        <Select
            {...props}
            onChange={handleChange}
            isMulti
            isClearable
            styles={{
                multiValueLabel: getMultiValueLabelStyle,
                valueContainer: valueContainerStyle,
                multiValue: valueStyle,
                singleValue: valueStyle,
            }}
        />
    );
}

export { getNextOptions };

TherigyMultiSelect.propTypes = propTypes;
TherigyMultiSelect.defaultProps = defaultProps;

export default TherigyMultiSelect;
