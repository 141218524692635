import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
const MessageBody = ({ message }) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: message }} />;
};

export default MessageBody;
