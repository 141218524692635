import PropTypes from 'prop-types';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from 'App/components/errors';

const TherigyErrorBoundary = ({ children }) => {
    return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>;
};

TherigyErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default TherigyErrorBoundary;
