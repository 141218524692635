/** @ngInject */
function UserService(_, $http, $q, $injector, urlBuilderService, jwtHelper, sessionStorageService, __env) {
    return {
        get,
        getBranches,
        getCompanyPasswordRequirements,
        getDisabledFields,
        getTherapy,
        setBranch,
        setCompanyPasswordRequirements,
        setDefaultBranch,
        setDisabledFields,
    };

    /**
     * Fetches the User Object
     */
    function get() {
        let token = sessionStorageService.getJwt();
        token = jwtHelper.decodeToken(token);

        // Get branches from localStorage
        // Inject authService here to avoid creating a circular dependency error
        // authService -> userService -> authService
        const branches = $injector.get('authService').getUserBranches(true);

        const user = {
            CdcType: token.data.CdcType,
            active_branch: {
                ID: token.data.BranchID,
                Name: token.data.BranchName,
                Privileges: token.data.UserPermissions,
            },
            active_company: {
                ID: token.data.CompanyID,
                Name: token.data.CompanyName,
            },
            active_roles: token.data.UserRoles,
            active_session: {
                SessionId: token.data.Token,
            },
            branches,
            company_permissions: token.data.CompanyPermissions,
            daylight_savings: token.data.DaylightSavings,
            default_branch: token.data.DefaultBranch,
            email: token.data.Email,
            expired_branches: token.data.ExpiredBranches,
            first_name: token.data.FirstName,
            id: token.data.UserID,
            is_password_expired: token.data.PasswordExpired,
            last_name: token.data.LastName,
            message_email: token.data.MessageEmail,
            suffix: token.data.Suffix,
            time_zone_offset: token.data.TimeZoneOffset,
            timeout_length: token.data.TimeoutLength,
            user_permissions: token.data.UserPermissions,
            user_attributes: token.data.UserAttributes,
            verbose: token.data.Verbose,
        };
        return $q.when(user);
    }

    /**
     * Gets branches the user has access to.
     */
    function getBranches() {
        const url = urlBuilderService.build('user', {
            user: '',
            'my-account': '',
        });
        return $http.get(url).then((res) => {
            const branches = {};
            _.each(res.data.results.Branches, (branch) => {
                branches[branch.ID] = branch;
            });
            return branches;
        });
    }

    /**
     * Gets all disabled fields for the user's company.
     * @param companyId - The user's company id.
     *
     * @return {Array} An array of the disabled fields
     */
    function getDisabledFields(companyId) {
        const url = urlBuilderService.build('getPatientDisabledFields', {
            company: companyId,
            disabledPatientFields: '',
        });
        return $http.get(url).then((res) => {
            return res.data;
        });
    }
    /**
     * Gets available therapies
     */
    function getTherapy() {
        const url = urlBuilderService.build('user', {
            user: '',
            therapy: '',
        });

        return $http
            .get(url)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err.data;
            });
    }

    /**
     * Changes branch
     * @param branchId - The branch id to change to.
     * @return {Promise}
     */

    function setBranch(divisionId) {
        const endpoint = `${__env.tasEndpoint}:${__env.tasPort}/tas/${__env.tasVersion}`;
        const jwt = sessionStorageService.getJwt();
        if (typeof jwt !== 'string') {
            return false;
        }

        return $http({
            method: 'PATCH',
            url: `${endpoint}/user/changeBranch`,
            data: { divisionId },
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        }).then((res) => {
            return res.data;
        });
    }

    /**
     * Change Users Default Branch
     * @param branchId
     */
    function setDefaultBranch(divisionId) {
        const endpoint = `${__env.tasEndpoint}:${__env.tasPort}/tas/${__env.tasVersion}`;
        const session = sessionStorageService.getJwt();
        if (typeof session !== 'string') {
            return false;
        }

        return $http({
            method: 'PATCH',
            url: `${endpoint}/user/changeDefaultBranch`,
            data: { divisionId },
            headers: {
                Authorization: `Bearer ${session}`,
            },
        }).then((res) => {
            return res.data;
        });
    }

    /**
     * Gets a company password requirements
     * @return {Promise}
     */

    function getCompanyPasswordRequirements() {
        const endpoint = `${__env.tasEndpoint}:${__env.tasPort}/tas/${__env.tasVersion}`;
        const jwt = sessionStorageService.getJwt();
        if (typeof jwt !== 'string') {
            return false;
        }
        return $http({
            method: 'GET',
            url: `${endpoint}/user/changePasswordRequirements`,
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        }).then((res) => {
            return res.data;
        });
    }

    /**
     * Sets a company password requirements
     * @param minLength
     * @param maxLength
     * @param minNumNumbers
     * @param minNumUppercase
     * @param minNumSymbols
     * @param minNumRequirements
     * @return {Promise}
     */

    function setCompanyPasswordRequirements(
        minLength,
        maxLength,
        minNumNumbers,
        minNumUppercase,
        minNumSymbols,
        minNumRequirements
    ) {
        const endpoint = `${__env.tasEndpoint}:${__env.tasPort}/tas/${__env.tasVersion}`;
        const jwt = sessionStorageService.getJwt();
        if (typeof jwt !== 'string') {
            return false;
        }
        return $http({
            method: 'PATCH',
            url: `${endpoint}/user/changePasswordRequirements`,
            data: {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line radix
                minLength: parseInt(minLength),
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line radix
                maxLength: parseInt(maxLength),
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line radix
                minNumNumbers: parseInt(minNumNumbers),
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line radix
                minNumUppercase: parseInt(minNumUppercase),
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line radix
                minNumSymbols: parseInt(minNumSymbols),
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line radix
                minNumRequirements: parseInt(minNumRequirements),
            },
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        });
    }

    /**
     * Sets the passed in fields as disabled.
     *
     * @param {string} companyId - The company id.
     * @param {Array} fields - The fields to set as disabled.
     * @return {Promise} True if successful; false otherwise.
     */
    function setDisabledFields(companyId, fields) {
        const url = urlBuilderService.build('setPatientDisabledFields', {
            company: companyId,
            disabledPatientFields: '',
        });

        return $http.post(url, fields).then((res) => {
            return res.data;
        });
    }
}

export default UserService;
