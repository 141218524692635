import config from '../components/config/config';
import getSlsBasePath from '../utils/getSlsBasePath/getSlsBasePath';
import createAxios from 'App/utils/createAxios';

const slsHost = getSlsBasePath(config, 'admin');

const templateService = createAxios({
    baseURL: `${slsHost}/admin/${config.slsVersion}/protocol/template`,
});

function getProtocolTemplates() {
    return templateService.get();
}

/**
 * @typedef CreateProtocolTemplatePaylaod
 * @param {string} name
 */

/**
 * Creates a new protocol template
 * @param {CreateProtocolTemplatePaylaod} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
function createProtocolTemplate(payload) {
    return templateService.post('', payload);
}

function deleteProtocolTemplate(uuid) {
    return templateService.delete(`/${uuid}`);
}

function publishProtocolTemplate(uuid) {
    return templateService.post(`/${uuid}/publish`);
}

function getProtocolTemplate(uuid) {
    return templateService.get(`/${uuid}`);
}

function updateProtocolTemplate(uuid, payload) {
    return templateService.put(`/${uuid}`, payload);
}

export {
    createProtocolTemplate,
    deleteProtocolTemplate,
    getProtocolTemplate,
    getProtocolTemplates,
    publishProtocolTemplate,
    updateProtocolTemplate,
};
