/**
 * This service is responsible for retrieving prescriberObj data.
 */
import * as R from 'ramda';
import { getData, stripAllHtml } from '../utils';

/** @ngInject */
function PrescriberService($http, urlBuilderService, PrescriberAdapterService) {
    const service = {
        get,
        post,
    };

    function get(search) {
        const url = urlBuilderService.get('physicianLookup', { id: search });
        return $http
            .get(url)
            .then(getData)
            .then(PrescriberAdapterService.doNormalise)
            .then(sanitizeNotes)
            .catch((err) => {
                return err.data;
            });
    }

    function sanitizeNotes(obj) {
        return R.over(R.lensProp('notes'), stripAllHtml, obj);
    }

    function post(obj) {
        const preparedObj = PrescriberAdapterService.doPrepare(obj);
        return $http({
            url: urlBuilderService.get('physicianSubmit'),
            method: 'POST',
            params: preparedObj,
            paramSerializer: '$httpParamSerializerJQLike',
        })
            .then(getData)
            .then(sanitizeNotes)
            .catch((err) => {
                return err.data;
            });
    }

    return service;
}

export default PrescriberService;
