function ConfirmDeleteChildrenModalController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve) {
            ctrl.numberOfAnswers = ctrl.resolve.modalData.numberOfAnswers;
        }
    }
}

export default ConfirmDeleteChildrenModalController;
