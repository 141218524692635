import CMSService from 'App/services/CMSService';

/** @ngInject */
function ContentPublisherController($q, _, toastr) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.addContentGroups = addContentGroups;
    ctrl.publishContent = publishContent;
    ctrl.removeContentGroups = removeContentGroups;
    ctrl.selectMonographType = selectMonographType;

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function $onInit() {
        ctrl.contentGroupsToPublish = [];
    }

    function addContentGroups() {
        _.each(ctrl.selectedToAdd, (uuid) => {
            ctrl.contentGroupsToPublish.push(_.find(ctrl.contentGroups, { uuid }));
            _.remove(ctrl.contentGroups, { uuid });
        });
    }

    function publishContent() {
        if (ctrl.contentGroupsToPublish.length === 0) {
            return false;
        }
        ctrl.loading = true;
        const promises = {};
        _.each(ctrl.contentGroupsToPublish, (contentGroup) => {
            promises[contentGroup.uuid] = CMSService.publishContentGroup(contentGroup.uuid);
        });
        return $q
            .all(promises)
            .then((res) => {
                let successCount = 0;
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-shadow
                _.each(res, (res, uuid) => {
                    // Check if all content group published.
                    if (_.findIndex(_.values(res), false) !== -1) {
                        const contentGroup = _.find(ctrl.readyToPublish, {
                            uuid,
                        });
                        toastr.error(`${contentGroup.name} failed to publish. Please contact support.`);
                    } else {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-plusplus
                        successCount++;
                    }
                });
                if (successCount > 0) {
                    toastr.success(`${successCount} content groups published successfully.`);
                }
            })
            .catch(() => {
                toastr.error(
                    'An unknown error has occurred when trying to publish the content groups. Please contact support.'
                );
            })
            .finally(() => {
                ctrl.loading = false;
                ctrl.contentGroupsToPublish = [];
                ctrl.selectMonographType();
            });
    }

    function removeContentGroups() {
        _.each(ctrl.selectedToRemove, (uuid) => {
            ctrl.contentGroups.push(_.find(ctrl.contentGroupsToPublish, { uuid }));
            _.remove(ctrl.contentGroupsToPublish, { uuid });
        });
    }

    function selectMonographType() {
        ctrl.loading = true;
        ctrl.contentGroupsToPublish = [];

        return CMSService.getContentGroups(ctrl.monographType, {
            hasChanges: true,
            unpublished: true,
        })
            .then((res) => {
                ctrl.contentGroups = _.values(res);
                ctrl.originalContentGroups = angular.copy(ctrl.contentGroups);

                if (ctrl.originalContentGroups.length === 0) {
                    if (ctrl.monographType === 'teaching') {
                        ctrl.monographDisplayName = 'teaching point';
                    } else {
                        ctrl.monographDisplayName = ctrl.monographType;
                    }
                }
            })
            .catch(() => {
                ctrl.contentGroups = [];
            })
            .finally(() => {
                ctrl.originalContentGroups = angular.copy(ctrl.contentGroups);
                ctrl.loading = false;
            });
    }
}

export default ContentPublisherController;
