import { queryClient } from 'Lib/queryClient';

/** @ngInject */
function LogoutController($location, $state, authService, jwtHelper, sessionStorageService) {
    const ctrl = this;
    ctrl.$onInit = $onInit;

    function $onInit() {
        let sso = false;
        const jwt = sessionStorageService.getJwt();
        let token;

        if (typeof jwt === 'string' && jwt.length > 0) {
            // If we have a token get the sso info from it.
            token = jwtHelper.decodeToken(jwt);

            if (token.data.CompanyPermissions.SsoEnabled) {
                sso = true;
            }
        }

        if (window.newrelic) {
            window.newrelic.setUserId(null);
        }

        if (window.walkme) {
            window.walkme = {
                userId: undefined,
                userAge: undefined,
                email: undefined,
                fullName: undefined,
                privilegeAdmin: undefined,
                privilegeAssignment: undefined,
                privilegeProtocol: undefined,
                privilegeReports: undefined,
                companyName: undefined,
            };
        }

        queryClient.clear();

        if (sso === true) {
            return authService.logout().then(() => {
                if (
                    typeof token.data.CompanyPermissions.SsoLogout === 'string' &&
                    token.data.CompanyPermissions.SsoLogout.trim() !== ''
                ) {
                    window.location.href = token.data.CompanyPermissions.SsoLogout;
                } else {
                    ctrl.ssoLoginUrl = token.data.CompanyPermissions.SsoLogin;
                    ctrl.ssoLogout = true;
                }
            });
        }
        ctrl.sessionExpired = false;
        return authService.logout().then((res) => {
            $state.go('auth.login', {
                sessionExpired: $location.search().sessionExpired || $state.params.sessionExpired,
            });
            return res;
        });
    }
}

export default LogoutController;
