import { useQuery } from 'react-query';
import CompanyService from '@/app/services/CompanyService';
import { errorHandler } from '@/app/utils';

function useFetchtCustomMenuQuery() {
    return useQuery(
        ['customMenu'],
        () => {
            return CompanyService.getCustomMenus();
        },
        {
            onError: errorHandler,
            // every 8 hours or until relogin
            cacheTime: 1000 * 60 * 60 * 8,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchtCustomMenuQuery;
