import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ManualPageTable from 'App/components/ManualPageTable/ManualPageTable';
import hasLength from 'App/utils/hasLength';
import { useFormikContext } from 'formik';
import propOrDash from 'App/utils/propOrDash';
import { camelCase, isEqual } from 'lodash';
import { useGetHeaderMapping } from '../../hooks/useGetHeaderMapping';
import EditActivityDueDateModal from 'App/components/PatientProfile/components/PatientActivities/components/EditActivityDueDateModal';
import ActivityNotesModal from 'App/components/PatientProfile/components/PatientActivities/components/ActivityNotesModal';
import { TextInput } from 'Lib/form/TextField';
import { useUserContext } from 'App/contexts/UserContext';
import { queryClient } from 'Lib/queryClient';
import { defaultActivityDateModalState, defaultActivityNoteModalState } from '../../defaultWorkQueueState';
import useFetchActivityCount from 'App/features/WorkQueue/hooks/useFetchActivityCount';

export const WorkQueueTable = () => {
    const {
        values: {
            result: { workQueueTableHeaders, workQueueActivityCount, workQueueResult },
            filterParams,
            modalState: { activityDateModal: activityDateModalState, activityNoteModal: activityNoteModalState },
        },
        setFieldValue,
        isSubmitting,
    } = useFormikContext();
    const { page, count, search } = filterParams;
    const user = useUserContext();
    const { company_permissions: { MckessonCpsIntegration: isMcKessonEnabled = false } = {} } = user;
    const [searchState, setSearchState] = useState(search || {});
    const headerMapping = useGetHeaderMapping();
    const onFilterChange = useCallback(
        (e) => {
            const prevSearch = { ...searchState };
            if (e.target.value === '') {
                delete prevSearch[e.target.name];
            } else {
                prevSearch[e.target.name] = e.target.value;
            }
            setSearchState(prevSearch);
        },
        [searchState]
    );

    const { refetch: refetchActivityCount } = useFetchActivityCount();

    const refetchWorkQueueResult = () => {
        queryClient.refetchQueries(['work_queue', JSON.stringify(filterParams)]);
        refetchActivityCount();
    };
    const onHideActivityDueDateModal = (refetch) => {
        if (refetch) {
            refetchWorkQueueResult();
        }
        setFieldValue('modalState.activityDateModal', defaultActivityDateModalState);
    };
    const onHideActivityNoteStatus = (refetch) => {
        if (refetch) {
            refetchWorkQueueResult();
        }
        setFieldValue('modalState.activityNoteModal', defaultActivityNoteModalState);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setFieldValue('filterParams.search', searchState);
        }, 800);
        return () => clearTimeout(timer);
    }, [searchState, setFieldValue]);

    useEffect(() => {
        if (!isEqual(searchState, search)) {
            setSearchState(search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const columns = useMemo(() => {
        if (!workQueueTableHeaders?.headers) {
            return [];
        }
        return workQueueTableHeaders?.headers?.map((header) => {
            const mapping = headerMapping[header.fieldSelected];
            return {
                Header: header.nameOfHeader,
                accessor: propOrDash(camelCase(mapping.accessor || header.fieldSelected)),
                sortName: mapping.sortName || header.fieldSelected,
                id: header.fieldOrder,
                canSort: mapping.canSort || false,
                Filter: (
                    <TextInput
                        name={mapping.filterName || header.fieldSelected}
                        placeholder={`Filter by ${header.nameOfHeader}`}
                        onChange={onFilterChange}
                        value={searchState ? searchState[mapping.filterName || header.fieldSelected] || '' : ''}
                    />
                ),
                canFilter: true,
                ...(mapping.Cell ? { Cell: mapping.Cell } : {}),
            };
        });
    }, [workQueueTableHeaders?.headers, headerMapping, onFilterChange, searchState]);

    const canSort = hasLength(workQueueResult);

    const onPageChange = ({ pageIndex, pageSize }) => {
        setFieldValue('filterParams.page', pageIndex);
        setFieldValue('filterParams.count', pageSize);
    };

    const onSortChange = (sort) => {
        const sortField = columns[sort.sortKey - 1]?.sortName;
        setFieldValue('filterParams.sorting', {
            [sortField]: sort.sortOrder,
        });
    };

    return (
        <>
            {isMcKessonEnabled && (
                <div>
                    <h3 className="header-work-queue">Work Queue</h3>
                </div>
            )}
            <ManualPageTable
                data={workQueueResult}
                totalRows={workQueueActivityCount}
                placeholderText="There are no activities that match your current search criteria."
                columns={columns}
                canSort={canSort}
                isLoading={isSubmitting}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                pageSizeOptions={[5, 10, 25]}
                currentPageIndex={count}
                currentPage={page}
                initialSortKey="patient_name"
                initialSortOrder="asc"
            />
            {activityDateModalState.show && (
                <EditActivityDueDateModal onHide={onHideActivityDueDateModal} {...activityDateModalState} />
            )}
            {activityNoteModalState.show && (
                <ActivityNotesModal onHide={onHideActivityNoteStatus} {...activityNoteModalState} />
            )}
        </>
    );
};
