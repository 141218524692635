/** @ngInject */
function MyAccountController(
    _,
    __env,
    $http,
    $httpParamSerializerJQLike,
    $rootScope,
    $scope,
    $state,
    authService,
    jwtHelper,
    sessionStorageService,
    $location
) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.closeAlert = closeAlert;
    ctrl.submit = submit;
    ctrl.isPasswordExpired = false;
    ctrl.emailRegex = __env.emailRegex;

    /**
     * @summary Initializes the component
     */
    function $onInit() {
        ctrl.redirect = $state.params.redirect;
        ctrl.account = {};
        ctrl.isPasswordExpired = sessionStorageService.isPasswordExpired();
        return $http({
            method: 'GET',
            url: '/stm/my_account.php?angular=true',
        }).then((res) => {
            _.assignIn(ctrl, res.data);
            ctrl.account = {
                address_1_1: res.data.address_1_1,
                address_1_2: res.data.address_1_2,
                city_1: res.data.city_1,
                confirmPassword: null,
                country_1: res.data.country_1,
                currentPassword: null,
                daylight_savings: res.data.daylight_savings === '1',
                email: res.data.email,
                first_name: res.data.first_name,
                last_name: res.data.last_name,
                message_email: res.data.message_email === 'Y',
                password: null,
                province_1: res.data.province_1,
                telephone_1: res.data.telephone_1,
                timeout_length: res.data.timeout_length,
                time_zone_id: res.data.time_zone_id,
                title: res.data.title,
                verbose: res.data.verbose === '1',
                zipcode_1: res.data.zipcode_1,
            };
            ctrl.attributes = {
                ehr_provider_id: res.data.ehr_provider_id,
            };
            const jwt = sessionStorageService.getJwt();
            const token = jwtHelper.decodeToken(jwt);
            ctrl.ssoEnabled = token.data.CompanyPermissions.SsoEnabled === true;
            ctrl.isEhrEnabled = ctrl.ehrProviders.length > 0;
        });
    }

    /**
     * @summary Handler for closing alerts boxes
     */
    function closeAlert() {
        ctrl.error = undefined;
        ctrl.success = undefined;
    }

    /**
     * @summary Submits the my account form
     * @description
     * Submits the my account form. Updates any modified account information and/or password.
     * @param e - The current event
     * @returns {Promise} Resolves if successful, fails otherwise
     */
    function submit(e) {
        ctrl.loading = true;
        ctrl.success = undefined;
        ctrl.error = undefined;

        e.preventDefault();
        $scope.$broadcast('show-errors-check-validity');

        if (ctrl.myAccountForm.$invalid) {
            return;
        }

        const user = _.cloneDeep(ctrl.account);
        user.daylight_savings = ctrl.account.daylight_savings ? 1 : 0;
        user.message_email = ctrl.account.message_email ? 'Y' : 'N';
        user.verbose = ctrl.account.verbose ? 1 : 0;

        // checks if any of the password fields are being used
        if (user.currentPassword || user.password || user.confirmPassword) {
            if ((!!user.currentPassword && !!user.password && !!user.confirmPassword) === false) {
                ctrl.success = undefined;
                ctrl.error = 'One or more of the password fields is missing or incorrect. Please try again.';
                ctrl.loading = false;
                window.scrollTo(0, 0);
                return;
            }
        }

        if (user.confirmPassword !== user.password) {
            ctrl.success = undefined;
            ctrl.error = 'The password and confirmation password must match.';
            ctrl.loading = false;
            window.scrollTo(0, 0);
            return;
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return $http
            .post(
                '/stm/my_account.php?angular=true',
                $httpParamSerializerJQLike({
                    user,
                    attributes: ctrl.attributes,
                    registerSubmit: true,
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            )
            .then((res) => {
                if (user.password) {
                    ctrl.success = `${res.data.message}. `;
                    return authService.changePassword(user.currentPassword, user.password);
                }
                ctrl.success = '';
                return { message: res.data.message };
            })
            .then((res) => {
                ctrl.error = undefined;
                ctrl.success += res.message;
                ctrl.account.password = undefined;
                ctrl.account.confirmPassword = undefined;
                ctrl.account.currentPassword = undefined;
                const timeout = sessionStorageService.get('timeout');
                timeout.secs = ctrl.account.timeout_length * 60;
                timeout.lastUpdated = new Date();
                sessionStorageService.set('timeout', timeout);
                return authService.updateJwt();
            })
            .then(() => {
                ctrl.isPasswordExpired = sessionStorageService.isPasswordExpired();
            })
            .then(() => {
                return authService.getUser();
            })
            .then((res) => {
                $rootScope.$emit('updateUser', res);
                return res;
            })
            .catch((err) => {
                window.scrollTo(0, 0);
                ctrl.success = undefined;
                ctrl.error = err.data.message;
            })
            .finally(() => {
                window.scrollTo(0, 0);
                ctrl.loading = false;

                const sendBackAfterSave =
                    window.history?.state?.sendBackAfterSave || $location.search().sendBackAfterSave;

                if (sendBackAfterSave) {
                    window.history.back();
                }
            });
    }
}

export default MyAccountController;
