import * as R from 'ramda';

import {
    hasLength,
    parseAssessmentSettings,
    shouldShowRetiredAssessment,
    sortAndPrepend,
    stripAllHtml,
    stripCurlyTags,
} from 'App/utils';
import {
    allDiagnosisOption,
    allMedicationsOption,
    DISPLAY_OPERATORS,
    OPERATORS,
    REFERRAL_TYPES,
    TRIGGER_TYPES,
} from '../constants';
import AssessmentService from 'App/services/AssessmentService';
import { getFilterOperator, groupFilters } from '../utils';

/**
 * An enumeration of the available actions.
 */
const Actions = Object.freeze({
    ADD_TRIGGER: 'ADD_TRIGGER',
    DELETE_PROTOCOL: 'DELETE_PROTOCOL',
    DELETE_TRIGGER: 'DELETE_TRIGGER',
    GET_AVAILABLE_RULE_ASSESSMENTS_SUCCESS: 'GET_AVAILABLE_RULE_ASSESSMENTS_SUCCESS',
    RESET: 'RESET',
    SET_INITIAL_DATA: 'SET_INITIAL_DATA',
    SET_MED_OPTIONS: 'SET_MED_OPTIONS',
    TOGGLE_DELETE_PROTOCOL_MODAL: 'TOGGLE_DELETE_PROTOCOL_MODAL',
    TOGGLE_DELETE_TRIGGER_MODAL: 'TOGGLE_DELETE_TRIGGER_MODAL',
});

/**
 * The default/initial state
 */
const initialState = {
    /**
     * Preloaded Assessments needed when creating rules.
     */
    availableRuleAssessments: [],
    /**
     * Ad-hoc function that will be called when the user confirms the delete protocol action.
     */
    deleteProtocolAdHocFn: null,
    protocolToDelete: null,
    /**
     * Ad-hoc function that will be called when the user confirms the delete trigger action.
     */
    deleteTriggerAdHocFn: null,
    /**
     * Options available to the diagnosis code dropdown
     */
    diagnosisCodeOptions: [],

    /**
     * Flag to indicate this is in read-only [view] mode.
     */
    isReadOnly: true,
    /**
     * The dropdown options available to the mediation field.
     */
    medicationOptions: [],
    /**
     * The protocol itself.
     */
    protocol: null,
    /**
     * The triggers seen in the table.
     */
    protocolTriggers: [],
    /**
     * Triggers that have been added, but not yet saved. They include an additional `remoteTrigger` property that is
     * used to send the trigger up to the server.
     */
    addedTriggers: [],
    /**
     * Triggers that have been deleted, but not yet removed from the server.
     */
    deletedTriggers: [],
    /**
     * The diagnosis code options that have been selected.
     */
    selectedDiagnosisCodes: [],
    /**
     * The medcation options that have been selected.
     */
    selectedMedications: [],
    /**
     * Flag to show the protocol delete modal.
     */
    showDeleteProtocolModal: false,
    /**
     * Flag to show the trigger delete modal.
     */
    showDeleteTriggerModal: false,
    /**
     * This value is set in the initial state of the reducer. No action is defined to modify it later.
     */
    showRetiredAssessments: false,
};

/**
 * Converts an object to an array and then sorts it by the name prop.
 */
const sortByName = R.sortBy(R.prop('name'));

/**
 * Gets the values for the date field.
 * The `dueDate` is used for the filter value of the cell, while the `dueDateValues` is used
 * in the display
 * @param trigger
 * @returns {object}
 */
function getDateField(trigger) {
    const { days, beforeAfter, dateFieldText } = trigger;

    if (R.isNil(days)) {
        return {
            dueDate: '--',

            dueDateValues: {
                days: '--',
                field: '',
            },
        };
    }

    return {
        dueDate: `${days} days ${beforeAfter ? 'after' : 'before'} ${dateFieldText}`,

        dueDateValues: {
            days: `${days} days ${beforeAfter ? 'after' : 'before'}`,
            field: dateFieldText,
        },
    };
}

/**
 * Simple composition to generate the activity trigger text.
 * @param {object} - The trigger.
 */
const getActivityEvent = R.compose(R.concat('Completion of '), R.propOr('', 'onCompletionOfActionName'));

/**
 * Simple abstraction to toggle a boolean state value.
 * @param {string} key - The state key.
 * @param {object} state - The state with the key to toggle
 */
const toggleState = R.curry((key, state) => {
    return R.over(R.lensPath([key]), R.not, state);
});

/**
 * Gets the conditions for an activity group.
 * This function is intended to be used within a reduce function.
 *
 * Note that this function generates the filter value, and not the display.
 * This is due in part that we want to format the display differently than
 * the actual value. See the component table config for the display value.
 *
 * @see getConditions
 * @param {string} result - The current result
 * @param {object} filter - The current filter
 * @param {number} idx - The index for the array
 * @returns {string}
 */
function activityConditionsReducer(result, filter, idx) {
    let temp = result;
    if (idx !== 0) {
        temp = R.concat('or', temp);
    }
    if (filter.operator === OPERATORS.IS_ANSWERED) {
        return R.concat(` when "${stripAllHtml(filter.questionText)}" ${DISPLAY_OPERATORS.is_answered} `, temp);
    }

    return R.concat(
        ` when "${stripAllHtml(filter.questionText)}" ${getFilterOperator(filter)} "${stripAllHtml(
            filter.answerText
        )}" `,
        temp
    );
}

/**
 * Gets the filter condition groups.
 *
 * Note that this function generates the filter value, and not the display.
 * This is due in part that we want to format the display differently than
 * the actual value. See the component table config for the display value.
 *
 * @param filters
 * @returns {string}
 */
const getConditions = (filters) => {
    if (hasLength(filters)) {
        return R.compose(
            R.join('and'),
            R.map(R.addIndex(R.reduce)(activityConditionsReducer, '')),
            R.values,
            groupFilters
        )(filters);
    }
    return '--';
};

/**
 * Simple abstraction for getting the profile event.
 * @returns {string}
 */
const getProfileEvent = () => {
    return `Addition of medication`;
};

const getInsuranceEvent = () => 'Addition of patient insurance';

const getReferralStatusEvent = () => 'Change of referral status';

const getReferralActivityStatusEvent = (activityType) => {
    return `Change of referral activity status (${R.propOr('N/A', activityType, REFERRAL_TYPES)})`;
};

/**
 * Creates a profile rule that can be displayed in the table.
 * @param {object} profileTrigger - The trigger
 * @param {string} nextAssessmentName - The name of the next assessment
 * @returns {object} - Profile rule
 */
function createProfileRule(profileTrigger, nextAssessmentName) {
    const profileRule = {
        ...profileTrigger,
        type: TRIGGER_TYPES.PROFILE,
        activityTriggered: nextAssessmentName,
    };

    profileRule.event = getProfileEvent();

    const due = getDateField(profileTrigger);
    profileRule.dueDate = due.dueDate;
    profileRule.dueDateValues = due.dueDateValues;

    profileRule.conditions = getConditions(profileTrigger.profileFilters);
    return profileRule;
}

function createInsuranceRule(insuranceTrigger, nextAssessmentName) {
    const insuranceRule = {
        ...insuranceTrigger,
        type: TRIGGER_TYPES.INSURANCE,
        activityTriggered: nextAssessmentName,
    };

    insuranceRule.event = getInsuranceEvent();

    const due = getDateField(insuranceTrigger);
    insuranceRule.dueDate = due.dueDate;
    insuranceRule.dueDateValues = due.dueDateValues;

    insuranceRule.conditions = getConditions(insuranceTrigger.filter);
    return insuranceRule;
}

function createReferralStatusRule(referralTrigger, nextAssessmentName) {
    const rule = {
        ...referralTrigger,
        type: TRIGGER_TYPES.REFERRAL_STATUS,
        activityTriggered: nextAssessmentName,
    };

    rule.event = getReferralStatusEvent();

    const due = getDateField(referralTrigger);
    rule.dueDate = due.dueDate;
    rule.dueDateValues = due.dueDateValues;

    rule.conditions = getConditions(referralTrigger.filter);
    return rule;
}

function createReferralActivityStatusRule(referralActivityTrigger, nextAssessmentName) {
    const rule = {
        ...referralActivityTrigger,
        activityTriggered: nextAssessmentName,
    };

    rule.event = getReferralActivityStatusEvent(rule.referralActivityType);

    const due = getDateField(referralActivityTrigger);
    rule.dueDate = due.dueDate;
    rule.dueDateValues = due.dueDateValues;

    rule.conditions = getConditions(referralActivityTrigger.filter);
    return rule;
}
/**
 * Creates an activity rule that can be displayed in the table.
 * @param {object} activityTrigger - The trigger
 * @param {string} nextAssessmentName - The name of the next assessment
 * @returns {object} - Activity rule
 */
function createActivityRule(activityTrigger, nextAssessmentName) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    activityTrigger.activityFilters = R.map(
        R.compose(R.over(R.lensProp('questionText'), stripCurlyTags), R.over(R.lensProp('answerText'), stripCurlyTags)),
        activityTrigger.activityFilters
    );

    const activityRule = {
        ...activityTrigger,
        type: TRIGGER_TYPES.ACTIVITY,
        activityTriggered: nextAssessmentName,
    };

    activityRule.event = getActivityEvent(activityTrigger);

    const due = getDateField(activityTrigger);

    activityRule.dueDate = due.dueDate;
    activityRule.dueDateValues = due.dueDateValues;

    /**
     * This is needed in order to allow for the correct filtering and sorting of this column.
     * The displayed values are populated in the component using the same values
     */
    activityRule.conditions = getConditions(activityTrigger.activityFilters);
    return activityRule;
}

/**
 * Filters `allOptions` by the `selectedOptions` based on the compareProps values.
 * Note: The only reason we need this is because the names aren't provided in the
 * protocol. If we wanted to hydrate those as well, this could go away, and we could,
 * probably just set the values directly.
 * @param {string} selectCompareProp - The prop from the selected object to compare with.
 * @param {string} allCompareProp - The prop from the `all` object to compare with.
 * @param {object | array} selectedOptions - The selected options
 * @param {object | array} allOptions - All available options
 */
const getSelectedOptions = R.curry((selectCompareProp, allCompareProp, selectedOptions, allOptions) => {
    const selected = R.values(selectedOptions);

    return allOptions.filter((option) => !!selected.find(R.propEq(R.prop(allCompareProp, option), selectCompareProp)));
});

/**
 * Sets the state of the data after doing all the fetching.
 * @param {object} protocol - The protocol for the page.
 * @param {array} medications - The available medications.
 * @param {array} diagnosis - The available diagnosis codes.
 * @param {object} triggers - The associated triggers.
 * @param {object} state - The current state to be updated.
 * @returns {object} - The new state.
 */
function setInitialData({ protocol, medications, diagnosis, triggers }, state) {
    const { protocolDiagnoses, protocolDrugs } = protocol;

    // Create All Medication Options
    const allMedicationOptions = sortAndPrepend(allMedicationsOption, 'name', medications);

    let newState = R.compose(
        R.set(R.lensPath(['addedTriggers']), []),
        R.set(R.lensPath(['deletedTriggers']), []),
        R.set(R.lensPath(['isReadOnly']), R.isNil(protocol.companyId)),
        R.set(R.lensPath(['medicationOptions']), allMedicationOptions)
    )(state);

    // Select all selected Medications
    if (protocol.drugAny) {
        newState = R.set(R.lensPath(['selectedMedications']), allMedicationOptions, newState);
    } else {
        newState = R.set(
            R.lensPath(['selectedMedications']),
            getSelectedOptions('medicationId', 'id', protocolDrugs, allMedicationOptions),
            newState
        );
    }

    /**
     * Protocol Diagnosis Codes
     */

    // Create all Diagnosis Code Options
    const allDiagnosisCodeOptions = sortAndPrepend(allDiagnosisOption, 'code', diagnosis);

    newState = R.set(R.lensPath(['diagnosisCodeOptions']), allDiagnosisCodeOptions, newState);

    // Select all selected Diagnosis Codes
    if (protocol.diagnosisAny) {
        newState = R.set(R.lensPath(['selectedDiagnosisCodes']), allDiagnosisCodeOptions, newState);
    } else {
        newState = R.set(
            R.lensPath(['selectedDiagnosisCodes']),
            getSelectedOptions('diagnosisId', 'code', protocolDiagnoses, allDiagnosisCodeOptions),
            newState
        );
    }

    /**
     * Protocol Object state
     */
    newState = R.set(R.lensPath(['protocol']), protocol, newState);

    /**
     * Protocol Triggers
     */

    newState = R.set(
        R.lensPath(['protocolTriggers']),
        R.compose(createRulesData, R.sortBy(R.prop('id')), R.values)(triggers),
        newState
    );

    return newState;
}

/**
 * Centralizes the rule-creating logic to simplify the implementation.
 *
 * This function is Curried
 *
 * @param {function} ruleCreator - The creator function to be ran.
 * @param {string} triggerKey - The key for where to find the specific trigger.
 * @param {object} trigger - The trigger object that contains the specific trigger: activityTrigger, profileTrigger, etc
 * @param {object[]} triggerArray - The array that holds the modified/parsed triggers.
 *
 * @return {object[]} - The trigger array with the new trigger added, or a function depending on the number of args.
 */
const createRule = R.curry((ruleCreator, triggerKey, trigger, triggerArray) => {
    const ret = R.compose(
        R.ifElse(
            R.prop(triggerKey),
            R.compose(
                R.flip(R.append)(triggerArray),
                R.flip(ruleCreator)(trigger.nextAssessmentName),
                R.assoc('remoteTrigger', trigger.remoteTrigger),
                R.prop(triggerKey)
            ),
            R.always(triggerArray)
        )
    )(trigger);
    return ret;
});

const createRulesReducer = (triggerArray, trigger) => {
    return R.compose(
        createRule(createProfileRule, 'profileTrigger', trigger),
        createRule(createInsuranceRule, 'insuranceTrigger', trigger),
        createRule(createActivityRule, 'activityTrigger', trigger),
        createRule(createReferralStatusRule, 'referralTrigger', trigger),
        createRule(createReferralActivityStatusRule, 'referralActivityTrigger', trigger)
    )(triggerArray);
};

/**
 * Creates the Trigger rules.
 * @param {array} protocolTriggers - The triggers (both activity and profile) to be processed.
 * @returns {array}
 */
function createRulesData(protocolTriggers) {
    if (protocolTriggers) {
        return R.reduce(createRulesReducer, [], protocolTriggers);
    }
    return [];
}

function createRuleAssessmentOptions(assessments, state) {
    const [therapyAssessments, generalAssessments, referralAssessments] = assessments;

    const filterAndSortAssessments = R.compose(
        sortByName,
        R.filter(shouldShowRetiredAssessment(state.showRetiredAssessments)),
        R.map(parseAssessmentSettings),
        R.values
    );

    const therapyGroup = !R.equals(therapyAssessments, generalAssessments)
        ? [
              {
                  label: R.path(['protocol', 'therapy', 'name'], state),
                  options: filterAndSortAssessments(therapyAssessments),
              },
          ]
        : [];
    const referralGroup = referralAssessments
        ? [
              {
                  label: 'Referral',
                  options: referralAssessments.map((referralAssessment) => {
                      return {
                          id: referralAssessment.referralTypeEnum,
                          name: referralAssessment.name,
                          referralTypeEnum: referralAssessment.referralTypeEnum,
                      };
                  }),
              },
          ]
        : [];
    return R.set(
        R.lensPath(['availableRuleAssessments']),
        [
            ...therapyGroup,
            {
                label: AssessmentService.GENERAL_NAME,
                options: filterAndSortAssessments(generalAssessments),
            },
            ...referralGroup,
        ],
        state
    );
}

/**
 * Takes the current state and an action and returns the new state.
 * @param {object} state - The current/previous state.
 * @param {object} action - An action containing both a `type` and a `payload`
 * @returns {object} newState - The new state, or previous state if no actions matched.
 */
function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case Actions.SET_INITIAL_DATA: {
            return setInitialData(payload.data, state);
        }

        case Actions.RESET: {
            return initialState;
        }

        case Actions.TOGGLE_DELETE_TRIGGER_MODAL: {
            return R.compose(
                toggleState('showDeleteTriggerModal'),
                R.set(R.lensPath(['deleteTriggerAdHocFn']), payload.deleteFn)
            )(state);
        }

        case Actions.TOGGLE_DELETE_PROTOCOL_MODAL: {
            return R.compose(
                toggleState('showDeleteProtocolModal'),
                R.set(R.lensPath(['protocolToDelete']), payload.protocol)
            )(state);
        }

        case Actions.DELETE_TRIGGER: {
            return R.compose(
                // toggleState('showDeleteTriggerModal'),
                R.set(R.lensPath(['showDeleteTriggerModal']), false),
                R.set(R.lensPath(['deleteTriggerAdHocFn']), null),
                (s) => {
                    let found = R.find(R.propEq(payload.triggerId, 'id'), s.protocolTriggers);
                    /**
                     * Check for a trigger that has already been saved to the server
                     */
                    if (found) {
                        return R.compose(
                            R.over(R.lensProp('deletedTriggers'), R.append(found)),
                            R.over(R.lensProp('protocolTriggers'), R.reject(R.propEq(payload.triggerId, 'id')))
                        )(s);
                    }

                    found = R.find(R.propEq(payload.triggerId, 'id'), s.addedTriggers);

                    /**
                     * Check for a trigger that was just added, but not yet saved.
                     */
                    if (found) {
                        return R.compose(
                            R.over(R.lensProp('addedTriggers'), R.reject(R.propEq(payload.triggerId, 'id')))
                        )(s);
                    }
                    return s;
                }
            )(state);
        }

        case Actions.GET_AVAILABLE_RULE_ASSESSMENTS_SUCCESS: {
            return createRuleAssessmentOptions(payload.assessments, state);
        }

        case Actions.ADD_TRIGGER: {
            return R.compose(R.over(R.lensProp('addedTriggers'), R.flip(createRulesReducer)(payload.trigger)))(state);
        }

        default:
            throw new Error(`Unknown or missing action type: ${type}`);
    }
}

/**
 * A collection of actions used to codify how to create a given action.
 */
const actionCreators = {
    addTrigger(trigger) {
        return {
            type: Actions.ADD_TRIGGER,
            payload: { trigger },
        };
    },

    deleteTrigger(triggerId) {
        return {
            type: Actions.DELETE_TRIGGER,
            payload: { triggerId },
        };
    },

    getAvailableRuleAssessmentsSuccess(assessments) {
        return {
            type: Actions.GET_AVAILABLE_RULE_ASSESSMENTS_SUCCESS,
            payload: { assessments },
        };
    },

    reset() {
        return {
            type: Actions.RESET,
        };
    },

    setInitialData(data) {
        return {
            type: Actions.SET_INITIAL_DATA,
            payload: { data },
        };
    },

    toggleDeleteTriggerModal(deleteFn) {
        return {
            type: Actions.TOGGLE_DELETE_TRIGGER_MODAL,
            payload: { deleteFn },
        };
    },

    toggleDeleteProtocolModal(protocol) {
        return {
            type: Actions.TOGGLE_DELETE_PROTOCOL_MODAL,
            payload: { protocol },
        };
    },
};

export { actionCreators, initialState, createRulesData, reducer };
