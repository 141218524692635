/** @ngInject */
function ReportEngineController($q, $sce, $window, reportEngineService) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.embedUrl = '';
    ctrl.loadDefaultDashboard = loadDefaultDashboard;
    ctrl.loadExplore = loadExplore;
    ctrl.loading = false;
    ctrl.loadReport = loadReport;
    ctrl.loadTherigyContentMenu = loadTherigyContentMenu;
    ctrl.loadUserContentMenu = loadUserContentMenu;
    ctrl.therigyContent = {};
    ctrl.userContent = {};

    function $onInit() {
        ctrl.loadTherigyContentMenu();
        ctrl.loadDefaultDashboard();
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        $window.addEventListener('message', (event) => {
            if (event.source === document.getElementById('report-frame').contentWindow) {
                const data = JSON.parse(event.data);
                if (data.type === 'page:changed') {
                    return ctrl.loadUserContentMenu();
                }
            }
        });
    }

    function loadDefaultDashboard() {
        ctrl.loading = true;
        return reportEngineService.getDefaultDashboard().then((res) => {
            ctrl.loading = false;
            ctrl.embedUrl = $sce.trustAsResourceUrl(res.embedUrl);
        });
    }

    function loadExplore(model, explore) {
        ctrl.loading = true;
        return reportEngineService.getExplore(model, explore).then((res) => {
            ctrl.loading = false;
            ctrl.embedUrl = $sce.trustAsResourceUrl(res.embedUrl);
        });
    }

    function loadReport(type, reportId) {
        ctrl.loading = true;
        return reportEngineService.getReport(type, reportId).then((res) => {
            ctrl.loading = false;
            ctrl.embedUrl = $sce.trustAsResourceUrl(res.embedUrl);
        });
    }

    function loadTherigyContentMenu() {
        const promises = {
            therigyContent: reportEngineService.getTherigyContent(),
            userContent: reportEngineService.getUserContent(),
        };
        return (
            $q
                .all(promises)
                .then((res) => {
                    ctrl.therigyContent = res.therigyContent;
                    ctrl.userContent = res.userContent;
                })
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((err) => {
                    if (err.status === 404) {
                        // User doesn't exist until a report is loaded the first time.
                        return ctrl.getDefaultDashboard().then(() => {
                            return ctrl.loadTherigyContentMenu();
                        });
                    }
                })
        );
    }

    function loadUserContentMenu() {
        return reportEngineService.getUserContent().then((res) => {
            ctrl.userContent = res;
        });
    }
}

export default ReportEngineController;
