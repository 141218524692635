import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { TextField } from 'Lib/form/TextField';

import CommonPatientFieldWrapper from './CommonPatientFieldWrapper';
import SectionTitleStyle from './SectionTitleStyle';

const MedicalPlanForm = () => {
    return (
        <CommonPatientFieldWrapper fieldName="medicalPlan">
            {() => (
                <div>
                    <SectionTitleStyle>
                        <h2>Medical Plan</h2>
                    </SectionTitleStyle>

                    <Row>
                        <CommonPatientFieldWrapper fieldName="medicalPlan">
                            {(commonProps) => (
                                <Col md={6}>
                                    <TextField {...commonProps} label="Medical Plan" placeholder="Medical Plan" />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        <CommonPatientFieldWrapper fieldName="remoteMedicalPlanId">
                            {(commonProps) => (
                                <Col md={6}>
                                    <TextField {...commonProps} label="Medical Plan ID" placeholder="Medical Plan ID" />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        <CommonPatientFieldWrapper fieldName="pbm">
                            {(commonProps) => (
                                <Col md={6}>
                                    <TextField {...commonProps} label="PBM" placeholder="PBM" />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        <CommonPatientFieldWrapper fieldName="remotePbmId">
                            {(commonProps) => (
                                <Col md={6}>
                                    <TextField {...commonProps} label="PBM ID" placeholder="PBM ID" />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                    </Row>
                </div>
            )}
        </CommonPatientFieldWrapper>
    );
};

export default MedicalPlanForm;
