import _ from 'lodash';
import qs from 'qs';

function createContentMonographUrl({ labelType, patientTherapies, patientMedications }) {
    const paramsObj = {};

    if (labelType === 'disease') {
        paramsObj.labels = _.map(patientTherapies, (pt) => {
            return _.camelCase(pt.therapy.name);
        });
    }
    if (labelType === 'drug' || labelType.match(/^drug_/)) {
        paramsObj.labels = _.map(patientMedications, (pm) => {
            return _.camelCase(pm.medication.name);
        });
    }

    const paramsString = qs.stringify(paramsObj, { arrayFormat: 'repeat' });
    return `/#!/content-monograph/${labelType}${paramsString ? `?${paramsString}` : ''}`;
}

/** @ngInject */
function CompileDirective($, $compile, $sce, $state) {
    return {
        restrict: 'A',
        link(scope, element, attrs) {
            scope.$watch(
                () => {
                    // Parse the html just like ng-bind-html would
                    const compiled = $sce.parseAsHtml(attrs.compile)(scope);

                    const isActivityPage = $state.is('app.activity');
                    // Extra async dependencies only needed for disease & drug monographs, only needed on activity page
                    if (compiled.match(/\{\{(?:disease|drug)_/) && isActivityPage) {
                        return scope.$ctrl.patientTherapies && scope.$ctrl.patientMedications && compiled;
                    }

                    return compiled;
                },
                (value) => {
                    if (value && value.toString() && value.indexOf) {
                        let newValue = value;
                        // If this is an anchor tag, update the markup to include the stripped du-smooth-scroll
                        // attribute
                        const pendingHrefIndex = value.indexOf('href="#pend-activity-btn"');
                        if (pendingHrefIndex !== -1) {
                            newValue = `${value.slice(0, pendingHrefIndex)} du-smooth-scroll ${value.slice(
                                pendingHrefIndex
                            )}`;
                        }
                        element.html(newValue);

                        // If this is a table, make it responsive and add our styles
                        $(element)
                            .find('table')
                            .attr('class', 'therigy-table')
                            .wrap('<div class="table-responsive"></div>');

                        const matches = value.match(/\{\{[^{}]*\}\}/gm);
                        if (matches && matches.length > 0) {
                            const {
                                $ctrl: { patientTherapies, patientMedications },
                            } = scope;
                            for (let i = 0, len = matches.length; i < len; i += 1) {
                                const match = matches[i];
                                const labelTemplate = match.slice(2, -2);
                                const labelParts = labelTemplate.split('__');
                                const labelType = labelParts[0];
                                const linkText = labelParts[1];
                                const contentMonographUrl = createContentMonographUrl({
                                    labelType,
                                    patientTherapies,
                                    patientMedications,
                                });
                                newValue = newValue.replace(
                                    match,
                                    `<a
                                        class="show-content-${labelType}"
                                        href="${contentMonographUrl}"
                                        target="_blank"
                                    >
                                        ${linkText}
                                    </a>`
                                );
                            }
                        }

                        element.html(newValue);

                        $compile(element.contents())(scope);
                    }
                }
            );
        },
    };
}

export default CompileDirective;
