import { useQuery } from 'react-query';

import { getActivityQuestions } from 'App/services/AMSService';
import { errorHandler } from 'App/utils';

function useFetchActivityQuestions(activityId) {
    return useQuery(['activityQuestions', activityId], () => getActivityQuestions(activityId), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchActivityQuestions;
