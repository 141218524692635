import dayjs from 'dayjs';
import { clone } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaPaperclip } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { patientPropType } from 'Lib/types';
import * as R from 'ramda';
import { v4 as uuid } from 'uuid';

import Table from 'App/components/Table';
import useFetchPatientActivityFiles from 'App/hooks/useFetchPatientActivityFiles';
import useFetchSummaryNotes from 'App/hooks/useFetchSummaryNotes';
import { NOTE_TYPE_TO_SUMMARY_NOTE_TYPE } from 'App/services/PESService';
import CreatedByCell from './CreatedByCell';
import { formatForCell } from './DateFormatCell';
import SummaryNoteEditModal from './SummaryNoteEditModal';
import AttachmentsCell from './AttachmentsCell';
import ActionsCell from './ActionsCell';
import AddToPDFCell from 'App/features/ClinicalSummary/AddToPDFCell';
import { localize } from 'App/utils';
import NotesDownload from './NotesDownload';
import SafeHtmlCell from './SafeHtmlCell';

const sortDateColumn = (rowA, rowB, columnId) => {
    return dayjs(rowA.values[columnId]).diff(rowB.values[columnId]);
};

function mapSummaryNoteToDownloadNoteModel(note) {
    return {
        id: uuid(),
        name: note.subject,
        user: `${note.createdByUser.firstName} ${note.createdByUser.lastName}`,
        completed: localize(note.createdOn, 'M/D/YYYY H:mm A'),
        edited: localize(note.lastEditedOn, 'M/D/YYYY H:mm A'),
        body: note.body,
        history: note.history?.map(mapSummaryNoteToDownloadNoteModel),
    };
}

function groupSummaryNotes(summaryNotes, attachments) {
    return R.compose(
        R.map((grp) => {
            return R.compose(
                R.over(R.lensProp('createdOn'), formatForCell),
                R.over(R.lensProp('lastEditedOn'), formatForCell),
                R.over(
                    R.lensProp('history'),
                    R.sort((a, b) => dayjs(b.createdOn).diff(a.createdOn))
                )
            )(grp);
        }),
        R.values,
        R.reduce((agg, curr) => {
            const currGroupValue = agg[curr.parentNoteId];
            return {
                ...agg,
                [curr.parentNoteId]: {
                    ...curr,
                    attachments: attachments?.patientFiles.filter((file) => file.noteId === curr.parentNoteId) ?? [],
                    history: [...(currGroupValue?.history || []), curr],
                    createdOn: (currGroupValue || curr).createdOn,
                    lastEditedOn: curr.createdOn,
                },
            };
        }, {}),
        R.values
    )(summaryNotes);
}

const PatientSummaryNotesTable = ({ patient }) => {
    const patientId = patient.id;
    const [addNoteModalOpen, setAddNoteModalOpen] = useState(false);
    const [notesToDownload, setNotesToDownload] = useState({});
    const [showNotesModal, setShowNotesModal] = useState(false);

    const { data: summaryNotes, isLoading } = useFetchSummaryNotes(patientId);
    const { data: attachments } = useFetchPatientActivityFiles(patientId);

    const summaryNotesList = useMemo(() => groupSummaryNotes(summaryNotes, attachments), [summaryNotes, attachments]);

    const columns = useMemo(() => {
        return [
            {
                id: 'attachments',
                Header: <FaPaperclip />,
                accessor: 'attachments.length',
                Cell: AttachmentsCell,
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: 'Actions',
                Cell: ActionsCell,
            },
            {
                Header: 'Type',
                accessor: (row) => NOTE_TYPE_TO_SUMMARY_NOTE_TYPE[row.type],
            },
            {
                Header: 'Activity/Subject',
                accessor: 'subject',
                Cell: SafeHtmlCell,
            },
            {
                Header: 'Created By',
                accessor: (row) => CreatedByCell({ value: row.createdByUser }),
            },
            {
                Header: 'Created On',
                accessor: 'createdOn',
                sortType: sortDateColumn,
            },
            {
                Header: 'Last Edited On',
                accessor: 'lastEditedOn',
                sortType: sortDateColumn,
            },
            {
                Header: 'Add to PDF',
                Cell: AddToPDFCell,
                onChange: (event) => {
                    const newValue = clone(notesToDownload);
                    newValue[event.target.name] = event.target.checked;
                    setNotesToDownload(newValue);
                },
                accessor: (row) => !!notesToDownload[row.id],
                disableFilters: true,
                disableSortBy: true,
            },
        ];
    }, [notesToDownload]);

    const noteRows = summaryNotesList.filter((note) => !!notesToDownload[note.id]);

    const notes = noteRows.map(mapSummaryNoteToDownloadNoteModel);

    const handleDownloadNotes = () => {
        if (noteRows.length === 0) {
            return toast.error('No Notes Selected');
        }

        setShowNotesModal(R.not);
    };

    return (
        <div>
            <h2>Summary Notes</h2>

            <Button bsStyle="link" onClick={() => setAddNoteModalOpen(true)} id="add_note">
                Add Note
            </Button>
            {' | '}
            <Button bsStyle="link" onClick={handleDownloadNotes}>
                Download Selected Notes
            </Button>

            <Table
                columns={columns}
                data={summaryNotesList}
                isLoading={isLoading}
                initialState={{
                    sortBy: [
                        {
                            id: 'lastEditedOn',
                            desc: true,
                        },
                    ],
                }}
            />
            {addNoteModalOpen && (
                <SummaryNoteEditModal onClose={() => setAddNoteModalOpen(false)} patientId={patientId} />
            )}

            {showNotesModal && (
                <NotesDownload onDone={() => setShowNotesModal(false)} patient={patient} notes={notes} />
            )}
        </div>
    );
};

PatientSummaryNotesTable.propTypes = {
    patient: patientPropType.isRequired,
};

export default PatientSummaryNotesTable;
