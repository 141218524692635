import axios from 'axios';
import jwtDecode from 'jwt-decode';
import createAxios from 'App/utils/createAxios';
import __env from '../components/config/config';
import AuthService from '../services/AuthService';
import CompanyService from '../services/CompanyService';
import { errorHandler } from '../utils';
import getAuthorizationHeader from '../utils/getAuthorizationHeader';
import getSlsBasePath from '../utils/getSlsBasePath/getSlsBasePath';
import SessionStorageService from './SessionStorageService';
import UrlBuilderService from './UrlBuilderService';

const slsHost = getSlsBasePath(__env, 'user');

const user = createAxios({
    baseURL: `${slsHost}/${__env.slsVersion}`,
});

const tas = createAxios({
    baseURL: `${__env.tasEndpoint}:${__env.tasPort}/tas/${__env.tasVersion}`,
});

class UserService {
    /**
     * Fetches the User Object
     */
    static get = () => {
        let token = SessionStorageService.getJwt();

        if (!token) {
            return null;
        }

        token = jwtDecode(token);

        // Get branches from localStorage
        const branches = AuthService.getUserBranches(true);

        return {
            ...token.data,
            active_branch: {
                ID: token.data.BranchID,
                Name: token.data.BranchName,
                Privileges: token.data.UserPermissions,
            },
            active_company: {
                ID: token.data.CompanyID,
                Name: token.data.CompanyName,
            },
            active_roles: token.data.UserRoles,
            active_session: {
                SessionId: token.data.Token,
            },
            branches,
            company_permissions: token.data.CompanyPermissions,
            daylight_savings: token.data.DaylightSavings,
            default_branch: token.data.DefaultBranch,
            email: token.data.Email,
            expired_branches: token.data.ExpiredBranches,
            first_name: token.data.FirstName,
            id: token.data.UserID,
            is_password_expired: token.data.PasswordExpired || false,
            last_name: token.data.LastName,
            message_email: token.data.MessageEmail,
            suffix: token.data.Suffix,
            time_zone_offset: token.data.TimeZoneOffset,
            timeout_length: token.data.TimeoutLength,
            verbose: token.data.Verbose,
            user_permissions: token.data.UserPermissions,
            user_attributes: token.data.UserAttributes,
        };
    };

    /**
     * Gets branches the user has access to.
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    static getBranches = async () => {
        const url = UrlBuilderService.build('user', {
            user: '',
            'my-account': '',
        });
        try {
            const response = await axios.get(url);
            const branches = {};
            response.data.results.Branches.forEach((branch) => {
                branches[branch.ID] = branch;
            });
            return branches;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    };

    static getEhrBranchSettings = async () => {
        const branches = await UserService.getBranches();
        const branchIds = Object.keys(branches);

        return Promise.allSettled(
            branchIds.map((branch) => CompanyService.getBranchSettings('ehrType', null, false, branch))
        )
            .then((result) => result.filter((r) => r.status === 'fulfilled').map((r) => r.value))
            .catch(errorHandler);
    };

    /**
     * Fetches the count of unread and not deleted user messages.
     * @returns {Promise<messageCount>} - The count of unread and not deleted user messages
     */
    static getMessageCount() {
        return axios.get(`${slsHost}/${__env.slsVersion}/message/count`, getAuthorizationHeader()).then((res) => {
            const {
                data: { count },
            } = res;
            if (window) {
                window.localStorage.setItem('messageCount', count);
            }
            return res.data;
        });
    }

    /**
     * Fetches user messages.
     * @returns {Promise<messages>} - The user messages
     */
    static getUserMessages() {
        return user.get(`/messages`);
    }

    /**
     * Update messages viewed or deleted status
     * @returns {Promise<patchMessage>} - The response with update staus as true/false
     */
    static patchUserMessage(patchBody) {
        return user.patch(`/message/update-view-message-board`, patchBody);
    }

    /**
     * Gets available therapies
     */
    static getTherapy = async () => {
        const url = UrlBuilderService.build('user', {
            user: '',
            therapy: '',
        });
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            return error.data;
        }
    };

    /**
     * Changes branch
     * @param branchId - The branch id to change to.
     * @return {Promise}
     */
    static setBranch = async (divisionId) => {
        return tas.patch(`/user/changeBranch`, { divisionId });
    };

    /**
     * Change Users Default Branch
     * @param branchId
     */
    static setDefaultBranch = async (divisionId) => {
        return tas.patch(`/user/changeDefaultBranch`, { divisionId });
    };

    /**
     * Sets the passed in fields as disabled.
     *
     * @param {string} companyId - The company id.
     * @param {Array} fields - The fields to set as disabled.
     * @return {Promise} True if successful; false otherwise.
     */
    static setDisabledFields = async (companyId, fields) => {
        const url = UrlBuilderService.build('setPatientDisabledFields', {
            company: companyId,
            disabledPatientFields: '',
        });

        const response = await axios.post(url, fields);
        return response.data;
    };
}

export default UserService;
