import { getNavigationForUser, hasLength } from 'App/utils';
import { mapLocationActive } from 'App/utils/getNavigationForUser/getNavigationForUser';
import { useUserContext } from 'App/contexts/UserContext';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import './NavBar.scss';

const NavBar = () => {
    const user = useUserContext();
    const match = useRouteMatch();

    const navData = useMemo(() => {
        return R.compose(mapLocationActive(match.path), getNavigationForUser)(user);
    }, [user, match.path]);
    return (
        <div className="main-menu" id="nav-bar">
            <ul>
                {R.map((nav) => {
                    const anchorClass = nav.active ? 'active' : '';
                    const listClass = R.compose(R.concat(R.__, '-menu'), R.toLower)(nav.display);
                    return (
                        <li
                            id={`nav-bar-${nav.display}`}
                            key={nav.display}
                            className={listClass}
                            data-testid={nav.display}
                        >
                            {nav.location ? (
                                <a href={nav.location} className={anchorClass}>
                                    {nav.display}
                                </a>
                            ) : (
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a className={anchorClass}>{nav.display}</a>
                            )}
                            {hasLength(nav.children) && (
                                <ul className="dropdown">
                                    {R.map((child) => {
                                        const childClassname = child.active ? 'active' : '';
                                        return (
                                            <li key={child.display}>
                                                <a href={child.location} className={childClassname}>
                                                    {child.display}
                                                </a>
                                            </li>
                                        );
                                    }, nav.children)}
                                </ul>
                            )}
                        </li>
                    );
                }, navData)}
                <div className="clearfix" />
            </ul>
        </div>
    );
};
export default NavBar;
