import PropTypes from 'prop-types';

const patientInsurancePropType = PropTypes.shape({
    name: PropTypes.string,
    planPatientId: PropTypes.string,
    pharmacyInsurancePersonCode: PropTypes.string,
    groupNumber: PropTypes.string,
    payerIdCode: PropTypes.string,
    payerSegment: PropTypes.string,
    medicalProductLine: PropTypes.string,
    pharmacyProductLine: PropTypes.string,
    deductableAmount: PropTypes.number,
    deductableAmountPaidToDate: PropTypes.number,
    pharmacyPlanOopMax: PropTypes.number,
    pharmacyPlanOopMaxPaidToDate: PropTypes.number,
    coverageEffectiveDate: PropTypes.string,
    coverageEndDate: PropTypes.string,
    coverageType: PropTypes.string,
    isIntegratedHighDeductible: PropTypes.string,
    isPharmacyBenefitsCarveOutFromMedical: PropTypes.string,
    pharmacyInsuranceGroupId: PropTypes.string,
    pharmacyInsuranceBin: PropTypes.string,
    pharmacyInsurancePcn: PropTypes.string,
    helpDeskPhone: PropTypes.string,
    disabledOn: PropTypes.string,
    disabledBy: PropTypes.string,
});

export default patientInsurancePropType;
