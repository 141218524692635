/** @ngInject */
function EngageMessagingOptInModalController(
    _paq,
    $http,
    $q,
    $scope,
    $window,
    toastr,
    dateService,
    engageService,
    patientService
) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doCloseModal = doCloseModal;
    ctrl.submit = submit;

    function $onChanges(changes) {
        if (changes.patient && ctrl.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
            ctrl.originalPatient = angular.copy(ctrl.patient);
        }
        if (changes.subscription && ctrl.subscription !== null) {
            ctrl.subscription = angular.copy(ctrl.subscription);
            ctrl.infoMessage = ctrl.subscription
                ? `Submitting this form will resend the welcome text message.`
                : `Submitting this form will send an OPT IN text message to the patient.<b>Once the patient texts ` +
                  `back YES, they will be enrolled in mobile messaging</b>. Following enrollment, the patient will ` +
                  `receive text message(s) with a hyperlink to the mobile-enabled assessment(s) based on the ` +
                  `assessment's due date.<br><br>` +
                  `<b>The patient may opt out of text communications anytime by replying "UNSUBSCRIBE" ` +
                  `to 833-354-1731</b> (the number from which texts will be sent).` +
                  `<br><br>The patient must be enrolled prior to creating the activity for the activity ` +
                  `to send via text message.<br>` +
                  `<br>Disclaimer: Text messages are unsecure and unencrypted ` +
                  `(unless the patient has taken additional security precautions).` +
                  ` These text communications are governed by your pharmacy's Notice of Privacy Practices.`;

            ctrl.successMessage = 'Patient enrolled successfully';

            if (ctrl.subscription && typeof ctrl.subscription.subscribedOn === 'string') {
                ctrl.subscription.subscribedOn = dateService.create(ctrl.subscription.subscribedOn);
            }
        }
    }

    function $onInit() {
        ctrl.notification = false;
        ctrl.loading = false;
    }

    function doCloseModal() {
        _paq.push(['trackEvent', 'TherigyEngage', 'Modal from Profile - Cancel button']);
        ctrl.close();
    }

    function submit() {
        ctrl.loading = true;

        if (ctrl.subscription && ctrl.originalPatient.mobilePhone === ctrl.patient.mobilePhone) {
            ctrl.close({ $value: false });
            return true;
        }

        let promise = $q.when();

        // Update the patient's phone number if it has changed.
        if (ctrl.originalPatient.mobilePhone !== ctrl.patient.mobilePhone) {
            promise = patientService.update(ctrl.patient.id, {
                mobile_phone: ctrl.patient.mobilePhone,
            });
        }

        // Subscribe if not subscription exists or update subscription if phone number has changed.
        return promise
            .then(() => {
                if (!ctrl.subscription || ctrl.originalPatient.mobilePhone !== ctrl.patient.mobilePhone) {
                    return engageService.subscribeSMS(ctrl.patient.id);
                }
                return $q.when();
            })
            .then(() => {
                ctrl.notification = false;
                ctrl.close({ $value: true });
                toastr.success(ctrl.successMessage);
                return true;
            })
            .catch((err) => {
                ctrl.loading = false;
                ctrl.notification = err.data.message;
                return false;
            });
    }
}

export default EngageMessagingOptInModalController;
