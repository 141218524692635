/** @ngInject */
function PatientOtherConditionController(_, $scope, patientOtherConditionService, icd10Service) {
    const ctrl = this;
    const deletedConditions = [];

    ctrl.$onChanges = $onChanges;
    ctrl.conditionLookup = conditionLookup;
    ctrl.doChangeValue = doChangeValue;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;
    ctrl.removeCondition = removeCondition;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
            if (ctrl.patient.id) {
                return patientOtherConditionService.getMany(ctrl.patient.id).then((res) => {
                    ctrl.patient.other_condition = _.values(res);
                });
            }
            ctrl.patient.other_condition = [];
            ctrl.no_known_medical_conditions = false;
        }

        doChangeValue();
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function conditionLookup(search) {
        ctrl.conditionOptions = [];
        if (typeof search === 'string') {
            const resultsLimit = 5;
            return icd10Service.search(search, resultsLimit).then((res) => {
                const icd10Data = {};
                _.forEach(res, (item) => {
                    icd10Data[item.id] = item;
                });
                ctrl.conditionOptions = _.values(icd10Data);
                return _.values(res);
            });
        }
    }

    function doChangeValue(index) {
        if (index !== undefined) {
            const selectedItem = ctrl.patient.other_condition[index];
            if (typeof selectedItem?.text === 'object') {
                // If selectedItem.text is an object, update it to the concatenated value
                selectedItem.text = `${selectedItem.text.code} - ${selectedItem.text.shortDescription}`;
            }
        }

        $scope.$emit('addPatientPage_updatePatient', {
            other_condition: _.compact(ctrl.patient.other_condition),
            no_known_medical_conditions: ctrl.patient.no_known_medical_conditions,
        });
        $scope.$emit('addPatientPage_updateDeletedOtherConditions', _.compact(deletedConditions));
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
    }

    function removeCondition(index) {
        if (Object.prototype.hasOwnProperty.call(ctrl.patient.other_condition[index], 'id')) {
            deletedConditions.push(ctrl.patient.other_condition[index].id);
        }
        ctrl.patient.other_condition.splice(index, 1);
        doChangeValue();
    }
}

export default PatientOtherConditionController;
