import PESService from 'App/services/PESService';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { errorHandler } from 'App/utils';

function useSaveWorkQueueFilterMutation() {
    const queryClient = useQueryClient();

    return useMutation((reqBody) => PESService.saveFilter(reqBody), {
        onError: errorHandler,
        onSuccess: async (data) => {
            toast.success('Filter has been saved successfully.');
            const formattedFilters = data.map((it) => ({
                ...it,
                filter: JSON.parse(it.filter),
            }));
            await queryClient.setQueryData(['workQueue', 'savedFilters'], formattedFilters);
        },
    });
}

export default useSaveWorkQueueFilterMutation;
