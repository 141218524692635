/** @ngInject */
function ExpiredLicenseModalController($state) {
    const ctrl = this;
    ctrl.reRoute = reRoute;
    ctrl.cancel = cancel;

    function cancel() {
        ctrl.dismiss({});
    }

    function reRoute() {
        $state.go('app.accessView');
        ctrl.dismiss({});
    }
}

export default ExpiredLicenseModalController;
