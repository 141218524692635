import { useQuery } from 'react-query';

import { getUsers } from 'App/services/DivisionService';

function useFetchDivisionUsers() {
    return useQuery(['division', 'users'], getUsers, {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchDivisionUsers;
