import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isNil, path } from 'ramda';

import CompanyService from '../../services/CompanyService';
import PESService from '../../services/PESService';
import { shouldExecutePatientSearch, shouldExecuteVisitSearch } from '../../services/EhrService';
import { hasLength, isExpired } from '../../utils';
import { SynchronousQueue } from '../../utils/synchronousQueue';

dayjs.extend(utc);
/**
 *  assessment-preview.component.js creates preview functionality for this component.
 *  When making changes to this file verify functionality of the assessment-preview.component.
 */
/** @ngInject */
function AssessmentController(
    _,
    __env,
    $,
    $q,
    $scope,
    $state,
    $uibModal,
    $window,
    authService,
    activityStatusService,
    amsService,
    assessmentService,
    dateService,
    jwtHelper,
    moment,
    pesService,
    sessionStorageService,
    socketService,
    toastr
) {
    const ctrl = this;

    ctrl.cdnURL = __env.cdnURL;
    ctrl.isModalOpen = false;
    ctrl.isLockModalOpen = false;
    ctrl.activityEhrEligible = false;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeSummaryNote = doChangeSummaryNote;
    ctrl.doSaveResponse = doSaveResponse;
    ctrl.generatePdf = generatePdf;
    ctrl.isClinicalAndPcc = isClinicalAndPcc;
    ctrl.openAssessmentContentModal = openAssessmentContentModal;
    ctrl.openDeleteModal = openDeleteModal;
    ctrl.openEhrEncounterNoteModal = openEhrEncounterNoteModal;
    ctrl.openSaveResponseRetryModal = openSaveResponseRetryModal;
    ctrl.pendAssessment = pendAssessment;
    ctrl.saveSummaryNote = saveSummaryNote;
    ctrl.submitAssessmentForm = submitAssessmentForm;
    ctrl.updateAssessmentQuestions = updateAssessmentQuestions;
    ctrl.isResendEhrEligible = isResendEhrEligible;
    ctrl.goToMyAccount = goToMyAccount;

    ctrl.responseSelectQueue = new SynchronousQueue();

    $scope.$on('openAssessmentContentModal', openAssessmentContentModal);
    $scope.$on('saveAssessmentQuestionResponse', (event, data) => {
        ctrl.responseSelectQueue.enqueue(() => doSaveResponse(event, data));
    });
    $scope.$on('updateAssessmentQuestions', updateAssessmentQuestions);
    $scope.$on('updateActivityDate', (event, data) => {
        ctrl.activityDate = data;
    });

    function isIE11() {
        return !window.ActiveXObject && 'ActiveXObject' in window;
    }

    async function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            processActivityData(ctrl.activity);

            const retiredOn = path(['assessment', 'retiredOn'], ctrl.activity);
            if (retiredOn) {
                ctrl.showRetiredAlert = isExpired(retiredOn);
                ctrl.retiredOn = dayjs.utc(retiredOn).format('MMMM D, YYYY');
            }
        }

        if (changes.branchSettings && ctrl.branchSettings) {
            ctrl.branchSettings = angular.copy(ctrl.branchSettings);
            ctrl.userEhrEligible = true;
            if (!isNil(ctrl.branchSettings.ehrNotesHiddenSections)) {
                try {
                    ctrl.branchSettings.ehrNotesHiddenSections = JSON.parse(ctrl.branchSettings.ehrNotesHiddenSections);
                } catch (e) {
                    ctrl.branchSettings.ehrNotesHiddenSections = false;
                    toastr.warning('The EHR hidden section property is malformed.');
                }
            }

            if (ctrl.branchSettings.noteRequiresEhrProviderId === true) {
                ctrl.userEhrEligible = !!ctrl.user.user_attributes.ehrProviderId;
            }

            if (isNil(ctrl.branchSettings.ehrType)) {
                ctrl.activityEhrActivity = false;
            } else {
                ctrl.activityEhrEligible = _.get(ctrl.activity, 'assessment.settings.settings.ehrEncounterNote', false);
            }
        }

        if (changes.patient && ctrl.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
            ctrl.patient.age = pesService.getAge(ctrl.patient.birthDate);
            const promises = {
                medications: pesService.getMedication(ctrl.patient.id),
                therapies: pesService.getTherapies(ctrl.patient.id),
                identifiers: PESService.getIdentifiers(ctrl.patient.id),
                visits: PESService.getVisits(ctrl.patient.id),
            };

            // Defaulting this to true so that we don't display "Patient is not eligible..."
            // until we've verified that the patient has no identifiers in STM and that
            // no identifiers were returned from the EHR patient search
            if (ctrl.activityEhrEligible) {
                ctrl.patientEhrEligible = true;
            }

            $q.all(promises).then(async (res) => {
                ctrl.patientMedications = res.medications;
                ctrl.patientTherapies = res.therapies;
                ctrl.patientIdentifiers = res.identifiers;
                ctrl.patientVisits = res.visits;

                if (ctrl.activityEhrEligible) {
                    ctrl.patientEhrEligible = await isPatientEhrEligible(ctrl.patient.id);
                }
            });
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            ctrl.fullyScriptedMode = ctrl.user.verbose === 1;
        }
        if (changes.transitionParams && ctrl.transitionParams) {
            ctrl.transitionParams = angular.copy(ctrl.transitionParams);
        }

        if (ctrl.activity.completedOn) {
            ctrl.activityCompletedOnValue = dateService.localize(ctrl.activity.completedOn, 'LL');
        }
    }

    function goToMyAccount(nextState) {
        window.history.pushState(nextState, null, '/#!/myAccount');
    }

    function isResendEhrEligible() {
        if (!(ctrl.activity && ctrl.activity.activityEhrNotes && ctrl.activityEhrEligible && ctrl.patientEhrEligible)) {
            return false;
        }
        const { sentOn, failed } = ctrl.activity.activityEhrNotes;

        const hasNoteBeenSentToEhr = !!sentOn && !failed;

        return !hasNoteBeenSentToEhr && ctrl.activity.status === 1;
    }

    function $onInit() {
        if (ctrl.patient && ctrl.user) {
            if (ctrl.patient.divisionId !== ctrl.user.active_branch.ID) {
                authService
                    .changeBranch(ctrl.patient.divisionId)
                    .then((branchChangeUser) => {
                        if (branchChangeUser) {
                            ctrl.user = branchChangeUser;
                        }
                    })
                    .catch(() => {
                        ctrl.patient = false;
                        $scope.go('app.404');
                    });
            }
        }

        // If the socket is already active revalidate jwt.
        if (socketService.connected()) {
            socketService.emit('verifyJwt', sessionStorageService.getJwt());
        }

        socketService.on('connect', () => {
            socketService.emit('verifyJwt', sessionStorageService.getJwt());
        });

        socketService.on('disconnect', () => {
            socketService.reconnectIO();
        });

        socketService.on('jwtInvalid', () => {
            $state.go('auth.logout');
        });

        socketService.on('jwtVerified', () => {
            ctrl.jwtVerified = true;
            focusListener();
        });

        socketService.on('activityAssigned', () => {
            ctrl.assigned = true;
        });

        socketService.on('activityUnassigned', () => {
            ctrl.assigned = false;
        });

        socketService.on('activityLocked', (user) => {
            if (user) {
                ctrl.lockedName = user;
            }
            ctrl.locked = true;
            if (!ctrl.isLockModalOpen) {
                openLockedModal();
            }
        });

        if (!isIE11()) {
            $window.addEventListener('focus', focusListener);
            $window.addEventListener('blur', blurListener);
        }

        if (ctrl.branchSettings) {
            ctrl.isEhrEligible = !!ctrl.branchSettings.ehrType;
        }
    }

    $scope.$on('$destroy', () => {
        if (!isIE11()) {
            $window.removeEventListener('focus', focusListener);
            $window.removeEventListener('blur', blurListener);
        }
        ctrl.exiting = true;
        closeLockedModal();
        emitUnassignActivity();

        socketService.removeAllListeners();
        socketService.close();
    });

    function blurListener() {
        connectAndRun(emitUnassignActivity);
    }

    function refreshActivity(forceUpdate = false) {
        return (
            amsService
                .getActivity(ctrl.patient.id, ctrl.activity.id, true)
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .then((activity) => {
                    /**
                     * First, check to see if we want to force an update. This is useful for the EHR modal, which seems
                     * to fall out of sync for some reason.
                     *
                     * Then, only process the activity if the last update was made my someone else and that date is in
                     * the future
                     */
                    if (
                        forceUpdate ||
                        (activity.updatedBy !== ctrl.user.id && activity.updatedOn > ctrl.activity.updatedOn)
                    ) {
                        return processActivityData(activity);
                    }
                })
                .catch((error) => {
                    // ams returns 400 for patient not found
                    return error.status === 400 ? $state.go('app.404') : $state.go('app.500');
                })
        );
    }

    function focusListener() {
        setTimeout(() => {
            if ($window.document.hasFocus() && ctrl.exiting !== true) {
                connectAndRun(emitAssignActivity);
                refreshActivity();
            }
        }, 100);
    }

    function processActivityData(activity) {
        ctrl.activity = angular.copy(activity);
        const promises = {
            activity: amsService.getActivityQuestions(ctrl.activity.id),
        };

        if (ctrl.activity.assessment.settings && ctrl.activity.assessment.settings.settings.enhancedSummaryNote) {
            ctrl.activityClinicalIssues = CompanyService.getCustomMenu('activity_clinical_issues');
        }

        return $q.all(promises).then((res) => {
            // Manipulate the response before we create any one-time bindings
            amsService.formatRapid3Questions(res.activity);
            getScores(ctrl.activity.assessment.scoring, res.activity);

            // Flat question list for storing state
            ctrl.questionList = angular.copy(res.activity);

            // Nested question list for display
            ctrl.allQuestions = angular.copy(amsService.attachChildren(res.activity));

            // Remove any hidden or disable questions and split them into their respective groups/sections
            let questions = amsService.formatQuestions(ctrl.allQuestions);
            questions = amsService.splitQuestions(questions);
            ctrl.questions = angular.copy(questions.questions);
            ctrl.specialQuestions = angular.copy(questions.specialQuestions);
            ctrl.therapySummaryNote = questions.therapySummaryNote.length > 0;
            ctrl.groupedQuestions = angular.copy(questions.groupedQuestions);
            const groupIds = _.keys(questions.groupedQuestions);
            const questionGroups = _.values(amsService.formatQuestionGroups(groupIds));
            ctrl.questionGroups = _.remove(questionGroups, (g) => {
                return groupIds.indexOf(g.id.toString()) !== -1;
            });

            ctrl.activity = angular.copy(ctrl.activity);
        });
    }

    function connectAndRun(fn) {
        if (socketService.connected()) {
            fn();
        } else {
            socketService.reconnectIO();
            fn();
        }
    }

    function closeLockedModal() {
        if (ctrl.lockedModal) {
            ctrl.lockedModal.close();
        }
    }

    function completeAssessment(ehrNote = false) {
        // Save summary note
        ctrl.busy = true;

        return ctrl.saveSummaryNote().then(() => {
            // Save the assessment status; This will also fire an email if required.
            // This needs to be completed before triggering protocols as the completeAssessment relies on the status
            return amsService
                .saveStatus(ctrl.activity.id, 'Finished')
                .then(() => {
                    const promises = {
                        // Send update to TMS
                        tms: activityStatusService
                            .sendActivityUpdate(ctrl.patient.id, ctrl.activity.id, true)
                            .catch(() => {
                                toastr.warning('Failed to send activity update.');
                            }),
                    };
                    if (ehrNote !== false) {
                        promises.ehrNote = amsService.saveEhrNote(ctrl.activity.id, ehrNote);
                    }

                    const token = jwtHelper.decodeToken(sessionStorageService.getJwt());
                    if (!token.data.CompanyPermissions.NewProtocolManager) {
                        // TODO: Remove this check when all companies are using new protocol system.
                        // Trigger the enabled protocols if the company is not using the new protocol system.
                        promises.trigger = assessmentService.completeAssessment(ctrl.patient.id, ctrl.activity.id);
                    }

                    return $q.all(promises).then(() => {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        $window.location = `/#!/patient/${ctrl.patient.id}?branchId=${ctrl.patient.divisionId}`;
                    });
                })
                .catch((e) => {
                    toastr.error(e.data.message);
                    ctrl.busy = false;
                    throw new Error();
                });
        });
    }

    function doChangeSummaryNote(summaryNote) {
        doSaveResponse(summaryNote);
    }

    function doSaveResponse(event, data) {
        // prevent form from submitting
        ctrl.savingResponse = true;
        if (ctrl.locked) {
            if (!ctrl.isLockModalOpen) {
                openLockedModal();
                ctrl.savingResponse = true;
            }
            return;
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return amsService
            .saveResponse(ctrl.activity.id, data.question.id, data.response)
            .then((res) => {
                if (!ctrl.questionList[data.question.id]) {
                    ctrl.questionList[data.question.id] = data.question;
                }

                ctrl.questionList[data.question.id].response = data.response.response;
                if (data.unselectedAnswers) {
                    removeQuestions(ctrl.questionList, data.unselectedAnswers);
                }
                ctrl.questionList = angular.copy(ctrl.questionList);

                getScores(ctrl.activity.assessment.scoring, ctrl.questionList);
                return res;
            })
            .catch((error) => {
                if (error.status !== 409) {
                    openSaveResponseRetryModal(event, data);
                } else {
                    // do nothing
                }
            })
            .finally(() => {
                // form can submit normally again
                ctrl.savingResponse = false;
            });
    }

    function getScores(scoring, questions) {
        if (_.size(ctrl.activity.assessment.scoring) > 0) {
            ctrl.showScoring = true;
            const scores = amsService.getScores(scoring, questions);
            let followUpDate = new Date();

            if (
                ctrl.activity.status === 1 &&
                ctrl.activity.completedOn !== '0000-00-00 00:00:00' &&
                ctrl.activity.completedOn !== null
            ) {
                followUpDate = ctrl.activity.completedOn;
            }

            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-restricted-syntax
            for (const p in scores) {
                if (Object.prototype.hasOwnProperty.call(scores, p)) {
                    scores[p].followUpDate = moment(followUpDate, 'YYYY/MM/DD')
                        .add(scores[p].followUp, 'days')
                        .toDate();
                }
            }

            // TODO: Rapid 3 hack
            // Handle the functional scoring
            const functionalScore = scores['15446'] || scores['15450'] || scores['15554'] || scores['15558'];
            if (functionalScore) {
                $scope.$broadcast('updateRapid3FunctionalScore', {
                    value: functionalScore.value,
                });
            }

            // TODO: Rapid 3 hack
            // Handle the total scoring
            const totalScore = scores['15447'] || scores['15451'] || scores['15555'] || scores['15559'];
            if (totalScore) {
                $scope.$broadcast('updateRapid3TotalScore', {
                    followUpDate: totalScore.followUpDate,
                    stratification: totalScore.stratification,
                    value: totalScore.value,
                });
            }

            // TODO: Rapid 3 hack
            delete scores['15446'];
            delete scores['15447'];
            delete scores['15450'];
            delete scores['15451'];
            delete scores['15554'];
            delete scores['15555'];
            delete scores['15558'];
            delete scores['15559'];
            ctrl.scores = scores;
        }
    }

    function emitAssignActivity() {
        // only assign the activity if it is pending or new, and not already assigned to this session.
        if (ctrl.assigned !== true && ctrl.activity && [0, 3].includes(ctrl.activity.status)) {
            console.log('emitAssign');
            socketService.emit('assignActivity', {
                activityId: ctrl.activity.id,
                user: _.pick(ctrl.user, ['id', 'first_name', 'last_name']),
            });
        }
    }

    function emitUnassignActivity() {
        socketService.emit('unassignActivity', {
            activityId: ctrl.activity.id,
            userId: ctrl.user.id,
        });
    }

    function isClinicalAndPcc() {
        const roles = ctrl.user.active_roles;
        const pccOnly = !roles.RN && !roles.RPh && roles.PCC;
        return ctrl.activity.assessment.defaultNoteType === 1 && pccOnly;
    }

    function openDeleteModal() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'confirmDeleteModal',
            keyboard: false,
            size: 'md',
            resolve: {
                modalData() {
                    return {
                        activityId: ctrl.activity.id,
                    };
                },
            },
        });

        modalInstance.result
            .then((res) => {
                if (res && res.status === 'Deleted') {
                    ctrl.saveSummaryNote().then(() => {
                        $state.go('app.patient', {
                            patientId: ctrl.patient.id,
                            branchId: ctrl.patient.division_id,
                        });
                    });
                }
                return res;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function openEhrEncounterNoteModal(resubmit = false) {
        refreshActivity(true).then(() => {
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                component: 'ehrEncounterNoteModal',
                keyboard: false,
                size: 'md',
                resolve: {
                    modalData() {
                        return {
                            activity: ctrl.activity,
                            activityDate: ctrl.activityDate,
                            branchSettings: ctrl.branchSettings,
                            clinicalIssues: ctrl.newSummaryNoteClinicalIssues,
                            medications: Object.values(ctrl.patientMedications),
                            patient: ctrl.patient,
                            patientIdentifiers: Object.values(ctrl.patientIdentifiers),
                            questions: ctrl.allQuestions,
                            questionGroups: ctrl.questionGroups,
                            summaryNote: ctrl.newSummaryNote,
                            user: ctrl.user,
                        };
                    },
                },
            });
            modalInstance.result
                .then((res) => {
                    if (!resubmit) {
                        // If not a resubmit the completeAssessment function will
                        // send the note after updating activity status.
                        return completeAssessment(res);
                    }
                    // If this is a resubmit, the activity is already marked as finished.
                    return amsService.saveEhrNote(ctrl.activity.id, res);
                })
                .then((res) => {
                    // eslint-disable-next-line no-param-reassign
                    $window.location = `/#!/patient/${ctrl.patient.id}?branchId=${ctrl.patient.divisionId}`;
                    return res;
                })
                .catch((err) => {
                    return err;
                });
        });
    }

    function openLockedModal() {
        ctrl.isLockModalOpen = true;
        ctrl.lockedModal = $uibModal.open({
            backdrop: 'static',
            component: 'lockAssessmentModal',
            keyboard: false,
            size: 'md',
            resolve: {
                modalData() {
                    return {
                        lockedName: ctrl.lockedName,
                    };
                },
            },
        });
        ctrl.lockedModal.result
            .then(() => {
                ctrl.isLockModalOpen = false;
            })
            .catch(() => {
                ctrl.isLockModalOpen = false;
            });
    }

    function openSaveResponseRetryModal(event, response) {
        if (ctrl.isModalOpen) {
            return;
        }
        ctrl.isModalOpen = true;
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'saveResponseRetryModal',
            keyboard: false,
            size: 'md',
            resolve: {
                modalData() {
                    return {
                        activityId: ctrl.activity.id,
                        questionId: response.question.questionId,
                        response: response.response,
                    };
                },
            },
        });
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return modalInstance.result
            .then(() => {
                ctrl.isModalOpen = false;
                return ctrl.doSaveResponse(event, response);
            })
            .catch((err) => {
                ctrl.isModalOpen = false;
                return err.data;
            });
    }

    function generatePdf() {
        // hide UI
        $('#generate-pdf img').hide(0);
        $('#generate-pdf i').show(0);
        $('.summary-note-wrapper').css('resize', 'none').css('height', 'auto');
        $('#walkme-player').hide();

        // grab body HTML
        const htmlString = document.body.outerHTML;

        return amsService.generatePdf(htmlString, 'activity', ctrl.activity.name, ctrl.activity.id).finally(() => {
            $('#generate-pdf img').show(0);
            $('#generate-pdf i').hide(0);
            $('.summary-note-wrapper').css('resize', 'vertical').css('height', '120px');
            $('#walkme-player').show();
            $window._paq.push(['trackEvent', 'TherigyAssessments', 'Activity - Generate PDF']);
        });
    }

    function pendAssessment() {
        return ctrl
            .saveSummaryNote()
            .then(amsService.saveStatus(ctrl.activity.id, 'Pending'))
            .then(() => {
                $state.go('app.patient', {
                    patientId: ctrl.patient.id,
                    branchId: ctrl.patient.division_id,
                });
            })
            .catch((e) => {
                if (e !== false) {
                    toastr.error(e.data.message);
                }
            });
    }

    function removeQuestions(questions, answerIds) {
        const unansweredQuestions = _.filter(questions, (q) => {
            return answerIds.indexOf(q.parentAnswerId) !== -1;
        });

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = unansweredQuestions.length; i < len; i++) {
            const hasChildren = _.flatten(_.map(unansweredQuestions[i].answers, 'children'));

            if (hasChildren.length > 0) {
                const childAnswerIds = _.keys(unansweredQuestions[i].answers);
                removeQuestions(questions, childAnswerIds);
            } else {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                delete questions[unansweredQuestions[i].questionId];
            }
        }
    }

    function saveSummaryNote() {
        if (_.isEmpty(ctrl.newSummaryNoteClinicalIssues)) {
            ctrl.newSummaryNoteClinicalIssues = [];
        }

        if (_.isEmpty(ctrl.newSummaryNote) && ctrl.newSummaryNoteClinicalIssues.length === 0) {
            return $q.when();
        }

        let emrCheck = {};
        if (
            ctrl.user.company_permissions.SendClinicalIdentifiedIssue &&
            !_.isEmpty(ctrl.newSummaryNoteClinicalIssues)
        ) {
            emrCheck = $uibModal.open({
                backdrop: 'static',
                component: 'sendEmrSummaryNoteModal',
                keyboard: false,
                size: 'md',
            });
        } else {
            // Nothing to check return completed promise
            emrCheck.result = $q.when().then(() => {
                return { sendToEmr: false };
            });
        }

        return emrCheck.result.then((res) => {
            return pesService
                .createSummaryNote(
                    ctrl.patient.id,
                    ctrl.activity.id,
                    ctrl.newSummaryNoteClinicalIssues,
                    res.sendToEmr,
                    ctrl.newSummaryNote,
                    ctrl.activity.assessment.name,
                    ctrl.activity.assessment.defaultNoteType === 0 ? 'PCC' : 'CLINICAL',
                    null
                )
                .then(() => {
                    ctrl.newSummaryNote = null;
                    ctrl.newSummaryNoteClinicalIssues = [];
                    return true;
                })
                .catch(() => {
                    toastr.error('Failed to save summary note.');
                    return $q.reject(false);
                });
        });
    }

    function openAssessmentContentModal(event, label) {
        $uibModal.open({
            backdrop: 'static',
            component: 'assessmentContentModal',
            openedClass: 'assessment-content-modal',
            windowClass: 'assessment-content-modal',
            windowTopClass: 'assessment-content-modal',
            keyboard: false,
            size: 'lg',
            resolve: {
                modalData() {
                    const modalData = {
                        label,
                        labels: [],
                        versionDate: null,
                    };

                    const type = label.split('_');

                    if (type[0]) {
                        modalData.labelType = type[0];

                        if (type[0] === 'disease') {
                            modalData.labels = _.map(ctrl.patientTherapies, (pt) => {
                                return _.camelCase(pt.therapy.name);
                            });
                        }

                        if (type[0] === 'drug') {
                            modalData.labels = _.map(ctrl.patientMedications, (pm) => {
                                return _.camelCase(pm.medication.name);
                            });
                        }

                        if (
                            ctrl.activity.status === 1 &&
                            ctrl.activity.completedOn !== '0000-00-00 00:00:00' &&
                            ctrl.activity.completedOn !== null
                        ) {
                            modalData.versionDate = moment(ctrl.activity.completedOn).format('YYYY-MM-DD');
                        }
                    }

                    return modalData;
                },
            },
        });
    }

    function submitAssessmentForm() {
        if (ctrl.activityEhrEligible && ctrl.patientEhrEligible && ctrl.userEhrEligible) {
            openEhrEncounterNoteModal();
        } else {
            completeAssessment();
        }
    }

    function updateAssessmentQuestions(event, questions) {
        // TODO: Rapid 3 hack
        amsService.formatRapid3Questions(questions);
        ctrl.questionList = _.assignIn(ctrl.questionList, questions);
        ctrl.questionList = angular.copy(ctrl.questionList);
        ctrl.allQuestions = angular.copy(amsService.attachChildren(ctrl.questionList));
    }

    async function isPatientEhrEligible(patientId) {
        const identifierList = Object.values(ctrl.patientIdentifiers);

        const executePatientSearch = shouldExecutePatientSearch(identifierList, ctrl.branchSettings);
        const executeVisitSearch = shouldExecuteVisitSearch(ctrl.patientVisits, ctrl.branchSettings);

        if (!executePatientSearch && !executeVisitSearch) {
            return true;
        }

        ctrl.isSearchingEhr = true;
        try {
            if (executePatientSearch) {
                const identifiers = await PESService.getPatientIdentifiersFromEhr(patientId);

                if (hasLength(Object.values(identifiers))) {
                    ctrl.patientIdentifiers = identifiers;
                }
            }

            if (executeVisitSearch) {
                const visits = await PESService.getPatientVisitsFromEhr(patientId);

                if (hasLength(Object.values(visits))) {
                    ctrl.patientVisits = visits;
                }
            }
            // silently catch errors from FHIR search, this shouldn't influence our eligibility checks
            // error codes from the FHIR searches can be legitimate responses, so we don't want
            // to block the activity if that occurs, and ensure eligibility is calculated based on
            // the existence of identifiers/visits
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }

        ctrl.isSearchingEhr = false;
        return (
            !isEmpty(ctrl.patientIdentifiers) &&
            (!isEmpty(ctrl.patientVisits) || !ctrl.branchSettings.ehrNoteRequiresVisit)
        );
    }
}

export default AssessmentController;
