import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import SummaryNoteEditModal from './SummaryNoteEditModal';
import PropTypes from 'prop-types';
import summaryNotePropType from './types/summaryNotePropType';

const ActionsCell = ({ row }) => {
    const [noteModalOpen, setNoteModalOpen] = useState(false);
    return (
        <>
            <Button bsStyle="link" onClick={() => setNoteModalOpen(true)}>
                View/Edit
            </Button>
            {noteModalOpen && (
                <SummaryNoteEditModal
                    onClose={() => setNoteModalOpen(false)}
                    patientId={row.original.patientId}
                    existingNote={row.original}
                />
            )}
        </>
    );
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        original: summaryNotePropType,
    }).isRequired,
};

export default ActionsCell;
