import React, { useCallback } from 'react';
import { FormControl } from 'react-bootstrap';
import compose from 'ramda/src/compose';
import path from 'ramda/src/path';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
const SearchColumnFilter = ({ column: { filterValue, setFilter, Header } }) => {
    const onFilterChange = useCallback(
        (event) => {
            return compose(setFilter, path(['target', 'value']))(event);
        },
        [setFilter]
    );

    return (
        <FormControl
            type="text"
            value={filterValue || ''}
            onChange={onFilterChange}
            bsSize="small"
            placeholder={`Filter by ${Header}`}
            className="input-filter"
            aria-label={`Filter by ${Header}`}
        />
    );
};

export default SearchColumnFilter;
