import PropTypes from 'prop-types';
import React from 'react';
import { Radio } from 'react-bootstrap';

import asFormField from './asFormField';

const RadioInput = ({
    name,
    value,
    onChange,
    onBlur,
    className,
    style,

    options,
    disabled,
}) => {
    return (
        <div className={className} style={style}>
            {options.map((opt) => (
                <Radio
                    key={opt.id}
                    name={name}
                    value={opt.id}
                    checked={opt.id === value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled}
                >
                    {opt.option}
                </Radio>
            ))}
        </div>
    );
};

RadioInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            option: PropTypes.node,
        })
    ).isRequired,
    disabled: PropTypes.bool,
};

RadioInput.defaultProps = {
    value: null,
    className: '',
    style: null,

    disabled: false,
};

export const RadioField = asFormField({ WrappedComponent: RadioInput });
