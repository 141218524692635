/**
 * This service handles requests for the Assignment Manager
 */
/** @ngInject */
function AssignmentManagerService($http, urlBuilderService) {
    return {
        assignTasks,
        getUsers,
    };

    function assignTasks(params) {
        const url = urlBuilderService.build('assign', {
            'assignment-manager': '',
        });

        return $http.post(url, params).then((res) => {
            return res.data;
        });
    }

    function getUsers(params) {
        const url = urlBuilderService.build('', {
            'assignment-manager/users': '',
        });

        return $http
            .get(url, {
                params,
            })
            .then((res) => {
                return res.data;
            });
    }
}

export default AssignmentManagerService;
