import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import RxFill from './RxFill';
import { Button, Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import PatientPrescriberTypeAhead from './PatientPrescriberTypeAhead';
import * as R from 'ramda';
import { DatePickerInput } from '../../../lib/form/DatePickerField';
import useFetchRequiredPatientFields from 'App/hooks/useFetchRequiredPatientFields';
import useFetchDisabledPatientFields from 'App/hooks/useFetchDisabledPatientFields';
import { cx } from '@emotion/css';

const propTypes = {
    onAddRxFill: PropTypes.func.isRequired,
    handlePropChange: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    patientPrescriptions: PropTypes.object,
    medicationId: PropTypes.string,
    index: PropTypes.number,
    handleFillPropChange: PropTypes.func,
    emitEventToAddPrescriber: PropTypes.func,
    removeRxNumber: PropTypes.func,
    handleRemoveFillNumber: PropTypes.func,
    handleSearchPrescriber: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    searchPrescriber: PropTypes.array,
    handleSelectedPrescriber: PropTypes.func,
    handleEndRxNumber: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    medication: PropTypes.object,
    handleResetEndPrescription: PropTypes.func,
    validateRxFillNumber: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    validateRxFillNumberErrors: PropTypes.object,
};
const defaultProps = {
    patientPrescriptions: {},
    medicationId: '',
    index: 0,
    handleFillPropChange: () => {},
    emitEventToAddPrescriber: () => {},
    removeRxNumber: () => {},
    handleRemoveFillNumber: () => {},
    handleSearchPrescriber: () => {},
    searchPrescriber: [],
    handleSelectedPrescriber: () => {},
    handleEndRxNumber: () => {},
    medication: {},
    handleResetEndPrescription: () => {},
    validateRxFillNumber: () => {},
    validateRxFillNumberErrors: {},
};

function RxNumber(props) {
    const {
        handlePropChange,
        patientPrescriptions,
        medicationId,
        index,
        handleFillPropChange,
        emitEventToAddPrescriber,
        removeRxNumber,
        handleRemoveFillNumber,
        handleSearchPrescriber,
        searchPrescriber,
        handleSelectedPrescriber,
        handleEndRxNumber,
        medication,
        handleResetEndPrescription,
        validateRxFillNumber,
        validateRxFillNumberErrors,
        onAddRxFill,
    } = props;

    const fillNumberIndex = [];
    const prescriptionKeys = Object.keys(patientPrescriptions) || [];
    const prescription = patientPrescriptions?.[medicationId]?.rxNumbers;
    const [initialPrescription] = useState(R.clone(patientPrescriptions?.[medicationId]?.rxNumbers?.[index]));

    const isNew = !!initialPrescription._internalUiId;
    const { data: requiredPatientFields } = useFetchRequiredPatientFields();
    const { data: disabledPatientFields } = useFetchDisabledPatientFields();

    const requiredFields = Object.values(requiredPatientFields || {}).map((item) => item.field);
    const disabledFields = Object.values(disabledPatientFields || {}).map((item) => item.field);

    if (prescriptionKeys.length) {
        for (const key of prescriptionKeys) {
            patientPrescriptions[key]?.rxNumbers?.[index]?.rxFill?.map((fill, idx) => fillNumberIndex.push(idx));
        }
    }

    function handleReFillsAllowed(value) {
        if (value > 99) {
            handlePropChange(medicationId, index, 'refillsAuthorized', 99);
        } else {
            handlePropChange(medicationId, index, 'refillsAuthorized', value);
        }
    }

    const removeFillNumber = (rxIndex, fillIndex) => {
        handleRemoveFillNumber(medicationId, rxIndex, fillIndex);
    };

    const rxFillArray = patientPrescriptions?.[medicationId]?.rxNumbers?.[index]?.rxFill;

    const refillsAuthorized = R.path([index, 'refillsAuthorized'], prescription);

    const isAddFillDisabled = R.anyPass([
        () => disabledFields.includes('add_fill'),
        R.flip(R.lte)(rxFillArray.length - 1),
    ])(refillsAuthorized);

    const isRxNumberDisabled = useMemo(() => {
        if (isNew) {
            return false;
        }

        return initialPrescription.rxNumber || disabledFields.includes('rx_number');
    }, [disabledFields, initialPrescription.rxNumber, isNew]);

    const isRxStrengthDisabled = useMemo(() => {
        if (isNew) {
            return false;
        }

        return initialPrescription.strength || disabledFields.includes('rx_strength');
    }, [disabledFields, initialPrescription.strength, isNew]);

    const isRefillDisabled = useMemo(() => {
        if (isNew) {
            return false;
        }
        return initialPrescription.refillsAuthorized > 0 || disabledFields.includes('refills_allowed');
    }, [disabledFields, initialPrescription.refillsAuthorized, isNew]);

    const isRxNumberWrittenDateDisabled = useMemo(() => {
        if (isNew) {
            return false;
        }
        return !!initialPrescription.start || disabledFields.includes('rx_number_written_date');
    }, [disabledFields, initialPrescription.start, isNew]);

    const isRxNumberExpDateDisabled = useMemo(() => {
        if (isNew) {
            return false;
        }
        return !!initialPrescription.fillExpiration || disabledFields.includes('rx_number_expiration_date');
    }, [disabledFields, initialPrescription.fillExpiration, isNew]);
    return (
        <div className="prescription-container">
            <Row className="rxNumber-group">
                <Col className="col-sm-4 col-md-5th">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="rxNumber"
                            className={cx({
                                'invalid-insurance-field field-required':
                                    requiredFields.includes('rx_number') &&
                                    R.pathOr('', [index, 'rxNumber'], validateRxFillNumberErrors),
                                'field-required': requiredFields.includes('rx_number'),
                            })}
                        >
                            Rx Number
                        </ControlLabel>
                        <FormControl
                            id="rxNumber"
                            name="rxNumber"
                            type="text"
                            placeholder="Rx Number"
                            autoComplete="off"
                            className={cx({
                                'invalid-insurance-form':
                                    requiredFields.includes('rx_number') &&
                                    R.pathOr('', [index, 'rxNumber'], validateRxFillNumberErrors),
                            })}
                            onChange={(event) => {
                                handlePropChange(medicationId, index, event.target.id, event.target.value);
                                validateRxFillNumber(index, event.target.id, event.target.value);
                            }}
                            value={R.pathOr('', [index, 'rxNumber'], patientPrescriptions?.[medicationId]?.rxNumbers)}
                            disabled={isRxNumberDisabled}
                        />
                        {requiredFields.includes('rx_number') &&
                            (R.pathOr('', [index, 'rxNumber'], validateRxFillNumberErrors) ? (
                                <span className="error-message-insurance">
                                    {R.pathOr('', [index, 'rxNumber'], validateRxFillNumberErrors)}
                                </span>
                            ) : null)}
                    </FormGroup>
                </Col>

                <Col className="col-sm-4 col-md-5th">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="strength"
                            className={cx({
                                'invalid-insurance-field field-required':
                                    requiredFields.includes('rx_strength') &&
                                    R.pathOr('', [index, 'strength'], validateRxFillNumberErrors),
                                'field-required': requiredFields.includes('rx_strength'),
                            })}
                        >
                            {' '}
                            Rx Strength
                        </ControlLabel>
                        <FormControl
                            id="strength"
                            name="strength"
                            type="text"
                            placeholder="Rx Strength"
                            autoComplete="off"
                            className={cx({
                                'invalid-insurance-form':
                                    requiredFields.includes('rx_strength') &&
                                    R.pathOr('', [index, 'strength'], validateRxFillNumberErrors),
                            })}
                            onChange={(event) => {
                                handlePropChange(medicationId, index, event.target.id, event.target.value);
                                validateRxFillNumber(index, event.target.id, event.target.value);
                            }}
                            value={R.pathOr('', [index, 'strength'], patientPrescriptions?.[medicationId]?.rxNumbers)}
                            disabled={isRxStrengthDisabled}
                        />
                        {requiredFields.includes('rx_strength') &&
                        R.pathOr('', [index, 'strength'], validateRxFillNumberErrors) ? (
                            <span className="error-message-insurance">
                                {R.pathOr('', [index, 'strength'], validateRxFillNumberErrors)}
                            </span>
                        ) : null}
                    </FormGroup>
                </Col>

                <Col className="col-sm-4 col-md-5th">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="refillsAuthorized"
                            className={cx({
                                'invalid-insurance-field field-required':
                                    requiredFields.includes('refills_allowed') &&
                                    R.pathOr('', [index, 'refillsAuthorized'], validateRxFillNumberErrors),
                                'field-required': requiredFields.includes('refills_allowed'),
                            })}
                        >
                            Refills Allowed
                        </ControlLabel>
                        <FormControl
                            id="refillsAuthorized"
                            name="refillsAuthorized"
                            type="number"
                            min="0"
                            max="99"
                            placeholder="Refills Allowed"
                            autoComplete="off"
                            className={cx({
                                'invalid-insurance-form':
                                    requiredFields.includes('refills_allowed') &&
                                    R.pathOr('', [index, 'refillsAuthorized'], validateRxFillNumberErrors),
                            })}
                            onChange={(event) => {
                                handlePropChange(medicationId, index, event.target.id, +event.target.value);
                                handleReFillsAllowed(R.clamp(0, 99, +event.target.value));
                                validateRxFillNumber(index, event.target.id, event.target.value);
                            }}
                            value={R.pathOr(
                                '',
                                [index, 'refillsAuthorized'],
                                patientPrescriptions?.[medicationId]?.rxNumbers
                            )}
                            disabled={isRefillDisabled}
                        />
                        {requiredFields.includes('refills_allowed') &&
                        R.pathOr('', [index, 'refillsAuthorized'], validateRxFillNumberErrors) ? (
                            <span className="error-message-insurance">
                                {R.pathOr('', [index, 'refillsAuthorized'], validateRxFillNumberErrors)}
                            </span>
                        ) : null}
                    </FormGroup>
                </Col>

                <Col className="col-sm-4 col-md-5th">
                    <PatientPrescriberTypeAhead
                        id="physician"
                        name="physician"
                        type="text"
                        placeholder="Prescriber"
                        patientPrescriptions={patientPrescriptions}
                        medicationId={medicationId}
                        index={index}
                        handlePropChange={handlePropChange}
                        handleSearchPrescriber={handleSearchPrescriber}
                        searchPrescriber={searchPrescriber}
                        emitEventToAddPrescriber={emitEventToAddPrescriber}
                        handleSelectedPrescriber={handleSelectedPrescriber}
                        initialValue={patientPrescriptions}
                        validateRxFillNumber={validateRxFillNumber}
                        validateRxFillNumberErrors={validateRxFillNumberErrors}
                    />
                </Col>

                <Col className="col-sm-4 col-md-5th end-rx-number-container">
                    {R.pathOr('', [index, 'id'], prescription) && !prescription?.[index]?.summary ? (
                        <Button
                            className="btn-end-rx-number"
                            onClick={() => {
                                handleEndRxNumber(
                                    patientPrescriptions[medicationId].rxNumbers[index],
                                    index,
                                    medication
                                );
                            }}
                        >
                            End Rx Number
                        </Button>
                    ) : null}
                </Col>

                {patientPrescriptions?.[medicationId]?.rxNumbers?.[index]?.id === null ||
                patientPrescriptions?.[medicationId]?.rxNumbers?.[index]?.id === undefined ? (
                    <Col className="col-sm-3 col-md-5th">
                        <ControlLabel className="rxNumber-group-remove" onClick={() => removeRxNumber(index)}>
                            <i className="fas fa-fw fa-times" />
                        </ControlLabel>
                    </Col>
                ) : null}
            </Row>

            <Row className="rxNumber-group">
                <Col className="col-sm-3 col-md-6th">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="start"
                            className={cx({
                                'invalid-insurance-field field-required':
                                    requiredFields.includes('rx_number_written_date') &&
                                    R.pathOr('', [index, 'start'], validateRxFillNumberErrors),
                                'field-required': requiredFields.includes('rx_number_written_date'),
                            })}
                        >
                            Rx Number Written Date
                        </ControlLabel>
                        <DatePickerInput
                            name="start"
                            placeholder="YYYY/MM/DD"
                            dateFormat="yyyy/MM/dd"
                            className={cx({
                                'invalid-insurance-form':
                                    requiredFields.includes('rx_number_written_date') &&
                                    R.pathOr('', [index, 'start'], validateRxFillNumberErrors),
                            })}
                            value={
                                patientPrescriptions?.[medicationId]?.rxNumbers?.[index]?.start
                                    ? new Date(patientPrescriptions[medicationId].rxNumbers[index].start)
                                    : null
                            }
                            onChange={(date) => {
                                handlePropChange(medicationId, index, 'start', date);
                                validateRxFillNumber(index, 'start', date);
                            }}
                            onBlur={Function}
                            disabled={isRxNumberWrittenDateDisabled}
                        />
                        {requiredFields.includes('rx_number_written_date') &&
                        R.pathOr('', [index, 'start'], validateRxFillNumberErrors) ? (
                            <span className="error-message-insurance">
                                {R.pathOr('', [index, 'start'], validateRxFillNumberErrors)}
                            </span>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col className="col-sm-3 col-md-6th">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="fillExpiration"
                            className={cx({
                                'invalid-insurance-field field-required':
                                    requiredFields.includes('rx_number_expiration_date') &&
                                    R.pathOr('', [index, 'fillExpiration'], validateRxFillNumberErrors),
                                'field-required': requiredFields.includes('rx_number_expiration_date'),
                            })}
                        >
                            Rx Number Exp. Date
                        </ControlLabel>
                        <DatePickerInput
                            name="fillExpiration"
                            placeholder="YYYY/MM/DD"
                            dateFormat="yyyy/MM/dd"
                            className={cx({
                                'invalid-insurance-form':
                                    requiredFields.includes('rx_number_expiration_date') &&
                                    R.pathOr('', [index, 'fillExpiration'], validateRxFillNumberErrors),
                            })}
                            value={
                                patientPrescriptions?.[medicationId]?.rxNumbers?.[index]?.fillExpiration
                                    ? new Date(patientPrescriptions[medicationId].rxNumbers[index].fillExpiration)
                                    : null
                            }
                            onBlur={() => {}}
                            onChange={(date) => {
                                handlePropChange(medicationId, index, 'fillExpiration', date);
                                validateRxFillNumber(index, 'fillExpiration', date);
                            }}
                            disabled={isRxNumberExpDateDisabled}
                        />
                        {requiredFields.includes('rx_number_expiration_date') &&
                        R.pathOr('', [index, 'fillExpiration'], validateRxFillNumberErrors) ? (
                            <span className="error-message-insurance">
                                {R.pathOr('', [index, 'fillExpiration'], validateRxFillNumberErrors)}
                            </span>
                        ) : null}
                    </FormGroup>
                </Col>
            </Row>

            {prescription?.[index]?.summary ? (
                <Row className="rx-prescription-end">
                    <div className="global-info-message">
                        <Col className="col-sm-12 col-md-12">
                            <i className="fa fa-fw fa-info-circle" /> {prescription[index].summary}
                            <Button
                                className="btn btn-link"
                                onClick={() => {
                                    handleEndRxNumber(
                                        patientPrescriptions[medicationId].rxNumbers[index],
                                        index,
                                        medication
                                    );
                                }}
                            >
                                <i className="fa fa-fw fa-pencil" /> Edit
                            </Button>
                            <Button
                                className="btn btn-link"
                                onClick={() => {
                                    handleResetEndPrescription(index, medication);
                                }}
                            >
                                <i className="fa fa-fw fa-times" /> Cancel Prescription End
                            </Button>
                        </Col>
                    </div>
                </Row>
            ) : null}

            {rxFillArray.length > 0 && refillsAuthorized >= 0 ? (
                <div className="rxFill-container">
                    {rxFillArray.map((fill, fillIdx) => {
                        return (
                            <div key={`${fill.id || fill._internalUiId || `${index}-fillIdx`}`}>
                                <RxFill
                                    fillIndex={fillIdx}
                                    rxIndex={index}
                                    rxFill={rxFillArray}
                                    handleFillPropChange={handleFillPropChange}
                                    medicationId={medicationId}
                                    removeFillNumber={removeFillNumber}
                                    validateRxFillNumber={validateRxFillNumber}
                                    validateRxFillNumberErrors={validateRxFillNumberErrors}
                                />
                            </div>
                        );
                    })}
                </div>
            ) : null}

            <Button
                className="btn btn-link"
                onClick={() => onAddRxFill(medicationId, index)}
                disabled={isAddFillDisabled}
            >
                <i className="fa fa-fw fa-plus" />
                Add Fill
            </Button>
        </div>
    );
}

RxNumber.propTypes = propTypes;
RxNumber.defaultProps = defaultProps;

export default RxNumber;
