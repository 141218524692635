import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    message: PropTypes.node,
    children: PropTypes.node,
};
const defaultProps = {
    message: '',
    children: null,
};

function TherigyInfoMessage({ message, children }) {
    return (
        <div className="row">
            <div className="col-md-12">
                {children || (
                    <>
                        <i className="fa fa-fw fa-info-circle" />
                        <span>{message}</span>
                    </>
                )}
            </div>
        </div>
    );
}

TherigyInfoMessage.propTypes = propTypes;
TherigyInfoMessage.defaultProps = defaultProps;

export default TherigyInfoMessage;
