import { useMutation, useQueryClient } from 'react-query';
import ActivityMedicationService from 'App/services/ActivityMedicationService';
import * as R from 'ramda';

function useCreateMedicationAssociationMutation(patientId, activityId) {
    const queryClient = useQueryClient();

    return useMutation(
        (medicationAssociation) => {
            return ActivityMedicationService.createMedicationAssociation(patientId, activityId, medicationAssociation);
        },
        {
            onSuccess(m) {
                const existingMeds = queryClient.getQueryData(['medicationAssociations', patientId, activityId]);
                queryClient.setQueryData(
                    ['medicationAssociations', patientId, activityId],
                    R.assoc(m.id, m, existingMeds)
                );
            },
        }
    );
}

export default useCreateMedicationAssociationMutation;
