/**
 * Controller for rx notes modal.
 * @param medicationsService
 */
/** @ngInject */
function PrescriptionAddNoteController(medicationsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.cancelForm = cancelForm;
    ctrl.submitForm = submitForm;
    ctrl.note = null;
    ctrl.saveFailed = false;

    /**
     * Sets values in resolve to properties.
     * @param changes
     */
    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.patientId) {
                ctrl.patientId = angular.copy(ctrl.resolve.patientId);
            }
            if (ctrl.resolve.prescription) {
                ctrl.prescription = angular.copy(ctrl.resolve.prescription);
            }
        }
    }

    /**
     * Sends api request to save a note to the specified rx number
     */
    function submitForm() {
        ctrl.savingNote = true;
        return medicationsService
            .addRxNote(ctrl.patientId, ctrl.prescription.medication_id, ctrl.prescription.id, ctrl.note)
            .then(() => {
                ctrl.saveFailed = false;
                ctrl.close({
                    $value: {
                        saved: true,
                    },
                });
            })
            .catch(() => {
                ctrl.saveFailed = true;
            })
            .finally(() => {
                ctrl.savingNote = false;
            });
    }

    /**
     * Closes the add note modal.
     */
    function cancelForm() {
        ctrl.dismiss({ $value: 'cancel' });
    }
}

export default PrescriptionAddNoteController;
