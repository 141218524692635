/** @ngInject */
function PasswordManagerController(_, $scope, userService) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.closeAlert = closeAlert;
    ctrl.submit = submit;

    /**
     * @summary Initializes the component
     */
    function $onInit() {
        return userService.getCompanyPasswordRequirements().then((res) => {
            ctrl.passwordSettings = res;
        });
    }

    /**
     * @summary Handler for closing alerts boxes
     */
    function closeAlert() {
        ctrl.error = undefined;
        ctrl.success = undefined;
    }

    /**
     * @summary Submits password manager form
     * @param e - The current event
     * @returns {Promise} Resolves if successful, fails otherwise
     */
    function submit(e) {
        ctrl.loading = true;
        ctrl.success = undefined;
        ctrl.error = undefined;
        e.preventDefault();
        if (ctrl.passwordManagerForm.$invalid) {
            return;
        }
        const passwordSettings = _.cloneDeep(ctrl.passwordSettings);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return userService
            .setCompanyPasswordRequirements(
                passwordSettings.minLength,
                passwordSettings.maxLength,
                passwordSettings.minNumNumbers,
                passwordSettings.minNumUppercase,
                passwordSettings.minNumSymbols,
                passwordSettings.minNumRequirements
            )
            .then((res) => {
                ctrl.error = undefined;
                ctrl.success = res.data.message;
                return res;
            })
            .catch((err) => {
                ctrl.success = undefined;
                ctrl.error = err.data.message;
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }
}

export default PasswordManagerController;
