import React from 'react';
import SelectField from 'Lib/form/SelectField';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import useFetchPatientStausOptions from 'App/hooks/useFetchPatientStatusOptions';

const propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.shape({}),
};

const defaultProps = {
    onChange: () => {},
    value: null,
};
function PatientStatus({ onChange, value }) {
    const { isLoading, data } = useFetchPatientStausOptions();

    return (
        <SelectField
            fieldName="searchParams.patientStatus"
            options={data}
            label="Patient Status"
            getOptionValue={R.prop('ID')}
            getOptionLabel={R.prop('ValueName')}
            isClearable
            onChange={onChange}
            isLoading={isLoading}
            value={value}
        />
    );
}

PatientStatus.propTypes = propTypes;
PatientStatus.defaultProps = defaultProps;
export default PatientStatus;
