import React from 'react';
import UserService from 'App/services/UserService';
import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted';
import * as R from 'ramda';
import { getAlertCount, getAlertUsers } from '../../services/AlertService';
import isOnLoginPage from 'App/utils/isOnLoginPage';
import mountReactComponent from 'App/utils/mountReactComponent';
import LatestAppVersion from 'App/components/LatestAppVersion';
import AppContextForAngular from 'App/components/AppContextForAngular';
import HelpIcon from 'App/components/Header/icons/HelpIcon';

/** @ngInject */
function AppHeaderController(__env, $rootScope, $scope) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.$onDestroy = $onDestroy;
    ctrl.cdnURL = __env.cdnURL;
    ctrl.search = search;
    ctrl.newMessages = 0;
    ctrl.alertMessageCount = 0;
    ctrl.showAlertCenter = false;

    let timeout;

    let isPDXUser = false;

    async function getAlertCountTimer() {
        if (isOnLoginPage()) {
            return;
        }

        return getAlertCount().then(({ count }) => {
            ctrl.alertMessageCount = count;

            // then setup an interval
            if (!isOnLoginPage()) {
                timeout = setTimeout(getAlertCountTimer, 600_000);
            }
        });
    }

    function $onInit() {
        const user = UserService.get();

        const hasAlertCenter = isPermitted(PERMISSION_TYPES.ALERT_CENTER, user);
        isPDXUser = isPermitted(PERMISSION_TYPES.PDX_FIND_PATIENT, user);

        if (hasAlertCenter) {
            getAlertUsers()
                .then(
                    R.compose(
                        (alertuser) => {
                            if (alertuser) {
                                ctrl.showAlertCenter = true;
                            }
                            return alertuser;
                        },
                        R.find(R.propEq(user.id, 'userId')),
                        R.values
                    )
                )
                .then((alertUser) => {
                    if (alertUser) {
                        return getAlertCountTimer();
                    }
                });
        }
        mountReactComponent('#icon-bar-help', <HelpIcon />);
        mountReactComponent(
            '#latest-app-version-banner',
            <AppContextForAngular>
                <LatestAppVersion />
            </AppContextForAngular>
        );
    }

    function $onDestroy() {
        clearTimeout(timeout);
    }

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            if (ctrl.user.CdcType === 'user:Support:provision') {
                ctrl.user.email = getTruncatedEmail(ctrl.user.email);
            }
        }
    }

    function getTruncatedEmail(email) {
        return email?.replace(/\+.?[^@]*/i, '');
    }

    /**
     * @summary Changes the message count the app-header
     */
    const setMessageCount = $rootScope.$on('messageCount', (event, messageCount) => {
        ctrl.newMessages = messageCount;
    });

    // Clean up the '$rootScope' listener when this controller gets destroyed
    $scope.$on('$destroy', setMessageCount);

    /**
     * @summary Redirects to the search page with the search criteria entered
     */
    function search() {
        if (ctrl.searchName) {
            ctrl.searching = true;
            let firstName;
            let lastName;
            let names;

            ctrl.searchName = ctrl.searchName.trim();
            if (ctrl.searchName.indexOf(',') !== -1) {
                names = ctrl.searchName.split(',');
                firstName = names[1];
                lastName = names[0];
            } else if (ctrl.searchName.indexOf(' ') !== -1) {
                names = ctrl.searchName.split(' ');
                firstName = names[0];
                lastName = names.slice(1).join(' '); // to handle the name in <first last last> format
            } else {
                firstName = '';
                lastName = ctrl.searchName;
            }

            ctrl.searchName = undefined;
            const findPageRoute = isPDXUser ? '/#!/pdxFindPatient' : '/#!/findPatient';
            window.location = `${findPageRoute}?lastName=${lastName.trim()}&firstName=${firstName.trim()}`;
            ctrl.searching = false;
        }
    }
}

export default AppHeaderController;
