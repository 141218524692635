import { useQuery } from 'react-query';

import { getAssessment } from 'App/services/AMSService';
import { errorHandler } from 'App/utils';

function useFetchAssessment(assessmentId) {
    return useQuery(['assessment', assessmentId], () => getAssessment(assessmentId), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchAssessment;
