import * as R from 'ramda';

const useActivityNoteStatusModal = (row) => {
    const activityId = R.path(['original', 'activityId'], row);
    const typeLabel = R.path(['original', 'type'], row);
    const name = R.path(['original', 'activityName'], row);
    const firstName = R.path(['original', 'patientFirstName'], row);
    const lastName = R.path(['original', 'patientLastName'], row);

    return {
        show: true,
        activity: {
            id: activityId,
            typeLabel,
            name,
        },
        patient: {
            firstName,
            lastName,
        },
    };
};

export default useActivityNoteStatusModal;
