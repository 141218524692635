import React from 'react';
import PropTypes from 'prop-types';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import Main from 'App/components/styles/Main/Main';
import { Helmet } from 'react-helmet';
import { catchPhrase } from 'App/components/config/config';
import PageTitle from 'App/components/styles/PageTitle/PageTitle';
import PageWrapper from 'App/components/styles/PageWrapper/PageWrapper';
import { NotFound } from 'App/components/errors';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import * as R from 'ramda';

const propTypes = {
    permissionType: PropTypes.oneOf(R.keys(PERMISSION_TYPES)).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    title: PropTypes.string.isRequired,
    showPageTitle: PropTypes.bool,
};

const defaultProps = {
    showPageTitle: true,
};

function Page({ permissionType, children, title, showPageTitle }) {
    const hasPermission = useIsPermitted(permissionType);

    return (
        <Main>
            <Helmet>
                <title>
                    {hasPermission ? title : 'Not Found'} - {catchPhrase}
                </title>
            </Helmet>
            {hasPermission && showPageTitle && <PageTitle>{title}</PageTitle>}

            <PageWrapper>{hasPermission ? children : <NotFound />}</PageWrapper>
        </Main>
    );
}

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
