import styled from '@emotion/styled';
import DeleteModal from 'App/components/DeleteModal';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import { addTherapyDiagnosis, deleteTherapyDiagnosis, getTherapyDiagnosis } from 'App/services/AdminService';
import { search } from 'App/services/ICD10Service';
import { errorHandler, hasLength } from 'App/utils';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import DiagnosesTable from '../DiagnosesTable';
import { toast } from 'react-toastify';

const CenterWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
`;

const propTypes = {
    therapyId: PropTypes.string.isRequired,
    setIsSaving: PropTypes.func,
};

const defaultProps = {
    setIsSaving: Function,
};

/**
 * Creates a list of predicate functions based on the current diagnosis codes selected. These are to be used
 * by R.reject in order to filter out currently selected diagnosis codes from the server results.
 * @see getDefaultOptions
 * @param currentDiagnoses
 * @returns {(({code}) => boolean)[]}
 */
function createCodePredicates(currentDiagnoses) {
    if (hasLength(currentDiagnoses)) {
        return R.map(
            (currentDiag) => (serverDiag) => {
                return R.propEq(currentDiag.code, 'code', serverDiag);
            },
            currentDiagnoses
        );
    }
    return [R.F];
}

function getDefaultOptions(initialList, currentDiagnoses) {
    try {
        return R.compose(
            R.sortBy(R.prop('code')),
            R.reject(R.anyPass(createCodePredicates(currentDiagnoses)))
        )(initialList);
    } catch (error) {
        console.error(error);
        return [];
    }
}

function DiagnosisList({ therapyId, setIsSaving }) {
    //#region State
    const [currentDiagnoses, setCurrentDiagnoses] = useState([]);
    const [deleteDiagnosisModalData, setDeleteDiagnosisModalData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [initialDiagnosesOptions, setInitialDiagnosesOptions] = useState([]);

    //#endregion

    //#region Actions

    const createDiagnosis = (diag, idx) => {
        setIsSaving(true);
        return addTherapyDiagnosis(therapyId, diag.code)
            .then((newDiag) => {
                setCurrentDiagnoses(R.set(R.lensIndex(idx), newDiag));
                toast.success('Diagnosis added successfully');
            })
            .finally(() => setIsSaving(false));
    };

    const handleSaveDiagnosis = (diag, idx, option, diagnoses) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-use-before-define
        return diag.isNew ? createDiagnosis(option, idx) : changeDiagnosis(option, idx, diagnoses);
    };

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    const removeDiagnosis = (diag, idx) => {
        if (diag.isNew) {
            setCurrentDiagnoses(R.remove(idx, 1));
            return Promise.resolve(diag);
        }
        setDeleteDiagnosisModalData({
            message: `Are you sure you want to delete this diagnosis?`,
            fn: () => {
                setDeleteDiagnosisModalData(null);
                setIsSaving(true);
                return deleteTherapyDiagnosis(therapyId, diag.id)
                    .then(() => {
                        setCurrentDiagnoses(R.remove(idx, 1));
                        toast.success('Diagnosis removed successfully');
                    })
                    .finally(() => setIsSaving(false));
            },
        });
    };

    const changeDiagnosis = (diag, idx, diagnoses) => {
        const currentDiag = R.prop(idx, diagnoses);

        const currentDiagName = `${currentDiag.code}: ${currentDiag.description}`;
        const diagName = `${diag.code}: ${diag.shortDescription}`;

        setDeleteDiagnosisModalData({
            // eslint-disable-next-line max-len
            message: `Are you sure you want to remove this diagnosis (${currentDiagName}) and add the new one (${diagName})?`,
            fn: () => {
                setDeleteDiagnosisModalData(null);
                setIsSaving(true);
                return deleteTherapyDiagnosis(therapyId, currentDiag.id)
                    .then(() => addTherapyDiagnosis(therapyId, diag.code))
                    .then((newDiag) => {
                        const propEqCode = R.propEq(newDiag.code, 'code');

                        setCurrentDiagnoses(R.set(R.lensIndex(idx), newDiag));

                        setInitialDiagnosesOptions(R.compose(R.reject(propEqCode), R.append(currentDiag)));
                        toast.success('Diagnosis updated successfully');
                    })
                    .catch((error) => {
                        errorHandler(error);
                    })
                    .finally(() => setIsSaving(false));
            },
        });
    };

    const loadOptions = (text) => {
        return search(text)
            .then(R.tryCatch(R.values, R.always([])))
            .then(R.reject(R.anyPass(createCodePredicates(currentDiagnoses))))
            .catch(errorHandler);
    };
    //#endregion

    //#region Side Effects
    useEffect(() => {
        if (therapyId) {
            getTherapyDiagnosis(therapyId)
                .then((diagnoses) => {
                    setIsLoading(false);
                    setCurrentDiagnoses(R.values(diagnoses));
                })
                .catch(errorHandler)
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [therapyId]);

    useEffect(() => {
        search('a').then((codes) => {
            setInitialDiagnosesOptions(R.values(codes));
        });
    }, []);
    //#endregion

    return (
        <>
            <Row>
                <Col md={12}>
                    {isLoading ? (
                        <CenterWrapper>
                            <TherigyLoadingSpinner />
                        </CenterWrapper>
                    ) : (
                        <DiagnosesTable
                            data={currentDiagnoses}
                            isLoading={isLoading}
                            onDelete={removeDiagnosis}
                            onSave={handleSaveDiagnosis}
                            updateDiagnosesState={setCurrentDiagnoses}
                            loadOptions={loadOptions}
                            defaultOptions={getDefaultOptions(initialDiagnosesOptions, currentDiagnoses)}
                        />
                    )}
                </Col>
            </Row>
            {deleteDiagnosisModalData ? (
                <DeleteModal
                    onHide={() => setDeleteDiagnosisModalData(null)}
                    show
                    title="Delete Diagnosis"
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/jsx-handler-names
                    onDelete={deleteDiagnosisModalData.fn}
                    message={deleteDiagnosisModalData.message}
                />
            ) : null}
        </>
    );
}

DiagnosisList.propTypes = propTypes;
DiagnosisList.defaultProps = defaultProps;

export default DiagnosisList;
