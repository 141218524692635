import angular from 'angular';

import EditPatientController from './edit-patient.controller';
import EditPatientConfig from './edit-patient.config';

import AssignMedicationTherapyController from './assign-therapy/assign-therapy.controller';
import AssignMedicationTherapyModalController from './assign-therapy/assign-therapy-modal.controller';
import EndMedicationController from './end-medication/end-medication.component';
import AddTherapyModalController from '../therapy/add-therapy-modal/add-therapy-modal.controller';

export default angular
    .module('components.editPatient', ['ngMessages', 'ui.router', 'ui.select'])
    .component('editPatient', {
        bindings: {
            advancedReferral: '<',
            disabledFields: '<',
            integration: '<',
            user: '<',
        },
        controller: EditPatientController,
        template: require('./edit-patient.html'),
    })
    .config(EditPatientConfig)
    .component('assignMedicationTherapy', {
        bindings: {
            patient: '<',
            onModalDone: '&',
        },
        controller: AssignMedicationTherapyController,
        template: '<a data-testid="unassigned-med-alert" href ng-click="$ctrl.open()">Assign Therapeutic Category</a>',
    })
    .component('patientPrescription', {
        bindings: {
            patientPrescriptions: '=',
        },
        controller: AddTherapyModalController,
        template: require('../therapy/add-therapy-modal/add-therapy-modal.controller'),
    })
    .component('assignMedicationTherapyModal', {
        bindings: {
            modalInstance: '<',
            resolve: '<',
        },
        controller: AssignMedicationTherapyModalController,
        template: require('./assign-therapy/assign-therapy-modal.html'),
    })
    .component('endMedication', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: EndMedicationController,
        template: require('./end-medication/end-medication.html'),
    }).name;
