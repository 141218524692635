function OptInTherapyController() {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.submit = submit;

    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.therapy) {
                ctrl.therapy = angular.copy(ctrl.resolve.therapy);
                ctrl.therapy.status = {};
            }
        }
    }

    function $onInit() {
        ctrl.today = new Date();
        ctrl.today.setHours(0, 0, 0, 0);
    }

    function submit() {
        ctrl.saving = true;
        ctrl.therapy.status.opt_in = true;
        ctrl.close({
            $value: ctrl.therapy,
        });
    }
}

export default OptInTherapyController;
