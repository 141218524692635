import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import TreatmentHistoryModal from './TreatmentHistoryModal';
import PropTypes from 'prop-types';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';

const propTypes = {
    therapyId: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

const defaultProps = {};

function TreatmentHistory({ therapyId, onConfirm }) {
    const [showModal, setShowModal] = useState(false);
    const isTreatmentHistoryEnabled = useIsPermitted(PERMISSION_TYPES.ENABLE_TREATMENT_HISTORY);

    if (isTreatmentHistoryEnabled) {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    type="button"
                    data-id="add-treatment-history"
                    bsStyle="link"
                    bsSize="sm"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus /> Treatment History
                </Button>

                {showModal ? (
                    <TreatmentHistoryModal
                        onHide={() => setShowModal(false)}
                        onConfirm={onConfirm}
                        therapyId={therapyId}
                    />
                ) : null}
            </div>
        );
    }
    return null;
}

TreatmentHistory.propTypes = propTypes;
TreatmentHistory.defaultProps = defaultProps;

export default TreatmentHistory;
