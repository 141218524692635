import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import * as R from 'ramda';

const propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

const defaultProps = {
    onClick: () => {},
};

function ResetButton({ children, onClick }) {
    const { handleReset } = useFormikContext();
    return (
        <Button type="button" onClick={R.compose(handleReset, onClick)}>
            {children}
        </Button>
    );
}

ResetButton.propTypes = propTypes;
ResetButton.defaultProps = defaultProps;

export default ResetButton;
