import Tooltip from 'react-bootstrap/lib/Tooltip';
import styled from '@emotion/styled';

const StyledIcon = styled.i`
    color: var(--brandDanger);
    cursor: pointer;

    &.active {
        color: shade(var(--brandDanger), 20%);
    }

    &.inactive {
        cursor: initial;
    }
`;

const StyledTooltip = styled(Tooltip)`
    .tooltip-inner {
        color: #fff;
        background-color: var(--manatee);
        text-align: left;
        max-width: 100%;
    }

    .tooltip-inner ul {
        list-style-type: none;
        margin-bottom: 0;
        padding: 4px;
    }

    .tooltip-arrow {
        display: none;
    }
`;

export {
    StyledIcon,
    StyledTooltip
};
