import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { Col, Modal, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap/lib/InputGroup';
import { toast } from 'react-toastify';
import { updateActivityDueDate } from 'App/services/PatientActivityService';
import { updateActivityReferrals } from 'App/services/RMSService';
import { errorHandler } from 'App/utils';
import * as yup from 'yup';
import { DatePickerField } from 'Lib/form/DatePickerField';
import SubmitButton from 'Lib/form/SubmitButton';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';

const validationSchema = yup.object().shape({
    dateDue: yup
        .date()
        .max('9999/12/31', 'Please enter an activity due date')
        .required('Please enter an activity due date')
        .nullable(),
});

const propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    activity: PropTypes.shape({
        id: PropTypes.string,
        dateDue: PropTypes.string,
        typeLabel: PropTypes.string,
        patientId: PropTypes.string,
    }),
};

const defaultProps = {
    show: false,
    onHide: () => {},
    activity: {
        id: '',
        dateDue: null,
        typeLabel: '',
        patientId: '',
    },
};

const EditActivityDueDateModal = ({ show, onHide, activity }) => {
    const initialValues = {
        activityId: activity?.id,
        dateDue: activity?.dateDue ? new Date(activity?.dateDue) : null,
        error: null,
    };

    return (
        <Modal show={show} onHide={() => onHide(false)} backdrop="static" className="patient-edit-due-date">
            <Modal.Header>
                <Modal.Title>Edit Due Date</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    let promise;
                    const dateDue = dayjs(
                        Date.UTC(
                            values?.dateDue.getFullYear(),
                            values?.dateDue.getMonth(),
                            values?.dateDue.getDate(),
                            23,
                            59,
                            59
                        )
                    ).toISOString();
                    if (activity.typeLabel === 'Referral' || activity.typeLabel === 'REF') {
                        promise = updateActivityReferrals(activity, dateDue);
                    } else {
                        const params = {
                            patientId: activity.patientId,
                            dateDue,
                            activityId: values.activityId,
                        };
                        promise = updateActivityDueDate(params);
                    }
                    promise
                        .then(() => {
                            toast.success('Activity due date updated successfully');
                        })
                        .catch(errorHandler)
                        .finally(() => {
                            setSubmitting(false);
                            onHide(true);
                        });
                }}
            >
                {({ isSubmitting, handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Modal.Body>
                                <Row>
                                    <Col md={12}>
                                        <DatePickerField
                                            fieldName="dateDue"
                                            label="Activity Due Date"
                                            placeholder="YYYY/MM/DD"
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Row>
                                    <Col md={12}>
                                        <FormButtonGroup>
                                            <Button
                                                disabled={isSubmitting}
                                                bsClass="btn btn-default"
                                                onClick={() => onHide(false)}
                                            >
                                                Cancel
                                            </Button>
                                            <SubmitButton className="save-due-date" id="due-date-modal-submit">
                                                Save
                                            </SubmitButton>
                                        </FormButtonGroup>
                                    </Col>
                                </Row>
                            </Modal.Footer>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

EditActivityDueDateModal.propTypes = propTypes;
EditActivityDueDateModal.defaultProps = defaultProps;

export default EditActivityDueDateModal;
