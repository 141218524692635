import PropTypes from 'prop-types';
import useFetchBranchesQuery from 'App/hooks/useFetchBranchesQuery';
import useFetchCustomMenusQuery from 'App/hooks/useFetchCustomMenusQuery';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultProps = {};

function LoadBaseData({ children }) {
    useFetchBranchesQuery();
    useFetchCustomMenusQuery();

    return children;
}

LoadBaseData.propTypes = propTypes;
LoadBaseData.defaultProps = defaultProps;

export default LoadBaseData;
