import { Col, Row } from 'react-bootstrap';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { TextField } from 'Lib/form/TextField';

const propTypes = {
    disable: PropTypes.bool.isRequired,
};

const PaddedRow = styled(Row)`
    padding: 0.5% 0;
`;

const SsoConfigurationForm = (props) => {
    const { disable } = props;

    return (
        <Form>
            <PaddedRow>
                <Col md={2}>
                    <strong>Certificate</strong>
                </Col>
                <Col md={6}>
                    <TextField
                        data-testid="cert"
                        id="cert"
                        fieldName="cert"
                        disabled={disable}
                        placeholder="Certificate"
                    />
                </Col>
            </PaddedRow>
            <PaddedRow>
                <Col md={2}>
                    <strong>Entry Point</strong>
                </Col>
                <Col md={6}>
                    <TextField
                        data-testid="entryPoint"
                        id="entryPoint"
                        disabled={disable}
                        fieldName="entryPoint"
                        placeholder="Entry Point"
                    />
                </Col>
            </PaddedRow>
            <PaddedRow>
                <Col md={2}>
                    <strong>Logout Url (Optional)</strong>
                </Col>
                <Col md={6}>
                    <TextField
                        data-testid="logoutUrl"
                        id="logoutUrl"
                        disabled={disable}
                        fieldName="logoutUrl"
                        placeholder="Logout Url"
                    />
                </Col>
            </PaddedRow>
            <PaddedRow>
                <Col md={2}>
                    <strong>Identifier Format (Optional)</strong>
                </Col>
                <Col md={6}>
                    <TextField
                        data-testid="identifierFormat"
                        id="identifierFormat"
                        disabled={disable}
                        fieldName="identifierFormat"
                        placeholder="Identifier Format"
                    />
                </Col>
            </PaddedRow>
            <PaddedRow>
                <Col md={2}>
                    <strong>Privilege Param (Optional)</strong>
                </Col>
                <Col md={6}>
                    <TextField
                        data-testid="privilegeParam"
                        id="privilegeParam"
                        disabled={disable}
                        fieldName="privilegeParam"
                        placeholder="Privilege Param"
                    />
                </Col>
            </PaddedRow>
        </Form>
    );
};

SsoConfigurationForm.propTypes = propTypes;

export default SsoConfigurationForm;
