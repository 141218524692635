import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';

function useFetchPatientReferral(patientId) {
    return useQuery(['patient', patientId, 'referral'], () => PESService.getPatientReferral(patientId), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchPatientReferral;
