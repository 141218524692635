import graphClient from '../common/graphClient';
import { gql } from 'graphql-request';

/*
GraphQl request for update patient activity due date
 */
function updateActivityDueDate(params) {
    return graphClient.request(
        gql`
            mutation UpdateActivityDueDate($patientId: ID!, $activityId: ID!, $dateDue: String!) {
                updateActivityDueDate(patientId: $patientId, activityId: $activityId, dateDue: $dateDue) {
                    patientId
                    brand
                    divisionId
                    companyId
                    status
                    dateDue
                }
            }
        `,
        params
    );
}
/*
GraphQl request to add patient activity
 */
function createActivity(params) {
    return graphClient.request(
        gql`
            mutation addActivities($patientId: ID!, $pollId: ID!, $additionalGroupIds: [ID]) {
                addActivities(patientId: $patientId, pollId: $pollId, additionalGroupIds: $additionalGroupIds) {
                    patientId
                    id
                    pollId
                }
            }
        `,
        params
    );
}

export { updateActivityDueDate, createActivity };
