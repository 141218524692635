import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as R from 'ramda';
import ReactSelect from 'react-select/async';
import FieldLabel from 'Lib/form/FieldLabel';
import FormButtonGroup from '../styles/FormButtonGroup';
import { Button } from 'react-bootstrap';
import SubmitButton from 'Lib/form/SubmitButton';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    show: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    value: PropTypes.object,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    index: PropTypes.number,
    title: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    diags: PropTypes.array,
    modalState: PropTypes.func.isRequired,
    updateDiagnosesState: PropTypes.func.isRequired,
    loadOptions: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    defaultOptions: PropTypes.array,
};

const defaultProps = {
    show: false,
};

function DiagnosisModal({
    onHide,
    show,
    onSave,
    value,
    index,
    title,
    diags,
    modalState,
    updateDiagnosesState,
    loadOptions,
    defaultOptions,
}) {
    const saveDiagnosis = async (values) => {
        const { diagnosis } = values;

        if (value.isNew) {
            updateDiagnosesState([...diags, diagnosis]);
        }

        await onSave(value, index ?? diags.length, diagnosis, diags);
        modalState(null);
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{ diagnosis: value }}
                validationSchema={Yup.object().shape({
                    diagnosis: Yup.object().required('Please select a diagnosis.'),
                })}
                onSubmit={saveDiagnosis}
            >
                {({ handleSubmit, setFieldValue, setFieldTouched, errors, values }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Modal.Body>
                            <FieldLabel fieldName="diagnosis" label="Diagnosis" required hasError={!!errors.diagnosis}>
                                <ReactSelect
                                    cacheOptions
                                    defaultOptions={defaultOptions}
                                    loadOptions={loadOptions}
                                    className="form-field"
                                    placeholder="Diagnosis"
                                    value={values.diagnosis.isNew ? null : values.diagnosis}
                                    getOptionLabel={R.converge(
                                        (...args) => R.join('', args),
                                        [R.prop('code'), R.always(': '), R.prop('longDescription')]
                                    )}
                                    getOptionValue={R.prop('id')}
                                    onChange={(opt) => {
                                        setFieldTouched('diagnosis', true);
                                        setFieldValue('diagnosis', opt);
                                    }}
                                />
                                {errors.diagnosis && <div className="error-message">{errors.diagnosis}</div>}
                            </FieldLabel>
                        </Modal.Body>

                        <Modal.Footer>
                            <FormButtonGroup>
                                <Button bsStyle="default" onClick={onHide} type="button">
                                    Cancel
                                </Button>
                                <SubmitButton>Save</SubmitButton>
                            </FormButtonGroup>
                        </Modal.Footer>
                    </form>
                )}
            </Formik>
        </Modal>
    );
}

DiagnosisModal.propTypes = propTypes;
DiagnosisModal.defaultProps = defaultProps;

export default DiagnosisModal;
