import localize from 'App/utils/localize';
import dayjs from 'dayjs';

/** @ngInject */
function DateService(moment) {
    return {
        addTimeDesignator,
        create,
        dateTimeFormat,
        localize,
        timeZoneAdjust,
        utc,
        truncTime,
        toLocalISODate,
        getCurrentTimestamp,
    };

    function addTimeDesignator(date) {
        if (typeof date === 'string') {
            return date.replace(' ', 'T');
        }
        return null;
    }

    /**
     * Create Date by stripping out the time from the string
     * That works in all browsers.
     * @param date
     * @param dateOnly - Use for datepicker dates
     * @returns {Date}
     * @deprecated
     */
    function create(date, dateOnly = false) {
        if (typeof date === 'string' && date.length >= 10 && date !== '0000-00-00 00:00:00') {
            if (dateOnly) {
                return new Date(moment.utc(date).startOf('day').toISOString());
            }
            return new Date(moment.utc(date).toISOString());
        }
        return null;
    }

    function dateTimeFormat(date) {
        return moment(date).format('YYYY/MM/DD h:mma');
    }

    /**
     * Returns a date that is in the proper timezone.
     *
     * @param {Date} date - The date.
     * @param {integer} offset - The timezone offset.
     * @param {boolean} dst - A flag to determine if the daylight savings setting is set.
     *
     * @return {Date} The timezone adjusted date.
     */
    function timeZoneAdjust(date, offset, dst) {
        const result = moment(date);

        // Update the offset if its day light savings
        if (dst && result.isDST()) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-plusplus, no-param-reassign
            offset++;
        }

        // Apply the offset
        result.add(offset, 'hours');

        // Return the formatted date
        return result.format('YYYY-MM-DD h:mm a');
    }

    /**
     * Returns just the date value as a string
     * @param {Date | String} date
     * @returns {String}
     */
    function truncTime(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    function utc(date) {
        if (typeof date === 'string' && date.length >= 10 && date !== '0000-00-00 00:00:00') {
            return new Date(moment.utc(date));
        }
        return null;
    }

    function toLocalISODate(date) {
        let dateISO = dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ');
        dateISO = dateISO.substring(0, dateISO.indexOf('T') + 1).concat(getCurrentTimestamp()); //replace any hh:mm:ss here (could be inaccurate) with fresh hh:mm:ss
        return dateISO;
    }

    function getCurrentTimestamp() {
        return dayjs.utc().format('HH:mm:ss'); //pull current HH:mm:ss here
    }
}

export default DateService;
