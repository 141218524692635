import { useQuery } from 'react-query';
import CompanyService from 'App/services/CompanyService';
import { errorHandler } from 'App/utils';

function useFetchTherapyCategoriesQuery() {
    return useQuery(['categories'], CompanyService.getTherapyCategories, {
        /**
         * Todo: Remove the errorHandler when the getTherapyCategories is moved to use the createAxios implementaiton
         */
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchTherapyCategoriesQuery;
