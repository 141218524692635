import React from 'react';
import * as R from 'ramda';
import { forbidden, isBoom } from '@hapi/boom';
import mountReactComponent from 'App/utils/mountReactComponent';
import Login from 'App/features/Login';
import { configureMatomo, configureNewRelic, configureWalkme } from 'App/services/TrackingService';

/**
 * @ngdoc directive
 * @name login
 * @module components.auth
 *
 * @description
 *
 * This is the login component responsible for handling logging in.
 **/
/** @ngInject */
function LoginController(
    _,
    $http,
    $httpParamSerializerJQLike,
    $rootScope,
    $state,
    $timeout,
    $uibModal,
    __env,
    _paq,
    userService,
    authService,
    jwtHelper,
    sessionStorageService
) {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.submit = submit;
    ctrl.passwordExpired = false;
    ctrl.emailRegex = __env.emailRegex;
    let resetDefaultBranch;

    function mountLogin() {
        mountReactComponent('#login-form', <Login />);
    }

    /**
     * @summary Initializes the component
     */
    function $onInit() {
        mountLogin();

        if (document.cookie.indexOf('redirect_url') !== -1) {
            const target =
                decodeURIComponent(document.cookie)
                    .replace(/&amp;/g, '&')
                    .split(';')
                    .map((entry) => {
                        return entry.trim();
                    })
                    .filter((entry) => {
                        return entry.split('=')[0] === 'redirect_url';
                    })[0]
                    .split('redirect_url=')[1] || '';
            authService.setTargetPage(target);
        }

        ctrl.formData = {
            next: '',
            login_submit: 'Login',
        };
        ctrl.passwordExpired = false;
        ctrl.passwordReset = $state.params.passwordReset;
        ctrl.sessionExpired = $state.params.sessionExpired;
    }

    /**
     * @summary Handles the login form submit
     * @description
     * Responsible for logging in the user.
     * @returns {Promise} Resolves if successful; rejects otherwise
     */

    function submit() {
        ctrl.passwordReset = false;
        ctrl.sessionExpired = false;
        if (ctrl.login.$invalid) {
            return false;
        }
        ctrl.logout = undefined;
        ctrl.loading = true;

        return authService
            .login(ctrl.formData.email, ctrl.formData.password)
            .then((res) => {
                const { data } = res;

                if (data.ApiUser) {
                    throw forbidden('Access denied: API-only users cannot access TherigySTM.');
                }

                /**
                 * the token is required to be in the rootscope
                 */
                // eslint-disable-next-line no-param-reassign
                $rootScope.token = data.Token;
                window.localStorage.setItem('userId', data.UserID);
                window.localStorage.setItem('branchId', data.BranchID);
                window.localStorage.setItem('companyId', data.CompanyID);
                window.localStorage.setItem('sessionId', data.Token);
                window.localStorage.setItem('userAge', data.UserAge);
                ctrl.passwordExpired = data.PasswordExpired;
                resetDefaultBranch = data.DefaultBranch === false;

                authService.set($rootScope.token);

                let token = sessionStorageService.getJwt();

                if (token === null) {
                    return;
                }

                const targetPage = authService.getTargetPage();

                token = jwtHelper.decodeToken(token);

                configureNewRelic(token.data);

                configureMatomo(token.data);

                configureWalkme(token.data);

                if (ctrl.passwordExpired === true) {
                    $state.go('app.myAccount');
                } else if (resetDefaultBranch === true) {
                    resetDefaultBranch = undefined;
                    $state.go('app.accessView', { resetDefaultBranch: true });
                } else if (targetPage) {
                    authService.clearTargetPage();
                    window.location = targetPage;
                } else {
                    $state.go('app.workQueue', { dateRange: 'this_week' });
                    /* Reload the page to refresh user ID in the WalkMe data after logging in
                    This is necessary for capturing UUID as per the WalkMe documentation https://support.walkme.com/knowledge-base/snippet/ */
                    const currentUrl = window.location.href;
                    window.location.replace(currentUrl);
                }
            })
            .then(() => {
                return userService.get();
            })
            .then((data) => {
                if (data.expired_branches && data.expired_branches.length >= 1 && data.default_branch === false) {
                    licenseExpiredModal();
                }
            })
            .catch((err) => {
                if (err.data && err.data.message) {
                    ctrl.error = err.data.message;
                    if (err.data.statusCode === 402) {
                        authService.logout();
                    }
                }
                if (err.status === 401) {
                    ctrl.error =
                        'The email or password you have entered is invalid. Your account will be locked after 3 failed attempts.';
                } else if (isBoom(err)) {
                    ctrl.error = err.output.payload.message;
                } else {
                    ctrl.error = R.path(['data', 'message'], err);
                }
                ctrl.loading = false;
                return err;
            });
    }

    function licenseExpiredModal() {
        $uibModal.open({
            component: 'expiredLicenseModal',
            size: 'md',
        });
    }
}

export default LoginController;
