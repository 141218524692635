import angular from 'angular';
import { StripTagsFilter, UnescapeHtml } from './html-to-plain-text.filter';
import truncateFilter from './truncateFilter';

/**
 *
 * @ngdoc module
 * @name filters
 *
 * @description
 *
 * This is the filters module. It includes all of our reusable filters.
 *
 **/
export default angular
    .module('filters', [])
    .filter('truncate', truncateFilter)
    .filter('stripTags', StripTagsFilter)
    .filter('unescapeHtml', UnescapeHtml).name;
