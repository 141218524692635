/** @ngInject */
function ReferralManagerController(_, $state, $uibModal, dateService, NgTableParams, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.createReferral = createReferral;

    function _filterReferrals(data, filterValues) {
        const filteredData = data.filter((item) => {
            return (
                (filterValues.status ? filterValues.status[item.status] : true) &&
                (filterValues.status_reason
                    ? item.status_reason.toLowerCase().indexOf(filterValues.status_reason.toLowerCase()) !== -1
                    : true) &&
                (filterValues.number
                    ? item.number.toLowerCase().indexOf(filterValues.number.toLowerCase()) !== -1
                    : true) &&
                (filterValues.medicationList
                    ? item.medicationList.toLowerCase().indexOf(filterValues.medicationList.toLowerCase()) !== -1
                    : true)
            );
        });

        const counts = filteredData.length > ctrl.tableParams.count() ? [5, 10, 25] : [];
        ctrl.tableParams.settings({ counts });

        return filteredData;
    }

    function _formatReferrals(referrals) {
        return _.forEach(referrals, (referral) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            referral.referralUpdatedOnValue = dateService.localize(referral.updated_on, 'YYYY/MM/DD hh:mm A');
        });
    }

    function $onChanges(changes) {
        if (changes.referrals && ctrl.referrals) {
            ctrl.referrals = angular.copy(ctrl.referrals);
            ctrl.referrals = _formatReferrals(ctrl.referrals);

            ctrl.tableParams = new NgTableParams(
                {
                    count: 25,
                    filter: {
                        status: { Pending: true },
                    },
                    sorting: { created_on: 'desc' },
                },
                {
                    counts: [10, 25, 50],
                    dataset: ctrl.referrals,
                    filterOptions: { filterFn: _filterReferrals },
                }
            );
        }

        if (changes.rxs && ctrl.rxs) {
            ctrl.rxs = angular.copy(ctrl.rxs);
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function $onInit() {
        ctrl.patientId = $state.params.patientId;
        ctrl.referralStatuses = ['Pending', 'No Go', 'Hold', 'Complete'];
    }

    function createReferral() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'patientProfileAddReferralModal',
            keyboard: false,
            resolve: {
                modalData() {
                    return {
                        patientId: ctrl.patientId,
                        referrals: ctrl.referrals,
                        rxs: ctrl.rxs,
                    };
                },
            },
        });

        modalInstance.result
            .then((res) => {
                if (res) {
                    return rmsService.getReferrals(ctrl.patientId).then((referrals) => {
                        ctrl.referrals = angular.copy(referrals);
                        ctrl.referrals = _formatReferrals(ctrl.referrals);
                        ctrl.tableParams.settings().dataset = ctrl.referrals;
                        ctrl.tableParams.reload().then((data) => {
                            if (data.length === 0 && ctrl.tableParams.total() > 0) {
                                ctrl.tableParams.page(ctrl.tableParams.page() - 1);
                                ctrl.tableParams.reload();
                            }
                        });
                        return res;
                    });
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    }
}

export default ReferralManagerController;
