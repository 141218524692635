import { useFormikContext } from 'formik';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { TextField } from 'Lib/form/TextField';

import SectionTitleStyle from './SectionTitleStyle';
import CommonPatientFieldWrapper from './CommonPatientFieldWrapper';

const OtherMedicationsForm = () => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <CommonPatientFieldWrapper fieldName="otherText">
            {() => (
                <div>
                    <SectionTitleStyle>
                        <h2>Other Medications</h2>
                    </SectionTitleStyle>

                    {values.otherMedication.map((otherMedication, index) => (
                        <Row key={otherMedication.id || `index-${index}`}>
                            <CommonPatientFieldWrapper fieldName="otherText">
                                {(commonProps) => (
                                    <Col md={6}>
                                        <TextField
                                            {...commonProps}
                                            fieldName={`otherMedication[${index}].text`}
                                            label="Other Medication"
                                            placeholder="Other Medication"
                                        />
                                    </Col>
                                )}
                            </CommonPatientFieldWrapper>
                            <CommonPatientFieldWrapper fieldName="otherRouteOfAdministration">
                                {(commonProps) => (
                                    <Col md={6}>
                                        <TextField
                                            {...commonProps}
                                            fieldName={`otherMedication[${index}].routeOfAdmin`}
                                            label="Route of Administration"
                                            placeholder="Route of Administration"
                                        />
                                    </Col>
                                )}
                            </CommonPatientFieldWrapper>
                            <CommonPatientFieldWrapper fieldName="otherStrength">
                                {(commonProps) => (
                                    <Col md={6}>
                                        <TextField
                                            {...commonProps}
                                            fieldName={`otherMedication[${index}].dose`}
                                            label="Strength"
                                            placeholder="Strength"
                                        />
                                    </Col>
                                )}
                            </CommonPatientFieldWrapper>
                            <CommonPatientFieldWrapper fieldName="otherFrequency">
                                {(commonProps) => (
                                    <Col md={6}>
                                        <TextField
                                            {...commonProps}
                                            fieldName={`otherMedication[${index}].frequency`}
                                            label="Frequency"
                                            placeholder="Frequency"
                                            addonButton={
                                                <Button
                                                    onClick={() => {
                                                        values.otherMedication.splice(index, 1);
                                                        setFieldValue('otherMedication', values.otherMedication);
                                                    }}
                                                >
                                                    <FaMinus />
                                                </Button>
                                            }
                                        />
                                    </Col>
                                )}
                            </CommonPatientFieldWrapper>
                        </Row>
                    ))}

                    <Button
                        bsStyle="link"
                        onClick={() => {
                            const newIndex = values.otherMedication.length;
                            setFieldValue(`otherMedication[${newIndex}]`, {});
                        }}
                    >
                        <FaPlus /> Add Other Medication
                    </Button>
                </div>
            )}
        </CommonPatientFieldWrapper>
    );
};

export default OtherMedicationsForm;
