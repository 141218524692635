/** @ngInject */
function RXHistoryModalController(medicationsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.cancelForm = cancelForm;
    ctrl.addNote = addNote;

    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.prescriptions) {
                ctrl.prescriptions = angular.copy(ctrl.resolve.prescriptions);
            }
        }
    }

    function $onInit() {
        // nothing to init
    }

    function cancelForm() {
        ctrl.dismiss({ $value: 'cancel' });
    }

    function addNote() {
        ctrl.saving = true;
        return medicationsService
            .addRxNote(ctrl.modalData.patientId, ctrl.modalData.medication.ID, ctrl.rxId, ctrl.note)
            .then(() => {
                ctrl.mode = 'rx-history';
                ctrl.saving = false;
            });
    }
}

export default RXHistoryModalController;
