import { useMachine } from '@xstate/react';
import * as R from 'ramda';
import { useEffect } from 'react';
import { assign, createMachine } from 'xstate';
import { hasLength, warning } from '../../utils';

const Actions = Object.freeze({
    BLUR: 'BLUR',
    CANCEL: 'CANCEL',
    CHANGE: 'CHANGE',
    EDIT: 'EDIT',
    FAIL: 'FAIL',
    RESET: 'RESET',
    SAVE: 'SAVE',
    SUCCESS: 'SUCCESS',
});

const States = Object.freeze({
    NOT_EDITING: 'NOT_EDITING',
    EDITING: 'EDITING',
    DIRTY: 'DIRTY',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
});

const formFieldStandaloneMachine = createMachine(
    {
        id: 'formField',
        context: {
            initialValue: null,
            currentValue: null,
            isActive: false,
            handleSave: null,
            validator: null,
            error: null,
        },
        initial: States.NOT_EDITING,
        states: {
            [States.NOT_EDITING]: {
                entry: ['resetCurrent', 'clearErrors'],
                on: {
                    [Actions.EDIT]: { target: States.EDITING },
                },
            },
            [States.EDITING]: {
                entry: ['activate'],
                exit: ['deactivate'],
                on: {
                    [Actions.CANCEL]: { target: States.NOT_EDITING },
                    [Actions.BLUR]: { target: States.NOT_EDITING },
                    [Actions.CHANGE]: { target: States.DIRTY, actions: ['updateCurrent'] },
                },
            },
            [States.DIRTY]: {
                entry: ['activate'],
                exit: ['deactivate'],
                on: {
                    [Actions.CHANGE]: { actions: ['updateCurrent'] },
                    [Actions.CANCEL]: { target: States.NOT_EDITING },
                    [Actions.SAVE]: { target: States.PENDING },
                    [Actions.RESET]: { target: States.EDITING },
                },
            },
            [States.PENDING]: {
                invoke: {
                    id: 'save-data',
                    src: (context) => {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line consistent-return
                        return new Promise((resolve, reject) => {
                            if (context.validator) {
                                try {
                                    const result = context.validator(context.currentValue);
                                    if (R.is(Promise, result)) {
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line no-promise-executor-return
                                        return resolve(result);
                                    }
                                    if (!!result && result !== 0) {
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line no-promise-executor-return
                                        return resolve(context.currentValue);
                                    }
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line no-promise-executor-return
                                    return reject(result);
                                } catch (error) {
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line no-promise-executor-return
                                    return reject(error);
                                }
                            }
                        }).then(() => {
                            return context.handleSave && context.handleSave(context.currentValue);
                        });
                    },
                    onDone: {
                        target: States.NOT_EDITING,
                        actions: ['updateInitial'],
                    },
                    onError: {
                        target: States.FAILED,
                        actions: ['handleError'],
                        exit: ['clearErrors'],
                    },
                },
            },
            [States.FAILED]: {
                entry: ['activate'],
                exit: ['deactivate'],
                on: {
                    [Actions.CHANGE]: { target: States.DIRTY, actions: ['updateCurrent'] },
                    [Actions.CANCEL]: { target: States.NOT_EDITING },
                    [Actions.SAVE]: { target: States.PENDING },
                },
            },
        },
    },
    {
        actions: {
            activate: assign((context) => {
                return R.set(R.lensProp('isActive'), true, context);
            }),
            clearErrors: assign((context) => {
                return R.set(R.lensProp('error'), null, context);
            }),
            deactivate: assign((context) => {
                return R.set(R.lensProp('isActive'), false, context);
            }),
            handleError: assign((context, error) => {
                const message = R.cond([
                    [R.path(['data', 'response', 'data', 'message']), R.path(['data', 'response', 'data', 'message'])],
                    [R.path(['data', 'message']), R.path(['data', 'message'])],
                    [R.path(['message']), R.path(['message'])],
                    [R.T, R.always('An unknown error occurred.')],
                ])(error);

                return R.set(R.lensProp('error'), message, context);
            }),
            resetCurrent: assign((context) => {
                return R.chain(R.assoc('currentValue'), R.prop('initialValue'))(context);
            }),
            updateInitial: assign((context) => {
                return R.chain(R.assoc('initialValue'), R.prop('currentValue'))(context);
            }),
            updateCurrent: assign((context, event) => {
                return R.assoc('currentValue', event.payload, context);
            }),
        },
    }
);

/**
 * A React hook for managing a standalone form field.
 * @param {function} onSave - The function that will be passed the current value of the field.
 * @param {function} validator - A function that will be used to validate the field.
 * @param {any} initialValue - The initial value that will be set on the field. Changes to this value after mount will
 * be ignored.
 * @returns {object} - The current state and helpers.
 */
function useStandaloneFormField({ onSave, validator, initialValue }) {
    //region Warnings
    warning(onSave, 'Missing `onSave` function in `useStandaloneFormField` hook. Value will not be persisted.');
    warning(validator, 'Missing `validator` function in `useStandaloneFormField` hook. Value will not be validated.');
    //endregion

    //#region State
    const [state, send] = useMachine(formFieldStandaloneMachine, {
        context: {
            currentValue: initialValue,
            initialValue,
            handleSave: onSave,
            validator,
        },
    });

    const { context } = state;
    const { isActive } = context;

    //#endregion

    //#region Actions
    const setValue = (value) => {
        send({ type: Actions.CHANGE, payload: value });
    };

    const handleCancel = () => {
        send({ type: Actions.CANCEL });
    };

    const handleBlur = () => {
        send({ type: Actions.BLUR });
    };

    const handleSave = (event) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        send({ type: Actions.SAVE });
    };

    const onEdit = () => {
        send({ type: Actions.EDIT });
    };
    //#endregion

    //#region Side Effects
    useEffect(() => {
        if (R.equals(initialValue, context.currentValue)) {
            send({ type: Actions.RESET });
        }
    }, [state.value, send, initialValue, context.currentValue]);
    //#endregion

    //#region RunTime Calculations
    const hasFailed = R.equals(States.FAILED, state.value);
    const isDisabled = R.equals(States.PENDING, state.value);
    const isDirty = R.anyPass([R.equals(States.DIRTY), R.equals(States.FAILED)])(state.value);
    const isValid = R.allPass([
        () => !hasFailed,
        () => {
            if (isDirty) {
                return !hasLength(context.error);
            }
            return true;
        },
    ])(undefined);
    const isNotEditing = R.equals(States.NOT_EDITING, state.value);
    const isPending = R.equals(States.PENDING, state.value);
    //#endregion

    return {
        Actions,
        States,
        handleBlur,
        handleCancel,
        handleSave,
        hasFailed,
        isActive,
        isDirty,
        isDisabled,
        isNotEditing,
        isPending,
        isValid,
        onEdit,
        send,
        setValue,
        state,
    };
    //#endregion
}

export default useStandaloneFormField;
