/** @ngInject */
function LockAssessmentModalController($window) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.previousPage = previousPage;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve) {
            if (ctrl.resolve.modalData) {
                ctrl.resolve.modalData = angular.copy(ctrl.resolve.modalData);
                ctrl.lockedName = ctrl.resolve.modalData.lockedName;
            }
        }
    }

    function previousPage() {
        $window.history.back();
    }
}

export default LockAssessmentModalController;
