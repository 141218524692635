import angular from 'angular';

import ContentLibraryController from './content-library.controller';
import ContentLibraryConfig from './content-library.config';

import AssociateContentGroupResourceModalController from './associate-content-group-resource-modal/associate-content-group-resource-modal.controller';
import DeleteContentGroupResourceModalController from './delete-content-group-resource-modal/delete-content-group-resource-modal.controller';
import ContentLibraryEditMonographController from './edit-monograph/edit-monograph.controller';
import ContentLibraryEditMonographConfig from './edit-monograph/edit-monograph.config';
import ContentLibraryMonographEditorController from './monograph-editor/monograph-editor.controller';

export default angular
    .module('components.contentLibrary', ['angular-ladda', 'duScroll', 'ngclipboard', 'ngTable'])
    .value('duScrollBottomSpy', true)
    .component('contentLibrary', {
        bindings: {
            user: '<',
        },
        controller: ContentLibraryController,
        template: require('./content-library.html'),
    })
    .config(ContentLibraryConfig)
    .component('associateContentGroupResourceModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: AssociateContentGroupResourceModalController,
        template: require('./associate-content-group-resource-modal/associate-content-group-resource-modal.html'),
    })
    .component('deleteContentGroupResourceModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: DeleteContentGroupResourceModalController,
        template: require('./delete-content-group-resource-modal/delete-content-group-resource-modal.html'),
    })
    .component('contentLibraryEditMonograph', {
        bindings: {
            contentGroup: '<',
            user: '<',
        },
        controller: ContentLibraryEditMonographController,
        template: require('./edit-monograph/edit-monograph.html'),
    })
    .config(ContentLibraryEditMonographConfig)
    .component('contentLibraryMonographEditor', {
        bindings: {
            contentVersion: '<',
            onChangeContentVersion: '&',
        },
        controller: ContentLibraryMonographEditorController,
        template: require('./monograph-editor/monograph-editor.html'),
    }).name;
