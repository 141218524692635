import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import SelectField from 'Lib/form/SelectField';

import useFetchPhysicianAutocomplete from '../../hooks/useFetchPhysicianAutocomplete';
import SpaceBetweenContainer from '../SpaceBetweenContainer';
import EditPhysicianModal from './EditPhysicianModal';

const PhysicianTypeaheadField = ({ fieldPrefix, disabled, required }) => {
    const { values } = useFormikContext();
    const [inputValue, setInputValue] = useState('');
    const { data: physicians, isLoading: isAutocompleteLoading } = useFetchPhysicianAutocomplete(inputValue);

    const [prescriberModalOpen, setPrescriberModalOpen] = useState(false);
    const physicianId = get(values, `${fieldPrefix}.physician.id`);

    return (
        <>
            <SelectField
                fieldName={`${fieldPrefix}.physician`}
                label={
                    <SpaceBetweenContainer>
                        <div>Prescriber</div>
                        <Button bsStyle="link" className="btn-default" onClick={() => setPrescriberModalOpen(true)}>
                            {physicianId ? 'Edit' : 'Add'}
                        </Button>
                    </SpaceBetweenContainer>
                }
                placeholder="Prescriber"
                options={
                    physicians
                        ? Object.values(physicians).map((physician) => ({
                              ...physician,
                              firstName: physician.physician_first_name,
                              lastName: physician.physician_last_name,
                          }))
                        : []
                }
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                onInputChange={setInputValue}
                isLoading={isAutocompleteLoading}
                isDisabled={disabled}
                required={required}
            />
            {prescriberModalOpen && (
                <EditPhysicianModal existingPhysicianId={physicianId} onClose={() => setPrescriberModalOpen(false)} />
            )}
        </>
    );
};

PhysicianTypeaheadField.propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
};
PhysicianTypeaheadField.defaultProps = {
    disabled: false,
    required: false,
};

export default PhysicianTypeaheadField;
