import Cookies from 'js-cookie';
import TokenService from './TokenService';
import jwtDecode from 'jwt-decode';
import config from '../components/config/config';
import * as R from 'ramda';

class SessionStorageService {
    /**
     * @summary Gets the provided key, and will return the result as an object if json is set true;
     *
     * @param {string} key - The key to lookup in local storage.
     * @returns {string|object}
     */
    static get = (key) => {
        if (!key || typeof key !== 'string') {
            throw new Error(`Invalid key provided expected type string; got type ${typeof key} ${key}`);
        }
        const row = window.localStorage.getItem(`V2_${TokenService.get()}_${key}`);
        if (row === null) {
            return {};
        }
        return JSON.parse(row).value;
    };

    /**
     * @summary Finds all versioned local storage items that have expired and removes them.
     */
    static clearExpired = () => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax
        for (const i in window.localStorage) {
            if (i.substr(0, 2) === 'V2') {
                const row = JSON.parse(window.localStorage.getItem(i));
                if (new Date(row.expiration) < new Date()) {
                    window.localStorage.removeItem(i);
                }
            }
        }
    };

    /**
     * @summary Gets the provided key, and will return the result as an object if json is set true;
     *
     * @description
     * This differs from get(). Get retrieves keys that are prefixed by the user token. This retrieves keys that
     * are prefixed by the user id.
     *
     * @param {string} key - The key to lookup in local storage.
     * @param {boolean} json - Whether to return the result as JSON or not
     * @returns {string|object|null}
     */
    static getOnUser = (key, json) => {
        if (typeof key !== 'string') {
            throw new Error(`Invalid key provided expected type string; got type ${typeof key} ${key}`);
        }

        const userId = window.localStorage.getItem('userId');
        if (userId) {
            const value = window.localStorage.getItem(`${userId}_${key}`);
            if (json === true) {
                try {
                    return typeof value === 'string' ? JSON.parse(value) : null;
                } catch (e) {
                    window.localStorage.removeItem(`${userId}_${key}`);
                }
            }
            return value;
        }
        throw new Error('No user id found for this user');
    };

    /**
     * @summary Deletes the provided `key` from local storage.
     *
     * @param {string} key
     * @returns void
     */
    static remove = (key) => {
        if (typeof key !== 'string') {
            throw new Error(`Invalid key provided expected type string; got type ${typeof key} ${key}`);
        }

        return window.localStorage.removeItem(`${TokenService.get()}_${key}`);
    };

    /**
     * @summary Sets a value in local storage
     *
     * @description
     * Takes the provided key and prepends it with the session token, if the provided `value` is an object
     * it will be JSON stringified to enable its storage in local storage
     *
     * @param {string} key - Key to store the value by
     * @param {any} value - Value to be stored.
     * @param {number} expiration - TTL in seconds
     * @returns void
     */
    static set = (key, value, expiration = 3600) => {
        if (typeof expiration !== 'number' || expiration <= 0) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            expiration = 3600;
        }
        if (typeof key !== 'string') {
            throw new Error(`Invalid key provided expected type string; got type ${typeof key} ${key}`);
        }

        const expirationDate = new Date(new Date().setSeconds(new Date().getSeconds() + expiration));
        const item = {
            value,
            expiration: expirationDate,
        };
        return window.localStorage.setItem(`V2_${TokenService.get()}_${key}`, JSON.stringify(item));
    };

    /**
     * @summary Sets a value in local storage
     *
     * @description
     * Takes the provided key and prepends it with the user id, if the provided `value` is an object
     * it will be JSON stringified to enable its storage in local storage
     *
     * @param {string} key - Key to store the value by
     * @param {any} value - Value to be stored.
     * @returns void
     */
    static setOnUser(key, value) {
        if (typeof key !== 'string') {
            throw new Error(`Invalid key provided expected type string; got type ${typeof key} ${key}`);
        }
        if (typeof value === 'object') {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            value = JSON.stringify(value);
        }

        const userId = window.localStorage.getItem('userId');
        if (userId) {
            return window.localStorage.setItem(`${userId}_${key}`, value);
        }
        throw new Error('No user id found for this user');
    }

    /**
     * Gets the domain.tld
     * @returns {string}
     */
    static getCookieDomain() {
        const [tld, domain] = config.therigyEndpoint
            .replace(/(https?:\/\/|\/)/, '')
            .split('.')
            .reverse();

        return `${domain}.${tld}`;
    }

    /**
     * @summary Saves JWT in a cross-browser manner
     *
     * @param {string} token - JWT
     * @returns {null}
     */
    static setJwt = (token) => {
        const chunks = token.match(/.{1,1000}/g); // IE bug with long LS values
        window.localStorage.setItem('JWT-chunks', chunks.length);
        chunks.forEach((chunk, index) => {
            window.localStorage.setItem(`JWT-${index}`, chunk);
        });
    };

    static clearSession() {
        localStorage.clear();
    }

    static setSession(jwt) {
        const domain = `.${SessionStorageService.getCookieDomain()}`;
        Cookies.remove('sso_jwt', { path: '/', domain });
        const { data } = jwtDecode(jwt);
        Cookies.set('sessid', data.Token);
        window.localStorage.setItem('userId', data.UserID);
        window.localStorage.setItem('branchId', data.BranchID);
        window.localStorage.setItem('companyId', data.CompanyID);
        window.localStorage.setItem('userAge', data.UserAge);
        this.setJwt(jwt);
        window.localStorage.setItem('sessionId', Cookies.get('sessid'));
        window.localStorage.setItem('currentToken', Cookies.get('sessid'));
    }

    static getSsoCookie() {
        return Cookies.get('sso_jwt');
    }

    /**
     * @summary Retrieves JWT in a cross-browser manner
     *
     * @returns {string}
     */
    static getJwt = () => {
        // If the user is arriving from SSO get the JWT out of the cookie.
        const ssoCookie = Cookies.get('sso_jwt');
        if (ssoCookie) {
            const domain = `.${SessionStorageService.getCookieDomain()}`;
            Cookies.remove('sso_jwt', { path: '/', domain });
            const { data } = jwtDecode(ssoCookie);
            Cookies.set('sessid', data.Token);
            window.localStorage.setItem('userId', data.UserID);
            window.localStorage.setItem('branchId', data.BranchID);
            window.localStorage.setItem('companyId', data.CompanyID);
            window.localStorage.setItem('userAge', data.UserAge);
            this.setJwt(ssoCookie);
            window.localStorage.setItem('sessionId', Cookies.get('sessid'));
            window.localStorage.setItem('currentToken', Cookies.get('sessid'));
            return ssoCookie;
        }

        let jwt = '';
        const jwtChunks = window.localStorage.getItem('JWT-chunks');

        if (!jwtChunks) {
            return jwt;
        }

        for (let index = 0; index < jwtChunks; index += 1) {
            jwt += window.localStorage.getItem(`JWT-${index}`);
        }

        return jwt;
    };

    static getDecodedJwt = () =>
        R.compose(
            R.ifElse(R.isEmpty, R.always({}), (d) => jwtDecode(d)),
            SessionStorageService.getJwt
        )();
}

export default SessionStorageService;
