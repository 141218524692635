import PESService from './PESService';
import convertKeysToSnakeCase from '../utils/keyConversion/convertKeysToSnakeCase';
/**
 * This service handles requests for 'patient_other_medication'
 */
/** @ngInject */
function PatientOtherMedicationService($http, _, urlBuilderService) {
    return {
        del,
        getMany,
        patch,
        post,
    };

    function del(patientId, secondaryMedicationId) {
        return PESService.deletePatientOtherMedication(patientId, secondaryMedicationId).then((res) =>
            convertKeysToSnakeCase(res)
        );
    }

    function getMany(patientId) {
        return PESService.getPatientOtherMedication(patientId).then((res) => convertKeysToSnakeCase(res));
    }

    function patch(patientId, secondaryMedicationId, body) {
        return PESService.updatePatientOtherMedication(patientId, secondaryMedicationId, body).then((res) =>
            convertKeysToSnakeCase(res)
        );
    }

    function post(patientId, body) {
        const url = urlBuilderService.build('post', {
            patient: patientId,
            otherMedication: '',
        });

        return $http.post(url, body).then((res) => {
            return res.data;
        });
    }
}

export default PatientOtherMedicationService;
