import createAxios from 'App/utils/createAxios';
import config from '../components/config/config';
import getSlsBasePath from '../utils/getSlsBasePath/getSlsBasePath';

const slsHost = getSlsBasePath(config, 'tas');

const tas = createAxios({
    baseURL: `${slsHost}/${config.slsVersion}`,
});

class TasService {
    static tas = createAxios({
        baseURL: `${slsHost}/${config.slsVersion}`,
    });

    /**
     * Fetches all custom menus for a company.
     * @returns boolean - An array of custom menu options.
     */
    static async getTknLogin() {
        const response = await tas.get(`/tknLogin`);
        return response.loginurl;
    }
}

export default TasService;
