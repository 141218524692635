import { useEffect } from 'react';
import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';
import useProfileDataState from './useProfileDataState';

/**
 * Custom hook which make all the http call to fetch patient insurance required to render patient detail page
 * @param {string} patientId
 * @returns {Object}
 */
function useGetPatientInsurance(patientId) {
    const {
        data: insurance,
        setData: setInsurance,
        isLoading: isInsuranceLoading,
        triggerRefetch: triggerInsuranceRefetch,
        refetch,
        setIsLoading,
    } = useProfileDataState();

    useEffect(() => {
        PESService.getPatientInsurance(patientId)
            .then((insuranceResponse) => {

                setInsurance(insuranceResponse);
            })
            .catch(errorHandler)
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, refetch]);

    return {
        insurance,
        isInsuranceLoading,
        triggerInsuranceRefetch,
    };
}

export default useGetPatientInsurance;
