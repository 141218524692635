import axios from 'axios';
import dayjs from 'dayjs';
import { upperCase } from 'lodash';

import { stripAllHtml } from 'App/utils';
import createAxios from 'App/utils/createAxios';
import config from '../components/config/config';
import getAuthorizationHeader from '../utils/getAuthorizationHeader';
import getSlsBasePath from '../utils/getSlsBasePath/getSlsBasePath';
import convertKeysToCamelCase from '../utils/keyConversion/convertKeysToCamelCase';
import UrlBuilderService from './UrlBuilderService';
import graphClient from '../common/graphClient';
import { gql } from 'graphql-request';
import * as R from 'ramda';

const slsHost = getSlsBasePath(config, 'pes');

const { pesEndpoint, pesPort, pesVersion } = config;
const pesBaseUrl = `${pesEndpoint}:${pesPort}/pes/${pesVersion}`;
const pesSlsBaseUrl = `${slsHost}/${config.slsVersion}`;

const expressPes = createAxios({
    baseURL: pesBaseUrl,
});

const slsPes = createAxios({
    baseURL: pesSlsBaseUrl,
});

const slsPesignoreForbiddenAndNotFound = createAxios(
    {
        baseURL: pesSlsBaseUrl,
    },
    { shouldShowToastFn: ignoreForbiddenAndNotFound }
);

const { protocol, host, port } = window.location;
const therigyURL = `${protocol}//${host}${port ? `:${port}` : ''}`;
const phpRequest = createAxios({
    baseURL: `${therigyURL}/app.api.php/api`,
});

function ignoreForbiddenAndNotFound(error) {
    const { status } = error.response;
    if (status === 403 || status === 404) {
        return false;
    }

    return true;
}

export const NOTE_TYPE_TO_SUMMARY_NOTE_TYPE = Object.freeze({
    0: 'PCC',
    1: 'Clinical',
});

class PESService {
    /**
     * Fetches the patient's labels
     * @param patientId - The patient id.
     * @returns {Promise<labels>} - A map of patient labels keyed by id.
     */
    static getPatientLabels(patientId) {
        return slsPes.get(`/patient/${patientId}/labels`);
    }

    /**
     * Adds a patient label
     * @param {string} patientId - The patient id.
     * @param {string} label - The patient label.
     * @returns {Promise<labels>} - A map of patient labels keyed by id.
     */
    static addPatientLabel(patientId, label) {
        return slsPes.post(`/patient/${patientId}/label`, {
            label,
        });
    }

    /**
     * Deletes a patient label
     * @param {string} patientId - The patient id.
     * @param {integer} labelId - A patient label id.
     * @returns {Promise<label>} - The deleted patient label object.
     */
    static deletePatientLabel(patientId, labelId) {
        return slsPes.delete(`/patient/${patientId}/label/${labelId}`);
    }

    /**
     * otherMedication Object
     * @typedef {Object} OtherMedication
     * @property {string} id
     * @property {string} patientId
     * @property {string} text
     * @property {string} dose
     * @property {string} frequency
     * @property {string} dateTime
     * @property {string} employeeId
     * @property {string} brand
     * @property {string} routeOfAdmin
     * @property {string} nursingCare
     * @property {string} createdOn
     */

    /**
     * Get patient other medications
     * @param {string} patientId
     * @returns {Promise<OtherMedication[]>}
     */
    static getPatientOtherMedication(patientId) {
        return slsPes.get(`/patient/${patientId}/otherMedication`);
    }

    /**
     * Delete patient other medications by Id
     * @param {string} patientId
     * @param {string} otherMedicationId
     * @returns {Promise<OtherMedication[]>}
     */
    static deletePatientOtherMedication(patientId, otherMedicationId) {
        return slsPes.delete(`/patient/${patientId}/otherMedication/${otherMedicationId}`);
    }

    /**
     * Update patient other medications by Id
     * @param {string} patientId
     * @param {string} otherMedicationId
     * @returns {Promise<OtherMedication[]>}
     */
    static updatePatientOtherMedication(patientId, otherMedicationId, body) {
        return slsPes.patch(`/patient/${patientId}/otherMedication/${otherMedicationId}`, body);
    }

    /**
     * Create patient other medication
     * @param {string} patientId
     * @param {OtherMedication} body
     * @returns {Promise<OtherMedication>}
     */
    static createPatientOtherMedication(patientId, body) {
        return expressPes.post(`/patient/${patientId}/otherMedication`, body);
    }

    /**
     * Update patient subscription
     * @param {string} patientId
     * @param {string} subscriptionTypeId
     * @returns {Promise<Subscriptions[]>}
     */
    static updatePatientSubscription(patientId, subscriptionTypeId, body) {
        return expressPes.patch(`/patient/${patientId}/subscription/${subscriptionTypeId}`, body);
    }

    /**
     * Update patient
     * @param {string} patientId
     * @returns {Promise}
     */
    static updatePatient(patientId, body) {
        return expressPes.patch(`/patient/${patientId}`, body);
    }

    /**
     * Create patient
     * @param {object} body - payload of patient data to create
     * @returns {Promise}
     */
    static createPatient(body) {
        return expressPes.post('/patient', body);
    }

    /**
     * @typedef Identifier
     * @property {string} createdOn - The timestamp of creation
     * @property {number} id - The unique ID of the row
     * @property {string} identifier - The identifier
     * @property {string} identifierType - The identifier type
     * @property {string} patientId - The patient ID
     * @property {string} uuid - The uuid of the row
     */

    /**
     * @typedef IdentifierTypeObject
     * @property {string} Type - The identifier-type
     * @property {string} Id - the value of the identifier-type
     */

    /**
     * Fetches the patient's identifiers
     * @param patientId - The patient id.
     * @returns {Promise<[Identifier]>} - A map of patient identifiers keyed by uuid.
     */
    static getIdentifiers(patientId) {
        return slsPes.get(`/patient/${patientId}/identifiers`);
    }

    /**
     * Adds the patient's identifiers
     * @param patientId - The patient id.
     * @param {[{Id: string, Type: string}]} newIdentifiers - An array of the patient identifiers to add
     * @returns {Promise<[Identifier]>} - A map of patient added identifiers keyed by uuid.
     */
    static addIdentifiers(patientId, newIdentifiers) {
        return slsPes.post(`/patient/${patientId}/identifiers`, newIdentifiers);
    }

    /**
     * Updates/Adds the specified patient identifier(s).
     * @param {string} patientId - The patient id.
     * @param {(IdentifierTypeObject|IdentifierTypeObject[])} identifiers - The identifier(s) to update.
     * @returns {Promise<Identifier>} The updated identifier object
     */
    static async upsertPatientIdentifiers(patientId, identifiers) {
        return slsPes.patch(`/patient/${patientId}/identifiers`, identifiers);
    }

    /**
     * Deletes the specified patient identifiers.
     * @param {string} patientId - The patient id.
     * @param {string} identifierType - The patient identifierType to be deleted
     * @returns {Promise<Identifier>} The updated identifier object
     */
    static async deletePatientIdentifier(patientId, identifierType) {
        return slsPes.delete(`/patient/${patientId}/identifiers/${identifierType}`);
    }

    /**
     * Fetches the patient's visits
     * @param patientId - The id of the patient
     * @returns {Promise<visits>} - A map of patient visits keyed by uuid
     */
    static getVisits(patientId) {
        return slsPes.get(`/patient/${patientId}/visits`);
    }

    /**
     * Adds a patient referral
     * @param patientId - The patient id.
     * @returns {Promise<patientReferral>} - A patient referral object.
     */
    static addPatientReferral(patientId) {
        return slsPes.post(`/patient/${patientId}/referral`, null);
    }

    /**
     * Deletes a patient referral
     * @param patientId - The patient id.
     * @returns {Promise<patientReferral>} - A patient referral object.
     */
    static deletePatientReferral(patientId) {
        return slsPes.delete(`/patient/${patientId}/referral`);
    }

    /**
     * Gets a patient referral
     * @param patientId - The patient id.
     * @returns {Promise<patientReferral>} - A patient referral object.
     */
    static getPatientReferral(patientId) {
        return slsPes.get(`/patient/${patientId}/referral`);
    }

    /**
     * Fetches the patient's activities
     * @param patientId - the patient id.
     * @returns {Promise<activities>} - A map of patient activities keyed by uuid
     */
    static getActivities(patientId) {
        return slsPes.get(`/patient/${patientId}/activity`);
    }

    /**
     * Fetches the patient's Details
     * @param patientId - the patient id.
     * @returns {Promise<activities>} - A map of patient details
     */
    static get(patientId) {
        return slsPes.get(`/patient/${patientId}`);
    }

    /**
     * Gets patient prescriptions
     * @param patientId - The patient id.
     * @param medicationId - The medication id.
     * @returns {Promise<patientPrescription>} - A patient prescription object.
     */
    static getPatientPrescriptions(patientId, medicationId, params = {}) {
        return slsPes.get(`/patient/${patientId}/medication/${medicationId}/prescriptions`, {
            params,
        });
    }

    /**
     * Gets a patient medication
     * @param patientId - The patient id.
     * @returns {Promise<patientMedication>} - A patient medication object.
     */
    static getPatientMedications(patientId) {
        return slsPes.get(`/patient/${patientId}/medications`);
    }

    /**
     * Gets a patient therapies list
     * @param patientId - The patient id.
     * @returns {Promise<patientTherapies>} - A patient therapies list object.
     */
    static getTherapies(patientId) {
        return slsPes.get(`/patient/${patientId}/therapy`);
    }

    /**
     * Gets a patient precriptions, prescription status, and medication details
     * @param {string} patientId - The patient id.
     * @returns {Promise<rx>} - A patient prescription objects.
     */
    static getPatientRx(patientId) {
        return slsPes.get(`/patient/${patientId}/rx`);
    }

    /**
     * Sends a request to the EHR for missing patient identifiers
     * @param patientId - The patient id.
     * @returns {Promise<patientIdentifiers[]>} - A keyed hash of the patient identifiers stored in the EHR
     */
    static getPatientIdentifiersFromEhr(patientId) {
        return slsPesignoreForbiddenAndNotFound.get(`/ehr/${patientId}/identifiers`);
    }

    /**
     * Sends a request to the EHR for missing patient visits
     * @param patientId - The patient id.
     * @returns {Promise<patientVisits>} - A keyed hash of the patient identifiers stored in the EHR
     */
    static getPatientVisitsFromEhr(patientId) {
        return slsPesignoreForbiddenAndNotFound.get(`/ehr/${patientId}/visits`);
    }

    /**
     * Sends a request to the EHR to sync patient data
     * @param patientId - The patient id.
     * @returns {Promise<object>} - A keyed hash of patient data
     */
    static syncPatientWithEhr(patientId) {
        return slsPesignoreForbiddenAndNotFound.get(`/ehr/${patientId}/sync`);
    }

    /**
     * Sends a request to get patient unassigned medications
     * @param patientId - The patient id.
     * @returns {Promise<rx>} - A keyed hash of the patient identifiers stored in the EHR
     */
    static getPatientUnassignedMedications(patientId) {
        return slsPes.get(`/patient/${patientId}/unassignedMedications`);
    }

    /**
     * Gets all disabled fields for the user's company.
     * @param companyId - The user's company id.
     *
     * @return {Array} An array of the disabled fields
     */
    static getDisabledFields(companyId) {
        return slsPes.get(`/company/${companyId}/disabledPatientFields`);
    }

    /**
     * Sends a request to get work queue headers
     * @returns {Promise<headers>} - A keyed hash of work queue headers
     */
    static getHeader() {
        return slsPes.get(`/workqueue/header`);
    }

    /**
     * Get saved work queue filters
     * @returns {Promise<filters>} - A keyed hash of work queue filters
     */
    static getFilters() {
        return slsPes.get(`/workqueue/filter`);
    }

    /**
     * Delete work queue filter
     * @param {Object} - object containing filter uuid
     * @returns {Promise<filters>} response with existing filters list
     */
    static deleteFilter(params) {
        return phpRequest.post('/work-queue/delete-filter', params);
    }

    /**
     * Save work queue filter
     * @param {object} params - work queue filter object
     * @returns
     */
    static saveFilter(params) {
        return phpRequest.post('work-queue/filter', params);
    }

    /**
     * Get activity status and notes
     * @param {string} activityId
     * @returns {Promise<activityStatus>} - A keyed hash of activity status
     */
    static getActivityNotes(activityId) {
        return slsPes.get(`/patient/activity-status/${activityId}`);
    }

    /**
     * Get workqueue counts
     * @returns {Promise<workqueueCounts>} - an object conatining workqueue counts
     */
    static getCounts() {
        return slsPes.get(`/workqueue/counts`);
    }

    /**
     * Get workqueue Patient & activity details
     * @param params - The filter params.
     * @returns {Promise} - A workqueue response object
     */
    static getWorkqueue(params) {
        return slsPes.post(`/workqueue`, params);
    }

    static getAssociationQueue() {
        return expressPes.get(`/associationQueue`);
    }

    /**
     * Create Patient Insurance
     * @param params - The filter params.
     * @returns {Promise} - A insurance response object
     */
    static addPatientInsurance(patientId, params) {
        return slsPes.post(`/patient/${patientId}/insurance`, params);
    }

    /**
     * Update Patient Insurance
     * @param params - The filter params.
     * @returns {Promise} - A insurance response object
     */
    static updatePatientInsurance(patientId, insuranceId, params) {
        return slsPes.patch(`/patient/${patientId}/insurance/${insuranceId}`, params);
    }

    /**
     * Get Patient Insurance
     * @returns {Promise} - A insurance response object
     */
    static getPatientInsurance(patientId) {
        return slsPes.get(`/patient/${patientId}/insurance`);
    }

    /**
     * Get Patient Subscription
     * @param {string} patientId
     * @returns {Promise}
     */
    static getPatientSubscriptions(patientId, params = {}) {
        return expressPes
            .get(`/patient/${patientId}/subscriptions`, {
                params,
            })
            .then(convertKeysToCamelCase);
    }

    /**
     * Subscribe Patient SMS
     * @param {string} patientId
     * @returns {Promise}
     */
    static subscribeSMS(patientId) {
        const url = UrlBuilderService.build('engageSMSSubscribe', {
            patient: patientId,
            subscriptions: '',
            sms: '',
        });
        return axios.post(url, {}, getAuthorizationHeader());
    }

    /**
     * Get Patient messages
     * @param {string} patientId
     * @returns {Promise}
     */
    static getPatientMessages(patientId) {
        return expressPes.get(`/patient/${patientId}/messages`);
    }
    /**
     * Add Patient Prescriptions
     * @param {string} patientId
     * @returns {Promise}
     */

    static addPatientPrescriptions(patientId, patientMedicationRxNumbers) {
        return graphClient
            .request(
                gql`
                    mutation addPatientPrescription($patientId: String!, $patientMedicationRxNumbers: [prescriptions]) {
                        addPatientPrescription(
                            patientId: $patientId
                            patientMedicationRxNumbers: $patientMedicationRxNumbers
                        ) {
                            id
                            uuid
                            rxNumber
                            patientMedicationId
                            physicianPatientMedicationId
                            strength
                            ndcType
                            ndc
                            dispensedMedication
                            ctime
                            mtime
                            start
                            end
                            endReason
                            writtenQuantity
                            daysSupply
                            fillDate
                            dispensedQuantity
                            soldDate
                            readyDate
                            createDate
                            patientPayAmount
                            itemSource
                            deliveryOption
                            trackingId
                            estimatedDeliveryDateTime
                            trackingUrl
                            productExpirationDate
                            route
                            form
                            refillQuantity
                            refillsAuthorized
                            refillsRemaining
                            fillNumber
                            expectedFillDate
                            expandedSig
                            daw
                            fillExpiration
                        }
                    }
                `,
                { patientId, patientMedicationRxNumbers }
            )
            .then(R.propOr([], 'addPatientPrescription'));
    }

    /**
     * Add Patient Prescriptions
     * @param {string} patientId
     * @returns {Promise}
     */

    static addRxFills(patientId, patientMedicationId, patientMedicationRxNumberId, newRxFills) {
        return graphClient
            .request(
                gql`
                    mutation addRxFill(
                        $patientId: ID!
                        $patientMedicationId: String
                        $patientMedicationRxNumberId: Int
                        $newRxFills: [Fill]!
                    ) {
                        addRxFill(
                            patientId: $patientId
                            patientMedicationId: $patientMedicationId
                            patientMedicationRxNumberId: $patientMedicationRxNumberId
                            newRxFills: $newRxFills
                        ) {
                            patientMedicationRxNumberId
                            fillNumber
                            fillDate
                            dispensedQuantity
                            daysSupply
                        }
                    }
                `,
                { patientId, patientMedicationId, patientMedicationRxNumberId, newRxFills }
            )
            .then(R.propOr([], 'addRxFill'));
    }

    /**
     * Gets a patient precriptions, prescription status, and medication details
     * @param {string} patientId - The patient id.
     * @returns {Promise<rx>} - A patient prescription objects.
     */
    static getAllPatientPrescriptions(patientId) {
        return graphClient
            .request(
                gql`
                    query getAllPatientPrescriptions($patientId: String!) {
                        getAllPatientPrescriptions(patientId: $patientId) {
                            id
                            patientId
                            medicationId
                            rxs {
                                rxNumber
                                strength
                                start
                                end
                            }
                        }
                    }
                `,
                { patientId }
            )
            .then(R.propOr([], 'getAllPatientPrescriptions'));
    }

    /**
     * Gets a patient precriptions, prescription status, and medication details
     * @param {string} patientId - The patient id.
     * @returns {Promise<rx>} - A patient prescription objects.
     */
    static getPatientMedicationRxNumber(patientId, medicationId, activeOnly) {
        return graphClient
            .request(
                gql`
                    query getPatientPrescription($patientId: String!, $medicationId: String, $activeOnly: Boolean) {
                        getPatientPrescription(
                            patientId: $patientId
                            medicationId: $medicationId
                            activeOnly: $activeOnly
                        ) {
                            id
                            uuid
                            rxNumber
                            patientMedicationId
                            strength
                            fillNumber
                            fillDate
                            dispensedQuantity
                            daysSupply
                            start
                            end
                            endReason
                            refillsAuthorized
                            fillExpiration
                            physician {
                                id
                                firstName
                                lastName
                                npi
                                phoneNumber
                                faxNumber
                            }
                            medication {
                                id
                                name
                            }
                            prescriptionData {
                                id
                                data
                            }
                            rxFill {
                                id
                                patientMedicationRxNumberId
                                fillNumber
                                fillDate
                                dispensedQuantity
                                daysSupply
                                createdOn
                            }
                        }
                    }
                `,
                { patientId, medicationId, activeOnly }
            )
            .then(R.propOr([], 'getPatientPrescription'));
    }

    /**
     * Update Rx FIlls
     * @param {string} patientId - The patient id.
     * @param {string} fillId - The Fill id.
     * @param {Object} rxFill - The rxFills to be updated.
     * @returns {Promise<rx>} - A patient prescription objects.
     */
    static updateRxFill(patientId, fillId, rxFill) {
        return graphClient
            .request(
                gql`
                    mutation updateRxFill($patientId: ID!, $fillId: Int, $rxFill: Fill) {
                        updateRxFill(patientId: $patientId, fillId: $fillId, rxFill: $rxFill) {
                            id
                            patientMedicationRxNumberId
                            fillNumber
                            fillDate
                            dispensedQuantity
                            daysSupply
                            updatedOn
                            updatedBy
                        }
                    }
                `,
                { patientId, fillId, rxFill }
            )
            .then(R.propOr({}, 'updateRxFill'));
    }

    static getPatientActivityFiles(patientId) {
        return slsPes.get(`/patient/${patientId}/patientActivityFiles`);
    }

    static getMedication(patientId, params) {
        return expressPes.get(`/patient/${patientId}/medication`, { params });
    }

    static getAge(birthDate) {
        return dayjs().diff(birthDate, 'year');
    }

    static getPrescriptions(patientId) {
        return expressPes.get(`/patient/${patientId}/prescriptions`);
    }

    static getICD10(patientId, therapyId) {
        return expressPes.get(`/patient/${patientId}/therapy/${therapyId}/icd10`);
    }

    static getAllergy(patientId) {
        return expressPes.get(`/patient/${patientId}/allergy`);
    }

    static getOtherCondition(patientId) {
        return expressPes.get(`/patient/${patientId}/otherCondition`);
    }

    static getSummaryNotes(patientId, type) {
        const params = {};
        if (type) {
            params.type = upperCase(NOTE_TYPE_TO_SUMMARY_NOTE_TYPE[type]);
        }
        return expressPes.get(`/patient/${patientId}/summaryNotes?${new URLSearchParams(params).toString()}`);
    }

    static createSummaryNote({ patientId, activityId, noteBody, noteSubject, noteType, parentNoteId }) {
        return expressPes.post(`/patient/${patientId}/summaryNotes`, {
            activityId,
            body: stripAllHtml(noteBody),
            subject: stripAllHtml(noteSubject),
            type: upperCase(NOTE_TYPE_TO_SUMMARY_NOTE_TYPE[noteType]),
            parent: parentNoteId,
        });
    }

    /**
     * Find patients
     * @param params - The search params.
     * @returns {Promise} - Patients response object
     */
    static findPatients(params) {
        return slsPes.post(`/patients`, params);
    }

    /**
     * Create and delete Patient ICD10
     * @param params - The filter params.
     * @returns {Promise} - A patient condition ICD10 response object
     */
    static addRemovePatientICD10(patientId, therapyId, params) {
        return slsPes.put(`/patient/${patientId}/therapy/${therapyId}/addRemovePatientICD10`, params);
    }

    /**
     * Get Patient ICD10
     * @returns {Promise} - A patient condition ICD10 response object
     */
    static getAllTherapyICD10s(patientId, therapyId) {
        return slsPes.get(`/patient/${patientId}/therapy/${therapyId}/patientICD10s`);
    }

    /**
     * @typedef MedicationStatus
     * @property {string} start - The ISO date string of when the medication started.
     * @property {string} end - The ISO date string of when the medication ended.
     * @property {string} endReason - The reason the medication was ended.
     * @property {string} [note] - Any additional notes.
     */
    /**
     * @typedef TreatmentHistoryPayload
     * @property {string} medicationId
     * @property {MedicationStatus} status
     */
    /**
     * Create a new Treatment History
     * @param {string} patientId
     * @param {TreatmentHistoryPayload} payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    static addTreatmentHistory(patientId, payload) {
        return slsPes.post(`/patient/${patientId}/treatment-history`, payload);
    }

    /**
     * Gets patient's predictive model risk data
     * @param patientId - The patient id.
     * @returns {Promise} - A patient predictive model object.
     */
    static getPatientPredictiveModelRisk(patientId) {
        return slsPes.get(`/patient/${patientId}/predictive-model`);
    }

    /**
     * Update Patient Branch
     * @returns {Promise} - A branch response object
     */
    static updatePatientBranch(patientId, branchId) {
        return slsPes.patch(`/patient/${patientId}/branch/${branchId}`);
    }

    /**
     * Legacy implementation of the patient update. Needed in some circumstances
     * @deprecated
     * @param patientId
     * @param body
     * @returns {Promise<AxiosResponse<any>>}
     */
    static updatePatientPhp(patientId, body) {
        return phpRequest.patch(`/patient/${patientId}`, body);
    }
}

export default PESService;
