import axios from 'axios';
import { keyBy, sortBy } from 'lodash';
import createAxios from 'App/utils/createAxios';
import UrlBuilderService from './UrlBuilderService';
import * as R from 'ramda';
import __env from 'App/components/config/config';

const { protocol, host, port } = window.location;
const therigyURL = `${protocol}//${host}${port ? `:${port}` : ''}`;
const phpRequest = createAxios({
    baseURL: `${therigyURL}/app.api.php/api`,
});

const pesEndpoint = createAxios({
    baseURL: `${__env.pesEndpoint}:${__env.pesPort}/pes/${__env.pesVersion}`,
});

const getStatusOptions = async () => {
    const url = UrlBuilderService.build('getMany', {
        resource: '',
        menu: '',
        patientstatus: '',
        options: '',
    });
    const { data } = await axios.get(url);

    return sortBy(Object.values(keyBy(data, 'ID')), 'ValueName');
};

async function updateHighRiskReasons(patientId, highRiskReasons) {
    return phpRequest.post(`/patient/${patientId}/risk`, highRiskReasons).then(() => {
        /**
         * I'm only doing this because the return value from the PHP request is trash
         */
        return getHighRiskReasons(patientId);
    });
}

async function disablePatient(patientId) {
    return phpRequest.delete(`/patient/${patientId}`);
}

async function enablePatient(patientId) {
    return phpRequest.patch(`/patient/${patientId}/disable`);
}

function getHighRiskReasons(patientId) {
    return pesEndpoint.get(`/patient/${patientId}/highRisk`).catch(R.compose(R.always([]), R.tap(console.error)));
}

export { disablePatient, enablePatient, getStatusOptions, updateHighRiskReasons, getHighRiskReasons };
