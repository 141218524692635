/** @ngInject */
function defaultQuestionDiffConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.questionDiff',
        url: '/question-diff',
        views: {
            '': 'questionDiff',
            'pageTitle@^': {
                template: 'Question Comparison Tool',
            },
        },
    });
}

export default defaultQuestionDiffConfig;
