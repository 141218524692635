import FlexCenter from 'App/components/styles/FlexCenter';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import { cloneAssessment, getAllTherapies, getAllTherapyAssessments } from 'App/services/AdminService';
import AssessmentService from 'App/services/AssessmentService';
import { errorHandler } from 'App/utils';
import * as R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Row from 'react-bootstrap/lib/Row';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';

const THERIGY_CO_NAME = 'Therigy';

const isNullOrEmpty = R.anyPass([R.isNil, R.isEmpty]);

function CloneAssessmentPage() {
    //#region State
    const history = useHistory();
    const { categoryId, assessmentId } = useParams();
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [assessments, setAssessments] = useState([]);
    const [sourceAssessment, setSourceAssessment] = useState({});
    const [category, setCategory] = useState(null);
    const [targetAssessment, setTargetAssessment] = useState(null);
    const [isAssessmentsLoading, setIsAssessmentsLoading] = useState(false);
    //#endregion

    //#region FetchData
    useEffect(() => {
        if (categoryId && assessmentId) {
            const promise = [getAllTherapies(), AssessmentService.getAssessment(assessmentId)];
            Promise.all(promise)
                .then(([therapyCategories, currentAssessment]) => {
                    //prettier-ignore
                    R.compose(
                        setCategories,
                        R.sortBy(R.compose(R.toLower, R.prop('name'))),
                        R.values,
                    )(therapyCategories);

                    setSourceAssessment(currentAssessment);
                })
                .catch(errorHandler)
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [categoryId, assessmentId]);

    const targetCategoryId = R.prop('id', category);

    useEffect(() => {
        if (targetCategoryId && assessmentId) {
            setTargetAssessment(null);
            setAssessments([]);
            setIsAssessmentsLoading(true);
            getAllTherapyAssessments(targetCategoryId)
                .then(R.values)
                .then(R.reject(R.propEq(assessmentId, 'id')))
                .then(setAssessments)
                .finally(() => {
                    setIsAssessmentsLoading(false);
                });
        }
    }, [targetCategoryId, assessmentId]);
    //#endregion

    //#region Click/Action/Event Handlers
    const navigateBack = () => history.push(`/assessment-manager/${categoryId}`);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        cloneAssessment(assessmentId, targetAssessment.id)
            .then(navigateBack)
            .catch((error) => {
                errorHandler(error);
                setIsLoading(false);
            });
    };

    const isValid = useMemo(() => {
        //prettier-ignore
        return R.allPass([
            R.prop('assessmentId'),
            R.path(['targetAssessment', 'id']),
        ])({
            assessmentId,
            targetAssessment,
        });
    }, [assessmentId, targetAssessment]);
    //#endregion

    if (isLoading) {
        return (
            <FlexCenter>
                <TherigyLoadingSpinner />
            </FlexCenter>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <ControlLabel htmlFor="movingAssessment">Cloning assessment</ControlLabel>
                                <FormControl
                                    id="movingAssessment"
                                    type="text"
                                    autoComplete="off"
                                    disabled
                                    value={sourceAssessment?.name}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={12}>
                            <FormGroup>
                                <ControlLabel htmlFor="therapeutic-categories" className="field-required">
                                    Select therapeutic category
                                </ControlLabel>
                                <Select
                                    classNamePrefix="react-select"
                                    options={categories}
                                    getOptionValue={R.prop('id')}
                                    getOptionLabel={R.prop('name')}
                                    value={category}
                                    inputId="therapeutic-categories"
                                    isClearable
                                    placeholder="Select therapeutic category"
                                    onChange={(opt) => {
                                        setCategory(opt);
                                        setTargetAssessment(null);
                                    }}
                                    isLoading={isLoading}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={12}>
                            {category ? (
                                <FormGroup>
                                    <ControlLabel htmlFor="target-assessment" className="field-required">
                                        Select target assessment
                                    </ControlLabel>
                                    <Select
                                        classNamePrefix="react-select"
                                        options={assessments}
                                        getOptionValue={R.prop('id')}
                                        getOptionLabel={(option) => {
                                            const companyPath = ['company', 'name'];

                                            if (
                                                R.anyPass([
                                                    R.pathEq(THERIGY_CO_NAME, companyPath),
                                                    R.pathSatisfies(isNullOrEmpty, companyPath),
                                                ])(option)
                                            ) {
                                                return R.prop('name', option);
                                            }

                                            return `${R.prop('name', option)} (${R.path(companyPath, option)})`;
                                        }}
                                        value={targetAssessment}
                                        inputId="target-assessment"
                                        isClearable
                                        placeholder="Select target assessment"
                                        onChange={setTargetAssessment}
                                        isLoading={isAssessmentsLoading}
                                    />
                                </FormGroup>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={12}>
                            <div className="form-button-group">
                                <Button className="btn" type="button" onClick={navigateBack}>
                                    Cancel
                                </Button>
                                <Button type="submit" className="btn btn-primary" disabled={!isValid}>
                                    Clone Assessment
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </form>
    );
}

export default CloneAssessmentPage;
