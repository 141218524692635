import { useQuery } from 'react-query';

import { getPhysician } from 'App/services/PhysicianService';

function useFetchPhysician(physicianId) {
    return useQuery(['physician', physicianId], () => getPhysician(physicianId), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchPhysician;
