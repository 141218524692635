import React from 'react';
import PropTypes from 'prop-types';
import useCompanySettings from '@/app/hooks/useCompanySettings';
import TherigyLoadingSpinner from '@/app/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import FlexCenter from 'App/components/styles/FlexCenter';

const propTypes = {
    content: PropTypes.string,
};

const defaultProps = {
    content: '',
};

function template(branchName, content, phoneNumber) {
    return `${branchName}: ${content} Please call the pharmacy at ${phoneNumber}. 
    Unmonitored number, 
    please do not respond via text. Text UNSUBSCRIBE to unsubscribe; HELP for help.
    `;
}

const MessagePreview = ({ content }) => {
    const { data, isLoading } = useCompanySettings(['messagingBranchName', 'messagingPhone']);

    return isLoading ? (
        <FlexCenter>
            <TherigyLoadingSpinner />
        </FlexCenter>
    ) : (
        <div>{template(data[0], content, data[1])}</div>
    );
};

MessagePreview.propTypes = propTypes;
MessagePreview.defaultProps = defaultProps;

export default MessagePreview;
