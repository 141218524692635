import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ActionCell from '../styles/ActionCell';
import Table from '../Table/Table';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.array,
    isLoading: PropTypes.bool,
};

const defaultProps = {
    data: [],
    isLoading: false,
};

const tableStateReducer = (state, action, prevState) => {
    const initialSort = [{ id: 'name', desc: false }];

    const { type } = action;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-case
    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
    }
    return state;
};

function TherapyTable(props) {
    //#region State
    const { path } = useRouteMatch();
    //#endregion

    //#region RunTime Calculations
    const columns = useMemo(() => {
        return [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Action',
                id: 'action',
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row }) => {
                    return (
                        <ActionCell>
                            {/* TODO: Fix this the next time the file is edited. */}
                            {/* eslint-disable-next-line react/prop-types */}
                            <Link to={`${path}/${row.original.id}`}>Edit</Link>
                        </ActionCell>
                    );
                },
            },
        ];
    }, [path]);
    //#endregion

    return <Table {...props} columns={columns} stateReducer={tableStateReducer} />;
}

TherapyTable.propTypes = propTypes;
TherapyTable.defaultProps = defaultProps;

export default TherapyTable;
