import { getData } from 'App/utils';
import axios from 'axios';
import URLBuilder from './UrlBuilderService';

function search(query, limit) {
    const url = URLBuilder.build('searchIcd10', {
        therapy: '',
        search: '',
        'auto-complete': '',
        icd10: '',
    });
    return axios
        .get(url, {
            params: {
                query,
                ...(limit ? { limit } : {}),
            },
        })
        .then(getData);
}

export { search };
