import React, { useMemo } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import SelectField from 'Lib/form/SelectField';
import useGetCustomMenuQuery from 'App/hooks/useGetCustomMenuQuery';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    highRiskvalue: PropTypes.shape({
        id: PropTypes.number,
    }),
};

const defaultProps = {
    highRiskvalue: [],
};

function HighRiskReasons({ onChange, highRiskvalue }) {
    const { isLoading, data } = useGetCustomMenuQuery('high_risk_reason');
    const labels = useMemo(() => {
        return data
            ? R.compose(
                  R.map(
                      R.applySpec({
                          id: R.prop('value'),
                          name: R.prop('value'),
                      })
                  )
              )(data)
            : [];
    }, [data]);

    return (
        <SelectField
            fieldName="searchParams.highRiskReasonvalue"
            options={labels}
            label="High-Risk Reasons"
            getOptionValue={R.prop('id')}
            getOptionLabel={R.prop('name')}
            isClearable
            onChange={(option) => {
                onChange(option);
            }}
            isDisabled={highRiskvalue?.id === 0}
            isMulti
            isLoading={isLoading}
        />
    );
}

HighRiskReasons.propTypes = propTypes;
HighRiskReasons.defaultProps = defaultProps;

export default HighRiskReasons;
