import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';

function useFetchMultiplePatientMedicationRxNumber(patientId, medicationIds) {
    return useQuery(
        ['patient', patientId, 'medicationRxNumbers', medicationIds],
        () =>
            Promise.all(
                medicationIds.map((medicationId) =>
                    PESService.getPatientMedicationRxNumber(patientId, medicationId, true)
                )
            ),
        {
            onError: errorHandler,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchMultiplePatientMedicationRxNumber;
