import useFetchDisabledPatientFields from 'App/hooks/useFetchDisabledPatientFields';
import useFetchRequiredPatientFields from 'App/hooks/useFetchRequiredPatientFields';

const propTypes = {};

const defaultProps = {};

function RequiredDisabledPatientFields() {
    const { data: requiredPatientFields } = useFetchRequiredPatientFields();
    const { data: disabledPatientFields } = useFetchDisabledPatientFields();

    return {
        requiredPatientFieldsArr: Object.values(requiredPatientFields || {}).map((item) => item.field),
        disabledPatientFieldsArr: Object.values(disabledPatientFields || {}).map((item) => item.field),
    };
}
RequiredDisabledPatientFields.propTypes = propTypes;
RequiredDisabledPatientFields.defaultProps = defaultProps;

export default RequiredDisabledPatientFields;
