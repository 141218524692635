import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import LaddaButton from '@/app/components/LaddaButton';

const propTypes = {
    children: PropTypes.node.isRequired,
    ignoreDirty: PropTypes.bool,
};

const defaultProps = {
    ignoreDirty: false,
};

function SubmitButton({ children, ignoreDirty, ...props }) {
    const formik = useFormikContext();

    const isDisabled = R.ifElse(
        R.always(ignoreDirty || formik.dirty),
        R.anyPass([R.always(!formik.isValid), R.always(formik.isSubmitting)]),
        R.T
    );
    return (
        <LaddaButton {...props} type="submit" bsStyle="primary" disabled={isDisabled()} isLoading={formik.isSubmitting}>
            {children}
        </LaddaButton>
    );
}

SubmitButton.propTypes = propTypes;
SubmitButton.defaultProps = defaultProps;

export default SubmitButton;
