import React from 'react';

import { ErrorForbidden, NotFound } from 'App/components/errors';
import Main from '../../components/styles/Main/Main';
import ProtocolManagerRouter from './ProtocolManagerRouter';
import { useProtocolAccess } from 'App/utils/hooks/useIsPermitted';

const ProtocolManagerPage = () => {
    const { hasProtocolBuilder, hasEditProtocol } = useProtocolAccess();

    if (!hasEditProtocol) {
        return <ErrorForbidden permissionName="Edit Company Protocol Settings" />;
    }

    if (!hasProtocolBuilder) {
        return <NotFound />;
    }

    return (
        <Main>
            <ProtocolManagerRouter />
        </Main>
    );
};

export default ProtocolManagerPage;
