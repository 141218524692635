/** @ngInject */
function PatientStatusService($http, urlBuilderService) {
    const promises = {};
    const service = {
        getMany,
    };

    function getMany() {
        if (promises.getMany) {
            return promises.getMany;
        }

        const url = urlBuilderService.build('getMany', {
            resource: '',
            menu: '',
            patientstatus: '',
            options: '',
        });

        promises.getMany = $http
            .get(url)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err.data;
            })
            .finally(() => {
                promises.getMany = null;
            });

        return promises.getMany;
    }

    return service;
}

export default PatientStatusService;
