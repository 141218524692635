import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'App/components/Table';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import propOrDash from 'App/utils/propOrDash';
import dateOrDashUtc from 'App/utils/dateOrDashUtc';
import getActivityCell from './getActivityCell';
import useFetchPatientMessages from 'App/hooks/useFetchPatientMessages';
import * as R from 'ramda';

dayjs.extend(dayjsUtc);

const propTypes = {
    patientId: PropTypes.string.isRequired,
    divisionId: PropTypes.string.isRequired,
    showMessageViewModal: PropTypes.func.isRequired,
};

const defaultProps = {};

function PatientMessagingHistoryTable({ patientId, divisionId, showMessageViewModal }) {
    const { isLoading, data } = useFetchPatientMessages(patientId);

    const columns = useMemo(
        () => [
            {
                Header: 'Sent',
                accessor: dateOrDashUtc(['sentOn']),
                disableFilters: true,
            },
            {
                Header: 'Mobile',
                accessor: propOrDash('mobilePhone'),
                disableFilters: true,
            },
            {
                Header: 'Answers Received',
                /**
                 * This dates come in with "N/A" instead of null
                 */
                accessor: R.compose(
                    dateOrDashUtc(['answeredOn']),
                    R.over(R.lensProp('answeredOn'), R.ifElse(R.equals('N/A'), R.always(null), R.identity))
                ),
                disableFilters: true,
            },
            {
                Header: 'Activity',
                accessor: propOrDash('name'),
                disableFilters: true,
                Cell: getActivityCell(patientId, divisionId, showMessageViewModal),
            },
            {
                Header: 'Activity Due Date',
                /**
                 * This dates come in with "N/A" instead of null
                 */
                accessor: R.compose(
                    dateOrDashUtc(['dateDue']),
                    R.over(R.lensProp('dateDue'), R.ifElse(R.equals('N/A'), R.always(null), R.identity))
                ),
                disableFilters: true,
            },
        ],
        [patientId, divisionId, showMessageViewModal]
    );

    return <Table columns={columns} data={data} placeholderText="There is no message history." isLoading={isLoading} />;
}

PatientMessagingHistoryTable.propTypes = propTypes;
PatientMessagingHistoryTable.defaultProps = defaultProps;

export default PatientMessagingHistoryTable;
