import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';

function useFetchPatientLabels(patientId) {
    return useQuery(
        ['patient', patientId, 'labels'],
        () => (!patientId ? null : PESService.getPatientLabels(patientId)),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchPatientLabels;
