import { capitalize, first, forEach, get, mapValues, omit, omitBy } from 'lodash';
import * as R from 'ramda';

import convertKeysToSnakeCase from './keyConversion/convertKeysToSnakeCase';
import convertKeysToCapitalCase from './keyConversion/convertCapitalCase';
import isExpired from 'App/utils/isExpired';
import { formatUtcDate, isoDateOnlyFormat } from 'App/services/DateService';

/**
 * Converts medications payload from new serverless endpoint into
 * old PHP endpoint format.  This function will be removed once
 * angular page is converted to new React page
 * @param {Object} patientMedications Payload from Serverless function
 * @param {string} type Legacy keyword 'all' passed in if page want's all user's medications including ended
 * medications.
 * @returns
 */
export function convertMedicationsPayload(patientMedications, type) {
    const newPatientMedicationsPayload = mapValues(patientMedications, (patientMedication) => {
        const newPatientMedication = {
            ...omit(patientMedication, ['therapy', 'status', 'medication']),
            collection: patientMedication.therapy,
            status: first(patientMedication.status) || {
                id: null,
                patient_medication_id: null,
                started_by: null,
                start: null,
                ended_by: null,
                end: null,
                end_reason: null,
            },
            medication: { ...omit(patientMedication.medication, 'displayName') },
        };

        newPatientMedication.ended = R.allPass([
            R.prop('status'),
            R.path(['status', 'end']),
            R.compose(isExpired, R.path(['status', 'end'])),
        ])(newPatientMedication);

        if (patientMedication.medication && patientMedication.medication.name) {
            newPatientMedication.medication.stm_name = patientMedication.medication.name;
            newPatientMedication.medication.name = patientMedication.medication.displayName;
        }

        return newPatientMedication;
    });

    if (type === 'all') return convertKeysToSnakeCase(newPatientMedicationsPayload);

    return convertKeysToSnakeCase(
        omitBy(newPatientMedicationsPayload, (medication) => medication.status && medication.ended)
    );
}

export function convertMedicationRxPayload(medicationRxs) {
    const newMedicationRxPayload = mapValues(medicationRxs, (medicationRx) => {
        const physFirstName = get(medicationRx, 'physician.firstName', null);
        const physLastName = get(medicationRx, 'physician.lastName', null);
        return {
            ...omit(medicationRx, ['physician']),
            medicationId: medicationRx.medication.id,
            physician: {
                id: get(medicationRx, 'physician.id', null),
                physician_first_name: physFirstName,
                physician_full_name: physFirstName && physLastName ? `${physFirstName} ${physLastName}` : null,
                physician_last_name: physLastName,
            },
        };
    });

    return convertKeysToSnakeCase(newMedicationRxPayload);
}

export function convertPatientDetailsPayload(patientDetails) {
    const newPatientDetails = {
        ...omit(patientDetails, [
            'id',
            'insuranceIdNOTUSED',
            'isPdxpatient',
            'noKnownAllergies',
            'noKnownMedicalConditions',
            'mdAddress1',
            'mdAddress2',
            'otherAllergy',
            'humanId',
            'addressLine1',
            'addressLine2',
        ]),
        ID: patientDetails.id,
        InsuranceIdNotUsed: patientDetails.insuranceIdNOTUSED,
        Ispdxpatient: patientDetails.isPdxpatient,
        HighRisk: patientDetails.highRisk ? 1 : 0,
        NoKnownAllergies: !!+patientDetails.noKnownAllergies,
        NoKnownMedicalConditions: !!+patientDetails.noKnownMedicalConditions,
        OtherAllergy: patientDetails?.otherAllergy ? patientDetails.otherAllergy.split(',') : [],
        EcName: `${patientDetails.ecLastName} ${patientDetails.ecFirstName}`,
        HumanId: `${patientDetails.humanId}`,
        address_line_1: patientDetails.addressLine1,
        address_line_2: patientDetails.addressLine2,
        md_address_1: patientDetails.mdAddress1,
        md_address_2: patientDetails.mdAddress2,
        birthDate:
            patientDetails.birthDate && formatUtcDate({ date: patientDetails.birthDate, format: isoDateOnlyFormat }),
        dateTime: patientDetails.dateTime && formatUtcDate({ date: patientDetails.dateTime }),
        updatedOn: patientDetails.updatedOn && formatUtcDate({ date: patientDetails.updatedOn }),
        gender: capitalize(patientDetails.gender),
        welcomePacketReturned:
            patientDetails.welcomePacketReturned &&
            formatUtcDate({ date: patientDetails.welcomePacketReturned, format: isoDateOnlyFormat }),
        welcomePacketSent:
            patientDetails.welcomePacketSent &&
            formatUtcDate({ date: patientDetails.welcomePacketSent, format: isoDateOnlyFormat }),

        address: [
            patientDetails.addressLine1,
            patientDetails.addressLine2,
            patientDetails.city,
            patientDetails.state,
            patientDetails.zip,
            patientDetails.country,
        ]
            .join(' ')
            .trim(),
    };

    return convertKeysToSnakeCase(newPatientDetails);
}

export function convertPatientTherapyPayload(data) {
    const convertedData = convertKeysToSnakeCase(data);
    forEach(convertedData, (therapy) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        therapy.icd_9 = therapy.icd9;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        therapy.icd_10 = therapy.icd10;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        therapy.status.start =
            therapy.status.start && therapy.status.start !== '0000-00-00 00:00:00'
                ? new Date(therapy.status.start)
                : null;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        therapy.status.end =
            therapy.status.end && therapy.status.end !== '0000-00-00 00:00:00' ? new Date(therapy.status.end) : null;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        delete therapy.icd9;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        delete therapy.icd10;
    });
    return convertedData;
}

export function convertUnassignedMedicationsPayload(data) {
    // the assign-therapy-modal component that assigns TCs to unassigned medications
    // is expecting the secondary diagnosis field to be "Name" rather than "Text"
    // When that component is updated to React, it should use the API
    // contract supplied by the serverless endpoint instead

    if (data.diagnosis?.secondary?.length) {
        const secondaries = data.diagnosis.secondary;
        secondaries.forEach((diagnosis) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            diagnosis.name = diagnosis.text;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete diagnosis.text;
        });
    }
    return convertKeysToCapitalCase(data);
}

export function convertPatientPrescriptions(prescriptions) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-param-reassign
    prescriptions = convertKeysToSnakeCase(prescriptions);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const id in prescriptions) {
        const prescription = prescriptions[id];
        prescription.id += '';
        prescription.medication_id = get(prescription, 'medication.id', null);
        prescription.patient_medication_id = null;
        prescription.physician = {
            id: get(prescription, 'physician.id', null),
            physician_first_name: get(prescription, 'physician.first_name', ''),
            physician_full_name: `${get(prescription, 'physician.first_name', '')} ${get(
                prescription,
                'physician.last_name',
                ''
            )}`,
            physician_last_name: get(prescription, 'physician.last_name', ''),
        };
        prescription.status = get(prescription, 'prescription_data.data.status', null);
        delete prescription.prescription_data;
        delete prescription.medication;
    }
    return prescriptions;
}

/**
 * Converts the PatientService.getPrescriptions payload from new new Serverless endpoint to the old PHP endpoint.
 * The serverless function it converts is PESService.getPatientRx
 * @param {Object} patientMedications key/value object containing all medications and related prescriptions from
 * serverless endpoint
 * @returns {Array<Object>} original PHP endpoint payload that UI was originally consuming
 */
export function convertGetPatientRx(patientMedications) {
    const createObject = (patientMedication, prescription) => {
        const { end, start } = prescription;
        const { start: statusStart, end: statusEnd } = R.pathOr({}, ['status'])(patientMedication);
        return {
            collection_name: R.pathOr(null, ['therapy', 'name'])(patientMedication),
            end_reason: R.pathOr(null, ['endReason'])(prescription),
            end: end ? formatUtcDate({ date: end }) : null,
            group_id: R.pathOr(null, ['medication', 'groupId'])(patientMedication),
            id: R.pathOr(null, ['id'])(prescription),
            medication_id: R.pathOr(null, ['medication', 'id'])(patientMedication),
            name: R.pathOr(null, ['medication', 'name'])(patientMedication),
            patient_medication_id: R.pathOr(null, ['id'])(patientMedication),
            rx_number: R.pathOr(null, ['rxNumber'])(prescription),
            start: start ? formatUtcDate({ date: start }) : null,
            strength: R.pathOr(null, ['strength'])(prescription),
            uuid: R.pathOr(null, ['uuid'])(prescription),
            status_start: R.isNil(statusStart) ? null : formatUtcDate({ date: statusStart }),
            status_end: R.isNil(statusEnd) ? null : formatUtcDate({ date: statusEnd }),
        };
    };
    const rxs = [];

    Object.values(patientMedications).forEach((patientMedication) => {
        const prescriptions = R.pathOr([], ['rxs'])(patientMedication);

        if (R.isEmpty(prescriptions)) {
            rxs.push(createObject(patientMedication, {}));
        } else {
            prescriptions.forEach((prescription) => {
                rxs.push(createObject(patientMedication, prescription));
            });
        }
        return convertKeysToSnakeCase(rxs);
    });
    return rxs;
}
