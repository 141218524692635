import arm from '@stm/arm';
import * as R from 'ramda';
import { getData, stripAllHtml } from '../utils';

const { Risk } = arm;

/** @ngInject */
function AmsService(_, $http, $q, $window, __env, sessionStorageService, pako, moment, toastr) {
    const endpoint = `${__env.amsEndpoint}:${__env.amsPort}/ams/${__env.amsVersion}`;
    const specialQuestionNames = [
        'activity_date',
        'need_by_date',
        'therapy_start_date',
        'cost_savings',
        'assessment_status',
    ];
    let questionGroups = null;

    return {
        addActivityNote,
        attachChildren,
        formatQuestionGroups,
        formatQuestions,
        formatRapid3Questions,
        getActivity,
        getActivityQuestions,
        getActivityQuestionsById,
        getAllAssessmentQuestions,
        getAssessment,
        getAssessmentQuestionsById,
        getParentQuestions,
        getQuestionsById,
        getQuestionGroups,
        getResponses,
        getScores,
        generatePdf,
        getTherapyAssessments,
        questionGroups,
        saveEhrNote,
        saveResponse,
        saveStatus,
        specialQuestionNames,
        sortQuestions,
        sortSpecialQuestions,
        splitQuestions,
    };

    function _authorization() {
        return {
            headers: {
                Authorization: `Bearer ${sessionStorageService.getJwt()}`,
            },
        };
    }

    function addActivityNote(activityId, status, note) {
        const url = `${endpoint}/activity/${activityId}/status`;
        return $http
            .post(
                url,
                {
                    status: stripAllHtml(status),
                    notes: stripAllHtml(note),
                },
                _authorization()
            )
            .then((res) => {
                return res.data;
            });
    }

    function attachChildren(questions) {
        const childrenIds = [];
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax
        for (const id in questions) {
            if (Object.prototype.hasOwnProperty.call(questions, id)) {
                const question = questions[id];
                const children = _.filter(questions, ['parentQuestionId', id]);
                if (children.length) {
                    question.children = _.keyBy(children, 'questionId');
                    Array.prototype.push.apply(childrenIds, _.keys(question.children));
                    attachChildren(question.children);
                }
            }
        }

        return _.omit(questions, childrenIds);
    }

    function formatQuestionGroups(groupIds) {
        // TODO: Remove hack for group order
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = groupIds.length; i < len; i++) {
            if (!Object.prototype.hasOwnProperty.call(questionGroups, groupIds[i])) {
                questionGroups[groupIds[i]] = {
                    id: groupIds[i],
                    groupOrder: 0,
                };
            }
        }
        return questionGroups;
    }

    function formatQuestions(questions) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        questions = questions || {};
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        questions = _.values(angular.copy(questions));
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        questions = _.filter(questions, (q) => {
            return (q.attributes && !q.attributes.hidden) || q.response || q.response === 0;
        });
        return _.orderBy(questions, ['attributes.position'], ['asc']);
    }

    // TODO: Rapid 3 hack
    function formatRapid3Questions(questions) {
        // Functional status score questions
        if (questions['58b99cba867992c0f7']) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            questions['58b99cba867992c0f7'].type = 'rapid3_functional_score';
        }
        if (questions['58b99d7d20913df9a5']) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            questions['58b99d7d20913df9a5'].type = 'rapid3_functional_score';
        }
        if (questions['5a0aff955c004ddce0']) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            questions['5a0aff955c004ddce0'].type = 'rapid3_functional_score';
        }
        if (questions['5a0b0a8717f1c6ad04']) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            questions['5a0b0a8717f1c6ad04'].type = 'rapid3_functional_score';
        }
        if (questions['5a0b0de6e33b117541']) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            questions['5a0b0de6e33b117541'].type = 'rapid3_functional_score';
        }

        // Total score questions
        if (questions['58b99cbadc1588ce17']) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            questions['58b99cbadc1588ce17'].type = 'rapid3_total_score';
        }
        if (questions['58b99d7d87ae196bda']) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            questions['58b99d7d87ae196bda'].type = 'rapid3_total_score';
        }
        if (questions['5a0aff95812e72b349']) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            questions['5a0aff95812e72b349'].type = 'rapid3_total_score';
        }
        if (questions['5a0b0d3ea54c8e0ca1']) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            questions['5a0b0d3ea54c8e0ca1'].type = 'rapid3_total_score';
        }
        if (questions['5a0b0de63b732e5e46']) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            questions['5a0b0de63b732e5e46'].type = 'rapid3_total_score';
        }
    }

    function getActivity(patientId, activityId, includeAssessment) {
        const url = `${endpoint}/patient/${patientId}/activity/${activityId}`;
        const config = _authorization();
        config.params = {
            includeAssessment,
        };
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function sanitizeResponses(question) {
        if (R.is(String, question.response)) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            question.response = stripAllHtml(question.response);
        }
        return question;
    }

    function getActivityQuestions(activityId) {
        const url = `${endpoint}/activity/${activityId}/questions`;
        return $http.get(url, _authorization()).then(getData).then(R.map(sanitizeResponses));
    }

    function getActivityQuestionsById(activityId, questionIds) {
        const url = `${endpoint}/activity/${activityId}/questions`;
        return $http
            .post(
                url,
                {
                    questionIds,
                },
                _authorization()
            )
            .then((res) => {
                return res.data;
            });
    }

    function getAssessment(assessmentId) {
        const url = `${endpoint}/assessment/${assessmentId}`;
        return $http.get(url, _authorization()).then((res) => {
            return res.data;
        });
    }

    function getAssessmentQuestionsById(assessmentId, questionIds) {
        const url = `${endpoint}/assessment/${assessmentId}/questions`;
        return $http
            .post(
                url,
                {
                    questionIds,
                },
                _authorization()
            )
            .then((res) => {
                return res.data;
            });
    }

    function getAllAssessmentQuestions(assessmentId) {
        const config = _authorization();
        config.params = { allQuestions: 'true' };
        const url = `${endpoint}/assessment/${assessmentId}/questions`;
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getParentQuestions(assessmentId) {
        const url = `${endpoint}/assessment/${assessmentId}/questions`;
        return $http.get(url, _authorization()).then((res) => {
            return res.data;
        });
    }

    function getQuestionsById(assessmentId, questionIds) {
        const url = `${endpoint}/assessment/${assessmentId}/questions`;
        return $http
            .post(
                url,
                {
                    questionIds,
                },
                _authorization()
            )
            .then((res) => {
                return res.data;
            });
    }

    function getQuestionGroups() {
        if (questionGroups === null) {
            const url = `${endpoint}/questionGroups`;
            return $http.get(url, _authorization()).then((res) => {
                questionGroups = res.data;
                return questionGroups;
            });
        }
        return $q.when(questionGroups);
    }

    function getResponses(activityId, questionIds) {
        const url = `${endpoint}/activity/${activityId}/responses`;
        let p;
        if (questionIds && Array.isArray(questionIds) && questionIds.length > 0) {
            p = $http.post(
                url,
                {
                    questionIds,
                },
                _authorization()
            );
        } else {
            p = $http.get(url, _authorization());
        }
        return p.then((res) => {
            return res.data;
        });
    }

    function getScores(questions, scoring) {
        return Risk.calculate(questions, scoring);
    }

    function generatePdf(assessmentMarkup, assessmentType, assessmentName, activityId) {
        const url = `${endpoint}/pdf/generate`;
        const auth = _authorization();

        const compressed = pako.deflate(assessmentMarkup, { to: 'string' });
        const base64String = btoa(compressed);

        return $http
            .post(
                url,
                {
                    assessmentCompressed: base64String,
                    assessmentType,
                },
                {
                    responseType: 'arraybuffer',
                    headers: auth.headers,
                }
            )
            .then((res) => {
                // generate blob
                const blobData = new Blob([res.data], {
                    type: 'application/pdf',
                });
                // generate filename and download
                const date = moment().format('YYYY-MM-DD').toString();
                const filename = `${assessmentName.split(' ').join('-')}-${
                    activityId ? `${activityId}-${date}` : date
                }.pdf`;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blobData, filename);
                } else {
                    const blobUrl = $window.URL.createObjectURL(blobData);

                    const link = $window.document.createElement('a');
                    link.href = blobUrl;
                    link.setAttribute('download', filename);
                    $window.document.body.appendChild(link);
                    link.click();
                    // clean up
                    link.remove();
                    $window.URL.revokeObjectURL(blobUrl);
                }
            })
            .catch((error) => {
                toastr.warning('Failed to generate PDF');
                // newRelic exists in window object if in production.
                if ($window.newrelic) {
                    $window.newrelic.noticeError(error);
                }
            });
    }

    function getTherapyAssessments(categoryId, includeMobileStatus, isShowRetired) {
        const config = _authorization();
        if (includeMobileStatus === true) {
            config.params = { isMobileEnabled: true };
        }

        if (isShowRetired === true) {
            config.params = { ...config.params, isShowRetired };
        }
        const url = `${endpoint}/therapy/${categoryId}/assessments`;
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function saveEhrNote(activityId, message) {
        const url = `${endpoint}/activity/${activityId}/ehrEncounterNote`;
        return $http.post(url, message, _authorization()).then((res) => {
            return res.data;
        });
    }

    function saveResponse(activityId, questionId, response) {
        const url = `${endpoint}/activity/${activityId}/response/${questionId}`;
        const cleanedResponse = sanitizeResponses(response);
        const clientSubmissionTimestamp = moment().valueOf();
        const mergedResponse = R.mergeRight(cleanedResponse, { clientSubmissionTimestamp });
        return $http.put(url, mergedResponse, _authorization()).then((res) => {
            return res.data;
        });
    }

    function saveStatus(activityId, status, notes) {
        const url = `${endpoint}/activity/${activityId}/status`;
        return $http.patch(url, { status, notes }, _authorization()).then((res) => {
            return res.data;
        });
    }

    function sortQuestions(a, b) {
        // Sort special dates.
        if (specialQuestionNames.indexOf(a.name) !== -1 && specialQuestionNames.indexOf(b.name) !== -1) {
            if (a.name === 'activity_date' || b.name === 'activity_date') {
                return a.name === 'activity_date' ? -1 : 1;
            }
            return a.name === 'need_by_date' ? -1 : 1;
        }

        // Always move special fields to the top
        if (specialQuestionNames.indexOf(a.name) !== -1 && specialQuestionNames.indexOf(b.name) === -1) {
            return -1;
        }
        if (specialQuestionNames.indexOf(a.name) === -1 && specialQuestionNames.indexOf(b.name) !== -1) {
            return 1;
        }

        // Summary note always goes just under special dates.
        if (a.name === 'therapy_summary_note' || b.name === 'therapy_summary_note') {
            if (a.name === 'therapy_summary_note' && specialQuestionNames.indexOf(b.name) !== -1) {
                return 1;
            }
            if (specialQuestionNames.indexOf(a.name) !== -1 && b.name === 'therapy_summary_note') {
                return -1;
            }

            if (a.name === 'therapy_summary_note') {
                return -1;
            }
            return 1;
        }

        if (a.group === null && b.group !== null) {
            return -1;
        }
        if (b.group === null && a.group !== null) {
            return 1;
        }

        if (a.attributes.group_id === b.attributes.group_id) {
            if (a.attributes.position > b.attributes.position) {
                return 1;
            }
            if (a.attributes.position < b.attributes.position) {
                return -1;
            }
            return 0;
        }
        if (a.group.groupOrder > b.group.groupOrder) {
            return 1;
        }
        if (a.group.groupOrder < b.group.groupOrder) {
            return -1;
        }
        return 0;
    }

    function sortSpecialQuestions(questions, order) {
        const sorted = [];
        const len = order.length;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus,no-var,vars-on-top
        for (var i = 0; i < len; i++) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-loop-func
            const question = _.find(questions, (q) => {
                return q.name === order[i];
            });
            if (question) {
                sorted.push(question);
            }
        }
        const unsorted = _.remove(questions, (q) => {
            return !_.includes(sorted, q);
        });
        return _.concat(sorted, unsorted);
    }

    function splitQuestions(questions) {
        const res = {};

        // Break out special questions
        res.specialQuestions = _.remove(questions, (q) => {
            return specialQuestionNames.indexOf(q.name) !== -1;
        });

        // Sort special questions
        res.specialQuestions = sortSpecialQuestions(res.specialQuestions, ['activity_date', 'need_by_date']);

        // Break out therapySummaryNote
        res.therapySummaryNote = _.remove(questions, (q) => {
            return q.name === 'therapy_summary_note';
        });

        // Break out groups
        res.groupedQuestions = _.remove(questions, (q) => {
            return q.attributes && q.attributes.group_id;
        });
        res.groupedQuestions = _.groupBy(res.groupedQuestions, 'attributes.group_id');

        res.questions = _.remove(questions, (q) => {
            return q.parentQuestionId === null;
        });

        return res;
    }
}

export default AmsService;
