import graphClient from '../common/graphClient';
import { gql } from 'graphql-request';

function getByMedicationIds(medicationIds) {
    return graphClient.request(
        gql`
            query medicationSettings($medicationIds: [String!]!) {
                medicationSettings(medicationIds: $medicationIds) {
                    uuid
                    name
                    medicationId
                    therapyId
                    enroll
                    manage
                }
            }
        `,
        {
            medicationIds,
        }
    );
}

function getByTherapy(therapyId) {
    return graphClient.request(
        gql`
            query therapyMedicationSettings($therapyId: String!) {
                therapyMedicationSettings(therapyId: $therapyId) {
                    uuid
                    name
                    medicationId
                    therapyId
                    enroll
                    manage
                }
            }
        `,
        {
            therapyId,
        }
    );
}

function patchSettings(settings) {
    return graphClient.request(
        gql`
            mutation editMedicationSettings($settings: [MedicationSettingInput]) {
                editMedicationSettings(settings: $settings) {
                    uuid
                    name
                    medicationId
                    therapyId
                    enroll
                    manage
                }
            }
        `,
        {
            settings,
        }
    );
}

export { getByMedicationIds, getByTherapy, patchSettings };
