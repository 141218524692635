import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell';
import { Viewer } from '@toast-ui/react-editor';
import { sortBy, startCase } from 'lodash';
import qs from 'qs';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Logo from 'App/components/Header/Logo';
import FlexCenter from 'App/components/styles/FlexCenter';
import Main from 'App/components/styles/Main/Main';
import PageWrapper from 'App/components/styles/PageWrapper/PageWrapper';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import CMSService from 'App/services/CMSService';
import hasLength from 'App/utils/hasLength';
import useFetchContentMonograph from './hooks/useFetchContentMonograph';

const ContentMonographDisplay = () => {
    const { label } = useParams();
    const { search } = useLocation();
    const parsedSearchQueries = qs.parse(search, { ignoreQueryPrefix: true });
    const { labels } = parsedSearchQueries;
    const { data, isLoading } = useFetchContentMonograph(label, { labels });

    if (isLoading) {
        return (
            <Main>
                <PageWrapper>
                    <FlexCenter>
                        <TherigyLoadingSpinner />
                    </FlexCenter>
                </PageWrapper>
            </Main>
        );
    }

    const monographs = Object.values(data);

    return (
        <div className="content-monograph-display">
            {hasLength(monographs) ? (
                monographs.map((cg) => (
                    <React.Fragment key={cg.id}>
                        <div className="content-monograph-header">
                            <div id={`content-modal-group-name-${cg.label}`} className="content-monograph-group-name">
                                {startCase(cg.name)}
                            </div>
                            <Logo />
                        </div>
                        <div className="content-monograph-content">
                            {sortBy(cg.contentVersions, (cv) => CMSService.labelOrder[cg.type].indexOf(cv.label)).map(
                                (cv) => {
                                    if (!cv.content) {
                                        return null;
                                    }
                                    return (
                                        <div key={cv.id} id={`content-modal-content-${cg.label}-${cv.label}`}>
                                            <Viewer
                                                initialValue={cv.content}
                                                usageStatistics={false}
                                                plugins={[tableMergedCell]}
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </React.Fragment>
                ))
            ) : (
                <>
                    <div className="content-monograph-header">
                        <Logo />
                    </div>
                    <Main>
                        <PageWrapper>
                            <p>No monograph data found.</p>
                        </PageWrapper>
                    </Main>
                </>
            )}
        </div>
    );
};

export default ContentMonographDisplay;
