import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Col, Row } from 'react-bootstrap';
import {
    PatientSection,
    bindTherapyAndMedications,
    getSectionVisibility,
    toggleSectionVisibility,
} from '../../PatientProfile.utils';
import AccordionWrapper from 'App/components/AccordionWrapper/AccordionWrapper';
import { TherapyHeaders } from './components/TherapyHeaders';
import TherapyMedications from './components/TherapyMedications';

import './TherapeuticCategories.scss';
import { patientTherapyPropType } from 'Lib/types';
import medicationsPropType from 'Lib/types/medication';

const propTypes = {
    therapies: PropTypes.arrayOf(patientTherapyPropType).isRequired,
    medications: medicationsPropType.isRequired,
};

const defaultProps = {};

const TherapeuticCategories = ({ therapies, medications }) => {
    const [showEndedMedication, setShowEndedMedication] = useState(false);
    const formattedTherapies = useMemo(
        () => bindTherapyAndMedications(therapies, medications),
        [therapies, medications]
    );

    return (
        <AccordionWrapper
            title="Therapeutic Categories & Medications"
            initialVisible={getSectionVisibility(PatientSection.therapies)}
            ActionElement={
                <Checkbox
                    checked={showEndedMedication}
                    onChange={() => {
                        setShowEndedMedication(!showEndedMedication);
                    }}
                >
                    Show Ended Medications
                </Checkbox>
            }
            onClick={() => toggleSectionVisibility(PatientSection.therapies)}
        >
            <Row id="therapy-section">
                <Col md={12}>
                    {formattedTherapies.map((therapy) => (
                        <div
                            key={therapy?.id}
                            className={`therapy-container ${
                                therapy.medications.length < 1 ? 'therapy-no-medications' : ''
                            }`}
                        >
                            <TherapyHeaders therapy={therapy} />
                            {therapy?.medications &&
                                therapy?.medications.map((patientMed) =>
                                    (patientMed?.ended && showEndedMedication) || !patientMed?.ended ? (
                                        <TherapyMedications
                                            key={patientMed?.id}
                                            patientMed={patientMed}
                                            showEndedMedication={showEndedMedication}
                                        />
                                    ) : null
                                )}
                        </div>
                    ))}
                </Col>
            </Row>
        </AccordionWrapper>
    );
};

TherapeuticCategories.propTypes = propTypes;
TherapeuticCategories.defaultProps = defaultProps;

export default TherapeuticCategories;
