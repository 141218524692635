import React from 'react';
import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted';
import mountReactComponent from 'App/utils/mountReactComponent';
import PESService from 'App/services/PESService';
import FillNumberHistory from 'App/components/PatientPrescription/FillNumberHistory';

/** @ngInject */
function MedicationHistoryModalController($q, $uibModal, _, medicationsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.addMedicationNote = addMedicationNote;
    ctrl.addPrescriptionNote = addPrescriptionNote;
    ctrl.viewMedicationNotes = viewMedicationNotes;
    ctrl.viewPrescriptionNotes = viewPrescriptionNotes;
    ctrl.openViewNotesModal = openViewNotesModal;
    ctrl.openAddNoteModal = openAddNoteModal;
    ctrl.cancelForm = cancelForm;
    ctrl.hasNewRxFillNumberEnabled = isPermitted(PERMISSION_TYPES.ENABLE_RX_FILL_NUMBERS, ctrl.user);
    ctrl.patientPrescriptions = [];
    ctrl.isNewFillLoading = false;

    /**
     * Sets values in resolve to properties.
     * @param changes
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        ctrl.history = null;
        ctrl.rxHistory = null;

        if (changes.resolve) {
            ctrl.loading = true;

            if (ctrl.resolve.patientId) {
                ctrl.patientId = angular.copy(ctrl.resolve.patientId);
            }
            if (ctrl.resolve.medication) {
                ctrl.medication = angular.copy(ctrl.resolve.medication);
            }
            if (ctrl.medication && ctrl.patientId) {
                ctrl.isNewFillLoading = true;
                PESService.getPatientMedicationRxNumber(ctrl.patientId, ctrl.medication.id, true).then((rxRes) => {
                    ctrl.patientPrescriptions = rxRes;
                    ctrl.isNewFillLoading = false;
                    mountIt();
                });
            }

            return $q
                .all({
                    history: medicationsService.getHistory(ctrl.patientId, ctrl.medication.id),
                    rxHistory: medicationsService.getRxHistory(ctrl.patientId, ctrl.medication.id),
                })
                .then((res) => {
                    ctrl.history = _.values(res.history);
                    ctrl.rxHistory = _.orderBy(_.values(res.rxHistory), ['end', 'start'], ['desc', 'asc']);
                    return res;
                })
                .finally(() => {
                    ctrl.loading = false;
                });
        }
    }

    /**
     * Mount react
     */
    mountIt();

    function mountIt() {
        mountReactComponent(
            '#fill-number-history',
            <FillNumberHistory patientPrescriptions={ctrl.patientPrescriptions} />
        );
    }

    /**
     * Closes the prescription history modal.
     */
    function cancelForm() {
        ctrl.dismiss({ $value: 'cancel' });
    }

    /**
     * Opens the modal to view rx notes.
     * @param patientId
     * @param prescriptionId
     * @param medicationId
     */
    function viewMedicationNotes(patientId, statusId, medicationId) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'medicationNotes',
            size: 'lg',
            resolve: {
                patientId() {
                    return patientId;
                },
                medicationId() {
                    return medicationId;
                },
                statusId() {
                    return statusId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    function addMedicationNote(patientId, statusId, medicationId) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'medicationAddNote',
            size: 'md',
            resolve: {
                medicationId() {
                    return medicationId;
                },
                patientId() {
                    return patientId;
                },
                statusId() {
                    return statusId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    /**
     * Opens the modal to view rx notes.
     * @param patientId
     * @param prescriptionId
     * @param medicationId
     */
    function viewPrescriptionNotes(patientId, prescriptionId, medicationId) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'prescriptionNotes',
            size: 'lg',
            resolve: {
                patientId() {
                    return patientId;
                },
                medicationId() {
                    return medicationId;
                },
                prescriptionId() {
                    return prescriptionId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    /**
     * Opens the modal to add an rx note.
     * @param patientId
     * @param prescription
     */
    function addPrescriptionNote(patientId, prescription) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'prescriptionAddNote',
            size: 'md',
            resolve: {
                prescription() {
                    return prescription;
                },
                patientId() {
                    return patientId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    /**
     * Opens the modal to view rx notes.
     * @param patientId
     * @param prescriptionId
     * @param medicationId
     */
    function openViewNotesModal(patientId, statusId, medicationId) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'medicationNotes',
            size: 'lg',
            resolve: {
                patientId() {
                    return patientId;
                },
                medicationId() {
                    return medicationId;
                },
                statusId() {
                    return statusId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    /**
     * Opens the modal to add an rx note.
     * @param patientId
     * @param prescription
     */
    function openAddNoteModal(patientId, statusId, medicationId) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'medicationAddNote',
            size: 'md',
            resolve: {
                statusId() {
                    return statusId;
                },
                patientId() {
                    return patientId;
                },
                medicationId() {
                    return medicationId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }
}

export default MedicationHistoryModalController;
