import * as R from 'ramda';
import axios from 'axios';
import { getData } from 'App/utils';
import { isProd, isQA, isUAT, isStaging } from 'App/components/config/config';

function getLatestVersion() {
    const path = isProd || isQA || isUAT || isStaging ? '/deploy.json' : '/stm/deploy.json';
    return axios
        .get(path, {
            headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: 0,
            },
        })
        .then(getData)
        .then(R.prop('commit_hash'));
}

export { getLatestVersion };
