/** @ngInject */
function AssessmentQuestionRapid3TotalScoreController(_, $sce, $scope, $timeout, amsService, moment) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    $scope.$on('updateRapid3TotalScore', updateRapid3TotalScore);

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
        }

        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);
            ctrl.question.id = ctrl.question.officialQuestionId
                ? ctrl.question.officialQuestionId
                : ctrl.question.questionId;
        }

        if (ctrl.fullyScriptedMode !== undefined && ctrl.question) {
            setQuestionText(ctrl.question);
        }

        if (ctrl.question && ctrl.activity) {
            $timeout(() => {
                angular.element(document.querySelector('#rapid3-score')).html(ctrl.question.response);
                angular.element(document.querySelector('#rapid3-score_compact')).html(ctrl.question.response);
                setStratification(ctrl.question.response);
            }, 1000);
        }
    }

    function setQuestionText(question) {
        if (question) {
            const compactQuestion = question.compactQuestion || question.question;
            ctrl.question.questionDisplay = $sce.trustAsHtml(
                ctrl.fullyScriptedMode ? question.question : compactQuestion
            );
            ctrl.question = angular.copy(ctrl.question);
        }
    }

    function setStratification(score) {
        let stratificationText = null;
        let followUp = null;
        if (score <= 3) {
            followUp = 180;
            stratificationText = 'Remission';
        } else if (score >= 3.1 && score <= 6) {
            followUp = 180;
            stratificationText = 'Low Activity';
        } else if (score >= 6.1 && score <= 12) {
            followUp = 30;
            stratificationText = 'Moderate Activity';
        } else if (score > 12) {
            followUp = 30;
            stratificationText = 'High Activity';
        }

        let followUpDate = new Date();

        if (
            ctrl.activity.status === 1 &&
            ctrl.activity.completedOn !== '0000-00-00 00:00:00' &&
            ctrl.activity.completedOn !== null
        ) {
            followUpDate = ctrl.activity.completedOn;
        }

        const followUpDateText = moment(followUpDate, 'YYYY/MM/DD').add(followUp, 'days').format('YYYY-MM-DD');

        angular.element(document.querySelector('#rapid3-stratification')).html(stratificationText);
        angular.element(document.querySelector('#rapid3-stratification_compact')).html(stratificationText);
        angular.element(document.querySelector('#rapid3-follow-up-date')).html(followUpDateText);
        angular.element(document.querySelector('#rapid3-follow-up-date_compact')).html(followUpDateText);
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function updateRapid3TotalScore(event, totalScore) {
        if (_.isNumber(totalScore.value)) {
            ctrl.question.response = totalScore.value;
            setStratification(totalScore.value);

            angular.element(document.querySelector('#rapid3-stratification')).html(totalScore.stratification);
            angular.element(document.querySelector('#rapid3-stratification_compact')).html(totalScore.stratification);
            angular.element(document.querySelector('#rapid3-score')).html(totalScore.value);
            angular.element(document.querySelector('#rapid3-score_compact')).html(totalScore.value);
            angular
                .element(document.querySelector('#rapid3-follow-up-date'))
                .html(moment(totalScore.followUpDate).format('YYYY/MM/DD'));
            angular
                .element(document.querySelector('#rapid3-follow-up-date_compact'))
                .html(moment(totalScore.followUpDate).format('YYYY/MM/DD'));
            return amsService.saveResponse(ctrl.activity.id, ctrl.question.id, {
                response: totalScore.value,
            });
        }
    }
}

export default AssessmentQuestionRapid3TotalScoreController;
