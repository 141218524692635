/** @ngInject */
function PatientViewAttachmentsController(
    _,
    __env,
    $http,
    $httpParamSerializerJQLike,
    $scope,
    $timeout,
    $window,
    CSRF_ID_VALUE,
    CSRF_ID_NAME
) {
    const ctrl = this;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.cdnURL = __env.cdnURL;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.cancelModal = function () {
        ctrl.dismiss({ $value: 'Upload cancelled' });
    };
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    ctrl.closeModal = function (data) {
        ctrl.close({ $value: data });
    };
    ctrl.deleteFiles = deleteFiles;
    ctrl.hideUploadFile = hideUploadFile;
    ctrl.optionToggled = optionToggled;
    ctrl.toggleAll = toggleAll;
    ctrl.uploadFile = uploadFile;

    // File handler
    $scope.$on('onFileSelect', (event, args) => {
        // Validate the file and add it to the scope of this controller
        $scope.$apply(() => {
            ctrl.file = undefined;
            const { file } = args;

            // File validation
            if (file) {
                ctrl.uploadFileForm.file.$setValidity('required', true);

                if (file.size <= ctrl.uploadLimitInt) {
                    ctrl.uploadFileForm.file.$setValidity('maxSize', true);
                } else {
                    ctrl.errorFileSize = file.size;
                    ctrl.uploadFileForm.file.$setValidity('maxSize', false);
                }
            } else {
                ctrl.uploadFileForm.file.$setValidity('required', false);
            }

            ctrl.file = args.file;
        });
    });

    function $onChanges() {
        if (ctrl.resolve && ctrl.resolve.modalData) {
            ctrl.files = ctrl.resolve.modalData.files;
            ctrl.notes = ctrl.resolve.modalData.notes;
            ctrl.numberOfUploads = ctrl.resolve.modalData.numberOfUploads;
            ctrl.patientId = ctrl.resolve.modalData.patientId;
            ctrl.uploadLimit = ctrl.resolve.modalData.uploadLimit;
            ctrl.uploadLimitInt = ctrl.resolve.modalData.uploadLimitInt;
            if (ctrl.notes.length) {
                ctrl.subject = ctrl.notes[0].parent_note_id;
            }
        }
    }

    function $onInit() {
        ctrl.disableDelete = true;
        ctrl.isAllSelected = false;
        ctrl.filter = 'orig_file_name';
    }

    function deleteFiles() {
        const targets = [];
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = ctrl.files.length; i < len; i++) {
            if (ctrl.files[i].selected) {
                targets.push(ctrl.files[i].id);
            }
        }

        return $http
            .post(
                '/stm/patient_activity.php',
                $httpParamSerializerJQLike({
                    delete: true,
                    targets,
                }),
                {
                    params: { patientId: ctrl.patientId },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                }
            )
            .then((res) => {
                _.remove(ctrl.files, (f) => {
                    return f.selected;
                });
                ctrl.numberOfUploads = ctrl.files.length;
                return res.data;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function hideUploadFile() {
        ctrl.uploadFileForm.$setPristine();
        ctrl.file = null;
        if (ctrl.notes.length) {
            ctrl.subject = ctrl.notes[0].parent_note_id;
        }
        ctrl.description = null;
        ctrl.showUploadFile = false;
    }

    function optionToggled() {
        ctrl.isAllSelected = ctrl.files.every((file) => {
            if (file.selected) {
                ctrl.disableDelete = false;
            }
            return file.selected;
        });

        const selected = _.filter(ctrl.files, 'selected');
        ctrl.disableDelete = selected.length === 0;
    }

    function toggleAll() {
        const toggleStatus = ctrl.isAllSelected;
        ctrl.disableDelete = !toggleStatus;
        angular.forEach(ctrl.files, (file) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            file.selected = toggleStatus;
        });
    }

    function uploadFile() {
        ctrl.errorMsg = null;
        ctrl.uploading = true;

        const url = `/stm/patient_activity.php?submit=true&patient_id=${ctrl.patientId}`;
        return $http({
            method: 'POST',
            url,
            headers: { 'Content-Type': undefined }, // Let the browser determine the content-type

            // Transform the request to allow us to post the file and the json data
            transformRequest(data) {
                // eslint-disable-next-line
                var formData = new FormData();
                formData.append('parent_note_id', data.parent_note_id);
                formData.append('form_file_description', data.form_file_description);
                formData.append('file', ctrl.file);
                return formData;
            },
            data: {
                parent_note_id: ctrl.subject,
                form_file_description: ctrl.description,
                files: ctrl.file,
            },
        })
            .then(() => {
                return $http.get(`/stm/patient_activity.php?angular=true&patient_id=${ctrl.patientId}`);
            })
            .then((res) => {
                ctrl.files = _.values(res.data.PATIENT_FILES);
                ctrl.numberOfUploads = res.data.NUMBER_OF_UPLOADS;
                ctrl.hideUploadFile();
                return res;
            })
            .catch((err) => {
                if (err.status > 0) {
                    if (err.data && err.data.message) {
                        ctrl.errorMsg = `${err.status}: ${err.data.message}`;
                    } else {
                        ctrl.errorMsg = `${err.status}: ${err.data}`;
                    }
                }
            })
            .finally(() => {
                ctrl.uploading = false;
            });
    }
}

export default PatientViewAttachmentsController;
