/** @ngInject */
function AssessmentQuestionMessageController($scope) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doShowContent = doShowContent;

    function $onChanges(changes) {
        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
            setQuestionText(ctrl.question);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);
            ctrl.question.id = ctrl.question.officialQuestionId
                ? ctrl.question.officialQuestionId
                : ctrl.question.questionId;
            setQuestionText(ctrl.question);
        }
    }

    function setQuestionText(question) {
        if (question) {
            const compactQuestion = question.compactQuestion || question.question;
            ctrl.questionDisplay = ctrl.fullyScriptedMode ? question.question : compactQuestion;
        }
    }

    function doShowContent(content) {
        $scope.$emit('openAssessmentContentModal', content);
    }
}

export default AssessmentQuestionMessageController;
