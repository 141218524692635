import React from 'react';
import { FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';

const propTypes = {
    placeHolder: PropTypes.string,
    name: PropTypes.string.isRequired,
    onFilter: PropTypes.func.isRequired,
};

const defaultProps = {
    placeHolder: '',
};

const SearchColumnFilterManual = ({ placeHolder, name, onFilter }) => {
    return (
        <FormControl
            type="text"
            name={name}
            onChange={onFilter}
            bsSize="small"
            placeholder={placeHolder}
            className="input-filter"
            aria-label={placeHolder}
        />
    );
};

SearchColumnFilterManual.propTypes = propTypes;
SearchColumnFilterManual.defaultProps = defaultProps;

export default SearchColumnFilterManual;
