/** @ngInject */
function SortWeekDaysComparator(daysOfWeekService) {
    return orderWeekDay;

    function orderWeekDay(dayOne, dayTwo) {
        /* Week order, don't like it hardcoded, however its very un likely to change. */

        /* Sorting logic
         if first day is in higher order
         than second return true. */
        return daysOfWeekService.indexOf(dayOne.value) > daysOfWeekService.indexOf(dayTwo.value) ? 1 : -1;
    }
}

export default SortWeekDaysComparator;
