import CompanyService from 'App/services/CompanyService';
import { errorHandler } from 'App/utils';
import { useQuery } from 'react-query';

function useFetchUnassignedMedicationCount(reqBody) {
    return useQuery(
        ['workQueue', 'unassignedMedicationCount', JSON.stringify(reqBody?.search)],
        () => CompanyService.getUnassignedMedicationCount(reqBody),
        {
            onError: errorHandler,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchUnassignedMedicationCount;
