const lockedFields = [
    'first_name',
    'last_name',
    'middle_name',
    'address_line_1',
    'address_line_2',
    'city',
    'state',
    'zip',
    'country',
    'home_phone',
    'mobile_phone',
    'work_phone',
    'suffix',
    'birth_date',
    'marital_status',
    'external_id',
];

export default lockedFields;
