import CMSService from 'App/services/CMSService';

/** @ngInject */
function ContentLibraryEditMonographController(
    _,
    $q,
    $uibModal,
    $window,
    toastr,
    NgTableParams,
    CSRF_ID_VALUE,
    CSRF_ID_NAME
) {
    const ctrl = this;
    const contentTitles = {
        drug: {
            administration: 'Administration',
            bbw: 'BBW',
            contraindications: 'Contraindications',
            doseModifications: 'Dose Modifications',
            howSupplied: 'How Supplied',
            indications: 'Indication(s)',
            interactions: 'Drug Interactions',
            labAndMonitoring: 'Lab and Monitoring Guidelines',
            miscCounsellingPoints: 'Miscellaneous Patient Counselling Points',
            overview: 'Drug Overview',
            recommendedDose: 'Recommended Dose',
            rems: 'REMS',
            sideEffects: 'Side Effects',
            storage: 'Storage',
            warningsAndPrecautions: 'Warning and Precautions',
        },
        disease: {
            complications: 'Complications/Progression',
            goals: 'Goals of Therapy',
            management: 'Therapy Management',
            misc: 'Miscellaneous',
            overview: 'Disease Overview',
            prevention: 'Prevention',
            references: 'References & Version History',
            support: 'Support',
            treatment: 'Treatment',
        },
        // The content titles for teaching points are generated dynamically
        teaching: {
            generic: 'Generic',
            evaluation: 'Evaluation',
            strategy: 'Strategies',
            references: 'References',
        },
    };
    const { labelOrder } = CMSService;
    let originalContentGroup = null;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.createChildContentGroup = createChildContentGroup;
    ctrl.deleteContentGroupResource = deleteContentGroupResource;
    ctrl.onChangeContentVersion = onChangeContentVersion;
    ctrl.saveDraft = saveDraft;

    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;
    ctrl.loading = true;
    ctrl.disabledContentGroups = [];

    function $onChanges(changes) {
        if (changes.contentGroup && ctrl.contentGroup) {
            ctrl.contentGroup = angular.copy(ctrl.contentGroup);
            _formatContentVersions();

            // Store the original content group so we can check if any changes were made
            originalContentGroup = angular.copy(ctrl.contentGroup);
            ctrl.loading = false;
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function _formatContentVersions() {
        const contentVersions = _.sortBy(ctrl.contentGroup.contentVersions, (cv) => {
            return labelOrder[ctrl.contentGroup.type].indexOf(cv.label);
        });
        ctrl.contentGroup.contentVersions = _.keyBy(contentVersions, 'uuid');

        if (ctrl.contentGroup.children) {
            _.forEach(ctrl.contentGroup.children, (cg, contentGroupUuid) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-shadow
                const contentVersions = _.sortBy(ctrl.contentGroup.children[contentGroupUuid].contentVersions, (cv) => {
                    return labelOrder[ctrl.contentGroup.type].indexOf(cv.label);
                });
                ctrl.contentGroup.children[contentGroupUuid].contentVersions = _.keyBy(contentVersions, 'uuid');
            });
        }
    }

    function _refreshContentGroup(uuid) {
        return CMSService.getContentGroup(uuid, {
            includeContentResource: true,
        }).then((res) => {
            ctrl.contentGroup = res;
            originalContentGroup = angular.copy(ctrl.contentGroup);
            _formatContentVersions();
            return res;
        });
    }

    function $onInit() {
        ctrl.labelOrder = labelOrder[ctrl.contentGroup.type];
        ctrl.contentTitles = contentTitles[ctrl.contentGroup.type];
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function createChildContentGroup() {
        if (ctrl.contentGroup.type === 'teaching') {
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                component: 'associateContentGroupResourceModal',
                windowClass: 'associate-content-group-resource-modal',
                keyboard: false,
                resolve: {
                    contentGroup() {
                        return ctrl.contentGroup;
                    },
                },
            });

            modalInstance.result
                .then(() => {
                    return _refreshContentGroup(ctrl.contentGroup.uuid);
                })
                .catch((err) => {
                    return err;
                });
        } else {
            return false;
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function deleteContentGroupResource(contentGroup) {
        if (ctrl.contentGroup.type === 'teaching') {
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                component: 'deleteContentGroupResourceModal',
                windowClass: 'delete-content-group-resource-modal',
                keyboard: false,
                resolve: {
                    contentGroup() {
                        return contentGroup;
                    },
                },
            });

            modalInstance.result
                .then((res) => {
                    ctrl.disabledContentGroups.push(res.uuid);
                    delete ctrl.contentGroup.children[res.uuid];
                    return res;
                })
                .catch((err) => {
                    return err;
                });
        } else {
            return false;
        }
    }

    function onChangeContentVersion(contentVersion) {
        if (ctrl.contentGroup.contentVersions[contentVersion.uuid]) {
            ctrl.contentGroup.contentVersions[contentVersion.uuid].content = contentVersion.content;
        }

        if (
            ctrl.contentGroup.children[contentVersion.contentGroupUuid] &&
            ctrl.contentGroup.children[contentVersion.contentGroupUuid].contentVersions &&
            ctrl.contentGroup.children[contentVersion.contentGroupUuid].contentVersions[contentVersion.uuid]
        ) {
            ctrl.contentGroup.children[contentVersion.contentGroupUuid].contentVersions[contentVersion.uuid].content =
                contentVersion.content;
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function saveDraft() {
        ctrl.saving = true;
        const promises = [];

        // Only save if the content has changed
        _.forEach(ctrl.contentGroup.contentVersions, (cv, uuid) => {
            if (cv.content !== originalContentGroup.contentVersions[uuid].content) {
                promises.push(
                    CMSService.saveContentVersion(cv.uuid, {
                        content: cv.content,
                    })
                );
            }
        });

        // Only save if the content has changed
        _.forEach(ctrl.contentGroup.children, (cg, cgUuid) => {
            _.forEach(ctrl.contentGroup.children[cgUuid].contentVersions, (cv, uuid) => {
                if (cv.content !== originalContentGroup.children[cgUuid].contentVersions[uuid].content) {
                    promises.push(
                        CMSService.saveContentVersion(cv.uuid, {
                            content: cv.content,
                        })
                    );
                }
            });
        });

        // Remove the unassociated content groups
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = ctrl.disabledContentGroups.length; i < len; i++) {
            promises.push(CMSService.deleteContentGroup(ctrl.disabledContentGroups[i]));
        }

        if (promises.length > 0) {
            return $q
                .all(promises)
                .then(() => {
                    toastr.success('Monograph draft saved successfully.');
                    // Update the content versions
                    return CMSService.getContentGroup(ctrl.contentGroup.uuid, {
                        includeContentResource: true,
                    }).then((res) => {
                        ctrl.contentGroup = angular.copy(res);
                        originalContentGroup = angular.copy(ctrl.contentGroup);
                        _formatContentVersions();
                        return res;
                    });
                })
                .catch((err) => {
                    toastr.error('Unable to save monograph draft. Please try again.');
                    return err;
                })
                .finally((res) => {
                    ctrl.saving = false;
                    return res;
                });
        }
        toastr.warning('There are no changes to save.');
        ctrl.saving = false;
    }
}

export default ContentLibraryEditMonographController;
