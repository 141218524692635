function AssessmentQuestionTextboxController() {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeAnswer = doChangeAnswer;

    function $onInit() {
        ctrl.ngModelOptions = {
            updateOn: 'default blur mousedown',
            allowInvalid: true,
            debounce: {
                default: 800,
                blur: 0,
                mousedown: 0,
                '*': 500,
            },
        };
    }

    function $onChanges(changes) {
        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);
            ctrl.response = angular.copy(ctrl.question.response);
        }
    }

    function doChangeAnswer() {
        ctrl.onChangeAnswer({
            answer: ctrl.response,
        });
    }
}

export default AssessmentQuestionTextboxController;
