import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import dateOrDash from 'App/utils/dateOrDash';
import pathOrDash from 'App/utils/pathOrDash';
import useTherapeuticCategoryHistory from 'App/components/PatientProfile/hooks/useTherapeuticCategoryHistory';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import AddNoteModal from './AddNoteModal';
import { NoteModalTypes } from '../../../constants';
import NoteListModal from './NoteListModal';
import { noop } from 'lodash';
import { FaInfoCircle } from 'react-icons/fa';
import IconWrapper from '../../../../../common/styles/IconWrapper';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    categoryId: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    therapyName: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    patientId: PropTypes.string.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    show: PropTypes.bool.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    onHide: PropTypes.func.isRequired,
};
const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    categoryId: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    therapyName: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    patientId: '',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    show: false,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    onHide: noop,
};

function TherapeuticCategoryHistory({ categoryId, therapyName, patientId, show, onHide }) {
    const { therapeuticCategoryHistory, isLoading } = useTherapeuticCategoryHistory(patientId, categoryId);
    const [showAddNote, setShowAddNote] = useState(false);
    const [showNoteList, setShowNoteList] = useState(false);
    const [noteModalData, setNoteModalData] = useState({});

    const showNoteModal = useCallback(
        (parentNoteId, noteType, isAddNote) => {
            setNoteModalData({
                parentNoteId,
                categoryId,
                patientId,
                noteType,
            });
            if (isAddNote) {
                setShowAddNote(true);
            } else {
                setShowNoteList(true);
            }
        },
        [categoryId, patientId]
    );

    const hideNoteModal = useCallback(() => {
        setNoteModalData({});
        setShowAddNote(false);
        setShowNoteList(false);
    }, []);

    return (
        <>
            <Modal
                show={show && !showAddNote && !showNoteList}
                onHide={onHide}
                backdrop="static"
                className="patientMedicationHistoryModal"
                bsSize="large"
            >
                <Modal.Header closeButton={false}>
                    <Modal.Title className="header">Therapeutic Category Status History</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {isLoading ? (
                        <Row>
                            <Col md={12} className="text-center">
                                <TherigyLoadingSpinner />
                            </Col>
                        </Row>
                    ) : (
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                            {therapeuticCategoryHistory.length > 0 ? (
                                <table className="therigy-table">
                                    <colgroup>
                                        <col width="24%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="24%" />
                                        <col width="22%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Therapeutic Category</th>
                                            <th>Opt In Date</th>
                                            <th>Opt Out Date</th>
                                            <th>Reason</th>
                                            <th className="actions">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {therapeuticCategoryHistory.map((history) => (
                                            <tr className="therapy-row" key={history?.id}>
                                                <td>{therapyName}</td>
                                                <td>{dateOrDash(['start'], history)}</td>
                                                <td>{dateOrDash(['end'], history)}</td>
                                                <td>{pathOrDash(['endReason'], history)}</td>
                                                <td>
                                                    <Button
                                                        bsClass="btn-link"
                                                        onClick={() =>
                                                            showNoteModal(history?.id, NoteModalTypes?.therapy, false)
                                                        }
                                                    >
                                                        View Notes
                                                    </Button>
                                                    |
                                                    <Button
                                                        bsClass="btn-link"
                                                        onClick={() =>
                                                            showNoteModal(history?.id, NoteModalTypes?.therapy, true)
                                                        }
                                                    >
                                                        Add Note
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="row">
                                    <div className="col-md-12">
                                        <IconWrapper>
                                            <FaInfoCircle />
                                        </IconWrapper>
                                        <span>There is no status history for this therapeutic category.</span>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        data-automation-id="therapeutic_notes_close"
                        bsStyle="default"
                        type="button"
                        onClick={onHide}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {showAddNote && <AddNoteModal {...noteModalData} show={showAddNote} onHide={hideNoteModal} />}
            {showNoteList && <NoteListModal {...noteModalData} show={showNoteList} onHide={hideNoteModal} />}
        </>
    );
}

TherapeuticCategoryHistory.propTypes = propTypes;
TherapeuticCategoryHistory.defaultProps = defaultProps;

export default TherapeuticCategoryHistory;
