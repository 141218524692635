/** @ngInject */
function QueueManagerService(_, $http, urlBuilderService) {
    return {
        getFieldOrder,
        saveFieldOrder,
    };

    function getFieldOrder(companyId) {
        const url = urlBuilderService.build('getFieldOrder', {
            'queue-manager': '',
            'field-order': companyId,
        });

        return $http.get(url).then((res) => {
            return _.keyBy(res.data, (r) => {
                return r.branch.division_id;
            });
        });
    }

    function saveFieldOrder(companyId, body) {
        const url = urlBuilderService.build('getFieldOrder', {
            'queue-manager': '',
            'field-order': companyId,
        });

        return $http.post(url, body).then((res) => {
            return _.keyBy(res.data, (r) => {
                return r.branch.division_id;
            });
        });
    }
}

export default QueueManagerService;
