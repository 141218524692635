import React from 'react';
import EditProtocolPage from './EditProtocolPage';
import { EditProtocolContextProvider } from './EditProtocolContextProvider';

const propTypes = {};

const defaultProps = {};

function EditProtocolPageWrapper(props) {
    return (
        <EditProtocolContextProvider>
            <EditProtocolPage {...props} />
        </EditProtocolContextProvider>
    );
}

EditProtocolPageWrapper.propTypes = propTypes;
EditProtocolPageWrapper.defaultProps = defaultProps;

export default EditProtocolPageWrapper;
