import React from 'react';
import PropTypes from 'prop-types';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '@/lib/queryClient';

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
};

const defaultProps = {};

function QueryClientWrapper({ children }) {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

QueryClientWrapper.propTypes = propTypes;
QueryClientWrapper.defaultProps = defaultProps;

export default QueryClientWrapper;
