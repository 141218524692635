import curry from 'ramda/src/curry';
import compose from 'ramda/src/compose';
import ifElse from 'ramda/src/ifElse';
import { hasLength } from './index';
import prepend from 'ramda/src/prepend';
import identity from 'ramda/src/identity';
import sortBy from 'ramda/src/sortBy';
import prop from 'ramda/src/prop';
import values from 'ramda/src/values';

/**
 * Sort and prepend an array.
 * This function is curried.
 * @param {object} prependValue - The value to be prepended to the top of the options.
 * @param {string} sortKey - The name of the property to be used in sorting ascending.
 * @param {array} data - The array of items that need to be added to the list of options.
 * @type {array}
 */
const sortAndPrepend = curry((prependValue, sortKey, data) => {
    return compose(ifElse(hasLength, prepend(prependValue), identity), sortBy(prop(sortKey)), values)(data);
});

export default sortAndPrepend;
