/** @ngInject */
function Icd10Service($http, $httpParamSerializerJQLike, urlBuilderService) {
    const service = {
        search,
    };

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-shadow
    function search(search, limit) {
        const url = urlBuilderService.build('searchIcd10', {
            therapy: '',
            search: '',
            'auto-complete': '',
            icd10: '',
        });

        return $http
            .get(url, {
                params: {
                    query: search,
                    ...(limit ? { limit } : {}),
                },
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err.data;
            });
    }

    return service;
}

export default Icd10Service;
