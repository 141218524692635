import * as R from 'ramda';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { v4 as uuid } from 'uuid';
import { hasLength } from '../../utils';
import { StyledIcon, StyledTooltip } from './styles';

const riskSegmentPropType = PropTypes.shape({
    action: PropTypes.string,
    createdOn: PropTypes.string,
    riskSegment: PropTypes.string,
});

const propTypes = {
    riskSegments: PropTypes.arrayOf(riskSegmentPropType),
    showOnHover: PropTypes.bool,
};
const defaultProps = {
    riskSegments: [],
    showOnHover: false,
};

const PatientPredictiveModelRiskIndicator = ({ riskSegments, showOnHover }) => {
    const tooltipId = useRef(uuid());

    const renderTooltip = () => (
        <StyledTooltip id={tooltipId.current} key={tooltipId.current}>
            <ul>
                {R.compose(
                    R.addIndex(R.map)((reason, index) => (
                        <li key={`${tooltipId.current}-${index}`}>
                            <strong>{reason.riskSegment}</strong>
                            {reason.action ? ` - ${reason.action}` : ''}
                        </li>
                    ))
                )(riskSegments)}
            </ul>
        </StyledTooltip>
    );

    const renderIcon = () => (
        <StyledIcon
            className="fa fa-fw fa-exclamation-triangle predictive-model-risk-indicator"
            aria-label="Predictive Model Risk"
        />
    );

    return (
        <span className="patient-predictive-risk-indicator">
            {hasLength(riskSegments) ? (
                <OverlayTrigger
                    trigger={showOnHover ? ['hover', 'focus'] : 'click'}
                    placement="right"
                    rootClose
                    overlay={renderTooltip()}
                >
                    {renderIcon()}
                </OverlayTrigger>
            ) : (
                renderIcon()
            )}
        </span>
    );
};

PatientPredictiveModelRiskIndicator.propTypes = propTypes;
PatientPredictiveModelRiskIndicator.defaultProps = defaultProps;

export default PatientPredictiveModelRiskIndicator;
