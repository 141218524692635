/** @ngInject */
function IncompleteConfigurationConfig($stateProvider) {
    $stateProvider.state({
        name: 'auth.incompleteConfiguration',
        url: '/incompleteConfiguration',
        component: 'incompleteConfiguration',
    });
}

export default IncompleteConfigurationConfig;
