import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { Col, Row, ControlLabel } from 'react-bootstrap';
import { TextField } from '@/lib/form/TextField';
import { useFormikContext } from 'formik';
import { FaInfoCircle } from 'react-icons/fa';

import '../PatientMessagingEnrollmentModalStyle.scss';

import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import ResetButton from 'Lib/form/ResetButton';
import SubmitButton from 'Lib/form/SubmitButton';
import formatPhoneNumber from '../../../../../utils/formatPhone';

const propTypes = {
    onHide: PropTypes.func.isRequired,

    mobilePhone: PropTypes.string,
};

const defaultProps = {
    mobilePhone: '',
};

function UnenrollmentForm({ onHide, mobilePhone }) {
    const infoMessage1Line1 = `Submitting this form will unsubscribe the patient from patient messaging.
    The patient no longer receive text messages.`;
    const infoMessage1Line2 = `The patient can resubscribe by texting "SUBSCRIBE" to 833-354-1731.`;
    const form = useFormikContext();
    const setFormfieldValue = () => {
        if (form && mobilePhone) form.setFieldValue('phone', formatPhoneNumber(mobilePhone), true);
    };
    useEffect(() => {
        setFormfieldValue();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <form onSubmit={form.handleSubmit}>
            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <ControlLabel htmlFor="patientMobile">Patient Mobile Phone</ControlLabel> &nbsp;
                        <Row>
                            <Col sm={12}>
                                <TextField name="phone" fieldName="phone" placeholder="(###) ###-####" disabled />
                            </Col>
                        </Row>
                        <Row className="patienteducation-message">
                            <Col sm={12}>
                                <FaInfoCircle className="info-icon" /> &nbsp;
                                <span>{infoMessage1Line1}</span>
                                <br />
                            </Col>
                        </Row>
                        <Row className="patienteducation-message">
                            <Col sm={12}>
                                <span>{infoMessage1Line2}</span>
                                <br />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <FormButtonGroup>
                    <ResetButton
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Cancel
                    </ResetButton>
                    <SubmitButton>Unenroll</SubmitButton>
                </FormButtonGroup>
            </Modal.Footer>
        </form>
    );
}
UnenrollmentForm.propTypes = propTypes;
UnenrollmentForm.defaultProps = defaultProps;

export default UnenrollmentForm;
