import React from 'react';
import Select from 'react-select';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import * as R from 'ramda';
import { isFieldValid } from '../utils';
import * as PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { field as fieldPropType } from 'App/features/ProtocolManager/AddRule/AddRule.proptypes';

const propTypes = {
    fields: PropTypes.arrayOf(fieldPropType).isRequired,
};

const defaultProps = {};

function FilterFields({ fields }) {
    const formik = useFormikContext();
    const { values: formikValues, setFieldValue } = formik;

    return (
        <FormGroup validationState={isFieldValid('filterField', formik)}>
            <div className="label-tooltip-group">
                <ControlLabel htmlFor="patient-fields">Filter Fields</ControlLabel>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="patient-fields-tooltip">Fields available for filtering.</Tooltip>}
                >
                    <FaInfoCircle className="info-icon" />
                </OverlayTrigger>
            </div>

            <Select
                inputId="patient-fields"
                className="form-control react-select"
                classNamePrefix="react-select"
                options={R.reject((field) => R.path(['conditions', field.label], formikValues), fields)}
                isClearable
                value={formikValues.filterField}
                onChange={(option) => setFieldValue('filterField', option)}
                onBlur={() => formik.setFieldTouched('filterField')}
            />
        </FormGroup>
    );
}

FilterFields.propTypes = propTypes;
FilterFields.defaultProps = defaultProps;

export default FilterFields;
