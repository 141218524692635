import { useQuery } from 'react-query';

import CMSService from 'App/services/CMSService';
import { errorHandler } from 'App/utils';

function useFetchContentMonograph(label, params) {
    return useQuery(['contentMonograph', label, params], () => CMSService.getContent(label, params), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchContentMonograph;
