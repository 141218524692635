import * as R from 'ramda';

/**
 * Checks if a given prop on a given object is null, undedefined, an empty string or false
 *
 * This function is curried
 * @param {string} prop
 * @param {any} data
 */
const propIsFalsy = R.propSatisfies(R.anyPass([R.isNil, R.equals(false), R.equals('')]));

export default propIsFalsy;
