import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.shape({
        original: PropTypes.shape({
            uuid: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

const defaultProps = {};

function TemplateNameLink({ value, row }) {
    return <Link to={`/protocol-template-builder/template/${row.original.uuid}`}>{value}</Link>;
}

TemplateNameLink.propTypes = propTypes;
TemplateNameLink.defaultProps = defaultProps;

export default TemplateNameLink;
