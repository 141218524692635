import React, { useEffect, useRef } from 'react';
import Notes from './Notes';
import html2pdf from 'html2pdf.js';
import { notesPatient, noteType } from './types';
import PropTypes from 'prop-types';

const propTypes = {
    notes: PropTypes.arrayOf(noteType).isRequired,
    patient: notesPatient.isRequired,
    onDone: PropTypes.func.isRequired,
};

const defaultProps = {};

function NotesDownload({ notes, patient, onDone }) {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            html2pdf(ref.current.firstChild, {
                filename: 'summary_notes.pdf',
                margin: 15,
                pagebreak: {
                    mode: ['avoid-all'],
                },
            });
        }
        onDone();
    }, [onDone]);

    return (
        <div ref={ref} style={{ display: 'none' }}>
            <Notes notes={notes} patient={patient} />
        </div>
    );
}

NotesDownload.propTypes = propTypes;
NotesDownload.defaultProps = defaultProps;

export default NotesDownload;
