export const authorizationList = [
    'Ampule',
    'Applicator',
    'Bag',
    'Bar',
    'Blister',
    'Bottle',
    'Box',
    'Can',
    'Canister',
    'Caplet',
    'Capsule',
    'Cartridge',
    'Cassette',
    'Container',
    'Disk',
    'Dose Pack',
    'Dual Pack',
    'Each',
    'Fluid Ounce',
    'Gallon',
    'Gram',
    'Implant',
    'Inhalation',
    'Inhaler',
    'Kilogram',
    'Kit',
    'Liter',
    'Metric Drop',
    'Milliequivalent',
    'Milligram',
    'Milliliter',
    'Nebule',
    'Ounce',
    'Package',
    'Packet',
    'Pad',
    'Patch',
    'Pint',
    'Pouch',
    'Pound',
    'Pre-filled Pen Syringe',
    'Quart',
    'Ring',
    'Sachet',
    'Spray',
    'Stick',
    'Strip',
    'Swab',
    'Syringe',
    'Tablespoon',
    'Tablet',
    'Teaspoon',
    'Troche',
    'Tube',
    'Unspecified',
    'Vial',
];
