import * as R from 'ramda';
import useFetchCustomMenusQuery from 'App/hooks/useFetchCustomMenusQuery';
import hasLength from 'App/utils/hasLength';

function useGetCustomMenuQuery(name) {
    const { isLoading, data } = useFetchCustomMenusQuery();

    if (isLoading) {
        return [];
    }

    return {
        isLoading,
        data: R.compose(
            R.map(
                R.applySpec({
                    value: R.prop('valueName'),
                    label: R.prop('valueName'),
                })
            ),
            R.ifElse(hasLength, R.identity, R.always([])),
            R.prop(name)
        )(data),
    };
}

export default useGetCustomMenuQuery;
