import * as R from 'ramda';
import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted';

/** @ngInject */
function PrescriberTypeaheadController($uibModal, $scope, _, physicianService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeValue = doChangeValue;
    ctrl.openPrescriberModal = ctrl.hasNewRxFillNumberEnabled === true ? openPrescriberModalNew : openPrescriberModal;
    ctrl.search = search;
    const prescriberModalListener = $scope.$on('openPrescriberModal', openPrescriberModalNew);
    ctrl.hasNewRxFillNumberEnabled = isPermitted(PERMISSION_TYPES.ENABLE_RX_FILL_NUMBERS, ctrl.user);

    function $onChanges(changes) {
        if (changes.editable) {
            ctrl.editable = angular.copy(ctrl.editable);
        }
        if (changes.minChar) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line radix
            ctrl.minChar = parseInt(ctrl.minChar);
        }
        if (changes.ngDisabled) {
            ctrl.ngDisabled = angular.copy(ctrl.ngDisabled);
        }
        if (changes.ngReadonly) {
            ctrl.ngReadonly = angular.copy(ctrl.ngReadonly);
        }
        if (changes.required) {
            ctrl.required = angular.copy(ctrl.required);
        }
    }

    /**
     * @summary Initializes the component
     */
    function $onInit() {
        $scope.$on('$destroy', prescriberModalListener);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        ctrl.model.$render = function () {
            ctrl.name = ctrl.model.$name;
            if (
                ctrl.model.$viewValue &&
                ctrl.model.$viewValue.physician_first_name &&
                ctrl.model.$viewValue.physician_last_name
            ) {
                ctrl.value = ctrl.model.$viewValue;
            }
        };
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function search(value) {
        if (value) {
            return physicianService
                .autocomplete(value)
                .then((res) => {
                    return _.sortBy(res, ['physician_first_name', 'physician_last_name']).splice(0, 5);
                })
                .catch(() => {
                    return {};
                });
        }
    }

    function setFullName(value) {
        return R.assoc('physician_full_name', `${value.physician_first_name} ${value.physician_last_name}`, value);
    }

    function doChangeValue(value) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        value = setFullName(value);
        ctrl.model.$setViewValue(value);
        ctrl.onChangeValue({
            value,
        });
    }

    function openPrescriberModal(prescriber) {
        const prescriberId =
            prescriber && Object.prototype.hasOwnProperty.call(prescriber, 'id') ? prescriber.id : null;
        ctrl.prescriberId = prescriberId;

        const modalInstance = $uibModal.open({
            size: 'lg',
            backdrop: 'static',
            windowClass: 'add-prescriber-modal',
            component: 'prescriberModalComponent',
            resolve: {
                prescriberId() {
                    return prescriberId;
                },
            },
        });

        modalInstance.result
            .then((selectedItem) => {
                ctrl.selected = selectedItem;
                return ctrl.selected;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function openPrescriberModalNew(event, prescriber) {
        openPrescriberModal(prescriber);
    }
}

export default PrescriberTypeaheadController;
