function StripTagsFilter() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    return function (text) {
        return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    };
}

function UnescapeHtml() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line func-names
    return function (text) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        text = text || '';
        return text
            .replace(/&amp;/g, '&')
            .replace(/&quot;/g, '"')
            .replace(/&#x27;/g, "'")
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&#x2F;/g, '/')
            .replace(/&#x5C;/g, '\\')
            .replace(/&#96;/g, '`');
    };
}

export { StripTagsFilter, UnescapeHtml };
