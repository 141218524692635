import React from 'react';
import styled from '@emotion/styled';
import { FaInfoCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

const NoRowsCell = styled('td')`
    height: 25vh;
    text-align: center;
`;

const NoRowsText = styled('span')`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const propTypes = {
    message: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    children: PropTypes.node,
};

const defaultProps = {
    message: 'There are no rows that match your current search criteria.',
};

function TableMessage({ message, children }) {
    return (
        <tr>
            <NoRowsCell colSpan="100">
                <NoRowsText>
                    {children || (
                        <>
                            <FaInfoCircle style={{ marginRight: '.5rem' }} /> {message}
                        </>
                    )}
                </NoRowsText>
            </NoRowsCell>
        </tr>
    );
}

TableMessage.propTypes = propTypes;
TableMessage.defaultProps = defaultProps;

export default TableMessage;
