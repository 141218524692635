import React from 'react';
import { Formik } from 'formik';
import { Button, ModalBody, ModalFooter } from 'react-bootstrap';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';
import { TextField } from 'Lib/form/TextField';
import { useHistory } from 'react-router-dom';
import useCreateProtocolTemplateMutation from 'App/hooks/useCreateProtocolTemplateMutation';
import { useBuilderContext } from '../BuilderContext';
import * as R from 'ramda';

const propTypes = {};

const defaultProps = {};

function CreateTemplateForm() {
    const { setShowCreateModal } = useBuilderContext();
    const history = useHistory();

    const createProtocolTemplateMutation = useCreateProtocolTemplateMutation();

    return (
        <Formik
            initialValues={{ name: '' }}
            onSubmit={(values) => {
                return createProtocolTemplateMutation
                    .mutateAsync(values)
                    .then(({ uuid }) => history.push(`/protocol-template-builder/template/${uuid}`));
            }}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <TextField fieldName="name" required label="Template Name" placeholder="Template Name" />
                    </ModalBody>
                    <ModalFooter>
                        <FormButtonGroup>
                            <Button type="button" onClick={() => setShowCreateModal(R.F)}>
                                Cancel
                            </Button>
                            <SubmitButton>Create</SubmitButton>
                        </FormButtonGroup>
                    </ModalFooter>
                </form>
            )}
        </Formik>
    );
}

CreateTemplateForm.propTypes = propTypes;
CreateTemplateForm.defaultProps = defaultProps;

export default CreateTemplateForm;
