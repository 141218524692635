import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import { formatDate } from 'App/components/PatientProfile/PatientProfile.utils';
import TherigyErrorBoundary from 'App/components/errors/TherigyErrorBoundary';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import CustomMenuField from 'Lib/form/CustomMenu';
import { DatePickerField } from 'Lib/form/DatePickerField';
import SubmitButton from 'Lib/form/SubmitButton';
import { TextareaField } from 'Lib/form/TextareaField';
import { rxPropType } from 'Lib/types';

import SpaceBetweenContainer from '../SpaceBetweenContainer';

const RxNumberEndModal = ({ onClose, onSubmit, existingRxNumber }) => {
    return (
        <Modal show onHide={onClose}>
            <TherigyErrorBoundary>
                <Modal.Header>
                    <Modal.Title>Rx Number End</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        rxNumber: {
                            ...existingRxNumber,
                            endReason: existingRxNumber.endReason ? { valueName: existingRxNumber.endReason } : null,
                            notes: existingRxNumber.notes || '',
                        },
                    }}
                    onSubmit={(values) => {
                        const submissionValues = {
                            rxNumber: {
                                ...values.rxNumber,
                                endReason: values.rxNumber.endReason.valueName,
                            },
                        };
                        onSubmit(submissionValues);
                        onClose();
                    }}
                >
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Row>
                                    <Col md={6} className="form-group">
                                        <div>Medication Name</div>
                                        <div>{values.rxNumber.medication.name}</div>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <div>Rx Number</div>
                                        <div>{values.rxNumber.rxNumber}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="form-group">
                                        <div>Prescriber</div>
                                        <div>
                                            {values.rxNumber.physician
                                                ? `${values.rxNumber.physician.firstName} ${values.rxNumber.physician.lastName}`
                                                : '--'}
                                        </div>
                                    </Col>
                                    <Col md={6} className="form-group">
                                        <div>Rx Number Start Date</div>
                                        <div>{values.rxNumber.start ? formatDate(values.rxNumber.start) : '--'}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <DatePickerField
                                            fieldName="rxNumber.end"
                                            label="Rx Number End Date"
                                            placeholder="YYYY/MM/DD"
                                            required
                                            minDate={values.rxNumber.start}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <CustomMenuField
                                            fieldName="rxNumber.endReason"
                                            menuName="medication_opt_out_reasons"
                                            label="Prescription End Reason"
                                            required
                                        />
                                    </Col>
                                </Row>
                                <TextareaField
                                    fieldName="rxNumber.notes"
                                    label={
                                        <SpaceBetweenContainer>
                                            <div>Notes</div>
                                            <div>{values.rxNumber.notes.length}/200</div>
                                        </SpaceBetweenContainer>
                                    }
                                    placeholder="Write notes here"
                                    maxLength={200}
                                />
                            </Modal.Body>

                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <SubmitButton>Done</SubmitButton>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    )}
                </Formik>
            </TherigyErrorBoundary>
        </Modal>
    );
};

RxNumberEndModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    existingRxNumber: rxPropType,
};
RxNumberEndModal.defaultProps = {
    existingRxNumber: null,
};

export default RxNumberEndModal;
