import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import ActionCell from '../styles/ActionCell';
import Table from '../Table/Table';
import MedicationModal from './MedicationModal';
import { Button } from 'react-bootstrap';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    updateMedicationsState: PropTypes.func.isRequired,
};

const defaultProps = {
    data: [],
    isLoading: false,
};

const tableStateReducer = (state, action, prevState) => {
    const initialSort = [{ id: 'name', desc: false }];

    const { type } = action;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-case
    switch (type) {
        case 'resetSortBy': {
            return prevState;
        }
        case 'init': {
            return R.set(R.lensProp('sortBy'), initialSort, state);
        }
        default: {
            return state;
        }
    }
};

function MedicationsTable(props) {
    const [editMedicationModalData, setEditMedicationModalData] = useState(null);
    const editMedication = (med) => {
        setEditMedicationModalData({
            value: med,
            title: 'Edit Medication',
        });
    };
    const addMedication = () => {
        const newMed = {
            id: uuid(),
            isNew: true,
        };
        setEditMedicationModalData({
            value: newMed,
            title: 'Add Medication',
        });
    };
    //#endregion

    const { onDelete } = props;

    function actionCell({ row }) {
        return (
            <ActionCell>
                <Button
                    bsStyle="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                        editMedication(row.original, row.index);
                    }}
                    data-testid={`edit-btn-${row.index}`}
                >
                    Edit
                </Button>
                <Button
                    bsStyle="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                        return onDelete(row.original, row.index);
                    }}
                    data-testid={`delete-btn-${row.index}`}
                >
                    Delete
                </Button>
            </ActionCell>
        );
    }

    actionCell.propTypes = {
        row: PropTypes.shape({
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line react/forbid-prop-types
            orignal: PropTypes.object,
            index: PropTypes.number,
        }),
    };

    //#region RunTime Calculations
    const columns = [
        {
            Header: 'Medications',
            id: 'name',
            accessor: 'name',
        },
        {
            Header: 'Actions',
            id: 'action',
            Cell: actionCell,
        },
    ];
    //#endregion

    return (
        <>
            <Table {...props} columns={columns} stateReducer={tableStateReducer} />
            <Button bsStyle="link" onClick={addMedication}>
                <i className="fa fa-solid fa-plus" /> Add Medication
            </Button>
            {editMedicationModalData && (
                <MedicationModal
                    onHide={() => setEditMedicationModalData(null)}
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/destructuring-assignment
                    onSave={props.onSave}
                    show
                    title={editMedicationModalData.title}
                    value={editMedicationModalData.value}
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/destructuring-assignment
                    meds={props.data}
                    modalState={setEditMedicationModalData}
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/destructuring-assignment
                    updateMedicationsState={props.updateMedicationsState}
                />
            )}
        </>
    );
}

MedicationsTable.propTypes = propTypes;
MedicationsTable.defaultProps = defaultProps;

export default MedicationsTable;
