import { cx } from '@emotion/css';
import * as R from 'ramda';
import { InsuranceContextProvider } from '../../contexts/InsuranceContext';
import PatientInsurance from './PatientInsurance';
import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import styled from '@emotion/styled';
import LoadingSpinner from 'App/components/TherigyLoadingSpinner';
import dayjs from 'dayjs';
import { isExpired } from 'App/utils';

const propTypes = {
    tabList: PropTypes.shape({
        tabList: PropTypes.arrayOf(PropTypes.number),
        activeState: PropTypes.number,
    }),
    setTabList: PropTypes.func,
    isReadOnly: PropTypes.bool,
    patientInsurance: PropTypes.shape({
        name: PropTypes.string,
        planPatientId: PropTypes.string,
        pharmacyInsurancePersonCode: PropTypes.string,
        groupNumber: PropTypes.string,
        payerIdCode: PropTypes.string,
        payerSegment: PropTypes.string,
        medicalProductLine: PropTypes.string,
        pharmacyProductLine: PropTypes.string,
        deductableAmount: PropTypes.number,
        deductableAmountPaidToDate: PropTypes.number,
        pharmacyPlanOopMax: PropTypes.number,
        pharmacyPlanOopMaxPaidToDate: PropTypes.number,
        coverageEffectiveDate: PropTypes.string,
        coverageEndDate: PropTypes.string,
        coverageType: PropTypes.string,
        isIntegratedHighDeductible: PropTypes.string,
        isPharmacyBenefitsCarveOutFromMedical: PropTypes.string,
        pharmacyInsuranceGroupId: PropTypes.string,
        pharmacyInsuranceBin: PropTypes.string,
        pharmacyInsurancePcn: PropTypes.string,
        helpDeskPhone: PropTypes.string,
        disabledOn: PropTypes.string,
        disabledBy: PropTypes.string,
        disabledByUser: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        }),
    }),
    handleChangeProps: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    patientInsuranceEnable: PropTypes.object,
    validationInsuranceErrors: PropTypes.shape({
        name: PropTypes.string,
        planPatientId: PropTypes.string,
        pharmacyInsuranceGroupId: PropTypes.string,
        pharmacyInsuranceBin: PropTypes.string,
        pharmacyInsurancePcn: PropTypes.string,
    }),
    validateInsurancePlan: PropTypes.func,
    editPatientInsurance: PropTypes.bool,
    disablePlan: PropTypes.func,
    disableInsurancePlan: PropTypes.bool,
    removePlan: PropTypes.func,
    isBiDisabled: PropTypes.bool,
    biInsurance: PropTypes.bool,
    toggleDetach: PropTypes.func,
    isBiInsuranceLoading: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    requiredFields: PropTypes.array,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    disabledFields: PropTypes.array,
};

const defaultProps = {
    tabList: {
        tabList: [1],
        activeState: 1,
    },
    setTabList: noop,
    patientInsurance: {},
    isReadOnly: false,
    handleChangeProps: noop,
    patientInsuranceEnable: {},
    validationInsuranceErrors: {},
    validateInsurancePlan: noop,
    editPatientInsurance: false,
    disablePlan: noop,
    disableInsurancePlan: false,
    removePlan: noop,
    isBiDisabled: false,
    biInsurance: false,
    toggleDetach: noop,
    isBiInsuranceLoading: false,
    requiredFields: [],
    disabledFields: [],
};

const SpinnerStyle = styled('div')`
    position: absolute;
    background: rgb(255 255 255 / 47%);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function InsuranceTabs(props) {
    const {
        tabList,
        setTabList,
        isReadOnly,
        patientInsurance,
        handleChangeProps,
        patientInsuranceEnable,
        validationInsuranceErrors,
        validateInsurancePlan,
        editPatientInsurance,
        disablePlan,
        disableInsurancePlan,
        removePlan,
        isBiDisabled,
        biInsurance,
        isBiInsuranceLoading,
        toggleDetach,
        requiredFields,
        disabledFields,
    } = props;

    return (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {biInsurance && isBiInsuranceLoading ? (
                <SpinnerStyle style={{ width: '800px', height: '39px' }}>
                    <LoadingSpinner />
                </SpinnerStyle>
            ) : (
                <>
                    <ul className="nav nav-tabs tab-panel-content" role="tablist">
                        {tabList.tabList.map((item, index) => (
                            <li
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                role="presentation"
                                className={cx({
                                    active: tabList.activeState === item,
                                })}
                            >
                                {/* TODO: Fix this the next time the file is edited. */}
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                                <a
                                    aria-controls={item}
                                    role="tab"
                                    data-toggle="tab"
                                    onClick={() => {
                                        setTabList({ tabList: tabList.tabList, activeState: item });
                                    }}
                                    className={cx('null', {
                                        'disable-insurance-plan':
                                            isReadOnly &&
                                            (R.path([item, 'disabledOn'], patientInsurance) ||
                                                isExpired(
                                                    dayjs(R.path([item, 'coverageEndDate'], patientInsurance))
                                                        .utc()
                                                        .format('YYYY-MM-DD 23:59:59')
                                                )),
                                    })}
                                >
                                    {isReadOnly &&
                                    (R.path([item, 'disabledOn'], patientInsurance) ||
                                        isExpired(
                                            dayjs(R.path([item, 'coverageEndDate'], patientInsurance))
                                                .utc()
                                                .format('YYYY-MM-DD 23:59:59')
                                        )) ? (
                                        <span>
                                            {patientInsurance[item].name}
                                            <span className="plan-disabled">-Disabled</span>
                                        </span>
                                    ) : (
                                        R.pathOr(`Plan ${item}`, [item, 'name'], patientInsurance)
                                    )}{' '}
                                    {isReadOnly || isBiDisabled ? null : (
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                                        <i
                                            className="fa fa-window-close"
                                            title="remove-plan"
                                            onClick={(event) =>
                                                biInsurance ? toggleDetach() : removePlan(event, item)
                                            }
                                        />
                                    )}
                                </a>
                            </li>
                        ))}
                    </ul>

                    <div className="tab-content">
                        {tabList.tabList.map((item, index) => (
                            <div
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                role="tabpanel"
                                className={cx('tab-pane', {
                                    active: tabList.activeState === item,
                                })}
                            >
                                <InsuranceContextProvider>
                                    <PatientInsurance
                                        item={item}
                                        onChangeText={handleChangeProps}
                                        patientInsurance={
                                            !isReadOnly ||
                                            R.path([item, 'disabledOn'], patientInsurance) ||
                                            isExpired(
                                                dayjs(R.path([item, 'coverageEndDate'], patientInsurance))
                                                    .utc()
                                                    .format('YYYY-MM-DD 23:59:59')
                                            )
                                                ? patientInsurance
                                                : patientInsuranceEnable
                                        }
                                        isReadOnly={isReadOnly}
                                        validationInsuranceErrors={validationInsuranceErrors}
                                        validateInsurancePlan={validateInsurancePlan}
                                        editPatientInsurance={editPatientInsurance}
                                        disablePlan={disablePlan}
                                        disableInsurancePlan={disableInsurancePlan}
                                        isBiDisabled={isBiDisabled}
                                        requiredFields={requiredFields}
                                        disabledFields={disabledFields}
                                    />
                                </InsuranceContextProvider>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
}

InsuranceTabs.propTypes = propTypes;
InsuranceTabs.defaultProps = defaultProps;

export default InsuranceTabs;
