/** @ngInject */
function ReportEngineService($http, $q, _, __env, jwtHelper, sessionStorageService) {
    const service = {
        getDefaultDashboard,
        getExplore,
        getReport,
        getTherigyContent,
        getUser,
        getUserContent,
    };

    function _authorization() {
        return {
            headers: {
                Authorization: `Bearer ${sessionStorageService.getJwt()}`,
            },
        };
    }

    function _getReportUrl() {
        return `${__env.trsEndpoint}:${__env.trsPort}/trs/reports/1.0.0/`;
    }

    function getExplore(model, explore) {
        return $http.get(`${_getReportUrl()}/explore/${model}/${explore}`, _authorization()).then((res) => {
            return res.data;
        });
    }

    function getDefaultDashboard() {
        return $http.get(`${_getReportUrl()}/default`, _authorization()).then((res) => {
            return res.data;
        });
    }

    function getReport(type, id) {
        return $http.get(`${_getReportUrl()}/${type}/${id}`, _authorization()).then((res) => {
            return res.data;
        });
    }

    function getTherigyContent() {
        const config = _authorization();
        return $http.get(`${_getReportUrl()}content/therigy`, config).then((res) => {
            return res.data;
        });
    }

    function getUser() {
        const token = jwtHelper.decodeToken(sessionStorageService.getJwt()).data;
        return $http.get(`${_getReportUrl()}user/${token.UserID}`, _authorization()).then((res) => {
            return res.data;
        });
    }

    function getUserContent() {
        const config = _authorization();
        return $http.get(`${_getReportUrl()}content/user`, config).then((res) => {
            return res.data;
        });
    }

    return service;
}

export default ReportEngineService;
