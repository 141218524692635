import { parseAssessmentSettings, shouldShowRetiredAssessment } from 'App/utils';
import * as R from 'ramda';
import UserService from 'App/services/UserService';

/** @ngInject */
function PatientProfileAddActivityController(_, __env, toastr, amsService, patientService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeCategory = doChangeCategory;
    ctrl.submit = submit;
    let showRetiredAssessments = false;

    function $onChanges(changes) {
        showRetiredAssessments = R.pathOr(false, ['company_permissions', 'ShowRetiredAssessments'], UserService.get());

        if (changes.resolve && ctrl.resolve) {
            ctrl.patient = angular.copy(ctrl.resolve.modalData.patient);
            ctrl.categories = angular.copy(ctrl.resolve.modalData.categories);
            ctrl.categoryCount = ctrl.categories.length;
            ctrl.additionalCategories = angular.copy(ctrl.resolve.modalData.categories);
            // Ensure "General" therapy is an option.
            if (
                _.findIndex(ctrl.categories, {
                    id: __env.therapyIds.general,
                }) === -1
            ) {
                ctrl.categories.push({
                    id: __env.therapyIds.general,
                    name: 'General',
                });
            }
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function doChangeCategory(category) {
        if (category.name !== 'General') {
            ctrl.additionalCategoryIds = [];
        }

        if (typeof category.id === 'string') {
            ctrl.busy = true;
            ctrl.activity = [];

            return amsService.getTherapyAssessments(category.id, true, showRetiredAssessments).then((res) => {
                ctrl.activities = R.compose(
                    R.filter(shouldShowRetiredAssessment(showRetiredAssessments)),
                    R.map(parseAssessmentSettings),
                    R.values
                )(res);
                ctrl.busy = false;
                return res;
            });
        }
    }

    function submit() {
        ctrl.busy = true;

        if (ctrl.categoryCount === 1) {
            ctrl.additionalCategoryIds = [ctrl.categories[0].id];
        }

        return patientService
            .createActivity(ctrl.patient.id, {
                poll_id: ctrl.activity.id,
                additional_group_ids: ctrl.additionalCategoryIds,
            })
            .then((res) => {
                toastr.success('Your activity has been added successfully');
                ctrl.close({ $value: res });
            })
            .catch(() => {
                toastr.error('Your activity was not added successfully');
                ctrl.dismiss({ $value: null });
            });
    }
}

export default PatientProfileAddActivityController;
