/**
 * Controller for prescription history modal component.
 * @param $uibModal
 */
/** @ngInject */
function PrescriptionHistoryModalController($uibModal) {
    const ctrl = this;
    ctrl.history = null;
    ctrl.openAddNoteModal = openAddNoteModal;
    ctrl.openViewNotesModal = openViewNotesModal;

    ctrl.$onChanges = $onChanges;
    ctrl.cancelForm = cancelForm;

    /**
     * Sets values in resolve to properties.
     * @param changes
     */
    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.patientId) {
                ctrl.patientId = angular.copy(ctrl.resolve.patientId);
            }
            if (ctrl.resolve.prescriptions) {
                ctrl.prescriptions = angular.copy(ctrl.resolve.prescriptions);
            }
            if (ctrl.resolve.medication) {
                ctrl.medication = angular.copy(ctrl.resolve.medication);
            }
        }
    }

    /**
     * Closes the prescription history modal.
     */
    function cancelForm() {
        ctrl.dismiss({ $value: 'cancel' });
    }

    /**
     * Opens the modal to view rx notes.
     * @param patientId
     * @param prescriptionId
     * @param medicationId
     */
    function openViewNotesModal(patientId, prescriptionId, medicationId) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'prescriptionNotes',
            size: 'lg',
            resolve: {
                patientId() {
                    return patientId;
                },
                medicationId() {
                    return medicationId;
                },
                prescriptionId() {
                    return prescriptionId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    /**
     * Opens the modal to add an rx note.
     * @param patientId
     * @param prescription
     */
    function openAddNoteModal(patientId, prescription) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'prescriptionAddNote',
            size: 'md',
            resolve: {
                prescription() {
                    return prescription;
                },
                patientId() {
                    return patientId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }
}

export default PrescriptionHistoryModalController;
