import PropTypes from 'prop-types';
import React from 'react';
import Footer from '@/app/components/Header/Footer';

import Header from '@/app/components/Header/Header';
import NavBar from '@/app/components/Header/NavBar';
import TherigyErrorBoundary from '../errors/TherigyErrorBoundary';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const WithHeader = (props) => {
    const { children } = props;

    return (
        <div className="page-container">
            <Header />
            <NavBar />
            <div className="content-wrap">
                <TherigyErrorBoundary>{children}</TherigyErrorBoundary>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};

WithHeader.propTypes = propTypes;

export default WithHeader;
