/** @ngInject */
function EngageService(_, $http, urlBuilderService) {
    return {
        getSubscriptions,
        getPatientVideos,
        subscribeEducationContent,
        subscribeSMS,
    };

    function getSubscriptions(portalUserId) {
        const url = urlBuilderService.build('patientPortal', {
            user: portalUserId,
            subscriptions: '',
        });
        return $http.get(url).then((res) => {
            return res.data;
        });
    }

    function getPatientVideos(portalUserId, type) {
        const url = urlBuilderService.build('patientPortal', {
            user: portalUserId,
            resources: '',
            videos: type,
        });
        return $http.get(url).then((res) => {
            return res.data;
        });
    }

    function subscribeEducationContent(patientId) {
        const url = urlBuilderService.build('engageVidSubscribe', {
            patient: patientId,
            subscriptions: '',
            videos: '',
        });
        return $http.post(url).then((res) => {
            return res.data;
        });
    }

    function subscribeSMS(patientId) {
        const url = urlBuilderService.build('engageSMSSubscribe', {
            patient: patientId,
            subscriptions: '',
            sms: '',
        });
        return $http.post(url).then((res) => {
            return res.data;
        });
    }
}

export default EngageService;
