import React from 'react';
import { Modal, ModalHeader, ModalTitle } from 'react-bootstrap';
import { useBuilderContext } from '../BuilderContext';
import CreateTemplateForm from './CreateTemplateForm';

const propTypes = {};

const defaultProps = {};

function CreateTemplateModal() {
    const { showCreateModal } = useBuilderContext();

    if (showCreateModal) {
        return (
            <Modal backdrop="static" show>
                <ModalHeader>
                    <ModalTitle>Create Protocol Template</ModalTitle>
                </ModalHeader>
                <CreateTemplateForm />
            </Modal>
        );
    }

    return false;
}

CreateTemplateModal.propTypes = propTypes;
CreateTemplateModal.defaultProps = defaultProps;

export default CreateTemplateModal;
