import styled from '@emotion/styled';
import React from 'react';

import { attachChildren, splitQuestions } from 'App/services/AMSService';
import { reactQueryPropType } from 'Lib/types';
import { ErrorMessage } from '../errors';
import AssessmentForm from './AssessmentForm';
import AssessmentScrollspy from './AssessmentScrollspy';
import AssessmentSidebar from './sidebar/AssessmentSidebar';
import useFetchEhrBranchSettings from './hooks/useFetchEhrBranchSettings';
import useFetchQuestionGroups from './hooks/useFetchQuestionGroups';
import PageSpinner from 'App/components/PageSpinner';

const AssessmentDisplayContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
`;

const AssessmentDisplay = ({ activityQuery, patientQuery, questionsQuery }) => {
    const questionGroupsQuery = useFetchQuestionGroups();
    const branchSettingsQuery = useFetchEhrBranchSettings();

    if (
        patientQuery.isLoading ||
        activityQuery.isLoading ||
        questionsQuery.isLoading ||
        questionGroupsQuery.isLoading ||
        branchSettingsQuery.isLoading
    ) {
        return <PageSpinner />;
    }

    if (
        patientQuery.isError ||
        activityQuery.isError ||
        questionsQuery.isError ||
        questionGroupsQuery.isError ||
        branchSettingsQuery.isError
    ) {
        return <ErrorMessage />;
    }

    const questionsWithChildren = attachChildren(questionsQuery.data);
    const split = splitQuestions(questionsWithChildren);

    const relevantGroups = Object.keys(split.groupedQuestions)
        .map((id) => questionGroupsQuery.data[id] || { id: Number(id), groupOrder: 0 })
        .sort((a, b) => a.groupOrder - b.groupOrder);

    return (
        <AssessmentDisplayContainer>
            <AssessmentSidebar patient={patientQuery.data} />
            <AssessmentForm
                activity={activityQuery.data}
                allQuestions={questionsQuery.data}
                questionsWithChildren={questionsWithChildren}
                splitQuestions={split}
                questionGroups={relevantGroups}
                patient={patientQuery.data}
                branchSettings={branchSettingsQuery.data}
            />
            <AssessmentScrollspy questionGroups={relevantGroups} />
        </AssessmentDisplayContainer>
    );
};

AssessmentDisplay.propTypes = {
    activityQuery: reactQueryPropType.isRequired,
    patientQuery: reactQueryPropType.isRequired,
    questionsQuery: reactQueryPropType.isRequired,
};

AssessmentDisplay.defaultProps = {};

export default AssessmentDisplay;
