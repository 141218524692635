/** @ngInject */
function AssessmentDeleteModalController(assessmentService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.deleteAssessment = deleteAssessment;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve && ctrl.resolve.assessment) {
            ctrl.assessment = ctrl.resolve.assessment;
        }
    }

    function deleteAssessment() {
        return assessmentService.deleteAssessment(ctrl.assessment.id, ctrl.assessment.groupId).then(() => {
            ctrl.close({
                $value: ctrl.assessment,
            });
        });
    }
}

export default AssessmentDeleteModalController;
