/** @ngInject */
function ReferralHistoryController(NgTableParams, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges() {
        if (ctrl.patientId && ctrl.referralId) {
            return rmsService.getHistory(ctrl.patientId, ctrl.referralId).then((res) => {
                ctrl.tableParams = new NgTableParams(
                    {
                        count: 10,
                        sorting: { updated_on: 'desc' },
                    },
                    {
                        counts: [],
                        dataset: res,
                    }
                );

                if (ctrl.tableParams.total() > ctrl.tableParams.count()) {
                    ctrl.tableParams.settings({ counts: [5, 10, 25] });
                }
                return res;
            });
        }
    }
}

export default ReferralHistoryController;
