import angular from 'angular';
import * as R from 'ramda';
import PESService from '../../services/PESService';
import CompanyService from '../../services/CompanyService';
import { shouldSyncPatient } from '../../services/EhrService';
import React from 'react';
import ReactDOM from 'react-dom';
import PatientInsuranceTabs from 'App/components/PatientInsurance/PatientInsuranceTabs';
import { isPermitted, formatPhone } from 'App/utils';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import { capitalize } from 'lodash';
import {
    PatientPredictiveModelRiskIndicator,
    PatientPredictiveModelRiskText,
} from 'App/components/PatientPredictiveModelRisk';
import mountReactComponent from 'App/utils/mountReactComponent';

/** @ngInject */
function PatientController(
    _,
    $q,
    $scope,
    $state,
    $uibModal,
    $window,
    authService,
    dateService,
    patientOtherMedicationService,
    patientService,
    pesService,
    rmsService,
    sessionStorageService,
    therapyService,
    CSRF_ID_VALUE,
    CSRF_ID_NAME,
    unassignedMedicationService,
    __env
) {
    const ctrl = this;
    ctrl.isLoadingPatient = true;

    if (isPermitted(PERMISSION_TYPES.ENABLE_REACT_PATIENT_PROFILE, ctrl.user)) {
        return null;
    }

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.getFormattedAddress = getFormattedAddress;
    ctrl.formatPhone = formatPhone;
    ctrl.openAddActivityModal = openAddActivityModal;
    ctrl.openOtherMedicationsModal = openOtherMedicationsModal;
    ctrl.updatePatient = updatePatient;
    ctrl.updateShowEndedMedications = updateShowEndedMedications;
    ctrl.updateShowPatientSection = updateShowPatientSection;
    ctrl.viewAttachments = viewAttachments;
    ctrl.refreshData = refreshData;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;
    ctrl.branchList = [];
    ctrl.syncingWithEhr = false;
    ctrl.patientInsurance = {};
    ctrl.showInsurancePlan = true;
    ctrl.loadingPatientInsurance = false;
    ctrl.disableInsurancePlan = false;
    ctrl.hasNewRxFillNumberEnabled = isPermitted(PERMISSION_TYPES.ENABLE_RX_FILL_NUMBERS, ctrl.user);

    $scope.$on('updatePatient', (event, data) => {
        return pesService.get(data.patient_id).then((res) => {
            res.branchName = ctrl.user.active_branch.Name;
            ctrl.patient = angular.copy(res);
            return res;
        });
    });

    $scope.$on('updateReferrals', (event, data) => {
        return rmsService.getReferrals(data.patient_id).then((res) => {
            ctrl.referrals = angular.copy(res);
            return res;
        });
    });

    function _refreshActivities(patientId) {
        const referralChain = rmsService.getReferrals(patientId).then((referrals) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = referrals.length; i < len; i++) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                for (let j = 0, len2 = referrals[i].activities.length; j < len2; j++) {
                    const { notes } = referrals[i].activities[j];
                    if (notes && notes.length > 0) {
                        const latestNote = notes[notes.length - 1];
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        referrals[i].activities[j].note_status = latestNote.status;
                    } else {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        referrals[i].activities[j].note_status = referrals[i].activities[j].status;
                    }

                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    referrals[i].activities[j].type_label = 'Referral';
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    referrals[i].activities[j].date_due = dateService.localize(referrals[i].activities[j].due_on);
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    referrals[i].activities[j].date_completed = dateService.localize(
                        referrals[i].activities[j].completed_on
                    );

                    switch (referrals[i].activities[j].status) {
                        case 'Appealed':
                        case 'Appealed - Overturned':
                        case 'Appealed - Upheld':
                        case 'Approved':
                        case 'Complete':
                        case 'Completed':
                        case 'Denied':
                        case 'Not Required':
                        case 'Overturned':
                        case 'Partially Approved':
                        case 'Upheld':
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            referrals[i].activities[j].activity_status_lookup_id = '1';
                            break;
                        case 'Deleted':
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            referrals[i].activities[j].activity_status_lookup_id = '2';
                            break;
                        default:
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            referrals[i].activities[j].activity_status_lookup_id = '0';
                            break;
                    }
                }
            }
            return referrals;
        });

        return $q
            .all({
                activities: patientService.getActivities(patientId),
                referrals: referralChain,
            })
            .then((res) => {
                const { activities } = res;
                const { referrals } = res;

                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                for (let i = 0, len = referrals.length; i < len; i++) {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-plusplus
                    for (let j = 0, len2 = referrals[i].activities.length; j < len2; j++) {
                        activities.push(referrals[i].activities[j]);
                    }
                }
                ctrl.activities = angular.copy(activities);

                ctrl.pendingActivities = activities.filter((a) => {
                    return (
                        parseInt(a.activity_status_lookup_id, 10) === 0 ||
                        parseInt(a.activity_status_lookup_id, 10) === 3
                    );
                });
                ctrl.pastActivities = activities.filter((a) => {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line radix
                    return parseInt(a.activity_status_lookup_id) === 1;
                });
                ctrl.deletedActivities = activities.filter((a) => {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line radix
                    return parseInt(a.activity_status_lookup_id) === 2;
                });

                return activities;
            })
            .catch((e) => {
                ctrl.activities = [];
                ctrl.pendingActivities = [];
                ctrl.pastActivities = [];
                ctrl.deletedActivities = [];
                return e;
            });
    }

    $scope.$on('patientActivitiesUpdated', () => {
        _refreshActivities(ctrl.patientId);
    });

    function $onChanges(changes) {
        const promises = [];
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            ctrl.branchList = ctrl.user.branches;
        }
        if (ctrl.unassignedMedications && ctrl.unassignedMedications.length) {
            promises.push(
                therapyService.getTherapy().then((res) => {
                    ctrl.companyTherapies = _.values(res);
                })
            );
        }
        return Promise.all(promises);
    }

    function handleDisablePlan(value) {
        ctrl.disableInsurancePlan = value;
        mountIt();
    }

    function removeInsurancePlan(index) {
        delete ctrl.patientInsurance[index];
        mountIt();
    }

    function toggleInsurancePlans() {
        ctrl.showInsurancePlan = !ctrl.showInsurancePlan;
        mountIt();
    }

    /**
     * Mount react
     */
    mountIt();
    mountPredictiveModelComponent();
    function mountIt() {
        const interval = setInterval(() => {
            const dom = document.getElementById('insurance-plans');
            if (dom) {
                ReactDOM.render(
                    <PatientInsuranceTabs
                        patientInsurance={ctrl.patientInsurance || []}
                        removeInsurancePlan={removeInsurancePlan}
                        toggleInsurancePlans={toggleInsurancePlans}
                        showInsurancePlans={ctrl.showInsurancePlan}
                        handleDisablePlan={handleDisablePlan}
                        disableInsurancePlan={ctrl.disableInsurancePlan}
                        isReadOnly
                        loadingPatientInsurance={ctrl.loadingPatientInsurance}
                    />,
                    dom
                );
                clearInterval(interval);
            }
        }, 100);
    }
    function mountPredictiveModelComponent() {
        if (!ctrl.patientPredictiveModelEnabled) return;
        mountReactComponent(
            `#predictive-risk-${ctrl.patientId}`,
            <PatientPredictiveModelRiskIndicator riskSegments={ctrl.patient.predictiveModel} />
        );
        mountReactComponent(
            `#predictive-risk-reason-${ctrl.patientId}`,
            <PatientPredictiveModelRiskText riskSegments={ctrl.patient.predictiveModel} />
        );
    }

    async function setPatientIdentifiers() {
        const ehrBranchSettings = await CompanyService.getEhrBranchSettings();
        ctrl.ehr_primary_id_type = ehrBranchSettings.ehrPrimaryIdType;
        ctrl.ehr_enabled = !!ctrl.ehr_primary_id_type;
        if (!ctrl.patientId) {
            return;
        }

        const identifiers = Object.values(await PESService.getIdentifiers(ctrl.patient.id));
        ctrl.patient.ehrId = identifiers.find(
            (identifierEntry) => identifierEntry.identifierType === ctrl.ehr_primary_id_type
        )?.identifier;

        const idDisplayMap = {
            external_id: ctrl.patient.externalId || ctrl.patient.external_id,
            human_id: ctrl.patient.humanId || ctrl.patient.human_id,
            ehr_id: ctrl.patient.ehrId,
            other_id: ctrl.patient.otherId,
        };

        if (ctrl.user.company_permissions.DisplayedId === 'ehr_id') {
            idDisplayMap.ehrFallback = idDisplayMap.external_id;
        }

        ctrl.displayedId = idDisplayMap[ctrl.user.company_permissions.DisplayedId] || idDisplayMap.ehrFallback || '';
    }

    function $onInit() {
        ctrl.referralStatusFilter = ['Pending'];
        ctrl.patientId = $state.params.patientId;
        ctrl.patientOtherMedications = [];
        ctrl.supportEmail = __env.supportEmail;

        const showPatientSection = sessionStorageService.getOnUser('patientProfile_', true);
        if (showPatientSection && typeof showPatientSection === 'object') {
            ctrl.showPatientSection = showPatientSection;
        } else {
            ctrl.showPatientSection = {
                pendingActivities: true,
                pastActivities: false,
                deletedActivities: false,
                therapies: true,
            };
        }

        const promises = {
            patient: pesService.get(ctrl.patientId),
            otherMedications: patientOtherMedicationService.getMany(ctrl.patientId),
            identifiers: PESService.getIdentifiers(ctrl.patientId),
            visits: PESService.getVisits(ctrl.patientId),
        };

        PESService.getPatientInsurance(ctrl.patientId)
            .then((res) => {
                ctrl.loadingPatientInsurance = true;
                ctrl.patientInsurance = res;
                mountIt();
            })
            .finally(() => {
                ctrl.loadingPatientInsurance = false;
            });

        return $q
            .all(promises)
            .then((res) => {
                let branchCheck = $q.when();
                if (res.patient.divisionId !== ctrl.user.active_branch.ID) {
                    branchCheck = authService.changeBranch(res.patient.divisionId);
                }
                return branchCheck
                    .then((branchChangeUser) => {
                        ctrl.patient = angular.copy(res.patient);
                        if (ctrl.patient.gender) {
                            ctrl.patient.gender = capitalize(ctrl.patient.gender);
                        }

                        if (branchChangeUser) {
                            ctrl.user = branchChangeUser;
                        }
                        ctrl.patientPredictiveModelEnabled = isPermitted(
                            PERMISSION_TYPES.PATIENT_PREDICTIVE_MODEL,
                            ctrl.user
                        );
                        // no route exists for getting branch info by ID, using users info instead to infer patients
                        // branch
                        ctrl.patient.branchName = ctrl.user.active_branch.Name;

                        ctrl.patientOtherMedications = _.values(res.otherMedications);

                        _refreshActivities(ctrl.patientId);

                        patientService
                            .getMedications(ctrl.patientId, 'all')
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-shadow
                            .then((res) => {
                                const medications = _.values(res);
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line no-shadow
                                const promises = {};

                                for (let i = 0, len = medications.length; i < len; i += 1) {
                                    if (ctrl.hasNewRxFillNumberEnabled) {
                                        promises[medications[i].medication.id] =
                                            PESService.getPatientMedicationRxNumber(
                                                ctrl.patient.id,
                                                medications[i].medication.id
                                            );
                                    } else {
                                        promises[medications[i].medication.id] = patientService.getMedicationRxs(
                                            ctrl.patientId,
                                            medications[i].medication.id
                                        );
                                    }
                                }
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line no-shadow
                                return $q.all(promises).then((res) => {
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line no-plusplus
                                    for (let i = 0, len = medications.length; i < len; i++) {
                                        if (ctrl.hasNewRxFillNumberEnabled) {
                                            medications[i].patientPrescriptions = {};
                                            medications[i].patientPrescriptions[medications[i].medication.id] =
                                                medications[i].patientPrescriptions[medications[i].medication.id] || {};
                                            medications[i].patientPrescriptions[
                                                medications[i].medication.id
                                            ].rxNumbers =
                                                medications[i].patientPrescriptions[medications[i].medication.id]
                                                    .rxNumbers || [];
                                            medications[i].patientPrescriptions[
                                                medications[i].medication.id
                                            ].rxNumbers = _.values(res[medications[i].medication.id]);
                                        } else {
                                            medications[i].prescriptions = _.values(res[medications[i].medication.id]);
                                        }
                                    }
                                    ctrl.medications = medications;
                                });
                            })
                            .catch(() => {
                                ctrl.medications = [];
                            });

                        patientService
                            .getProfile(ctrl.patientId)
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-shadow
                            .then((res) => {
                                ctrl.profile = res;
                            })
                            .catch(() => {
                                ctrl.profile = [];
                            });

                        rmsService
                            .getReferrals(ctrl.patientId)
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-shadow
                            .then((res) => {
                                ctrl.referrals = res;
                            })
                            .catch(() => {
                                ctrl.referrals = [];
                            });

                        patientService
                            .getPrescriptions(ctrl.patientId)
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-shadow
                            .then((res) => {
                                ctrl.rxs = res;
                            })
                            .catch(() => {
                                ctrl.rxs = [];
                            });

                        patientService
                            .getTherapies(ctrl.patientId)
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-shadow
                            .then((res) => {
                                ctrl.therapies = res;
                            })
                            .catch(() => {
                                ctrl.therapies = [];
                            });

                        // If patient belongs to EHR enabled branch, sync identifiers and/or visits
                        if (ctrl.branchSettings.ehrType) {
                            syncPatientWithEhr(res.identifiers, res.visits);
                        }

                        unassignedMedicationService
                            .getPatient(ctrl.patientId)
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-shadow
                            .then((res) => {
                                ctrl.unassignedMedications = res;
                                if (_.get(ctrl, 'unassignedMedications.Unassignedmedications.length')) {
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line no-shadow
                                    therapyService.getTherapy().then((res) => {
                                        ctrl.companyTherapies = _.values(res);
                                    });
                                }
                            })
                            .catch(() => {
                                ctrl.unassignedMedications = [];
                            });

                        if (ctrl.patientPredictiveModelEnabled) {
                            PESService.getPatientPredictiveModelRisk(ctrl.patientId).then((riskResponse) => {
                                ctrl.patient.predictiveModel = riskResponse || [];
                                mountPredictiveModelComponent();
                            });
                        }
                    })
                    .catch(() => {
                        ctrl.patient = false;
                    });
            })
            .then(() => {
                if (ctrl.patientId) {
                    return PESService.getIdentifiers(ctrl.patientId);
                }
                return [];
            })
            .then((res) => {
                const identifiers = Object.values(res);
                const otherIdRow = identifiers.filter((row) => row.identifierType === 'stm_other_id')[0];
                ctrl.patient.otherId = otherIdRow ? otherIdRow.identifier : null;
            })
            .then(setPatientIdentifiers)
            .catch(() => {
                ctrl.patient = false;
            })
            .finally(() => {
                ctrl.isLoadingPatient = false;
            });
    }

    function getFormattedAddress() {
        if (ctrl.patient) {
            return [
                ctrl.patient.addressLine1,
                ctrl.patient.addressLine2,
                `${ctrl.patient.city}, ${ctrl.patient.state}`,
                ctrl.patient.zip,
            ].join(' ');
        }
        return false;
    }

    function openAddActivityModal() {
        if (!ctrl.patient || !ctrl.therapies) {
            return;
        }

        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'patientProfileAddActivity',
            resolve: {
                modalData() {
                    return {
                        patient: ctrl.patient,
                        categories: _.values(ctrl.therapies),
                    };
                },
            },
        });

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return modalInstance.result.then((res) => {
            if (res) {
                _refreshActivities(ctrl.patient.id);
                $state.go('app.activity', {
                    patientId: ctrl.patient.id,
                    activityId: res.id,
                });
            }
        });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function refreshData(res) {
        if (res) {
            return (
                $q
                    .all({
                        activities: _refreshActivities(ctrl.patient.id),
                        therapies: patientService.getTherapies(ctrl.patient.id),
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-shadow
                        medications: patientService.getMedications(ctrl.patient.id, 'all').then((res) => {
                            const medications = _.values(res);
                            const promises = {};
                            for (let i = 0, len = medications.length; i < len; i += 1) {
                                if (ctrl.hasNewRxFillNumberEnabled) {
                                    promises[medications[i].medication.id] = PESService.getPatientMedicationRxNumber(
                                        ctrl.patient.id,
                                        medications[i].medication.id
                                    );
                                } else {
                                    promises[medications[i].medication.id] = patientService.getMedicationRxs(
                                        ctrl.patient.id,
                                        medications[i].medication.id
                                    );
                                }
                            }
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-shadow
                            return $q.all(promises).then((res) => {
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line no-plusplus
                                for (let i = 0, len = medications.length; i < len; i++) {
                                    if (ctrl.hasNewRxFillNumberEnabled) {
                                        medications[i].patientPrescriptions = {};
                                        medications[i].patientPrescriptions[medications[i].medication.id] =
                                            medications[i].patientPrescriptions[medications[i].medication.id] || {};
                                        medications[i].patientPrescriptions[medications[i].medication.id].rxNumbers =
                                            medications[i].patientPrescriptions[medications[i].medication.id]
                                                .rxNumbers || [];
                                        medications[i].patientPrescriptions[medications[i].medication.id].rxNumbers =
                                            _.values(res[medications[i].medication.id]);
                                    } else {
                                        medications[i].prescriptions = _.values(res[medications[i].medication.id]);
                                    }
                                }
                                return medications;
                            });
                        }),
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-shadow
                        rxs: patientService.getPrescriptions(ctrl.patient.id).then((res) => {
                            return res;
                        }),
                        referrals: rmsService.getReferrals(ctrl.patient.id),
                        unassignedMedications: unassignedMedicationService.getPatient(ctrl.patientId),
                    })
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-shadow
                    .then((res) => {
                        ctrl.activities = angular.copy(res.activities);
                        ctrl.medications = angular.copy(res.medications);
                        ctrl.therapies = angular.copy(res.therapies);
                        ctrl.referrals = angular.copy(res.referrals);
                        ctrl.rxs = angular.copy(res.rxs);
                        ctrl.unassignedMedications = angular.copy(res.unassignedMedications);

                        if (_.get(ctrl, 'unassignedMedications.Unassignedmedications.length') === 0) {
                            ctrl.hideUnassignedMedicationsAlert = true;
                        }
                    })
            );
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function openOtherMedicationsModal() {
        if (ctrl.patientOtherMedications.length > 2) {
            return $uibModal.open({
                component: 'patientOtherMedicationModal',
                resolve: {
                    patientId() {
                        return ctrl.patientId;
                    },
                },
                size: 'lg',
            });
        }
    }

    function updatePatient(update) {
        ctrl.updatingPatient = true;
        return patientService
            .update(ctrl.patient.id, update)
            .then((res) => {
                return res;
            })
            .finally(() => {
                ctrl.updatingPatient = false;
            });
    }

    function updateShowEndedMedications(value) {
        ctrl.showEndedMedications = angular.copy(value);
    }

    function updateShowPatientSection(key) {
        ctrl.showPatientSection[key] = !ctrl.showPatientSection[key];
        sessionStorageService.setOnUser('patientProfile_', ctrl.showPatientSection);
    }

    function viewAttachments() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'patientViewAttachments',
            size: 'md',
            resolve: {
                modalData() {
                    return {
                        files: ctrl.profile.PATIENT_FILES,
                        notes: ctrl.profile.PATIENT_NOTES,
                        numberOfUploads: ctrl.profile.NUMBER_OF_UPLOADS,
                        patientId: ctrl.patient.id,
                        uploadLimit: ctrl.profile.UPLOAD_LIMIT,
                        uploadLimitInt: ctrl.profile.UPLOAD_LIMIT_INT,
                    };
                },
            },
        });

        return modalInstance.result
            .then((res) => {
                ctrl.profile.NUMBER_OF_UPLOADS = res.numberOfUploads;
                ctrl.profile.PATIENT_FILES = res.files;
                return res.data;
            })
            .catch((err) => {
                return err.data;
            });
    }

    async function syncPatientWithEhr(identifiers, visits) {
        const shouldSync = shouldSyncPatient(Object.values(identifiers), visits, ctrl.branchSettings);

        if (!shouldSync) {
            ctrl.patientSynced = true;
            return;
        }

        try {
            ctrl.syncingWithEhr = true;
            const syncResult = await PESService.syncPatientWithEhr(ctrl.patient.id);

            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            identifiers = R.mergeDeepLeft(identifiers, syncResult.identifiers);
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            visits = R.mergeDeepLeft(visits, syncResult.visits);

            // silently catch errors from the FHIR sync. We don't want to erroneously mark the patient
            // as ineligible if they have identifiers and visits, but the patient sync returns an error code
            // (such as not having an FHIR identifier)
        } catch (error) {
            console.error(error);
        } finally {
            const doesExist = R.compose(R.not, R.either(R.isEmpty, R.isNil));

            const isSynced = R.allPass([
                R.propSatisfies(doesExist, 'identifiers'),
                R.anyPass([
                    R.propSatisfies(doesExist, 'visits'),
                    R.complement(R.propSatisfies(R.identity, 'visitRequired')),
                ]),
            ]);

            const visitRequired = ctrl.branchSettings.ehrNoteRequiresVisit;
            ctrl.patientSynced = isSynced({ identifiers, visits, visitRequired });
            ctrl.syncingWithEhr = false;
        }
    }
}

export default PatientController;
