/** @ngInject */
function WorkQueueBranchFilterController(authService, workQueueService, CSRF_ID_VALUE, CSRF_ID_NAME) {
    const ctrl = this;

    ctrl.all_branches = true;
    ctrl.branches = {};
    ctrl.visibleBranches = null;

    ctrl.$onChanges = $onChanges;
    ctrl.doChangeFilter = doChangeFilter;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    function $onChanges(changes) {
        if (changes.allBranches && ctrl.allBranches !== undefined) {
            ctrl.all_branches = angular.copy(ctrl.allBranches);
        }

        if (changes.visibleBranches && Array.isArray(ctrl.visibleBranches)) {
            ctrl.visibleBranches = angular.copy(ctrl.visibleBranches);
        }

        if (changes.branches && ctrl.branches) {
            ctrl.branches = angular.copy(ctrl.branches);
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function doChangeFilter() {
        ctrl.onChangeFilter({
            filter: {
                all_branches: ctrl.all_branches,
                branches: workQueueService.clearObject(ctrl.branches),
            },
        });
    }
}

export default WorkQueueBranchFilterController;
