import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import { Col, Row, FormControl, ControlLabel } from 'react-bootstrap';
import { Formik } from 'formik';
import { FaInfoCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import './PatientMessagingEnrollmentModalStyle.scss';
import formatPhoneNumber from '../../../../utils/formatPhone';
import { errorHandler } from '../../../../utils';
import PESService from '../../../../services/PESService';
import { patientSubscriptionPropType } from 'Lib/types';
import { localize } from 'App/utils';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
    subscriptionValue: patientSubscriptionPropType,
    mobilePhone: PropTypes.string,
    patientId: PropTypes.string,
};

const defaultProps = {
    show: false,
    subscriptionValue: null,
    mobilePhone: '',
    patientId: '',
};

function PatientMessagingEnrollmentModal({ onHide, show, subscriptionValue, mobilePhone, patientId }) {
    const title = 'Patient Messaging';
    const infoMessage1Line1 = subscriptionValue
        ? 'Submitting this form will resend the welcome text message.'
        : `Submitting this form will send an OPT IN text message to the patient.`;
    const infoMessage1Line2 = subscriptionValue
        ? ''
        : `Once the patient texts back YES, they will be enrolled in mobile messaging.`;
    const infoMessage1Line3 = subscriptionValue
        ? ''
        : ` Following enrollment, the patient will ` +
          `receive text message(s) with a hyperlink to the mobile-enabled assessment(s) based on the ` +
          `assessment's due date.`;
    const infoMessage2Line1 = subscriptionValue
        ? ''
        : `The patient may opt out of text communications anytime by replying "UNSUBSCRIBE" to 833-354-1731`;
    const infoMessage2Line2 = subscriptionValue ? '' : ` (the number from which texts will be sent).`;
    const infoMessage2Line3 = subscriptionValue
        ? ''
        : ` The patient must be enrolled prior to creating the activity for the activity to send via text message.`;

    const infoMessage3 = subscriptionValue
        ? ''
        : `Disclaimer: Text messages are unsecure and unencrypted (unless the patient has taken additional security ` +
          `precautions). These text communications are governed by your pharmacy's Notice of Privacy Practices.`;

    const validateForm = (values) => {
        const errors = {};
        if (!values.phone) {
            errors.phone = 'Phone is required';
        } else if (!/^\(\d{3}\) \d{3}-\d{4}$/i.test(values.phone)) {
            errors.phone = 'Invalid phone number.';
        }
        return errors;
    };
    const onSubmit = (values) => {
        if (subscriptionValue && mobilePhone === values.phone) {
            onHide(false);
        } else {
            PESService.updatePatient(patientId, { mobile_phone: values.phone })
                .then(() => {
                    return PESService.subscribeSMS(patientId).then(() => {
                        onHide(true);
                        toast.success('Patient enrolled successfully');
                    });
                })
                .catch((error) => {
                    errorHandler(error);
                    onHide(false);
                });
        }
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static" className="patientMessagingModal">
            <Modal.Header closeButton={false}>
                <Modal.Title className="header" data-testid="patientMessagingModal">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    phone: formatPhoneNumber(mobilePhone) || '',
                }}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(false);
                    onSubmit(values);
                }}
                validate={validateForm}
            >
                {({ errors, touched, handleChange, values, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row>
                                <Col sm={6}>
                                    <div className="enrollment-information">
                                        <div>
                                            Enrolled:&nbsp;
                                            <span>{subscriptionValue?.status === true ? 'Yes' : 'No'}</span>
                                        </div>
                                    </div>
                                </Col>
                                {subscriptionValue?.status && (
                                    <Col sm={6}>
                                        <div className="enrollment-information">
                                            <div>
                                                Enrollment Date:&nbsp;
                                                <span>
                                                    {localize(subscriptionValue?.subscribedOn, 'YYYY-MM-DD hh:mm:ss')}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <ControlLabel
                                        htmlFor="patientMobile"
                                        className={`field-required ${
                                            errors.phone && touched.phone && 'has-label-error'
                                        }`}
                                    >
                                        Patient Mobile Phone
                                    </ControlLabel>{' '}
                                    &nbsp;
                                    <Row>
                                        <Col sm={12}>
                                            <FormControl
                                                type="text"
                                                name="phone"
                                                required
                                                placeholder="(###) ###-####"
                                                label="Patient Mobile"
                                                className={`${errors.phone && touched.phone && 'has-error'}`}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setFieldValue('phone', formatPhoneNumber(e.target.value));
                                                }}
                                                value={values.phone}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="patienteducation-message">
                                        <Col sm={12}>
                                            <FaInfoCircle className="info-icon" /> &nbsp;
                                            <span>
                                                {infoMessage1Line1}
                                                <b>{infoMessage1Line2}</b>
                                                {infoMessage1Line3}
                                            </span>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row className="patienteducation-message">
                                        <Col sm={12}>
                                            <span>
                                                <b>{infoMessage2Line1}</b>
                                                {infoMessage2Line2}
                                            </span>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row className="patienteducation-message">
                                        <Col sm={12}>
                                            <span>{infoMessage2Line3}</span>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row className="patienteducation-message">
                                        <Col sm={12}>
                                            <span>{infoMessage3}</span>
                                            <br />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button
                                bsStyle="default"
                                type="button"
                                onClick={() => {
                                    onHide(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button bsStyle="primary" type="submit" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </form>
                )}
            </Formik>
        </Modal>
    );
}

PatientMessagingEnrollmentModal.propTypes = propTypes;
PatientMessagingEnrollmentModal.defaultProps = defaultProps;

export default PatientMessagingEnrollmentModal;
