import React from 'react';
import Routes from './routes';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'App/components/errors';
import { ThemeProvider } from '@emotion/react';
import styleVars from './sass/base/_vars.scss';
import { cssTransition, ToastContainer } from 'react-toastify';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { isProd, isQA, isStaging, isUAT } from './app/components/config/config';
import { queryClient } from './lib/queryClient';

const Pop = cssTransition({
    enter: 'Toastify__toast__pop-enter',
    exit: 'Toastify__toast__pop-exit',
    appendPosition: true,
});

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={styleVars}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Routes />
                </ErrorBoundary>
                <ToastContainer
                    autoClose={10000}
                    transition={Pop}
                    closeOnClick
                    closeButton
                    draggable={false}
                    pauseOnFocusLoss={false}
                    hideProgressBar
                />
            </ThemeProvider>
            {isStaging || isUAT || isProd || isQA ? null : (
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            )}
        </QueryClientProvider>
    );
}

export default App;
