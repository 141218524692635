import React from 'react';
import { useFormikContext } from 'formik';

const ActivityCount = () => {
    const {
        values: {
            result: { workQueueActivityCount },
        },
    } = useFormikContext();

    return (
        <div className="filter-section filter-section-activity-count">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="filter-section-header control-label">Activity Count</label>
            <div className="activity-count">{workQueueActivityCount.toLocaleString('en-US')}</div>
        </div>
    );
};

export default ActivityCount;
