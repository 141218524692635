import { useQuery } from 'react-query';
import { errorHandler } from '@/app/utils';
import { getActivityTextSchedule } from '@/app/services/PatientMessagingService';

const useActivityTextScheduleQuery = () => {
    return useQuery(['getActivityTextSchedule'], getActivityTextSchedule, {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        initialData: ['Default'],
        onSuccess: (textSchedle = []) => {
            textSchedle.unshift('Default');
            return textSchedle;
        },
    });
};

export default useActivityTextScheduleQuery;
