import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmDeleteChildrenModal = ({ onClose, numberOfAnswers }) => {
    return (
        <Modal show backdrop="static">
            <Modal.Body>
                <h4 className="text-center">WARNING</h4>
                <div className="text-center">
                    Changing this answer will remove {numberOfAnswers} sub-level answer(s).
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onClose(false)}>Cancel</Button>
                <Button bsStyle="primary" onClick={() => onClose(true)}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmDeleteChildrenModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    numberOfAnswers: PropTypes.number.isRequired,
};

export default ConfirmDeleteChildrenModal;
