import { useQuery } from 'react-query';

import { useUserContext } from 'App/contexts/UserContext';
import PESService from 'App/services/PESService';

function useFetchDisabledPatientFields() {
    const user = useUserContext();
    const companyId = user.active_company.ID;
    return useQuery(['companies', companyId, 'disabledPatientFields'], () => PESService.getDisabledFields(companyId), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchDisabledPatientFields;
