import { queryClient } from 'Lib/queryClient';
import * as R from 'ramda';
import { setPatientIdentifiers } from 'App/utils/setPatientIdentifiers';

/** @ngInject */
function ReferralAppealActivityController(_, $filter, $state, $uibModal, moment, rmsService, toastr, __env) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.deleteActivity = deleteActivity;
    ctrl.doChangeReferralDocument = doChangeReferralDocument;
    ctrl.isEffectiveEndDateValid = isEffectiveEndDateValid;
    ctrl.save = save;
    ctrl.saving = false;

    ctrl.isReferralPMEnabled = false;

    function $onChanges(changes) {
        // List of denied Prior Authorizations
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
            ctrl.original = angular.copy(ctrl.activity);

            ctrl.activity.status = ctrl.activity.status ? ctrl.activity.status : 'Pending Submission';
            ctrl.disableInputs = ctrl.activity.status === 'Deleted';

            if (ctrl.original.status === 'Pending') {
                ctrl.original.status = 'Pending Submission';
                ctrl.activity.status = 'Pending Submission';
            }

            if (ctrl.original.status === 'Overturned' || ctrl.original.status === 'Upheld') {
                ctrl.disableActivity = true;
            }

            if (changes.activities && ctrl.activities) {
                ctrl.activities = angular.copy(ctrl.activities);
                ctrl.deniedPAs = [];
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                for (let i = 0, len = ctrl.activities.length; i < len; i++) {
                    ctrl.deniedPAs.push({
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line radix
                        id: parseInt(ctrl.activities[i].id),
                        label: `PA denied on ${$filter('date')(ctrl.activities[i].details.denied_on, 'yyyy/MM/dd')} (${
                            ctrl.activities[i].medicationList
                        })`,
                    });
                }
            }
        }

        if (changes.appealDocuments && ctrl.appealDocuments) {
            ctrl.originalAppealDocuments = angular.copy(_.keyBy(ctrl.appealDocuments, 'name'));
            ctrl.appealDocuments = angular.copy(ctrl.appealDocuments);
            ctrl.appealDocumentOptions = {
                'Denial Letter': false,
                'MD Notes': false,
                'Required clinical information/labs': false,
                'Appeals Letter with MD signature': false,
            };

            ctrl.appealDocuments.forEach((doc) => {
                ctrl.appealDocumentOptions[doc.name] = true;
            });
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function $onInit() {
        ctrl.activityId = $state.params.activityId;
        ctrl.patientId = $state.params.patientId;
        ctrl.referralId = $state.params.referralId;
        ctrl.supportEmail = __env.supportEmail;

        const flags = queryClient.getQueryData(['featureFlags']);
        ctrl.isReferralPMEnabled = R.propOr(false, 'FEATURE_REFERRAL_PROTOCOL_MANAGER', flags);

        return setPatientIdentifiers(ctrl).catch((err) => {
            ctrl.displayedId = '';
            return err;
        });
    }

    function deleteActivity() {
        ctrl.disableInputs = true;

        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'confirmationModal',
            size: 'md',
            windowClass: 'delete-confirmation-modal',
            resolve: {
                message() {
                    return 'Are you sure you want to delete this activity?';
                },
                title() {
                    return 'Delete Activity';
                },
            },
        });

        return modalInstance.result
            .then(() => {
                return rmsService
                    .deleteActivity(ctrl.patientId, ctrl.referralId, ctrl.activityId, ctrl.activity)
                    .then(() => {
                        return $state.go('app.referral', {
                            patientId: ctrl.patientId,
                            referralId: ctrl.referralId,
                        });
                    });
            })
            .catch((err) => {
                ctrl.disableInputs = false;
                toastr.error(err.data.message ? err.data.message : 'An error occurred while deleting this activity.');
                return err;
            });
    }

    function doChangeReferralDocument(document, value) {
        if (value) {
            if (Object.prototype.hasOwnProperty.call(ctrl.originalAppealDocuments, document)) {
                ctrl.appealDocuments.push(ctrl.originalAppealDocuments[document]);
            } else {
                ctrl.appealDocuments.push({
                    name: document,
                });
            }
        } else {
            _.remove(ctrl.appealDocuments, (ad) => {
                return ad.name === document;
            });
        }
    }

    function isEffectiveEndDateValid() {
        if (!ctrl.isReferralPMEnabled) {
            ctrl.activityInformationForm.effective_end_date.$setValidity('invalid', true);
            if (
                ctrl.activity.details.benefit_requires_re_verification === true &&
                ctrl.activity.details.re_verification_schedule_option === 'days_prior'
            ) {
                if (!ctrl.activity.details.effective_end_date) {
                    ctrl.activityInformationForm.effective_end_date.$setValidity('invalid', false);
                    return;
                }

                // Check that the effective end date is two weeks out and set the due_on date to two weeks prior to the
                // effective end date
                ctrl.fortnight = moment().startOf('day').add(14, 'day').toDate();
                ctrl.due_on = moment(ctrl.activity.details.effective_end_date).subtract(14, 'day').toDate();
                ctrl.activityInformationForm.effective_end_date.$setValidity(
                    'invalid',
                    ctrl.fortnight <= ctrl.activity.details.effective_end_date
                );
            }
        }
    }

    function save() {
        const promises = [];
        // eslint-disable-next-line no-multi-assign
        ctrl.disableInputs = ctrl.saving = true;

        promises.push(
            rmsService.updateActivity(
                ctrl.activity.patient_id,
                ctrl.activity.referral_id,
                ctrl.activity.id,
                ctrl.activity
            )
        );
        // See if a new Prior Auth Activity was requested.
        if (!ctrl.isReferralPMEnabled && ctrl.activity.details.benefit_requires_re_verification === true) {
            let dueOn = null;
            if (ctrl.activity.details.re_verification_schedule_option === 'specific_date') {
                dueOn = ctrl.activity.details.re_verification_due_date;
            } else if (ctrl.activity.details.re_verification_schedule_option === 'days_prior') {
                dueOn = moment(ctrl.activity.details.effective_end_date).subtract(14, 'day').toDate();
            }

            promises.push(
                rmsService.createActivity(ctrl.activity.patient_id, ctrl.activity.referral_id, {
                    type: '1',
                    due_on: dueOn,
                    medication: _.map(ctrl.activity.medications, (m) => {
                        return {
                            medication_id: m.medication_id,
                        };
                    }),
                })
            );
        }

        if (ctrl.activity.details.schedule_followup_appeal === 'Yes') {
            promises.push(
                rmsService.createActivity(ctrl.activity.patient_id, ctrl.activity.referral_id, {
                    type: '3',
                    details: {
                        pa_activity_id: ctrl.activity.details.pa_activity_id,
                    },
                    medication: _.map(ctrl.activity.medications, (m) => {
                        return {
                            medication_id: m.medication_id,
                        };
                    }),
                })
            );
        }

        return Promise.all(promises)
            .then(([appeal]) => {
                return Promise.all(
                    ctrl.appealDocuments.map((file) => {
                        return rmsService.createAppealDocument(
                            ctrl.activity.patient_id,
                            ctrl.activity.referral_id,
                            ctrl.activity.id,
                            {
                                name: file.name,
                                answer_id: appeal.answer_id,
                            }
                        );
                    })
                ).catch(() => {
                    throw Error('One or more of your appeal documents did not save properly. Please try again.');
                });
            })
            .then(() => {
                return Promise.all(
                    ctrl.activity.medications.map((medication) => {
                        if (medication.data) {
                            return rmsService.updateMedication(
                                ctrl.activity.patient_id,
                                ctrl.activity.referral_id,
                                medication.id,
                                {
                                    activity_id: medication.activity_id,
                                    medication_id: medication.medication_id,
                                    rx_number_id: medication.id,
                                    data: {
                                        enabled: medication.data.enabled,
                                        authorized_quantity: medication.data.authorized_quantity,
                                        authorized_uom: medication.data.authorized_uom,
                                        ndc_specific_approval: medication.data.ndc_specific_approval,
                                        approved_ndc: medication.data.approved_ndc,
                                        rx_number: medication.data.rx_number,
                                    },
                                }
                            );
                        }
                        return Promise.resolve(undefined);
                    })
                ).catch(() => {
                    throw Error('One or more of your medications did not save properly. Please try again.');
                });
            })
            .then(() => {
                return rmsService.createReferralActivityNotes(ctrl.activityId, {
                    note: null,
                    status: ctrl.activity.status,
                });
            })
            .then((res) => {
                ctrl.disableInputs = false;
                ctrl.saving = false;
                toastr.success('Activity information has been updated successfully.');
                $state.go('app.referral', {
                    patientId: ctrl.patientId,
                    referralId: ctrl.referralId,
                });
                return res;
            })
            .catch((error) => {
                let errorMsg = 'Activity information did not update successfully. Please try again.';
                if (error.status === 400 && error.data.error?.isJoi) {
                    errorMsg = error.data.error.details[0].message;
                }
                toastr.error(errorMsg, {
                    closeButton: true,
                    closeHtml: '<button><i class="fa fa-fw fa-times"></i></button>',
                    tapToDismiss: false,
                    timeOut: 25000,
                });
            });
    }
}

export default ReferralAppealActivityController;
