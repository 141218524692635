import { useQuery } from 'react-query';

import { getActivity } from 'App/services/AMSService';
import { errorHandler } from 'App/utils';

function useFetchActivity(patientId, activityId) {
    return useQuery(['activity', patientId, activityId], () => getActivity(patientId, activityId), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchActivity;
