import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useReducerWithBoundCreators } from 'App/utils';
import { actionCreators, initialState, reducer } from './editProtocolReducer';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultProps = {};

const defaultContext = { state: initialState, dispatch: () => {}, actions: {} };

const EditProtocolContext = createContext(defaultContext);

const { Provider } = EditProtocolContext;

function EditProtocolContextProvider({ children }) {
    const showRetiredAssessments = useIsPermitted(PERMISSION_TYPES.SHOW_RETIRED_ASSESSMENTS);

    const value = useReducerWithBoundCreators(reducer, { ...initialState, showRetiredAssessments }, actionCreators);

    return <Provider value={value}>{children}</Provider>;
}

EditProtocolContextProvider.propTypes = propTypes;
EditProtocolContextProvider.defaultProps = defaultProps;

export { EditProtocolContext, EditProtocolContextProvider, defaultContext };
