import PropTypes from 'prop-types';

const activityPropType = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    assessment: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        settings: PropTypes.shape({
            settings: PropTypes.shape({
                ehrEncounterNote: PropTypes.bool,
                requireMedicationAssociation: PropTypes.bool,
            }),
        }),
        scoring: PropTypes.objectOf(
            PropTypes.shape({
                riskName: PropTypes.string,
                riskDescription: PropTypes.string,
            })
        ),
    }),
    activityEhrNotes: PropTypes.shape({
        sentOn: PropTypes.string,
        failed: PropTypes.bool,
    }),
});

export default activityPropType;
