import PropTypes from 'prop-types';

const otherMedicationPropType = PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    dose: PropTypes.string,
    frequency: PropTypes.string,
    routeOfAdmin: PropTypes.string,
    dateTime: PropTypes.string,
});
const otherMedicationsPropType = PropTypes.arrayOf(otherMedicationPropType);

export default otherMedicationsPropType;
