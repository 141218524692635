import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';

//#region PropTypes
const propTypes = {
    onHide: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    show: PropTypes.bool,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    title: PropTypes.string,
};

const defaultProps = {
    show: false,
    // no-op
    onDelete: () => {},
    message: 'Are you sure you want to delete this item?',
    title: 'Delete Item',
};

//#endregion

const style = { fontSize: '1.8rem' };
function DeleteModal({ onHide, show, onDelete, message, title }) {
    return (
        <Modal show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {
                    // String? render it in a <p>
                    // eslint-disable-next-line no-nested-ternary
                    typeof message === 'string' ? (
                        <p style={style}>{message}</p>
                    ) : // Function? Call it with the style
                    typeof message === 'function' ? (
                        message({ style })
                    ) : (
                        // Anything else? Render it
                        message
                    )
                }

                <p>
                    <strong>This action cannot be undone.</strong>
                </p>
            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle="default" onClick={onHide}>
                    Cancel
                </Button>
                <Button bsStyle="danger" onClick={onDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

DeleteModal.propTypes = propTypes;
DeleteModal.defaultProps = defaultProps;

export default DeleteModal;
