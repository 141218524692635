import React, { useMemo } from 'react';
import * as R from 'ramda';
import useFetchGenderQuery from 'App/hooks/useFetchGenderQuery';
import SelectField from 'Lib/form/SelectField';
import PropTypes from 'prop-types';

const propTypes = {
    label: PropTypes.string,
};

const defaultProps = {
    label: 'Gender',
};

function GenderSelect({ label }) {
    const { isLoading, data } = useFetchGenderQuery();

    const genders = useMemo(() => {
        return data
            ? R.compose(
                  R.sortBy(R.prop('name')),
                  R.map(
                      R.applySpec({
                          id: R.prop('valueName'),
                          name: R.prop('valueName'),
                      })
                  )
              )(data)
            : [];
    }, [data]);

    return (
        <SelectField
            fieldName="searchParams.gender"
            options={genders}
            label={label}
            getOptionValue={R.prop('id')}
            getOptionLabel={R.prop('name')}
            isClearable
            isLoading={isLoading}
        />
    );
}

GenderSelect.propTypes = propTypes;
GenderSelect.defaultProps = defaultProps;

export default GenderSelect;
