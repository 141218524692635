import React, { useEffect, useState } from 'react';
import Main from '../styles/Main/Main';
import { Helmet } from 'react-helmet';
import PageWrapper from '../styles/PageWrapper/PageWrapper';
import PageTitle from '../styles/PageTitle/PageTitle';
import Message from './components/Message';
import ViewMessageHeader from './components/ViewMessageHeader';
import { Button } from 'react-bootstrap';
import DeleteModal from '../DeleteModal/DeleteModal';
import styled from '@emotion/styled';
import UserService from 'App/services/UserService';
import TherigyLoadingSpinner from '../TherigyLoadingSpinner/TherigyLoadingSpinner';
import { toast } from 'react-toastify';
import './ViewMessages.scss';

const FlexCenter = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
`;

const ViewMessages = () => {
    //#region State
    const [messages, setMessages] = useState([]);
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        UserService.getUserMessages()
            .then((res) => {
                setMessages([...res]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const onMessageViewed = (id) => {
        UserService.patchUserMessage({
            messageIds: [id],
            viewed: true,
        }).then((res) => {
            if (res[id]) {
                const tempMessages = messages.map((message) => {
                    if (message.id === id)
                        return {
                            ...message,
                            viewed: 1,
                        };
                    return message;
                });
                setMessages([...tempMessages]);
            }
        });
    };

    const onMessageSelectClick = (e, id) => {
        if (selectedMessages.includes(id)) {
            setSelectedMessages(selectedMessages.filter((ele) => ele !== id));
        } else {
            setSelectedMessages([...selectedMessages, id]);
        }
    };

    const deleteMessage = () => {
        UserService.patchUserMessage({
            messageIds: [...selectedMessages],
            deleted: true,
        })
            .then((res) => {
                const deletedIds = [];
                selectedMessages.map((id) => {
                    if (res[id]) {
                        deletedIds.push(id);
                    }
                    return null;
                });
                const tempMessages = [...messages].filter((message) => !deletedIds.includes(message?.id));
                setMessages([...tempMessages]);
                setSelectedMessages([]);
                setShowDeleteModal(false);
                toast.success('Messages deleted successfully.');
            })
            .catch((err) => {
                console.log(err);
                toast.error('Failed to delete Messages.');
            });
    };
    //#endregion

    return (
        <Main className="view-message-page">
            <Helmet>
                <title>My Therigy Messages</title>
            </Helmet>

            <PageTitle>My Therigy Messages</PageTitle>

            <PageWrapper className="view-message-page-wrapper">
                <ViewMessageHeader />
                {isLoading ? (
                    <FlexCenter>
                        <TherigyLoadingSpinner />
                    </FlexCenter>
                ) : (
                    messages.map((message) => {
                        return (
                            <Message
                                key={message?.id}
                                message={message}
                                slectedMessages={selectedMessages}
                                onMessageSelectClick={onMessageSelectClick}
                                onMessageViewed={onMessageViewed}
                            />
                        );
                    })
                )}
                <br />
                <Button
                    className="view-message-delete-btn"
                    disabled={selectedMessages.length < 1}
                    onClick={() => setShowDeleteModal(true)}
                >
                    Delete
                </Button>

                {showDeleteModal && (
                    <DeleteModal
                        onHide={() => setShowDeleteModal(false)}
                        onDelete={deleteMessage}
                        show={showDeleteModal}
                        message="Deleting messages will remove them from your message board. Do you want to continue?"
                        title="Delete Therigy message"
                    />
                )}
            </PageWrapper>
        </Main>
    );
};

export default ViewMessages;
