import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { useUserContext } from 'App/contexts/UserContext';
import SessionStorageService from 'App/services/SessionStorageService';
import { socket } from 'App/services/SocketService';
import { activityPropType } from './types';

const ActivityLocking = ({ activity }) => {
    const history = useHistory();
    const user = useUserContext();
    const [isLocked, setIsLocked] = useState(false);
    const [lockedName, setLockedName] = useState(null);

    function emitAssignActivity() {
        if (activity.status === 1 || activity.status === 2) {
            return;
        }
        socket.emit('assignActivity', {
            activityId: activity.id,
            user,
        });
    }

    function emitUnassignActivity() {
        socket.emit('unassignActivity', {
            activityId: activity.id,
            userId: user.id,
        });
    }

    function handleConnect() {
        socket.emit('verifyJwt', SessionStorageService.getJwt());
    }

    function handleJwtVerified() {
        emitAssignActivity();
    }

    function handleActivityLocked(lockingUser) {
        setIsLocked(true);
        setLockedName(lockingUser);
    }

    function handleFocus() {
        emitAssignActivity();
    }

    function handleBlur() {
        emitUnassignActivity();
    }

    function handleComponentMount() {
        if (socket.connected) {
            handleConnect();
        }
        
        socket.on('connect', handleConnect);
        socket.on('jwtVerified', handleJwtVerified);
        socket.on('activityLocked', handleActivityLocked);

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);
    }

    function handleComponentUnmount() {
        emitUnassignActivity();

        socket.off('connect', handleConnect);
        socket.off('jwtVerified', handleJwtVerified);
        socket.off('activityLocked', handleActivityLocked);

        window.removeEventListener('focus', handleFocus);
        window.removeEventListener('blur', handleBlur);
    }

    useEffect(() => {
        handleComponentMount();
        return handleComponentUnmount;
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Only run on mount & unmount
    }, []);

    const handleGoBack = () => {
        history.goBack();
    }

    if (!isLocked) {
        return null;
    }

    return (
        <Modal show>
            <Modal.Body className="text-center text-danger">
                <div data-testid="activity-locked-user">
                    This assessment is currently being modified{lockedName && ` by ${lockedName}`}.
                </div>
                <div>Please try again later.</div>
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="primary" onClick={handleGoBack}>
                    Back
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ActivityLocking.propTypes = {
    activity: activityPropType.isRequired,
};

export default ActivityLocking;
