/** @ngInject */
function PatientAllergiesController($scope) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeValue = doChangeValue;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;
    ctrl.removeAllergy = removeAllergy;

    function $onChanges(changes) {
        if (changes.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
            if (!ctrl.patient.id) {
                ctrl.patient.other_allergy = [];
            }
        }
        doChangeValue();
    }

    function doChangeValue() {
        $scope.$emit('addPatientPage_updatePatient', {
            allergy: ctrl.patient.allergy,
            other_allergy: ctrl.patient.other_allergy,
            no_known_allergies: ctrl.patient.no_known_allergies,
        });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
    }

    function removeAllergy(index) {
        ctrl.patient.other_allergy.splice(index, 1);
        doChangeValue();
    }
}

export default PatientAllergiesController;
