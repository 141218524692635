import { useFormikContext } from 'formik';
import React from 'react';

import { TextareaField } from 'Lib/form/TextareaField';
import { activityPropType } from './types';

const maxLength = 2500;

const NewSummaryNote = ({ activity }) => {
    const { values } = useFormikContext();
    const isActivityComplete = activity.status === 1;
    const isActivityDeleted = activity.status === 2;

    return (
        <div>
            <TextareaField
                fieldName="newSummaryNote"
                label={<h4>Summary Note:</h4>}
                maxLength={maxLength}
                disabled={isActivityComplete || isActivityDeleted}
            />
            <div className="text-right">
                {values.newSummaryNote.length}/{maxLength}
            </div>
        </div>
    );
};

NewSummaryNote.propTypes = {
    activity: activityPropType.isRequired,
};

export default NewSummaryNote;
