import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onFilter: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
};

const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    placeHolder: '',
};

const SelectColumnFilterManual = ({ onFilter, name, options, label }) => {
    return (
        <select onChange={onFilter} name={name} className="filter filter-select form-control" aria-label={label}>
            <option value="">All</option>
            {options.map(({ value, text }, i) => (
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/no-array-index-key
                <option key={i} value={value}>
                    {text}
                </option>
            ))}
        </select>
    );
};

SelectColumnFilterManual.propTypes = propTypes;
SelectColumnFilterManual.defaultProps = defaultProps;

export default SelectColumnFilterManual;
