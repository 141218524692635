/**
 * This service handles requests for the RXNCONSO table
 */
/** @ngInject */
function DiagnosisService($http, urlBuilderService) {
    const service = {
        search,
    };

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-shadow
    function search(search) {
        const url = urlBuilderService.build('searchDiagnosis', {
            resource: '',
            diagnosis: '',
        });

        return $http
            .get(url, {
                params: {
                    name: search,
                    code: search,
                },
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err;
            });
    }

    return service;
}

export default DiagnosisService;
