import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { trackingUrl, walkmeUrl, localhostEndpoint, newRelicTracking } from '../components/config/config';

/**
 * Tacking loader component.
 * Loads any tracking scripts like Walkme and Piwik within the <head /> tags for the app.
 */
const TrackingLoader = () => {
    const { search } = useLocation();

    const disableTutorials = useMemo(() => {
        return new URLSearchParams(search).get('disableTutorials') === 'true';
    }, [search]);

    useEffect(() => {
        if (window) {
            // Tracking event array for Piwik
            window._paq = [];
            window._paq.push(['trackPageView']);
            window._paq.push(['enableLinkTracking']);
            window._paq.push(['setTrackerUrl', `${trackingUrl}/piwik.php`]);
            window._paq.push(['setSiteId', '1']);

            // Configure Walkme only if Walkme is enabled
            if (walkmeUrl) {
                window._walkmeConfig = { smartLoad: true };
            }
        }
    }, []);

    return (
        <Helmet>
            {trackingUrl && trackingUrl !== localhostEndpoint && (
                <script type="text/javascript" async defer src={`${trackingUrl}/piwik.js`} />
            )}
            {!disableTutorials && walkmeUrl && (
                <script id="walkme-script" type="text/javascript" async src={walkmeUrl} />
            )}
            {newRelicTracking && [
                <script key="newRelicConfig" type="text/javascript">
                    {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line max-len
                        `window.NREUM || (NREUM = {});NREUM.info = {"licenseKey": "6288db0ffa","applicationID": 1134002031};`
                    }
                </script>,
                <script
                    key="newRelicLoader"
                    type="text/javascript"
                    async
                    src="https://js-agent.newrelic.com/nr-loader-spa-current.min.js"
                />,
            ]}
        </Helmet>
    );
};

export default TrackingLoader;
