import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import TherigyLoadingSpinner from '../../TherigyLoadingSpinner/TherigyLoadingSpinner';

const CenterWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
`;
const propTypes = {};

const defaultProps = {};

function SaveModal() {
    //#region State
    const [show, setShow] = useState(false);
    //#endregion

    //#region Side Effects
    useEffect(() => {
        // only show the modal if it's been loaded for more than 250ms
        const timeout = setTimeout(() => {
            setShow(true);
        }, 250);

        return () => clearTimeout(timeout);
    }, []);
    //#endregion

    return (
        <Modal backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>Saving...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CenterWrapper>
                    <TherigyLoadingSpinner />
                </CenterWrapper>
            </Modal.Body>
        </Modal>
    );
}

SaveModal.propTypes = propTypes;
SaveModal.defaultProps = defaultProps;

export default SaveModal;
