import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import RxFillNumberView from '../RxFillNumberView';
import * as R from 'ramda';
import { cx } from '@emotion/css';
import { preparePresciptions } from 'App/components/PatientPrescription/utils';

const propTypes = {
    handleAddRxNotes: PropTypes.func,
    handleViewRxNotes: PropTypes.func,
    medication: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    patientId: PropTypes.string,
    // TODO: Convert this an an array so we can do better typing
    // eslint-disable-next-line react/forbid-prop-types
    patientPrescriptions: PropTypes.object,
    showEndedMedications: PropTypes.bool,
    viewProfile: PropTypes.bool,
    viewRxNumber: PropTypes.bool,
};

const defaultProps = {
    handleAddRxNotes: () => {},
    handleViewRxNotes: () => {},
    patientPrescriptions: {},
    patientId: '',
    showEndedMedications: false,
    viewProfile: false,
    viewRxNumber: false,
};

function ViewPatientPrescription({
    handleAddRxNotes,
    handleViewRxNotes,
    medication,
    patientId,
    patientPrescriptions,
    showEndedMedications,
    viewProfile,
    viewRxNumber,
}) {
    const filteredPrescriptions = useMemo(
        () => preparePresciptions(patientPrescriptions, medication, viewProfile, showEndedMedications),
        [medication, patientPrescriptions, showEndedMedications, viewProfile]
    );

    const rxPairs = R.values(filteredPrescriptions);
    return (
        <div className={cx({ 'patient-prescription-container': viewRxNumber })}>
            {rxPairs.length > 0
                ? rxPairs.map((group) => {
                      return group.rxNumbers.map((rx, numberIdx) => {
                          return (
                              <div key={`${rx.id || rx._internalUiId}`}>
                                  <RxFillNumberView
                                      patientPrescriptions={filteredPrescriptions}
                                      prescription={rx}
                                      medicationId={medication.id}
                                      rx={numberIdx}
                                      viewProfile={viewProfile}
                                      handleViewRxNotes={() =>
                                          handleViewRxNotes(patientId, patientPrescriptions, numberIdx, medication)
                                      }
                                      patientId={patientId}
                                      medication={medication}
                                      handleAddRxNotes={() => handleAddRxNotes(patientId, rx)}
                                  />
                              </div>
                          );
                      });
                  })
                : null}
        </div>
    );
}

ViewPatientPrescription.propTypes = propTypes;
ViewPatientPrescription.defaultProps = defaultProps;

export default ViewPatientPrescription;
