import createAxios from 'App/utils/createAxios';

const { protocol, host, port } = window.location;
const therigyURL = `${protocol}//${host}${port ? `:${port}` : ''}`;
const phpRequest = createAxios({
    baseURL: `${therigyURL}/app.api.php/api`,
});

export function deleteOtherCondition(patientId, otherConditionId) {
    return phpRequest.delete(`/patient/${patientId}/otherCondition/${otherConditionId}`);
}

export function updateOtherCondition(patientId, otherConditionId, body) {
    return phpRequest.patch(`/patient/${patientId}/otherCondition/${otherConditionId}`, body);
}

export function createOtherCondition(patientId, body) {
    return phpRequest.post(`/patient/${patientId}/otherCondition`, body);
}
