/** @ngInject */
function AssignmentManagerConfig($stateProvider) {
    $stateProvider.state({
        component: 'assignmentManager',
        name: 'app.assignmentManager',
        url: '/assignment-manager',
        params: {
            dateRange: {
                value: null,
                squash: true,
                dynamic: true,
            },
        },
        redirectTo(trans) {
            const user = trans.injector().getAsync('user');

            return user
                .then((res) => {
                    if (
                        res.active_branch.Privileges.CompanyAdmin === true ||
                        res.active_branch.Privileges.BranchAdmin === true ||
                        res.active_branch.Privileges.WorkQueueAssignment === true
                    ) {
                        return true;
                    }
                    throw Error('The user does not have permission to view this page.');
                })
                .catch(() => {
                    return {
                        state: 'app.403',
                        params: {
                            permissionName: 'Assign Tasks in the Work Queue',
                        },
                    };
                });
        },
    });
}

export default AssignmentManagerConfig;
