/** @ngInject */
function AuthConfig($stateProvider, $urlRouterProvider) {
    $stateProvider
        .state('auth', {
            redirectTo: 'auth.login',
            url: '',
            component: 'auth',
        })
        .state('app.403', {
            component: 'therigyForbidden',
            params: {
                permissionName: null,
            },
        })
        .state('app.404', {
            component: 'therigyNotFound',
        })
        .state('app.500', {
            component: 'therigyUnknownError',
        });

    $urlRouterProvider.otherwise(($injector) => {
        $injector.invoke(($state) => {
            $state.go('app.404');
        });
        return true;
    });
}

export default AuthConfig;
