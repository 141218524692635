import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectField from 'Lib/form/SelectField';
import * as R from 'ramda';
import useGetReferralMedication from '../../hooks/useGetReferralMedication';
import { useFormikContext } from 'formik';
import { dateOnlySlashesFormat, formatUtcDate } from 'App/services/DateService';
import TherigyInfoMessage from '../TherigyInfoMessage/TherigyInfoMessage';
import useFetchReferralQuery from 'App/hooks/useFetchReferralQuery';
import CompanyService from 'App/services/CompanyService';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import { referralActivityPropType, referralPropType } from './types';

const activityInProgressStatus = {
    0: ['Pending', 'Created'],
    1: ['Pending', 'Created'],
    2: ['Pending', 'Pending Submission', 'Created', 'Submitted'],
    3: ['Pending', 'Pending Submission', 'Created', 'Submitted'],
};

const propTypes = {
    ...referralPropType,
    ...referralActivityPropType,
    activitiesMap: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])))
        .isRequired,
    validPriorAuthorizations: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            statusDate: PropTypes.string,
            medications: PropTypes.arrayOf(
                PropTypes.shape({
                    medication: PropTypes.shape({
                        name: PropTypes.string,
                    }),
                })
            ),
        })
    ).isRequired,
};

const defaultProps = {};

const ReferralMedicationSelect = ({ patientId, referralId, activities, activitiesMap, validPriorAuthorizations }) => {
    const isReferralPMEnabled = CompanyService.getFeatureFlagStatus(PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER);
    const { data: medications } = useGetReferralMedication(patientId, referralId);
    const { values, setFieldValue, setErrors } = useFormikContext();
    const { data: referral } = useFetchReferralQuery(patientId, referralId);

    useEffect(() => {
        setFieldValue('isReferralPMEnabled', isReferralPMEnabled);
    }, [isReferralPMEnabled, setFieldValue]);

    useEffect(() => {
        setFieldValue('paActivityId', []);
        if (isReferralPMEnabled) {
            const type = values?.type?.id;
            const activityInProgress = activities?.find((it) => {
                return it.type === Number(type) && activityInProgressStatus[type].includes(it.status);
            });
            setFieldValue('showReferralWarningMessage', !!activityInProgress?.id);
            if (type !== null) {
                setFieldValue('medication', referral?.medications.map((it) => ({ id: it.medicationId })) || []);
            }
        } else {
            setFieldValue('medication', []);
            setFieldValue('showReferralWarningMessage', false);
        }
        setTimeout(() => {
            setErrors({});
        }, 0);
    }, [activities, isReferralPMEnabled, referral?.medications, setErrors, setFieldValue, values?.type?.id]);

    const paOptions = useMemo(() => {
        return validPriorAuthorizations.map((it) => {
            return {
                name: `PA denied on ${formatUtcDate({
                    date: it.statusDate,
                    format: dateOnlySlashesFormat,
                })} (${it.medications?.map((i) => i?.medication?.name).join(', ')})`,
                id: it.id,
                raw: it,
            };
        });
    }, [validPriorAuthorizations]);

    const medicationList = useMemo(() => {
        if (!(values?.type?.id >= 0) || !activitiesMap || !medications || isReferralPMEnabled) {
            return [];
        }
        const medList = medications.filter((m) => {
            return activitiesMap[values?.type?.id].indexOf(m.medication) === -1;
        });
        return medList.map((it) => {
            return {
                id: it.medicationId,
                name: `${it?.medication?.name} - ${it?.medication?.therapy?.name}`,
            };
        });
    }, [activitiesMap, isReferralPMEnabled, medications, values?.type?.id]);

    if (values?.type?.name && medicationList.length === 0 && !isReferralPMEnabled) {
        return <TherigyInfoMessage message={`Every medication is already on an existing ${values?.type?.name}.`} />;
    }
    if (isReferralPMEnabled && values?.showReferralWarningMessage) {
        return (
            <div>
                <TherigyInfoMessage
                    message={`There is already a ${values?.type?.name} in process for this referral.`}
                />
                <div className="info-add-activity-modal">
                    <span>
                        <b>Are you sure you want to continue adding another?</b>
                    </span>
                </div>
            </div>
        );
    }

    if (!medicationList.length) {
        return null;
    }

    return (
        <>
            {values.type?.id === 3 && (
                <div>
                    <SelectField
                        options={paOptions}
                        isClearable
                        required
                        label="Upheld Prior Authorization to Appeal"
                        fieldName="paActivityId"
                        getOptionValue={R.prop('id')}
                        getOptionLabel={R.prop('name')}
                    />
                </div>
            )}
            <div>
                <SelectField
                    options={medicationList}
                    isClearable
                    required
                    label={isReferralPMEnabled ? 'Medication' : 'Medications'}
                    fieldName="medication"
                    getOptionValue={R.prop('id')}
                    getOptionLabel={R.prop('name')}
                    isMulti
                />
            </div>
        </>
    );
};

ReferralMedicationSelect.propTypes = propTypes;
ReferralMedicationSelect.defaultProps = defaultProps;

export default ReferralMedicationSelect;
