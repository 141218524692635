import PESService from './PESService';
import { convertUnassignedMedicationsPayload } from '../utils/phpPayloadConversions';

/** @ngInject */
function UnassignedMedicationService($http, urlBuilderService) {
    const service = {
        getPatients,
        getPatient,
    };

    return service;

    function getPatients(companyId) {
        const url = urlBuilderService.build('company/id/unassigned-medication', {
            company: companyId,
            'unassigned-medications': '',
        });

        return (
            $http
                .get(url)
                .then(urlBuilderService.normalize)
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((res) => {
                    if (res.status === 404) {
                        return [];
                    }
                })
        );
    }

    function getPatient(patientId) {
        return (
            PESService.getPatientUnassignedMedications(patientId)
                .then(convertUnassignedMedicationsPayload)
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line consistent-return
                .catch((error) => {
                    if (error.status === 404) {
                        return [];
                    }
                })
        );
    }
}

export default UnassignedMedicationService;
