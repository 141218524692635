import hasLength from 'App/utils/hasLength';
import { cx } from '@emotion/css';
import React from 'react';
import { historyNoteType } from './types';
import PropTypes from 'prop-types';

const propTypes = {
    history: PropTypes.arrayOf(historyNoteType).isRequired,
};

const defaultProps = {};

function History({ history }) {
    if (!hasLength(history)) {
        return null;
    }

    return (
        <>
            {history.map((hist, idx, array) => {
                const isLast = array.length - 1 === idx;
                return (
                    <tr key={hist.id}>
                        <td
                            className={cx('note history', {
                                'no-bottom-border': isLast,
                            })}
                            colSpan={2}
                        >
                            {hist.body}
                        </td>
                        <td
                            className={cx('content time date', {
                                'no-bottom-border': isLast,
                            })}
                        >
                            {hist.completed}
                        </td>
                        <td
                            className={cx('content time date', {
                                'no-bottom-border': isLast,
                            })}
                        >
                            {hist.edited}
                        </td>
                    </tr>
                );
            })}
        </>
    );
}

History.propTypes = propTypes;
History.defaultProps = defaultProps;

export default History;
