function AvailabilityTimepickerController() {
    const ctrl = this;
    ctrl.$onInit = $onInit;
    ctrl.pickerChangeStartHours = changeStartHours;
    ctrl.pickerChangeEndHours = changeEndHours;

    function $onInit() {
        ctrl.pickerStartDate = new Date().setHours(ctrl.day.startHour, ctrl.day.startMinute);
        ctrl.pickerEndDate = new Date().setHours(ctrl.day.endHour, ctrl.day.endMinute);

        ctrl.hstep = 1;
        ctrl.mstep = 15;
        ctrl.ismeridian = true;
    }

    function changeStartHours() {
        ctrl.day.startHour = ctrl.pickerStartDate.getHours();
        ctrl.day.startMinute = ctrl.pickerStartDate.getMinutes();
        ctrl.onChange({ day: ctrl.day });
    }

    function changeEndHours() {
        ctrl.day.endHour = ctrl.pickerEndDate.getHours();
        ctrl.day.endMinute = ctrl.pickerEndDate.getMinutes();
        ctrl.onChange({ day: ctrl.day });
    }
}

export default AvailabilityTimepickerController;
