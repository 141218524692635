import Axios from 'axios';
import * as R from 'ramda';

import SessionStorageService from 'App/services/SessionStorageService';
import errorHandler from './errorHandler';
import getData from './getData';

function authRequestInterceptor(config) {
    const token = SessionStorageService.getJwt();
    if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
    }
    // eslint-disable-next-line no-param-reassign
    config.headers.Accept = 'application/json';
    return config;
}

/**
 * @callback shouldShowToastFn
 * @param {Error} The error message
 * @return {Boolean} Whether or not to show the toast message
 */

/**
 *
 * @param config
 * @param {shouldShowToastFn} shouldShowToastFn
 * @returns {AxiosInstance}
 */
function createAxios(config, { shouldShowToastFn = R.T } = {}) {
    const token = SessionStorageService.getJwt();
    const axios = Axios.create(config);

    axios.interceptors.request.use(authRequestInterceptor);
    axios.interceptors.response.use(getData, (error) => {
        const hasToken = Boolean(token);
        if (token) {
            errorHandler(error, hasToken ? shouldShowToastFn(error) : false);
            return Promise.reject(error);
        }
    });

    return axios;
}

export default createAxios;
