import { useMachine } from '@xstate/react';
import DeleteModal from 'App/components/DeleteModal';
import ActionCell, { ActionButtonLink } from 'App/components/styles/ActionCell';
import SearchColumnFilter from 'App/components/Table/SearchColumnFilter';
import Table from 'App/components/Table/Table';
import * as R from 'ramda';
import React, { useCallback, useEffect, useMemo } from 'react';
import Button from 'react-bootstrap/lib/Button';
import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../../contexts/UserContext';
import { setAlertAsViewed } from '../../../services/AlertService';
import DetailsModal from './DetailsModal';
import stateMachine, { Events, stateMatchers } from './financialAssistanceAlertsMachine';

const propTypes = {};

const defaultProps = {};

function FinancialAssistance() {
    //#region State
    const [state, send] = useMachine(stateMachine);

    const { value, context } = state;

    const { data, isAllSelected, modalProgramId } = context;

    const user = useUserContext();

    const { updateCount } = user;

    //#endregion

    const updateViewedOn = useCallback(
        (alert) => {
            if (R.prop('viewedOn', alert)) {
                return;
            }

            if (R.prop('id', alert)) {
                setAlertAsViewed(alert.uuid)
                    .then(updateCount)
                    .catch(() => {
                        // do nothing
                    });
            }
        },
        [updateCount]
    );

    /**
     * This is only needed so that when moving back and forth between Angular and React, it's possible that the icon
     * can show an unread count, but after transitioning to the React version, the count is incorrect.
     */
    useEffect(() => {
        if (updateCount) {
            updateCount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //#region RunTime Calculations
    const columns = useMemo(() => {
        const anyChecked = R.any(R.prop('checked'), data);
        return [
            {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/no-unstable-nested-components
                Header: () => {
                    const label = isAllSelected ? 'Deselect all messages' : 'Select all messages';
                    return (
                        <span style={{ display: 'flex' }}>
                            <input
                                type="checkbox"
                                checked={isAllSelected}
                                onChange={() => send(Events.TOGGLE_ALL)}
                                title={label}
                                aria-label={label}
                            />
                            <Button
                                bsStyle="link"
                                bsSize="sm"
                                aria-label="Delete selected messages"
                                title="Delete selected messages"
                                disabled={!anyChecked}
                                onClick={() => send(Events.DELETE)}
                            >
                                <FaTrash />
                            </Button>
                        </span>
                    );
                },
                id: 'checked',
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row }) => {
                    return (
                        <input
                            type="checkbox"
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line react/prop-types
                            aria-label={`Toggle ${row.original.subject}`}
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line react/prop-types
                            checked={row.original.checked}
                            onChange={() => {
                                send({
                                    type: Events.TOGGLE_ITEM,
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line react/prop-types
                                    index: row.index,
                                });
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Subject',
                accessor: R.converge(R.concat, [R.prop('subject'), R.prop('message')]),
                Filter: SearchColumnFilter,
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row }) => {
                    const subject = R.pathOr('--', ['original', 'subject'], row);
                    const message = R.pathOr('--', ['original', 'message'], row);
                    return (
                        <>
                            <div>
                                <strong>{subject}</strong>
                            </div>
                            <div>{message}</div>
                        </>
                    );
                },
            },
            {
                Header: 'Last Update',
                accessor: 'createdOn',
                Filter: SearchColumnFilter,
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-shadow, react/prop-types, react/no-unstable-nested-components
                Cell: ({ value }) => {
                    return <span>{value}</span>;
                },
            },
            {
                Header: 'Actions',
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/prop-types, react/no-unstable-nested-components
                Cell: ({ row }) => {
                    const programId = R.path(['original', 'settings', 'programId'], row);
                    return (
                        <ActionCell>
                            <ActionButtonLink
                                onClick={() => {
                                    send({
                                        type: Events.SHOW_DETAILS,
                                        programId,
                                    });
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line react/prop-types
                                    updateViewedOn(row.original);
                                }}
                            >
                                Program Details
                            </ActionButtonLink>
                            <Link
                                to={`/alerts-center/financial-assistance/${programId}/patients`}
                                onClick={() => {
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line react/prop-types
                                    updateViewedOn(row.original);
                                }}
                            >
                                View Available Patients
                            </Link>
                        </ActionCell>
                    );
                },
            },
        ];
    }, [data, isAllSelected, send, updateViewedOn]);
    //#endregion

    return (
        <>
            <h3>Financial Assistance Funds</h3>

            <Table
                columns={columns}
                data={data}
                isLoading={stateMatchers.isPending(value)}
                placeholderText="No alerts found."
            />

            {stateMatchers.isDetailsShowing(value) && (
                <DetailsModal programId={modalProgramId} onHide={() => send(Events.HIDE_DETAILS)} />
            )}

            <DeleteModal
                onHide={() => send(Events.CANCEL)}
                message="Are you sure you want to delete the selected alerts?"
                onDelete={() => send({ type: Events.CONFIRM, onDone: updateCount })}
                show={stateMatchers.isConfirmDelete(value)}
                title="Delete selected alerts?"
            />
        </>
    );
}

FinancialAssistance.propTypes = propTypes;
FinancialAssistance.defaultProps = defaultProps;

export default FinancialAssistance;
