import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import CompanyService from 'App/services/CompanyService';

/** @ngInject */
function PatientReferralManagerController($rootScope, $scope, _) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeValue = doChangeValue;
    ctrl.showReferralManager = false;
    const updatePatientMedicationsEvent = $rootScope.$on('patientMedicationsChanged', updatePatientMedications);
    ctrl.isCustomOptionsEnable = isCustomOptionsEnable;
    ctrl.referralProtocolManagerEnabled = false;

    // Unbind the listener when the controller scope is destroyed
    $scope.$on('$destroy', updatePatientMedicationsEvent);

    function $onChanges(changes) {
        if (changes.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
        }
        if (changes.user) {
            ctrl.user = angular.copy(ctrl.user);
        }

        ctrl.referralProtocolManagerEnabled = CompanyService.getFeatureFlagStatus(
            PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER
        );
    }

    function $onInit() {
        ctrl._ = _;
        ctrl.custom_referral_check = {};
        ctrl.referralProtocolManagerEnabled = CompanyService.getFeatureFlagStatus(
            PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER
        );

        if (ctrl.referralProtocolManagerEnabled) {
            ctrl.patient.referral_select = 'no';
        } else {
            ctrl.showReferralManager = true;
        }
    }

    function doChangeValue() {
        $scope.$emit('addPatientPage_updatePatient', {
            referralType: ctrl.patient.referral_select,
            referralConfig: ctrl.custom_referral_check,
        });
    }

    function isCustomOptionsEnable() {
        return (
            ctrl.medicationsAdded.length > 1 &&
            ctrl.referralProtocolManagerEnabled &&
            !ctrl.user.company_permissions.OnboardAfterReferral
        );
    }

    function updatePatientMedications(event, data) {
        ctrl.medicationsAdded = data.medicationsAdded;
        ctrl.prescriptionsAdded = data.prescriptionsAdded;
        ctrl.prescriptionCount = [];
        ctrl.medicationPrescriptionMap = {};
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = ctrl.medicationsAdded.length; i < len; i++) {
            const medication = ctrl.medicationsAdded[i];
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-multi-assign
            const mapping = (ctrl.medicationPrescriptionMap[medication.id] = {});
            mapping.medication = medication;
            mapping.prescriptions = [];
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-plusplus
            for (let x = 0, xlen = ctrl.prescriptionsAdded.length; x < xlen; x++) {
                if (typeof ctrl.prescriptionsAdded[x][medication.id] !== 'undefined') {
                    mapping.prescriptions = ctrl.prescriptionsAdded[x][medication.id];
                    ctrl.prescriptionCount = ctrl.prescriptionCount.concat(ctrl.prescriptionsAdded[x][medication.id]);
                }
            }
        }
    }
}

export default PatientReferralManagerController;
