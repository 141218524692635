import CompanyService from 'App/services/CompanyService';

/**
 * A thunk version of getFeatureFlagStatus. Useful, particularly, in the isPermitted function.
 * @param flagName
 * @return {function(): boolean}
 */
const isFlagEnabled = (flagName) => () => CompanyService.getFeatureFlagStatus(flagName);

export default isFlagEnabled;
