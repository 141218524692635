import React from 'react';
import PropTypes from 'prop-types';
import hasLength from 'App/utils/hasLength';
import { noteType } from './types';
import { cx } from '@emotion/css';
import History from './History';

const propTypes = {
    note: noteType.isRequired,
    isLast: PropTypes.bool,
};

const defaultProps = {
    isLast: false,
};

function Note({ note, isLast }) {
    const hasHistories = hasLength(note.history);
    return (
        <table>
            <thead>
                <tr>
                    <th className="header">Activity/Subject</th>
                    <th className="header completed">Completed By</th>
                    <th className="header date">Completed On</th>
                    <th className="header date">Last Edited</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="content">{note.name}</td>
                    <td className="content completed">{note.user}</td>
                    <td className="content date">{note.completed}</td>
                    <td className="content date">{note.edited}</td>
                </tr>
                <tr>
                    <td
                        className={cx('note', {
                            'no-bottom-border': isLast || !hasHistories,
                        })}
                        colSpan={2}
                    >
                        {note.body}
                    </td>
                    <td
                        className={cx('content time date', {
                            'no-bottom-border': isLast || !hasHistories,
                        })}
                    />
                    <td
                        className={cx('content time date', {
                            'no-bottom-border': isLast || !hasHistories,
                        })}
                    />
                </tr>
                <History history={note.history} />
            </tbody>
        </table>
    );
}

Note.propTypes = propTypes;
Note.defaultProps = defaultProps;

export default Note;
