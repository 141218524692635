import React, { useState } from 'react';
import * as R from 'ramda';
import { ControlLabel } from 'react-bootstrap';
import Select from 'react-select';
import MedicationManagement from 'App/components/MedicationManagement/MedicationManagement';
import PropTypes from 'prop-types';
import PlaceHolderMessage from 'App/components/styles/PlaceholderMessage/PlaceHolderMessage';
import FormGroup from 'react-bootstrap/lib/FormGroup';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    therapies: PropTypes.array.isRequired,
};

const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    therapies: [],
};

function TherapySearch(props) {
    const [searchValue, setSearchValue] = useState(false);

    return (
        <div>
            <FormGroup style={{ width: '500px' }}>
                <ControlLabel htmlFor="therapy-select">Select a Therapy</ControlLabel>
                <Select
                    classNamePrefix="react-select"
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/destructuring-assignment
                    options={props.therapies}
                    getOptionValue={R.prop('id')}
                    getOptionLabel={R.prop('name')}
                    inputId="therapy-select"
                    isClearable={false}
                    onChange={(option) => {
                        setSearchValue(option.id);
                    }}
                />
            </FormGroup>
            {searchValue ? (
                <MedicationManagement searchValue={[searchValue]} searchType="therapy" />
            ) : (
                <PlaceHolderMessage placeholderText="Select a therapy." />
            )}
        </div>
    );
}

TherapySearch.propTypes = propTypes;
TherapySearch.defaultProps = defaultProps;

export default TherapySearch;
