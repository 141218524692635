import React from 'react';
import { Col, Row } from 'react-bootstrap';

import CustomMenuField from 'Lib/form/CustomMenu';
import { TextField } from 'Lib/form/TextField';

import CommonPatientFieldWrapper from './CommonPatientFieldWrapper';
import { PhoneField } from './PhoneField';
import SectionTitleStyle from './SectionTitleStyle';

const EmergencyContactForm = () => {
    return (
        <CommonPatientFieldWrapper fieldName="ecFirstName">
            {() => (
                <div>
                    <SectionTitleStyle>
                        <h2>Emergency Contact Information</h2>
                    </SectionTitleStyle>

                    <Row>
                        <CommonPatientFieldWrapper fieldName="ecFirstName">
                            {(commonProps) => (
                                <Col md={3}>
                                    <TextField {...commonProps} label="First Name" placeholder="First Name" />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        <CommonPatientFieldWrapper fieldName="ecLastName">
                            {(commonProps) => (
                                <Col md={3}>
                                    <TextField {...commonProps} label="Last Name" placeholder="Last Name" />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                    </Row>
                    <Row>
                        <CommonPatientFieldWrapper fieldName="ecTelephone">
                            {(commonProps) => (
                                <Col md={3}>
                                    <PhoneField {...commonProps} label="Home Phone" />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                        <CommonPatientFieldWrapper fieldName="ecMobilePhone">
                            {(commonProps) => (
                                <Col md={3}>
                                    <PhoneField {...commonProps} label="Mobile Phone" />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                    </Row>
                    <Row>
                        <CommonPatientFieldWrapper fieldName="ecRelationship">
                            {(commonProps) => (
                                <Col md={3}>
                                    <CustomMenuField
                                        {...commonProps}
                                        label="Relationship"
                                        menuName="ec_relationship"
                                        isClearable
                                    />
                                </Col>
                            )}
                        </CommonPatientFieldWrapper>
                    </Row>
                </div>
            )}
        </CommonPatientFieldWrapper>
    );
};

export default EmergencyContactForm;
