import { useEffect } from 'react';
import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';
import useProfileDataState from './useProfileDataState';
import { fetchReferralActivityNotes } from 'App/services/RMSService';
import * as R from 'ramda';

/**
 * Custom hook which make the http call to fetch activity notes
 * @param {string} activityId
 * @param {string} activityType
 * @returns {Object}
 */
function useGetActivityNotes(activityId, activityType) {
    const {
        data: activityNotes,
        setData: setActivityNotes,
        isLoading: isActivityNotesLoading,
        triggerRefetch: triggerActivityNoteRefetch,
        refetch,
        setIsLoading,
    } = useProfileDataState([]);

    useEffect(() => {
        let promiseObj;

        if (activityType === 'Referral' || activityType === 'REF') {
            promiseObj = fetchReferralActivityNotes(activityId).then(
                R.compose(
                    R.reverse,
                    R.map(
                        R.applySpec({
                            createdBy: (note) => `${note?.createdName?.firstName} ${note?.createdName?.lastName}`,
                            createdOn: R.prop('createdOn'),
                            id: R.prop('id'),
                            notes: R.prop('note'),
                            status: R.prop('status'),
                        })
                    )
                )
            );
        } else {
            promiseObj = PESService.getActivityNotes(activityId).then(R.values);
        }
        promiseObj
            .then(setActivityNotes)
            .catch(errorHandler)
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityId, activityType, refetch]);

    return {
        activityNotes,
        isActivityNotesLoading,
        triggerActivityNoteRefetch,
    };
}

export default useGetActivityNotes;
