/** @ngInject */
function ReferralManagerSettingsAddRxStatusRuleController(_, $q, divisionService, medicationsService, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;

    ctrl.doChangeTherapy = doChangeTherapy;
    ctrl.submit = submit;
    ctrl.updateTherapyMedications = updateTherapyMedications;
    ctrl.updateTherapyPolls = updateTherapyPolls;

    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.therapies) {
                ctrl.therapies = angular.copy(ctrl.resolve.therapies);
            }

            if (ctrl.resolve.branchId) {
                ctrl.branchId = ctrl.resolve.branchId;
            }

            if (ctrl.resolve.rule && ctrl.resolve.rule.id) {
                ctrl.rule = angular.copy(ctrl.resolve.rule);
                ctrl.therapyId = ctrl.rule.group_id;
                ctrl.medication = ctrl.rule.medication_id;
                ctrl.poll = ctrl.rule.poll_id;
                ctrl.status = ctrl.rule.status;
                ctrl.days_out = ctrl.rule.days_out;
                doChangeTherapy(ctrl.therapyId);
            }
        }
    }

    function doChangeTherapy() {
        updateTherapyMedications();
        updateTherapyPolls();
    }

    function submit() {
        if (typeof ctrl.days_out !== 'number') {
            ctrl.days_out = null;
            return false;
        }

        const body = {
            medication_id: ctrl.medication,
            status: ctrl.status,
            group_id: ctrl.therapyId,
            poll_id: ctrl.poll,
            days_out: ctrl.days_out,
        };

        if (ctrl.rule && ctrl.rule.id) {
            return rmsService.updateRxStatusRule(ctrl.branchId, ctrl.rule.id, body).then((res) => {
                ctrl.close({ $value: res });
                return res;
            });
        }
        return rmsService.createRxStatusRule(ctrl.branchId, body).then((res) => {
            ctrl.close({ $value: res });
            return res;
        });
    }

    function updateTherapyMedications() {
        ctrl.loadingMedications = true;
        return medicationsService
            .get(ctrl.therapyId)
            .then((res) => {
                ctrl.medications = _.values(res);
                return res;
            })
            .finally(() => {
                ctrl.loadingMedications = false;
            });
    }

    function updateTherapyPolls() {
        ctrl.loadingPolls = true;
        return divisionService
            .getPolls({
                therapyId: ctrl.therapyId,
            })
            .then((res) => {
                ctrl.polls = res;
                return res;
            })
            .finally(() => {
                ctrl.loadingPolls = false;
            });
    }
}

export default ReferralManagerSettingsAddRxStatusRuleController;
