/**
 * Controller for rx notes modal.
 * @param medicationsService
 */
/** @ngInject */
function MedicationAddNoteController(medicationsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.cancelForm = cancelForm;
    ctrl.submitForm = submitForm;
    ctrl.note = null;
    ctrl.saveFailed = false;

    /**
     * Sets values in resolve to properties.
     * @param changes
     */
    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.patientId) {
                ctrl.patientId = angular.copy(ctrl.resolve.patientId);
            }

            if (ctrl.resolve.medicationId) {
                ctrl.medicationId = angular.copy(ctrl.resolve.medicationId);
            }

            if (ctrl.resolve.statusId) {
                ctrl.statusId = angular.copy(ctrl.resolve.statusId);
            }
        }
    }

    /**
     * Sends api request to save a note to the specified rx number
     */
    function submitForm() {
        if (typeof ctrl.statusId !== 'string' && typeof ctrl.resolve === 'function') {
            ctrl.resolve({
                $value: ctrl.prescription,
            });
        }

        ctrl.savingNote = true;
        return medicationsService
            .addNote(ctrl.patientId, ctrl.medicationId, ctrl.statusId, ctrl.note)
            .then(() => {
                ctrl.saveFailed = false;
                ctrl.close({
                    $value: {
                        saved: true,
                    },
                });
            })
            .catch(() => {
                ctrl.saveFailed = true;
            })
            .finally(() => {
                ctrl.savingNote = false;
            });
    }

    /**
     * Closes the add note modal.
     */
    function cancelForm() {
        ctrl.dismiss({ $value: 'cancel' });
    }
}

export default MedicationAddNoteController;
