import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateProtocolPage from './CreateProtocolPage';
import EditProtocolPage from './EditProtocolPage';
import { ProtocolManagerContextProvider } from './ProtocolManagerContext';
import ProtocolManagerHomePage from './ProtocolManagerHomePage';

function ProtocolManagerRouter() {
    const { path } = useRouteMatch();

    return (
        <div data-testid="protocol-manager-rewrite">
            <ProtocolManagerContextProvider>
                <Switch>
                    <Route path={`${path}/protocol/:protocolId`} component={EditProtocolPage} />
                    <Route path={`${path}/protocol`} component={CreateProtocolPage} />
                    <Route exact path={path} component={ProtocolManagerHomePage} />
                </Switch>
            </ProtocolManagerContextProvider>
        </div>
    );
}

export default ProtocolManagerRouter;
