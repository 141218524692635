import angular from 'angular';

import CustomMenuController from './custom-menu/custom-menu.controller';
import DatepickerController from './datepicker/datepicker.controller';
import InputConfig from './input.config';
import MoneyInputController from './money-input/money-input.controller';
import StatesController from './states/states.controller';
import TextInputController from './text-input/text-input.controller';
import StatesService from '../../services/states.service';
import PatientLabelsInputController from './patient-labels-input/patient-labels-input.controller';
import PatientReferralInputController from './patient-referral-input/patient-referral-input.controller';

export default angular
    .module('components.input', ['ngMessages', 'ui.bootstrap', 'ui.mask'])
    .config(InputConfig)
    .component('customMenu', {
        bindings: {
            default: '@',
            label: '@',
            menuName: '@',
            onChangeValue: '&',
            ngDisabled: '<',
            ngRequired: '<',
            readonly: '<',
            placeholder: '@',
            showDefault: '<',
            tabIndex: '<',
        },
        controller: CustomMenuController,
        require: {
            form: '^form',
            model: 'ngModel',
        },
        template: require('./custom-menu/custom-menu.html'),
    })
    .component('datepicker', {
        bindings: {
            automationId: '@',
            dateFormat: '@',
            datepickerOptions: '<',
            errors: '<',
            label: '@',
            mask: '@',
            ngDisabled: '<',
            ngReadonly: '<',
            ngRequired: '<',
            onChangeValue: '&',
            placeholder: '@',
            subLabel: '<',
            tabIndex: '<',
        },
        controller: DatepickerController,
        require: {
            form: '^form',
            model: 'ngModel',
        },
        template: require('./datepicker/datepicker.html'),
    })
    .component('moneyInput', {
        bindings: {
            inputClass: '@',
            name: '@',
            ngDisabled: '<',
            placeholder: '@',
        },
        controller: MoneyInputController,
        require: {
            model: 'ngModel',
        },
        template: require('./money-input/money-input.html'),
    })
    .component('patientLabelsInput', {
        bindings: {
            isLocked: '<',
            patientLabels: '<',
            emptyPatientLabel: '<',
            tabIndex: '@',
            isRequired: '<',
            form: '<',
        },
        require: {
            form: '^form',
        },
        controller: PatientLabelsInputController,
        template: require('./patient-labels-input/patient-labels-input.html'),
    })
    .component('patientReferralInput', {
        bindings: {
            isLocked: '<',
            patientReferral: '<',
            tabIndex: '@',
        },
        controller: PatientReferralInputController,
        template: require('./patient-referral-input/patient-referral-input.html'),
    })
    .component('states', {
        bindings: {
            allowClear: '<',
            editable: '<',
            label: '@',
            ngRequired: '<',
            onChangeState: '&',
            placeholder: '@',
            requiredMessage: '@',
            minChar: '@',
            tabIndex: '<',
        },
        controller: StatesController,
        require: {
            form: '^form',
            model: 'ngModel',
        },
        template: require('./states/states.html'),
    })
    .component('textInput', {
        bindings: {
            errors: '<',
            id: '@',
            label: '@',
            mask: '@',
            min: '@',
            max: '@',
            ngDisabled: '<',
            ngMaxlength: '@',
            ngMinlength: '@',
            ngPattern: '@',
            ngReadonly: '<',
            ngRequired: '<',
            onChangeValue: '&',
            placeholder: '@',
            subLabel: '<',
            tabIndex: '<',
            filter: '@',
            type: '@',
        },
        controller: TextInputController,
        require: {
            form: '^form',
            model: 'ngModel',
        },
        template: require('./text-input/text-input.html'),
    })
    .service('statesService', StatesService).name;
