import CompanyService from '../../../../services/CompanyService';

/** @ngInject */
function TherapyStatusHistoryController($q, dateService, therapyService) {
    const ctrl = this;
    ctrl.history = null;

    ctrl.$onChanges = $onChanges;
    ctrl.addNote = addNote;
    ctrl.viewNotes = viewNotes;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.category && ctrl.category.id) {
            ctrl.loading = true;
            return $q
                .all({
                    history: therapyService.getHistory(ctrl.category.patient_id, ctrl.category.id),
                })
                .then((results) => {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    results.customMenu = CompanyService.getCustomMenu('therapy_opt_out_reasons');

                    if (Object.keys(results.history).length > 0) {
                        const history = [];
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-restricted-syntax
                        for (const p in results.history) {
                            if (Object.prototype.hasOwnProperty.call(results.history, p)) {
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line no-restricted-globals
                                const startDate = !isNaN(dateService.create(results.history[p].Start))
                                    ? dateService.create(results.history[p].Start)
                                    : '';
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line no-restricted-globals
                                const endDate = !isNaN(dateService.create(results.history[p].End))
                                    ? dateService.create(results.history[p].End)
                                    : '';
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line no-param-reassign
                                results.history[p].Start = startDate;
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line no-param-reassign
                                results.history[p].End = endDate;
                                if (results.history[p].EndReason) {
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line no-param-reassign
                                    results.history[p].EndReason =
                                        results.customMenu[results.history[p].EndReason].valueName;
                                }
                                history.push(results.history[p]);
                            }
                        }
                        ctrl.history = history;
                    } else {
                        ctrl.history = false;
                    }
                })
                .finally(() => {
                    ctrl.loading = false;
                });
        }
    }

    function addNote(therapyStatusId) {
        ctrl.mode = 'therapy-status-add-note';
        ctrl.therapyStatusId = therapyStatusId;
    }

    function viewNotes(therapyStatusId) {
        ctrl.mode = 'therapy-status-view-notes';
        ctrl.therapyStatusId = therapyStatusId;
    }
}

export default TherapyStatusHistoryController;
