import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { isFieldValid } from 'App/features/ProtocolManager/utils';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import Select from 'react-select';
import * as R from 'ramda';
import { useFormikContext } from 'formik';

import useEditProtocolContext from 'App/features/ProtocolManager/EditProtocolPage/useEditProtocolContext';

const propTypes = {};

const defaultProps = {};

function ActivityToTrigger() {
    const formik = useFormikContext();
    const {
        state: { availableRuleAssessments },
    } = useEditProtocolContext();
    const updatedAvailableRuleAssessments = availableRuleAssessments.map((assessment) => {
        const newAssessment = { ...assessment };
        if (assessment.label === 'Referral') {
            newAssessment.options = assessment.options.filter((option) => option.name !== 'Appeal');
        }
        return { ...newAssessment };
    });
    return (
        <FormGroup validationState={isFieldValid('activityToTrigger', formik)}>
            <div className="label-tooltip-group">
                <ControlLabel htmlFor="activity-to-trigger" className="field-required">
                    Activity to Trigger
                </ControlLabel>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="activity-trigger-tooltip">The activity that will be triggered</Tooltip>}
                >
                    <FaInfoCircle className="info-icon" />
                </OverlayTrigger>
            </div>
            <Select
                inputId="activity-to-trigger"
                className="form-control react-select"
                options={updatedAvailableRuleAssessments}
                value={formik.values.activityToTrigger}
                getOptionValue={R.prop('id')}
                getOptionLabel={R.prop('name')}
                onChange={(option) => formik.setFieldValue('activityToTrigger', option)}
                onBlur={() => formik.setFieldTouched('activityToTrigger')}
                placeholder="Select activity"
            />
        </FormGroup>
    );
}

ActivityToTrigger.propTypes = propTypes;
ActivityToTrigger.defaultProps = defaultProps;

export default ActivityToTrigger;
