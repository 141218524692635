import { useQuery } from 'react-query';
import { getTherapy } from 'App/services/TherapyService';

function useGetTherapy() {
    return useQuery(['workQueue', 'therapy'], () => getTherapy(), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useGetTherapy;
