/** @ngInject */
function SocketService(io, __env, $rootScope) {
    const socket = io(`${__env.amsSocketEndpoint}:${__env.amsSocketPort}`, {
        forceNew: true,
        path: __env.amsSocketPath,
        reconnection: true,
        reconnectionAttempts: 3,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        transports: ['websocket'],
    });
    function connected() {
        return socket.connected;
    }
    function reconnectIO() {
        socket.open();
    }

    function on(eventName, callback) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        socket.on(eventName, function () {
            const args = arguments; // eslint-disable-line prefer-rest-params
            $rootScope.$apply(() => {
                callback.apply(socket, args);
            });
        });
    }
    function emit(eventName, data, callback) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        socket.emit(eventName, data, function () {
            const args = arguments; // eslint-disable-line prefer-rest-params
            $rootScope.$apply(() => {
                if (callback) {
                    callback.apply(socket, args);
                }
            });
        });
    }

    function removeAllListeners() {
        return socket.removeAllListeners();
    }

    function close() {
        socket.close();
    }

    return {
        reconnectIO,
        connected,
        emit,
        on,
        removeAllListeners,
        close,
    };
}

export default SocketService;
