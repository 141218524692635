import { useQuery } from 'react-query';

import ActivityMedicationService from 'App/services/ActivityMedicationService';

function useFetchMedicationAssociations(patientId, activityId) {
    return useQuery(
        ['medicationAssociations', patientId, activityId],
        () => {
            /**
             * Patient ID is optional here to support the AssessmentPreview, which needs to render without a patientId
             */
            return !patientId ? null : ActivityMedicationService.getMedicationAssociations(patientId, activityId);
        },
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchMedicationAssociations;
