/** @ngInject */
function ReferralManagerSettingsConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.referralManagerSettings',
        url: '/referral-manager-settings',
        views: {
            '': 'referralManagerSettings',
            'pageTitle@^': {
                template: 'Referral Manager Settings',
            },
        },
        redirectTo() {
            return 'app.workQueue';
        },
    });
}

export default ReferralManagerSettingsConfig;
