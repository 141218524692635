import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import dayjs from 'dayjs';

import './PatientMessagingEnrollmentModalStyle.scss';

const propTypes = {
    queueReason: PropTypes.string,
    message: PropTypes.string,
    sentOn: PropTypes.string,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};
const defaultProps = {
    queueReason: '',
    message: '',
    sentOn: '',
};

function PatientMessagingViewModal({ show, onHide, queueReason, message, sentOn }) {
    const title = useMemo(() => {
        if (queueReason === 'massText') {
            return 'Mass Text';
        }
        if (queueReason === 'oneTime') {
            return 'One-Time Text';
        }
    }, [queueReason]);
    return (
        <Modal show={show} onHide={onHide} backdrop="static" backdropClassName="patientMessagingViewModal">
            <Modal.Header closeButton={false}>
                <Modal.Title className="header">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Sent: {dayjs(sentOn).format('YYYY-MM-DD')}</p>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button id="close" bsStyle="default" type="button" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

PatientMessagingViewModal.propTypes = propTypes;
PatientMessagingViewModal.defaultProps = defaultProps;

export default PatientMessagingViewModal;
