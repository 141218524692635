import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import Page from 'App/components/Page';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import { Button, Col, Row } from 'react-bootstrap';
import useFetchPatient from 'App/hooks/useFetchPatient';
import NotFound from 'App/components/errors/NotFound';
import PatientProfileTitle from 'App/components/PatientProfile/PatientProfileTitle';
import ReferralInformationForm from 'App/components/ReferralInformationForm/ReferralInformationForm';
import ReferralMedicationTable from 'App/components/ReferralMedicationTable/ReferralMedicationTable';
import FlexContainer from 'App/common/styles/FlexContainer';
import ReferralActivityTable from 'App/components/ReferralActivityTable/ReferralActivityTable';
import ReferralCommunicationTable from 'App/components/ReferralCommunicationTable/ReferralCommunicationTable';
import { FaHistory } from 'react-icons/fa';
import IconWrapper from 'App/common/styles/IconWrapper';
import ReferralHistoryModal from 'App/components/ReferralHistoryModal/ReferralHistoryModal';

const propTypes = {};

const defaultProps = {};

function ReferralDetail() {
    const { patientId, referralId } = useParams();
    const { isLoading, isError, data: patient } = useFetchPatient(patientId);
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    if (isLoading) {
        return <TherigyLoadingSpinner />;
    }

    if (isError) {
        return (
            <Page
                title="Not Found"
                showPageTitle={false}
                permissionType={PERMISSION_TYPES.ENABLE_REACT_REFERRAL_DETAIL}
            >
                <NotFound />
            </Page>
        );
    }

    return (
        <Page title="Referral" permissionType={PERMISSION_TYPES.ENABLE_REACT_REFERRAL_DETAIL}>
            <div className="container page-wrapper">
                <Row>
                    <Col md={12}>
                        <PatientProfileTitle patient={patient} isReferralPage showEhrStatus={false} />
                    </Col>
                </Row>
                <nav className="profile-actions">
                    <ul className="list-inline">
                        <li>
                            <Link to={`/patient/${patientId}`}>Back to Patient Profile</Link>
                        </li>
                        <li>
                            <Link to={`/patient/${patientId}/referral-manager`}>Back to Referral Manager</Link>
                        </li>
                    </ul>
                </nav>
                <FlexContainer>
                    <h3>Referral Information</h3>
                    <div className="add-create-action">
                        <Button bsStyle="link" onClick={() => setShowHistoryModal(true)}>
                            <IconWrapper>
                                <FaHistory />
                            </IconWrapper>
                            Show Referral Status History
                        </Button>
                    </div>
                </FlexContainer>

                <ReferralInformationForm />
                <ReferralMedicationTable patientId={patientId} referralId={referralId} />
                <ReferralActivityTable patientId={patientId} referralId={referralId} />
                <ReferralCommunicationTable patientId={patientId} referralId={referralId} />
                {showHistoryModal && (
                    <ReferralHistoryModal
                        show={showHistoryModal}
                        onHide={() => setShowHistoryModal(false)}
                        patientId={patientId}
                        referralId={referralId}
                    />
                )}
            </div>
        </Page>
    );
}

ReferralDetail.propTypes = propTypes;
ReferralDetail.defaultProps = defaultProps;

export default ReferralDetail;
