/** @ngInject */
function AssessmentQuestionNumberController(amsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeAnswer = doChangeAnswer;
    ctrl.score = null;

    // TODO: Rapid3 hack
    ctrl.hideScoringGroups = [
        32, // QOL Assessment
        48, // QOL Assessment
        52, // QOL Assessment
        56, // QOL Assessment
        97, // RAPID3 Questionnaire
    ];

    function $onChanges(changes) {
        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);
            ctrl.response = angular.copy(ctrl.question.response);
            ctrl.score = getScore();
        }
    }

    function doChangeAnswer() {
        ctrl.score = getScore();
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-globals
        const response = isNaN(ctrl.response) || ctrl.response === null ? '' : ctrl.response;
        ctrl.onChangeAnswer({
            answer: response,
        });
    }

    function getScore() {
        if (ctrl.question.attributes.risk_config) {
            const question = angular.copy(ctrl.question);
            question.response = ctrl.response;
            const scores = amsService.getScores(ctrl.activity.assessment.scoring, [question]);
            if (scores[ctrl.question.attributes.risk_config]) {
                return scores[ctrl.question.attributes.risk_config].value;
            }
            ctrl.score = 0;
            return 0;
        }
        return null;
    }
}

export default AssessmentQuestionNumberController;
