import { getNavigationForUser } from 'App/utils';
import * as R from 'ramda';
import { mapLocationActive } from '../utils/getNavigationForUser/getNavigationForUser';

/** @ngInject */
function AppNavController(_, $state, $rootScope) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.isInsightsState = isInsightsState;
    ctrl.onChangeAccount = onChangeAccount;
    ctrl.search = search;
    ctrl.navData = [];

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line default-param-last
    function getNavData(currentUrl = '', user) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        currentUrl = R.replace(/^(http.+#!)?(.[^?/]*).*/, '$2', currentUrl);
        return R.compose(mapLocationActive(currentUrl), getNavigationForUser)(user);
    }

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            ctrl.navData = getNavData($state.current.url, ctrl.user);
        }
    }

    /**
     * @summary Initializes the component
     */
    function $onInit() {
        ctrl.showNotice = false;

        $rootScope.$on('$locationChangeSuccess', (event, currentUrl = '') => {
            ctrl.navData = getNavData(currentUrl, ctrl.user);
        });
    }

    /**
     * @summary Checks if the current state is part of the TherigyInsights menu
     * @return {boolean}
     */
    function isInsightsState() {
        return ['app.insights'].indexOf($state.current.name) !== -1;
    }

    /**
     * @summary Sets account information into scope.
     * @param {Object} account - The user account.
     */
    function onChangeAccount(account) {
        ctrl.timeoutLength = account.timeout_length;
    }

    /**
     * @summary Redirects to the search page with the search criteria entered
     */
    function search() {
        window.location = `/#!/findPatient?lastName=${ctrl.searchName}`
    }
}

export default AppNavController;
