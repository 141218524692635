import dayjs from 'dayjs';

function findRelevantRxFill(rxFills) {
    if (!rxFills) {
        return null;
    }
    return rxFills.reduce((prev, curr) => {
        if (!prev) {
            return curr;
        }
        return dayjs(curr.createdOn).isAfter(prev.createdOn) || curr.fillNumber > prev.fillNumber ? curr : prev;
    }, null);
}

export default findRelevantRxFill;
