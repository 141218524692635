/** @ngInject */
function EngageProductionSelectionModalController(_paq) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeMode = doChangeMode;
    ctrl.doCloseModal = doCloseModal;
    ctrl.doLearnMore = doLearnMore;

    function $onChanges(changes) {
        if (changes.patientEducationSubscription && ctrl.patientEducationSubscription !== null) {
            ctrl.patientEducationSubscription = angular.copy(ctrl.patientEducationSubscription);
        }

        if (changes.patientMessagingSubscription && ctrl.patientMessagingSubscription !== null) {
            ctrl.patientMessagingSubscription = angular.copy(ctrl.patientMessagingSubscription);
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function $onInit() {
        ctrl.learnMoreUrlPm = 'https://knowledge.therigy.com/course/view.php?id=33';
        ctrl.learnMoreUrlPec = 'https://www.therigy.com/therigystm#section3';
    }

    function doChangeMode(mode) {
        if (mode === 'patientEducation') {
            _paq.push(['trackEvent', 'TherigyEngage', 'Modal from Profile - Patient Education Content CTA']);
        }

        if (mode === 'patientMessaging') {
            _paq.push(['trackEvent', 'TherigyEngage', 'Modal from Profile - Patient Messaging CTA']);
        }
        ctrl.onChangeMode({
            mode,
        });
    }

    function doCloseModal() {
        _paq.push(['trackEvent', 'TherigyEngage', 'Modal from Profile - Cancel button']);
        ctrl.close();
    }

    function doLearnMore(mode) {
        if (mode === 'patientEducation') {
            _paq.push(['trackEvent', 'TherigyEngage', 'Modal from Profile - Patient Education Content CTA']);
        }

        if (mode === 'patientMessaging') {
            _paq.push(['trackEvent', 'TherigyEngage', 'Modal from Profile - Patient Messaging CTA']);
        }
    }
}

export default EngageProductionSelectionModalController;
