import { useFormikContext } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FaPlus, FaWindowClose } from 'react-icons/fa';

import NavTabs from 'App/components/NavTabs/NavTabs';
import InsuranceFormArea from 'App/components/PatientInsurance/InsuranceFormArea';
import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';

import SectionTitleStyle from './SectionTitleStyle';

const PatientInsuranceForm = () => {
    const { values, setFieldValue, validateForm } = useFormikContext();

    return (
        <>
            <SectionTitleStyle>
                <h2>Insurance Plans</h2>
                <Button
                    bsStyle="link"
                    onClick={() => {
                        const newIndex = values.patientInsurance.length;
                        setFieldValue(`patientInsurance.[${newIndex}]`, {});
                        setImmediate(() => validateForm());
                    }}
                >
                    <FaPlus /> Add Insurance Plan
                </Button>
            </SectionTitleStyle>

            {!values.patientInsurance.length ? (
                <TherigyInfoMessage message="There are no insurance plans attached to this patient." />
            ) : (
                <NavTabs
                    items={values.patientInsurance}
                    renderNavTab={(item, index) => (
                        <>
                            <span>{item.name || `Plan ${index + 1}`} </span>
                            <FaWindowClose
                                onClick={() => {
                                    values.patientInsurance.splice(index, 1);
                                    setFieldValue('patientInsurance', values.patientInsurance);
                                    setImmediate(() => validateForm());
                                }}
                            />
                        </>
                    )}
                    renderPaneContent={(item, index) => (
                        <InsuranceFormArea fieldPrefix={`patientInsurance[${index}]`} />
                    )}
                />
            )}
        </>
    );
};

export default PatientInsuranceForm;
