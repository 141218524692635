import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import PatientEhrTile from './PatientEhrTile';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import { patientPropType } from 'Lib/types';
import PatientHighRiskIndicator from '../PatientHighRiskIndicator';
import { PatientPredictiveModelRiskIndicator, PatientPredictiveModelRiskText } from '../PatientPredictiveModelRisk';
import useFetchPatientPredictiveModelRisk from 'App/hooks/useFetchPatientPredictiveModelRisk';
import './PatientProfileTitleStyles.scss';
import PatientReferralInput from './PatientReferralInput';
import useFetchPatientLabels from 'App/hooks/useFetchPatientLabels';
import PatientDisplayId from '../PatientDisplayId/PatientDisplayId';

const propTypes = {
    patient: patientPropType.isRequired,
    ehr: PropTypes.shape({
        branchSettings: PropTypes.shape({
            ehrNoteType: PropTypes.string,
            ehrType: PropTypes.string,
            ehrPrimaryIdType: PropTypes.string,
        }),
        patientSynced: PropTypes.bool,
        syncingWithEhr: PropTypes.bool,
    }),
    isReferralPage: PropTypes.bool,
    showEhrStatus: PropTypes.bool,
};
const defaultProps = {
    isReferralPage: false,
    showEhrStatus: true,
    ehr: {},
};

const FlexCenter = styled('div')`
    display: flex;
    justify-content: space-between;
`;

const PatientProfileTitle = ({ patient, ehr, isReferralPage, showEhrStatus }) => {
    const { firstName, lastName, preferredName, pronouns, status, id } = patient;
    const { data: patientLabel = {} } = useFetchPatientLabels(id);
    const patientPredictiveModelEnabled = useIsPermitted(PERMISSION_TYPES.PATIENT_PREDICTIVE_MODEL);
    const { data: predictiveModel } = useFetchPatientPredictiveModelRisk(patient.id, patientPredictiveModelEnabled);
    const hasPredictiveModelRiskData = patientPredictiveModelEnabled && predictiveModel?.length > 0;

    return (
        <>
            <FlexCenter>
                <h1 style={{ fontSize: '30px' }}>
                    {lastName}, {firstName} {preferredName && `"${preferredName}"`}
                    &nbsp;
                    <PatientDisplayId patientId={patient.id} />
                    {patient.highRisk && <PatientHighRiskIndicator patientId={patient.id} />}
                    {hasPredictiveModelRiskData && (
                        <>
                            <PatientPredictiveModelRiskIndicator riskSegments={predictiveModel} />
                            <div style={{ fontSize: '12px', paddingTop: '10px' }}>
                                <PatientPredictiveModelRiskText riskSegments={predictiveModel} />
                            </div>
                        </>
                    )}
                </h1>
                {isReferralPage && <PatientReferralInput patientId={patient.id} />}
                {showEhrStatus && <PatientEhrTile ehr={ehr} status={status} />}
            </FlexCenter>
            <Row>
                <Col md={2}>{pronouns && `(${pronouns})`}</Col>
            </Row>
            {patientLabel && (
                <div className="patient-labels">
                    {Object.values(patientLabel).map((labels) => (
                        <span className="patient-label" key={labels.id}>
                            {labels.label}
                        </span>
                    ))}
                </div>
            )}
        </>
    );
};

PatientProfileTitle.propTypes = propTypes;
PatientProfileTitle.defaultProps = defaultProps;

export default PatientProfileTitle;
