import React from 'react';

const CustomDatePickerInput = React.forwardRef((props, ref) => {
    return (
        <input
            {...props}
            autoComplete="off"
            className="form-control filter-value custom-date-picker-input"
            aria-label="Filter Value Date"
            ref={ref}
        />
    );
});

CustomDatePickerInput.displayName = 'CustomDatePickerInput';

export default CustomDatePickerInput;
