import React from 'react';
import { useParams } from 'react-router-dom';

import AssessmentDisplay from 'App/components/Assessments/AssessmentDisplay';
import useFetchPatient from '../../hooks/useFetchPatient';
import useFetchActivity from './hooks/useFetchActivity';
import useFetchActivityQuestions from './hooks/useFetchActivityQuestions';

const Activity = () => {
    const { patientId, activityId } = useParams();
    const activityQuery = useFetchActivity(patientId, activityId);
    const patientQuery = useFetchPatient(patientId);
    const questionsQuery = useFetchActivityQuestions(activityId);

    return (
        <AssessmentDisplay activityQuery={activityQuery} patientQuery={patientQuery} questionsQuery={questionsQuery} />
    );
};

export default Activity;
