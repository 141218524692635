/** @ngInject */
function ICD9TypeaheadController(_, icd9Service) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeValue = doChangeValue;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.editable) {
            ctrl.editable = angular.copy(ctrl.editable);
        }
        if (changes.therapyId) {
            if (ctrl.therapyId) {
                return icd9Service
                    .search(ctrl.therapyId)
                    .then((res) => {
                        ctrl.codes = _.values(res);
                    })
                    .catch(() => {
                        ctrl.codes = [];
                    });
            }
        }

        if (changes.minChar) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line radix
            ctrl.minChar = parseInt(ctrl.minChar);
        }
        if (changes.ngDisabled) {
            ctrl.ngDisabled = angular.copy(ctrl.ngDisabled);
        }
        if (changes.required) {
            ctrl.required = angular.copy(ctrl.required);
        }
    }

    function $onInit() {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        ctrl.model.$render = function () {
            ctrl.name = ctrl.model.$name;
            ctrl.value = ctrl.model.$viewValue;
        };
    }

    function doChangeValue(value) {
        ctrl.model.$setViewValue(value);
        ctrl.onChangeValue({
            icd9: value,
        });
    }
}

export default ICD9TypeaheadController;
