/**
 * A collection of permission based hooks, most of them wrappers to make things a bit more semantic.
 */
import { useUserContext } from 'App/contexts/UserContext';
import isPermitted, { PERMISSION_TYPES } from '../isPermitted';
import * as R from 'ramda';

function useIsPermitted(permissionType) {
    const user = useUserContext();

    return isPermitted(permissionType, user);
}

function useAlertCenterAccess() {
    return useIsPermitted(PERMISSION_TYPES.ALERT_CENTER);
}

function useAlertManagerAccess() {
    return useIsPermitted(PERMISSION_TYPES.ALERT_MANAGER);
}

function useAssessmentManagerAccess() {
    return useIsPermitted(PERMISSION_TYPES.ASSESSMENT_MANAGER);
}

function useProtocolAccess() {
    const user = useUserContext();

    return R.applySpec({
        hasProtocolBuilder: isPermitted(PERMISSION_TYPES.PROTOCOL_MANAGER),
        hasEditProtocol: isPermitted(PERMISSION_TYPES.EDIT_PROTOCOLS),
        hasBuilderAndNewManager: R.allPass([isPermitted(PERMISSION_TYPES.PROTOCOL_MANAGER)]),
    })(user);
}

function useQuestionGroupsAccess() {
    return useIsPermitted(PERMISSION_TYPES.QUESTION_GROUPS);
}

function useTherapeuticCategoriesAccess() {
    return useIsPermitted(PERMISSION_TYPES.THERAPEUTIC_CATEGORIES);
}

export {
    useAlertCenterAccess,
    useAlertManagerAccess,
    useAssessmentManagerAccess,
    useProtocolAccess,
    useQuestionGroupsAccess,
    useTherapeuticCategoriesAccess,
};
export default useIsPermitted;
