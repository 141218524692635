import PropTypes from 'prop-types';

const rxPropType = PropTypes.shape({
    id: PropTypes.number,
    rxNumber: PropTypes.string,
    strength: PropTypes.string,
    refillsAuthorized: PropTypes.number,
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    endReason: PropTypes.string,
    fillExpiration: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    status: PropTypes.string,
    rxFill: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            fillNumber: PropTypes.number,
            fillDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
            dispensedQuantity: PropTypes.number,
            daysSupply: PropTypes.number,
        })
    ),
    physician: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        npi: PropTypes.string,
    }),
    medication: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
});

export default rxPropType;
