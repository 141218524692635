import React, { useMemo } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import useFetchPatientLabelsMenu from 'App/hooks/useFetchPatientLabelsMenu';
import SelectField from 'Lib/form/SelectField';

const propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
    onChange: Function,
    value: null,
};
function PatientLabels({ onChange, value }) {
    const { isLoading, data } = useFetchPatientLabelsMenu();
    const labels = useMemo(() => {
        return data
            ? R.compose(
                  R.sortBy(R.prop('name')),
                  R.map(
                      R.applySpec({
                          id: R.prop('valueName'),
                          name: R.prop('valueName'),
                      })
                  )
              )(data)
            : [];
    }, [data]);
    return (
        <SelectField
            isMulti
            fieldName="searchParams.patientLabels"
            options={labels}
            label="Patient Labels"
            getOptionValue={R.prop('id')}
            getOptionLabel={R.prop('name')}
            inputId="patient-labels"
            placeholder="Select Labels(s)"
            isClearable
            onChange={onChange}
            isLoading={isLoading}
            value={value}
            disabled={labels.length > 0}
        />
    );
}

PatientLabels.propTypes = propTypes;
PatientLabels.defaultProps = defaultProps;
export default PatientLabels;
