import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CompanyService from 'App/services/CompanyService';
import { errorHandler } from 'App/utils';
import * as R from 'ramda';
import { ControlLabel } from 'react-bootstrap';
import Select from 'react-select';
import MedicationManagement from 'App/components/MedicationManagement/MedicationManagement';
import PlaceHolderMessage from 'App/components/styles/PlaceholderMessage/PlaceHolderMessage';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    therapies: PropTypes.array.isRequired,
};

const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    therapies: [],
};

function MedicationSearch(props) {
    const [medGroups, setMedGroups] = useState(true);
    const [loading, setLoading] = useState(true);
    const [searchValues, setSearchValues] = useState(false);

    useEffect(() => {
        CompanyService.getLicencedMedications()
            .then((res) => {
                const groups = {};
                for (const [id, med] of Object.entries(res)) {
                    if (!groups[`${med.name}`]) {
                        groups[`${med.name}`] = { ids: [], name: med.name };
                    }
                    groups[`${med.name}`].ids.push(id);
                }
                setMedGroups(R.sortBy(R.prop('name'), Object.values(groups)));
            })
            .catch((error) => {
                errorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (medGroups.length) {
        return (
            <div>
                <FormGroup style={{ width: '500px' }}>
                    <ControlLabel htmlFor="medication-select">Select a Medication</ControlLabel>
                    <Select
                        classNamePrefix="react-select"
                        options={medGroups}
                        getOptionValue={R.prop('ids')}
                        getOptionLabel={R.prop('name')}
                        inputId="medication-select"
                        onChange={(option) => {
                            setSearchValues(option.ids);
                        }}
                        isLoading={loading}
                    />
                </FormGroup>
                {searchValues ? (
                    <MedicationManagement
                        searchValue={searchValues}
                        searchType="medication"
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line react/destructuring-assignment
                        therapies={props.therapies}
                    />
                ) : (
                    <PlaceHolderMessage placeholderText="Select a medication." />
                )}
            </div>
        );
    }
    return <PlaceHolderMessage>{loading ? <TherigyLoadingSpinner /> : 'No medications found.'}</PlaceHolderMessage>;
}

MedicationSearch.propTypes = propTypes;
MedicationSearch.defaultProps = defaultProps;

export default MedicationSearch;
