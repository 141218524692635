/** @ngInject */
function AssessmentEditConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.assessmentEdit',
        url: '/assessment-manager/:categoryId/edit/{pollId}',
        component: 'assessmentEdit',
    });
}

export default AssessmentEditConfig;
