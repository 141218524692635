import { updatePatientTherapies } from 'App/services/TherapyService';
import * as R from 'ramda';
import convertKeysToSnakeCase from 'App/utils/keyConversion/convertKeysToSnakeCase';

async function submitPatientTherapies(patientId, values) {
    return updatePatientTherapies(patientId, {
        medications: R.compose(R.indexBy(R.path(['medication', 'id'])), convertKeysToSnakeCase)(values.medications),
        therapies: R.compose(R.indexBy(R.prop('id')), convertKeysToSnakeCase)(values.therapies),
    });
}

export { submitPatientTherapies };
