import styled from '@emotion/styled';

const PatientCardSectionLabel = styled.div`
    font-size: 14px;
    color: var(--manatee);
    font-weight: bold;
    margin-bottom: 3px;
`;

export default PatientCardSectionLabel;
