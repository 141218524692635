import { useQuery } from 'react-query';

import AssessmentService from 'App/services/AssessmentService';
import { errorHandler } from 'App/utils';

function useFetchAssessmentQuestions(assessmentId) {
    return useQuery(['assessmentQuestions', assessmentId], () => AssessmentService.getQuestions(assessmentId), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchAssessmentQuestions;
