class TokenService {
    /**
     * @summary Returns the current session token.
     * @description
     * Gets the current session token from one place:
     * 1) The currentToken object in local storage
     *
     * If no key is found, it will redirect to the login page.
     *
     * @returns {string} The session token
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    static get = () => {
        if (typeof window.localStorage.getItem('currentToken') === 'string') {
            return window.localStorage.getItem('currentToken');
        }
        window.location.href = '/#!/login/';
    };

    /**
     * @summary Sets a user token into local storage.
     * @description
     * This method is used to set the 'currentToken' value in local storage, this action needs to
     * occur here as requring SessionStorage will create a circular dependency.
     *
     * @param {string} token
     */
    static set = (token) => {
        window.localStorage.setItem('currentToken', token);
    };

    /**
     * @summary Removes the currentToken
     */
    static remove = () => {
        window.localStorage.removeItem('currentToken');
    };
}

export default TokenService;
