import useFetchReferralQuery from 'App/hooks/useFetchReferralQuery';
import { useMemo } from 'react';
import * as R from 'ramda';
import dayjs from 'dayjs';

function convertToUtcDate(date) {
    return dayjs.utc(date).toDate();
}

function convertTextToOption(status) {
    if (status) {
        return {
            label: status,
            value: status,
        };
    }
    return null;
}

function useGetReferral(patientId, referralId, statuses) {
    const referralQuery = useFetchReferralQuery(patientId, referralId);

    const referral = useMemo(() => {
        if (referralQuery.isLoading) {
            return null;
        }

        return R.compose(
            // eslint-disable-next-line no-shadow
            (referral) => {
                let ref = referral;
                if (referral.status) {
                    const statusMenu = R.find(R.propEq(referral.status.value, 'value'), statuses);
                    if (statusMenu) {
                        ref = R.assoc('status', statusMenu, ref);

                        if (referral.statusReason && statusMenu?.children) {
                            const statusReasonMenu = R.find(
                                R.propEq(referral.statusReason.value, 'value'),
                                statusMenu.children
                            );
                            if (statusReasonMenu) {
                                ref = R.assoc('statusReason', statusReasonMenu, ref);

                                if (referral.statusSubReason && statusReasonMenu?.children) {
                                    const statusSubReasonMenu = R.find(
                                        R.propEq(referral.statusSubReason.value, 'value'),
                                        statusReasonMenu.children
                                    );
                                    if (statusSubReasonMenu) {
                                        ref = R.assoc('statusSubReason', statusSubReasonMenu, ref);

                                        if (referral.statusAction && statusSubReasonMenu?.children) {
                                            const statusActionMenu = R.find(
                                                R.propEq(referral.statusAction.value, 'value'),
                                                statusSubReasonMenu.children
                                            );
                                            if (statusActionMenu) {
                                                ref = R.assoc('statusAction', statusActionMenu, ref);

                                                if (referral.statusOutcome && statusActionMenu?.children) {
                                                    const statusOutcomeMenu = R.find(
                                                        R.propEq(referral.statusOutcome.value, 'value'),
                                                        statusActionMenu.children
                                                    );
                                                    if (statusOutcomeMenu) {
                                                        ref = R.assoc('statusOutcome', statusOutcomeMenu, ref);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                return ref;
            },
            R.over(R.lensProp('receivedBy'), convertTextToOption),
            R.over(R.lensProp('dispenseStatus'), convertTextToOption),
            R.over(R.lensProp('dispenseStatusReason'), convertTextToOption),
            R.over(R.lensProp('statusOutcome'), convertTextToOption),
            R.over(R.lensProp('statusAction'), convertTextToOption),
            R.over(R.lensProp('statusSubReason'), convertTextToOption),
            R.over(R.lensProp('statusReason'), convertTextToOption),
            R.over(R.lensProp('status'), convertTextToOption),
            R.over(R.lensProp('createdOn'), convertToUtcDate),
            R.over(R.lensProp('updatedOn'), convertToUtcDate),
            R.over(R.lensProp('receivedOn'), convertToUtcDate)
        )(referralQuery.data);
    }, [referralQuery.data, referralQuery.isLoading, statuses]);

    return {
        ...referralQuery,
        referral,
    };
}

export default useGetReferral;
