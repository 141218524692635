import { useMutation, useQueryClient } from 'react-query';
import ActivityMedicationService from 'App/services/ActivityMedicationService';
import * as R from 'ramda';

function useDeleteMedicationAssociationMutation(patientId, activityId) {
    const queryClient = useQueryClient();

    return useMutation(
        (associationId) => {
            return ActivityMedicationService.deleteMedicationAssociation(patientId, activityId, associationId);
        },
        {
            onSuccess(m) {
                const existingMeds = queryClient.getQueryData(['medicationAssociations', patientId, activityId]);
                queryClient.setQueryData(
                    ['medicationAssociations', patientId, activityId],
                    R.dissoc(m.id, existingMeds)
                );
            },
        }
    );
}

export default useDeleteMedicationAssociationMutation;
