import { render } from 'react-dom';

function mountReactComponent(mountSelector, component) {
    let count = 0;
    const interval = setInterval(() => {
        const mountPoint = document.querySelector(mountSelector);
        if (mountPoint) {
            render(component, mountPoint);
            clearInterval(interval);
        } else if (count === 10) {
            console.warn('Failed to mount component. Selector never produced a node: ', mountSelector);
            clearInterval(interval);
        }
        count += 1;
    }, 100);
}

export default mountReactComponent;
