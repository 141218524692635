/** @ngInject */
function PharmacyInformationController($scope) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeValue = doChangeValue;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;

    function $onChanges(changes) {
        if (changes.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
        }
    }

    function doChangeValue() {
        $scope.$emit('addPatientPage_updatePatient', {
            medical_plan: ctrl.patient.medical_plan,
            pbm: ctrl.patient.pbm,
            remote_medical_plan_id: ctrl.patient.remote_medical_plan_id,
            remote_pbm_id: ctrl.patient.remote_pbm_id,
        });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
    }
}

export default PharmacyInformationController;
