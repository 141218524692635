/** @ngInject */
function ConfirmDeleteModalController($timeout, amsService) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.doDeleteAssessment = doDeleteAssessment;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve) {
            if (ctrl.resolve.modalData) {
                ctrl.resolve.modalData = angular.copy(ctrl.resolve.modalData);
                ctrl.activityId = ctrl.resolve.modalData.activityId;
            }
        }
    }

    function doDeleteAssessment() {
        ctrl.saving = true;
        return amsService
            .saveStatus(ctrl.activityId, 'Deleted')
            .then((res) => {
                ctrl.saving = false;
                ctrl.saved = true;
                $timeout(() => {
                    ctrl.close(res);
                }, 500);
                ctrl.modalInstance.close(res);
                return res;
            })
            .catch(() => {
                $timeout(() => {
                    ctrl.saving = false;
                }, 500);
            });
    }
}

export default ConfirmDeleteModalController;
