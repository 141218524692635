/** @ngInject */
function TherigyUnknownErrorController(__env) {
    const ctrl = this;
    ctrl.$onInit = $onInit;

    function $onInit() {
        ctrl.supportEmail = __env.supportEmail;
        ctrl.supportPhoneNumber = __env.supportPhoneNumber;
    }
}

export default TherigyUnknownErrorController;
