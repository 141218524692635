import CompanyService from '../../../services/CompanyService';

/** @ngInject */
function AssessmentCreateConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.assessmentCreate',
        url: '/assessment-manager/:categoryId/create',
        component: 'assessmentCreate',
        resolve: {
            categories(assessmentService) {
                return assessmentService.getAssessmentCategories();
            },
            ehrProvider() {
                return CompanyService.getBranchSettings('ehrType', '', false).then((res) => {
                    return res;
                });
            },
        },
    });
}

export default AssessmentCreateConfig;
