import { toast } from 'react-toastify';
import { useQueryClient, useMutation } from 'react-query';
import { errorHandler } from '@/app/utils';
import { deleteActivityTextSchedule } from '@/app/services/PatientMessagingService';

const useActivityTextScheduleDeleteMutation = (activityId) => {
    const queryClient = useQueryClient();
    return useMutation(() => deleteActivityTextSchedule(activityId), {
        onError: errorHandler,
        onSuccess: () => {
            toast.success('Activity Text Schedule deleted successfully');
            queryClient.invalidateQueries('getActivityTextSchedule');
        },
    });
};

export default useActivityTextScheduleDeleteMutation;
