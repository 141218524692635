// TODO: Replace state with poll object in binding
/** @ngInject */
function AssessmentQuestionCheckboxController(_, $scope, $state, $uibModal, amsService) {
    const ctrl = this;
    let answerWeights = null;

    ctrl.$onChanges = $onChanges;
    ctrl.doChangeAnswer = doChangeAnswer;
    ctrl.doShowContent = doShowContent;
    ctrl.response = {};
    ctrl.answersChecked = [];

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
        }

        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);
            ctrl.answers = _.values(ctrl.question.answers);
            if (ctrl.question.response) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                for (let i = 0, len = ctrl.question.response.length; i < len; i++) {
                    const value = ctrl.question.response[i];
                    ctrl.response[value] = true;
                }

                // Check if any checkboxes are already checked
                ctrl.answersChecked = _.filter(ctrl.response, (c) => {
                    return c;
                });
            }

            if (ctrl.question.attributes && ctrl.question.attributes.answerWeights) {
                answerWeights = _.pickBy(ctrl.question.attributes.answerWeights, (aw) => {
                    return aw !== null;
                });
                calculateScore();
            }

            if (ctrl.question.children) {
                const childQuestions = amsService.formatQuestions(ctrl.question.children);
                ctrl.childQuestions = _.groupBy(childQuestions, 'parentAnswerId');
            } else {
                ctrl.childQuestions = {};
            }
        }
    }

    function calculateScore() {
        ctrl.questionScore = null;
        if (_.size(answerWeights) !== 0) {
            let questionScore = 0;
            const responses = Object.keys(ctrl.response);

            if (responses.length !== 0) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                for (let i = 0, len = responses.length; i < len; i++) {
                    questionScore +=
                        ctrl.question.attributes.risk_weight * ctrl.question.attributes.answerWeights[responses[i]];
                }

                ctrl.questionScore = questionScore;
            }
        }
    }

    function _doChangeAnswer(answer) {
        ctrl.answersChecked = _.filter(ctrl.response, (c) => {
            return c;
        });

        if (!ctrl.response[answer.id]) {
            delete ctrl.response[answer.id];
            delete ctrl.childQuestions[answer.id];
        } else {
            // Get the child questions (if any)
            // eslint-disable-next-line no-lonely-if
            if (answer && Array.isArray(answer.children) && answer.children.length > 0) {
                amsService.getActivityQuestionsById(ctrl.activity.id, answer.children).then((res) => {
                    $scope.$emit('updateAssessmentQuestions', angular.copy(res));
                    let childQuestions = amsService.formatQuestions(res);
                    childQuestions = _.keyBy(childQuestions, 'questionId');
                    ctrl.childQuestions[answer.id] = amsService.attachChildren(childQuestions);
                });
            } else {
                ctrl.childQuestions[answer.id] = null;
            }
        }

        const unselectedAnswers = _.keys(_.omit(ctrl.question.answers, _.keys(ctrl.response)));
        if (ctrl.question.attributes && ctrl.question.attributes.answerWeights) {
            calculateScore();
        }

        ctrl.onChangeAnswer({
            answer: _.keys(ctrl.response),
            unselectedAnswers,
        });
    }

    function doChangeAnswer(answer) {
        const numberOfAnswers = ctrl.getNumberOfAnswers({
            questionId: `${ctrl.question.id}_${answer.id}`,
        });
        if (numberOfAnswers > 0) {
            ctrl.response[answer.id] = true;
            const modalInstance = $uibModal.open({
                backdrop: 'static',
                component: 'confirmDeleteChildrenModal',
                keyboard: false,
                size: 'md',
                resolve: {
                    modalData() {
                        return {
                            numberOfAnswers,
                        };
                    },
                },
            });
            modalInstance.result
                .then((doAction) => {
                    if (doAction) {
                        ctrl.response[answer.id] = false;
                        _doChangeAnswer(answer);
                    }
                })
                .catch((err) => {
                    return err.data;
                });
        } else {
            _doChangeAnswer(answer);
        }
    }

    function doShowContent(content) {
        $scope.$emit('openAssessmentContentModal', content);
    }
}

export default AssessmentQuestionCheckboxController;
