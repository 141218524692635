import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';

/*
fetch list of patient activity files and notes
 */
function useFetchPatientActivityFiles(patientId) {
    return useQuery(['patientActivity', patientId], () => PESService.getPatientActivityFiles(patientId), {
        onError: errorHandler,
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchPatientActivityFiles;
