/** @ngInject */
function Icd9Service($http, CacheFactory, urlBuilderService) {
    // Only create Cache for 'icd9ServiceCache' if it has expired or was deleted
    if (!CacheFactory.get('icd9ServiceCache')) {
        CacheFactory('icd9ServiceCache', {
            maxAge: 15 * 60 * 1000, // Items added to this cache expire after 15 minutes
            cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour
            deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
        });
    }

    const service = {
        search,
    };

    function search(therapyId) {
        const url = urlBuilderService.build('search', {
            therapy: therapyId,
            diagnosis: '',
            icd9: '',
        });

        return $http
            .get(url, {
                cache: CacheFactory.get('icd9ServiceCache'),
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.status === 404) {
                    return {};
                }
                return err;
            });
    }

    return service;
}

export default Icd9Service;
