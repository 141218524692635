import React from 'react';

/**
 * Used for tables, which have an "Action" column with a bunch of ActionButtonLinks.
 * Just give it children, and it will apply pipes through out them.
 * @example
 * <ActionCell>
 *     <ActionButtonLink>Copy</ActionButtonLink>
 *     <ActionButtonLink>Delete</ActionButtonLink>
 *     <ActionButtonLink>Save</ActionButtonLink>
 * </ActionCell/>
 *
 * Result: Copy | Delete | Save
 */
function ActionCell(props) {
    return <span className="action-cell" {...props} />;
}

export default ActionCell;
