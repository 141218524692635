/** @ngInject */
function AssignMedicationTherapyModalController(_, $q, therapyService) {
    const ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.$onChanges = $onChanges;
    ctrl.ok = closeModal;

    function $onInit() {
        ctrl.selectedTherapy = [];
    }

    function $onChanges(changes) {
        if (changes.modalInstance) {
            ctrl.cancel = angular.copy(ctrl.modalInstance.dismiss);
        }
        if (changes.resolve) {
            ctrl.patient = angular.copy(ctrl.resolve.modalData.patient);

            // Not all unassigned meds will have indication codes.
            ctrl.medsWithIndications = 0;
            for (const med of ctrl.patient.Unassignedmedications) {
                if (_.filter(med.Indications, 'Code').length === 0) {
                    // Remove indications with no diagnosis code.
                    med.Indications = [];
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-continue
                    continue;
                }
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                ctrl.medsWithIndications++;
            }
            ctrl.allMedsHaveIndications = ctrl.medsWithIndications === ctrl.patient.Unassignedmedications.length;
        }
    }

    /**
     * Save therapy selection and close the modal
     * @namespace
     * @property {Object} selection -  Therapy Selection for the medication
     * @property {Object} apiResponse - Response from calling the API
     */
    function closeModal() {
        ctrl.saving = true;
        return saveMedications()
            .then((res) => {
                ctrl.modalInstance.close({
                    selection: ctrl.selectedTherapy,
                    apiResponse: res.data,
                });
                return res.data;
            })
            .catch((err) => {
                return err.data;
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }

    /**
     * Add medication to uniqueTcRoutes object
     * That it will be submitted only once for the TC
     * @return {*}
     */
    function saveMedications() {
        const uniqueTcRoutes = createRoutes(ctrl.selectedTherapy);
        const promises = [];

        /* On each of those tc routes issue one save action */
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const therapyID in uniqueTcRoutes) {
            const { medications } = uniqueTcRoutes[therapyID];
            const { diagnoses } = uniqueTcRoutes[therapyID];
            promises.push(submitRequest(therapyID, medications, diagnoses));
        }

        return $q.all(promises);
    }

    /**
     * Creates Routes based on user selections,
     * Loops through each selection and adds medication to the route obj
     */
    function createRoutes(selectedTherapy) {
        const uniqueTcRoutes = [];
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const index in selectedTherapy) {
            const therapySelection = selectedTherapy[index];
            if (therapySelection && typeof therapySelection !== 'function') {
                const therapyId = therapySelection.ID;
                if (!uniqueTcRoutes[therapyId]) {
                    uniqueTcRoutes[therapyId] = {
                        medications: [
                            {
                                medName: ctrl.patient.Unassignedmedications[index].MedicationName,
                                activityDueDate: ctrl.patient.Unassignedmedications[index].ActivityDueDate,
                                isAppointmentReferralActivityMedication:
                                    ctrl.patient.Unassignedmedications[index].IsAppointmentReferralActivityMedication,
                            },
                        ],
                        diagnoses: ctrl.patient.Unassignedmedications[index].Indications,
                    };
                } else {
                    uniqueTcRoutes[therapyId].medications.push({
                        medName: ctrl.patient.Unassignedmedications[index].MedicationName,
                        activityDueDate: ctrl.patient.Unassignedmedications[index].ActivityDueDate,
                        isAppointmentReferralActivityMedication:
                            ctrl.patient.Unassignedmedications[index].IsAppointmentReferralActivityMedication,
                    });
                    uniqueTcRoutes[therapyId].diagnoses.concat(ctrl.patient.Unassignedmedications[index].Indications);
                }
            } else {
                throw new Error('Wrong therapySelection');
            }
        }
        return uniqueTcRoutes;
    }

    /**
     * Submit user selection
     * @param therapyID
     * @param medications
     * @param diagnoses
     * @returns {Promise} - patientMedication when successful, fails if not.
     */
    function submitRequest(therapyID, medications, diagnoses) {
        return therapyService.assignUnassignedMedication(ctrl.patient.ID, therapyID, {
            medications,
            diagnoses,
        });
    }
}

export default AssignMedicationTherapyModalController;
