import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';
import TherigyMultiSelect from '@/app/components/TherigyMultiSelect/TherigyMultiSelect';

import asFormField from './asFormField';

function SelectAllInput({
    name,
    value,
    onChange,
    onBlur,
    className,
    style,

    options,
    getOptionValue,
    getOptionLabel,
    placeholder,
    isClearable,
    isLoading,
    disabled,
}) {
    return (
        <TherigyMultiSelect
            inputId={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={cx('form-control react-select', className)}
            style={style}
            // classNamePrefix is necessary for automated tests
            classNamePrefix="react-select"
            options={options}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            placeholder={placeholder}
            isClearable={isClearable}
            isLoading={isLoading}
            isDisabled={disabled}
        />
    );
}

SelectAllInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.shape({})),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
};

SelectAllInput.defaultProps = {
    value: null,
    onBlur: null,
    className: '',
    style: null,

    getOptionValue: undefined,
    getOptionLabel: undefined,
    placeholder: undefined,
    isClearable: false,
    isLoading: false,
    disabled: false,
};

const SelectAllField = asFormField({ WrappedComponent: SelectAllInput });

export default SelectAllField;
