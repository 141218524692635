/** @ngInject */
function QuestionManagerConfig($stateProvider) {
    $stateProvider
        .state({
            name: 'app.assessmentQuestions',
            url: '/assessment-manager/:categoryId/questions/{pollId}',
            component: 'assessmentQuestions',
            resolve: {
                redirect($transition$, $state) {
                    if (!$transition$.params().pollId) {
                        $state.go('app.assessmentManager', {
                            categoryId: $transition$.params().categoryId,
                        });
                    }
                },
            },
        })
        .state({
            name: 'app.assessmentChildQuestions',
            url: '/assessment-manager/:categoryId/questions/{pollId}/parent-question/:parentQuestionId/parent-answer/:parentAnswerId',
            component: 'assessmentQuestions',
            resolve: {
                redirect($transition$, $state) {
                    if (!$transition$.params().pollId) {
                        $state.go('app.assessmentManager', {
                            categoryId: $transition$.params().categoryId,
                        });
                    }
                    if (!$transition$.params().parentAnswerId || !$transition$.params().parentQuestionId) {
                        $state.go('app.assessmentQuestions', {
                            categoryId: $transition$.params().categoryId,
                            pollId: $transition$.params().pollId,
                        });
                    }
                },
            },
        });
}

export default QuestionManagerConfig;
