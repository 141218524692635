import * as R from 'ramda';
import { getLatestMobileAssessment } from '@/app/components/PatientProfile/PatientProfile.utils.js';
import isPermitted, { PERMISSION_TYPES } from '@/app/utils/isPermitted';
import CompanyService from '@/app/services/CompanyService';

/** @ngInject */
function EngageController(_, _paq, $q, $scope, $uibModal, jwtHelper, engageService, pesService, sessionStorageService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.openOptInModal = openOptInModal;
    ctrl.openOptOutModal = openOptOutModal;
    ctrl.openPatientMessagingHistoryModal = openPatientMessagingHistoryModal;
    ctrl.openVideoModal = openVideoModal;
    ctrl.openSelectOneTimeMessageModel = openSelectOneTimeMessageModel;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.patient && ctrl.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
        }

        if (ctrl.patient && ctrl.user) {
            // TODO: Remove the OR condition once patientService.get() has been replaced on the patient profile.
            ctrl.portalUserId = ctrl.patient.portal_user_id || ctrl.patient.portalUserId;
            ctrl.loading = true;
            if (ctrl.portalUserId) {
                return pesService
                    .getPatientSubscriptions(ctrl.patient.id)
                    .then((res) => {
                        const assessments = [];
                        let currentAssessment = {};
                        ctrl.patientEducationSubscription = false;
                        ctrl.patientMessagingSubscription = false;

                        _.forEach(res, (s) => {
                            if (s.name === 'Pharmacy Videos' && s.unsubscribedOn === null) {
                                ctrl.patientEducationSubscription = angular.copy(s);

                                const promises = {
                                    medication: engageService.getPatientVideos(ctrl.portalUserId, 'medication'),
                                    therapy: engageService.getPatientVideos(ctrl.portalUserId, 'condition'),
                                };

                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line no-shadow
                                $q.all(promises).then((res) => {
                                    // Therapy Videos
                                    _.each(res.therapy, (video) => {
                                        ctrl.videos.push(video);
                                    });

                                    // Medication Videos
                                    _.each(res.medication, (data) => {
                                        _.each(data, (video) => {
                                            ctrl.videos.push(video);
                                        });
                                    });

                                    ctrl.videosLoaded = true;
                                });
                            }
                            // hardcoded subscription name for text refill notification
                            if (s.name === 'Mobile Assessments') {
                                assessments.push(s);
                            }
                        });
                        if (assessments.length) {
                            if (assessments.length === 1) {
                                currentAssessment = assessments[0];
                            } else if (assessments.length > 1) {
                                currentAssessment = getLatestMobileAssessment(assessments);
                            }
                            ctrl.patientMessagingSubscription = angular.copy(currentAssessment);
                            ctrl.patientMessagingSubscription.status = subscriptionStatus(
                                ctrl.patientMessagingSubscription
                            );
                        }
                    })
                    .catch(() => {
                        ctrl.error = true;
                    })
                    .finally(() => {
                        ctrl.loading = false;
                        ctrl.videosLoaded = true;
                    });
            }
            ctrl.loading = false;
            ctrl.videosLoaded = true;
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    async function $onInit() {
        ctrl.error = false;
        ctrl.videos = [];
        ctrl.videosLoaded = false;
        ctrl.messageSettings = {};
        ctrl.isMassTextingEnabled = isPermitted(PERMISSION_TYPES.MASS_TEXTING, ctrl.user);
        const jwt = sessionStorageService.getJwt();
        const token = jwtHelper.decodeToken(jwt);
        try {
            const messagingBranchName = await CompanyService.getBranchSettings(
                'messagingBranchName',
                false,
                false,
                token.data.BranchID
            );
            const messagingPhone = await CompanyService.getBranchSettings(
                'messagingPhone',
                false,
                false,
                token.data.BranchID
            );
            ctrl.messageSettings = {
                messagingBranchName,
                messagingPhone,
            };
        } catch (error) {
            console.error(error);
        }
    }

    function openOptInModal(mode) {
        if (mode === 'productSelect') {
            _paq.push(['trackEvent', 'TherigyEngage', 'From Profile - Enroll Patient link']);
        }

        const modalInstance = $uibModal.open({
            component: 'engageOptInModal',
            size: 'md',
            backdrop: 'static',
            resolve: {
                modalData() {
                    return {
                        mode,
                        patient: ctrl.patient,
                        patientEducationSubscription: ctrl.patientEducationSubscription,
                        patientMessagingSubscription: ctrl.patientMessagingSubscription,
                        user: ctrl.user,
                    };
                },
            },
        });

        modalInstance.result.then((res) => {
            if (res) {
                $scope.$emit('updatePatient', {
                    patient_id: ctrl.patient.id,
                });
            }
        });
    }
    function openOptOutModal(mode) {
        if (mode === 'productdeSelect') {
            _paq.push(['trackEvent', 'TherigyEngage', 'From Profile - Unenroll Patient link']);
        }

        const modalInstance = $uibModal.open({
            component: 'engageOptInModal',
            size: 'md',
            backdrop: 'static',
            resolve: {
                modalData() {
                    return {
                        mode,
                        patient: ctrl.patient,
                        patientEducationSubscription: ctrl.patientEducationSubscription,
                        patientMessagingSubscription: ctrl.patientMessagingSubscription,
                        user: ctrl.user,
                    };
                },
            },
        });

        modalInstance.result.then((res) => {
            if (res) {
                $scope.$emit('updatePatient', {
                    patient_id: ctrl.patient.id,
                });
            }
        });
    }
    function openPatientMessagingHistoryModal() {
        _paq.push(['trackEvent', 'TherigyEngage', 'From Profile - View Patient Messaging History link']);

        const modalInstance = $uibModal.open({
            component: 'patientMessagingHistoryModal',
            size: 'lg',
            backdrop: 'static',
            resolve: {
                patientMessagingData() {
                    return {
                        patient: ctrl.patient,
                        patientMessagingSubscription: ctrl.patientMessagingSubscription,
                        patientmessageViewModal: ctrl.patientmessageViewModal,
                    };
                },
            },
        });

        modalInstance.result.then((res) => {
            if (res) {
                $scope.$emit('updatePatient', {
                    patient_id: ctrl.patient.id,
                });
            }
        });
    }

    function openVideoModal(video) {
        $uibModal.open({
            component: 'engageVideoModal',
            size: 'lg',
            resolve: {
                modalData() {
                    return {
                        video,
                    };
                },
            },
        });
    }

    function subscriptionStatus(subscription) {
        if (subscription && subscription.subscribedOn && subscription.unsubscribedOn === null) {
            return true;
        }
        return false;
    }

    function openSelectOneTimeMessageModel() {
        const selectModalInstance = $uibModal.open({
            component: 'selectOneTimeMessageModel',
            size: 'lg',
            backdrop: 'static',
            resolve: {
                oneTimeMessage() {
                    return { messageSettings: ctrl.messageSettings };
                },
            },
        });

        selectModalInstance.result.then(openSendOneTimeMessage);
    }

    function openSendOneTimeMessage(modelData) {
        if (modelData) {
            $uibModal.open({
                component: 'sendOneTimeMessageModel',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    modalData() {
                        return {
                            ...modelData,
                            pharmacyName: R.pathOr('', ['messageSettings', 'messagingBranchName'], ctrl),
                            pharmacyPhone: R.pathOr('', ['messageSettings', 'messagingPhone'], ctrl),
                            patient: ctrl.patient,
                        };
                    },
                },
            });
        }
    }
}

export default EngageController;
