import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';

function useFetchPatientVisits(patientId) {
    return useQuery(['patientVisits', patientId], () => PESService.getVisits(patientId), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchPatientVisits;
