import { startCase } from 'lodash';
import CMSService from 'App/services/CMSService';

/** @ngInject */
function AssessmentContentModalController(_) {
    const ctrl = this;
    const { labelOrder } = CMSService;

    ctrl.$onChanges = $onChanges;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve) {
            ctrl.labelType = ctrl.resolve.modalData.labelType;

            const { label } = ctrl.resolve.modalData;
            const { labels } = ctrl.resolve.modalData;
            const { name } = ctrl.resolve.modalData;
            const { versionDate } = ctrl.resolve.modalData;

            return CMSService.getContent(name || label, {
                labels,
                versionDate,
            }).then((res) => {
                const contentGroups = res;

                _.forEach(contentGroups, (cg) => {
                    const contentVersions = _.sortBy(cg.contentVersions, (cv) => {
                        return labelOrder[cg.type].indexOf(cv.label);
                    });

                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    cg.name = startCase(cg.name);
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    cg.contentVersions = contentVersions;
                });

                ctrl.contentGroups = contentGroups;
            });
        }
    }
}

export default AssessmentContentModalController;
