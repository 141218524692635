import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import FlexContainer from 'App/common/styles/FlexContainer';
import IconWrapper from 'App/common/styles/IconWrapper';
import useGetReferralCommunications from './useGetReferralCommunications';
import Table from '../Table/Table';
import propOrDash from 'App/utils/propOrDash';
import dateOrDashUtc from 'App/utils/dateOrDashUtc';
import AddCommunicationModal from '../AddCommunicationModal/AddCommunicationModal';
import TherigyInfoMessage from '../TherigyInfoMessage/TherigyInfoMessage';

const propTypes = {
    patientId: PropTypes.string.isRequired,
    referralId: PropTypes.string.isRequired,
};

const defaultProps = {};

const columns = [
    {
        Header: 'Call #',
        accessor: propOrDash('number'),
        id: 'number',
    },
    {
        Header: 'Date',
        accessor: dateOrDashUtc(['createdOn']),
        id: 'createdOn',
    },
    {
        Header: 'made',
        accessor: 'made',
        id: 'made',
        Cell: ({ value }) => (value === 1 ? 'Yes' : 'No'),
    },
    {
        Header: 'Person Contacted',
        accessor: propOrDash('contact'),
        id: 'contact',
    },
    {
        Header: 'Reason',
        accessor: propOrDash('reason'),
        id: 'reason',
    },
    {
        Header: 'Call Outcome',
        accessor: propOrDash('outcome'),
        id: 'outcome',
    },
    {
        Header: 'Notes',
        accessor: propOrDash('notes'),
        id: 'notes',
    },
];

const initialState = {
    sortBy: [
        {
            id: 'createdOn',
            desc: true,
        },
    ],
};

function ReferralCommunicationTable({ patientId, referralId }) {
    const { data, isLoading, refetch } = useGetReferralCommunications(patientId, referralId);
    const [showAddModal, setShowAddModal] = useState(false);

    const onHide = () => {
        refetch();
        setShowAddModal(false);
    };

    return (
        <div className="referral-communication">
            <FlexContainer>
                <h3>Communications</h3>
                <div className="add-create-action">
                    <Button bsStyle="link" onClick={() => setShowAddModal(true)}>
                        <IconWrapper>
                            <FaPlus />
                        </IconWrapper>
                        Add Communication
                    </Button>
                </div>
            </FlexContainer>
            {data?.length > 0 && !isLoading ? (
                <Table initialState={initialState} columns={columns} data={data} />
            ) : (
                <TherigyInfoMessage message="There are currently no communications attached to this referral." />
            )}
            {showAddModal && (
                <div>
                    <AddCommunicationModal
                        show={showAddModal}
                        patientId={patientId}
                        onHide={onHide}
                        referralId={referralId}
                    />
                </div>
            )}
        </div>
    );
}

ReferralCommunicationTable.propTypes = propTypes;
ReferralCommunicationTable.defaultProps = defaultProps;

export default ReferralCommunicationTable;
