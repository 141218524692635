import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Link, generatePath, useHistory } from 'react-router-dom';

import { patientRoute } from 'App/common/routeLookup';
import { useUserContext } from 'App/contexts/UserContext';
import { generatePdf, saveStatus, sendActivityUpdate } from 'App/services/AMSService';
import { formatUtcDate } from 'App/services/DateService';
import { shouldExecutePatientSearch, shouldExecuteVisitSearch } from 'App/services/EhrService';
import PESService from 'App/services/PESService';
import { isExpired } from 'App/utils';
import { CheckboxField } from 'Lib/form/CheckboxField';
import { branchSettingsPropType, patientPropType } from 'Lib/types';
import TherigyLoadingSpinner from '../TherigyLoadingSpinner/TherigyLoadingSpinner';
import { cdnURL } from '../config/config';
import FormButtonGroup from '../styles/FormButtonGroup';

import ActivityLocking from './ActivityLocking';
import AssessmentScoring from './AssessmentScoring';
import ConfirmDeleteAssessmentModal from './ConfirmDeleteAssessmentModal';
import NewSummaryNote from './NewSummaryNote';
import SummaryNotesDisplay from './SummaryNotesDisplay';
import { scrollContainerId } from './constants';
import EhrEncounterNoteModal from './ehr/EhrEncounterNoteModal';
import AssessmentQuestion from './question/AssessmentQuestion';
import AssessmentQuestionErrorDisplay from './question/AssessmentQuestionErrorDisplay';
import AssessmentQuestionGroup from './question/AssessmentQuestionGroup';
import AssessmentSpecialQuestions from './question/AssessmentSpecialQuestions';
import { activityPropType, questionGroupPropType, questionPropType } from './types';

const FormStyle = styled.form`
    flex: 1 10 100%;
    padding: 20px;
    overflow: auto;
`;

const AssessmentTitleStyle = styled.div`
    display: flex;
    justify-content: space-between;
`;

const AssessmentName = styled.h1`
    font-size: 20px;
    margin-bottom: 20px;
`;

const PdfButtonImage = styled.img`
    height: 45px;
    cursor: pointer;
`;

const AssessmentToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

function getInitialFormValueForQuestion(question) {
    if (question.type === 'date' && question.response) {
        return dayjs(question.response).toDate();
    }
    if (question.type === 'select') {
        return question.answers[question.response];
    }
    return question.response;
}

const AssessmentForm = ({
    activity,
    allQuestions,
    questionsWithChildren,
    splitQuestions,
    questionGroups,
    patient,
    branchSettings,
}) => {
    const history = useHistory();
    const user = useUserContext();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isPdfLoading, setIsPdfLoading] = useState(false);
    const [ehrModalOpen, setEhrModalOpen] = useState(false);
    const [isEhrResubmit, setIsEhrResubmit] = useState(false);
    const [patientEhrEligible, setPatientEhrEligible] = useState(true);

    const isActivityComplete = activity.status === 1;
    const isActivityDeleted = activity.status === 2;

    const activityEhrEligible = !!branchSettings.ehrType && activity.assessment.settings?.settings.ehrEncounterNote;
    const userEhrEligible = branchSettings.noteRequiresEhrProviderId !== true || !!user.user_attributes.ehrProviderId;

    const isPatientEhrEligibleByIdentifiers = useCallback(async () => {
        const identifiers = await PESService.getIdentifiers(patient.id);
        const executeIdentifierSearch = shouldExecutePatientSearch(Object.values(identifiers), branchSettings);
        if (!executeIdentifierSearch) {
            return true;
        }
        // silently catch errors from EHR search, this shouldn't influence our eligibility checks
        // error codes from EHR searches can be legitimate responses, so don't block the activity
        // if that occurs, and calculate based on the existence of identifiers
        const ehrIdentifiers = await PESService.getPatientIdentifiersFromEhr(patient.id).catch(() => ({}));
        return !isEmpty(ehrIdentifiers) || !isEmpty(identifiers);
    }, [branchSettings, patient.id]);

    const isPatientEhrEligibleByVisits = useCallback(async () => {
        if (!branchSettings.ehrNoteRequiresVisit) {
            return true;
        }
        const visits = await PESService.getVisits(patient.id);
        const executeVisitSearch = shouldExecuteVisitSearch(visits, branchSettings);
        if (!executeVisitSearch) {
            return true;
        }
        // silently catch errors from EHR search, this shouldn't influence our eligibility checks
        // error codes from EHR searches can be legitimate responses, so don't block the activity
        // if that occurs, and calculate based on the existence of visits
        const ehrVisits = await PESService.getPatientVisitsFromEhr(patient.id).catch(() => ({}));
        return !isEmpty(ehrVisits);
    }, [branchSettings, patient.id]);

    const isPatientEhrEligible = useCallback(async () => {
        if (!activityEhrEligible) {
            return false;
        }
        const isEligibleByIdentifiers = await isPatientEhrEligibleByIdentifiers();
        const isEligibleByVisits = await isPatientEhrEligibleByVisits();
        return isEligibleByIdentifiers && isEligibleByVisits;
    }, [activityEhrEligible, isPatientEhrEligibleByIdentifiers, isPatientEhrEligibleByVisits]);

    useEffect(() => {
        isPatientEhrEligible().then(setPatientEhrEligible);
    }, [isPatientEhrEligible]);

    function saveSummaryNote(values) {
        if (!values.newSummaryNote) {
            return;
        }

        return PESService.createSummaryNote({
            patientId: patient.id,
            activityId: activity.id,
            noteBody: values.newSummaryNote,
            noteSubject: activity.assessment.name,
            noteType: activity.assessment.defaultNoteType,
        });
    }

    async function pendAssessment(values) {
        if (!patient.id) return;

        await saveSummaryNote(values);
        await saveStatus(activity.id, 'Pending');

        history.push(generatePath(patientRoute, { patientId: patient.id }));
    }

    async function completeAssessment(values) {
        if (!patient.id) return;

        await saveSummaryNote(values);
        await saveStatus(activity.id, 'Finished');
        await sendActivityUpdate(patient.id, activity.id, true);

        history.push(generatePath(patientRoute, { patientId: patient.id }));
    }

    const openDeleteModal = () => {
        if (!patient.id) return;
        setDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = async (confirm) => {
        if (confirm) {
            await saveStatus(activity.id, 'Deleted');

            history.push(generatePath(patientRoute, { patientId: patient.id }));
        }
        setDeleteModalOpen(false);
    };

    const handleGeneratePdf = async () => {
        setIsPdfLoading(true);

        const base = document.createElement('base');
        base.href = window.location.origin;
        document.head.insertBefore(base, document.head.firstChild);
        const htmlString = document.documentElement.outerHTML;

        try {
            await generatePdf(htmlString, !patient.id ? 'preview' : 'activity', activity.name, activity.id);
        } finally {
            setIsPdfLoading(false);
        }
    };

    function openEhrEncounterNoteModal(resubmit = false) {
        setEhrModalOpen(true);
        setIsEhrResubmit(resubmit);
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...questionsWithChildren.reduce((agg, question) => {
                    return {
                        ...agg,
                        [question.questionId]: {
                            ...question,
                            response: getInitialFormValueForQuestion(question),
                        },
                    };
                }, {}),
                fullyScriptedMode: user.verbose === 1,
                newSummaryNote: '',
            }}
            onSubmit={(values) => {
                if (activityEhrEligible && userEhrEligible) {
                    openEhrEncounterNoteModal();
                } else {
                    completeAssessment(values);
                }
            }}
        >
            {({ handleSubmit, submitCount, isValid, errors, values, isSubmitting }) => (
                <FormStyle onSubmit={handleSubmit} id={scrollContainerId}>
                    {isExpired(activity.assessment.retiredOn) && (
                        <Alert bsStyle="danger">
                            <i className="fa fa-fw fa-info-circle" /> Therigy retired this activity on{' '}
                            {formatUtcDate({ date: activity.assessment.retiredOn, format: 'MMMM D, YYYY' })} and will no
                            longer support or update its contents.
                        </Alert>
                    )}

                    <AssessmentTitleStyle>
                        <AssessmentName>{activity.name}</AssessmentName>

                        <div className="assessment-generate-pdf-container">
                            {isPdfLoading ? (
                                <TherigyLoadingSpinner />
                            ) : (
                                <PdfButtonImage
                                    src={`${cdnURL}/images/PDF-231x300.png`}
                                    alt="Generate PDF"
                                    onClick={handleGeneratePdf}
                                />
                            )}
                        </div>
                    </AssessmentTitleStyle>

                    {isActivityComplete && activity.completedByUser && activity.completedOn && (
                        <div>
                            <p>
                                This assessment was completed on{' '}
                                {formatUtcDate({ date: activity.completedOn, format: 'MMMM D, YYYY' })} by{' '}
                                {activity.completedByUser.firstName} {activity.completedByUser.lastName}.
                            </p>
                            {!!branchSettings.ehrType && (
                                <>
                                    <div>
                                        {activityEhrEligible
                                            ? 'Eligible for EHR Note: Yes'
                                            : 'Eligible for EHR Note: No'}
                                    </div>
                                    <div>
                                        {activityEhrEligible &&
                                        activity.activityEhrNotes &&
                                        activity.activityEhrNotes.sentOn &&
                                        !activity.activityEhrNotes.failed
                                            ? 'Note Sent to EHR: Yes'
                                            : 'Note Sent to EHR: No'}
                                    </div>
                                </>
                            )}
                        </div>
                    )}

                    <AssessmentToolbar>
                        <div>
                            <i className="fa fa-fw fa-asterisk text-danger" /> Indicates question is required
                        </div>
                        <CheckboxField fieldName="fullyScriptedMode" label="View Fully Scripted" />
                    </AssessmentToolbar>

                    <AssessmentSpecialQuestions
                        specialQuestions={splitQuestions.specialQuestions}
                        activity={activity}
                        patient={patient}
                    />

                    <SummaryNotesDisplay patient={patient} activity={activity} />

                    {splitQuestions.questions
                        .sort((a, b) => a.attributes.position - b.attributes.position)
                        .map((question) => (
                            <AssessmentQuestion
                                key={question.questionId}
                                question={question}
                                activity={activity}
                                patient={patient}
                            />
                        ))}

                    {questionGroups.map((group) => (
                        <AssessmentQuestionGroup
                            key={group.id}
                            group={group}
                            questions={splitQuestions.groupedQuestions[group.id]}
                            activity={activity}
                            patient={patient}
                        />
                    ))}

                    {!!Object.values(activity.assessment.scoring || {}).length && (
                        <AssessmentScoring activity={activity} />
                    )}

                    <NewSummaryNote activity={activity} />

                    {submitCount > 0 && !isValid && (
                        <div>
                            <div className="text-danger">
                                The following required question(s) must be answered to complete this assessment. Click
                                the unanswered question(s) below to enter the required answer(s).
                            </div>
                            <ul>
                                {Object.keys(errors).map((questionId) => (
                                    <AssessmentQuestionErrorDisplay
                                        key={questionId}
                                        questionId={questionId}
                                        allQuestions={allQuestions}
                                        patient={patient}
                                    />
                                ))}
                            </ul>
                        </div>
                    )}

                    {activityEhrEligible && !patientEhrEligible && (
                        <div data-testid="ehr-patient-identifiers" id="ehr-ineligible" className="text-danger">
                            This patient is not eligible to send EHR encounter notes.
                        </div>
                    )}
                    {activityEhrEligible && !userEhrEligible && (
                        <div data-testid="ehr-provider-id-missing" className="text-danger">
                            The encounter note cannot be sent until your EHR Provider ID is entered.{' '}
                            <Link
                                to={{
                                    pathname: '/myAccount',
                                    search: 'sendBackAfterSave=true',
                                }}
                            >
                                Click here to update.
                            </Link>
                        </div>
                    )}

                    <FormButtonGroup>
                        <Button
                            id="pend-activity-btn"
                            disabled={isActivityComplete || isActivityDeleted}
                            onClick={() => pendAssessment(values)}
                        >
                            Pend
                        </Button>
                        <Button
                            id="complete-btn"
                            type="submit"
                            bsStyle="primary"
                            disabled={isSubmitting || isActivityComplete || isActivityDeleted}
                        >
                            Complete
                        </Button>
                        {activityEhrEligible &&
                            patientEhrEligible &&
                            (!activity.activityEhrNotes?.sentOn || activity.activityEhrNotes?.failed) &&
                            isActivityComplete && (
                                <Button onClick={() => openEhrEncounterNoteModal(true)}>Send EHR Note</Button>
                            )}

                        <div style={{ flexGrow: 1 }} />

                        <Button disabled={isActivityComplete || isActivityDeleted} onClick={openDeleteModal}>
                            <span className="text-danger">Delete</span>
                        </Button>
                    </FormButtonGroup>

                    {ehrModalOpen && (
                        <EhrEncounterNoteModal
                            activity={activity}
                            patient={patient}
                            allQuestions={allQuestions}
                            branchSettings={branchSettings}
                            onClose={() => setEhrModalOpen(false)}
                            onComplete={() => {
                                if (isEhrResubmit) return;
                                return completeAssessment(values);
                            }}
                        />
                    )}

                    {deleteModalOpen && <ConfirmDeleteAssessmentModal onClose={handleCloseDeleteModal} />}

                    {activity.id && <ActivityLocking activity={activity} />}
                </FormStyle>
            )}
        </Formik>
    );
};

AssessmentForm.propTypes = {
    activity: activityPropType.isRequired,
    allQuestions: PropTypes.objectOf(questionPropType).isRequired,
    questionsWithChildren: PropTypes.arrayOf(questionPropType).isRequired,
    splitQuestions: PropTypes.shape({
        specialQuestions: PropTypes.arrayOf(questionPropType).isRequired,
        therapySummaryNote: PropTypes.arrayOf(questionPropType).isRequired,
        groupedQuestions: PropTypes.objectOf(PropTypes.arrayOf(questionPropType)).isRequired,
        questions: PropTypes.arrayOf(questionPropType).isRequired,
    }).isRequired,
    questionGroups: PropTypes.arrayOf(questionGroupPropType).isRequired,
    patient: patientPropType.isRequired,
    branchSettings: branchSettingsPropType.isRequired,
};

export default AssessmentForm;
