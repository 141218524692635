import moment from 'moment';

/** @ngInject */
function AssessmentService(_, __env, $http, $httpParamSerializerJQLike, urlBuilderService) {
    return {
        completeAssessment,
        createAssessment,
        deleteAssessment,
        deleteRisk,
        getAssessment,
        getAssessmentCategories,
        getMedAssociation,
        getRiskDetails,
        getRiskOptions,
        getCustomAssessments,
        getModifiedAssessments,
        publishAssessment,
        updateAssessment,
        saveRisk,
    };

    function completeAssessment(patientId, assessmentId) {
        const url = urlBuilderService.build('patientActivityComplete', {
            patient: patientId,
            activity: assessmentId,
            complete: '',
        });

        return $http.post(url).then((res) => {
            return res.data;
        });
    }

    function createAssessment(categoryId, body) {
        _.assignIn(body, {
            angular: true,
            default_note_type: 0,
            group_id: categoryId,
            submit: true,
        });

        return $http
            .post('/stm/assessment_edit.php', $httpParamSerializerJQLike(body), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
            })
            .then((res) => {
                return res;
            });
    }

    function deleteAssessment(assessmentId, groupId) {
        return $http
            .get('/stm/assessment_mgr.php', {
                params: {
                    delete_poll: true,
                    poll_id: assessmentId,
                    group_id: groupId,
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    function deleteRisk(pollId, riskId) {
        return $http({
            method: 'get',
            url: `${urlBuilderService.getHost()}/ajax/risk/delete_risk.php`,
            params: {
                scoring_id: riskId,
                poll_id: pollId,
            },
        }).then((res) => {
            return res.data;
        });
    }

    function getAssessment(categoryId, pollId) {
        return $http
            .get('/stm/assessment_edit.php', {
                params: {
                    angular: true,
                    group_id: categoryId,
                    poll_id: pollId,
                },
            })
            .then((res) => {
                let data = {};
                if (res.status === 200) {
                    data = {
                        assessment_owner: res.data.ASSESSMENT_OWNER,
                        assessment_status: res.data.ASSESSMENT_STATUS === 'checked',
                        category: res.data.GROUP_NAME,
                        cost_savings: res.data.COST_SAVINGS === 'checked',
                        description: res.data.DESCRIPTION,
                        ehr_encounter_note: res.data.EHR_ENCOUNTER_NOTE === true,
                        enhanced_summary_note: res.data.ENHANCED_SUMMARY_NOTE === true,
                        general_specialty: res.data.GENERAL_SPECIALTY === true,
                        hide: res.data.HIDE === true,
                        id: res.data.POLL_ID,
                        mobile_assessment_text_message: res.data.MOBILE_ASSESSMENT_TEXT_MESSAGE,
                        name: res.data.NAME,
                        need_by_date: res.data.NEED_BY_DATE === 'checked',
                        non_specialty_medication: res.data.NON_SPECIALTY_MEDICATION === 'checked',
                        priority: res.data.PRIORITY,
                        require_medication_association: res.data.REQUIRE_MEDICATION_ASSOCIATION === true,
                        referral_activity_for_vista: res.data.REFERRAL_ACTIVITY_FOR_VISTA === true,
                        retire: res.data.RETIRE === true,
                        retire_date: res.data.RETIRE_DATE
                            ? moment.utc(res.data.RETIRE_DATE).format('YYYY/MM/DD')
                            : null,
                        therapy_start_date: res.data.THERAPY_START_DATE === 'checked',
                        therapy_summary_note: res.data.SUMMARY_NOTE === 'checked',
                        type: res.data.ASSESSMENT_TYPE ? String(res.data.ASSESSMENT_TYPE) : '0',
                        mobileEnabled: res.data.MOBILE_ENABLED === true,
                    };

                    res.data = data;
                    return res.data;
                }
                return res.data;
            });
    }

    function getCustomAssessments(categoryId) {
        return $http
            .get('/stm/assessment_mgr.php', {
                params: {
                    only_custom_polls: true,
                    angular: true,
                    group_id: categoryId,
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    function getAssessmentCategories() {
        return $http
            .get('/stm/assessment_category.php', {
                params: {
                    angular: true,
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    function getMedAssociation(patientId, assessmentId) {
        const url = urlBuilderService.build('patientActivityMedication', {
            patient: patientId,
            activity: assessmentId,
            medication: '',
        });

        return $http.get(url).then((res) => {
            return res.data;
        });
    }

    function getModifiedAssessments() {
        return $http({
            method: 'get',
            url: urlBuilderService.build('modifiedAssessments', {
                assessment: 'modified',
            }),
        }).then((res) => {
            return res.data;
        });
    }

    function getRiskOptions(pollId) {
        return $http({
            method: 'get',
            url: urlBuilderService.build('riskOptions', {
                assessment: '',
                questions: pollId,
                riskoptions: '',
            }),
        }).then((res) => {
            return res.data;
        });
    }

    function getRiskDetails(pollId, riskId) {
        return $http({
            method: 'get',
            url: `${urlBuilderService.getHost()}/ajax/risk/load_risk.php`,
            params: {
                scoring_id: riskId,
                poll_id: pollId,
            },
        }).then((res) => {
            return res.data;
        });
    }

    function publishAssessment(assessmentId) {
        return $http({
            method: 'post',
            url: urlBuilderService.build('publish', {
                assessment: assessmentId,
                publish: '',
            }),
        }).then((res) => {
            return res.data;
        });
    }

    function updateAssessment(categoryId, pollId, body) {
        _.assignIn(body, {
            angular: true,
            group_id: categoryId,
            poll_id: pollId,
            submit: true,
        });

        return $http
            .post('/stm/assessment_edit.php', $httpParamSerializerJQLike(body), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
            })
            .then((res) => {
                return res;
            });
    }

    function saveRisk(pollId, scoringJson) {
        return $http({
            method: 'get',
            url: `${urlBuilderService.getHost()}/ajax/risk/save_risk.php`,
            params: {
                scoring_json: JSON.stringify(scoringJson),
                poll_id: pollId,
            },
        }).then((res) => {
            return res.data;
        });
    }
}

export default AssessmentService;
