import { useFormikContext } from 'formik';
import * as R from 'ramda';
import React from 'react';
import * as uuid from 'uuid';
import ActionCell, { ActionButtonLink } from 'App/components/styles/ActionCell';
import { addCondition } from '../../utils';
import ConditionCell from './ConditionCell';
import FilterCell from './FilterCell';

const propTypes = {};

const defaultProps = {};

function AddFilter() {
    //#region State
    const formik = useFormikContext();
    const { values: formikValues } = formik;
    //#endregion

    //#region Actions
    const { setValues } = formik;
    //#endregion

    return (
        <table className="therigy-table add-filter-table">
            <thead>
                <tr>
                    <th className="condition-header">Condition</th>
                    <th className="filter-header">Filters</th>
                    <th className="action-header">Action</th>
                </tr>
            </thead>

            <tbody>
                {R.compose(
                    R.map(([groupName, group]) => {
                        return (
                            <tr key={groupName}>
                                <ConditionCell
                                    groupName={groupName}
                                    onClick={() => {
                                        R.compose(
                                            setValues,
                                            addCondition(groupName, [{ key: uuid.v4(), operator: null, value: '' }])
                                        )(formikValues);
                                    }}
                                />

                                <FilterCell groupName={groupName} group={group} />

                                <td>
                                    <ActionCell>
                                        <ActionButtonLink
                                            onClick={() => {
                                                R.compose(
                                                    setValues,
                                                    R.dissocPath(['conditions', groupName])
                                                )(formikValues);
                                            }}
                                        >
                                            Delete Condition
                                        </ActionButtonLink>
                                    </ActionCell>
                                </td>
                            </tr>
                        );
                    }),
                    R.toPairs,
                    R.propOr([], 'conditions')
                )(formikValues)}
            </tbody>
        </table>
    );
}

AddFilter.propTypes = propTypes;
AddFilter.defaultProps = defaultProps;

export default AddFilter;
