import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';

function useFetchPatientAllergies(patientId) {
    return useQuery(['patient', patientId, 'allergies'], () => (!patientId ? null : PESService.getAllergy(patientId)), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
}

export default useFetchPatientAllergies;
