/** @ngInject */
function QueueManagerConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.queueManager',
        resolve: {
            branches($transition$, queueManagerService) {
                return $transition$
                    .injector()
                    .getAsync('user')
                    .then((res) => {
                        return queueManagerService.getFieldOrder(res.active_company.ID);
                    });
            },
        },
        url: '/queue-manager',
        views: {
            '': 'queueManager',
            'pageTitle@^': {
                template: 'Queue Manager',
            },
        },
    });
}

export default QueueManagerConfig;
