import React from 'react';
import PropTypes from 'prop-types';
import './patientPrescription.scss';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import * as R from 'ramda';
import { DatePickerInput } from '../../../lib/form/DatePickerField';
import useFetchRequiredPatientFields from 'App/hooks/useFetchRequiredPatientFields';
import useFetchDisabledPatientFields from 'App/hooks/useFetchDisabledPatientFields';
import { cx } from '@emotion/css';

const propTypes = {
    fillIndex: PropTypes.number,
    rxIndex: PropTypes.number,
    rxFill: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            fillNumber: PropTypes.number,
            fillDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
            daysSupply: PropTypes.number,
            dispensedQuantity: PropTypes.number,
        })
    ),
    handleFillPropChange: PropTypes.func,
    medicationId: PropTypes.string,
    removeFillNumber: PropTypes.func,
    validateRxFillNumber: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    validateRxFillNumberErrors: PropTypes.object,
};
const defaultProps = {
    fillIndex: 0,
    rxIndex: 0,
    rxFill: [],
    handleFillPropChange: () => {},
    medicationId: '',
    removeFillNumber: () => {},
    validateRxFillNumber: () => {},
    validateRxFillNumberErrors: {},
};

function RxFill(props) {
    const {
        fillIndex,
        rxIndex,
        rxFill,
        handleFillPropChange,
        medicationId,
        removeFillNumber,
        validateRxFillNumber,
        validateRxFillNumberErrors,
    } = props;
    const { data: requiredPatientFields } = useFetchRequiredPatientFields();
    const { data: disabledPatientFields } = useFetchDisabledPatientFields();
    const requiredPatientFieldsArr = Object.values(requiredPatientFields || {}).map((item) => item.field);
    const disabledPatientFieldsArr = Object.values(disabledPatientFields || {}).map((item) => item.field);

    function handleFillsQuantity(id, value) {
        if (value < 1) {
            handleFillPropChange(medicationId, rxIndex, fillIndex, id, 1);
        } else {
            handleFillPropChange(medicationId, rxIndex, fillIndex, id, +value);
        }
    }

    const currentRxFill = rxFill[fillIndex];

    return (
        <div>
            <Row className="rxNumber-group rxFill-group">
                <Col className="col-sm-4 col-md-5th">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="fillNumber"
                            className={cx({
                                'invalid-insurance-field field-required':
                                    requiredPatientFieldsArr.includes('fill_number') &&
                                    validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].fillNumber`],
                                'field-required': requiredPatientFieldsArr.includes('fill_number'),
                            })}
                        >
                            Fill Number
                        </ControlLabel>
                        <FormControl
                            id="fillNumber"
                            name="fillNumber"
                            type="number"
                            min="0"
                            placeholder="Fill Number"
                            disabled={disabledPatientFieldsArr.includes('fill_number')}
                            className={cx({
                                'invalid-insurance-form':
                                    requiredPatientFieldsArr.includes('fill_number') &&
                                    validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].fillNumber`],
                            })}
                            autoComplete="off"
                            onChange={(event) => {
                                handleFillPropChange(
                                    medicationId,
                                    rxIndex,
                                    fillIndex,
                                    event.target.id,
                                    +event.target.value
                                );
                                validateRxFillNumber(rxIndex, event.target.id, +event.target.value, fillIndex);
                            }}
                            value={R.propOr('', 'fillNumber', currentRxFill)}
                        />
                        {requiredPatientFieldsArr.includes('fill_number') &&
                        validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].fillNumber`] ? (
                            <span className="error-message-insurance">
                                {validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].fillNumber`]}
                            </span>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col className="col-sm-4 col-md-5th">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="fillDate"
                            className={cx('null', {
                                'invalid-insurance-field field-required':
                                    requiredPatientFieldsArr.includes('fill_date') &&
                                    validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].fillDate`],
                                'field-required': requiredPatientFieldsArr.includes('fill_date'),
                            })}
                        >
                            Fill Date
                        </ControlLabel>
                        <DatePickerInput
                            name="fillDate"
                            id="fillDate"
                            placeholder="YYYY/MM/DD"
                            value={rxFill?.[fillIndex]?.fillDate ? new Date(rxFill[fillIndex].fillDate) : null}
                            disabled={disabledPatientFieldsArr.includes('fill_date')}
                            className={cx({
                                'invalid-insurance-form':
                                    requiredPatientFieldsArr.includes('fill_date') &&
                                    validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].fillDate`],
                            })}
                            onBlur={() => {}}
                            onChange={(date) => {
                                handleFillPropChange(medicationId, rxIndex, fillIndex, 'fillDate', date);
                                validateRxFillNumber(rxIndex, 'fillDate', date, fillIndex);
                            }}
                        />
                        {requiredPatientFieldsArr.includes('fill_date') &&
                        validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].fillDate`] ? (
                            <span className="error-message-insurance">
                                {validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].fillDate`]}
                            </span>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col className="col-sm-4 col-md-5th">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="dispensedQuantity"
                            className={cx({
                                'invalid-insurance-field field-required':
                                    requiredPatientFieldsArr.includes('dispensed_quantity') &&
                                    validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].dispensedQuantity`],
                                'field-required': requiredPatientFieldsArr.includes('dispensed_quantity'),
                            })}
                        >
                            Dispensed Qty
                        </ControlLabel>
                        <FormControl
                            id="dispensedQuantity"
                            name="dispensedQuantity"
                            type="number"
                            min="1"
                            placeholder="Dispensed Qty"
                            disabled={disabledPatientFieldsArr.includes('dispensed_quantity')}
                            className={cx({
                                'invalid-insurance-form':
                                    requiredPatientFieldsArr.includes('dispensed_quantity') &&
                                    validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].dispensedQuantity`],
                            })}
                            autoComplete="off"
                            onChange={(event) => {
                                handleFillPropChange(
                                    medicationId,
                                    rxIndex,
                                    fillIndex,
                                    event.target.id,
                                    +event.target.value
                                );
                                validateRxFillNumber(rxIndex, event.target.id, +event.target.value, fillIndex);
                                handleFillsQuantity(event.target.id, +event.target.value);
                            }}
                            value={currentRxFill.dispensedQuantity >= 1 ? currentRxFill.dispensedQuantity : 1}
                        />
                        {requiredPatientFieldsArr.includes('dispensed_quantity') &&
                        validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].dispensedQuantity`] ? (
                            <span className="error-message-insurance">
                                {validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].dispensedQuantity`]}
                            </span>
                        ) : null}
                    </FormGroup>
                </Col>
                <Col className="col-sm-4 col-md-5th">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="daysSupply"
                            className={cx({
                                'invalid-insurance-field field-required':
                                    requiredPatientFieldsArr.includes('days_supply') &&
                                    validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].daysSupply`],
                                'field-required': requiredPatientFieldsArr.includes('days_supply'),
                            })}
                        >
                            Days Supply
                        </ControlLabel>
                        <FormControl
                            id="daysSupply"
                            name="daysSupply"
                            type="number"
                            min="1"
                            disabled={disabledPatientFieldsArr.includes('days_supply')}
                            placeholder="Days Supply"
                            className={cx({
                                'invalid-insurance-form':
                                    requiredPatientFieldsArr.includes('days_supply') &&
                                    validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].daysSupply`],
                            })}
                            autoComplete="off"
                            onChange={(event) => {
                                handleFillPropChange(
                                    medicationId,
                                    rxIndex,
                                    fillIndex,
                                    event.target.id,
                                    +event.target.value
                                );
                                validateRxFillNumber(rxIndex, event.target.id, +event.target.value, fillIndex);
                                handleFillsQuantity(event.target.id, +event.target.value);
                            }}
                            value={currentRxFill.daysSupply > 0 ? currentRxFill.daysSupply : 1}
                        />
                        {requiredPatientFieldsArr.includes('days_supply') &&
                        validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].daysSupply`] ? (
                            <span className="error-message-insurance">
                                {validateRxFillNumberErrors[rxIndex]?.[`rxFill[${fillIndex}].daysSupply`]}
                            </span>
                        ) : null}
                    </FormGroup>
                </Col>
                {rxFill?.[fillIndex]?.id === null || rxFill?.[fillIndex]?.id === undefined ? (
                    <Col className="col-sm-4 col-md-5th">
                        <ControlLabel
                            className="rxNumber-group-remove"
                            onClick={() => removeFillNumber(rxIndex, fillIndex)}
                        >
                            <i className="fas fa-fw fa-times" />
                        </ControlLabel>
                    </Col>
                ) : null}
            </Row>
        </div>
    );
}

RxFill.propTypes = propTypes;
RxFill.defaultProps = defaultProps;

export default RxFill;
