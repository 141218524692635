import { useEffect } from 'react';
import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';
import { getPatientSubscriptions } from 'App/components/PatientProfile/PatientProfile.utils';
import useProfileDataState from './useProfileDataState';

/**
 * Custom hook which make all the http call to fetch messaging subscription required to render patient detail page
 * @param {string} patientId
 * @returns {Object}
 */
function useGetPatientSubscriptions(patientId) {
    const {
        data: patientMessagingSubscription,
        setData: setPatientMessagingSubscription,
        isLoading: isSubscriptionLoading,
        triggerRefetch: triggerSubscriptionRefetch,
        refetch,
        setIsLoading,
    } = useProfileDataState(null);

    useEffect(() => {
        if (!patientId) {
            return;
        }
        PESService.getPatientSubscriptions(patientId)
            .then((subscriptionResult) => {
                const [, patientMessagingSubscriptions] = getPatientSubscriptions(subscriptionResult);
                setPatientMessagingSubscription(patientMessagingSubscriptions);
            })
            .catch(errorHandler)
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, refetch]);

    return {
        patientMessagingSubscription,
        isSubscriptionLoading,
        triggerSubscriptionRefetch,
    };
}

export default useGetPatientSubscriptions;
