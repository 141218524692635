import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
    url: '',
};

const propTypes = {
    url: PropTypes.string,
};

const WithIframe = (props) => {
    const { url } = props;
    const containerRef = useRef(null);

    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.clientHeight);

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        // Get the total client height from the parent window object
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-globals
        const { clientHeight } = parent.document.body;
        // Get the offset from top of the container
        const { offsetTop } = containerRef.current;
        // Get the footer height from the offsetHeight of node with id "footer"
        // multiplied by 2 to account for main container's height offset
        const footerHeight = document.getElementById('footer').offsetHeight * 2;
        // Get and set the total desired height of the iframe from the calculation
        setHeight(clientHeight - offsetTop - footerHeight);
    };

    useEffect(() => {
        updateWidthAndHeight();
        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    }, []);

    return url ? (
        <div ref={containerRef} data-testid="with-iframe">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe src={url} width={width} height={height} style={{ border: 'none', padding: '1rem' }} />
        </div>
    ) : null;
};

WithIframe.defaultProps = defaultProps;
WithIframe.propTypes = propTypes;

export default WithIframe;
