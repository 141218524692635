import getSlsBasePath from '../utils/getSlsBasePath';
import config from '../components/config/config';
import SessionStorageService from '../services/SessionStorageService';
import { GraphQLClient } from 'graphql-request';

const url = `${getSlsBasePath(config, 'stm')}/stm/1.0.0/graph`;

const graphClient = new GraphQLClient(url, {
    headers: () => ({
        Authorization: `Bearer ${SessionStorageService.getJwt()}`,
        Accept: 'application/json',
    }),
});

export default graphClient;
