import moment from 'moment';
import SessionStorageService from 'App/services/SessionStorageService';

/**
 * Looks up users relevant time settings (offset + dst) and returns
 * plain string adjusted accordingly.
 *
 * @param {String | Date} date - provided date, assumes its UTC.
 * @param {String} dateFormat - custom format https://momentjs.com/docs/#/displaying/format/
 * @return {String | null} string localized to user account settings
 */
function localize(date, dateFormat = 'YYYY-MM-DD') {
    const dateString = date instanceof Date ? date.toISOString() : date;
    if (
        typeof dateString !== 'string' ||
        dateString.length < 10 ||
        dateString === '0000-00-00 00:00:00' ||
        typeof dateFormat !== 'string' ||
        dateFormat.length === 0
    ) {
        return null;
    }

    // eslint-disable-next-line new-cap
    const result = new moment.utc(dateString);
    if (result.isValid() === false) {
        return null;
    }

    const { data: userSettings } = SessionStorageService.getDecodedJwt();
    const { TimeZoneOffset, DaylightSavings } = userSettings;
    let offset = TimeZoneOffset;
    if (DaylightSavings === 1) {
        // new local instance
        // eslint-disable-next-line new-cap
        const momentLocalInstance = new moment.utc(dateString).local();
        if (momentLocalInstance.isDST()) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-plusplus
            offset++;
        }
    }
    result.add(offset, 'hours');

    return result.format(dateFormat);
}

export default localize;
