/** @ngInject */
function ReferralActivityFinancialAssistanceController($state, toastr, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.save = save;

    function $onChanges(changes) {
        if (ctrl.activity && changes.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
            ctrl.original = angular.copy(ctrl.activity);

            ctrl.activity.status = ctrl.activity.status ? ctrl.activity.status : 'Pending';
            ctrl.activity.status_reason = ctrl.activity.status_reason ? ctrl.activity.status_reason : 'New';
        }
    }

    function $onInit() {
        ctrl.activityId = $state.params.activityId;
        ctrl.patientId = $state.params.patientId;
        ctrl.referralId = $state.params.referralId;
    }

    function save() {
        return rmsService
            .updateActivity(ctrl.activity.patient_id, ctrl.activity.referral_id, ctrl.activity.id, ctrl.activity)
            .then((res) => {
                toastr.success('Activity information has been updated successfully.');
                $state.go('app.referral', {
                    patientId: ctrl.patientId,
                    referralId: ctrl.referralId,
                });
                return res;
            })
            .catch((err) => {
                toastr.error('Activity information did not update successfully. Please try again.');
                return err;
            });
    }
}

export default ReferralActivityFinancialAssistanceController;
