import { $q } from 'angular-ui-router';

/** @ngInject */
function UserManagerController(divisionService, userService, CSRF_ID_VALUE, CSRF_ID_NAME) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;
    ctrl.branchList = [];

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            userService
                .getBranches()
                .then((branches) => {
                    if (branches) {
                        ctrl.branchList = Object.values(branches);
                        if (ctrl.branchList && Array.isArray(ctrl.branchList)) {
                            const promises = ctrl.branchList.reduce((acc, branch) => {
                                acc[branch.ID] = divisionService.getUsersById(branch.ID);
                                return acc;
                            }, {});
                            return $q.all(promises);
                        }
                    }
                    return false;
                })
                .then((usersPromises) => {
                    if (usersPromises) {
                        ctrl.users = usersPromises;
                    }
                });
        }
    }
}

export default UserManagerController;
