/** @ngInject */
function PatientEmergencyContactInformationController($scope) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeValue = doChangeValue;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;

    function $onChanges(changes) {
        if (changes.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
        }
    }
    function doChangeValue() {
        $scope.$emit('addPatientPage_updatePatient', {
            ec_first_name: ctrl.patient.ec_first_name,
            ec_last_name: ctrl.patient.ec_last_name,
            ec_mobile_phone: ctrl.patient.ec_mobile_phone,
            ec_relationship: ctrl.patient.ec_relationship,
            ec_telephone: ctrl.patient.ec_telephone,
        });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
    }
}

export default PatientEmergencyContactInformationController;
