/** @ngInject */
function PatientSynchronizationController(_, patientService, __env) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.closeAlert = closeAlert;

    /**
     * Directive initialisation
     */
    function $onInit() {
        ctrl.fieldsToImportFromPdx = [
            'addressLine1',
            'addressLine2',
            'birthDate',
            'city',
            'country',
            'email',
            'ethnicity',
            'externalId',
            'firstName',
            'gender',
            'homePhone',
            'languageSpoken',
            'lastName',
            'maritalStatus',
            'middleName',
            'mobilePhone',
            'state',
            'zip',
        ];
        ctrl.integration = {
            pdx: {},
        };
        ctrl.supportWebsite = __env.supportWebsite;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges() {
        if (ctrl.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
            ctrl.originalPatient = angular.copy(ctrl.patient);

            if (ctrl.patient.pdx) {
                ctrl.integration.pdx.searching = true;

                // Query PDX for patient changes
                return patientService
                    .getPDX(ctrl.patient.pdx.rxcomId)
                    .then((res) => {
                        ctrl.integration.pdx.status = 'success';
                        const filtered = _.pick(res, ctrl.fieldsToImportFromPdx);
                        const modified = fieldsModified(ctrl.originalPatient, filtered);

                        if (modified.length > 0) {
                            return submitPatient(ctrl.patient.id, _.pick(filtered, modified));
                        }
                        return true;
                    })
                    .catch((err) => {
                        ctrl.integration.pdx.status = err.status;
                    })
                    .finally(() => {
                        ctrl.integration.pdx.searching = false;
                    });
            }
        }
    }

    /**
     * Close Alert
     * @return void
     */
    function closeAlert() {
        ctrl.integration.pdx.status = false;
    }

    /**
     * @param id
     * @param patient
     * @return promise
     */
    function submitPatient(id, patient) {
        // Ensure correct key caseing for legacy api.
        _.each(patient, (value, key) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete patient[key];
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            patient[_.snakeCase(key)] = value;
        });
        return patientService.update(id, patient);
    }

    /**
     * Function that returns the difference between two objects
     * @param original
     * @param modified
     */
    function fieldsModified(original, modified) {
        if (!original || !modified) {
            return;
        }
        const differences = [];

        _.each(modified, (value, key) => {
            if (original[key] && typeof value === 'string' && value.toLowerCase() !== original[key].toLowerCase()) {
                if (differences.indexOf(key) === -1) {
                    differences.push(key);
                }
            }
        });
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return differences;
    }
}

export default PatientSynchronizationController;
