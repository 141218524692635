import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import pako from 'pako';
import io from 'socket.io-client';
import $ from 'jquery';

// Import Interceptors config
import HttpInterceptorConfig from './httpInterceptor.config';

// Import all the env variables from config.js
import env from './config.js';

// TODO: Deprecate all the CSRF* vars from angular components
const CSRF_ID_NAME = '';
const CSRF_ID_VALUE = '';

/**
 * app.constants contains all of the application's global variables
 */
export default angular
    .module('app.constants', [])
    .constant('__env', env)
    .constant('_', _)
    .constant('_paq', [])
    .constant('$', $)
    .constant('CSRF_ID_VALUE', CSRF_ID_VALUE)
    .constant('CSRF_ID_NAME', CSRF_ID_NAME)
    .constant('io', io)
    .constant('moment', moment)
    .constant('pako', pako)
    .config(HttpInterceptorConfig).name;
