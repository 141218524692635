import { useQuery } from 'react-query';

import PESService from 'App/services/PESService';
import { errorHandler } from 'App/utils';

function useFetchSummaryNotes(patientId, type) {
    return useQuery(
        ['summaryNotes', patientId, type],
        () => (!patientId ? null : PESService.getSummaryNotes(patientId, type)),
        {
            onError: errorHandler,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchSummaryNotes;
